import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class CertificationService {

  constructor(
    public service: ApiRootService,
    public http: HttpClient,
    public storage: SessionStorageService,
    public common: CommonService
  ) { }

  /* Name:Shailesh
      use: for getting admin appointment report by org code */
  getAdminAppointmentReport(date, orgCode, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAdminAppointmentReport/' + date + '/' + orgCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  }

  /* Name:Shailesh
     use: for getting weekly report of client by from date, to date and org code */
  getWeeklyReportOfClinician(wcode, fromdt, todt, orgCode, callback) {
    return this.http.get(
      this.service.getHealthCareUrl() + '/getWeeklyReportOfClinician/' + wcode + '/' + fromdt + '/' + todt + '/' + orgCode).
      subscribe((data) => {
        callback(data);
      });
  }

  
  /* Name:Vivek Chauhan
     use: for getting all Certification Master data list by page */
     getAllOrgCertificationMasterByOrganizationCodeByPage(orgCode, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getOrgCertificationMasterByOrganizationCodeByPage/' + orgCode).subscribe((data) => {
        callback(data);
      });
    }

  /* Name:Vivek Chauhan
     use: for getting all Certification Master data list by page */
    getOrgCertificationMasterByOrganizationCodeByPage(orgCode, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getOrgCertificationMasterByOrganizationCodeByPage/' + orgCode+ '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  }

  /* Name:Shailesh
     use: for getting all tracking details by user code */
  getAllUserTrackingByUserCodeAndDate(userCode,date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllUserTrackingByUserCodeAndDate/' + userCode + '/' + date).subscribe((data) => {
      callback(data);
    });
  }

    /* Name:Vivek Chauhan new API
    use: for adding/creating new Certification */
    saveAndUpdateOrgCertificationMaster(data, callback) {
      return this.http.post(this.service.HEALTHCARE_URL + '/saveAndUpdateOrgCertificationMaster', data).subscribe((data) => {
      callback(data);
      });
    }

  /* Name:Vivek Chauhan
  use: for Srarch Licence list by page */
  getOrgCertificationMasterByOrganizationCodeByPageSearch(orgCode, searchText, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getOrgCertificationMasterByOrganizationCodeByPage/'+ orgCode + '/' + searchText).subscribe((data) => {
      callback(data);
    });
  } 


  /* Name:Vivek Chauhan new API
    use: for delete/remove Certification */
    softDeleteOrgCertificationMasterByCertificationCode(certificationCode, callback) {
        return this.http.delete(this.service.HEALTHCARE_URL + '/softDeleteOrgCertificationMasterByCertificationCode/' + certificationCode).subscribe((data) => {
          callback(data);
    
        })
      }
}
