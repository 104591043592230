import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewJobCardService } from '../new-job-card.service';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'notify-job',
  templateUrl: './notify-job.component.html',
  styleUrls: ['./notify-job.component.scss']
})
export class NotifyJobComponent implements OnInit {

  img_serverPath: string = '';
  selectedStaff: any;
  showSpinner: boolean = false;
  selected_appoinmentProcedureCode: string;
  selected_appoinmentDurationCode: string;
  selected_appoinmentCode: string
  selected_date: string;
  distributionList: any = [];
  distributionList_Filter: any = [];
  distributionSeachInput: FormControl = new FormControl();
  staffSeachInput: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  selectedDistribution: any = [];
  selectedSkills: any[] = [];
  eligibleStaffsList: any = [];
  filtered_eligibleStaffsList: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<NotifyJobComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private jobCardService: NewJobCardService, 
    public dashboarService: DashboardService,
    private sessionService: SessionStorageService) { }

  ngOnInit(): void {
    // console.log('AdssignPopupData: ', this.data);  
    this.img_serverPath = this.jobCardService._apiSVC.getSuperAdmin() + '/downloadFile/';
    if(this.data && this.data?.appoinmentCode && this.data?.appoinmentProcedureCode && this.data?.date){
      this.selected_appoinmentCode = this.data.appoinmentCode;
      this.selected_appoinmentProcedureCode = this.data.appoinmentProcedureCode;
      this.selected_date = this.data.date;
      this.getDistributionList();
      this.getEligibleStaffs(this.data.appoinmentCode, this.data.appoinmentProcedureCode, this.data.date);
      this.adminGetEligibleStaffForNotify(this.data.appoinmentCode, this.data.appoinmentProcedureCode, this.data.date);
    }  
  }

  closePopup(isPopup = null): void {
    this.filtered_eligibleStaffsList = [];
    this.selected_appoinmentCode = null;
    this.selected_appoinmentProcedureCode = null;
    this.selected_date = null;
    this.selectedDistribution = [];
    this.selectedSkills = [];
    this.selectedStaff = null;
    this.selected_appoinmentDurationCode = null;
    this.dialogRef.close(isPopup);
  }

  getEligibleStaffs(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    this.showSpinner = true;
    this.jobCardService.getEligibleStaffs(appoinmentCode, appoinmentProcedureCode, date).subscribe((res: any) => {
      this.showSpinner = false;
      if (res.responsecode == 200) {
        this.eligibleStaffsList = res.data.workerWithDistanceList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;
        this.selectedStaff = this.eligibleStaffsList.find(ele => ele.isAssigned);
        this.staffSeachInput.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterStaff();
          });
      }
    });
  }  

  adminGetEligibleStaffForNotify(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    this.showSpinner = true;
    this.jobCardService.adminGetEligibleStaffForNotify(appoinmentCode, appoinmentProcedureCode, date).subscribe((res: any) => {
      this.showSpinner = false;
      if (res.responsecode == 200) {
        this.eligibleStaffsList = res.data.workerWithDistanceList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;
        this.selectedStaff = this.eligibleStaffsList.find(ele => ele.isAssigned);
        this.staffSeachInput.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterStaff();
          });
      }
    });
  }
  
  protected filterStaff() {
    if (this.eligibleStaffsList.length == 0) {
      return;
    }
    let search = this.staffSeachInput.value.trim();
    this.filtered_eligibleStaffsList = this.eligibleStaffsList.filter(ele => ele.worker.name.toLowerCase().includes(search.toLowerCase()));
  }

  getDistributionList() {
    let org_code = this.sessionService.get('orgCode');
    this.jobCardService.getAllDistributionList(org_code).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.distributionList = res.data;
        this.distributionList_Filter = this.distributionList;
        this.distributionSeachInput.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDistribution();
          });
      }
    });
  }

  protected filterDistribution() {
    if (this.distributionList.length == 0) {
      return;
    }
    let search = this.distributionSeachInput.value.trim();
    this.distributionList_Filter = this.distributionList.filter(ele => ele.distributionName.toLowerCase().includes(search.toLowerCase()));
  }

  sendNotification() {
    if ((this.selectedDistribution && this.selectedDistribution.length > 0) || (this.selectedSkills && this.selectedSkills.length > 0)) {
      // nothhing do
    } else { 
      this.dashboarService.common.message = 'Please select either distribution or staff';
      this.dashboarService.common.errorMessage = true;
      return;
    }

    let payload = {
      "appointmentsDurationCode": null,
      "appointmentsCode": this.selected_appoinmentCode,
      "appointmentsProceduresCode": this.selected_appoinmentProcedureCode,
      "date": this.selected_date,
      "organizationCode": this.sessionService.get('orgCode'),
      "distributionCodeList": this.selectedDistribution,
      "userCodeList": this.selectedSkills
    }
    // console.log('sendNotification: ', payload);
    // return;
    this.dashboarService.common.progressLoader = true;
    this.jobCardService.PublishJob(payload).subscribe((res: any) => {
      this.dashboarService.common.progressLoader = false;
      if (res.responsecode == 200) {
        this.dashboarService.common.successMessage = true;
        this.dashboarService.common.message = res.message;
        this.closePopup('success');
      }
      else {
        this.dashboarService.common.message = res.message;
        this.dashboarService.common.errorMessage = true;
      }
    })
  }


  displayText()
  {
     if(this.filtered_eligibleStaffsList.length > 0 && this.selectedSkills.length > 0)
      {
        const texts : any[] = this.filtered_eligibleStaffsList.filter(ele=> this.selectedSkills.includes(ele.worker.workerCode)) || [];

        return texts.length > 0 ? texts.map(ele=>ele.worker.name) : null ;
      }
  }

}
