import { Component, OnInit, Inject } from '@angular/core';
import { AppointmentService } from '../appointment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-appointment-filter',
  templateUrl: './appointment-filter.component.html',
  styleUrls: ['./appointment-filter.component.scss']
})
export class AppointmentFilterComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  procedureCode = 'all';
  constructor(
    public service: AppointmentService,
    public dialogRef: MatDialogRef<AppointmentFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

    ////console.log(this.procedureCode);
  try {
  
    this.service.getAllProcedureByOrganizationCode(this.service.session.get('orgCode'), (response) => {
      // ////console.log(response);
      if (response.responsecode) {
        response.data.forEach(element => {
          element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
          element.itemName = element.procedureName;
          this.dropdownList.push(element);
        });
        if(this.data.data == 'all'){
          this.selectedItems = response.data;
        } else {
          const selected = [];
          let prevSelected = [];
          prevSelected = this.data.data.split(',');
          // ////console.log(prevSelected);
          // ////console.log(this.dropdownList);
          prevSelected.forEach((element)=>{
            let pro = this.dropdownList.filter((e)=>{
              return e.organizationBaseProcedureCode == element;
            });
            // ////console.log(pro);
            if(pro){
              selected.push(pro.pop());
            }
          });
          setTimeout(()=>{
            // ////console.log(selected);
            this.selectedItems = selected;
          },200)
        }
      }
    })
} catch (error) {
  
}
    this.dropdownSettings = {
      singleSelection: false,
      text: "",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect filter-height",
      // badgeShowLimit: 5,
      maxHeight:200,
      autoPosition: true
    };

  }


  onItemSelect(item: any) {
    ////console.log(item);
  }
  OnItemDeSelect(item: any) {
    ////console.log(item);
    //////console.log(this.selectedItems);
    // this.getFilterdData();
  }

  onSelectAll(items: any) {
    ////console.log(items);
    //  this.getFilterdData();
  }
  onDeSelectAll(items: any) {
    ////console.log(items);
  }
/* Name:srinivas
       use: return cancel popup */
  onNoClick(status): void {
    if(status != 'all'){
      let selectedData = []
      if (this.selectedItems.length > 0) {
        this.selectedItems.forEach(element => {
          selectedData.push(element.organizationBaseProcedureCode);
        });
        ////console.log(this.selectedItems.length);
        ////console.log(selectedData.length);
        if(this.dropdownList.length == selectedData.length){
          this.procedureCode = 'all';
        } else {
          this.procedureCode = selectedData.toString();
        }
      }
    } else {
      this.procedureCode = 'all';
    }
    this.dialogRef.close(this.procedureCode);
  }

  onClose(){
    this.dialogRef.close();
  }
}
