import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { NewAppointmentService } from './new-appointment.service';
import { MatDialog } from '@angular/material/dialog';
import { RejectAppointmentComponent } from './reject-appointment/reject-appointment.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { ProcedureDetailComponent } from '../../providers-dashboard/appointment/procedure-detail/procedure-detail.component';
import { NotesPopupComponent } from '../../providers-dashboard/appointment/notes-popup/notes-popup.component';

@Component({
  selector: 'app-new-appointment',
  templateUrl: './new-appointment.component.html',
  styleUrls: ['./new-appointment.component.scss']
})

export class NewAppointmentComponent implements OnInit {

  navDate: any;
  currentDate = new Date();
  startDate: any;
  endDate: any;
  selectDate;
  selectedDate: any;
  weekDaysHeaderArr: Array<string> = [];
  currentSelectedDate;
  @Input() locale: string;
  gridArr: Array<any> = [];
  gridArrNew: Array<any> = [];
  appointmentCount = [];
  appointmentData = [];
  loggedUserType: any;
  selectedAppointmentDate = moment(this.currentDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  showPopup;
  showImg;
  page = 0;
  limit = 30;
  img_serverPath:string ='';
  constructor(public service: NewAppointmentService, public dialog: MatDialog,  public translate: TranslateService, public common: CommonService) { 
    this.translate.setDefaultLang(this.common.local.get('language'));
  }

  ngOnInit() {
    this.img_serverPath =  this.service.service.getSuperAdmin() + '/downloadFile/';
    this.selectDate = moment(this.currentDate).format('D');
    this.selectedDate = moment(this.currentDate).format('DD MMM');
    this.currentSelectedDate = moment(new Date()).format('YYYY-MM-DD');
    let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    // this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    this.startDate = moment().startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    this.loggedUserType = this.service.session.get('user_type');
    moment.locale(this.locale);
    this.navDate = moment();
    this.makeHeader();
    this.createBlanlGrid();
    this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
  }

  /* Name:srinivas
    use: returns month navigation . */

  changeNavMonth(num: number, monthData = null) {
    this.navDate.add(num, 'month');
    // //////console.log(this.datepipe.transform(this.navDate, 'dd/MM/yyyy'));
    // //////console.log(this.datepipe.transform(moment(), 'dd/MM/yyyy'));

    //////console.log(moment(this.navDate).format('DD/MM/YYYY'));
    //////console.log(moment().format('DD/MM/YYYY'));

    //////console.log(this.selectDate);
    // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1, 0);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    // if (this.datepipe.transform(this.currentDate, 'MMM') == this.datepipe.transform(firstDay, 'MMM')) {
    // if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
      // this.startDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
      // this.selectDate = +this.datepipe.transform(this.currentDate, 'dd');
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
      this.selectDate = moment(this.currentDate).format('DD');
      this.startDate = moment(firstDay).format('YYYY-MM-DD');
    // } 
    // this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    // this.selectedDate = this.datepipe.transform(this.dateFromNum(num, this.navDate), 'dd MMMM');
    // //console.log('firstDay', firstDay, this.startDate)
    // this.selectedDate = moment(this.dateFromNum(num, this.navDate)).format('dd MMM');
    this.currentSelectedDate = this.startDate;
    this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
 
  }

  /* Name:srinivas
    use: return date after adds number. */
  dateFromNum(num: number, referenceDate: any): any {
    let returnDate = moment(referenceDate);
    return returnDate.date(num);
  }
  /* Name:srinivas
    use: returns new header */

  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
  }
/* Name:srinivas
    use: create empty grid. */
  createBlanlGrid() {
    this.gridArrNew = [];
    this.gridArr = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {

        obj.value = i - initialEmptyCells + 1;
        obj.available = true;
        obj.highlight = '';
        obj.date = dateToCheck;
      }
      this.gridArr.push(obj);
    }
    this.gridArrNew=this.gridArr;
// //console.log('gridArrNew_1', this.gridArrNew);
    // this.service.common.progressLoader = false;

  }

  /* Name:srinivas
    use: returns new appointment by technician code and date. */
  getNewAppointment(code, startDate, endDate){
    // //////console.log('in');
    this.service.common.progressLoader = true;
    try {
      
      this.service.mobileGetStatusCountByTechnicianCodeAndBetweenDates(code, startDate, endDate,'assigned', (data)=>{
      ////console.log(data);
      if(data.responsecode == 200){
        this.appointmentCount = data.data;
        this.getNewAppointments(data.data);

        // this.service.common.progressLoader = false;
      } else {
        this.appointmentCount = [];
        this.createBlanlGrid();
        this.service.common.progressLoader = false;
      }
    }); 
  } catch (error) {
      
    }
  }
/* Name:srinivas
    use: returns all new appointments. */
  getNewAppointments(allData, startDate = null) {
    this.gridArr=[];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      // //////console.log(moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY'));
     // //console.log(allData.length);
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {
        if (allData.length > 0) {
          allData.forEach(element => {
            if(element.dateFormat == 'dd-MM-yyyy'){
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');

            } else if(element.dateFormat == 'MM-dd-yyyy'){
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MM-DD-YYYY');

            } else {
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');

            }
            if (dateToCheck == element.appointmentDate && element.appointmentCount != 0) {
              obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
              obj.available = true;
              obj.event = true;
              obj.eventData.push(element);
              obj.highlight = (obj?.currentDate > this.selectedDate) && element?.appointmentCount != 0 ? 'accepted' : '';
              obj.value = i - initialEmptyCells + 1;
              obj.date = dateToCheck;
              obj.dateFormat = element.dateFormat;
            } else {
              // obj.currentDate = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate), 'dd MMM');
              obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
              obj.available = true;
              obj.value = i - initialEmptyCells + 1;
              obj.date = dateToCheck;
              obj.dateFormat = element.dateFormat;
              obj.key = obj.currentDate;

            }
          });
        } else {
          obj.date = dateToCheck;
          obj.available = true;
          obj.value = i - initialEmptyCells + 1;
          obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');

        }
        // obj.highlight = '';
      }
      this.gridArr.push(obj);
     
    }
    // //////console.log(allData);
    // //////console.log(moment().format('MM-DD-YYYY'));
    const todaysDate = allData.filter((element)=>{
      return element.appointmentDate == moment().format('MM-DD-YYYY');
    });
    //////console.log(todaysDate);
    this.appointmentCount = todaysDate;
    this.gridArrNew=this.gridArr;
  //  //console.log('gridArrNew', this.gridArrNew);
    this.getAppointments(this.currentSelectedDate, this.service.common.pageOffset);
  }
/* Name:srinivas
    use: returns selected day. */
  selectDay(day: any) {
    // //console.log('selectDay: ', day);

    let momentObj;
    let momentString;
    
    if (day.dateFormat == 'dd-MM-yyyy') {
      momentObj = moment(day.date, 'DD-MM-YYYY');

    } else if (day.dateFormat == 'MM-dd-yyyy') {
      momentObj = moment(day.date, 'MM-DD-YYYY');

    } else {
      momentObj = moment(day.date, 'YYYY-MM-DD');

    }
    momentString = momentObj.format('DD MMM'); // 2016-07-15


    //const momentObj = moment(day.date, 'MM-DD-YYYY');
    //const momentString = momentObj.format('DD MMM');
    //////console.log(momentString);
    this.currentSelectedDate = momentString;
    this.selectDate = day.value;
    this.selectedDate = momentString;
    this.appointmentCount = day.eventData;
    this.selectedAppointmentDate = momentObj.format('YYYY-MM-DD');
    // this.startDate = this.selectedAppointmentDate;
    // this.endDate = this.selectedAppointmentDate;
    this.getAppointments(this.selectedAppointmentDate, this.service.common.pageOffset);
    // this.getNewAppointment(this.service.session.get('userCode'), momentString, momentString);
  }

  selectDayStatus(day: any) {
    // //console.log('selectDay: ', day);

    let momentObj;
    let momentString;
    
    if (day.dateFormat == 'dd-MM-yyyy') {
      momentObj = moment(day.date, 'DD-MM-YYYY');

    } else if (day.dateFormat == 'MM-dd-yyyy') {
      momentObj = moment(day.date, 'MM-DD-YYYY');

    } else {
      momentObj = moment(day.date, 'YYYY-MM-DD');

    }
    momentString = momentObj.format('DD MMM'); // 2016-07-15


    //const momentObj = moment(day.date, 'MM-DD-YYYY');
    //const momentString = momentObj.format('DD MMM');
    //////console.log(momentString);
    this.currentSelectedDate = momentString;
    this.selectDate = day.value;
    this.selectedDate = momentString;
    this.appointmentCount = day.eventData;
    this.selectedAppointmentDate = momentObj.format('YYYY-MM-DD');
    // this.startDate = this.selectedAppointmentDate;
    // this.endDate = this.selectedAppointmentDate;
    // this.getAppointmentsStatus(this.selectedAppointmentDate);
    this.getAppointments(this.selectedAppointmentDate, this.page);
    // this.getNewAppointment(this.service.session.get('userCode'), momentString, momentString);
  }

  /* Name:srinivas
    use: returns appointments. */
  getAppointments(appointmentDate, page, scroll = null){
    let newSelectedMonth = new Date(appointmentDate).toLocaleString('en-us', { month:'short'} );
    let newSelectedDay = new Date(appointmentDate).toLocaleString('en-us', { day: '2-digit' });
    let selectedMonthInCalander = new Date(this.currentSelectedDate).toLocaleString('en-us', { month:'short'} );
    const newFormateDate = newSelectedDay + ' ' + newSelectedMonth;
    // //console.log('SelectedDate', this.selectedDate, newFormateDate);
    if(!scroll){
      this.service.common.progressLoader = true;
    }
    try {
      
    // //console.log('appointmentDate: ', selectedMonthInCalander, newSelectedMonth);
    // return;
   if(selectedMonthInCalander != newSelectedMonth){
    // this.selectedDate = newFormateDate;
      this.appointmentData =  [];
      this.service.common.progressLoader = false; 
   } else {
    this.service.gettodaysAppoinmentsByworkerCode(this.service.session.get('userCode'), 'assigned', appointmentDate, appointmentDate, this.page, this.limit,(data)=>{
      ////console.log(data);
      if(data.responsecode == 200){
        if(scroll){
          page = page + 1;
          let arrayData = data.data;
          data.data.forEach(element => {
            arrayData.push(element)
          });
          setTimeout(()=>{
            this.appointmentData = arrayData;
          },500)
        } else {
          this.selectedDate = newFormateDate;
          page = 0;
          this.appointmentData = data.data;
          this.service.common.progressLoader = false;
        }
      } else {
        if (!scroll) {
          this.selectedDate = newFormateDate;
          this.appointmentData =  [];
          this.service.common.progressLoader = false;          
        }
      }
    });
  }
   } catch (error) {
      
    }
  }

/* Name:srinivas
    use: returns status. */
  getStatus(status) {
      this.gridArr = [];
      this.appointmentCount = [];
      // this.selectDate = +this.datepipe.transform(new Date(), 'dd');
      this.selectDate = moment().format('DD');
      // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
      const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
      let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
      let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      // if (this.datepipe.transform(this.currentDate, 'MMM') == this.datepipe.transform(firstDay, 'MMM')) {
      if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
        // this.startDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
        this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
      } else {
        // this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
        this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
      }
      // this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
      this.endDate = moment(lastDay).format('YYYY-MM-DD');
      this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
  }

/* Name:srinivas
    use: returns data  after scrolling. */
  onScroll() {
    //////console.log('scroll');
    this.service.common.pageOffset = this.service.common.pageOffset + 1;
    // this.getData(this.page, this.limit, 'scroll');
    this.getAppointments(this.selectedAppointmentDate, this.service.common.pageOffset, 'scroll');
  }

/* Name:srinivas
    use: returns image show or hide. */
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }
  /* Name:srinivas
    use: returns status wise list of appointmnets. */
  appointmentStatus(status, job){
    // //console.log('appointmentStatus', status, job);
    // return;

    let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    // this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    this.startDate = moment().startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');    

    const data = {
      appointmentsCode: job?.appointmentsCode,
      appointmentsProceduresCode: job?.appointmentsProceduresCode,
      date: job?.jobDate,
      organizationCode: this.service.session.get('orgCode'),
      workerCode: this.service.session.get('userCode')
    }
    if(status == 'accept'){

      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'Are you sure you want to accept the job?' }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          // return;
          this.service.common.progressLoader = true;
          this.service.jobAcceptByStaff(data).subscribe((res:any) =>{
            this.service.common.progressLoader = false;
            if (res.responsecode == 200) {
              this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);              
              this.service.common.message = res.message;
              this.service.common.successMessage = true;              
              setTimeout(() => {
                this.getAppointments(job.jobDate, 0);
              }, 1500);
             }
            else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
              
            }
          })
        }
      });

    } else {
      const dialogRef = this.dialog.open(RejectAppointmentComponent, {
        width: '300px',
        data: {}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          const data = {
            appointmentsCode: job?.appointmentsCode,
            appointmentsProceduresCode: job?.appointmentsProceduresCode,
            comment: result,
            date: job?.jobDate,
            organizationCode: this.service.session.get('orgCode'),
            workerCode: this.service.session.get('userCode')
          }
          // //console.log('ReqData: ', data);
          // return;
          this.service.common.progressLoader = true;
          this.service.jobRejectByStaff(data).subscribe((res:any) =>{
            this.service.common.progressLoader = false;
            if (res.responsecode == 200) {
              this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              setTimeout(() => {
                this.getAppointments(job.jobDate, 0);
              }, 1500);
             }
            else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
              
            }
          })
        }
      });

    }
  }
/* Name:srinivas
    use: show icon popup and hide. */
  getIconPopup(appointmentsDurationCode){
    if (this.showPopup == appointmentsDurationCode) {
      this.showPopup = '';
    } else {
      this.showPopup = appointmentsDurationCode;
    }
  }
  /* Name:srinivas
    use: returns all procedure list. */
  getProcedureDetails(procedure,notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: {procedure: procedure, notes: notes}
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });

  }
  /* Name:srinivas
    use: returns all notes detail. */
  getNotes(note) {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      width: '450px',
      data: note
    });
  }

}