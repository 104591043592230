import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'assigned-distribution-list-popup',
  templateUrl: './assigned-distribution-list-popup.component.html',
  styleUrls: ['./assigned-distribution-list-popup.component.scss']
})
export class AssignedDistributionListPopup implements OnInit {

  isShowDistributionList:boolean

  constructor(
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<AssignedDistributionListPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(){
    // console.log('Model_Data_AssignedDistributionListPopup: ', this.data.workerCode);
  }

}