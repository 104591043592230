<div id="printData" >
    <div class="row">
        <div class="col-md-12">
            <!-- <label class="title mb-2"> {{'main.labels.ReviewTimeCard' |
                translate }}</label> -->
                <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">
                    Time Card
                    </div>
        </div>
    </div>
</div>
<!-- <mat-tab-group> -->
    <!-- <mat-tab label="DAILY" class="daily"> -->
        <div class="row mt-4">

            <div class="col-md-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>Start Date</mat-label>
                    <input (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder="Choose a date"
                        [(ngModel)]="startDate" (dateChange)="onStartDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>End Date</mat-label>
                    <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder="Choose a date"
                        [(ngModel)]="endDate" (dateChange)="onEndDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchText" placeholder="Search">
                </mat-form-field>
            </div>
            
        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="is_loading"></mat-progress-bar>
        <div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true" id="checkinout">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1 position-relative">

                <ng-container matColumnDef="Staff_Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Staff</th>
                        <td mat-cell *matCellDef="let element" class="text-nowrap">
                            <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
                        </td>
                </ng-container>

                <ng-container matColumnDef="Custome_Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Customer</th>
                        <td mat-cell *matCellDef="let element" class="text-nowrap">
                            <span>
                                <img
                                  class="td_avatar_img"
                                  src="assets/common_images/profile.jpg"
                                  [src]="element?.customerLogo ? (baseURL + element.customerLogo) : 'assets/common_images/profile.jpg'"
                              />
                            </span>
                            <span class="ps-2">{{element.customerName | capitalize}}</span>
                        </td>
                </ng-container>

                <ng-container matColumnDef="Job_Id">
                    <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                        Job ID
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.jobId ? element.jobId  : '--'}}</td>
                </ng-container>

                <ng-container matColumnDef="Job_Site">
                    <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                        {{ 'main.labels.Job Site' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.siteName ? element.siteName  : '--'}}</td>
                </ng-container>

                <ng-container matColumnDef="Clock_In_Time">
                    <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                        Clock-In Time</th>
                        <td mat-cell *matCellDef="let element">{{element.checkinDateTime ? element.checkinDateTime  : '--'}}</td>
                </ng-container>

                <ng-container matColumnDef="Clock_Out_Time">
                    <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                        Clock-Out Time
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.checkoutDateTime ? element.checkoutDateTime  : '--'}}</td>
                </ng-container>

                <ng-container matColumnDef="Total_Time">
                    <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                        Total Time
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.totalTime ? element.totalTime  : '--'}}</td>
                </ng-container>

                <ng-container matColumnDef="Paid_Time">
                    <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                        Paid Time
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.paidTime ? element.paidTime  : '--'}}</td>
                </ng-container>
                

                <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef class="commentWidth">
                        Comments
                    </th>
                    <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
                        <span title="{{element.comment}}">{{element.comment ? element.comment  : '--'}}</span>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.adminAction ? element.adminAction  : '--'}}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Action</th>
                 <td mat-cell *matCellDef="let element"> 
                    <!-- <ng-template [ngIf]="element.adminAction==='pending' || element.adminAction==='rejected'" [ngIfElse]="elseOption"> -->
                        <!-- <button class="mr-2" mat-stroked-button color="primary">Approve</button>
                        <button class="mr-2" mat-stroked-button color="warn">Reject</button>
                        <button mat-stroked-button color="accent">Change Time</button> -->

                       
                        <div class="w-100 d-flex justify-content-start">
                        
                            <ng-template [ngIf]="hasPermission.editPermission === true">
                        
                                <span class="pt-2" (click)="addNote(element)">
                                    <img *ngIf="element && element?.noteList && element?.noteList?.length > 0"
                                        class="p-1 notebtnIc curser-pointer" style="cursor: pointer;" title="Note"
                                        src="assets/images/note-icon-active.svg" />
                                    <img *ngIf="element && (element?.noteList?.length === 0 || !element.noteList)"
                                        class="p-1 notebtnIc curser-pointer" style="cursor: pointer;" title="Note"
                                        src="assets/images/note-icon.svg" />
                                </span>
                                <button *ngIf="element?.adminAction === 'Submitted' || element?.adminAction === 'Not Submitted'" mat-icon-button
                                    aria-label="Approve icon" color="basic" matTooltip="Approve" (click)="approveCustomer(element)">
                                    <mat-icon>playlist_add_check</mat-icon>
                                </button>
                        
                                <button *ngIf="element?.adminAction === 'Submitted' || element?.adminAction === 'Not Submitted'"
                                    (click)="changeTimeCard(element)" mat-icon-button color="basic" matTooltip="Update Time">
                                    <mat-icon class="color-icon cursor-pointer">edit</mat-icon>
                                </button>
                        
                                <button *ngIf="!element.adminAction" (click)="addTimeCard(element)" mat-icon-button color="basic"
                                    matTooltip="Add Time">
                                    <mat-icon class="color-icon cursor-pointer">add</mat-icon>
                                </button>
                        
                            </ng-template>
                        
                            <button (click)="fn_view_details(element)" mat-icon-button color="basic" matTooltip="View Details">
                                <mat-icon class="color-icon cursor-pointer">remove_red_eye</mat-icon>
                            </button>
                        
                        </div>
                        

                        
                       

                <!-- </ng-template> -->

                <!-- <ng-template #elseOption>
                <span>&nbsp;</span>
                </ng-template> -->
                 </td>
                </ng-container>
    
                 <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
                    true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div *ngIf="ReviewTimeCard_List?.length === 0" class="no-data p-3 text-danger ">
                No Time Card Available
            </div>

        </div>


        <ng-template #view_details>
            <div class="view-details">
              <div class="row">
                <div class="col-md-6 align-self-center">
                  <h6 class="dialog-header pt-2">View Details</h6>
                </div>
                <div class="col-md-6">
                  <div class="row justify-content-end">
                    <button class="btn-close cursor-pointer" mat-dialog-close></button>
                  </div>
                </div>
              </div>
            
               <div class="row">

                <div class="col-6">
                    <p>Clock In Date</p>
                    <small>{{view_details_obj?.checkinDateTime}}</small>
                </div>

                <div class="col-6">
                    <p>Clock Out Date</p>
                    <small>{{view_details_obj?.checkoutDateTime}}</small>
                </div>

                <div class="col-12 mt-3">
                    <p>Comment</p>
                    <div class="sec-comment">
                    <small>{{view_details_obj?.comment}}</small>
                </div>
                </div>

                </div>
            
                
        
            </div>
          </ng-template>
     
 
 
