import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TeamManagementService } from '../team-management/team-management.service';
import { TranslateService } from '@ngx-translate/core';
import { DistributionService } from '../distribution-list/distribution-service';
import { Key } from 'protractor';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PopoverComponent } from 'src/app/components/popover/popover.component';

@Component({
  selector: 'common-data-list-view',
  templateUrl: './common-data-list-view.component.html',
  styleUrls: ['./common-data-list-view.component.scss']
})
export class CommonDataListViewComponent implements OnInit {
  private overlayRef: OverlayRef;
  baseURL: any;
  searchText: any;
  displayedColumns = [];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  isListView: boolean = false;

  constructor(public dialogRef: MatDialogRef<CommonDataListViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: TeamManagementService,private translate: TranslateService, public distributionService: DistributionService, private overlay: Overlay) {}

    ngOnInit() {
      this.baseURL = this.service._apiSVC.getSuperAdmin() + "/downloadFile/";
      console.log('Modal_Data: ', this.data);
    }

    changeView(data: any, isListView: any){    
      let cloneList = data && JSON.parse(JSON.stringify(data));
      // console.log('Data: ', data);
      if(isListView){
        this.isListView = true;
        if(this.data.popupTitle === 'Sites'){
          this.displayedColumns = ['avatarIc', 'customerName', 'siteIc', 'jobSite', 'fullAddress'];
          this.dataSource = new MatTableDataSource(cloneList);
          setTimeout(() => { 
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 2000);           
          console.log('onChangeView: ', this.data.popupTitle, this.dataSource);
        } else {
          this.displayedColumns = ['staffName', 'email' , 'phone'];
          this.dataSource = new MatTableDataSource(cloneList);
          setTimeout(() => { 
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;0
          }, 2000);
          console.log('onChangeView: ', this.data.popupTitle, this.dataSource);
        }
      } else {
        this.isListView = false;
      }
    }

    applyFilter(value = null) {
      // console.log('applyFilter_1: ', value);
      const filterValue = value.toLowerCase();
      const dataSourceValue =  this.data && this.data.userList && JSON.parse(JSON.stringify(this.data.userList));
      // console.log('applyFilter_2: ', dataSourceValue);
      this.dataSource = dataSourceValue && dataSourceValue.filter(item => {
        let matchCase =  (item?.staffName?.toLowerCase().includes(filterValue) || item?.customerName?.toLowerCase().includes(filterValue) || item?.email?.toLowerCase().includes(filterValue) || item?.mobilePhone?.includes(filterValue) || item?.site?.toLowerCase().includes(filterValue) || item?.customerApartmentNo?.toLowerCase().includes(filterValue) || item?.siteName?.toLowerCase().includes(filterValue) || item?.customerAddress?.toLowerCase().includes(filterValue) || item?.customerCity?.toLowerCase().includes(filterValue) || item?.customerState?.toLowerCase().includes(filterValue) || item?.customerCountry?.toLowerCase().includes(filterValue) || item.zipCode?.includes(filterValue));
        // console.log('Item_1: ', matchCase);
        return matchCase;
      });
    }

   /* Name:Vivek Chauhan
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.dialogRef.close('true');
  }

}
