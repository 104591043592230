<div class="container addmember-container">
    <div class="col-md-6 align-self-center">
        <span class="d-flex">
            <h6 class="dialog-header pt-2">{{ 'Technicians.labels.Add Member' | translate }}</h6>
            <span class="headerNoteTitleBtn pt-1">
                <span class="ps-4 cursor-pointer" (click)="technicianService.common.addUpdateNotes()">
                  <span class="notebtnContainer">
                    <img class="p-1 notebtnIc" src="assets/images/note-icon.svg" />
                    <span class="ps-1 noteBtnTxtInactive">Note</span>
                  </span>
                </span>
            </span>
        </span>
        <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
    </div>
    
    <form [formGroup]="addMember_Form" autocomplete="off">
       
    <div class="row g-3">
    <div class="form-group col-lg-6">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.First Name' | translate }}</mat-label>
                <input matInput formControlName="firstName" minlength="1" maxlength="50">
            </mat-form-field>

            <span class="help-block err"
            *ngIf="addMember_Form.controls.firstName.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.firstName.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>

            <span class="help-block err"
            *ngIf="addMember_Form.controls.firstName.errors?.pattern && 
            (is_formSubmitted || addMember_Form.controls.firstName.touched)">
            Should be only alphabets.</span>

            <span class="help-block err"
            *ngIf="addMember_Form.controls.firstName.errors?.minlength && 
            (is_formSubmitted || addMember_Form.controls.firstName.touched)">
            Char should be 1 or more. </span>

        </div>

        <div class="form-group col-lg-6">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.Last Name' | translate }}</mat-label>
                <input matInput formControlName="lastName" minlength="1" maxlength="50">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.lastName.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.lastName.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>

            <span class="help-block err"
            *ngIf="addMember_Form.controls.lastName.errors?.pattern && 
            (is_formSubmitted || addMember_Form.controls.lastName.touched)">
            Should be only alphabets.</span>

            <span class="help-block err"
            *ngIf="addMember_Form.controls.lastName.errors?.minlength && 
            (is_formSubmitted || addMember_Form.controls.lastName.touched)">
            Char should be 1 or more.</span>
            
        </div>

        <div class="form-group col-lg-6">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.Email' | translate }}</mat-label>
                <input matInput formControlName="email" maxlength="40">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.email.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.email.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>

            <!-- <span class="help-block err"
            *ngIf="addMember_Form.controls.email.errors?.pattern
            (is_formSubmitted || addMember_Form.controls.email.touched)">
            {{ 'common.Validators.Valid Email' | translate }}  </span> -->

            <span class="help-block err" 
            *ngIf="addMember_Form.controls.email.errors?.email && 
            (is_formSubmitted || addMember_Form.controls.email.touched)">
            Should be valid email </span>

            <span class="help-block err" 
            *ngIf="addMember_Form.controls.email.errors?.invalid && 
            (is_formSubmitted || addMember_Form.controls.email.touched)">
            Email is already present </span>

          

        </div>

        <div class="form-group col-lg-6">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.Phone' | translate }}</mat-label>
                <input matInput formControlName="mobilePhone" appPhoneMask   maxlength="12">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.mobilePhone.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.mobilePhone.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.mobilePhone.errors?.minlength && 
            (is_formSubmitted || addMember_Form.controls.mobilePhone.touched)">
            phone no should be of 10 digits  </span>
 
            <!-- <span class="help-block err"
            *ngIf="addMember_Form.controls.mobilePhone.errors?.pattern 
            (is_formSubmitted || addMember_Form.controls.mobilePhone.touched)">
            {{ 'common.Validators.Valid Phone' | translate }}  </span> -->

        </div>

        <div class="form-group col-lg-6">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.Work Phone' | translate }}</mat-label>
                <input matInput formControlName="workPhone" appPhoneMask  maxlength="12"> 
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.workPhone.errors?.minlength && 
            (is_formSubmitted || addMember_Form.controls.workPhone.touched)">
            phone no should be of 10 digits  </span>
           
         </div>

        <div class="form-group col-lg-4">
        </div>

        <div class="form-group col-lg-12">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
                <input matInput formControlName="location" ngx-google-places-autocomplete placeholder="Enter {{ 'main.labels.Job Site' | translate }}"
                [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.address.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.address.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>
        </div>

        <div class="form-group col-lg-3">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.City' | translate }}</mat-label>
                <input matInput formControlName="city" maxlength="20">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.city.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.city.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>
        </div>

        <div class="form-group col-lg-3">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.State' | translate }}</mat-label>
                <input matInput formControlName="stateName" maxlength="50">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.stateName.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.stateName.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>
        </div>

        <div class="form-group col-lg-3">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.Postcode' | translate }}</mat-label>
                <input matInput formControlName="postalCode" minlength="5" maxlength="10">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.postalCode.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.postalCode.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>
        </div>

        <div class="form-group col-lg-3">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'Technicians.labels.Country' | translate }}</mat-label>
                <input matInput formControlName="country" maxlength="20">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addMember_Form.controls.country.errors?.required && 
            (is_formSubmitted || addMember_Form.controls.country.touched)">
            {{ 'common.Validators.Required Field' | translate }}  </span>
        </div>

        <!-- <div class="form-group col-md-12">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="notes"></textarea>
               </mat-form-field>
        </div> -->
        
    </div>

    <div class="row mt-2">
        <div class="text-end">
            <button  mat-button (click)="closePopUp()" color="primary">  Close  </button>
    
              <button class="ms-4" mat-raised-button color="primary" (click)="addStaffMember()">
               Save
               </button>
        </div>
        </div>


</form>
 
</div>