<div class="col-md-12 create-appointment">
  <div class="w-100">
    <div class="row mb-4">
      <div class="col-md-6 align-self-center">
        <h4 class="mb-0">{{ 'Jobs.Create Jobs.labels.Create Job' | translate }}</h4>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end pe-2">
          <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
        </div>
      </div>
    </div>
    <div class="w-100 popup-viewport-body">
    <form [formGroup]="scheduleForm" >
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12 pe-0 m-0">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width " floatLabel="always">
                <mat-label>{{ 'Jobs.Create Jobs.labels.Job location' | translate }}</mat-label>
                <input type="text" matInput autoClose [matAutocomplete]="auto"
                   formControlName="patientName">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)="selectedPatient($event)">
                  <mat-option *ngFor="let option of optionsPatient" [value]="option">
                    {{option.customerName}}
                  </mat-option>
                </mat-autocomplete>

              </mat-form-field>
              <span class="help-block err"
                *ngIf="!scheduleForm.get('patientName').valid && (!submitted || scheduleForm.get('patientName').touched)">
                {{ 'Jobs.Create Jobs.Validators.Job location is required' | translate }} </span>

                <span class="help-block err"
                *ngIf="scheduleForm.controls.skillcode.errors?.required && !scheduleForm.controls.patientName .errors?.required && (!submitted)">{{ 'skills.validators.Please select from the list' | translate }}</span>

            </div>
            <!-- <div class="add-paitent text-start align-self-center">
              <mat-icon class="cursor-pointer" [routerLink]="['/dashboard','patient','patient-add']"
              [queryParams]="{ 'appointmentRedirect': 1 }">add_circle</mat-icon>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row mt-3 ms-0 me-0">
        <div class="col-md-12">
            <mat-label class="custom-label">{{ 'Jobs.Create Jobs.labels.Job Type' | translate }}</mat-label>
            <angular2-multiselect [data]="dropdownList" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedItems" [settings]="dropdownSettings" formControlName="procedureCode">
              <c-badge>
                <ng-template let-item="item">
                  <img [src]="item.image" class="img-filter" />
                  <label style="margin: 0px;">{{item.itemName}}</label>
                </ng-template>
              </c-badge>
            </angular2-multiselect>
          <span class="help-block err"
            *ngIf="!scheduleForm.get('procedureCode').valid && (!submitted || scheduleForm.get('procedureCode').touched)">{{ 'Jobs.Create Jobs.Validators.Job type is required' | translate }}</span>
        </div>
        <!-- <div class="col-md-12">
            <mat-checkbox class="is-flexible" formControlName="isDateFlexible" [(ngModel)]="service.scheduleForm.isDateFlexible"> Appointment Flexible</mat-checkbox>
        </div> -->
      </div>
      <div class="row mt-1 ms-0 me-0">
        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Address' | translate }}</mat-label>
            <textarea matInput placeholder="Enter your Address"
              ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
              (onAddressChange)="handleAddressChange($event)" formControlName="address"></textarea>
          </mat-form-field>
          <span class="help-block err"
            *ngIf="!scheduleForm.get('address').valid && (!submitted || scheduleForm.get('address').touched)">{{ 'Jobs.Create Jobs.Validators.Address is required' | translate }}</span>
        </div>
      </div>

      <div class="row ms-0 me-0" *ngIf="labelNumbers">
        <div class="col-md-4 col-sm-12">
          <div class="w-100 row">
            <div class=" col-md-5">
              <app-country-code (setCountryCode)="getCountryCode($event,'phoneCountryCode')" [setCode]="countrycodep"  [placeholder]="'Home No'">
              </app-country-code>
              <span class="help-block err"
                *ngIf="!scheduleForm.get('phoneCountryCode').valid && (!submitted || scheduleForm.get('phoneCountryCode').touched)">{{ 'Jobs.Create Jobs.Validators.Phone country code is required' | translate }}</span>
            </div>

            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width p-0 no-form">
                <mat-label>Phone Number</mat-label>
                <input matInput placeholder="" formControlName="phoneNumber"
                  [(ngModel)]="service.scheduleForm.homeNumber">
              </mat-form-field>
              <span class="help-block err"
                *ngIf="scheduleForm.get('phoneNumber').errors?.required  && (!submitted || scheduleForm.get('phoneNumber').touched)">
                {{ 'Jobs.Create Jobs.Validators.Home number is required' | translate }}</span>
              <span class="help-block err"
                *ngIf="scheduleForm.get('phoneNumber').errors?.pattern  && (!submitted || scheduleForm.get('phoneNumber').touched)">
                {{ 'Jobs.Create Jobs.Validators.Home number is required' | translate }}</span>
            </div>

          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="w-100 row">
            <div class="col-md-5">
              <app-country-code (setCountryCode)="getCountryCode($event,'mobileCountryCode')" [setCode]="countrycodem" [placeholder]="'Mobile No'">
              </app-country-code>
              <span class="help-block err"
                *ngIf="!scheduleForm.get('mobileCountryCode').valid && (!submitted || scheduleForm.get('mobileCountryCode').touched)">
                {{ 'Jobs.Create Jobs.Validators.Mobile country code is required' | translate }}</span>
            </div>

            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width no-form p-0">
                <mat-label>Mobile Number</mat-label>
                <input matInput placeholder="" formControlName="mobileNumber"
                  [(ngModel)]="service.scheduleForm.mobileNumber">
              </mat-form-field>
              <span class="help-block err"
                *ngIf="scheduleForm.get('mobileNumber').errors?.required  && (!submitted || scheduleForm.get('mobileNumber').touched)">
                {{ 'Jobs.Create Jobs.Validators.Mobile number is required' | translate }}</span>
              <span class="help-block err"
                *ngIf="scheduleForm.get('mobileNumber').errors?.pattern  && (!submitted || scheduleForm.get('mobileNumber').touched)">
                {{ 'Jobs.Create Jobs.Validators.Mobile number is required' | translate }}</span>
            </div>

          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="w-100 row">
            <div class="col-md-5">
              <app-country-code (setCountryCode)="getCountryCode($event,'preferredCountryCode')"
                [setCode]="preferredCountryCode" [placeholder]="'Additional Contact No'"></app-country-code>
              <span class="help-block" *ngIf="scheduleForm.get('preferredCountryCode').errors?.required
                && (!submitted || scheduleForm.get('preferredCountryCode').touched)">
                {{ 'Customers.validators.Country code is required' | translate }}</span>
            </div>
    
    
            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width p-0 no-form">
                <mat-label>Additional Number</mat-label>
                <input autocomplete="off"  matInput placeholder="" formControlName="preferredNumber"
                  [(ngModel)]="service.scheduleForm.additionalNumber">
              </mat-form-field>
    
              <span class="help-block err" *ngIf="scheduleForm.get('preferredNumber').errors?.required &&
                (!submitted || scheduleForm.get('preferredNumber').touched)">{{
                'Customers.validators.Preferred Number is required' | translate }}</span>
              <span class="help-block err" *ngIf="scheduleForm.get('preferredNumber').errors?.pattern &&
                (!submitted || scheduleForm.get('preferredNumber').touched)">{{
                'Customers.validators.Please enter valid Preferred number' | translate
                }}</span>
              <span class="help-block" *ngIf="(scheduleForm.get('preferredNumber').errors?.minlength ||
                scheduleForm.get('preferredNumber').errors?.maxlength) && (!submitted
                || scheduleForm.get('preferredNumber').touched)">{{
                'Customers.validators.Preferred number should be 10 digit' | translate
                }}</span>
            </div>
          </div>
        </div>

        <mat-radio-group class="pref-no" aria-label="Select an option" class="ispreferred-radio" formControlName="isPeferredNumber" [(ngModel)]="service.scheduleForm.preferredPhoneType">
          <mat-radio-button value="homeNumber" class="pref-no">Preferred No </mat-radio-button>
          <mat-radio-button value="mobileNumber" class="pref-no">Preferred No</mat-radio-button>
          <mat-radio-button value="additionalNumber" class="pref-no">Preferred No</mat-radio-button>
        </mat-radio-group>

        <span class="help-block ms-3 radio-err" *ngIf="scheduleForm.get('isPeferredNumber').errors?.required &&
        (!submitted || scheduleForm.get('isPeferredNumber').touched)">Please select one of preferred number</span>

        

      </div>

      <div class="row phone-labels ms-0 me-0" *ngIf="!labelNumbers">
        <div class="col-md-4 col-sm-12">
          <label>Home No.</label>
          <p *ngIf="selected_customer.homePhone">+{{selected_customer.mCountryCode}} {{selected_customer.homePhone}}</p>
          <p *ngIf="!selected_customer.homePhone">NA</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <label>Mobile No.</label>
          <p *ngIf="selected_customer.mobilePhone">+{{selected_customer.mCountryCode}} {{selected_customer.mobilePhone}}</p>
          <p *ngIf="!selected_customer.mobilePhone">NA</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <label>Additional Contact No.</label>
          <p *ngIf="selected_customer.workPhone">+{{selected_customer.mCountryCode}} {{selected_customer.workPhone}}</p>
          <p *ngIf="!selected_customer.workPhone">NA</p>
        </div>
        <mat-radio-group class="pref-no" aria-label="Select an option" class="ispreferred-radio" formControlName="isPeferredNumber" [(ngModel)]="service.scheduleForm.preferredPhoneType">
          <mat-radio-button value="homeNumber" class="pref-no">Preferred No </mat-radio-button>
          <mat-radio-button value="mobileNumber" class="pref-no">Preferred No</mat-radio-button>
          <mat-radio-button value="additionalNumber" class="pref-no">Preferred No</mat-radio-button>
        </mat-radio-group>

        <span class="help-block ms-3 radio-err" *ngIf="scheduleForm.get('isPeferredNumber').errors?.required &&
        (!submitted || scheduleForm.get('isPeferredNumber').touched)">Please select one of preferred number</span>
      </div>

      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Start Date' | translate }}</mat-label>
            <input [min]="today" matInput #nonworkingday formControlName="startDate" [matDatepicker]="picker2"
              (focus)="picker2.open()"
              placeholder="">
            <mat-datepicker-toggle matSuffix [for]="picker2" class="date-icon"></mat-datepicker-toggle>
            <mat-datepicker #picker2 (closed)="nonworkingday.blur()"></mat-datepicker>
          </mat-form-field>
          <span class="help-block err"
            *ngIf="!scheduleForm.get('startDate').valid && (!submitted || scheduleForm.get('startDate').touched)">
            {{ 'Jobs.Create Jobs.Validators.Start date is required' | translate }}</span>
        </div>
        <div class="col-md-6 repeat">
          <h5>{{ 'Jobs.Create Jobs.labels.Repeat' | translate }}</h5>
          <div class="row col-md-12 p-0 m-0">
            <p class="eachday text-uppercase col-md-8 p-0" *ngIf="service.scheduleForm.repeatType == ''">
              {{ 'Jobs.Create Jobs.labels.Never' | translate }}</p>
            <p class="eachday text-uppercase col-md-8 p-0" *ngIf="service.scheduleForm.repeatType != ''">
              {{ 'Jobs.Create Jobs.labels.Every' | translate }}
              {{service.scheduleForm.repeatType}}</p>
            <p class="eachday text-uppercase col-md-8 p-0" *ngIf="service.scheduleForm.endDate != ''">
              {{ 'Jobs.Create Jobs.labels.Till' | translate }}
              {{service.scheduleForm.endDate}}</p>
            <div class="col-md-4 p-0 text-end">
              <mat-icon class="cursor-pointer" (click)="repeat()">
                {{ 'Jobs.Create Jobs.labels.cached' | translate }}</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Start Time' | translate }}</mat-label>
            <mat-select formControlName="startTime" [(ngModel)]="service.scheduleForm.startTime" placeholder="Select Time">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchStart($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="">
              <mat-option *ngFor="let start of selectedStartTime" [value]="start.value">
                {{start.text}}
              </mat-option>
            </mat-select>
            <mat-icon class="start-time">access_time</mat-icon>
          </mat-form-field>
          <span class="help-block err"
            *ngIf="!scheduleForm.get('startTime').valid && (!submitted || scheduleForm.get('startTime').touched)">
            {{ 'Jobs.Create Jobs.Validators.Start time is required' | translate }}</span>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Duration' | translate }}</mat-label>
            <mat-select formControlName="duration" (selectionChange)="getDurationData($event)" placeholder="">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchEnd($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="">
              <mat-option *ngFor="let end of durationSelectDropdown" [value]="end.value">
                {{end.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block err"
            *ngIf="!scheduleForm.get('duration').valid && (!submitted || scheduleForm.get('duration').touched)">
            {{ 'Jobs.Create Jobs.Validators.Duration is required' | translate }}</span>
        </div>
      </div>
    
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Notes' | translate }}</mat-label>
            <textarea matInput placeholder=""
              formControlName="notes" [(ngModel)]="service.scheduleForm.notes"></textarea>
          </mat-form-field>
          <span class="help-block err"
            *ngIf="!scheduleForm.get('notes').valid && (!submitted || scheduleForm.get('notes').touched)">
            {{ 'Jobs.Create Jobs.Validators.If you change the duration you have to mention the reason behind it' | translate }}.</span>
        </div>
      </div>
      
      <ng-template  #address_popup>
        <h4 matDialogTitle>Choose Address</h4>
        
          <mat-radio-group formControlName="selected_address">
            <mat-radio-button *ngFor="let address of address_list" [value]="address">
                {{address.location}}
            </mat-radio-button>
        </mat-radio-group>
        
        <mat-dialog-actions align="end">
          <button mat-button (click)="selectAddress()" cdkFocusInitial>Select</button>
        </mat-dialog-actions>

      </ng-template>

    </form>
    </div>

    <div class="d-flex offset-md-8 justify-content-end pt-3">
      <button mat-button (click)="canelPopup()" class="cancel-btn-primary">{{ 'Technicians.labels.Cancel' | translate
        }}</button>
      <button class="ms-2 pe-4 ps-4" mat-raised-button color="primary" (click)="onSubmit()">{{ 'Jobs.Create
        Jobs.labels.Save' | translate }}</button>
    </div>

  </div>
</div>
 