<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">Department
  </div>
  <div class="col-lg-5 col-md-5 col-sm-12 searchfilter">
    <!-- <input autocomplete="off" matInput
      (keyup)="applyFilter($event.target.value)" type="text"
      class="form-control"
      placeholder="{{'licence.labels.Search Licence' | translate }}">
    <mat-icon class="cursor-pointer">search</mat-icon>     -->
  </div>
  <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    <button mat-raised-button color="primary" class="ms-2" routerLink="/dashboard/departmen/department-edit" title="Add Department">Add</button>
  </div>
</div>
<div class="row">
  <div class="col-md-12 table-scroll-height">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="departmentLogo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department Image</th>
        <td mat-cell *matCellDef="let element">
          <div class="skillImage" *ngIf="element.departmentLogo">
            <img [src]="element.imageUrl" alt="">
          </div>
          <div *ngIf="!element.departmentLogo">
            <div class="skillImage default">
              <img src="/assets/common_images/profile.jpg" title="No image"
                alt="">
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="departmentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department Name<i
            class="material-icons padding-icon">unfold_more</i></th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.departmentName}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent Department
          Name<i class="material-icons padding-icon">unfold_more</i></th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.parentName">
            <div>{{element.parentName}} </div>
          </div>
          <div *ngIf="!element.parentName">
            <div>NA </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status<i
            class="material-icons padding-icon">unfold_more</i></th>
        <td mat-cell *matCellDef="let element">
          <div>
              <div *ngIf="element.isActive === true">Active </div>
              <div *ngIf="element.isActive === false">Inactive </div>
          </div>    
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">Actions<i class="material-icons
            padding-icon">unfold_more</i></th>
        <td mat-cell *matCellDef="let element" class="action">
          <mat-icon class="color-icon" class="cursor-pointer"
            [routerLink]="['/dashboard','departmen','department-edit']"
            [queryParams]="{ 'id': element.id,
            'departmentName':element.departmentName,
            'parentid':element.parentid, 'parentName':element.parentName,
            'departmentLogo':element.departmentLogo, 'isActive':element.isActive
            }">edit</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="allDeptData.length == 0 || dataSource.filteredData.length == 0"
      class="">Departments are not available</div>
  </div>
</div>


<div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"></div>