<div class="col-md-12">
   <div class="row">
      <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">Notes</h6>
      </div>
      <div class="col-md-6">
         <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" matDialogClose></button>
         </div>
      </div>
   </div>
   <div class="row scrollContent removeSecPadd">

      <!-- <ng-template [ngIf]="is_not_PreviousDate" > -->
      <div class="col-12" [class.mb-3]="data.isNotesAdded">
         <div class="row inputContSpace">
            <div class="col-12">      
               <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
                <textarea class="noteInput" id="note_input_2" matInput rows="3" [(ngModel)]="txt_notes"></textarea>
               </mat-form-field>
           </div>
  
            <div class="col-6 text-start">
             <div class="checkboxLft">
               <mat-checkbox class="mat-option" [(ngModel)]="chx_isPublic" color="primary">Is Private</mat-checkbox>
             </div>
             </div>
  
             <div class="col-6 text-end">
             <!-- <button mat-raised-button color="primary"
               (click)="saveNote()">Add</button> -->
               <button *ngIf="isNoteEdit" mat-raised-button color="primary" (click)="updateNote(seletedNoteForEdit)">Update</button>
               <button *ngIf="!isNoteEdit" mat-raised-button color="primary" (click)="saveNote()">Add</button>
           </div>
         </div>
      </div>
   <!-- </ng-template> -->

      <div *ngIf="groupNoteData" class="col-12 p-0" [class.mt_24]="!data.isNotesAdded">
         <!-- <div *ngIf="groupNoteData" class="col-12"> -->
         <!-- "entityName": "Job" content Start -->
         <ng-container *ngIf="groupNoteData?.job && groupNoteData?.job?.noteArray?.length > 0"> 
            <div class="w-100 noteSecPadd">
            <h4 class="groupTitle w-100">Job/Shift Notes</h4>
            <div class="w-100 leftIndentSpace">
            <ng-container [ngTemplateOutlet]="jobDetailCard" [ngTemplateOutletContext]="{ noteDetail: groupNoteData.job }">
         </ng-container>
         </div>
         <ng-container [ngTemplateOutlet]="groupNoteContent" [ngTemplateOutletContext]="{ noteArray: groupNoteData.job.noteArray }"></ng-container> 
         </div>
         </ng-container>
         <!-- "entityName": "Job" content End -->
         <!-- "entityName": "Customer" content Start -->
         <ng-container *ngIf="groupNoteData?.customer && groupNoteData?.customer?.noteArray?.length > 0">
            <div class="w-100 noteSecPadd">
            <h4 class="groupTitle w-100">Customer Notes</h4> 
            <div class="w-100 detailSecSpace leftIndentSpace">               
               <div class="w-100 rowBoxStyle roundedBorder">
                  <div class="col p-0 m_width_50">
                     <div class="w-100 paddTop">
                        <img class="img_jobtype" [src]="groupNoteData.customer.customerLogo ? baseURL+groupNoteData.customer.customerLogo.customerLogo : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                     </div>
                  </div>
                  <div class="col paddTop ps_14">
                     <small>Customer Name </small><br />
                     <p>{{groupNoteData.customer.customerName | capitalize}}</p>
                  </div>
               </div>
            </div>
            <ng-container [ngTemplateOutlet]="groupNoteContent" [ngTemplateOutletContext]="{ noteArray: groupNoteData.customer.noteArray }">
         </ng-container>
         </div>
         </ng-container>
         <!-- "entityName": "Customer" content End -->
         <!-- "entityName": "Job Site" content Start -->
         <ng-container *ngIf="groupNoteData?.location && groupNoteData?.location?.noteArray?.length > 0">
            <div class="w-100 noteSecPadd">
            <h4 class="groupTitle w-100">Job Site Notes</h4>
            <div class="w-100 detailSecSpace leftIndentSpace">               
               <div class="w-100 rowBoxStyle roundedBorder">
                  <div class="col p-0 m_width_50">
                     <div class="w-100 paddTop">
                        <img class="img_jobtype" [src]="groupNoteData.location.customerLogo ? baseURL+groupNoteData.location.customerLogo : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                     </div>
                  </div>
                  <div class="col paddTop ps_14">
                     <small>Customer Name </small><br />
                     <p>{{groupNoteData.location?.customerName | capitalize}}</p>
                  </div>
                  <div *ngIf="groupNoteData.location?.siteName" class="col paddTop ps_14">
                     <small>Job Site </small><br />
                     <p [title]="groupNoteData.location.siteName ? groupNoteData.location.siteName : groupNoteData.location.siteName">{{groupNoteData.location?.siteName}}</p>
                  </div>
               </div>
            </div>
            <ng-container [ngTemplateOutlet]="groupNoteContent" [ngTemplateOutletContext]="{ noteArray: groupNoteData.location.noteArray }">
         </ng-container>
         </div>
         </ng-container>
         <!-- "entityName": "Job Site" content End -->
         <!-- "entityName": "Job Type" content Start -->
         <ng-container *ngIf="groupNoteData?.jobType && groupNoteData?.jobType?.noteArray?.length > 0">
            <div class="w-100 noteSecPadd">
            <h4 class="groupTitle w-100">Job Type Notes</h4>
            <div class="w-100 detailSecSpace leftIndentSpace">                
               <div class="w-100 rowBoxStyle roundedBorder">
                        <div class="col p-0 m_width_50">
                           <div class="w-100 paddTop">
                              <img class="img_jobtype" [src]="groupNoteData.jobType.jobIcon ? baseURL+groupNoteData.jobType.jobIcon : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                           </div>
                        </div>
                        <div class="col paddTop ps_14">
                           <small>Job Type </small><br />
                           <p>{{groupNoteData.jobType?.jobType | capitalize}}</p>
                        </div>
               </div>
            </div>
            <ng-container [ngTemplateOutlet]="groupNoteContent" [ngTemplateOutletContext]="{ noteArray: groupNoteData.jobType.noteArray }">
         </ng-container>
         </div>
         </ng-container>
         <!-- "entityName": "Job Type" content End -->
         <!-- "entityName": "Staff" content Start -->
         <ng-container *ngIf="groupNoteData?.staff && groupNoteData?.staff?.length > 0">
            <div class="w-100 noteSecPadd">
            <h4 class="groupTitle w-100">Staff Notes</h4> 
            <ng-container *ngFor="let staff of groupNoteData.staff; let idx = index; let last = last">
               <div class="w-100 detailSecSpace leftIndentSpace">                  
                  <div class="w-100 rowBoxStyle roundedBorder">

                     <div class="col p-0 m_width_50">
                        <div class="w-100 paddTop">
                           <img class="img_jobtype" [src]="staff.staffLogo ? baseURL+staff.staffLogo : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                        </div>
                     </div>
                     <div class="col paddTop ps_14">
                        <small>Staff Name </small><br />
                        <p class="cursor-pointer d-inline-block" (click)="notesService.common.openStaffDetailPopup(staff.staffCode)">{{staff?.staffName | capitalize}}</p>
                     </div>
                     
                  <!-- <div class="col paddTop">
                     <profile-avatar-with-name [userDetail]="staff" [lableTxt]="'Staff Name'" [isRowNameWithLogo]="true"></profile-avatar-with-name>
                  </div> -->
                  </div>
               </div>
               <ng-container [ngTemplateOutlet]="groupNoteContent" [ngTemplateOutletContext]="{ noteArray: staff.noteArray, arrIndex: idx}">
            </ng-container>
         </ng-container>
         </div>
         </ng-container>
         <!-- "entityName": "Staff" content End -->
      </div>

      <ng-template [ngIf]="!groupNoteData && data?.isNotesAdded && !is_Loading" class="col-md-12">
         <div class="w-100 noteSecPadd text-danger">
            Record Not Found
         </div>
      </ng-template>

      <ng-template [ngIf]="data?.isNotesAdded && is_Loading">
         <div class="div w-100 mt-4 d-flex p-3 text-center" style="justify-content: center;">
               <mat-spinner class="center_50_px_spinner"></mat-spinner>
             <p class="mt-3 mb-3  ms-5 text-center">Getting notes ...</p>
         </div>
       </ng-template>

   </div>
</div>
<ng-template #jobDetailCard let-noteDetail="noteDetail">
   <div *ngIf="noteDetail" class="w-100 card-box mt-3 mb-2 roundedBorder">
      <div class="Card_JobDetails">
         <div class="row job-content container mb-0">
            <div class="st-jobTypeDts-main-wrapper">
               <div class="row">
                  <div class="col-1" *ngIf="noteDetail?.week_day && noteDetail?.month_day && noteDetail?.month_name">
                     <div class="row">
                        <div class="card-jobdate fixBorderColor fixTxtColor">
                           <span>{{noteDetail?.week_day}}</span>
                           <span class="month-day">{{noteDetail?.month_day}}</span>
                           <span>{{noteDetail?.month_name}}</span>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="noteDetail?.customerName" class="col p-0 m_width_50">
                     <div class="w-100 paddTop">
                        <img class="img_jobtype" [src]="noteDetail.customerLogo ? baseURL+noteDetail.customerLogo : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                     </div>
                  </div>
                  <div *ngIf="noteDetail?.customerName" class="col paddTop">
                     <small>Customer Name </small><br />
                     <p>{{noteDetail?.customerName | capitalize}}</p>
                  </div>
                  <div *ngIf="noteDetail?.siteName" class="col-2 paddTop">
                     <small>Job Site </small><br />
                     <p [title]="noteDetail.siteName ? noteDetail.siteName : noteDetail.siteName">{{noteDetail?.siteName}}</p>
                  </div>
                  <div *ngIf="noteDetail?.jobType" class="col">
                     <div class="row">
                        <div class="col p-0 m_width_50">
                           <div class="w-100 paddTop">
                              <img class="img_jobtype" [src]="noteDetail.jobIcon ? baseURL+noteDetail.jobIcon : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                           </div>
                        </div>
                        <div class="col paddTop">
                           <small>Job Type </small><br />
                           <p>{{noteDetail?.jobType | capitalize}}</p>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="noteDetail?.startTime" class="col paddTop">
                     <small>Shift </small><br />
                     <p>{{noteDetail?.startTime}} - {{noteDetail?.endTime}}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</ng-template>
<ng-template #groupNoteContent let-noteArray="noteArray" let-arrIndex="arrIndex">
   <div class="w-100 ps-4">
      <threaded-note-list [noteArray]="noteArray" (noteChangeEvent)="noteChangeEvent($event, arrIndex)"></threaded-note-list>
   </div>
</ng-template>