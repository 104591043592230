import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class ElegibleAppointmentService {

  constructor(public service: ApiRootService,
    public common: CommonService,
    public http: HttpClient,
    public session: SessionStorageService) { }


 /* Name:srinivas
    use: returns all eligible appointment list for technician and date. */
  mobileGetEligibleAppointmentForTechnicianByDate(workerCode, appointmentDate, todate, procode, location, miles, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileGetEligibleAppointmentForTechnicianByDateFilter/' + workerCode + '/' + appointmentDate + '/' + todate + '/' + procode + '/' + location + '/' + miles+'/'+ this.session.get('selected_OrgCode')).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    });
  }

   /* Name:srinivas
    use: returns all procedure list. */
  getAllProcedureByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }
  
  /* Name:srinivas
    use: returns eligible procedure list.  */
  mobileAdminGetEligibleProcedureListByTechnicianCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileAdminGetEligibleProcedureListByTechnicianCode/' + code+'/'+ this.session.get('selected_OrgCode')).subscribe((data) => {
      callback(data);
    })
  }
}
