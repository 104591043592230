<ng-container *ngIf="isSkillEdit">
    <app-addskills [data]="skillData" (skillChangeEvent)="skillChangeEvent($event)"></app-addskills>
  </ng-container>
  <ng-container *ngIf="!isSkillEdit">
  
  <div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">{{'main.labels.Skills' | translate }}
  </div>
  <div class="col-lg-5 col-md-5 col-sm-12 searchfilter">
    <input autocomplete="off" matInput
        (keyup)="applyFilter($event.target.value)" type="text"
        class="form-control"
        placeholder="{{'skills.labels.Search Skill' | translate }}">
      <mat-icon class="cursor-pointer">search</mat-icon>
  </div>

  <ng-template [ngIf]="hasPermission.addPermission === true">
  <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    <button mat-raised-button color="primary" class="ms-2" (click)="addSkills()" title="Add Skills">Add</button>
  </div>
  </ng-template>

  </div>
  <div class="w-100">
    <div class="col-md-12">
      <div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  
          <ng-container matColumnDef="skillName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'skills.labels.Skills Name' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.skillName}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
            <td mat-cell *matCellDef="let element" class="action">

              <ng-template [ngIf]="hasPermission.editPermission === true">
              <mat-icon class="cursor-pointer" (click)="onEdit(element)">edit</mat-icon>
              </ng-template>
              <ng-template [ngIf]="hasPermission.delPermission === true">
                <mat-icon class="cursor-pointer ms-2" (click)="softDeleteOrganizationSkillBySkillCode(element)">delete</mat-icon>
              </ng-template>

            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="p-3 noList" *ngIf="skillList.length == 0 || dataSource.filteredData.length == 0">{{'skills.messages.Skills are not available' | translate }}</div>
      </div>
    </div>
  </div>
  
  <div class="w-100" *ngIf="service.common.infiniteLoader">
    <div class="loadergif">
      <img src="assets/typint_dots_black.gif" alt="Loader">
      <p>Loading...</p>
    </div>
  </div>
  </ng-container>