import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { ActivityLogService } from './activity-log.service';
import { CommonService } from 'src/app/shared/common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnDestroy {

  page:number = 0;
  limit:number = 500;
  activityList:any =[];
  filtered_Activity :string = 'All'
  private webSocket$ = new Subject<void>();

  constructor(
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    private _activityLogSVC : ActivityLogService,
    private _commonSVC : CommonService
  )
  {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
    this.getActivityLog();
    this.subscribeSocket_ActivityLog();

  }
 

  subscribeSocket_ActivityLog()
{
  this._commonSVC.webSocketSubcribeTopic("OrgDashboardTodayAllActivity_"+this._sessionStorageSVC.get('orgCode'))
  .pipe(takeUntil(this.webSocket$))
  .subscribe((res:any) =>
  {
    if(res.body)
    {
      this.getActivityLog();
    }
  });
}

  // subscribeDashboardRefresh()
  // {
  //   // unsubscribing this subject from dashboard component destroy()
  //  this._commonSVC.refreshDashboardSubject.subscribe(res =>{
  //   if(res)
  //   { 
  //     this.getActivityLog();
  //   }
    
  //  })
  // }

  getActivityLog(){
    let orgCode = this._sessionStorageSVC.get('orgCode');

    this._activityLogSVC.getActivityLogByPage(orgCode, this.filtered_Activity, this.page, this.limit).subscribe((res:any) =>{

      if(res.responsecode == 200){
         
        res.data.forEach(element => {
          if(element.userName)
          {
            element.shortName = this.getShortName(element.userName);
          }
         
        });

        this.activityList = res.data ;

      }

    })
  }

  getShortName(fullName) { 
    return fullName.split(' ').map(n => n[0]).join('');
  }

  filterActivities(filteredValue:string)
  {
   this.filtered_Activity = filteredValue ;
   this.activityList =[];
   this.getActivityLog() ;
  }



  ngOnDestroy(): void {
    this.webSocket$.next();
    this.webSocket$.unsubscribe();
  }




}
