<div class="col-md-12">
  <div class="row">
  <div class="col-md-5 col-sm-12 primary-color ">
    <div class="calendar-nav col-md-12 px-0 pb-4 mb-3 cal ">
      <div class="col-md-8 pe-2  p-0 fw-bold pointer header-name " (click)="getStatus('all')"
        [title]="navDate.format('MMMM YYYY')">
        <i class="fa fa-calendar ps-1 pe-3 cal-color" aria-hidden="true"></i>
        {{navDate.format('MMMM YYYY')}}
      </div>
      <div class="col-md-4  p-0 text-end">
        <div class="calendar-nav-previous-month  dt-yr ">
          <span class="resp-arrow">
            <span title="Previous Month"><i class="material-icons pe-2 pointer"
                (click)="changeNavMonth(-1)">keyboard_arrow_left</i></span>
            <span title="Next Month"><i class="material-icons pointer"
                (click)="changeNavMonth(1)">keyboard_arrow_right</i></span>
          </span>
        </div> 
      </div>
    </div>
    <div class="calendar col-12 col-md-12 col-sm-12 w-100">
      <div class="calendar-container  w-100">
        <div class="calendar-header  w-100">
          <div class="calendar-date" *ngFor="let day of weekDaysHeaderArr">
            {{day}}
          </div>
        </div>
        <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
        <div class="calendar-body">
          <div *ngFor="let day of gridArrNew" [ngClass]="day.currentDate == selectedDate?'eventClass':'inactive'"
            class="calendar-date {{day.highlight}} {{day.finalStatus}} pointer" [class.is-disabled]="!day.available"
            (click)="selectDay(day)">
            <button *ngIf="day.value !== 0" class="date-item">{{ day.value}} <span></span></button>
          </div>
        </div>
        <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
      </div>
    </div>
  </div>
  <div class="col-md-7 col-sm-12">
    <div class="d-flex flex-row justify-content-between w-100 logo">
      <h4 class="primary-color fw-bold pb-4 mb-3 header-name">{{ 'Jobs.labels.Jobs' | translate }}
      </h4>
    </div>
    <div class="popup-viewport-body overflow_hidden" *ngIf="timeofreq.length > 0">
      <div *ngFor="let newData of timeofreq; let i=index;" class="example-item">

        <div *ngIf="newData.iscurrent">
          <span class="app-date ms-2">{{newData.key}} :</span>
          <!-- <app-new-job-card [dataSource]="appointmentStatus" [is_Loading]="common.progressLoader"></app-new-job-card> -->

          <!-- <app-new-job-card [dataSource]="cardData"></app-new-job-card> -->
            <staff-job-card [cardData]="cardData"></staff-job-card>        
      
          <!-- <mat-card class=" mx-auto card-appointment mt-4 mb-4" *ngFor="let data of newData.eventData; let j=index;">
            <div class="row icon-round">
              <div class="col-md-1">
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'pending'">
                  hourglass_empty
                </i>
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'assigned'">
                  done
                </i>
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'accepted'">
                  done
                </i>
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'inprogress'">
                  more_horiz
                </i>
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'completed'">
                  done
                </i>
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'expired'">
                  history
                </i>
                <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'cancelled'">
                  close
                </i>
                <i class="material-icons icon-appointment {{data.status}}" *ngIf="data.status == 'late or cancelled'">
                  assignment_late
                </i>
              </div>
              <div class="col-md-3">
                <small>{{ 'dashboard.labels.Patient Name' | translate }}</small>
                <p>{{data.customerName}}</p>
              </div>
              <div class="col-md-8">

                <small>{{ 'dashboard.labels.Patient Address' | translate }}</small>
                <p *ngIf="data.customerAddress">{{data.customerAddress}}</p>
                <p *ngIf="!data.customerAddress">{{ 'dashboard.labels.Not Available' | translate }}</p>
              </div>

            </div>
            <hr class="p-0">
            <div class="row">
              <div class="col-md-2">
                <small>{{ 'common.labels.Start Time' | translate }}</small>
                <p>{{data.startTime}}</p>
              </div>
              <div class="col-md-2">
                <small>{{ 'common.labels.End Time' | translate }}</small>
                <p>{{data.endTime}}</p>
              </div>
              <div class="col-md-2 cursor-pointer" (click)="service.common.toggleDistance(data.appointmentsDurationCode)">
                <div class="distanceImg" *ngIf="service.common.showDistance !== data.appointmentsDurationCode">
                  <small>{{ 'dashboard.labels.Distance' | translate }}</small>
                  <p *ngIf="data.distanceText">{{data.distanceText}}</p>
                  <p *ngIf="!data.distanceText">0.00 Miles</p>
                </div>
                <div class="distanceImg" *ngIf="service.common.showDuration == data.appointmentsDurationCode">
                  <small>{{ 'Jobs.Create Jobs.labels.Duration' | translate }}</small>
                  <p *ngIf="data.durationText">{{data.durationText}}</p>
                  <p *ngIf="!data.durationText">0 Minute</p>
                </div>
              </div>

              <div class="col-md-1" >
                  <span *ngIf="data.notes || data.patientRecurringNotes || data.comments">
                      <img class="cursor-pointer mt-2" src="/assets/notes_icon.png" title="Notes" (click)="getNotes(data)">
                  </span>
                  <span *ngIf="!data.notes && !data.patientRecurringNotes && !data.comments">

                  </span>
              </div>
              

                  <div class="col-md-4 dis-img text-end pe-0">
               
                    <div class=" me-0 pe-0 " >
                      <div class=" ms-1 mb-2 cursor-pointer"  *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                        <img (click)="getProcedureDetails(procImg,data.notes)" class="img-icon" alt="No Image" *ngIf="i <= 3 && procImg.procedureIcon" title="{{procImg.procedureName}}"
                          [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                        <img alt="No Image" *ngIf="!procImg.procedureIcon"
                          src="../../../../assets/common_images/profile.jpg" />
                      </div>
                    </div>
                    <div class="ps-0 img-data" *ngIf="data.organizationBasedProcedureDetailsList.length > 4" (click)="showImage(data)" >
                      <div class="img-count cursor-pointer">
                          {{data.organizationBasedProcedureDetailsList.length - 4}}
                      </div>
                              
                    </div>
                  
        
                    <div class="img-open " *ngIf="data.appointmentsDurationCode == showImg"> 
                            <div class="img-icon me-0 mt-1" >
                              <div *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                                  <img (click)="getProcedureDetails(procImg,data.notes)" alt="No Image" class="me-2" *ngIf="i >= 4 && procImg.procedureIcon " title="{{procImg.procedureName}}"
                                  [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                              </div>
                            </div>
                    </div>
                </div>

            </div>
            <div *ngIf="data.status == 'assigned' && loggedUserType !== 'Staff'">
              <hr class="p-0 my-3">
              <div class="row">
                <div class="col-md-12 pe-0 text-start" >
                  <button class="accept-btn me-3" mat-raised-button (click)="appointmentStatus('accept', data.appointmentsDurationCode)">{{ 'dashboard.labels.Accept' | translate }}</button>
                  <button class="reject-btn" mat-raised-button (click)="appointmentStatus('reject', data.appointmentsDurationCode)">{{ 'dashboard.labels.Reject' | translate }}</button>
                </div>
              </div>
            </div>
          </mat-card> -->



        </div>

      </div>
    </div>
  </div>
  </div>
</div>