<!-- <p>add-distribution works!</p> -->
<div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="row mb-4">
        <div class="col-md-10 align-self-center">
          <span class="d-flex">
            <h6 class="skill-test pt-2">{{popupTitle}}</h6>
            <span class="headerNoteTitleBtn pt-1">
              <span class="ps-4 cursor-pointer" (click)="data?.noteList ? service.common.addUpdateNotes(data.noteList) : service.common.addUpdateNotes()">
                <span class="notebtnContainer" *ngIf="data.distributionCode && data?.noteList?.length > 0"><img class="p-1 notebtnIc" src="assets/images/note-icon-active.svg" /><span class="ps-1 noteBtnTxtActive">Note</span></span>
                  <span class="notebtnContainer" *ngIf="!data.distributionCode || data?.noteList?.length === 0"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" /><span class="ps-1 noteBtnTxtInactive">Note</span></span>
              </span>
          </span>
          </span>
        </div>
        <div class="col-md-2">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
          </div>
        </div>
      </div>  
      <form [formGroup]="reqForm" (ngSubmit)="onSubmit()">
        <div class="form-group col-md-12 col-sm-12 p-0">
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Distribution Name</mat-label>
            <input autoClose matInput formControlName="distributionName" type="text"
              placeholder="" maxlength="70">
          </mat-form-field>
          <span class="help-block err"
            *ngIf="reqForm.controls.distributionName.errors?.required && (!submitted || reqForm.controls.distributionName.touched)">{{ 'distributionList.validators.Distribution name is required' | translate }}</span>
        </div>

        <div class="w-100 col-md-12 col-sm-12 p-0 mt-3" *ngIf="allStaffMemberList.length > 0">
            <angular2-multiselect [data]="allStaffMemberList" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedCodeList" [settings]="dropdownSettings" formControlName="userCodeList"></angular2-multiselect>
            <span class="help-block err"
            *ngIf="reqForm.controls.userCodeList.errors?.required && (!submitted || reqForm.controls.userCodeList.touched)">Please select a user</span>
        </div>
              
      </form>
  
      <div class="d-flex offset-md-1 justify-content-end">
        <button mat-button (click)="onNoClick()" class="cancel-btn-primary me-2">{{ 'distributionList.labels.Cancel' | translate }}</button>
        <button type="submit" class="pe-4 ps-4 nowrapTxt" mat-raised-button color="primary" (click)="!isLoading && onSubmit()"> {{ popupTitle === 'Edit Distribution' ? 'Update' : 'Save'}} <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>
      </div>
    </div>
  </div>
  