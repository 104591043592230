import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ReportsService } from './reports.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { jsPDF} from 'jspdf';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { element } from 'protractor';
import { debounce } from 'lodash';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  // displayedColumns = ['clinicianName', 'patientName', 'appointmentDuration', 'scheduledStartTime', 'actualStartTime', 'actualEndTime','estimatedMiles','actualMiles', 'delayedReason'];


  displayedColumns = [
   { def: "jobDate",  visible: true },
   { def: "staffName",  visible: true },
   { def: "customerName",  visible: true },
   { def: "jobSite",  visible: true },
   { def: "jobId",  visible: true },
   { def: "jobType",  visible: true },
   { def: "scheduleStartTime",  visible: true },
   { def: "scheduleEndTime",  visible: true },
   { def: "actualStartTime",  visible: true },
   { def: "actualEndTime",  visible: true },
   { def: "estimatedMiles",  visible: false },
   { def: "actualMiles",  visible: false },
   { def: "notes",  visible: true },
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('iframe') iframe: ElementRef;
  // dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  date = new Date();
  formatedDate = moment(this.date).format('YYYY-MM-DD');
  formatedDateS = moment(this.date).startOf('week').format('YYYY-MM-DD');
  formatedDateE = moment(this.date).endOf('week').format('YYYY-MM-DD');
  dataSource;
  dataSourceW;
  // i = 0;
  userName = '';
  allReport = [];
  dataReport;
  orgCode;
  appointmentDate;
  toggaleVariable: any;

  exportAsConfig: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'checkinout', // the id of html/table element
    // options: { // html-docx-js document options
    //   orientation: 'landscape',
    //   margins: {
    //     top: '2000',
    //     left: '2000',
    //     right: '2000',
    //     bottom: '2000',
    //     header: '2000',
    //     gutter: '20'
    //   },
    // }
  };
  exportAsConfig1: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'weekly', // the id of html/table element
  };
  @ViewChild('content', { static: false }) content: ElementRef;
  WeeklyReport = [];
  allWorker: Observable<any>;
  worker = new FormControl();
  filteredOptions: Observable<any>;
  selectedWorker = 'all';
  keys = [];
  keyValue;
  optionWorkers = [];
  workerexits = false;
  workernotexit = true;
  workerName;

  page: number = 0;
  limit:number = 30;

  // moment().subtract(1, 'months').toDate()

  searchForm = {
    "endDate": moment().toDate(),
     "searchRequest": {
      "columnName": "",
      "columnValue": ""
    },
    "startDate": moment().toDate()
  }

  isLoading:boolean = false;
  driveTime_settingValue:string;

  toggleDateFilter:boolean = false;
  toggleStaffFilter:boolean = false;
  toggleCustomerFilter:boolean = false;
  toggleLocationFilter:boolean = false;
  togglejobIDFilter:boolean = false;
  togglejobTypeFilter:boolean = false;

  DateInput : string ;
  StaffInput : string ;
  CustomerInput : string ;
  LocationInput : string ;
  JobIDInput : string ;
  JobTypeInput : string ;

  constructor(
    public dialog: MatDialog,
    public service: ReportsService,
    private translate: TranslateService,
    private exportAsService: ExportAsService
    ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.getSettingByName();
    this.searchColumn = debounce(this.searchColumn, 1000);
  }

  onScroll() {
    this.page = this.page + 1;
    this.getDailyReport('scroll');
    // this.getAllReport(this.formatedDate, 'scroll');

  }

  export() {
    this.exportAsService.save(this.exportAsConfig, 'Check In & Out Report').subscribe(() => {
    });
  }

  exportWeekly() {
    this.exportAsService.save(this.exportAsConfig1, 'Weekly Check In-Out Report').subscribe(() => {
    });
  }

  ngOnInit() {
   // this.getAllReport(this.formatedDate);
   this.getDailyReport();
    this.appointmentDate = moment(this.formatedDate, 'YYYY-MM-DD').format('MM-DD-YYYY');
   // this.getWeeklyReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
    this.orgCode = this.service.storage.get('orgCode');
   // this.getAllWorker();
    this.filteredOptions = this.worker.valueChanges
      .pipe(
        startWith<string>(''),
        map(value => this._filter(value)));
  }

  displayFn(workerList): string | undefined {
    return workerList ? workerList.name : undefined;
  }

  private _filter(value): string[] {
    if (value && value.workerCode !== undefined) {
      this.getAllWorker();
    }
    if (value) {

      // const filterValue = name.toLowerCase();
      return this.optionWorkers.filter(option => option.name.toLowerCase().includes(value.toLowerCase()));

      // const filterValue = value.toLowerCase();
      // return this.optionWorkers.filter(option => option.name.toLowerCase().includes(filterValue));
    } else {
      return this.optionWorkers;
    }

  }

  selectedAuto(event) {

    this.filteredOptions = this.worker.valueChanges
      .pipe(
        startWith<string>(''),
        map(value => this._filter(value)));
    this.selectedWorker = event.option.value.workerCode;
    this.workerName = event.option.value.name;
    this.getWeeklyReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
    if (this.selectedWorker) {
      this.workerexits = true;
      this.workernotexit = false;
    }
  }

   /* Name:Shailesh
      use: for getting all appointment report by org code */
  getAllReport(date, scroll = null) {
    //console.log('date',date);
    if (!scroll) {      
      this.service.common.progressLoader = true;
    } else if (scroll) {
      this.service.common.infiniteLoader = true;
    }

    try {
      this.service.getAdminAppointmentReport(date, this.service.storage.get('orgCode'), this.page, this.limit, (data) => {        
        this.service.common.progressLoader = false;
        this.service.common.infiniteLoader = false;
      if (data.responsecode === 200) {
        data.data.forEach((element) => {
          if (element.actualStartTime != null) {
            let start; let end;
            if (element.dateFormat === 'dd-MM-yyyy hh:mm a') {
              start = moment(element.actualStartTime, 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD HH:mm');
              end = moment(element.scheduledStartTime, 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD HH:mm');
            } else if (element.dateFormat === 'MM-dd-yyyy hh:mm a') {
              start = moment(element.actualStartTime, 'MM-DD-YYYY hh:mm').format('YYYY-MM-DD HH:mm');
              end = moment(element.scheduledStartTime, 'MM-DD-YYYY hh:mm').format('YYYY-MM-DD HH:mm');
            } else if (element.dateFormat === 'yyyy-MM-dd hh:mm a') {
              start = moment(element.actualStartTime, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DD HH:mm');
              end = moment(element.scheduledStartTime, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DD HH:mm');
            }
            element.diff = moment.duration(moment(start).diff(end)).minutes();
          } else {
            element.diff = null;
          }
        });
        if (scroll) {

          const arrayData = this.dataSource.data;
          data.data.forEach(element => {
            arrayData.push(element);
          });
          setTimeout(() => {
            this.dataSource = new MatTableDataSource(arrayData);
            this.allReport = arrayData;
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 1000);

          //console.log(this.allReport);
          //console.log(this.service.common.message);

        } else {
          this.dataSource = new MatTableDataSource(data.data);
          this.allReport = data.data;
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sort;
        }

      } else {

        if (!scroll) {
          this.allReport = [];
          this.dataSource = new MatTableDataSource();
        } 
      }
    });
    } catch (error) {
      this.service.common.progressLoader = false;
      this.service.common.infiniteLoader = false;
      //console.log(error)
    }
    
  }

   /* Name:Shailesh
      use: for getting all worker and organization by org code */
  getAllWorker() {
    try {
      this.service.adminGetWorkerAndOrganizationFromWASActive(this.service.storage.get('orgCode'), (data) => {
      this.optionWorkers = [];
      data.data.forEach(element => {
        this.optionWorkers.push({ workerCode: element.workerRes.workerCode, name: element.workerRes.name });
      });
    });
    } catch (error) {
      //console.log(error)
    }
    
  }

   /* Name:Shailesh
      use: for getting weekly report of clinician by from date to date and org code */
  getWeeklyReport(wcode, fromdt, todt) {
    this.service.common.progressLoader = true;
    try {
       this.service.getWeeklyReportOfClinician(wcode, fromdt, todt, this.service.storage.get('orgCode'), (data) => {

      if (data.responsecode === 200) {
        this.service.common.progressLoader = false;
        this.WeeklyReport = data.data.bodyValue;
        this.keyValue = data.data.headerValue;
        this.keys = [];
        if (data.data.bodyValue.length > 0) {
          this.keys = Object.keys(data.data.bodyValue[0]);
        }

        this.dataSourceW = new MatTableDataSource(data.data.bodyValue);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSourceW.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
        this.dataSourceW.sort = this.sort;

      } else {
        this.WeeklyReport = [];
        this.dataSourceW = new MatTableDataSource();
        this.service.common.progressLoader = false;
      }
    });
    } catch (error) {
      //console.log(error);
    }
   
  }

  onDateChange(event, date) {
    this.page = 0;
    this.dataSource = new MatTableDataSource();
    this.allReport =[];

   if(date === 'fromDate') 
   {
    this.searchForm.startDate = event.value;
   }
   
   if(date === 'todate')
   {
    this.searchForm.endDate = event.value;
   }

    this.getDailyReport();
    // this.getAllReport(this.formatedDate);
  }

  onDateChangeS(event) {
    this.page = 0;
    this.formatedDateS = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.getWeeklyReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
  }

  onDateChangeE(event) {
    this.page = 0;
    this.formatedDateE = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.getWeeklyReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
  }

8
  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.allReport = this.dataSource.filteredData;
  }

  // selectWorker(event){
  //   ////console.log(event);
  // }

  /* Name:Shailesh
      use: for printing all report*/
  printReport(): void {
    const printContents = document.getElementById('tbl_report').innerHTML;
 
    this.iframe.nativeElement.name = "frame1";
  this.iframe.nativeElement.style.position = "absolute";
  this.iframe.nativeElement.style.top = "-1000000px";
  document.body.appendChild(this.iframe.nativeElement);
  let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write('<html><head><title>Check In/Out Report</title>');
  frameDoc.document.write('</head><body>');
  frameDoc.document.write(printContents);
  frameDoc.document.write('</body></html>');
  frameDoc.document.close();
  setTimeout(() => {
  window.frames["frame1"].focus();
  window.frames["frame1"].print();
  document.body.removeChild(this.iframe.nativeElement);
  }, 1000);

  }


  /* Name:Shailesh
      use: for printing weekly report */
  printWeeklyReport(): void {
    const printContents = document.getElementById('printWeeklyTable').innerHTML;

    // // const WindowPrt = window.open( '' , 'Print', 'left=0,top=0,width=1800,height=1200,toolbar=0,scrollbars=0,status=0');
    // const WindowPrt = window.open('');
    // WindowPrt.document.write(printContents.innerHTML);
    // // var dimg = document.getElementById('dimg');
    // // dimg.innerHTML ='<img src="'+this.service.common.webLogo+'" >';
    // setTimeout(() => {
    //   WindowPrt.print();
    //   WindowPrt.close();
    // }, 1000);

    this.iframe.nativeElement.name = "frame1";
    this.iframe.nativeElement.style.position = "absolute";
    this.iframe.nativeElement.style.top = "-1000000px";
    document.body.appendChild(this.iframe.nativeElement);
    let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head><title>Mileage Report</title>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(printContents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(() => {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(this.iframe.nativeElement);
    }, 1000);
    
  }


  getDailyReport(scroll?) {

    let req = {
      endDate: moment(this.searchForm.endDate).format('YYYY-MM-DD'),
      organizationCode: this.service.storage.get('orgCode'),
      searchRequest: {
        columnName: this.searchForm.searchRequest.columnName,
        columnValue: this.searchForm.searchRequest.columnValue
      },
      startDate: moment(this.searchForm.startDate).format('YYYY-MM-DD')
    }
    
    try {
      this.service.common.progressLoader = true;
       
      this.isLoading = true;
      this.service.getDailyReports(req, this.page, this.limit).subscribe((res:any) =>{
        this.service.common.progressLoader = false;
        this.isLoading = false;

        if(res.responsecode == 200)
        {
         let temp_array = [];
          if(scroll)
          {
            temp_array = this.dataSource.data;
          }

          res.data.forEach(element =>{  temp_array.push(element);  });

            this.dataSource = new MatTableDataSource(temp_array);
            this.allReport = temp_array;
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            // this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      this.isLoading = false;
      //console.log(error)
    }
    
  }

getSettingByName()
{
  this.service.common.getSettingNameByName('IsDrivingTime', this.service.storage.get('orgCode')).subscribe((res:any)=>{
    if (res.responsecode == 200) {
      if (res.settingValue === 'true')
      {

        this.displayedColumns.forEach(ele=>{
          if(ele.def === 'estimatedMiles' || ele.def === 'actualMiles')
          {
            ele.visible = true;
          }
        })

      }
     
   } 
  })

}


getDisplayedColumns(): string[] {
  return this.displayedColumns.filter(cd => cd.visible).map(cd => cd.def);
}

searchColumn(value:any, tbl_col:string)
{
 // //console.log(value,tbl_col)
if(value.trim())
{
  this.searchForm.searchRequest.columnName = tbl_col;
  this.searchForm.searchRequest.columnValue = value;
  this.dataSource = new MatTableDataSource();
  this.allReport = []
  this.page = 0 ;
  this.getDailyReport();
}

}

toggleFilter(variable:string)
{
  this.toggaleVariable = variable;
  this.toggleDateFilter = false;
  this.toggleStaffFilter = false;
  this.toggleCustomerFilter = false;
  this.toggleLocationFilter = false;
  this.togglejobIDFilter = false;
  this.togglejobTypeFilter = false;
 
  this.clearSearch();

  setTimeout(()=>{
    this[variable] = true ;
  },100)

}

clearSearch()
{
  this.DateInput = '';
  this.StaffInput = '';
  this.CustomerInput = '';
  this.LocationInput = '';
  this.JobIDInput = '';
  this.JobTypeInput = '';

  this.searchForm.searchRequest.columnName = '';
  this.searchForm.searchRequest.columnValue = '';

}

clearFilter(variable:string)
{
  this[variable] = false; 
  this.clearSearch();
  this.page = 0 ;
  this.getDailyReport();
}


generatePDF(){

      // const elementToPrint = document.getElementById('tbl_report'); //The html element to become a pdf
      // const pdf = new jsPDF('p', 'pt', 'a4');
      // pdf.html(elementToPrint);
      // pdf.save('web.pdf');


      let data = document.getElementById('tbl_report');
    
      const pdf = new jsPDF({
        format: 'letter',  // Choose the desired page size, e.g., 'letter', 'a4', etc.
        orientation: 'portrait',  // 'portrait' or 'landscape'
        unit: 'pt',  // Points as the unit (you can choose other units like 'mm', 'cm', etc.)
      });
  
      // Set margins (optional)
     // pdf.setMargins(40, 40, 40, 40);  // Adjust margins as needed (top, left, bottom, right)
  
		pdf.html(document.getElementById('tbl_report'), {
			callback: function (pdf) {
				// var iframe = document.createElement('iframe');
				// iframe.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:500px');
				// document.body.appendChild(iframe);
				// iframe.src = pdf.output('datauristring');
        pdf.save("tests.pdf")
			},
      margin: [40,40,40,40]
		});


}



}
