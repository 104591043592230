import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ChatService } from '../chat.service';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';


@Component({
  selector: 'app-chat-group',
  templateUrl: './chat-group.component.html',
  styleUrls: ['./chat-group.component.scss']
})
export class ChatGroupComponent implements OnInit {

  chatFormGroup: FormGroup;
  submitted = true;
  filteredOptions: Observable<any[]>;
  orgSelected = [];
  orgCode;
  allMarshals = [];
  resetForm = false;

  constructor(public service: ChatService,
    public dialogRef: MatDialogRef<ChatGroupComponent>,
    public translate: TranslateService, public common: CommonService
   ) { 
    this.translate.setDefaultLang(this.common.local.get('language'));
   }

  ngOnInit() {

    this.orgCode = this.service.common.local.get('orgCode');
    /* Name:Shailesh
      use: for getting clinician and organization by org code */
    try {
      this.service.getWorkerAndOrganizationFromWAS(this.orgCode, (response) => {
      ////console.log(response);
      if (response.responsecode == 200) {
        setTimeout(() => {
          this.allMarshals = response.data;
        }, 500);
      }
    })
    } catch (error) {
      //console.log(error)
    }
    

    this.chatFormGroup = new FormGroup({
      'groupname': new FormControl(null, Validators.required),
      'selectmarshalname': new FormControl(null),
      'marshalname': new FormControl(null, Validators.required),
      'startdate': new FormControl(null),
      'enddate': new FormControl(null),
    });

    this.filteredOptions = this.chatFormGroup.controls['selectmarshalname'].valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      );
  }

  displayFn(marshal): string | undefined {
    return marshal ? marshal.name : undefined;
  }

  private _filter(name) {
    ////console.log(name);
    if(name){
      const filterValue = ('' + name).toLowerCase();
      return this.allMarshals.filter(option => option.workerRes.name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
    }
    
  }

  selectedOption(event) {
    ////console.log(event);
    this.chatFormGroup.controls['marshalname'].setValue(event.option.value);
    const index = this.orgSelected.indexOf(event.option.value);
    if (index == -1) {
      this.orgSelected.push(event.option.value);
    }
    this.chatFormGroup.controls['selectmarshalname'].setValue(null);
    this.filteredOptions = this.chatFormGroup.controls['selectmarshalname'].valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      );
  }

  removeOrg(index) {
    this.orgSelected.splice(index, 1);
    if (index == 0) {
      this.chatFormGroup.controls['marshalname'].setValue(null);
    }
  }

  /* Name:Shailesh
      use: for creating the chat group */
  onSubmit() {
    ////console.log(this.chatFormGroup);
    ////console.log(this.orgSelected);
    const workerCode = [];
    this.orgSelected.forEach((element) => {
      workerCode.push(element.workerRes.workerCode);
    })

    let startdt='';
    let enddt='';
    ////console.log(this.chatFormGroup.value.startdate);
    ////console.log(moment(this.chatFormGroup.value.startdate).format('YYYY-MM-DD'));
    
    if(this.chatFormGroup.value.startdate){
      startdt=moment(this.chatFormGroup.value.startdate).format('YYYY-MM-DD');
    }
    if(this.chatFormGroup.value.enddate){
      enddt=moment(this.chatFormGroup.value.enddate).format('YYYY-MM-DD');
    }

    const data = {
      "adminCode": this.service.common.local.get('userCode'),
      "groupName": this.chatFormGroup.value.groupname,
      "organizationCode": this.service.common.local.get('orgCode'),
      "userCodeList": workerCode,
      "startdate":startdt,
      "enddate":enddt
    }
   
    ////console.log(data);
    if (this.chatFormGroup.valid) {
      this.submitted = true;
      try {
        this.service.createGroupAndMapWithUser(data, (res) => { 
          ////console.log(res);
          if (res.responsecode == 200) {
            this.orgSelected = [];
            this.chatFormGroup.reset();
            this.resetForm = true;
            setTimeout(() => {
              this.resetForm = false;
            }, 200);
            this.service.common.message = res.message;
            this.service.common.successMessage = true;
            this.cancel();
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
            this.cancel();
          }
        })
      } catch (error) {
        //console.log(error);
      }
     
    } else {
      this.submitted = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
