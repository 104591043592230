import { Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage'; 
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
 
@Component({
  selector: 'clockin-clockout-job-card',
  templateUrl: './clockin-clockout-job-card.component.html',
  styleUrls: ['./clockin-clockout-job-card.component.scss']
})
export class ClockinClockoutJobCardComponent implements OnInit {

  userType: any;
  @Input() status: string;

  yearMonthDateFormat = 'YYYY-MM-DD';

  currentSelectedDate;
  protected _onDestroy = new Subject<void>();
  allJobList = [];
  is_Loading: boolean = false;


  constructor(private _translateSVC: TranslateService,
    private _sessionSVC: SessionStorageService,
     public dashboarService: DashboardService,
  ) {
    this._translateSVC.setDefaultLang(this._sessionSVC.get('language'));
  }

  // private createTimerObservable(startTime: string, timezone: string): Observable<any> {
  //   return interval(1000).pipe(
  //     map(() => {
  //       let timezone_formate = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss')
  //       const currentTime = new Date(timezone_formate).getTime();
  //       const elapsedTime = currentTime - new Date(startTime).getTime();
  //       return this.formatElapsedTime(elapsedTime);
  //     })
  //   );
  // }

  // private formatElapsedTime(elapsedTime: number): string {
  //   // const days = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //   const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

  //   // return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  //   return `${hours}h ${minutes}m ${seconds}s`;
  // }

  ngOnInit(): void {

    this.userType = this._sessionSVC.get('user_type');

    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    if (this.status) {
      this.getShiftList();
    }

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  getShiftList() {
    if (this.currentSelectedDate && this.status) {
      this.is_Loading = true;
      if (this.userType === 'Customer') {
        this.dashboarService.getClockInClockOutShiftDetailsForCustomer(this.dashboarService.session.get('orgCode'), this.currentSelectedDate, this.status, this.dashboarService.session.get("logedIn_CustomerCode"), (res) => {
          this.is_Loading = false;
          this.dashboarService.common.progressLoader = false;
          if (res.responsecode == 200) {
            // res.data.forEach(ele => {
            //   if (ele.inBreak) {
            //     ele.breakStart_timer$ = this.createTimerObservable(ele.breakStartDateTimeFixedFormat, ele.timeZoneName);

            //   }
            // })
            this.allJobList = res?.data;
          }
          else {
            this.allJobList = [];
          }
        });

      }
      else {
        this.dashboarService.getClockInClockOutShiftDetails(this.dashboarService.session.get('orgCode'), this.currentSelectedDate, this.status, (res) => {
          this.is_Loading = false;
          this.dashboarService.common.progressLoader = false;
          if (res.responsecode == 200) {
            // res.data.forEach(ele => {
            //   if (ele.inBreak) {
            //     ele.breakStart_timer$ = this.createTimerObservable(ele.breakStartDateTimeFixedFormat, ele.timeZoneName);

            //   }
            // })
            this.allJobList = res?.data;
          } else {
            this.allJobList = [];
          }
        });
      }
    }
    // this.isShiftActive[idx] = true;
  }




  refreshData(event: any) {
    this.allJobList = [];
    this.getShiftList();
  }

}
