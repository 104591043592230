import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { TechnicianService } from '../../dashboards/providers-dashboard/technician/technician.service';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'popover-content',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  host: {'class': 'popoverCls'},
  // template: `
  //   <div class="popover-content" (click)="$event.stopPropagation()">
  //     <ng-container *ngTemplateOutlet="template"></ng-container>
  //     <button (click)="closePopover()">Close</button>
  //   </div>
  // `,
  // styles: [`
  //   .popover-content {
  //     border: 1px solid #ccc;
  //     padding: 10px;
  //     background: white;
  //     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  //   }
  // `]
})
export class PopoverComponent implements OnInit {
  fileUrl: any;
  @Input() appPopoverData: any;
  @Input() marginLeft: number;
  // @Input() template: TemplateRef<any>;
  @Output() close = new EventEmitter<void>();
  isChatProfileOpen: boolean = false;

  constructor(
    public technicianService: TechnicianService, public session: SessionStorageService,) {}

  ngOnInit() {
    this.isChatProfileOpen = this.session.get('user_type') && this.session.get('user_type') === 'Customer' ? false : (this.appPopoverData?.staffCode || this.appPopoverData?.workerCode || this.appPopoverData?.userCode || this.appPopoverData?.code) ? true : false;
    this.fileUrl = this.technicianService.service.getSuperAdmin() + '/downloadFile/';
    // console.log('PopoverData: ', this.marginLeft/15.5);
  }

  closePopover() {
    this.close.emit();
  }

  leave(event){
    // console.log('leave', true);
    this.close.emit();
  }
}
