<ul class="timeline pe-2 pt-2">
  <ng-container *ngFor="let noteVal of noteArray; let idx = index; let last = last">
      <li class="no-title-BG">
          <div class="d-flex justify-content-between">
              <b><span class="activities-title">Note By: {{noteVal?.userName | capitalize}}</span></b>
              <b><span class="activities-time float-end d-flex">
                <span>{{noteVal?.updatedAt}}</span>
                <span><span><mat-icon class="ms-2 icFont curser-pointer" (click)="updateNote(noteVal)">edit</mat-icon></span>
                <span><mat-icon class="ms-1 icFont curser-pointer" (click)="deleteNote(noteVal)">delete</mat-icon></span></span>
            </span></b>
          </div>
          <p class="activities-desc" [innerHTML]="noteVal.note"></p>
      </li>
  </ng-container>
</ul>