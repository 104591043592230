import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NewJobCardService } from '../new-job-card.service';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
import { TechnicianEditComponent } from "src/app/dashboards/providers-dashboard/technician/technician-edit/technician-edit.component";

@Component({
  selector: 'notified-list',
  templateUrl: './notified-list.component.html',
  styleUrls: ['./notified-list.component.scss']
})
export class NotifiedListComponent implements OnInit {

  notifyUsersList = [];
  showSpinner: boolean = false;
  popupTitle: any;

  constructor(public dialogRef: MatDialogRef<NotifiedListComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private jobCardService: NewJobCardService,public dashboarService: DashboardService) {}
  ngOnInit(): void {
    // console.log('Data: ', this.data);
    if(this.data){
      this.popupTitle = this.data?.status;
      this.getNofifyedStaffList(this.data);
    }
  }

  getNofifyedStaffList(data: any){
    if(data.appoinmentCode && data.appoinmentProcedureCode && data.date){
    this.showSpinner = true;
    this.jobCardService.getNotifiedUserList(data.appoinmentCode, data.appoinmentProcedureCode, data.date)
      .subscribe((res: any) => {
        this.showSpinner = false;      
        if (res.responsecode == 200) {
          this.notifyUsersList = res?.data;
        }
        else {
          this.notifyUsersList = [];
        }
      })
    }
  }


}
