import { Component, OnInit, Inject, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailTemplateService } from '../email-template/email-template-service.service';
import { DistributionService } from 'src/app/components/distribution-list/distribution-service';
import { RoleService } from 'src/app/components/role/role.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'email-configuration-setting',
  templateUrl: './email-configuration-setting.component.html',
  styleUrls: ['./email-configuration-setting.component.scss']
})
export class EmailConfigurationSetting implements OnInit {
  protected  close_pass_eye = true;
  protected  showLinkInfo = false;
  protected  showHostInfo = false;
  protected  enableHostInfo = false;
  dialogRef: any;
  @ViewChild('errorLog_popup')  errorLog_popup : TemplateRef<any>;
  emailConfigForm!: FormGroup;
  orgCode: any;
  isLoading: boolean = false;
  isTestEmailLoading: boolean = false;
  submitted = true;
  sendTestEmailSubmitted = false;
  errorLog_popupMessage: any;
  toEmail = new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]);

  constructor(
    public emailTemplateService: EmailTemplateService,
    public distributionService: DistributionService,
    public roleServices: RoleService,
    public dialog: MatDialog,
  ) {}


  ngOnInit() {
    this.orgCode = this.distributionService.storage.get('orgCode');
    // Validators.pattern(this.emailTemplateService.common.email)
    this.emailConfigForm = new FormGroup({
      authMethod: new FormControl(null),
      clientId: new FormControl(null),
      displayName: new FormControl(null, Validators.required),
      emailAddress: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
      emailHost: new FormControl(null, Validators.required),
      emailPort: new FormControl(null, Validators.required), 
      emailSettingCode: new FormControl(null),     
      emailSsl: new FormControl(null),
      starttlsEnable: new FormControl(null),
      organizationCode: new FormControl(this.orgCode),
      password: new FormControl(null, Validators.required),
      userName: new FormControl(null, Validators.required),
    });    
  }

  ngAfterViewInit() {    
    setTimeout(() => { 
      if(this.orgCode)
        this.getEmailSettingByOrgCode();
    }, 500);
  }

/* Name:Vivek Chauhan
     use: for adding/creating/saving templateReqForm in organization */
     onSubmit() {    
      this.submitted = false;
      // console.log("this.templateForm", this.templateForm);
      if (this.emailConfigForm.valid && (this.emailConfigForm.controls?.emailSsl?.value || this.emailConfigForm.controls?.starttlsEnable?.value)) {
        let convertEmailPortToNumber = this.emailConfigForm.controls?.emailPort?.value && parseInt(this.emailConfigForm.controls.emailPort.value);
        if(convertEmailPortToNumber){
          this.emailConfigForm.controls['emailPort'].setValue(convertEmailPortToNumber);
        }
      //   this.isLoading = true;
        // let staticVal = this.templateForm.controls['emailTemplate'].value;
        // let dynamicValue = staticVal ? staticVal?.replace(/[^{\}]+(?=})/g, event?.value?.name) : 'User';
        // this.templateForm.controls['emailTemplate'].setValue(res.data?.clientId);     
      // console.log('Form Values: ', this.emailConfigForm.value);
      // return;
      this.isLoading = true;
      this.emailTemplateService.common.progressLoader = true;
        this.emailTemplateService.saveOrUpdateEmailSetting(this.emailConfigForm.value).subscribe((response: any) => {
          this.emailTemplateService.common.progressLoader = false;
          if (response.responsecode === 200) {
            this.emailTemplateService.common.successMessage = true;
            this.emailTemplateService.common.message = response.message;
            this.isLoading = false;
          } else {
            this.emailTemplateService.common.errorMessage = true;
            this.emailTemplateService.common.message = response.message;
            this.isLoading = false;
          }
        });
      }
    }

    getEmailSettingByOrgCode() {
      this.emailTemplateService.common.progressLoader = true;
      this.emailTemplateService.getEmailSettingByOrgCode(this.orgCode).subscribe((res: any) => {
        this.emailTemplateService.common.progressLoader = false;
        let domain = "gmail.com";

        // let string = "smtp.gmail.com";
        // let domain = "gmail.com";

        // if (string.endsWith(domain)) {
        //     console.log("The string ends with gmail.com");
        // } else {
        //     console.log("The string does not end with gmail.com");
        // }
        // console.log('getEmailSettingByOrgCode', res); 
        if(res.responsecode === 200){
          this.emailConfigForm.controls['authMethod'].setValue(res.data?.authMethod); 
          this.emailConfigForm.controls['clientId'].setValue(res.data?.clientId); 
          this.emailConfigForm.controls['displayName'].setValue(res.data?.displayName); 
          this.emailConfigForm.controls['emailAddress'].setValue(res.data?.emailAddress); 
          this.emailConfigForm.controls['emailHost'].setValue(res.data?.emailHost); 
          this.emailConfigForm.controls['emailPort'].setValue(res.data?.emailPort); 
          this.emailConfigForm.controls['emailSettingCode'].setValue(res.data?.emailSettingCode); 
          this.emailConfigForm.controls['emailSsl'].setValue(res.data?.emailSsl); 
          this.emailConfigForm.controls['starttlsEnable'].setValue(res.data?.starttlsEnable);
          this.emailConfigForm.controls['password'].setValue(res.data?.password); 
          this.emailConfigForm.controls['userName'].setValue(res.data?.userName); 
          let domain = "gmail.com";
          if (res.data?.emailHost && res.data.emailHost.endsWith(domain)) {
            this.enableHostInfo = true;
              // console.log("The string ends with gmail.com");
          } else {
            this.enableHostInfo = false;
              // console.log("The string does not end with gmail.com");
          }
        }
      });
    }

    changeSSL(event, controlName){
      let string = this.emailConfigForm.controls.emailHost.value;
      let domain = "gmail.com";

      if (string.endsWith(domain)) {
        if(event.checked && controlName === 'emailSsl'){
          this.emailConfigForm.controls['emailPort'].setValue('465'); 
        }
        if(event.checked && controlName === 'starttlsEnable'){
          this.emailConfigForm.controls['emailPort'].setValue('587'); 
        }
          // console.log("The string ends with gmail.com");
      } else {
          // console.log("The string does not end with gmail.com");
      }
      // console.log('changeSSL: ', event.checked, controlName);
      if(event.checked && controlName === 'emailSsl'){
        this.emailConfigForm.controls['starttlsEnable'].setValue(false);
      } else {
        this.emailConfigForm.controls['emailSsl'].setValue(false);
      }
    }
    
    checkLinkIsGmail(value: any){
      let domain = "gmail.com";
      if (value.endsWith(domain)) {
        this.enableHostInfo = true;
          // console.log("The string ends with gmail.com");
          this.emailConfigForm.controls['emailPort'].setValue('465');
      } else {
        this.enableHostInfo = false;
          // console.log("The string does not end with gmail.com");
      }
    }

    sendTestEmailByOrgCode(){      
      this.sendTestEmailSubmitted = true;
      if(this.toEmail.status === "VALID"){
      this.isTestEmailLoading = true;
      this.errorLog_popupMessage = '';
      // console.log('toEmail', this.toEmail.value);
      // return;
      // this.emailTemplateService.common.progressLoader = true;
      this.emailTemplateService.sendTestEmailByOrgCode(this.orgCode, this.toEmail.value).subscribe((res: any) => {
        this.sendTestEmailSubmitted = false;
        // console.log('sendTestEmailByOrgCode', res);
        // this.emailTemplateService.common.progressLoader = false;
        this.isTestEmailLoading = false;
        // console.log('getEmailSettingByOrgCode', res); 
        if(res.responsecode === 200){
          this.emailTemplateService.common.successMessage = true;
          this.emailTemplateService.common.message = res.message;  
        } else if(res.responsecode === 400) {
          this.errorLog_popupMessage = res.message; 
          this.dialogRef = this.dialog.open(this.errorLog_popup, {
            disableClose: true,
            width: '700px'
          });
          this.dialogRef.afterClosed().subscribe(result => { 
            if (result != undefined) {
              this.errorLog_popupMessage = ''; 
            }
          });
        } else {
          this.emailTemplateService.common.errorMessage = true;
          this.emailTemplateService.common.message = res.message;
        }
      });
    }
    }

    closePopup(){
      this.dialogRef.close(true);
    }

}
