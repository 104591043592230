import { ChatService } from "./../../dashboards/providers-dashboard/chat/chat.service";
import { Component, OnInit, Output } from "@angular/core";
import { CommonService } from "src/app/shared/common.service";
import { ChatComponent } from "src/app/dashboards/providers-dashboard/chat/chat.component";
import { MatDialog } from "@angular/material/dialog";
import { SessionStorageService } from "angular-web-storage";
import { ActivatedRoute, Router } from '@angular/router';
import { IBtnControlsPermission } from "src/app/shared/interface/core.interface";

@Component({
  selector: "app-layout2",
  templateUrl: "./layout2.component.html",
  styleUrls: ["./layout2.component.scss"]
})
export class Layout2Component implements OnInit {
  toggleMenu = false;
  isMenuOpen = true;
  isMobileMenuOpen = false;
  userType;
  isWebAppPermission: false;
  controlPermission:IBtnControlsPermission;
  
  constructor(
    public router: Router,
    public common: CommonService,
    public dialog: MatDialog,
    public session: SessionStorageService,
    public service: ChatService,
    private route: ActivatedRoute,   
  ) {
  }

  ngOnInit() {    
    this.getChatUnreadCount();    
    this.isWebAppPermission = this.session.get('MenuListDataAndPagePermission').isWebApp;
    this.userType = this.session.get("user_type");
    this.controlPermission =  this.common.getBtnEventPermissions('Chat');
  }

  ngAfterViewInit(): void {
    //this.getQueryParam();
  }

  closeSuccess() {
    this.common.successMessage = false;
  }

  closeError() {
    this.common.errorMessage = false;
  }

  toggleSidebarMenu(){
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleSidebarMenuMobile(){
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  openMenu() {
    if (this.toggleMenu == false) {
      this.toggleMenu = true;
    } else {
      this.toggleMenu = false;
    }
  }

  // getQueryParam(){
  //   this.route.queryParams
  //   .subscribe(params => {
  //     if(params && params.chat && params.chat === 'true'){
  //         this.showChat();
  //         setTimeout(() => { 
  //           let url = this.router.url.split('?');
  //           //console.log('ActRoute_2: ', url);
  //           // return;
  //           if(url && url[1]){
  //             this.router.navigate([url[0]]);
  //             } else {
  //               this.router.navigate([url[0]], { queryParams: {chat:null }});
  //             }
  //         }, 300);
  //     }
  //     else {
         
  //     }
  //    }
  // );
  // }

  /* Name:Shailesh
      use: to open chat box */
  showChat(): void {
    this.service.common.progressLoader = true;

    // //console.log('UserType', this.userType);

    if (this.userType == "Health Technician") {
      try {
      this.service.adminGetAllGroupByUserCode(
        this.service.common.local.get("userCode"),
        response => {
          this.service.common.progressLoader = false;
          //console.log(response, "chat!!!");
          this.getChatData(response);          
        }
      );
    } catch (error) {
      this.service.common.progressLoader = false;
      //console.log(error);
    }
    } else {
      try {
        //console.log('UserType', this.userType, this.service.common.local.get("orgCode"), this.service.common.local.get("userCode"))
        this.service.adminGetAllGroupByOrganizationCode(
          this.service.common.local.get("orgCode"),
          this.service.common.local.get("userCode"),
          response => {
            //console.log('ResChat', response)
            this.service.common.progressLoader = false;
            this.getChatData(response);
          }
        );
      } catch (error) {
        this.service.common.progressLoader = false;
        //console.log(error);
      }
    }
  }

  getChatData(response) {
    ////console.log(response);
    // this.service.common.progressLoader = false;
    const dialogRef = this.dialog.open(ChatComponent, {
      width: "66%",
      panelClass: 'chat-popup',
      data: { arrayList: response }
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (this.common.globalChatCount != 0) {
        // this.common.getGlobalCountChat();
      // }
    });
  }

  getChatUnreadCount(){
    this.common.getGlobalCountChat();
  }
  
}
