<mat-horizontal-stepper linear #stepper class="custom-stepper" (selectionChange)="onStepChange($event)">
  <mat-step>

    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="welcomeActive?'completed-step':''">
        <div class="image-step">
          <img src="assets/welcome.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Welcome</p>
      </div>
    </ng-template>

    <div *ngIf="service.storage.get('orgStatus') == 'Pending' ">
      <p class="approval-text-head">Your account is under approval process.</p>
      <p class="approval-text">If it is taking time, please make sure you have provided all the required information and
        uploaded mandatory documents.</p>
    </div>

    <div *ngIf="service.storage.get('orgStatus') == 'Approved'">
      <p class="approval-text-head">Congratulations! Your account is approved.</p>
      <p class="approval-text">Now please update the organization's settings and create the mandatory masters using the
        following links to activate your account.</p>
    </div>
    <div class="text-end mt-4">
      <button mat-flat-button matStepperNext color="primary">Next</button>
    </div>

  </mat-step>
  <mat-step [stepControl]="uploadForm" [optional]="isUploadOptional">
    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="uploadActive?'completed-step':''">
        <div class="image-step">
          <img src="assets/upload_document.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Upload Documents</p>
      </div>
    </ng-template>
    <div class="row">
      <div class="col-md-6 header-name">

      </div>
      <div class="col-md-6 text-end float-end mb-2">
        <button mat-flat-button class="primary-btn" (click)="vieworgNotes()">{{
          'registration.provider.labels.View / Write Note to Admin' | translate }} <span class="note-org-dot"
            *ngIf="orgReadCount > 0">{{orgReadCount}}</span></button>

      </div>
    </div>

    <form [formGroup]="uploadForm" *ngIf="resetUpload" autocomplete="off">
      <div class="row">
        <div class="col-md-2 pe-0 ">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
              <mat-label>{{'Technicians.labels.Document Name' | translate }}</mat-label>
              <input matInput type="text" tabindex="-1" maxlength="100" class="form-control"
                formControlName="documentName" [(ngModel)]="uploadService.uploadData.documentName">
            </mat-form-field>
            <span class="help-block" *ngIf="uploadForm && uploadForm.controls.documentName.errors?.required &&
              (!uploadSubmitted || uploadForm.controls.documentName.touched)">{{
              'Technicians.Validators.Document name is required' |
              translate }}.</span>

            <span class="help-block" *ngIf="uploadForm && uploadForm.get('documentName').errors?.maxlength &&
            (!uploadSubmitted || uploadForm.get('documentName').touched)">
              {{ 'registration.provider.validators.Document name maxlength is 100' | translate }}
            </span>

          </div>
        </div>
        <div class="col-md-2 pe-0">
          <div class="form-group datepick">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>{{ 'Technicians.labels.Expiry Date' | translate }}</mat-label>
              <input [min]="today" [(ngModel)]="uploadService.uploadData.expiryDate" class="form-control" matInput
                formControlName="expiryDate" [matDatepicker]="picker2" (focus)="picker2.open()" placeholder="">
              <mat-datepicker-toggle matSuffix [for]="picker2" class="date-icon"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <span class="help-block"
              *ngIf="uploadForm && uploadForm.get('expiryDate').errors?.matDatepickerMin && ( !uploadSubmitted || uploadForm.get('expiryDate').touched)">
              {{ 'Jobs.Create Appointment.Validators.Date should be from todays date' | translate }}.</span>
          </div>
        </div>

        <div class="col-md-2 ">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
              <mat-label>{{'report.labels.Notes' | translate }}</mat-label>
              <input matInput type="text" maxlength="500" class="form-control" formControlName="notes"
                [(ngModel)]="uploadService.uploadData.note">
            </mat-form-field>
            <span class="help-block" *ngIf="uploadForm && uploadForm.get('notes').errors?.maxlength &&
            (!uploadSubmitted || uploadForm.get('notes').touched)">
              {{ 'registration.provider.validators.Notes maxlength is 500' | translate }}
            </span>
          </div>
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-start">

          <div class="certificate-name">
            {{uploadService.uploadData.uploadPath}}
          </div>
          <a class="btn-cert" mat-raised-button color="gray">{{'Technicians.labels.Browse' | translate }} <input
              formControlName="fileName" class="certificate-img" type="file" enctype="multipart/form-data"
              (change)="onCertificateUpload($event)" #fileInput></a>
          <div class="upload-loader pe-2 ms-4" *ngIf="showLoader">
            <mat-spinner diameter="40" class="mt-2"></mat-spinner>
          </div>

        </div>
        <div class="col-md-2 d-flex align-items-center justify-content-end ">
          <div class="form-group m-0 mt-2">
            <button type="submit" class="primary-btn me-2" [disabled]="!uploadButton" (click)="onSubmitUpload()"
              mat-flat-button>{{'Technicians.labels.Upload' | translate }}</button>

            <button type="submit" class="me-1" (click)="cancelUpload()" mat-flat-button>{{'common.labels.Cancel' |
              translate }} </button>
          </div>
        </div>

      </div>
    </form>
    <hr>

    <div class="row">
      <div class="col-md-12 table-scroll-height">

        <table mat-table [dataSource]="dataSourceUpload" class="mat-elevation-z8 patient-table document-table">
          <ng-container matColumnDef="documentName">
            <th width="400" mat-header-cell *matHeaderCellDef>{{'Technicians.labels.Document Name'
              |
              translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div *ngIf="!element.documentName">NA </div>
                <div *ngIf="element.documentName">{{element.documentName}} </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="documentStatus">
            <th mat-header-cell *matHeaderCellDef>{{'report.labels.Status' |
              translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div *ngIf="!element.documentStatus">NA </div>
                <div *ngIf="element.documentStatus" [ngClass]="[element.documentStatus == 'Accepted'?'text-green':'text-black',element.documentStatus == 'Rejected'?'text-red':'text-black']">
                  {{element.documentStatus}} </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="expiryDate">
            <th mat-header-cell *matHeaderCellDef>{{'Technicians.labels.Expiry Date' |
              translate }} </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div *ngIf="!element.expiryDate">NA </div>
                <div>{{element.expiryDate}} </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="rejectionComment">
            <th mat-header-cell *matHeaderCellDef>{{'Technicians.labels.Rejection Comment' |
              translate }} </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.rejectionComment}} </div>
              </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th width="200" mat-header-cell *matHeaderCellDef class="action">{{'skills.labels.Actions' |
              translate }}</th>
            <td mat-cell *matCellDef="let element" class="action">
              <mat-icon title="Edit" class="color-icon me-2" (click)="geteditData(element)"
                class="cursor-pointer color-icon me-2">create</mat-icon>
              <mat-icon title="Delete" (click)="deleteUpload(element.organizationDocumentCode)"
                class="cursor-pointer color-icon me-2">delete</mat-icon>


              <a class="downloadFile" [href]="this.service.common.superAdminUrl + element.uploadPath" download>
                <mat-icon title="Download" class="cursor-pointer color-icon me-2">
                  save_alt</mat-icon>
              </a>

              <mat-icon title="Notes {{element.unreadCommentCount}}" class="color-icon me-2"
                (click)="viewDocNotes(element.organizationDocumentCode)" class="cursor-pointer color-icon me-2">
                description <span class="note-dot" *ngIf="element.unreadCommentCount > 0"></span></mat-icon>


            </td>

          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedUploadColumns; sticky:
            true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedUploadColumns;"></tr>
        </table>

        <div *ngIf="allDocuments.length == 0 " class="mt-3">{{'Technicians.messages.Documents are not available' |
          translate
          }}</div>
      </div>
    </div>

    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onUploadScroll()"></div>
    <div class="text-end mt-4">
      <button mat-flat-button matStepperPrevious>Back</button>
      <button mat-flat-button matStepperNext color="primary" (click)="getNextStep(2)">Next</button>
    </div>

  </mat-step>
  <mat-step [stepControl]="setting" [optional]="isSettingOptional">

    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="workingActive?'completed-step':''">

        <div class="image-step">
          <img src="assets/working_hours.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Working Hours</p>
      </div>
    </ng-template>
    <form [formGroup]="setting" (ngSubmit)="onSubmitWorkingHours()" class="settings">

      <div class="form-group">
        <div class="orange-title float-start">
          {{ 'setting.labels.Office Days' | translate}}
        </div>
        <div class="float-end">
          <button mat-flat-button color="primary">Update Settings</button>
        </div>
        <div class="clearfix"></div>
        <div class="days">
          <mat-checkbox color="primary" formControlName="offmon" (change)="OfficeDaysValid($event, 'mon')"
            *ngIf="service.settings && service.settings[11].globalSettingName == 'office.days.mon'" class="day-name"
            [ngModel]="(service.settings[11].localSettingValue != null?service.settings[11].localSettingValue:service.settings[11].globalSettingValue)==='true'">
            Mon</mat-checkbox>
          <mat-checkbox color="primary" formControlName="offtue" (change)="OfficeDaysValid($event, 'tue')"
            *ngIf="service.settings && service.settings[12].globalSettingName == 'office.days.tue'" class="day-name"
            [ngModel]="(service.settings[12].localSettingValue != null?service.settings[12].localSettingValue:service.settings[12].globalSettingValue)==='true'">
            Tue</mat-checkbox>
          <mat-checkbox color="primary" formControlName="offwed" (change)="OfficeDaysValid($event, 'wed')"
            *ngIf="service.settings && service.settings[13].globalSettingName == 'office.days.wed'" class="day-name"
            [ngModel]="(service.settings[13].localSettingValue != null?service.settings[13].localSettingValue:service.settings[13].globalSettingValue)==='true'">
            Wed</mat-checkbox>
          <mat-checkbox color="primary" formControlName="offthu" (change)="OfficeDaysValid($event, 'thu')"
            *ngIf="service.settings && service.settings[14].globalSettingName == 'office.days.thu'" class="day-name"
            [ngModel]="(service.settings[14].localSettingValue != null?service.settings[14].localSettingValue:service.settings[14].globalSettingValue)==='true'">
            Thu</mat-checkbox>
          <mat-checkbox color="primary" formControlName="offfri" (change)="OfficeDaysValid($event, 'fri')"
            *ngIf="service.settings && service.settings[15].globalSettingName == 'office.days.fri'" class="day-name"
            [ngModel]="(service.settings[15].localSettingValue != null?service.settings[15].localSettingValue:service.settings[15].globalSettingValue)==='true'">
            Fri</mat-checkbox>
          <mat-checkbox color="primary" formControlName="offsat" (change)="OfficeDaysValid($event, 'sat')"
            *ngIf="service.settings && service.settings[16].globalSettingName == 'office.days.sat'" class="day-name"
            [ngModel]="(service.settings[16].localSettingValue != null?service.settings[16].localSettingValue:service.settings[16].globalSettingValue)==='true'">
            Sat</mat-checkbox>
          <mat-checkbox color="primary" formControlName="offsun" (change)="OfficeDaysValid($event, 'sun')"
            *ngIf="service.settings && service.settings[17].globalSettingName == 'office.days.sun'" class="day-name"
            [ngModel]="(service.settings[17].localSettingValue != null?service.settings[17].localSettingValue:service.settings[17].globalSettingValue)==='true'">
            Sun</mat-checkbox>
        </div>

        <span class="help-block"
          *ngIf="setting.get('officeDays').errors?.required && (!submittedSet || setting.get('officeDays').touched)"> {{
          'setting.validators.Please select atleast one Office day' | translate}}.
        </span>

      </div>


      <div class="form-group">
        <div class="orange-title">
          {{ 'setting.labels.Office Time' | translate}}
        </div>
        <div class="time-fields clearfix">
          <div class="form-group col-md-4 ps-0 float-start">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" *ngIf="service.settings">
              <mat-label>{{ 'setting.labels.Start Time' | translate}}</mat-label>
              <mat-select placeholder="HH:MM" formControlName="office_starttime"
                *ngIf="service.settings && service.settings[18].globalSettingName == 'officeTime.StartTime'"
                [(ngModel)]="service.settings[18].localSettingValue != null?service.settings[18].localSettingValue:service.settings[18].globalSettingValue"
                (openedChange)="myInput.focus()" (selectionChange)="timeChangeOffice($event, 'start')">
                <input class="myInput" #myInput matInput focused="'true'" type="text"
                  (keyup)="searchStart($event.target.value,'office')" (keydown)="$event.stopPropagation()"
                  autocomplete="off" placeholder="Search">
                <mat-option> {{ 'setting.labels.Please Select' | translate}}</mat-option>
                <mat-option *ngFor="let intervals of officeStartTime" [value]="intervals.value">
                  {{intervals.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="help-block"
              *ngIf="!setting.get('office_starttime').valid && (!submittedSet || setting.get('office_starttime').touched)">
              {{ 'setting.validators.Start time is required' | translate}}</span>
          </div>
          <div class="form-group col-md-4 ps-0 float-start">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" *ngIf="service.settings">
              <mat-label>{{ 'setting.labels.End Time' | translate}}</mat-label>
              <mat-select placeholder="HH:MM" formControlName="office_endtime"
                *ngIf="service.settings && service.settings[19].globalSettingName == 'officeTime.EndTime'"
                [(ngModel)]="service.settings[19].localSettingValue != null?service.settings[19].localSettingValue:service.settings[19].globalSettingValue"
                (openedChange)="myInput.focus()" (selectionChange)="timeChangeOffice($event, 'end')">
                <input class="myInput" #myInput matInput focused="'true'" type="text"
                  (keyup)="searchEndTime($event.target.value,'office')" (keydown)="$event.stopPropagation()"
                  autocomplete="off" placeholder="Search">
                <mat-option> {{ 'setting.labels.Please Select' | translate}}</mat-option>
                <mat-option *ngFor="let intervals of officeEndTime" [value]="intervals.value">
                  {{intervals.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="help-block"
              *ngIf="!setting.get('office_endtime').valid && (!submittedSet || setting.get('office_endtime').touched)">
              {{ 'setting.validators.End time is required' | translate}}</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="orange-title">
          {{ 'setting.labels.Operational Days' | translate}}
        </div>
        <div class="days">

          <mat-checkbox color="primary" formControlName="mon" (change)="OperationalDaysValid($event)" [disabled]="mon"
            *ngIf="service.settings && service.settings[0].globalSettingName == 'Working.Days.Mon'" class="day-name"
            [ngModel]="(service.settings[0].localSettingValue != null?service.settings[0].localSettingValue:service.settings[0].globalSettingValue)==='true'">
            Mon</mat-checkbox>
          <mat-checkbox color="primary" formControlName="tue" (change)="OperationalDaysValid($event)" [disabled]="tue"
            *ngIf="service.settings && service.settings[1].globalSettingName == 'Working.Days.Tue'" class="day-name"
            [ngModel]="(service.settings[1].localSettingValue != null?service.settings[1].localSettingValue:service.settings[1].globalSettingValue)==='true'">
            Tue</mat-checkbox>
          <mat-checkbox color="primary" formControlName="wed" (change)="OperationalDaysValid($event)" [disabled]="wed"
            *ngIf="service.settings && service.settings[2].globalSettingName == 'Working.Days.Wed'" class="day-name"
            [ngModel]="(service.settings[2].localSettingValue != null?service.settings[2].localSettingValue:service.settings[2].globalSettingValue)==='true'">
            Wed</mat-checkbox>
          <mat-checkbox color="primary" formControlName="thu" (change)="OperationalDaysValid($event)" [disabled]="thu"
            *ngIf="service.settings && service.settings[3].globalSettingName == 'Working.Days.thu'" class="day-name"
            [ngModel]="(service.settings[3].localSettingValue != null?service.settings[3].localSettingValue:service.settings[3].globalSettingValue)==='true'">
            Thu</mat-checkbox>
          <mat-checkbox color="primary" formControlName="fri" (change)="OperationalDaysValid($event)" [disabled]="fri"
            *ngIf="service.settings && service.settings[4].globalSettingName == 'Working.Days.Fri'" class="day-name"
            [ngModel]="(service.settings[4].localSettingValue != null?service.settings[4].localSettingValue:service.settings[4].globalSettingValue)==='true'">
            Fri</mat-checkbox>
          <mat-checkbox color="primary" formControlName="sat" (change)="OperationalDaysValid($event)" [disabled]="sat"
            *ngIf="service.settings && service.settings[5].globalSettingName == 'Working.Days.Sat'" class="day-name"
            [ngModel]="(service.settings[5].localSettingValue != null?service.settings[5].localSettingValue:service.settings[5].globalSettingValue)==='true'">
            Sat</mat-checkbox>
          <mat-checkbox color="primary" formControlName="sun" (change)="OperationalDaysValid($event)" [disabled]="sun"
            *ngIf="service.settings && service.settings[6].globalSettingName == 'Working.Days.Sun'" class="day-name"
            [ngModel]="(service.settings[6].localSettingValue != null?service.settings[6].localSettingValue:service.settings[6].globalSettingValue)==='true'">
            Sun</mat-checkbox>
        </div>
        <span class="help-block"
          *ngIf="setting.get('operationalDays').errors?.required && (!submittedSet || setting.get('operationalDays').touched)">{{
          'setting.validators.Please select atleast one Operational day' | translate}} .
        </span>
      </div>

      <div class="form-group">
        <div class="orange-title">
          {{ 'setting.labels.Operational Time' | translate}}
        </div>
        <div class="time-fields clearfix">
          <div class="form-group col-md-4 ps-0 float-start">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" *ngIf="service.settings">
              <mat-label>{{ 'setting.labels.Start Time' | translate}}</mat-label>
              <mat-select placeholder="HH:MM" formControlName="user_starttime"
                *ngIf="service.settings && service.settings[7].globalSettingName == 'WorkingTime.StartTime'"
                [(ngModel)]="service.settings[7].localSettingValue != null?service.settings[7].localSettingValue:service.settings[7].globalSettingValue"
                (openedChange)="myInput.focus();" (selectionChange)="timeChange($event, 'start')">
                <input class="myInput" #myInput matInput focused="'true'" type="text"
                  (keyup)="searchStart($event.target.value,'operational')" (keydown)="$event.stopPropagation()"
                  autocomplete="off" placeholder="Search">
                <mat-option> {{ 'setting.labels.Please Select' | translate}}</mat-option>
                <mat-option *ngFor="let intervals of selectedStartTime" [value]="intervals.value">
                  {{intervals.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="help-block"
              *ngIf="!setting.get('user_starttime').valid && (!submittedSet || setting.get('user_starttime').touched)">
              {{ 'setting.validators.Start time is required' | translate}}
            </span>
            <span class="help-block mt-2" *ngIf="timeMsgStart">{{timeMsgStart}}</span>
          </div>
          <div class="form-group col-md-4 ps-0 float-start">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" *ngIf="service.settings">
              <mat-label>{{ 'setting.labels.End Time' | translate}}</mat-label>
              <mat-select placeholder="HH:MM" formControlName="user_endtime"
                *ngIf="service.settings && service.settings[8].globalSettingName == 'WorkingTime.EndTime'"
                [(ngModel)]="service.settings[8].localSettingValue != null?service.settings[8].localSettingValue:service.settings[8].globalSettingValue"
                (openedChange)="myInput.focus();" (selectionChange)="timeChange($event, 'end')">
                <input class="myInput" #myInput matInput focused="'true'" type="text"
                  (keyup)="searchEndTime($event.target.value,'operational')" (keydown)="$event.stopPropagation()"
                  autocomplete="off" placeholder="Search">
                <mat-option> {{ 'setting.labels.Please Select' | translate}}</mat-option>
                <mat-option *ngFor="let intervals of selectedEndTime" [value]="intervals.value">
                  {{intervals.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="help-block"
              *ngIf="!setting.get('user_endtime').valid && (!submittedSet || setting.get('user_endtime').touched)"> {{
              'setting.validators.End time is required' | translate}}</span>
            <span class="help-block mt-2" *ngIf="timeMsgEnd">{{timeMsgEnd}}</span>
          </div>



        </div>
      </div>

      <div class="form-group">
        <div class="orange-title">
          {{ 'setting.labels.Specific Non Working Days' | translate}}
        </div>
        <div class="form-group col-md-4 p-0">
          <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
            <mat-label>{{ 'setting.labels.Select Date' | translate}}</mat-label>
            <input matInput [min]="todayDate" #nonworkingday (dateChange)="nonWorkingDays($event)"
              formControlName="workingDays" [matDatepicker]="picker" (focus)="picker.open()"
              placeholder="{{ 'setting.labels.Choose a date' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker (closed)="nonworkingday.blur()"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-chip-list
            *ngIf="service.settings && service.settings[10].globalSettingName == 'Specific.Non.Working.Days' && service.settings[10].localSettingValue == null"
            formControlName="nonworking_days" [(ngModel)]="service.settings[10].globalSettingValue">
            <mat-chip *ngFor="let date of service.settings[10].globalSettingValue.split(','); let i=index">
              <span>{{date}}</span> <a (click)="onRemoveChip(i)">
                <mat-icon>close</mat-icon>
              </a></mat-chip>
          </mat-chip-list>
          <mat-chip-list
            *ngIf="service.settings && service.settings[10].globalSettingName == 'Specific.Non.Working.Days' && service.settings[10].localSettingValue != null"
            formControlName="nonworking_days2" [(ngModel)]="service.settings[10].localSettingValue">
            <mat-chip *ngFor="let date of service.settings[10].localSettingValue.split(','); let i=index">
              <span>{{date}}</span> <a (click)="onRemoveChip(i)">
                <mat-icon>close</mat-icon>
              </a></mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </form>
    <div class="text-end mt-4">
      <button mat-flat-button matStepperPrevious>Back</button>
      <button mat-flat-button matStepperNext color="primary">Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="skillsForm" [optional]="isSkillOptional">
    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="skillActive?'completed-step':''">

        <div class="image-step">
          <img src="assets/skill.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Skill</p>
      </div>
    </ng-template>

    <form [formGroup]="skillsForm" (ngSubmit)="onSkillSubmit()" *ngIf="resetSkill">
      <div class="row">
        <div class="form-group col-md-5 col-sm-12">
          <mat-form-field appearance="outline" floatLabel="always" >
            <input autoClose matInput [matAutocomplete]="auto" formControlName="name" type="text" placeholder="">
            <mat-label>Skills Name</mat-label>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <span class="help-block err" *ngIf="skillsForm.controls.name.errors?.required &&
            (!submittedSkill || skillsForm.controls.name.touched)">{{
            'skills.validators.Skill name is required' | translate }}</span>
          <span class="help-block err" *ngIf="skillsForm.controls.skillcode.errors?.required &&
            !skillsForm.controls.name .errors?.required && (!submittedSkill)">{{
            'skills.validators.Please select from the list' | translate }}</span>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6 header-name">
        </div>
        <div class="col-md-6 text-end mb-4">
          <div>
            <button type="submit" class="primary-btn" mat-flat-button>Save Changes</button>
          </div>
          <div>
            <button type="button" class="me-4" (click)="cancelForm()" mat-flat-button>Cancel </button>
          </div>
        </div>
      </div>
    </form>
    <hr />
    <!-- <div class="col-md-12 col-sm-12 text-end mt-3 mb-3">

          <button type="submit" class="save-btn me-3" (click)="onSkillSubmit()"
            mat-raised-button>{{ 'skills.labels.Save' | translate }} </button>
          <button type="submit" class="save-btn" (click)="cancelForm()"
            mat-raised-button>Cancel </button>
        </div> -->
    <div class="row">
      <div class="col-md-12 table-scroll-height">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 skill-table mt-4">

          <ng-container matColumnDef="skillName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'skills.labels.Skills
              Name' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.skillName}} </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-end action">Actions</th>
            <td mat-cell *matCellDef="let element" class="text-end action">
              <mat-icon class="color-icon" class="cursor-pointer" (click)="onEdit(element)">edit</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="skillList.length == 0 || dataSource.filteredData.length ==
          0" class="mt-3 ms-3">{{'skills.messages.Skills are not available' | translate
          }}</div>
      </div>
    </div>


    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onSkillScroll()"></div>
    <div class="text-end mt-4">
      <button mat-flat-button matStepperPrevious>Back</button>
      <button mat-flat-button matStepperNext color="primary">Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="procedureForm" [optional]="isProcedureOptional">
    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="procedureActive?'completed-step':''">
        <div class="image-step">
          <img src="assets/procedure.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Procedure</p>
      </div>
    </ng-template>
    <form [formGroup]="procedureForm" (ngSubmit)="onSubmitProcedure()" class="procedure-form custom-form"
      *ngIf="resetProcedure">
      <div class="row">
        <div class="col-md-2 d-flex logo
          align-items-center justify-content-center">
          <div class="p-2 profile-box mb-4">
            <div class="img-proc">
              <img alt="No Image" class="img-proc" *ngIf="imageuploadProcedure" [src]="fileUrlProcedure" #imageUploaded
                class="profile-image avatar huge" />
              <img alt="No Image" title="No Image" class="img-proc" *ngIf="!imageuploadProcedure"
                src="/assets/common_images/profile_3.png" class="profile-image avatar huge" />
            </div>
            <input type="file" enctype="multipart/form-data" (change)="onFileChangeProcedure($event)" #fileInput
              style="display: none">
            <i class="material-icons logo-icon pointer" (click)="fileInput.click()">
              edit
            </i>
            <span class="help-block" *ngIf="!procedureForm.get('procedureIcon').valid &&
              (!submittedProcedure ||
              procedureForm.get('procedureIcon').touched)">{{
              'JobType.validators.Procedure icon is required' | translate }}</span>
          </div>
        </div>
        <div class="col-md-10">
          <div class="row">

            <div class="col-md-6 col-sm-12">
              <div class="form-group pb-0 mb-0">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'JobType.labels.Procedure Type' | translate }}</mat-label>
                  <mat-select formControlName="procedureName" (selectionChange)="getProcedureData($event)"
                    [(ngModel)]="service.procedureForm.procedureCode" class="select-dura">
                    <input class="myInput" #myInput matInput focused="'true'" type="text"
                      (keyup)="searchprocedure($event.target.value)" (keydown)="$event.stopPropagation()"
                      autocomplete="off" placeholder="Search">

                    <mat-option *ngFor="let food of selectedProcedure" [value]="food.procedureCode">
                      {{food.procedureName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <span class="help-block err" *ngIf="!procedureForm.get('procedureName').valid &&
                  (!submittedProcedure ||
                  procedureForm.get('procedureName').touched)">{{
                  'JobType.validators.Job Type is required' | translate
                  }}</span>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="form-group pb-0 mb-0">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'JobType.labels.Job Name' | translate }}</mat-label>
                  <input autoClose matInput placeholder="" type="text" class="form-control" formControlName="name"
                    [(ngModel)]="service.procedureForm.procedureName">
                </mat-form-field>

                <span class="help-block err" *ngIf="!procedureForm.get('name').valid &&
                  (!submittedProcedure ||
                  procedureForm.get('name').touched)">{{
                  'JobType.validators.Job name is required' | translate
                  }}</span>
              </div>
            </div>




            <div class="form-group col-md-6 col-sm-12">
              <mat-chip-list formControlName="selctedskills" class="d-inline-block
                mb-2">
                <mat-chip class="text-capitalize" *ngFor="let org of service.procedureForm.skillMasterList;let
                  i=
                  index">{{org.skillName}}
                  &nbsp;<a (click)="removeOrg(i)">
                    <mat-icon>close</mat-icon>
                  </a></mat-chip>
              </mat-chip-list>
              <mat-form-field appearance="outline" floatLabel="always"  class="skills-add">
                <mat-label>Skills</mat-label>
                <input autoClose matInput [matAutocomplete]="auto" formControlName="skills" type="text" placeholder=""
                  class="form-control">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)="selectedSkill($event)">
                  <mat-option *ngFor="let option of filteredOptionsProcedure |
                    async" [value]="option">
                    {{option.skillName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <span class="help-block err" *ngIf="procedureForm.controls.selctedskills.errors?.required &&
                (!submittedProcedure ||
                procedureForm.controls.selctedskills.touched)">{{
                'skills.validators.Skill name is required' | translate }}</span>
            </div>


          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 header-name">
        </div>
        <div class="col-md-6 text-end">
          <div>
            <button mat-flat-button type="submit" color="primary" type="submit">Save Changes</button>
          </div>
          <div>
            <button type="button" class="me-4" (click)="resetFormDataProcedure()" mat-flat-button>Cancel </button>
          </div>
        </div>
      </div>
    </form>
    <hr />
    <div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
      <table mat-table [dataSource]="dataSourceProcedure" matSort
        class="mat-elevation-z8 table-responsiv procedure-table">

        <ng-container matColumnDef="procedureIcon" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef width="140"> {{
            'main.labels.Procedures' | translate }} </th>
          <td mat-cell *matCellDef="let element" class="common-icon-round text-nowrap">
            <img alt="No Image" class="td_avatar_img" *ngIf="element.procedureIcon" [src]="service.service.getSuperAdmin() + '/downloadFile/' +
              element.procedureIcon" [title]="element.procedureIcon" />
            <img alt="No Image" class="td_avatar_img" *ngIf="!element.procedureIcon"
              src="../../../../../assets/common_images/profile.jpg" />
          </td>
        </ng-container>

        <ng-container matColumnDef="procedureName" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
            'JobType.labels.Job Name' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.procedureName}}
          </td>
        </ng-container>

        <ng-container matColumnDef="procedureId" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
            'JobType.labels.Job ID' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            {{element.procedureId}} </td>
        </ng-container>

        <ng-container matColumnDef="procedureDescription" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
            'JobType.labels.Job Description' | translate }} </th>
          <td mat-cell *matCellDef="let element"> <span>{{element.procedureDescription}}
            </span></td>
        </ng-container>

        <ng-container matColumnDef="durationData" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
            'JobType.labels.Job Duration' | translate }} </th>
          <td mat-cell *matCellDef="let element"> <span *ngIf="element.durationData">{{element.durationData}}
            </span><span *ngIf="!element.durationData"> -- </span></td>
        </ng-container>

        <ng-container matColumnDef="edit" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef class="action"> </th>
          <td mat-cell *matCellDef="let element" class="action">
            <i class="material-icons pointer"
              (click)="editProcedureData(element.organizationBaseProcedureCode)">edit</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsProcedure; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProcedure;"></tr>
      </table>
      <div *ngIf="procedureList.length == 0 ||
        dataSourceProcedure.filteredData.length == 0" class="ms-3 mt-3">{{
        'JobType.messages.Job data not available' | translate }}</div>
    </div>
    
    <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>

    <div class="text-end mt-4">
      <button mat-flat-button matStepperPrevious>Back</button>
      <button mat-flat-button color="primary" matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>

    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="patientActive?'completed-step':''">
        <div class="image-step">
          <img src="assets/patint.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Customer</p>
      </div>
    </ng-template>
    <div class="d-flex flex-row justify-content-between w-100 mb-2 logo
        align-items-start">
      <form class="create-position d-flex align-items-center" *ngIf="resetPat" [formGroup]="uploadPatient"
        (ngSubmit)="onPatSubmit()">
        <div class="form-group fileUpload d-inline-block">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>Upload File</mat-label>
            <input type="text" matInput placeholder="" formControlName="uploadFile">
            <input type="file" enctype="multipart/form-data" (change)="onFileChange($event)" #fileInput style="display:
                none">
            <i class="material-icons float-end logo-icon curser-pointer" title="Upload file"
              (click)="fileInput.click()">
              insert_photo
            </i>
          </mat-form-field>
          <mat-error class="err" *ngIf="!uploadPatient.get('uploadFile').valid
              && (!submittedPat ||
              uploadPatient.get('uploadFile').touched)">Please upload file</mat-error>
        </div>
        <button mat-flat-button color="primary" class="">Submit</button>
        <a class="cursor-pointer" title="Download Sample" href="{{url}}">Download
          Sample File</a>
      </form>
      <div class="col-lg-4 col-md-4 col-sm-12 searchpat pe-0">
        <input type="text" class="form-control" id="search-patient" autocomplete="off"
          placeholder="{{ 'Customers.labels.Search Customer' | translate}}" name="search-patient"
          [(ngModel)]="searchTerm" (keyup)="search()">
        <mat-icon class="cursor-pointer">search</mat-icon>
      </div>
    </div>
    <div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
      <table mat-table [dataSource]="dataSourcePat" matSort class="mat-elevation-z8 patient-table">

        <ng-container matColumnDef="patientName" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Customers.labels.Name' | translate}}
          </th>
          <td mat-cell *matCellDef="let element" class="common-icon-round">
            {{element.patientName}}
          </td>
        </ng-container>


        <ng-container matColumnDef="phoneNumber" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'common.labels.Phone No' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.phoneNumber">+{{element.phoneCountryCode}}
              {{element.phoneNumber}} </span>
            <span *ngIf="!element.phoneNumber">--</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="mobileNumber" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'common.labels.Mobile No' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.mobileNumber">+{{element.mobileCountryCode}}
              {{element.mobileNumber}}</span>
            <span *ngIf="!element.mobileNumber">--</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="patientAddress" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{
            'common.labels.Address' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <span class=""><span *ngIf="element.patientApartmentNo">{{element.patientApartmentNo+','}}</span>{{
              element.patientAddress+', '+element.patientCity+',
              '+element.patientState+' '+element.zipCode+',
              '+element.patientCountry}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsPat; sticky:
            true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsPat;"></tr>
      </table>

      <div *ngIf="patient.length == 0" class="mt-3 ms-3">{{
        'Customers.messages.Customer data not available' | translate}}</div>
    </div>

    <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>

    <div>
      <div class="text-end mt-4">
        <button mat-flat-button matStepperPrevious>Back</button>
        <button mat-flat-button color="primary" matStepperNext>Next</button>
      </div>
    </div>

  </mat-step>
  <mat-step>
    <ng-template matStepLabel>
      <div class="line-step-break" [ngClass]="doneActive?'completed-step':''">
        <div class="image-step">
          <img src="assets/done.png" class="tab-icon">
        </div>
        <div class="clearfix"></div>
        <p>Done</p>
      </div>
    </ng-template>
    <p class="approval-text-head">You have successfully configured the basic settings and masters.</p>
    <p class="approval-text">These settings can be update / changed any time as needed. Now you can go ahead and
      activate your organization to start the operations.</p>
    <div class="text-end">
      <button mat-flat-button matStepperPrevious>Back</button>
      <button mat-flat-button color="primary" matStepperNext (click)="getNextStep(7)">Activate</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>