import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
 import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
 
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    public http: HttpClient,
    public common: CommonService,
    public service: ApiRootService,
    public _session: SessionStorageService,
  ) { }
 

  
  /* Name:Shailesh
      use: for getting oraganization detail by org code and admin code */
  getOrganizationDetailByOrgCodeAndAdminCode(orgCode, userCode, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getOrganizationDetailByOrgCodeAndAdminCode/' + orgCode + '/' + userCode).subscribe((data) => {
      callback(data);
    })
  }
 

  /* Name:Shailesh
     use: for save upload documents by org code*/
  saveOrganizationDocument(formData, callback) {
    return this.http.post(this.service.getRegistrationUrl() + '/saveOrganizationDocument/', formData).subscribe((data) => {
      callback(data);
    })
  }  


  getAllRoleByOrgCode(orgCode:string)
  {  
    const url = this.service.getHealthCareUrl() + `/getAllRoleByOrganizationCodeAndCommitteeCodeAndRoleNameByPage/${orgCode}`;
    return this.http.get(url);
  }
  

  getAllRoleByOrgCodeByPage(orgCode, page, limit, callback) {  
    return this.http.get(this.service.getHealthCareUrl() + '/getAllRoleByOrganizationCodeAndCommitteeCodeAndRoleNameByPage/' + orgCode + '/' + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }

  getAllSystemRoleByOrgCode(orgCode: any) {
     const Url = this.service.getHealthCareUrl() + `/getAllSystemRoleByOrganizationCodeAndCommitteeCodeAndRoleName/${orgCode}`;
    return this.http.get(Url);
  }

  getAllPermissionList(systemRoleCode: string) {

   const URL = systemRoleCode ? `${this.service.getHealthCareUrl()}/getPagePermissionByRoleCode/${systemRoleCode}` : `${this.service.getHealthCareUrl()}/getPagePermissionByRoleCode`;
   
   return this.http.get(URL);

 }
 
 saveSystemRole(formData: any) {
  const SAVE_SYSTEM_ROLE_DETAILS_URL = `${this.service.getHealthCareUrl()}/v3/saveRoleMaster`;
    return this.http.post(SAVE_SYSTEM_ROLE_DETAILS_URL, formData);
}


saveSystemRoleProfile(request_payload: any) {
  const url = `${this.service.getHealthCareUrl()}/saveSystemRole`;
  return this.http.post(url, request_payload);
}


savePagePermissionDetailsByRole(formData: any) {
  const SAVE_ALL_PAGE_PERMISSIONS_DETAILS__BY_ROLE_URL = `${this.service.getHealthCareUrl()}/savePagePermissionRoleMapping`;

  return this.http.post(
    SAVE_ALL_PAGE_PERMISSIONS_DETAILS__BY_ROLE_URL,
    formData
  );
}


softDeleteBySystemRoleCode(systemRoleCode: any) {
  const SOFT_DELETE_ROLE_DETAILS_URL = `${this.service.getHealthCareUrl()}/softDeleteSystemRoleByRoleCode/${systemRoleCode}`;

  return this.http.delete(SOFT_DELETE_ROLE_DETAILS_URL);
}

}
