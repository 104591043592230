<div id="printData">
    <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">
                Time Card Report
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 text-end" *ngIf="dataSource.data.length !== 0">
                <button type="button" class="me-3 mt-0" mat-raised-button color="primary" (click)="export()">Excel</button>
                <button type="button" class="mt-0" mat-raised-button color="accent" (click)="printInvoiceReport()">  Print </button>
            </div>
        
    </div>
</div>

<form [formGroup]="checkInReportForm">
    <div class="row mt-4">
        <div class="col-md-4">
            <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                <mat-label>Start Date</mat-label>
                <input (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder="Choose a date"
                    [value]="startDate" (dateChange)="onStartDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                <mat-label>End Date</mat-label>
                <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder="Choose a date"
                    [value]="endDate" (dateChange)="onEndDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-4">
            <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" [formControl]="select" [(ngModel)]="searchText" placeholder="Search">
            </mat-form-field>
        </div>
        
    </div>
</form>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="table-scroll-height" id="time-card-report-dataTable" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1 position-relative">

        <ng-container matColumnDef="Staff_Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Staff</th>
            <td mat-cell *matCellDef="let element" class="text-nowrap">
                <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
            </td>
        </ng-container>

        <ng-container matColumnDef="Custome_Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Customer</th>
            <td mat-cell *matCellDef="let element" class="text-nowrap">
                <span class="ps-2">{{element.customerName | capitalize}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Job_Id">
            <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                Job ID
            </th>
            <td mat-cell *matCellDef="let element">{{element.jobId ? element.jobId : '--'}}</td>
        </ng-container>

        <ng-container matColumnDef="Job_Site">
            <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                {{ 'main.labels.Job Site' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{element.siteName ? element.siteName : '--'}}</td>
        </ng-container>

        <ng-container matColumnDef="Clock_In_Time">
            <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                Clock-In Time</th>
            <td mat-cell *matCellDef="let element">{{element.checkinDateTime ? element.checkinDateTime : '--'}}</td>
        </ng-container>

        <ng-container matColumnDef="Clock_Out_Time">
            <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                Clock-Out Time
            </th>
            <td mat-cell *matCellDef="let element">{{element.checkoutDateTime ? element.checkoutDateTime : '--'}}</td>
        </ng-container>

        <ng-container matColumnDef="Total_Time">
            <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                Total Time
            </th>
            <td mat-cell *matCellDef="let element">{{element.totalTime ? element.totalTime : '--'}}</td>
        </ng-container>

        <ng-container matColumnDef="Paid_Time">
            <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                Paid Time
            </th>
            <td mat-cell *matCellDef="let element">{{element.paidTime ? element.paidTime : '--'}}</td>
        </ng-container>


        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef class="commentWidth">
                Comments
            </th>
            <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
                <span title="{{element.comment}}">{{element.comment ? element.comment : '--'}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                Status
            </th>
            <td mat-cell *matCellDef="let element">{{element.adminAction ? element.adminAction : '--'}}</td>
        </ng-container>
 
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
 
            <div class="text-center p-5" *ngIf="dataSource.data.length === 0 && !isLoading">
                <span class="text-danger">No Records Found!</span>
            </div>
    
            <ng-template [ngIf]="dataSource.data.length === 0 && isLoading">
                <div class="div w-100 d-flex p-3 text-center" style="justify-content: center;">
                      <mat-spinner class="center_50_px_spinner"></mat-spinner>
                    <p class="mt-3 mb-3  ms-5 text-center">Getting time card reports ...</p>
                </div>
              </ng-template>
 </div>

 
   
<div id="time_card_report_tableData_forPrint" style="display: none;">
    <table style="font-size: 10px;" *ngIf="dataSource?.data.length > 0" class="position-relative">
        <thead>
            <tr>
                <td style="border-bottom: 1px solid;">Staff </td>
                <td style="border-bottom: 1px solid;">Customer </td>
                <td style="border-bottom: 1px solid;"> Job ID </td>
                <td style="border-bottom: 1px solid;"> Job Site   </td>
                <td style="border-bottom: 1px solid;">Clock-In Time  </td>
                <td style="border-bottom: 1px solid;">Clock-Out Time  </td>
                <td style="border-bottom: 1px solid;"> Total Time   </td>
                <td style="border-bottom: 1px solid;"> Paid Time  </td>
                <td style="border-bottom: 1px solid;">Comments   </td>
                <td style="border-bottom: 1px solid;"> Status  </td>
                
             </tr> 
         </thead>
        <tbody> 
            <tr *ngFor="let element of dataSource.data">
                <td style="border-bottom: 1px solid;">{{element.name}} </td>
                <td style="border-bottom: 1px solid;">{{element.customerName}}</td>
                <td style="border-bottom: 1px solid;">{{element.JobId}} </td>
                <td style="border-bottom: 1px solid;">{{element.siteName}} </td>
                <td style="border-bottom: 1px solid;">{{element.checkinDateTime}} </td>
                <td style="border-bottom: 1px solid;">{{element.checkoutDateTime}}</td>
                <td style="border-bottom: 1px solid;">{{element.totalTime}} </td>
                <td style="border-bottom: 1px solid;">{{element.paidTime}} </td>
                <td style="border-bottom: 1px solid;">{{element.comment}} </td>
                <td style="border-bottom: 1px solid;">{{element.adminAction}} </td>
                 
             </tr>
        </tbody>
    </table>
    </div>


 <iframe #iframe style="display:none;"></iframe>