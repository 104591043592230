<div id="printData" class="mb-4">
    <div class="row mt-3 mb-3">
        <div class="col-md-12">
            <label class="title mb-2">{{'Mileage report.labels.Mileage Report' | translate }} </label>
        </div>
    </div>
</div>

<mat-tab-group dynamicHeight class="tab-button-auto-width">
    <mat-tab label="DAILY" class="daily">
        <div class="row mt-3 ">

            <div class="col-md-2">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>Date</mat-label>
                    <input (focus)="picker.open()" matInput [matDatepicker]="picker" placeholder=""
                        [value]="formatedDate" (dateChange)="onDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <!-- <div class="col-md-2">
        <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
            <mat-label>End Date</mat-label>
            <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder=""
                [value]="formatedDateE" (dateChange)="onDateChangeE($event)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div> -->

         <!--   <div class="col-md-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                    <mat-label>Select Clinician</mat-label>
                    <input type="text" placeholder="" matInput [formControl]="worker"
                        [matAutocomplete]="auto" (keyup)="applyFilter($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="selectedAuto($event)">
                        <mat-option *ngFor="let option of filteredOptions |
                    async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
        -->
            <div class="col-md-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-75">
                    <mat-label>Route</mat-label>
                    <input matInput (keyup)="applyFilterd($event.target.value)"  placeholder="{{'report.labels.Search Technician' |
                        translate }}">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <button *ngIf="mileageReport.length != 0" type="button" class="ms-3 mt-2 float-end" mat-flat-button
                    color="primary" (click)="printReport()">
                    <i class="material-icons pe-2"> print</i>{{'report.labels.Print
            Report' | translate }} </button>
            </div>
        </div>
        <div class="table-scroll-height" id="checkinout" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="clinicianName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'report.labels.Technician Name' | translate }} </th>
                    <td mat-cell *matCellDef="let element">

                        {{element.clinicianName}}

                    </td>
                </ng-container>

                <ng-container matColumnDef="numberOfAppointments">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'Mileage report.labels.Number Of Appointments' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.numberOfAppointments">{{element.numberOfAppointments}}</span>
                        <span *ngIf="!element.numberOfAppointments">--</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="planMiles">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'Mileage report.labels.Plan Miles' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.planMiles">{{element.planMiles}}</span>
                        <span *ngIf="!element.planMiles">--</span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="planTime">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'Mileage report.labels.Plan Time' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.planTime">{{element.planTime}}</span>
                        <span *ngIf="!element.planTime">--</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actualMiles">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'Mileage report.labels.Actual Miles' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.actualMiles">{{element.actualMiles}}</span>
                        <span *ngIf="!element.actualMiles">--</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actualTime">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'Mileage report.labels.Actual Time' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.actualTime">{{element.actualTime}}</span>
                        <span *ngIf="!element.actualTime">--</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
            true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div *ngIf="mileageReport.length === 0" class="no-data p-3">
                {{'report.labels.No Report Generated' | translate }}
            </div>

        </div>
    </mat-tab>
    <mat-tab label="WEEKLY" class="weekly">
        <div class="row mt-3 ps-3">

            <div class="col-md-2">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>Start Date</mat-label>
                    <input (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder=""
                        [value]="formatedDateS" (dateChange)="onDateChangeS($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>End Date</mat-label>
                    <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder=""
                        [value]="formatedDateE" (dateChange)="onDateChangeE($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                    <mat-label>Select Clinician</mat-label>
                    <input type="text" placeholder="" matInput [formControl]="worker"
                        [matAutocomplete]="auto" (keyup)="applyFilter($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="selectedAuto($event)">
                        <mat-option *ngFor="let option of filteredOptions |
                            async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
            <div class="col-md-4">
                <button *ngIf="dataSourceW && dataSourceW.data.length != 0" type="button" class="ms-3 mt-2 float-end" mat-flat-button color="primary"
                    (click)="printWeeklyReport()">
                    <i class="material-icons pe-2"> print</i>{{'report.labels.Print
                    Report' | translate }} </button>
            </div>
        </div>
        <div class="table-scroll-height" *ngIf="dataSourceW && dataSourceW.data.length> 0">
            <table mat-table [dataSource]="dataSourceW" matSort>
                <ng-container matColumnDef="{{data}}" *ngFor="let data of
                    keys;let i= index">
                    <th mat-header-cell *matHeaderCellDef class="ps-4 text-center">
                        <span *ngIf="i == 0">{{data}}</span>
                        <span *ngIf="i >= 1"><span class="daydt">{{data.split('
                                ')[0]}}</span><br>
                            <span>({{data.split(' ')[1]}})</span></span>
                        <table *ngIf="i> 0">
                            <tr class="border">
                                <td class="border">{{'Mileage report.labels.Actual Miles' | translate }}</td>
                                <td class="border">{{'Mileage report.labels.Actual Time' | translate }}</td>
                                <td class="border">{{'Mileage report.labels.Number Of Appointments' | translate }}</td>
                                <td class="border">{{'Mileage report.labels.Plan Miles' | translate }}</td>
                                <td class="border">{{'Mileage report.labels.Plan Time' | translate }}</td>
                              
                            </tr>
                        </table>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="i == 0"> {{element.ClinicianName}} </div>
                        <table *ngIf="element[data] && i >= 1">
                            <tr class="border data" [ngStyle]="{'background-color':d.delayedInMin >=
                                20? '#ffe9ec' : '' }" *ngFor="let d of element[data];">
                                <td class="border wdt" *ngIf="d.actualMiles"><span class="datatables">{{d.actualMiles}}
                                        </span></td>
                                <td class="border wdt" *ngIf="!d.actualMiles"><span class="datatables">--</span>
                                </td>
                                <td class="border wdt" *ngIf="d.actualTime"><span class="datatables">{{d.actualTime}} </span></td>
                                <td class="border wdt" *ngIf="!d.actualTime"><span class="datatables">--</span>
                                </td>
                                <td class="border wdt" *ngIf="d.numberOfAppointments"><span class="datatables">{{d.numberOfAppointments}}</span></td>
                                <td class="border wdt" *ngIf="!d.numberOfAppointments"><span class="datatables">--</span>
                                </td>

                                <td class="border wdt" *ngIf="d.planMiles"><span
                                    class="datatables">{{d.planMiles}}</span></td>
                            <td class="border wdt" *ngIf="!d.planMiles"><span class="datatables">--</span>
                            </td>
                            <td class="border wdt" *ngIf="d.planTime"><span
                                class="datatables">{{d.planTime}}</span></td>
                            <td class="border wdt" *ngIf="!d.planTime"><span class="datatables">--</span>
                            </td>
                           
                            </tr>
                        </table>
                        <table *ngIf="element[data].length == 0">
                            <tr class="border data">
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                            </tr>
                        </table>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="keys; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: keys;"></tr>
            </table>
        </div>
        <div class="p-4" *ngIf="workerexits && dataSourceW.data.length <= 0">
            Selected Clinician not worked in this week
        </div>
        <div class="p-4" *ngIf="dataSourceW && dataSourceW.data.length <= 0 &&
            workernotexit">
            Please select Clinician for weekly report
        </div>
    </mat-tab>
</mat-tab-group>





<div class="mt-5" id="printTable" style="display: none;">
    <div>
        <span style="text-align:left; margin-top:10px; width: 50%; display:
            inline-block;">
            <strong>{{'report.labels.Appointment Date' | translate }} :</strong>
            {{formatedDate}}  </span>
        <span style="text-align:right; margin-top:10px; width: 50%; display:
            inline-block;"><strong>{{'report.labels.Technician Name' | translate }}
                :</strong> <span *ngIf="workerName"> {{workerName}} </span>
            <span *ngIf="!workerName">--</span></span>
    </div>

    <div style="width:100%; text-align: center;">
        <div style="width:100%;">
            <h3 style="text-align: center"> <img style="width: 10%;" [src]="service.common.webLogo"></h3>
        </div>
        <div style="width:100%; font-size:14px; font-weight:500;">
            <h3 style="text-align: center">
                {{'Mileage report.labels.Mileage Report' | translate }} </h3> <br>
        </div>
    </div>
    <table id="print" border="1" width="100%" style="border-style: solid; border-collapse: collapse; border: 1px solid
        #cecfd5; margin-bottom: 40px;">
        <tr style="text-align: left">
            <th style="padding: 10px 15px;">{{'report.labels.Sr No' | translate
                }}.</th>
            <th style="padding: 10px 15px;">{{'report.labels.Technician Name' |
                translate }}</th>
            <th style="padding: 10px 15px;">{{'Mileage report.labels.Number Of Appointments' |
                translate }}</th>
            <th style="padding: 10px 15px;">{{'Mileage report.labels.Plan Miles' | translate }}</th>
            <th style="padding: 10px 15px;">{{'Mileage report.labels.Plan Time' | translate }}</th>
            <th style="padding: 10px 15px;">{{'Mileage report.labels.Actual Miles' | translate }}</th>
            <th style="padding: 10px 15px;">{{'Mileage report.labels.Actual Time' | translate }}</th>
        </tr>
        <tr *ngFor="let element of mileageReport; let i=index;">

            <td style="padding: 10px 15px;">
                <span>{{i+1}}</span>
            </td>


            <td style="padding: 10px 15px;">
                <span *ngIf="element.clinicianName">{{element.clinicianName}}</span>
                <span *ngIf="!element.clinicianName">--</span>
            </td>

            <td style="padding: 10px 15px;">
                <span *ngIf="element.numberOfAppointments">{{element.numberOfAppointments}}</span>
                <span *ngIf="!element.numberOfAppointments">--</span>
            </td>
            <td style="padding: 10px 15px;">
                <span *ngIf="element.planMiles">{{element.planMiles}}</span>
                <span *ngIf="!element.planMiles">--</span>
            </td>
            <td style="padding: 10px 15px;">
                <span *ngIf="element.planTime">{{element.planTime}}</span>
                <span *ngIf="!element.planTime">--</span>
            </td>
            <td style="padding: 10px 15px;">
                <span *ngIf="element.actualMiles">{{element.actualMiles}}</span>
                <span *ngIf="!element.actualMiles">--</span>
            </td>
            <td style="padding: 10px 15px;">
                <span *ngIf="element.actualTime">{{element.actualTime}}</span>
                <span *ngIf="!element.actualTime">--</span>
            </td>

        </tr>
    </table>
</div>


<div class="mt-5" id="printWeeklyTable" style="display: none;">
    <div>
        <span style="text-align:left; margin-top:10px; width: 50%; display:
            inline-block;">
            <strong>{{'report.labels.Appointment Date' | translate }} :</strong>
            {{formatedDateS}} to
            {{formatedDateE}}</span>
       <!-- <span style="text-align:right; margin-top:10px; width: 50%; display:
            inline-block;"><strong>{{'report.labels.Technician Name' | translate }}
                :</strong> {{workerName}}</span> -->
    </div>
    <br>
    <div style="width:100%; text-align: center;">
        <div style="width:100%;">
            <h3 style="text-align: center"> <img style="width: 10%;" [src]="service.common.webLogo"></h3>
        </div>
        <div style="width:100%; font-size:14px; font-weight:500;">
            <h3 style="text-align: center">
                {{'Mileage report.labels.Weekly Mileage Report' | translate }} </h3> <br>
        </div>
    </div>

    <table id="print" border="1" width="100%" style="border-style: solid; border-collapse: collapse; border: 1px solid
        #cecfd5; margin-bottom: 40px;">
        <tr>
            <th style="font-size: 18px;">Days</th>
            <th style="font-size: 18px;">Schedules</th>
        </tr>
        <tr *ngFor="let data of keyValue; let i= index">
            <td style="padding: 10px 15px;" *ngIf="i> 0">
                <p style="text-align: center;"><span style="font-weight: 700; display: block;">{{data.dateKey.split('
                        ')[0]}}</span><span class="display: block;">( {{data.dateKey.split(' ')[1]}}
                        )</span></p>
            </td>
            <td *ngIf="i> 0" style="padding: 10px 15px;">
                <table border="1" width="100%" style="border-style: solid; border-collapse: collapse;
                    border: 1px solid #cecfd5; margin-bottom: 40px;">
                    <tr>
                        <th style="padding: 10px 15px;">{{'report.labels.Technician Name' | translate }}</th>
                       <th style="padding: 10px 15px;">{{'Mileage report.labels.Actual Miles' | translate }} </th>
                        <th style="padding: 10px 15px;">{{'Mileage report.labels.Actual Time' | translate }}</th>
                        <th style="padding: 10px 15px;">{{'Mileage report.labels.Number Of Appointments' | translate }}</th>
                        <th style="padding: 10px 15px;">{{'Mileage report.labels.Plan Miles' | translate }}</th>
                        <th style="padding: 10px 15px;">{{'Mileage report.labels.Plan Time' | translate }}</th>
                    </tr>
                    <tr *ngFor="let element of WeeklyReport; let z= index">
                        
                        <td style="padding: 10px 15px;">
                            {{element.ClinicianName}}
                        </td>
                        <td *ngIf="element[data.dateKey].length != 0">
                            <table width="100%">
                                <tr *ngFor="let inside of
                                    element[data.dateKey];">
                                    <td style="padding: 10px 15px;
                                        border-bottom: 1px solid
                                        #cecfd5;
                                        text-align: center;" >
                                        <span *ngIf="inside.actualMiles">{{inside.actualMiles}}</span>
                                        <span *ngIf="!inside.actualMiles" style="text-align: center;">--</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="element[data.dateKey].length != 0">
                            <table width="100%">
                                <tr *ngFor="let inside of
                                    element[data.dateKey];">
                                    <td style="padding: 10px 15px;
                                        border-bottom: 1px solid
                                        #cecfd5;">
                                        <span *ngIf="inside.actualTime">{{inside.actualTime}}</span>
                                        <span *ngIf="!inside.actualTime" style="text-align: center;">--</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="element[data.dateKey].length != 0">
                            <table width="100%">
                                <tr *ngFor="let inside of
                                    element[data.dateKey];">
                                    <td style="padding: 10px 15px;
                                        border-bottom: 1px solid
                                        #cecfd5;" >
                                        <span *ngIf="inside.numberOfAppointments">{{inside.numberOfAppointments}}</span>
                                        <span *ngIf="!inside.numberOfAppointments" style="text-align: center;">--</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="element[data.dateKey].length != 0">
                            <table width="100%">
                                <tr *ngFor="let inside of
                                    element[data.dateKey];">
                                    <td style="padding: 10px 15px;
                                        border-bottom: 1px solid #cecfd5;">
                                        <span *ngIf="inside.planMiles">{{inside.planMiles}}</span>
                                        <span *ngIf="!inside.planMiles" style="text-align: center;">--</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td *ngIf="element[data.dateKey].length != 0">
                            <table width="100%">
                                <tr *ngFor="let inside of
                                    element[data.dateKey];">
                                    <td style="padding: 10px 15px;
                                        border-bottom: 1px solid #cecfd5;">
                                        <span *ngIf="inside.planTime">{{inside.planTime}}</span>
                                        <span *ngIf="!inside.planTime" style="text-align: center;">--</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                       
                        <td *ngIf="element[data.dateKey].length == 0" style="text-align: center;">--</td>
                        <td *ngIf="element[data.dateKey].length == 0" style="text-align: center;">--</td>
                        <td *ngIf="element[data.dateKey].length == 0" style="text-align: center;">--</td>
                        <td *ngIf="element[data.dateKey].length == 0" style="text-align: center;">--</td>
                        <td *ngIf="element[data.dateKey].length == 0" style="text-align: center;">--</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>

<iframe #iframe style="display:none;"></iframe>