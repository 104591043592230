<div class="w-100">
  <div class="d-flex flex-row justify-content-between w-100 mb-3 logo align-items-center">
    <div class="col-lg-4 col-md-4 col-sm-12 float-start header-name">Staff Directory</div>
    <ng-template [ngIf]="hasPermission.addPermission === true">
      <div class="col-lg-8 col-md-6 col-sm-12 text-end">
        <a class="me-2" mat-stroked-button (click)="downloadSample()" target="_blank">
          <mat-icon>play_for_work</mat-icon>
          Download Sample
        </a>
        <a class="me-5" mat-stroked-button color="secondary" (click)="f_input.click()">
          <mat-icon style="transform: rotate(180deg);">play_for_work</mat-icon>
          Select File
        </a>
        <input type="file" hidden #f_input (change)="handleFileInputChange(f_input.files)" />
        <button class="me-3" mat-raised-button color="primary" (click)="uploadStaff()">Save</button>
        <button mat-raised-button color="primary" (click)="PopUp_AddMember()">Add</button>
      </div>
    </ng-template>
  </div>
  <div class="col-lg-11 mt-2 col-sm-12 text-end" *ngIf="currentUpldStaff_File.name">
    <span class="text-decoration-underline">Selected File &nbsp; : &nbsp; <b class="text-success">{{currentUpldStaff_File.name}}</b></span>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12 searchfilter">
      <input
        type="text"
        class="form-control"
        id="search-technician"
        autocomplete="off"
        placeholder="Search Staff"
        name="search-technician"
        [(ngModel)]="searchTerm"
        (keyup)="search()"
      />
      <mat-icon class="cursor-pointer">search</mat-icon>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 text-center">
      <mat-icon [class.active]="viewPoint==='cardview'" class="viewpoint me-2" (click)="changeView('cardview')">view_module</mat-icon>
      <mat-icon [class.active]="viewPoint==='listview'" class="viewpoint" (click)="changeView('listview')">list</mat-icon>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 text-end dropdown">

      <button mat-raised-button color="primary" class="me-2 p-0 resetBtn" title="Reset All Filter" (click)="resetAllSelectedFilters(); getData(0, 30, null, tabChangeActiveInactive === 'Active' ? true : false, true, null)">
        <mat-icon>restart_alt</mat-icon>
      </button>

      <button mat-raised-button color="primary" class="dropdown-toggle dropdown-toggle-no-caret me-2" data-bs-toggle="dropdown" aria-expanded="false">
        <span>Sort By</span>
        <span *ngFor="let sortItem of allSortNameList">
          <span *ngIf="sortItem?.isActive">: <b matTooltip="{{sortItem?.name}}">{{sortItem?.order}}</b></span>
        </span>
      </button>
      <div class="dropdown-menu">
        <ng-container *ngFor="let sortItem of allSortNameList; index as sortIdx">
          <a class="dropdown-item" href="javascript:void(0)" [class.active]="sortItem.isActive" (click)="sortBy(sortItem)"><b>{{sortItem?.order}}</b> {{sortItem?.name}}</a>
        </ng-container>
      </div>

      <button mat-raised-button color="primary" class="dropdown-toggle dropdown-toggle-no-caret" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false" class="dropdown-toggle dropdown-toggle-no-caret" [class.me-2]="dataSourceForCardView.length > 0" (click)="getAllSkillsOnlyByOrgCode()">
        <span>Filter By</span>
        <span *ngFor="let skill of selectedSkillList; index as skillIdxx">
          <span *ngIf="skill?.isSkillChecked && skillIdxx === 0">: {{skill?.skillName | capitalize}} 
            <span *ngIf="selectedSkillList.length > 1">+{{selectedSkillList.length-1}}</span>          
        </span>
        </span>
      </button>
      <div class="dropdown-menu">
        <div class="searchfilterBox searchfilter" (click)="$event.stopPropagation()">
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="Search"
            name="search-skill"
            [(ngModel)]="searchText"
          />
          <mat-icon class="cursor-pointer mt-2">search</mat-icon>
        </div>
        <div class="w-100 dropdownScroll">
        <ng-container *ngFor="let skill of allSkillList | searchFilter : searchText:['skillName']; index as skillIdx">
          <a class="dropdown-item skillDropItem" href="javascript:void(0)" (click)="$event.stopPropagation()">
            <input type="checkbox" class="checkBoxInput" [id]="'skill_'+skillIdx+'_'+skill.id" [name]="skill?.skillName" [value]="skill?.skillName" [checked]="skill.isSkillChecked" (change)="!isSkillLoading && selectSkillCodeList(skill, skill.id)">
            <label [for]="'skill_'+skillIdx+'_'+skill.id">{{skill?.skillName | capitalize}}</label>
          </a>
        </ng-container>
        </div>
        <div *ngIf="selectedSkillList.length > 0" class="w-100 d-flex justify-content-center mt-1 mb-1">
          <button mat-raised-button color="gray" type="button" class="btnCenter me-2" [disabled]="isSkillLoading" (click)="clearSkillFilters(true)">
            <mat-icon class="m-0 p-0">close</mat-icon>
          </button>
          <button mat-raised-button color="primary" type="button" class="btnCenter" [disabled]="isSkillLoading" (click)="!isSkillLoading && filterBySkillCodeList(selectedSkillCodeListForSort)">
            <mat-icon *ngIf="!isSkillLoading" class="m-0 p-0">done</mat-icon>
            <span *ngIf="isSkillLoading" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>        
        <div *ngIf="!selectedSkillList || selectedSkillList.length === 0" class="w-100 d-flex justify-content-center mt-1 mb-1">
          <button mat-raised-button color="gray" type="button" class="btnCenter" (click)="clearSkillFilters(false)">
            <mat-icon *ngIf="!isSkillLoading" class="m-0 p-0">close</mat-icon>
            <span *ngIf="isSkillLoading" class="spinner-border spinner-border-sm"></span>
          </button>
        </div>
      </div>

      <button *ngIf="dataSourceForCardView.length > 0" mat-raised-button color="primary" matTooltip="Send welcome email to all staff" (click)="sendWelcomeEmailToAllStaff()">
        <mat-icon>mail</mat-icon>
        Send to all
      </button>
    </div>
  </div>
  <div class="w-100 mt-2">
    <div class="StaffCount">
      <span>Active: <b class="activeCount">{{staffCountData?.active ? staffCountData.active : 0}}</b>&nbsp;&nbsp;Inactive: <b class="inactiveCount">{{staffCountData?.inactive ? staffCountData.inactive : 0}}</b></span>
    </div>
    <mat-tab-group dynamicHeight class="tab-button-auto-width" #tabGroup (selectedTabChange)="tabChangeEventActiveInactive($event)">      
      <mat-tab label="Active">
        <!-- Put Empty Tab Content -> Data Content is showing based on tab selection below -->
      </mat-tab>
      <mat-tab label="Inactive">
        <!-- Put Empty Tab Content -> Data Content is showing based on tab selection below -->
      </mat-tab>
    </mat-tab-group>

    <ng-container *ngIf="viewPoint && tabChangeActiveInactive">
      <div class="w-100">
        <div class="w-100 progressBarBox">              
          <mat-progress-bar mode="indeterminate" *ngIf="technicianService.common.infiniteLoader"></mat-progress-bar>
        </div>            
        <div class="table-scroll-height" id="staffListScroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
          <ng-container *ngIf="viewPoint === 'cardview'">
            <div class="w-100 pt-2" *ngIf="dataSourceForCardView && dataSourceForCardView.length > 0">
              <app-card-view-component
                [dataList]="dataSourceForCardView"
                [allRolesList]="allRolesList"
                [is_editPermission]="hasPermission.editPermission"
                [is_deletePermission]="hasPermission.delPermission"
                [is_addPermission]="hasPermission.addPermission"
                (RoleChangeCardViewEmmit)="RoleChangeFromCardView($event)"
                (EmitFileUploadComplete)="fileUploadComplete($event)"
                (sendWelcomeEmail_Emitter)="sendWelcomeEmail($event)"
                (isStaffListRefresh)="isStaffListRefresh($event)"
                (resetPassword_Emitter)="changePassword($event)"
                (addNote_Emitter)="addNote($event)"
                (calendarView_Emitter)="calendar_shcedule_view($event)"
              >
              </app-card-view-component>
            </div>
          </ng-container>
          <ng-container *ngIf="viewPoint === 'listview'">
            <div class="w-100 pt-2">
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-responsiv">
                <ng-container matColumnDef="name" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">
                    {{ "Technicians.labels.Name" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="common-icon-round tablet-view text-nowrap" data-label="name">
                    <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
                  </td>
                </ng-container>
                <ng-container matColumnDef="email" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                    {{ "Technicians.labels.Email" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" data-label="email">
                    <span> {{ element.email }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="role" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                    {{ "Technicians.labels.Role" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" data-label="email">
                    <div class="btn-group RoleChangeDropdownSection positionInitial d-flex">
                      <button
                        type="button"
                        class="btn btn-info btn-sm dropdown-toggle minWid w-100 text-truncate"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        matTooltip="{{element?.roleName ? element.roleName : ''}}"
                      >
                        {{element?.roleName ? element.roleName : 'Select'}}
                      </button>
                      <ul class="dropdown-menu roleMenu">
                        <ng-container *ngFor="let role of allRolesList; index as idx">
                          <li matTooltip="{{(role?.roleName ? role.roleName : '') | capitalize}}">
                            <a
                              class="dropdown-item curser-pointer text-truncate"
                              [class.active]="element?.roleName === role?.roleName"
                              href="javascript:void(0)"
                              (click)="element?.roleName !== role?.roleName && ChangeRole(element, role)"
                              [class.inactive]="element?.roleName === role?.roleName"
                            >
                              {{role.roleName | capitalize}}
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="phone" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                    {{ "Technicians.labels.Phone" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" data-label="phone" class="text-nowrap">
                    <span *ngIf="element.workPhone">+{{ element.workcountryCode }} {{ element.workPhone }} </span>
                    <span *ngIf="!element.workPhone">--</span>
                  </td>
                </ng-container>                
                <ng-container matColumnDef="employmentTypeName" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">
                    {{ "Technicians.labels.Emp Type" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="qual-color" data-label="employmentTypeName">
                    <span class="qual-color" *ngIf="element.employmentTypeName">{{ element.employmentTypeName }} </span>
                    <span class="qual-color" *ngIf="!element.employmentTypeName">--</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="skills" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                    Skills
                  </th>
                  <td mat-cell *matCellDef="let element" class="qual-color text-nowrap" data-label="skills">
                    <span class="qual-color text-nowrap">
                      <span *ngIf="element.skillList!=null">
                        <span *ngFor="let skill of element.skillList.split(','); let skillIdx = index; let last = last">
                          <span *ngIf="4 > skillIdx">{{skill | capitalize}}<span *ngIf="!last">, </span></span>
                          <span *ngIf="skillIdx === 5">{{skill | capitalize}} <span> </span></span>
                        </span>
                        <span style="color: green; font-weight: 500;" title="{{element.skillList}}" class="me-2" *ngIf="element.skillList.split(',').length > 5"> + {{element.skillList.split(',').length - 5}} 
                        </span>
                      </span>
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="isAppUseFor" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef class="text-nowrap">
                    Device Detail
                  </th>
                  <td mat-cell *matCellDef="let element" class="qual-color text-center" data-label="isActive">
                    <mat-icon *ngIf="element?.isAppRegistered && element?.appType === 'Android'" title="{{element?.appType}}{{element?.appVersion ? ('&#013;Version: ')+element.appVersion : ''}}{{element?.applastLogin ? ('&#013;Last login: ')+element.applastLogin : ''}}">smartphone</mat-icon>
                    <mat-icon *ngIf="element?.isAppRegistered && element?.appType === 'Ios'" title="{{element?.appType}}{{element?.appVersion ? ('&#013;Version: ')+element.appVersion : ''}}{{element?.applastLogin ? ('&#013;Last login: ')+element.applastLogin : ''}}">phone_iphone</mat-icon>
                    <mat-icon *ngIf="element?.isAppRegistered && element?.appType === 'Web'" title="{{element?.appType}}{{element?.appVersion ? ('&#013;Version: ')+element.appVersion : ''}}{{element?.applastLogin ? ('&#013;Last login: ')+element.applastLogin : ''}}">laptop</mat-icon>
                  </td>
                </ng-container>
                <ng-container matColumnDef="workerStatusCode" class="dis-icon">
                  <th mat-header-cell *matHeaderCellDef class="action text-nowrap"></th>
                  <td mat-cell *matCellDef="let element" data-label="status" class="action">
                    <a class="action-icon dropdown-toggle dropdown-toggle-no-caret cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_vert</i></a>
                    <div class="dropdown-menu dropdown-menu-right curser-pointer ">
                      <ng-template [ngIf]="hasPermission.editPermission === true">
                        <a class="dropdown-item" [routerLink]="['/dashboard', 'staffmember', 'member-edit']" [queryParams]="{ workerCode: element.workerCode }">
                          <mat-icon>edit</mat-icon>
                          &nbsp;Edit
                        </a>
                        <a class="dropdown-item" (click)="setActiveInactiveStaff(element, element?.isActive ? false : true)">
                          <mat-icon [ngStyle]="{'color': element?.isActive ? 'red' : 'green'}">
                            {{element?.isActive ? 'highlight_off' : 'check_circle'}}</mat-icon>
                            &nbsp;{{element?.isActive ? 'Inactive' : 'Active'}}
                        </a>
                      </ng-template>

                      <a class="dropdown-item" [routerLink]="['/dashboard', 'staffmember', 'member-edit']" [queryParams]="{ workerCode: element.workerCode , activeTab:'Exception' }">
                        <mat-icon>recent_actors</mat-icon>
                          &nbsp;Exceptions
                      </a>

                      <a class="dropdown-item" *ngIf="element.workerStatusCode">
                        <i class="material-icons pointer"> check </i>
                      </a>

                      <a class="dropdown-item" *ngIf="element.workerStatusCode">
                        <i class="material-icons pointer"> hourglass_empty</i>
                      </a>

                      <a class="dropdown-item" (click)="sendTemplate(element)">
                        <span class="me-3">
                          <mat-icon>send</mat-icon>
                          &nbsp;Send Email
                        </span>
                      </a>

                      <ng-template [ngIf]="element.isActive">                            
                        <a class="dropdown-item" (click)="sendWelcomeEmail(element.workerCode)">
                          <span class="me-3">
                            <mat-icon>mail</mat-icon>
                            &nbsp;Send Welcome Email
                          </span>
                        </a>
                        <a class="dropdown-item" (click)="changePassword(element.workerCode)">
                          <span>
                            <mat-icon>lock</mat-icon>
                            &nbsp;Reset Password
                          </span>
                        </a>
                      </ng-template>

                      <!-- <a class="dropdown-item" (click)="addNote(element)">
                        <span class="me-3">
                          <mat-icon style=" transform: rotate(-90deg);"   class="{{!element.notes ? 'grayIc' : ''}}">note</mat-icon>
                          &nbsp;Note
                        </span>
                      </a> -->

                      <a class="dropdown-item" (click)="addNote(element, element.name)">
                        <span class="ms-1">
                          <img  
                           [src]="element.isNoteAdded ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
                          &nbsp;Note
                        </span>
                      </a>
 
                      <a  class="dropdown-item" (click)="calendar_shcedule_view(element)">
                        <span>
                          <mat-icon>calendar_today</mat-icon> 
                        </span>
                        <span class="pt-1">
                          View Schedule
                        </span>
                      </a>

                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </ng-container>
          <div *ngIf="technicianList.length == 0" class="p-3 noList">
            {{ "Technicians.labels.No Technicians are present" | translate }}
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>




<ng-template #addNote_popUp>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Note</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-label>Note</mat-label>
        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
         <textarea matInput  [(ngModel)]="notes"></textarea>
        </mat-form-field>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-12 text-end">
      <button class="me-3" mat-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary"
        (click)="saveNote(btn_closePopUp)">save</button>
    </div>
  </div>

</ng-template>




<ng-template #schedule_view_popup>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <!-- <h6 class="dialog-header pt-2"></h6> -->
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="card-content w-100">
  <div class="row">
  <app-staff-calendar-view [staff_code]="workerCode"></app-staff-calendar-view>
  </div>
</div>

  <!-- <div class="row mt-3">
    <div class="col-lg-12 text-end">
      <button class="me-3" mat-button mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" >save</button>
    </div>
  </div> -->

</ng-template>