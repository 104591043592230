import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';
import { AppointmentService } from '../appointment.service';

@Component({
  selector: 'app-trade-shift',
  templateUrl: './trade-shift.component.html',
  styleUrls: ['./trade-shift.component.scss']
})
export class TradeShiftComponent implements OnInit {

  tradeShift: FormGroup;
  otherEligibleWorker = [];
  tradeShiftData;

  constructor(public dialogRef: MatDialogRef<TradeShiftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: CreateAppointmentService, public serviceAppointment: AppointmentService) { }

  ngOnInit() {
    this.tradeShift = new FormGroup({
      'worker': new FormControl(null, Validators.required)
    });

    this.tradeShiftData = this.data.data;
    //////console.log(this.tradeShiftData);
    try {


      this.service.getEligibleWorkerForJobsByJobDurationCode(this.tradeShiftData.appointmentsDurationCode, '', '', '', (data) => {
        if (data.responsecode == 200) {
          if (data.data.workerWithDistanceList.length > 0) {
            data.data.workerWithDistanceList.forEach(elementData => {
              if (elementData.worker) {
                elementData.worker.isAssigned = elementData.isAssigned;
                this.otherEligibleWorker.push(elementData.worker);
              }
            });
            setTimeout(() => {
              this.otherEligibleWorker.forEach((element) => {
                if (element.isAssigned == true) {
                  this.tradeShift.controls['worker'].setValue(element.workerCode);
                }
              })
            }, 500)
          } else {
            this.service.common.getWorkerAndOrganizationFromWAS(this.service.common.local.get('orgCode'), (data) => {
              if (data.data.length > 0) {
                data.data.forEach(element => {
                  if (element.workerRes) {
                    this.otherEligibleWorker.push(element.workerRes);
                  }
                });
              } else {
                this.otherEligibleWorker = [];
              }
            })
          }
          setTimeout(() => {
            //////console.log(this.otherEligibleWorker);
          }, 200)
        } else {
          this.otherEligibleWorker = [];
        }
      })
    } catch (error) {

    }
  }
  /* Name:srinivas
         use: close popup. */
  cancel() {
    this.dialogRef.close();
  }
  /* Name:srinivas
         use: save trade shift details. */
  onSubmit() {
    if (this.tradeShift.valid) {
      const worker = {
        "workerCode": this.tradeShift.value.worker
      }
      try {


        this.service.assignToWorkerByJobDurationCode(this.tradeShiftData.appointmentsDurationCode, worker, (res) => {
          if (res.responsecode == 200) {
            // this.service.common.message = res.message;
            // this.service.common.successMessage = true;
            this.dialogRef.close(res.message);
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        })
      } catch (error) {

      }
    }
  }

}
