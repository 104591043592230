import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProcedureService } from '../procedure.service';
import { TranslateService } from '@ngx-translate/core';
import { debounce } from 'lodash';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

export interface PeriodicElement {
  position: number;
  description: string;
  symbol: string;
  id: number;
} 

@Component({
  selector: 'app-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['./procedure-list.component.scss']
})

export class ProcedureListComponent implements OnInit, OnDestroy {
  
  displayedColumns: string[] = [
    'procedureIcon', 
    'procedureName', 
 // 'procedureId',
    'procedureDescription', 
    'regularRate',
    'overTimeRate',
    'edit'
  ];

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  procedureList = [];
  public show: boolean = false;
  public searchClick: any = 'Show';
  page = 0;
  limit = 30;
  hasPermission:IBtnControlsPermission ;
  dialogRef: any;
  private clearObs = new Subject<any>();
  
  constructor(
    public service: ProcedureService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private _commonSVC : CommonService
  ) { 
    this.translate.setDefaultLang(this.service.local.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }

  

  ngOnInit() {

    this.getData(this.page, this.limit);
    this.hasPermission =  this.service.common.getBtnEventPermissions("Job Type");
    this.subToPermissionChanged();
  } 

  applyFilter(searchText: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    ////console.log(this.dataSource.filteredData.length);
    this.page = 0;
    this.service.common.progressLoader = true;
  try {
    if(searchText && searchText.trim()){
    this.service.getAllProcedureByOrganizationCodeByPageSearch(this.service.local.get('orgCode'), searchText, (response) => {
      if(response.responsecode === 200){
          setTimeout(() => {
            this.procedureList = response.data;
            this.dataSource = new MatTableDataSource(this.procedureList);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }, 1000);
      } else {
        this.service.common.progressLoader = false;
          this.procedureList = [];
          setTimeout(() => {
            this.dataSource = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 1000);        
      }
  });
  } else {
    this.getData(this.page, this.limit);
  } 
  } catch (error) {
    //console.log(error);
  }
  }

  /* Name:Shailesh
      use: for getting all procedures by org code*/
  getData(page, limit, scroll = null) {
    if (!scroll) {
      this.service.common.progressLoader = true;
    } else if(scroll) {
      this.service.common.infiniteLoader = true;
    } 
    try {
      this.service.getAllProcedureByOrganizationCodeByPage(this.service.local.get('orgCode'), page, limit, (response) => {
      //////console.log(response);
      if (response.responsecode == 200) {
        if (scroll) {
          let arrayData = this.dataSource.data;
          response.data.forEach(element => {
            const index = this.service.common.durationHours.findIndex(data => data.value == element.duration);
            if (index !== -1) {
              element.durationData = this.service.common.durationHours[index].text;
            }
            arrayData.push(element);
          });
          setTimeout(() => {
            this.procedureList = arrayData;
            this.dataSource = new MatTableDataSource(arrayData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
            this.service.common.infiniteLoader = false;
          }, 1000);
        } else {
          response.data.forEach(element => {
            const index = this.service.common.durationHours.findIndex(data => data.value == element.duration);
            if (index !== -1) {
              element.durationData = this.service.common.durationHours[index].text;
            }
          });
         
            this.procedureList = response.data;
            this.dataSource = new MatTableDataSource(response.data);
            ////console.log(response.data)
          setTimeout(() => {  
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            // this.dataSource.paginator = this.paginator;
            this.service.common.progressLoader = false;
            this.service.common.infiniteLoader = false;
          });
         
        }

      } else {
        if (!scroll) {
          this.procedureList = [];
          this.dataSource = new MatTableDataSource();
        } else {
          this.service.common.infiniteLoader = false;
        }
        this.service.common.progressLoader = false;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

  filterToggle() {
    this.show = !this.show;

    if (this.show) {
      this.searchClick = "Hide";
    }
    else {
      this.searchClick = "Show";
    }
  }

  onScroll() {
    //////console.log('scroll');
    this.page = this.page + 1;
    this.getData(this.page, this.limit, 'scroll');
  }

        /* Name:Vivek Chauhan new API
  use: for delete/remove Skill */
  softDeleteOrganizationBasedProcedureByOrganizationBasedProcedureCode(jobType :any){ 
    // //console.log('softDeleteOrganizationBasedProcedureByOrganizationBasedProcedureCode: ', jobType);
    // return;
    if(jobType.organizationBaseProcedureCode){
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'Are you sure want to delete this job type?' }
      });
    
      dialogRef.afterClosed().subscribe(result => {
    
        if(result && result.toLowerCase() === 'ok'){
        this.service.softDeleteOrganizationBasedProcedureByOrganizationBasedProcedureCode(jobType.organizationBaseProcedureCode, (response) => {
          // //console.log(response);
          if (response.responsecode == 200) 
          {
             this.service.common.message = response.message;
              this.service.common.successMessage = true;

            this.procedureList = [];
            this.dataSource = new MatTableDataSource();
            this.page = 0;
            this.getData(this.page, this.limit);
          } 
          else
           {
             this.service.common.message = response.message;
              this.service.common.errorMessage = true;
            }
          });
        }
      });
    }
  }

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  // }

  subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this._commonSVC.getBtnEventPermissions("Job Type");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }

}
