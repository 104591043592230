import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionService } from './distribution-service';
import { TranslateService } from '@ngx-translate/core';
 import { debounce } from 'lodash';
import { AddDistributionComponent } from './add-distribution/add-distribution.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

@Component({
  selector: 'distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list.component.scss']
})
export class DistributionListComponent implements OnInit, OnDestroy {

  listData: any = [];
  displayedColumns: string[] = ['distributionName', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  hasPermission:IBtnControlsPermission ;
  @ViewChild('distributionDetails_TempRef') distributionDetails_TempRef: TemplateRef<any>;
  
  user_page:number = 0;
  user_limit:number = 20;
  is_Loading:boolean = false;
  img_serverPath: string = '';
  distributionUsers:any =[];
  tbl_selectedDistribution : any;
  staff_SearchText:string = '';
  private clearObs = new Subject<any>();
  
  constructor(
    public dialog: MatDialog,
    public service: DistributionService,
    private translate: TranslateService,
    private _commonSVC : CommonService
    ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }

  ngOnInit() {
    this.page = 0;
    this.getListDataByPage();
    this.hasPermission =  this._commonSVC.getBtnEventPermissions("Distribution Lists");
    this.img_serverPath = this.service.service.getSuperAdmin() + '/downloadFile/';
    this.subToPermissionChanged();    
  }
  
   /* Name:Vivek Chauhan
  use: for getting all Org Distribution Master data by org code */
  getListDataByPage(scroll = null) {
      // //console.log('getListDataByPage: ', this.page, scroll);
      if (!scroll) {
        this.service.common.progressLoader = true;
      }
      else if (scroll) {
        this.service.common.infiniteLoader = true;
      }
      try {
        this.service.getListDataByPage(this.service.storage.get('orgCode'), this.page, this.limit, (response) => {
          if(response.responsecode === 200){
            if (scroll) {
              let arrayData = this.dataSource.data;
              response.data.forEach(element => {
                arrayData.push(element)
              });
              setTimeout(() => {
                this.listData = arrayData;
                this.dataSource = new MatTableDataSource(arrayData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
                console.log('Sort_ss_1',  this.sort);
                this.dataSource.sort = this.sort;
                this.service.common.infiniteLoader = false;
              }, 1000);

            } else {
              ////console.log(response.data);
              setTimeout(() => {
                this.listData = response.data;
                this.dataSource = new MatTableDataSource(this.listData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
                console.log('Sort_ss_1',  this.sort);
                this.dataSource.sort = this.sort;
                this.service.common.progressLoader = false;
              }, 1000)
            }
          } else {
            if (!scroll) {
              this.listData = [];
              setTimeout(() => {
                this.dataSource = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
              }, 1000);      
            } else {
              this.service.common.infiniteLoader = false;
            }
            this.service.common.progressLoader = false;
          }
      });    
      } catch (error) {
        //console.log(error);
      }
  }

    /* Name:Vivek Chauhan
      use: for open edit distribution popup*/
    onEdit(data) {
      // //console.log('onEditData:', data);
      const dialogRef = this.dialog.open(AddDistributionComponent, {
        width: '500px',
        height: 'auto',
        maxHeight: '94vh',
        maxWidth:'500px',
        disableClose: true,
        data: data
    });
    dialogRef.updatePosition({
      top: '1%'    // Set top position
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result === 'success') {
        this.page = 0;
        this.getListDataByPage();
      }
    });
    }

      /* Name:Vivek Chauhan
      use: for open distribution add/create popup */
  addNew(): void {
    const dialogRef = this.dialog.open(AddDistributionComponent, {
      width: '500px',
      height: 'auto',
      maxHeight: '94vh',
      maxWidth:'500px',
      disableClose: true,
      data: {}
    });

    dialogRef.updatePosition({
      top: '1%'    // Set top position
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result === 'success') {
        this.page = 0;
        this.getListDataByPage();
      }
    });
  }

    /* Name:Vivek Chauhan new API
  use: for delete/remove Distribution */
  softDeleteByCode(data :any){
    // //console.log('softDeleteByCode: ', data);    
    if(data.distributionCode){ 
      const dialog = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { 
          msg: 'Are you sure you want to delete this distribution?'
        }
      });      
  
      dialog.afterClosed().subscribe(result => {    
        if(result && result.toLowerCase() === 'ok'){ 
          this.service.common.progressLoader = true;
          this.service.softDeleteByCode(data.distributionCode, (response) => {
            //console.log(response);
            this.service.common.progressLoader = false;
            if (response.responsecode == 200) {
              this.service.common.successMessage = true;
              this.service.common.message = response.message;
              this.page = 0;
              this.getListDataByPage();
            }
            else 
            {
              this.service.common.errorMessage = true;
              this.service.common.message = response.message;
            }
          });
        }
      });
    }
  }

  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.getListDataByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }

  /* Name:Vivek Chauhan
  use: for seraching Distribution Data name */
    applyFilter(searchText: string) {
      // //console.log('applyFilter', searchText);
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
    this.page = 0;
      this.service.common.progressLoader = true;
    try {
      if(searchText && searchText.trim()){
      this.service.getListDataBySearch(this.service.storage.get('orgCode'), searchText, (response) => {
        if(response.responsecode === 200){
            setTimeout(() => {
              this.listData = response.data;
              this.dataSource = new MatTableDataSource(this.listData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.progressLoader = false;
            }, 1000);
        } else {
          this.service.common.progressLoader = false;
            this.listData = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 1000);          
        }
    });
    } else {
      this.getListDataByPage();
    } 
    } catch (error) {
      //console.log(error);
    }
  }

 

  viewDetails(element:any)
  {

    this.tbl_selectedDistribution = element ;

    const dialog = this.dialog.open(this.distributionDetails_TempRef, {
      width: '50%',
      height: 'auto',
      maxHeight: '94vh',
      disableClose: true
      });

      dialog.updatePosition({
        top: '1%'    // Set top position
      });

      dialog.afterClosed().subscribe(result => {
        this.staff_SearchText = '' ;
        this.distributionUsers = [];
        this.user_page = 0;        
      });

      this.getDistributionStaff();
 
}


getDistributionStaff(isScroll:string=null)
{
  this.is_Loading = true ;
  this.service.v2_getDistributionUserListByDistributionCode(this.tbl_selectedDistribution?.distributionCode, this.user_page, this.user_limit, this.staff_SearchText).subscribe((response:any) => {
  this.is_Loading = false ;
 if(response.responsecode === 200)
 {
  let tempArray = [];
  if(isScroll)
  {
    tempArray = this.distributionUsers;
  }
   
  response.data.forEach(ele=>{
    tempArray.push(ele);
  })
  this.distributionUsers = tempArray;
  // this.distributionUsers =  [...this.distributionUsers, ...response.data];
 } 
  
});
}


onScrollDistributionStaff()
{
  this.user_page += 1;
  this.getDistributionStaff('scrolled');
}
  
staffSearch(searchText:string)
{
  if(this.staff_SearchText.trim() || searchText.trim())
  {
    this.staff_SearchText = searchText ;
    this.user_page = 0;
    this.distributionUsers = [] ;
    this.getDistributionStaff();
  }
}


subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this._commonSVC.getBtnEventPermissions("Distribution Lists");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }


}


