import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NotificationService } from './notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
 import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { MatDialog } from '@angular/material/dialog';
import { CallPatientComponent } from '../call-patient/call-patient.component';
import { CreateAppointmentService } from '../appointment/create-appointment/create-appointment.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
// import { AssignAppointmentComponent } from '../appointment/assign-appointment/assign-appointment.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  page = 0;
  limit = 30;
  notification = [];
  // displayedColumns: string[] = ['read', 'date', 'message', "call"];
  displayedColumns: string[] = [ 'read', 'notificationDate', 'description', ];
  dataSource = new MatTableDataSource();
  notificationCode;
  //showMessage = false;
  resultsLength = 0;
  currentDate = new Date();
  currentSelectedDate;
  yearMonthDateFormat = 'YYYY-MM-DD';
  codeListForMarkAsRead = [];
  isMarkAllLoading: boolean = false;
  isMarkAsReadLoading: boolean = false;
  //isNotificationDataLoad: boolean = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true })
  filter: ElementRef;
  userType: any;
  //_unsubscribeAll: Subject<{}>;
  expiredNotifications : boolean = false ;

  constructor(public service: NotificationService, public router: Router,
    public route: ActivatedRoute, public translate: TranslateService, public common: CommonService,
    public dialog: MatDialog, public createPatientService: CreateAppointmentService, ) 
    {
    this.translate.setDefaultLang(this.common.local.get('language'));
    //this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.userType = this.service.session.get("user_type");
    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    //this.getNotification(this.page, this.limit);
    // this.service.common.dataSourceNotification.sort = this.sort;
    this.getNotification_AsPer_Role();
  }

  /* Name:Shailesh
     use: for getting all healthcare notification by user code with read by page*/
  // getNotification(page, limit, scroll = null) {
  //   if (!scroll) {
  //     this.service.common.progressLoader = true;
  //   } else if(scroll){
  //     this.service.common.infiniteLoader = true;
  //   }
  //   try {
  //     //console.log('service Call!!!',page, limit);
  //     this.service.getAllHealthcareNotificationByUserCodeWithReadByPage(this.service.common.local.get('userCode'), page, limit, (response) => {
  //       //console.log(response,page, limit);
  //       if (response.responsecode == 200) {
  //         this.showMessage = false;
  //         this.service.common.allNotificationsCount = response.count;
  //         //console.log(this.service.common.allNotifications);
  //         if (scroll) {
  //           let arrayData = this.service.common.allNotifications;

  //           response.data.forEach(element => {
              

  //             element.appointmentsDeatilsList = [];
  //               if (element.appointmentDurationCode) {
  //                 this.service.getPatientDetailsByAppointmentDurationCodeList(
  //                   element.appointmentDurationCode,
  //                   response => {
  //                     //console.log(response);
  //                     if (response.responsecode == 200) {
  //                       element.appointmentsDeatilsList = response.data;
  //                     }
  //                   }
  //                 );
  //               }

  //             let startdate = element.notificationDate.split(' ')[0];
  //             let time = element.notificationDate.split(' ')[1];
  
  //             if (element.dateTimeFormat == 'MM-dd-yyyy hh:mm a') {
  //               element.notificationDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //               //////console.log(startDate);
  //             } else if (element.dateTimeFormat == 'dd-MM-yyyy  hh:mm a') {
  //               element.notificationDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //               //////console.log(startDate);
  //             } else {
  //               element.notificationDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //               //////console.log(startDate);
  //             }
  //             arrayData.push(element);
  //           });

  //           setTimeout(() => {
  //             this.service.common.allNotifications = [];
  //             this.service.common.allNotifications = arrayData;
  //             this.service.common.dataSourceNotification = new MatTableDataSource(arrayData)
  //             this.service.common.progressLoader = false;
           
  //             this.service.common.infiniteLoader = false;
  //           }, 2000);
  //         } else {

  //         response.data.forEach(element => {

  //           element.appointmentsDeatilsList = [];
  //               if (element.appointmentDurationCode) {
  //                 this.service.getPatientDetailsByAppointmentDurationCodeList(
  //                   element.appointmentDurationCode,
  //                   response => {
  //                     //console.log(response);
  //                     if (response.responsecode == 200) {
  //                       element.appointmentsDeatilsList = response.data;
  //                     }
  //                   }
  //                 );
  //               }

  //           //this.service.allNotificationCode.notificationCodeList.push(element.healthcareNotificationCode);
  //           this.service.notificationCodeList.notificationCodeList.push(element.healthcareNotificationCode)
  //           let startdate = element.notificationDate.split(' ')[0];
  //           let time = element.notificationDate.split(' ')[1];
  //           //console.log(startdate, time,' !!!');
  //           if (element.dateTimeFormat == 'MM-dd-yyyy hh:mm a') {
  //             element.notificationDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //             //////console.log(startDate);
  //           } else if (element.dateTimeFormat == 'dd-MM-yyyy  hh:mm a') {
  //             element.notificationDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //             //////console.log(startDate);
  //           } else if(element.dateTimeFormat == 'MM-dd-yyyy HH:mm:ss'){
  //             element.notificationDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //           } else if (element.dateTimeFormat == 'dd-MM-yyyy  HH:mm:ss') {
  //             element.notificationDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //             //////console.log(startDate);
  //           } else {

  //             //console.log(moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a'));

  //             element.notificationDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0], +time.split(':')[0], +time.split(':')[1]), 'DD-MM-YYYY').format('DD-MM-YYYY hh:mm a');
  //             //////console.log(startDate);
  //           }

  //           //console.log( element.notificationDate);
  //         });
  //           setTimeout(()=>{
  //             this.service.common.allNotifications = response.data;
  //             this.service.common.dataSourceNotification = new MatTableDataSource(this.service.common.allNotifications);
  //             this.service.common.progressLoader = false;
  //             this.service.common.infiniteLoader = false;
  //           },1000);
  //         }
  //       } else {
  //         if (!scroll) {
  //           this.showMessage = true;
  //           this.service.common.allNotificationsCount = '';
  //           this.service.common.allNotifications = [];
  //         } else {
  //           this.service.common.infiniteLoader = false;
  //         }
  //         this.service.common.progressLoader = false;
  //       }
  //     })
  //   } catch (error) {
  //     //console.log(error)
  //   }

  // }

  /* Name:Shailesh
     use: updating(mark as read) notification status with user code*/
  markAsRead(code) {
    //console.log("read");
    const read = {
      "isRead": true
    }
    try {
      this.service.updateHealthcareNotification(code, read, (response) => {
        if (response.responsecode == 200) {
          this.page = 0;
         // this.getNotification(this.page, this.limit);
          this.service.common.message = response.message;
          this.service.common.successMessage = true;

        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  markAsReadevent(value, code) {
    if(value.checked && code) {
      this.codeListForMarkAsRead.push(code);
    } else {
      let index = this.codeListForMarkAsRead && this.codeListForMarkAsRead?.length > 0 && this.codeListForMarkAsRead.indexOf(code);
      if (index > -1) {
        this.codeListForMarkAsRead.splice(index, 1);
      }
    }
    // //console.log('markAsReadevent_1', this.codeListForMarkAsRead);
  }


  
  markAsReadeventSelected() {
    // //console.log('markAsReadevent_2', this.codeListForMarkAsRead);
   ////console.log(value.checked, code);
    if(this.codeListForMarkAsRead && this.codeListForMarkAsRead?.length > 0){
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '320px',
      data: { msg: 'Are you sure you want to mark the selected notifications as read?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
      let requestBody = {
        notificationCodeList: this.codeListForMarkAsRead,
      };

      const isRead = true;
      this.isMarkAsReadLoading = true;
      // console.log("markAsReadevent_Req", isRead, requestBody);
      // return;
      this.service
        .setIsReadStatusForStaffMultiNotification(isRead, requestBody)
        .subscribe((res: any) => {
          this.isMarkAsReadLoading = false;
          if (res.responsecode == 200) {
           // this.common.For_Notification();
            this.codeListForMarkAsRead = [];
            this.page = 0;
            this.getNotification_AsPer_Role();
            this.service.common.message = res.message;
            this.service.common.successMessage = true;
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        });
      } 
      else
       {
        this.page = 0;
        this.getNotification_AsPer_Role()
        this.codeListForMarkAsRead = [];
      }
    });
    } else {
      this.service.common.message = 'Please select at least one notification for mark as read';
      this.service.common.errorMessage = true;
    }
  }

  markAllAsRead() {
    ////console.log(value.checked, code);
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '320px',
      data: { msg: 'Are you sure you want to all notifications mark as read?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        
    let requestBody = {
      isRead: true,
    };

    this.isMarkAllLoading = true;

    if(this.userType === 'Staff'){
      // console.log("markAllAsRead_Staff", this.service.session.get("userCode"), this.service.session.get('orgCode'), requestBody);
      // return;
      this.service
        .setIsReadStatusForAllStaffNotificationForUser(this.service.session.get('orgCode'), this.service.session.get("userCode"), requestBody)
        .subscribe((res: any) => {
          this.isMarkAllLoading = false;
          if (res.responsecode === 200) {
           // this.common.For_Notification();
            this.dataSource = new MatTableDataSource();
            this.page = 0;
            this.getNotification_AsPer_Role();
            this.service.common.message = res.message;
            this.service.common.successMessage = true;
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        });
    } else if(this.userType === 'Customer'){
        // console.log("markAllAsRead_Customer", this.service.session.get("logedIn_CustomerCode"), this.service.session.get('orgCode'), requestBody);
        // return;
        this.service
          .setIsReadStatusForAllStaffNotificationForCustomer(this.service.session.get('orgCode'), this.service.session.get("logedIn_CustomerCode"), requestBody)
          .subscribe((res: any) => {
            this.isMarkAllLoading = false;
            if (res.responsecode === 200) {
             // this.common.For_Notification();
              this.dataSource = new MatTableDataSource();
              this.page = 0;
              this.getNotification_AsPer_Role();
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
        });
      } else {
        // console.log("markAllAsRead_ORG", this.service.session.get('orgCode'), requestBody);
        // return;
        this.service
          .setIsReadAllNotificationForOrganization(this.service.session.get('orgCode'), requestBody)
          .subscribe((res: any) => {
            this.isMarkAllLoading = false;
            if (res.responsecode === 200) {
            //  this.common.For_Notification();
              this.dataSource = new MatTableDataSource();
              this.page = 0;
              this.getNotification_AsPer_Role();
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          });
        }      
      } 
    });
   }


  /* Name:Shailesh
     use: updating(mark as selected read) notification */
  // markAsSelectedRead() {
  //   //console.log(this.service.allNotificationCode);
  //   const isRead = true;

  //   try {
  //     this.service.updateMultipleHealthcareNotification(isRead, this.service.allNotificationCode, (response) => {
  //       if (response.responsecode == 200) {
  //         this.page = 0;
  //        // this.getNotification(this.page, this.limit);
  //        // this.service.common.message = response.message;
  //        // this.service.common.successMessage = true;
  //         this.service.allNotificationCode.notificationCodeList = [];
  //       }
  //     })
  //   } catch (error) {
  //     //console.log(error)
  //   }
  // }

  // markAsAllRead() {
  //   //console.log(this.service.notificationCodeList.notificationCodeList)
  //   const isRead = true;

  //   try {
  //     this.service.updateMultipleHealthcareNotification(isRead, this.service.notificationCodeList, (response) => {
  //       if (response.responsecode == 200) {
  //         ////console.log(response)
  //         this.page = 0;
  //       //  this.getNotification(this.page, this.limit);
  //        // this.service.common.message = response.message;
  //        // this.service.common.successMessage = true;
  //         this.service.notificationCodeList.notificationCodeList = [];
  //       }
  //     })
  //   } catch (error) {
  //     //console.log(error)
  //   }
  // }

 

  /* Name:Shailesh
     use: to open call patient popup*/
  openPatient(element): void {
    const dialogRef = this.dialog.open(CallPatientComponent, {
      width: '600px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
    });
  }

  /* Name:Shailesh
       use: assigning the appointment to eligible clinician*/
  // assignAppointment(jobDurationCode) {
  //   ////console.log(jobDurationCode)
  //   ////console.log(this.currentSelectedDate)
  //   this.service.common.progressLoader = true;
  //   let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
  //   let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);
  //   try {
  //     this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, this.currentSelectedDate, WeekstartDate, weekendDate, (data) => {
  //       ////console.log(data);
  //       if (data.data.workerWithDistanceList.length > 0) {
  //         const dialogRef = this.dialog.open(AssignAppointmentComponent, {
  //           width: '750px',
  //           disableClose: true,
  //           data: { data, jobDurationCode }
  //         });
  //         dialogRef.afterClosed().subscribe(result => {
  //           //////console.log(result);
  //           if (result != undefined) {
  //             ////console.log("close dialog")
  //           }
  //         });
  //       }
  //       this.service.common.progressLoader = false;

  //     });
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // }

    /* Name:Jatin
      use: for infinite scroll */
  onScroll() {
    this.page = this.page + 1;
    //this.getNotification(this.page, this.limit, 'scroll');
    this.getNotification_AsPer_Role('scroll');
  }


  getNotification_AsPer_Role(is_Scroll?:string)
  {
    this.service.common.infiniteLoader = true ;

      if(this.service.session.get('user_type') === 'Customer')
      {
          this.getAllStaffNotification_ForCustomer(is_Scroll);
      }
      else if(this.service.session.get('user_type') === 'Staff')
      {
         this.getAllStaffNotification_ForStaff(is_Scroll);
       }

      else{
       this.getAllStaffNotification_ForOrganization(is_Scroll);
       }
      
    
  }


  
  getAllStaffNotification_ForOrganization(is_Scroll?:string)
  {
    // if(is_Scroll === 'scroll'){
    //   this.isNotificationDataLoad = true;
    // } else {
    //   this.service.common.progressLoader = true;
    //   this.isNotificationDataLoad = true;
    // }
    this.service.getAllStaffNotification_ForOrganization(this.service.session.get('orgCode'), this.expiredNotifications , this.page ,this.limit).subscribe((res:any) =>{
             this.handleResponse(res, is_Scroll);
       })
  }

  getAllStaffNotification_ForStaff(is_Scroll?:string)
  {
    // if(is_Scroll === 'scroll'){
    //   this.isNotificationDataLoad = true;
    // } else {
    //   this.service.common.progressLoader = true;
    //   this.isNotificationDataLoad = true;
    // }
    this.service.getAllStaffNotification_ForStaff(this.service.session.get('userCode'), this.service.session.get('orgCode'), this.expiredNotifications , this.page ,this.limit).subscribe((res:any) =>{
      this.handleResponse(res, is_Scroll);
    })

  }

  getAllStaffNotification_ForCustomer(is_Scroll?:string)
  {
    // if(is_Scroll === 'scroll'){
    //   this.isNotificationDataLoad = true;
    // } else {
    //   this.service.common.progressLoader = true;
    //   this.isNotificationDataLoad = true;
    // }
    this.service.getAllStaffNotification_ForCustomer(this.service.session.get('logedIn_CustomerCode'), this.service.session.get('orgCode'), this.expiredNotifications , this.page ,this.limit).subscribe((res:any) =>{
      this.handleResponse(res, is_Scroll);
    })
  }


  handleResponse(response:any, is_Scroll?:string)
  {    
    this.service.common.infiniteLoader = false;
    //this.service.common.progressLoader = false;
   // this.isNotificationDataLoad = true;

   if(response.responsecode == 200)
   {
    let temp_array:any  = [];
    if(is_Scroll)
    {
       temp_array = this.dataSource.data;
    }

    response.data.forEach(element => {
      temp_array.push(element)
    });

    // if(temp_array && temp_array.length > 0){
    //   this.showMessage = false;
    // } else {
    //   this.showMessage = true;
    // }

    this.dataSource = new MatTableDataSource(temp_array);
    // Apply the sorting accessor for case-insensitive sorting
    this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
    this.dataSource.sort = this.sort;
    
   }
    
   else{
      if(!is_Scroll)
       this.dataSource = new MatTableDataSource();
   }

  }

  activeTab: string = 'Unread';

  onTabChanged = (tabChangeEvent: MatTabChangeEvent): void => 
    {
    this.activeTab = tabChangeEvent?.tab?.textLabel;
    this.page = 0 ;

    
    if(tabChangeEvent?.tab?.textLabel && tabChangeEvent?.tab?.textLabel  === 'Unread')
      {
         this.expiredNotifications = false;
         //this.displayedColumns.unshift('read')
         this.displayedColumns = [ 'read', 'notificationDate', 'description', ]
      } 
    else
     {
      //this.displayedColumns.shift();
      this.displayedColumns = ['notificationDate', 'description', ]
      this.expiredNotifications = true;
    }

    this.dataSource = new MatTableDataSource();
    this.getNotification_AsPer_Role();

  }


}
