import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
 import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
 
@Injectable()
export class StaffExceptionService {
 

  constructor(
    public service: ApiRootService,
    public http: HttpClient, 
    public _sessionSVC:SessionStorageService
  ) { }


  getAllStaffMemberByOrgCode(organizationCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getAllStaffMember/${organizationCode}`);
  }
 
  saveBlockedStaffs(formData:any) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveBlockedCustomerStaff/', formData)
  }

   
  getAllBlockedStaffByCustomerCode(customerCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getBlockedCustomerStaffByStaffCode/${customerCode}`);
  }

  getAllBlockedStaffByStaffCode(StaffCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getBlockedCustomerStaffForCustomer/${StaffCode}`);
  }

  removeStaffFromBlockList(blockedCustomerStaffCode:string)
  {
    return this.http.delete(this.service.getHealthCareUrl() + `/softDeleteBlockedCustomerStaff/${blockedCustomerStaffCode}`);
  }
 
    
  getCustomerSiteByCustomerCode(customerCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getCustomerAddressListByCustomerCode/${customerCode}`);
  }
}

