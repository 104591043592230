<div class="w-100 pt-3">
  <ng-container *ngFor="let parent_job of cardData; let pjIdx = index;">
  <ng-container *ngIf="parent_job?.appointmentDetailList && parent_job.appointmentDetailList.length > 0; else noJobs">

    <!-- <span title="P: {{parent_job | json}}">P : Data</span> -->
    <ng-container *ngFor="let element of parent_job.appointmentDetailList; let cjIdx = index; let last = last">
      <mat-card class="mx-auto card-appointment mt-3 mb-2">
      <div class="w-100">
      <div class="row job-content card-border" [class.childCardBorder]="pjIdx > 0 || cjIdx > 0" [style.border-color]="element.jobColor">
        <!-- <span title="C: {{element | json}}">C : {{element?.customerAddress}}</span> -->
        <div class="col-md-12 col-lg-12 p-0">
          <div class="row div-border m-0 mb-3 pt-1">

            <div class="col-4 p-0 pb-3">
              <div class="row">
              <p>Customer Name</p>
              <small>{{element?.customerName}}</small>
            </div>
            </div>

            <div class="col-4 ps-0 pb-3">
              <p>{{ 'main.labels.Job Site' | translate }}</p>
              <small>{{element?.customerAddress}}</small>
          </div>

            <div class="col-4 pb-3">
              <div class="row" *ngFor="let job_element of element.organizationBasedProcedureDetailsList; let cjobIdx = index; let last = last">
                <!-- <span title="CC: {{job_element | json}}">CC : {{job_element?.procedureName}}</span> -->
                <div class="col p-0 m_width_50">
                  <div class="w-100">
                  <img class="img_jobtype"
                    [src]="job_element?.procedureIcon ? img_serverPath+job_element.procedureIcon : 'assets/common_images/profile.jpg'">
                    </div>
                </div>
                <div class="col pt-3">
                    <p>{{job_element?.procedureName}}</p>
                </div>
              </div>
            </div>            
           
          </div>

          <div class="row m-0 pb-3">
            <div class="col-8 p-0">
              <div class="text-nowrap">
                <p>Shift</p>
              <small class="text-nowrap w-100">{{element?.startTime}} to {{element?.endTime}}</small>
              </div>
            </div>
            
            <div class="col-4 p-0">
              <div class="text-nowrap">
                <p>Status</p>
              <small class="text-nowrap w-100">{{element?.status | capitalize}}</small>
              </div>
            </div> 

          </div>

          <div class="row m-0 d-none">
            <div class="col-12 p-0 text-end">
                  <button mat-raised-button color="primary" class="me-2 mb-2">
                    Accept
                  </button>

                  <button mat-raised-button color="warn" class="mb-2">
                    Reject
                  </button>
            </div>
          </div>

        </div>

      </div>
      </div>
      </mat-card>
    </ng-container>
  </ng-container>
  <ng-template #noJobs>
    <div class="w-100">
      <p class="text-danger">No jobs are present for selected day</p>
    </div>
  </ng-template>
</ng-container>
</div>
