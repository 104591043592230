import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  constructor(
    public service: ApiRootService,
    public http: HttpClient,
    public storage: SessionStorageService,
    public common: CommonService
  ) { }

    /* Name:Vivek Chauhan
     use: for getting all Distribution User List */
     getDistributionUserListByDistributionCode(distributionCode, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getDistributionUserListByDistributionCode/' + distributionCode).subscribe((data) => {
        callback(data);
      });
    }

    /* Name:Vivek Chauhan
     use: for Srarch Distribution list by Search Text */
     getListDataBySearch(orgCode, searchText, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getLicenceMasterByOrganizationCodeByPage/'+ orgCode + '/' + searchText).subscribe((data) => {
      callback(data);
    });
  } 

  /* Name:Vivek Chauhan
     use: for getting all Distribution List by page */
     getListDataByPage(orgCode, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getDistributionListByOrganizationCodeByPage/' + orgCode+ '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  } 

  /* Name:Vivek Chauhan
     use: for getting all Distribution details by user code */
  getAllUserTrackingByUserCodeAndDate(userCode,date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllUserTrackingByUserCodeAndDate/' + userCode + '/' + date).subscribe((data) => {
      callback(data);
    });
  }

      /* Name:Vivek Chauhan new API
    use: for adding/creating new Distribution */
    createOrUpdate(data, callback) {
      return this.http.post(this.service.HEALTHCARE_URL + '/saveOrUpdateDistribution', data).subscribe((data) => {
          callback(data);
      });
      }


  /* Name:Vivek Chauhan new API
    use: for delete/remove Distribution */
    softDeleteByCode(code, callback) {
        return this.http.delete(this.service.HEALTHCARE_URL + '/softDeleteDistributionDetailsByDistributionCode/' + code).subscribe((data) => {
          callback(data);
    
        })
      }

  /* Name:Vivek Chauhan
  use: for getting All Staff  member */
  getAllStaffMemberByOrganizationCode(organizationCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllStaffMemberByOrganizationCode/' + organizationCode).subscribe((data) => {
      callback(data);
    })
  }

  getAllStaffMemberByOrgCode(organizationCode:string, callback){
    return this.http.get(this.service.getHealthCareUrl() + '/getAllStaffMember/' + organizationCode).subscribe((data) => {
      callback(data);
    })
  }

  v2_getDistributionUserListByDistributionCode(distributionCode,page:number,limit:number, searchText = undefined) 
  {
    let url:string;
    if(searchText)
    {
     url = `/v2/getDistributionUserListByDistributionCode/${distributionCode}/${searchText}?page=${page}&limit=${limit}`
    }
    else
    {
      url = `/v2/getDistributionUserListByDistributionCode/${distributionCode}?page=${page}&limit=${limit}`
    }

    return this.http.get(this.service.getHealthCareUrl() + url);
  }

}
