import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { TodaysJobService } from './dashboard-todays-job.service';
import { CommonService } from 'src/app/shared/common.service';
import { Observable, Subject, interval, map, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NewJobCardService } from 'src/app/components/new-job-card/new-job-card.service';
import * as moment from 'moment';
import { codeInput, cust_st_jt_dts } from 'src/app/shared/interface/core.interface';

@Component({
  selector: 'app-dashboard-todays-job',
  templateUrl: './dashboard-todays-job.component.html',
  styleUrls: ['./dashboard-todays-job.component.scss']
})
export class DashboardTodaysJobComponent implements OnDestroy{

  displayedColumns: string[] = [  'customer','jobSite','shift','staffRequired','staffScheduled'];
  dataSource = new MatTableDataSource();
  page:number= 0;
  limit:number = 500;
  //todaysJobList :any =[];
  selectedDay_Filter:string = 'Today';
  private webSocket$ = new Subject<void>();
  
  // protected job_shortData_Modal:cust_st_jt_dts ;
  protected job_shortData_Modal: codeInput ;
  protected dialogRef: any;
 

  @ViewChild('schdeuledStaff_popUp') schdeuledStaff_popUp: TemplateRef<any>;
  protected showSpinner : boolean = false ;

  constructor(
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    private _todaysJobSVC : TodaysJobService ,
    private _commonSVC : CommonService,
    private dialog: MatDialog,
    private _newCardSVC: NewJobCardService,
    )
  {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
    this._commonSVC.getpercentData();
    this.getTodaysJob();
    this.subscribeSocket_TodaysJob();
  }
 

  subscribeSocket_TodaysJob()
  {
    this._commonSVC.webSocketSubcribeTopic("OrgDashboardTodayJobs_"+this._sessionStorageSVC.get('orgCode'))
    .pipe(takeUntil(this.webSocket$))
    .subscribe((res:any) =>
    {
      
      if(res.body)
      {
        // this.operationalStatusList =  JSON.parse(res.body);
        this.getTodaysJob();
      }
    });
  }


  // subscribeDashboardRefresh()
  // {
  // //   // unsubscribing this subject from dashboard component destroy()
  // //  this._commomSVC.refreshDashboardSubject.subscribe(res =>{
  // //   if(res)
  // //   { 
  // //     this.getTodaysJob();
  // //   }
    
  // //  })
  // }

  getTodaysJob(is_pageScroll?:string)
  {
    let userCode = this._sessionStorageSVC.get('orgCode');
    this._todaysJobSVC.getTodaysJobByPage(userCode , this.selectedDay_Filter , this.page , this.limit).subscribe((res:any) =>{
      if(res.responsecode == 200){

        let temp_array :any =[];

        if(is_pageScroll)
        {
          temp_array = this.dataSource.data ;
        }

        res.data.forEach(ele_a =>{
 
              const Index = this._commonSVC.dashboardColorData.findIndex(data => data.percent == Math.round(ele_a.fullFilledRatio));
              // ////console.log(colodIndex, element.fullfillmentRatio);
              if (Index !== -1) {
                ele_a.BG_Color = this._commonSVC.dashboardColorData[Index].class;
              } else {
                //element.dataPercent = 0;
              } 
              
           temp_array.push(ele_a);

         });

        //  this.dataSource =   new MatTableDataSource(res.data);
        this.dataSource =   new MatTableDataSource(temp_array);

       }
    })
  }

  onScroll(){
    // this.page = (this.page + 1);
    // this.getTodaysJob('scroll');
  }


  filter(filterVale)
  {
    this.selectedDay_Filter = filterVale ;
    this.dataSource =   new MatTableDataSource();
    this.getTodaysJob();
  }



  ngOnDestroy(): void {
    this.webSocket$.next();
    this.webSocket$.unsubscribe();
  }


  
  showScheduledStaff(selectedJob:any) 
 {

  this.job_shortData_Modal = 
  {
     date: selectedJob.jobDateFixedFormat,
     appointmentsCode: selectedJob.appointmentsCode ,
     appointmentsProceduresCode : selectedJob.appointmentsProceduresCode,
     
  }

  //  this.job_shortData_Modal = {
  //    customerName: selectedJob.customerName,
  //    date: selectedJob.jobDateFixedFormat,
  //    siteName : selectedJob.siteName,
  //    siteAddress : selectedJob.siteAddress ,
  //    appointmentsCode: selectedJob.appointmentsCode ,
  //    appointmentsProceduresCode : selectedJob.appointmentsProceduresCode,
  //    jobTypeName : selectedJob.jobTypeName,
  //    jobTypeIcon : selectedJob.jobTypeIcon,
  //    rateCard : selectedJob.showRateCard,  
  //    jobTypeColor : selectedJob.jobColor 
  //  }
 
 
    // this.job_shortData_Modal = selectedJob;
    // this.getAllScheduledStaff();

   this.dialogRef = this.dialog.open(this.schdeuledStaff_popUp, { width: '85%',  panelClass: 'popup-pos' });

   this.dialogRef.afterClosed().subscribe((result) => {
  });

 }
 
 refreshData(event:any) {
  // this.dialogRef.close();
  // this.resetStaffPopUp();
  this.dataSource =   new MatTableDataSource();
  this.getTodaysJob();
}


}
