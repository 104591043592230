<div class="trade-shift">
  <h6>Trade Shift</h6>
  <form [formGroup]="tradeShift" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-5 align-self-center">
        <p>Date</p>
      </div>
      <div class="col-md-7">
        <p>{{tradeShiftData.jobStartDate.split(' ')[0]}}</p>
      </div>
      <div class="clearfix"></div>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-5 align-self-center">
        <p>Time</p>
      </div>
      <div class="col-md-7">
        <p>{{tradeShiftData.jobStartDate.split(' ')[1]}} - {{tradeShiftData.jobEndDate.split(' ')[1]}}</p>
      </div>
      <div class="clearfix"></div>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-5 align-self-center">
        <p>Patient Name</p>
      </div>
      <div class="col-md-7">
        <p>{{tradeShiftData.patientName}}</p>
      </div>
      <div class="clearfix"></div>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-5 align-self-center">
        <p>Patient Address</p>
      </div>
      <div class="col-md-7">
        <p>{{tradeShiftData.patientAddress}}</p>
      </div>
      <div class="clearfix"></div>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-5 align-self-center">
        <p>Phone Number</p>
      </div>
      <div class="col-md-7">
        <p>+{{tradeShiftData.pCountryCode}} {{tradeShiftData.phoneNumber}}</p>
      </div>
      <div class="clearfix"></div>
      <hr>
    </div>
    <div class="row mb-3">
      <div class="col-md-5 align-self-center">
        <p class="m-0">Trade Shift With</p>
      </div>
      <div class="col-md-7">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Clinician</mat-label>
          <mat-select formControlName="worker">
            <mat-option *ngFor="let worker of otherEligibleWorker" [value]="worker.workerCode">
              {{worker.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="text-center buttons-bottom">
      <a class="cursor-pointer" (click)="cancel()">Cancel</a>
      <button mat-raised-button color="primary" [disabled]="!tradeShift.valid">Submit</button>
    </div>
  </form>
</div>
