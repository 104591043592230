<div class="row">
  <div class="col-md-12 col-sm-12">
     <div class="row">
        <div class="col-md-10 align-self-center">
           <span class="d-flex">
              <h6 *ngIf="!isOpenForBulkEmail" class="skill-test">{{popupTitle}}</h6>
              <h6 *ngIf="isOpenForBulkEmail" class="skill-test">Send Bulk Email</h6>
              <!-- <span class="headerNoteTitleBtn pt-1">
                 <span class="ps-4 cursor-pointer" (click)="emailTemplateService.common.addUpdateNotes(data.noteList)">
                   <span class="notebtnContainer" *ngIf="data.templateCode && data?.noteList?.length > 0"><img class="p-1 notebtnIc" src="assets/images/note-icon-active.svg" /><span class="ps-1 noteBtnTxtActive">Note</span></span>
                     <span class="notebtnContainer" *ngIf="!data.templateCode || data?.noteList?.length === 0"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" /><span class="ps-1 noteBtnTxtInactive">Note</span></span>
                  </span>
                </span> -->
           </span>
        </div>
        <div class="col-md-2">
           <div class="row justify-content-end">
              <button *ngIf="!isOpenForBulkEmail" class="btn-close cursor-pointer" (click)="onNoClick()"></button>
              <button *ngIf="isOpenForBulkEmail" type="button" class="nowrapTxt w-auto me-2" mat-raised-button color="primary" (click)="!isSendLoading && onSend()"> Send <span *ngIf="isSendLoading" class="spinner-border spinner-border-sm"></span>
              </button>
           </div>
        </div>
     </div>
     <div class="w-100 position-relative" [class.scrollContent]="!isOpenForBulkEmail">
        <form [formGroup]="templateForm">

         <ng-container *ngIf="data?.isSend || isOpenForBulkEmail">
            <div class="col-md-12 mt-4">
               <div class="w-100">
                <div class="form-label">
                   <span>Email To</span>
                 </div>
                  <angular2-multiselect [data]="allStaffMemberList" (onSelect)="onItemSelect($event, 'user')" (onDeSelect)="onItemSelect($event, 'user')" (onSelectAll)="onItemSelect($event, 'user')" (onDeSelectAll)="onItemSelect($event, 'user')" [(ngModel)]="selectedUsers" [settings]="dropdownSettings" formControlName="userCodeList"></angular2-multiselect>
               </div>
               <div class="w-100 text-center orLabel">OR</div>
            </div>
            <div class="col-md-12 mt-3">
               <div class="w-100">
                <div class="form-label">
                   <span>Email To</span>
                 </div>
                  <angular2-multiselect [data]="allSkillsList" (onSelect)="onItemSelect($event, 'skill')" (onDeSelect)="onItemSelect($event, 'skill')" (onSelectAll)="onItemSelect($event, 'skill')" (onDeSelectAll)="onItemSelect($event, 'skill')" [(ngModel)]="selectedSkills" [settings]="dropdownSkillSettings" formControlName="skillCodeList"></angular2-multiselect>
               </div>
               <div class="w-100 text-center orLabel">OR</div>
            </div>
            <div class="col-md-12 mt-3">
               <div class="w-100">
                <div class="form-label">
                   <span>Email To</span>
                 </div>
                  <angular2-multiselect [data]="allDistributionsList" (onSelect)="onItemSelect($event, 'distribution')" (onDeSelect)="onItemSelect($event, 'distribution')" (onSelectAll)="onItemSelect($event, 'distribution')" (onDeSelectAll)="onItemSelect($event, 'distribution')" [(ngModel)]="selectedDistributions" [settings]="dropdownDistributionSettings" formControlName="distributionsCodeList"></angular2-multiselect>
               </div>
            </div>
            <div class="w-100">
               <span class="help-block err"
                  *ngIf="(!submitted && ((!selectedUsers || selectedUsers?.length === 0) && (!selectedSkills || selectedSkills?.length === 0) && (!selectedDistributions || selectedDistributions?.length === 0)))">Please select a user, skill or distribution</span>
            </div>
         </ng-container>
         
         <ng-container *ngIf="isOpenForBulkEmail">
         <div class="w-100 p-0 m-0">        
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
              <mat-label>Template</mat-label>
              <mat-select placeholder="Select Template" formControlName="emailTemplateName" (selectionChange)="changeTemplate($event)">
                <mat-option>
                  <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'Record not found'" (keyup)="templateSearch($event)"></ngx-mat-select-search>
                </mat-option>
                <ng-container *ngFor="let template of templateList_Filter">
                <mat-option [value]="template">
                  {{template?.emailTemplateName}}
                </mat-option>
              </ng-container>
              </mat-select>
            </mat-form-field>
            <span class="help-block err" *ngIf="templateForm.controls.emailTemplateName.errors?.required && (!submitted || templateForm.controls.emailTemplateName.touched)">Please select a template</span>       
          </div>
         </ng-container>

           <div class="col-md-12">
              <div class="form-group mb-0">
                 <mat-form-field appearance="outline" floatLabel="always" >
                    <mat-label>Subject</mat-label>
                    <input autoClose matInput formControlName="emailSubject" type="text"
                       placeholder="" maxlength="150" [disabled]="data.isSend">
                 </mat-form-field>
                 <span class="help-block err"
                    *ngIf="templateForm.controls.emailSubject.errors?.required && (!submitted || templateForm.controls.emailSubject.touched)">Template Subject Required</span>
              </div>
           </div>

           <ng-container *ngIf="!isOpenForBulkEmail">
           <div class="col-md-12">
              <div class="form-group mb-0">
                 <mat-form-field appearance="outline" floatLabel="always" >
                    <mat-label>Template Name</mat-label>
                    <input autoClose matInput formControlName="emailTemplateName" type="text"
                       placeholder="" maxlength="150" [disabled]="data.isSend">
                 </mat-form-field>
                 <span class="help-block err"
                    *ngIf="templateForm.controls.emailTemplateName.errors?.required && (!submitted || templateForm.controls.emailTemplateName.touched)">Template name is required</span>
              </div>
           </div>
         </ng-container>           
           <div class="col-md-12">
              <div class="form-group mb-0">
                 <quill-editor #quill [styles]="{height: '250px'}" class="w-100" [(modules)]="modules" (keyup)="matchKeysIsExistOrNot()" placeholder=""></quill-editor>
              </div>
           </div>
        </form>
     </div>
     <div *ngIf="!isOpenForBulkEmail" class="d-flex offset-md-1 justify-content-end">
        <!-- <button type="button" class="nowrapTxt" mat-raised-button color="primary"> Preview </button> -->
        <button *ngIf="!data?.isSend" type="button" class="nowrapTxt ms-2" mat-raised-button color="primary" (click)="!isSaveLoading && onSubmit()"> {{ popupTitle === 'Edit Template' ? 'Update' : 'Save'}} <span *ngIf="isSaveLoading" class="spinner-border spinner-border-sm"></span></button>
        <button *ngIf="data?.isSend" type="button" class="nowrapTxt ms-2" mat-raised-button color="primary" (click)="!isSendLoading && onSend()"> Send <span *ngIf="isSendLoading" class="spinner-border spinner-border-sm"></span>
        </button>
     </div>
  </div>
</div>