<!-- <p>distribution-list works!</p> -->
<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="float-start header-name">{{'distributionList.labels.Distribution List' | translate }}
  </div>

  <ng-template [ngIf]="hasPermission.addPermission === true">
  <div class="col-lg-4 col-md-4 col-sm-12 searchfilter text-end">
     <button mat-raised-button color="primary" (click)="addNew()">Add</button>
  </div>
</ng-template>

</div>
<div class="w-100">
  <div class="col-md-12">
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">

      <ng-container matColumnDef="distributionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 85%;">{{'distributionList.labels.Distribution Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.distributionName}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
        <td mat-cell *matCellDef="let element" class="action">

           <mat-icon class="cursor-pointer me-2" (click)="viewDetails(element)">visibility</mat-icon>
          
          <ng-template [ngIf]="hasPermission.editPermission === true">
          <mat-icon class="cursor-pointer" (click)="onEdit(element)">edit</mat-icon>
          </ng-template>

          <ng-template [ngIf]="hasPermission.delPermission === true">
          <mat-icon class="cursor-pointer ms-2" (click)="softDeleteByCode(element)">delete</mat-icon>
          </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!service.common.progressLoader && !service.common.infiniteLoader) && listData.length === 0" class="p-3 noList">{{'distributionList.messages.Distribution are not available' | translate }}</div>
    </div>

    <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>
    
  </div>
</div>



<ng-template #distributionDetails_TempRef>
  <div class="row  ">
     <div class="col-md-6 align-self-center">
       <h6 class="dialog-header pt-2">Distribution Details
       </h6>
     </div>
     <div class="col-md-6">
       <div class="row justify-content-end">
         <button class="btn-close cursor-pointer" mat-dialog-close></button>
       </div>
     </div>
   </div> 

   <div class="row">
   <div class="col-12">
    <span class="primary-color">Distribution Name :&nbsp;&nbsp;</span>
    <span>{{tbl_selectedDistribution?.distributionName}}</span>
   </div>

   <!-- <div class="col-12 mt-2">
    <span class="primary-color">Staff : &nbsp;&nbsp;</span>
  </div> -->
  
  <div class="col-8"></div>

    <div class="col-4">
       <input autocomplete="off" #staff_SearchText type="text" placeholder="Search staff"
        class="searchfilter form-control" name="search-name"  (keyup)="staffSearch(staff_SearchText.value)">
    </div>
   

    <div class="col-md-12 table-scroll-height table-responsive table-distribution"
    infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="100"  
    (scrolled)="onScrollDistributionStaff()" [scrollWindow]="false" [fromRoot]="true">

    <table class="table table-nowrap mb-0 position-relative">
      <thead>
          <tr>
               <th>Staff Name</th>
               <th>Email</th>
               <th>Phone</th>
             
          </tr>
      </thead>
      <tbody>
        <ng-template [ngIf]="distributionUsers.length > 0" [ngIfElse]="_noDataFound">
          <tr *ngFor="let element of distributionUsers">

              <td class="text-nowrap"> 
                <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
              </td>
             <td>{{element.email}}</td>
              <td>{{element.mobileNumber}}</td>
 
              
          </tr>
          </ng-template>

          <ng-template #_noDataFound>
            <div class="row no-job-found-div" *ngIf="!is_Loading">
              <span class="text-danger no-job-found-text ms-4">No staff found</span>
            </div>
        
            <ng-template [ngIf]="is_Loading">
              <div class="div w-100 mt-5">
                <mat-spinner class="center_50_px_spinner"></mat-spinner>
                <p class="mt-3 mb-3 w-100">Please Wait ...</p>
              </div>
            </ng-template>
        
          </ng-template>

     </tbody>
  </table>

  </div>

    

   </div>

 
 </ng-template>