import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { ShiftService } from '../shift.service';
import { CommonService } from 'src/app/shared/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.scss']
})
export class AddShiftComponent implements OnInit {
  @Input() isShortcutModalOpen: any;
  @ViewChild('addShift_TempRef') addShiftRef: ElementRef;


  addShift_Form: FormGroup;
  selectedTime: Date = new Date();
  dataSource = new MatTableDataSource();
  // displayedColumns: string[] = ['shiftName', 'startTime', 'endTime','breakStartTime', 'breakEndTime', 'grace' ,'check-in','actions'];
  displayedColumns: string[] = ['startTime', 'endTime', 'actions'];

  page: number = 0;
  limit: number = 10;
  is_formSubmitted: boolean = false;
  is_btnLoading: boolean = false;

  startTime_Filter: any = [] = this._commonSVC.time12Hrs;
  endTime_Filter: any = [] = this._commonSVC.time12Hrs;
  endTime_TimeList: any = [] = this._commonSVC.time12Hrs;

  constructor(
    public dialogRef: MatDialogRef<AddShiftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    private dialog: MatDialog,
    private _shiftSVC: ShiftService,
    private _commonSVC: CommonService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
  }

  ngOnInit(): void {
    // this.startTime_Filter = this.time;
    // this.endTime_Filter = this.time;
    this.initializeForm();
    this.getShiftsByPage();
    this.bindForm()
  }

  // ngAfterViewInit(): void {
  //   this.getQueryParam();
  // }

  bindForm() {
    if (this.data) {
      this.addShift_Form.controls.shift_master_code.setValue(this.data.shiftMasterCode);
      this.addShift_Form.controls.start_time.setValue(this.data.startTime);
      this.addShift_Form.controls.end_time.setValue(this.data.endTime);

    }

    if (this.data?.shiftBreakResponseList && this.data?.shiftBreakResponseList?.length > 0) {
      this.shiftBreakFormList.clear();
      this.data.shiftBreakResponseList.forEach((element: any) => {
        const newJob = this.fb.group({
          durationMin: new FormControl(element.durationMin, [Validators.required, Validators.pattern("^[1-9][0-9]?$|^100$")]),
          breakType: new FormControl(element.breakType, [Validators.required]),
          paidStatus: new FormControl(element.paidStatus, [Validators.required]),
          shiftBreakCode: new FormControl(element.shiftBreakCode),
        })
        this.shiftBreakFormList.push(newJob);
      });
    }
  }

  // getQueryParam() {
  //   this.route.queryParams
  //     .subscribe(params => {
  //       if (params && params.action && params.action == 'new_shift') {
  //         this.openShiftPopUp(this.addShiftRef);
  //       }
  //       else {

  //       }
  //     }
  //     );
  // }



  initializeForm() {
    this.addShift_Form = this.fb.group({
      start_time: new FormControl('', [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)]),
      end_time: new FormControl('', [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)]),
      shift_master_code: new FormControl(''),
      shiftBreakRequestList: this.fb.array([])
    });

  }

  get shiftBreakFormList() {
    return this.addShift_Form.get('shiftBreakRequestList') as FormArray;
  }

 
  addNewForm() {
    const newJob = this.fb.group({
      // durationMin: new FormControl('', [Validators.required, Validators.pattern(/^-?([0-5][0-9])?$/)]),
      durationMin: new FormControl('', [Validators.required, Validators.pattern("^[1-9][0-9]?$|^100$")]),
      breakType: new FormControl('', [Validators.required]),
      paidStatus: new FormControl('', [Validators.required]),
      shiftBreakCode: new FormControl('')
    })
    this.shiftBreakFormList.push(newJob);
  }

  deleteFrom_FormArray(i: number) {
    this.shiftBreakFormList.removeAt(i)
  }

  resetForm() {
    this.is_formSubmitted = false;
    this.addShift_Form.reset();
    this.shiftBreakFormList.clear();  // will empty formArray
  }

  getShiftsByPage(isTBL_Scroll?: string) {
    this._shiftSVC.getShiftMasterByPage(this._sessionStorageSVC.get('orgCode'), this.page, this.limit).subscribe((res: any) => {
      if (res.responsecode == 200) {
        if (isTBL_Scroll) {
          let temp_array = this.dataSource.data;
          res.data.forEach(element => {
            temp_array.push(element);
          });
          this.dataSource = new MatTableDataSource(temp_array);
        }
        else {
          this.dataSource = new MatTableDataSource(res.data);
        }

      }
    })
  }

  onScroll() {
    this.page = (this.page + 1);
    this.getShiftsByPage('scroll');
  }

  startTimeChange(event: any) {
    this.addShift_Form.controls.start_time.setValue(event);
  }

  endTimeChange(event: any) {
    this.addShift_Form.controls.end_time.setValue(event);
  }

  // openShiftPopUp(HTMLReference: any): void {

  //   this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true });

  //   this.dialogRef.afterClosed().subscribe(result => {
  //     this.resetForm();
  //     if (result) {
  //       this.page = 0;
  //       this.getShiftsByPage();
  //     }

  //   });
  // }

  // updateShift(data: any, HTMLReference: any) {

  //   this.addShift_Form.controls.shift_master_code.setValue(data.shiftMasterCode);
  //   this.addShift_Form.controls.start_time.setValue(data.startTime);
  //   this.addShift_Form.controls.end_time.setValue(data.endTime);

  //   if (data?.shiftBreakResponseList && data?.shiftBreakResponseList?.length > 0) {
  //     this.shiftBreakFormList.clear();
  //     data.shiftBreakResponseList.forEach((element: any) => {
  //       const newJob = this.fb.group({
  //         durationMin: new FormControl(element.durationMin, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
  //         breakType: new FormControl(element.breakType, [Validators.required]),
  //         paidStatus: new FormControl(element.paidStatus, [Validators.required]),
  //         shiftBreakCode: new FormControl(element.shiftBreakCode),
  //       })
  //       this.shiftBreakFormList.push(newJob);
  //     });
  //   }

  //   this.dialogRef = this.dialog.open(HTMLReference, { width: '50%', disableClose: true });
  //   this.dialogRef.afterClosed().subscribe(result => {
  //     this.resetForm();
  //     if (result) {
  //       this.page = 0;
  //       this.getShiftsByPage();
  //     }

  //   });

  // }

  closePopUp() {
    this.dialogRef.close();
  }


  add_UpdateShift() {
    this.is_formSubmitted = true;
    if (this.addShift_Form.valid) {
      this.is_btnLoading = true;

      let array: any = [];

      if (this.shiftBreakFormList.controls.length > 0) {

        this.shiftBreakFormList.controls.forEach((element: any) => {
          let temp: any = {
            "breakType": element.value.breakType,
            "durationMin": element.value.durationMin ? parseInt(element.value.durationMin) : element.value.durationMin,
            "paidStatus": element.value.paidStatus,
            "shiftBreakCode": element.shiftBreakCode ? element.shiftBreakCode : null
          }
          array.push(temp);
        })
      }

      var Jdata: any = {
        "organizationCode": this._sessionStorageSVC.get('orgCode'),
        "shiftMasterCode": this.addShift_Form.controls.shift_master_code.value || null,
        "endTime": this.addShift_Form.controls.end_time.value ? this._commonSVC.formateTime(this.addShift_Form.controls.end_time.value) :  this.addShift_Form.controls.end_time.value,
        "startTime": this.addShift_Form.controls.start_time.value ? this._commonSVC.formateTime(this.addShift_Form.controls.start_time.value) : this.addShift_Form.controls.start_time.value,
        "shiftBreakRequestList": array
      }

      this._shiftSVC.saveAndUpdateShift(Jdata).subscribe((res: any) => {
        this.is_formSubmitted = false;
        this.is_btnLoading = false;
        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          // this.dialogRef.close(true);
          this.dialogRef.close(res);
        }
        else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }

      })
    }

  }

  deleteShift(element: any) {
    if (element.shiftMasterCode) {
      this._shiftSVC.softDeleteShift(element.shiftMasterCode).subscribe((res: any) => {

        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.page = 0;
          this.getShiftsByPage();
        }
        else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      })
    }
  }


  startTimeKeyUp_ForFilter(value: any) {
    this.startTime_Filter = this._commonSVC.time12Hrs.filter(ele => ele.toLowerCase().includes(value.toLowerCase()));
  }

  endTimeKeyUp_ForFilter(value: any) {
    this.endTime_Filter = this.endTime_TimeList.filter(ele => ele.toLowerCase().includes(value.toLowerCase()));
  }

  // OnBlur_StartTime(startTime: string) {
  //   let list = this._commonSVC.setEndTimeArray(startTime);
  //   this.endTime_TimeList = list.length > 0 ? list : this.endTime_TimeList;
  //   this.endTime_Filter = Array.from(this.endTime_TimeList);
  // }


}

