<div class="d-flex flex-row justify-content-between w-100 mb-3 logo align-items-center">
  <div class="col-lg-5 col-md-5 col-sm-12 float-start header-name"> {{ 'Customers.labels.Customers' | translate}} </div>

  <div class="col-lg-2 col-md-2 col-sm-12 "> 
    <mat-icon  [class.active]="viewPoint==='cardview'" class="viewpoint me-2" (click)="changeView('cardview')">view_module</mat-icon>
    <mat-icon  [class.active]="viewPoint==='listview'" class="viewpoint" (click)="changeView('listview');">list</mat-icon>
  </div>

  <!-- <div class="col-lg-2 col-md-2 col-sm-12 searchpat">
    
  </div> -->

  <!-- <ng-template [ngIf]="hasPermission.addPermission === true"> -->
  <div class="col-lg-5 col-md-5 col-sm-12 searchpat ps-2">
    <div class="w-100 justify-content-end" style="display: inline-flex;">
    <input type="text" class="form-control me-2" id="search-patient" autocomplete="off"
      placeholder=" {{ 'Customers.labels.Search Customer' | translate}}" name="search-patient" [(ngModel)]="searchTerm"
      (keyup)="search($event)">
    <!-- <mat-icon class="cursor-pointer">search</mat-icon> -->
    <button mat-raised-button color="primary" class="me-2 p-0 resetBtn" title="Reset All Filter" (click)="refreshRestAllFilters();getData(0, 30);">
      <mat-icon>restart_alt</mat-icon>
    </button>

    <button mat-raised-button color="primary" class="dropdown-toggle dropdown-toggle-no-caret me-2 sortBtn" data-bs-toggle="dropdown" aria-expanded="false">
      <span>Sort By</span>
      <span *ngFor="let sortItem of allSortNameList">
        <span *ngIf="sortItem?.isActive">: <b matTooltip="{{sortItem?.name}}">{{sortItem?.order}}</b></span>
      </span>
    </button>
    <div class="dropdown-menu">
      <ng-container *ngFor="let sortItem of allSortNameList; index as sortIdx">
        <a class="dropdown-item" href="javascript:void(0)" [class.active]="sortItem.isActive" (click)="sortBy(sortItem)"><b>{{sortItem?.order}}</b> {{sortItem?.name}}</a>
      </ng-container>
    </div>

    <ng-template [ngIf]="hasPermission.addPermission">
    <button mat-raised-button routerLink="/dashboard/customer/customer-add" title="{{ 'Customers.labels.Add Customer' | translate}}" color="primary">Add</button>    
  </ng-template>

  </div>
  </div>
 <!-- </ng-template> -->

</div>


<mat-progress-bar mode="indeterminate" *ngIf="service.common.infiniteLoader"></mat-progress-bar>
<div class="table-scroll-height position-relative" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
 
  <ng-container *ngIf="viewPoint === 'cardview'">
      <div class="prof-view-card">

    <div class="row row-cols-1 row-cols-md-3 g-4 mb-4 ps-2 pe-2 pt-2" style="overflow: hidden;">
      <div class="col-md-3 col-sm-6 col-12 col-lg-4 col-xl-3" *ngFor = "let element of customerList_Filter; let i = index">
        <div class="card customerCard" [class.inactiveCard]="!element.isActive" >
        
        <div class="d-flex justify-content-between align-items-center mt-1">

           <div class="dropdown profile-action">
            <!-- <span class="pe-2">
              <mat-icon [title]="'Send Email'" (click)="sendTemplate(element)">send</mat-icon>
            </span>
            <span class="pe-2" [style.color]="element?.isContactRegistered ? '#34b6b9' : '#f44336'">
              <span *ngIf="element?.unRegisteredCount > 0" [title]="element?.unRegisteredCount ? 'Unregistered Count' : ''" class="position-absolute translate-middle badge rounded-pill bg-danger badgePos" (click)="openContactDialog(element.customerCode)">{{element?.unRegisteredCount}}</span>
              <mat-icon [title]="element?.isContactRegistered ? 'Registered' : 'Not Registered'" (click)="openContactDialog(element.customerCode)">how_to_reg</mat-icon></span>
            <span title="Note">
              <img class="noteIc curser-pointer" (click)="openNotePopUp(element, element.customerName)" [src]="element.isNoteAdded ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
            </span> -->
            <a
            (click)="$event.stopPropagation()"
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <ng-template [ngIf]="hasPermission.editPermission === true">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                [routerLink]="['/dashboard', 'customer', 'customer-add']" [queryParams]="{ patientCode: element.customerCode }"
                ><span><mat-icon>edit</mat-icon></span><span class="pt-1">Edit</span>
              </a>

              </ng-template>

              <ng-template [ngIf]="hasPermission.delPermission === true">
              <a class="dropdown-item" (click)="$event.stopPropagation(); Active_InActiveCustomer(element,i)">
                <span><mat-icon [ngStyle]="{'color':element.isActive ? 'red' : 'green'}">
                  {{element.isActive ? 'highlight_off' : 'check_circle'}}</mat-icon>
                </span><span class="pt-1">{{element.isActive ? 'Inactive' : 'Áctive'}}</span>
              </a>
              </ng-template>

              <a
                class="dropdown-item"
                href="javascript:void(0)" (click)="$event.stopPropagation(); openDialog(element.customerCode)"
                ><span><mat-icon>calendar_today</mat-icon></span><span class="pt-1">View Schedule</span></a
              >
            </div>
          </div>

        </div>          
    
          <div class="route p_t_16"  [routerLink]="hasPermission.editPermission === true ? ['/dashboard', 'customer', 'customer-add'] : null"
           [queryParams]="hasPermission.editPermission === true ? { patientCode: element.customerCode } : null">

            <ng-template [ngIf]="hasPermission.addPermission === true" [ngIfElse]="imgTemp">
           <div class="w-100 justify-content-between text-center imgAtTop">
             <input class="fileUpload-input" accept="image/*" type="file" enctype="multipart/form-data" (click)="$event.stopPropagation()" (change)="onFileChange($event,element)" #fileInput>
             <img (click)="$event.stopPropagation()" [src]="img_serverPath + element.customerLogo" 
            class="card-img-top" alt="User Image" 
            (click)="$event.stopPropagation(); fileInput.click()"
            onerror="this.src='assets/common_images/profile.jpg';">

            <i class="material-icons edit-icon pointer" (click)="$event.stopPropagation(); fileInput.click()"> edit  </i>
            </div>
          </ng-template>
          <ng-template #imgTemp>
            <div class="w-100 justify-content-between text-center pt-3 imgAtTop">
            <img  [src]="img_serverPath + element.customerLogo" 
            class="card-img-top" alt="User Image" 
             onerror="this.src='assets/common_images/profile.jpg';">
             </div>
          </ng-template>
    
          <ng-template [ngIf]="hasPermission.editPermission === true" [ngIfElse]="elseTemp">
          <div class="card-body text-center text-transform"> 
            <h6 class="card-title">{{element.customerName | capitalize}}</h6> 
           </div>
           </ng-template>
           <ng-template #elseTemp>
            <div class="card-body text-center">
              <h6 class="card-title">{{element.customerName | capitalize}}</h6>
            </div>             
           </ng-template>
           <div class="w-100 actionCenterButton">
                <span class="pe-2" (click)="$event.stopPropagation()">
                  <mat-icon [title]="'Send Email'" (click)="sendTemplate(element)">send</mat-icon>
                </span>
                <span (click)="$event.stopPropagation()" class="pe-2" [style.color]="element?.isContactRegistered ? '#34b6b9' : '#f44336'">
                  <span *ngIf="element?.unRegisteredCount > 0" [title]="element?.unRegisteredCount ? 'Unregistered Count' : ''" class="position-absolute translate-middle badge rounded-pill bg-danger badgePos" (click)="openContactDialog(element.customerCode)">{{element?.unRegisteredCount}}</span>
                  <mat-icon [title]="element?.isContactRegistered ? 'Registered' : 'Not Registered'" (click)="openContactDialog(element.customerCode)">how_to_reg</mat-icon></span>
                <span title="Note" (click)="$event.stopPropagation()">
                  <img class="noteIc curser-pointer" (click)="openNotePopUp(element, element.customerName)" [src]="element.isNoteAdded ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
                </span>           
         </div>
        </div>

        </div>
      </div>
    </div>
     
  </div>
</ng-container>
 
<ng-container *ngIf="viewPoint === 'listview'">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 patient-table">

    <ng-container matColumnDef="customerName" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Customers.labels.Customer Name' | translate}} </th>
      <td mat-cell *matCellDef="let element" class="common-icon-round">
        {{element.customerName | capitalize}}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerEmail" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.labels.Email' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.customerEmail"> {{element.customerEmail}}</span>
        <span *ngIf="!element.customerEmail">--</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="mainPhone" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.labels.Phone' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.mainPhone">{{element.mainPhone}} </span>
        <span *ngIf="!element.mainPhone">--</span>
      </td>
    </ng-container>
 

    <ng-container matColumnDef="patientAddress" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.labels.Address' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let address of element.customerAddressResponseList; let idx = index">
          <span
            title="{{address?.customerApartmentNo ? address?.customerApartmentNo+',' : ''}}&nbsp;{{ address?.customerAddress+', '+address?.customerCity+', '+address?.customerState+' '+address?.zipCode+', '+address?.customerCountry}}">
            <i class="material-icons">
              location_city
            </i>
          </span>
        </ng-container>
      
      </td>
    </ng-container>

    <ng-container matColumnDef="edit" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="action"> </th>
      <td mat-cell *matCellDef="let element" class="action">

        <div class="d-flex justify-content-between align-items-center pe-2">
        
        <ng-template [ngIf]="hasPermission.editPermission === true">

          <mat-icon [title]="'Send Email'" class="me-2" (click)="sendTemplate(element)">send</mat-icon>
          
          <i class="material-icons pointer " title="Edit" [routerLink]="['/dashboard','customer','customer-add']"
          [queryParams]="{ 'patientCode': element.customerCode }">edit</i>
          </ng-template>

          <div class="pe-2" [style.color]="element?.isContactRegistered ? '#34b6b9' : '#f44336'">
            <span *ngIf="element?.unRegisteredCount > 0" [title]="element?.unRegisteredCount ? 'Unregistered Count' : ''" class="position-absolute translate-middle badge rounded-pill bg-danger badgePos" (click)="openContactDialog(element.customerCode)">{{element?.unRegisteredCount}}</span>
            <mat-icon [title]="element?.isContactRegistered ? 'Registered' : 'Not Registered'" (click)="openContactDialog(element.customerCode)">how_to_reg</mat-icon></div>
          
          <div title="Note">
            <img class="pointer" (click)="openNotePopUp(element, element.customerName)"
               [src]="element.isNoteAdded ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
           </div>

        <i class="material-icons pointer ps-2" title="View Schedule"
         (click)="openDialog(element.customerCode)">calendar_today</i>

      </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>

  <div *ngIf="customerList_Filter.length == 0" class="p-3 noList">{{ 'Customers.messages.Not Available' | translate}}</div>
</div>

<!-- <div class="w-100" *ngIf="service.common.infiniteLoader">
  <div class="loadergif">
    <img src="assets/typint_dots_black.gif" alt="Loader">
    <p>Loading...</p>
  </div>
</div> -->
 