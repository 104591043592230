import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-layout1',
  templateUrl: './layout1.component.html',
  styleUrls: ['./layout1.component.scss']
})
export class Layout1Component implements OnInit {

  constructor(public common: CommonService) { }

  ngOnInit() {
  }

  closeSuccess() {
    this.common.loginsuccessMessage = false;
  }

  closeError() {
    this.common.loginerrorMessage = false;
  }
}
