
<div class="w-100 create-appointment">
  <div class="col-md-12">

    <div class="row m-0">
      <div class="col-md-6">
        <h4 class="mb-0">{{ 'Jobs.labels.Jobs' | translate }} </h4>
      </div>
      <div class="col-md-6 text-end">
        <div class="row justify-content-end">
          <!-- <button type="button" (click)="canelPopup()" class="w-auto me-2" mat-raised-button>{{ 'Technicians.labels.Cancel' | translate }}</button>
          <button type="button" (click)="onSubmit()" class="w-auto me-2" mat-raised-button color="primary">Save</button> -->
          <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
        </div>
      </div>
      </div>

      <div class="w-100 popup-viewport-body">

    <form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()">
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-11 m-0">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
                <mat-label>{{ 'Jobs.Create Jobs.labels.Job location' | translate }}</mat-label>
                <input type="text" matInput autoClose [matAutocomplete]="auto" placeholder=""
                  formControlName="patientName">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)="selectedPatient($event)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.patientName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <span class="help-block"
                *ngIf="!scheduleForm.get('patientName').valid && (!submitted || scheduleForm.get('patientName').touched)">
                {{ 'Jobs.Create Jobs.Validators.Job location is required' | translate }}</span>
            </div>
            <div class="col-md-1 text-start align-self-center">
              <mat-icon class="cursor-pointer" (click)="addPatient()">add_circle</mat-icon>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <mat-label class="custom-label">{{ 'Jobs.Create Jobs.labels.Job Type' | translate }}</mat-label>
            <angular2-multiselect [data]="dropdownList" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedItems" [settings]="dropdownSettings" formControlName="procedureCode">
              <c-badge>
                <ng-template let-item="item">
                  <img [src]="item.image" class="img-filter" />
                  <label style="margin: 0px;">{{item.itemName}}</label>
                </ng-template>
              </c-badge>
            </angular2-multiselect>
           
          <span class="help-block"
            *ngIf="!scheduleForm.get('procedureCode').valid && (!submitted || scheduleForm.get('procedureCode').touched)">
            {{ 'Jobs.Create Jobs.Validators.Job type is required' | translate }}</span>
        </div>
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Address' | translate }}</mat-label>
            <textarea matInput placeholder="" ngx-google-places-autocomplete [options]='options'
              #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
              formControlName="address"></textarea>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('address').valid && (!submitted || scheduleForm.get('address').touched)">
            {{ 'Jobs.Create Jobs.Validators.Address is required' | translate }}</span>
        </div>
      </div>

      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-4">
          <div class="form-group row">
            <div class=" col-md-5">
              <app-country-code (setCountryCode)="getCountryCode($event,'phoneCountryCode')" [setCode]="countrycodep" [placeholder]="'Home No'">
              </app-country-code>
              <span class="help-block"
                *ngIf="!scheduleForm.get('phoneCountryCode').valid && (!submitted || scheduleForm.get('phoneCountryCode').touched)">
                {{ 'Jobs.Create Jobs.Validators.Phone country code is required' | translate }}</span>
            </div>
            <div class=" col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-11 p-0 no-form">
                <mat-label>Phone Number</mat-label>
                <input matInput placeholder="" formControlName="phoneNumber"
                  [(ngModel)]="service.scheduleForm.homeNumber">
              </mat-form-field>
              <span class="help-block"
                *ngIf="!scheduleForm.get('phoneNumber').valid && (!submitted || scheduleForm.get('phoneNumber').touched)">
                {{ 'Jobs.Create Jobs.Validators.Phone number is required' | translate }} </span>

            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group row">
            <div class=" col-md-5">
              <app-country-code (setCountryCode)="getCountryCode($event,'mobileCountryCode')" [setCode]="countrycodem" [placeholder]="'Mobile No'">
              </app-country-code>
              <span class="help-block"
                *ngIf="!scheduleForm.get('mobileCountryCode').valid && (!submitted || scheduleForm.get('mobileCountryCode').touched)">
                {{ 'Jobs.Create Jobs.Validators.Mobile country code is required' | translate }}</span>
            </div>

            <div class=" col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-11 p-0 no-form">
                <mat-label>Mobile Number</mat-label>
                <input matInput placeholder="" formControlName="mobileNumber"
                  [(ngModel)]="service.scheduleForm.mobileNumber">
              </mat-form-field>

              <span class="help-block"
                *ngIf="!scheduleForm.get('mobileNumber').valid && (!submitted || scheduleForm.get('mobileNumber').touched)">
                {{ 'Jobs.Create Jobs.Validators.Mobile number is required' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group row"> 
            <div class="col-md-5">
              <app-country-code  (setCountryCode)="getCountryCode($event,'preferredCountryCode')"
                [setCode]="preferredCountryCode" [placeholder]="'Additional Contact No'"></app-country-code>
              <span class="help-block" *ngIf="scheduleForm.get('preferredCountryCode').errors?.required
                && (!submitted || scheduleForm.get('preferredCountryCode').touched)">
                {{ 'Customers.validators.Country code is required' | translate }}</span>
            </div>
    
    
            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width p-0 no-form">
                <mat-label>Additional Number</mat-label>
                <input autocomplete="off"  matInput placeholder="" formControlName="preferredNumber"
                  [(ngModel)]="service.scheduleForm.additionalNumber">
              </mat-form-field>
    
              <span class="help-block err" *ngIf="scheduleForm.get('preferredNumber').errors?.required &&
                (!submitted || scheduleForm.get('preferredNumber').touched)">{{
                'Customers.validators.Preferred Number is required' | translate }}</span>
              <span class="help-block err" *ngIf="scheduleForm.get('preferredNumber').errors?.pattern &&
                (!submitted || scheduleForm.get('preferredNumber').touched)">{{
                'Customers.validators.Please enter valid Preferred number' | translate
                }}</span>
              <span class="help-block" *ngIf="(scheduleForm.get('preferredNumber').errors?.minlength ||
                scheduleForm.get('preferredNumber').errors?.maxlength) && (!submitted
                || scheduleForm.get('preferredNumber').touched)">{{
                'Customers.validators.Preferred number should be 10 digit' | translate
                }}</span>
            </div>
          </div>
        </div>  

        <mat-radio-group aria-label="Select an option" class="ispreferred-radio" formControlName="isPeferredNumber" [(ngModel)]="service.scheduleForm.preferredPhoneType">
          <mat-radio-button value="homeNumber" class="pref-no">Preferred No</mat-radio-button>
          <mat-radio-button value="mobileNumber" class="pref-no">Preferred No</mat-radio-button>
          <mat-radio-button value="additionalNumber" class="pref-no">Preferred No</mat-radio-button>
        </mat-radio-group>
        <span class="help-block err radio-err" *ngIf="scheduleForm.get('isPeferredNumber').errors?.required &&
                (!submitted || scheduleForm.get('isPeferredNumber').touched)">Please select one of preferred number</span>
        
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Start Date' | translate }}</mat-label>
            <input [min]="today" matInput #nonworkingday formControlName="startDate" [matDatepicker]="picker2"
              (focus)="picker2.open()" placeholder="">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 (closed)="nonworkingday.blur()"></mat-datepicker>
          </mat-form-field>
          <span class="help-block"
            *ngIf="scheduleForm.get('startDate').errors?.required && (!submitted || scheduleForm.get('startDate').touched)">
            {{ 'Jobs.Create Jobs.Validators.Start date is required' | translate }}</span>

          <span class="help-block"
            *ngIf="scheduleForm.get('startDate').errors?.matDatepickerMin && (!submitted || scheduleForm.get('startDate').touched)">
            {{ 'Jobs.Create Jobs.Validators.Date should be from todays date' | translate }}.</span>
        </div>
        <div class="col-md-6 repeat">
          <h5>{{ 'Jobs.Create Jobs.labels.Repeat' | translate }}</h5>
          <div class="row col-md-12 p-0 m-0">
            <p class="eachday text-uppercase col-md-8 p-0" *ngIf="service.scheduleForm.repeatType == ''">{{ 'Jobs.Create Jobs.labels.Never' | translate }}</p>
            <p class="eachday text-uppercase col-md-8 p-0" *ngIf="service.scheduleForm.repeatType != ''">{{ 'Jobs.Create Jobs.labels.Every' | translate }}
              {{service.scheduleForm.repeatType}}</p>
            <p class="eachday text-uppercase col-md-8 p-0" *ngIf="service.scheduleForm.endDate != ''">{{ 'Jobs.Create Jobs.labels.Till' | translate }}
              {{service.scheduleForm.endDate}}</p>
            <div class="col-md-4 p-0 text-end">
              <mat-icon class="cursor-pointer" (click)="repeat()">{{ 'Jobs.Create Jobs.labels.cached' | translate }}</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Start Time' | translate }}</mat-label>
            <mat-select formControlName="startTime" [(ngModel)]="service.scheduleForm.startTime">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchStart($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="Search">
              <mat-option *ngFor="let start of selectedStartTime" [value]="start.value">
                {{start.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('startTime').valid && (!submitted || scheduleForm.get('startTime').touched)">
            {{ 'Jobs.Create Jobs.Validators.Start time is required' | translate }}</span>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Duration' | translate }}</mat-label>
            <mat-select formControlName="duration"  (selectionChange)="getDurationData($event)">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchEnd($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="Search">
              <mat-option *ngFor="let end of durationSelectDropdown" [value]="end.value">
                {{end.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('duration').valid && (!submitted || scheduleForm.get('duration').touched)">
            {{ 'Jobs.Create Jobs.Validators.Duration is required' | translate }} </span>
        </div>
      </div>

      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-12">
          <mat-checkbox formControlName="isDateFlexible" [(ngModel)]="service.scheduleForm.isDateFlexible"> Appointment Flexible</mat-checkbox>
        </div>
      </div>

      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Notes' | translate }}</mat-label>
            <textarea matInput placeholder="" formControlName="notes"
              [(ngModel)]="service.scheduleForm.notes"></textarea>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('notes').valid && (!submitted || scheduleForm.get('notes').touched)">
            {{ 'Jobs.Create Jobs.Validators.If you change the duration you have to mention the reason behind it' | translate }}.</span>
        </div>
      </div>
    </form>

    </div>

    <div class="d-flex offset-md-8 justify-content-end pt-3">
      <button mat-button (click)="canelPopup()" class="cancel-btn-primary">{{ 'Technicians.labels.Cancel' | translate
        }}</button>
      <button class="ms-2 pe-4 ps-4" mat-raised-button color="primary" (click)="onSubmit()">{{ 'Jobs.Create
        Jobs.labels.Save' | translate }}</button>
    </div>

  </div>
</div>
