<mat-tab-group dynamicHeight class="tab-button-auto-width">
    <mat-tab label="Random Assign">
        <div class="my-3 appoinment-tab">
            <button mat-button mat-raised-button color="primary" class="me-3" (click)="unassignAppointment()">Unassign Appointment</button>
            <button mat-button mat-raised-button color="primary" (click)="adminRandomAssignForDemo()">Random Assign</button>
        </div>

        <div *ngIf="randomDriveTIme" class="drive-time">
            <b>Total Drive Time:</b> {{randomDriveTIme}}
        </div>
        <div class="table-scroll-height" id="checkinout" *ngIf="allReport.length > 0">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="clinicianName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Clinician Name </th>
                    <td mat-cell *matCellDef="let element" >
                        <span *ngIf="element.name">
                            {{element.name}}</span>
                        <span *ngIf="!element.name">NA</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noOfAppointment">
                    <th mat-header-cell *matHeaderCellDef>
                        No. of Appoimtment </th>
                    <td mat-cell *matCellDef="let element" >
                        <span *ngIf="element.count">{{element.count}}</span>
                        <span *ngIf="!element.count">NA</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="drivingTime">
                    <th mat-header-cell *matHeaderCellDef>
                        Drive Time </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.drivingTime">{{element.drivingTime}}</span>
                        <span *ngIf="!element.drivingTime">NA</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
                    true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div *ngIf="allReport.length === 0" class="no-data p-3">
                No Appoimtment assign 
            </div>


        </div>


    </mat-tab>
    <mat-tab label="Auto Assign" class="mx-3" [disabled]="!isRandom">
        <div class="my-3 appoinment-tab">
            <button mat-button mat-raised-button color="primary" class="me-3" (click)="unassignAppointment()">Unassign Appointment</button>
            <!-- <button mat-button mat-raised-button color="primary" (click)="adminAutoAssignForDemo()">Auto Assign</button> -->
        </div>
        <div *ngIf="autoDriveTime" class="drive-time">
            <b>Total Drive Time:</b> {{autoDriveTime}}
        </div>
        
        <div class="table-scroll-height" id="checkinout" *ngIf="autoAssign.length > 0">
            <table mat-table [dataSource]="dataSource1" matSort>

                <ng-container matColumnDef="clinicianName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Clinician Name </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.name">
                            {{element.name}}</span>
                        <span *ngIf="!element.name">NA</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noOfAppointment">
                    <th mat-header-cell *matHeaderCellDef>
                        No. of Appoimtment </th>
                    <td mat-cell *matCellDef="let element" >
                        <span *ngIf="element.count">{{element.count}}</span>
                        <span *ngIf="!element.count">NA</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="drivingTime">
                    <th mat-header-cell *matHeaderCellDef>
                        Drive Time </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.drivingTime">{{element.drivingTime}}</span>
                        <span *ngIf="!element.drivingTime">NA</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
                    true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div *ngIf="autoAssign.length === 0" class="no-data p-3">
                No Appoimtment assign 
            </div>


        </div>

    </mat-tab>
</mat-tab-group>


