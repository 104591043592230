import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
 
@Injectable()
export class AuthGuardService implements CanActivate  {
  routerLink;
  constructor(
    private auth: AuthService, 
    private route: Router,
    //private _validateRouteSVC : ValidateRouteService
    ) {}
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> |Promise<boolean> | boolean {     

    // //console.log('CurrentRoutes',this.currentRoutes);
    // //console.log('RouteConfig',route.routeConfig, route.routeConfig.path);

    if( (route.routeConfig.path == 'dashboard') || (route.routeConfig.path == 'dashboard-technician')
     || (route.routeConfig.path == 'dashboard-customer'))
    {
      this.auth.setReturnUrl(state.url);
      this.routerLink = route?.routeConfig?.path;
    }
     else
      {
      this.auth.clearReturnUrl();
      //console.log(route.routeConfig.data.title)
      this.routerLink = route?.routeConfig?.data?.title;
    }   

    // return this.auth.isAuthenticated(this.routerLink)
    // return this.auth.isAuthenticated(state.url)

  // console.log("active route " + state.url +'  '+ this._validateRouteSVC.isValidRoute(state.url))


  return this.auth.isAuthenticated(this.routerLink)
      .then(
        (authenticated: boolean) => {          
          if(authenticated) {
            this.auth.login();
            return true;
          } else {
            this.route.navigate(['/']);
            return false;
          }
        }
      );
  }
}
