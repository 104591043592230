import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { AdminCustomerReportService } from '../admin-customer-report.service'; 
import { ApiRootService } from 'src/app/shared/api-root.service';
import * as moment from 'moment';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'add-time-card',
  templateUrl: './add-time-card.component.html',
  styleUrls: ['./add-time-card.component.scss']
})
export class AddTimeCardComponent implements OnInit {

  inputForm: FormGroup;
  clinicianCode: any;
  currentDate = new Date();
  currentTime: any;
  orgCode: any;
  submitted = true;
  formatedDate : string = moment(this.currentDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  userType;
  checkinTime: FormControl = new FormControl(null, [Validators.required]);
  checkoutTime: FormControl = new FormControl(null, [Validators.required]);

  myDateValue: Date;
  isMeridian = false;
  mytime: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddTimeCardComponent>,
    private _translateSVC: TranslateService,
    public _storageSVC: SessionStorageService,
    private service : AdminCustomerReportService,
    private _apiService: ApiRootService,
    
    ) {
    this._translateSVC.setDefaultLang(this._storageSVC.get('language'));

    this.inputForm = new FormGroup({
      "organizationCode": new FormControl(null),
      "clinicianCheckinDetilsCode": new FormControl(null),
      "startTime": new FormControl(null, [Validators.required]),
      'endTime' :new FormControl(null, [Validators.required]),
      'sendNotification' :new FormControl(false),
      'isAdmin': new FormControl(false),
      'appointmentsDurationCode': new FormControl(null),
      'workerCode': new FormControl(null),
      // 'comment': new FormControl(null, [Validators.required])
    }); 

    const d = new Date(),
      h = (d.getHours()<10?'0':'') + d.getHours(),
      m = (d.getMinutes()<10?'0':'') + d.getMinutes();
    this.currentTime = h + ':' + m;
  }

  ngOnInit() {

    this.myDateValue = new Date();
    this.userType = this._storageSVC.get('user_type');
    this.orgCode = this._storageSVC.get('orgCode'); 
    this.clinicianCode = this.data?.clinicianCheckinDetilsCode;

    if(this.data && this.orgCode){
      this.inputForm.controls['organizationCode'].setValue(this.orgCode);
    }
    // if(this.data && this.data?.comment){
    //   this.inputForm.controls['comment'].setValue(this.data.comment);
    // }
    if(this.data?.clinicianCheckinDetilsCode){
      this.inputForm.controls['clinicianCheckinDetilsCode'].setValue(this.data.clinicianCheckinDetilsCode);
    }
    if(this.data?.appointmentsDurationCode){
      this.inputForm.controls['appointmentsDurationCode'].setValue(this.data.appointmentsDurationCode);
    }
    if(this.userType){
      this.inputForm.controls['isAdmin'].setValue(this.userType === 'Customer' ? false : true);
    }
    if(this.data?.workerCode){
      this.inputForm.controls['workerCode'].setValue(this.data.workerCode);
    }
    if(this.data?.startTime){    
      this.inputForm.controls['startTime'].setValue(this.dateForSetDate(this.data.startTime));
      this.checkinTime.setValue(this.convertTo12HourFormat(this.data.startTime));
    }
    if(this.data?.endTime){      
      this.inputForm.controls['endTime'].setValue(this.dateForSetDate(this.data.endTime));
      this.checkoutTime.setValue(this.convertTo12HourFormat(this.data.endTime));
    }

  }

  dateForSetDate(dateVal: any){
    const date = dateVal.slice(0, 10);
    const newdate = date;
    if(date){
      const formatedDate = moment(newdate, 'MM-DD-YYYY').format('YYYY-MM-DD');
      // //console.log('NewDatete: ', dateVal, date, newdate);
      return formatedDate;
    }
  }

  convertTo12HourFormat(dateVal: any){
    const time12Hour = dateVal.slice(11);

    const [time, period] = time12Hour.split(' ');
    const [hours, minutes] = time.split(':');
    
    // Convert hours to a number
    let hour = parseInt(hours, 10);
    
    // If the period is PM and the hour is not 12, add 12 to the hour
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    }
    
    // If the period is AM and the hour is 12, set the hour to 0
    if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    
    // Format the hour and minutes as two-digit strings
    const formattedHour = hour.toString().padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    
    // Create the 24-hour time string
    const time24Hour = `${formattedHour}:${formattedMinutes}`;

    // //console.log('time24Hour: ', time24Hour, time12Hour);
    
    return time24Hour;
  }

  onDateChange(event:any, formField: any) {
    // //console.log('onDateChange: ', event.value);
    let newInputDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    //console.log('FDate', newInputDate);
    this.inputForm.controls[formField].setValue(newInputDate);
    this.checkShiftGrateEaightHrsOrNot();
  }

  onTimeChange(event:any, formField: any) {
    if(event.target.value){
    // //console.log('onTimeChange: ', event.target.value, formField);
    if(formField === 'checkinTime'){
      this.checkinTime.setValue(event.target.value);
    }
    if(formField === 'checkoutTime'){
      this.checkoutTime.setValue(event.target.value);
    }    
    this.checkShiftGrateEaightHrsOrNot();
    }
  }

  checkShiftGrateEaightHrsOrNot(){
    if(this.inputForm.controls.startTime.value && this.inputForm.controls.endTime.value){

      // Define the two date objects
      const selectedInDate = Number(new Date(this.inputForm.controls.startTime.value));
      const selectedOutDate = Number(new Date(this.inputForm.controls.endTime.value));

      // Calculate the difference in milliseconds
      const timeDifference = selectedOutDate - selectedInDate; // This will give time difference in milliseconds

      // Convert the difference to days
      const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);

      // console.log(`Time difference in milliseconds: ${timeDifference}`);
      // console.log(`Time difference in days: ${differenceInDays}`);

      const inTime = this.checkinTime.value;
      const outTime = this.checkoutTime.value;

      if (differenceInDays>0 || (inTime && outTime && this.service.common.isMoreThan8Hours(inTime, outTime))) {
        // console.log("TimeDiff_Time difference is greater than 8 hours");
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '600px',
          disableClose: true,
          data: { 
            msg: 'The Shift Start and End times will result in a shift greater than 8 hours and may required Overtime payments. Do you want to save these shift hours?',
            cancelBtnLabel: 'Cancel', 
            confirmBtnLabel: 'Save'
            // hideNoBtn: true
           }
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result && result.toLowerCase() === 'ok') {
            //  Do Nothing..
          }
          else
          {
            this.checkinTime.reset();
            this.checkoutTime.reset();
            // this.is_approve_and_save = false;
          }
  
        });
        } else {
          // console.log("TimeDiff_Time difference is less than or equal to 8 hours");
        }
      }
  }

  onSubmit(){
    this.submitted = false;
    if (this.checkinTime.invalid) {
      // Mark the FormControl as touched to display error messages
      this.checkinTime.markAsTouched();
    }
    if (this.checkoutTime.invalid) {
      // Mark the FormControl as touched to display error messages
      this.checkoutTime.markAsTouched();
    }
    try {
      if (this.inputForm.valid && this.checkinTime.valid && this.checkoutTime.valid) {
        this.inputForm.controls['startTime'].setValue(this.inputForm.value.startTime + ' ' + this.checkinTime.value+':00');
        this.inputForm.controls['endTime'].setValue(this.inputForm.value.endTime + ' ' + this.checkoutTime.value+':00');
        // console.log('FormForSubmit2: ', this.inputForm.value);
        // return;
        this.service.common.progressLoader = true;
        this.service.saveManualClockInAndOut(this.inputForm.value, (response) => {
                
            if (response.responsecode == 200) {
              this.inputForm.reset();
              this.checkinTime.setValue('');
              this.checkoutTime.setValue('');
              this.dialogRef.close(true)
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
              // this.getListDataByPage();
            } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
      })
        this.dialogRef.close(true);
      } 
    } catch (error) {
      //console.log(error)
    }
  }

  setIsApproved(event: any){
    if(event && event.checked){
    let confirmMSG = 'Are you sure want to ' + (this.userType === 'Customer' ? 'notify' : 'approved') + ' this time card?.' + (this.userType === 'Customer' ? '' : ' Once approved, you cannot edit.') ;

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {
  
      if(result && result.toLowerCase() === 'ok'){

      } else {
      this.inputForm.controls.sendNotification.setValue(event.checked ? false : true);
      }
    });        
  }
}


   timePickerOpen(id: any){
    if(id){
      let timePickerElement = <HTMLInputElement> document.getElementById(id);
      timePickerElement.focus();
    }
  }

}
