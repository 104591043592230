  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="float-start header-name mb-2">{{selectedDay_Filter}}{{selectedDay_Filter !== 'Next 7 Days' ? "'s" : ''}} Jobs</div> -->
      <div class="float-start header-name mb-2">
        {{selectedDay_Filter+"'s"}} Jobs
         {{dataSource?.data.length > 0 ? '( ' + dataSource?.data[0]?.jobDate + ' )' : ''}}
      </div>
      <div class="float-end header-name mb-2">
         <div class="p-1 ps-3 dropdown dropdown-menus">
       <i class="fa fa-filter cursor-pointer dropdown-toggle" aria-hidden="true" id="dropdownMenuButtonHeader" data-bs-toggle="dropdown" aria-expanded="false"></i>
          <ul class="dropdown-menu mt-1" aria-labelledby="dropdownMenuButtonHeader">
            <li ><a [ngClass]="{'active-li': selectedDay_Filter === 'Today'}" class="dropdown-item py-0 my-0" (click)="filter('Today')" href="javascript:void(0)">Today</a>
              <li ><a [ngClass]="{'active-li': selectedDay_Filter === 'Tomorrow'}" class="dropdown-item py-0 my-0" (click)="filter('Tomorrow')" href="javascript:void(0)">Tomorrow</a>
                <!-- <li ><a [ngClass]="{'active-li': selectedDay_Filter === 'Next 7 Days'}" class="dropdown-item py-0 my-0" (click)="filter('Next 7 Days')" href="javascript:void(0)">Next 7 Days</a> -->
            </ul>
        </div>
      </div>
  </div>

    <div class="col-md-12 todays-job table-scroll-height table-responsive table-job"
    infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="100" 
    (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
  
      <!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2">
      <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TodaysJob.labels.Customer' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
        </ng-container>
      
        <ng-container matColumnDef="jobSite">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'main.labels.Job Site' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <span [title]="element.siteAddress">{{element.siteName}}</span></td>
          </ng-container>
    
        <ng-container matColumnDef="shift">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px;">Shift</th>
            <td mat-cell *matCellDef="let element" style="width: 200px;">
               <div class="row" *ngFor="let items of element.jobParentResList">
                <div class="color-header" *ngIf="items.jobTypeWithShiftList.length > 0">

                  <div class="row focus" *ngFor="let ele_shift of items.jobTypeWithShiftList">
                     <div class="col-12" style="width: 220px;">
                      <span> {{ele_shift.startTime +' - '+ ele_shift.endTime}}
                    </span>
                    <span>
                     {{ele_shift.jobTypeName}}
                    </span>
                  </div>
            </div>

                </div>
              </div>
             
            </td>
          </ng-container>

           <ng-container matColumnDef="staffRequired">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Required</th>
            <td mat-cell *matCellDef="let element">
              <div *ngFor="let items of element.jobParentResList">
                <div *ngIf="items.jobTypeWithShiftList.length > 0">

                  <div class="focus padding text-center" *ngFor="let ele_shift of items.jobTypeWithShiftList">
                     <span>
                      {{ele_shift.required}} 
                     </span>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="staffScheduled">
             <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheduled</th>
            <td mat-cell *matCellDef="let element" >
              <div *ngFor="let items of element.jobParentResList">
                <div class="color-header" *ngIf="items.jobTypeWithShiftList.length > 0">

                  <div class=" focus padding text-center {{ele_shift.BG_Color}}" *ngFor="let ele_shift of items.jobTypeWithShiftList">
                     <span>
                      {{ele_shift.scheduled}}
                     </span>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="dataSource.data.length == 0"
        class=""> <span style="font-size: 12px;">No Jobs Created For Today's</span></div>  -->

        <!-- <table class="table table-nowrap mb-0">
          <thead>
              <tr>
                   <th>Customer</th>
                  <th>{{ 'main.labels.Job Site' | translate }}</th>
                  <th>Shift</th>
                  <th>Required</th>
                  <th>Scheduled</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let element of dataSource?.data">
                  <td>{{element.customerName}}</td>

                  <td><span [title]="element.siteAddress">{{element.siteName}}</span></td>

                  <td>
                    <div class="row" *ngFor="let items of element.jobParentResList">
                      <div class="color-header" *ngIf="items.jobTypeWithShiftList.length > 0">
      
                        <div class="row focus" *ngFor="let ele_shift of items.jobTypeWithShiftList">
                           <div class="col-12" style="width: 220px;">
                            <span class="text-breakLine">{{items.dateString}}</span>
                            <span class="text-breakLine"> {{ele_shift.startTime +' - '+ ele_shift.endTime}}
                          </span>
                          <span>
                           {{ele_shift.jobTypeName}}
                          </span>
                        </div>
                       </div>
                     </div>
                    </div>
                  </td>

                  
                    <td>
                    <div *ngFor="let items of element.jobParentResList">
                      <div *ngIf="items.jobTypeWithShiftList.length > 0">
      
                        <div class="focus padding text-center" *ngFor="let ele_shift of items.jobTypeWithShiftList">
                           <span>
                            {{ele_shift.required}} 
                           </span>
                        </div>
      
                      </div>
                    </div>
                  </td>
                                    
                    <td>
                    <div *ngFor="let items of element.jobParentResList">
                      <div class="color-header" *ngIf="items.jobTypeWithShiftList.length > 0">
      
                        <div class=" focus padding text-center {{ele_shift.BG_Color}}" *ngFor="let ele_shift of items.jobTypeWithShiftList">
                           <span>
                            {{ele_shift.scheduled}}
                           </span>
                        </div>
      
                      </div>
                    </div>
                  </td>
              </tr>
         </tbody>
      </table> -->

      <table class="table table-nowrap mb-0">
        <thead>
            <tr>
                 <th>Shift</th>
                <th>Job Type</th>
                <th>Customer</th>
                <th>{{ 'main.labels.Job Site' | translate }}</th>
                <th>Required</th>
                <th>Filled</th>
            </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="dataSource?.data.length > 0">
            <ng-container *ngFor="let element of dataSource?.data">
            <tr (click)="element.jobDurationDetailsList.length > 0 && showScheduledStaff(element)">

                <td>
                  <!-- <span class="text-breakLine">{{element.jobDate}}</span> -->
                  <span>{{element.startTime}} - {{element.endTime}}</span>
                </td>

                <td>{{element.jobTypeName}}</td>

                <td>{{element.customerName}}</td>

                <td><span [title]="element.siteAddress">{{element.siteName}}</span></td>

                <td class="focus">{{element.required}}</td>

                <td class="fullfillment-color">
                  <div class="d-flex justify-content-between">
                    <span class="focus {{element.BG_Color}}">
                      {{element.scheduled}}
                    </span>
  
                    <mat-icon *ngIf="element.jobDurationDetailsList.length > 0"> supervisor_account</mat-icon>
                  </div>
                   

                  </td>
                
            </tr>
          </ng-container>
            </ng-template>

            <ng-template [ngIf]="dataSource?.data.length === 0">
              <tr>
                <td colspan="6" class="text-danger">No Records Found</td>
              </tr>
            </ng-template>

       </tbody>
    </table>

         
  </div>
  </div>
 

  <ng-template #schdeuledStaff_popUp>
    <div class="row">
      <div class="col-md-6 align-self-center">
        <h6 class="dialog-header pt-2">Scheduled Staff</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" matDialogClose></button>
        </div>
      </div>
    </div>
  
    <div class="row">
  
      <app-common-card 
      [job_shortData_Modal]="job_shortData_Modal"
      (emitter_handleEvent)="refreshData($event)">
    </app-common-card>

    </div>
  </ng-template>