import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LicenceService } from './licence.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportAsService } from 'ngx-export-as';
import { debounce } from 'lodash';
import { AddLicenceComponent } from './add-licence/add-licence.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss']
})
export class LicenceComponent implements OnInit {

  licenceData: any = [];
  displayedColumns: string[] = ['licenceName', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  controlsPermissions:IBtnControlsPermission ;

  constructor(
    public dialog: MatDialog,
    public service: LicenceService,
    private translate: TranslateService,
     ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }

  ngOnInit() {
    this.page = 0;
    this.getLicenceMasterByOrganizationCodeByPage();
    this.controlsPermissions =  this.service.common.getBtnEventPermissions();  
  }
  
   /* Name:Vivek Chauhan
  use: for getting all Org Licence Master data by org code */
  getLicenceMasterByOrganizationCodeByPage(scroll = null) {
      // //console.log('getLicenceMasterByOrganizationCodeByPage: ', this.page, scroll);
      if (!scroll) {
        this.service.common.progressLoader = true;
      }
      else if (scroll) {
        this.service.common.infiniteLoader = true;
      }
      try {
        this.service.getLicenceMasterByOrganizationCodeByPage(this.service.storage.get('orgCode'), this.page, this.limit, (response) => {
          if(response.responsecode === 200){
            if (scroll) {
              let arrayData = this.dataSource.data;
              response.data.forEach(element => {
                arrayData.push(element)
              });
              setTimeout(() => {
                this.licenceData = arrayData;
                this.dataSource = new MatTableDataSource(arrayData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.infiniteLoader = false;
              }, 1000);

            } else {
              ////console.log(response.data);
              setTimeout(() => {
                this.licenceData = response.data;
                this.dataSource = new MatTableDataSource(this.licenceData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.progressLoader = false;
              }, 1000)
            }
          } else {
            if (!scroll) {
              this.licenceData = [];
              setTimeout(() => {
                this.dataSource = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
              }, 1000);      
            } else {
              this.service.common.infiniteLoader = false;
            }
            this.service.common.progressLoader = false;
          }
      });    
      } catch (error) {
        //console.log(error);
      }
  }

    /* Name:Vivek Chauhan
      use: for open edit licence popup*/
    onEdit(data) {
      // //console.log('onEditData:', data);
      const dialogRef = this.dialog.open(AddLicenceComponent, {
        width: '500px',
        disableClose: true,
        data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result === 'success') {
        this.page = 0;
        this.getLicenceMasterByOrganizationCodeByPage();
      }
    });
    }

      /* Name:Vivek Chauhan
      use: for open licence add popup */
  addLicence(): void {
    const dialogRef = this.dialog.open(AddLicenceComponent, {
      width: '500px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result === 'success') {
        this.page = 0;
        this.getLicenceMasterByOrganizationCodeByPage();
      }
    });
  }

    /* Name:Vivek Chauhan new API
  use: for delete/remove Licence */
  softDeleteLicenceMasterByLicenceCode(data :any){
    // //console.log('softDeleteLicenceMasterByLicenceCode: ', data);
    
    if(data?.licenceCode){ 

      const dialog = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { 
          msg: 'Are you sure want to delete this licence?'
        }
      });      
  
      dialog.afterClosed().subscribe(result => {    
        if(result && result.toLowerCase() === 'ok'){ 
          this.service.common.progressLoader = true;
          this.service.softDeleteLicenceMasterByLicenceCode(data.licenceCode, (response) => {
            this.service.common.progressLoader = false;
            //console.log(response);
            if (response.responsecode == 200) {
              this.service.common.successMessage = true;
              this.service.common.message = response.message;
              this.page = 0;
              this.getLicenceMasterByOrganizationCodeByPage();
            } 
            else{
              this.service.common.errorMessage = true;
              this.service.common.message = response.message;
            }
          });
        }
      });
    }

  }

  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.getLicenceMasterByOrganizationCodeByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }

  /* Name:Vivek Chauhan
  use: for seraching Licence Data name */
    applyFilter(searchText: string) {
      // //console.log('applyFilter', searchText);
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
    this.page = 0;
      this.service.common.progressLoader = true;
    try {
      if(searchText && searchText.trim()){
      this.service.getLicenceMasterByOrganizationCodeByPageSearch(this.service.storage.get('orgCode'), searchText, (response) => {
        if(response.responsecode === 200){
            setTimeout(() => {
              this.licenceData = response.data;
              this.dataSource = new MatTableDataSource(this.licenceData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.progressLoader = false;
            }, 1000);
        } else {
          this.service.common.progressLoader = false;
            this.licenceData = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 1000);          
        }
    });
    } else {
      this.getLicenceMasterByOrganizationCodeByPage();
    } 
    } catch (error) {
      //console.log(error);
    }
  }

}

