<div class="row">
     <div class="col-lg-12">
    <div class="float-start header-name mb-2">Today's {{filtered_Activity}} Activities</div>
    <div class="float-end">
        <div class="p-1 ps-3 dropdown dropdown-menus">
            <i class="fa fa-filter cursor-pointer dropdown-toggle" aria-hidden="true" id="FilterActivities" data-bs-toggle="dropdown" aria-expanded="false"></i>
               <ul class="dropdown-menu mt-1" aria-labelledby="FilterActivities">
                 <li ><a [ngClass]="{'active-li': filtered_Activity === 'All'}" class="dropdown-item py-0 my-0" (click)="filterActivities('All')" href="javascript:void(0)">All</a>
                   <!-- <li ><a [ngClass]="{'active-li': filtered_Activity === 'Regular'}" class="dropdown-item py-0 my-0" (click)="filterActivities('Regular')" href="javascript:void(0)">Regular</a> -->
                     <li ><a [ngClass]="{'active-li': filtered_Activity === 'Exceptional'}" class="dropdown-item py-0 my-0" (click)="filterActivities('Exceptional')" href="javascript:void(0)">Exception</a>
                 </ul>
             </div>
  </div>
      </div>
<div class="activity-feed" style="height: calc(100vh - 300px); overflow-y: auto;">
   <div class="w-100 ps-3 pe-3 mb-4">
    <!-- <div class="feed-item" *ngFor="let activity of activityList">      
        <div class="date">{{activity.time}}</div>
      <div class="text">
        <div class="textContent">

            <div class="circle me-2">
                <p class="circle-inner" >{{activity.shortName}}</p>
            </div>
        <div>
            <span class="activityDescriptionTxt" [innerHTML]="activity.description">  </span></div>
    </div>
</div>
    </div> -->

     <ul class="timeline">
               <ng-container  *ngFor="let activity of activityList">
                    <li class="{{activity.activityType ? activity.activityType : 'no-title-BG'}}">
                        <div class="d-flex justify-content-between">
                            <!-- <h5 class="activities-title">Heading Title</h5> -->
                            <h5 class="activities-title">{{activity.activityTitle}}</h5>
                            <h6 class="activities-time float-end">{{activity.time}}</h6>
                        </div>
                        <p class="activities-desc" [innerHTML]="activity.description"></p>
                        <!-- <p class="activities-info">Job type: Routine Checkup and Qty: 1</p> -->
                    </li>
                </ng-container>
                    <!-- <li class="title-yellow">
                        <div class="d-flex justify-content-between">
                            <h5 class="activities-title">Heading Title</h5>
                            <h6 class="activities-time float-end">4:42 PM</h6>
                        </div>
                        <p class="activities-desc">Job has been created for Customer: Arun and Site: Station Road, 
                            Lucknow, Uttar Pradesh, India, 226004</p>
                        <p class="activities-info">Job type: Routine Checkup and Qty: 1</p>
                    </li>
                    <li class="title-green">
                        <div class="d-flex justify-content-between">
                            <h5 class="activities-title">Heading Title</h5>
                            <h6 class="activities-time float-end">4:42 PM</h6>
                        </div>
                        <p class="activities-desc">Job has been created for Customer: Arun and Site: Station Road, 
                            Lucknow, Uttar Pradesh, India, 226004</p>
                        <p class="activities-info">Job type: Routine Checkup and Qty: 1</p>
                    </li>
                    <li class="title-blue">
                        <div class="d-flex justify-content-between">
                            <h5 class="activities-title">Heading Title</h5>
                            <h6 class="activities-time float-end">4:42 PM</h6>
                        </div>
                        <p class="activities-desc">Job has been created for Customer: Arun and Site: Station Road, 
                            Lucknow, Uttar Pradesh, India, 226004</p>
                        <p class="activities-info">Job type: Routine Checkup and Qty: 1</p>
                    </li> -->
                    
                </ul>

    <div class="text-center mt-5" *ngIf="activityList.length == 0">

        <!-- <img src="assets/common_images/no-result.jpg" style="width: 55%;"> -->
        <div>
            <p class="text-danger">No Today's {{filtered_Activity}} Activities Found</p>
        </div>
         
    </div>


    </div>
  </div>
</div>