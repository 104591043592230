import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  patientData = {
    'patientIcon': '',
    'patientName': '',
    'patientId': '',
    'phoneNumber': '',
    'mobileNumber': '',
    'patientAddress': '',
    'location':'',
    'patientHistoryDescription': '',
    'recurringNotes': '',
    'patientDocumentMappingReqList': [],
    'patientLatitude': '',
    'patientLongitude': '',
    'patientCountry': '',
    'patientCity': '',
    'zipCode': '',
    'patientState': '',
    "isActive": true,
    'mobileCountryCode': '',
    "organizationCode": "",
    "patientEmail": "",
    'phoneCountryCode': '',
    'patientCode': '',
    'address': '',
    'patientApartmentNo': '',
    'areaReferDoctor': '',
    'caretakerNumber': '',
    'caretakerCountryCode': '',
    'preferredNumber': '',
    'preferredCountryCode': '',
    'isPreferSameClinician': true,
    'excludeClinician': '',
    'experience': 0,
    'minimumProficiency': 0,
    'preferredPhoneType': ''
  };
  customerData: {
    'customerCode': '',
    'customerName': '',
    'organizationCode': '',
    'homePhone': '',
    'mobilePhone': '',
    'workPhone': '',
    'mCountryCode': '',
    'customerEmail': '',
    'customerAddressResponseList': []
  }

  constructor(
    public service: ApiRootService,
    public common: CommonService,
    public http: HttpClient,
    public local: SessionStorageService
  ) { }


 /* Name:Shailesh
      use: for getting patient detail by org code and by page*/
  getPatientDetailByOrganizationCodeByPage(code, page, limit, patientName, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByOrganizationCodeByPage/' + code + '/' + patientName + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
      //////console.log(data);
    })
  }

  /* Name:Shailesh
      use: for getting patient detail by org code*/
  getPatientByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
      //////console.log(data);
    })
  }

  /* Name:Vivek Chauhan
  use: for getting All Customer Details code*/
  getAllCustomerDetailsByOrganizationCode(reqBody, page, limit, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCodeByPage' + '?page=' + page + '&limit=' + limit, reqBody).subscribe((data) => {
      callback(data);
    })
  }

  getAllCustomerDetails(orgCode:string,) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCode/' + orgCode);
  }

   /* Name:Shailesh
      use: for getting patient detail by patient code*/
  getPatientDetailByPatientCode(patientCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByPatientCode/' + patientCode).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vivek Chauhan
 use: for getting Customer details by Customer code*/
  getCustomerDetailByCustomerCode(customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByCustomerCode/' + customerCode).subscribe((data) => {
      callback(data);
    })
  }



  /* Name:Shailesh
      use: for save patient detail*/
  savePatientDetail(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/savePatientDetail', formData).subscribe((data) => {
      callback(data);
    })
  }

  // getAllPatientMaster(callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByOrganizationCode').subscribe((data) => {
  //     callback(data);
  //     //////console.log(data);
  //   })
  // }

  /* Name:Shailesh
      use: for update patient detail by patient code*/
  updatePatientDetailByPatientCode(orgBasedCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updatePatientDetailByPatientCode/' + orgBasedCode, formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vicek Chauhan
    use: for update patient detail by Customer code*/
  updateCustomerDetailByCustomerCode(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateCustomer/', formData).subscribe((data) => {
      callback(data);
    })
  }

  // getAllJobsByOrganizationCodeWithFilter(orgCode, status, startDt, endDt, patientCode, callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/getAllJobsByOrganizationCodeWithFilter/' + orgCode + '/' + status + '/' + startDt + '/' + endDt + '/' + patientCode).subscribe((data) => {
  //     callback(data);
  //   })
  // }

  /* Name:Shailesh
      use: for getting all jobs by organization code filter*/
  getAllJobsByOrganizationCodeWithFilter(orgCode, status, startDt, endDt, patientCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetAllAppointmentsByOrganizationCodeWithFilter/' + orgCode + '/' + status + '/' + startDt + '/' + endDt + '/' + patientCode).subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }

  /* Name:Shailesh
      use: for updating patient document*/
  uploadPatientDocumentFile(file, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/uploadPatientDocumentFile', file).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for Delete patient document by patient document mapping code*/
  softDeletePatientDocumentByPatientDocumentMappingCode(code, callback) {
    return this.http.delete(this.service.getHealthCareUrl() + '/softDeletePatientDocumentByPatientDocumentMappingCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for getting worker and organization */
  getWorkerAndOrganizationFromWAS(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getWorkerAndOrganizationFromWAS/' + code).subscribe((data) => {
      callback(data);
    })
  }

    /* Name:Vivek Chauhan
  use: for getting All Role Name*/
  getAllRoleName() {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllRoleName')
  }

  /* Name:Vivek Chauhan
  use: for Add/Update admin */
  saveOrUpdateCustomerAdmin(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateCustomerAdmin', formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vivek Chauhan
  use: for getting all admin list using customer code */
  getCustomerAdminDetailsByCustomerCode(customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getCustomerAdminDetailsByCustomerCode/' + customerCode).subscribe((data) => {
      callback(data);
    })
  }

/* Name:Vivek Chauhan
  use: for Delete/Remove user/admin data from list*/
  softDeleteCustomerAdminDetailsByCustomerAdminCode(customerAdminCode, callback) {
    return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteCustomerAdminDetailsByCustomerAdminCode/' + customerAdminCode).subscribe((data) => {
      callback(data);
    })
  }

  getCustDetailsByCustAdminCode(CustomerAdminCode:string){
    return this.http.get(this.service.getHealthCareUrl() + '/getCustomerDetailsByCustomerAdminCode/' + CustomerAdminCode)
   
  }

 
  Save_OR_UpdateCustomer(formData:any) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateCustomerOnlyForCustomer/', formData)
  }

  // Save_OR_SiteAddress(formData:any) {   // api for saving multiple site  at a time
  //   return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateCustomerAddressOnlyForCustomerAddress/', formData)
  // }


  Save_OR_SiteAddress(formData:any) {  //pi for saving single site  at a time
    return this.http.post(this.service.getHealthCareUrl() + '/saveCustomerAddressOnlyForCustomerAddressFormJob/', formData)
  }

  DeleteSiteAddress(Cust_AddressCode:string) {
    return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteCustomerSiteByCustomerAddressCode/'+ Cust_AddressCode)
  }

  Active_OR_InActiveCustomer(formData:any) {
    return this.http.post(this.service.getHealthCareUrl() + '/setActiveOrInactiveStatusForCustomer/', formData)
  }


  saveCustomerLogo(formData:any) {
    return this.http.post(this.service.getHealthCareUrl() + '/updateCustomerLogo/', formData)
  }

  getAllAppoinmentData_ByCustomerCode(organizationCode:string, startDT:string, endDT:string, customerCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/v2/adminGetAllAppointmentsByOrganizationCodeForCustomerWithFilterDateTime/${organizationCode}/all/${startDT}/${endDT}/all/${customerCode}`);
  }
 
  getAppoinments_Counts(organizationCode:string, startDT:string, endDT:string, customerCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/v3/adminGetAllAppointmentsByOrganizationCodeWithFilter/${organizationCode}/all/${startDT}/${endDT}/${customerCode}`);
  }

  getAllStaffMemberByOrgCode(organizationCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getAllStaffMember/${organizationCode}`);
  }
 
  saveBlockedStaffs(formData:any) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveBlockedCustomerStaff/', formData)
  }

   
  getAllBlockedStaffByCustomerCode(customerCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getBlockedCustomerStaffByStaffCode/${customerCode}`);
  }

  removeStaffFromBlockList(blockedCustomerStaffCode:string)
  {
    return this.http.delete(this.service.getHealthCareUrl() + `/softDeleteBlockedCustomerStaff/${blockedCustomerStaffCode}`);
  }
 
    
  getCustomerSiteByCustomerCode(customerCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getCustomerAddressListByCustomerCode/${customerCode}`);
  }
}

