import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../skills/skill-list/skill-list.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { AppointmentService } from '../appointment.service';

@Component({
  selector: 'app-procedure-detail',
  templateUrl: './procedure-detail.component.html',
  styleUrls: ['./procedure-detail.component.scss']
})
export class ProcedureDetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProcedureDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translate: TranslateService,
    public session: SessionStorageService,
    public service: AppointmentService

  ) {
    this.translate.setDefaultLang(this.session.get('language'));
   }

  ngOnInit() {
    ////console.log(this.data);
  }
  /* Name:srinivas
       use: close popup. */
  onNoClick(){
    this.dialogRef.close();
  }
}
