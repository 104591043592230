import { ReportsService } from './../reports/reports.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MileageReportService } from './mileage-report.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mileage-report',
  templateUrl: './mileage-report.component.html',
  styleUrls: ['./mileage-report.component.scss']
})
export class MileageReportComponent implements OnInit {
  displayedColumns = ['clinicianName', 'numberOfAppointments', 'planMiles', 'planTime', 'actualMiles', 'actualTime'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('iframe') iframe: ElementRef;
  optionWorkers = [];
  worker = new FormControl();
  filteredOptions: Observable<any>;
  date = new Date();
  formatedDate = moment(this.date).format('YYYY-MM-DD');
  formatedDateS = moment(this.date).startOf('week').format('YYYY-MM-DD');
  formatedDateE = moment(this.date).endOf('week').format('YYYY-MM-DD');
  dataSource;
  dataSourceW;
  selectedWorker = 'all';
  workerD = 'all'
  workerexits = false;
  workernotexit = true;
  workerName;
  keys = [];
  keyValue;
  mileageReport = [];
  page: any = 0;
  WeeklyReport = [];

  constructor(public service: MileageReportService, public reportService: ReportsService,
    private translate: TranslateService, ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
  }

  onScroll() {
    this.page = this.page + 1;
    this.getMileageReport(this.workerD, this.formatedDate, this.formatedDate);
    this.getWeeklyMileageReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
  }

  ngOnInit() {

    this.getAllWorker();
    this.getMileageReport(this.workerD, this.formatedDate, this.formatedDate)
    this.getWeeklyMileageReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
    setTimeout(() => {
      this.filteredOptions = this.worker.valueChanges
        .pipe(
          startWith<string>(''),
          map(value => this._filter(value)));
    }, 1000);

  }

  private _filter(value): string[] {
    //console.log(value)
    if (value) {
      //console.log(value);
      const filterValue = value.toLowerCase();
      return this.optionWorkers.filter(option => option.name.toLowerCase().includes(filterValue));
    } else {
      // //console.log(this.optionWorkers);
      this.selectedWorker = "all";
      this.getWeeklyMileageReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
      return this.optionWorkers;
    }


  }

  displayFn(workerList): string | undefined {
    return workerList ? workerList.name : undefined;

  }

  selectedAuto(event) {
    //console.log(event);
    // this.filteredOptions = this.worker.valueChanges
    //  .pipe(
    //    startWith<string>(''),
    //   map(value => this._filter(value)));
    this.selectedWorker = event.option.value.workerCode;
    this.workerName = event.option.value.name;
    this.getWeeklyMileageReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
    if (this.selectedWorker) {
      this.workerexits = true;
      this.workernotexit = false;
    }
  }

  /* Name:Shailesh
  use: for getting all clinician detail*/
  getAllWorker() {
    try {
      this.reportService.adminGetWorkerAndOrganizationFromWASActive(this.service.storage.get('orgCode'), (data) => {
        this.optionWorkers = [];
        data.data.forEach(element => {
          this.optionWorkers.push({ workerCode: element.workerRes.workerCode, name: element.workerRes.name });

        });
        //this.optionWorkers.push({ workerCode: "all", name: "All" });
      });
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Shailesh
  use: for getting daily mileage report by worker code from date and to date and org code*/
  getMileageReport(wcode, fromdt, todt) {
    this.service.common.progressLoader = true;
    try {
      this.service.getAdminAppointmentMileageReport(wcode, fromdt, todt, this.service.storage.get('orgCode'), (data) => {
        // //console.log(data);
        if (data.responsecode === 200) {
          this.service.common.progressLoader = false;
          this.mileageReport = data.data;
          this.dataSource = new MatTableDataSource(data.data);

        } else {
          this.mileageReport = [];
          this.dataSource = new MatTableDataSource();
          this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      //console.log(error);
    }

  }

  /* Name:Shailesh
  use: for getting weekly mileage report by worker code from date and to date and org code*/
  getWeeklyMileageReport(wcode, fromdt, todt) {
    this.service.common.progressLoader = true;
    try {
      this.service.getAdminAppointmentMileageReportWeekly(wcode, fromdt, todt, this.service.storage.get('orgCode'), (data) => {
        //console.log(data);
        if (data.responsecode === 200) {
          this.service.common.progressLoader = false;

          this.WeeklyReport = data.data.bodyValue;
          //console.log(this.WeeklyReport);
          this.keyValue = data.data.headerValue;
          this.keys = [];

          if (data.data.bodyValue.length > 0) {
            this.keys = Object.keys(data.data.bodyValue[0]);
          }
          this.dataSourceW = new MatTableDataSource(data.data.bodyValue);
        } else {
          this.WeeklyReport = [];
          this.dataSourceW = new MatTableDataSource();
          this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      //console.log(error);
    }

  }

  onDateChange(event) {
    this.page = 0;
    this.formatedDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.getMileageReport(this.selectedWorker, this.formatedDate, this.formatedDate);
  }
  onDateChangeS(event) {
    this.page = 0;
    this.formatedDateS = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.getWeeklyMileageReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
  }

  onDateChangeE(event) {
    this.page = 0;
    this.formatedDateE = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.getWeeklyMileageReport(this.selectedWorker, this.formatedDateS, this.formatedDateE);
  }

  applyFilterd(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.mileageReport = this.dataSource.filteredData;
  }

  applyFilter(filterValue) {
    this.filteredOptions = this.worker.valueChanges
      .pipe(
        startWith<string>(''),
        map(value => this._filter(filterValue.target.value)));
    //this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  /* Name:Shailesh
  use: for printing daily mileage report*/
  printReportold(): void {
    //   const printContents = document.getElementById('printTable');

    //     const WindowPrt = window.open( '' , 'Print', 'left=0,top=0,width=1800,height=1200,toolbar=0,scrollbars=0,status=0');
    //  // const WindowPrt = window.open('');
    //   WindowPrt.document.write(printContents.innerHTML);
    //   // var dimg = document.getElementById('dimg');
    //   // dimg.innerHTML ='<img src="'+this.service.common.webLogo+'" >';
    //   setTimeout(() => {
    //     WindowPrt.print();
    //     WindowPrt.close();
    //   }, 1000); 

    this.service.common.printReport('printTable');

  }


  
/* Name:Shailesh
use: for printing daily mileage report*/
printReport(): void {
  const printContents = document.getElementById('printTable').innerHTML;
  
  // const WindowPrt = window.open( '' , 'Print', 'left=0,top=0,width=1800,height=1200,toolbar=0,scrollbars=0,status=0');
  //const WindowPrt = window.open('');er
  //WindowPrt.document.write(printContents.innerHTML);
  // var dimg = document.getElementById('dimg');
  // dimg.innerHTML ='<img src="'+this.service.common.webLogo+'" >';
  //var frame1 = document.createElement('iframe');
  this.iframe.nativeElement.name = "frame1";
  this.iframe.nativeElement.style.position = "absolute";
  this.iframe.nativeElement.style.top = "-1000000px";
  document.body.appendChild(this.iframe.nativeElement);
  let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write('<html><head><title>Mileage Report</title>');
  frameDoc.document.write('</head><body>');
  frameDoc.document.write(printContents);
  frameDoc.document.write('</body></html>');
  frameDoc.document.close();
  setTimeout(() => {
  window.frames["frame1"].focus();
  window.frames["frame1"].print();
  document.body.removeChild(this.iframe.nativeElement);
  }, 1000);
}

  /* Name:Shailesh
  use: for printing weekly mileage report*/
  printWeeklyReport(): void {
    
    const printContents = document.getElementById('printWeeklyTable').innerHTML;

    // // const WindowPrt = window.open( '' , 'Print', 'left=0,top=0,width=1800,height=1200,toolbar=0,scrollbars=0,status=0');
    // const WindowPrt = window.open('');
    // WindowPrt.document.write(printContents.innerHTML);
    // // var dimg = document.getElementById('dimg');
    // // dimg.innerHTML ='<img src="'+this.service.common.webLogo+'" >';
    // setTimeout(() => {
    //   WindowPrt.print();
    //   WindowPrt.close();
    // }, 1000); 

    this.iframe.nativeElement.name = "frame1";
    this.iframe.nativeElement.style.position = "absolute";
    this.iframe.nativeElement.style.top = "-1000000px";
    document.body.appendChild(this.iframe.nativeElement);
    let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head><title>Mileage Report</title>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(printContents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(() => {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(this.iframe.nativeElement);
    }, 1000);

  }
}
