<div class="col-md-12">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header manageTopSpace">Assigned Distribution List</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <assigned-distribution-list-for-staff [workerCode]="data?.workerCode" [listOpenFrom]="'Staff List'"></assigned-distribution-list-for-staff>
    </div>
  </div>

</div>
