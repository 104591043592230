<div class="row">
    <div class="col-md-12 text-end">
        <i class="material-icons cursor-pointer close-icon" (click)="onNoClick()"> close </i>
    </div>
    <div class="col-md-12 text-center">
      <div class="mt-4 mb-4">
          <i class="material-icons check-icon"> check </i>
      </div>
      <div class="mb-4">
         <!-- <p> All appointments are assigned.</p> -->
         <p>{{data.msgShow}}</p>
      </div>  
      <hr>
      <div class="mt-3">
         <!-- <button mat-button class="no-btn me-4" (click)="onNoClick()">Cancel</button> -->
          <button mat-raised-button class="yes-btn" (click)="onNoClick()">Ok</button>
      </div>
    </div>
</div>
