<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">{{ 'main.labels.Job Type' | translate }}
  </div>
  <div class="col-lg-5 col-md-5 col-sm-12 searchfilter">
    <input matInput (keyup)="applyFilter($event.target.value)" type="text" autocomplete="false" class="form-control ps-2" autocomplete="off" placeholder="{{ 'JobType.labels.Search Job' | translate }}">
    <mat-icon class="cursor-pointer">search</mat-icon> 
  </div>

  <ng-template [ngIf]="hasPermission.addPermission === true">
  <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    <button mat-raised-button color="primary" class="ms-2" routerLink="/dashboard/jobtype/jobtype-edit" title="Create Procedure">Add</button>
  </div>
 </ng-template>

</div>
<div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-responsiv">

    <ng-container matColumnDef="procedureIcon" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef width="40"> {{ 'main.labels.Job' | translate }} </th>
      <td mat-cell *matCellDef="let element" class="common-icon-round text-nowrap">
        <img alt="No Image" class="td_avatar_img" *ngIf="element.procedureIcon"
          [src]="service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon"
          [title]="element.procedureIcon" />
        <img alt="No Image" class="td_avatar_img" *ngIf="!element.procedureIcon"
          src="../../../../../assets/common_images/profile.jpg" />
      </td>
    </ng-container>

    <ng-container matColumnDef="procedureName" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'JobType.labels.Job Name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.procedureName}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="procedureId" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'JobType.labels.Job ID' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.procedureId}} </td>
    </ng-container> -->

    <ng-container matColumnDef="procedureDescription" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'JobType.labels.Job Description' | translate }} </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.procedureDescription}} </span></td>
    </ng-container>

    <ng-container matColumnDef="regularRate" class="dis-icon">
      <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'JobType.labels.Regular Rate' | translate }} </th> -->
      <th mat-header-cell *matHeaderCellDef mat-sort-header>  Billing Rate/Hr </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.regularRate ? '$'+element.regularRate : '--'}} </span></td>
    </ng-container>

    <ng-container matColumnDef="overTimeRate" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'JobType.labels.Over Time Rate' | translate }} </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.overTimeRate ? element.overTimeRate +'%' : '--'}} </span></td>
    </ng-container>

    <ng-container matColumnDef="edit" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="action"> </th>
      <td mat-cell *matCellDef="let element" class="action">
        <!-- [routerLink]="['/dashboard','procedure','procedure-edit'] -->
        <ng-template [ngIf]="hasPermission.editPermission === true">
          <i class="material-icons pointer" [routerLink]="['/dashboard','jobtype','jobtype-edit']"
          [queryParams]="{ 'procedureCode': element.organizationBaseProcedureCode }">edit</i>
         </ng-template>

          <ng-template [ngIf]="hasPermission.delPermission === true">
          <i class="material-icons pointer ms-2" (click)="softDeleteOrganizationBasedProcedureByOrganizationBasedProcedureCode(element)">delete</i>
          </ng-template>
      </td>
    </ng-container>

    

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="procedureList.length == 0 || dataSource.filteredData.length == 0" class="p-3 noList">{{ 'JobType.messages.Procedure data not available' | translate }}</div>
</div>

<div class="w-100" *ngIf="service.common.infiniteLoader">
  <div class="loadergif">
    <img src="assets/typint_dots_black.gif" alt="Loader">
    <p>Loading...</p>
  </div>
</div>