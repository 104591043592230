import { ChatService } from './../../chat/chat.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppointmentService } from './../appointment.service';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AppointmentPopupComponent } from './../appointment-popup/appointment-popup.component';
import { AllappointmentPopupComponent } from './../allappointment-popup/allappointment-popup.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AssignedWorkerComponent } from './../create-appointment/assigned-worker/assigned-worker.component';
declare var google;
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import 'moment-timezone';
import { ShowRoutesComponent } from './../show-routes/show-routes.component';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentFilterComponent } from './../appointment-filter/appointment-filter.component';
import { SiteMapComponent } from './../site-map/site-map.component';
import { ChatComponent } from '../../chat/chat.component';
import { CreateAppointmentComponent } from './../create-appointment/create-appointment.component';
import { AssignAppointmentComponent } from './../assign-appointment/assign-appointment.component';
import { SplitAppointmentComponent } from './../split-appointment/split-appointment.component';
import { CreateAppointmentService } from './../create-appointment/create-appointment.service';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from 'src/app/shared/common.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { SessionStorageService } from 'angular-web-storage';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd DD MMMM YYYY',
    monthYearLabel: 'ddd DD MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'ddd DD MMMM YYYY',
  },
};


@Component({
  selector: 'app-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class JobViewComponent implements OnInit {
  public show_activecard: boolean = false;
  @ViewChild('mapDis', { static: true }) mapDis;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  locations: any;
  map;
  bounds;
  gmarker = [];
  isZoom: boolean = false;
  markers = [];
  allBooking = [];
  currentDate = new Date();
  userType: any;
  page = 0;
  limit = 30;
  // limit = 100;
  status = 'all';
  statuses = [
    { status: 'all', view: 'All' },
    { status: 'completed', view: 'Completed' },
    { status: 'pending', view: 'Pending' },
    { status: 'assigned', view: 'Assigned' },
    { status: 'inprogress', view: 'Inprogress' },
    { status: 'accepted', view: 'Accepted' },
  ];
  locale = {
    format: 'MM/DD/YYYY',
    direction: 'ltr', // could be rtl
    weekLabel: 'W',
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Okay',
    clearLabel: 'Clear',
    // customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1 // first day is monday
  };
  selected: any;
  alwaysShowCalendars: boolean;
  invalidDates: _moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  searchTerm;
  allFilteredData = [];
  showPopup: any;
  hours = [];
  currentDateTransfrom;
  statusCountList = [];
  workerAppointmentList = [];
  quarterDivision = ['00', '15', '30', '45'];
  date;
  allStatusCount;
  assignedStatusCount;
  formatedDate;
  hoursInterval = [];
  message;
  selectedDate;
  showmsg = true;
  technicianName = 'all';
  procedureCode = 'all';
  arrayData = [];
  procedureData = [];
  selectedProcedure: any[];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  yearMonthDateFormat = 'YYYY-MM-DD';
  currentSelectedDate;
  isShowRefresh: boolean = true;
  actionPermissions: any = [];
  driveTime_settingValue:string = '';
  controlsPermissions:IBtnControlsPermission ;
  
  constructor(
    public service: AppointmentService,
    public datepipe: DatePipe,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public ChatService: ChatService,
    public createService: CreateAppointmentService,
    private _commonService: CommonService,
    public session: SessionStorageService,
  ) {
    this.translate.setDefaultLang(this.service.session.get('language'));
  }

  /* Name:Shailesh
      use: search clinician in list */
  search() {

    this.statusCountList = [];
    this.workerAppointmentList =[];
    this.allStatusCount = '';
    this.assignedStatusCount = '';

    if (this.searchTerm) {
      this.technicianName = this.searchTerm.toLowerCase();
      this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page = 0, this.limit = 30, null, 'search');
    } else {
      this.technicianName = 'all';
      this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page = 0, this.limit = 30, null, 'search');
      // this.service.common.progressLoader = false;
      //this.workerAppointmentList = this.allFilteredData;
    }
  }

  ngOnInit() {
    this.userType = this.session.get('user_type');
    this.getData();
    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);

    /* Name:Shailesh
      use: getting all procedure by organization code */
    try {
      this.service.getAllProcedureByOrganizationCode(this.service.session.get('orgCode'), (response) => {
        //////console.log(response);
        if (response.responsecode) {
          response.data.forEach(element => {
            element.itemName = element.procedureName;
          });
          this.procedureData = response.data;
          this.selectedProcedure = response.data;
          this.dropdownList = response.data;
          response.data.forEach(element => {
            this.selectedItems.push(element);
          });
          // this.selectedItems = response.data

        }
      })
    } catch (error) {
      //console.log(error)
    }

    this.actionPermissions = this._commonService.getPermissionByComponentName("Jobs");
    this.getSettingNameByName();

    this.controlsPermissions =  this._commonService.getBtnEventPermissions();

  }

  selectedTabChange(event: any) {

  }

  /* Name:Shailesh
      use: for getting all appointment*/
  getData(selected = null) {
    this.activatedRoute.queryParams.subscribe((paramData) => {
      ////console.log(paramData.date);
      if (paramData.date) {
        ////console.log('paramData')
        if (!selected) {
          ////console.log('paradsfsdfmData')

          this.date = new FormControl(moment(paramData.date));
          this.selectedDate = this.date.value;
          this.service.common.getpercentData();
          this.currentDateTransfrom = paramData.date;
          this.formatedDate = moment(paramData.date).format('MM-dd-yyyy');
        }

        this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
      } else {
        ////console.log('!dfsdfsdfsdfsparamData')
        if (!selected) {
          this.date = new FormControl(moment());
          this.selectedDate = this.date.value;
          this.service.common.getpercentData();
          this.currentDateTransfrom = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
          this.formatedDate = this.datepipe.transform(this.currentDate, 'MM-dd-yyyy');
        }
        this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
      }
    })
  }

  /* Name:Shailesh
      use: getting appointment detail of clinician by organization code */
  getAppoitmentData(startDate, endDate, technicianName, procedureCode, page, limit, scroll = null, serach = null) {
    // debugger
    if (!scroll && !serach && this.isShowRefresh) {
      this.service.common.progressLoader = true;
    } else if (scroll) {
      this.service.common.infiniteLoader = true;
    }
    let allstatus = 'pending,assigned,accepted,inprogress,completed,expired,late or cancelled';
    // this.service.getAppointmentDetailOfTechnicianByOrganizationCode(this.service.session.get('orgCode'), 'all', startDate, endDate, (data) => {
    try {
      this.service.adminGetAppointmentDetailOfJobByOrganizationCodeForTestByPage(this.service.session.get('orgCode'), allstatus, startDate, endDate, technicianName, procedureCode, page, limit, (data) => {
        // //console.log(data);
        // allStatusCount=this.data.allStatusCount;
        if (data.responsecode == 200) {
          if (data.data.statusCountList.length > 0) {
            data.data.statusCountList.forEach(element => {
              if (element.timeFormat === "hh:mm a") {
                element.viewStartTime = moment(element.startDateTimestamp).utc().format("h a");
              } else {
                element.viewStartTime = moment(element.startDateTimestamp).utc().format(element.timeFormat);
              }
              element.viewEndTime = moment(element.endDateTimestamp).utc().format(element.timeFormat);
              element.startTime = moment(element.startDateTimestamp).format("HH:mm");
              element.endTime = moment(element.endDateTimestamp).format("HH:mm");
              this.hoursInterval = [];
              let x = element.startDate.split(' ')[1].split(':')[0];
              for (let i = 0; i < 60; i++) {
                let k;
                if (i <= 9) {
                  k = '0' + i;
                } else {
                  k = i;
                }
                this.hoursInterval.push(x + ':' + k);
              }
              element.interval = this.hoursInterval;

              if (element.fullfillmentRatio != null) {
                const roundData = Math.round(element.fullfillmentRatio)
                // //////console.log(this.service.common.dashboardColorData);
                const colodIndex = this.service.common.dashboardColorData.findIndex(data => data.percent == roundData);
                // //console.log('_Job_Details_col', colodIndex, roundData);
                element.dataPercent = this.service.common.dashboardColorData[colodIndex].class;
                // //console.log('_Job_Details_col', colodIndex, roundData, element.dataPercent, colodIndex);
              } else {
                element.dataPercent = '';
              }
            });
          }
          this.statusCountList = data.data.statusCountList;
          this.allStatusCount = data.data.allStatusCount;
          this.assignedStatusCount = data.data.assignedStatusCount;

          if (scroll) {

            if (data.data.appointmentDetailList.length > 0) {
              // this.arrayData = this.workerAppointmentList;
              let tempArray = [];
              tempArray = this.workerAppointmentList;
              // data.data.appointmentDetailList.forEach(elementData => {
              //     elementData.startTime = moment(elementData.timestampJobsStartTime).utc().format("HH:mm");
              //     elementData.endTime = moment(elementData.timestampJobsEndTime).utc().format("HH:mm");

              //     elementData.viewStartTime = moment(elementData.timestampJobsStartTime).utc().format(elementData.timeFormat);
              //     elementData.viewEndTime = moment(elementData.timestampJobsEndTime).utc().format(elementData.timeFormat);

              //     elementData.driveTime = moment(elementData.drivingStartTimeStamp).tz(elementData.timezone).format("HH:mm");

              //     tempArray.push(elementData)
              //   });
              // this.arrayData.push(data.data.appointmentDetailList)
              // tempArray.push(data.data.appointmentDetailList)
              // this.workerAppointmentList = data.data.appointmentDetailByWorkerCodeList;
              // this.allFilteredData = data.data.appointmentDetailByWorkerCodeList;

              setTimeout(() => {
                //////console.log(this.arrayData);
                // this.workerAppointmentList = this.arrayData;
                this.workerAppointmentList = tempArray;
               // this.allFilteredData = this.arrayData;
                this.service.common.progressLoader = false;
                this.service.common.infiniteLoader = false;
              }, 300);
            } else {
              this.service.common.progressLoader = false;
              this.service.common.infiniteLoader = false;
            }

          } else {

            // if (data.data.appointmentDetailList.length > 0) {
            //    data.data.appointmentDetailList.forEach(elementData => {
            //       elementData.startTime = moment(elementData.timestampJobsStartTime).utc().format("HH:mm");
            //       elementData.endTime = moment(elementData.timestampJobsEndTime).utc().format("HH:mm");

            //       elementData.viewStartTime = moment(elementData.timestampJobsStartTime).utc().format(elementData.timeFormat);
            //       elementData.viewEndTime = moment(elementData.timestampJobsEndTime).utc().format(elementData.timeFormat);

            //       elementData.driveTime = moment(elementData.drivingStartTimeStamp).tz(elementData.timezone).format("HH:mm");
            //     });
            // }
            // //console.log('Data_Job_Details: ', data)
            this.workerAppointmentList = data.data.appointmentDetailList;
           // this.allFilteredData = data.data.appointmentDetailList;
            //  //////console.log(this.assignedStatusCount);
            //  //////console.log(this.allStatusCount);
            //////console.log(this.statusCountList);
            // //console.log('StatusCountList_Job_Details', this.statusCountList);
            // //console.log('WorkerAppointmentList_Job_Details', this.workerAppointmentList);
            this.service.common.progressLoader = false;
            this.service.common.infiniteLoader = false;
            if (data.message != "Success") {
              this.message = data.message;
              this.showmsg = true;
            } else {
              this.message = '';
              this.showmsg = false;
            }

          }


        } else {
          if (!scroll) {
            this.service.common.progressLoader = false;
            this.service.common.infiniteLoader = false;
            this.message = data.message;
            this.statusCountList = [];
            this.workerAppointmentList = [];
          }
        }
      })
    } catch (error) {
      //console.log(error);
    }

  }

  addJobFullFilledRatioClass(fullFilledRatioPercent: any){
    if(fullFilledRatioPercent){
    if(fullFilledRatioPercent >= 11 && fullFilledRatioPercent <= 20){
      return 'redmed';
    } 
    else if(fullFilledRatioPercent >= 21 && fullFilledRatioPercent <= 30){
      return 'redlight';
    }
    else if(fullFilledRatioPercent >= 31 && fullFilledRatioPercent <= 40){
      return 'yellowdark';
    }
    else if(fullFilledRatioPercent >= 41 && fullFilledRatioPercent <= 50){
      return 'yellowmeddark';
    }
    else if(fullFilledRatioPercent >= 51 && fullFilledRatioPercent <= 60){
      return 'yellowmedlight';
    }
    else if(fullFilledRatioPercent >= 61 && fullFilledRatioPercent <= 70){
      return 'yellowlight';
    }
    else if(fullFilledRatioPercent >= 71 && fullFilledRatioPercent <= 80){
      return 'greenlight';
    }
    else if(fullFilledRatioPercent >= 81 && fullFilledRatioPercent <= 90){
      return 'greenmed';
    }
    else if(fullFilledRatioPercent >= 91 && fullFilledRatioPercent <= 100){
      return 'greendark';
    }
    else if(fullFilledRatioPercent >= 101){
      return 'reddark';
    } else {
      return 'reddark';
    }
  }
  }

  setUtcTimeFormate(timestamp: any, timeZone: any){
    // //console.log('newTime_Job_Details_1', timestamp, timeZone);
    if(timestamp && timeZone){
      let newTime =  moment(timestamp).tz(timeZone).format("HH:mm");
      // //console.log('newTime_Job_Details_2', newTime, timestamp, timeZone);
    return moment(timestamp).tz(timeZone).format("HH:mm");
    }
  }

  convert12to24(time12: any) {
    if(time12){
    const [time, period] = time12.split(' ');
    let [hours, minutes] = time.split(':');
  
    hours = parseInt(hours, 10);
  
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Ensure the hours and minutes are displayed as two digits
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.padStart(2, '0');
  
    return `${hours}:${minutes}`;
  }
  }
  

  /* Name:Shailesh
      use: to choose date */
  choosedDate(event) {
    //////console.log(event);
    this.selectedDate = event.value;
    this.date.setValue(event.value);
    const date = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.currentDateTransfrom = date;
    this.arrayData = [];
    this.workerAppointmentList = [];
    this.statusCountList = [];
    this.getAppoitmentData(date, date, this.technicianName, this.procedureCode, this.page = 0, this.limit = 30);
  }
  onItemSelect(item: any) {
    //////console.log(item);
    //////console.log(this.selectedItems);
    this.getFilterdData();
  }
  OnItemDeSelect(item: any) {
    //////console.log(item);
    //////console.log(this.selectedItems);
    this.getFilterdData();
  }

  onSelectAll(items: any) {
    //////console.log(items);
    this.getFilterdData();
  }

  getFilterdData() {
    let selectedData = []
    if (this.selectedItems.length > 0) {
      this.selectedItems.forEach(element => {
        selectedData.push(element.organizationBaseProcedureCode);
      });
      this.procedureCode = selectedData.toString();
    } else {
      this.procedureCode = 'all';
    }
    this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page = 0, this.limit = 30, null, 'search');
  }
  onDeSelectAll(items: any) {
    //////console.log(items);
    this.procedureCode = 'all'
    this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page = 0, this.limit = 30, null, 'search');
  }

  toggle() {
    this.show_activecard = !this.show_activecard;
  }

  geteditData(element) {
    //////console.log(element);
    if (this.showPopup == element.appointmentsDurationCode) {
      this.showPopup = '';
    } else {
      this.showPopup = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }

  /* Name:Shailesh
      use: to open appointment list popup */
  showAppointment(appointment) {
    //console.log('showAppointment_Job', appointment);

    const dialogRef = this.dialog.open(AppointmentPopupComponent, {
      width: '730px',
      disableClose: true,
      data: { 'appointment': appointment }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      this.isShowRefresh = false;
      // if (result != undefined) {
      this.page = 0;
      this.limit = 30;
      document.getElementById('color-grid1').scrollTop = 0;
      this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
      // }
    });
  }

  /* Name:Shailesh
      use: to open appointment popup count */
  openAppointmentCounts(data) {
    ////console.log(data);
    const date = this.datepipe.transform(data.startDate.split(' ')[0], 'yyyy-MM-dd');
    ////console.log(date);
    let WeekstartDate = moment(date).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(date).endOf('week').format(this.yearMonthDateFormat);
    // 
    if (data.statusCount > 0) {
     // this.service.common.progressLoader = true;
      const dialogRef = this.dialog.open(AllappointmentPopupComponent, {
        width: '900px',
        disableClose: true,
        panelClass : 'popup-pos',
        data: { date, data, procedure: this.procedureCode, currentSelectedDate: date, WeekstartDate: WeekstartDate, weekendDate: weekendDate }
      });
      dialogRef.afterClosed().subscribe(result => {
        //////console.log(result);
        if (result) {
          this.isShowRefresh = false;
          this.page = 0;
          this.limit = 30;
          document.getElementById('color-grid1').scrollTop = 0;
          this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
        }
      });

    }
  }

  openSingleAppointment(data, workIdx) {
    ////console.log(data);
    // const date = this.datepipe.transform(data.startDate.split(' ')[0], 'yyyy-MM-dd');
    ////console.log(date);
    // 
    if (data) {
     // this.service.common.progressLoader = true;
      const dialogRef = this.dialog.open(AllappointmentPopupComponent, {
        width: '900px',
        disableClose: true,
        panelClass : 'popup-pos',
        data: { data, isSingleOpen: true, workIdx: workIdx}
      });
      dialogRef.afterClosed().subscribe(result => {
        //////console.log(result);
        if (result) {
          this.isShowRefresh = false;
          this.page = 0;
          this.limit = 30;
          document.getElementById('color-grid1').scrollTop = 0;
          this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
        }
      });

    }
  }

  /* Name:Shailesh
     use: to change next and previous date */
  arrowDateChange(arrow) {
    if (arrow == 'left') {
      // this.date.value.add(-1, 'days');
      this.date = new FormControl(moment(this.selectedDate).add(-1, 'days'));
      this.choosedDate(this.date);
    } else if (arrow == 'right') {
      // this.date.value.add(+1, 'days');
      this.date = new FormControl(moment(this.selectedDate).add(+1, 'days'));
      this.choosedDate(this.date);
    }
  }

  /* Name:Shailesh
     use: to auto assign appointments to clinician */
  autoAssign() {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to automatically assign staff?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          const orgCode = this.service.session.get('orgCode');
          this.service.common.progressLoader = true;
          this.service.common.percentageLoader = true;
          try {
            this.service.adminAssignJobsByOptaplanner(orgCode, this.currentDateTransfrom, this.currentDateTransfrom, (res) => {
              //////console.log(res);
              if (res.responsecode == 200) {

                this.service.totalRecords = res.data.totalRecord;
                this.service.processedRecords = res.data.processRecord;
                this.service.common.progressText = 'Processing ' + this.service.processedRecords + ' of  ' + this.service.totalRecords + ' appointments';
                try {
                  this.service.getAppointmentThreadProcessingByCode(res.data.appointmentsThreadProcessingCode, (response) => {
                    let arrayData = [];
                    response.data.ewresLst.forEach(element => {
                      if (element.workerWithDistanceList.length > 0) {
                        arrayData.push(element);
                      }
                    });
                    setTimeout(() => {
                      //console.log(arrayData);
                      this.service.common.progressLoader = false;
                      this.service.common.progressText = '';
                      this.service.common.percentageLoader = false;
                      if (arrayData.length == 0) {
                        this.getSplitAppointment(response.data.appointmentsDurationCodeList);
                      } else {

                        this.openDialog(arrayData);
                      }
                    }, 2000);
                  })
                } catch (error) {
                  //console.log(error)
                }

              } else {
                this.service.common.progressLoader = false;
                this.service.common.message = res.message;
                this.service.common.errorMessage = true;
              }

            })
          } catch (error) {
            //console.log(error)
          }
        } catch (error) {
          //console.log(error)
        }

      }
    });

  }

  /* Name:Shailesh
     use: to open split appointment popup */
  getSplitAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(SplitAppointmentComponent, {
      width: '450px',
      disableClose: true,
      data: { jobDurationCode }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        this.isShowRefresh = false;
        // this.getAppointments();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }

  /* Name:Shailesh
     use: to open assign appointment to clinician popup  */
  openDialog(workers): void {
    ////console.log(this.currentDate);
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      width: '560px',
      disableClose: true,
      data: { data: workers, fromdate: this.currentDateTransfrom, toDate: this.currentDateTransfrom, scheduledDate: this.currentDateTransfrom }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      this.isShowRefresh = false;
      this.page = 0;
      this.limit = 30;
      document.getElementById('color-grid1').scrollTop = 0;
      this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
    });
  }

  onScroll() {
    //////console.log('scroll');
    this.page = this.page + 1;
    // this.getData(this.page, this.limit, 'scroll');
    this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit, 'scroll');
  }

  /* Name:Shailesh
     use: to open popup to show route between clinciian to patient */
  showRoute(data) {
    //////console.log(data);
    this.service.common.progressLoader = true;
    try {
      this.service.mobileGetRouteByTechnicianCodeAndDate(data.workerCode, this.currentDateTransfrom, (response) => {
        if (response.responsecode == 200) {
          const dialogRef = this.dialog.open(ShowRoutesComponent, {
            width: '800px',
            disableClose: true,
            height: '575px',
            data: { workerCode: data.workerCode, data: response.data }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
              this.isShowRefresh = false;
              this.service.common.message = result;
              this.service.common.successMessage = true;
            }
          });
        } else {
          this.service.common.progressLoader = false;
          this.service.common.message = 'No Job is present';
          this.service.common.errorMessage = true;
        }
      });
    } catch (error) {
      //console.log(error)
    }

  }

  filterTech() {

    const dialogRef = this.dialog.open(AppointmentFilterComponent, {
      width: '550px',
      disableClose: true,
      data: { data: this.procedureCode }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log(result);
      if (result! + undefined) {
        this.isShowRefresh = false;
        this.procedureCode = result;
        this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page = 0, this.limit = 30, null, 'search');
      }
    })
  }

  /* Name:Shailesh
       use: to open sitemap popup */
  siteMap() {
    this.service.common.progressLoader = true;
    try {
      this.service.adminGetSitemapDetailsByOrganizationCode(this.service.session.get('orgCode'), (response) => {
        ////console.log(response);
        if (response.responsecode == 200) {
          const dialogRef = this.dialog.open(SiteMapComponent, {
            width: '550px',
            disableClose: true,
            data: { data: response.data }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.isShowRefresh = false;
            ////console.log(result);
            // this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, result, this.page = 0, this.limit = 10, null, 'search');

          })
        } else {
          this.service.common.progressLoader = false;
        }

      })
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Shailesh
     use: to open chat box popup */
  showChat(element) {
    ////console.log(element);
    this.service.common.progressLoader = true;
    try {
      this.ChatService.adminGetGroupDetailByUserCodeAndAdminCode(element.workerCode, this.service.session.get('userCode'), (response) => {
        ////console.log(response);
        if (response.responsecode == 200) {
          ////console.log(response.data.groupDetailsCode);
          this.service.common.progressLoader = false;
          this.openPerticularChat(response.data.groupDetailsCode);
        } else {
          this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      //console.log(error)
    }


  }

  /* Name:Shailesh
       use: to open selected clinician popup */
  openPerticularChat(groupDetailsCode): void {
    ////console.log(groupDetailsCode);
    const dialogRef = this.dialog.open(ChatComponent, {
      width: '66%',
      panelClass: 'chat-popup',
      data: { groupDetailsCode: groupDetailsCode },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isShowRefresh = false;
      if (this.service.common.globalChatCount != 0) {
          this.service.common.getGlobalCountChat();
      }
    });
  }

  /* Name:Shailesh
       use: to open create appointment popup */
  openAppointment() {
    const dialogRef = this.dialog.open(CreateAppointmentComponent, {
      width: '70%',
      height: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      this.isShowRefresh = false;
      if (result != undefined) {
        if (result.saveAndassign == 'saveAndassign') {
          this.assignAppointment(result.appointmentsDurationCode);
        } else {
          this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
        }
      }
    });
  }

  /* Name:Shailesh
     use: to open assign appointment popup */
  assignAppointment(jobDurationCode) {
    this.service.common.progressLoader = true;
    let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);
    //console.log(this.currentSelectedDate, WeekstartDate, weekendDate);

    try {
      this.createService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, this.currentSelectedDate, WeekstartDate, weekendDate, (data) => {
        //console.log(data);

        //|| ProcedureDetailsList == 1
        if (data.data.workerWithDistanceList.length > 0) {
          const dialogRef = this.dialog.open(AssignAppointmentComponent, {
            width: '750px',
            disableClose: true,
            data: { data, jobDurationCode }
          });
          dialogRef.afterClosed().subscribe(result => {
            //console.log(result);
            if (result != undefined) {
              this.isShowRefresh = false;
              // this.appointmentchange = true;
              // this.getAppointments();
              // this.dialogRef.close('ok');
              this.dialog.closeAll();
              //this.getData();
              // //console.log(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
              this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
              this.service.common.message = result;
              this.service.common.successMessage = true;
              //this.getStatus('all', this.currentSelectedDate);
            }
          });
        }
        else {

          const dialogRef = this.dialog.open(SplitAppointmentComponent, {
            width: '450px',
            disableClose: true,
            data: { data, jobDurationCode }
          });
          dialogRef.afterClosed().subscribe(result => {
            //////console.log(result);
            if (result != undefined) {
              this.isShowRefresh = false;
              // this.appointmentchange = true;
              // this.getAppointments();
              this.getAppoitmentData(this.currentDateTransfrom, this.currentDateTransfrom, this.technicianName, this.procedureCode, this.page, this.limit);
              this.dialog.closeAll();
              this.service.common.message = result;
              this.service.common.successMessage = true;
              // this.getStatus('all', this.currentSelectedDate);
            }
          });
        }
        this.service.common.progressLoader = false;

      });
    } catch (error) {
      //console.log(error)
    }
  }

  getSettingNameByName()
  {
    this.service.getSettingNameByName('IsDrivingTime', this.service.session.get('orgCode')).subscribe((res:any)=> {
      if (res.responsecode == 200) {
         this.driveTime_settingValue = res.settingValue
        //  //console.log('this.driveTime_settingValue_Job_Details: ', this.driveTime_settingValue);
      }  
    })
  }


}

