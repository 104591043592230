import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JobRateCardService } from '../job-rate-card/job-rate-card.service';
import { TranslateService } from '@ngx-translate/core';
import { debounce } from 'lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface PeriodicElement {
  position: number;
  description: string;
  symbol: string;
  id: number;
} 

@Component({
  selector: 'job-rate-card',
  templateUrl: './job-rate-card.component.html',
  styleUrls: ['./job-rate-card.component.scss']
})

export class JobRateCardComponent implements OnInit {
  @Input() customerCode: any;
  displayedColumns: string[] = [
    'procedureIcon', 
    'procedureName',
    'regularRate', 
    'overTimeRate'
  ];

  showSaveBtn = {};

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  procedureList = [];
  public show: boolean = false;
  public searchClick: any = 'Show';
  page = 0;
  limit = 30;
  

  constructor(
    public service: JobRateCardService,
    public translate: TranslateService

  ) { 
    this.translate.setDefaultLang(this.service.local.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }  

  ngOnInit() {
    this.page = 0;
    this.getData(this.page, this.limit);
  } 

  applyFilter(searchText: string) {
    this.page = 0;
    this.service.common.progressLoader = true;
  try {
    if(searchText && searchText.trim()){
    this.service.getAllJobTypeListByOrganizationCodeSearchText(this.service.local.get('orgCode'), this.customerCode, searchText, (response) => {
      if(response.responsecode === 200){
          setTimeout(() => {
            this.procedureList = response.data;
            this.dataSource = new MatTableDataSource(this.procedureList);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }, 1000);
      } else {
        this.service.common.progressLoader = false;
          this.procedureList = [];
          setTimeout(() => {
            this.dataSource = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 1000);        
      }
  });
  } else {
    this.getData(this.page, this.limit);
  } 
  } catch (error) {
    //console.log(error);
  }
  }

  /* Name:Vivek Chauhan
      use: for getting all Job Type by org code and Customer code*/
  getData(page, limit, scroll = null) {
    if (!scroll) {
      this.service.common.progressLoader = true;
    } else if(scroll) {
      this.service.common.infiniteLoader = true;
    } 
    try {
      this.service.getAllJobTypeListByOrganizationCodeByPage(this.service.local.get('orgCode'), this.customerCode, page, limit, (response) => {
      //////console.log(response);
      if (response.responsecode == 200) {
        if (scroll) {
          let arrayData = this.dataSource.data;
          response.data.forEach(element => {
            const index = this.service.common.durationHours.findIndex(data => data.value == element.duration);
            if (index !== -1) {
              element.durationData = this.service.common.durationHours[index].text;
            }
            arrayData.push(element);
          });
          setTimeout(() => {
            this.procedureList = arrayData;
            this.dataSource = new MatTableDataSource(arrayData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
            this.service.common.infiniteLoader = false;
          }, 1000);
        } else {
          response.data.forEach(element => {
            const index = this.service.common.durationHours.findIndex(data => data.value == element.duration);
            if (index !== -1) {
              element.durationData = this.service.common.durationHours[index].text;
            }
          });
         
            this.procedureList = response.data;
            this.dataSource = new MatTableDataSource(response.data);
            ////console.log(response.data)
          setTimeout(() => {  
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            // this.dataSource.paginator = this.paginator;
            this.service.common.progressLoader = false;
            this.service.common.infiniteLoader = false;
          });
         
        }

      } else {
        if (!scroll) {
          this.procedureList = [];
          this.dataSource = new MatTableDataSource();
        } else {
          this.service.common.infiniteLoader = false;
        }
        this.service.common.progressLoader = false;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

  filterToggle() {
    this.show = !this.show;

    if (this.show) {
      this.searchClick = "Hide";
    }
    else {
      this.searchClick = "Show";
    }
  }

  onScroll() {
    //////console.log('scroll');
    this.page = this.page + 1;
    this.getData(this.page, this.limit, 'scroll');
  }

  editRowData(event, item: any, idx: number, keyName: string){
    // //console.log('editRowData', idx, keyName, event.target.value, item);
    this.showSaveBtn[idx] = true;
    return item[keyName] = event.target.value;
  }

  saveRowData(dataSource: any){    
    // //console.log('saveRowData', idx, item);
    // this.showSaveBtn[idx] = false;
    let cloneRateList =  dataSource?.data && dataSource.data.length > 0 && JSON.parse(JSON.stringify(dataSource.data));
    let customerReq = cloneRateList && cloneRateList.length > 0 && cloneRateList.map((item) => {
      if(item){
        let newItem = {
            customerCode: this.customerCode,
            customerJobTypeRateCode: item?.customerJobTypeRateCode,
            organizationBaseProcedureCode: item?.organizationBaseProcedureCode,
            overTimeRate: item?.overTimeRate ? parseFloat( item.overTimeRate) : 0,
            regularRate: item?.regularRate ? parseFloat( item.regularRate) : 0
          }
          return newItem;
      }
      return item;
    });
    // //console.log('saveRowDataReq', customerReq);
    // return;
    this.service.common.progressLoader = true;
    this.service.saveOrUpdateCustomerJobTypeRate(customerReq, (response) => {
      this.service.common.progressLoader = false;
      if(response.responsecode === 200){
        this.page = 0;
        this.getData(this.page, this.limit);
        this.service.common.message = response.message;
        this.service.common.successMessage = true;
      } else {
        this.service.common.message = response.message;
        this.service.common.errorMessage = true;
      }
    })
  }

}

