import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BroadcastMessageService {

  constructor(private http: HttpClient, private service: ApiRootService) { }

  bmformData = {
      "isAdmin": true,
      "message": "string",
      "organizationCode": "string",
      "userCode": "string",
      "userName": "string"
  }

   /* Name:Shailesh
      use: to send broadcast message to all clinician and groups */
  saveBroadcastMessage(formData, callback){
    return this.http.post(this.service.getChatUrl() + '/saveBroadcastMessage' , formData).subscribe((data) => {
        callback(data);
      }
    )
  }

}
