import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {

  constructor(
    public service: ApiRootService,
    public http: HttpClient,
    public storage: SessionStorageService,
    public common: CommonService,
  ) { }

  /* Name:Vivek Chauhan
  use: for getting All Staff  member */
  getAllCompletedJobsByCustomerCode(organizationCode, customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllAppointmentsByOrganizationCodeAndCustomerCodeForInvoice/' + organizationCode + '/' + customerCode).subscribe((data) => {
      callback(data);
    })
  }

    /* Name:Vivek Chauhan
  use: for getting All Staff  member with start and end date range */
  getAllCompletedJobsByCustomerCodeV2(organizationCode, customerCode, startDate, endDate, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/getAllAppointmentsByOrganizationCodeAndCustomerCodeForInvoice/' + organizationCode + '/' + customerCode + '/' + startDate + '/' + endDate).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vivek Chauhan new API
  use: for delete/remove Invoice */
  softDeleteInvoiceDetail(invoiceCode, callback) {
    return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteInvoiceDetail/' + invoiceCode).subscribe((data) => {
      callback(data);
    })
  }

    /* Name:Vivek Chauhan
  use: for getting All Staff  member */
  generateInvoice(data, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/generateInvoice', data).subscribe((data) => {
        callback(data);
    });
    }

        /* Name:Vivek Chauhan
  use: for Save Invoice data */
    saveInvoice(data, callback){
      return this.http.post(this.service.getHealthCareUrl() + '/saveInvoice', data).subscribe((data) => {
        callback(data);
    });
    }

    // {organizationCode}/{startDT}/{endDT}

      /* Name:Vivek Chauhan
  use: for getting All saved invoice list */
  getAllSavedInvoiceByOrganizationCode(orgCode, page, limit, formatedDateS, formatedDateE, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllInvoiceByPage/' + orgCode + '/' + formatedDateS + '/' + formatedDateE + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  } 

  /* Name:Vivek Chauhan
  use: for getting All saved invoice list for customer */
  getAllInvoiceForCustomerByPage(orgCode, page, limit, formatedDateS, formatedDateE, customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllInvoiceForCustomerByPage/' + orgCode + '/' + formatedDateS + '/' + formatedDateE + '/' + customerCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  } 
  
  /* Name:Vivek Chauhan
  use: for getting invoice detail by invoice code */
  getInvoiceDetail(invoiceCode, groupBy, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getInvoiceDetail/' + invoiceCode + '/' + groupBy).subscribe((data) => {
      callback(data);
    })
  }

  //   /* Name:Vivek Chauhan
  // use: for send invoice to coustomer by invoice code */
  // sendInvoiceToCustomer(invoiceCode, callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/sendInvoiceToCustomer/' + invoiceCode).subscribe((data) => {
  //     callback(data);
  //   })
  // }

      /* Name:Vivek Chauhan
  use: for send invoice to coustomer by invoice code */
  sendInvoiceToCustomer(reqBody, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/sendInvoiceToCustomer/', reqBody).subscribe((data) => {
      callback(data);
    })
  }

  /* name: Vivek Chauhan
  use: Send attachment on email. */
  sendInvoiceWithAttachmentToCustomer(formData : FormData) {
    return this.http.post(this.service.getHealthCareUrl() + '/sendInvoiceWithAttachmentToCustomer/', formData);
  }

  /* name: Vivek Chauhan
  use: Send attachment on email with multiple keys */
  sendInvoiceWithAttachmentToCustomerNew(formData : FormData) {
    return this.http.post(this.service.getHealthCareUrl() + '/v2/sendInvoiceWithAttachmentToCustomer/', formData);
  }

  /* Name:Vivek Chauhan
  use: for getting Customer Admin List */
  getCustomerAdminList(customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getCustomerAdminDetailsOnlyByCustomerCode/' + customerCode).subscribe((data) => {
      callback(data);
    })
  }
  
  getInvoiceReports(organizationCode:string, startDT:string, endDT:string, customerName:string, invoiceStatus:string, page:number, limit:number) : Observable<any>
  {
    return this.http.get(`${this.service.getHealthCareUrl()}/getInvoiceReportByPage/${organizationCode}/${startDT}/${endDT}/${customerName}/${invoiceStatus}?page=${page}&limit=${limit}`);
  }

  updateInvoicePaymentDetails(invoiceCode:string, payload:any) : Observable<any>
  {
    return this.http.put(`${this.service.getHealthCareUrl()}/updateInvoicePaymentDetails/${invoiceCode}`, payload);
  }
  
}
