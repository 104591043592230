<div class="col-md-12">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Failed Staff</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <div class="w-100 d-flex">
          <input type="text" class="form-control w-100 me-4" id="search-patient" autocomplete="off"
             placeholder="Search By Staff" name="search-patient" 
             [(ngModel)]="searchText"
             (keyup)="applyFilter($event.target.value)">         
        <button class="btn-close cursor-pointer" matDialogClose></button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <ul class="{{filtered_eligibleStaffsList.length > 4 ? 'poupup-content' : ''}}"
      *ngIf="filtered_eligibleStaffsList.length > 0">
      <li class="mt-2" *ngFor="let element of filtered_eligibleStaffsList | searchFilter : searchText:['workerName', 'failReason', 'email']; index as idx">
        <div class="row">
          <div class="col-md-12 font-card">
            <mat-card class="example-card">
              <mat-card-content>
                <div class="row">
                  <div class="col avatarBox">
                    <img class="staff-image me-2" [alt]="element.workerName"
                      [src]="img_serverPath + element.workerLogo"
                      onerror="this.src='assets/common_images/profile.jpg';" />
                  </div>
                  <div class="col w-auto staff-hover">
                    <p class="mb-0 ms-2"><span appPopover [appPopoverData]="element" [placement]="'top'">{{element.workerName | capitalize}}</span></p>
                    <small class="email mb-0 ms-2">{{element.email}}</small>
                  </div>

                  <div class="col">
                    <p class="mb-0">Failed Reason: </p>
                    <small class="email mb-0">{{element.failReason}}</small>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>

          </div>

        </div>
      </li>
    </ul>

    <div class="row d-flex align-items-center spinner spinner-sm" *ngIf="showSpinner && filtered_eligibleStaffsList.length === 0">
      <div class="col-lg-1 text-end">
        <mat-spinner></mat-spinner>
      </div>
      <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
    </div>

    <div class="row" *ngIf="!showSpinner && filtered_eligibleStaffsList.length === 0">
      <div>
        <span class="text-danger">No Staff Available</span>
      </div>
    </div>
  </div>

</div>