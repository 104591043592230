<div class="row mb-2" *ngIf="!isShortcutModalOpen">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">{{addShift_Form.controls.shift_master_code.value ? "Update Shift" : "Add Shift"}}</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
      </div>
    </div>
  </div>

  <div class="row main-div">
    <form [formGroup]="addShift_Form">
    <div class="row mb-3">
        <div class="form-group col-lg-6">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{'Shift.labels.Shift Start Time' | translate }}</mat-label>
            <input matInput formControlName="start_time" [matAutocomplete]="startTime_autocomplete"
            (keyup)="startTimeKeyUp_ForFilter(addShift_Form.controls.start_time.value.trim())">
            <mat-icon matSuffix >access_time</mat-icon>
            </mat-form-field>

             <mat-autocomplete #startTime_autocomplete>
            <mat-option  *ngFor="let time of startTime_Filter " [value]="time">
              <span>{{ time }} </span>
            </mat-option>
          </mat-autocomplete>
           
          <span class="help-block err"
            *ngIf="addShift_Form.controls.start_time.errors?.required && (is_formSubmitted || addShift_Form.controls.start_time.touched)">{{ 'Shift.Validators.Field can not be a blank' | translate }}
          </span>
          <span class="help-block err"
          *ngIf="addShift_Form.controls.start_time.errors?.pattern && (is_formSubmitted || addShift_Form.controls.start_time.touched)">Expected format: H:mm AM|PM
        </span>
        </div>

        <div class="form-group col-lg-6">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{'Shift.labels.Shift End Time' | translate }}</mat-label>
            <input matInput  formControlName="end_time" (keyup)="endTimeKeyUp_ForFilter(addShift_Form.controls.end_time.value.trim())"
            [matAutocomplete]="endTime_autocomplete">
            <mat-icon matSuffix >access_time</mat-icon>
           </mat-form-field>

          <mat-autocomplete #endTime_autocomplete>
            <mat-option  *ngFor="let time of endTime_Filter " [value]="time">
              <span>{{ time }} </span>
            </mat-option>
          </mat-autocomplete>
          <span class="help-block err"
            *ngIf="addShift_Form.controls.end_time.errors?.required && (is_formSubmitted || addShift_Form.controls.end_time.touched)">{{ 'Shift.Validators.Field can not be a blank' | translate }}
          </span>
          <span class="help-block err"
          *ngIf="addShift_Form.controls.end_time.errors?.pattern && (is_formSubmitted || addShift_Form.controls.end_time.touched)">Expected format: H:mm AM|PM
        </span>
        </div>
      </div>

      <div *ngIf="shiftBreakFormList.controls.length ==0">
        <button mat-button color="primary" (click)="addNewForm()">Add Break</button>
      </div>

      <ng-container formArrayName="shiftBreakRequestList">
        <div class="w-100 overflowHeight pt-1 pb-1">
          <div class="row mb-3" *ngFor="let element of shiftBreakFormList.controls; let i=index ; let last = last;" [formGroupName] = "i">
            <div class="col-lg-4">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Break Type</mat-label>
                <input matInput formControlName="breakType" type="text"
                  >
              </mat-form-field>
              <span class="help-block err"
              *ngIf="shiftBreakFormList.controls[i].get('breakType').errors?.required && (is_formSubmitted || shiftBreakFormList.controls[i].get('breakType').touched)">
              Break type is required
            </span>
          </div>

          <div class="col-lg-3">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Paid Status</mat-label>
              <mat-select formControlName="paidStatus">
                <mat-option value="Paid">Paid</mat-option>
                <mat-option value="Unpaid">Unpaid</mat-option>
              </mat-select>
            </mat-form-field>
            <span class="help-block err"
            *ngIf="shiftBreakFormList.controls[i].get('paidStatus').errors?.required && (is_formSubmitted || shiftBreakFormList.controls[i].get('paidStatus').touched)">
            Please select status
          </span>
        </div>

        <div class=" col-lg-3">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Duration</mat-label>
            <input class="minute_input" matInput formControlName="durationMin" type="text" placeholder="Ex. 00">
            <i class="minute" matSuffix>Min</i>
          </mat-form-field>
          <span class="help-block err"
          *ngIf="shiftBreakFormList.controls[i].get('durationMin').errors?.required && (is_formSubmitted || shiftBreakFormList.controls[i].get('durationMin').touched)">
          Please select duration
        </span>
          <span class="help-block err"
            *ngIf="shiftBreakFormList.controls[i].get('durationMin').errors?.pattern && (is_formSubmitted || shiftBreakFormList.controls[i].get('durationMin').touched)">
            Duration should be between 1 to 100 (min)
          </span>
        </div>

        <div class="form-group col-lg-2 d-flex">          
          <div class="row">
            <button mat-icon-button color="warn" (click)="deleteFrom_FormArray(i)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" *ngIf="last" (click)="addNewForm()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
  
      </form>
  </div>

  <div class=" footer mt-4 d-flex offset-md-8 justify-content-end">
    <button *ngIf="!isShortcutModalOpen" mat-button (click)="closePopUp()" color="primary">
      Close
    </button>
    <button class="ms-4" [disabled]="is_btnLoading" mat-raised-button color="primary" (click)="add_UpdateShift()">
      {{addShift_Form.controls.shift_master_code.value ? "Update" : "Save"}}
     </button>
 </div>