import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import {  MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../../providers-dashboard/reports/reports.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-tracking-log',
  templateUrl: './tracking-log.component.html',
  styleUrls: ['./tracking-log.component.scss']
})
export class TrackingLogComponent implements OnInit, OnDestroy{
  page: number;
  dataSource;
  allReport = [];
  exportAsConfig: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'checkinout', // the id of html/table element

  };

  displayedColumns = ['staffName', 'trackingDate', 'createdTime','latitude','longitude'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('iframe') iframe: ElementRef;

  // date = new Date();
  // formatedDate = moment(this.date).format('YYYY-MM-DD');
  // activeStaffList:any[] = [] ;
  // activeStaffList_Filter:any[] = [] ;
  //activeStaffSeachInput:FormControl =  new FormControl();
  //protected _onDestroy = new Subject<void>();
  ctx_date:FormControl =  new FormControl(moment().toDate());
  ctx_staff:FormControl =  new FormControl();

  constructor(
    public dialog: MatDialog,
    public service: ReportsService,
    private translate: TranslateService,
    private exportAsService: ExportAsService,
    private _commonSVC : CommonService
  ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
  }
  
  ngOnInit(): void {
   // this.getAllReport(this.formatedDate);
   //this.getActiveStaffs();

  }

  // onDateChange(event) {
  //   this.page = 0;
  //   this.formatedDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
  //   this.getAllReport(this.formatedDate);
  // }

  // applyFilter(filterValue: string) {

  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   this.allReport = this.dataSource.filteredData;
  // }

  onScroll() {
    //this.page = this.page + 1;
   // this.getAllReport(this.formatedDate, 'scroll');

  }

  // export() {
  //   this.exportAsService.save(this.exportAsConfig, 'Check In & Out Report').subscribe(() => {
  //   });
  // }

   
  getAllReport() {
    
    let date = moment(this.ctx_date.value).format('YYYY-MM-DD');
    // let StaffCode = this.ctx_staff.value.staffCode
    let StaffCode = this.ctx_staff.value ;

    try {
      this.service.common.progressLoader = true;
      this.service.getAllUserTrackingByUserCodeAndDate(StaffCode, date, (data) => {
        this.service.common.progressLoader = false;
        if (data.responsecode === 200) {

          this.dataSource = new MatTableDataSource();
          this.dataSource = new MatTableDataSource(data.data);
          this.allReport = data.data;
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sort;
          
         
        } else {

          this.allReport = [];
          this.dataSource = new MatTableDataSource();
           
        }
      });
    } catch (error) {
      //console.log(error)
    }

  }


//   getActiveStaffs()
//   {
//     try {
//       // this.service.adminGetWorkerAndOrganizationFromWASActive(this.service.storage.get('orgCode'), (data) => {
//         this.service.getAllStaffMemberByOrgCode(this.service.storage.get('orgCode')).subscribe((res:any) => {
      
//           if(res.responsecode === 200)
//         {
         
//           // data.data.forEach(element => {
//           //   this.activeStaffList.push({ workerCode: element.workerRes.workerCode, name: element.workerRes.name });
//           // });

//           this.activeStaffList = res.data ;
//           this.activeStaffList_Filter = res.data ;
//           console.log("this.activeStaffLis", this.activeStaffList);

//           this.activeStaffSeachInput.valueChanges
//           .pipe(takeUntil(this._onDestroy))
//           .subscribe(() => {
//             this.filterActiveStaff();
//           });

//         }
//         else{
//           this.activeStaffList = [];
//           this.activeStaffList_Filter = [];
//         }
      
//     });
//     } catch (error) {
//       //console.log(error)
//     }
//   }

//  protected filterActiveStaff()
//   {
//     if (this.activeStaffList.length == 0) {
//       return;
//     }
//      let search = this.activeStaffSeachInput.value.trim();
//      this.activeStaffList_Filter = this.activeStaffList.filter(ele =>  ele.name.toLowerCase().includes(search.toLowerCase()));
//   }

  searchReport()
  {
    if(this.ctx_date.value && this.ctx_staff.value)
    {
         this.getAllReport();
    }

  }

  onStaffChanged(event:any)
  {
    this.ctx_staff.setValue(event.staffCode);
   }

  ngOnDestroy(): void {
    // this._onDestroy.next();
    // this._onDestroy.complete();
  }



}
