<!-- <div class="title">
    <h6 *ngIf="!data.data">Add Document</h6>
    <h6 *ngIf="data.data">Update Document</h6>
    <mat-icon (click)="onNoClick()" class="cursor-pointer close-icon">close</mat-icon>
  </div> -->
<div class="row">
  <div class="col-md-6 align-self-center">
    <h6 class="dialog-header pt-2" *ngIf="!data.data">Add Document</h6>
    <h6 class="dialog-header pt-2" *ngIf="data.data">Update Document</h6>
    <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
  </div>
</div>

<form [formGroup]="uploadForm" class="business_uploadDoc">
  <div class="row g-3">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always" class="example-full-width ">
          <mat-label>{{'Technicians.labels.Document Name' | translate }}</mat-label>
          <input matInput type="text" maxlength="100" class="form-control" formControlName="documentName"
            [(ngModel)]="service.uploadData.documentName">
        </mat-form-field>
        <span class="help-block" *ngIf="uploadForm && uploadForm.controls.documentName.errors?.required &&
          (!uploadSubmitted || uploadForm.controls.documentName.touched)">{{
          'Technicians.Validators.Document name is required' |
          translate }}.</span>

        <span class="help-block" *ngIf="uploadForm && uploadForm.get('documentName').errors?.maxlength &&
        (!uploadSubmitted || uploadForm.get('documentName').touched)">
          {{ 'registration.provider.validators.Document name maxlength is 100' | translate }}
        </span>

      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group datepick">
        <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
          <mat-label>{{ 'Technicians.labels.Expiry Date' | translate }}</mat-label>
          <input [min]="today" [(ngModel)]="service.uploadData.expiryDate" class="form-control" matInput
            formControlName="expiryDate" [matDatepicker]="picker2" (focus)="picker2.open()" placeholder="">
          <mat-datepicker-toggle matSuffix [for]="picker2" class="date-icon"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <span class="help-block"
          *ngIf="uploadForm && uploadForm.get('expiryDate').errors?.matDatepickerMin && ( !uploadSubmitted || uploadForm.get('expiryDate').touched)">
          {{ 'Jobs.Create Appointment.Validators.Date should be from todays date' | translate }}.</span>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always" class="example-full-width ">
          <mat-label>{{'report.labels.Notes' | translate }}</mat-label>
          <input matInput type="text" maxlength="500" class="form-control" formControlName="notes"
            [(ngModel)]="service.uploadData.note">
        </mat-form-field>
        <span class="help-block" *ngIf="uploadForm && uploadForm.get('notes').errors?.maxlength &&
        (!uploadSubmitted || uploadForm.get('notes').touched)">
          {{ 'registration.provider.validators.Notes maxlength is 500' | translate }}
        </span>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <div class="certificate-name">
          {{service.uploadData.uploadPath}}
        </div>
        <a class="btn-cert" mat-raised-button color="gray">{{'Technicians.labels.Browse' | translate }} <input
            formControlName="fileName" class="certificate-img" type="file" enctype="multipart/form-data"
            (change)="onCertificateUpload($event)" #fileInput></a>
        <div class="upload-loader pe-2" *ngIf="showLoader">
          <mat-spinner diameter="40" class="mt-2"></mat-spinner>
        </div>
      </div>



    </div>

    <div class="col-md-12 mt-5 text-end float-end">

      <div class="form-group ">
        <button mat-raised-button color="primary" class="primary-btn me-1" [disabled]="!uploadButton"
          (click)="uploadDoc()">{{'Technicians.labels.Upload' | translate }}
        </button>
        <!-- <button mat-flat-button class="primary-btn" (click)="cancelUpload()">{{'common.labels.Cancel' | translate }}
            </button> -->

      </div>
    </div>


  </div>

</form>