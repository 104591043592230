<div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="row mb-4">
        <div class="col-md-6 align-self-center">
          <h6 class="skill-test pt-2">{{popupTitle}}</h6>
        </div>
        <div class="col-md-6">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
          </div>
        </div>
      </div>  
      <form [formGroup]="licenceForm" (ngSubmit)="onSubmit()">
        <div class="form-group col-md-12 col-sm-12 p-0">
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Licence Name</mat-label>
            <input autoClose matInput formControlName="licenceName" type="text"
              placeholder="">
          </mat-form-field>
          <span class="help-block err"
            *ngIf="licenceForm.controls.licenceName.errors?.required && (!submitted || licenceForm.controls.licenceName.touched)">{{ 'licence.validators.Licence name is required' | translate }}</span>
        </div>

        <div class="w-100 col-md-12 col-sm-12 p-0">
          <div class="row  checkbox-lbl">
            <div class="col-lg-4">
             <mat-checkbox formControlName="issuedBy">Issued By</mat-checkbox>
            </div>
            <div class="col-lg-4">
              <mat-checkbox formControlName="numberValue">Number</mat-checkbox>
             </div>
             <div class="col-lg-4">
              <mat-checkbox id="expiration" formControlName="expiration">Expiration</mat-checkbox>
             </div>
         </div>
        </div>
              
      </form>
  
      <div class="d-flex offset-md-8 justify-content-end mt-4">
        <button type="submit" class="me-4 nowrapTxt" mat-raised-button color="primary" (click)="!isLoading && onSubmit()"> {{ popupTitle === 'Edit Licence' ? 'Update' : 'Save'}} <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>
      
        <button mat-button (click)="onNoClick()" class="cancel-btn-primary">{{ 'licence.labels.Cancel' | translate }}</button>
       </div>
    </div>
  </div>
  