
<span class="d-inlineFlex" [class.maxWidthHov]="lableTxt" [class.d-flex]="lableTxt" appPopover [appPopoverData]="appPopoverData" [placement]="'top'">
  <span>
      <img [src]="fileUrl + appPopoverData.userLogo" class="user-image" [class.headAvatar]="lableTxt" alt="User Image" onerror="this.src='assets/common_images/profile.jpg';">
 </span>
  <!-- <p *ngIf="lableTxt" class="lableTxt">
    <small>{{lableTxt}}</small><br>
  <span>{{appPopoverData.userName | capitalize}}</span>
  </p>

  <p *ngIf="!lableTxt" class="withoutLableTxt">
    <span>{{appPopoverData.userName | capitalize}}</span>
  </p> -->

  <p *ngIf="lableTxt" class="break-words">
    <small>{{lableTxt}}</small><br>
  <span>{{appPopoverData.userName | capitalize}}</span>
  </p>
  
  <p *ngIf="!lableTxt" class="break-words adjLineHeight">
    <span>{{appPopoverData.userName | capitalize}}</span>
  </p>

</span>