import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LicenceService } from '../licence.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-licence',
  templateUrl: './add-licence.component.html',
  styleUrls: ['./add-licence.component.scss']
})
export class AddLicenceComponent implements OnInit {

  licenceForm: FormGroup;
  options = [];
  licencedata;
  fileUrl;
  filetitle;
  imageupload;
  imageMsg;
  popupTitle: string = 'Add Licence';
  submitted = true;
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddLicenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public licenceService: LicenceService,private translate: TranslateService) {}

  ngOnInit() {
    this.licenceForm = new FormGroup({
      "organizationCode":new FormControl(null),
      "licenceName": new FormControl(null, [Validators.required]),
      "licenceCode":new FormControl(null),
      'issuedBy' :new FormControl(false),
      'numberValue' :new FormControl(false),
      'expiration' :new FormControl(false)
    });    

    /* Name:Shailesh
     use: for getting licence by id */

    try {
      // //console.log('ModData_:', this.data);
      if (this.data && this.data.licenceCode) {
            this.popupTitle = 'Edit Licence';
            this.licenceForm.controls['licenceName'].setValue(this.data.licenceName);
            this.licenceForm.controls['licenceCode'].setValue(this.data.licenceCode);
            this.licenceForm.controls['issuedBy'].setValue(this.data.issuedBy);
            this.licenceForm.controls['numberValue'].setValue(this.data.numberValue);
            this.licenceForm.controls['expiration'].setValue(this.data.expiration);
            
            this.licenceService.common.progressLoader = false;
          } else {
            this.popupTitle = 'Add Licence';
            this.licenceForm.reset();
            this.licenceService.common.progressLoader = false;
          }
      
    } catch (error) {
      //console.log(error)
    }  }


  /* Name:Shailesh
      use: for searching licence name */
  private _filter(value, field): string[] {
    //////console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        this.licenceForm.controls['licenceCode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        this.licenceForm.controls['licenceCode'].setValue(value.value);
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  displayFn(option): string | undefined {
    return option ? option.name : undefined;
  }

  /* Name:Vivek Chauhan
     use: for adding/creating/saving licenceForm in organization */
  onSubmit() {
    // //console.log('Form: ', this.licenceForm);
    try {
      if (this.licenceForm.valid) {
        this.licenceForm.controls['organizationCode'].setValue(this.licenceService.storage.get('orgCode'));
        // //console.log('AddLicenceReq: ', this.licenceForm.value);
        // return;
        this.isLoading = true;
        this.submitted = true;
        this.licenceService.saveAndUpdateLicenceMaster(this.licenceForm.value, (response) => {
          this.isLoading = false;
          if (response.responsecode == 200) {
            this.dialogRef.close('success');
            this.licenceForm.reset();
            this.licenceService.common.successMessage = true;
            this.licenceService.common.message = response.message;
          } else {
            this.submitted = false;
            this.licenceService.common.errorMessage = true;
            this.licenceService.common.message = response.message;
          }
        })
      } 
    } catch (error) {
      //console.log(error)
    }
    
    ////console.log(this.licenceForm);

  }

   /* Name:Shailesh
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.dialogRef.close('true');
  }
}

