import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepeatAppointmentComponent } from '../create-appointment/repeat-appointment/repeat-appointment.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { startWith, map } from 'rxjs/operators';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MapsAPILoader } from '@agm/core';
import { AssignedWorkerComponent } from '../create-appointment/assigned-worker/assigned-worker.component';
import { PatientDataComponent } from '../create-appointment/patient-data/patient-data.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { TranslateService } from '@ngx-translate/core';

const moment = _moment;
declare var google;
declare var window;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};


@Component({
  selector: 'app-update-appointment-series',
  templateUrl: './update-appointment-series.component.html',
  styleUrls: ['./update-appointment-series.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class UpdateAppointmentSeriesComponent implements OnInit {


  scheduleForm: FormGroup;
  options = { componentRestrictions: { country: "us" } };
  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;
  countrycodep = this.service.common.CountryCode;
  countrycodem = this.service.common.CountryCode;
  selectedStartTime = [];
  selectedEndTime = [];
  submitted = true;
  assignList = [];
  patientData = [];
  optionsPatient = [];
  filteredOptions: Observable<any>;
  patientDataByCode = [];
  procedureData = [];
  today;
  itip;
  itim;
  instancep;
  jobCode = '';
  durationSelectDropdown: any[];
  updatedAddress: any;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  allDurations: any[];
  selectedDuration: string;
  preferredCountryCode = this.service.common.CountryCode;
  cancelUpdate = false;

  constructor(private route: ActivatedRoute,
    public service: CreateAppointmentService, private dialog: MatDialog, private router: Router, private datepipe: DatePipe, private mapsAPILoader: MapsAPILoader, public translate: TranslateService, public dialogRef: MatDialogRef<UpdateAppointmentSeriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translate.setDefaultLang(this.service.session.get('language'));

    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });

  }


  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
  }


  ngOnInit() {

    /* Name:Shailesh
     use: for getting all procedures by organization code */
    try {
      this.service.getAllProcedureByOrganizationCode(this.service.session.get('orgCode'), (response) => {
        // ////console.log(response);
        if (response.responsecode) {
          this.procedureData = response.data;
          // let procedureData = [];
          response.data.forEach((element, index) => {
            element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
            element.itemName = element.procedureName;
            this.dropdownList.push({ id: element.organizationBaseProcedureCode, image: element.image, itemName: element.itemName, organizationBaseProcedureCode: element.organizationBaseProcedureCode, duration: element.duration })
          });
          // this.dropdownList = procedureData;
        }
      })
    } catch (error) {
      //console.log(error)
    }

    this.itip = document.querySelector('#phone');
    this.itim = document.querySelector('#mobile');

    this.durationSelectDropdown = this.service.common.durationHours;

    //this.route.queryParams.subscribe(params => {
    ////console.log(params.jobCode);
    //this.jobCode = params.jobCode;
    this.jobCode = this.data.jobCode;
    this.getLoadFormData();

    //});

     /* Name:Shailesh
      use: getting time slot by organization code */
    try {
      this.service.common.getTimeSlotByOrganisationCode(this.service.common.local.get('orgCode'), (response) => {
      this.selectedStartTime = response.data;
      this.selectedEndTime = response.data;
    });
    } catch (error) {
      //console.log(error)
    }
    
    this.getPatients();

    this.scheduleForm.valueChanges.subscribe((val) => {
      this.cancelUpdate = true;
    });
  }

  getLoadFormData() {
    this.getServiceEmpty();
    this.scheduleForm = new FormGroup({
      'address': new FormControl(null, Validators.required),
      'phoneCountryCode': new FormControl(this.countrycodep, Validators.required),
      'phoneNumber': new FormControl(null, Validators.required),
      'mobileCountryCode': new FormControl(this.countrycodem),
      'mobileNumber': new FormControl(null),
      'startDate': new FormControl(null, Validators.required),
      'endDate': new FormControl(null),
      'startTime': new FormControl(null, Validators.required),
      'duration': new FormControl(null, Validators.required),
      'procedureCode': new FormControl(null, Validators.required),
      'patientName': new FormControl(null, Validators.required),
      // 'jobName': new FormControl(null, Validators.required),
      'notes': new FormControl(null),
      'preferredCountryCode': new FormControl(this.preferredCountryCode, Validators.required),
      'preferredNumber': new FormControl(null, [Validators.required, Validators.pattern(this.service.common.phonenumber)]),
      'isPeferredNumber': new FormControl(null, Validators.required),
      'isDateFlexible': new FormControl(null)
    });

     /* Name:Shailesh
      use: for getting job series by job code */
    setTimeout(() => {
      try {
        this.service.getJobsSeriesByJobsCode(this.jobCode, (response) => {
        //console.log(response.data);
        if (response.responsecode == 200) {
          this.service.scheduleForm.jobName = response.data.jobName;
          //this.service.scheduleForm.patientCode =  response.data.patientCode;
          this.patientDataByCode = response.data.patientDetail;
          this.service.scheduleForm.patientCode = this.patientDataByCode['patientCode'];
          this.service.scheduleForm.homeCountryCode = this.patientDataByCode['phoneCountryCode'];
          this.countrycodep = this.patientDataByCode['phoneCountryCode'];
          this.countrycodem = this.patientDataByCode['mobileCountryCode'];
          this.service.scheduleForm.homeNumber = this.patientDataByCode['phonrNumber'];
          this.service.scheduleForm.mobileCountryCode = this.countrycodem;
          this.service.scheduleForm.mobileNumber = this.patientDataByCode['mobileNumber'];

          this.service.scheduleForm.additionalNumber = this.patientDataByCode['preferredNumber'];
          if (response.data.additionalCountryCode) {
            this.preferredCountryCode = this.patientDataByCode['preferredCountryCode'];
            this.service.scheduleForm.additionalCountryCode = this.preferredCountryCode;
            this.scheduleForm.controls['preferredCountryCode'].setValue(this.preferredCountryCode);
          }
          this.service.scheduleForm.preferredPhoneType = this.patientDataByCode['preferredPhoneType'];

          this.scheduleForm.get('patientName').setValue({ "patientName": this.patientDataByCode['patientName'], "patientCode": this.patientDataByCode['patientCode'] });
          // this.service.scheduleForm.organizationBaseProcedureCodeList = response.data.obpList;

          response.data.obpList.forEach((element, index) => {
            // this.selectedItems.push(element);
            // ////console.log(element);
            element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
            element.itemName = element.procedureName;
            element.id = element.organizationBaseProcedureCode
          });
          setTimeout(() => {
            this.selectedItems = response.data.obpList;
            // ////console.log(this.selectedItems, this.dropdownList)
          }, 200);

          this.scheduleForm.controls['procedureCode'].setValue(this.selectedItems);
          this.service.scheduleForm.notes = response.data.notes;
          this.service.scheduleForm.organizationCode = response.data.organizationCode;
          this.service.scheduleForm.startTime = response.data.startTime;
          let startDate = response.data.startDT;
          //console.log(response.data.startTime);
          const time = this.service.scheduleForm.startTime.split(':');
          let hours = +time[0];
          const month = startDate.split('-');
          //console.log(month);

          const newDate = new Date(+month[2], +month[0] - 1, +month[1], hours, +time[1], hours, +time[1]);
          //console.log(newDate, ' start');
          //console.log(moment(newDate));
          this.scheduleForm.controls['startDate'].setValue(moment(newDate));
          this.service.scheduleForm.startDate = +startDate.split('-')[1] + '/' + +startDate.split('-')[0] + '/' + +startDate.split('-')[2];
          this.today = moment(newDate);
          const endData = response.data.endDT;
          const endmonth = endData.split('-');
          const endnewDate = new Date(+endmonth[2], +endmonth[0] - 1, +endmonth[1]);
          this.scheduleForm.controls['endDate'].setValue(moment(endnewDate));
          this.service.scheduleForm.isDateFlexible = response.data.isDateFlexible;
          this.service.scheduleForm.endDate = this.datepipe.transform(endnewDate, 'yyyy-MM-dd');
          this.service.scheduleForm.durationHours = response.data.durationHours;
          this.service.scheduleForm.durationMinute = response.data.durationMinute;
          this.scheduleForm.controls['duration'].setValue(response.data.durationHours + ':' + response.data.durationMinute + ':00');
          this.selectedDuration = response.data.durationHours + ':' + response.data.durationMinute + ':00';
          this.service.scheduleForm.daysList = [];
          if (response.data.daysList) {
            const days = response.data.daysList.split(',');
            if (days.length > 0) {
              days.forEach(day => {
                this.service.scheduleForm.daysList.push(+day);
              });
            }
          }

          this.service.scheduleForm.repeatType = response.data.repeatType;
          this.service.scheduleForm.repeatValue = response.data.repeatValue;
          this.service.scheduleForm.isDateOfMonth = response.data.isDateOfMonth;
          setTimeout(() => {
            this.cancelUpdate = false;
          }, 1000)
        }
      });
      } catch (error) {
        //console.log(error)
      }
      
    }, 500);

    this.filteredOptions = this.scheduleForm.controls['patientName'].valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      );
  }


  codeLatLng(response, callback) {
    // ////console.log(response)
    let geocoder = new google.maps.Geocoder();
    // let newLatLang = new google.maps.LatLng(response.data.patientLatitude, response.data.patientLongitude);

    // geocoder.geocode({ 'latLng': newLatLang }, function (results, status) {
    geocoder.geocode({ 'address': response.data.patientApartmentNo + ',' + response.data.patientAddress + ',' + response.data.patientCity + ',' + response.data.patientState + ',' + response.data.zipCode + ',' + response.data.patientCountry }, function (results, status) {
      ////console.log(results, status);
      callback(results);
    });
  }


  private _filter(name): String[] {
    ////console.log(name);
    ////console.log(this.optionsPatient);
    if (name && name.patientCode != undefined) {
      this.getPatientsDetail(name.patientCode);
    }


    if (name) {
      const filterValue = name;
      return this.optionsPatient.filter(option => option.patientName.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return this.optionsPatient;
    }


  }

  displayFn(optionsPatient): string | undefined {
    //////console.log(optionsPatient);
    return optionsPatient ? optionsPatient.patientName : '';
  }

  selectedPatient(event) {
    //////console.log(event.option.value);
    this.getPatientsDetail(event.option.value.patientCode);

  }

   /* Name:Shailesh
      use: to get patient detail */
  getPatientsDetail(code) {
    try {
      this.service.getPatientDetailByCode(code, (data) => {

      this.codeLatLng(data, (addr) => {
        // alert(addr);
        ////console.log(addr, addr[0].formatted_address);
        this.updatedAddress = addr[0].formatted_address;
        // this.scheduleForm.controls['address'].setValue(this.updatedAddress);
        this.scheduleForm.get('address').setValue(this.updatedAddress);
      });

      //////console.log(data.data);
      this.patientDataByCode = data.data;
      if (this.patientDataByCode['phoneCountryCode']) {
        this.service.scheduleForm.homeCountryCode = this.patientDataByCode['phoneCountryCode'];
        this.countrycodep = this.patientDataByCode['phoneCountryCode'];
        this.scheduleForm.controls['phoneCountryCode'].setValue(this.countrycodep);
      }
      if (this.patientDataByCode['mobileCountryCode']) {
        this.countrycodem = this.patientDataByCode['mobileCountryCode'];
        this.service.scheduleForm.mobileCountryCode = this.patientDataByCode['mobileCountryCode'];
        this.scheduleForm.controls['mobileCountryCode'].setValue(this.countrycodem);
      }
      this.service.scheduleForm.patientCode = code;
      this.service.scheduleForm.homeNumber = this.patientDataByCode['phoneNumber'];
      this.service.scheduleForm.mobileNumber = this.patientDataByCode['mobileNumber'];
      this.service.scheduleForm.additionalNumber = this.patientDataByCode['preferredNumber'];
      if (this.patientDataByCode['preferredCountryCode']) {
        this.preferredCountryCode = this.patientDataByCode['preferredCountryCode'];
        this.service.scheduleForm.additionalCountryCode = this.patientDataByCode['preferredCountryCode'];
        this.scheduleForm.controls['preferredCountryCode'].setValue(this.countrycodem);
      }
      //////console.log(this.patientDataByCode['patientLatitude']);
      //////console.log(this.service.scheduleForm.patientDetail.mobileCountryCode);
      //////console.log(this.service.scheduleForm.patientDetail.phoneCountryCode);
      // this.scheduleForm.get('address').setValue(this.patientDataByCode['patientAddress']);
      this.service.scheduleForm.preferredPhoneType = this.patientDataByCode['preferredPhoneType'];
      this.service.scheduleForm.patientDetail.patientAddress = this.patientDataByCode['patientAddress'];
      this.service.scheduleForm.patientDetail.patientCity = this.patientDataByCode['patientCity'];
      this.service.scheduleForm.patientDetail.patientCountry = this.patientDataByCode['patientCountry'];
      this.service.scheduleForm.patientDetail.patientLatitude = this.patientDataByCode['patientLatitude'];
      this.service.scheduleForm.patientDetail.patientLongitude = this.patientDataByCode['patientLongitude'];
      this.service.scheduleForm.patientDetail.patientState = this.patientDataByCode['patientState'];
      this.service.scheduleForm.patientDetail.zipCode = this.patientDataByCode['zipCode'];
      this.service.scheduleForm.patientDetail.patientApartmentNo = this.patientDataByCode['patientApartmentNo'];
    })
    } catch (error) {
      //console.log(error);
    }
    
  }

  getNumber(event, countryCode) {
    this.scheduleForm.controls[countryCode].setValue(event.dialCode);
    this.service.scheduleForm.patientDetail[countryCode] = event.dialCode;
  }

  searchStart(query) {
    // //////console.log('query', query);
    let result = this.selectStart(query)
    this.selectedStartTime = result;
  }

  selectStart(query) {
    //////console.log(query);
    let result = [];
    for (let a of this.service.common.timeIntervals) {
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }


  searchEnd(query) {
    // //////console.log('query', query);
    let result = this.selectStart(query)
    this.selectedEndTime = result;
  }


  handleAddressChange(address) {
    //////console.log(address);
    //////console.log(address.address_components);
    this.updateServiceAddress(address);
  }

   /* Name:Shailesh
      use: to to get current location of patient */
  getCurrentLocation(lat, lng) {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = { lat: lat, lng: lng };
      let that = this;
      geocoder.geocode({ 'location': latlng }, function (results) {
        //////console.log(results);
        if (results[0]) {
          that.updateServiceAddress(results[0]);
          ////////console.log(that.currentLocation);
        } else {
          //////console.log('No results found');
        }
      });
    });
  }

   /* Name:Shailesh
      use: to reset patient detail */
  updateServiceAddress(address) {

    this.service.scheduleForm.patientDetail.patientCity = '';
    this.service.scheduleForm.patientDetail.patientCity = "",
      this.service.scheduleForm.patientDetail.patientCountry = "",
      this.service.scheduleForm.patientDetail.patientLatitude = "",
      this.service.scheduleForm.patientDetail.patientLongitude = "",
      this.service.scheduleForm.patientDetail.patientState = "",
      this.service.scheduleForm.patientDetail.zipCode = ""
    this.service.scheduleForm.patientDetail.patientApartmentNo = ""

    let places: any;
    const componentForm = {
      route: 'long_name',
      locality: 'long_name',
      political: 'long_name',
      administrative_area_level_1: 'long_name',
      postal_code: 'short_name',
      country: 'long_name'
    };

    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (componentForm[addressType]) {
        if (addressType === 'route') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientAddress = val;
        }
        if (addressType === 'street_number') {
          const val = address.address_components[i]['long_name'];
          this.service.scheduleForm.patientDetail.patientApartmentNo = val;
        }
        if (addressType === 'political') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientAddress = val;
        }
        if (addressType === 'postal_code') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.zipCode = val;
        }
        if (addressType === 'locality') {
          const val = address.address_components[i][componentForm[addressType]];
          //////console.log(val);
          this.service.scheduleForm.patientDetail.patientCity = val;
        }
        if (addressType === 'administrative_area_level_1') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientState = val;
        }
        if (addressType === 'country') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientCountry = val;
        }
      }
    }

    this.service.scheduleForm.patientDetail.patientLatitude = address.geometry.location.lat();
    this.service.scheduleForm.patientDetail.patientLongitude = address.geometry.location.lng();
    //////console.log(this.service.scheduleForm);
    // this.showMapmarker(this.service.workerHome.latitude ,this.service.workerHome.longitude);
    this.scheduleForm.controls['address'].setValue(address.formatted_address);
    this.service.scheduleForm.patientDetail.patientAddress = address.formatted_address;
  }

   /* Name:Shailesh
      use: to get patient detail*/
  getPatients() {
    this.service.common.progressLoader = true;
    try {
       this.service.getPaitents(this.service.common.local.get('orgCode'), (data) => {
      if (data.responsecode === 200) {
        this.patientData = data.data;
        this.patientData.forEach(element => {
          this.optionsPatient.push({ "patientName": element.patientName, "patientCode": element.patientCode });
        });
        //////console.log(this.optionsPatient);
        this.service.common.progressLoader = false;
      } else {
        this.service.common.progressLoader = false;
      }
    });
    } catch (error) {
      //console.log(error)
    }
   
  }

  getServiceEmpty() {
    this.service.scheduleForm = {
      "daysList": [
        0
      ],
      "customerAddressCode": "string",
      "customerCode": "string",
      "durationHours": 0,
      "durationMinute": 0,
      "endDate": "",
      "isDateOfMonth": true,
      "jobName": "",
      "notes": "",
      "organizationCode": "",
      "patientCode": "",
      "patientDetail": {
        "isActive": true,
        "mobileCountryCode": "",
        "mobileNumber": "",
        "organizationCode": "",
        "patientAddress": "",
        "patientCity": "",
        "patientCode": "",
        "patientCountry": "",
        "patientEmail": "",
        "patientLatitude": "",
        "patientLongitude": "",
        "patientName": "",
        "patientState": "",
        "phoneCountryCode": "",
        "phoneNumber": "",
        "zipCode": "",
        "patientApartmentNo": "",
        "preferredCountryCode": "",
        "preferredNumber": "",
        "preferredPhoneType": ""
      },
      "organizationBaseProcedureCodeList": [],
      "repeatType": "",
      "repeatValue": 0,
      "startDate": "",
      "startTime": "",
      "additionalCountryCode": "",
      "additionalNumber": "",
      'preferredPhoneType': '',
      "mobileCountryCode": "",
      "mobileNumber": "",
      "homeCountryCode": "",
      "homeNumber": "",
      "isDateFlexible": false
    }
  }

   /* Name:Shailesh
      use: to update appointment in series*/
  onSubmit() {
    //////console.log(this.scheduleForm);
    //////console.log(this.patientDataByCode);
    // //////console.log(this.service.scheduleForm);
    if (this.scheduleForm.valid && this.jobCode) {
      this.service.common.progressLoader = true;
      this.submitted = true;
      this.scheduleForm.value.startDate = this.datepipe.transform(this.scheduleForm.value.startDate, 'yyyy-MM-dd');
      this.scheduleForm.value.endDate = this.datepipe.transform(this.scheduleForm.value.endDate, 'yyyy-MM-dd');
      // this.service.scheduleForm.endDate = this.scheduleForm.value.endDate;
      // } else {
      if (this.service.scheduleForm.endDate == '') {
        this.service.scheduleForm.endDate = this.scheduleForm.value.startDate;
        this.service.scheduleForm.daysList = null;
        this.service.scheduleForm.repeatType = 'day';
        this.service.scheduleForm.repeatValue = 1;
        this.service.scheduleForm.isDateOfMonth = false;
      }

      if (this.service.scheduleForm.isDateOfMonth == null) {
        this.service.scheduleForm.isDateOfMonth = false;

      }
      this.service.scheduleForm.startDate = this.scheduleForm.value.startDate;

      this.service.scheduleForm.organizationCode = this.service.common.local.get('orgCode');

      if (this.patientDataByCode.length > 0 || this.patientDataByCode['patientName']) {
        //////console.log(this.patientDataByCode['patientName']);
        this.service.scheduleForm.patientDetail.patientName = this.patientDataByCode['patientName'];
      } else {
        //////console.log(this.scheduleForm.value.patientName);
        this.service.scheduleForm.patientDetail.patientName = this.scheduleForm.value.patientName;
      }
      this.service.scheduleForm.patientDetail.organizationCode = this.service.common.local.get('orgCode');
      this.service.scheduleForm.patientDetail.patientLongitude = this.service.scheduleForm.patientDetail.patientLongitude.toString();
      this.service.scheduleForm.patientDetail.patientLatitude = this.service.scheduleForm.patientDetail.patientLatitude.toString();
      this.service.scheduleForm.durationHours = this.scheduleForm.value.duration.split(':')[0];
      this.service.scheduleForm.durationMinute = this.scheduleForm.value.duration.split(':')[1];
      this.service.scheduleForm.organizationBaseProcedureCodeList = [];
      this.selectedItems.forEach(element => {
        this.service.scheduleForm.organizationBaseProcedureCodeList.push(element.organizationBaseProcedureCode);
      });

      try {
         this.service.updateWorkerJob(this.jobCode, this.service.scheduleForm, (res) => {
        //////console.log(res);
        if (res.responsecode == 200) {
          if (res.manualAssignment == false) {
            let workers = [];
            // res.data.eligibleWorkerResList.forEach(element => {

            //   if (element.workerWithDistanceList.length > 0) {
            //     element.workerWithDistanceList.forEach(elementData => {
            //       if (elementData.worker) {
            //         elementData.worker.jobsCode = element.jobsCode
            //         workers.push(elementData.worker);
            //       }
            //     });
            //   }
            // });
            workers = res.data.eligibleWorkerResList;
            setTimeout(() => {
              this.service.common.progressLoader = false;
              //////console.log(workers);
              if (workers.length > 0) {
                //////console.log(workers);
                this.openDialog(workers);
              } else {
                this.service.common.message = 'Appointment created successfully but not worker was associated with the Appointment.';
                this.service.common.errorMessage = true;
                //this.router.navigate(['/dashboard/appointment']);
                this.dialogRef.close('true');
              }
            }, 200);
          } else {
            this.service.common.message = res.message;
            this.service.common.successMessage = true;
            this.service.common.progressLoader = false;
            //this.router.navigate(['/dashboard/appointment']);
            this.dialogRef.close('true');
          }
        } else {
          this.service.common.progressLoader = false;
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })
      } catch (error) {
        //console.log(error)
      }
     
    } else {
      this.submitted = false;
    }
  }

   /* Name:Shailesh
      use: to open assign clinician to appointment popup */
  openDialog(workers): void {
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      width: '350px',
      data: { data: workers }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
    });
  }

   /* Name:Shailesh
      use: to open popup for repeat appointments */
  repeat() {


    const dialogRef = this.dialog.open(RepeatAppointmentComponent, {
      width: '350px',
      data: { startDate: this.scheduleForm.controls['startDate'].value, dataDetails: this.service.scheduleForm, endDate: this.scheduleForm.controls['endDate'].value }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        if (result.days == null) {
          this.service.scheduleForm.daysList.push(0);
        } else {
          this.service.scheduleForm.daysList = result.days;
        }
        this.service.scheduleForm.repeatType = result.repeatevery;
        this.service.scheduleForm.repeatValue = result.repeatnum;
        this.scheduleForm.controls['startDate'].setValue(moment(result.startDate));
        this.service.scheduleForm.startDate = this.datepipe.transform(result.startDate, 'yyyy-MM-dd');
        this.service.scheduleForm.endDate = this.datepipe.transform(result.endDate, 'yyyy-MM-dd');
        this.service.scheduleForm.isDateOfMonth = result.isDateOfMonth;
      }
    });
  }

   /* Name:Shailesh
      use: to open popup for adding patient */
  addPatient(): void {
    const dialogRef = this.dialog.open(PatientDataComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      this.getPatients();
      //////console.log(result);
      if (result) {
        const data = {
          patientCode: result.data.patientCode,
          patientName: result.data.patientName
        };
        this.scheduleForm.controls['patientName'].setValue(data);
      }
    });
  }

  getCountryCode(event, formControlName) {
    //////console.log(event, formControlName);
    this.scheduleForm.controls[formControlName].setValue(event);
    this.service.scheduleForm.patientDetail[formControlName] = event;
  }
  getProcedureData(event) {
    //////console.log(event.value);
    const index = this.procedureData.findIndex(data => data.organizationBaseProcedureCode == event.value);
    //////console.log(index, this.procedureData[index]);
    const duration = this.procedureData[index].duration;
    if (duration) {
      this.scheduleForm.controls['duration'].setValue(duration);
      this.scheduleForm.controls['notes'].setErrors(null);
    } else {
      this.scheduleForm.controls['duration'].setValue(null);
    }

  }



  onItemSelect(item: any) {
    ////console.log(item, this.selectedItems);
    // if (item.duration) {
    this.getDuration();
    // }

  }

  /* Name:Shailesh
      use: to get duration of appointment */
  getDuration() {
    this.allDurations = [];
    if (this.selectedItems.length > 0) {
      this.selectedItems.forEach(element => {
        this.allDurations.push(element.duration);
        const totalDurations = this.allDurations.slice(1)
          .reduce((prev, cur) => moment.duration(cur).add(prev),
            moment.duration(this.allDurations[0]));
        let duration = moment.utc(totalDurations.asMilliseconds()).format("HH:mm:ss");
        this.scheduleForm.controls['duration'].setValue(duration);
        this.scheduleForm.controls['notes'].setErrors(null);
        this.selectedDuration = duration;
      });
    } else {
      this.scheduleForm.controls['duration'].setValue(null);
      this.selectedDuration = '';
    }
  }

  getDurationData(event) {
    //////console.log(event);
    if (event.value !== this.selectedDuration) {
      this.scheduleForm.controls['notes'].setErrors({ 'incorrect': true });
    } else {
      this.scheduleForm.controls['notes'].setErrors(null);
    }

  }

  canelPopup() {
    if (this.cancelUpdate) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'This page is asking you to confirm that you want to reset – If any data you have entered may not be saved?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          // this.getForm();
          //this.getLoadFormData();
          this.onNoClick();
        }
      });
    } else {
      this.onNoClick();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
