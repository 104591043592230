<div class="col-md-12">
  <div class="row">
  <div class="col-md-5 col-sm-12 primary-color ">
    <div class="calendar-nav col-md-12 px-0 pb-4 mb-3 cal ">
      <div class="col-md-8 pe-2 dt-yr  p-0 fw-bold pointer" (click)="getStatus('all')"
        [title]="navDate.format('MMMM YYYY')">
        <i class="fa fa-calendar ps-1 pe-3 cal-color" aria-hidden="true"></i>
        {{navDate.format('MMMM YYYY')}}
      </div>
      <div class="col-md-4  p-0 text-end">
        <div class="calendar-nav-previous-month  dt-yr ">
          <span class="resp-arrow">
            <span title="Previous Month"><i class="material-icons pe-2 pointer"
                (click)="changeNavMonth(-1)">keyboard_arrow_left</i></span>
            <span title="Next Month"><i class="material-icons pointer"
                (click)="changeNavMonth(1)">keyboard_arrow_right</i></span>
          </span>
        </div>
      </div>
    </div>
    <div class="calendar col-12 col-md-12 col-sm-12 w-100">
      <div class="calendar-container  w-100">
        <div class="calendar-header  w-100">
          <div class="calendar-date" *ngFor="let day of weekDaysHeaderArr">
            {{day}}
          </div>
        </div>
        <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
        <div class="calendar-body">
          <div *ngFor="let day of gridArrNew" [ngClass]="day.currentDate == selectedDate?'eventClass':'inactive'"
            class="calendar-date {{day.highlight}} pointer" [class.is-disabled]="!day.available"
            (click)="selectDay(day)">
            <button *ngIf="day.value !== 0" class="date-item">{{ day.value}} <span></span></button>
          </div>
        </div>
        <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
      </div>
    </div>
  </div>
  <div class="col-md-7 col-sm-12">
    <div class="">
      <div class="timelist">
        <h4 class="primary-color dt-yr fw-bold">
            {{ 'common.labels.Eligible Appointment' | translate }} 
        </h4>
        <form [formGroup]="serachFilter" (ngSubmit)="onSearch()" *ngIf="!resetForm">
          <div class="row">
            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width  p-0 ">
                <mat-label>{{'common.labels.Search by location' | translate}}</mat-label>
                <input placeholder="" matInput formControlName="address"
                  ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)">
              </mat-form-field>
              <mat-error class="err"
              *ngIf="serachFilter.controls.address.errors?.required && (!submitted || serachFilter.controls.address.touched)">
              {{ 'Technicians.Validators.Location is required' | translate }}</mat-error>
            <mat-error class="err"
              *ngIf="serachFilter.controls.address.errors?.invalid && (!submitted || serachFilter.controls.address.touched)">
              {{ 'Technicians.Validators.Enter valid Location' | translate }}</mat-error>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                <mat-label>{{'common.labels.Up to miles' | translate}}</mat-label>
                <input matInput placeholder="" type="number" formControlName="miles">
              </mat-form-field>
              <mat-error class="err"
              *ngIf="serachFilter.controls.miles.errors?.required && (!submitted || serachFilter.controls.miles.touched)">
              {{ 'Technicians.Validators.Miles is required' | translate }}</mat-error>
            </div>
            <div class="col-md-2">
              <button class="me-1" title="{{'common.labels.Search' | translate}}"><mat-icon>search</mat-icon></button>
              <mat-icon class="cursor-pointer" title="{{'Customers.labels.Reset' | translate}}" (click)="resetFilter()">cached</mat-icon>
            </div>
          </div>
        </form>
        <div class="popup-viewport-body" *ngIf="eligibleData.length > 0" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
          <div class="example-item" *ngFor="let data of eligibleData; let j=index;">

            <span class="app-date ms-2 mt-3 mb-2" *ngIf="data.key">{{data.key}} :</span>
            <mat-card class=" mx-auto card-appointment mt-4 mb-4" *ngFor="let newData of data.eventData; let j=index;">
              <div class="row icon-round">
                <div class="col-md-2">

                  <small>{{ 'common.labels.Start Time' | translate }}</small>
                  <p>{{newData.startTime.split(' ')[0]}} {{newData.startTime.split(' ')[1]}}</p>
                </div>
                <div class="col-md-2">

                  <small>{{ 'common.labels.End Time' | translate }}</small>
                  <p>{{newData.endTime.split(' ')[0]}} {{newData.endTime.split(' ')[1]}}</p>
                </div>
                <div class="col-md-3">
                  <small>{{ 'dashboard.labels.Patient Name' | translate }}</small>
                  <p class=" text-capitalize">{{newData.customerName}}</p>
                </div>
                <div class="col-md-5">
                  <small>{{ 'dashboard.labels.Patient Address' | translate }}</small>
                  <p *ngIf="newData.customerAddress">
                     {{newData.customerAddress}}
                  </p>
                  <p *ngIf="!newData.customerAddress">
                      {{ 'dashboard.labels.Not Available' | translate }}
                  </p>
                </div>
              </div>
              <hr class="p-0">
              <div class="row">

                <div class="d-flex col-md-5 pe-0" >
                  <ng-container *ngIf="loggedUserType !== 'Customer'">
                  <button class="accept-btn me-3" mat-raised-button (click)="appointmentStatus('accept', newData.appointmentsDurationCode)">{{ 'dashboard.labels.Accept' | translate }}</button>
                  <button class="reject-btn" mat-raised-button (click)="appointmentStatus('reject', newData.appointmentsDurationCode)">{{ 'dashboard.labels.Reject' | translate }}</button>
                </ng-container>
                </div>

                
                <div class="col-md-2 text-center">
                  <span *ngIf="newData.notes || newData.patientRecurringNotes || newData.comments">
                      <img class="cursor-pointer mt-2" src="/assets/notes_icon.png" title="Notes" (click)="getNotes(newData)">
                  </span>
                  <span *ngIf="!newData.notes && !newData.patientRecurringNotes && !newData.comments"></span>
                </div>
                

                <div class="col-md-5 dis-img text-end pe-0">
               
                  <div class=" me-0 pe-0 " >
                    <div class=" ms-1 mb-2 cursor-pointer"  *ngFor="let procImg of newData.organizationBasedProcedureDetailsList; index as i">
                      <img (click)="getProcedureDetails(procImg,data.notes)" class="img-icon" alt="No Image" *ngIf="i <= 3 && procImg.procedureIcon" title="{{procImg.procedureName}}"
                        [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                      <img alt="No Image" *ngIf="!procImg.procedureIcon"
                        src="../../../../assets/common_images/profile.jpg" />
                    </div>
                  </div>
                  <div class="ps-0 img-data" *ngIf="newData.organizationBasedProcedureDetailsList.length > 4" (click)="showImage(newData)" >
                    <div class="img-count cursor-pointer">
                        {{newData.organizationBasedProcedureDetailsList.length - 4}}
                    </div>
                            
                  </div>
                
      
                  <div class="img-open " *ngIf="newData.appointmentsDurationCode == showImg"> 
                          <div class="img-icon me-0 mt-1" >
                            <div *ngFor="let procImg of newData.organizationBasedProcedureDetailsList; index as i">
                                <img (click)="getProcedureDetails(procImg,data.notes)" alt="No Image" class="me-2" *ngIf="i >= 4 && procImg.procedureIcon " title="{{procImg.procedureName}}"
                                [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                            </div>
                          </div>
                  </div>
              </div>

              </div>
            </mat-card>
          </div>
        </div>
        <div *ngIf="eligibleData.length == 0" class="mt-4">
            {{ 'dashboard.labels.No appointments are present' | translate }}.
        </div>
      </div>
    </div>
  </div>
  <!-- <ngx-float-button (click)="filterTech()" icon="filter_list" title="Filter" class="filter-appointment">
  </ngx-float-button> -->

  <i class="material-icons filter-icon elegibleAppoinment" (click)="filterTech()" title="Filter">
    filter_list </i>
</div>
</div>