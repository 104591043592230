import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, takeUntil } from 'rxjs';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
 

@Component({
  selector: 'staff-list-select',
  templateUrl: './staff-list-select.component.html',
  styleUrls: ['./staff-list-select.component.scss']
})
export class StaffListSelectComponent implements  OnInit, OnDestroy {

  @Input() selected_staff_code : string = "" ; // use this code to show selected staff in dropdown
  @Input() disabled : boolean = false ;

   @Output() selectionChanged  = new EventEmitter() ;

/*
 * @Input() staffList:any[] = []; 
 * using this variable as staff list array list ,
 * To avoid multiple api calling when using this component for form array or other iteration part
 */
  @Input() staffList:any[] = []; 


  ctrl_staff : FormControl = new FormControl(); ;
 
  staffSeachInput: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();

   staffList_Filter:any[] =[];

   constructor(
    private common_api_service : CommonApiService,
    private session_service : SessionStorageService
   ){  }


  // ngOnChanges(changes: SimpleChanges): void {
    
  //   if(this.selected_staff_code)
  //   {
  //     this.bindDropDown();
  //   }

  // }


  ngOnInit(): void {

    if(this.staffList.length > 0)
    {
      this.staffList_Filter = [...this.staffList] ;
       this.bindDropDown();
       this.formValueChangedSubs();
     }
    else
    {
      this.getAllStaffMemberByOrgCode();
    }

    if(this.disabled)
    {
    this.ctrl_staff.disable();
    }
     
  }


  bindDropDown()
  {
    if(this.selected_staff_code && this.staffList.length >0 && this.staffList_Filter.length > 0)
      {
        let selected_value = this.staffList_Filter.find((ele:any)=> ele.staffCode === this.selected_staff_code);

        if(selected_value)
          this.ctrl_staff.setValue(selected_value) ;

      }

  }

   getAllStaffMemberByOrgCode()
   {
     this.common_api_service.get_all_staff_by_org_code(this.session_service.get('orgCode')).subscribe((response:any) =>{
         
           if (response.responsecode == 200) 
             {
              this.staffList = response.data;
              this.staffList_Filter = response.data;
              this.bindDropDown();
              this.formValueChangedSubs();
             } 
       });
   }


   formValueChangedSubs()
   {
     this.staffSeachInput.valueChanges
     .pipe(takeUntil(this._onDestroy))
     .subscribe(() => {
       this.filterCustomer();
     });
   }
 
   private filterCustomer() {
     if (this.staffList.length == 0) {
       return;
     }
     let search = this.staffSeachInput.value.trim();
 
     this.staffList_Filter = this.staffList.filter((ele) =>
       ele.name.toLowerCase().includes(search.toLowerCase())
     );
   }

   onSelectionChanged(event:any)
   {
    this.selectionChanged.emit(this.ctrl_staff.value)
   }

  ngOnDestroy(): void {
   this._onDestroy.next();
   this._onDestroy.complete();
  }


}
