<div class="d-flex flex-row justify-content-between w-100 mb-2 logo align-items-center">
  <div class="float-start header-name">Notes</div>
  <div class="col-lg-4 col-md-4 col-sm-12 searchfilter text-end">
      <input type="text" class="form-control w-100" autocomplete="off" placeholder="Search" name="search-patient" [(ngModel)]="searchTerm" (keyup)="applyFilter($event.target.value)" />
  </div>
</div>
<div class="w-100 boxPadd">
  <div class="col-md-12">
      <div class="row boxBorder">
          <div class="col-md-3 borderRight boxHeight">
              <div class="row pb-2">
                  <div class="mb-2 d_content">
                      <mat-progress-bar mode="indeterminate" *ngIf="notesService.common.infiniteLoader"></mat-progress-bar>
                  </div>
                  <div *ngIf="listData?.length > 0" class="table-scroll-height w-100 ps-0 pe-0" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
                      <ng-container *ngFor="let group of listData; let gIdx = index;">
                          <div class="w-100 text-start groupTitle">{{group.groupBy}}</div>
                          <ng-container *ngFor="let note of group.groupList; let i = index;">
                              <div title="{{note?.entityName}}" [class.selectedNote]="note.isSelected" class="w-100 noteBoxPadd cursor-pointer borderBottom" (click)="getNoteDetail(note, gIdx, i)">
                                
                                <div class="d-flex gap-2 justify-content-between entity">

                                    <span class="ps-0">
                                        {{note?.entityName === 'JobType'
                                         ? 'Job Type' : note?.entityName === 'Staff' 
                                         ? 'Staff' : note?.entityName === 'Customer' 
                                         ? 'Customer' : note?.entityName === 'Location' 
                                         ? 'Job Site' : note?.entityName === 'Shift' 
                                         ? 'Shift' : note?.entityName === 'DistributionList' 
                                         ? 'Distribution List' : note?.entityName === 'Invoice' 
                                         ? 'Invoice': note?.entityName === 'JobProcedures' 
                                         ? 'Job/Shift' : note?.entityName === 'Timecard' 
                                         ? 'Time Card' : ''}}
                                         </span>

                                         <span>
                                            {{note.noteFor}}
                                         </span>

                                  </div>

                                <div class="d-flex">
                                <div class="w-75 nowrapTxt text-truncate noteTitleBox" [title]="note.title">
                                      <b class="font-bold">{{note.title | capitalize}}</b>
                                  </div>

                                  <div class="w-25 nowrapTxt text-truncate text-end float-end pe-1 timeStamp">
                                    <span title="{{note?.updatedAt}}">{{note?.updatedAtTime ? note.updatedAtTime : '---'}}</span>
                                </div>
                                </div>
                                  <!-- <div class="w-100 nowrapTxt text-truncate bodyTxt">
                                      {{note.body}}
                                  </div> -->
 

                                  <!-- <div class="w-100 nowrapTxt text-truncate text-end float-end pe-1 timeStamp">
                                      <span title="{{note?.updatedAt}}">{{note?.updatedAtTime ? note.updatedAtTime : '---'}}</span>
                                  </div> -->
                              </div>
                          </ng-container>
                      </ng-container>
                  </div>
                  <div *ngIf="!notesService.common.infiniteLoader && !notesService.common.progressLoader && listData.length === 0" class="p-3">Note list are not available</div>
              </div>
          </div>
          <div class="col-md-9">
              <div class="row pb-2">
                  <div class="mb-2 d_content">
                      <mat-progress-bar mode="indeterminate" *ngIf="isNoteDetailLoading"></mat-progress-bar>
                  </div>
                  <div class="table-scroll-height w-100" *ngIf="noteDetail">
                      <div class="w-100" *ngIf="noteDetail?.entityName === 'JobProcedures'; else commonTemplate">
                          <div class="w-100 text-start groupTitle ps-0">Job/Shift Notes</div>
                          <ng-container *ngTemplateOutlet="jobDetailCard"></ng-container>                          
                      </div>
                      <ng-template #commonTemplate>
                      <div class="w-100">

                             <div class="w-100 text-start groupTitle ps-0">
                                {{noteDetail?.entityName === 'JobType'
                                 ? 'Job Type' : noteDetail?.entityName === 'Staff' 
                                 ? 'Staff' : noteDetail?.entityName === 'Customer' 
                                 ? 'Customer' : noteDetail?.entityName === 'Location' 
                                 ? 'Job Site' : noteDetail?.entityName === 'Shift' 
                                 ? 'Shift' : noteDetail?.entityName === 'DistributionList' 
                                 ? 'Distribution List' : noteDetail?.entityName === 'Invoice' 
                                 ? 'Invoice' : noteDetail?.entityName === 'Timecard' 
                                 ? 'Time Card' : ''}} Notes
                                 </div>

                          <ng-container *ngTemplateOutlet="detailContent"></ng-container> 
                      </div>
                    </ng-template>
                      <!-- threaded-note-list Component Use for show note list in Treaded view -->
                      <div class="w-100 ps-4">
                        <threaded-note-list [noteArray]="noteDetail.noteArray" (noteChangeEvent)="noteChangeEvent($event)"></threaded-note-list>
                      </div>                      
                      <div *ngIf="!isNoteDetailLoading && !noteDetail" class="p-3">Note data are not available</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #jobDetailCard>
    <div class="ps-3">
    <div *ngIf="noteDetail" class="mx-auto card-box mt-3 mb-2 roundedBorder">
      <div class="Card_JobDetails">
          <div class="row job-content container mb-0">
              <div class="st-jobTypeDts-main-wrapper">
                  <div class="row">
                      <div class="col-1" *ngIf="noteDetail?.week_day && noteDetail?.month_day && noteDetail?.month_name">
                          <div class="row">
                              <div class="card-jobdate fixTxtColor">
                                  <span>{{noteDetail?.week_day}}</span>
                                  <span class="month-day">{{noteDetail?.month_day}}</span>
                                  <span>{{noteDetail?.month_name}}</span>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="noteDetail?.customerName" class="col p-0 m_width_50">
                        <div class="w-100 paddTop">
                            <img class="img_jobtype" [src]="noteDetail.customerLogo ? baseURL+noteDetail.customerLogo : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                        </div>
                    </div>
                      <div *ngIf="noteDetail?.customerName" class="col paddTop">
                          <small>Customer Name </small><br />
                          <p>{{noteDetail?.customerName | capitalize}}</p>
                      </div>
                      <div *ngIf="noteDetail?.siteName" class="col-2 paddTop">
                          <small>Job Site </small><br />
                          <p [title]="noteDetail.siteName ? noteDetail.siteName : noteDetail.siteName">{{noteDetail?.siteName}}</p>
                      </div>
                      <div *ngIf="noteDetail?.jobType" class="col">
                          <div class="row">
                              <div class="col p-0 m_width_50">
                                  <div class="w-100 paddTop">
                                      <img class="img_jobtype" [src]="noteDetail.jobIcon ? baseURL+noteDetail.jobIcon : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                                  </div>
                              </div>
                              <div class="col paddTop">
                                  <small>Job Type </small><br />
                                  <p>{{noteDetail?.jobType | capitalize}}</p>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="noteDetail?.startTime" class="col paddTop">
                          <small>Shift </small><br />
                          <p>{{noteDetail?.startTime}} - {{noteDetail?.endTime}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    </div>
</ng-template>

<ng-template #detailContent>
    <div class="ps-3">
  <div *ngIf="noteDetail" class="w-100 detailSecSpace">
    <div class="row rowBoxStyle roundedBorder">
      <div *ngIf="noteDetail?.customerName" class="col">
        <div class="row">
            <div class="col p-0 m_width_50">
                <div class="w-100 paddTop">
                    <img class="img_jobtype" [src]="noteDetail.customerLogo ? baseURL+noteDetail.customerLogo : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                </div>
            </div>
            <div class="col paddTop">
                <small>Customer Name </small><br />
                <p>{{noteDetail?.customerName | capitalize}}</p>
            </div>
        </div>
      </div>
        <div *ngIf="noteDetail?.staffName || noteDetail?.entityName === 'Timecard'" class="col">
          <div class="row">
              <div class="col ps-0 paddTop">
                <profile-avatar-with-name [userDetail]="noteDetail" [lableTxt]="'Staff Name'" [isRowNameWithLogo]="true"></profile-avatar-with-name>
              </div>
          </div>
        </div>
      <div *ngIf="noteDetail?.siteName" class="col paddTop">
          <small>Job Site </small><br />
          <p [title]="noteDetail.siteName ? noteDetail.siteName : noteDetail.siteName">{{noteDetail?.siteName}}</p>
      </div> 
      <div *ngIf="noteDetail?.distributionName" class="col paddTop">
        <small>Distribution Name </small><br />
        <p>{{noteDetail?.distributionName}}</p>
    </div>    
      <div *ngIf="noteDetail?.jobType" class="col">
        <div class="row">
            <div class="col p-0 m_width_50">
                <div class="w-100 paddTop">
                    <img class="img_jobtype" [src]="noteDetail.jobIcon ? baseURL+noteDetail.jobIcon : 'assets/common_images/profile.jpg'" onerror="this.src='assets/common_images/profile.jpg';" />
                </div>
            </div>
            <div class="col paddTop">
                <small>Job Type </small><br />
                <p>{{noteDetail?.jobType | capitalize}}</p>
            </div>
        </div>
      </div>                          
      <div *ngIf="noteDetail?.startTime" class="col paddTop">
          <small>Shift </small><br />
          <p>{{noteDetail?.startTime}} - {{noteDetail?.endTime}}</p>
      </div>
      <div *ngIf="noteDetail?.shift" class="col paddTop">
        <small>Shift </small><br />
        <p>{{noteDetail?.shift}}</p>
      </div>
      <div *ngIf="noteDetail?.invoiceId" class="col paddTop">
        <small>Invoice Id </small><br />
        <p>{{noteDetail?.invoiceId}}</p>
      </div>
      <div *ngIf="noteDetail?.invoiceDate" class="col paddTop">
        <small>Invoice Date </small><br />
        <p>{{noteDetail?.invoiceDate}}</p>
      </div>
      <div *ngIf="noteDetail?.clockinDateTime" class="col paddTop">
        <small>Clock-In Time </small><br />
        <p>{{noteDetail?.clockinDateTime}}</p>
      </div>
      <div *ngIf="noteDetail?.clockoutDateTime" class="col paddTop">
        <small>Clock-Out Time </small><br />
        <p>{{noteDetail?.clockoutDateTime}}</p>
      </div>
  </div>
</div>
</div>
</ng-template>
