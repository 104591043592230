import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TeamManagementService } from '../team-management/team-management.service';
import { TranslateService } from '@ngx-translate/core';
import { DistributionService } from '../distribution-list/distribution-service';
import { Key } from 'protractor';

@Component({
  selector: 'add-team-site',
  templateUrl: './add-team-site.component.html',
  styleUrls: ['./add-team-site.component.scss']
})
export class AddTeamSiteComponent implements OnInit {

  reqForm: FormGroup;
  options = [];
  customerSiteListWithDetail: any;
  popupTitle: string = 'Add Team';
  submitted = false;
  staffDropdownSettings = {};
  selectedCodeList: any[];
  selectedStaffCodeList: any[];
  selectedAddressCodeList: any[];
  allStaffMemberList: any[];
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddTeamSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: TeamManagementService,private translate: TranslateService, public distributionService: DistributionService) {
      this.staffDropdownSettings = {
        singleSelection: false,
        text: "Select Staff",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: "select_user_section",
        // badgeShowLimit: 5,
        maxHeight:197,
        autoPosition: true       
      };
      this.getAllStaffMemberByOrganizationCode();
    }

  ngOnInit() {
    console.log('Modal_Data: ', this.data);
    this.reqForm = new FormGroup({
      "teamName": new FormControl(null, [Validators.required]),
      // "distributionCode": new FormControl(null),
      "userCodeList": new FormControl(null, [Validators.required]),
      "addressCodeList": new FormControl(null),
    });
    this.allCustomerSiteListWithDetail();
    }


  /* Name:Vivek Chauhan
      use: for searching distribution name */
  private _filter(value, field): string[] {
    //////console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        // this.reqForm.controls['distributionCode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        // this.reqForm.controls['distributionCode'].setValue(value.value);
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  displayFn(option): string | undefined {
    return option ? option.name : undefined;
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    // //console.log(this.selectedCodeList);
    console.log('selectedCodeList: ', this.selectedCodeList);
  }
  OnItemDeSelect(item: any) {
    // //console.log(item);
    // //console.log(this.selectedCodeList);
  }
  onSelectAll(items: any) {
    // //console.log(items);
  }
  onDeSelectAll(items: any) {
    // //console.log(items);
  }

  allCustomerSiteListWithDetail() {

    this.service.common.progressLoader = true;
    this.service.getAllCustomerSiteDetailsWithCustomerByOrganizationCode(this.service.storage.get('orgCode')).subscribe((response: any) => {

      this.service.common.progressLoader = false;

      if (response.responsecode == 200) {

        this.customerSiteListWithDetail = this.groupData(response.data);
      }
      else {
        this.customerSiteListWithDetail = [];
      }
      console.log('CustomerSiteListWithDetail: ', this.customerSiteListWithDetail);
    })

  }

  onJobSiteChange(event) {
    this.selectedAddressCodeList = event.value;
  }

  groupData(data: any): { [key: string]: any } {
    const groupedData = data.reduce((groups, item) => {
      const key = item.customerName;
      if (!groups[key]) {
        groups[key] = [];
      }
      if(item.customerAddressResponseList && item.customerAddressResponseList.length > 0){
        item.customerAddressResponseList.forEach((addressListItem) => {
          let newItem = {
            "customerName": item.customerName,
            "customerCode": item.customerCode,
            "siteName": addressListItem.siteName,
            "customerAddressCode":  addressListItem.customerAddressCode
          }
          // console.log('customerSiteListWithDetail', addressListItem, newItem)
          groups[key].push(newItem);
        })
      }      
      return groups;
    }, {});
    return groupedData;
  }

  /* Name:Vivek Chauhan
  use: for getting All Staff Member list page*/
  getAllStaffMemberByOrganizationCode() {
    try {
        this.service.common.progressLoader = true;    
        this.distributionService.getAllStaffMemberByOrganizationCode(
          this.service.storage.get('orgCode'), (response) => {
            if (response.responsecode === 200) {                
              // //console.log('ResData: ', response.data);
              response?.data && response.data.length > 0 && response.data.forEach((element) => { element.itemName = element.name; element.id = element.userCode });
              this.allStaffMemberList = response?.data;
              this.service.common.progressLoader = false;
            } else {
              this.allStaffMemberList = [];
              this.service.common.progressLoader = false;
            }                   

            /* Name:Vivek Chauhan
            use: for getting distribution by id */

            try {
              // //console.log('ModData_:', this.data);
              this.selectedCodeList = [];
              this.selectedAddressCodeList = [];
              if (this.data && this.data.teamMasterCode) {
                  // this.getDistributionUserListByDistributionCode(this.data.teamMasterCode);
                  this.selectedCodeList = this.data && this.data.teamStaffMappingResponseList && this.data.teamStaffMappingResponseList.length > 0 ? this.data.teamStaffMappingResponseList.map((element) => { 
                    if(element && element.staffCode){
                      element.name = element.staffName;
                      element.id = element.staffCode;
                      element.userCode = element.staffCode;
                      element.itemName = element.staffName;
                      element.isActive = true;
                      delete element.staffCode;
                      delete element.logo;
                      element = element;
                    }
                    return element;
                   }) : [];

                   this.selectedAddressCodeList = this.data && this.data.customerAddressResponseList && this.data.customerAddressResponseList.length > 0 ? this.data.customerAddressResponseList.map((element) => { 
                    if(element && element.customerAddressCode){
                      element = element.customerAddressCode;
                      element = element;
                    }
                    return element;
                   }) : [];
                    this.popupTitle = 'Edit Team';
                    if(this.data && this.data.teamName){
                      this.reqForm.controls['teamName'].setValue(this.data.teamName);
                    }
                    this.service.common.progressLoader = false;
                  } else {                    
                    this.popupTitle = 'Add Team';
                    this.reqForm.reset();
                    this.service.common.progressLoader = false;
                  }
              
            } catch (error) {
              //console.log(error)
            }
          });
      } catch (error) {
          //console.log(error)
      }
  }

  getDistributionUserListByDistributionCode(distributionCode: any, ) { 
    // //console.log('getDistributionUserListByDistributionCode: ', distributionCode);
    this.selectedCodeList = [];
    try {


    this.distributionService.v2_getDistributionUserListByDistributionCode(distributionCode,0,1000).subscribe((response:any) => {
      if(response.responsecode === 200)
      {
        response.data.forEach((element) => 
        { 
          element.itemName = element.name; 
          element.id = element.userCode
        });
   
    this.selectedCodeList = response.data ;

      }  
  });


    } catch (error) {
      //console.log(error)
    }
  }

  isSupervisor(event:any, item: any){
    //console.log('isSupervisor', item, event.checked);
    item.isSupervisor = event.checked;
    //console.log('isSupervisor_1', item, this.selectedCertificationItems);
    return item;
  }

  /* Name:Vivek Chauhan
     use: for adding/creating/saving reqForm in organization */
  onSubmit() {  
    this.submitted = true;
    try {
      if (this.reqForm.valid) {
        
        let cloneList = this.selectedCodeList ? JSON.parse(JSON.stringify(this.selectedCodeList)) : [];
        let newListData = cloneList && cloneList.length > 0 ? cloneList.map((user) => {
          let newItem = {
          "isSupervisor": user?.isSupervisor ? true : false,
          "staffCode": user.userCode,
          }
          return newItem;
        }) : [];

        let addReqBody = {
            customerAddressCodeList: this.selectedAddressCodeList,
            isActive: true,
            organizationCode: this.service.storage.get('orgCode'),
            teamMasterCode: this.data ? this.data.teamMasterCode : null,
            teamName: this.reqForm.controls.teamName.value,
            teamStaffMappingRequestList: newListData
          }

        // console.log('AddDistributionReq: ', addReqBody);
        // return;
        this.service.common.progressLoader = true;
        this.isLoading = true;

        this.service.saveAndUpdateTeamMaster(addReqBody).subscribe((res:any) =>{
          this.service.common.progressLoader = false;
          this.isLoading = false;
          this.submitted = false;
          if (res.responsecode == 200) {
            this.service.common.successMessage = true;
            this.service.common.message = res.message;
             //this.updateParent.emit('');
             this.dialogRef.close('success');
            }
          else {
             this.service.common.message = res.message;
            this.service.common.errorMessage = true;
            
          }
        });
      } 
    } catch (error) {
      //console.log(error)
    }
    
    ////console.log(this.reqForm);

  }

   /* Name:Vivek Chauhan
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.dialogRef.close('true');
  }
}
