<div class="worker">
  <mat-icon class="cursor-pointer icon-right" (click)="confirmAppointment()">close</mat-icon>
  <h4 *ngIf="eligibleWorker == false && data.data.length > 0">{{ 'dashboard.messages.Following Technicians are Assigned' | translate }}</h4>
  <h4 *ngIf="eligibleWorker == true">{{ 'common.labels.Select Technicians' | translate }}</h4>
  <div class="assign-popup" *ngIf="data.data.length == 0">
        <div class="text-center"> 
            <div class="mb-3">
              <img width="65px" src="assets/common_images/technician_popup_1.png">
            </div>
            <div>
              <p>{{ 'common.labels.No Technician are Assigned' | translate }}</p>
            </div>
            <div>
              <small>
                {{ 'common.labels.Do you want to split this appointment?' | translate }}
              </small>
            </div>
        </div>
        <hr>
        <div class="text-center">
            <button mat-button class="me-4 no-btn">No</button>
            <button mat-raised-button class="yes-btn">Yes</button>
        </div>
  </div>
  <div *ngIf="data.data.length > 0" class="wrokerpopup">
    <div *ngIf="eligibleWorker == false" class="wrokerpopup">
      <ul >
        <li *ngFor="let worker of data.data" >
          <div class="row ">
            <div class="col-md-2">
              <img  alt="No Image" class="product-image" *ngIf="worker.workerWithDistanceList.length > 0 && worker.workerWithDistanceList[0].worker.workerLogo != null && worker.workerWithDistanceList[0].worker.workerLogo != ''" #imageUploaded [alt]="worker.name"
                [src]="service.service.getSuperAdmin() + '/downloadFile/' + worker.workerWithDistanceList[0].worker.workerLogo" />
              <img alt="No Image" *ngIf="worker.workerWithDistanceList.length == 0 || (worker.workerWithDistanceList.length > 0 && worker.workerWithDistanceList[0].worker.workerLogo == null || worker.workerWithDistanceList[0].worker.workerLogo == '')" [src]="'assets/common_images/profile.jpg'" class="product-image">
            </div>
            <div class="col-md-7 detail p-0">
              <p class="name mb-0" *ngIf="worker.workerWithDistanceList.length > 0">{{worker.workerWithDistanceList[0].worker.name}} 
                   <mat-icon (click)="openPatientDetails(worker.appointmentsDurationCode)">info</mat-icon>  </p>
              <p class="email mb-0" *ngIf="worker.workerWithDistanceList.length > 0">{{worker.workerWithDistanceList[0].worker.email}}</p>
              <p class="email mb-0" *ngIf="worker.workerWithDistanceList.length == 0">No worker assigned</p>
              <p class="date mb-0">{{worker.jobDurationStartTime}}</p>
              <div *ngIf="worker.appointmentsDurationCode === selectedAppointment" class="ap-pt-detail shadow">
                <div class="w-100 text-end">
                    <span><span class="material-icons" (click)="openPatientDetails(worker.appointmentsDurationCode)"> clear </span></span>
                </div>
                <p class="m-0"><strong>Customer Name:</strong> <span class="ps-2">{{worker.customerName}} </span></p>
                <p class="m-0"> <strong>Job Date:</strong> <span class="ps-2"> {{worker.appointmentsDurationStartTime.split(' ')[0]}}</span></p>
                <p class="m-0"> <strong>Job Start time: </strong><span class="ps-2"> {{worker.appointmentsDurationStartTime.split(' ')[1]+' '+ worker.appointmentsDurationStartTime.split(' ')[2]}}</span></p>
              </div>
            </div>
            
            <div class="col-md-2 p-0 text-end">
              <i *ngIf="worker.workerWithDistanceList.length > 0" class="material-icons cursor-pointer" (click)="onEditWorker(worker.appointmentsDurationCode)">
                edit
              </i>
            </div>
          </div>


         
        </li>
      </ul>
      <div class="text-center buttons-bottom">
        <a class="cursor-pointer" (click)="redirectAppointment()">Cancel</a>
        <button mat-raised-button color="primary" (click)="confirmAppointment()">Confirm</button>
      </div>
    </div>

    <div *ngIf="eligibleWorker == true">
      <form [formGroup]="eligible" (ngSubmit)="onSubmit()">
        <ul *ngIf="otherEligibleWorker.length > 0">
          <mat-radio-group aria-label="Select an option" formControlName="worker">
            <li *ngFor="let worker of otherEligibleWorker">
              <div class="row">
                <div class="col-md-2 p-0 ps-4 text-center align-self-center">
                    <mat-radio-button [value]="worker.workerCode" [checked]="worker.isAssigned"></mat-radio-button>
                </div>
                <div class="col-md-3">
                  <img class="product-image" *ngIf="worker.workerLogo" #imageUploaded [alt]="worker.name"
                    [src]="service.service.getSuperAdmin() + '/downloadFile/' + worker.workerLogo" />
                  <img *ngIf="!worker.workerLogo" [src]="'assets/common_images/profile.jpg'" class="product-image">
                </div>
                <div class="col-md-7 detail p-0">
                  <p class="name mb-0">{{worker.name}}</p>
                  <p class="email mb-0">{{worker.email}}</p>
                </div>
              </div>
            </li>
            </mat-radio-group>
          </ul>
          <div class="row" *ngIf="otherEligibleWorker.length == 0">
            <p class="col-md-12">No other worker available for the job</p>
          </div>
          <div class="text-center buttons-bottom">
            <a class="cursor-pointer" (click)="cancelEligible()">Cancel Job</a>
            <button mat-raised-button color="primary" [disabled]="!eligible.valid">Save</button>
          </div>
        </form>
    </div>
  </div>
</div>