import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
 
@Injectable({
    providedIn: 'root'
})
export class TodaysJobService {

constructor(
    private service: ApiRootService,
private http: HttpClient,
) {   }

getTodaysJobByPage(org_code:string , filterValue:string , page:number, limit:number){
  return this.http.get(this.service.getHealthCareUrl() + `/v2/getTodaysJobsByPage/${org_code}/${filterValue}?page=${page}&limit=${limit}`)
}
 
}