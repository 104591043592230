import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'wvcTimeShorting'
})
export class WvcTimeShortingPipe implements PipeTransform {

  transform(items: any[]): any[] {
    if (!items || items.length <= 1) {
      return items;
    }

    return items.slice().sort((a, b) => {
      const timeA = new Date('2000-01-01 ' + a.startTime).getTime();
      const timeB = new Date('2000-01-01 ' + b.startTime).getTime();

      // const timeA = moment(a.dynamicDate ? a.dynamicDate : '2000-01-01' +' '+ a.startTime).toDate().getTime();
      // const timeB = moment(b.dynamicDate ? b.dynamicDate : '2000-01-01' +' '+ b.startTime).toDate().getTime();

       return timeA - timeB;
    });
  }
    

}
