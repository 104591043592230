import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';
import * as moment from 'moment';

@Component({
  selector: 'app-assign-appointment',
  templateUrl: './assign-appointment.component.html',
  styleUrls: ['./assign-appointment.component.scss']
})
export class AssignAppointmentComponent implements OnInit {

  eligible: FormGroup;
  otherEligibleWorker = [];
  showMessage = false;
  approvedStatus = [];
  selectedWorker;
  ScheduleCountData;
  searchTerm;
  appointmentfilter = [];

  constructor(public dialogRef: MatDialogRef<AssignAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: CreateAppointmentService) { }

  ngOnInit() {
    ////console.log(this.data);

    this.eligible = new FormGroup({
      'worker': new FormControl(null, Validators.required)
    });

    // this.service.getEligibleWorkerForJobsByJobDurationCode(this.data.jobDurationCode, (data)=>{
      
    if (this.data.data.responsecode == 200) {
      ////console.log(this.data.data);
      if (this.data.data.data.workerWithDistanceList.length > 0) {
        this.data.data.data.workerWithDistanceList.forEach(elementData => {
          if (elementData.worker) {
            elementData.worker.isAssigned = elementData.isAssigned;
            elementData.worker.distance = elementData.distance;
            elementData.worker.distanceText = elementData.distanceText;
            elementData.worker.durationText = elementData.durationText;

            
            elementData.worker.scheduledAppointmentDaily = elementData.scheduledAppointmentDaily;
            elementData.worker.targetAppointmentDaily = elementData.targetAppointmentDaily;
            elementData.worker.targetAppointmentWeekly = elementData.targetAppointmentWeekly;
            elementData.worker.targetHoursDaily = elementData.targetHoursDaily;
            elementData.worker.targetHoursWeekly = elementData.targetHoursWeekly;
            elementData.worker.scheduledAppointmentWeekly = elementData.scheduledAppointmentWeekly;
            elementData.worker.scheduledHoursDaily = elementData.scheduledHoursDaily;
            elementData.worker.scheduledHoursWeekly = elementData.scheduledHoursWeekly;
            elementData.worker.skillMasterList = elementData.skillMasterList;

            this.otherEligibleWorker.push(elementData.worker);

            ////console.log(this.otherEligibleWorker);
          }
        });
        this.appointmentfilter= this.otherEligibleWorker;

        setTimeout(() => {
          this.otherEligibleWorker.forEach((element) => {
            if (element.isAssigned == true) {
              this.eligible.controls['worker'].setValue(element.workerCode);
            }
          })
        }, 500)
      } else {
        this.service.common.progressLoader = true;
        try {
       
          //this.service.common.getWorkerAndOrganizationFromWAS(this.service.common.local.get('orgCode'), (data) => {
          this.service.common.getWorkerAndOrganizationFromWAS(this.data.data.data.appointmentsDurationCode, (data) => {
          ////console.log(data.data);
          if (data.responsecode == 200) {
            this.service.common.progressLoader = false;
            if (data.data.workerWithDistanceList.length > 0) {
              data.data.workerWithDistanceList.forEach(element => {
                if (element.worker ) 
                {
                  this.otherEligibleWorker.push(element.worker);
                }
              });
            }
            else {
              this.showMessage = true;
              this.otherEligibleWorker = [];
              this.approvedStatus = [];
            }
          } else {
            this.showMessage = true;
            this.otherEligibleWorker = [];
            this.approvedStatus = [];
          }
        })   
        } catch (error) {
          
        }
      }
      setTimeout(() => {
        //////console.log(this.otherEligibleWorker);
        this.service.common.progressLoader = false;
      }, 200)
    } else {
      this.showMessage = true;
      this.service.common.progressLoader = false
      this.otherEligibleWorker = [];
    }
    // })
  }

// ngOnInit(): void {

//   this.eligible = new FormGroup({
//         'worker': new FormControl(null, Validators.required)
//       });

//   this.service.common.progressLoader = true;
//   try {
 
//     //this.service.common.getWorkerAndOrganizationFromWAS(this.service.common.local.get('orgCode'), (data) => {
//     this.service.common.getWorkerAndOrganizationFromWAS('612a2bfe-aa89-4991-b11f-dca189d7dc52', (data) => {
//     ////console.log(data.data);
//     if (data.responsecode == 200) {
//       this.service.common.progressLoader = false;
//       if (data.data.workerWithDistanceList.length > 0) {
//         data.data.workerWithDistanceList.forEach(element => {
//           if (element.worker ) 
//           {
//             this.otherEligibleWorker.push(element.worker);
//             this.appointmentfilter = this.otherEligibleWorker ;
//           }
//         });
//       }
//       else {
//         this.showMessage = true;
//         this.otherEligibleWorker = [];
//         this.approvedStatus = [];
//       }
//     } else {
//       this.showMessage = true;
//       this.otherEligibleWorker = [];
//       this.approvedStatus = [];
//     }
//   })   
//   } catch (error) {
    
//   }
// }


/* Name:srinivas
       use: save assign appointment details.*/
  onSubmit() {
    if (this.eligible.valid) {
      this.service.common.progressLoader = true;
      const worker = {
        "workerCode": this.eligible.value.worker
      }
      try {              
      this.service.assignToWorkerByJobDurationCode(this.data.jobDurationCode, this.data.jobDurationCode, (res) => {
        if (res.responsecode == 200) {
          this.eligible.reset();
          // this.service.common.message = res.message;
          // this.service.common.successMessage = true;
          this.service.common.progressLoader = false;
          this.dialogRef.close(res.message);
        } else {
          this.service.common.progressLoader = false;
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })
    } catch (error) {
        
      }
    }
  }
/* Name:srinivas
       use: return worker details. */
  showWorkerDetails(workerCode){
    this.selectedWorker = workerCode;
    ////console.log(this.selectedWorker);
    ////console.log(this.data['WeekstartDate'])
    ////console.log(this.data['weekendDate'])
    ////console.log(this.data['StartDate'])
   this.getAllhours(this.selectedWorker,this.data['StartDate'],this.data['WeekstartDate'],this.data['weekendDate'],this.service.session.get('orgCode'));

  }
/* Name:srinivas
       use: close worker detals. */
  closeWorkerDetails(){
    ////console.log('ascascas');
    this.selectedWorker = null;
    ////console.log(this.selectedWorker);
  }
/* Name:srinivas
       use: cancel eligible popup */
  cancelEligible() {
    this.dialogRef.close();
  }

  /* Name:srinivas
       use: return all hours */
  getAllhours(workerCode, selectedDate, startdate, enddate,orgCode) {
    try {
    
    this.service.mobileAdminGetScheduleCountAndOrganizationBasedProcedureDetailsByWorkerCodeScheduleDateAndDateRange(workerCode, selectedDate, startdate, enddate,orgCode, (response) => {
      ////console.log(response);
      if (response.responsecode == 200) {
        this.ScheduleCountData = response.data;

        
      }
    })  
    } catch (error) {
      
    }
  }
/* Name:srinivas
       use: return after search details. */ 
  search() {
    ////console.log(this.searchTerm);
    if (this.searchTerm) {
      ////console.log(this.otherEligibleWorker);
      const filterValue = ('' + this.searchTerm).toLowerCase();
      this.appointmentfilter = this.otherEligibleWorker.filter((item) => {
        return  (item.name.toLowerCase().includes(filterValue.toLowerCase())) ;
      });

      if (this.appointmentfilter.length==0){
        this.showMessage=true;
      }

    ////console.log(this.appointmentfilter);
    } else {
      this.appointmentfilter = this.otherEligibleWorker;

    }
 

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
