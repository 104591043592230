<div class="row">
  <div class="col-6 text-start align-self-center">
    <div class="header-name">Scheduling Rules</div>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
  </div>
</div>

<form [formGroup]="schedulingForm" (ngSubmit)="onSubmit()" *ngIf="schedulingRules">
<div class="table-scroll-height mt-3" formArrayName="rules">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" width="70"></th>
        <th scope="col">Criteria</th>
        <th scope="col" width="300">Selection</th>
        <th scope="col" width="200">Sorting Order</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rules of schedulingRules; let i = index" [formGroupName]="i">
        <th scope="row" class="ps-3 pe-0">
          <mat-checkbox #preferred [checked]="rules.isChecked" formControlName="isChecked"></mat-checkbox>
        </th>
        <td>
          <p class="mt-3">{{rules.criteriaDisplayName}}</p>
        </td>
        <td>
          <mat-form-field appearance="outline" floatLabel="always"  class="w-100" *ngIf="rules.selectionDisplayList">
            <mat-label>Select</mat-label>
            <mat-select placeholder="" [disabled]="!preferred._checked" formControlName="selectionValue">
              <mat-option *ngFor="let pref of rules.selectionDisplayList" [value]="pref">
                {{pref | capitalize}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <p *ngIf="!rules.selectionDisplayList">None</p>
        </td>
        <td>
          <div class="">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
              <mat-label>Priority</mat-label>
              <input type="number" min="0" max="10" formControlName="sortingOrder" [readonly]="!preferred._checked" matInput placeholder="" autocomplete="off">
            </mat-form-field>
          </div>
          <input type="hidden" formControlName="appointmentsRuleMasterCode" [value]="rules.appointmentsRuleMasterCode">
          <input type="hidden" formControlName="appointmentsRuleOrganizationMappingCode" [value]="rules.appointmentsRuleOrganizationMappingCode">
        </td>
      </tr>
    </tbody>
  </table>
</div>
</form>
