import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";
import { UserRegistrationService } from 'src/app/dashboards/providers-dashboard/user-registration/user-registration.service';
import { InvoiceService } from '../invoice-service';
import { ProfileService } from 'src/app/dashboards/providers-dashboard/profile/profile.service';

@Component({
  selector: 'app-save-and-post-invoice',
  templateUrl: './save-and-post-invoice.component.html',
  styleUrls: ['./save-and-post-invoice.component.scss']
})
export class SaveAndPostInvoiceComponent implements OnInit {
  protected otherEmails:string = '';
  selectedCustomerListForSaveOrSend: any;
  selectedEmailList: any[] = [];
  customerContactList: any[] = [];
  showSpinner: boolean = false;
  isSendPdfLoading: boolean = false;
  invalidEmails: any[] = [];
  // customerList:any =[];

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<SaveAndPostInvoiceComponent>, public service: UserRegistrationService, @Inject(MAT_DIALOG_DATA) public data: any,  public invoiceService: InvoiceService, public profileService: ProfileService) {}

  ngOnInit() {    
    console.log('ModelData: ', this.data);     
    if(this.data && this.data?.invoiceDetail?.customerCode){
    this.getAdminListByCustomerCode(this.data.invoiceDetail.customerCode);  
    }
  }

  getAdminListByCustomerCode(customerCode: any){
    this.showSpinner = true;
    this.invoiceService.getCustomerAdminList(customerCode, (res:any) => {
      // console.log('Res: ', res);
    // this.service.getAllCustomerDetails(this.service.local.get('orgCode')).subscribe((res:any) => {  
      this.showSpinner = false; 
     if(res.responsecode == 200){      
      // let newArrList = res?.data && res?.data?.length > 0 && res.data.map((item, idx) => ({
      //   adminEmail: item.adminEmail,
      //   adminName: item.adminName
      // }));
       let newArrList = res?.data && res?.data?.length > 0 && res.data.map((item, idx) => {
        if(idx !== 0){
          let newItem = {
            adminEmail: item.adminEmail,
            adminName: item.adminName
          }
          return newItem
        } else {
          let newItem = {
            adminEmail: item.adminEmail,
            adminName: item.adminName,
            isAssigned: true
          }
          return newItem
        }
       });
      
      this.customerContactList = newArrList ;     
      console.log('CustomerContactList: ', this.customerContactList);   
     }
   })
   }

  /* Name:Vivek Chauhan
  use: for use check email is valid or not is typed in textarea/textbox or input field */ 
   validateEmailOrNot(event){
    if(event.target.value && event.target.value.trim()){
    const value = event.target.value;

      // Regular expression to validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Split the string by comma and trim whitespace
      const values = value.split(',').map(v => v.trim());

      // Check each value and classify as valid or invalid email
      const validEmails = [];
      const invalidEmails = [];

      let newValidValues = values && values?.length > 0 && values?.filter(item => item !== '');

      newValidValues && newValidValues?.length > 0 && newValidValues?.forEach(val => {
        const trimmedValue = val.trim();
          if (emailRegex.test(trimmedValue)) {
              validEmails.push(trimmedValue);
          } else {
              invalidEmails.push(trimmedValue);
          }
      });
      
      if(invalidEmails?.length > 0){
        this.invalidEmails = invalidEmails;        
      } else {
        this.invalidEmails = [];
      }

      // Output the results
      // console.log('Valid Emails:', validEmails);
      // console.log('Invalid Emails:', invalidEmails, this.invalidEmails);
    } else {
      this.invalidEmails = [];
    }
   }

   onItemSelect(item: any) {
    // console.log('onItemSelect', item);
    // console.log(this.selectedCodeList);
  }
  OnItemDeSelect(item: any) {
    // //console.log(item);
    // //console.log(this.selectedCodeList);
  }
  onSelectAll(items: any) {
    // //console.log(items);
  }
  onDeSelectAll(items: any) {
    // //console.log(items);
  }

  postOrSaveAndPost(invoiceDetail: any, action = null){
    // console.log('SaveNote: ', this.customerContactList, this.otherEmails);
    // console.log('InvoiceDetail: ', invoiceDetail);
    const assignedEmails = this.customerContactList?.filter(item => item.isAssigned).map(item => item.adminEmail);
    let otherEmails = this.otherEmails && this.otherEmails.trim() && this.otherEmails.replace(/\s+/g, '');
    if(assignedEmails?.length === 0 && !this.otherEmails.trim()){
      this.invoiceService.common.message = 'Please select at least one valid email';
      this.invoiceService.common.errorMessage = true;
      return true;
    }
    let reqBody = {
      contactEmailList: assignedEmails ? assignedEmails : [],
      invoiceCode: invoiceDetail?.invoiceCode,
      invoiceStatus: action ? action : null,
      otherEmails: otherEmails ? otherEmails : ''
    };     
    
    // console.log('ReqBody_Send: ', reqBody);
    // return;
    this.isSendPdfLoading = true;
    this.invoiceService.sendInvoiceToCustomer(reqBody, (res) => { 
      this.isSendPdfLoading = false;
        if (res.responsecode == 200) {
          this.dialogRef.close('success'); 
          this.service.common.successMessage = true;
          this.service.common.message = res.message;
        }
        else {
          this.service.common.errorMessage = true;
          this.service.common.message = res.message;
        }
    });
  }

  sendWithAttachment(data: any) {
    const assignedEmails = this.customerContactList?.filter(item => item.isAssigned).map(item => item.adminEmail);
    const assignedEmailsString = this.customerContactList?.filter(item => item.isAssigned).map(item => item.adminEmail).join(',');
    let otherEmails = this.otherEmails && this.otherEmails.trim() && this.otherEmails.replace(/\s+/g, '');

    if(assignedEmails?.length === 0 && !this.otherEmails.trim()){
      this.invoiceService.common.message = 'Please select at least one valid email';
      this.invoiceService.common.errorMessage = true;
      return true;
    }

    const formData: FormData = new FormData();
      formData.append("file", data?.pdfFile, data?.attachmentName);
      formData.append("invoiceCode", this.data.invoiceDetail.invoiceCode);
      formData.append("contactEmailList", assignedEmailsString ? assignedEmailsString : '');
      formData.append("otherEmails", otherEmails ? otherEmails : '');
      // console.log('uploadFile: ', formData);
      // return;
      this.isSendPdfLoading = true;
      this.invoiceService.sendInvoiceWithAttachmentToCustomerNew(formData).subscribe((res:any) => {
        this.isSendPdfLoading = false;
        if(res.responsecode == 200){
          this.dialogRef.close('success'); 
          this.invoiceService.common.message = res.message;
          this.invoiceService.common.successMessage = true;}
        else {
          this.invoiceService.common.message = res.message;
          this.invoiceService.common.errorMessage = true;
        }  
      })
  }

}
