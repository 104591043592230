import { ApiRootService } from './../../../shared/api-root.service';
import { CommonService } from './../../../shared/common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    public http: HttpClient,
    public common: CommonService,
    public service: ApiRootService
  ) { }

  allMasterdocs=[];
  adminData = {
    adminDetails: {
      confirmedPassword: null,
      email: null,
      isActive: false,
      name: null,
      password: null,
      userCode: null,
      userName: null,
      adminImage: null,
      isAdmin: true,
      isOwner: false,
      organizationCode: null
    }
  }

  uploadData = {
    documentName: null,
    expiryDate: null,
    organizationCode: null,
    uploadPath: null,
    note: null,
    userCode: null,
    orgDocWiseCommentCode: null

  }

  noteData = {
    docComment: null,
    note: null,
    orgDocWiseCommentCode: null,
    organizationDocumentCode: null
  }

  orgNoteData = {
    docComment: null,
    note: null,
    orgWiseDocCommentCode: null,
    organizationCode: null
  }
  profileData = {
    adminDetails: {
      confirmedPassword: null,
      email: null,
      isActive: null,
      name: null,
      password: null,
      userCode: null,
      userName: null,
      adminImage: null
    },
    isAdmin: null,
    role: '',
    ownerDetails: {
      organizationName: "",
      organizationCode: "",
      lat: "",
      lang: "",
      isActive: null,
      organizationLogo: "",
      ownerImage: "",
      name: "",
      email: "",
      primaryContact: "",
      offHoursContact: "",
      password: null,
      confirmedPassword: null,
      userCode: "",
      userName: ""
    },
  }

  // getOrganizationDetailByOrgCodeAndAdminCode(orgCode,adminCode,callback){
  //   return this.http.get(this.service.getRegistrationUrl()+'/getOrganizationDetailByOrgCodeAndAdminCode/'+orgCode+'/'+adminCode).subscribe((data)=>{
  //     callback(data);
  //   })
  // }

  /* Name:Shailesh
      use: for getting oraganization detail by org code and admin code */
  getOrganizationDetailByOrgCodeAndAdminCode(orgCode, userCode, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getOrganizationDetailByOrgCodeAndAdminCode/' + orgCode + '/' + userCode).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
        use: for checking email id available or not */
  checkEmailAvialablity(email, callback) {
    // const email_new = encodeURIComponent(email);
    return this.http.get(this.service.getRegistrationUrl() + '/getEmailAvailability/' + email).subscribe((data) => {
      callback(data);
    })
  }



  /* Name:Shailesh
      use: for checking organization name is available or not*/
  checkOrgNameAvialablity(orgname, callback) {
    // const email_new = encodeURIComponent(email);
    //console.log(orgname);
    if (orgname) {
      return this.http.get(this.service.getRegistrationUrl() + '/getOrganizationNameAvailability/' + orgname).subscribe((data) => {
        callback(data);
      })
    } else {
      callback(true);
    }
  }

  /* Name:Shailesh
      use: for unique user name is available or not */
  getIsUniqueUserNameForUpdate(userCode, username, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getIsUniqueUserNameForUpdate/' + userCode + '/' + username).subscribe((data) => {
      callback(data);
    })
  }

  // updateOrganisationDetail(formData,orgCode,admincode,callback){
  //   return this.http.put(this.service.getRegistrationUrl()+'/updateOrganizationDetailByOrgCodeAndAdminCode/'+orgCode+'/'+admincode,formData).subscribe((data)=>{
  //     callback(data);
  //   })
  // }

  /* Name:Shailesh
      use: for updating organization detail by org code*/
  updateOrganisationDetail(formData, orgCode, isAadmin, callback) {
    //console.log(formData, isAadmin, orgCode);
    return this.http.put(this.service.getRegistrationUrl() + '/updateOrganizationDetailByOrgCodeAndAdminCode/' + orgCode + '/' + isAadmin, formData).subscribe((data) => {
      callback(data);
    })
  }

  updateOrganizationDocument(formData, orgCode, callback) {
    return this.http.put(this.service.getRegistrationUrl() + '/updateOrganizationDocument/' + orgCode, formData).subscribe((data) => {
      callback(data);
    })
  }


  /* Name:Shailesh
     use: for save upload documents by org code*/
  saveOrganizationDocument(formData, callback) {
    return this.http.post(this.service.getRegistrationUrl() + '/saveOrganizationDocument/', formData).subscribe((data) => {
      callback(data);
    })
  }
  softDeletePermissionsByPermissionsCode(docCode, callback) {
    return this.http.delete(this.service.getRegistrationUrl() + '/softDeletePermissionsByOrganizationDocumentCode/' + docCode).subscribe((data) => {
      callback(data);

    })
  }

  getAllOwnerAdminListByOrgCode(code, usercode, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getAllOwnerAdminListByOrgCode/' + code + '/true/' + usercode).subscribe((data) => {
      callback(data);
    });
  }

  getOrganizationDocumentByOrganizationCode(orgCode,page,limit, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getOrganizationDocumentByOrganizationCode/' + orgCode + '?page='+page+'&limit='+limit).subscribe((data) => {
      callback(data);
    })
  }
  getOrganizationDocumentWiseNoteCommentByOrganizationDocumentCode(Code, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getOrganizationDocumentWiseNoteCommentByOrganizationDocumentCode/' + Code ).subscribe((data) => {
      callback(data);
    })
  }

  

  addNewAdmin(data, callback) {
    return this.http.post(this.service.getRegistrationUrl() + '/addNewAdmin', data).subscribe((data) => {
      callback(data)
    });
  }

  updateOwnerAdminDetails(code, data, callback) {
    return this.http.put(this.service.getRegistrationUrl() + '/updateOwnerAdminDetails/' + code, data).subscribe((data) => {
      callback(data)
    })
  }

  getAllServiceTypeByIsActive(callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllServiceTypeByIsActive/true').subscribe((data) => {
      callback(data)
    });
  }

  saveServiceTypeOrganization(data, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveServiceTypeOrganization', data).subscribe((data) => {
      callback(data)
    });
  }

  softDeleteServiceTypeOrganizationByServiceTypeOrganizationCode(code, callback) {
    return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteServiceTypeOrganizationByServiceTypeOrganizationCode/' + code).subscribe((data) => {
      callback(data)
    });
  }

  getServiceTypeOrganizationByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getServiceTypeOrganizationByOrganizationCode/' + code).subscribe((data) => {
      callback(data)
    });
  }
  saveAndUpdateOrganizationDocumentWiseNoteComment(userCode, data, callback) {
    return this.http.put(this.service.getRegistrationUrl() + '/saveAndUpdateOrganizationDocumentWiseNoteComment/' + userCode + '/' + true, data).subscribe((data) => {
      callback(data)
    })
  }

  getOrganizationWiseDocumentNoteCommentByOrganizationCode(code, callback) {
    return this.http.get(this.service.getRegistrationUrl() + '/getOrganizationWiseDocumentNoteCommentByOrganizationCode/' + code).subscribe((data) => {
      callback(data)
    });
  }
  

  saveAndUpdateOrganizationWiseDocumentNoteComment(userCode, data, callback) {
    return this.http.put(this.service.getRegistrationUrl() + '/saveAndUpdateOrganizationWiseDocumentNoteComment/' + userCode + '/' + true, data).subscribe((data) => {
      callback(data)
    })
  }
  softDeleteOrganizationDocumentWiseCommentByCode(Code, callback) {
    return this.http.delete(this.service.getRegistrationUrl() + '/softDeleteOrganizationDocumentWiseCommentByCode/' + Code ).subscribe((data) => {
      callback(data)
    })
  }
  softDeleteOrganizationWiseDocumentCommentByCode(Code, callback) {
    return this.http.delete(this.service.getRegistrationUrl() + '/softDeleteOrganizationWiseDocumentCommentByCode/' + Code ).subscribe((data) => {
      callback(data)
    })
  }

  setIsReadOrganizationDocumentWiseCommentByCode(code, data, callback){
    return this.http.put(this.service.getRegistrationUrl()+'/setIsReadOrganizationDocumentWiseCommentByCode/'+code, data).subscribe((data)=>{
        callback(data)
    });
}

setIsReadOrganizationWiseDocumentCommentByCode(code, data, callback){
  return this.http.put(this.service.getRegistrationUrl()+'/setIsReadOrganizationWiseDocumentCommentByCode/'+code, data).subscribe((data)=>{
      callback(data)
  });
}
getAllDocuments(all, page, count, callback) {
  return this.http.get(this.service.getRegistrationUrl() + '/getAllHealthcareDocumentMasterByDocumentNameAndPage/' + all + '?page=' + page + '&limit=' + count).subscribe((data) => {
    callback(data);
  })
}
}
