import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SchedulingRulesService {

  constructor(public service: ApiRootService, public common: CommonService, public http: HttpClient) { }

   /* Name:Shailesh
      use: for getting scheduling rule by org code */
  adminGetSchedulingRuleByOrganizationCode(code, callback){
    return this.http.get(this.service.getHealthCareUrl()+'/adminGetSchedulingRuleByOrganizationCode/'+code).subscribe((data)=>{
      callback(data);
    });
  }

   /* Name:Shailesh
      use: for adding/creating appointment rule by org code */
  adminAssignOrganizationToAppointmentsRule(code, data, callback){
    return this.http.post(this.service.getHealthCareUrl()+'/adminAssignOrganizationToAppointmentsRule/'+code, data).subscribe((data)=>{
      callback(data);
    });
  }
}
