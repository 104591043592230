<div class="requested-job table-list">
    <mat-progress-bar mode="indeterminate" *ngIf="show_tbl_data_progressbar"></mat-progress-bar>
    <div class="w-100 sticky-tbl-th">
      <div class="col-md-12 table-scroll-height"
      infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="100" 
      (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    
    <div class="requested-job-card-list">
        <ng-template [ngIf]="dataSource?.data.length > 0" [ngIfElse]="noRequestedJobsFound">
          <ng-container *ngFor="let outer_ele of dataSource.data">
        <mat-card class="job-mat-card mt-3 mb-3">
          <div class="row">

            <div class="col-2">
              <small>Customer Name </small><br>
              <p> {{outer_ele.customerName}}</p>
            </div>

            <div class="col-2">
              <small>{{ 'main.labels.Job Site' | translate }}</small><br>
              <p> {{outer_ele.jobLocation}}</p>
            </div>

            <div class="col-2">
              <small>Job Date </small><br>
              <p> {{outer_ele.startDT}}</p>
            </div>
            <div class="col-2">
              <small>Order Date </small><br>
              <p> {{outer_ele.orderDT}}</p>
            </div>
            <div class="col-2">
                <small>Status</small><br>
                <p class="badge bg-warning" *ngIf="outer_ele.requestStatus?.toLowerCase() === 'requested' && !isExpired "> {{outer_ele.requestStatus}}</p>
                <p class="badge bg-success" *ngIf="outer_ele.requestStatus?.toLowerCase() === 'processed' "> {{outer_ele.requestStatus}}</p>
                <p class="badge bg-success" *ngIf="outer_ele.requestStatus?.toLowerCase() === 'published' "> {{outer_ele.requestStatus}}</p>
                <p class="badge bg-danger"  *ngIf="outer_ele.isExpired || isExpired">Expired</p>
              </div>
               
              <ng-template [ngIf]="controlsPermissions.addPermission === true">
            <div class="col-2 text-end" 
            *ngIf="outer_ele.requestStatus?.toLowerCase() === 'requested' && !isExpired">
              <button type="button" mat-raised-button color="primary" (click)="viewJob(outer_ele,requestedJob_TempRef)">Accept job</button>
            </div>
            </ng-template>

            <hr>
   
            <div class="col-12">
  
              <ng-template [ngIf]="outer_ele.appointmentsProceduresRequestResponseList.length > 0">
                <table>
                  <thead>
                    <tr>
                      <th><small>Shift</small></th>
                      <th><small>Job Type</small></th>
                      <th><small>Job Qty</small></th>
                      <th><small>Uniform</small></th>
                      <th><small>Equipment</small></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let inner_ele of outer_ele.appointmentsProceduresRequestResponseList">
                      <th ><span>{{inner_ele.startTime}} - {{inner_ele.endTime}}</span></th>
                      <th >
                        
                        <!-- <img alt="No Image" class="commom-img-style" 
                          [src]="inner_ele?.jobTypeIcon ? (img_serverPath + inner_ele?.jobTypeIcon) : 'assets/common_images/profile.jpg'" /> -->

                        <span>{{inner_ele.jobTypeName}}</span>
                      </th>
                      <th ><span>{{inner_ele.numberOfWorker}}</span></th>
                      <th><span>{{inner_ele.uniform ? inner_ele.uniform : '--'}}</span></th>
                      <th><span>{{inner_ele.equipments ? inner_ele.equipments : '--'}}</span></th>
                    </tr>
                    </tbody>
                </table>
              </ng-template>
   
              </div>
  
          </div>
            </mat-card>
          </ng-container>
          </ng-template>
  
          <ng-template #noRequestedJobsFound>
           <div class="col-12 mt-5 text-center">
           <span class="text-danger">No any job requested till yet</span>
           </div>
          </ng-template>
       </div>


        <!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" multiTemplateDataRows>
      
          <ng-container matColumnDef="startDT">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Date</th>
            <td mat-cell *matCellDef="let element"> {{element.startDT}}</td>
          </ng-container>
          
          <ng-container matColumnDef="orderDT">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Date</th>
            <td mat-cell *matCellDef="let element"> {{element.orderDT}}</td>
          </ng-container>
    
          <ng-container matColumnDef="requestStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element"> {{element.requestStatus}}</td>
          </ng-container>
 
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element; let i = index" [attr.colspan]="displayedColumns.length">
     
          <ng-template [ngIf]="element.appointmentsProceduresRequestResponseList.length > 0">
          <div class="row mb-1">
            <div class="col-3"><strong><span>Shift</span></strong></div>
             <div class="col-2"><strong><span>Job Qty</span></strong></div>
            <div class="col-2"><strong><span>Uniform</span></strong></div>
            <div class="col-2"><strong><span>Equipment</span></strong></div>
          </div>
          <div class="row" *ngFor="let inner_ele of element.appointmentsProceduresRequestResponseList ">
            <div class="col-3"><small>{{inner_ele.startTime}} - {{inner_ele.endTime}}</small></div>
             <div class="col-2"><small>{{inner_ele.numberOfWorker}}</small></div>
            <div class="col-2"><small>{{inner_ele.uniform ? inner_ele.uniform : '--'}}</small></div>
            <div class="col-2"><small>{{inner_ele.equipments ? inner_ele.equipments : '--'}}</small></div>
          </div>
        </ng-template>
        
        </td>
      </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
           <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleRow(row)"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [class.hide-row]="!row.isExpanded">  </tr>
        </table> -->
     
      </div>
    </div>

 
    </div>



    <ng-template #requestedJob_TempRef>
      <div class="row">
         <div class="col-md-6 align-self-center">
           <h6 class="dialog-header pt-2">Requested Job</h6>
         </div>
         <div class="col-md-6">
           <div class="row justify-content-end">
             <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
           </div>
         </div>
       </div> 
   
       <div class="row shortcutPopupScrollBody">
        <app-new-job [isShortcutModalOpen]="true" [is_RequestedJob]="true" [is_RequestedJob_Data]="selected_job"></app-new-job>
       </div>
     </ng-template>