import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
 
@Injectable({
    providedIn: 'root'
})
export class ShiftService {

constructor(
public service: ApiRootService,
public http: HttpClient,
) {   }

getShiftMasterByPage(org_code:string , page:number, limit:number){
  return this.http.get(this.service.getHealthCareUrl() + `/getShiftMasterByOrganizationCodeByPage/${org_code}?page=${page}&limit=${limit}`)
}

saveAndUpdateShift(request_body:any) {
return this.http.post(this.service.getHealthCareUrl() + '/saveAndUpdateShiftMaster', request_body);
}

softDeleteShift(shiftMasterCode:string) {
return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteShiftMasterByShiftMasterCode/'+shiftMasterCode);
}
 
setActiveOrInActiveShifts(shiftMasterCode:string , payload:any) {
  return this.http.put(this.service.getHealthCareUrl() + '/setActiveOrInActiveShifts/'+shiftMasterCode, payload);
  }

}