
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn : 'root'
})


export class CryptoService {

private  key : string = "esoft@12345#?!$$";
 
  constructor() { }

  performeEncryption(value:any) {
    //encryptUsingAES256
    let _key = CryptoJS.enc.Utf8.parse(this.key);
    let _iv = CryptoJS.enc.Utf8.parse(this.key);

    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  performeDecryption(value:string) {
    //decryptUsingAES256
    let _key = CryptoJS.enc.Utf8.parse(this.key);
    let _iv = CryptoJS.enc.Utf8.parse(this.key);

    let decrypted = CryptoJS.AES.decrypt(
        value , _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decrypted) ;
  }
 




}