import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
 
@Injectable({
    providedIn: 'root'
})

export class ActivityLogService {

constructor(
private service: ApiRootService,
private http: HttpClient,
) {   }

getActivityLogByPage(org_code:string , activity_type:string ,page:number, limit:number){
  return this.http.get(this.service.getHealthCareUrl() + `/getActivityLogByPage/${org_code}/${activity_type}?page=${page}&limit=${limit}`)
}

 
}