<div class="general-setting StickyPosition position-relative">
    <div class="row generalSettings">
        <form [formGroup]="GeneralSetting_Form">
            <div class="row">
                <div class="accordian-description">
                    <div class="row position-sticky">
                        <div class="col-6 text-start">
                            <h4 class="header-name">
                                General Settings
                            </h4>
                        </div>

                        <ng-template [ngIf]="hasPermission.editPermission === true">
                        <div class="col-6 text-end">
                            <button mat-raised-button color="primary"
                             (click)="updateGeneralSetting()"
                             [disabled]="isLoading">
                             <span  *ngIf="isLoading" class="spinner-border spinner-border-sm"></span>&nbsp;
                             Update Setting</button>
                        </div>
                        </ng-template>

                        <div>
                            <hr>
                        </div>

                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="accordian-description admin-settings">
                                <div class="col-md-4 float-start">
                                    <div class="orange-title mt-3">
                                        {{ 'setting.labels.Scheduling Mode' | translate}}
                                    </div>
                                       <!-- <div class="form-group col-md-4 float-start">

                                                <div class="assign">
                                                    <span>{{ 'setting.labels.Scheduling Mode Auto' | translate}}</span>

                                                    <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="scheduling_mode_auto"
                                                        (change)="changeFull('scheduling_mode_auto',$event)"></mat-slide-toggle>

                                                </div>
                                            </div> -->
                                            <div class="form-group float-start">
                                                <div class="assign">
                                                    <span>{{ 'setting.labels.Scheduling Mode Distribution List' |
                                                        translate}}</span>
                                                    <!-- <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="scheduling_mode_distribution_list"
                                                        (change)="changeFull('scheduling_mode_distribution_list',$event)"
                                                       [ngModel]="(_systemSettingsSVC.settings[0].AdministratorSetting[25].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[25].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[25].globalSettingValue) === 'true'"></mat-slide-toggle> -->

                                                    <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="scheduling_mode_distribution_list"
                                                        (change)="changeFull('scheduling_mode_distribution_list',$event)"></mat-slide-toggle>

                                                </div>
                                            </div>
                                            <!-- <div class="form-group col-md-4 float-start">
                                                <div class="assign">
                                                    <span>{{ 'setting.labels.Scheduling Mode Self Signup' |
                                                        translate}}</span>
                                                    <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="scheduling_mode_self_signup"
                                                        (change)="changeFull('scheduling_mode_self_signup',$event)"></mat-slide-toggle>
                                                </div>
                                            </div> -->
                                         
                                </div>

                                <div class="col-md-4 float-start">
                                    <div class="orange-title mt-3">
                                        Billing Rate/Hr
                                    </div>
                                          <div class="form-group float-start">
                                                <div class="assign">
                                                    <span>Show Billing Rate/Hr</span>
                                                   <mat-slide-toggle class="ms-4" color="primary" formControlName="RatePerHour"></mat-slide-toggle>

                                                </div>
                                            </div>
                                  </div>
                             </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="accordian-description admin-settings">
                                <div class="col-md-12 float-start">
                                    <div class="orange-title mt-0">
                                        {{ 'setting.labels.Distribution List' | translate}}
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <!-- <div class="form-group col-md-4 float-start">
                                                <div class="assign">
                                                    <span>{{ 'setting.labels.Distribution List Auto' |
                                                        translate}}</span>
                                                    <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="distribution_list_auto"
                                                        (change)="changeFull('distribution_list_auto',$event)"></mat-slide-toggle>
                                                </div>
                                            </div> -->
                                            <div *ngIf="GeneralSetting_Form.controls['distribution_list_auto']?.value === true"
                                                class="form-group col-md-4 float-start">
                                                <div class="assign">
                                                    <span>{{ 'setting.labels.Distribution List Auto Skill Based' |
                                                        translate}}</span>
                                                    <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="distribution_list_skill_based"
                                                        [ngModel]="(GeneralSetting_Form.controls['distribution_list_location_based']?.value === true) ? false : GeneralSetting_Form.controls['distribution_list_skill_based']?.value"
                                                        (change)="changeFull('distribution_list_skill_based',$event)"></mat-slide-toggle>
                                                </div>
                                            </div>
                                            <div *ngIf="GeneralSetting_Form.controls['distribution_list_auto']?.value === true"
                                                class="form-group col-md-4 float-start">
                                                <div class="assign">
                                                    <span>{{ 'setting.labels.Distribution List Auto Location Based' |
                                                        translate}}</span>
                                                    <mat-slide-toggle class="ms-4" color="primary"
                                                        formControlName="distribution_list_location_based"
                                                        [ngModel]="(GeneralSetting_Form.controls['distribution_list_skill_based']?.value === true) ? false : GeneralSetting_Form.controls['distribution_list_location_based']?.value"
                                                        (change)="changeFull('distribution_list_location_based',$event)"></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="assign">
                                                <span>{{ 'setting.labels.Distribution List User Defined' |
                                                    translate}}</span>
                                                <!-- <mat-slide-toggle class="ms-4" color="primary"
                                                    formControlName="distribution_list_userdefine"
                                                    (change)="changeFull('distribution_list_userdefine',$event)"
                                                    [ngModel]="(_systemSettingsSVC.settings[0].AdministratorSetting[26].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[26].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[26].globalSettingValue) === 'true'"></mat-slide-toggle> -->

                                                <mat-slide-toggle class="ms-4" color="primary"
                                                    formControlName="distribution_list_userdefine"
                                                    (change)="changeFull('distribution_list_userdefine',$event)"></mat-slide-toggle>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="accordian-description admin-settings">

                        <hr>
                      <div class="row g-2 col-12">  
                        <div class="date-format col-md-4 float-start">
                            <div class="orange-title mt-3">
                                {{ 'setting.labels.Date Format' | translate}}
                            </div>
                            <div class="form-group">
                                <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                                    *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[6].globalSettingName == 'DateFormat'">
                                    <mat-label>Select Date</mat-label>
                                    <mat-select placeholder=""
                                        [(ngModel)]="_systemSettingsSVC.settings[0].AdministratorSetting[6].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[6].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[6].globalSettingValue"
                                        formControlName="dateformat">
                                        <mat-option>{{ 'setting.labels.Please Select' | translate}}</mat-option>
                                        <mat-option *ngFor="let format of dateformat" [value]="format.value">
                                            {{format.format}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <span class="help-block err"
                                    *ngIf="!GeneralSetting_Form.get('dateformat').valid && (isForm_Submitted  || GeneralSetting_Form.get('dateformat').touched)">{{
                                    'setting.validators.Date format is required' | translate}}</span>
                            </div>
                        </div>
                         
                           <div class="col-md-4">
                                <div class="orange-title mt-3">
                                    {{ 'setting.labels.Time Format' | translate}}
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                                        *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[7].globalSettingName == 'TimeFormat'">
                                        <mat-label>Select Time</mat-label>
                                        <mat-select placeholder="Select time"
                                            [(ngModel)]="_systemSettingsSVC.settings[0].AdministratorSetting[7].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[7].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[7].globalSettingValue"
                                            formControlName="timeformat">
                                            <mat-option>{{ 'setting.labels.Please Select' | translate}}</mat-option>
                                            <mat-option *ngFor="let format of _commonSVC.hoursFormat"
                                                [value]="format.format">
                                                {{format.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="help-block err"
                                        *ngIf="!GeneralSetting_Form.get('timeformat').valid && (isForm_Submitted  || GeneralSetting_Form.get('timeformat').touched)">{{
                                        'setting.validators.Time format is required' | translate}}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="orange-title mt-3">
                                    {{ 'setting.labels.Time Zone' | translate}}
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                                        *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[8].globalSettingName == 'TimeZone'">
                                        <mat-label>Select time zone</mat-label>
                                        <mat-select placeholder=""
                                              formControlName="timezone" (openedChange)="myInput.focus()">
                                            <input class="myInput" #myInput matInput focused="'true'" type="text"
                                                (keyup)="search($event.target.value)"
                                                (keydown)="$event.stopPropagation()" autocomplete="off"
                                                placeholder="Search">
                                            <!-- <mat-option> {{ 'setting.labels.Please Select' | translate}}</mat-option> -->
                                            <mat-option *ngFor="let time of selectedTimezone"
                                                [value]="time.timeZoneCode">
                                                {{time.timeZoneName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="help-block err"
                                        *ngIf="!GeneralSetting_Form.get('timezone').valid && (isForm_Submitted  || GeneralSetting_Form.get('timezone').touched)">{{
                                        'setting.validators.Time zone is required' | translate}}</span>
                                </div>
                            </div>

                            <!-- <div class="col-md-4">
                                <div class="orange-title mt-3">
                                    {{ 'setting.labels.Show Drive Time' | translate}}
                                </div>
                                <div class="assign"
                                    *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[21].globalSettingName == 'IsDrivingTime'">
                                    <span>{{ 'setting.labels.Drive Time' | translate}}</span>
                                    <mat-slide-toggle class="ms-4" color="primary"
                                        [ngModel]="(_systemSettingsSVC.settings[0].AdministratorSetting[21].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[21].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[21].globalSettingValue) === 'true'"
                                        formControlName="driveTime" [checked]="driveTimeShow"></mat-slide-toggle>
                                </div>
                            </div> -->
                         
                   </div>

                        <div class="row g-2 mb-3 col-12">
                         <div class="col-md-4">
                                <div class="assign">
                                    <span>{{ 'setting.labels.Geo fence for Clock-In' | translate}}</span>
                                    <mat-slide-toggle class="ms-4" color="primary"
                                        formControlName="geoFenceClockIn"></mat-slide-toggle>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field appearance="outline" floatLabel="always"
                                    class="example-full-width inline"
                                    *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[29].globalSettingName == 'eligible.staff.radius'">
                                    <mat-label>{{ 'setting.labels.Notify staff with in radius (Miles)' |
                                        translate}}</mat-label>
                                    <input matInput placeholder="" formControlName="notifyStaffRadius"
                                        [(ngModel)]="_systemSettingsSVC.settings[0].AdministratorSetting[29].localSettingValue != null 
                                                    ? _systemSettingsSVC.settings[0].AdministratorSetting[29].localSettingValue
                                                    : _systemSettingsSVC.settings[0].AdministratorSetting[29].globalSettingValue">
                                </mat-form-field>
                                <span class="help-block err"
                                    *ngIf="GeneralSetting_Form.get('notifyStaffRadius').errors?.pattern && (isForm_Submitted  || GeneralSetting_Form.get('notifyStaffRadius').touched)">
                                    {{ 'setting.validators.Filed value should be integer' | translate}}</span>
                            </div>

                            <div class="col-md-4"
                                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[23].globalSettingName == 'distance_tolerance'">
                                <mat-form-field appearance="outline" floatLabel="always"
                                    class="example-full-width inline">
                                    <!-- <mat-label>{{ 'setting.labels.Distance Tolerance' | translate}}</mat-label> -->
                                    <mat-label>Allow Clock-In Radius ({{radius_unit_label}})</mat-label>
                                    <input matInput placeholder="" formControlName="distance_tolerance"
                                        [(ngModel)]="_systemSettingsSVC.settings[0].AdministratorSetting[23].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[23].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[23].globalSettingValue">
                                </mat-form-field>
                                <span class="help-block err"
                                    *ngIf="GeneralSetting_Form.get('distance_tolerance').errors?.required && (isForm_Submitted  || GeneralSetting_Form.get('distance_tolerance').touched)">
                                    {{ 'setting.validators.Distance Tolerance is required' | translate}}</span>

                                <span class="help-block err"
                                    *ngIf="GeneralSetting_Form.get('distance_tolerance').errors?.pattern && (isForm_Submitted  || GeneralSetting_Form.get('distance_tolerance').touched)">
                                    {{ 'setting.validators.Please enter a valid distance tolerance' | translate}}</span>

                            </div>
                        </div>

                        <div class="row g-2 mb-3 col-12">

                            <div class="col-md-4">
                                <div class="assign">
                                    <span>Employees can be Site Supervisor</span>
                                    <mat-slide-toggle class="ms-4" color="primary"
                                        formControlName="Employees_Site_Supervisor"></mat-slide-toggle>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <mat-form-field appearance="outline" floatLabel="always"
                                    class="example-full-width inline">
                                    <!-- <mat-label>Clock-Out Alert Time (Minutes)</mat-label> -->
                                    <mat-label>Force Clock-Out Delay (Minutes)</mat-label>
                                     <input matInput formControlName="ClockOutAlertTime">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4">
                                <mat-form-field appearance="outline" floatLabel="always"
                                    class="example-full-width inline">
                                    <mat-label>Clock-Out Geofence Radius ({{radius_unit_label}})</mat-label>
                                    <input matInput formControlName="ClockOutGeofenceRadius">
                                </mat-form-field>

                                <span class="help-block err"
                                *ngIf="GeneralSetting_Form.get('ClockOutGeofenceRadius').errors?.required 
                                && (isForm_Submitted  || GeneralSetting_Form.get('ClockOutGeofenceRadius').touched)">
                                Please set Clock-Out radius
                                </span>

                                <span class="help-block err"
                                *ngIf="GeneralSetting_Form.get('ClockOutGeofenceRadius').errors?.pattern && (isForm_Submitted  || GeneralSetting_Form.get('ClockOutGeofenceRadius').touched)">
                                Please enter a valid radius</span>

                                <span class="help-block err"
                                *ngIf="GeneralSetting_Form.get('ClockOutGeofenceRadius').errors?.notANumber && (isForm_Submitted  || GeneralSetting_Form.get('ClockOutGeofenceRadius').touched)">
                                Please enter a valid radius</span>

                                <span class="help-block err"
                                *ngIf="GeneralSetting_Form.get('ClockOutGeofenceRadius').errors?.radius 
                                && (isForm_Submitted  || GeneralSetting_Form.get('ClockOutGeofenceRadius').touched)">
                                Should be longer than Clock-In radius
                                </span>
 

                            </div>

                            <div class="col-md-4">
                                <div class="assign">
                                    <span>Automated Job Approval for Customers</span>
                                    <mat-slide-toggle class="ms-4" color="primary"
                                        formControlName="autoJobApproval"></mat-slide-toggle>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="assign">
                                    <span>Enable Notes for Supervisor</span>
                                    <mat-slide-toggle class="ms-4" color="primary"
                                        formControlName="note_enable_for_supervisor"></mat-slide-toggle>
                                </div>
                            </div>

                            </div>

                            
                            <div class="row">
                             <div class="col-md-4">
                                <div class="orange-title">
                                    Radius Unit
                                </div>
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Radius Unit</mat-label>
                                    <mat-select formControlName="radius_unit" placeholder="select radius unit">
                                        <mat-option value="Meters">Meters </mat-option>
                                        <mat-option value="Feet">Feet</mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>


                            <div class="col-md-4">
                                <div class="orange-title">
                                    Clock-In Mode
                                </div>
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Clock-In Mode</mat-label>
                                    <mat-select formControlName="clockin_mode" placeholder="select clock-in mode">
                                        <mat-option value="Location">Location </mat-option>
                                        <mat-option value="Location with PIN">Location with PIN</mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>
                        
                        <hr>

                        <div class="row g-2">
                            <div class="orange-title">
                                Today's All Activities
                            </div>
                            <div class="row">
                                <div class="col">
                                    <ng-container *ngFor="let activity of allActivityList; let i = index">
                                        <!-- <mat-checkbox color="primary" [checked]="isChecked(activity)">
                                    {{activity}}
                              </mat-checkbox> -->

                                        <mat-checkbox color="primary" [checked]="activity.isChecked"
                                            (change)="onChangeDemo($event , i)">
                                            {{activity.activityName}}
                                        </mat-checkbox>

                                    </ng-container>

                                    <!-- <mat-form-field>
                                  <mat-select #selectElement  formControlName="allActivity" (selectionChange)="onChangeDemo($event)" multiple="true">
                                    <mat-option [selected]="activity.isChecked"  [value]="activity.activityName" *ngFor="let activity of allActivityList"> {{activity.activityName}}</mat-option>
                                    
                                  </mat-select>
                                </mat-form-field> -->


                                </div>
                            </div>
                        </div>

                        <div class="row g-2 mt-2">
                            <div class="orange-title">
                                Calendar View
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field appearance="outline" floatLabel="always">
                                        <mat-label>Calendar view</mat-label>
                                        <mat-select formControlName="calendarView" placeholder="select calendar view">
                                            <mat-option value="Next seven days">Next seven days</mat-option>
                                            <mat-option value="Start of week">Start of week</mat-option>

                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8"
                                    *ngIf="GeneralSetting_Form.controls.calendarView.value === 'Start of week'">
                                    <mat-radio-group class="me-2" formControlName="startOfWeek" name="radioButtons">
                                        <mat-radio-button id="Mon" value="Mon">Mon</mat-radio-button>
                                        <mat-radio-button id="Tue" value="Tue">Tue</mat-radio-button>
                                        <mat-radio-button id="Wed" value="Wed">Wed</mat-radio-button>
                                        <mat-radio-button id="Thu" value="Thu">Thu</mat-radio-button>
                                        <mat-radio-button id="Fri" value="Fri">Fri</mat-radio-button>
                                        <mat-radio-button id="Sat" value="Sat">Sat</mat-radio-button>
                                        <mat-radio-button id="Sun" value="Sun">Sun</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                        </div>
                        <hr>

                        <div class="row g-2">
                            <div class="orange-title">
                                User Limitations
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="assign">
                                        <span class="pe-5">Ignore User Work Preference </span>
                                        <mat-slide-toggle class="ms-4" color="primary" formControlName="ignore_user_work_preference" 
                                        (change)="switchRadioToggle(_systemSettingsSVC.settings[0].AdministratorSetting[47], $event)"></mat-slide-toggle>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="assign">
                                        <span class="pe-5">Add a Clock-In button to the user's schedule </span>
                                        <mat-slide-toggle class="ms-4" color="primary" formControlName="button_show_to_schedule_CheckIn" (change)="addCheckInButtonForSchedule(_systemSettingsSVC.settings[0].AdministratorSetting[44], $event)"></mat-slide-toggle>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="assign">
                                        <span class="pe-5">Allow Multiple Clock-Ins </span>
                                        <mat-slide-toggle class="ms-4" color="primary" formControlName="allow_multiple_clock_ins" (change)="switchRadioToggle(_systemSettingsSVC.settings[0].AdministratorSetting[49], $event)"></mat-slide-toggle>
                                    </div>
                                </div>

                                <ng-template class="assign"
                                 [ngIf]="_systemSettingsSVC.settings && _systemSettingsSVC.settings[0].AdministratorSetting[21].globalSettingName == 'IsDrivingTime'">
                                <div class="col-12 mb-3">
                                    <div class="assign">
                                        <span class="pe-5">  {{ 'setting.labels.Show Drive Time' | translate}}</span>
                                        <mat-slide-toggle class="ms-4" color="primary"
                                        [ngModel]="(_systemSettingsSVC.settings[0].AdministratorSetting[21].localSettingValue != null?_systemSettingsSVC.settings[0].AdministratorSetting[21].localSettingValue:_systemSettingsSVC.settings[0].AdministratorSetting[21].globalSettingValue) === 'true'"
                                        formControlName="driveTime" [checked]="driveTimeShow"></mat-slide-toggle>
                                    </div>
                                </div>
                                </ng-template>
 
                                <div class="col-6">
                                    <!-- <mat-checkbox></mat-checkbox> -->
                                    <span>User can't reject a shift less than </span>
                                </div>

                                <div class="col-6">
                                    <div class="d-flex">
                                        <input type="text" class="form-control" formControlName="UserCanNotRejectShift">
                                        &nbsp;
                                        <!-- <select class="form-select">
                                            <option value="Days" selected>Days</option>
                                        </select> -->
                                        <span>Hours</span>
                                        &nbsp;
                                        <span>Before its starts</span>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-6">
                                     <span> User can't un-claim a shift less than</span>
                                </div>

                                <div class="col-6">
                                    <div class="d-flex">
                                        <input type="text" class="form-control"
                                            formControlName="UserCanNotUnClaimShift">
                                        &nbsp;
                                        <select class="form-select">
                                            <option value="Days" selected>Days</option>
                                        </select>
                                        &nbsp;
                                        <span>Before its starts</span>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row">
                                <div class="col-6">
                                    <!-- <mat-checkbox></mat-checkbox> -->
                                    <span>User can only clock-in to a shift up to </span>
                                </div>

                                <div class="col-6">
                                    <div class="d-flex">
                                        <input type="text" class="form-control" formControlName="clockInbefore">
                                        &nbsp;
                                        <span>Minutes</span>
                                        <!-- <select class="form-select">
                                            <option value="Minutes" selected>Minutes</option>
                                        </select> -->
                                        &nbsp;
                                        <span>Before its starts</span>
                                    </div>
                                </div>
                            </div>

                            <!-- comment Swap Allowed content as per Ajit Sir -->

                            <div class="row mt-3">

                                <div class="col-12 mb-3">
                                    <div class="assign">
                                        <span class="pe-5">Swap Allowed </span>
                                        <mat-slide-toggle class="ms-4" color="primary" formControlName="enable_notifications_for_shift_swapping_based_on_skill" (change)="swapAllowBasedOnSkillChange(_systemSettingsSVC.settings[0].AdministratorSetting[45], $event)"></mat-slide-toggle>
                                    </div>
                                </div>

                                <div class="col-12 mb-3" *ngIf="GeneralSetting_Form.controls['enable_notifications_for_shift_swapping_based_on_skill']?.value">
                                    <div class="assign">

                                        <mat-radio-button id="Swapping_based_on_the_Skills" class="me-3" value="Skill" [checked]="((_systemSettingsSVC.settings[0].AdministratorSetting[46].localSettingValue != null 
                                        && _systemSettingsSVC.settings[0].AdministratorSetting[46].localSettingValue === 'Skill') || (_systemSettingsSVC.settings[0].AdministratorSetting[46].globalSettingValue != null && _systemSettingsSVC.settings[0].AdministratorSetting[46].globalSettingValue === 'Skill')) ? true : false" (click)="swappingBasedOn(_systemSettingsSVC.settings[0].AdministratorSetting[46], 'Skill')">Swapping based on the Skills</mat-radio-button>

                                        <mat-radio-button id="Swapping_based_on_the_Distribution_List" value="Distribution List" [checked]="((_systemSettingsSVC.settings[0].AdministratorSetting[46].localSettingValue != null && _systemSettingsSVC.settings[0].AdministratorSetting[46].localSettingValue === 'Distortion List') || (_systemSettingsSVC.settings[0].AdministratorSetting[46].globalSettingValue != null && _systemSettingsSVC.settings[0].AdministratorSetting[46].globalSettingValue === 'Distortion List')) ? true : false" (click)="swappingBasedOn(_systemSettingsSVC.settings[0].AdministratorSetting[46], 'Distortion List')">Swapping based on the Distribution List</mat-radio-button>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <hr>

                        <div class="row g-2">
                            <div class="orange-title">
                                Scheduler Limitations
                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <!-- <mat-checkbox></mat-checkbox> -->
                                    <span>
                                        Max work hours per user per week
                                    </span>
                                </div>

                                <div class="col-5">
                                    <div class="d-flex">
                                        <input type="text" class="form-control"
                                            formControlName="MaxWorkHoursPerUserPerWeek">
                                        &nbsp;<span>Hours</span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <!-- <mat-checkbox></mat-checkbox> -->
                                    <span>
                                        Max number of shifts per user per week
                                    </span>
                                </div>

                                <div class="col-5">
                                    <div class="d-flex">
                                        <input type="text" class="form-control"
                                            formControlName="MaxNumberOfShiftsPerUserPerWeek">
                                        &nbsp;<span>Shifts</span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <!-- <mat-checkbox></mat-checkbox> -->
                                    <span>
                                        Max work hours per user per day
                                    </span>
                                </div>

                                <div class="col-5">
                                    <div class="d-flex">
                                        <input type="text" class="form-control"
                                            formControlName="MaxWorkHoursPerUserPerDay">
                                        &nbsp;<span>Hours</span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <!-- <mat-checkbox></mat-checkbox> -->
                                    <span>
                                        Max number of shifts per user per day
                                    </span>
                                </div>

                                <div class="col-5">
                                    <div class="d-flex">
                                        <input type="text" class="form-control"
                                            formControlName="MaxNumberOfShiftsPerUserPerDay">
                                        &nbsp;<span>Shifts</span>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>


                </div>
            </div>
        </form>
    </div>
</div>