<div class="w-100">
  <!-- <mat-tab-group dynamicHeight class="tab-button-auto-width" (selectedTabChange)="tabChanged($event)">

      <mat-tab label="Pending">
        </mat-tab>

      <mat-tab label="Processed">
        </mat-tab>

      <mat-tab label="Expired">
        </mat-tab>

    </mat-tab-group>  -->
    
 
<div class="w-100">
  <div class="col-md-12">
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab position-relative">

      <ng-container matColumnDef="staffName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Staff</th>
          <td mat-cell *matCellDef="let element" class="text-nowrap">
            <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
          </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Customer</th>
        <td mat-cell *matCellDef="let element" class="text-nowrap">
          {{element.customerName | capitalize}}
        </td>
      </ng-container>

      <ng-container matColumnDef="siteName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="commentWidth">{{ 'main.labels.Job Site' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
          <span title="{{element.siteName}}">{{element.siteName}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="jobType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Type</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.jobType}}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Start</th>
        <td mat-cell *matCellDef="let element" class="text-nowrap">
          {{element.startTime}}
        </td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="commentWidth">Reason</th>
        <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
          <span title="{{element.reson}}">{{element.reson}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="adminComment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Admin Comment</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.adminComment}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">{{rendered_componentFor?.toLowerCase() === 'pending' ? 'Actions' : 'Status'}}</th>
        <td mat-cell *matCellDef="let element" class="action">

          <ng-container *ngIf="rendered_componentFor?.toLowerCase() === 'processed'">
            <span class="badge bg-success" *ngIf="element.cancelStatus.toLowerCase() === 'approved'">{{element.cancelStatus | capitalize}}</span>
          <span class="badge bg-danger" *ngIf="element.cancelStatus.toLowerCase() === 'rejected'">{{element.cancelStatus | capitalize}}</span>
          </ng-container>

          <ng-container *ngIf="rendered_componentFor?.toLowerCase() === 'pending'">
            <a href="" class="action-btn btn dropdown-toggle dropdown-toggle-no-caret" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z" fill="#565658"/>
                <path d="M11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.89543 7.89543 0 9 0C10.1046 0 11 0.89543 11 2Z" fill="#565658"/>
                <path d="M18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2C14 0.89543 14.8954 0 16 0C17.1046 0 18 0.89543 18 2Z" fill="#565658"/>
                </svg>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <!--  <ng-container *ngIf="element?.endDate && element.isExpired; else disabledBtn"> -->
              <ng-container *ngIf="element?.endDate && element.isExpired; else disabledBtn">
                <a class="dropdown-item disabled" href="javascript:void(0)"><img src="assets/images/tick-icon.svg" class="me-2">Approve</a>
                <a class="dropdown-item disabled" href="javascript:void(0)"><img src="assets/images/cross-icon.svg" class="me-2">Reject</a>                
              </ng-container>
              <!-- <ng-template #disabledBtn> -->
                <a class="dropdown-item" href="javascript:void(0)" (click)="approveJobRequest(element)"><img src="assets/images/tick-icon.svg" class="me-2">Approve</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="rejectJobRequest(element)"><img src="assets/images/cross-icon.svg" class="me-2">Reject</a>
              <!-- </ng-template> -->
            </div>
          </ng-container>         

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!service.common.progressLoader && !service.common.infiniteLoader) && jobCancellationRequestData.length === 0" class="p-3 noList text-danger">
      No records found
    </div>
    </div>

    <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>
    
  </div>
</div>

 
</div>

<ng-template #assignDialogRef let-data>
  <div class="col-md-12">
  <div class="row  ">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Assign / Notify Job</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" id="closeAssignDialog" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <!-- <div class="col-md-12">
      <h2>{{data}}</h2>
    </div> -->
    <div class="col-lg-12 text-end">
      <button class="me-2" mat-button matDialogClose>Cancel</button>
      <button mat-raised-button color="primary" class="me-2" (click)="openNotifyPopUp(data.appointmentsCode, data.appointmentsProceduresCode, service.common.convertDateFormat_dd_mm_yyyy_to_yyyy_dd_mm(data.startTime))">Notify</button>
      <button mat-raised-button color="primary" (click)="openAssignPopUp(data.appointmentsCode, data.appointmentsProceduresCode, service.common.convertDateFormat_dd_mm_yyyy_to_yyyy_dd_mm(data.startTime))">Assign</button>  
    </div>
  </div>
  </div>
</ng-template>