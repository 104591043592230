import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  scheduleForm = {
    "daysList": [
      0
    ],
    "durationHours": 0,
    "durationMinute": 0,
    "endDate": "",
    "isDateOfMonth": false,
    "jobName": "",
    "notes": "",
    "address": "",
    "organizationCode": "",
    "patientCode": "",
    "isActive": true,
    "mobileCountryCode": "",
    "mobileNumber": "",
    "patientAddress": "",
    "patientCity": "",
    "patientCountry": "",
    "patientEmail": "",
    "patientLatitude": "",
    "patientLongitude": "",
    "patientName": "",
    "patientState": "",
    "homeCountryCode": "",
    "homeNumber": "",
    "zipCode": "",
    "organizationBaseProcedureCodeList": [],
    "repeatType": "",
    "repeatValue": 0,
    "startDate": "",
    "startTime": "",
    "additionalCountryCode": "",
    "additionalNumber": "",
    'preferredPhoneType': '',
    'isDateFlexible': false,
    "patientDetail": {
      "isActive": true,
      "mobileCountryCode": "",
      "mobileNumber": "",
      "organizationCode": "",
      "patientAddress": "",
      "patientCity": "",
      "patientCode": "",
      "patientCountry": "",
      "patientEmail": "",
      "patientLatitude": "",
      "patientLongitude": "",
      "patientName": "",
      "patientState": "",
      "phoneCountryCode": "",
      "phoneNumber": "",
      "zipCode": "",
      "patientApartmentNo": "",
      "preferredNumber":"",
      "preferredCountryCode":""
    },
  };

  // "patientDetail": {
  //   "isActive": true,
  //   "mobileCountryCode": "",
  //   "mobileNumber": "",
  //   "organizationCode": "",
  //   "patientAddress": "",
  //   "patientCity": "",
  //   "patientCode": "",
  //   "patientCountry": "",
  //   "patientEmail": "",
  //   "patientLatitude": "",
  //   "patientLongitude": "",
  //   "patientName": "",
  //   "patientState": "",
  //   "phoneCountryCode": "",
  //   "phoneNumber": "",
  //   "zipCode": ""
  // },
  totalRecords = 0;
  processedRecords = 0;

  constructor(
    public http: HttpClient,
    public service: ApiRootService,
    public session: SessionStorageService,
    public common: CommonService
  ) { }

  /* Name:Shailesh
      use: getting all appointments by organization code with filter page*/
  getAllJobsByOrganizationCodeWithFilterByPage(code, status, startDt, endDt, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllJobsByOrganizationCodeWithFilterByPage/' + code + '/' + status + '/' + startDt + '/' + endDt + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: getting clinician appointment duration by apointment duration code */
  getWorkerJobDurationByJobDurationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetWorkerAppointmentDurationByAppointmentDurationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: getting all procedures by organization code */
  getAllProcedureByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: getting patient detail by organization code */
  getPaitents(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByOrganizationCode/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: getting patient detail by patient code */
  getPatientDetailByCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByPatientCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for updating job duration by job duration code*/
  updateJobDurationByJobDurationCode(code, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateAppointmentDurationByAppointmentDurationCode/' + code, formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: getting appointment detail of clinician by organization code */
  getAppointmentDetailOfTechnicianByOrganizationCode(orgCode, status, startDt, endDt, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAppointmentDetailOfTechnicianByOrganizationCodeForTest/' + orgCode + '/' + status + '/' + startDt + '/' + endDt).subscribe((data) => {
      callback(data);
    })
  }

 /* Name:Shailesh
      use: getting all jobs by organization code with filter date time filter*/
  getAllJobsByOrganizationCodeWithFilterDateTime(orgCode, status, procedure, startDt, endDt, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v3/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTime/' + orgCode + '/' + status + '/' + startDt + '/' + endDt + '/' + procedure).subscribe((data) => {
      callback(data);
    })
  }

 /* Name:Vivek Chauhan
      use: getting Single jobs by organization code with appointmentsCode and appointmentsProceduresCode*/
      getJobsFullDetailsByAppointmentsProceduresCode(orgCode, appointmentsCode, appointmentsProceduresCode, startDt, callback) {
        return this.http.get(this.service.getHealthCareUrl() + '/getJobsFullDetailsByAppointmentsProceduresCode/' + orgCode + '/' + appointmentsCode + '/' + appointmentsProceduresCode + '/' + startDt).subscribe((data) => {
          callback(data);
        })
      }

   /* Name:Shailesh
      use: getting jobs assign to worker by job code and job duration code */
  getJobsAssignToWorkerByJobsCodeAndJobDurationCode(jobcode, jobdurationcode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getJobsAssignToWorkerByJobsCodeAndJobDurationCode/' + jobcode + '/' + jobdurationcode).subscribe((data) => {
      callback(data);
    })
  }


 /* Name:Shailesh
      use: to change/cancel assign jobs by job duration code */
  assignToWorkercancelByJobDurationCode(jobdurationcode, data, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminAssignAppointmentToWorkerCancelByAppointmentsDurationCode/' + jobdurationcode, data).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: getting full job detail by job duration code*/
  getFullJobDetailsByJobDurationCode(jobdurationcode, callback) {

    return this.http.get(this.service.getHealthCareUrl() + '/adminGetWorkerAppointmentDurationByAppointmentDurationCode/' + jobdurationcode).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: for assigning jobs by optaplanner with org code */
  adminAssignJobsByOptaplanner(code, startDate, endDate, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminAssignAppointmentByOptaplanner/' + code + '/' + startDate + '/' + endDate).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: getting appointment tread processing by code */
  getAppointmentThreadProcessingByCode(code,callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/getAppointmentThreadProcessingByCode/'+code).subscribe((data)=>{
      //callback(data);
      this.getresponseData(data,callback,code);
    })
  }

   /* Name:Shailesh
      use: getting appointment thread processing by code */
  getresponseData(response,callback,code){
    if(response.responsecode == 200){
       //console.log(response);
      this.totalRecords = response.data.totalRecord;
      this.processedRecords = response.data.processRecord;

      this.common.progressText ='Processing '+ this.processedRecords + ' of ' + this.totalRecords + ' appointments';
      //console.log(this.common.progressText);
      if(response.data.totalRecord == response.data.processRecord){
        callback(response);
      } else {
        this.getAppointmentThreadProcessingByCode(code,callback);
      }
    }
  }

 /* Name:Shailesh
      use: getting appointment detail of Staff by organization code and by page*/
  adminGetAppointmentDetailOfTechnicianByOrganizationCodeForTestByPage(orgCode, status, startDt, endDt, technicianName, procedureCode, page, limit, callback) {
  
   if(this.session.get('user_type') === 'Customer'){
      /// for Customer Admin
      //  this.session.get('userCode')   == CustomerAdminCode
      return this.http.get(this.service.getHealthCareUrl() + '/adminGetAppointmentDetailOfTechnicianByOrganizationCodeWithCustomerFilterByPage/' + orgCode + '/' + status + '/' + startDt + '/' + endDt + '/' + technicianName + '/' + procedureCode + '/' + this.session.get('userCode') + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      })
    }
    else{
      return this.http.get(this.service.getHealthCareUrl() + '/adminGetAppointmentDetailOfTechnicianByOrganizationCodeWithFilterByPage/' + orgCode + '/' + status + '/' + startDt + '/' + endDt + '/' + technicianName + '/' + procedureCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      })
    }

  }

  /* Name:Vivek Chauhan
     use: getting appointment detail of Job by organization code and by page*/
  adminGetAppointmentDetailOfJobByOrganizationCodeForTestByPage(orgCode, status, startDt, endDt, technicianName, procedureCode, page, limit, callback) {

    if(this.session.get('user_type') === 'Customer'){
      /// for Customer Admin
       //  this.session.get('userCode')   == CustomerAdminCode

      //  /staffingservice/v2/adminGetAllJobsByOrganizationCodeWithFilterForCustomerByPage/{organizationCode}/{status}/{startDT}/{endDT}/{customerCode}/{orgBasedProcedureCodeList}

      // /staffingservice/v2/adminGetAllJobsByOrganizationCodeWithFilterByPage/{organizationCode}/all/{startDT}/{endDT}/all/all?page=0&limit=10

      return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAllJobsByOrganizationCodeWithFilterForCustomerByPage/' + orgCode + '/'+ 'all' + '/' + startDt + '/' + endDt + '/' + this.session.get('logedIn_CustomerCode') + '/'+ 'all' + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      })
    }
    else{
      return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAllJobsByOrganizationCodeWithFilterByPage/' + orgCode + '/' + 'all' + '/' + startDt + '/' + endDt + '/' + technicianName + '/' + procedureCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      })
    }
    
  }

  // assignToWorkerAcceptedByJobDurationCode(jobDurationcode, formData, callback) {
  //   return this.http.put(this.service.getHealthCareUrl() + '/assignToWorkerAcceptedByJobDurationCode/' + jobDurationcode, formData).subscribe((data) => {
  //     callback(data);
  //   }, (error) => {
  //     callback({ responsecode: 500, message: this.common.apiStopError });
  //   })
  // } adminMobileAssignAppointmentToWorkerAcceptedByAppointmentsDurationCode

  /* Name:Shailesh
      use: to assign appointment to clinician accepted by job duration code */
  assignToWorkerAcceptedByJobDurationCode(jobDurationcode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminMobileAssignAppointmentToWorkerAcceptedByAppointmentsDurationCode/' + jobDurationcode, formData).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    })
  }

  /* Name:Shailesh
      use: getting route by technician code and date */
  mobileGetRouteByTechnicianCodeAndDate(code, date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileGetAllRouteByTechnicianCodeAndDate/' + code + '/' + date+'/'+this.session.get('orgCode')).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: getting sitemap detail by organization code */
  adminGetSitemapDetailsByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetSitemapDetailsByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: update patient recurring note by patient code */
  updatePatientRecurringNotesByPatientCode(code,formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updatePatientRecurringNotesByPatientCode/' + code , formData).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: assigning appointment by optaplanner*/
  adminAssignAppointmentByOptaplanner(code, startDate, endDate, appDurationList, callback){
    return this.http.get(this.service.getHealthCareUrl()+'/adminAssignAppointmentByOptaplanner/'+code+'/'+startDate+'/'+endDate+'/'+appDurationList).subscribe((data)=>{
      callback(data);
    })
  }

  getSettingNameByName(settingName:string, orgCode:string){
    return this.http.get(this.service.getHealthCareUrl()+'/getSettingNameByName/'+settingName+'/'+orgCode)
  }

  getAppoinmentWithDateTime(orgCode, status, orgBasedProcedureCodeList, startDt, endDt, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTimeForJobView/' + orgCode + '/' + status + '/' + startDt + '/' + endDt + '/' + orgBasedProcedureCodeList).subscribe((data) => {
      callback(data);
    })
  }

}
