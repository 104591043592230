<div class="w-100">
  <mat-tab-group
    dynamicHeight
    class="tab-button-auto-width"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Skills">
      <ng-container *ngIf="tabAtciveFor === 'Skills'">
        <div class="w-100 mt-3">
          <skill-list></skill-list>
        </div>
      </ng-container>
    </mat-tab>

    <mat-tab label="Licencing">
      <ng-container *ngIf="tabAtciveFor === 'Licencing'">    
        <div class="w-100 mt-3">      
          <app-licence></app-licence>
        </div>
      </ng-container>
    </mat-tab>
    
    <mat-tab label="Certifications">
      <ng-container *ngIf="tabAtciveFor === 'Certifications'">
        <div class="w-100 mt-3">
          <app-certification></app-certification>
        </div>        
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
