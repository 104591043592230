<div class="w-100 create-appointment">
  <div class="col-md-12">

    <div class="row mb-3">
      <div class="col-md-6">
        <h4 class="mb-0">{{ 'Jobs.Create Jobs.labels.Update Job' | translate }} </h4>
      </div>
      <div class="col-md-6 text-end">
        <div class="row justify-content-end">
          <!-- <button type="button" (click)="canelPopup()" class="w-auto me-2" mat-raised-button>{{ 'Technicians.labels.Cancel'
            | translate }}</button>
          <button type="button" (click)="onSubmit()" class="w-auto me-2" mat-raised-button color="primary">{{ 'Jobs.Create
          Jobs.labels.Save' | translate }}</button> -->
          <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
        </div>
      </div>
    </div>

    <div class="w-100 popup-viewport-body">

    <form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()">
      <div class="row mb-2 ms-0 me-0">
      
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-11 m-0">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
                <mat-label>{{ 'Jobs.Create Jobs.labels.Job location' | translate }}</mat-label>
                <input type="text" readonly="true" matInput autoClose [matAutocomplete]="auto"
                  placeholder="" formControlName="patientName">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.patientName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-1 text-start align-self-center">
              <mat-icon class="cursor-pointer">add_circle</mat-icon>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <mat-label class="custom-label">{{ 'Jobs.Create Jobs.labels.Job Type' | translate }}</mat-label>
            <angular2-multiselect [data]="dropdownList" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedItems" [settings]="dropdownSettings" formControlName="procedureCode">
              <c-badge>
                <ng-template let-item="item">
                  <img [src]="item.image" class="img-filter" />
                  <label style="margin: 0px;">{{item.itemName}}</label>
                </ng-template>
              </c-badge>
            </angular2-multiselect>
          
        </div>
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" floatLabel="always">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Address' | translate }}</mat-label>
            <textarea readonly="true" matInput placeholder="" ngx-google-places-autocomplete [options]='options'
              #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" formControlName="address"
              [(ngModel)]="service.scheduleForm.address"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-4">
          <div class="w-100 row">
            <div class="col-md-5 disabledCountry">
              <app-country-code [disable]="true" (setCountryCode)="getCountryCode($event,'phoneCountryCode')" [setCode]="countrycodep" [placeholder]="'Home No'">
              </app-country-code>
            </div>
            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-11 no-form p-0">
                <mat-label>Home Number</mat-label>
                <input readonly="true" matInput placeholder="" formControlName="phoneNumber"
                  [(ngModel)]="service.scheduleForm.homeNumber">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="w-100 row">
            <div class="col-md-5 disabledCountry">
              <app-country-code [disable]="true" (setCountryCode)="getCountryCode($event,'mobileCountryCode')" [setCode]="countrycodem" [placeholder]="'Mobile No'">
              </app-country-code>
            </div>
            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-11 no-form p-0">
                <mat-label>Mobile Number</mat-label>
                <input readonly="true" matInput placeholder="" formControlName="mobileNumber"
                  [(ngModel)]="service.scheduleForm.mobileNumber">
              </mat-form-field>

            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="w-100 row"> 
            <div class="col-md-5 disabledCountry ">
              <app-country-code  [disable]="true" (setCountryCode)="getCountryCode($event,'preferredCountryCode')"
                [setCode]="preferredCountryCode" [placeholder]="'Additional Contact No'"></app-country-code>
              <span class="help-block" *ngIf="scheduleForm.get('preferredCountryCode').errors?.required
                && (!submitted || scheduleForm.get('preferredCountryCode').touched)">
                {{ 'Customers.validators.Country code is required' | translate }}</span>
            </div>
    
    
            <div class="col-md-7">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width p-0 no-form">
                <mat-label>Additional Number</mat-label>
                <input autocomplete="off" readonly="true"  matInput placeholder="" formControlName="preferredNumber"
                  [(ngModel)]="service.scheduleForm.additionalNumber">
              </mat-form-field>
    
            </div>
          </div>
        </div>

        <mat-radio-group class="pref-no" aria-label="Select an option" class="ispreferred-radio" formControlName="isPeferredNumber" [(ngModel)]="service.scheduleForm.preferredPhoneType">
          <mat-radio-button value="homeNumber" class="pref-no">Preferred No</mat-radio-button>
          <mat-radio-button value="mobileNumber" class="pref-no">Preferred No</mat-radio-button>
          <mat-radio-button value="additionalNumber" class="pref-no">Preferred No</mat-radio-button>
        </mat-radio-group>
        
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Start Date' | translate }}</mat-label>
            <input [min]="today" matInput #nonworkingday formControlName="startDate" [matDatepicker]="picker2"
              (focus)="picker2.open()" placeholder="">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 (closed)="nonworkingday.blur()"></mat-datepicker>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('startDate').valid && (!submitted || scheduleForm.get('startDate').touched)">
            {{ 'Jobs.Create Jobs.Validators.Start date is required' | translate }}</span>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>"{{ 'Jobs.Create Jobs.labels.End Date' | translate }}</mat-label>
            <input [min]="today" matInput #nonworkingday1 formControlName="endDate" [matDatepicker]="picker3"
              (focus)="picker3.open()" placeholder="">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3 (closed)="nonworkingday1.blur()"></mat-datepicker>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('endDate').valid && (!submitted || scheduleForm.get('endDate').touched)">
            {{ 'Jobs.Create Jobs.Validators.End date is required' | translate }}</span>
        </div>
      </div>
      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Start Time' | translate }}</mat-label>
            <mat-select formControlName="startTime" [(ngModel)]="service.scheduleForm.startTime">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchStart($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="">
              <mat-option *ngFor="let start of selectedStartTime" [value]="start.value">
                {{start.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('startTime').valid && (!submitted || scheduleForm.get('startTime').touched)">
            {{ 'Jobs.Create Jobs.Validators.Start time is required' | translate }}</span>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Duration' | translate }}</mat-label>
            <mat-select formControlName="duration" (selectionChange)="getDurationData($event)">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchEnd($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="">
              <mat-option *ngFor="let end of durationSelectDropdown" [value]="end.value">
                {{end.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('duration').valid && (!submitted || scheduleForm.get('duration').touched)">
            {{ 'Jobs.Create Jobs.Validators.Duration is required' | translate }}</span>
        </div>
      </div>

      <div class="row mb-2 ms-0 me-0">
        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'Jobs.Create Jobs.labels.Notes' | translate }}</mat-label>
            <textarea matInput placeholder="" formControlName="notes"
              [(ngModel)]="service.scheduleForm.notes"></textarea>
          </mat-form-field>
          <span class="help-block"
            *ngIf="!scheduleForm.get('notes').valid && (!submitted || scheduleForm.get('notes').touched)">
            {{ 'Jobs.Create Jobs.Validators.If you change the duration you have to mention the reason behind it' | translate }}.</span>
        </div>
      </div>
    </form>

    </div>

    <div class="d-flex offset-md-8 justify-content-end pt-3">
      <button mat-button (click)="canelPopup()" class="cancel-btn-primary">{{ 'Technicians.labels.Cancel' | translate
        }}</button>
      <button class="ms-2 pe-4 ps-4" mat-raised-button color="primary" (click)="onSubmit()">{{ 'Jobs.Create
        Jobs.labels.Save' | translate }}</button>
    </div>

  </div>
</div>