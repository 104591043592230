import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
 
@Injectable()
export class CommonNotesService {

constructor(
public service: ApiRootService,
public http: HttpClient,
public common: CommonService,
) {   }

/* Name: Vivek Chauhan
use: for get all note master list by Org Code*/
getNoteEntityMappingByOrganizationCodeByPage(orgCode, page, limit, search, callback) {
  let searchVal = search && search.trim() ? ('/'+search): '';
  return this.http.get(this.service.getHealthCareUrl() + '/getNoteEntityMappingByOrganizationCodeByPage/' + orgCode + searchVal + '?page=' + page + '&limit=' + limit).subscribe((data) => {
    callback(data);
  });
} 

/* Name: Vivek Chauhan
use: for get all note master list by Org Code*/
getNoteEntityDetailMappingByNoteEntityMappingCode(noteEntityMappingCode, callback) {
  return this.http.get(this.service.getHealthCareUrl() + '/getNoteEntityMappingByNoteEntityMappingCode/' + noteEntityMappingCode).subscribe((data) => {
    callback(data);
  });
} 

/* Name: Vivek Chauhan
use: for get all note master list by user code */
getNoteListDataByUserCode(userCode, callback) {
  return this.http.get(this.service.getHealthCareUrl() + '/getNoteMasterByUserCode/' + userCode).subscribe((data) => {
    callback(data);
  });
} 

/* Name: Vivek Chauhan
use: for Save and Update note */
saveAndUpdateNoteMaster(request_body:any) {
  return this.http.post(this.service.getHealthCareUrl() + '/saveAndUpdateNoteMaster', request_body);
}

/* Name:Vivek Chauhan new API
  use: for delete/remove note */
  softDeleteNoteMasterByNoteMasterCode(noteMasterCode, callback) {
    return this.http.delete(this.service.HEALTHCARE_URL + '/softDeleteNoteMasterByNoteMasterCode/' + noteMasterCode).subscribe((data) => {
      callback(data);
    })
  }

/* Name: Vivek Chauhan
use: for get Job Note Entity Mapping By Entity Name And Key And OrganizationCode */
  getJobNoteEntityMappingByEntityNameAndKeyAndOrganizationCode(orgCode, appointmentsProcedureCode, date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getJobNoteEntityMappingByEntityNameAndKeyAndOrganizationCode/' + orgCode + '/' + appointmentsProcedureCode + '/' + date).subscribe((data) => {
      callback(data);
    });
  } 

  /* Name:Vivek Chauhan new API
  use: for delete/remove Note */
  softDeleteByNoteEntityMappingCode(noteEntityMappingCode, callback) {
    return this.http.delete(this.service.HEALTHCARE_URL + '/softDeleteNoteByNoteEntityMappingCode/' + noteEntityMappingCode).subscribe((data) => {
      callback(data);
    })
  }
 
 
}