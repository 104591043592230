import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class NewAppointmentService {

  constructor(public service: ApiRootService, public common: CommonService,  public http:  HttpClient, public session: SessionStorageService) { }

   /* Name:srinivas
    use: returns status count by technician code and dates. */

  mobileGetStatusCountByTechnicianCodeAndBetweenDates(workerCode,startdt,enddt,status,callback) {
   ////console.log(this.session.get('orgCode'));
   
   return this.http.get(this.service.getHealthCareUrl() + '/v2/mobileGetDashboardByTechnicianCodeAndBetweenDatesFilterNotified/' + workerCode + '/' + startdt + '/' + enddt +'/'+ this.session.get('selected_OrgCode')).subscribe((data) => {
    callback(data);
  }, (error) => {
    callback({ responsecode: 500, message: this.common.apiStopError });
  });
  }

  // /staffingservice/mobileGetNotifiedJobs/{workerCode}/{organizationCode}
  // /staffingservice/mobileGetNotifiedJobs/{workerCode}/{organizationCode}/{fromDate}/{toDate}

 /* Name:srinivas
    use: returns today appointments list by technicians. */

  gettodaysAppoinmentsByworkerCode(workerCode, status, startDt, endDT, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileGetNotifiedJobs/' + workerCode + '/' + this.session.get('selected_OrgCode') + '/' + startDt + '/' + endDT +'?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    });
  }

  mobileGetDashboardByTechnicianCodeAndBetweenDatesFilterNotified(workerCode, startDt, endDT, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/mobileGetDashboardByTechnicianCodeAndBetweenDatesFilterNotified/' + workerCode + '/' + startDt + '/' + endDT +'/'+ this.session.get('selected_OrgCode')).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    });
  }


/* Name:srinivas
    use: Assign appointment to technician. */
  

  assignToWorkerAcceptedByJobDurationCode(jobDurationcode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminMobileAssignAppointmentToWorkerAcceptedByAppointmentsDurationCode/' + jobDurationcode, formData).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    })
  }

  /* Name:Vivek Chauhan
  use: Accept job by Staff. */
  jobAcceptByStaff(request_body) {
    return this.http.post(this.service.getHealthCareUrl() + '/jobAcceptByStaff' , request_body)
  }

  /* Name:Vivek Chauhan
  use: Reject Job with comment by Staff. */
  jobRejectByStaff(request_body) {
    return this.http.post(this.service.getHealthCareUrl() + '/jobRejectByStaff' , request_body)
  }

  /* Name:srinivas
    use: returns all procedure list. */
  assignToWorkerrejectedByJobDurationCode(jobDurationcode,formData,callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminMobileAssignAppointmentToWorkerRejectedByAppointmentsDurationCode/' + jobDurationcode, formData).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    })
  }
}
