<div class="d-flex flex-row justify-content-between w-100 mb-3 logo
  align-items-center">
  <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">{{'Shift.labels.Shift Segment' | translate }}
  </div>
  <div class="col-lg-5 col-md-5 col-sm-12 searchfilter">
    <!-- <input autocomplete="off" matInput
      (keyup)="applyFilter($event.target.value)" type="text"
      class="form-control"
      placeholder="{{'skills.labels.Search Skill' | translate }}">
    <mat-icon class="cursor-pointer">search</mat-icon>    -->
  </div>

  <ng-template [ngIf]="controlsPermissions.addPermission === true">
  <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    <button mat-raised-button color="primary" class="ms-2" (click)="openShiftPopUp()" title="Add Shift">Add</button>
  </div>
  </ng-template>
   
</div>

<mat-progress-bar mode="indeterminate" *ngIf="show_tbl_data_progressbar"></mat-progress-bar>
<div class="w-100 sticky-tbl-th">
  <div class="col-md-12 table-scroll-height"
  infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="100" 
  (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" multiTemplateDataRows>
  
      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Shift.labels.Start Time' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.startTime}}</td>
      </ng-container>

      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Shift.labels.End Time' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.endTime}}</td>
      </ng-container>
    
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">Action</th>
        <td mat-cell *matCellDef="let element" class="action">
          <ng-template [ngIf]="controlsPermissions.editPermission === true">
          <mat-icon class="color-icon cursor-pointer me-3" (click)="updateShift(element)">edit</mat-icon>
        </ng-template>
          <!-- <mat-icon class="color-icon cursor-pointer"  (click)="deleteShift(element)">delete</mat-icon> -->
         
          <ng-template [ngIf]="controlsPermissions.delPermission === true">
          <mat-icon class="color-icon cursor-pointer  {{element.isActive ? 'text-success' : 'text-danger'}}" 
          (click)="active_deactive_shift(element)" [matTooltip]="element.isActive ? 'InActive Shift' : 'Active Shift'" >
            {{element.isActive ? 'check_circle' : 'cancel'}}
          </mat-icon>
          </ng-template>
           
        </td>
      </ng-container>

  <!-- Expandable row content -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element; let i = index" [attr.colspan]="displayedColumns.length">
      <!-- <div class="expanded-element-container"[class.expanded]="element.isExpanded">
        <div class="expanded-element-content">
         {{element | json}}
        </div>
      </div> -->
      
      <ng-template [ngIf]="element.shiftBreakResponseList.length > 0" [ngIfElse]="no_breaks">
      <div class="row mb-1">
        <div class="col-3"><strong><span>Break Type</span></strong></div>
        <div class="col-3"><strong><span>Paid Status</span></strong></div>
        <div class="col-3"><strong><span>Duration</span></strong></div>
      </div>
      <div class="row" *ngFor="let break of element.shiftBreakResponseList ">
        <div class="col-3"><small>{{break.breakType}}</small></div>
        <div class="col-3"><small>{{break.paidStatus}}</small></div>
        <div class="col-3"><small>{{break.durationMin}}&nbsp;min</small></div>
      </div>
    </ng-template>
    <ng-template #no_breaks>
      <span class="text-danger">No Break Added</span>
    </ng-template>
    </td>
  </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleRow(row)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [class.hide-row]="!row.isExpanded">  </tr>
    </table>
 
  </div>
</div>