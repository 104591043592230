import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';
import { NewNotificationService } from '../../shared/services/notification.service';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../../shared/auth.service';
import { Location} from '@angular/common';
import { SiteMaintenanceComponent } from 'src/app/common/unauthorized/site-maintenance/site-maintenance.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class GigFlexInterceptor implements HttpInterceptor {
  constructor(
    public local: SessionStorageService,
    public router: Router,
    public notification: NewNotificationService,
    public auth: AuthService,
    public dialog: MatDialog,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    let authToken = this.local.get('token');
    let orgCode = this.local.get('orgCode');

    request = request.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + authToken,
        'organizationCode': orgCode ? orgCode : ''
      }
    });
    // return next.handle(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }), catchError(async (error: HttpErrorResponse) => {
        //console.log(error);
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        ////console.log(this.router.url);
        if (error.status == 401 && this.router.url !== '/login') {
          this.auth.logout();
          return;
        }
        else if (this.router.url == '/login' && error.status == 401) {
          this.auth.common.progressLoader = false;
          this.auth.common.loginmessage = 'Username or Password Incorrect';
          this.auth.common.loginerrorMessage = true;
          return data;
        } else if(this.router.url == '/login' && error.status == 403){
          this.auth.common.progressLoader = false;
          this.auth.common.loginmessage = error.error.message;
          this.auth.common.loginerrorMessage = true;
          return data;
        }
        else {
          //console.log(error);
          if(error.status === 500){
            let splitUrl = this.router.url.split( '/' )[1];
            let redirectURL = splitUrl + '/site-maintenance';
            let errorURL = error.url;
            let chatApiError = errorURL.split( '/' )[3];
            // console.log('Error_500: ', error);
            if(splitUrl === 'login' || splitUrl === 'staffing-emp-registration' || splitUrl === 'registration' || splitUrl === 'forgot-password' || splitUrl === 'reset-password'){
              const dialogRef = this.dialog.open(SiteMaintenanceComponent, {
                width: '80vw',
                height: 'auto',
                maxHeight: '94vh',
                maxWidth:'80vw',
                disableClose: false,
                data: null
            });
            dialogRef.updatePosition({
              top: '10%'    // Set top position
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result != undefined && result === 'success') {
                // Do nothing
              }
            });
            ;
            } else {
              this.router.navigate([redirectURL]);
            }
          } else {
            this.notification.showError(error.message);
          }
          // return throwError(error);
          return;
        }

      }));
  }
}
