import { CommonService } from './../../../shared/common.service';
import { Component, OnInit, Inject } from '@angular/core';
import { BroadcastMessageService } from './broadcast-message.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss']
})
export class BroadcastMessageComponent implements OnInit {

  bcmForm: FormGroup;
  submitted = true;
  constructor(public service:BroadcastMessageService, public dialogRef: MatDialogRef<BroadcastMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private localStorage: SessionStorageService, public common: CommonService, public translate: TranslateService,) { }

  ngOnInit() {
    this.translate.setDefaultLang(this.localStorage.get('language'));

    this.bcmForm = new FormGroup({
      'msg': new FormControl(null, Validators.required),
    })
  }

  /* Name:Shailesh
      use: to send broadcast message to all clinician and groups */
  sendMsg(){
    this.service.bmformData.message = this.bcmForm.value.msg
    this.service.bmformData.organizationCode = this.localStorage.get('orgCode')
    this.service.bmformData.userCode = this.localStorage.get('userCode')
    this.service.bmformData.userName = this.localStorage.get('username')
    // ////console.log(this.service.bmformData.message)
    // ////console.log(this.service.bmformData.userCode)
    // ////console.log(this.service.bmformData.userName)
    // ////console.log(this.bcmForm)
    if (this.bcmForm.valid) {
      this.submitted = true;
      // this.common.progressLoader = true;
      try {
        this.service.saveBroadcastMessage(this.service.bmformData, (response) => {
        ////console.log(response)
        if(response.responsecode = 200){
          this.common.progressLoader = false;
          this.common.message = response.message
          this.common.successMessage = true;
          this.onNoClick()
        } else {
          this.common.progressLoader = false;
          this.common.message = response.message;
          this.common.errorMessage = true;
          this.onNoClick()
        }
      })
      } catch (error) {
        this.common.progressLoader = false;
        //console.log(error)
      }
      
    } else {
      this.submitted = false;
      this.common.progressLoader = false;
    }  
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
