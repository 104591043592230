import { Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { TechnicianService } from "src/app/dashboards/providers-dashboard/technician/technician.service";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { AssignedDistributionListPopup } from 'src/app/components/assigned-distribution-list-popup/assigned-distribution-list-popup.component';
import { DirectEmailSendComponent } from 'src/app/components/email-template/direct-email-send/direct-email-send.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-card-view-component',
  templateUrl: './card-view-component.component.html',
  styleUrls: ['./card-view-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CardViewComponentComponent implements OnInit {
  tooltipEmailMessages: string[];
  tooltipPhoneMessages: string[];
  @ViewChildren('emailTooltip') emailTooltip!: QueryList<MatTooltip>;
  @ViewChildren('phoneTooltip') phoneTooltip!: QueryList<MatTooltip>;
  dropdownOpen: { [key: string]: boolean } = {};
  // @Input() dataList: any;

  @Input() dataList: any;
  private previousDataList: any;

  ngDoCheck() {
    if(this.previousDataList && this.dataList && (this.dataList.length > this.previousDataList.length)){
      console.log('dataList has changed:', this.dataList.length, this.previousDataList.length);
      this.tooltipEmailMessages = this.dataList.map(() => 'Click to copy');
      this.tooltipPhoneMessages = this.dataList.map(() => 'Click to copy');
    }
    if (this.dataList !== this.previousDataList) {
      // console.log('dataList has changed:', this.dataList);
      this.previousDataList = this.cloneDataList(this.dataList); // You might need a deep clone function here
    }
  }

  private cloneDataList(dataList: any) {
    return JSON.parse(JSON.stringify(dataList)); // This is a simple deep clone, adapt as necessary
  }

  @Input() allRolesList: any;
  baseURL: any;
  
  @Output() emitSelectedDate = new EventEmitter<any>();
  @Output() RoleChangeCardViewEmmit = new EventEmitter<any>();
  @Output() fileUploadEmit = new EventEmitter<any>();
  @Output() EmitFileUploadComplete = new EventEmitter<any>();
  @Output() isStaffListRefresh = new EventEmitter<any>();

  @Output() sendWelcomeEmail_Emitter = new EventEmitter<any>();
  @Output() resetPassword_Emitter = new EventEmitter<any>();
  @Output() addNote_Emitter = new EventEmitter<any>();
  @Output() calendarView_Emitter = new EventEmitter<any>();

  @Input() is_editPermission: boolean = false;
  @Input() is_deletePermission: boolean = false;
  @Input() is_addPermission: boolean = false;

  constructor( public technicianService: TechnicianService, private dialog: MatDialog) { 
    // console.log("CardViewComponentComponent dataList",this.dataList);
  }

  ngOnInit() {    
    // console.log('DataListView', this.dataList);
    this.tooltipEmailMessages = this.dataList.map(() => 'Click to copy');
    this.tooltipPhoneMessages = this.dataList.map(() => 'Click to copy');
    this.baseURL = this.technicianService.service.getSuperAdmin() + "/downloadFile/"
  }

  // ChangeRole(worker: any, role:any){
  //   if(role.roleCode && worker.workerCode){
  //     let roleObj = {
  //       worker: worker,
  //       role: role
  //     }
  //     this.RoleChangeCardViewEmmit.emit(roleObj);
  //   }
  // }
 
  onFileChange(event , selectedEle) {
    const target = event.target;
    if (event.target.files.length > 0) {
      let allowedExtension = ['jpeg', 'jpg', 'png'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
       let index =  allowedExtension.findIndex((ele:any) => ele === fileExtension);
  
       if(index == -1){
        this.technicianService.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') ;
        this.technicianService.common.errorMessage = true;
        event.target.value = '';
        return;
       }
  
       if(event.target.files[0].size >= 2000000)
       {
        this.technicianService.common.message = 'file size should be less then 2MB';
        this.technicianService.common.errorMessage = true;
        event.target.value = '';
        return;
       }
  
         const formData = new FormData();
          formData.append("file", event.target.files[0]);
          this.technicianService.common.progressLoader = true;
          this.technicianService.common.uploadImage(formData, (res) => {
            this.technicianService.common.progressLoader = false;
             if(res.responsecode == 200){
              // res.filename
              this.saveStaffLogo(res.filename , selectedEle)
             }
             else{
              this.technicianService.common.message = "Failed to upload file";
              this.technicianService.common.errorMessage = true;
             }
            });
  
    }
  }
  
  
  saveStaffLogo(filename , worker){
    
    if(filename && worker.workerCode)
    {
      let JSON = {
        "workerCode": worker.workerCode,
        "workerLogo": filename
      }
      this.technicianService.common.progressLoader = true;
      this.technicianService.saveStaffLogo(JSON).subscribe((response:any) =>{
        this.technicianService.common.progressLoader = false;
        if (response.responsecode == 200) {
          this.technicianService.common.message = response.message;
           this.technicianService.common.successMessage = true;
           this.EmitFileUploadComplete.emit();
          } else {
          this.technicianService.common.message = response.message;
           this.technicianService.common.errorMessage = true;
          }
      })
  
    }
  
  }

  setActiveInactiveStaff(workerRes: any, isActive:boolean){

    // console.log('setActiveInactiveStatus: ', workerRes, isActive);

    if(workerRes?.workerCode){

    let confirmMSG = "Are you sure want to "+ (isActive ? 'Active ' : 'Inactive ') +"staff "+ (workerRes?.name && this.technicianService.common.toTitleCase(workerRes.name)) +" ?" ;

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {     
      
         if(result && result.toLowerCase() === 'ok')
         {
              let data= {
                "isActiveStatus": isActive,
                "userCode": workerRes.workerCode
              }
              // console.log('setActiveInactiveStatus_1: ', data);              
              //  return;
              this.technicianService.common.progressLoader = true;
              this.technicianService.setActiveInactiveStaff(data, (response) => {
                if (response.responsecode == 200) {
                  this.isStaffListRefresh.emit();
                  if(isActive){
                    workerRes.isActive = true;
                    } else {
                      workerRes.isActive = false;
                    } 
                    this.technicianService.common.message = response.message;
                    this.technicianService.common.progressLoader = false;
                    this.technicianService.common.successMessage = true;
                  } else {
                    this.technicianService.common.message = response.message;
                    this.technicianService.common.progressLoader = false;
                    this.technicianService.common.errorMessage = true;
                }
              })
         }
    });
    }
}


sendWelcomeEmail(workerCode:string)
{
  this.sendWelcomeEmail_Emitter.emit(workerCode);
}

changePassword(workerCode:string)
{
  this.resetPassword_Emitter.emit(workerCode);
}

addNote(selectedCardData:any, name:any)
{
this.addNote_Emitter.emit({selectedCardData, name});
}

toggleDropdown(workerCode: string, event: MouseEvent) {
  event.stopPropagation(); // Prevents the card click event
  this.dropdownOpen[workerCode] = !this.dropdownOpen[workerCode];
  console.log("this.dropdownOpen",this.dropdownOpen)
}

copyClipboardText(text: string, index: number, toolTipName = null) {
  // console.log('toolTipName: ', toolTipName);
  // this.tooltipMessages = 'Click to copy';
  // Copy text logic
  navigator.clipboard.writeText(text).then(() => {
    // Update the tooltip message for the specific item    
    // Show the tooltip
    if(toolTipName === 'email'){
      this.tooltipEmailMessages[index] = 'Copied!';
      this.emailTooltip.toArray()[index].show();
    }
    if(toolTipName === 'phone'){
      this.tooltipPhoneMessages[index] = 'Copied!';
      this.phoneTooltip.toArray()[index].show();
    }    
    // Optionally reset tooltip message after some time
    setTimeout(() => {      
      if(toolTipName === 'email'){
        this.tooltipEmailMessages[index] = 'Click to copy';
        this.emailTooltip.toArray()[index].hide();
      }
      if(toolTipName === 'phone'){
        this.tooltipPhoneMessages[index] = 'Click to copy';
        this.phoneTooltip.toArray()[index].hide();
      }       
    }, 2000); // Hide after 2 seconds
  });
}

ChangeRole(worker: any, role:any){
  event.stopPropagation(); // Prevents the card click event
  if(role.roleCode && worker.workerCode){
    let roleObj = {
      worker: worker,
      role: role
    }
    this.RoleChangeCardViewEmmit.emit(roleObj);
    this.dropdownOpen[worker.workerCode] = !this.dropdownOpen[worker.workerCode];
    console.log("this.dropdownOpen",this.dropdownOpen)
  }
}


openStaffCalendarView(selectedCardData:any)
{
 this.calendarView_Emitter.emit(selectedCardData) ;
}

openAssignedDistributionListPopup(workerCode: any){
  if(workerCode){
    const dialogRef = this.dialog.open(AssignedDistributionListPopup, { 
      width: '60%', 
      disableClose: true,
      data: { workerCode: workerCode}
     });

    dialogRef.afterClosed().subscribe(result => {
    if (result){
      console.log('openAddDistribution:', result);
    }
    });
  }
}

  /* Name:Vivek Chauhan
  use: for open Sent template on emails popup */
  sendTemplate(staff = null): void {
    // console.log('Staff: ', staff);
    // return;
    if(staff?.email){
      const dialogRef = this.dialog.open(DirectEmailSendComponent, {
        width: '850px',
        height: 'auto',
        maxHeight: '94vh',
        maxWidth:'850px',
        disableClose: true,
        data: { selectedEmail : staff.email, isSend: true}
      });
  
      dialogRef.updatePosition({
        top: '1%'    // Set top position
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result === 'success') {
          // Do Nothing
        }
      });
    }
  }

  // copyText() {
  //   // Copy text logic (replace with your actual text copy logic)
  //   const textToCopy = 'Your text here';
  //   navigator.clipboard.writeText(textToCopy).then(() => {
  //     // Show "Copied!" tooltip
  //     this.copyTooltip.message = 'Copied!';
  //     this.copyTooltip.show();

  //     // Optionally hide tooltip after some time
  //     setTimeout(() => {
  //       this.copyTooltip.hide();
  //     }, 2000); // Hide after 2 seconds
  //   });
  // }

}
