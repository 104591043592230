import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DistributionService } from '../distribution-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'add-distribution',
  templateUrl: './add-distribution.component.html',
  styleUrls: ['./add-distribution.component.scss']
})
export class AddDistributionComponent implements OnInit {

  reqForm: FormGroup;
  options = [];
  popupTitle: string = 'Add Distribution';
  submitted = true;
  dropdownSettings = {};
  selectedCodeList: any[] = [];
  allStaffMemberList: any[] = [];
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddDistributionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: DistributionService,private translate: TranslateService) {
      this.dropdownSettings = {
        singleSelection: false,
        text: "Select User",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: "select_user_section",
        // badgeShowLimit: 5,
        maxHeight:197,
        autoPosition: true       
      };
      this.getAllStaffMemberByOrganizationCode();
    }

  ngOnInit() {
    this.reqForm = new FormGroup({
      "organizationCode": new FormControl(null),
      "distributionName": new FormControl(null, [Validators.required]),
      "distributionCode": new FormControl(null),
      "userCodeList": new FormControl([]),
      "noteRequest": new FormControl(null)
    });
    }


  /* Name:Vivek Chauhan
      use: for searching distribution name */
  private _filter(value, field): string[] {
    //////console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        this.reqForm.controls['distributionCode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        this.reqForm.controls['distributionCode'].setValue(value.value);
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  displayFn(option): string | undefined {
    return option ? option.name : undefined;
  }

  onItemSelect(item: any) {
    // console.log('onItemSelect', item);
    // console.log(this.selectedCodeList);
  }
  OnItemDeSelect(item: any) {
    // //console.log(item);
    // //console.log(this.selectedCodeList);
  }
  onSelectAll(items: any) {
    // //console.log(items);
  }
  onDeSelectAll(items: any) {
    // //console.log(items);
  }

  getDistributionUserListByDistributionCode(distributionCode: any, ) { 
    // //console.log('getDistributionUserListByDistributionCode: ', distributionCode);
    // return;
    this.selectedCodeList = [];
    try {
    //   this.service.getDistributionUserListByDistributionCode(distributionCode, (response) => {
    //     if(response.responsecode === 200){
    //       response.data.forEach((element) => { 
    //         element.itemName = element.certificationName;
    //         element.id = element.certificationCode
    //       });
    //       const myArrayFiltered = response.data && response.data.length > 0 && this.allStaffMemberList && this.allStaffMemberList.length > 0 && this.allStaffMemberList.filter((el) => {
    //         return response.data.some((f) => {
    //           return f.name === el.name;
    //         });
    //       });
    //   this.selectedCodeList = myArrayFiltered && myArrayFiltered?.length > 0 ? myArrayFiltered : [];
    //   // //console.log('myArrayFiltered: ',this.selectedCodeList, myArrayFiltered);
    //     } else {
    //     }
    // });


    this.service.v2_getDistributionUserListByDistributionCode(distributionCode,0,1000).subscribe((response:any) => {
      if(response.responsecode === 200)
      {
        response.data.forEach((element) => 
        { 
          element.itemName = element.name; 
          element.id = element.userCode || element.staffCode
        });
   
    this.selectedCodeList = response.data ;

      }  
  });


    } catch (error) {
      //console.log(error)
    }
  }

  /* Name:Vivek Chauhan
     use: for adding/creating/saving reqForm in organization */
  onSubmit() {
    // //console.log('Form: ', this.reqForm);
    try {
      if (this.reqForm.valid) {
        this.reqForm.controls['noteRequest'].setValue(this.service.common.noteRequest);
        this.reqForm.controls['organizationCode'].setValue(this.service.storage.get('orgCode'));
        let colneUserCodeList = this.reqForm.controls.userCodeList.value && this.reqForm.controls.userCodeList.value.length > 0 ?  JSON.parse(JSON.stringify(this.reqForm.controls.userCodeList.value)) : [];
        const userCodeListReq = colneUserCodeList.map((currentValue) => (currentValue.userCode || currentValue.staffCode));
      
        if(userCodeListReq && userCodeListReq?.length > 0){
        this.reqForm.controls['userCodeList'].setValue(userCodeListReq);
        } else {
          let userCodeListVal = [];
          this.reqForm.controls['userCodeList'].setValue(userCodeListVal);
        }
        // console.log('AddDistributionReq: ', this.reqForm.value);
        // return;
        this.isLoading = true;
        this.submitted = true;
        this.service.createOrUpdate(this.reqForm.value, (response) => {
          this.isLoading = false;
          if (response.responsecode == 200) {
            this.service.common.resetNotesRequestForm();
            this.dialogRef.close('success');            
            this.reqForm.reset();
            this.service.common.successMessage = true;
            this.service.common.message = response.message;
          } else {
            this.submitted = false;
            this.service.common.errorMessage = true;
            this.service.common.message = response.message;
          }
        })
      } 
    } catch (error) {
      //console.log(error)
    }
    
    ////console.log(this.reqForm);

  }

/* Name:Vivek Chauhan
  use: for getting All Staff Member list page*/
  getAllStaffMemberByOrganizationCode() {
        try {
            this.service.common.progressLoader = true;    
            this.service.getAllStaffMemberByOrgCode(
              this.service.storage.get('orgCode'), (response) => {
                if (response.responsecode === 200) {                
                  // //console.log('ResData: ', response.data);
                  response?.data && response.data.length > 0 && response.data.forEach((element) => { element.itemName = element.name; element.id = element.staffCode });
                  this.allStaffMemberList = response?.data;
                  this.service.common.progressLoader = false;
                } else {
                  this.allStaffMemberList = [];
                  this.service.common.progressLoader = false;
                }                   

                /* Name:Vivek Chauhan
                use: for getting distribution by id */

                try {
                  if (this.data && this.data.distributionCode) {
                      this.getDistributionUserListByDistributionCode(this.data.distributionCode);
                        this.popupTitle = 'Edit Distribution';
                        this.reqForm.controls['distributionName'].setValue(this.data.distributionName);
                        this.reqForm.controls['distributionCode'].setValue(this.data.distributionCode);
                        this.service.common.progressLoader = false;
                      } else {
                        this.popupTitle = 'Add Distribution';
                        this.reqForm.reset();
                        this.service.common.progressLoader = false;
                      }
                  
                } catch (error) {
                  //console.log(error)
                }
              });
          } catch (error) {
              //console.log(error)
          }
      }

   /* Name:Vivek Chauhan
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.service.common.resetNotesRequestForm();
    this.reqForm.controls['noteRequest'].setValue(null);
    this.dialogRef.close('true');
  }
}


