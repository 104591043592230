<!-- <p>distribution-list works!</p> -->
<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <!-- <div class="float-start header-name">Email Templates
  </div> -->

  <!-- <div class="col-lg-3 col-md-3 col-sm-12 float-start pe-3">
    
    <mat-form-field appearance="outline" floatLabel="always"  class="filter-tech">
      <mat-label>Search</mat-label>
      <input matInput id="searchFilter" (keyup)="applyFilter($event.target.value)" placeholder="Search" autocomplete="false">
    </mat-form-field>
  </div> -->

  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
    <div class="col float-start header-name lh_36">
        Email Templates
    </div>
    <div class="col d-flex text-center" style="justify-content: end;">
        <input type="text" class="form-control w-auto me-2" autocomplete="off" placeholder="Search" (keyup)="applyFilter($event.target.value)">   
        <button mat-raised-button color="primary" (click)="addNew()">Add</button>      
    </div>
  </div>

</div>
<div class="w-100">
  <div class="col-md-12">
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">

      <ng-container matColumnDef="templateName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40%;">Template</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.emailTemplateName}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="templateSubject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 45%;">Subject</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.emailSubject}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
        <td mat-cell *matCellDef="let element" class="action">

          <mat-icon [disabled]="element?.isPredined" [class.icBtnDisabled]="element.isPredined" title="Send Email" class="cursor-pointer" (click)="!element?.isPredined && sendTemplate(element)">send</mat-icon>

          <ng-template [ngIf]="hasPermission.editPermission === true">
            <mat-icon title="Edit Template" class="cursor-pointer ms-2" (click)="addNew(element)">edit</mat-icon> 
          </ng-template>
          
          <ng-template [ngIf]="hasPermission.delPermission === true">
            <mat-icon [disabled]="element.isPredined" [class.icBtnDisabled]="element.isPredined" title="Delete Template" class="cursor-pointer ms-2" (click)="!element?.isPredined && deleteTemplate(element)">delete</mat-icon>
          </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!emailTemplateService.common.progressLoader && !emailTemplateService.common.infiniteLoader) && allTemplates.length === 0" class="p-3 noList">Templates are not available</div>
    </div>

    <div class="w-100" *ngIf="emailTemplateService.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>
    
  </div>
</div>