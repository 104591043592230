<div class="container-fluid">
   <form class="mb-4" [formGroup]="techSettingForm" (ngSubmit)="onSubmit()">
    <div class="row setting-head mb-4">
        <div class="col-md-2">
            <div class="float-start header-name-t"> 
              <hr class="hr-head">
            </div>
        </div>
        <div class="col-md-10">
            <div class="text-end">
              <div  class="w-100 d_inline">
                <button mat-raised-button type="submit" color="primary" class="me-2">{{ 'common.labels.Save Changes' | translate }}</button>
              </div>
              <button mat-button type="button" [routerLink]="['/dashboard-technician']" class="">{{ 'common.labels.Cancel' | translate }}</button>
            </div>
        </div>
    </div>
   <div class="mb-5">   
      
        <mat-accordion multi="true">
          <mat-expansion-panel [expanded]="step === 0" >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="fw-bold accordian-title">{{ 'setting.labels.General Settings' | translate }}</span>
              </mat-panel-title>
            
            </mat-expansion-panel-header>
            <div class="date-format col-md-4 float-start pe-3">
              <div class="orange-title mt-3">
                  {{ 'setting.labels.Date Format' | translate }}
              </div>
              <div class="form-group">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" 
                *ngIf="settingData && settingData[0].GeneralSetting[1].globalSettingName == 'DateFormat'">
                <mat-label>Select Date</mat-label>
                  <mat-select placeholder=""
                  [(ngModel)]="settingData[0].GeneralSetting[1].localSettingValue != null?settingData[0].GeneralSetting[1].localSettingValue:settingData[0].GeneralSetting[1].globalSettingValue"
                  formControlName="dateFormat">
                    <mat-option>Please Select</mat-option>
                    <mat-option *ngFor="let format of dateformat" [value]="format.value">
                      {{format.format}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="time-format col-md-8 float-start row">
              <div class="col-md-6">
                <div class="orange-title mt-3">
                    {{ 'setting.labels.Time Format' | translate }}
                </div>
                <div class="form-group">
                  <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" 
                  *ngIf="settingData && settingData[0].GeneralSetting[2].globalSettingName == 'TimeFormat'">
                  <mat-label>Select Time</mat-label>
                    <mat-select placeholder=""
                    [(ngModel)]="settingData[0].GeneralSetting[2].localSettingValue != null?settingData[0].GeneralSetting[2].localSettingValue:settingData[0].GeneralSetting[2].globalSettingValue"
                    formControlName="timeFormat" >
                      <mat-option>Please Select</mat-option>
                      <mat-option *ngFor="let format of common.hoursFormat" [value]="format.format">
                        {{format.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="orange-title mt-3">
                    {{ 'setting.labels.Time Zone' | translate }}
                </div>
                <div class="form-group">
                  <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" 
                  *ngIf="settingData && settingData[0].GeneralSetting[0].globalSettingName == 'TimeZone'">
                  <mat-label>Select time zone</mat-label>
                    <mat-select placeholder=""
                    [(ngModel)]="settingData[0].GeneralSetting[0].localSettingValue != null?settingData[0].GeneralSetting[0].localSettingValue:settingData[0].GeneralSetting[0].globalSettingValue"  
                    (openedChange)="myInput.focus()" formControlName="timeZone">
                      <input class="myInput" #myInput matInput focused="'true'" type="text"
                        (keyup)="search($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                        placeholder="Search">
                      <mat-option>Please select</mat-option>
                      <mat-option *ngFor="let time of selectedTimezone" [value]="time.timeZoneCode">
                        {{time.timeZoneName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- <mat-expansion-panel (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="fw-bold accordian-title">{{ 'setting.labels.Notification Settings' | translate }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-group col-md-4 p-0 float-start ">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width"  *ngIf="settingData && settingData[1].NotificationSetting[0].globalSettingName == 'AlertMinutes'">
                  <mat-label>{{ 'setting.labels.Alert Job' | translate }}</mat-label>
                <input matInput placeholder="" 
                [(ngModel)]="settingData[1].NotificationSetting[0].localSettingValue != null?settingData[1].NotificationSetting[0].localSettingValue:settingData[1].NotificationSetting[0].globalSettingValue"
                formControlName="alertMinutes">
                </mat-form-field>
                <div class="inline">
                    {{ 'setting.labels.Minutes' | translate }} 
                </div>
            </div>
            <div class="form-group col-md-4 p-0 float-start">
              <div class="inline">
                  {{ 'setting.labels.SMS Notifications' | translate }}
              </div>
              <mat-form-field appearance="outline" floatLabel="always"   *ngIf="settingData && settingData[1].NotificationSetting[1].globalSettingName == 'sms_notifications'">
                <mat-label>Selsect</mat-label>
                <mat-select  
                  [(ngModel)]="settingData[1].NotificationSetting[1].localSettingValue != null?settingData[1].NotificationSetting[1].localSettingValue:settingData[1].NotificationSetting[1].globalSettingValue"
                  formControlName="sms_notifications">
                  <mat-option *ngFor="let bool of boolean" [value]="bool.value">
                    {{bool.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group col-md-4 p-0 float-start">
              <div class="inline">
                  {{ 'setting.labels.Mail Notifications' | translate }}
              </div>
              <mat-form-field appearance="outline" floatLabel="always"   *ngIf="settingData && settingData[1].NotificationSetting[2].globalSettingName == 'mail_notifications'">
                <mat-label>Select</mat-label>
                <mat-select  
                  [(ngModel)]="settingData[1].NotificationSetting[2].localSettingValue != null?settingData[1].NotificationSetting[2].localSettingValue:settingData[1].NotificationSetting[2].globalSettingValue"
                  formControlName="mail_notifications">
                  <mat-option *ngFor="let bool of boolean" [value]="bool.value">
                    {{bool.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-expansion-panel> -->

          <!-- <ng-container *ngIf="isDeleteUser"> -->

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="isDeleteUser">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="fw-bold accordian-title">Delete Account</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <delete-user></delete-user>  -->
            <button mat-raised-button type="button" color="warn" class="me-2 mt-3" (click)="softDeleteUserByUserCode(userCode)">Delete Account</button>
          </mat-expansion-panel>

        <!-- </ng-container> -->

        </mat-accordion>
     
   </div>  
  </form>
</div>    