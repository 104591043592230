import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable().pipe(delay(1));

  constructor(private common: CommonService) {}

  show() {
    // this.common.progressLoader = true;
    this._loading.next(true); 
  }

  hide() {
    this.common.progressLoader = false;
    this._loading.next(false);
  }
}
