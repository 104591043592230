import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.auth.clearSession();
    // this.auth.unSubscribeObservablesWhenLogOut();
  }

}
