<div class="w-100">
    <mat-tab-group dynamicHeight class="tab-button-auto-width" (selectedTabChange)="tabChanged($event)">

        <mat-tab label="Pending">
          <ng-container *ngIf="tabAtciveFor === 'Pending'">
            <pending-leaves [isShortcutModalOpen]="isShortcutModalOpen"></pending-leaves>
          </ng-container>        
        </mat-tab>
  
        <mat-tab label="Processed">
          <ng-container *ngIf="tabAtciveFor === 'Processed'">
            <processed-leaves [isShortcutModalOpen]="isShortcutModalOpen"></processed-leaves>
          </ng-container>        
        </mat-tab>

        <mat-tab label="Expired">
          <ng-container *ngIf="tabAtciveFor === 'Expired'">
            <expired-leaves [isShortcutModalOpen]="isShortcutModalOpen"></expired-leaves>
          </ng-container>        
        </mat-tab>
  
      </mat-tab-group> 
</div>