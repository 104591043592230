import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveService } from '../../../components/leave/leave-service';
import * as moment from 'moment';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { RequestNewJobService } from "src/app/dashboards/customer-dashboard/cust-request-new-job/request-new-job.service";
import { RejectAppointmentComponent } from 'src/app/dashboards/technician-dashboard/new-appointment/reject-appointment/reject-appointment.component';
import { AssignJobComponent } from 'src/app/components/new-job-card/assign-job/assign-job.component';
import { NotifyJobComponent } from 'src/app/components/new-job-card/notify-job/notify-job.component';

@Component({
  selector: 'cancel-jobs',
  templateUrl: './cancel-jobs.component.html',
  styleUrls: ['./cancel-jobs.component.scss']
})
export class CancelJobsComponent implements OnInit {
  @ViewChild("assignDialogRef") assignDialogRef: TemplateRef<any>;
  dialogRef: any;
  @Input() isShortcutModalOpen: any;  
  tabAtciveFor: string = 'Pending';
  // jobStatus: string = 'pending';
  jobStatus : string = 'approved,rejected';
  jobCancellationRequestData: any = [];
  displayedColumns: string[] = ['staffName', 'customerName', 'siteName', 'jobType', 'startTime', 'reason', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  today: any;
  baseURL: any;

  @Input() isExpired:boolean = false;
  @Input() rendered_componentFor :string = "pending" ;
  private isTBL_Scroll:boolean = false;
 

  constructor(
    public dialog: MatDialog,
    public service: LeaveService,
    public jobService: RequestNewJobService,
  ) {}

  ngOnInit() {
    this.baseURL = this.service.service.getSuperAdmin() + "/downloadFile/"
    //console.log(this.isShortcutModalOpen)
    this.page = 0;
    //this.getListDataByPage();
    this.addColDynamically();
    this.manageApi_Calls();
    this.today = moment(new Date()).format('MM-DD-YYYY');
  }

  addColDynamically()
  {
    if(this.rendered_componentFor?.toLowerCase() === 'pending' 
    || this.rendered_componentFor?.toLocaleLowerCase() === 'expired')
      {
      this.displayedColumns = this.displayedColumns.filter((column)=> column !== 'adminComment');
     }
     else 
      {
      let column = 'adminComment';
      this.displayedColumns.splice(7, 0, column);
      }
  }

  manageApi_Calls()
  {
    if(this.rendered_componentFor?.toLowerCase() === 'pending' || this.rendered_componentFor?.toLowerCase() === 'expired')
      {
      
        this.getAllCancelJobs();
      }
      else
      {
        this.getListDataByPage();
      }
  }
  
  // tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  //   this.page = 0;
  //   if(tabChangeEvent?.tab?.textLabel?.toLowerCase() === 'pending' 
  //   || tabChangeEvent?.tab?.textLabel?.toLocaleLowerCase() === 'expired')
  //     {
  //     this.displayedColumns = this.displayedColumns.filter((column)=> column !== 'adminComment');
  //    }
  //    else 
  //     {
  //     let column = 'adminComment';
  //     this.displayedColumns.splice(7, 0, column);
  //     }

  //   this.tabAtciveFor = tabChangeEvent?.tab?.textLabel;

     
  //   setTimeout(() => {
  //     this.jobCancellationRequestData = [];
  //     this.dataSource = new MatTableDataSource();
  //     this.dataSource.sort = this.sort;
  //   }, 200);
  //   // //console.log('tabChangeEvent => ', tabChangeEvent?.tab?.textLabel);    
  //  // this.jobStatus = tabChangeEvent?.tab?.textLabel === 'Pending' ? 'pending' : 'approved,rejected';
  //  // this.getListDataByPage();
  //  this.manageApi_Calls();
  // }
  
  approveJobRequest(job: any){
    let approveStatus = 'approved';
    let msg;
      msg = 'Are you sure you want to approve this job cancellation request'
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: msg }
    });
  
    dialogRef.afterClosed().subscribe(result => {  
      if(result && result.toLowerCase() === 'ok'){
        if(job?.cancelJobAssignmentCode){
          const requestBody = {
            adminComment: null,
            cancelJobAssignmentCode: job.cancelJobAssignmentCode,
            status: true
          }   
          // console.log('RequestBody:', requestBody);
          // return;
          this.service.common.progressLoader = true;
          this.jobService.cancellationJobRequestApproveDecline(requestBody).subscribe((response: any) => {                  
              if (response.responsecode == 200) {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.successMessage = true;
                this.page = 0;
                // this.getListDataByPage();
                this.manageApi_Calls();
               // this.getGlobalCancelJobRequestCount();
                this.dialog.open(this.assignDialogRef, {
                  width: '500px',
                  data: job 
                });
              } else {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.errorMessage = true;
              }
        })
        }
  }
  })
  }

  rejectJobRequest(job) {
    const dialogRef = this.dialog.open(RejectAppointmentComponent, {
      width: '300px',
      disableClose: true,
      data: {labelText: 'Reason', inputData: job }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      if (job?.cancelJobAssignmentCode && result != undefined) {
       const requestBody = {
          adminComment: result,
          cancelJobAssignmentCode: job.cancelJobAssignmentCode,
          status: false
        }
        try {
          this.service.common.progressLoader = true;
          // console.log('RequestBody:', requestBody);
          // return;
          this.jobService.cancellationJobRequestApproveDecline(requestBody).subscribe((res: any) => {
            if (res.responsecode == 200) {
              this.page = 0;
              // this.getListDataByPage();
              this.manageApi_Calls();
            //  this.getGlobalCancelJobRequestCount();
              // this.getStatus('all');
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              // this.getAppointments(this.selectedAppointmentDate, this.dashboarService.common.pageOffset);
              // this.getNewAppointment(this.dashboarService.session.get('userCode'), this.startDate, this.endDate);
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
              this.service.common.progressLoader = false;
            }
          })
        } catch (error) {
          this.service.common.progressLoader = false;
          //console.log(error)
        }
      }
    });
  }

/* 
name: VivekChauhan
use: return Cancel Job Request Count by organization code .
*/
// getGlobalCancelJobRequestCount() {
//   this.service.common.getCancelJobRequestCount(this.service.storage.get('orgCode'), false, (data) => {
//     if (data.responsecode == 200) {
//       this.service.common.globalCancelJobRequestCount = data.count;
//     } else {
//       this.service.common.globalCancelJobRequestCount = 0;
//     }
//   })
// }
  
   /* Name:Vivek Chauhan
  use: for getting all Cancle Job data by org code */
  getListDataByPage() {
      // //console.log('getListDataByPage: ', this.page, scroll);
      if (!this.isTBL_Scroll) {
        this.service.common.progressLoader = true;
      }
      else if (this.isTBL_Scroll) {
        this.service.common.infiniteLoader = true;
      }
      try {
        this.jobService.getAllCancelJobAssignmentByOrganizationCodePage(this.service.storage.get('orgCode'), this.jobStatus, this.page, this.limit, (response) => {
          if(response.responsecode === 200){
            // this.getGlobalLeaveCount(this.service.storage.get('orgCode'));
            if (this.isTBL_Scroll) {
              let arrayData = this.dataSource.data;
              response.data.forEach(element => {
                arrayData.push(element)
              });
              setTimeout(() => {
                this.jobCancellationRequestData = arrayData;
                this.dataSource = new MatTableDataSource(arrayData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.infiniteLoader = false;
              }, 1000);

            } else {
              ////console.log(response.data);
              setTimeout(() => {
                this.jobCancellationRequestData = response.data;
                this.dataSource = new MatTableDataSource(this.jobCancellationRequestData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.progressLoader = false;
              }, 1000)
            }
          } else {
            if (!this.isTBL_Scroll) {
              this.jobCancellationRequestData = [];
              setTimeout(() => {
                this.dataSource = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
              }, 1000);      
            } else {
              this.service.common.infiniteLoader = false;
            }
            this.service.common.progressLoader = false;
          }
      });    
      } catch (error) {
        //console.log(error);
      }
  }

  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.isTBL_Scroll = true ;
    this.manageApi_Calls()
    //this.getListDataByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }

  /* Name:Vivek Chauhan
  use: for seraching Distribution Data name */
    applyFilter(searchText: string) {
      return;
      // //console.log('applyFilter', searchText);
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
    this.page = 0;
      this.service.common.progressLoader = true;
    try {
      if(searchText && searchText.trim()){
      this.service.getListDataBySearchPending(this.service.storage.get('orgCode'), this.jobStatus, searchText, (response) => {
        if(response.responsecode === 200){
            setTimeout(() => {
              this.jobCancellationRequestData = response.data;
              this.dataSource = new MatTableDataSource(this.jobCancellationRequestData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.progressLoader = false;
            }, 1000);
        } else {
          this.service.common.progressLoader = false;
            this.jobCancellationRequestData = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 1000);          
        }
    });
    } else {
      this.getListDataByPage();
    } 
    } catch (error) {
      //console.log(error);
    }
  }

  openAssignPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    let popupData = {
      appoinmentCode: appoinmentCode,
      appoinmentProcedureCode: appoinmentProcedureCode,
      date: date
    }

    this.dialogRef = this.dialog.open(AssignJobComponent, {
      disableClose: true,
      width: '700px',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {      
      if (result != undefined && result === 'success') {
        // Success
        document.getElementById('closeAssignDialog').click();
      }
    });    
  }

  openNotifyPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    let popupData = {
      appoinmentCode: appoinmentCode,
      appoinmentProcedureCode: appoinmentProcedureCode,
      date: date
    }

    this.dialogRef = this.dialog.open(NotifyJobComponent, {
      disableClose: true,
      width: '500px',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('CloseNotifyPopupReq:', result);
      if (result != undefined && result === 'success') {
        // Success
        document.getElementById('closeAssignDialog').click();
      }
    }); 
  }


getAllCancelJobs()
{
  if (!this.isTBL_Scroll) {
    this.service.common.progressLoader = true;
  }
  else if (this.isTBL_Scroll) {
    this.service.common.infiniteLoader = true;
  }

   this.jobService.getAllCancelJobs(this.service.storage.get('orgCode'), this.isExpired , this.page , this.limit).subscribe((res:any)=>
    {

      if (!this.isTBL_Scroll) {
        this.service.common.progressLoader = false;
      }
      else if (this.isTBL_Scroll) {
        this.service.common.infiniteLoader = false;
      }

      if(res.responsecode === 200)
        {
            this.jobCancellationRequestData = [...this.jobCancellationRequestData, ...res.data]
            this.dataSource = new MatTableDataSource(this.jobCancellationRequestData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
             this.dataSource.sort = this.sort;
        }
        else
        {
          if(!this.isTBL_Scroll)
            {
              this.dataSource = new MatTableDataSource();
              this.jobCancellationRequestData = [];
            }
        }
 
   })
}

}

