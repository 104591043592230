import { Component } from '@angular/core';

@Component({
  selector: 'site-maintenance',
  templateUrl: './site-maintenance.component.html',
  styleUrls: ['./site-maintenance.component.scss']
})
export class SiteMaintenanceComponent {

}
