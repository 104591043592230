import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';

@Component({
  selector: 'app-split-appointment',
  templateUrl: './split-appointment.component.html',
  styleUrls: ['./split-appointment.component.scss']
})
export class SplitAppointmentComponent implements OnInit {


  splitAppointment;
  constructor(public dialogRef: MatDialogRef<SplitAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: CreateAppointmentService) { }

  ngOnInit() {
    ////console.log(this.data.data);
  }

/* Name:Shailesh
      use: for splitting appointments */
  submit() {

    this.service.common.progressLoader = true;
    //console.log(this.data);
    try {
       this.service.adminGetAppointmentDetailsToSplit(this.data.jobDurationCode, this.service.session.get('orgCode'), (data) => {
      //console.log(data);
      if (data.responsecode == 200) {
        this.service.common.progressLoader = false;
        this.cancel();
        /* this.splitAppointment = data.data;
         this.service.splitForm.appointmentsCode = this.splitAppointment.appointmentsCode;
         this.service.splitForm.appointmentsDurationCode = this.splitAppointment.appointmentsDurationCode;
         this.splitAppointment.procedureDetailsesList.forEach(element => {
           let obj: any = {};
           obj.durationHours = element.duration.split(':')[0];
           obj.durationMinute = element.duration.split(':')[1];
           obj.notes = element.notes;
           obj.organizationBaseProcedureCode = element.organizationBaseProcedureCode;
           obj.startTime = element.startTime;
   
           this.service.splitForm.procedureDetailsesList.push(obj);
         });
         ////console.log(this.service.splitForm);
         this.service.common.progressLoader = false;
         this.service.adminSplitAppointment(this.service.splitForm, (res) => {
           ////console.log(res);
           if (res.responsecode == 200) {
             this.service.common.progressLoader = false;
             this.dialogRef.close(res.message);
           } else {
             this.service.common.message = res.message;
             this.service.common.errorMessage = true;
           }
         }) */
      } else {
        this.service.common.progressLoader = false;
        this.cancel();
        this.service.common.message = data.message;
        this.service.common.errorMessage = data.message;
      }
    })
    } catch (error) {
      
    }
   

  }

  cancel() {
    this.dialogRef.close();
  }
}
