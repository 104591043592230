import { Component, OnInit, Inject } from '@angular/core';
import { TimeOfRequestService } from '../time-of-request.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from 'src/app/dashboards/providers-dashboard/skills/skill-list/skill-list.component';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};

@Component({
  selector: 'app-add-leave',
  templateUrl: './add-leave.component.html',
  styleUrls: ['./add-leave.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class AddLeaveComponent implements OnInit {

  leaveform: FormGroup;
  submitted = true;
  userCode;
  orgCode;
  resetForm = true;
  date = moment().format('YYYY-MM-DD');
  today = new Date();
  dataForEdit: any;

  constructor(
    public service: TimeOfRequestService,
    public dialogRef: MatDialogRef<AddLeaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,public translate: TranslateService, public common: CommonService
  ) { 
    this.translate.setDefaultLang(this.service.session.get('language'));
  }
/* Name:srinivas
      use: page loads. */
  ngOnInit() 
  {
    this.initializeForm();
    this.userCode = this.service.session.get('userCode');
    this.orgCode = this.service.session.get('orgCode');
  
    if(this.data){
      this.dataForEdit = this.data;
      const newStartDate = this.dataForEdit?.startDate && new Date(this.dataForEdit.startDate);
      const newEndDate = this.dataForEdit?.endDate && new Date(this.dataForEdit.endDate);
      this.leaveform.controls['fromDate'].setValue(newStartDate);
      this.leaveform.controls['toDate'].setValue(newEndDate);
      this.leaveform.controls['reason'].setValue(this.dataForEdit?.comment);
    }

    
  }

initializeForm()
{
  this.leaveform = new FormGroup({
    'fromDate': new FormControl(this.date, Validators.required),
    'toDate': new FormControl(this.date, Validators.required),
    'reason': new FormControl(null, Validators.required),
  });
}

/* Name:srinivas
      use: save data. */
  onSubmit() {
    if (this.leaveform.valid) {

try {  
      const addRequest = {
        startDate: moment(this.leaveform.value.fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(this.leaveform.value.toDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        comment: this.leaveform.value.reason,
        workerCode: this.userCode,
        organizationCode: this.orgCode,
        leaveDetailsCode: this.dataForEdit?.leaveDetailsCode
      }

      const futureDate = moment(addRequest.endDate).isAfter(addRequest.startDate);
      const todayDate = moment(addRequest.startDate).isSame(addRequest.endDate, 'day');
      if (futureDate || todayDate) {
        this.service.saveOrUpdateLeaveDetails(addRequest, (response) => {
          if (response.responsecode == 200) {
            this.service.common.message = response.message;
            this.service.common.successMessage = true;
            this.dialogRef.close('success');
          } else {
            this.service.common.message = response.message;
            this.service.common.errorMessage = true;
          }
        });
      } else {
        this.service.common.message = 'To date should be after from date';
        this.service.common.errorMessage = true;
      }
    } catch (error) {
  
    }
    } else {
      this.submitted = false;
      this.service.common.progressLoader = false;
    }
    
  }

/* Name:srinivas
      use: close popUp. */
  closeDialog(): void {
    this.dialogRef.close('close');
  }
}
