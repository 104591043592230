export const environment = {
  production: false,
  environmentName: 'Development',
  // apiUrl: 'https://healthcare.gigflex.com:8763',
  // apiUrl: 'https://staging-healthcare.gigflex.com:8763'
//    apiUrl: 'https://demo.gigflex.com:8762',
 apiUrl: 'https://api-dev.gigflex.com:8762',
//  apiUrl: 'https://staffworks.gigflex.com:8762',
 //apiUrl:'https://demo.gigflex.com:8762',
 //  apiUrl: 'https://api.gigflex.com:8763',
 //apiUrl: 'https://healthcare-dev.gigflex.com:8762',
 //apiUrl: 'https://healthcare.gigflex.com:8763',
 version: '2.0.29',
versionCheckURL:'https://staffworks.gigflex.com/version.json',
 versionCheckURLSecure:'https://staffworks.gigflex.com/version.json',
 // versionCheckURL:'http://healthcare.gigflex.com/version.json',
 // versionCheckURLSecure:'https://healthcare.gigflex.com/version.json'
//  brokerURL : 'https://staffworks.gigflex.com:8088/socket',
 brokerURL : 'https://api-dev.gigflex.com:8088/socket',
}; 