import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { TechProfileService } from '../tech-profile.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss']
})
export class AddProviderComponent implements OnInit {

  filteredProviders: Observable<any[]>;
  workerCode;
  providersList;
  selectedProviders = [];
  providerForm: FormGroup;
  message = 'No provider present';

  constructor(public dialogRef: MatDialogRef<AddProviderComponent>, public service: TechProfileService) {
  }

  ngOnInit() {

    this.workerCode = this.service.storage.get('userCode');

    this.providerForm = new FormGroup({
      'providers': new FormControl(null)
    })

    try {
      
       this.service.mobileAdminGetEligibleOrganisationListByTechnicianCode(this.workerCode, (data) => {
      if (data.responsecode == 200) {
        this.providersList = data.data;
        ////console.log(this.providersList);
      } else {
        if(data.message == 'Worker has no skills.'){
          this.message = data.message;
        } else {
          this.message = 'No provider present.';
        }
        this.providersList = [];
      }
    });
   } catch (error) {
      
    }

    this.filteredProviders = this.providerForm.controls['providers'].valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      );
  }
  /* Name:srinivas
    use: return provider display. */

  providersDisplayFn(certification): string | undefined {
    // ////console.log(certification);
    return certification ? certification.organizationName : undefined;
  }
  /* Name:srinivas
      use:return filtered value. */
  private _filter(name): String[] {
    // ////console.log(name);
    const filterValue = name;
    if (filterValue && this.providersList.length > 0) {
      return this.providersList.filter(option => option.organizationName.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
    } else {
      return this.providersList;
    }
  }
  /* Name:srinivas
      use: returns selected provides list. */
  selectedOption(event) {
    const index = this.selectedProviders.findIndex(fruit => fruit.organizationName === event.option.value.organizationName);
    if (index == -1) {
      this.selectedProviders.push(event.option.value);
      this.providerForm.controls['providers'].setValue(null);
    } else {
      this.service.common.message = event.option.value.organizationName + ' is already selected';
      this.service.common.errorMessage = true;
    }
  }
/* Name:srinivas
      use: removes the organization. */
  removeOrg(index) {
    this.selectedProviders.splice(index, 1);
    if (this.selectedProviders.length == 0) {
      this.providerForm.controls['providers'].setValue(null);
    }
  }
/* Name:srinivas
      use: close popUp. */
  onClose() {
    this.dialogRef.close();
  }
/* Name:srinivas
      use: save the data. */
  onSubmit() {
    if (this.selectedProviders.length > 0) {
      // ////console.log(this.selectedProviders);
      const data = [];
      this.selectedProviders.forEach((element) => {
        data.push({
          "isAssign": true,
          "organizationCode": element.organizationCode
        })
      })
      try {
        
        this.service.mobileAdminAssignUnassignWorkerToOrganization(this.workerCode, data, (res) => {
          if (res.responsecode == 200) {
            this.dialogRef.close('done');
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        })
      } catch (error) {
        
      }
    }
  }

}
