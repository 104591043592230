import { BroadcastMessageComponent } from './../../dashboards/providers-dashboard/broadcast-message/broadcast-message.component';
import { Component, OnInit, ViewChild, Output, Renderer2, Inject, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/dashboards/providers-dashboard/notification/notification.service';
import { SessionStorageService } from 'angular-web-storage';
// import { ProcedureEditComponent } from 'src/app/dashboards/providers-dashboard/procedure/procedure-edit/procedure-edit.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { MatDialog } from '@angular/material/dialog';
// import { RxStompService } from "@stomp/ng2-stompjs";
import { RxStompService } from 'src/app/rx-stomp.service';
// import { OrgPopupComponent } from 'src/app/dashboards/technician-dashboard/org-popup/org-popup.component';
import { ConfirmLogoutComponent } from '../confirm-logout/confirm-logout.component';
import { AuthService } from 'src/app/shared/auth.service';
import { Subject, takeUntil } from 'rxjs';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  page = 0;
  limit = 5;
  notification;
  count;
  userType;
  LoggedUserRole: any;
  // @ViewChild(ProcedureEditComponent, { static: true }) procedure: ProcedureEditComponent;
  topicName = 'chat_';
  orgCodes = [];
  orgCode: any;
  dismiss = false;
  loggedUserData: any;
  baseURL: any;
  organizationLogo: any;
  notificationList:any
  loggedIn_userCode:string;

  isPermissionForSettings:boolean = false ;

 private destroyObs  = new Subject<string>();

  constructor(public service: NotificationService, public session: SessionStorageService,
     private translate:TranslateService, public common: CommonService,
     public dialog: MatDialog, public rxStompService: RxStompService,
       public auth: AuthService, private _router: Router
    )
     {
    this.translate.setDefaultLang(this.service.session.get('language'));
    // this.router.events.subscribe((val)=>{
    //   this.dismiss = false;
    // });
  }
 

  saveFormData() {
    //////console.log(this.procedure);
  }
  ngOnInit() {
    this.baseURL = this.service.service.getSuperAdmin() + "/downloadFile/"
    ////console.log(this.router)

    // const s = this.renderer2.createElement('script');
    // s.type = 'text/javascript';
    // s.src = 'assets/chat-app.js';
    // s.text = ``;
    // this.renderer2.appendChild(this._document.body, s);


    this.topicSub();
  //  this.getNotification(this.page, this.limit)
    this.userType = this.session.get('user_type');
    this.orgCodes= this.session.get('org_list');
    this.LoggedUserRole = this.session.get('MenuListDataAndPagePermission')?.role;
    // //console.log('LoggedUserRole: ', this.LoggedUserRole)
    this.orgCode= this.session.get('orgCode');
    // //console.log('loggedUserData_UserType: ', this.userType);    
    // loggedUserData
    this.loggedUserData = this.session.get('loggedUserData');
    // //console.log('loggedUserData: ', this.loggedUserData, this.orgCode);
    this.getSettingBySettingName('Organization Logo');

   // this.common.For_Notification();

    // this.common.subSocket_ForOrgNotification();
    // this.common.subSocket_ForStaffNotification();
    
    // this.loggedIn_userCode = this.service.common.local.get('userCode');

    this.loggedIn_userCode = this.session.get('userCode');

    if(this.session.get('MenuListDataAndPagePermission'))
    {
      this.isPermissionForSettings = this.session.get('MenuListDataAndPagePermission').ppLst.some(
        ele => ele.pagePermissionName.toLowerCase() === 'settings'
        );
    }

    if(this.userType === "Customer")
      {
        this.common.isAllowAutoApproval();
      }
    

  }

  /* Name:Shailesh
      use: for getting notification*/
  // getNotification(page, limit) { 
  //   try {
  //      this.service.getAllHealthcareNotificationByUserCodeWithReadByPage(this.session.get('userCode'), page, limit, (response) => {
  //    //console.log(response);
  //    if(response.responsecode === 200){
  //     this.notification = response.data;
  //     // ////console.log(this.notification);
  //     this.service.common.allNotificationsCount = response.count;
  //     ////////console.log(this.count);
  //    } else {
  //      this.notification = [];
  //      this.service.common.allNotificationsCount = '';
  //    }
  //   })
  //   } catch (error) {
  //     //console.log(error)
  //   }
   
  // }

  // getCount() {
  //   this.getNotification(this.page, this.limit);
  //   // //////console.log(this.count);
  // }

  confirmPopup(){
    //////console.log(this.router.url);
    this.service.common.confirmEmit.emit('showpopup');
  }

  /* Name:Shailesh
      use: to open broadcast message popup */
  // sendBrdCstMsg(): void {
  //   const dialogRef = this.dialog.open(BroadcastMessageComponent, {
  //     width: '550px',
  //     data: {}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     // ////console.log('The dialog was closed');  
  //   });
  // }

  /* Name:Shailesh
      use: for getting chat message count */
  topicSub(){
    //console.log("from header comp sub start", '/topic/' + this.topicName + this.session.get('userCode'));
    try {
      if(!this.common.allGroupResponse){
        // console.log('allGroupResponse_header: ', this.common.allGroupResponse);
        this.common.getGlobalCountChat();
        this.common.getAllChatGroupGlobalChat();
      }
        this.rxStompService.watch('/topic/' + this.topicName + this.session.get('super_admin_code'))
        .pipe(takeUntil(this.destroyObs)).subscribe((message) => {
      ////console.log(message);
      //let body = JSON.parse(message.body);
      // console.log("from header comp sub data", '/topic/' + this.topicName + this.session.get('userCode'));
      // console.log(body); 
      this.common.getAllChatGroupGlobalChat(true);
    });
    } catch (error) {
      //console.log(error)
    }
  
  }

  getSettingBySettingName(settingName: any){
    try {
      this.common.getSettingBySettingName(settingName, this.orgCode, (response)=>{
        // //console.log('getWebLogo', response);
     if(response.responsecode == 200){
       this.organizationLogo = response.settingValue;
     } else {
       
     }
   })
   } catch (error) {
     //console.log(error)
   }
  }

    /* Name:Shailesh
      use: to logout from application */
      logout() {
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '320px',
          data: { msg: 'Are you sure you want to logout?' }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.auth.logout();
          }
        });
      }


  // changeOrg(){
  //   const dialogRef = this.dialog.open(OrgPopupComponent, {
  //     width: '300px',
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     //console.log(result);
  //     if(result){
  //     this.common.orgCodeEmit.emit(result);   
  //     }
  //   });
  // }

  // dismissMsg(){
  //   this.dismiss = true;
  // }

  // navigate(){

  //   if(this.service.session.get('user_type') === 'Customer')
  //   {
  //     this._router.navigate(['/dashboard-customer/notification'])
  //   }
  //   else if(this.service.session.get('user_type') === 'Staff')
  //   {
  //     this._router.navigate(['/dashboard-technician/notification'])
  //    }

  //   else{
  //     this._router.navigate(['/dashboard/notification'])
  //    }

  // }


  logoClicked()
  {
    if(this.service.session.get('user_type') === 'Staff')
    {
      this._router.navigate(['/dashboard-technician'])
    }
    else
    {
      let isPermissionForDashboar_View  = this.common.getBtnEventPermissions("Dashboard");

        if(isPermissionForDashboar_View.viewPermission === true)
        {
            if(this.service.session.get('user_type') === 'Customer')
            {
            this._router.navigate(['/dashboard-customer'])
            }
            else{
              this._router.navigate(['/dashboard'])
             }

        }
    }
  }


  ngOnDestroy(): void {
    this.destroyObs.next('');
    this.destroyObs.complete();
  }

}
