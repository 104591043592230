<div class="row setting-head">
  <div class="col-md-8">
    <div class="float-start header-name">
      <span>{{ 'setting.labels.Prefered Work Hours' | translate }}</span>
      <hr class="hr-head">
    </div>
  </div>
</div>
<div class="row">
  <div class="days inline col-md-6  mb-3" *ngFor="let day of service.days">
    <div *ngIf="daycode != day.days" class="worker-preferred-hours">
      <div>
        <span class="dayName">{{day.dayName}}</span>
        <i class="material-icons cursor-pointer" *ngIf="editShow" (click)="onEdit(day)"> edit</i>
        <div class="times">
          <span class="label-title d-inline-block">{{ 'setting.labels.From Time' | translate }} - </span>
          <span *ngIf="day.fromTime == ''" class="d-inline-block">{{ 'setting.labels.NA' | translate }} &nbsp;&nbsp; </span>
          <span class="font-weight d-inline-block me-2" *ngIf="day.fromTime != ''">
            {{day.fromTimeView}}
          </span>
          <span class="label-title d-inline-block">{{ 'setting.labels.To Time' | translate }} - </span>
          <span *ngIf="day.toTime == ''" class="d-inline-block"> {{ 'setting.labels.NA' | translate }}</span>
          <span class="font-weight d-inline-block" *ngIf="day.toTime != ''">
            {{day.endTimeView}}
          </span>
        </div>
      </div>
    </div>

    <form [formGroup]="formDay" (ngSubmit)="onSubmit()" *ngIf="daycode == day.days">
      <div class="row">
        <div class="days inline col-md-12">
          <mat-checkbox color="primary" title="Reset Time" class="repeat-day" (change)="selectDay($event,day.workerhoursOfOperationCode)">
            {{day.dayName}}
          </mat-checkbox>
          <mat-checkbox (change)="repeatAllDay($event)" class="ms-3">Repeat All Days</mat-checkbox>
          <i class="material-icons cursor-pointer reset-icon" title="Reset Time"> autorenew </i>
          <i class="material-icons cursor-pointer" (click)="close()"> close </i>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-md-6 col-lg-3 ps-0 ms-3 float-start">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
            <mat-label>{{ 'setting.labels.From Time' | translate }}</mat-label>
            <mat-select placeholder="HH:MM" formControlName="fromTime" [disabled]="readonly">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchStart($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="Search">
              <mat-option>{{ 'setting.labels.Please Select' | translate }}</mat-option>
              <mat-option *ngFor="let intervals of selectedStartTime" [value]="intervals.value">
                <p *ngIf="timeFormat === 'hh:mm a'">  {{intervals.text}}</p>
                <p *ngIf="timeFormat === 'HH:mm'"> {{intervals.value}}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group col-md-4 ps-0 float-start">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
            <mat-label>{{ 'setting.labels.To Time' | translate }}</mat-label>
            <mat-select placeholder="HH:MM" formControlName="toTime" [disabled]="readonly">
              <input class="myInput" #myInput matInput focused="'true'" type="text"
                (keyup)="searchEndTime($event.target.value)" (keydown)="$event.stopPropagation()" autocomplete="off"
                placeholder="Search">
              <mat-option>{{ 'setting.labels.Please Select' | translate }}</mat-option>
              <mat-option *ngFor="let intervals of selectedEndTime" [value]="intervals.value">
                <p *ngIf="timeFormat === 'hh:mm a'">  {{intervals.text}}</p>
                <p *ngIf="timeFormat === 'HH:mm'"> {{intervals.value}}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2 text-start">
          <button class="mt-4" mat-raised-button color="primary">{{ 'setting.labels.Save' | translate }} </button>
        </div>
        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="workerhoursOfOperationCode">
      </div>
    </form>
  </div>
</div>
<mat-divider class="p-3 mt-4 mb-4"></mat-divider>
<div class="row">
  <div class="col-md-8">
    <div class="float-start header-name">
      <span>{{ 'setting.labels.Available For Extra Work' | translate }}</span>
      <hr class="hr-head-w">
    </div>
  </div>
  <div class="col-md-12">
    <div class="accordian-description row">
      <div class="form-group col-md-10">
        <div class="days inline" *ngFor="let day of service.preferedDays; let i=index;">
          <mat-checkbox color="primary" (change)="onChecked(i, $event)" [checked]="day.checked"
            [value]="day.dayCode"> {{day.dayName}}</mat-checkbox>
        </div>
      </div>
      <div class="col-md-2">
        <div class="row justify-content-end">
          <button class="mt-3 ms-4" mat-raised-button color="primary" (click)="saveExtraDay()">{{ 'setting.labels.Save' |
            translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>