import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PreferenceSettingService {

  days = [
    {
      "days": 2,
      "dayName": "Mon",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "days": 3,
      "dayName": "Tue",
      "id": 0,
      "checked": false,
      "workerCode": "",
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "days": 4,
      "dayName": "Wed",
      "id": 0,
      "checked": false,
      "workerCode": "",
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "days": 5,
      "dayName": "Thu",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "days": 6,
      "dayName": "Fri",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "days": 7,
      "dayName": "Sat",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": ""
    },
    {
      "days": 1,
      "dayName": "Sun",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    }
  ];

  preferedDays = [
    {
      "dayCode": 2,
      "dayName": "Mon",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "dayCode": 3,
      "dayName": "Tue",
      "id": 0,
      "checked": false,
      "workerCode": "",
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "dayCode": 4,
      "dayName": "Wed",
      "id": 0,
      "checked": false,
      "workerCode": "",
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "dayCode": 5,
      "dayName": "Thu",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "dayCode": 6,
      "dayName": "Fri",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "dayCode": 7,
      "dayName": "Sat",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    },
    {
      "dayCode": 1,
      "dayName": "Sun",
      "workerCode": "",
      "id": 0,
      "checked": false,
      "fromTime": '',
      "toTime": "",
      "workerhoursOfOperationCode": "",
      "fromTimeView":"",
      "endTimeView":""
    }
  ];

  constructor(public common: CommonService,
    public service: ApiRootService,
    public http: HttpClient, ) { }
/* Name:srinivas
    use: save worker extra days. */
  saveWorkerAvailabilityExtraWork(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveWorkerAvailabilityExtraWork/', formData).subscribe((data) => {
      callback(data);
    })
  }
  /* Name:srinivas
    use: get worker days by worker code. */
  getWorkerAvailabilityExtraWorkByWorkerCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getWorkerAvailabilityExtraWorkByWorkerCode/' + code).subscribe((data) => {
      callback(data);
    })
  }
  /* Name:srinivas
    use: get worker hours records. */
  getworkerHoursOfOperationByworkerCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getworkerHoursOfOperationByworkerCode/' + code).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
    use: save hours .*/
  saveHoursOfOperation(data, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveWorkerHoursofOperation', data).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:srinivas
    use: save hours for week. */

  saveHoursOfOperationForWeek(data, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveWorkerHoursofOperationForWeek', data).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
    use: update hours record. */
  updateHoursOfOperation(code, data, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateworkerHoursOfOperationByhourofOperationCode/' + code, data).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
    use: cancel hour record by work hours code. */
  cancelWorkerHoursOfOperationByHoursOfOperationCode(code, callback) {
    return this.http.delete(this.service.getHealthCareUrl() + '/cancelWorkerHoursOfOperationByHoursOfOperationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }


}
