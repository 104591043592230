import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {}

@Component({
  selector: 'app-orgapproval',
  templateUrl: './orgapproval.component.html',
  styleUrls: ['./orgapproval.component.scss']
})
export class OrgapprovalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OrgapprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
}
