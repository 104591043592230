<div class="row">
  <div class="col-md-12 col-sm-12">
     <div class="row mb-2">
        <div class="col-md-5 align-self-center">
           <h6 class="skill-test pt-2">{{data.popupTitle}}</h6>
        </div>
        <!-- <p (click)="onChangeView(data.userList)">ChangeView</p> -->
        <div class="col-md-2 text-center">
            <mat-icon  [class.active]="!isListView" class="viewpoint me-2" (click)="changeView(data.userList, false)">view_module</mat-icon>
            <mat-icon  [class.active]="isListView" class="viewpoint" (click)="changeView(data.userList, true);">list</mat-icon>
        </div>
        <div class="col-md-5">
           <div class="row justify-content-end pr_34">
            <div *ngIf="!isListView" class="d-flex flex-row border-box">
               <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
               <input autocomplete="off" type="text" placeholder="Search"
                 class="searchfilter form-control" name="search-name" [(ngModel)]="searchText">
             </div>
             <div *ngIf="isListView" class="d-flex flex-row border-box">
               <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
               <input autocomplete="off" type="text" placeholder="Search"
                 class="searchfilter form-control" name="search-name" (keyup)="applyFilter($event.target.value)">
             </div>
              <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
           </div>
        </div>
     </div>
     <div class="w-100 scrollBody">
      <ng-container *ngIf="data.popupTitle === 'Teams' || data.popupTitle === 'Supervisors'">
        <div class="row row-cols-1 row-cols-md-3 g-4 mb-4 ps-0 ms-0 pe-0 me-0 pt-0">
         <ng-container *ngIf="!isListView">
           <div class="col-md-3 col-sm-6 col-12 col-lg-4 col-xl-3" *ngFor = "let user of data.userList | searchFilter : searchText:['staffName', 'mobilePhone', 'email']; index as idx; let last = last;">
              <div class="card staffCard">
                 <div class="d-flex">
                    <div class="w-100 justify-content-top text-center pt-3 imgAtTop">
                       <img [src]="user?.logo ? (baseURL + user?.logo) : 'assets/common_images/profile.jpg'"
                          class="card-img-top" alt="User Image"  onerror="this.src='assets/common_images/profile.jpg';">
                    </div>
                    <div class="card-body text-center">
                       <h5 class="card-title"><span appPopover [appPopoverData]="user" [placement]="'top'">{{user?.staffName | capitalize}}</span> </h5>
                       <div class="text-truncate email" title="{{user?.mobilePhone}}">{{user?.mobilePhone}}</div>
                       <div class="text-truncate email" title="{{user?.email}}">{{user?.email}}</div>                       
                    </div>
                 </div>
              </div>
           </div>
           </ng-container>
           <ng-container *ngIf="isListView">
           <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 patient-table position-relative">
            <ng-container matColumnDef="avatarIc" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef width="45px"></th>
                  <td mat-cell *matCellDef="let element" width="45px">
                     <img
                        class="td_avatar_img"
                        [src]="element?.logo ? (baseURL + element?.logo) : 'assets/common_images/profile.jpg'"
                        onerror="this.src='assets/common_images/profile.jpg';"
                     />
                  </td>
             </ng-container>

            <ng-container matColumnDef="staffName" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">{{ data.popupTitle === 'Teams' ? 'Staff' : 'Supervisor'}} </th>
               <td mat-cell *matCellDef="let element; let i = index; let last = last;" class="common-icon-round" class="text-nowrap">
                  <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
               </td>
             </ng-container>     

             <ng-container matColumnDef="email" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef class="text-nowrap">Email</th>
               <td mat-cell *matCellDef="let element" class="common-icon-round" class="text-nowrap">
                 {{element.email}}
               </td>
             </ng-container>  

             <ng-container matColumnDef="phone" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef class="text-nowrap">Phone</th>
               <td mat-cell *matCellDef="let element" class="common-icon-round" class="text-nowrap">
                 {{element.mobilePhone}}
               </td>
             </ng-container> 
             <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
         </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="data.popupTitle === 'Sites'">
        <div class="row row-cols-1 row-cols-md-3 g-4 mb-4 ps-0 ms-0 pe-0 me-0 pt-0">
         <ng-container *ngIf="!isListView">
           <div class="col-md-3 col-sm-6 col-12 col-lg-4 col-xl-3" *ngFor = "let site of data.userList | searchFilter : searchText:['customerName', 'siteName']; index as idx">
            <ng-container>
               <div class="card staffCard">
                  <div class="d-flex">
                     <div class="w-100 justify-content-top text-center pt-3 imgAtTop">
                        <img
                        class="card-img-top"
                        [src]="site?.customerLogo ? (baseURL + site?.customerLogo) : 'assets/common_images/profile.jpg'"
                        onerror="this.src='assets/common_images/profile.jpg';"
                     />
                     </div>
                     <div class="card-body text-center">
                        <h5 class="card-title">{{site?.customerName | capitalize}} </h5>
                        <div class="text-truncate email" title="{{site?.customerApartmentNo ? (site.customerApartmentNo+', ') : ''}}{{site?.siteName ? (site.siteName+', ') : ''}}{{site?.customerAddress ? (site.customerAddress+', ') : ''}}{{site?.customerCity ? (site.customerCity+', ') : ''}}{{site?.customerState ? (site.customerState+', ') : ''}}{{site?.customerCountry ? (site.customerCountry+', ') : ''}}{{site?.zipCode ? site.zipCode : ''}}">{{site?.siteName}}</div> 
                     </div>
                  </div>
               </div>
            </ng-container>
           </div>
           </ng-container>
           <ng-container *ngIf="isListView">
           <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 patient-table">
            <ng-container matColumnDef="avatarIc" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef width="45px"></th>
                  <td mat-cell *matCellDef="let element" width="45px">
                     <img
                        class="td_avatar_img"
                        [src]="element?.customerLogo ? (baseURL + element?.customerLogo) : 'assets/common_images/profile.jpg'"
                        onerror="this.src='assets/common_images/profile.jpg';"
                     />
                  </td>
             </ng-container>

            <ng-container matColumnDef="customerName" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Customer Name</th>
               <td width="150px" style="max-width: 250px;" mat-cell *matCellDef="let element" class="common-icon-round" class="text-nowrap text-truncate" title="{{element?.customerName | capitalize}}">
                 {{element?.customerName | capitalize}}
               </td>
             </ng-container>    
             
             <ng-container matColumnDef="siteIc" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef width="45px"></th>
                  <td mat-cell *matCellDef="let element" width="45px">
                     <span title="{{element?.siteName ? element.siteName : ''}}">
                        <i class="material-icons">
                          location_city
                        </i>
                      </span>
                  </td>
             </ng-container>

             <ng-container matColumnDef="jobSite" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">{{ 'main.labels.Job Site' | translate }}</th>
               <td mat-cell *matCellDef="let element" class="common-icon-round" class="text-nowrap">
                 {{element.siteName}}
               </td>
             </ng-container> 
             
             <ng-container matColumnDef="fullAddress" class="dis-icon">
               <th mat-header-cell *matHeaderCellDef class="text-nowrap">Address</th>
               <td mat-cell *matCellDef="let element" class="common-icon-round" class="text-nowrap">
                  <span *ngIf="element?.customerApartmentNo">{{element?.customerApartmentNo}}, </span>
                  <span *ngIf="element?.siteName">{{element?.siteName}}, </span>
                  <span *ngIf="element?.customerAddress">{{element?.customerAddress}}, </span>
                  <span *ngIf="element?.customerCity">{{element?.customerCity}}, </span>
                  <span *ngIf="element?.customerState">{{element?.customerState}}, </span>
                  <span *ngIf="element?.customerCountry">{{element?.customerCountry}}, </span>
                  <span *ngIf="element?.zipCode">{{element?.zipCode}}</span>
               </td>
             </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
         </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="(data.userList | searchFilter : searchText:['staffName', 'mobilePhone', 'email', 'customerName', 'siteName']) as result">       
         <p *ngIf="result.length === 0">Record Not Found</p>
      </ng-container>
     </div>
     <!-- <div class="d-flex offset-md-8 justify-content-end">
        <button mat-button (click)="onNoClick()" class="cancel-btn-primary">Cancel</button>
        <button type="submit" class="ms-2 pe-4 ps-4 nowrapTxt" mat-raised-button color="primary" (click)="!isLoading && onSubmit()"> {{ popupTitle === 'Add Team' ? 'Save' : 'Update'}} <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>
        </div> -->
  </div>
</div>