<div class="row">
    <div class="col-md-6">
        <span>
            <h6 class="title-pd mb-0">{{ 'common.labels.Broadcast Message' | translate }}</h6>
        </span>
        
    </div>
    <div class="col-md-6 text-end">
        <i class="material-icons cursor-pointer" (click)="onNoClick()"> close </i>
    </div>
    <div class="col-md-12 mt-3">
        <form class="example-form" [formGroup]="bcmForm" autocomplete="off">
            <div class="input-content">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                    <mat-label>{{ 'common.labels.Type a message' | translate }}</mat-label>
                    <textarea matInput placeholder="" formControlName="msg"></textarea>
                </mat-form-field>
                <mat-error class="help-block err"
                    *ngIf="bcmForm.get('msg').errors?.required && (!submitted || bcmForm.get('msg').touched)">
                    {{ 'common.labels.Please Enter Message' | translate }}
                </mat-error>
            </div>
        </form>
    </div>


    <div class="text-center col-md-12 mt-3">
        <button class="me-3" color="secondary" mat-button (click)="onNoClick()"> {{ 'common.labels.Cancel' | translate }} </button>
        <button color="primary" mat-raised-button (click)="sendMsg()"> {{ 'common.labels.Send' | translate }} </button>
    </div>
</div>