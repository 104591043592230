import { OrgapprovalComponent } from './orgapproval/orgapproval.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingService } from './onboarding.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { ProfileService } from '../profile/profile.service';
import { AddOrganizaionnoteComponent } from '../profile/add-organizaionnote/add-organizaionnote.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { AdddocumentnoteComponent } from '../profile/adddocumentnote/adddocumentnote.component';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  providers: [DatePipe],
})
export class OnboardingComponent implements OnInit {
  displayedColumns: string[] = ['skillName', 'actions'];
  dataSource = new MatTableDataSource();
  page = 0;
  limit = 10;
  skillList = [];
  skillsForm: FormGroup;
  filteredOptions: Observable<string[]>;
  options: any = [];
  imageMsg: string;
  fileUrl: any;
  filetitle: any;
  imageupload: boolean;
  skilldata: any;
  submittedSkill: boolean = true;
  editData;
  orgSkillList: any;
  resetSkill = true;
  displayedColumnsPat = ['patientName', 'phoneNumber', 'mobileNumber', 'patientAddress'];
  dataSourcePat = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public show: boolean = false;
  public searchClick: any = 'Show';

  patient = [];
  pagePat = 0;
  limitPat = 10;
  searchTerm;
  patientName = 'all';

  formData = new FormData();
  uploadPatient: FormGroup;
  submittedPat = true;
  resetPat = true;
  url = this.service.common.superAdminUrl + 'Sample-upload-patient-1594907709257.xls';


  // procedure 
  displayedColumnsProcedure: string[] = ['procedureIcon', 'procedureName', 'procedureId', 'procedureDescription', 'durationData', 'edit'];
  dataSourceProcedure = new MatTableDataSource();

  procedureList = [];
  public showProcedure: boolean = false;
  public searchClickProcedure: any = 'Show';
  pageProcedure = 0;
  limitProcedure = 10;
  dropdownSettings: any;
  submittedProcedure: boolean = true;
  fileUrlProcedure: any;
  filetitleProcedure: any;
  imageuploadProcedure: boolean;
  procedures: any = [];
  filteredOptionsProcedure: Observable<any>;
  procedureCode: any;
  procedureData: [];
  procDataByCode: [];
  selectedEndTime = [];
  durationSelectDropdown: any[];
  selectedProcedure: any[];
  skillsProcedure;
  optionsProcedure = [];
  procedureForm: FormGroup;
  cancelProcedure = false;
  resetProcedure = true;
  selectedStep = 2;
  @ViewChild('stepper') stepper: MatStepper;
  isSkillOptional = false;
  isProcedureOptional = false;
  uploadForm: FormGroup;
  submittedAdmin = true;
  allDocuments = [];
  pageDoc = 0;
  limitDoc = 10;
  displayedUploadColumns: string[] = ['documentName', 'documentStatus', 'expiryDate', 'rejectionComment', 'actions'];
  organizationDocumentCode;
  dataSourceUpload = new MatTableDataSource();
  today = new Date();
  uploadButton: boolean = false;
  orgReadCount = 0;
  uploadSubmitted = true;
  resetUpload: boolean = true;
  documentsCount: any;
  allOrgNotes = [];
  allNotes = [];
  showLoader: boolean = false;
  setting: FormGroup;
  officeStartTime = [];
  officeEndTime = [];
  selectedStartTime = [];
  selectedAlertTime = [];
  Operationalchecked;
  Officechecked;
  sun = true;
  mon = true;
  tue = true;
  wed = true;
  thu = true;
  fri = true;
  sat = true;
  submittedSet = true;
  timeMsgStart;
  timeMsgEnd;
  nonWorkingDaysSplitGlobal = [];
  nonWorkingDaysSplitLocal = [];
  todayDate = new Date();
  dateFormat;
  updateSettings = [];
  welcomeActive = false;
  isSettingOptional = false;
  isUploadOptional = false;
  uploadActive = false;
  workingActive = false;
  skillActive = false;
  procedureActive = false;
  patientActive = false;
  doneActive = false;
  isPatientOptional = false;
  selectedSkillItems =[]

  constructor(
    private formBuilder: FormBuilder,
    public service: OnboardingService,
    public translate: TranslateService,
    public router: Router,
    public uploadService: ProfileService,
    public dialog: MatDialog,
    private common: CommonService,
    public datepipe: DatePipe,
      
  ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Skills",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      // badgeShowLimit: 5,
      maxHeight:200,
      autoPosition: true
    };
  }
  ngAfterViewInit() {
    setTimeout(() => {
      //this.stepper.selectedIndex = 1; 
      this.stepper.selectedIndex = this.service.storage.get('step');
    }, 0);
    //console.log(this.service.storage.get('step'), this.stepper.selectedIndex);

  }

  ngOnInit() {
    this.service.skills.organizationCode = this.service.storage.get('orgCode');
    this.service.getAllSkills().subscribe((data) => {
      //console.log(data);
      this.skilldata = data;
      if (this.skilldata) {
        if (this.skilldata.responsecode === 200) {
          this.skilldata.data.forEach(skills => {
            this.options.push({ "name": skills.skillName, "value": skills.skillCode });
          });
          //console.log(this.options);
          this.filteredOptions = this.skillsForm.controls['name'].valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
          );
          this.service.common.progressLoader = false;
        } else {
          this.service.common.progressLoader = false;
        }
      }
    });
    this.getSkillList(this.page);
    this.cancelForm();
    this.getFormSetup();
    this.getData(this.page, this.limit);
    this.getDataProcedure(this.pageProcedure);
    this.setUploadform();
    this.getallDocuments(this.pageDoc);
    this.resetFormDataProcedure();
    this.service.getAllProcedureMaster((procedureResponse) => {
      //////console.log(procedureResponse);
      if (procedureResponse.responsecode == 200) {
        this.service.common.progressLoader = false;
        this.procedures = procedureResponse.data;
        this.selectedProcedure = this.procedures;
      } else {
        this.service.common.progressLoader = false;
      }
    });
    setTimeout(() => {
      if (this.skillList.length > 0) {
        this.isSkillOptional = true;
      }
      if (this.procedureList.length > 0) {
        this.isProcedureOptional = true;
      }
      if (this.service.settings[10].localSettingValue != null) {
        this.isSettingOptional = true;
      }
      if (this.allDocuments.length > 0 && this.service.storage.get('orgStatus') !== 'Pending') {
        this.isUploadOptional = true;
      }
      //console.log(this.isSkillOptional, this.isProcedureOptional, this.isSettingOptional, this.isUploadOptional);
      setTimeout(() => {
        //this.stepper.selectedIndex = 1; 
        this.stepper.selectedIndex = this.service.storage.get('step');
      }, 0);
      //console.log(this.service.storage.get('step'), this.stepper.selectedIndex);
    }, 2000);

    this.setting = new FormGroup({
      'offmon': new FormControl(null),
      'offtue': new FormControl(null),
      'offwed': new FormControl(null),
      'offthu': new FormControl(null),
      'offfri': new FormControl(null),
      'offsat': new FormControl(null),
      'offsun': new FormControl(null),
      'office_starttime': new FormControl(null, Validators.required),
      'office_endtime': new FormControl(null, Validators.required),
      'operationalDays': new FormControl(null, Validators.required),
      'officeDays': new FormControl(null, Validators.required),
      'mon': new FormControl(null),
      'tue': new FormControl(null),
      'wed': new FormControl(null),
      'thu': new FormControl(null),
      'fri': new FormControl(null),
      'sat': new FormControl(null),
      'sun': new FormControl(null),
      'user_starttime': new FormControl(null, Validators.required),
      'user_endtime': new FormControl(null, Validators.required),
      'workingDays': new FormControl(null),
      'nonworking_days2': new FormControl(null),
    });

    try {
      this.common.getTimeSlotByOrganisationCode(this.service.storage.get('orgCode'), (response) => {
        if (response.responsecode == 200) {
          this.common.timeIntervals = response.data;
          this.selectedStartTime = this.common.timeIntervals;
          this.selectedEndTime = this.common.timeIntervals;
          this.selectedAlertTime = this.common.timeIntervals;
          this.officeStartTime = this.common.timeIntervals;
          this.officeEndTime = this.common.timeIntervals;
          //////console.log(this.common.timeIntervals);
        }
      });
    } catch (error) {
      //console.log(error)
    }

    this.getAllsettings(this.service.storage.get('orgCode'));
  }

  getAngularDropwownList(lsitData: any){
    return this.common.getAngularDropwownList(lsitData);
  }

  onItemSelect(item: any) {
    //console.log('onItemSelect', item);
    //console.log(this.selectedSkillItems);
  }
  OnItemDeSelect(item: any) {
    //console.log(item);
    //console.log(this.selectedSkillItems);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  onDeSelectAll(items: any) {
    //console.log(items);
  }

  getAllsettings(orgCode) {
    this.common.progressLoader = true;
    try {
      this.service.getUserPreferencesLocalSettingWithDefaultGlobalSettingByOrganizationCode(orgCode, (data) => {
        if (data.responsecode == 200) {
          this.service.settings = data.data;
          //console.log(this.service.settings);

          if (!this.service.settings[0].globalSettingValue && !this.service.settings[1].globalSettingValue && !this.service.settings[2].globalSettingValue && !this.service.settings[3].globalSettingValue && !this.service.settings[4].globalSettingValue && !this.service.settings[5].globalSettingValue && !this.service.settings[6].globalSettingValue) {
            ////////console.log(this.Operationalchecked + '.....if');
            this.Operationalchecked = false;
          } else {
            this.Operationalchecked = true;
            ////////console.log(this.Operationalchecked + '.....else');
            this.setting.controls['operationalDays'].setValue(this.Operationalchecked);
          }

          if (!this.service.settings[11].globalSettingValue && !this.service.settings[12].globalSettingValue && !this.service.settings[13].globalSettingValue && !this.service.settings[14].globalSettingValue && !this.service.settings[15].globalSettingValue && !this.service.settings[16].globalSettingValue && !this.service.settings[17].globalSettingValue) {
            ////////console.log(this.Officechecked + '.....if');
            this.Officechecked = false;
          } else {
            this.Officechecked = true;
            ////////console.log(this.Officechecked + '.....else');
            this.setting.controls['officeDays'].setValue(this.Officechecked);
          }

          if (this.service.settings[10].localSettingValue == null) {
            this.setting.controls['nonworking_days2'].setValue(this.service.settings[10].globalSettingValue);
            this.service.settings[10].localSettingValue = this.service.settings[10].globalSettingValue;
            this.isSettingOptional = true;
          } else {
            this.setting.controls['nonworking_days2'].setValue(this.service.settings[10].localSettingValue);
            this.isSettingOptional = false;
          }

          if (this.service.settings[11].localSettingValue != null && this.service.settings[11].localSettingValue == 'true') {
            this.mon = false;
          } else if (this.service.settings[11].globalSettingValue == 'true') {
            this.mon = false;
          }

          if (this.service.settings[12].localSettingValue != null && this.service.settings[12].localSettingValue == 'true') {
            this.tue = false;
          } else if (this.service.settings[12].globalSettingValue == 'true') {
            this.tue = false;
          }

          if (this.service.settings[13].localSettingValue != null && this.service.settings[13].localSettingValue == 'true') {
            this.wed = false;
          } else if (this.service.settings[13].globalSettingValue == 'true') {
            this.wed = false;
          }

          if (this.service.settings[14].localSettingValue != null && this.service.settings[14].localSettingValue == 'true') {
            this.thu = false;
          } else if (this.service.settings[14].globalSettingValue == 'true') {
            this.thu = false;
          }

          if (this.service.settings[15].localSettingValue != null && this.service.settings[15].localSettingValue == 'true') {
            this.fri = false;
          } else if (this.service.settings[15].globalSettingValue == 'true') {
            this.fri = false;
          }

          if (this.service.settings[16].localSettingValue != null && this.service.settings[16].localSettingValue == 'true') {
            this.sat = false;
          } else if (this.service.settings[16].globalSettingValue == 'true') {
            this.sat = false;
          }

          if (this.service.settings[17].localSettingValue != null && this.service.settings[17].localSettingValue == 'true') {
            this.sun = false;
          } else if (this.service.settings[17].globalSettingValue == 'true') {
            this.sun = false;
          }

          this.service.settings.forEach(element => {
            element.userCode = orgCode;
          });

          this.common.progressLoader = false;
        } else {
          this.common.progressLoader = false;
        }
      });
    } catch (error) {
      this.common.progressLoader = false;
      //console.log(error)
    }
  }

  timeChange(event, time) {
    ////console.log(event);
    ////console.log(time);
    const officStart = this.setting.controls['office_starttime'].value;
    const officEnd = this.setting.controls['office_endtime'].value;

    ////console.log(officStart + ' ---- ' + officEnd);

    if (time == 'start') {
      if (event.value >= officStart && event.value <= officEnd) {
        ////console.log('IN');
        this.timeMsgStart = '';
      } else {
        this.setting.controls['user_starttime'].setValue(null);
        //this.setting.controls['user_endtime'].setValue(null);
        this.timeMsgStart = 'Operational start Time choosed should be between office start time and end time';
        ////console.log('Time should be betwen start and end time of office days')
      }
    }
    if (time == 'end') {
      if (event.value >= officStart && event.value <= officEnd) {
        ////console.log('IN');
        this.timeMsgEnd = '';
      } else {
        this.setting.controls['user_endtime'].setValue(null);
        this.timeMsgEnd = 'Operational end Time should be between office start time and end time';
        ////console.log('Time should be betwen start and end time of office days')
      }
    }
  }

  timeChangeOffice(event, time) {
    ////console.log(event);
    ////console.log(time);
    const officStart = this.setting.controls['user_starttime'].value;
    const officEnd = this.setting.controls['user_endtime'].value;

    ////console.log(officStart + ' ---- ' + officEnd);

    if (time == 'start') {
      if (event.value > officStart) {
        this.setting.controls['user_starttime'].setValue(null);
        this.timeMsgStart = 'Operational start Time choosed should be between office start time and end time';
      } else {
        this.timeMsgStart = '';
      }
    }

    if (time == 'end') {
      if (event.value < officEnd) {
        this.setting.controls['user_endtime'].setValue(null);
        this.timeMsgEnd = 'Operational end Time choosed should be between office start time and end time';
      } else {
        this.timeMsgEnd = '';
      }
    }
  }

  /* Name:Shailesh
      use: for setting operation days */
  OperationalDaysValid(event) {
    ////console.log(event);
    if (event.checked) {
      this.Operationalchecked = true;
      this.setting.controls['operationalDays'].setValue(this.Operationalchecked);
    }
    else {
      if (!this.setting.value.mon && !this.setting.value.tue && !this.setting.value.wed && !this.setting.value.thu && !this.setting.value.fri && !this.setting.value.sat && !this.setting.value.sun) {
        ////////console.log(this.Operationalchecked + '.....if');
        this.Operationalchecked = false;
        this.setting.controls['operationalDays'].setValue(null);
      } else {
        this.Operationalchecked = true;
        ////////console.log(this.Operationalchecked + '.....else');
        this.setting.controls['operationalDays'].setValue(this.Operationalchecked);
      }
    }
  }


  OfficeDaysValid(event, day) {
    ////////console.log(event.checked);
    if (event.checked) {
      //console.log(day);
      if (day == 'sun') { this.sun = false; }
      if (day == 'mon') { this.mon = false; }
      if (day == 'tue') { this.tue = false; }
      if (day == 'wed') { this.wed = false; }
      if (day == 'thu') { this.thu = false; }
      if (day == 'fri') { this.fri = false; }
      if (day == 'sat') { this.sat = false; }
      this.Officechecked = true;
      this.setting.controls['officeDays'].setValue(this.Officechecked);
    } else {
      this.setting.controls[day].setValue(false);
      if (day == 'sun') { this.sun = true; }
      if (day == 'mon') { this.mon = true; }
      if (day == 'tue') { this.tue = true; }
      if (day == 'wed') { this.wed = true; }
      if (day == 'thu') { this.thu = true; }
      if (day == 'fri') { this.fri = true; }
      if (day == 'sat') { this.sat = true; }
      if (!this.setting.value.offmon && !this.setting.value.offtue && !this.setting.value.offwed && !this.setting.value.offthu && !this.setting.value.offfri && !this.setting.value.offsat && !this.setting.value.offsun) {
        ////////console.log(this.Officechecked + '.....if');
        this.Officechecked = false;
        this.setting.controls['officeDays'].setValue(null);
      } else {
        this.Officechecked = true;
        ////////console.log(this.Officechecked + '.....else');
        this.setting.controls['officeDays'].setValue(this.Officechecked);
      }
    }


  }


  searchStart(query, type) {
    // //////console.log('query', query);
    let result = this.selectStart(query)
    if (type == 'office') {
      this.officeStartTime = result;
    } else {

      this.selectedStartTime = result;
    }
  }

  selectStart(query) {
    let result = [];
    for (let a of this.common.timeIntervals) {
      // ////console.log(a);
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result;
  }

  searchEndTime(query, type) {
    // //////console.log('query', query);
    let result = this.selectEndTime(query)
    if (type == 'office') {
      this.officeEndTime = result;
    } else {
      this.selectedEndTime = result;
    }
  }

  selectEndTime(query) {
    let result = [];
    for (let a of this.common.timeIntervals) {
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result;
  }


  /* Name:Shailesh
    use: for getting all nonworking days */
  nonWorkingDays(event) {
    //////console.log(event.value);
    this.dateFormat = this.datepipe.transform(event.value, 'dd MMMM yyyy');
    // this.chipsDate.push(this.dateFormat);

    if (this.service.settings[10].localSettingValue != null) {
      this.nonWorkingDaysSplitLocal = this.service.settings[10].localSettingValue.split(',');
    }

    if (this.nonWorkingDaysSplitLocal.indexOf(this.dateFormat) == -1) {
      if (this.service.settings[10].globalSettingName == 'Specific.Non.Working.Days' && this.service.settings[10].localSettingValue != null) {
        this.service.settings[10].localSettingValue += ',' + this.dateFormat;
      }
    }
    this.setting.controls['workingDays'].setValue(null);
  }

  onRemoveChip(index) {
    //////console.log(index);
    if (this.service.settings[10].localSettingValue != null) {
      this.nonWorkingDaysSplitLocal = this.service.settings[10].localSettingValue.split(',');
      this.nonWorkingDaysSplitLocal.splice(index, 1);
      this.service.settings[10].localSettingValue = this.nonWorkingDaysSplitLocal.toString();
    }
  }

  onSubmitWorkingHours() {
    if (this.setting.valid) {
      this.submittedSet = true;
      this.service.settings[0].localSettingValue = this.setting.value.mon;
      this.service.settings[1].localSettingValue = this.setting.value.tue;
      this.service.settings[2].localSettingValue = this.setting.value.wed;
      this.service.settings[3].localSettingValue = this.setting.value.thu;
      this.service.settings[4].localSettingValue = this.setting.value.fri;
      this.service.settings[5].localSettingValue = this.setting.value.sat;
      this.service.settings[6].localSettingValue = this.setting.value.sun;
      this.service.settings[7].localSettingValue = this.setting.value.user_starttime;
      this.service.settings[8].localSettingValue = this.setting.value.user_endtime;
      //this.service.settings[3].UserPreferences[9].localSettingValue = this.setting.value.user_offhours;
      this.service.settings[10].localSettingValue = this.setting.value.nonworking_days2;

      this.service.settings[11].localSettingValue = this.setting.value.offmon;
      this.service.settings[12].localSettingValue = this.setting.value.offtue;
      this.service.settings[13].localSettingValue = this.setting.value.offwed;
      this.service.settings[14].localSettingValue = this.setting.value.offthu;
      this.service.settings[15].localSettingValue = this.setting.value.offfri;
      this.service.settings[16].localSettingValue = this.setting.value.offsat;
      this.service.settings[17].localSettingValue = this.setting.value.offsun;

      this.service.settings[18].localSettingValue = this.setting.value.office_starttime;
      this.service.settings[19].localSettingValue = this.setting.value.office_endtime;

      this.service.settings.forEach(element => {
        this.updateSettings.push(element);
      });

      try {
        this.service.updateSettingByUserCode(this.service.storage.get('orgCode'), this.updateSettings, (res) => {
          //////console.log(res);
          if (res.responsecode == 200) {
            this.common.message = this.translate.instant('setting.messages.' + res.message);
            this.common.successMessage = true;
            this.getAllsettings(this.service.storage.get('orgCode'));
            this.common.getColors();
          } else {
            this.common.message = this.translate.instant('setting.messages.' + res.message);
            this.common.errorMessage = true;
          }
        })
      } catch (error) {
        //console.log(error)
      }
    } else {
      this.submittedSet = false;
    }
  }


  editProcedureData(procedureCode) {
    if (procedureCode) {
      this.service.common.progressLoader = true;
      this.procedureCode = procedureCode;
      this.service.procedureForm.procedureCode = this.procedureCode;

      try {
        this.service.getProcedureByOrganizationBasedProcedureCode(this.procedureCode, (response) => {
          //console.log(response);
          this.skillsProcedure = response.data.skillMasterList;
          if (response.responsecode == 200) {
            this.service.getAllProcedureMaster((procedureResponse) => {
              //////console.log(procedureResponse);
              if (procedureResponse.responsecode == 200) {
                this.service.common.progressLoader = false;
                this.procedures = procedureResponse.data;
                this.selectedProcedure = procedureResponse.data;
              } else {
                this.service.common.progressLoader = false;
              }
            });
            this.service.procedureForm.procedureName = response.data.procedureName;
            this.service.procedureForm.procedureDescription = response.data.procedureDescription;
            this.service.procedureForm.procedureCode = response.data.procedureCode;
            this.service.procedureForm.procedureId = response.data.procedureId;
            this.service.procedureForm.organizationBaseProcedureCode = response.data.organizationBaseProcedureCode;
            this.service.procedureForm.organizationCode = response.data.organizationCode;
            this.service.procedureForm.id = response.data.id;
            this.service.procedureForm.duration = response.data.duration;
            this.service.procedureForm.skillMasterList = response.data.skillMasterList !== null ? response.data.skillMasterList : [];
            this.service.procedureForm.isDateFlexible = response.data.isDateFlexible;
            ////console.log(response.data.skillMasterList);
            if (response.data.skillMasterList && response.data.skillMasterList.length > 0) {
              this.procedureForm.controls['selctedskills'].setValue(response.data.skillMasterList);
            }
            //////console.log(this.service.procedureForm.duration);
            if (response.data.procedureIcon) {
              this.fileUrlProcedure = this.service.service.getSuperAdmin() + '/downloadFile/' + response.data.procedureIcon;
              this.service.procedureForm.procedureIcon = response.data.procedureIcon;
              this.procedureForm.controls['procedureIcon'].setValue(response.data.procedureIcon);
              this.imageuploadProcedure = true;
            }
          }
          setTimeout(() => {
            this.cancelProcedure = false;
          }, 500);
        })
      } catch (error) {
        //console.log(error)
      }

    }
  }
  resetFormDataProcedure() {

    this.procedureDataReset();
    this.procedureForm = new FormGroup({
      'procedureIcon': new FormControl(null, Validators.required),
      'procedureName': new FormControl(null, Validators.required),
      'name': new FormControl(null, Validators.required),
      'skills': new FormControl(null),
      'selctedskills': new FormControl(null, Validators.required),
      "isDateFlexible": new FormControl(false)
    });

    this.resetProcedure = false;
    setTimeout(() => {
      this.resetProcedure = true;
    });
    this.getAllSkilsProcedure();



    this.filteredOptionsProcedure = this.procedureForm.controls['skills'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterProcedure(value))
    );
  }

  onSubmitProcedure() {
    //console.log(this.procedureForm);
    if (this.procedureForm.valid) {
      this.submittedProcedure = true;
      //////console.log(this.service.procedureForm);
      this.service.procedureForm.organizationCode = this.service.storage.get('orgCode');
      if (this.procedureCode) {
        try {
          this.service.updateProcedureByOrganizationBasedProcedureCode(this.service.procedureForm.organizationBaseProcedureCode, this.service.procedureForm, (response) => {
            //////console.log(response);
            if (response.responsecode == 200) {
              this.service.common.message = response.message;
              this.service.common.successMessage = true;
              this.imageuploadProcedure = false;
              this.resetFormDataProcedure();
              this.pageProcedure = 0;
              this.getDataProcedure(this.pageProcedure);
              this.isProcedureOptional = true;
            } else {
              this.service.common.message = response.message;
              this.service.common.errorMessage = true;
            }
          })
        } catch (error) {
          //console.log(error)
        }

      } else {
        try {
          this.service.saveOrganizationBasedProcedure(this.service.procedureForm, (response) => {
            //////console.log(response);
            if (response.responsecode == 200) {
              this.resetFormDataProcedure();
              this.service.common.message = response.message;
              this.service.common.successMessage = true;
              this.imageuploadProcedure = false;
              this.pageProcedure = 0;
              this.getDataProcedure(this.pageProcedure);
              this.isProcedureOptional = true;
            } else {
              this.service.common.message = response.message;
              this.service.common.errorMessage = true;
            }
          })
        } catch (error) {
          //console.log(error)
        }

      }
    } else {
      this.submittedProcedure = false;
    }
  }

  getProcedureData(event) {
    //////console.log(event);
    const index = this.procedures.findIndex(data => data.procedureCode == event.value);
    //////console.log(index);
    if (index !== -1)
      // ////console.log(this.procedures[index]);
      this.skillsProcedure = this.procedures[index].skillMasterList;
    this.setProcedureData(this.procedures[index]);

  }

  setProcedureData(element) {
    //console.log(element);
    if (element) {
      this.service.procedureForm.procedureName = element.procedureName;
      this.service.procedureForm.procedureDescription = element.procedureDescription;
      this.service.procedureForm.procedureCode = element.procedureCode;
      this.service.procedureForm.procedureId = element.procedureId;
      //this.service.procedureForm.duration = element.duration;
      if (element.skillMasterList.length > 0) {
        this.procedureForm.controls['selctedskills'].setValue(element.skillMasterList);
        this.service.procedureForm.skillMasterList = element.skillMasterList! == null ? element.skillMasterList : [];
      }
      if (element.procedureIcon) {
        //////console.log(this.fileUrl);
        this.fileUrlProcedure = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
        this.service.procedureForm.procedureIcon = element.procedureIcon;
        this.procedureForm.controls['procedureIcon'].setValue(element.procedureIcon);
        this.imageuploadProcedure = true;
      }
      if (element.organizationBaseProcedureCode) {
        this.service.procedureForm.organizationBaseProcedureCode = element.organizationBaseProcedureCode;
      }
      if (element.id) {
        this.service.procedureForm.id = element.id;
      }
    }
  }
  onFileChangeProcedure(event) {
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      try {
        this.service.common.uploadImage(formData, (res) => {
          //////console.log(res);
          this.fileUrlProcedure = res.fileurl;
          this.filetitleProcedure = res.filename;
          this.service.procedureForm.procedureIcon = res.filename;
          this.procedureForm.controls['procedureIcon'].setValue(res.filename);
          //////console.log(this.service.procedureForm.procedureIcon);
          this.imageuploadProcedure = true;
        })
      } catch (error) {
        //console.log(error)
      }

    }
  }

  selectprocedure(query) {
    // //////console.log(query);
    let result = [];
    for (let a of this.procedures) {
      //////console.log(query, a.procedureName.toLowerCase());
      //////console.log(a.procedureName.toLowerCase().includes(query.toLowerCase()));
      if (a.procedureName.toLowerCase().includes(query.toLowerCase())) {
        result.push(a);
      }
    }
    return result;
  }

  searchprocedure(query) {
    let result = this.selectprocedure(query);
    this.selectedProcedure = result;
  }
  getAllSkilsProcedure() {
    try {
      this.service.getAllSkillsProcedure(this.service.storage.get('orgCode'), (skills) => {
        if (skills.responsecode == 200) {
          this.optionsProcedure = skills.data;
          //console.log(this.optionsProcedure);
          this.filteredOptionsProcedure = this.procedureForm.controls['skills'].valueChanges.pipe(
            startWith(''),
            map(value => this._filterProcedure(value))
          );
        } else {
          this.optionsProcedure = [];
        }
      });
    } catch (error) {
      //console.log(error)
    }

  }
  procedureDataReset() {
    this.service.procedureForm = {
      "duration": "",
      "id": 0,
      "organizationBaseProcedureCode": "",
      "organizationCode": "",
      "procedureCode": "",
      "procedureDescription": "",
      "procedureIcon": "",
      "procedureName": "",
      "procedureId": "",
      "skillMasterList": [],
      "isDateFlexible": false
    }
  }

  selectedSkill(skill) {
    //console.log(skill);
    const index = this.service.procedureForm.skillMasterList ? this.service.procedureForm.skillMasterList.findIndex((element) => element.skillName == skill.option.value.skillName) : -1;
    //console.log(this.service.procedureForm.skillMasterList);
    if (index == -1) {
      this.service.procedureForm.skillMasterList.push(skill.option.value);
      ////console.log(this.service.procedureForm.skillMasterList);
      this.procedureForm.controls['selctedskills'].setValue(skill.option.value);
      this.procedureForm.controls['skills'].setValue(null);
      ////console.log(this.procedureForm.controls['skills'].value);
    } else {
      this.procedureForm.controls['skills'].setValue(null);
    }
  }


  removeOrg(i) {
    ////console.log(i);
    this.service.procedureForm.skillMasterList.splice(i, 1);
    if (this.service.procedureForm.skillMasterList.length == 0) {
      this.procedureForm.controls['selctedskills'].setValue(null);
    }
  }
  getDataProcedure(page, scroll = null) {
    if (!scroll) {
      this.service.common.progressLoader = true;
    } else if (scroll) {
      this.service.common.infiniteLoader = true;
    }
    try {
      this.service.getAllProcedureByOrganizationCodeByPage(this.service.storage.get('orgCode'), page, this.limitProcedure, (response) => {
        //////console.log(response);
        if (response.responsecode == 200) {
          if (scroll) {
            let arrayData = this.dataSourceProcedure.data;
            response.data.forEach(element => {
              const index = this.service.common.durationHours.findIndex(data => data.value == element.duration);
              if (index !== -1) {
                element.durationData = this.service.common.durationHours[index].text;
              }
              arrayData.push(element);
            });
            setTimeout(() => {
              this.procedureList = arrayData;
              this.dataSourceProcedure = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSourceProcedure.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSourceProcedure.sort = this.sort;
              this.service.common.progressLoader = false;
              this.service.common.infiniteLoader = false;
            }, 1000);
          } else {
            response.data.forEach(element => {
              const index = this.service.common.durationHours.findIndex(data => data.value == element.duration);
              if (index !== -1) {
                element.durationData = this.service.common.durationHours[index].text;
              }
            });

            this.procedureList = response.data;
            this.dataSourceProcedure = new MatTableDataSource(response.data);
            ////console.log(response.data)
            setTimeout(() => {
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSourceProcedure.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSourceProcedure.sort = this.sort;
              // this.dataSource.paginator = this.paginator;
              this.service.common.progressLoader = false;
              this.service.common.infiniteLoader = false;
            });

          }

        } else {
          if (!scroll) {
            this.procedureList = [];
            this.dataSourceProcedure = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSourceProcedure.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSourceProcedure.sort = this.sort;
          } else {
            this.service.common.infiniteLoader = false;
          }
          this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }
  onScrollProcedure() {
    //////console.log('scroll');
    this.pageProcedure = this.pageProcedure + 1;
    this.getDataProcedure(this.pageProcedure, 'scroll');
  }

  private _filter(value): string[] {
    //console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        this.skillsForm.controls['skillcode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        this.skillsForm.controls['skillcode'].setValue(value.value);
        this.service.skills.skillList[0].skillCode = value.value;
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  displayFn(option): string | undefined {
    return option ? option.name : undefined;
  }

  private _filterProcedure(value) {
    //console.log(value);
    if (value && value != '') {
      //console.log('IN');
      //const filterValue = ('' + value).toLowerCase();

      const filterValue = value;
      //return this.options.filter((option) => option.skillName.toLowerCase().includes(filterValue.toLowerCase()));
      return this.optionsProcedure.filter((option) => option.skillName.toLowerCase().indexOf(filterValue.toLowerCase()) == 0);
    } else {
      //console.log('OUT');
      return this.optionsProcedure;
    }
  }

  displayFnProcedure(option): string | undefined {
    return option ? option.skillName : undefined;
  }

  onFileChangeSkill(event) {
    //////console.log(event);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      let allowedExtension = ['jpeg', 'jpg', 'png', 'bmp'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      let isValidFile = false;

      for (let index in allowedExtension) {

        if (fileExtension === allowedExtension[index] && event.target.files[0].size <= 2000000) {
          isValidFile = true;
          this.imageMsg = '';
          this.service.common.uploadImage(formData, (res) => {
            //////console.log(res);
            this.fileUrl = res.fileurl;
            this.filetitle = res.filename;
            this.service.skills.skillList[0].skillImage = res.filename;
            //////console.log(this.skillService.skills.skillList[0].skillImage);
            this.imageupload = true;
          })
          break;
        }
      }
      if (!isValidFile) {
        event.srcElement.value = '';
        event.target.files = null;
        this.imageMsg = 'Allowed Extensions are : *.' + allowedExtension.join(', *.') + ' and file size should be less then 2MB';
      }
    }
  }

  getSkillList(page, scroll = null) {
    try {
      if (!scroll) {
        this.service.common.progressLoader = true;
      }
      this.service.getSkillsByOrganizationCodeByPage(this.service.storage.get('orgCode'), page, this.limit, (data) => {
        //////console.log(data);
        if (data.responsecode == 200) {

          if (scroll) {
            let arrayData = this.dataSource.data;
            data.data.forEach(element => {
              if (element.skillImage != null) {
                element.imageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + element.skillImage;
              }
              arrayData.push(element);
            });

            setTimeout(() => {
              this.skillList = arrayData;
              this.dataSource = new MatTableDataSource(this.skillList);// Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.progressLoader = false;

            }, 1000);

          } else {
            this.skillList = data.data;
            //////console.log(this.skillList);

            this.skillList.forEach(element => {
              if (element.skillImage != null) {
                element.imageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + element.skillImage;
              }
            });
            this.dataSource = new MatTableDataSource(this.skillList);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }

        } else {
          this.service.common.progressLoader = false;
          if (!scroll) {
            this.dataSource = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.skillList = [];

          }
        }
      });
    } catch (error) {
      //console.log(error)
    }

  }

  onSkillSubmit() {
    try {
      //console.log(this.skillsForm);
      if (this.skillsForm.valid) {
        this.submittedSkill = true;

        this.service.saveSkills(this.service.skills, (response) => {

          if (response.responsecode == 200) {

            this.service.common.successMessage = true;
            this.service.common.message = response.message;
            this.resetSkill = false;
            this.cancelForm();
            this.page = 0;
            this.getSkillList(this.page);
            this.isSkillOptional = true;
            this.getAllSkilsProcedure();
            setTimeout(() => {
              this.resetSkill = true;
            });

          } else {
            this.service.common.errorMessage = true;
            this.service.common.message = response.message;
          }
        })
      } else {
        this.submittedSkill = false;
      }
    } catch (error) {
      //console.log(error)
    }
    //////console.log(this.skillsForm);
    //////console.log(this.skillService.skills);

  }

  onSkillScroll() {
    //////console.log('scroll');
    this.page = this.page + 1;
    this.getSkillList(this.page, 'scroll');
  }

  onEdit(element) {
    //console.log(element);
    this.editData = element;
    this.skillsForm.controls['name'].setValue({ name: element.skillName, code: element.skillCode });
    this.skillsForm.controls['skillcode'].setValue(element.skillCode);
    if (element.skillImage != null) {
      this.service.skills.skillList[0].skillCode = element.skillCode;
      this.service.skills.skillList[0].skillImage = element.skillImage;

    } else {
      this.imageupload = false;
    }

  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.fileUrl = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  cancelForm() {
    this.skillsForm = new FormGroup({
      "name": new FormControl(null, [Validators.required]),
      "skillcode": new FormControl(null, [Validators.required]),
    });
    this.filteredOptions = this.skillsForm.controls['name'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.resetSkill = false;
    setTimeout(() => {
      this.resetSkill = true;
    });
  }
  applyFilter(filterValue: string) {
    this.dataSourcePat.filter = filterValue.trim().toLowerCase();
  }

  getData(page, limit, scroll = null, search = null) {
    if (!scroll && !search) {
      this.service.common.progressLoader = true;
    }
    else if (scroll) {
      this.service.common.infiniteLoader = true;
    }
    try {
      this.service.getPatientDetailByOrganizationCodeByPage(this.service.storage.get('orgCode'), page, limit, this.patientName, (response) => {
        // this.service.common.progressLoader = false;
        ////console.log(response)
        if (response.responsecode == 200) {
          if (scroll) {
            let arrayData = this.dataSourcePat.data;
            response.data.forEach(element => {
              arrayData.push(element)
            });
            setTimeout(() => {
              this.patient = arrayData;
              this.dataSourcePat = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSourcePat.sort = this.sort;
              this.service.common.progressLoader = false;
              this.service.common.infiniteLoader = false;
            }, 1000);

          } else {
            //////console.log(response.data);
            setTimeout(() => {
              this.patient = response.data;
              this.dataSourcePat = new MatTableDataSource(this.patient);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSourcePat.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSourcePat.sort = this.sort;
              this.service.common.progressLoader = false;
              this.service.common.infiniteLoader = false;
            }, 1000)

          }
          if (this.patient.length > 0) {
            this.isPatientOptional = true;
          } else {
            this.isPatientOptional = false;
          }
        } else {
          if (!scroll) {
            this.patient = [];
            setTimeout(() => {
              this.dataSourcePat = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSourcePat.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSourcePat.sort = this.sort;
            }, 1000)

          } else {
            this.service.common.infiniteLoader = false;
          }
          this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  onScroll() {
    ////console.log('scroll');
    this.pagePat = this.pagePat + 1;
    this.getData(this.pagePat, this.limitPat, 'scroll');
  }

  search() {

    if (this.searchTerm) {
      this.patientName = this.searchTerm.toLowerCase();
      this.getData(this.pagePat = 0, this.limitPat = 10, null, 'search');
    } else {
      this.patientName = 'all';
      this.getData(this.pagePat = 0, this.limitPat = 10, null, 'search');

    }
  }

  getFormSetup() {
    this.uploadPatient = new FormGroup({
      uploadFile: new FormControl(null, Validators.required),
      organizationCode: new FormControl(null, Validators.required),
    });
    this.formData = new FormData();
    this.formData.append("organizationCode", this.service.storage.get('orgCode'));
    this.uploadPatient.controls['organizationCode'].setValue(this.service.storage.get('orgCode'));
  }

  onFileChange(event) {
    //console.log(event);
    //console.log(event.target.files[0]);
    var _validFileExtensions = [".xls", ".xlsx"];
    if (event.target.files.length > 0) {

      var sFileName = event.target.value;
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
          blnValid = true;
          this.formData.append("file", event.target.files[0]);
          this.uploadPatient.controls['uploadFile'].setValue(event.target.files[0].name);
          break;
        }
      }

      if (!blnValid) {
        alert("Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
        return false;
      }

    }
  }

  onPatSubmit() {
    //console.log(this.uploadPatient);
    //console.log(this.formData);
    if (this.uploadPatient.valid) {
      this.submittedPat = true;
      //console.log(this.uploadPatient);
      this.service.common.progressLoader = true;
      this.service.uploadFile(this.formData, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.successMessage = true;
          this.getFormSetup();
          this.resetPat = false;
          setTimeout(() => {
            this.resetPat = true;
          });
          this.getData(this.pagePat = 0, this.limitPat = 10, null, null);
        } else {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.errorMessage = true;
          this.getFormSetup();
        }
      });
    } else {
      this.submittedPat = false;
    }
  }

  getNextStep(step) {
    //console.log(step);
    if (step == 2 && this.service.storage.get('orgStatus') == 'Pending') {
      //console.log('pending');
      const dialogRef = this.dialog.open(OrgapprovalComponent, {
        width: '350px'
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log(result);

      });
    } else {

      if(step == 2) {
        this.isUploadOptional = true;
      }
      let form = {
        "isOnboardingCompleted": step !== 7 ? false : true,
        "stepNo": step
      };
      this.service.updateOrganizationOnboarding(this.service.storage.get('organizationOnboardingCode'), form, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.service.storage.set('step', step);
          if (step == 7) {
            this.service.storage.set('isOnboarding', false);
            this.router.navigate(['/dashboard/appointment']);
          }
        } else {
          this.service.common.message = response.message;
          this.service.common.errorMessage = true;
        }
      })
    }
  }

  onStepChange(event) {
    //console.log(event);
    //console.log(event.previouslySelectedIndex);
    //console.log(event.previouslySelectedIndex == 2 && this.service.storage.get('orgStatus') == 'Pending');
    if (event.previouslySelectedIndex == 2 && this.service.storage.get('orgStatus') == 'Pending') {
      //console.log('pending');
      const dialogRef = this.dialog.open(OrgapprovalComponent, {
        width: '350px'
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log(result);

      });
    } else {

      this.getNextStep(event.selectedIndex);
      if (event.selectedIndex >= 1) {
        this.welcomeActive = true;
      } else if (event.selectedIndex == 0) {
        this.welcomeActive = false;
      }
      if (event.selectedIndex >= 2) {
        this.uploadActive = true;
      } else if (event.selectedIndex == 1) {
        this.uploadActive = false;
      }
      if (event.selectedIndex >= 3) {
        this.workingActive = true;
      } else if (event.selectedIndex == 2) {
        this.workingActive = false;
      }
      if (event.selectedIndex >= 4) {
        this.skillActive = true;
      } else if (event.selectedIndex == 3) {
        this.skillActive = false;
      }
      if (event.selectedIndex >= 5) {
        this.procedureActive = true;
      } else if (event.selectedIndex == 4) {
        this.procedureActive = false;
      }
      if (event.selectedIndex >= 6) {
        this.patientActive = true;
      } else if (event.selectedIndex == 5) {
        this.patientActive = false;
      }
    }
  }

  setUploadform() {
    this.uploadForm = new FormGroup({
      'documentName': new FormControl(null, Validators.required),
      'expiryDate': new FormControl(null),
      'fileName': new FormControl(null),
      'notes': new FormControl(null),
    });
  }

  onSubmitUpload() {
    //console.log(this.uploadForm);
    if (this.uploadForm.valid) {

      try {
        this.uploadService.uploadData.organizationCode = this.service.storage.get('orgCode');
        this.uploadService.uploadData.userCode = this.service.storage.get('userCode');
        //console.log(this.uploadService.uploadData);
        if (this.organizationDocumentCode) {
          this.uploadService.updateOrganizationDocument(this.uploadService.uploadData, this.organizationDocumentCode, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              this.setUploadform();
              this.resetUpload = false;
              setTimeout(() => {
                this.resetUpload = true;
              });

              setTimeout(() => {
                this.getallDocuments(this.pageDoc);
                this.cancelUpload();
              }, 1000);

              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
            } else {

              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })
        } else {
          this.uploadService.saveOrganizationDocument(this.uploadService.uploadData, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              this.setUploadform();
              this.resetUpload = false;
              setTimeout(() => {
                this.resetUpload = true;
              });
              setTimeout(() => {
                this.getallDocuments(this.pageDoc);
                this.cancelUpload();
              }, 1000);

              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
            } else {

              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })

        }

      } catch (error) {
        //console.log(error)
      }
    }
    else {
      this.uploadSubmitted = false;
    }
  }



  getallDocuments(page, scroll = null) {
    try {
      this.service.common.progressLoader = false;
      this.cancelUpload();
      this.uploadService.getOrganizationDocumentByOrganizationCode(this.service.storage.get('orgCode'), page, this.limitDoc, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          if (scroll) {
            let arrayData = response.data.organizationDocumentResponseList;
            setTimeout(() => {
              this.allDocuments = arrayData;
              this.orgReadCount = response.data.unreadCommentCount;
              this.dataSourceUpload = new MatTableDataSource(this.allDocuments);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSourceUpload.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSourceUpload.sort = this.sort;
              this.service.common.progressLoader = false;
            }, 1000);

          } else {
            this.orgReadCount = response.data.unreadCommentCount;
            this.allDocuments = response.data.organizationDocumentResponseList;
            this.dataSourceUpload = new MatTableDataSource(this.allDocuments);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSourceUpload.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSourceUpload.sort = this.sort;
            this.service.common.progressLoader = false;
          }
          if (this.allDocuments.length > 0 && this.service.storage.get('orgStatus') !== 'Pending') {
            this.isUploadOptional = true;
          } else {
            this.isUploadOptional = false;
          }
        }
        else {
          if (!scroll) {
            this.allDocuments = [];
            this.dataSourceUpload = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSourceUpload.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSourceUpload.sort = this.sort;
          }
          this.service.common.progressLoader = false;
        }
      });
    } catch (error) {

    }
  }

  geteditData(element) {
    //console.log('element', element);
    if (element) {
      this.uploadService.uploadData.documentName = element.documentName;
      this.uploadService.uploadData.expiryDate = element.expiryDate;
      this.uploadService.uploadData.uploadPath = element.uploadPath;
      this.organizationDocumentCode = element.organizationDocumentCode;
      this.uploadButton = true;
      this.uploadSubmitted = true;
      this.uploadService.uploadData.note = element.note;
      this.uploadService.uploadData.orgDocWiseCommentCode = element.orgDocWiseCommentCode;

    }
  }

  deleteUpload(documentCode) {
    //console.log(documentCode);
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete this document?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.uploadService.softDeletePermissionsByPermissionsCode(documentCode, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              setTimeout(() => {
                this.getallDocuments(this.pageDoc);
                this.cancelUpload();
              }, 1000);
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
            } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })

        } catch (error) {
          //console.log(error)
        }

      }
    });



  }

  onUploadScroll() {
    //console.log('onUploadScroll');
    this.pageDoc = this.pageDoc + 1;
    this.getallDocuments(this.pageDoc, 'scroll');
  }


  cancelUpload() {
    this.setUploadform();
    this.uploadSubmitted = true;
    this.uploadService.uploadData.documentName = null;
    this.uploadService.uploadData.expiryDate = null;
    this.uploadService.uploadData.uploadPath = null;
    this.organizationDocumentCode = null;
    this.uploadService.uploadData.note = null;
    this.uploadService.uploadData.orgDocWiseCommentCode = null;
    this.uploadButton = false;
    this.resetUpload = false;
    setTimeout(() => {
      this.resetUpload = true;
    });

  }


  onCertificateUpload(event) {
    //console.log(event);
    this.showLoader = true;
    if (event.target.files.length > 0) {

      let allowedExtension = ['TXT', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'PDF', 'PAGES', 'AI', 'PSD', 'TIFF', 'DXF', 'SVG', 'EPS', 'PS', 'TTF', 'XPS', 'ZIP', 'RAR'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      let isValidFile = false;
      //////console.log(fileExtension);
      for (let index in allowedExtension) {
        if (fileExtension === allowedExtension[index].toLowerCase() && event.target.files[0].size <= 5000000) {
          isValidFile = true;
          //console.log('111111111');
          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          ////console.log(formData,event.target.files[0]);
          this.service.common.uploadAllTypeFile(formData, (res) => {
            //console.log(res);
            if (res.responsecode == 200) {
              this.uploadService.uploadData.uploadPath = res.filename;
              this.uploadButton = true;
              this.showLoader = false;
            }
            else {
              this.common.errorMessage = true;
              this.common.message = res.message;
              this.showLoader = false;
            }

          });
          break;
        }
      }

      if (!isValidFile) {
        this.showLoader = false;
        //////console.log(isValidFile);Allowed Extensions are : .' + allowedExtension.join(', .') + ' and
        this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 5MB';
        this.service.common.errorMessage = true;
        //event.srcElement.value = '';
        event.target.value = '';

      }
    }

  }

  getallNotes(organizationDocumentCode) {

    try {

      this.uploadService.getOrganizationDocumentWiseNoteCommentByOrganizationDocumentCode(organizationDocumentCode, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.allNotes = response.data.orgDocCmtList;

        }
        else {
          this.allNotes = [];
        }
      });
    } catch (error) {

    }
  }

  viewDocNotes(organizationDocumentCode) {
    this.service.common.progressLoader = true;
    this.getallNotes(organizationDocumentCode);
    setTimeout(() => {
      this.service.common.progressLoader = false;
      const dialogRef = this.dialog.open(AdddocumentnoteComponent, {
        width: '800px',
        disableClose: true,
        data: { notes: this.allNotes, DocumentCode: organizationDocumentCode }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.getallDocuments(this.pageDoc);
        }
      });

    }, 2000);

  }

  getOrgNotes() {
    this.uploadService.getOrganizationWiseDocumentNoteCommentByOrganizationCode(this.service.storage.get('orgCode'), (response) => {
      //console.log(response);
      if (response.responsecode == 200) {
        this.allOrgNotes = response.data.organizationWiseDocumentCommentResponseList;
      }
      else {
        this.allOrgNotes = [];

      }
    });
  }

  vieworgNotes() {
    this.service.common.progressLoader = true;
    this.getOrgNotes();
    this.resetUpload = false;
    setTimeout(() => {
      this.resetUpload = true;
    });
    setTimeout(() => {
      this.service.common.progressLoader = false;
      const dialogRef = this.dialog.open(AddOrganizaionnoteComponent, {
        width: '800px',
        disableClose: true,
        data: { orgNotes: this.allOrgNotes }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          // this.getallDocuments(this.pageDoc);
        }
      });
    }, 1000);
  }
}
