import { Component, OnInit, Inject } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { ProfileService } from '../profile.service';
import { CommonService } from 'src/app/shared/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit {

  reset: boolean = true;
  showLoader: boolean = false;
  uploadButton: boolean = false;
  uploadForm: FormGroup;
  organizationDocumentCode;
  today = new Date();
  uploadSubmitted = true;
  constructor(public service: ProfileService,
    public dialogRef: MatDialogRef<AddDocumentComponent>,
    private localStorage: SessionStorageService,
    private common: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog, ) { }

  ngOnInit(): void {
    this.uploadForm = new FormGroup({
      'documentName': new FormControl(null, Validators.required),
      'expiryDate': new FormControl(null),
      'fileName': new FormControl(null),
      'notes': new FormControl(null),
    });
    //console.log(this.data)
    if (this.data.data) {

      this.service.uploadData.documentName = this.data.data.documentName;
      this.service.uploadData.expiryDate = this.data.data.expiryDate;
      this.service.uploadData.uploadPath = this.data.data.uploadPath;
      this.organizationDocumentCode = this.data.data.organizationDocumentCode;
      this.uploadButton = true;
      this.uploadSubmitted = true;
      this.service.uploadData.note = this.data.data.note;
      this.service.uploadData.orgDocWiseCommentCode = this.data.data.orgDocWiseCommentCode;
    } else {
      this.cancelUpload();
    }

    //console.log(this.service.uploadData);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCertificateUpload(event) {
    //console.log(event);
    
    if (event.target.files.length > 0) 
    {

      let allowedExtension = ['TXT', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'PDF', 'PAGES', 'AI', 'PSD', 'TIFF', 'DXF', 'SVG', 'EPS', 'PS', 'TTF', 'XPS', 'ZIP', 'RAR'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      //let isValidFile = false;
      
      if(!allowedExtension.some(ele=>ele.toLocaleLowerCase() === fileExtension))
      {
        this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(',  .') ;
        this.service.common.errorMessage = true;
        event.target.value = '';
        return false;
      }


      if(!(event.target.files[0].size <= 5000000))
      {
        this.service.common.message = "Upload a file less than or equal to 5 MB.";
        this.service.common.errorMessage = true;
        event.target.value = '';
        return false;
      }

         const formData = new FormData();
          formData.append("file", event.target.files[0]);

          this.showLoader = true;
          this.service.common.uploadAllTypeFile(formData, (res) => {
            //console.log(res);
            
            if (res.responsecode == 200) {
              this.service.uploadData.uploadPath = res.filename;
              this.uploadButton = true;
              this.showLoader = false;
            }
            else {
              this.common.errorMessage = true;
              this.common.message = res.message;
              this.showLoader = false;
            }

          });


      // for (let index in allowedExtension) 
      // {
      //   if (fileExtension === allowedExtension[index].toLowerCase() && event.target.files[0].size <= 5000000) {
      //     isValidFile = true;
      //     //console.log('111111111');
      //     const formData = new FormData();
      //     formData.append("file", event.target.files[0]);
      //     ////console.log(formData,event.target.files[0]);
      //     this.service.common.uploadAllTypeFile(formData, (res) => {
      //       //console.log(res);
      //       if (res.responsecode == 200) {
      //         this.service.uploadData.uploadPath = res.filename;
      //         this.uploadButton = true;
      //         this.showLoader = false;
      //       }
      //       else {
      //         this.common.errorMessage = true;
      //         this.common.message = res.message;
      //         this.showLoader = false;
      //       }

      //     });
      //     break;
      //   }
      // }

      // if (!isValidFile) {
      //   this.showLoader = false;
      //   //////console.log(isValidFile);Allowed Extensions are : .' + allowedExtension.join(', .') + ' and
      //   this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 5MB';
      //   this.service.common.errorMessage = true;
      //   //event.srcElement.value = '';
      //   event.target.value = '';

      // }


    }

  }

  uploadDoc() {
    //console.log(this.uploadForm);
    if (this.uploadForm.valid) {

      try {
        this.service.uploadData.organizationCode = this.localStorage.get('orgCode');
        this.service.uploadData.userCode = this.localStorage.get('userCode');
        //console.log(this.service.uploadData);
        if (this.organizationDocumentCode) {
          this.service.updateOrganizationDocument(this.service.uploadData, this.organizationDocumentCode, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              this.dialogRef.close('true');
              this.cancelUpload();
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
            } else {

              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })
        } else {
          this.service.saveOrganizationDocument(this.service.uploadData, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              this.dialogRef.close('true');
              this.cancelUpload();
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
            } else {

              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })

        }

      } catch (error) {
        //console.log(error)
      }
    }
    else {
      this.uploadSubmitted = false;
    }
  }

  cancelUpload() {
    this.uploadSubmitted = true;
    this.service.uploadData.documentName = null;
    this.service.uploadData.expiryDate = null;
    this.service.uploadData.uploadPath = null;
    this.organizationDocumentCode = null;
    this.service.uploadData.note = null;
    this.service.uploadData.orgDocWiseCommentCode = null;
    this.uploadButton = false;
    //this.setUploadform();
    // this.reset = false;
    // setTimeout(() => {
    //   this.reset = true;
    // });

  }


}
