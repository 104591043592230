import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ProvidersDashboardService } from '../../providers-dashboard.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../skills/skill-list/skill-list.component';
import { Router } from '@angular/router';
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';

@Component({
  selector: 'app-show-routes',
  templateUrl: './show-routes.component.html',
  styleUrls: ['./show-routes.component.scss']
})
export class ShowRoutesComponent implements OnInit {

  @ViewChild('mapDis', { static: true }) mapDis;
  directionsService;
  directionsDisplay;
  map;
  isZoom: boolean = false;
  distance;
  duration;
  driveDuration: any;
  originaddress: any;
  destinationaddress: any;
  legend: HTMLElement;

  constructor(
    public dialogRef: MatDialogRef<ShowRoutesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public service: ProvidersDashboardService,
    public router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    //console.log(this.data);
    if (this.data['appointmentsDurationCode']) {
      this.googleMap(this.data['data']);
    } else {
      this.initMap();
    }

  }

 /* Name:srinivas
       use: return google map. */
  googleMap(element) {
    this.isZoom = false;
    this.map = new google.maps.Map(document.getElementById('map'),
      {
        zoom: 2,
        center: new google.maps.LatLng(32.483, 16.084),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      });
    this.directionsService = new google.maps.DirectionsService;
    this.directionsDisplay = new google.maps.DirectionsRenderer(
      {
        // markerOptions: {
        //   clickable: true,
        //   flat: true,
        //   icon: { url: './../../assets/Car-icons/location_1.png' },
        //   visible: true,
        //   map: this.map
        // },
        polylineOptions: {
          strokeColor: "#16A085",
          strokeWeight: 4
        },
        map: this.map,
        suppressMarkers: true,
        suppressInfoWindows: false
      }
    );
    const oms = new OverlappingMarkerSpiderfier(this.map,
      { markersWontMove: true, markersWontHide: true });
    this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay, element, this.map,oms)
  }
/* Name:srinivas
       use: return google distance and markers. */
  calculateAndDisplayRoute(directionsService, directionsDisplay, element, map,oms) {
    //let start =19.117719+ ',' + 72.86327;
    let start = element[0].latitude + ',' + element[0].longitude;
    let end = element[1].latitude + ',' + element[1].longitude;

    let request = {
      origin: start,
      destination: end,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.legend = document.getElementById('mapLegend');
    ////console.log(this.legend);
    if (this.legend) {
      ////console.log(this.legend);
      let div = document.createElement('div');
      div.innerHTML = `<div class="text-truncate" ><b title="Est. Miles">Est. Miles:</b><span title="`+this.data['miles']+`"> `+this.data['miles']+` </span></div><div class="text-truncate" ><b title="Est. Drive Time">Est. Drive Time:</b><span title="`+ this.data['driveTime']+`"> `+ this.data['driveTime']+` </span></div>`;

      this.legend.appendChild(div);

    }

    directionsService.route(request, (response, status) => {
      //////console.log(response);
      if (status == google.maps.DirectionsStatus.OK) {
        this.distance = response.routes[0].legs[0].distance.text;
        this.duration = response.routes[0].legs[0].duration.text;
        this.driveDuration = response.routes[0].legs[0].duration.text;
        directionsDisplay.setDirections(response);

        const infowindow = new google.maps.InfoWindow();


        // {
        //   size: new google.maps.Size(150, 50)
        // }
        //////console.log(response);
        let legs = response.routes[0].legs;
        let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        //////console.log(legs);


        //////console.log(legs[0], labels);
        let marker = new google.maps.Marker({
          position: legs[0].start_location,
          map: directionsDisplay.getMap(),
          label: {
            text: labels[0],
            color: '#ffffff'
          },
          // title: 'label' + 1,
          // icon: { url: './../../assets/Car-icons/location_1.png' },
          zIndex: Math.round(legs[0].start_location.lat() * -100000) << 5

        });
        google.maps.event.addListener(marker, 'click', function () {
          //////console.log(marker);
          infowindow.setContent('<b>Address</b>: ' + legs[0].start_address);
          infowindow.open(map, marker);
        })

        oms.addMarker(marker);
                  oms.addListener(marker, 'click', function () {
                    infowindow.setContent('<b>Address</b>: ' + legs[0].start_address );
                    infowindow.open(map, marker);
                  });
                  oms.addListener('unspiderfy', function (markers) {
                    //console.log(markers);
                    // for (let i = 0; i < markers.length; i++) {
                    //   markers[i].setIcon(icon);
                    // }
                  });
              
                  oms.addListener('spiderfy', function (markers) {
                    //console.log(markers);
                    // for (let i = 0; i < markers.length; i++) {
                    //   markers[i].setIcon(icon);
                    // }
                  });
        let marker1 = new google.maps.Marker({
          position: legs[0].end_location,
          map: directionsDisplay.getMap(),
          label: {
            text: labels[1],
            color: '#ffffff'
          },
          // title: 'label' + 1,
          // icon: { url: './../../assets/Car-icons/location_1.png' },
          zIndex: Math.round(legs[0].end_location.lat() * -100000) << 5

        });
        google.maps.event.addListener(marker1, 'click', function () {
          //////console.log(marker1);
          infowindow.setContent('<b>Address</b>: ' + legs[0].end_address + "<br> " + '<b>Drive Time</b>: ' + legs[0].duration.text);
          infowindow.open(map, marker1);
        })

        oms.addMarker(marker1);
        oms.addListener(marker1, 'click', function () {
          infowindow.setContent('<b>Address</b>: ' + legs[0].end_address + "<br> " + '<b>Drive Time</b>: ' + legs[0].duration.text);
          infowindow.open(map, marker1);
        });
        oms.addListener('unspiderfy', function (markers) {
          //console.log(markers);
          // for (let i = 0; i < markers.length; i++) {
          //   markers[i].setIcon(icon);
          // }
        });
    
        oms.addListener('spiderfy', function (markers) {
          //console.log(markers);
          // for (let i = 0; i < markers.length; i++) {
          //   markers[i].setIcon(icon);
          // }
        });

        // let step = 1;
        // let infowindow2 = new google.maps.InfoWindow();
        // infowindow2.setContent(response.routes[0].legs[0].start_address + "<br>" + 'Drive Time:' + response.routes[0].legs[0].duration.text);
        // infowindow2.setPosition(response.routes[0].legs[0].steps[step].end_location);
        // infowindow2.open(map);
      }
    });
  }

  /* Name:srinivas
       use: google map initialize. */
  initMap() {
    let directionsService = new google.maps.DirectionsService;

    let map = new google.maps.Map(document.getElementById('map'), {

      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoom: 12,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_LEFT
      },
      scaleControl: true,
      fullscreenControl: true
    });
    let directionsRenderer = new google.maps.DirectionsRenderer({
      map: map, suppressMarkers: true,
      suppressInfoWindows: false
    });
    //directionsDisplay.setMap(map);

    const oms = new OverlappingMarkerSpiderfier(map,
      { markersWontMove: true, markersWontHide: true });
    this.calculateAndDisplayAllRoutes(directionsService, directionsRenderer, map, oms);
  }
/* Name:srinivas
       use: set title and address bar and markers. */
  calculateAndDisplayAllRoutes(directionsService, directionsDisplay, map, oms) {
    // this.service.common.progressLoader = true;
    let waypts = [];
    // this.service.mobileGetRouteByTechnicianCodeAndDate(this.service.data.workerCode, this.currentDate, (response) => {
    //   if (response.responsecode == 200) {
    let cnt = 0;
    //////console.log(this.data['data']);

    this.legend = document.getElementById('mapLegend');
    ////console.log(this.legend);
    if (this.legend) {
      ////console.log(this.legend);
      let div = document.createElement('div');
      div.innerHTML = `<div class="text-truncate" ><b title="Est. Miles">Est. Miles:</b><span title="` + this.data['data'].distance + `"> ` + this.data['data'].distance + ` </span></div><div class="text-truncate" ><b title="Est. Drive Time">Est. Drive Time:</b><span title="` + this.data['data'].duration + `"> ` + this.data['data'].duration + ` </span></div>`;

      // div.innerHTML = `<div class="text-truncate" title="Drive Time"><b>Drive Time:</b><span> ` + this.data['data'].duration + ` </span></div><div class="text-truncate" title="Miles"><b>Miles:</b><span> ` + this.data['data'].distance + ` </span></div>`;
      this.legend.appendChild(div);

    }
   // this.data['data'].jrepolst[3].isHomeAddress = false;
    this.data['data'].jrepolst.forEach(element => {

      //createMarker(end, 'end');

      cnt = cnt + 1;
      if (element.isHomeAddress) {
        //console.log(cnt+'if');
        this.originaddress = element.address;
        // this.createMarker(element.latitude+','+element.longitude, 'start');

        this.fitBoundsMapLocation(element.latitude, element.longitude, map);
      } else if (cnt === this.data['data'].jrepolst.length) {
        //console.log(cnt+'elseif');
        this.destinationaddress = element.address;
        //this.createMarker(element.latitude+','+element.longitude, 'end');

        this.fitBoundsMapLocation(element.latitude, element.longitude, map);
      } else {
        //console.log(cnt+'else');
        this.fitBoundsMapLocation(element.latitude, element.longitude, map);

        waypts.push({
          location: element.address,
          stopover: true
        });
      }
    })
    setTimeout(() => {
      directionsService.route({
        origin: this.originaddress,
        destination: this.destinationaddress,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: 'DRIVING'
      }, function (response, status) {
        if (status === 'OK') {
          directionsDisplay.setDirections(response);
          const infowindow = new google.maps.InfoWindow();
          //////console.log(response);
          let legs = response.routes[0].legs;
          let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

          for (let i = 0; i < legs.length; i++) {
            let markerletter = "A".charCodeAt(0);
            markerletter += i;
            markerletter = +String.fromCharCode(markerletter);
            //////console.log(legs[i], labels);
            let labelText;
            // //console.log(i+1 , legs.length)
            // if(i+1 == legs.length) {
            //   labelText = {
            //     text: 'A',
            //     color: '#ffffff'
            //   };
            // } else {
              labelText = {
                text: labels[i],
                color: '#ffffff'
              }
           // }
            //console.log(labelText);
            let marker = new google.maps.Marker({
              position: legs[i].start_location,
              map: directionsDisplay.getMap(),
              label: labelText,
              // title: 'label' + i,
              // icon: { url: './../../assets/Car-icons/location_1.png' },
              zIndex: Math.round(legs[i].start_location.lat() * -100000) << 5

            });


            // google.maps.event.addListener(marker, 'click', function () {
            //   //////console.log(marker);
            //   infowindow.setContent('<b>Address</b>: ' + legs[i].start_address + "<br> " + '<b>Drive Time</b>: ' + legs[i].duration.text);
            //   infowindow.open(map, marker);
            // })
            oms.addMarker(marker);
            if (i === 0) {
              google.maps.event.addListener(marker, 'click', function () {
                ////console.log(marker);
                infowindow.setContent('<b>Address</b>: ' + legs[i].start_address);
                infowindow.open(map, marker);
              });
              oms.addListener(marker, 'click', function () {
                ////console.log(marker);
                infowindow.setContent('<b>Address</b>: ' + legs[i].start_address);
                infowindow.open(map, marker);
              });
            }
            else {
              google.maps.event.addListener(marker, 'click', function () {
                infowindow.setContent('<b>Address</b>: ' + legs[i].start_address + "<br> " + '<b>Drive Time</b>: ' + legs[i].duration.text);
                infowindow.open(map, marker);
              })
              oms.addListener(marker, 'click', function () {
                 infowindow.setContent('<b>Address</b>: ' + legs[i].start_address + "<br> " + '<b>Drive Time</b>: ' + legs[i].duration.text);
            });
            }
            
            oms.addListener('unspiderfy', function (markers) {
            //console.log(markers);
            // for (let i = 0; i < markers.length; i++) {
            // markers[i].setIcon(icon);
            // }
            });
  
            oms.addListener('spiderfy', function (markers) {
            //console.log(markers);
            // for (let i = 0; i < markers.length; i++) {
            // markers[i].setIcon(icon);
            // }
            });
          }


          let i = legs.length;
          let markerletter = "A".charCodeAt(0);
          markerletter += i;
          markerletter = +String.fromCharCode(markerletter);
          let marker = new google.maps.Marker({
            position: legs[legs.length - 1].end_location,
            map: directionsDisplay.getMap(),
            label: null,
            // title: 'label' + i,
            // icon: { url: './../../assets/Car-icons/location_1.png' },
            zIndex: Math.round(legs[legs.length - 1].end_location.lat() * -100000) << 5
          });
          google.maps.event.addListener(marker, 'click', function () {
            infowindow.setContent('<b>Address</b>: ' + legs[legs.length - 1].end_address + "<br> " + '<b>Drive Time</b>: ' + legs[legs.length - 1].duration.text);
            infowindow.open(map, marker);
          })


        

        } else {
          window.alert('Directions request failed due to ' + JSON.stringify(status));
        }
      });
    }, 1000);
    // }
    this.service.common.progressLoader = false;
    // })
  }
/* Name:srinivas
       use: set address bound in map. */
  fitBoundsMapLocation(lat, lang, map) {
    const oms = new OverlappingMarkerSpiderfier(map,
      { markersWontMove: true, markersWontHide: true });

    let marker;
    const bounds = new google.maps.LatLngBounds();
    //const infowindow = new google.maps.InfoWindow();
    let loc = new google.maps.LatLng(lat, lang);
    bounds.extend(loc);
    marker = new google.maps.Marker({
      position: { lat: loc.lat(), lng: loc.lng() },
      animation: google.maps.Animation.DROP,
      draggable: true,

    });
    oms.addListener(marker, 'click', function () {
      //////console.log(marker);
      //infowindow.open(this.map, marker);
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }

    });
    map.fitBounds(bounds);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
