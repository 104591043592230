import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NewJobService } from '../new-job/new-job.service';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-job-details',
  templateUrl: './view-job-details.component.html',
  styleUrls: ['./view-job-details.component.scss']
})
export class ViewJobDetailsComponent  {

  @Input() Job_ModalData : any;
  @Output() event: EventEmitter<any> = new EventEmitter();

  jobDetails:any

  constructor(
    private _newJobSVC: NewJobService,
    private _commonSVC: CommonService,
    private _sessionSVC: SessionStorageService,
    private modalService: BsModalService
    ){}


 
  ngOnInit()
  {
    setTimeout(()=>{
     this.getJobDetails();
    },500)
     
      //this.getJobDetails();
  }

  getJobDetails()
  {
     this._commonSVC.progressLoader = true ;
    this._newJobSVC.getJobsByAppointmentsProceduresCode(
        this._sessionSVC.get("orgCode"),
        this.Job_ModalData.appointmentsCode,
        this.Job_ModalData.appointmentsProceduresCode,
        this.Job_ModalData.jobDate
      )
      .subscribe((res: any) => {
        this._commonSVC.progressLoader = false ;
       
        if(res.responsecode == 200)
        {
          this.jobDetails = res.data[0];
          console.log("this.jobDetails", this.jobDetails)
        }

      });
  }


  closeBsPopUp() 
  {
    this.modalService.hide();
    this.triggerEvent('');
  }


  triggerEvent(data:any) {
    this.event.emit('');
  }


}
