
<div class="row row-cols-1 row-cols-md-3 g-4 mb-4 ps-0 ms-0 pe-0 me-0 pt-2">
  <div class="col-md-3 col-sm-6 col-12 col-lg-4 col-xl-3" *ngFor="let element of dataList; let i = index">

    <!-- [routerLink]="['/dashboard', 'staffmember', 'member-edit']" [queryParams]="{ workerCode: element.workerCode }" -->
     <div class="card staffCard cursor-pointer" [class.inactiveCard]="!element.isActive">
      
    <div class="deviceIcon">

      <mat-icon *ngIf="element?.isAppRegistered && element?.appType === 'Android'" title="{{element?.appType}}{{element?.appVersion ? ('&#013;Version: ')+element.appVersion : ''}}{{element?.applastLogin ? ('&#013;Last login: ')+element.applastLogin : ''}}">smartphone</mat-icon>
      <mat-icon *ngIf="element?.isAppRegistered && element?.appType === 'Ios'" title="{{element?.appType}}{{element?.appVersion ? ('&#013;Version: ')+element.appVersion : ''}}{{element?.applastLogin ? ('&#013;Last login: ')+element.applastLogin : ''}}">phone_iphone</mat-icon>
      <mat-icon *ngIf="element?.isAppRegistered && element?.appType === 'Web'" title="{{element?.appType}}{{element?.appVersion ? ('&#013;Version: ')+element.appVersion : ''}}{{element?.applastLogin ? ('&#013;Last login: ')+element.applastLogin : ''}}">laptop</mat-icon>
    
    </div>
      <div class="dropdown profile-action">
        <!-- <span class="me-1">
          <mat-icon [title]="'Send Email'" (click)="sendTemplate(element)">send</mat-icon>
        </span>
        <span title="Distribution List">
          <mat-icon class="distListIc" (click)="openAssignedDistributionListPopup(element.workerCode)"> list_alt</mat-icon>
        </span>
        <span title="Note">
          <img class="noteIc curser-pointer" (click)="addNote(element, element.name)" [src]="element.isNoteAdded ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
        </span> -->
        <a (click)="$event.stopPropagation()"
          href="javascript:void(0)"
          class="action-icon dropdown-toggle dropdown-toggle-no-caret"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ><i class="material-icons">more_vert</i></a
        >
        <div class="dropdown-menu dropdown-menu-right" (click)="$event.stopPropagation()">

          <ng-template [ngIf]="is_editPermission === true"> 
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            [routerLink]="['/dashboard', 'staffmember', 'member-edit']" [queryParams]="{ workerCode: element.workerCode }"
            ><span><mat-icon>edit</mat-icon></span><span class="pt-1">Edit</span></a
          >
          </ng-template>

          <!-- <ng-template [ngIf]="is_deletePermission === true">  -->
          <a class="dropdown-item" (click)="setActiveInactiveStaff(element, element?.isActive ? false : true)" 
            href="javascript:void(0)"
            >
            <span><mat-icon [ngStyle]="{'color': element?.isActive ? 'red' : 'green'}">
              {{element?.isActive ? 'highlight_off' : 'check_circle'}}</mat-icon>
            </span>
            <span class="pt-1">{{element?.isActive ? 'Inactive' : 'Active'}}</span></a
          >

          <a
          class="dropdown-item"
          href="javascript:void(0)"
          [routerLink]="['/dashboard', 'staffmember', 'member-edit']" [queryParams]="{ workerCode: element.workerCode , activeTab:'Exception' }"
          ><span><mat-icon>recent_actors</mat-icon></span><span class="pt-1">Exceptions</span></a>

          <!-- </ng-template> -->
          <ng-template [ngIf]="element.isActive">
          <a  class="dropdown-item"  (click)="sendWelcomeEmail(element.workerCode)">
            <span>
              <mat-icon>mail</mat-icon> 
            </span>
            <span class="pt-1">
              Send Welcome Email
            </span>
          </a>

          <a  class="dropdown-item"  (click)="changePassword(element.workerCode)">
            <span>
              <mat-icon>lock</mat-icon> 
            </span>
            <span class="pt-1">
              Reset Password
            </span>
          </a>
        </ng-template>

        <a  class="dropdown-item" (click)="openStaffCalendarView(element)">
          <span>
            <mat-icon>calendar_today</mat-icon> 
          </span>
          <span class="pt-1">
            View Schedule
          </span>
        </a>

        </div>
      </div>

      <div class="d-flex" [routerLink]="is_editPermission === true ? ['/dashboard', 'staffmember', 'member-edit'] : null"
      [queryParams]="is_editPermission === true ? { workerCode: element.workerCode } : null">

      <div class="w-100 justify-content-top text-center pt-3 imgAtTop">

        <ng-template [ngIf]="is_addPermission === true" [ngIfElse]="elseTemp"> 
        <input class="fileUpload-input" accept="image/*" type="file" enctype="multipart/form-data" (click)="$event.stopPropagation()" (change)="onFileChange($event,element)" #fileInput>
        
        <img [title]="element?.skillList ? 'Skills: ' + element.skillList : ''" 
             [src]="element?.workerLogo ? (baseURL + element?.workerLogo) : 'assets/common_images/profile.jpg'"
             class="card-img-top" alt="User Image"
             (click)="$event.stopPropagation(); fileInput.click()"
             onerror="this.src='assets/common_images/profile.jpg';">

        <i class="material-icons edit-icon pointer" (click)="$event.stopPropagation(); fileInput.click()"> edit  </i>
       </ng-template>
       <ng-template #elseTemp>
        <img [title]="element?.skillList ? 'Skills: ' + element.skillList : ''" 
        [src]="element?.workerLogo ? (baseURL + element?.workerLogo) : 'assets/common_images/profile.jpg'"
        class="card-img-top" alt="User Image"  onerror="this.src='assets/common_images/profile.jpg';">
       </ng-template>

      </div>

      <div class="card-body text-center">
        <!-- <h5 class="card-title">{{element?.name}} {{ "Technicians.labels.Name" | translate }}</h5>
        <p class="card-text">{{element?.employmentTypeName}} {{ "Technicians.labels.Emp Type" | translate }}</p> -->
        <h5 class="card-title" [title]="element?.skillList ? 'Skills: ' + element.skillList : ''">{{element?.name | capitalize}} </h5>

        <div class="address-info"  (click)="$event.stopPropagation()">
          <div class="copy-container" *ngIf="element.email">
            <span><i class="fa fa-fw me-2 fa-envelope"></i>{{ element.email }}</span>
            <i class="fa fa-fw fa-copy copy-icon" (click)="copyClipboardText(element.email, i, 'email')" [matTooltip]="tooltipEmailMessages[i]"
            #emailTooltip="matTooltip"></i>
          </div>
          <div class="copy-container" *ngIf="element.workPhone">
            <span><i class="fa fa-fw me-2 fa-phone"></i>{{ element.workPhone }}</span>
            <i class="fa fa-fw fa-copy copy-icon" (click)="copyClipboardText(element.workPhone, i, 'phone')" [matTooltip]="tooltipPhoneMessages[i]"
            #phoneTooltip="matTooltip"></i>
          </div>
          <div class="btn-group w-auto RoleChangeDropdownSection positionInitial d-grid mb-2" (click)="$event.stopPropagation()">
            <span type="button" class="arrowPositionRel user-role dropdown-toggle mb-0 minWid w-100 text-truncate d-block" data-bs-toggle="dropdown" aria-expanded="false" matTooltip="{{element?.roleName ? element.roleName : ''}}">
              <i class="fa fa-fw me-1 fa-user"></i>
              {{element?.roleName ? element.roleName : 'Select'}}
            </span>
            <ul class="dropdown-menu roleMenu">
              <ng-container *ngFor="let role of allRolesList; index as idx">
                <li matTooltip="{{(role?.roleName ? role.roleName : '') | capitalize}}"><a class="dropdown-item curser-pointer text-truncate" [class.active]="element?.roleName === role?.roleName" href="javascript:void(0)" [class.inactive]="element?.roleName === role?.roleName" (click)="element?.roleName !== role?.roleName && ChangeRole(element, role)">{{role.roleName | capitalize}}</a></li>
              </ng-container>
            </ul>
          </div>
          <div class="w-100 actionCenterButton">
            <span class="me-1" (click)="$event.stopPropagation()">
              <mat-icon [title]="'Send Email'" (click)="sendTemplate(element)">send</mat-icon>
            </span>
            <span title="Distribution List" (click)="$event.stopPropagation()" class="me-1">
              <mat-icon class="distListIc" (click)="openAssignedDistributionListPopup(element.workerCode)"> list_alt</mat-icon>
            </span>
            <span title="Note" (click)="$event.stopPropagation()">
              <img class="noteIc curser-pointer" (click)="addNote(element, element.name)" [src]="element.isNoteAdded ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
            </span>
          </div>
         </div>
        <!-- <div class="btn-group w-100 RoleChangeDropdownSection positionInitial d-grid" (click)="$event.stopPropagation()">
          <button type="button" class="btn btn-info btn-sm dropdown-toggle minWid w-100 text-truncate" data-bs-toggle="dropdown" aria-expanded="false" matTooltip="{{element?.roleName ? element.roleName : ''}}">
            {{element?.roleName ? element.roleName : 'Select'}}
          </button>
          <ul class="dropdown-menu roleMenu">
            <ng-container *ngFor="let role of allRolesList; index as idx">
              <li matTooltip="{{(role?.roleName ? role.roleName : '') | capitalize}}"><a class="dropdown-item curser-pointer text-truncate" [class.active]="element?.roleName === role?.roleName" href="javascript:void(0)" [class.inactive]="element?.roleName === role?.roleName" (click)="element?.roleName !== role?.roleName && ChangeRole(element, role)">{{role.roleName | capitalize}}</a></li>
            </ng-container>
          </ul>
        </div> -->
      </div>
      </div>


    </div>
  </div>
</div>



  
  <!-- <div class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3" *ngFor = "let element of dataList">
    <div class="profile-widget">
      <div class="profile-img">
        <a href="javascript:void(0)" class="avatar"
          ><img [src]="element?.workerLogo ? (fileUrl + element?.workerLogo) : 'assets/common_images/profile.jpg'" class="card-img-top" alt="User Image">
      </a>
      </div>
      <div class="dropdown profile-action">
        <a
          href="javascript:void(0)"
          class="action-icon dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ><i class="material-icons">more_vert</i></a
        >
        <div class="dropdown-menu dropdown-menu-right">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target="#edit_employee"
            ><mat-icon>edit</mat-icon>Edit</a
          >
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target="#delete_employee"
            ><mat-icon>delete</mat-icon>Delete</a
          >
        </div>
      </div>
      <h4 class="user-name m-t-10 mb-0 text-ellipsis">
        <a href="#"> {{element?.name}} {{ "Technicians.labels.Name" | translate }}</a>
      </h4>
      <div class="small text-muted">  {{element?.employmentTypeName}} {{ "Technicians.labels.Emp Type" | translate }}</div>
    </div>
  </div> -->
  
