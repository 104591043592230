<div class="col-md-12">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header">Assign/Unassign Distribution</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="table-scroll-height mb-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" [fromRoot]="true">
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">
      
            <ng-container matColumnDef="distributionName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 85%;">
                Distribution List
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <div><mat-checkbox class="mat-option" [checked]="element.isAssigned" (change)="toggleSelection(element, $event)" color="primary">{{element?.distributionName}}</mat-checkbox></div>
                  <!-- <div class="lh_40">{{element?.distributionName}} </div> -->
                </div>
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
          </table>
      
          <div *ngIf="(!technicianService.common.progressLoader && !technicianService.common.infiniteLoader) && listData.length === 0" 
          class="p-3 noList text-danger">
          {{'distributionList.messages.Distribution are not available' | translate }}</div>
          </div>
      
          <div class="w-100" *ngIf="technicianService.common.infiniteLoader">
            <div class="loadergif">
              <img src="assets/typint_dots_black.gif" alt="Loader">
              <p>Loading...</p>
            </div>
          </div>
          
        </div>
  </div>

  <div class="row mt-1">    
    <div class="col-lg-12 text-end">
      <button mat-raised-button color="primary" (click)="assignDistribution()">Submit</button>
    </div>
  </div>
</div>
