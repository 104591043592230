<div class="title">
    <h6>Document Notes</h6>
    <mat-icon (click)="onNoClick()" class="cursor-pointer close-icon">close</mat-icon>
  </div>
  
  
  
  
  <div class="fromto">

    <div *ngIf="allOrgNotes.length > 0" class="example-container popup-viewport-body mb-5">
   <!-- <div  *ngFor="let note of allOrgNotes">
      <ul class="timeline">
        <li *ngIf="note.noteUserName">
          <div class="timeline-badge"><i class="glyphicon glyphicon-check"></i>  </div>      
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4 class="timeline-title"><strong>{{note.noteUserName}}</strong>
                <mat-icon title="Edit" (click)="geteditNote(note)" class="cursor-pointer color-icon me-2 float-end">create</mat-icon></h4>
              
            
              <p><small class="text-muted">{{note.noteDate}}</small></p>
            </div>
  
            
            <div class="timeline-body">
              <p>{{note.note}}</p>
            </div>
            
          </div>
        </li>
        <li class="timeline-inverted" *ngIf="note.commentUserName">
          <div class="timeline-badge warning"></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4 class="timeline-title"> <strong>{{note.commentUserName}}</strong></h4>
              <p> <small class="text-muted">{{note.noteDate}}</small></p>
            </div>
            <div class="timeline-body">
              <p>{{note.docComment}}</p>
            </div>
          </div>
        </li>
      </ul>
    </div> -->
    <div *ngFor="let note of allOrgNotes" class="docchat">
      <div *ngIf="note.noteUserName" class="userchat">
        <div class="timeline-heading">
          <h4 class="timeline-title"><strong>{{note.noteUserName}}</strong>
            <div class="editicon float-end">
            <mat-icon class="editicon cursor-pointer me-1" (click)="geteditNote(note)">edit</mat-icon>
            <mat-icon *ngIf="note.isdelete" class="editicon cursor-pointer"  title ="Delete" (click)="deleteNote(note.orgWiseDocCommentCode)">delete</mat-icon>
          </div>
        </h4>
          <p><small class="" *ngIf="note.noteDate">{{note.noteDate.split(':')[0]}}:{{note.noteDate.split(':')[1]}}</small></p>
        </div> 
        <div class="timeline-body mt-2"> 
          <p>{{note.note}}</p>
        </div>
      </div>
      <div *ngIf="note.commentUserName" class="adminchat">
        <div class="timeline-heading">
          <h4 class="timeline-title"> <strong>{{note.commentUserName}}</strong></h4>
          <p> <small class="" *ngIf="note.noteDate">{{note.noteDate.split(':')[0]}}:{{note.noteDate.split(':')[1]}}</small></p>
        </div>
        <div class="timeline-body mt-2">
          <p>{{note.docComment}}</p>
        </div>
      </div>
    </div>
    </div>

    <div *ngIf="allOrgNotes.length == 0" class="text-danger mb-5">No history present</div>


    <form [formGroup]="orgNotesForm" *ngIf="reset">
      <div class="row">
        <div class="col-md-10 ">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
            <mat-label>{{'report.labels.Notes' | translate }}</mat-label>
            <input matInput class="form-control" tabindex="-1" formControlName="note" [(ngModel)]="service.orgNoteData.note">
          </mat-form-field>
          <!-- <span class="help-block err" *ngIf="orgNotesForm && (orgNotesForm.controls.note.errors?.required ) && (!submitted || orgNotesForm.controls.note.touched)">{{
                  'Technicians.Validators.Note is required' |
                  translate }}.</span> -->
        </div>
        <!-- <div class="send col-md-2 " > -->
          <div class="col-md-2">
          <button mat-raised-button color="primary" class="primary-btn me-2" (click)="onSubmit()" [disabled]="sendButton">Send
          </button>
          
        </div>
      </div>
    </form>


  </div>