import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopoverComponent } from 'src/app/components/popover/popover.component';

@Directive({
  selector: '[appPopover]'
})
export class PopoverDirective implements OnDestroy {
  @Input('appPopoverData') appPopoverData: any;
  @Input('placement') placement: any;
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  @HostListener('mouseenter')
  show() {
    this.createOverlay();
  }

  // @HostListener('mouseleave')
  // hide() {
  //   this.destroyOverlay();
  // }

  @HostListener('click')
  toggle() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.destroyOverlay();
    } else {
      this.createOverlay();
    }
  }

  private createOverlay() {
    if (!this.overlayRef) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]);
      this.overlayRef = this.overlay.create({ positionStrategy });
      if(this.overlayRef && this.placement){
  
        let element = this.overlayRef.hostElement.innerHTML;
  
        // Using regular expression to extract id attribute
        let match = element.match(/id="([^"]+)"/);
        let idValue = match[1]; // Extracted id value

        if (match) {            
            // console.log('OverlayRef_id', `id='${idValue}'`);
            var elementId = document.getElementById(idValue);
            elementId.classList.add(this.placement);
        } else {
            // console.log('ID attribute not found.');
        }
        if(this.appPopoverData?.isRowNameWithLogo){
          var elementId = document.getElementById(idValue);
            elementId.classList.add('logoLftSpace');
        }
        if(this.appPopoverData?.lableTxt){
          var elementId = document.getElementById(idValue);
            elementId.classList.add('withLableTxt');
        }
      }
    } 

    if (!this.overlayRef.hasAttached()) {
      const popoverPortal = new ComponentPortal(PopoverComponent);
      const popoverRef = this.overlayRef.attach(popoverPortal);
      popoverRef.instance.appPopoverData = this.appPopoverData;
      // console.log('Placement', this.placement);
      popoverRef.instance.close.subscribe(() => {
      this.destroyOverlay();
    });
    }
  }

  private destroyOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  ngOnDestroy() {
    this.destroyOverlay();
  }
}
