<div class="col-md-12">
  <div class="row pb-3">
    <div class="col-md-4">
      <div class="float-start header-name pt-1">Team Management</div>
    </div>
    <div class="col-md-3"></div>
    <div class="col-md-5 searchpat">
      <div class="w-100 d-flex">
      <input type="text" class="form-control w-100" id="search-patient" autocomplete="off"
        placeholder="Search by Team Name" name="search-patient" [(ngModel)]="searchTerm"
        (keyup)="applyFilter($event.target.value)">
      <mat-icon class="cursor-pointer">search</mat-icon>

      <ng-template [ngIf]="hasPermission.addPermission === true">
      <button mat-raised-button color="primary" class="ms-2" (click)="openPopupAddUpdateTeam()" title="Add Team">Add</button>
      </ng-template>

      </div>
    </div>
  </div>
</div>
<div class="w-100" style="position: absolute;z-index: 99;left: 30%; margin-top: 10px; max-width: 66%;">
  <!-- <div class="three-dot-menu" [popover]="Menucontentt" container="body"
    placement="auto" [adaptivePosition]="true" [outsideClick]="true"
    containerClass="popover-zIndex">
    <mat-icon style="font-size: 19px;">more_vert</mat-icon>
  </div> -->
  <!-- <button type="button" class="btn btn-lg btn-danger" data-bs-toggle="popover" data-bs-title="Popover title" data-bs-content="And here's some amazing content. It's very engaging. Right?">Click to toggle popover</button>
  <button type="button" class="btn btn-secondary" data-bs-toggle="popover" data-bs-placement="right" data-bs-custom-class="custom-popover" data-bs-title="Custom popover" data-bs-content="This popover is themed via CSS variables.">
     Custom popover
  </button>  -->
  <!-- <button type="button" class="btn btn-warning" 
                    data-bs-toggle="popover" data-bs-trigger="hover" title="Left Popover"
                    [data-bs-content]="Menucontentt"
                    data-bs-custom-class="custom-popover"> 
              Hover me (Left) 
           </button>  -->
  <!-- <button type="button" class="btn btn-danger" 
                 data-bs-toggle="popover" data-bs-trigger="hover"
              data-bs-placement="right" title="Right Popover"
              data-bs-content="This is the content of the Right Popover."
              data-bs-custom-class="custom-popover"> 
              Hover me (Right) 
           </button> -->
           <ng-template #Menucontentt>

            <div class="d-flex flex-column">
            <button mat-raised-button color="primary" class="mb-2">
              Notify
            </button>

            <button mat-raised-button color="warn">
            Assign
            </button>
            </div>
          </ng-template>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="service.common.infiniteLoader"></mat-progress-bar>
<div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 patient-table">

    <ng-container matColumnDef="teamName" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap"> Team Name </th>
      <td width="150px" style="max-width: 250px;" mat-cell *matCellDef="let element" class="common-icon-round" class="text-nowrap text-truncate" title="{{element.teamName}}">
        {{element.teamName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="associateStaffs" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="text-nowrap">Associate Staffs </th>
      <td mat-cell *matCellDef="let element" class="text-nowrap">
        <ng-container *ngFor="let staff of element.associatedStaffList; let userIdx = index">
          <span class="me-2 curser-pointer" *ngIf="5 > userIdx" (click)="openPopupViewList(element, 'Teams')">
            <img
              class="td_avatar_img"
              [src]=" service._apiSVC.getSuperAdmin() + '/downloadFile/' + staff.logo "
              [title]="staff.staffName"
              onerror="this.src='assets/common_images/profile.jpg';"
            />
          </span>
        </ng-container>        
        <span class="me-2 curser-pointer" *ngIf="element.associatedStaffList.length > 5" (click)="openPopupViewList(element, 'Teams')">
          + {{element.associatedStaffList.length - 5}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="supervisors" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="text-nowrap">Supervisor</th>
      <td mat-cell *matCellDef="let element" class="text-nowrap">
        <ng-container *ngFor="let supervisor of element.supervisorList; let supervisorIdx = index">
          <span class="me-2 curser-pointer" *ngIf="5 > supervisorIdx" (click)="openPopupViewList(element, 'Supervisors')">
            <img
              class="td_avatar_img"
              [src]=" service._apiSVC.getSuperAdmin() + '/downloadFile/' + supervisor.logo "
              [title]="supervisor.staffName"
              onerror="this.src='assets/common_images/profile.jpg';"
            />
          </span>
        </ng-container>        
        <span class="me-2 curser-pointer" *ngIf="element.supervisorList.length > 5" (click)="openPopupViewList(element, 'Supervisors')">
          + {{element.supervisorList.length - 5}}
        </span>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="mainPhone" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.labels.Phone' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.mainPhone">{{element.mainPhone}} </span>
        <span *ngIf="!element.mainPhone">--</span>
      </td>
    </ng-container> -->
 

    <ng-container matColumnDef="jobSite" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef>{{ 'main.labels.Job Site' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="text-nowrap">
        <ng-container *ngFor="let address of element.customerAddressResponseList; let addressIdx = index">
          <span *ngIf="5 > addressIdx"
            title="{{address?.siteName ? address.siteName : ''}}" (click)="openPopupViewList(element, 'Sites')">
            <i class="material-icons curser-pointer">
              location_city
            </i>
          </span>
        </ng-container>
        <span class="me-2 curser-pointer cls-pos" *ngIf="element.customerAddressResponseList.length > 5" (click)="openPopupViewList(element, 'Sites')">
          + {{element.customerAddressResponseList.length - 5}}
        </span>
      
      </td>
    </ng-container>

    <ng-container matColumnDef="edit" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="action"> </th>
      <td mat-cell *matCellDef="let element" class="action">
        <ng-template [ngIf]="hasPermission.editPermission === true">
         <i class="material-icons pointer" (click)="openPopupAddUpdateTeam(element)">edit</i>
          </ng-template>
          
        <!-- <i class="material-icons pointer ps-2" title="View Schedule" (click)="openDialog(element.customerCode)">calendar_today</i> -->

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="teamList_Filter.length == 0" class="p-3 noList">Team Not Available</div>
</div>
