<div class="row">
  <div class="col-10">
    <h5 class="text-start mb-3 ps-2">
      <strong class="primary-color ms-1">{{ 'common.labels.Leave Request' | translate }} </strong>
    </h5>
  </div>
  <div class="col-2 text-end">
    <i class="material-icons cursor-pointer close-icon" (click)="closeDialog()">
      clear
    </i>
  </div>
</div>

<form [formGroup]="leaveform" (ngSubmit)="onSubmit()" *ngIf="resetForm">

  <div class="col-md-12">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
      <!-- <mat-label>{{ 'common.labels.From date' | translate }}</mat-label> -->
      <mat-label>Start Date</mat-label>
      <input [min]="today" matInput #nonworkingday formControlName="fromDate" [matDatepicker]="picker"
        (focus)="picker.open()" placeholder="">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker (closed)="nonworkingday.blur()"></mat-datepicker>
    </mat-form-field>
    <span class="help-block err"
      *ngIf="!leaveform.get('fromDate').valid && (!submitted || leaveform.get('fromDate').touched)">{{ 'common.Validators.From date is required' | translate }}</span>
  </div>

  <div class="col-md-12">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
      <!-- <mat-label>{{ 'common.labels.To date' | translate }}</mat-label> -->
      <mat-label>End Date</mat-label>
      <input [min]="today" matInput #nonworkingday formControlName="toDate" [matDatepicker]="picker2"
        (focus)="picker2.open()" placeholder="">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2 (closed)="nonworkingday.blur()"></mat-datepicker>
    </mat-form-field>
    <span class="help-block err"
      *ngIf="!leaveform.get('toDate').valid && (!submitted || leaveform.get('toDate').touched)">{{ 'common.Validators.To date is required' | translate }}</span>
  </div>

  <div class="col-md-12">
    <mat-form-field appearance="outline" floatLabel="always" >
      <mat-label>{{ 'common.labels.Leave a comment' | translate }}</mat-label>
      <textarea matInput placeholder="" formControlName="reason" cols="5" rows="3"></textarea>
    </mat-form-field>
    <span class="help-block err"
      *ngIf="!leaveform.get('reason').valid && (!submitted || leaveform.get('reason').touched)">{{ 'common.Validators.Reason is required' | translate }}</span>
  </div>

  <div class="col-md-12 text-center">
    <button mat-raised-button type="submit">{{ 'common.labels.Add Leave' | translate }}</button>
  </div>

</form>