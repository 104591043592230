import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProvidersDashboardService } from '../../providers-dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentService } from '../appointment.service';
import { SplitAppointmentComponent } from '../split-appointment/split-appointment.component';
import { AssignedWorkerComponent } from '../create-appointment/assigned-worker/assigned-worker.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss']
})
export class AppointmentListComponent implements OnInit {
  userType: any
  allAppontments = [];
  displayedColumns: string[] = ['patientName', 'procedureName', 'shift', 'location', 'status', 'workerName'];
  // 
  date = new Date();
  startDate = moment(this.date).format('YYYY-MM-DD');
  enddDate = moment(this.date).format('YYYY-MM-DD');
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('iframe') iframe: ElementRef;
  exportAsConfig: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'printTable', // the id of html/table element

  };
  isPreviewPDFLoading:boolean = false;
  showPopup;
  status = 'all';
  count = 0;
  norecords = false;
  constructor(public service: ProvidersDashboardService, public common: CommonService,
    private translate: TranslateService, private exportAsService: ExportAsService,     public dialog: MatDialog, public serviceAppointment: AppointmentService,
    public session: SessionStorageService,
  ) {
    this.translate.setDefaultLang(this.service.session.get('language'));

  }

  ngOnInit() {
    this.userType = this.session.get('user_type');
    this.showPopup = '';
    // this.formatedDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.getData();

  }
/* Name:srinivas
       use: return all appointment details */
  getData() {

    if (this.startDate > this.enddDate) {
      //console.log('End Date must be after Start Date.');
      this.service.common.message = 'End Date must be after Start Date.';
      this.service.common.errorMessage = true;
    } else {
      let statusData;
      if(this.status == 'all'){
        statusData = 'all';
      } else if(this.status == 'accepted'){
        statusData = 'accepted,inprogress,completed';
      } else if('pending') { 
        statusData = 'pending,notified';
      }
      this.getallAppointment(this.service.session.get('orgCode'), statusData, this.startDate, this.enddDate, 'all');
    }
  }
/* Name:srinivas
       use: get all appointment*/
  getallAppointment(code, status, startDate, endDate, patientCode) {
    this.service.common.progressLoader = true;
    try {
      
    
    this.service.adminGetAllAppointmentsByOrganizationCodeAndPatientWithFilter(code, status, startDate, endDate, patientCode, (response) => {
      //console.log(response);
      if (response.responsecode == 200) {
        this.count = response.count;
        this.allAppontments = response.data;
        this.norecords = false;
        this.dataSource = new MatTableDataSource(this.allAppontments);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.sort;
        this.service.common.progressLoader = false;
      }
      else {
        this.count = 0;
        this.norecords = true;
        this.allAppontments = [];
        this.dataSource = new MatTableDataSource();
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.sort;
        this.service.common.progressLoader = false;
      }
    });
    } catch (error) {
      
    }
  }
  /* Name:srinivas
       use: return filter details */
  applyFilter(filterValue: string) {
    this.showPopup = '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
    //////console.log( this.dataSource);
  }
  /* Name:srinivas
       use: save the data in excel */
  export() {
    this.exportAsService.save(this.exportAsConfig, 'Job List').subscribe(() => {
    });
  }

  /* Name:srinivas
       use: open data for print and save */
  printReport(): void {
    const printContents = document.getElementById('printTable');
    let current = document.getElementById("current")
    current.innerHTML = printContents.textContent;

    //console.log('printContents: ', printContents)

    // const WindowPrt = window.open( '' , 'Print', 'left=0,top=0,width=1800,height=1200,toolbar=0,scrollbars=0,status=0');
    const WindowPrt = window.open('');
    WindowPrt.document.write(printContents.innerHTML);
    // var dimg = document.getElementById('dimg');
    // dimg.innerHTML ='<img src="'+this.service.common.webLogo+'" >';
    setTimeout(() => {
      WindowPrt.print();
      WindowPrt.close();
    }, 1000);

    // this.iframe.nativeElement.name = "frame1";
    // this.iframe.nativeElement.style.position = "absolute";
    // this.iframe.nativeElement.style.top = "-1000000px";
    // document.body.appendChild(this.iframe.nativeElement);
    // let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
    // frameDoc.document.open();
    // frameDoc.document.write('<html><head><title>Mileage Report</title>');
    // frameDoc.document.write('</head><body>');
    // frameDoc.document.write(printContents);
    // frameDoc.document.write('</body></html>');
    // frameDoc.document.close();
    // setTimeout(() => {
    // window.frames["frame1"].focus();
    // window.frames["frame1"].print();
    // document.body.removeChild(this.iframe.nativeElement);
    // }, 1000);

  }
  
  print_PDF(div_id){
    this.isPreviewPDFLoading = true;
    let data = document.getElementById(div_id);  
    html2canvas(data, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      let fileWidth = 200;
      let fileHeight = canvas.height * fileWidth / canvas.width;    
      const FILEURI = canvas.toDataURL('image/jpeg');
      let PDF = new jspdf('p', 'mm', 'a4');
      PDF.addImage(FILEURI, 'JPEG', 5, 5, fileWidth, fileHeight, 'someAlias', 'FAST');
      PDF.setProperties({ title: 'Jobs List' });
      this.isPreviewPDFLoading = false;
      window.open(PDF.output('bloburl'));
    }); 
  }

  /* Name:srinivas
       use: on date change assign value */
  onDateChangeS(event) {
    this.startDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');

  }
/* Name:srinivas
       use:on date change assign value */
  onDateChangeE(event) {
    this.enddDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');

  }
  /* Name:srinivas
       use: show or hide popup */
  geteditData(element) {
    //console.log('element');
    //console.log(this.showPopup);
    if (this.showPopup == element.appointmentsDurationCode) {
      this.showPopup = '';
    } else {
      this.showPopup = element.appointmentsDurationCode;
    }
  }

/* Name:srinivas
       use: status wise details filter */
  changeStatus(event) {
    this.showPopup = '';
    //console.log('event', event);
    this.status = event.value;
    if(event?.value){
      this.getData();
    }
  }

  autoAssign() {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to auto staff assign?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          const orgCode = this.service.session.get('orgCode');
          this.service.common.progressLoader = true;
          this.service.common.percentageLoader = true;
          this.serviceAppointment.adminAssignJobsByOptaplanner(orgCode, this.startDate, this.enddDate, (res) => {
            //////console.log(res);
            if (res.responsecode == 200) {

              this.serviceAppointment.totalRecords = res.data.totalRecord;
              this.serviceAppointment.processedRecords = res.data.processRecord;
              this.service.common.progressText = 'Processing ' + this.serviceAppointment.processedRecords + ' of  ' + this.serviceAppointment.totalRecords + ' appointments';

              this.serviceAppointment.getAppointmentThreadProcessingByCode(res.data.appointmentsThreadProcessingCode, (response) => {
                let arrayData = [];
                response.data.ewresLst.forEach(element => {
                  if (element.workerWithDistanceList.length > 0) {
                    arrayData.push(element);
                  }
                });
                setTimeout(() => {
                  //console.log(arrayData);
                  this.service.common.progressLoader = false;
                  this.service.common.progressText = '';
                  this.service.common.percentageLoader = false;
                  if (arrayData.length == 0) {
                    this.getSplitAppointment(response.data.appointmentsDurationCodeList);
                  } else {

                    this.openDialog(arrayData);
                  }
                }, 2000);
              })
            } else {
              this.service.common.progressLoader = false;
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }

          })
        } catch (error) {
          //console.log(error)
        }

      }
    });
    
  }

  getSplitAppointment(jobDurationCode){
    const dialogRef = this.dialog.open(SplitAppointmentComponent, {
      width: '450px',
      disableClose: true,
      data: { jobDurationCode }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        // this.isShowRefresh = false;
        // this.getAppointments();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }
  openDialog(workers): void {
    ////console.log(this.currentDate);
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      width: '560px',
      disableClose: true,
      data: { data: workers, fromdate: this.startDate, toDate: this.enddDate, scheduledDate: this.startDate }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      this.getData();
    });
  }
}
