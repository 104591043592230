
  <div class="col-md-12">
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">

      <ng-container matColumnDef="distributionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 85%;">Distribution Lists 
          <span class="addIcon" (click)="$event.stopPropagation(); openAddDistribution()">
            <mat-icon title="Assign/Unassign Distribution">add</mat-icon>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.distributionName}} </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!technicianService.common.progressLoader && !technicianService.common.infiniteLoader) && listData.length === 0" 
    class="p-3 noList text-danger">
    {{'distributionList.messages.Distribution are not available' | translate }}</div>
    </div>

    <div class="w-100" *ngIf="technicianService.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>
    
  </div>