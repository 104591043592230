import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../skills/skill-list/skill-list.component';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent implements OnInit {

  @ViewChild('mapDis', { static: true }) mapDis;
  directionsService = new google.maps.DirectionsService();
  directionsDisplay;
  isZoom: boolean;
  duration: any;
  distance: any;
  map;
  nextAddress = 0;
  bounds;
  delay = 100;

  constructor(
    public dialogRef: MatDialogRef<SiteMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public common: CommonService
  ) { }

  ngOnInit() {
    this.initialize();
  }

  /* Name:Shailesh
      use: to intialize the map */
  initialize() {
    this.isZoom = false;
    this.map = new google.maps.Map(document.getElementById('map'),{
        zoom: 12,
        center: new google.maps.LatLng(32.483, 16.084),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
    });
    this.bounds = new google.maps.LatLngBounds();
    this.data['data'].forEach(element => {
    let startData = element;
    let start = startData.workerLatitude + ',' + startData.workerLongitude;
    let end = startData.patientLatitude + ',' + startData.patientLongitude;
    let request = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.DRIVING
    };
    this.directionsService.route(request, (result, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        this.directionsDisplay = new google.maps.DirectionsRenderer({
          suppressBicyclingLayer: true,
          suppressMarkers: true,
          preserveViewport: true // don't zoom to fit the route
        });
        this.directionsDisplay.setMap(this.map);
        this.directionsDisplay.setDirections(result);
        const infowindow = new google.maps.InfoWindow();
        let legs = result.routes[0].legs;
        let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        let image;
        if (startData.appointmentsStatus == 'late or cancelled') {
          image = 'pointer_red.png'
        } else {
          image = 'pointer_green.png'
        }
        let marker = new google.maps.Marker({
          position: legs[0].start_location,
          map: this.directionsDisplay.getMap(),
          label: {
            text: labels[0],
            color: '#000',
            fontSize: "20px"
          },
          icon: { url: './../../assets/' + image },
          zIndex: Math.round(legs[0].start_location.lat() * -100000) << 5
        });
        google.maps.event.addListener(marker, 'click', function () {
          infowindow.setContent('<b>Clinician Name:</b>' + startData.workerName + '</br><b>Address</b>: ' + legs[0].start_address);
          infowindow.open(this.map, marker);
        })
        var marker1 = new google.maps.Marker({
          position: legs[0].end_location,
          map: this.directionsDisplay.getMap(),
          label: {
            text: labels[1],
            color: '#000',
            fontSize: "20px"
          },
          icon: { url: './../../assets/' + image },
          zIndex: Math.round(legs[0].end_location.lat() * -100000) << 5
        });
        google.maps.event.addListener(marker1, 'click', function () {
          infowindow.setContent('<b>Patient Name:</b>' + startData.patientName + '</br><b>Address</b>: ' + legs[0].end_address + "<br> " + '<b>Drive Time</b>: ' + legs[0].duration.text);
          infowindow.open(this.map, marker1);
        })
        this.bounds.union(result.routes[0].bounds);
        this.map.fitBounds(this.bounds);
      }
    });
    
    });

    this.common.progressLoader = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}




