<div class="d-flex justify-content-between w-100 logo align-items-center">
  
  <div class="float-start header-name" *ngIf="!routeData.id"> Add Department</div>
  <div class="float-start header-name" *ngIf="routeData.id"> Edit Department</div>


  <div class="text-center float-end">
    <button mat-flat-button class="primary-btn" (click)="onSubmit()">Save Changes</button>
    <div>
      <a mat-button (click)="cancelPopup()">Cancel</a>
    </div>
  </div>

</div>


  

    <form [formGroup]="deptForm" (ngSubmit)="onSubmit()">

      <div class="d-flex flex-row w-100 justify-content-center logo align-items-center">
        <div class="p-2 profile-box mb-4">
          <div class="img-proc">
            <img alt="No Image" *ngIf="imageupload" [src]="fileUrl" #imageUploaded class="profile-image avatar huge"  title="Department Image" />
            <img alt="No Image" *ngIf="!imageupload" src="assets/common_images/profile.jpg" title="No image" #imageUploaded
              class="profile-image avatar huge" />
          </div>
          <input type="file" enctype="multipart/form-data" (change)="onFileChange($event)" #fileInput
            style="display: none">
          <i class="material-icons logo-icon pointer" (click)="fileInput.click()">
            edit
          </i>
        </div>
      </div>

    <div class="row">  

      <div class="form-group col-md-6 col-sm-6 ">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Department Name</mat-label>
          <input autoClose matInput formControlName="departmentName" type="text" placeholder=""
            [(ngModel)]="service.deptData.departmentName">
        </mat-form-field>
        <span class="help-block err"
          *ngIf="deptForm.controls.departmentName.errors?.required && (!submitted || deptForm.controls.departmentName.touched)">
          Deparment name is required</span>
      </div>
      <div class="form-group col-md-6 col-sm-6 p">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Parent Deparment Name</mat-label>
          <input matInput [matAutocomplete]="auto" formControlName="parentId" placeholder="">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="form-group col-md-6 col-sm-6 ">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Status</mat-label>
          <mat-select formControlName="isActive" [(ngModel)]="service.deptData.isActive">
            <mat-option value="true"> Active </mat-option>
            <mat-option value="false"> Inactive </mat-option>
          </mat-select>

        </mat-form-field>
        <span class="help-block err"
          *ngIf="deptForm.controls.isActive.errors?.required && (!submitted || deptForm.controls.isActive.touched)">
          Status is required</span>
      </div>

   
    </div>
    </form>

 
