import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveService } from '../leave-service';
import { TranslateService } from '@ngx-translate/core';
import { ExportAsService } from 'ngx-export-as';
import { debounce } from 'lodash';
 import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { CommonService } from 'src/app/shared/common.service';
import * as moment from 'moment';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

@Component({
  selector: 'pending-leaves',
  templateUrl: './pending-leaves.component.html',
  styleUrls: ['./pending-leaves.component.scss']
})

export class PendingLeavesComponent implements OnInit, OnDestroy {
  @Input() isShortcutModalOpen: any;
  leaveStatus: string = 'pending';
  distributionData: any = [];
  displayedColumns: string[] = ['name', 'startDate', 'endDate', 'comment', 'leaveStatus', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  today: any;

  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    public service: LeaveService,
    private translate: TranslateService,
     public common: CommonService,
    ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }

  ngOnInit() {
    this.page = 0;
    this.getListDataByPage();
    this.today = moment(new Date()).format('MM-DD-YYYY');
    this.hasPermission =  this.service.common.getBtnEventPermissions("Time Off");
    this.subToPermissionChanged();
  }

  approveRequest(data: any){
    let approveStatus = 'approved';
    let msg;
    if(data.startDate && data.endDate)
    {
      msg = 'Are you sure you want to approve the leave request from '+ data.startDate + ' to ' +data.endDate + '?'
    }
    else
    {
      msg = 'Are you sure you want to approve the leave request?'
    }
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: msg }
    });
  
    dialogRef.afterClosed().subscribe(result => {

      //console.log('result: ', result)
  
      if(result && result.toLowerCase() === 'ok'){
        // //console.log('Data: ', data);
        if(data?.leaveDetailsCode && approveStatus){
          // //console.log('Approve: ', data?.leaveDetailsCode, approveStatus);
          // return;
          this.service.common.progressLoader = true;
          this.service.updateLeaveStatus(data.leaveDetailsCode, approveStatus, null, (response) => {
                  
              if (response.responsecode == 200) {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.successMessage = true;
                this.getListDataByPage();
              } else {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.errorMessage = true;
              }
        })
        }
  }
  })
  }

  declineRequest(data: any){
    let approveStatus = 'rejected';
    let msg;
    if(data.startDate && data.endDate)
    {
      msg = 'Are you certain you wish to decline the leave request for the period from '+ data.startDate + ' to ' +data.endDate + '?'
    }
    else
    {
      msg = 'Are you sure you want to decline the leave request.'
    }

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: msg }
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
    if(result && result.toLowerCase() === 'ok'){
        // //console.log('Data: ', data);
        if(data?.leaveDetailsCode && approveStatus){
          // //console.log('Rejected: ', data?.leaveDetailsCode, approveStatus);
          // return;
          this.service.common.progressLoader = true;
          this.service.updateLeaveStatus(data.leaveDetailsCode, approveStatus, null, (response) => {
                  
              if (response.responsecode == 200) {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.successMessage = true;
                this.getListDataByPage();
              } else {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.errorMessage = true;
              }
        })
        }
    }
  })
  }
  
   /* Name:Vivek Chauhan
  use: for getting all Org Distribution Master data by org code */
  getListDataByPage(scroll = null) {
      // //console.log('getListDataByPage: ', this.page, scroll);
      if (!scroll) {
        this.service.common.progressLoader = true;
      }
      else if (scroll) {
        this.service.common.infiniteLoader = true;
      }
      try {
        this.service.getListDataByPagePending(this.service.storage.get('orgCode'), false, this.page, this.limit, (response) => {
          if(response.responsecode === 200){
            // this.getGlobalLeaveCount(this.service.storage.get('orgCode'));
            if (scroll) {
              let arrayData = this.dataSource.data;
              response.data.forEach(element => {
                arrayData.push(element)
              });
              setTimeout(() => {
                this.distributionData = arrayData;
                this.dataSource = new MatTableDataSource(arrayData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.infiniteLoader = false;
              }, 1000);

            } else {
              ////console.log(response.data);
              setTimeout(() => {
                this.distributionData = response.data;
                this.dataSource = new MatTableDataSource(this.distributionData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.progressLoader = false;
              }, 1000)
            }
          } else {
            if (!scroll) {
              this.distributionData = [];
              setTimeout(() => {
                this.dataSource = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
              }, 1000);      
            } else {
              this.service.common.infiniteLoader = false;
            }
            this.service.common.progressLoader = false;
          }
      });    
      } catch (error) {
        //console.log(error);
      }
  }


  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.getListDataByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }

  /* Name:Vivek Chauhan
  use: for seraching Distribution Data name */
    applyFilter(searchText: string) {
      // //console.log('applyFilter', searchText);
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
    this.page = 0;
      this.service.common.progressLoader = true;
    try {
      if(searchText && searchText.trim()){
      this.service.getListDataBySearchPending(this.service.storage.get('orgCode'), false, searchText, (response) => {
        if(response.responsecode === 200){
            setTimeout(() => {
              this.distributionData = response.data;
              this.dataSource = new MatTableDataSource(this.distributionData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.progressLoader = false;
            }, 1000);
        } else {
          this.service.common.progressLoader = false;
            this.distributionData = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 1000);          
        }
    });
    } else {
      this.getListDataByPage();
    } 
    } catch (error) {
      //console.log(error);
    }
  }

  // getGlobalLeaveCount(orgCode: string) {
  //   this.common.getLeavePendingCount(orgCode, (data) => {
  //     if (data.responsecode == 200) {
  //       this.common.globalLeaveCount = data.count;
  //     } else {
  //       this.common.globalLeaveCount = 0;
  //     }
  //   })
  // }



  subToPermissionChanged()
{
 this.common.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this.common.getBtnEventPermissions("Time Off");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }


}




