import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { jobCardActions } from 'src/app/shared/interface/core.interface';
// import { TranslateService } from '@ngx-translate/core';
// import { SessionStorageService } from 'angular-web-storage';
// import { NewJobCardService } from './new-job-card.service';
// import { MatDialog } from '@angular/material/dialog';
// import { CommonService } from 'src/app/shared/common.service';
//  import { Subject } from 'rxjs';
// import * as moment from 'moment';
// import { DashboardService } from 'src/app/core/services/dashboard-service.service';
// import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { NotifiedListComponent } from 'src/app/components/new-job-card/notified-list/notified-list.component';
// import { AssignJobComponent } from 'src/app/components/new-job-card/assign-job/assign-job.component';
// import { NotifyJobComponent } from 'src/app/components/new-job-card/notify-job/notify-job.component';
// import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
// import { cust_st_jt_dts } from 'src/app/shared/interface/core.interface';

@Component({
  selector: 'app-new-job-card',
  templateUrl: './new-job-card.component.html',
  styleUrls: ['./new-job-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewJobCardComponent  {

  // userType: any;

  dataSource_filter: any[];
  @Input() dataSource: any[];
  @Input() status: string;
  @Input() is_Loading: boolean = false;
  @Output() updateParent = new EventEmitter();

  // @ViewChild('notifypopup') notifypopup: TemplateRef<any>
  // @ViewChild("EditJob_PopUp") EditJob_PopUp: TemplateRef<any>;
  // @ViewChild("ViewInfo_PopUp") ViewInfo_PopUp: TemplateRef<any>;
  // @ViewChild("ViewJobDetails_PopUp") ViewJobDetails_PopUp: TemplateRef<any>;
//   img_serverPath: string = '';
//   dialogRef: any;
//   statusCount: any = [];
//   shiftStatusCount = [];
//   popupTitle: any;
//   currentDate = new Date();
//   appointmentData = [];
//   startDate: any;
//   endDate: any;
//   selectedMonth: any;
//   currentDateSelected = '';
//   ShowSelectedStart;
//   ShowSelectedEnd;
//   startTime = '00:00:00';
//   endTime = '23:59:59';
//   yearMonthDateFormat = 'YYYY-MM-DD';
//   monthDateYear = 'MM-DD-YYYY'
//   dateMonthYear = 'DD-MM-YYYY'
//   dateMonthNameFormat = 'DD MMMM';
//   dateShotMonthName = 'DD MMM';
//   selectDate: any;
//   selectedDate: any;
//   currentSelectedDate;
//   protected _onDestroy = new Subject<void>();
//   EditJobmodalData: any
//   copiedJobData: any;
//   is_JobCopied: boolean = false;
//   jobInfo:any;
//   bsModalRef: BsModalRef; 
//  config = {
//   animated: true,
//   keyboard: true,
//   backdrop: true,
//   ignoreBackdropClick: true,
//   class: "modal-xl",
// };
// View_JobmodalData:any ;

@Output() trigger_nextSevenDays = new EventEmitter();
@Output() trigger_previousSevenDays = new EventEmitter();

@Input() allowActions : jobCardActions = {
  allowEdit: true,
  allowDelete: true,
  allowAssignStaff: true,
  allowNotifyStaff: true,
  allowToViewNotifiedStaff: true,
  allowToCopyJob: true,
  allowView: true,
  allowRosterPrint: false,
  allowViewInfo: true,
  allowToAddScheduledStaff : true
};

// @Input() allowToCopyJob : boolean  = true ;  // default allow to copy/paste job
// protected job_shortData_Modal : cust_st_jt_dts ;

  // constructor(private _translateSVC: TranslateService,
  //   private _sessionSVC: SessionStorageService,
  //   private _newCardSVC: NewJobCardService,
  //   private dialog: MatDialog,
  //   public _commonSVC: CommonService,
  //   public dashboarService: DashboardService,
  // ) {  }


  // ngOnInit(): void {
  //   this.userType = this._sessionSVC.get('user_type');
  //   this.img_serverPath = this._newCardSVC._apiSVC.getSuperAdmin() + '/downloadFile/';
  //   this.selectDate = moment(this.currentDate).format('D');
  //   this.selectedDate = moment(this.currentDate, this.yearMonthDateFormat).format(this.dateMonthNameFormat);
  //   this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
  //   this.currentDateSelected = this.selectedDate;
  //   let firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
  //   let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  //   this.startDate = moment(firstDay, this.yearMonthDateFormat).format(this.yearMonthDateFormat);
  //   this.endDate = moment(lastDay, this.yearMonthDateFormat).format(this.yearMonthDateFormat);
  //   ////console.log(this.startDate);
  //   this.ShowSelectedStart = this.currentSelectedDate;
  //   this.ShowSelectedEnd = this.currentSelectedDate;
  //   this.dataSource_filter = this.dataSource;
  // }

  // ngOnDestroy() {
  //   this._onDestroy.next();
  //   this._onDestroy.complete();
  // }

  // ngOnChanges() {
  //   if (this.dataSource.length > 0) {
  //     this.dataSource.forEach(element => {
  //       element.week_day = moment(element.jobDateFixedFormat).format('ddd');
  //       element.month_day = moment(element.jobDateFixedFormat).format('D');
  //       element.month_name = moment(element.jobDateFixedFormat).format('MMM');
  //     })
  //   }
  // }

  // getNotifyList(status: any, appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
  //   this.popupTitle = status;
  //   const dialogRef = this.dialog.open(NotifiedListComponent, {
  //     width: '50%',
  //     disableClose: false,
  //     data: {status: status, appoinmentCode: appoinmentCode, appoinmentProcedureCode: appoinmentProcedureCode, date: date}
  //   }); 
  // }

  // openAssignPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
  //   let popupData = {
  //     appoinmentCode: appoinmentCode,
  //     appoinmentProcedureCode: appoinmentProcedureCode,
  //     date: date
  //   }

  //   this.dialogRef = this.dialog.open(AssignJobComponent, {
  //     disableClose: true,
  //     width: '700px',
  //     data: popupData
  //   });

  //   this.dialogRef.afterClosed().subscribe(result => {
  //     console.log('CloseAssignPopupReq:', result);
  //     if (result != undefined && result === 'success') {
  //       this.updateParent.emit('');
  //     }
  //   });    
  // }

  // openNotifyPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
  //   let popupData = {
  //     appoinmentCode: appoinmentCode,
  //     appoinmentProcedureCode: appoinmentProcedureCode,
  //     date: date
  //   }

  //   this.dialogRef = this.dialog.open(NotifyJobComponent, {
  //     disableClose: true,
  //     width: '500px',
  //     data: popupData
  //   });

  //   this.dialogRef.afterClosed().subscribe(result => {
  //     // console.log('CloseNotifyPopupReq:', result);
  //     if (result != undefined && result === 'success') {
  //       this.updateParent.emit('');
  //     }
  //   }); 
  // }

  

  // showScheduledStaff(
  //    customerName:string, 
  //    date:string,
  //    siteName:string,
  //    siteAddress:string,
  //    appointmentsCode:string,
  //    selcetedJob_Data:any,
  //    tempRef: TemplateRef<any>) 
  // {
  //   // this.job_shortData_Modal = {
  //   //   customerName: customerName,
  //   //   date: date,
  //   //   siteName : siteName,
  //   //   siteAddress : siteAddress ,
  //   //   appointmentsCode: appointmentsCode ,
  //   //   jobType_Data : selcetedJob_Data

  //   // }

  //   this.job_shortData_Modal = {
  //     customerName: customerName,
  //     date: date,
  //     siteName : siteName,
  //     siteAddress : siteAddress ,
  //     appointmentsCode: appointmentsCode ,
  //     appointmentsProceduresCode : selcetedJob_Data.appointmentsProceduresCode,
  //     jobTypeName : selcetedJob_Data.jobTypeName,
  //     jobTypeIcon : selcetedJob_Data.jobTypeIcon,
  //     rateCard : selcetedJob_Data.showRateCard,  
  //     jobTypeColor : selcetedJob_Data.jobColor 
  //   }

  //   this.dialogRef = this.dialog.open(tempRef, { width: '85%' });

  //   this.dialogRef.afterClosed().subscribe((result) => {
  //     //this.job_shortData_Modal = "";
  // });

  // }

  // refreshData(event:any) {
  //   this.dialogRef.close();
  //   this.updateParent.emit('');
  // }

  // CardEditJob(appointmentsCode: string, appointmentsProceduresCode: string, jobDate: string) {
  //   const initialState = {
  //     appointmentsCode: appointmentsCode,
  //     appointmentsProceduresCode: appointmentsProceduresCode,
  //     jobDate: jobDate,
  //     isOpenFrom: 'Job_Card_View'      
  //     // jobDate: selectDate.date, //
  //   };
  //   this.EditJobmodalData = initialState;
  //   this.dialogRef = this.dialog.open(this.EditJob_PopUp, { width: '80%', height:'80%' });
  // }

  // editJob_RefreshList(event) {
  //   this.dialogRef.close();
  //   this.updateParent.emit('');
  // }

  // cancelCopyPaste(){
  //   this.is_JobCopied = false;
  //   this.copiedJobData = '';
  // }

  // CopyJob(customerCode: string, siteAddressCode: string, childData: any) {
  //   let data = {
  //     customerCode: customerCode,
  //     siteAddressCode: siteAddressCode,
  //     jobData: childData
  //   }
  //   this.is_JobCopied = true;
  //   this.copiedJobData = data;
  //   // console.log("this.copiedJobData", this.copiedJobData);
  // }

  // pasteJobOnSelectedDay(day: any){
  //    if (this.copiedJobData && day) {
  //     let array: any = [];
  //     let temp: any = {
  //       shiftMasterCode: this.copiedJobData.jobData.shiftMasterCode,
  //       startTime: this.copiedJobData.jobData.startTime,
  //       endTime: this.copiedJobData.jobData.endTime,
  //       equipments: this.copiedJobData.jobData.equipments,
  //       numberOfWorker: this.copiedJobData.jobData.required,
  //       organizationBaseProcedureCode: this.copiedJobData.jobData.organizationBaseProcedureCode,
  //       uniform: this.copiedJobData.jobData.uniform,
  //       ratePerHours : this.copiedJobData.jobData.ratePerHour
  //     };
  //     array.push(temp);
  //     // let jobDate = moment(day.date).format(this.yearMonthDateFormat);
  //     let jobDate = day.yearMonthDateFormat;
  //     // let orderDate = moment(day.date).format(this.yearMonthDateFormat);
  //     let final_Data: any =
  //      {
  //       customerAddressCode: this.copiedJobData.siteAddressCode,
  //       // customerAdminCode: this.copiedJobData.jobData.customerAdminCode,
  //       customerAdminCode: null, // passing null value when copy/paste job
  //       customerCode: this.copiedJobData.customerCode,
  //       endDate: jobDate,
  //       startDate: jobDate,
  //       jobTypeWithJobData: array,
  //       // orderDT: orderDate,
  //       orderDT: '',
  //       organizationCode: this._sessionSVC.get("orgCode"),
  //       siteContact: this.copiedJobData.jobData.siteContact,
  //       siteContactPh: this.copiedJobData.jobData.siteContactPh,
  //       specification: this.copiedJobData.jobData.specification,
  //       jobId: "",
  //       isDateOfMonth: false,
  //       daysList: [],
  //       repeatType: "day",
  //       repeatValue: 1,
  //     };

  //     this._commonSVC.progressLoader = true;
  //     this._newCardSVC.createNewJob(final_Data).subscribe((res: any) => {
  //       this._commonSVC.progressLoader = false;

  //       if (res.responsecode == 200) 
  //       {
  //         this.updateParent.emit('');
  //         this.is_JobCopied = false;
  //         this.copiedJobData = '';
  //         this._commonSVC.successMessage = true;
  //         this._commonSVC.message = res.message;
  //       }
  //       else 
  //       {
  //         this._commonSVC.errorMessage = true;
  //         this._commonSVC.message = res.message;
  //       }
  //     });
  //   }
  // }

  // CardViewInfo(data:any){
  //  this.jobInfo = data;
  //  this.dialogRef = this.dialog.open(this.ViewInfo_PopUp, { width: '60%' });
  //  this.dialogRef.afterClosed().subscribe(result => { this.jobInfo = '' ;  });
  // }

  // showActionsButtons(date:string){
  //   return  moment(new Date(date)).format("MM-DD-YYYY") < moment(new Date()).format("MM-DD-YYYY") 
  // }

  // showPreviousBtn(date:string){
  //   return  moment(new Date(date)).format("MM-DD-YYYY") <= moment(new Date()).format("MM-DD-YYYY") 
  // }

  // CardViewJob(appointmentsCode: string, appointmentsProceduresCode: string, jobDate: string){ 
  //   const initialState = {
  //     appointmentsCode: appointmentsCode,
  //     appointmentsProceduresCode: appointmentsProceduresCode,
  //     jobDate: jobDate,
  //     isOpenFrom: 'Job_Card_View'
  //   };
  //   this.View_JobmodalData = initialState;
  //   this.dialogRef = this.dialog.open(this.ViewJobDetails_PopUp, { width: '70%' });
  // }

  // nextSevenDays(){
  //   this.trigger_nextSevenDays.emit();
  // }

  // PreSevenDays(){
  //   this.trigger_previousSevenDays.emit();
  // }


  // deleteJob(appointmentsCode: string, appointmentsProceduresCode: string, jobDate: string) 
  // {

  //   const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
  //     width: '400px',
  //     disableClose: true,
  //     data: { msg: 'Are you sure want to delete this shift?' }
  //   });
  
  //   dialogRef.afterClosed().subscribe(result => 
  //     {
  //     if(result && result.toLowerCase() === 'ok')
  //     {
  //       this._commonSVC.progressLoader = true;
  //       this._newCardSVC.deleteJob(appointmentsCode, jobDate, appointmentsProceduresCode, this._sessionSVC.get("orgCode")).subscribe((res: any) => {
  //         this._commonSVC.progressLoader = false;
    
  //         if (res.responsecode == 200) 
  //         {
  //           this.updateParent.emit('');
  //           this._commonSVC.successMessage = true;
  //           this._commonSVC.message = res.message;
  //         }
  //         else 
  //         {
  //           this._commonSVC.errorMessage = true;
  //           this._commonSVC.message = res.message;
  //         }
  //       });
  //     }
  //   });


  // }

 

handleEvent(event:any)
{
  this.updateParent.emit(event)
}

}
