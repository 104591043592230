import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { ShiftService } from './shift.service';
import { CommonService } from 'src/app/shared/common.service';
import { AddShiftComponent } from './add-shift/add-shift.component';
import { MatSort } from '@angular/material/sort';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-shift-segment',
  templateUrl: './shift-segment.component.html',
  styleUrls: ['./shift-segment.component.scss'],
})
export class ShiftSegmentComponent implements OnInit, OnDestroy {

  @ViewChild('addShift_TempRef') addShiftRef: ElementRef;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  selectedTime: Date = new Date();
  dataSource = new MatTableDataSource();
  // displayedColumns: string[] = ['shiftName', 'startTime', 'endTime','breakStartTime', 'breakEndTime', 'grace' ,'check-in','actions'];
  displayedColumns: string[] = ['startTime', 'endTime', 'actions'];

  page: number = 0;
  limit: number = 30;
  dialogRef: any;
  is_formSubmitted: boolean = false;
  is_btnLoading: boolean = false;
  show_tbl_data_progressbar : boolean = false;

  controlsPermissions:IBtnControlsPermission ;
  private clearObs = new Subject<any>();

  constructor(
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    private dialog: MatDialog,
    private _shiftSVC: ShiftService,
    private _commonSVC: CommonService,

  ) {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
  }


  ngOnDestroy(): void {
    this.clearObs.next(null);
    this.clearObs.complete();

  }

  ngOnInit(): void {

    this.getShiftsByPage();
    this.controlsPermissions =  this._commonSVC.getBtnEventPermissions();
    this.subToPermissionChanged();
  }



  getShiftsByPage(isTBL_Scroll?: string) {
    this.show_tbl_data_progressbar = true;
    this._shiftSVC.getShiftMasterByPage(this._sessionStorageSVC.get('orgCode'), this.page, this.limit).subscribe((res: any) => {
      this.show_tbl_data_progressbar = false;
      if (res.responsecode == 200) {
        if (isTBL_Scroll) {
          let temp_array = this.dataSource.data;
          res.data.forEach(element => {
            element.isExpanded = true;
            temp_array.push(element);
          });
          this.dataSource = new MatTableDataSource(temp_array);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.matSort;
        }
        else {

          res.data.forEach(element => {
            element.isExpanded = true;
          });
          this.dataSource = new MatTableDataSource(res.data);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.matSort;
        }


      }
    })
  }

  onScroll() {
    this.page = (this.page + 1);
    this.getShiftsByPage('scroll');
  }

  openShiftPopUp(): void {

    this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.page = 0;
        this.getShiftsByPage();
      }

    });
  }

  updateShift(data: any) {
    this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true, data: data });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.page = 0;
        this.getShiftsByPage();
      }

    });
  } 

  closePopUp() {
    this.dialog.closeAll();
  }


  deleteShift(element: any) {
    if (element.shiftMasterCode) {
      this._shiftSVC.softDeleteShift(element.shiftMasterCode).subscribe((res: any) => {

        if (res.responsecode == 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
          this.page = 0;
          this.getShiftsByPage();
        }
        else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      })
    }
  }



  toggleRow(element: any): void {
    element.isExpanded = !element.isExpanded;
    //this.dataSource.data = [...this.dataSource.data];
  }


  active_deactive_shift(element: any) {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: "Are you sure want to " + (element.isActive ? 'InActive' : 'Active') + " this shift" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.toLowerCase() === 'ok') {

        let req_body = {
          "isActive": element.isActive ? false : true
        }

        this._shiftSVC.setActiveOrInActiveShifts(element.shiftMasterCode, req_body).subscribe((res: any) => {

          if (res.responsecode == 200) {
            this._commonSVC.successMessage = true;
            this._commonSVC.message = res.message;
            this.dataSource = new MatTableDataSource();
            this.page = 0;
            this.getShiftsByPage();
          }
          else {
            this._commonSVC.errorMessage = true;
            this._commonSVC.message = res.message;
          }
        })

      }
    });

 }


 subToPermissionChanged()
 {
  this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    console.log("newPermissions" , res);
    if(res.responsecode === 200)
      {
        this.controlsPermissions =  this._commonSVC.getBtnEventPermissions();
      }
  })
 }


}
