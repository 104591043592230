import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { AdminCustomerReportService } from '../admin-customer-report.service'; 
import { ApiRootService } from 'src/app/shared/api-root.service';
import * as moment from 'moment';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'change-time-card',
  templateUrl: './change-time-card.component.html',
  styleUrls: ['./change-time-card.component.scss']
})
export class ChangeTimeCardComponent implements OnInit {

  inputForm: FormGroup;
  clinicianCode: any;
  // currentDate = new Date();
  currentTime: any;
  orgCode: any;
  submitted = true;
  // formatedDate : string = moment(this.currentDate, 'MM-DD-YYYY').format('YYYY-MM-DD');

  checkinTime: FormControl = new FormControl(null, [Validators.required]);
  checkoutTime: FormControl = new FormControl(null, [Validators.required]);

  myDateValue: Date;
  isMeridian = false;
  mytime: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeTimeCardComponent>,
    private _translateSVC: TranslateService,
    public _storageSVC: SessionStorageService,
    private service : AdminCustomerReportService,
    private _apiService: ApiRootService,
    
    ) {
    this._translateSVC.setDefaultLang(this._storageSVC.get('language'));

    this.inputForm = new FormGroup({
      "organizationCode":new FormControl(null),
      "clinicianCheckinDetilsCode": new FormControl(null),
      "checkinDateTime":new FormControl(null, [Validators.required]),
      'checkoutDateTime' :new FormControl(null, [Validators.required]),
      'sendOnlyNotification' :new FormControl(false),
      'comment': new FormControl(null, [Validators.required])
    }); 

    const d = new Date(),
      h = (d.getHours()<10?'0':'') + d.getHours(),
      m = (d.getMinutes()<10?'0':'') + d.getMinutes();
    this.currentTime = h + ':' + m;
  }

  ngOnInit() {

    this.myDateValue = new Date();

    this.orgCode = this._storageSVC.get('orgCode'); 
    this.clinicianCode = this.data?.clinicianCheckinDetilsCode;

    if(this.data && this.orgCode){
      this.inputForm.controls['organizationCode'].setValue(this.orgCode);
    }
    if(this.data && this.data?.comment){
      this.inputForm.controls['comment'].setValue(this.data.comment);
    }
    if(this.data?.clinicianCheckinDetilsCode){
      this.inputForm.controls['clinicianCheckinDetilsCode'].setValue(this.data.clinicianCheckinDetilsCode);
    }

    // if(this.data?.checkinDateTime){    
    //   this.inputForm.controls['checkinDateTime'].setValue(this.dateForSetDate(this.data.checkinDateTime));
    //   this.checkinTime.setValue(this.convertTo12HourFormat(this.data.checkinDateTime));
    // }
    // if(this.data?.checkoutDateTime){      
    //   this.inputForm.controls['checkoutDateTime'].setValue(this.dateForSetDate(this.data.checkoutDateTime));
    //   this.checkoutTime.setValue(this.convertTo12HourFormat(this.data.checkoutDateTime));
    // }

    if(this.data?.checkinDateFixedFormat || this.data?.checkinDateTime){    
      // this.inputForm.controls['checkinDateTime'].setValue(this.dateForSetDate(this.data.checkinDateTime));

      this.inputForm.controls['checkinDateTime'].setValue(
        this.data.checkinDateFixedFormat ? moment(this.data.checkinDateFixedFormat,'YYYY-MM-DD').toDate() : moment(this.data.checkinDateTime, 'DD-MM-YYYY').toDate()
      );

      this.checkinTime.setValue(this.convertTo12HourFormat(this.data.checkinDateTime));
    }

    if(this.data?.checkoutDateFixedFormat || this.data?.checkoutDateTime)
      {      
      // this.inputForm.controls['checkoutDateTime'].setValue(this.dateForSetDate(this.data.checkoutDateTime));

      this.inputForm.controls['checkoutDateTime'].setValue(
        this.data.checkoutDateFixedFormat ? moment(this.data.checkoutDateFixedFormat,'YYYY-MM-DD').toDate() : moment(this.data.checkoutDateTime, 'DD-MM-YYYY').toDate()
      );

      this.checkoutTime.setValue(this.convertTo12HourFormat(this.data.checkoutDateTime));
    }

  }

  dateForSetDate(dateVal: any){
    const date = dateVal.slice(0, 10);
    const newdate = date;
    if(date){
      const formatedDate = moment(newdate, 'MM-DD-YYYY').format('YYYY-MM-DD');
      // //console.log('NewDatete: ', dateVal, date, newdate);
      return formatedDate;
    }
  }

  convertTo12HourFormat(dateVal: any){
    const time12Hour = dateVal.slice(11);

    const [time, period] = time12Hour.split(' ');
    const [hours, minutes] = time.split(':');
    
    // Convert hours to a number
    let hour = parseInt(hours, 10);
    
    // If the period is PM and the hour is not 12, add 12 to the hour
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    }
    
    // If the period is AM and the hour is 12, set the hour to 0
    if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    
    // Format the hour and minutes as two-digit strings
    const formattedHour = hour.toString().padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    
    // Create the 24-hour time string
    const time24Hour = `${formattedHour}:${formattedMinutes}`;

    // //console.log('time24Hour: ', time24Hour, time12Hour);
    
    return time24Hour;
  }

  onDateChange(event:any, formField: any) {
    // console.log('onDateChange', event.value);
    this.checkShiftGrateEaightHrsOrNot();
  }

  onTimeChange(event:any, formField: any) {
    if(event.target.value){
    // //console.log('onTimeChange: ', event.target.value, formField);
    if(formField === 'checkinTime'){
      this.checkinTime.setValue(event.target.value);
    }
    if(formField === 'checkoutTime'){
      this.checkoutTime.setValue(event.target.value);
    }
    this.checkShiftGrateEaightHrsOrNot();
    }
  }

  checkShiftGrateEaightHrsOrNot(){ 
    if(this.inputForm.controls.checkinDateTime.value && this.inputForm.controls.checkoutDateTime.value){

      // Define the two date objects
      const selectedInDate = Number(new Date(this.inputForm.controls.checkinDateTime.value));
      const selectedOutDate = Number(new Date(this.inputForm.controls.checkoutDateTime.value));

      // Calculate the difference in milliseconds
      const timeDifference = selectedOutDate - selectedInDate; // This will give time difference in milliseconds

      // Convert the difference to days
      const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);

      // console.log(`Time difference in milliseconds: ${timeDifference}`);
      // console.log(`Time difference in days: ${differenceInDays}`);

      const inTime = this.checkinTime.value;
      const outTime = this.checkoutTime.value;

      if (differenceInDays>0 || (inTime && outTime && this.service.common.isMoreThan8Hours(inTime, outTime))) {
        // console.log("TimeDiff_Time difference is greater than 8 hours");
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '600px',
          disableClose: true,
          data: { 
            msg: 'The Shift Start and End times will result in a shift greater than 8 hours and may required Overtime payments. Do you want to save these shift hours?',
            cancelBtnLabel: 'Cancel', 
            confirmBtnLabel: 'Save'
            // hideNoBtn: true
           }
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result && result.toLowerCase() === 'ok') {
            //  Do Nothing..
          }
          else
          {
            this.checkinTime.reset();
            this.checkoutTime.reset();
            // this.is_approve_and_save = false;
          }
  
        });
        } else {
          // console.log("TimeDiff_Time difference is less than or equal to 8 hours");
        }
      }
  }

  // onSubmit(){
  //   this.submitted = false;
  //   if (this.checkinTime.invalid) {
  //     // Mark the FormControl as touched to display error messages
  //     this.checkinTime.markAsTouched();
  //   }
  //   if (this.checkoutTime.invalid) {
  //     // Mark the FormControl as touched to display error messages
  //     this.checkoutTime.markAsTouched();
  //   }
  //   try {
  //     if (this.inputForm.valid && this.checkinTime.valid && this.checkoutTime.valid) {
  //       this.inputForm.controls['checkinDateTime'].setValue(this.inputForm.value.checkinDateTime + ' ' + this.checkinTime.value+':00');
  //       this.inputForm.controls['checkoutDateTime'].setValue(this.inputForm.value.checkoutDateTime + ' ' + this.checkoutTime.value+':00');
  //       // console.log('FormForSubmit: ', this.inputForm.value);
  //       // return;
  //       let confirmMSG = "Are you sure want to change this time card and approved?. Once submitted, you cannot edit." ;

  //       const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
  //         width: '400px',
  //         data: { msg: confirmMSG }
  //       });

  //       dialogRef.afterClosed().subscribe(result => {
      
  //        if(result && result.toLowerCase() === 'ok'){
  //         this.service.common.progressLoader = true;
  //         this.service.sendAdminActionTimeSheet(this.inputForm.value, (response) => {
                
  //           if (response.responsecode == 200) {
  //             this.inputForm.reset();
  //             this.checkinTime.setValue('');
  //             this.checkoutTime.setValue('');
  //             this.dialogRef.close(true)
  //             this.service.common.message = response.message;
  //             this.service.common.progressLoader = false;
  //             this.service.common.successMessage = true;
  //             // this.getListDataByPage();
  //           } else {
  //             this.service.common.message = response.message;
  //             this.service.common.progressLoader = false;
  //             this.service.common.successMessage = true;
  //           }
  //       });
  //       this.dialogRef.close(true);
  //        }
  //       });        
  //     } 
  //   } catch (error) {
  //     //console.log(error)
  //   }
  // }


  set_for_save_and_approve()
  {
    this.submitted = false;
    if (this.checkinTime.invalid) {
      // Mark the FormControl as touched to display error messages
      this.checkinTime.markAsTouched();
    }
    if (this.checkoutTime.invalid) {
      // Mark the FormControl as touched to display error messages
      this.checkoutTime.markAsTouched();
    }
    try {
      if (this.inputForm.valid && this.checkinTime.valid && this.checkoutTime.valid) 
        {

         let confirmMSG = "Are you sure want to change this time card and approved?. Once submitted, you cannot edit." ;

        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '400px',
          data: { msg: confirmMSG }
        });
    
        dialogRef.afterClosed().subscribe(result => {
      
         if(result && result.toLowerCase() === 'ok')
          {
            // this.inputForm.controls['checkinDateTime'].setValue(this.inputForm.value.checkinDateTime + ' ' + this.checkinTime.value+':00');
            // this.inputForm.controls['checkoutDateTime'].setValue(this.inputForm.value.checkoutDateTime + ' ' + this.checkoutTime.value+':00');

            this.inputForm.controls['checkinDateTime'].setValue(
              `${moment(this.inputForm.value.checkinDateTime).format('YYYY-MM-DD')} ${this.checkinTime.value}:00`
            );

            this.inputForm.controls['checkoutDateTime'].setValue(
              `${moment(this.inputForm.value.checkoutDateTime).format('YYYY-MM-DD')} ${this.checkoutTime.value}:00`
            );

            this.inputForm.value.saveOnly = false ;   // adding new property into object ;

            console.log("this.inputForm.value",this.inputForm.value)

            this.send_actionsheet();
         
         }
        });
           
      } 
    }
     catch (error) {
      //console.log(error)
    }
  }

 

  save_only()
  {
    this.submitted = false;
    if (this.checkinTime.invalid) {
      // Mark the FormControl as touched to display error messages
      this.checkinTime.markAsTouched();
    }
    if (this.checkoutTime.invalid) {
      // Mark the FormControl as touched to display error messages
      this.checkoutTime.markAsTouched();
    }
    try {
      if (this.inputForm.valid && this.checkinTime.valid && this.checkoutTime.valid) 
        {

       // this.inputForm.controls['checkinDateTime'].setValue(this.inputForm.value.checkinDateTime + ' ' + this.checkinTime.value+':00');
            // this.inputForm.controls['checkoutDateTime'].setValue(this.inputForm.value.checkoutDateTime + ' ' + this.checkoutTime.value+':00');

            this.inputForm.controls['checkinDateTime'].setValue(
              `${moment(this.inputForm.value.checkinDateTime).format('YYYY-MM-DD')} ${this.checkinTime.value}:00`
            );

            this.inputForm.controls['checkoutDateTime'].setValue(
              `${moment(this.inputForm.value.checkoutDateTime).format('YYYY-MM-DD')} ${this.checkoutTime.value}:00`
            );

            this.inputForm.value.saveOnly = true ;   // adding new property into object ;
  
        console.log("this.inputForm.value",this.inputForm.value)
        this.send_actionsheet();

       } 
    }
     catch (error) {
      //console.log(error)
    }
  }

  send_actionsheet()
  {
    this.service.common.progressLoader = true;
    this.service.sendAdminActionTimeSheet(this.inputForm.value, (response) => {
          
      if (response.responsecode == 200) {
        this.inputForm.reset();
        this.checkinTime.setValue('');
        this.checkoutTime.setValue('');
        this.dialogRef.close(true)
        this.service.common.message = response.message;
        this.service.common.progressLoader = false;
        this.service.common.successMessage = true;
        // this.getListDataByPage();
      } else {
        this.service.common.message = response.message;
        this.service.common.progressLoader = false;
        this.service.common.successMessage = true;
      }
  });
  }


  setIsApproved(event: any){
    if(event && event.checked){
    let confirmMSG = "Are you sure want to approved this time card?. Once approved, you cannot edit." ;

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {
      
         if(result && result.toLowerCase() === 'ok'){

         } else {
          this.inputForm.controls.sendOnlyNotification.setValue(event.checked ? false : true);
         }
        });        
  }
}

}
