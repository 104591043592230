import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl
} from "@angular/forms";
// import { TechnicianService } from '../technician.service';
import { Router, ActivatedRoute } from "@angular/router";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import * as _moment from "moment";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { TechnicianService } from "../../providers-dashboard/technician/technician.service";
import { SessionStorageService } from "angular-web-storage";
import { TranslateService } from "@ngx-translate/core";
import { AddProviderComponent } from "./add-provider/add-provider.component";
import { TechProfileService } from "./tech-profile.service";
import { ConfirmLogoutComponent } from "src/app/common/confirm-logout/confirm-logout.component";
import { DeleteReasonComponent } from "./delete-reason/delete-reason.component";
const moment = _moment;
declare var google;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "ddd DD MMMM YYYY",
    monthYearLabel: "ddd DD MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "ddd DD MMMM YYYY"
  }
};

@Component({
  selector: "app-tech-profile",
  templateUrl: "./tech-profile.component.html",
  styleUrls: ["./tech-profile.component.scss"],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class TechProfileComponent implements OnInit, OnDestroy {
  workerCode;
  personalform: FormGroup;
  professionalForm: FormGroup;
  submitted = true;
  skills = [];
  certifications = [];
  dropdownSettings = {};
  dropdownSettings1 = {};
  private _unsubscribeAll: Subject<any>;
  skillsList = [];
  certificationsList = [];
  chipSkills = [];
  chipCertificaitons = [];
  personalSubmitted;
  userName;
  userDesignation;

  filteredOptionsSkill: Observable<any[]>;
  filteredCertificateOptions: Observable<any[]>;
  skillSelected = [];
  certificationsSelected = [];

  imageupload = false;
  fileUrl: any;
  filetitle: any;

  personal = true;
  professional = false;

  userSkill: any;
  userProfession: any;

  @ViewChild("mapDis", { static: true }) mapDis;
  directionsService;
  directionsDisplay;
  map;
  isZoom: boolean = false;
  myLatlng = new google.maps.LatLng(32.483, 16.084);

  @ViewChild("password", { static: true }) password;
  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;
  options = { types: [], componentRestrictions: { country: ["us", "in"] } };
  lat: any;
  lng: any;
  filteredOptions: Observable<any>;
  homeCountryCode = "44";
  workerCountryCode = "44";
  languages = [];
  empTypeCode: any;
  empTypeName: any;
  selectedLang = "English";
  currenYear = new Date();
  extendedYear;
  breakYear;
  minDate: string;
  maxDate;
  employeeType: any;
  selectedEmpType;
  updatedAddress: any;
  providersList = [];
  displayedColumns: string[] = ["providername", "actions"];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cancel = true;

  date = new Date();
  formatedDate;
  certificatedate = [];
  isAddProvider = false;

  constructor(
    public technicianService: TechnicianService,
    public router: Router,
    public route: ActivatedRoute,
    public datepipe: DatePipe,
    public storage: SessionStorageService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public service: TechProfileService
  ) {
    this.technicianService.common.sub = this.technicianService.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);

        if (data == "showpopup") {
          this.technicianService.common.showConfirmPopup();
        }
      }
    );
    this.translate.setDefaultLang(
      this.technicianService.common.local.get("language")
    );
  }

  ngOnDestroy() {
    this.technicianService.common.sub.unsubscribe();
  }
  /* Name:srinivas
       use: return valid true of false. */
  apartmentValid(control: AbstractControl): Promise<any> | Observable<any> {
    const confirmPassword = new Promise<any>((resolve, reject) => {
      ////console.log(control.value.trim());
      if (control.value.trim()) {
        ////console.log(control.value.trim());
        resolve(null);
      } else {
        ////console.log(control.value.trim());
        resolve({
          invalid: true
        });
      }
    });
    return confirmPassword;
  }
  /* Name:srinivas
       use: returns current location . */
  getCurrentLocation(callback) {
    navigator.geolocation.getCurrentPosition(
      function(position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        ////console.log(position);
        callback(position);
      },
      function() {
        // handleLocationError(true, infoWindow, map.getCenter());
      }
    );
  }
  /* Name:srinivas
       use: create google marker using by location. */
  initialize(latitude = null, longitude = null) {
    let newLatLang;

    if (latitude && longitude) {
      newLatLang = new google.maps.LatLng(latitude, longitude);
    } else {
      this.getCurrentLocation(response => {
        ////console.log(response);
        newLatLang = new google.maps.LatLng(
          response.coords.latitude,
          response.coords.longitude
        );
      });
    }
    setTimeout(() => {
      ////console.log(newLatLang)
      this.isZoom = false;
      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 18,
        center: newLatLang,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      });

      let marker = new google.maps.Marker({
        map: this.map,
        position: newLatLang,
        draggable: true,
        icon: { url: "./../../assets/location_1.png" }
      });
      let geocoder = new google.maps.Geocoder();
      let infowindow = new google.maps.InfoWindow();

      if (this.personalform.value.address) {
        let addressName = "";
        if (
          !this.technicianService.personalWorker.apartmentNo &&
          this.technicianService.personalWorker.address
        ) {
          addressName +=
            this.technicianService.personalWorker.address +
            "," +
            this.technicianService.personalWorker.city +
            "," +
            this.technicianService.personalWorker.state +
            "," +
            this.technicianService.personalWorker.country +
            "," +
            this.technicianService.personalWorker.postCode;
        }
        if (
          !this.technicianService.personalWorker.apartmentNo &&
          !this.technicianService.personalWorker.address
        ) {
          addressName +=
            this.technicianService.personalWorker.city +
            "," +
            this.technicianService.personalWorker.state +
            "," +
            this.technicianService.personalWorker.country +
            "," +
            this.technicianService.personalWorker.postCode;
        }
        if (
          this.technicianService.personalWorker.apartmentNo &&
          !this.technicianService.personalWorker.address
        ) {
          addressName +=
            this.technicianService.personalWorker.apartmentNo +
            "," +
            this.technicianService.personalWorker.city +
            "," +
            this.technicianService.personalWorker.state +
            "," +
            this.technicianService.personalWorker.country +
            "," +
            this.technicianService.personalWorker.postCode;
        }
        if (
          this.technicianService.personalWorker.apartmentNo &&
          this.technicianService.personalWorker.address
        ) {
          addressName +=
            this.technicianService.personalWorker.apartmentNo +
            "," +
            this.technicianService.personalWorker.address +
            "," +
            this.technicianService.personalWorker.city +
            "," +
            this.technicianService.personalWorker.state +
            "," +
            this.technicianService.personalWorker.country +
            "," +
            this.technicianService.personalWorker.postCode;
        }
        infowindow.setContent(addressName);
        infowindow.open(this.map, marker);
      } else {
        geocoder.geocode({ latLng: newLatLang }, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(this.map, marker);
            }
          }
        });
      }
      this.getMarkerData(marker, geocoder, infowindow, responseData => {
        this.handleAddressChange(responseData);
      });
    }, 1000);
  }
  /* Name:srinivas
       use: get google marker details. */
  getMarkerData(marker, geocoder, infowindow, callback) {
    google.maps.event.addListener(marker, "dragend", function() {
      ////console.log(marker);
      ////console.log(marker.getPosition());
      geocoder.geocode({ latLng: marker.getPosition() }, function(
        results,
        status
      ) {
        ////console.log(results, status);
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            infowindow.setContent(results[0].formatted_address);
            infowindow.open(this.map, marker);
            callback(results[0]);
          }
        }
      });
    });
  }
  /* Name:srinivas
       use: page load. */
  ngOnInit() {
    this.extendedYear = this.currenYear.getFullYear();
    this.extendedYear = this.extendedYear + 20;
    const data = this.extendedYear - 38;
    this.breakYear = data + "-12-31";
    this.maxDate = this.extendedYear + "-12-31";
    this.minDate = new Date().toISOString().split("T")[0];

    this.personalform = new FormGroup({
      workerLogo: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.technicianService.common.name)
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.pattern(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
        )
      ]),
      cpassword: new FormControl(
        null,
        [
          Validators.pattern(
            "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
          )
        ],
        this.confirmPassword.bind(this)
      ),
      language: new FormControl(null, Validators.required),
      mobileNo: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.technicianService.common.phonenumber)
      ]),
      emergencyNo: new FormControl(null, [
        Validators.pattern(this.technicianService.common.phonenumber)
      ]),
      address: new FormControl(null, Validators.required),
      city: new FormControl(
        null,
        [Validators.required],
        this.apartmentValid.bind(this)
      ),
      state: new FormControl(
        null,
        Validators.required,
        this.apartmentValid.bind(this)
      ),
      postCode: new FormControl(
        null,
        [Validators.required, Validators.pattern(this.technicianService.common.postCode)],
        this.apartmentValid.bind(this)
      ),
      country: new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern(this.technicianService.common.onlyNameWithSpace)
        ],
        this.apartmentValid.bind(this)
      ),
      workerCountryCode: new FormControl(
        this.workerCountryCode,
        Validators.required
      ),
      homeCountryCode: new FormControl(
        this.homeCountryCode,
        Validators.required
      ),
      dob: new FormControl(null),
      designation: new FormControl(null),
      qualification: new FormControl(null),
      expYear: new FormControl(null, Validators.required),
      expMonth: new FormControl(null, Validators.required),
      employmentType: new FormControl(null, Validators.required),
      fullAddress: new FormControl(
        null,
        [Validators.required],
        this.apartmentValid.bind(this)
      ),
      apartmentNo: new FormControl(
        null,
        [Validators.required],
        this.apartmentValid.bind(this)
      ),
      tevvsain: new FormControl(null),
      legalName: new FormControl(null),
      parmanentAddress: new FormControl(null)
    });
    this.technicianService.personalWorker.homeCountryCode = this.homeCountryCode;
    this.technicianService.personalWorker.workerCountryCode = this.workerCountryCode;
    this.workerCode = this.storage.get("userCode");
    //  //////console.log(this.storage.get('userCode'))
    this.getAllSkillsByOrgCode();
    // this.getAllWorkerProfessionDetail();
    this.getAllCertificationByOrgCode();
    // this.route.queryParams.subscribe((data) => {
    if (this.workerCode) {
      //////console.log(this.workerCode);
      this.technicianService.getWorkerProfilePersonalTabforMobileByworkerCode(
        this.workerCode,
        this.technicianService.common.local.get("language"),
        response => {
          //console.log(response);
          if (response.responsecode == 200) {
            if (response.data.latitude && response.data.longitude) {
              this.technicianService.personalWorker.location =
                response.data.location;
              this.personalform.controls["address"].setValue(
                response.data.location
              );
              this.initialize(response.data.latitude, response.data.longitude);
            } else {
              this.initialize();
            }

            this.technicianService.personalWorker = response.data;
            this.userName = response.data.name;
            this.userDesignation = response.data.designation;
            if (this.technicianService.personalWorker.homeCountryCode) {
              this.homeCountryCode = this.technicianService.personalWorker.homeCountryCode;
              this.personalform.controls["homeCountryCode"].setValue(
                this.homeCountryCode
              );
            }
            if (this.technicianService.personalWorker.workerCountryCode) {
              this.workerCountryCode = this.technicianService.personalWorker.workerCountryCode;
              this.personalform.controls["workerCountryCode"].setValue(
                this.workerCountryCode
              );
            } else {
              this.technicianService.personalWorker.workerCountryCode = this.workerCountryCode;
            }

            if (response.data.workerLogo) {
              this.fileUrl =
                this.technicianService.service.getSuperAdmin() +
                "/downloadFile/" +
                response.data.workerLogo;
              this.technicianService.professionalWorker.workerLogo =
                response.data.workerLogo;
              this.imageupload = true;
            }
            //////console.log(response.data.dob);
            if (response.data.dob) {
              let dob = response.data.dob.split(" ")[0];
              const time = "00";
              let hours = "00";
              const month = dob.split("-");
              const newDate = new Date(+month[0], +month[1] - 1, +month[2]);
              //////console.log(newDate);
              this.personalform.controls["dob"].setValue(moment(newDate));
              this.technicianService.personalWorker.dob =
                +dob.split("-")[1] +
                "/" +
                +dob.split("-")[0] +
                "/" +
                +dob.split("-")[2];
            }
          }
        }
      );

      try {
        this.technicianService.getWorkerProfileProfessionalTabforMobileByworkerCode(
          this.workerCode,
          response => {
            //console.log(response);
            if (response.responsecode == 200) {
              this.technicianService.professionalWorker = response.data;
              if (response.data.skilmaster.length > 0) {
                //this.professionalForm.controls['skills'].setValue(JSON.stringify(response.data.skilmaster));
                this.professionalForm.controls["skillsSearch"].setValue(
                  JSON.stringify(response.data.skilmaster)
                );
                this.professionalForm.controls["skills"].setValue(null);
              }
              if (
                response.data.certificationmasterResList &&
                response.data.certificationmasterResList.length > 0
              ) {
                this.professionalForm.controls["certifications"].setValue(
                  JSON.stringify(response.data.certificationmaster)
                );
                this.certificationsSelected =
                  response.data.certificationmasterResList;
                this.technicianService.professionalWorker.certificationmaster =
                  response.data.certificationmasterResList;
                this.technicianService.professionalWorker.certificationmaster.forEach(
                  ele => {
                    ele.viewDate = moment(ele.certificationExpirationDate);

                    let date = new FormControl(ele.viewDate);
                    this.certificatedate.push(date.value);
                  }
                );
                //console.log(this.formatedDate);
              } else {
                this.technicianService.professionalWorker.certificationmaster = [];
              }
            }
          }
        );

        this.technicianService.checkAddProviderByClinicianCode(
          this.workerCode,
          response => {
            //console.log(response);
            if (response.responsecode == 200) {
              this.isAddProvider = response.data.isAddProvider;
            }
          }
        );
      } catch (error) {}
    }
    // })

    this.getAllEmploymentType();

    this.professionalForm = new FormGroup({
      skills: new FormControl(null),
      skillsSearch: new FormControl(null, Validators.required),
      certifications: new FormControl(null),
      certificationsSearch: new FormControl(null),
      employmentType: new FormControl(null, Validators.required)
    });

    this.dropdownSettings = {
      singleSelection: false,
      text: "Skills List",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      // badgeShowLimit: 5,
      autoPosition: true,
    };

    this.dropdownSettings1 = {
      singleSelection: false,
      text: "Certification List",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      // badgeShowLimit: 5,
      autoPosition: true,
    };

    setTimeout(() => {
      this.filteredOptionsSkill = this.professionalForm.controls[
        "skills"
      ].valueChanges.pipe(
        startWith(""),
        map(name => this._filter(name, "skill"))
      );

      this.filteredCertificateOptions = this.professionalForm.controls[
        "certifications"
      ].valueChanges.pipe(
        startWith(""),
        map(name => this._filter(name, "certificate"))
      );
    }, 1000);

    this.languages = this.technicianService.common.language;
    // //////console.log(this.languages);

    this.getProviderDetail(this.workerCode);
  }

  handlePermanentAddressChange(address) {
    //console.log(address);
    if (address.geometry) {
      let places: any;
      const componentForm = {
        route: "long_name",
        locality: "long_name",
        political: "long_name",
        administrative_area_level_1: "long_name",
        postal_code: "short_name",
        country: "long_name",
        street_number: "long_name",
        sublocality: "long_name",
        sublocality_level_2: "long_name"
      };
      let addressName = "";
      for (let i = 0; i < address.address_components.length; i++) {
        const addressType = address.address_components[i].types[0];
        if (componentForm[addressType]) {
          ////console.log(addressType);
          if (
            addressType === "sublocality" ||
            addressType === "sublocality_level_2"
          ) {
            const val =
              address.address_components[i][componentForm[addressType]];
            //console.log(val);

            addressName = val;
          }
          if (addressType === "route") {
            const val =
              address.address_components[i][componentForm[addressType]];
            addressName = val;
          }
          if (addressType === "street_number") {
            const val =
              address.address_components[i][componentForm[addressType]];
            addressName += "," + val;
          }

          if (addressType === "political") {
            const val =
              address.address_components[i][componentForm[addressType]];
            // this.technicianService.personalWorker.address = val;
          }
          if (addressType === "postal_code") {
            const val =
              address.address_components[i][componentForm[addressType]];
            addressName += "," + val;
          }
          if (addressType === "locality" || addressType === "postal_town") {
            const val =
              address.address_components[i][componentForm[addressType]];
            addressName += "," + val;
          }
          if (addressType === "administrative_area_level_1") {
            const val =
              address.address_components[i][componentForm[addressType]];
            addressName += "," + val;
          }
          if (addressType === "country") {
            const val =
              address.address_components[i][componentForm[addressType]];
            addressName += "," + val;
          }
        }
      }
      //console.log(addressName);
      this.technicianService.personalWorker.parmanentAddress = addressName;
    }
  }

  /* Name:srinivas
       use: return provider details. */
  getProviderDetail(code) {
    this.service.getWorkerApprovalStatusByWorkerCode(code, data => {
      if (data.responsecode == 200) {
        this.providersList = data.data;
        ////console.log(this.providersList);
        this.dataSource = new MatTableDataSource(this.providersList);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.sort;
      }
    });
  }
  /* Name:srinivas
       use: returns google lat longs. */
  codeLatLng(response, callback) {
    let geocoder = new google.maps.Geocoder();

    let newLatLang = new google.maps.LatLng(
      response.data.latitude,
      response.data.longitude
    );

    geocoder.geocode({ latLng: newLatLang }, function(results, status) {
      ////console.log(results, status);
      callback(results);
    });
  }
  /* Name:srinivas
       use:saves the details. */
  onSubmit() {
    ////console.log(this.personalform);

    if (this.personalform.valid) {
      try {
        this.technicianService.common.progressLoader = true;
        this.submitted = true;
        // //////console.log(this.personalform);
        // //////console.log(this.technicianService.personalWorker);
        this.technicianService.personalWorker.dob = this.datepipe.transform(
          this.personalform.value.dob,
          "yyyy-MM-dd"
        );
        //////console.log(this.technicianService.personalWorker.dob)
        this.technicianService.updateworkerProfilePersonelTabByworkerCodeForStaff(
          this.workerCode,
          this.technicianService.personalWorker,
          response => {
            // //////console.log(response);
            if (response.responsecode == 200) {
              this.technicianService.common.message = response.message;
              this.technicianService.common.progressLoader = false;
              this.technicianService.common.successMessage = true;
              // this.router.navigate(['/dashboard/technician/technician-list']);
            } else {
              this.technicianService.common.message = response.message;
              this.technicianService.common.progressLoader = false;
              this.technicianService.common.errorMessage = true;
            }
          }
        );
      } catch (error) {}
    } else {
      this.submitted = false;
    }
  }
  /* Name:srinivas
       use: update the detais. */

  onSubmitPro() {
    // //////console.log(this.professionalForm);
    if (this.professionalForm.valid) {
      try {
        this.technicianService.common.progressLoader = true;
        this.submitted = true;

        // //////console.log(this.technicianService.professionalWorker);

        this.technicianService.updateWorkerProfileProfessionalTabforMobileByworkerCode(
          this.workerCode,
          this.technicianService.professionalWorker,
          res => {
            this.technicianService.common.progressLoader = false;
            if (res.responsecode == 200) {
              this.technicianService.common.message = res.message;
              this.technicianService.common.successMessage = true;
            } else {
              this.technicianService.common.message = res.message;
              this.technicianService.common.errorMessage = true;
            }
          }
        );
      } catch (error) {}
    } else {
      this.submitted = false;
    }
  }

  /* Name:srinivas
       use: returns skill by org code. */
  getAllSkillsByOrgCode() {
    this.service.getAllSkillMaster(data => {
      //console.log(data.data);
      if ((data.responsecode = 200)) {
        this.skills = data.data;
        // //////console.log(data);
        // //////console.log(this.skills);
      }
    });
  }
  /* Name:srinivas
       use: return certificate by org code. */
  getAllCertificationByOrgCode() {
    this.service.getAllCertificationsMaster(data => {
      //console.log(this.certifications);
      if ((data.responsecode = 200)) {
        this.certifications = data.data;
      }
    });
  }
  /* Name:srinivas
       use: return all employe type details. */
  getAllEmploymentType() {
    try {
      this.technicianService.getAllEmploymentType(response => {
        if (response.responsecode == 200) {
          this.employeeType = response.data;
        }
      });
    } catch (error) {}
  }
  /* Name:srinivas
       use: return skill name. */
  displayFn(skill): string | undefined {
    // //////console.log(skill);
    return skill ? skill.skillName : undefined;
  }
  /* Name:srinivas
       use: return certificate name. */
  certificatedisplayFn(certification): string | undefined {
    // //////console.log(certification);
    return certification ? certification.certificationName : undefined;
  }
  /* Name:srinivas
       use: filter data by name. */
  private _filter(name, field): String[] {
    //console.log(name, field);
    //const filterValue = name;
    // if (field === 'skill') {
    //   return this.skills.filter(option => option.skillName.toLowerCase().indexOf(filterValue) === 0);
    // } else {
    //   return this.certifications.filter(option => option.certificationName.toLowerCase().indexOf(filterValue) === 0);
    // }
    if (field == "skill") {
      if (name && name != "") {
        //console.log("in");
        const filterValue = name;
        return this.skills.filter(
          option => option.skillName.toLowerCase().indexOf(filterValue) === 0
        );
      } else {
        //console.log("name");
        //console.log(this.skills);
        return this.skills;
      }
    } else {
      if (name && name != "") {
        //console.log("IN");
        const filterValue = name;
        return this.certifications.filter(
          option =>
            option.certificationName.toLowerCase().indexOf(filterValue) === 0
        );
      } else {
        //console.log("OUT");
        //console.log(this.certifications);
        return this.certifications;
      }
    }
  }
  /* Name:srinivas
       use: set control data by selected wise. */

  selectedOption(event, field) {
    if (field === "skill") {
      // //////console.log(this.technicianService.professionalWorker.skilmaster, event.option.value);
      const index = this.technicianService.professionalWorker.skilmaster.findIndex(
        fruit => fruit.skillName === event.option.value.skillName
      );
      if (index == -1) {
        this.professionalForm.controls["skillsSearch"].setValue(
          event.option.value
        );
        this.technicianService.professionalWorker.skilmaster.push(
          event.option.value
        );
        this.professionalForm.controls["skills"].setValue(null);
      } else {
        this.technicianService.common.message =
          event.option.value.skillName + " is already selected";
        this.technicianService.common.errorMessage = true;
      }
    } else {
      const index = this.certificationsSelected.findIndex(
        fruit =>
          fruit.certificationName === event.option.value.certificationName
      );
      if (index == -1) {
        this.professionalForm.controls["certificationsSearch"].setValue(
          event.option.value
        );
        this.technicianService.professionalWorker.certificationmaster.push(
          event.option.value
        );
        this.professionalForm.controls["certifications"].setValue(null);
      } else {
        this.technicianService.common.message =
          event.option.value.certificationName + " is already selected";
        this.technicianService.common.errorMessage = true;
      }
    }
  }
  /* Name:srinivas
       use: set control data  . */
  removeOrg(index, field) {
    if (field === "skill") {
      this.technicianService.professionalWorker.skilmaster.splice(index, 1);
      if (this.technicianService.professionalWorker.skilmaster.length == 0) {
        this.professionalForm.controls["skillsSearch"].setValue(null);
      }
    } else {
      this.technicianService.professionalWorker.certificationmaster.splice(
        index,
        1
      );
      if (
        this.technicianService.professionalWorker.certificationmaster.length ==
        0
      ) {
        this.professionalForm.controls["certificationsSearch"].setValue(null);
      }
    }
  }
  /* Name:srinivas
       use: set country code. */
  getNumber(event = null, countryCode) {
    this.personalform.controls[countryCode].setValue(event.dialCode);
    this.technicianService.personalWorker[countryCode] = event.dialCode;
  }
  /* Name:srinivas
       use: get address. */
  handleAddressChange(address) {
    //////console.log(address);
    if (address.geometry) {
      this.updateServiceAddress(address);
    }
    // this.addressValidation(this.personalform.controls['address']);
  }
  /* Name:srinivas
       use: checks address validation. */
  addressValidation(control: AbstractControl): Promise<any> | Observable<any> {
    const addressValidation = new Promise<any>((resolve, reject) => {
      let addr = control.value;
      // Get geocoder instance
      let geocoder = new google.maps.Geocoder();
      // Geocode the address
      // //////console.log(control);
      geocoder.geocode({ address: addr }, function(results, status) {
        // //////console.log(results);
        // //////console.log(status);
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          if (addr === results[0].formatted_address) {
            resolve(null);
          } else {
            resolve({
              invalid: true
            });
          }
          // show an error if it's not
        } else {
          // //////console.log('invalid');
          resolve({
            invalid: true
          });
          // //////console.log(control);
        }
      });
    });
    return addressValidation;
  }

  getAppartmentNumber(event) {
    //console.log(event);
    if (event.target.value) {
      this.initialize(
        this.technicianService.personalWorker.latitude,
        this.technicianService.personalWorker.longitude
      );
    }
  }

  /* Name:srinivas
       use: set all keys data. */
  updateServiceAddress(address) {
    //console.log(address);

    if (address) {
      (this.technicianService.personalWorker.address = ""),
        (this.technicianService.personalWorker.city = ""),
        (this.technicianService.personalWorker.postCode = ""),
        (this.technicianService.personalWorker.country = ""),
        (this.technicianService.personalWorker.latitude = ""),
        (this.technicianService.personalWorker.longitude = "");
      this.technicianService.personalWorker.apartmentNo = "";
      // this.technicianService.personalWorker.zipCode = ""

      let places: any;
      const componentForm = {
        route: "long_name",
        locality: "long_name",
        political: "long_name",
        administrative_area_level_1: "long_name",
        postal_code: "short_name",
        country: "long_name",
        sublocality: "long_name",
        sublocality_level_2: "long_name"
      };
      let addressName = "";

      for (let i = 0; i < address.address_components.length; i++) {
        const addressType = address.address_components[i].types[0];
        if (componentForm[addressType]) {
          if (
            addressType === "sublocality" ||
            addressType === "sublocality_level_2"
          ) {
            const val =
              address.address_components[i][componentForm[addressType]];
            //console.log(val);
            this.personalform.controls["fullAddress"].setValue(val);
            this.technicianService.personalWorker.address = val;
          }
          if (addressType === "route") {
            const val =
              address.address_components[i][componentForm[addressType]];
            this.technicianService.personalWorker.address =
              address.formatted_address;
            this.personalform.controls["fullAddress"].setValue(val);
          }
          if (addressType === "political") {
            const val =
              address.address_components[i][componentForm[addressType]];
            this.technicianService.personalWorker.address =
              address.formatted_address;
          }
          if (addressType === "postal_code") {
            const val =
              address.address_components[i][componentForm[addressType]];
            this.technicianService.personalWorker.postCode = val;
          }
          if (addressType === "locality" || addressType === "postal_town") {
            const val =
              address.address_components[i][componentForm[addressType]];
            // //////console.log(val);
            this.technicianService.personalWorker.city = val;
          }
          // if (addressType === 'administrative_area_level_1') {
          //   const val = address.address_components[i][componentForm[addressType]];
          //   this.technicianService.personalWorker.patientState = val;
          // }
          if (addressType === "country") {
            const val =
              address.address_components[i][componentForm[addressType]];
            this.technicianService.personalWorker.country = val;
          }
        }
      }
      // //////console.log(address.geometry.location.lat())
      this.technicianService.personalWorker.latitude = address.geometry.location.lat();
      this.technicianService.personalWorker.longitude = address.geometry.location.lng();
      // //////console.log(this.technicianService.personalWorker);
      // this.showMapmarker(this.service.workerHome.latitude ,this.service.workerHome.longitude);
      if (address.name) {
        this.personalform.controls["address"].setValue(address.name);
        this.technicianService.personalWorker.location = address.name;
      } else {
        if (
          this.technicianService.personalWorker.apartmentNo &&
          this.technicianService.personalWorker.address
        ) {
          addressName +=
            this.technicianService.personalWorker.apartmentNo +
            "," +
            this.technicianService.personalWorker.address;
        } else {
          addressName += this.technicianService.personalWorker.address;
        }
        this.personalform.controls["address"].setValue(addressName);
        this.technicianService.personalWorker.location = addressName;
      }
      this.initialize(
        this.technicianService.personalWorker.latitude,
        this.technicianService.personalWorker.longitude
      );
      // this.personalform.controls['address'].setValue(this.technicianService.personalWorker.address);
    }
  }
  /* Name:srinivas
       use: returns password validation. */
  confirmPassword(control: FormControl): Promise<any> | Observable<any> {
    const confirmPassword = new Promise<any>((resolve, reject) => {
      // //////console.log(control.value, this.password.nativeElement.value)
      if (
        control.value !== this.password.nativeElement.value &&
        this.password.nativeElement.value
      ) {
        resolve({
          invalid: true
        });
      } else {
        resolve(null);
      }
    });
    return confirmPassword;
  }
  /* Name:srinivas
       use:tab change details. */
  tabChange(tab) {
    ////console.log(tab);
    if (tab.index == "0") {
      if (this.personal == true) {
        this.personal = false;
      } else {
        this.personal = true;
        this.professional = false;
      }
      // this.personal = true;
      this.cancel = true;
    }
    if (tab.index == "1") {
      if (this.professional == true) {
        this.professional = false;
      } else {
        this.professional = true;
        this.personal = false;
      }
      // this.professional = true;
      this.cancel = true;
    }
    if (tab.index == "2") {
      this.professional = false;
      this.personal = false;
      this.cancel = false;
    }
  }
  /* Name:srinivas
       use: uploaded file validation and set to control. */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      let allowedExtension = ["jpeg", "jpg", "png"];
      let fileExtension = event.target.files[0].name
        .split(".")
        .pop()
        .toLowerCase();
      let isValidFile = false;
      //////console.log(fileExtension);
      for (let index in allowedExtension) {
        if (
          fileExtension === allowedExtension[index] &&
          event.target.files[0].size <= 2000000
        ) {
          isValidFile = true;
          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          this.technicianService.common.uploadImage(formData, res => {
            //////console.log(res);
            this.fileUrl = res.fileurl;
            this.filetitle = res.filename;
            this.technicianService.professionalWorker.workerLogo = res.filename;
            this.technicianService.personalWorker.workerLogo = res.filename;
            this.personalform.controls["workerLogo"].setValue(res.filename);
            this.imageupload = true;
          });
          break;
        }
      }

      if (!isValidFile) {
        //////console.log(isValidFile);
        this.technicianService.common.message =
          "Allowed Extensions are : ." +
          allowedExtension.join(", .") +
          " and file size should be less then 2MB";
        this.technicianService.common.errorMessage = true;
        //event.srcElement.value = '';
        event.target.value = "";
      }
    }
  }
  /* Name:srinivas
       use:  uploaded file validation and set to control. */
  onCertificateUpload(certificate, event) {
    //////console.log(event);
    // //////console.log(certificate);
    // //////console.log(this.technicianService.professionalWorker.certificationmaster);

    if (event.target.files.length > 0) {
      let allowedExtension = ["jpeg", "jpg", "png", "pdf"];
      let fileExtension = event.target.files[0].name
        .split(".")
        .pop()
        .toLowerCase();
      let isValidFile = false;
      //////console.log(fileExtension);
      for (let index in allowedExtension) {
        if (
          fileExtension === allowedExtension[index] &&
          event.target.files[0].size <= 2000000
        ) {
          isValidFile = true;

          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          //console.log(formData);
          this.technicianService.common.uploadImage(formData, res => {
            //console.log(res);
            this.technicianService.professionalWorker.certificationmaster.forEach(
              element => {
                if (
                  element.certificationName == certificate.certificationName
                ) {
                  element.certificationUrl = res.filename;
                }
              }
            );
          });
          break;
        }
      }

      if (!isValidFile) {
        //////console.log(isValidFile);
        this.technicianService.common.message =
          "Allowed Extensions are : ." +
          allowedExtension.join(", .") +
          " and file size should be less then 2MB";
        this.technicianService.common.errorMessage = true;
        //event.srcElement.value = '';
        event.target.value = "";
      }
    }
  }
  /* Name:srinivas
       use: set approved details. */
  changeActive(event) {
    //////console.log(event);
    this.technicianService.personalWorker.organizationWiseApprovalList[0].isApproved =
      event.value;
    this.technicianService.professionalWorker.organizationWiseApprovalList[0].isApproved =
      event.value;
  }
  /* Name:srinivas
       use: return country code */
  getCountryCode(event, formControlName) {
    //////console.log(event, formControlName);
    this.personalform.controls[formControlName].setValue(event);
    this.technicianService.personalWorker[formControlName] = event;
  }
  /* Name:srinivas
       use: open add provider popUP */
  addProvider() {
    const dialogRef = this.dialog.open(AddProviderComponent, {
      width: "450px",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      this.getProviderDetail(this.workerCode);
    });
  }
  /* Name:srinivas
       use: deactivate provider. */
  onDeactivate(status,code) {
    let msg;
    if(status == true) {
      msg = "Are you sure you want to activate provider?"
    } else {
      msg = "Are you sure you want to deactivate provider?"
    }
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: "450px",
      data: { msg: msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      if (result != undefined) {
        const dialogRefNew = this.dialog.open(DeleteReasonComponent, {
          width: "450px"
        });

        dialogRefNew.afterClosed().subscribe(resultNew => {
          //console.log(resultNew,'resultNew!!!');
          if (resultNew != undefined) {
            const data = {
              isActivated: status,
              organizationCode: code,
              inactiveReason: resultNew
            };
            this.service.mobileAdminSetOrgActivateStatusByTechnicianCode(
              this.workerCode,
              data,
              res => {
                if (res.responsecode == 200) {
                  this.service.common.message = res.message;
                  this.service.common.successMessage = true;
                  this.getProviderDetail(this.workerCode);
                } else {
                  this.service.common.message = res.message;
                  this.service.common.errorMessage = true;
                }
              }
            );
          }
        });
      }
    });
  }
  /* Name:srinivas
       use: delete provider. */
  onDelete(code) {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: "450px",
      data: { msg: "Are you sure you want to delete provider?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        const dialogRefNew = this.dialog.open(DeleteReasonComponent, {
          width: "450px"
        });

        dialogRefNew.afterClosed().subscribe(resultNew => {
          //console.log(resultNew,'resultNew!!!');
          if (resultNew != undefined) {
            const data = [];
            data.push({
              isAssign: false,
              organizationCode: code,
              deletionReason: resultNew
            });

            //console.log(data, "data form");
            this.service.mobileAdminAssignUnassignWorkerToOrganization(
              this.workerCode,
              data,
              res => {
                if (res.responsecode == 200) {
                  this.service.common.message = res.message;
                  this.service.common.successMessage = true;
                  this.getProviderDetail(this.workerCode);
                } else {
                  this.service.common.message = res.message;
                  this.service.common.errorMessage = true;
                }
              }
            );
          }
        });
      }
    });
  }
  /* Name:srinivas
       use: return expiry certificate details. */
  expirCertDate(date, event) {
    //console.log(event);
    this.technicianService.professionalWorker.certificationmaster.forEach(
      element => {
        element.certificationExpirationDate = moment(event.value).format(
          "YYYY-MM-DD"
        );
        //console.log(element.viewDate);
      }
    );
  }
}
