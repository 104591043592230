import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SkillsService } from '../skills.service';
import { CertificationService } from 'src/app/dashboards/providers-dashboard/certification/certification.service';
import { LicenceService } from 'src/app/dashboards/providers-dashboard/licence/licence.service'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addskills',
  templateUrl: './addskills.component.html',
  styleUrls: ['./addskills.component.scss']
})
export class AddskillsComponent implements OnInit {
  @Input() data: any;
  @Output() skillChangeEvent: EventEmitter<any> =new EventEmitter<any>();
  skillsForm: FormGroup;
  options = [];
  skilldata;
  fileUrl;
  filetitle;
  imageupload;
  imageMsg;
  submitted = true;
  certificationData: any = [];
  licenceData: any = [];
  selectedCertificationItems = [];
  selectedLicenceItems = [];
  name = 'Angular Multi Select Dropdown';
  dropdownSettings = {};
  dropdownSettingsLicence = {};
  popupTitle: string = 'Add Skill';
  isLoading: boolean = false;

  constructor(public skillService: SkillsService, public certificationService: CertificationService, public licenceService: LicenceService, private translate: TranslateService) {
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Certification",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      maxHeight: 170,
      // badgeShowLimit: 5,
      autoPosition: true,
    };
    this.dropdownSettingsLicence = {
      singleSelection: false,
      text: "Select Licence",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      maxHeight: 170,
      // badgeShowLimit: 5,
      autoPosition: true,
    };
    this.translate.setDefaultLang(this.skillService.storage.get('language'));
  }

  ngOnInit() {
    this.skillsForm = new FormGroup({
      "organizationCode":new FormControl(null),
      "skillName": new FormControl(null, [Validators.required]),
      "skillCode": new FormControl(null),
      'skillCertificationMappingRequestList' : new FormControl([]),
      'skillLicenceMappingRequestList' : new FormControl([]),
      // 'skillCertificationMappingRequestList' : new FormControl(null, [Validators.required]),
      // 'skillLicenceMappingRequestList' : new FormControl(null, [Validators.required]),
    });  
        
    this.getCertification();
    this.getLicence();

    /* Name:Shailesh
     use: for getting skill by id */

    try {
      // //console.log('ModData_:', this.data);
      if (this.data && this.data.id) {
            if(this.data?.certificationMapList){
              this.data.certificationMapList.forEach((element) => {
                element.itemName = element.certificationName;
                element.id = element.certificationCode
              });
            }
            if(this.data?.licenceMapList){
              this.data.licenceMapList.forEach((element) => {
                element.itemName = element.licenceName;
                element.id = element.licenceCode
              });
            }
            this.popupTitle = 'Edit Skill';
            this.skillsForm.controls['skillName'].setValue(this.data.skillName);
            this.skillsForm.controls['skillCode'].setValue(this.data.skillCode);
            this.selectedCertificationItems = this.data.certificationMapList || [];
            this.selectedLicenceItems = this.data.licenceMapList || [];
            this.skillService.common.progressLoader = false;
            // //console.log('ModData_1:', this.data);
          } else {
            this.popupTitle = 'Add Skill';
            this.skillsForm.reset();
            this.skillService.common.progressLoader = false;
          }
      
    } catch (error) {
      //console.log(error)
    }  }


  /* Name:Shailesh
      use: for searching skill name */
  private _filter(value, field): string[] {
    //////console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        this.skillsForm.controls['skillCode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        this.skillsForm.controls['skillCode'].setValue(value.value);
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  getCertification() { 
    this.certificationData = [];
    try {
      this.certificationService.getAllOrgCertificationMasterByOrganizationCodeByPage(this.skillService.storage.get('orgCode'), (data) => {
        if(data.responsecode === 200){
          data.data.forEach((element) => {
            element.itemName = element.certificationName;
            element.id = element.certificationCode
          });
      this.certificationData = data?.data ? data.data : [];
        } else {
        }
    });
    } catch (error) {
      //console.log(error)
    }
  }

  getLicence() { 
    this.licenceData = [];
    try {
      this.licenceService.getAllLicenceMasterByOrganizationCodeByPage(this.skillService.storage.get('orgCode'), (data) => {
        if(data.responsecode === 200){
          data.data.forEach((element) => {
            element.itemName = element.licenceName;
            element.id = element.licenceCode
          });
      this.licenceData = data?.data ? data.data : [];
        } else {
        }
    });
    } catch (error) {
      //console.log(error)
    }
  }

  isRequired(event:any, item: any){
    //console.log('isRequired', item, event.checked);
    item.isRequired = event.checked;
    //console.log('isRequired_1', item, this.selectedCertificationItems);
    return item;
  }

  onItemSelect(item: any) {
    //console.log('onItemSelect', item);
    //console.log(this.selectedCertificationItems);
  }
  OnItemDeSelect(item: any) {
    //console.log(item);
    //console.log(this.selectedCertificationItems);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  onDeSelectAll(items: any) {
    //console.log(items);
  }

  /* Name:Shailesh
     use: for adding/creating/saving skill in organization */
  onSubmit() { 
    // //console.log('Form: ', this.skillsForm);
    try {
      if (this.skillsForm.valid) {
        this.skillsForm.controls['organizationCode'].setValue(this.skillService.storage.get('orgCode'));
        let cloneOfArray = this.skillsForm.controls.skillCertificationMappingRequestList.value.length>0 ? JSON.parse(JSON.stringify(this.skillsForm.controls.skillCertificationMappingRequestList.value)) : [];

        this.skillsForm.controls.skillCertificationMappingRequestList.value.length>0 && this.skillsForm.controls.skillCertificationMappingRequestList.value.map((certification) => {
          if(certification){
           certification.isRequired = certification.isRequired ? certification.isRequired : false;
          }
          return certification;
        });

        this.skillsForm.controls.skillLicenceMappingRequestList.value.length>0 && this.skillsForm.controls.skillLicenceMappingRequestList.value.map((licence) => {
          if(licence){
            licence.isRequired = licence.isRequired ? licence.isRequired : false;
          }
          return licence;
        });

        // this.skillsForm.controls['skillCertificationMappingRequestList'].setValue(cloneOfArray);
        // //console.log('AddSkillReq: ', this.skillsForm.value);
        // return;
        this.isLoading = true;
        this.submitted = true;
        this.skillService.saveAndUpdateAllOrganizationSkill(this.skillsForm.value, (response) => {
          this.isLoading = false;
          if (response.responsecode == 200) {
            // this.dialogRef.close('success');
            this.skillChangeEvent.emit('success');
            this.skillsForm.reset();
            this.skillService.common.successMessage = true;
            this.skillService.common.message = response.message;
          } else {
            this.skillService.common.errorMessage = true;
            this.skillService.common.message = response.message;
          }
        })
      } else {
        this.submitted = false;
      }
    } catch (error) {
      //console.log(error)
    }
    //////console.log(this.skillsForm);
    //////console.log(this.skillService.skills);

  }

   /* Name:Shailesh
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.skillChangeEvent.emit('false');
    // this.dialogRef.close('true');
  }
}
