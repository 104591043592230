import { Component, OnInit, ViewChild, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable } from 'rxjs';
import { PatientService } from '../patient.service';
import { Router, ActivatedRoute } from '@angular/router';
 import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';
 import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { settings_keys } from 'src/app/shared/enum/settings.enum';
declare var google;

@Component({
  selector: 'add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})

export class AddLocationComponent implements OnInit, AfterViewInit , OnDestroy {
  @Input() isShortcutModalOpen: boolean = false;
  @Input() customerCode: string;
  siteAddress_Form: FormGroup;
  isSiteForm_submitted :boolean = false;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options = { types: [], componentRestrictions: { country: ["in", "us"] } };
  selectedCustomerCode = [];
  allLocationList = []
  customerList = [];
   @ViewChild('mapDis', { static: true }) mapDis;
   map;
  isZoom: boolean = false;
  cancelPatient = false;

  private circleFence_ClockIn: google.maps.Circle | null = null;
  private circleFence_ClockOut: google.maps.Circle | null = null;
  // private marker : google.maps.Marker | null = null;
  private dragEndListener = null;
  private markerDragEndLatLng:any;

  radius_unit_label : string = "Meters"
 

  constructor(
    public service: PatientService, public router: Router, public route: ActivatedRoute, public translate: TranslateService,
    private dialog: MatDialog, private fb: FormBuilder,
    private commonApiService : CommonApiService,
  ) {
    this.translate.setDefaultLang(this.service.local.get('language'));


    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
  }


  ngAfterViewInit(): void {
    this.initialize();
  }


  /* Name:Shailesh
        use: for getting current location*/
  getCurrentLocation(callback) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      ////console.log(position);
      callback(position);

    }, function () {
      // handleLocationError(true, infoWindow, map.getCenter());
    });
  }

  // /* Name:Shailesh
  //     use: for initializing patient address*/
  // initialize(LocationForm_CurrenIndex?:number  , patientLatitude = null, patientLongitude = null, mapInfoWindowText = null) {
  //   let newLatLang;
  //   ////console.log(patientLatitude, patientLongitude);
  //   if (patientLatitude && patientLongitude) {

  //     newLatLang = new google.maps.LatLng(patientLatitude, patientLongitude)
  //   } else {

  //     this.getCurrentLocation((response) => {
  //       ////console.log(response);
  //       newLatLang = new google.maps.LatLng(response.coords.latitude, response.coords.longitude);
  //     })

  //   }

  //   setTimeout(() => {
  //     console.log('LatLong_1', newLatLang);
  //     this.isZoom = false;
 
  //     if(!this.map)
  //     {
  //       this.map = new google.maps.Map(document.getElementById('map'), {
  //         zoom: 18,
  //         center: newLatLang,
  //         mapTypeId: google.maps.MapTypeId.ROADMAP,
  //         disableDefaultUI: true
  //       } );
  //     }

  //     if (this.marker) {
  //       this.marker.setMap(null); 
  //     }

  //     this.marker = new google.maps.Marker({
  //       map: this.map,
  //       position: newLatLang,
  //       draggable: true,
  //       icon: { url: './../../assets/location_1.png' },
  //     });

  //     let geocoder = new google.maps.Geocoder();
  //     let infowindow = new google.maps.InfoWindow();      

  //     if(mapInfoWindowText)
  //     {
  //       console.log('LatLong_3', this.marker);
  //       infowindow.setContent(mapInfoWindowText);
  //       infowindow.open(this.map, this.marker);
  //     }
     
  //     if(patientLatitude && patientLongitude)
  //     {
  //       // this.drawCircleFence(LocationForm_CurrenIndex);
  //     }


  //     this.removeDragEndListener();
  //     this.getMarkerData(this.marker, geocoder, infowindow, (responseData, markerDragEndObj) => {

  //        this.markerDragEndLatLng = markerDragEndObj;
  //           console.log('LatLong_2', responseData, LocationForm_CurrenIndex);
  //        this.handleMarkerChange(responseData, LocationForm_CurrenIndex);
  //       //  this.drawCircleFence(LocationForm_CurrenIndex);
        
  //     })
     

  //   }, 500);

  // }

    /* Name:Shailesh
      use: for initializing patient address*/
      initialize(LocationForm_CurrenIndex?:number  , patientLatitude = null, patientLongitude = null, mapInfoWindowText = null) {
        let newLatLang;
        ////console.log(patientLatitude, patientLongitude);
        this.service.common.resetNotesRequestForm();
        if (patientLatitude && patientLongitude) {
    
          newLatLang = new google.maps.LatLng(patientLatitude, patientLongitude)
        } else {
    
          this.getCurrentLocation((response) => {
            ////console.log(response);
            newLatLang = new google.maps.LatLng(response.coords.latitude, response.coords.longitude);
          })
    
        }
    
        setTimeout(() => {
          this.isZoom = false;
    
          this.map = new google.maps.Map(document.getElementById('map'),
            {
              zoom: 12,
              center: newLatLang,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              disableDefaultUI: true,
              zoomControl: true, // Ensure zoom control is enabled
              zoomControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_LEFT,
                style: google.maps.ZoomControlStyle.SMALL

               // style: google.maps.ZoomControlStyle.DEFAULT 
              }
            });
    
          let marker = new google.maps.Marker({
            map: this.map,
            position: newLatLang,
            draggable: true,
            icon: { url: './../../assets/location_1.png' },
          });
    
          let geocoder = new google.maps.Geocoder();
          let infowindow = new google.maps.InfoWindow();      
    
          if(mapInfoWindowText)
          {
            infowindow.setContent(mapInfoWindowText);
            infowindow.open(this.map, marker);
          }

          if(patientLatitude && patientLongitude)
            {
              this.drawCircleFence(LocationForm_CurrenIndex);
            }
            this.removeDragEndListener();
         
    
          this.getMarkerData(marker, geocoder, infowindow, (responseData, markerDragEndObj) => {
            // if(!search){
            //console.log('responseData: ', responseData)
           // this.handleAddressChange(responseData, LocationForm_CurrenIndex);
           this.markerDragEndLatLng = markerDragEndObj;
           this.handleMarkerChange(responseData, LocationForm_CurrenIndex);
           this.drawCircleFence(LocationForm_CurrenIndex);
            // }
          })
        }, 500);
    
      }
 
 

  removeDragEndListener() {
    if (this.dragEndListener) {
        google.maps.event.removeListener(this.dragEndListener);
        this.dragEndListener = null;
    }
  }

   

  /* Name:Shailesh
      use: for getting markar on map*/
  getMarkerData(marker, geocoder, infowindow, callback) 
  {
   this.dragEndListener =  google.maps.event.addListener(marker, 'dragend', function () {
      
      //  console.log("marker.getPosition()",marker.getPosition());
      //  console.log("marker change", {lat:marker.getPosition().lat() , lng : marker.getPosition().lng()})


      geocoder.geocode({ 'latLng': marker.getPosition() }, function (results, status) {
        ////console.log(results, status);
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
         
            infowindow.setContent(results[0].formatted_address);
            infowindow.open(document.getElementById('map'), marker);
            callback(results[0],marker);
          }
        }
      });
    });
  }


  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
  }

  ngOnInit() {
   
    this.siteAddress_Form =  this.fb.group({
      'addressList': this.fb.array([this.createAddress(null)])
    });

    if(this.isShortcutModalOpen){
      this.getAllCustomerList();
    }
 
    if(this.customerCode){
     this.getCustomerDetail(this.customerCode);
    }
    this.getRadiusUnitLabel();

  }


  /* Name:Shailesh
      use: for confirm password*/
  apartmentValid(control: AbstractControl): Promise<any> | Observable<any> {
    const confirmPassword = new Promise<any>(
      (resolve, reject) => {
        ////console.log(control.value.trim());
        if (control.value.trim()) {
          resolve(null);
        } else {
          resolve({
            invalid: true
          });
        }
      });
    return confirmPassword;
  }

  changeCustomer(event: any){
    //console.log('changeCustomer: ', event.value);
    if(event?.value){
      this.addressList.clear() //  clearing form array
      //this.resetFormData();
      this.service.common.progressLoader = true;
      this.getCustomerDetail(event.value);
      this.selectedCustomerCode = event.value;
      this.customerCode = event.value;
    }
   }

   getAllCustomerList(){
    this.service.common.progressLoader = true;
    this.service.getAllCustomerDetails(this.service.local.get('orgCode')).subscribe((res:any) => {       
     if(res.responsecode == 200){
        this.customerList = res.data ;
        this.service.common.progressLoader = false;
     }
   })
   }

  //  getCustomerAddressList(customerCode: any){
  //   this.service.getCustomerDetailByCustomerCode(customerCode, (response) => {
  //     if (response.responsecode == 200) {
  //       this.service.common.progressLoader = false;
  //       this.service.customerData = response.data;
 
  //     }
  //   })
  // }



  getCustomerDetail(patientCode: any){
    this.service.common.progressLoader = true;
    // this.service.getPatientDetailByPatientCode(this.patientCode, (response) => {
      this.service.getCustomerDetailByCustomerCode(patientCode, (response) => {
        if (response.responsecode == 200) {
          this.service.common.progressLoader = false;
          this.service.patientData = response;
          this.service.customerData = response.data;
 
 
          if (response.data?.customerAddressResponseList?.length > 0) {
            let addressListDataRes = response.data?.customerAddressResponseList;
            this.allLocationList = response.data?.customerAddressResponseList;
              this.addressList.removeAt(0);  // removing extra form which is on 0 position

            addressListDataRes?.forEach((address, idx) => {
              // console.log('addressListDataResIndex: ', address, idx);
              this.addAddress(address);
              if (idx === 0) {
                this.initialize(idx, address?.customerLatitude, address?.customerLongitude, address?.location);
              }
              this.applyValidations(idx);
            })
          }
   
        } else {
          this.service.common.progressLoader = false;
        }
      });
  }

  get addressList(): FormArray {   
    return <FormArray>this.siteAddress_Form.get('addressList');
  }

  createAddress(formData: any): FormGroup {
    // //console.log('Edit Data: ', formData);
    if (formData) {
      // return this.fb.group({
        return new FormGroup({
        'customerAddressCode': new FormControl(formData?.customerAddressCode ? formData?.customerAddressCode : null),
        'customerAddress': new FormControl(formData?.customerAddress ? formData?.customerAddress : ''),
        'customerApartmentNo': new FormControl(formData?.customerApartmentNo ? formData?.customerApartmentNo : ''),
        'customerCity': new FormControl(formData?.customerCity ? formData?.customerCity : '', Validators.required, this.apartmentValid.bind(this)),
        'customerCountry': new FormControl(formData?.customerCountry ? formData?.customerCountry : '', Validators.required, this.apartmentValid.bind(this)),
        'zipCode': new FormControl(formData?.zipCode ? formData?.zipCode : '', [Validators.pattern(this.service.common.postCode)]),
        'customerState': new FormControl(formData?.customerState, Validators.required, this.apartmentValid.bind(this)),
        'customerLatitude': new FormControl(formData?.customerLatitude ? formData?.customerLatitude : ''),
        'customerLongitude': new FormControl(formData?.customerLongitude ? formData?.customerLongitude : ''),
        'location': new FormControl(formData?.location ? formData?.location : ''),
        'siteName': new FormControl(formData?.siteName ? formData?.siteName : '', Validators.required),
        'siteContact': new FormControl(formData?.siteContact ? formData?.siteContact : ''),
        'siteContactPhone': new FormControl(formData?.siteContactPhone ? formData?.siteContactPhone : ''),
        'specialInstructions': new FormControl(formData?.specialInstructions ? formData?.specialInstructions : ''),
        'siteRadius': new FormControl(formData?.siteRadius ? formData?.siteRadius : null),
        'siteRadiusClockout'  : new FormControl(formData?.siteRadiusClockout ? formData?.siteRadiusClockout : null),
        'setClockInRadiusMaxValue' : new FormControl(5000),
        'setClockOutRadiusMaxValue' : new FormControl(6000),
        'clockinMode' : new FormControl(formData?.clockinMode ? formData?.clockinMode : '')
      },this.validateGeoFence.bind(this))
    } 
    else
     {
      // return this.fb.group({
         return new FormGroup({
        'customerAddressCode': new FormControl(null),
        'customerAddress': new FormControl(''),
        'customerApartmentNo': new FormControl(''),
        'customerCity': new FormControl('', Validators.required, this.apartmentValid.bind(this)),
        'customerCountry': new FormControl('', Validators.required, this.apartmentValid.bind(this)),
        'customerState': new FormControl('', Validators.required, this.apartmentValid.bind(this)),
        'zipCode': new FormControl(''),
        'customerLatitude': new FormControl(''),
        'customerLongitude': new FormControl(''),
        'location': new FormControl(''),
        'siteName': new FormControl('', Validators.required),
        'siteContact': new FormControl(''),
        'siteContactPhone': new FormControl(''),
        'specialInstructions': new FormControl(''),
        'siteRadius' : new FormControl(''),
        'siteRadiusClockout'  : new FormControl(''),
        'setClockInRadiusMaxValue' : new FormControl(5000),
        'setClockOutRadiusMaxValue' : new FormControl(6000),
        'clockinMode' : new FormControl('')
      },this.validateGeoFence.bind(this))
    }
  }

  addAddress(formData: any) {   
    this.addressList.push(this.createAddress(formData));
  }

  /* Name:Shailesh
     use: for changing address*/
  handleAddressChange(address, i) {

    if (address.geometry) {
      // //console.log('Address_Geometry:', address);
      this.service.patientData.patientLatitude = address.geometry.location.lat();
      this.service.patientData.patientLongitude = address.geometry.location.lng();
    this.updateServiceAddress(address, i);
    
    }

  }

  /* Name:Shailesh
     use: for address validation*/
  addressValidation(control: AbstractControl): Promise<any> | Observable<any> {
    const addressValidation = new Promise<any>(
      (resolve, reject) => {
        let addr = control.value;
        // Get geocoder instance
        let geocoder = new google.maps.Geocoder();
        // Geocode the address
        // ////console.log(control.value);
        geocoder.geocode({ 'address': addr }, function (results, status) {
          ////console.log(results);
          ////console.log(status);
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            ////console.log(addr, results[0].formatted_address)
            if (addr === results[0].formatted_address) {
              resolve(null);

            } else {
              resolve({
                invalid: true
              });
            }
            // show an error if it's not
          } else {
            //////console.log('invalid');
            resolve({
              invalid: true
            });
          }
        });
      });
    return addressValidation;
  }
 
  updateServiceAddress(address, idx) {

  let formAddressListArray = this.siteAddress_Form.get('addressList') as FormArray;

    if (this.siteAddress_Form?.value?.addressList?.length > 0) {  

      let newLatForAdd = address.geometry.location.lat().toString();
      let newLongForAdd = address.geometry.location.lng().toString();
      // //console.log('LatLogNew, ', newLatForAdd, newLongForAdd)

      this.siteAddress_Form.value?.addressList?.find((addListVal) => {  
        if (addListVal?.customerLatitude === newLatForAdd && addListVal?.customerLongitude === newLongForAdd){

          formAddressListArray.controls?.forEach((ctrl: FormArray, ctrl_idx) => {
            if (ctrl_idx === idx) {
              let oldLocationValue = ctrl?.controls['location']?.value ? ctrl?.controls['location']?.value : '';
              if ((this.siteAddress_Form.value?.addressList?.length - 1) === idx){   
                ctrl.reset();
              } else {
                ctrl.reset();
              }
              this.service.common.message = 'Location already exists for this customer.';
              this.service.common.errorMessage = true;
            }
          })
          return true;

          } else {

      let places: any;
            const componentForm = {
              route: 'long_name',
              locality: 'long_name',
              political: 'long_name',
              administrative_area_level_1: 'long_name',
              postal_code: 'short_name',
              country: 'long_name',
              street_number: ' long_name',
              sublocality: 'long_name',
              sublocality_level_2: 'long_name'
            };

            let addressName = '';

            // patientApartmentNo
            // fullAddress
            // patientCity
            // patientState
            // patientCountry
            // zipCode


            // //console.log('FormsData: ', this.addressList.controls[idx]);

            this.service.patientData.patientLatitude = address.geometry.location.lat();
            this.service.patientData.patientLongitude = address.geometry.location.lng();

            formAddressListArray?.controls?.forEach((ctrl: FormArray, ctrl_idx) => {
              if (ctrl_idx === idx) {
                ctrl.controls['customerLatitude'].setValue(address.geometry.location.lat());
                ctrl.controls['customerLongitude'].setValue(address.geometry.location.lng());
              }
            })

            for (let i = 0; i < address.address_components.length; i++) {
              const addressType = address.address_components[i].types[0];
              if (componentForm[addressType]) {
                if (addressType === 'sublocality' || addressType === 'sublocality_level_2') {
                  const val = address.address_components[i][componentForm[addressType]];
                  // //console.log(val);

                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerAddress'].setValue(val);
                    }
                  })
                  this.service.patientData.address = val;
                }
                // if (addressType === 'street_number') {
                //   const val = address?.address_components[i]['long_name'] ? address?.address_components[i]['long_name'] : '';
                //   this.service.patientData.patientApartmentNo = val ? val : '';
                //   formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                //     if (ctrl_idx === idx) {
                //       ctrl.controls['customerApartmentNo'].setValue(val);
                //     }
                //   })
                // }
                if (addressType === 'route') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  this.service.patientData.patientAddress = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerAddress'].setValue(val);
                    }
                  })
                }

                if (addressType === 'political') {
                  const val = address.address_components[i][componentForm[addressType]];
                  // this.service.patientData.patientAddress += ', ' + val;
                }
                if (addressType === 'postal_code') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  // if (!this.service.patientData.zipCode) {
                  this.service.patientData.zipCode = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['zipCode'].setValue(val);
                    }
                  })
                  // }
                }
                if (addressType === 'locality' || addressType === 'postal_town') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  //////console.log(val);
                  // if (!this.service.patientData.patientCity) {
                  this.service.patientData.patientCity = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerCity'].setValue(val);
                    }
                  })
                  // }

                }
                if (addressType === 'administrative_area_level_1') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  // if (!this.service.patientData.patientState) {
                  this.service.patientData.patientState = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerState'].setValue(val);
                    }
                  })
                  // }
                }
                if (addressType === 'country') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  // if (!this.service.patientData.patientCountry) {
                  this.service.patientData.patientCountry = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerCountry'].setValue(val);
                    }
                  })
                  // }
                }
              }
            }

              formAddressListArray?.controls?.forEach((ctrl: FormArray, ctrl_idx) => {
                if (ctrl_idx === idx) {
                  ctrl.controls['location'].setValue(address?.formatted_address);
                }
              });

            let mapInfoWindow = address?.formatted_address;          
          this.initialize(idx ,this.service.patientData.patientLatitude, this.service.patientData.patientLongitude, mapInfoWindow);
            ////console.log(this.service.patientData.patientAddress);

        }

      });
    }

  }

 
  handleMarkerChange(address, idx)
  {
    let formAddressListArray = this.siteAddress_Form.get('addressList') as FormArray;

    if (this.siteAddress_Form?.value?.addressList?.length > 0) {  

      let newLatForAdd = address.geometry.location.lat().toString();
      let newLongForAdd = address.geometry.location.lng().toString();
      // //console.log('LatLogNew, ', newLatForAdd, newLongForAdd)

      this.siteAddress_Form.value?.addressList?.find((addListVal) => {  
        if (addListVal?.customerLatitude === newLatForAdd && addListVal?.customerLongitude === newLongForAdd){

          formAddressListArray.controls?.forEach((ctrl: FormArray, ctrl_idx) => {
            if (ctrl_idx === idx) {
              let oldLocationValue = ctrl?.controls['location']?.value ? ctrl?.controls['location']?.value : '';
              if ((this.siteAddress_Form.value?.addressList?.length - 1) === idx){   
                ctrl.reset();
              } else {
                ctrl.reset();
              }
              this.service.common.message = 'Location already exists for this customer.';
              this.service.common.errorMessage = true;
            }
          })
          return true;

          } else {

      
            const componentForm = {
              route: 'long_name',
              locality: 'long_name',
              political: 'long_name',
              administrative_area_level_1: 'long_name',
              postal_code: 'short_name',
              country: 'long_name',
              street_number: ' long_name',
              sublocality: 'long_name',
              sublocality_level_2: 'long_name'
            };

    
            this.service.patientData.patientLatitude = address.geometry.location.lat();
            this.service.patientData.patientLongitude = address.geometry.location.lng();

            formAddressListArray?.controls?.forEach((ctrl: FormArray, ctrl_idx) => {
              if (ctrl_idx === idx) {
                // ctrl.controls['customerLatitude'].setValue(address.geometry.location.lat());
                // ctrl.controls['customerLongitude'].setValue(address.geometry.location.lng());

                // instead of setting geoCode lat lng,  setting marker dragend lat lng
                ctrl.controls['customerLatitude'].setValue(this.markerDragEndLatLng.getPosition().lat());
                ctrl.controls['customerLongitude'].setValue(this.markerDragEndLatLng.getPosition().lng());

              }
            })

            for (let i = 0; i < address.address_components.length; i++) {
              const addressType = address.address_components[i].types[0];
              if (componentForm[addressType]) {
                if (addressType === 'sublocality' || addressType === 'sublocality_level_2') {
                  const val = address.address_components[i][componentForm[addressType]];
                  // //console.log(val);

                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerAddress'].setValue(val);
                    }
                  })
                  this.service.patientData.address = val;
                }
                // if (addressType === 'street_number') {
                //   const val = address?.address_components[i]['long_name'] ? address?.address_components[i]['long_name'] : '';
                //   this.service.patientData.patientApartmentNo = val ? val : '';
                //   formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                //     if (ctrl_idx === idx) {
                //       ctrl.controls['customerApartmentNo'].setValue(val);
                //     }
                //   })
                // }
                if (addressType === 'route') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  this.service.patientData.patientAddress = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerAddress'].setValue(val);
                    }
                  })
                }

                if (addressType === 'political') {
                  const val = address.address_components[i][componentForm[addressType]];
                  // this.service.patientData.patientAddress += ', ' + val;
                }
                if (addressType === 'postal_code') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  // if (!this.service.patientData.zipCode) {
                  this.service.patientData.zipCode = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['zipCode'].setValue(val);
                    }
                  })
                  // }
                }
                if (addressType === 'locality' || addressType === 'postal_town') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  //////console.log(val);
                  // if (!this.service.patientData.patientCity) {
                  this.service.patientData.patientCity = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerCity'].setValue(val);
                    }
                  })
                  // }

                }
                if (addressType === 'administrative_area_level_1') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  // if (!this.service.patientData.patientState) {
                  this.service.patientData.patientState = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerState'].setValue(val);
                    }
                  })
                  // }
                }
                if (addressType === 'country') {
                  const val = address?.address_components[i][componentForm[addressType]] ? address?.address_components[i][componentForm[addressType]] : '';
                  // if (!this.service.patientData.patientCountry) {
                  this.service.patientData.patientCountry = val;
                  formAddressListArray.controls.forEach((ctrl: FormArray, ctrl_idx) => {
                    if (ctrl_idx === idx) {
                      ctrl.controls['customerCountry'].setValue(val);
                    }
                  })
                  // }
                }
              }
            }

              formAddressListArray?.controls?.forEach((ctrl: FormArray, ctrl_idx) => {
                if (ctrl_idx === idx) {
                  ctrl.controls['location'].setValue(address?.formatted_address);
                }
              });

            }

          });
        }
  }



  /* Name:Shailesh
     use: for cancel popup*/
  cancelPopup() {
    if (this.cancelPatient) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          // this.resetFormData();
          // this.router.navigate(['/dashboard/patient/patient-list']);
          this.router.navigate(['/dashboard/customer/customer-list']);
        }
      });
    } else {
      this.service.common.resetNotesRequestForm();
      // this.router.navigate(['/dashboard/patient/patient-list']);
      this.router.navigate(['/dashboard/customer/customer-list']);
    }
  }

 
 
  extractAddressComponent(place: Address, componentType: string) {
    const component = place.address_components.find(comp =>
      comp.types.some(type => type === componentType)
    );
    return component ? component.long_name : '';
  }

 
  

  saveSiteAddress(index:number){
    if(this.isShortcutModalOpen && !this.customerCode){
      this.service.common.message = 'Please Select a customer before save address.';
      this.service.common.errorMessage = true;
      return;
    }
    this.isSiteForm_submitted =  true;
   if(this.addressList.controls[index].valid)
   {
     
       let obj ={
         "customerAddress": this.addressList.controls[index].value.customerAddress,
         "customerAddressCode": this.addressList.controls[index].value.customerAddressCode,
         "customerApartmentNo": this.addressList.controls[index].value.customerApartmentNo,
         "customerCity": this.addressList.controls[index].value.customerCity  ,
        //  "customerCode":   this.patientForm.controls['customerCode'].value ,
         "customerCode":   this.customerCode ,
         "customerCountry": this.addressList.controls[index].value.customerCountry  ,
         "customerLatitude": this.addressList.controls[index].value.customerLatitude  ,
         "customerLongitude": this.addressList.controls[index].value.customerLongitude  ,
         "customerState": this.addressList.controls[index].value.customerState  ,
         "location": this.addressList.controls[index].value.location,
         "zipCode": this.addressList.controls[index].value.zipCode ,
         "siteName": this.addressList.controls[index].value.siteName ,
         "siteContact": this.addressList.controls[index].value.siteContact ,
         "siteContactPhone": this.addressList.controls[index].value.siteContactPhone ,
         "siteId": ""  ,
         "specialInstructions" : this.addressList.controls[index].value.specialInstructions,
         "siteRadius" : this.addressList.controls[index].value.siteRadius ? parseFloat(this.addressList.controls[index].value.siteRadius) :  null,
         "siteRadiusClockout" : this.addressList.controls[index].value.siteRadiusClockout ? parseFloat(this.addressList.controls[index].value.siteRadiusClockout) : null,
         "noteRequest": this.service?.common?.noteRequest ? this.service.common.noteRequest : null,
         "clockinMode" : this.addressList.controls[index].value.clockinMode,
        }
     //console.log(obj);
     this.service.common.progressLoader = true;
     this.service.Save_OR_SiteAddress(obj).subscribe((response:any) =>{
       this.service.common.progressLoader = false;
        this.isSiteForm_submitted =  false;
       if (response.responsecode == 200) {
        this.service.common.resetNotesRequestForm();
         this.service.common.message = response.message;
         this.service.common.successMessage = true;

         // setting currently saved site  'customerAddressCode' for updating the same site
         this.addressList.controls[index].get("customerAddressCode").setValue(response.data.customerAddressCode);
          } else {
         this.service.common.message = response.message;
         this.service.common.errorMessage = true;
         }
     })

   }
 }


 removeAddress(i:number) { 
  if( this.addressList.controls[i].value.customerAddressCode)
  {
    this.service.common.progressLoader = true;
    this.service.DeleteSiteAddress(this.addressList.controls[i].value.customerAddressCode).subscribe((response:any) =>{
      this.service.common.progressLoader = false;
       if (response.responsecode == 200) {
        this.service.common.message = response.message;
        this.service.common.successMessage = true;
        this.addressList.removeAt(i);  // removing locally , instead of calling refresh api (customerAddressList  api)
        this.initialize();  // reset map
      }
       else {
        this.service.common.message = response.message;
        this.service.common.errorMessage = true;
        }
    });
  }
  else{
    this.addressList.removeAt(i);
      this.initialize();  // reset map
  }
 
}


// geoFenceRadiousChange(event:any, formIndex:number, fenceFor:string)
// {
//   if (!/^[1-9][0-9]*$/.test(event.target.value)) 
//   {
//     event.target.value = '';

//     if(fenceFor === 'clockin')
//       {
//         if (this.circleFence_ClockIn) {
//           this.circleFence_ClockIn.setMap(null); // Remove existing circle from the map
//         }
//       }
     
//       if(fenceFor === 'clockout')
//         {
//           if (this.circleFence_ClockOut) {
//             this.circleFence_ClockOut.setMap(null); // Remove existing circle from the map
//           }
//         }

//     return;
//   }

//   this.drawCircleFence(formIndex);
// }
  
drawCircleFence(formIndex:number)
{
  this.drawnClockInFence(formIndex);
  this.drawnClockOutFence(formIndex);

  // if(fenceFor === 'clockin')
  //  if( this.addressList.controls[formIndex].value.siteRadius )
  //   {
  //     if (this.circleFence_ClockIn) {
  //       this.circleFence_ClockIn.setMap(null); // Remove existing circle from the map
  //     }

  //     if(this.addressList.controls[formIndex].value.customerLatitude &&
  //       this.addressList.controls[formIndex].value.customerLongitude &&
  //       this.addressList.controls[formIndex].value.siteRadius )  
  //       {
  //       this.circleFence_ClockIn = new google.maps.Circle({
  //           strokeColor: "#FF0000",
  //           strokeOpacity: 0.8,
  //           strokeWeight: 2,
  //           fillColor: "#FF0000",
  //           fillOpacity: 0.35,
  //           map: this.map,
  //           center: { 
  //              lat: parseFloat(this.addressList.controls[formIndex].value.customerLatitude),
  //              lng: parseFloat(this.addressList.controls[formIndex].value.customerLongitude) 
  //             },
  //             radius: parseFloat(this.addressList.controls[formIndex].value.siteRadius ),
  //           draggable: false
  //         });
   
  //       }

  //   }
   
  //   // if(fenceFor === 'clockout')
  //     if(this.addressList.controls[formIndex].value.siteRadiusClockout )
  //     {
  //       if (this.circleFence_ClockOut) {
  //         this.circleFence_ClockOut.setMap(null); // Remove existing circle from the map
  //       }

  //       if(this.addressList.controls[formIndex].value.customerLatitude &&
  //         this.addressList.controls[formIndex].value.customerLongitude &&
  //         this.addressList.controls[formIndex].value.siteRadiusClockout )  
  //         {
  //         this.circleFence_ClockOut = new google.maps.Circle({
  //             strokeColor: "#28B463",
  //             strokeOpacity: 0.5,
  //             strokeWeight: 1,
  //             fillColor: "#2ECC71",
  //             fillOpacity: 0.30,
  //             map: this.map,
  //             center: { 
  //                lat: parseFloat(this.addressList.controls[formIndex].value.customerLatitude),
  //                lng: parseFloat(this.addressList.controls[formIndex].value.customerLongitude) 
  //               },
  //               radius: parseFloat(this.addressList.controls[formIndex].value.siteRadiusClockout ),
  //             draggable: false
  //           });
     
  //         }

  //     }

   
  
//   // Listen to circle's dragend event to update the center
//   google.maps.event.addListener(this.circle, 'dragend', () => {
//     const newCenter = this.circle!.getCenter();
//     console.log('New center:', newCenter.lat(), newCenter.lng());
//   });
 
}

  // validateGeoFence(control: FormControl): Promise<any> | Observable<any> {

  //   if (!control.get("siteRadius").value?.trim() && !control.get("siteRadiusClockout").value?.trim()) {
  //     control.get("siteRadius").setErrors(null);
  //     control.get("siteRadiusClockout").setErrors(null);
  //     return
  //   }

  //   if (control.get("siteRadius").value || control.get("siteRadiusClockout").value) {
  //     control.get("siteRadius").setErrors(Validators.required);
  //     control.get("siteRadiusClockout").setErrors(Validators.required);
  //   }

  //   if (control.get("siteRadius").value?.trim() && control.get("siteRadiusClockout").value?.trim()) 
  //     {
  //       control.get("siteRadius").setErrors(null);
  //       control.get("siteRadiusClockout").setErrors(null);

  //     if (parseFloat(control.get("siteRadius").value) >= parseFloat(control.get("siteRadiusClockout").value)) {
  //       control.get("siteRadiusClockout").setErrors({ radius: true })
  //     }
  //     else {
  //       control.get("siteRadiusClockout").setErrors(null);
  //     }

  //   }

  // }


   validateGeoFence(control: FormControl): Promise<any> | Observable<any> {

    if (!control.get("siteRadius").value && !control.get("siteRadiusClockout").value) {
       return
    }
 
    if (control.get("siteRadius").value && control.get("siteRadiusClockout").value) 
      {
      
      if (parseFloat(control.get("siteRadius").value) >= parseFloat(control.get("siteRadiusClockout").value)) {
        control.get("siteRadiusClockout").setErrors({ radius: true })
      }
      else {
        control.get("siteRadiusClockout").setErrors(null);
      }

    }

  }

  clockInDragEnd(event:any, formIndex:number)
  {
    this.addressList.controls[formIndex].get("siteRadius").setValue(event.value > 0 ? event.value : null)
    this.applyValidations(formIndex);
   this.drawnClockInFence(formIndex);
  }

  clockOutDragEnd(event:any, formIndex:number)
  {
    this.addressList.controls[formIndex].get("siteRadiusClockout").setValue(event.value > 0 ? event.value : null)
    this.applyValidations(formIndex);
   this.drawnClockOutFence(formIndex);
   }

  drawnClockInFence(formIndex:number)
  {
    // if( this.addressList.controls[formIndex].value.siteRadius )
    //   {
        if (this.circleFence_ClockIn) {
          this.circleFence_ClockIn.setMap(null); // Remove existing circle from the map
        }
  
        if(this.addressList.controls[formIndex].value.customerLatitude &&
          this.addressList.controls[formIndex].value.customerLongitude &&
          this.addressList.controls[formIndex].value.siteRadius )  
          {
          this.circleFence_ClockIn = new google.maps.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              map: this.map,
              center: { 
                 lat: parseFloat(this.addressList.controls[formIndex].value.customerLatitude),
                 lng: parseFloat(this.addressList.controls[formIndex].value.customerLongitude) 
                },
                radius: parseFloat(this.addressList.controls[formIndex].value.siteRadius ),
              draggable: false
            });
     
            let clockInRadius = parseFloat(this.addressList.controls[formIndex].value.siteRadius);
            let clockOutRadius = parseFloat(this.addressList.controls[formIndex].value.siteRadiusClockout );
   
            if(!clockOutRadius)
             {
            let zoom = this.getZoomLevel(clockInRadius);
            this.map.setZoom(zoom);
             }

          }
  
     // }
  }


  drawnClockOutFence(formIndex:number)
  {
    // if(this.addressList.controls[formIndex].value.siteRadiusClockout )
    //   {
        if (this.circleFence_ClockOut) {
          this.circleFence_ClockOut.setMap(null); // Remove existing circle from the map
        }

        if(this.addressList.controls[formIndex].value.customerLatitude &&
          this.addressList.controls[formIndex].value.customerLongitude &&
          this.addressList.controls[formIndex].value.siteRadiusClockout )  
          {
          this.circleFence_ClockOut = new google.maps.Circle({
            strokeColor: " #ef9999",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: " #ef9999",
            fillOpacity: 0.30,
              map: this.map,
              center: { 
                 lat: parseFloat(this.addressList.controls[formIndex].value.customerLatitude),
                 lng: parseFloat(this.addressList.controls[formIndex].value.customerLongitude) 
                },
                radius: parseFloat(this.addressList.controls[formIndex].value.siteRadiusClockout ),
              draggable: false
            });
     
         // }

          
            let zoom = this.getZoomLevel(parseFloat(this.addressList.controls[formIndex].value.siteRadiusClockout ));
            this.map.setZoom(zoom);
         

      }
  }

  getZoomLevel(distance) {
    var scale = distance / 500; 
    var zoomLevel = Math.floor(16 - Math.log(scale) / Math.LN2);
    return zoomLevel;
  }

  applyValidations(index:number)
  {
     if(this.addressList.controls[index].value.siteRadius 
      || this.addressList.controls[index].value.siteRadiusClockout )
      {
        this.addressList.controls[index].get("siteRadius").setValidators([Validators.required]);
        this.addressList.controls[index].get("siteRadiusClockout").setValidators([Validators.required]);
      }
      else{
        this.addressList.controls[index].get("siteRadius").setValidators(null);
        this.addressList.controls[index].get("siteRadiusClockout").setValidators(null);
      } 

      this.addressList.controls[index].get("siteRadius").updateValueAndValidity();
      this.addressList.controls[index].get("siteRadiusClockout").updateValueAndValidity();
   }


   sliderSetMaxValue(index:number, flag:string)
   {
    if(flag === 'for clockin')
      {
        this.addressList.controls[index].get("siteRadius").setValue(null);
        if (this.circleFence_ClockIn) {
          this.circleFence_ClockIn.setMap(null); // Remove existing circle from the map
        }
        
      }

      if(flag === 'for clockout')
        {
          this.addressList.controls[index].get("siteRadiusClockout").setValue(null);
          if (this.circleFence_ClockOut) {
            this.circleFence_ClockOut.setMap(null); // Remove existing circle from the map
          }
        }
   }


   getRadiusUnitLabel()
   {
      this.commonApiService.get_setting_value_by_setting_name(settings_keys.RADIUS_UNIT, this.service.local.get('orgCode')).subscribe((res:any)=>{
       if(res.responsecode === 200)
       {
          this.radius_unit_label = res.settingValue ;
       }
      })
   }


}
