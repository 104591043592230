import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NewJobCardService } from 'src/app/components/new-job-card/new-job-card.service';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
import { SessionStorageService } from 'angular-web-storage';
import { debounce } from 'lodash';


@Component({
  selector: 'failed-staff-list',
  templateUrl: './failed-staff-list.component.html',
  styleUrls: ['./failed-staff-list.component.scss']
})
export class FailedStaffListPopup implements OnInit {
  searchText: any;
  img_serverPath: string = '';
  eligibleStaffsList: any = [];
  filtered_eligibleStaffsList: any[] = [];
  showSpinner: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<FailedStaffListPopup>,
    public dialog: MatDialog, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private jobCardService: NewJobCardService, 
    public dashboarService: DashboardService,
     ) 
     {
      this.applyFilter = debounce(this.applyFilter, 600);
     }

  ngOnInit(): void {
    // console.log('FailedStaffListPopup_Content: ', this.data.failedStaffList); 
    if(this.data?.failedStaffList){
      this.eligibleStaffsList = this.data.failedStaffList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;
    }
    this.img_serverPath = this.jobCardService._apiSVC.getSuperAdmin() + '/downloadFile/'; 
  }

  applyFilter(value: string) {
    // console.log('applyFilter: ', value);
    const filterValue = value.toLowerCase();
      const dataSourceValue =  this.eligibleStaffsList && this.eligibleStaffsList && JSON.parse(JSON.stringify(this.eligibleStaffsList));
      // console.log('applyFilter_2: ', dataSourceValue);
      this.filtered_eligibleStaffsList = dataSourceValue && dataSourceValue.filter(item => {
        let matchCase =  (item?.workerName?.toLowerCase().includes(filterValue) || item?.email?.toLowerCase().includes(filterValue) || item?.failReason?.toLowerCase().includes(filterValue));
        // console.log('Item_1: ', matchCase);
        return matchCase;
      });
  }

}
