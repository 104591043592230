<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
align-items-center">
<div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">Leave Requests
</div>
<div class="col-lg-5 col-md-5 col-sm-12 searchfilter">
  <!-- <input autocomplete="off" matInput
      (keyup)="applyFilter($event.target.value)" type="text"
      class="form-control"
      placeholder="{{'skills.labels.Search Skill' | translate }}">
    <mat-icon class="cursor-pointer">search</mat-icon> -->
</div>
<div class="col-lg-1 col-md-1 col-sm-12 float-end text-end">
  <button mat-raised-button color="primary" (click)="openDialog()" title="{{ 'common.labels.Leave Request' | translate }}">Add</button>
</div>
</div>

<div class="row">
  <div class="w-100">
    <div class="col-md-12">
  <div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">
  
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <div>{{element.startDate}} </div>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
          <td mat-cell *matCellDef="let element">
            <div>
              <div>{{element.endDate}} </div>
            </div>
          </td>
        </ng-container>processed-leaves
  
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="commentWidth">Comments</th>
          <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
            <span title="{{element.comment}}">{{element.comment ? element.comment  : '--'}}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="leaveStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="badge bg-success" *ngIf="element.leaveStatus.toLowerCase() === 'approved'">{{element.leaveStatus | capitalize}}</span>
            <span class="badge bg-warning" *ngIf="element.leaveStatus.toLowerCase() === 'pending'">{{element.leaveStatus | capitalize}}</span>
            <span class="badge bg-danger" *ngIf="element.leaveStatus.toLowerCase() === 'rejected'">{{element.leaveStatus | capitalize}}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
          <td mat-cell *matCellDef="let element" class="action">            
            <i class="material-icons pointer" [class.disabledActionBtn]="element.leaveStatus.toLowerCase() !== 'pending'" (click)="element.leaveStatus.toLowerCase() === 'pending' && openDialog(element)">edit</i>
          <!-- <i class="material-icons pointer ms-2" [class.disabledActionBtn]="element.leaveStatus.toLowerCase() !== 'pending'">delete</i> -->
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
  
      <div *ngIf="(!service.common.progressLoader && !service.common.infiniteLoader) && distributionData.length === 0" class="p-3 noList">Leave request are not available</div>
      </div>
  
      <div class="w-100" *ngIf="service.common.infiniteLoader">
        <div class="loadergif">
          <img src="assets/typint_dots_black.gif" alt="Loader">
          <p>Loading...</p>
        </div>
      </div>
      
    </div>
  </div>
</div>