import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TimeOfRequestService } from './time-of-request.service';
import { AddLeaveComponent } from './add-leave/add-leave.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-time-of-request',
  templateUrl: './time-of-request.component.html',
  styleUrls: ['./time-of-request.component.scss']
})
export class TimeOfRequestComponent implements OnInit {

  navDate: any;
  currentDate = new Date();
  startDate: any;
  endDate: any;
  selectDate;
  selectedDate: any;
  weekDaysHeaderArr: Array<string> = [];
  currentSelectedDate;
  @Input() locale: string;
  gridArr: Array<any> = [];
  gridArrNew: Array<any> = [];
  timeofreq = [];

  leaveStatus: string = 'pending';
  distributionData: any = [];
  displayedColumns: string[] = ['startDate', 'endDate', 'comment', 'leaveStatus', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  today: any;

  constructor(
    public service: TimeOfRequestService,
    public dialog: MatDialog, public translate: TranslateService, public common: CommonService
  ) {
    this.translate.setDefaultLang(this.common.local.get('language'));
  }

  ngOnInit() {
    // this.selectDate = moment(this.currentDate).format('D');
    // this.selectedDate = moment(this.currentDate).format('DD MMM');
    // this.currentSelectedDate = moment(new Date()).format('YYYY-MM-DD');
    // let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    // this.startDate = moment().startOf('month').format('YYYY-MM-DD');
    // this.endDate = moment(lastDay).format('YYYY-MM-DD');

    // moment.locale(this.locale);
    // this.navDate = moment();
    // this.makeHeader();
    // this.createBlanlGrid();
   // this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);

    this.page = 0;
    this.getListDataByPage();
    //this.today = moment(new Date()).format('MM-DD-YYYY');
  }

  /* Name:srinivas
        use: change month navigation by number. */
  changeNavMonth(num: number, monthData = null) {
    this.navDate.add(num, 'month');
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
      this.selectDate = moment(this.currentDate).format('DD');
    } else {
      this.startDate = moment(firstDay).format('YYYY-MM-DD');
    }
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    this.selectedDate = moment(this.dateFromNum(num, this.navDate)).format('dd MMM');
    if (!monthData) {

      this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
      //////console.log(monthData);
    }
  }
  /* Name:srinivas
        use: change date from number. */
  dateFromNum(num: number, referenceDate: any): any {
    let returnDate = moment(referenceDate);
    return returnDate.date(num);
  }
  /* Name:srinivas
        use: create header. */
  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
  }
  /* Name:srinivas
        use: create empty table. */
  createBlanlGrid() {
    this.gridArrNew = [];
    this.gridArr = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {

        obj.value = i - initialEmptyCells + 1;
        obj.available = true;
        obj.highlight = '';
        obj.date = dateToCheck;
      }
      this.gridArr.push(obj);
    }
    this.gridArrNew = this.gridArr;
  }
  /* Name:srinivas
        use: return time off details. */
  getTimeOfReq(code, startDate, endDate) {
    try {

      this.service.common.progressLoader = true;
      this.service.getTimeOffByWorkerCodeAndBetweenDates(code, startDate, endDate, (data) => {
        //console.log(data);
        if (data.responsecode == 200) {
          this.timeofreq = data.data;
          this.getNewAppointments(data.data);
          this.service.common.progressLoader = false;
        } else {
          this.timeofreq = [];
          this.createBlanlGrid();
          this.service.common.progressLoader = false;
        }
      });
    } catch (error) {

    }
  }
  /* Name:srinivas
        use: returns new appointment. */
  getNewAppointments(allData, startDate = null) {
   // //console.log(allData)
    this.gridArr = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        ////console.log('11111');
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
        obj.dateFormat ='dd-MM-yyyy';
        obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
        obj.key = obj.currentDate;
      } else {
        if (allData.length > 0) {
          allData.forEach(element => {
            //dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MM-DD-YYYY');
          //   //console.log(element);
            if (element.dateFormat == 'dd-MM-yyyy') {
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');

            } else if (element.dateFormat == 'MM-dd-yyyy') {
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MM-DD-YYYY');

            } else {
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');
            }
            if (dateToCheck <= element.toDate && dateToCheck >= element.fromDate) {
              obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
              obj.available = true;
              obj.event = true;
              obj.eventData.push(element);
              obj.highlight = 'leavecolor';
              obj.value = i - initialEmptyCells + 1;
              obj.date = dateToCheck;
              obj.dateFormat = element.dateFormat;
            } else {
              obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
              obj.available = true;
              obj.value = i - initialEmptyCells + 1;
              obj.date = dateToCheck;
              obj.dateFormat = element.dateFormat;

            }
          });
        } else {
          obj.date = dateToCheck;
          obj.available = true;
          obj.value = i - initialEmptyCells + 1;
          obj.dateFormat ='dd-MM-yyyy';
          obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
          obj.key = obj.currentDate;
        }
      }
      this.gridArr.push(obj);
    }
    const todaysDate = allData.filter((element) => {
      const currenSelectedDate = this.currentSelectedDate.split('-')[1] + '-' + this.currentSelectedDate.split('-')[2] + '-' + this.currentSelectedDate.split('-')[0];
      if (currenSelectedDate >= element.fromDate && currenSelectedDate <= element.toDate) {
        return element;
      }

    });
    this.timeofreq = todaysDate;
    this.gridArrNew = this.gridArr;

    this.service.common.progressLoader = false;

  }
  /* Name:srinivas
        use: return selected day. */
  selectDay(day: any) {

    let momentObj;
    let momentString;
    if (day.dateFormat == 'dd-MM-yyyy') {
      momentObj = moment(day.date, 'DD-MM-YYYY');
    } else if (day.dateFormat == 'MM-dd-yyyy') {
      momentObj = moment(day.date, 'MM-DD-YYYY');
    } else {
      momentObj = moment(day.date, 'YYYY-MM-DD');
    }
    momentString = momentObj.format('DD MMM'); // 2016-07-15

    this.currentSelectedDate = momentObj.format('YYYY-MM-DD');
    this.selectDate = day.value;
    this.selectedDate = momentString;
    this.timeofreq = day.eventData;
  }
  /* Name:srinivas
        use: return status. */
  getStatus(status) {
    this.gridArr = [];
    this.timeofreq = [];
    this.selectDate = moment().format('DD');
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    } else {
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    }
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
  }
  /* Name:srinivas
      use: open popUp. */
  openDialog(data = null): void {
    const dialogRef = this.dialog.open(AddLeaveComponent, {
      width: '350px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result !== 'close') {
      this.page = 0;
      this.getListDataByPage();
      this.today = moment(new Date()).format('MM-DD-YYYY');
      }
    });
  }
  /* Name:srinivas
        use: close popUp. */
  onCancel(code) {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '480px',
      data: { msg: 'Are you sure you want to cancel the leave request?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result != undefined) {
        try {

          this.service.common.progressLoader = true;
          this.service.cancelTimeOffBytimeOffCode(code, (res) => {
            if (res.responsecode == 200) {
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
              this.service.common.message = res.message;
              this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
            } else {
              this.service.common.progressLoader = false;
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          })
        } catch (error) {

        }
      }
    });
  }  
  
   /* Name:Vivek Chauhan
  use: for getting all Org Distribution Master data by org code */
  getListDataByPage(scroll = null) {
    if (!scroll) {
      this.service.common.progressLoader = true;
    }
    else if (scroll) {
      this.service.common.infiniteLoader = true;
    }
    try {
      this.service.getListDataByPagePending(this.service.session.get('userCode'), this.page, this.limit, (response) => {
        if(response.responsecode === 200){
          if (scroll) {
            let arrayData = this.dataSource.data;
            response.data.forEach(element => {
              arrayData.push(element)
            });
            setTimeout(() => {
              this.distributionData = arrayData;
              this.dataSource = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.infiniteLoader = false;
            }, 1000);

          } else {
            setTimeout(() => {
              this.distributionData = response.data;
              this.dataSource = new MatTableDataSource(this.distributionData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.progressLoader = false;
            }, 1000)
          }
        } else {
          if (!scroll) {
            this.distributionData = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 1000);      
          } else {
            this.service.common.infiniteLoader = false;
          }
          this.service.common.progressLoader = false;
        }
    });    
    } catch (error) {
      //console.log(error);
    }
}

/* Name:Vivek Chauhan
use: for infinite scroll */
onScroll() {
  this.page = this.page + 1;
  this.getListDataByPage('scroll');
}

/* Name:Vivek Chauhan
use: for seraching Distribution Data name */
  applyFilter(searchText: string) {
    // //console.log('applyFilter', searchText);
  // this.dataSource.filter = filterValue.trim().toLowerCase();
  
  this.page = 0;
    this.service.common.progressLoader = true;
  try {
    if(searchText && searchText.trim()){
    this.service.getListDataBySearchPending(this.service.session.get('orgCode'), this.leaveStatus, searchText, (response) => {
      if(response.responsecode === 200){
          setTimeout(() => {
            this.distributionData = response.data;
            this.dataSource = new MatTableDataSource(this.distributionData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }, 1000);
      } else {
        this.service.common.progressLoader = false;
          this.distributionData = [];
          setTimeout(() => {
            this.dataSource = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 1000);          
      }
  });
  } else {
    this.getListDataByPage();
  } 
  } catch (error) {
    //console.log(error);
  }
}


}
