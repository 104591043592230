import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/common.service';
import { TechnicianService } from '../technician.service';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-staff-calendar-view',
  templateUrl: './staff-calendar-view.component.html',
  styleUrls: ['./staff-calendar-view.component.scss'],
  providers :[DatePipe]
})


export class StaffCalendarViewComponent {
 
  @Input() staff_code : string | null= "" ;

    navDate: any;
    weekDaysHeaderArr: Array<string> = [];
    gridArr: Array<any> = [];
    // selectedDate: any;
    status = 'pending,assigned,accepted,inprogress,completed';
    page = 0;
    limit = 10;
   
    appointmentData: any = [];
    // currentDate = new Date();
    currentDate = moment().toDate();
    selectDate: any;
    showImg;
    default_startTime: string = '00:00:00';
    default_endTime: string = '23:59:59';
    is_Loading: boolean = false;
    startDate ; 
    endDate;
  
    constructor(
       public datepipe: DatePipe,
      public dialog: MatDialog,
      private _commonSVC : CommonService,
      private technicianService: TechnicianService,
      private sessionService : SessionStorageService
    ) { }
  
  
    ngOnInit()
     {
      this._commonSVC.getpercentData();
     // moment.locale(this.locale);
       this.navDate = moment();
       this.selectDate = moment().format('DD');
       // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
       const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
       let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
       let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
       this.startDate = moment(firstDay).format('YYYY-MM-DD');
       this.endDate = moment(lastDay).format('YYYY-MM-DD');
     
      this.makeHeader();
      this.createCalendar();
      this.getAppoinments_Counts();
      this.getAllAppoinmentData_ForStaff();
  
    }
  
    /* For creating week days*/
    makeHeader() {
      const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
      weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
    }
  
    /* Name:Shailesh
       use: for show and hide icon image*/
    showImage(element) {
      // ////console.log(element);
      // ////console.log(this.showPopup);
      if (this.showImg == element.appointmentsDurationCode) {
        this.showImg = '';
      } else {
        this.showImg = element.appointmentsDurationCode;
      }
      // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
    }
  
    /* Name:Shailesh
       use: for getting all jobs by org code with filter*/
    // getDataByClick(orgCode, status, startDt, endDt, patientCode, monthChange = null) {
    //   this.service.common.progressLoader = true;
    //   try {
    //     this.service.getAllJobsByOrganizationCodeWithFilter(orgCode, status, startDt, endDt, patientCode, (response) => {
    //       ////console.log(response);
    //       if (response.responsecode == 200) {
    //         const date = moment(this.startDate).format('MM');
    //         const datemonth = moment().format('MM');
    //         //////console.log(date,datemonth);
    //         // this.getNewAppointments(response.data);
    //         this.createCalendar();
  
    //         setTimeout(() => {
    //           let index;
    //           if (monthChange && date !== datemonth) {
    //             //////console.log(this.startDate);
    //             index = this.gridArr.findIndex((data) => data.currentDate == moment(this.startDate).format('DD MMM'));
    //           } else {
    //             index = this.gridArr.findIndex((data) => data.currentDate == moment(this.currentDate).format('DD MMM'));
    //           }
  
    //           if (index !== -1) {
    //             this.selectDay(this.gridArr[index]);
    //           }
    //         }, 100);
    //       } else {
    //         // this.getNewAppointments([]);
    //         this.createCalendar();
    //       }
    //     })
    //   } catch (error) {
    //     //console.log(error)
    //   }
  
    // }
  
    /* Name:Shailesh
       use: for getting new appointments*/
    // getNewAppointments(allData) {
  
      createCalendar() 
      {
      const firstDayDate = moment(this.navDate).startOf('month');
      const initialEmptyCells = firstDayDate.weekday();
      const lastDayDate = moment(this.navDate).endOf('month');
      const lastEmptyCells = 6 - lastDayDate.weekday();
      const daysInMonth = this.navDate.daysInMonth();
      const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
      for (let i = 0; i < arrayLength; i++) {
        let obj: any = {};
        //obj.eventData = [];
        // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));

        let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY');
        let date_formate_yyyy_mm_dd = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');
         obj.formatted_date = date_formate_yyyy_mm_dd;
         obj.date_obj = new Date(date_formate_yyyy_mm_dd);
  
        if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
          obj.value = 0;
          obj.available = false;
          obj.highlight = '';
          obj.date = dateToCheck;
         
        } 
        else
         {
        
          obj.date = dateToCheck;
          obj.available = true;
          obj.value = i - initialEmptyCells + 1;
  
          obj.highlight = '';
        }
        this.gridArr.push(obj);
      }
      
    }
  
    dateFromNum(num: number, referenceDate: any): any {
      let returnDate = moment(referenceDate);
      return returnDate.date(num);
    }
  
    /* Name:Shailesh
       use: to change month on clickl*/
    changeNavMonth(num: number) 
    {
      this.navDate.add(num, 'month');
      this.gridArr = [];
      this.appointmentData = [];
      // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
      const month = moment(this.navDate).toDate()
      let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
      this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
      let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
      // this.selectedDate = this.datepipe.transform(this.dateFromNum(num, this.navDate), 'dd MMMM');
      this.selectDate = +this.datepipe.transform(this.currentDate, 'dd');
     // this.getDataByClick(this.service.local.get('orgCode'), this.status, this.startDate, this.endDate, this.dialog_customer_data['patientCode'], 'monthChange');
     this.createCalendar();
     this.getAllAppoinmentData_ForStaff();
      this.getAppoinments_Counts();
  
    }
  
  resetValues()
  {
    this.gridArr = [];
    this.appointmentData = [];
    this.currentDate = new Date();
    this.startDate = '';
    this.endDate = '' ;
     this.selectDate = null;
  }
  
 
  
    selectDay(day: any) {
     
      this.selectDate = day.value;
      this.startDate = day.formatted_date ;
      this.endDate = day.formatted_date ;
  
      this.getAllAppoinmentData_ForStaff();
    }
  
    // close() {
    //   this.dialogRef.close();
    // }
  
  
  
    // RefreshAppoinmentsList(event:any)
    // {
    //    this.appointmentData =[]
    //     this.getAllAppoinmentData_ForCustomer();
    // }
    // this.startDate + ' ' + this.default_startTime,
    // this.endDate + ' ' + this.default_endTime, 

    getAllAppoinmentData_ForStaff() {
  
      this.appointmentData = [] ;
      this.is_Loading = true;

      this.technicianService.get_appoinment_details_for_staff(
        this.sessionService.get('orgCode'), 
        'all',
        this.startDate,
         this.endDate, 
         'all',
         this.staff_code
      ).subscribe((res: any) => {
      // console.log("get_appoinment_details_for_staff", res);
        this.is_Loading = false;
        if (res.responsecode == 200) {
           this.appointmentData = res.data;
        }
  
       })
  
    }
  
   
  
    getAppoinments_Counts()
    {
      this.technicianService.get_appoinment_counts_for_staff(
        this.sessionService.get('orgCode'),
        'all',
        this.startDate + ' ' + this.default_startTime, 
        this.endDate + ' ' + this.default_endTime,
        this.staff_code
      ).subscribe((res: any) => {
  
        // console.log("getAppoinments_Counts", res) ;

         if (res.responsecode == 200) {
  
           this.setCounts(res.data);
          
        }
  
       })
    }
    
  
    setCounts(data: any) {
      // let color = this.status === 'pending' ? 'reddark' : 'greendark';
      this.gridArr.forEach(parent_ele => {
  
        for (var i = 0; i < data.length; i++) {
          if ((parent_ele.formatted_date in data[i])) {
             
            const obj = JSON.parse( data[i][parent_ele.formatted_date] ) ;
            
            parent_ele.jobsCount = obj.count;
   
            if(obj.fullfillmentRatio !== null && obj.fullfillmentRatio !== '')
            {
              const Index = this._commonSVC.dashboardColorData.findIndex(data => data.percent == Math.round(obj.fullfillmentRatio));
             
              if (Index !== -1) {
                parent_ele.fullfillmentRatio = this._commonSVC.dashboardColorData[Index].class;
              } 
    
            }
           
            
             break;
  
          }
        }
  
      });
   
    }
  
    refreshData()
    {
      this.getAllAppoinmentData_ForStaff();
    }
  
  
  
  }


