import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
} from "@angular/core";
 
import { Subject, takeUntil } from "rxjs";
 import { EventColor } from "calendar-utils";
import * as moment from "moment";
import { ApiRootService } from "src/app/shared/api-root.service";
import { HttpClient } from "@angular/common/http";
import { SessionStorageService } from "angular-web-storage";
 import { CommonService } from "src/app/shared/common.service";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NewJobComponent } from "src/app/dashboards/providers-dashboard/appointment/new-job/new-job.component";
 import { CdkDragDrop } from "@angular/cdk/drag-drop";
 import { NewJobService } from "src/app/dashboards/providers-dashboard/appointment/new-job/new-job.service";
import { AddDistributionComponent } from "../distribution-list/add-distribution/add-distribution.component";
import { TranslateService } from "@ngx-translate/core";
import { DashboardService } from "src/app/core/services/dashboard-service.service";
 import { IBtnControlsPermission, codeInput, cust_st_jt_dts } from "src/app/shared/interface/core.interface";
import { ViewJobDetailsComponent } from "src/app/dashboards/providers-dashboard/appointment/view-job-details/view-job-details.component";
import { NotifiedListComponent } from 'src/app/components/new-job-card/notified-list/notified-list.component';
import { AssignJobComponent } from 'src/app/components/new-job-card/assign-job/assign-job.component';
import { NotifyJobComponent } from 'src/app/components/new-job-card/notify-job/notify-job.component';
import { ConfirmLogoutComponent } from "src/app/common/confirm-logout/confirm-logout.component";
import { NewJobCardService } from "../new-job-card/new-job-card.service";
import { IPagePermissionResponse } from "src/app/shared/interface/login.interface";

const colors: Record<string, EventColor> = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};
interface NewJobComponentInitialState {
  title: string;
  message: string;
  options: string;
}
@Component({
  selector: "app-WeeklyCalendar-component",
  templateUrl: "./WeeklyCalendar-component.html",
  styleUrls: ["./WeeklyCalendar-component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush, // Use OnPush strategy
})
export class WeeklyCalendarComponent implements OnInit , OnDestroy {
  currentWeekStart: string;
  currentWeekEnd: string;
  weekDays: any[] = [];
  weekDaysData: any[] = [];
  transformedData: any = {};
  eventsData: any[] = []; // Initialize as an empty array
  JobTypeData: any;
  yearMonthDateFormat = "YYYY-MM-DD";
  dataLoaded = false;
  distinctJobTypeNames: any;
  ddl_CustomerAddressList: any;
  CustomerAddressListGroup: any;
  customerAddressCode: any;
  distributionList: any = [];
  distributionList_Filter: any = [];
  selected_appoinmentProcedureCode: string;
  selected_appoinmentDurationCode: string;
  selected_appoinmentCode: string;
  selected_date: string;
  distributionSeachInput: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  selectedSite: any;
  today = moment();
  dialogRef: any;
  isShortcutModalOpen: boolean = false;
  protected selectedCustomerCode: string;
  protected selectedSiteCode: string;
  bsModalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-xl",
  };
  Object: any;
  isHidden = false;
  isCopiied = false;
  CopiedJob: any = null;
  CopiedJobIndex: any;
  isPasteActive = false;
  showPopover = false;
  private _currentPopover: any;
  @ViewChild("targetElement") targetElement: ElementRef;
  // Add variables to track the state of the menu
  menuStates: boolean[][] = [];
  selectedDistribution: any = [];
  selectedSkills: any = [];
  eligibleStaffsList: any = [];
  filtered_eligibleStaffsList: any = [];
  selectedStaff: any;

  staffSeachInput: FormControl = new FormControl();
  showSpinner: boolean = false;
  @ViewChild("notifypopup") notifypopup: TemplateRef<any>;
  img_serverPath: string = "";
  nonWorkingDaySettingData: any;
  hasPermission:IBtnControlsPermission ;

  // protected scheduledUsers: cust_st_jt_dts;
  protected scheduledUsers: codeInput;
  addReadOnlyOnLoad: boolean = false;
  pastDate = moment().toDate();
  @ViewChild("jobPaste_calendar") jobPaste_calendar : TemplateRef<any>;
  
  nonWorkingDays: any[] = [];
   officeDay: any[] = [];

   private clearObs = new Subject<any>();

  constructor(
    private service: ApiRootService,
    private http: HttpClient,
    private _sessionStorageSVC: SessionStorageService,
    private _commonSVC: CommonService,
    public dialog: MatDialog,
    private modalService: BsModalService,
    private _newJobSVC: NewJobService,
    private translate: TranslateService,
    public dashboarService: DashboardService,
    private _newCardSVC: NewJobCardService,
     
  ) {
    //this.initializeCalendar();
    this.translate.setDefaultLang(this._sessionStorageSVC.get("language"));

    this._commonSVC.getpercentData();
    this.getDistributionList();

    this.currentWeekStart =this.dateForCalanderView(this.today);
    this.currentWeekEnd = this.dateForCalanderView(this.today.clone().add(6, "days"));
    const startDatelocal = this.today.format(this.yearMonthDateFormat);
    const endDate = this.today
      .clone()
      .add(6, "days")
      .format(this.yearMonthDateFormat);
    const orgCode = this._sessionStorageSVC.get("orgCode");
    this.getAllJobSites(orgCode, startDatelocal, endDate).subscribe(
      (res: any) => {
        console.log("ddl_CustomerAddressList", res);
        if (res.responsecode == 200) {
          this.ddl_CustomerAddressList = res.data;

          //this.CustomerAddressListGroup = groupBy(res.data, 'customerName');
          this.CustomerAddressListGroup = this.groupData(res.data);
          console.log(
            "CustomerAddressListGroup",
            this.CustomerAddressListGroup
          );
          this.customerAddressCode = this.CustomerAddressListGroup['All'][0].customerAddressCode;
          this.selectedSite = this.CustomerAddressListGroup['All'][0];
          this._commonSVC.getSevenDaysByStartOfWeek((days: any) => {
            //this.gridArrNew = days;
            //this.getHeader();
            console.log("getSevenDaysByStartOfWeek", days);
            this.weekDays = days;
            this.updateCalendar(this.today);
          });
        } else {
          this.dataLoaded = true;
          this.CustomerAddressListGroup=[];
          const allOption = {
            customerName: 'All',
            customerCode: 'All',
            siteId: 'All',
            siteName: 'All',
            customerAddressCode: 'all',
            customerAddress: '',
            customerApartmentNo: '',
            customerCountry: '',
            customerState: '',
            customerCity: '',
            customerLatitude: '',
            customerLongitude: '',
            zipCode: '',
            location: '',
            siteContact: '',
            siteContactPhone: ''
          };
          this.CustomerAddressListGroup['All'] = [allOption];
          this.customerAddressCode = this.CustomerAddressListGroup['All'][0].customerAddressCode;
          this.selectedSite = this.CustomerAddressListGroup['All'][0];
          console.log(
            "CustomerAddressListGroup",
            this.CustomerAddressListGroup
          );
          this._commonSVC.getSevenDaysByStartOfWeek((days: any) => {
            //this.gridArrNew = days;
            //this.getHeader();
            console.log("getSevenDaysByStartOfWeek else", days);
            this.weekDays = days;
            this.currentWeekStart = this.dateForCalanderView(this.weekDays[0].dateObj);
            this.currentWeekEnd = this.dateForCalanderView(this.weekDays[this.weekDays.length - 1].dateObj);
            const transformedData: any = {};

            const settingData = this.nonWorkingDaySettingData;
         
              for (const item of this.weekDays) {
                for (const dateKey of Object.keys(item)) {
                  const events = item[dateKey];
                  const day = moment(dateKey).format("ddd");
                 
                    const jobTypeName = "No data";
                    if (!transformedData[jobTypeName]) {
                      transformedData[jobTypeName] = { datesData: {} };
                    }
                    if (!transformedData[jobTypeName].hasOwnProperty("datesData")) {
                      transformedData[jobTypeName].datesData = {};
                    }
        
                    
                    let isWorkingDay = true;
        
                    if (settingData?.length > 0) {
                      isWorkingDay = settingData.some(
                        (setting) =>
                          setting.settingName.toLowerCase() ==
                          `Working.Days.${day}`.toLowerCase()
                      );
                      isWorkingDay=!isWorkingDay

                    }
                    transformedData[jobTypeName].datesData[dateKey] = {
                      events: [],
                      isWorkingDay: isWorkingDay,
                    };
                  
                }
              }
            this.transformedData=transformedData;
          });
        }
      }
    );
  }
 

  dateForCalanderView(dateVal: any){
      const dateString = dateVal;

      // Create a new Date object from the provided date string
      const date = new Date(dateString);

      // Define an array to map month numbers to month names
      const monthNames = [
        "Jan", "Feb", "March",
        "April", "May", "June", "July",
        "Aug", "Sep", "Oct",
        "Nov", "Dec"
      ];

      // const monthNames = [
      //   "January", "February", "March",
      //   "April", "May", "June", "July",
      //   "August", "September", "October",
      //   "November", "December"
      // ];

      // Get day, month, and year from the Date object
      const day = date.getDate();
      const monthIndex = date.getMonth(); // Month index is 0-based
      const year = date.getFullYear();

      // Format the date in the desired format
      const formattedDate = `${day}-${monthNames[monthIndex]}-${year}`;
      return formattedDate;
  }

  groupData(data: any): { [key: string]: any } {
    const groupedData = data.reduce((groups, item) => {
      const key = item.customerName;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
      return groups;
    }, {});
    const allOption = {
      customerName: 'All',
      customerCode: 'All',
      siteId: 'All',
      siteName: 'All',
      customerAddressCode: 'all',
      customerAddress: '',
      customerApartmentNo: '',
      customerCountry: '',
      customerState: '',
      customerCity: '',
      customerLatitude: '',
      customerLongitude: '',
      zipCode: '',
      location: '',
      siteContact: '',
      siteContactPhone: ''
    };
    groupedData['All'] = [allOption];
    return groupedData;

  }
  /*  initializeCalendar() {
    const today = moment();
    const startOfWeek = today.clone().startOf('week');
    const endOfWeek = today.clone().endOf('week');
    this.currentWeekStart = startOfWeek.format('ddd D');
    this.currentWeekEnd = endOfWeek.format('ddd D');
   
  
    // Calculate and populate weekDays and dates for the current week
    for (let i = 0; i < 7; i++) {
      this.weekDays.push(startOfWeek.clone().add(i, 'days'));
      this.dates.push(startOfWeek.clone().add(i, 'days'));
    }
  } */

  /* getEventsForDate(day: string): any[] {
    if (this.eventsData && this.eventsData) {
      const eventsForDay = this.eventsData.find((item) => {
        const dateString = Object.keys(item)[0]; // Extract the date string from the object
        return dateString === day;
      });
  
      if (eventsForDay) {
        console.log("eventsForDay[day]",eventsForDay[day])
        return eventsForDay[day] || [];
      }
    }
    return [];
  } */
  getEventsForDate(day: string, jobTypeName: string): any[] {
    // console.log("getEventsForDate", day, jobTypeName);
    if (this.eventsData && this.eventsData.length > 0) {
      const eventsForDay = this.eventsData.find((item) => {
        const dateString = Object.keys(item)[0]; // Extract the date string from the object
        return dateString === day;
      });

      if (eventsForDay) {
        const events = eventsForDay[day] || [];

        // Filter events by jobTypeName
        const filteredEvents = events.filter((event) => {
          return event.jobParentResList.some((parentRes) => {
            return parentRes.jobTypeWithShiftList.some((jobType) => {
              if (jobType.jobTypeName === jobTypeName) {
                const Index = this._commonSVC.dashboardColorData.findIndex(
                  (data) => data.percent == Math.round(jobType.fullFilledRatio)
                );
                if (Index !== -1) {
                  jobType.BG_Color =
                    this._commonSVC.dashboardColorData[Index].class;
                } else {
                  jobType.BG_Color = "";
                }
                return true; // Return true to include this event
              }
              return false; // Return false to exclude this event
            });
          });
        });

        // Sort filteredEvents by startTime
        filteredEvents.sort((a, b) => {
          // Convert startTime strings to comparable values
          const timeA = this.getTimeValue(a.startTime);
          const timeB = this.getTimeValue(b.startTime);
          return timeA - timeB;
        });

        return filteredEvents;
      }
    }

    return [];
  }

  showAppointment(appointment: any) {
    console.log("showAppointment_Weekly: ", appointment);
  }

  // Helper function to convert time strings to comparable values
  getTimeValue(timeString: string): number {
    const time = timeString.toLowerCase().trim();
    const parts = time.match(/(\d+):(\d+)\s+(am|pm)/);

    if (parts && parts.length === 4) {
      let hours = parseInt(parts[1]);
      const minutes = parseInt(parts[2]);
      const period = parts[3];

      if (period === "pm" && hours !== 12) {
        hours += 12;
      } else if (period === "am" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    }

    return 0; // Return 0 for invalid time strings
  }
  getGridColumn(): string {
    // Span across 7 columns (1 to 7 in a 1-based index)
    return "1 / span 7";
  }
  previousWeek() {
    const previousWeekStartDate = moment(this.weekDays[0].date).subtract(
      7,
      "days"
    );
    this.weekDays = this._commonSVC.getPreviousSevenDays();
    this.updateCalendar(previousWeekStartDate);
  }

  nextWeek() {
    const nextWeekStartDate = moment(this.weekDays[0].date).add(7, "days");
    this.weekDays = this._commonSVC.getNextSevenDays();
    this.updateCalendar(nextWeekStartDate);
  }

  private updateCalendar(startDate: moment.Moment) {
    //this.weekDays = [];
    /* for (let i = 0; i < 7; i++) {
      const day = startDate.clone().add(i, "days");
      this.weekDays.push({
        date: day,
      });
    } */

    const startDatelocal = this.weekDays[0].yearMonthDateFormat; //startDate.format(this.yearMonthDateFormat);

    const endDate = this.weekDays[this.weekDays.length - 1].yearMonthDateFormat; //startDate.clone().add(6, "days");
    //const endDatelocal = endDate.format(this.yearMonthDateFormat);
    const orgCode = this._sessionStorageSVC.get("orgCode");

    // console.log("getJobsByPage",customerAddressCode);
    if(orgCode && startDatelocal && endDate && this.customerAddressCode){
      this.addReadOnlyOnLoad = true;
    this.getJobsWeeklyView(
      orgCode,
      startDatelocal,
      endDate,
      this.customerAddressCode
    ).subscribe((res: any) => {
      this.addReadOnlyOnLoad = false;
      // console.log("getJobsByPage", res);
      if (res.responsecode == 200) {
        this.dataLoaded = true;
        //  console.log("getJobsByPage", res.data);
        this.eventsData = res.data;
        // console.log("getJobsByPage 1", this.eventsData);
        // Call the function to transform the data
        this.transformedData = res && this.transformData(res);
        // console.log("transformedData", this.transformedData);
        /*  const fieldsToDistinct = ["jobTypeName"];
        // this.eventsData =this.sortJobTypeWithShiftList(res.data);
        this.distinctJobTypeNames = this.getDistinctJobTypeNames(
          res,
          fieldsToDistinct
        );

        console.log("distinctJobTypeNames", this.distinctJobTypeNames);
        
        // Loop through distinctJobTypeNames
        for (const jobType of this.distinctJobTypeNames) {
          const jobTypeName = jobType.jobTypeName;
          this.weekDaysData[jobTypeName] = {};

          // Loop through days
          for (let i = 0; i < 7; i++) {
           // const day = startDate.clone().add(i, "days");
           const day = startDate.clone().add(i, "days").format(this.yearMonthDateFormat);
           this.weekDaysData[jobTypeName][day] = { events: [] };

           // Replace this with your logic to get events for a specific day and job type
           const eventsForDayAndJobType = this.getEventsForDate(
             day,
             jobType.jobTypeName
           );
           console.log("eventsForDayAndJobType", eventsForDayAndJobType);
          // this.weekDaysData[jobTypeName] = { jobTypeIcon: jobTypeIcon };
           // Push events into the array
           this.weekDaysData[jobTypeName][day].events = eventsForDayAndJobType.map((event) => {
             // Modify the structure of each event
             return { [event.propertyName]: event.propertyValue };
           });
          } 
        
        
        }*/
      } else {
        this.addReadOnlyOnLoad = false;
        this.dataLoaded = true;
        for (let i = 0; i < 7; i++) {
          var day = startDate.clone().add(i, "days");
          var dayevents = [];
          var eventdataforday = {
            date: day,
            events: dayevents,
          };

          this.weekDaysData.push(eventdataforday);
        }
      }
    });
  }
    
    this.currentWeekStart = this.dateForCalanderView(this.weekDays[0].dateObj);
    this.currentWeekEnd = this.dateForCalanderView(this.weekDays[this.weekDays.length - 1].dateObj);
    // console.log("today", this.today);
    // console.log("weekDaysData", this.weekDaysData);
  }


  transformData(data: any) {
    const transformedData: any = {};

    const settingData = this.nonWorkingDaySettingData;
    for (const item of data.data) {
      for (const dateKey of Object.keys(item)) {
        //if (dateKey === 'events') continue; // Skip the 'events' key
        //console.log(`datakey--: ${dateKey}`);
        const events = item[dateKey];
        const day = moment(dateKey).format("ddd");

        let isWorkingDay = true;

        if (settingData && settingData?.length > 0) {
          isWorkingDay = settingData.some(
            (setting) =>
              setting.settingName.toLowerCase() ==
              `Working.Days.${day}`.toLowerCase()
          );
          isWorkingDay=!isWorkingDay

        }

       // console.log(`datakey--: ${dateKey}`, day);

        for (const event of events) {
          const jobParentResList = event.jobParentResList || [];

          for (const jobParentRes of jobParentResList) {
            const jobTypeWithShiftList =
              jobParentRes.jobTypeWithShiftList || [];

            for (const jobType of jobTypeWithShiftList) {
              const jobTypeName = jobType.jobTypeName;
              const Index = this._commonSVC.dashboardColorData.findIndex(
                (data) => data.percent == Math.round(jobType.fullFilledRatio)
              );
              if (Index !== -1) {
                jobType.BG_Color =
                  this._commonSVC.dashboardColorData[Index].class;
              } else {
                jobType.BG_Color = "";
              }
              jobType.appointmentsCode = jobParentRes.appointmentsCode;
              jobType.dynamicDate = jobParentRes.dateString;  // Set new [dynamicDate] Dynamic Key for API call as per Setting Date
              jobType.jobDateFixedFormat = jobParentRes.jobDateFixedFormat  // date fixed format frp api "YYYY-MM-DD"
              jobType.customerCode = event.customerCode;
              jobType.customerName = event.customerName;
              jobType.siteAddress = event.siteAddress;
              jobType.siteAddressCode = event.siteAddressCode;
              jobType.customerAddressCode = event.siteAddressCode;
              
              jobType.siteName = event.siteName;
              //jobType.specification =  event.specification;

              if (!transformedData[jobTypeName]) {
                transformedData[jobTypeName] = { datesData: {} };
              }

              if (!transformedData[jobTypeName].datesData[dateKey]) {
                transformedData[jobTypeName].datesData[dateKey] = {
                  events: [],
                };
              }
              //transformedData[jobTypeName].appointmentsCode = jobParentRes.appointmentsCode;
              transformedData[jobTypeName].datesData[dateKey].isWorkingDay =
                isWorkingDay;
              transformedData[jobTypeName].jobTypeIcon = jobType.jobTypeIcon;
              transformedData[jobTypeName].organizationBaseProcedureCode =
                jobType.organizationBaseProcedureCode;
              transformedData[jobTypeName].datesData[dateKey].events.push(
                jobType
              );
            }
          }
        }
      }
    }

    // Add missing dates with empty events arrays
    const allDates = this.getAllDatesInRange(data.data);

    for (const jobTypeName of Object.keys(transformedData)) {
      if (!transformedData[jobTypeName].hasOwnProperty("datesData")) {
        transformedData[jobTypeName].datesData = {};
      }

      allDates.forEach((dateKey) => {
        if (!transformedData[jobTypeName].datesData.hasOwnProperty(dateKey)) {
          const day = moment(dateKey).format("ddd");

          let isWorkingDay = true;
          if (settingData && settingData?.length > 0) {
            isWorkingDay = settingData.some(
              (setting) =>
                setting.settingName.toLowerCase() ==
                `Working.Days.${day}`.toLowerCase()
            );
            isWorkingDay=!isWorkingDay

          }
          transformedData[jobTypeName].datesData[dateKey] = {
            events: [],
            isWorkingDay: isWorkingDay,
          };
        }
      });
    }
    if (Object.keys(transformedData).length == 0) {
      for (const item of data.data) {
        for (const dateKey of Object.keys(item)) {
          const events = item[dateKey];
          const day = moment(dateKey).format("ddd");
         
            const jobTypeName = "No data";
            if (!transformedData[jobTypeName]) {
              transformedData[jobTypeName] = { datesData: {} };
            }
            if (!transformedData[jobTypeName].hasOwnProperty("datesData")) {
              transformedData[jobTypeName].datesData = {};
            }

            
            let isWorkingDay = true;

            if (settingData?.length > 0) {
              isWorkingDay = settingData.some(
                (setting) =>
                  setting.settingName.toLowerCase() ==
                  `Working.Days.${day}`.toLowerCase()
              );
              isWorkingDay=!isWorkingDay

            }
            transformedData[jobTypeName].datesData[dateKey] = {
              events: [],
              isWorkingDay: isWorkingDay,
            };
          
        }
      }
    } else {
      console.log("transformedData keys are there");
    }
    return transformedData;
  }

  // Helper function to get all unique dates from the data
  getAllDatesInRange(data: any[]): string[] {
    const allDates: string[] = [];

    for (const item of data) {
      for (const dateKey of Object.keys(item)) {
        allDates.push(dateKey);
      }
    }

    return [...new Set(allDates)];
  }

  ngOnInit() {
    //this.getAllShiftMaster();
    this.img_serverPath = this.service.getSuperAdmin() + "/downloadFile/";
    //this._commonSVC.getnonWorkingDayslist();
    this._commonSVC.nonWorkingDayData$.subscribe((data) => {
      if (data) {
        this.nonWorkingDaySettingData = data;
        console.log("this.nonWorkingDaySettingData", this.nonWorkingDaySettingData)
        // You can now use this.nonWorkingDaySettingData in your component
      }
    });

    this.hasPermission =  this._commonSVC.getBtnEventPermissions("Job Scheduling");

    this.getworkingDays()
    this.getSpecificNonWorkingDays();
    this.subToPermissionChanged();
  }

  getJobsWeeklyView(
    org_code: string,
    startDT: any,
    endDT: any,
    customerAddressCode: any
  ) {
    return this.http.get(
      this.service.getHealthCareUrl() +
        `/getJobList/${org_code}/${startDT}/${endDT}/${customerAddressCode}`
    ); //?page=${page}&limit=${limit}`)
  }
  getAllJobSites(org_code: string, startDT: any, endDT: any) {
    return this.http.get(
      this.service.getHealthCareUrl() +
        `/getAllJobSites/${org_code}/${startDT}/${endDT}`
    ); //?page=${page}&limit=${limit}`)
  }
  // Function to get distinct jobTypeName values
  getDistinctJobTypeNames(data, fields) {
    const distinctValues = new Map();

    data.data.forEach((dateObject) => {
      const date = Object.keys(dateObject)[0]; // Get the date string
      const events = dateObject[date]; // Get the events array

      events.forEach((event) => {
        event.jobParentResList.forEach((parentRes) => {
          parentRes.jobTypeWithShiftList.forEach((jobType) => {
            const key = fields.map((field) => jobType[field]).join("-");
            if (!distinctValues.has(key)) {
              distinctValues.set(key, { ...jobType }); // Create a copy of the jobType object
            }
          });
        });
      });
    });

    return Array.from(distinctValues.values());
  }
  onJobSiteChange(event) {
    //console.log("onJobSiteChange", event.value);
    this.customerAddressCode = event.value.customerAddressCode;
    this.selectedSite = event.value;
    const StartDate = moment(this.weekDays[0].date);

    this.selectedCustomerCode = event.value.customerCode;
    this.selectedSiteCode = event.value.customerAddressCode;

    this.updateCalendar(StartDate);
  }
  getAllDistributionList(org_code: string) {
    return this.http.get(
      this.service.getHealthCareUrl() +
        "/getDistributionListByOrganizationCodeByPage/" +
        org_code +
        "?page=0&limit=1000"
    );
  }
  getDistributionList() {
    const orgCode = this._sessionStorageSVC.get("orgCode");
    this.getAllDistributionList(orgCode).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.distributionList = res.data;
        this.distributionList_Filter = this.distributionList;
        console.log("this.distributionList", this.distributionList);
        this.distributionSeachInput.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDistribution();
          });
      }
    });
  }
  protected filterDistribution() {
    if (this.distributionList.length == 0) {
      return;
    }
    let search = this.distributionSeachInput.value.trim();
    this.distributionList_Filter = this.distributionList.filter((ele) =>
      ele.distributionName.toLowerCase().includes(search.toLowerCase())
    );
  }

  AddShift(PopUpTemplate_ref) {
    this.dialogRef = this.dialog.open(PopUpTemplate_ref, {
      width: "30%",
      disableClose: true,
    });
    // this.dialogRef = this.dialog.open(AddShiftComponent, {
    //   width: "50%",
    //   disableClose: true,
    // });
  }

  AddJob(selectDate: any, organizationBaseProcedureCode: any)
   {
    // this.selectedCustomerCode = "";//eventdata.customerCode;
    // this.selectedSiteCode = "";//eventdata.customerAddressCode;
    // const initialState = { message: 'popup message', title:'popup title'};
    const initialState = {
      selectedCustomerCode: this.selectedCustomerCode,
      selectedSiteCode: this.selectedSiteCode,
      JobDate: selectDate,
      organizationBaseProcedureCode: organizationBaseProcedureCode,
      isOpenFrom: 'WeeklyCalendar_View',
    };

    this.bsModalRef = this.modalService.show(NewJobComponent, this.config);
    this.bsModalRef.content.AddJobmodalData = initialState;
    this.bsModalRef.content.closeBtnName = "Close";
    //this.cd.detectChanges();
    this.bsModalRef.content.event.subscribe((res) => {
      //this.resetCopyPaste();
      this.updateCalendar(this.today);
    });
    //this.bsModalRef = this.modalService.show(NewJobComponent, Object.assign({}, this.config, { class: 'modal-lg', initialState }));
    // this.bsModalRef.content.closeBtnName = 'Close';
    //this.bsModalRef.content.event.subscribe(res => {
    //this.itemList.push(res.data);
    // });
    /* this.dialogRef = this.dialog.open(NewJobComponent, {
      width: "80%",
      disableClose: false,
    });
    this.dialogRef.componentInstance.modalData =initialState; */
  }

  onOption(msg: string) {
    alert(msg);
  }
  title = "button-toggle-app";

  selectedValue: String[] = ["First"];

  toggleOptions: Array<String> = ["First", "Second"];

  selectionChanged(item) {
    console.log("Selected value: " + item.value);

    this.selectedValue.forEach((i) => console.log(`Included Item: ${i}`));
  }
  sortJobTypeWithShiftList(data) {
    data.forEach((dateObject) => {
      const date = Object.keys(dateObject)[0];
      const events = dateObject[date];

      events.forEach((event) => {
        event.jobParentResList.forEach((parentRes) => {
          parentRes.jobTypeWithShiftList.sort((a, b) => {
            // Parse the startTime strings to create timestamps for comparison
            const timeA = new Date("1970-01-01 " + a.startTime).getTime();
            const timeB = new Date("1970-01-01 " + b.startTime).getTime();

            return timeA - timeB;
          });
        });
      });
    });
  }
  listA = ["Item 1", "Item 2", "Item 3"];
  listB: string[] = []; // Initialize List B as an empty array
  onItemDragged(event: CdkDragDrop<any[]>): void {
    // Handle drag event (if needed)
  }

  onItemDropped(event: CdkDragDrop<any[]>): void {
    // Call your API here
    /*  this.apiService.callYourApi(event).subscribe((response) => {
      // Handle the API response as needed
    }); */
    const droppedData = event.item.data;
    // For example:
    console.log("Item dropped:", droppedData);
    /* if (event.previousContainer === event.container) {
      // Item was reordered within the same list
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // Item was dropped into a different container (e.g., from distribution to shiftdetails)
      // Handle your logic here, such as updating data or making API calls
      // You can access the dropped data with event.item.data
      const droppedData = event.item.data;
      // For example:
      console.log('Item dropped:', droppedData);
    } */
  }
  onDrop(event: CdkDragDrop<string[]>, targetList: string): void {
    if (event.previousContainer !== event.container) {
      // The item was dragged from List A to List B
      const item = event.item.data;

      // Simulate an API action (replace with your actual API call)
      this.performApiAction(item);

      // Add the item to List B without removing it from List A
      this.listB.push(item);
    }
  }

  // Simulate an API action (replace with your actual API call)
  private performApiAction(item: string): void {
    // Replace this with your API logic
    console.log(`API action performed for ${item}`);
  }

  CopyJob(jobTypeWithShiftList: any) 
  {
    this.isCopiied = true;
    this.CopiedJob = jobTypeWithShiftList;
    
    //this.CopiedJobIndex=selectediddex;
    this.dialogRef = this.dialog.open(this.jobPaste_calendar, { width: '25%' });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.resetCopyPaste();
      });

  }

  resetCopyPaste()
  {
    // to reset copy/paste
    this.dialogRef.close();
    this.isCopiied = false;
    this.CopiedJob = null;
  }

  activepaste(selectday: any) {
    this.isPasteActive = true;
  }
  onMouseEnter() {
    // Set iscopied to true when the mouse enters the div
    //this.iscopied = true;
  }

  onMouseLeave() {
    // Set iscopied to false when the mouse leaves the div
    //this.iscopied = false;
  }


  JobPasteDateChange(event)
  {
     this.PasteJob(moment(event).format(this.yearMonthDateFormat));
  }


  PasteJob(selectDate: any) {
    let array: any = [];

    let temp: any = {
      // shiftMasterCode: this.CopiedJob.shiftMasterCode,breakList
      shiftMasterCode: null,
      startTime: this.CopiedJob.startTime,
      endTime: this.CopiedJob.endTime,
      equipments: this.CopiedJob.equipments,
      numberOfWorker: this.CopiedJob.required,
      organizationBaseProcedureCode: this.CopiedJob.organizationBaseProcedureCode,
      uniform: this.CopiedJob.uniform,
      ratePerHours : this.CopiedJob.ratePerHour,
      shiftBreakRequestList: this.CopiedJob.breakList && this.CopiedJob.breakList.length > 0 && this.CopiedJob.breakList.map((ele: any) => {
        return {
          "breakType": ele.breakType,
          "duration": ele.durationMin ? Number(ele.durationMin) :  null,
          "paidStatus": ele.paidStatus === 'Paid' ? true : false
        }
      }) || [],
    };

    array.push(temp);

    let jobDate = selectDate; //.date.format(this.yearMonthDateFormat);

   // let orderDate = selectDate; //.date.format(this.yearMonthDateFormat);

    let final_Data: any = 
    {
      customerAddressCode: this.CopiedJob.customerAddressCode,
      // customerAdminCode: this.CopiedJob.customerAdminCode,
      customerAdminCode: null, // passing null value when copy/paste job
      customerCode: this.CopiedJob.customerCode,
      endDate: jobDate,
      startDate: jobDate,
      jobTypeWithJobData: array,
      // orderDT: orderDate,
      orderDT: '',
      organizationCode: this._sessionStorageSVC.get("orgCode"),
      siteContact: this.CopiedJob.siteContact,
      siteContactPh: this.CopiedJob.siteContactPh,
      specification: this.CopiedJob.specification,
      jobId: "",

      isDateOfMonth: false,
      daysList: [],
      repeatType: "day",
      repeatValue: 1,
    };
    this._commonSVC.progressLoader = true;
    this._newJobSVC.createNewJob(final_Data).subscribe((res: any) => {
      this._commonSVC.progressLoader = false;

      if (res.responsecode == 200) {
        
        this.resetCopyPaste();
        this.updateCalendar(this.weekDays[0].date);
        this._commonSVC.successMessage = true;
        this._commonSVC.message = res.message;
      } else {
        this._commonSVC.errorMessage = true;
        this._commonSVC.message = res.message;
      }
    });
  }
  AddDistribution(): void {
    const dialogRef = this.dialog.open(AddDistributionComponent, {
      width: "500px",
      disableClose: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined && result === "success") {
        this.getDistributionList();
      }
    });
  }
  allowDrop(ev, eventdata) {
    console.log("allowDrop", ev, eventdata);
    ev.preventDefault();
  }

  drag(ev, distribution) {
    console.log("drag", ev, distribution);
    //ev.dataTransfer.setData("text", ev.target.id);
    const definition = JSON.stringify(distribution);
    ev.dataTransfer.setData("text", ev.target.id);
    ev.dataTransfer.setData("distribution", definition);
  }

  drop(ev, eventdata, date) {
    var data = ev.dataTransfer.getData("text");
    var distributiondata = JSON.parse(ev.dataTransfer.getData("distribution"));
    console.log("drop", ev, eventdata, data, distributiondata, date);
    // ev.preventDefault();
    //var data = ev.dataTransfer.getData("text");
    // ev.target.appendChild(document.getElementById(data));
    this.sendNotification(eventdata, distributiondata, date);
  }
  
  sendNotification(selectedAppointment, distributiondata, date) {
    let JSON: any = {
      appointmentsCode: selectedAppointment.appointmentsCode,
      appointmentsProceduresCode:
        selectedAppointment.appointmentsProceduresCode,
      appointmentsDurationCode: "",
      date: date,
      organizationCode: this.dashboarService.session.get("orgCode"),
      distributionCodeList: [distributiondata.distributionCode],
      userCodeList: [],
    };

    console.log(JSON);

    this.dashboarService.common.progressLoader = true;
    this.dashboarService.PublishJob(JSON).subscribe((res: any) => {
      this.dashboarService.common.progressLoader = false;
      if (res.responsecode == 200) {
        this.dashboarService.common.successMessage = true;
        this.dashboarService.common.message = res.message;
        this.updateCalendar(this.weekDays[0].date);
      } else {
        this.dashboarService.common.message = res.message;
        this.dashboarService.common.errorMessage = true;
      }
    });
  }

  EditJob(selectDate: any, shiftdetails: any) {
    //getJobsByAppointmentsProceduresCode

    const initialState = {
      appointmentsCode: shiftdetails.appointmentsCode,
      appointmentsProceduresCode: shiftdetails.appointmentsProceduresCode,
      jobDate: selectDate,
      isOpenFrom: 'WeeklyCalendar_View',
      // jobDate: selectDate.date, //
    };

    this.bsModalRef = this.modalService.show(NewJobComponent, this.config);
    this.bsModalRef.content.EditJobmodalData = initialState;
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.event.subscribe((res) => {
     // this.resetCopyPaste();
      this.updateCalendar(this.today);
    });

    /*   this._newJobSVC.createNewJob(final_Data).subscribe((res: any) => {
    }); */
  }
  togglePopover() {
    console.log("togglePopover");
    this.showPopover = !this.showPopover;
  }
  // Function to toggle the menu
  toggleMenu(i: number, j: number) {
    console.log(`Toggle menu for i=${i}, j=${j}`);
    if (this.menuStates[i] === undefined) {
      this.menuStates[i] = [];
    }
    this.menuStates[i][j] = !this.menuStates[i][j];
  }

  // Function to check if the menu is open
  isMenuOpen(i: number, j: number) {
    console.log("isMenuOpen");
    if (this.menuStates[i] === undefined) {
      return false;
    }
    return this.menuStates[i][j];
  }

  openNotifyPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    let popupData = {
      appoinmentCode: appoinmentCode,
      appoinmentProcedureCode: appoinmentProcedureCode,
      date: date
    }

    this.dialogRef = this.dialog.open(NotifyJobComponent, {
      disableClose: true,
      width: '500px',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('CloseNotifyPopupReq:', result);
      if (result != undefined && result === 'success') {
        this.updateCalendar(this.weekDays[0].date);
      }
    }); 
  }

  openAssignPopUp(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    let popupData = {
      appoinmentCode: appoinmentCode,
      appoinmentProcedureCode: appoinmentProcedureCode,
      date: date
    }

    this.dialogRef = this.dialog.open(AssignJobComponent, {
      disableClose: true,
      width: '700px',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('CloseAssignPopupReq:', result);
      if (result != undefined && result === 'success') {
        this.updateCalendar(this.weekDays[0].date);
      }
    });    
  }
    // Function to format time as "5P" without leading zeros
   public  formatTime(time) {
      const [hours, minutes, period] = time.split(/[:\s]/);

      // Remove leading zeros from hours
      const formattedHours = parseInt(hours, 10);
      const formattedMinuts = parseInt(minutes, 10);
      const newMinut = formattedMinuts ? (formattedMinuts > 9 ? ('.'+formattedMinuts) : ('.0'+formattedMinuts)) : ''
   // Display only 'P' for PM, nothing for AM
   const periodText = period.toUpperCase() === 'PM' ? 'p' : 'a';   
   return `${formattedHours}${newMinut}${periodText}`;
    }

    showScheduledUsers(element:any, templateRef:TemplateRef<ElementRef>)
    {
      // this.scheduledUsers = element;

      // this.scheduledUsers =   {
      //   customerName: element.customerName,
      //   date: element.jobDateFixedFormat,
      //   siteName : element.siteName,
      //   siteAddress : element.siteAddress ,
      //   appointmentsCode: element.appointmentsCode ,
      //   jobType_Data : element
  
      // }

      
      this.scheduledUsers  = 
      {
         date: element.jobDateFixedFormat,
         appointmentsCode: element.appointmentsCode ,
         appointmentsProceduresCode : element.appointmentsProceduresCode,
       
      }

      // this.scheduledUsers  = {
      //   customerName: element.customerName,
      //   date: element.jobDateFixedFormat,
      //   siteName : element.siteName,
      //   siteAddress : element.siteAddress ,
      //   appointmentsCode: element.appointmentsCode ,
      //   appointmentsProceduresCode : element.appointmentsProceduresCode,
      //   jobTypeName : element.jobTypeName,
      //   jobTypeIcon : element.jobTypeIcon,
      //   rateCard : element.showRateCard,  
      //   jobTypeColor : element.jobColor 
      // }
  
      
      this.dialogRef = this.dialog.open(templateRef, {width: "85%"});
      this.dialogRef.afterClosed().subscribe((result) => {
          //this.scheduledUsers = "";
      });
 
     }

ViewJob(selectDate: string, shiftdetails: any)
{
  const initialState = {
    appointmentsCode: shiftdetails.appointmentsCode,
    appointmentsProceduresCode: shiftdetails.appointmentsProceduresCode,
    jobDate: selectDate,
    isOpenFrom: 'WeeklyCalendar_View'
    // jobDate: selectDate.date, //
  };

  this.bsModalRef = this.modalService.show(ViewJobDetailsComponent, this.config);
  this.bsModalRef.content.Job_ModalData = initialState;
  this.bsModalRef.content.closeBtnName = "Close";
  // this.bsModalRef.content.event.subscribe((res) => {
  //   console.log("closed");
  //   this.updateCalendar(this.today);
  // });
}

showActions(date:string)
{
  //return  moment() > moment(date) ;
    return moment().format(this.yearMonthDateFormat) <= moment(date).format(this.yearMonthDateFormat)
}

getNotifyStaffList(status: any, appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
  const dialogRef = this.dialog.open(NotifiedListComponent, {
    width: '50%',
    disableClose: false,
    data: {status: status, appoinmentCode: appoinmentCode, appoinmentProcedureCode: appoinmentProcedureCode, date: date}
  }); 
}

refreshData(event)
{
  // this.dialogRef.close();
  this.updateCalendar(this.today);
}

deleteJob(selectDate: any, selected_job: any) 
{
   //this.resetCopyPaste();

  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
    width: '400px',
    disableClose: true,
    data: { msg: 'Are you sure want to delete this shift?' }
  });

  dialogRef.afterClosed().subscribe(result => 
    {
    if(result && result.toLowerCase() === 'ok')
    {
      this._commonSVC.progressLoader = true;
      this._newCardSVC.deleteJob(selected_job.appointmentsCode, selectDate, selected_job.appointmentsProceduresCode, this._sessionStorageSVC.get("orgCode")).subscribe((res: any) => {
        this._commonSVC.progressLoader = false;
  
        if (res.responsecode == 200) 
        {
          this.updateCalendar(this.today);
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
        }
        else 
        {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }
      });
    }
  });


}


getSpecificNonWorkingDays() {
  this._commonSVC.getSettingBySettingName("Specific.Non.Working.Days", this._sessionStorageSVC.get("orgCode"), (response) => {
    // //console.log('getWebLogo', response);
    if (response.responsecode == 200) {
      if (response.settingValue) {
        let dates: [] = response.settingValue.split(",")
        dates.forEach((ele: any) => {
          if (ele) {
            this.nonWorkingDays.push(new Date(ele));
          }
        });

        //console.log("this.nonWorkingDays" , this.nonWorkingDays)

      }
    }
  })
}


getworkingDays() {
  this._commonSVC.getSettingNameByNameWithOperator("Working.Days.", this._sessionStorageSVC.get("orgCode"))
    .subscribe((response: any) => {

      if (response.responsecode == 200) {
        this.officeDay = response.data.map((setting) => {
          return { ...setting, day: this._commonSVC.getDayByDayName(setting.settingName.split(".")[2]) };
        });
      }

    })

}

nonWorkingHourFilter = (d: Date): boolean => {
  if (d) {
    const time = d.getTime();
    const day = d.getDay();
    const disableDay: any[] = this.officeDay.filter(x => x.settingValue === 'false');
    // return (!this.nonWorkingDays.find(x=>x.getTime()==time) || this.officeDay.find(x=> (x.settingValue === 'false' && day !== x.day)));
    //console.log('SaveEditedJob_2', !disableDay.find(x=>x.day==day) && !this.nonWorkingDays.find(x=>x.getTime()==time));
    return !disableDay.find(x => x.day == day) && !this.nonWorkingDays.find(x => x.getTime() == time);
  }

}


subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
      this.hasPermission =  this._commonSVC.getBtnEventPermissions("Job Scheduling");
     }
 })
}


ngOnDestroy(): void {
  this.clearObs.next(''),
  this.clearObs.complete();
}

}
