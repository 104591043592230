<div class="d-flex flex-row justify-content-between w-100 logo items-center">
  <div class="float-start header-name">{{popupTitle}}</div>
  <div class="ms-auto p-2">
    <div class="text-center d-flex">
      <button class="mb-2 me-2 nowrapTxt" mat-raised-button color="primary" (click)="!isLoading && onSubmit()"> {{popupTitle === 'Edit Skill' ? 'Update' : 'Save'}} <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>
      <br />
      <button mat-button (click)="onNoClick()">{{ 'skills.labels.Cancel' | translate }}</button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 col-sm-12"> 
    <div class="form-card">
    <form [formGroup]="skillsForm" (ngSubmit)="onSubmit()">
      <div class="w-100 col-md-12 col-sm-12 p-0">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Skills Name</mat-label>
          <input autoClose matInput formControlName="skillName" type="text"
            placeholder="Skills Name">
        </mat-form-field>
        <span class="help-block err"
          *ngIf="skillsForm.controls.skillName.errors?.required && (!submitted || skillsForm.controls.skillName.touched)">{{ 'skills.validators.Skill name is required' | translate }}</span>
      </div> 

      <div class="w-100 col-md-12 col-sm-12 p-0" style="margin-top: 30px;">
        <div class="form-label">
          <span>Certification</span>
        </div>
        <angular2-multiselect [data]="certificationData" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedCertificationItems" [settings]="dropdownSettings" formControlName="skillCertificationMappingRequestList">
          <c-badge>
            <ng-template let-item="item">
              <div class="col-md-12">
                <div class="row">
                  <div class="col" style="max-width: calc(100% - 108px);">
                    <div class="row">
                      <label title="{{item.itemName}}" style="margin: 0px; padding: 3px 10px 0px 5px; flex-wrap: nowrap; white-space: nowrap;">{{item.itemName}}</label>
                    </div>
                  </div>
                  <div (click)="$event.stopPropagation();" class="col">
                    <div class="row justify-content-end">
                      <mat-checkbox class="requiredCheckbox" (click)="$event.stopPropagation();" (change)="isRequired($event, item)" [checked]='item.isRequired'>Required</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </c-badge>
        </angular2-multiselect>
      <!-- <span class="help-block err"
        *ngIf="!skillsForm.get('skillCertificationMappingRequestList').valid && (!submitted || skillsForm.get('skillCertificationMappingRequestList').touched)">Certification is required</span> -->
    </div>

    <div class="w-100 col-md-12 col-sm-12 p-0" style="margin-top: 30px;">
      <div class="form-label">
        <span>License</span>
      </div>
      <angular2-multiselect appScrollAdjustment [data]="licenceData" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedLicenceItems" [settings]="dropdownSettingsLicence" formControlName="skillLicenceMappingRequestList">
        <c-badge>
          <ng-template let-item="item">
            <div class="col-md-12">
              <div class="row">
                <div class="col" style="max-width: calc(100% - 108px);">
                  <div class="row">
                    <label title="{{item.itemName}}" style="margin: 0px; padding: 3px 10px 0px 5px; flex-wrap: nowrap; white-space: nowrap;">{{item.itemName}}</label>
                  </div>
                </div>
                <div (click)="$event.stopPropagation();" class="col">
                  <div class="row justify-content-end">
                    <mat-checkbox class="requiredCheckbox" (click)="$event.stopPropagation();" (change)="isRequired($event, item)" [checked]='item.isRequired'>Required</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </c-badge>
      </angular2-multiselect>
    <!-- <span class="help-block err"
      *ngIf="!skillsForm.get('skillLicenceMappingRequestList').valid && (!submitted || skillsForm.get('skillLicenceMappingRequestList').touched)">Licence is required</span> -->
  </div>
            
    </form>
    </div>
    <!-- <div class="d-flex offset-md-8 justify-content-end">
      <button mat-button (click)="onNoClick()" class="cancel-btn-primary">{{ 'skills.labels.Cancel' | translate }}</button>
      <button type="submit" class="ms-2 pe-4 ps-4" mat-raised-button color="primary" (click)="onSubmit()">{{popupTitle === 'Edit Skill' ? 'Update' : 'Save'}}</button>
    </div> -->
  </div>
</div>

