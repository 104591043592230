<div class="w-100 p-0 m-0">

    <div class="row">
      <div class="col-md-6">
          <label class="header-name">Invoice Report </label>
      </div>

      <div class="col-6 text-end" *ngIf="dataSource.data.length !== 0">
        <button type="button" class="me-3 mt-0" mat-raised-button color="primary" (click)="export()">Excel</button>
        <button type="button" class="mt-0" mat-raised-button color="accent" (click)="printInvoiceReport()">  Print </button>
    </div>
  </div>
  
  <form [formGroup]="invoiceReportInputForm">
    <div class="row mt-3">
      <div class="col-3">
          <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
              <mat-label>Start Date</mat-label>
              <input (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder=""
                 formControlName="startDate" (dateChange)="onChangeDate($event)">
                  
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
      </div>
  
      <div class="col-3">
          <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
              <mat-label>End Date</mat-label>
              <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder=""
              formControlName="endDate" (dateChange)="onChangeDate($event)">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
      </div>

      <div class="col-3">
         <!-- <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
            <mat-label>Customer Name</mat-label>
            <input  matInput formControlName="customerName">
          </mat-form-field> -->
          
         <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Customer Name</mat-label>
              <input matInput [matAutocomplete]="customer_autocomplete"
              (input)="on_customer_name_inputs($event.target.value)"
              (keyup)="searchCustomer($event.target.value)">
               </mat-form-field>
  
               <mat-autocomplete #customer_autocomplete>
              <mat-option  *ngFor="let cust of all_customer_list_filtered " [value]="cust.customerName" (click)="on_customer_name_inputs(cust.customerName)">
                <span>{{ cust.customerName }} </span>
              </mat-option>
            </mat-autocomplete>
           

      </div>
 
      <div class="col-3">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Invoice Status</mat-label>
          <!-- (selectionChange)="onChange($event) -->
          <mat-select formControlName="invoiceStatus" (selectionChange)="onChangeInvoiceStatus($event)">
             <mat-option value="All">All</mat-option>
             <mat-option value="Paid">Paid</mat-option>
             <mat-option value="Unpaid">Unpaid</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <!-- <div class="col-4 text-center">
        <button  type="button" mat-raised-button color="primary" >Search</button>
      </div> -->

    </div>
</form>
  </div>
  
 
  
    <div class="w-100">
      <div class="col-md-12">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="table-scroll-height" id="invoive-report-dataTable" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
       
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">
  
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.customerName}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="invoiceId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Id</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.invoiceId}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Date</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.invoiceDate}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="deadlinesPayment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Deadline</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.deadlinesPayment}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="taxAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax Amount</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.taxAmount}} </div>
              </div>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="adjustmentAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Adjustment Amount</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.adjustmentAmount}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Amount</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.finalTotalAmount}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="invoiceStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Status</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.invoiceStatus}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="paymentDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.paymentDate}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="paymentMode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.paymentMode}} </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="paymentReference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Reference</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.paymentReference}} </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="adjustmentReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Adjustment Reason</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.adjustmentReason}} </div>
              </div>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        </table>
    
        <div class="text-center p-5" *ngIf="dataSource.data.length === 0 && !isLoading">
            <span class="text-danger">No Records Found!</span>
        </div>

        <ng-template [ngIf]="dataSource.data.length === 0 && isLoading">
            <div class="div w-100 d-flex p-3 text-center" style="justify-content: center;">
                  <mat-spinner class="center_50_px_spinner"></mat-spinner>
                <p class="mt-3 mb-3  ms-5 text-center">Getting invoice reports ...</p>
            </div>
          </ng-template>
        
      </div>
    </div>
    </div>
  
  


    
<div id="invoice_report_tableData_forPrint" style="display: none;">
    <table style="font-size: 10px;" *ngIf="dataSource?.data.length > 0" class="position-relative">
        <thead>
            <tr>
                <td style="border-bottom: 1px solid;">Customer Name </td>
                <td style="border-bottom: 1px solid;">Invoice Id </td>
                <td style="border-bottom: 1px solid;"> Invoice Date </td>
                <td style="border-bottom: 1px solid;">Payment Deadline </td>
                <td style="border-bottom: 1px solid;">Tax Amount</td>
                <td style="border-bottom: 1px solid;">Adjustment Amount</td>
                <td style="border-bottom: 1px solid;">Total Amount </td>
                <td style="border-bottom: 1px solid;">Invoice Status</td>
                <td style="border-bottom: 1px solid;">Payment Date </td>
                <td style="border-bottom: 1px solid;">Payment Mode</td>
                <td style="border-bottom: 1px solid;">Payment Reference</td>
                <td style="border-bottom: 1px solid;">Adjustment Reason</td>
             </tr> 
         </thead>
        <tbody>
            <tr *ngFor="let element of dataSource.data">
                <td style="border-bottom: 1px solid;">{{element.customerName}} </td>
                <td style="border-bottom: 1px solid;">{{element.invoiceId}}</td>
                <td style="border-bottom: 1px solid;">{{element.invoiceDate}} </td>
                <td style="border-bottom: 1px solid;">{{element.deadlinesPayment}} </td>
                <td style="border-bottom: 1px solid;">{{element.taxAmount}} </td>
                <td style="border-bottom: 1px solid;">{{element.adjustmentAmount}}</td>
                <td style="border-bottom: 1px solid;">{{element.totalAmount}} </td>
                <td style="border-bottom: 1px solid;">{{element.invoiceStatus}} </td>
                <td style="border-bottom: 1px solid;">{{element.paymentDate}} </td>
                <td style="border-bottom: 1px solid;">{{element.paymentMode}} </td>
                <td style="border-bottom: 1px solid;">{{element.paymentReference}} </td>
                <td style="border-bottom: 1px solid;">{{element.adjustmentReason}} </td>
             </tr>
        </tbody>
    </table>
    </div>


 <iframe #iframe style="display:none;"></iframe>