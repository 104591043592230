<div class="row appoint-header mb-1 pt-4">
    <div class="col-lg-3 col-md-3 col-sm-12 search-tabview search">
        <input autocomplete="off" type="text" class="form-control" id="search-appointment"
            placeholder="{{ 'Jobs.labels.Search Customer' | translate }}" name="search-appointment"
            [(ngModel)]="searchTerm" (keyup)="search()">
        <mat-icon class="search_ic">search</mat-icon>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-1 align-items-center">
                <label for="pwd">{{ 'Jobs.labels.Date' | translate }}</label>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-12 date-tabview">
                <div class="form-group selected-date text-start">
                    <input [value]="date.value" (dateChange)="choosedDate($event)" class="form-control ps-2" matInput
                        #nonworkingday [matDatepicker]="picker1" (focus)="picker1.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 (closed)="nonworkingday.blur()"></mat-datepicker>
                    <div class="arrows">
                        <mat-icon (click)="arrowDateChange('left')">chevron_left</mat-icon>
                        <mat-icon (click)="arrowDateChange('right')">chevron_right</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5  col-sm-12 float-end">
        <ul class="swatches p-0">

            <li *ngIf="userType === 'Customer'">
                <div>
                    <span class="appointment-swatch pendingJob"></span> Pending Jobs
                </div>
            </li>

            <li *ngIf="userType === 'Customer'">
                <div>
                    <span class="appointment-swatch"></span> Assigned Jobs
                </div>
            </li>

            <li *ngIf="userType !== 'Customer'">
                <div>
                    <span class="appointment-swatch"></span> {{ 'Jobs.labels.Job' | translate }}
                </div>
            </li>
            <!-- <li *ngIf="userType !== 'Customer'">
                <div *ngIf="driveTime_settingValue === 'true'">
                    <span class="driving-swatch"></span> {{ 'Jobs.labels.Drive Time' | translate }}
                </div>
            </li> -->
            <li>
                <div class="assign-tabview mt-3">
                    <!-- <button *ngIf="userType !== 'Customer'" color="primary" mat-button mat-raised-button class="me-2" (click)="autoAssign()">{{
                        'Jobs.labels.Auto Assign' | translate }}
                    </button> -->
                    <button color="primary" class="me-2" mat-button mat-raised-button
                        routerLink="/dashboard/appointmentlist">List
                        View</button>

                    <ng-template [ngIf]="controlsPermissions.addPermission === true">
                        <button mat-raised-button
                            *ngIf="userType === 'Customer' || actionPermissions?.addPermission == true"
                            title="Create Job" routerLink="/dashboard/new-job-creation" color="primary"
                            (click)="addDistribution()">
                            Add
                        </button>
                    </ng-template>
                </div>
            </li>
        </ul>
    </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="service.common.infiniteLoader"></mat-progress-bar>
<div class="color-grid" *ngIf="statusCountList.length > 0">
    <div class="color-header">
        <div class="row">
            <div class="col-md-3 col-sm-12 align-self-center">
                <h4># {{ 'Jobs.labels.of Jobs' | translate }} <span
                        class="statusall">({{assignedStatusCount}}/{{allStatusCount}})</span> </h4>
            </div>
            <div class="col-md-9 col-sm-12 mb-3">
                <div class="row m-0 mb-3">
                    <div class="hours col p-0 text-center" *ngFor="let hr of statusCountList; let i = index">
                        {{hr.viewStartTime}}</div>
                </div>
                <div class="row m-0">
                    <div class="status-count col p-0 text-center cursor-pointer" [ngClass]="status.dataPercent"
                        *ngFor="let status of statusCountList; let i = index" (click)="openAppointmentCounts(status)">
                        <div class="statusCountData">
                            {{status.assignedCount}}/{{status.allCount}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="color-grid1" id="color-grid1" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
        <div class="worker-detail">
            <div class="row" *ngFor="let worker of workerAppointmentList; let wI = index">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="d-flex">
                        <div class="img">
                            <img *ngIf="worker.customerLogo"
                                [src]="service.service.getSuperAdmin() + '/downloadFile/' + worker.customerLogo" alt="">
                            <img *ngIf="!worker.customerLogo" src="assets/common_images/profile.jpg" alt="">
                        </div>
                        <div class="detail">
                            <p class="name text-truncate">{{worker.customerName}}</p>
                            <p class="email text-truncate">{{worker.siteName}}</p>
                        </div>
                        <!-- <div class="icons">
                            <mat-icon class="d-inline-block cursor-pointer" (click)="showRoute(worker)"
                                title="Show Route">where_to_vote</mat-icon>
                            <mat-icon class="d-inline-block cursor-pointer" title="Open chat"
                                (click)="showChat(worker)">chat</mat-icon>
                        </div> -->
                        <div class="clearfix"></div>
                    </div>
                    <div>
                        <hr class="hr-line">
                    </div>
                </div>

                <div class="col-lg-9 col-md-9 col-sm-12 m-0 mb-3">
                    <ng-container *ngFor="let pworkerList of worker?.jobParentResList; let wGt = index">
                        <div class="row m-0" style="border: 1px solid rgba(0,0,0,.12); margin-bottom: 15px;">
                            <!-- <div class="hours booked-hours col p-0" *ngFor="let hrs of statusCountList; let m = index">
                            <div class="row m-0">                                                               
                                <span *ngFor="let i of hrs.interval" class="col p-0">                                    
                                    <div [ngClass]="userType !== 'Customer' && driveTime_settingValue === 'true' && (i >= worker?.driveTime && i < worker?.startTime)?'bg-orange':''"
                                        [title]="(i >= worker?.driveTime && i < worker?.startTime)?'Distance: '+worker?.distanceText+' - Duration: '+worker?.durationText+'':(i >= worker?.startTime && i < worker?.endTime)?''+worker?.viewStartTime +' - '+ worker?.viewEndTime+'':''">
                                        <div [ngClass]="(i >= worker?.startTime && i < worker?.endTime && worker?.workerCode) ? 'bg-blue' : (i >= worker?.startTime && i < worker?.endTime && !worker?.workerCode)? 'bg-red' :''"
                                            [title]="(i >= worker?.startTime && i < worker?.endTime)?''+worker?.viewStartTime +' - '+ worker?.viewEndTime+'':(i >= worker?.driveTime && i < worker?.startTime)?'Distance: '+worker?.distanceText+' - Drive Time: '+worker?.durationText+'':''">
                                            <small *ngIf="worker"
                                                (click)="showAppointment(worker)">&nbsp;</small>
                                            <small *ngIf="!worker">&nbsp;</small>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div> -->
                            <!-- <span>ML: {{wI + 1}}</span> -->

                            <!-- <span>PL: {{wI + 10}}, {{wGt + 1}}</span> -->
                            <ng-container
                                *ngFor="let pworkerListArr of pworkerList?.jobTypeWithShiftList; let worIdx = index">
                                <!-- <span>CL: {{worIdx + 1}}, {{pworkerListArr?.scheduled}}, {{driveTime_settingValue}}</span> -->
                                <!-- <ng-container *ngFor="let jobDuration of pworkerListArr?.jobDurationDetailsList; let JobIdx = index"> -->
                                <ng-container *ngIf="pworkerListArr">
                                    <span class="pt-2">Job Type: {{pworkerListArr?.jobTypeName}}
                                        ({{pworkerListArr?.scheduled}}/{{pworkerListArr?.required}})</span>
                                    <ng-container *ngFor="let hrs of statusCountList; let m = index">
                                        <div *ngIf="hrs" class="hours booked-hours col p-0">
                                            <div class="row m-0">
                                                <ng-container
                                                    *ngFor="let interval_item of hrs.interval; let intIdx = index">
                                                    <span class="col p-0">
                                                        <!-- <span title="L: {{JobIdx + 1}}, {{m + 3}}, {{pworkerListArr?.scheduled}}, {{driveTime_settingValue}}">.</span> -->
                                                        <div style="z-index: 3;">&nbsp;

                                                            <div *ngIf="pworkerListArr?.scheduled === 0 || !pworkerListArr?.scheduled"
                                                                title="Job Start Time: {{ pworkerListArr?.startTime && convert12to24(pworkerListArr?.startTime)}}, Job End Time: {{pworkerListArr?.endTime && convert12to24(pworkerListArr?.endTime)}}"
                                                                [ngClass]="((interval_item >= convert12to24(pworkerListArr?.startTime)) && (interval_item < convert12to24(pworkerListArr?.endTime))) ? 'reddark':''"
                                                                style="z-index: 7; margin-top: -24px; cursor: pointer;"
                                                                (click)="openSingleAppointment(pworkerList, worIdx)">
                                                                &nbsp;
                                                            </div>

                                                            <div *ngIf="pworkerListArr?.scheduled > 0" title="Job Start Time: {{ pworkerListArr?.startTime && convert12to24(pworkerListArr?.startTime)}}, Job End Time: {{pworkerListArr?.endTime && convert12to24(pworkerListArr?.endTime)}}" [ngClass]="interval_item && pworkerListArr?.fullFilledRatio && pworkerListArr?.startTime && pworkerListArr?.endTime && (interval_item >= convert12to24(pworkerListArr?.startTime)) && (convert12to24(pworkerListArr?.endTime) >= interval_item) && addJobFullFilledRatioClass(pworkerListArr?.fullFilledRatio)"
                                                                style="z-index: 7; margin-top: -24px; cursor: pointer;"
                                                                (click)="openSingleAppointment(pworkerList, worIdx)">
                                                                &nbsp;
                                                            </div>

                                                        </div>
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- </ng-container> -->
                                </ng-container>
                            </ng-container>


                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
        <div class="appointment-message" *ngIf="showmsg">
            <h5 class="tab-view">{{message}}</h5>
        </div>
    </div>

    <!-- <div class="w-100" *ngIf="service.common.infiniteLoader">
        <div class="loadergif">
          <img src="assets/typint_dots_black.gif" alt="Loader">
          <p>Loading...</p>
        </div>
      </div> -->

</div>

<i class="material-icons filter-icon forJob" (click)="filterTech()" title="Filter">
    filter_list </i>