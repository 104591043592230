import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class SystemSettingsService {
 
    settings:any;

  constructor(public service: ApiRootService, public http: HttpClient) { }

   /* Name:Shailesh
      use: for getting all local setting with global setting by user code */
  getAllgetAllLocalSettingWithDefaultGlobalSettingByUserCode(code){
    return this.http.get(this.service.getHealthCareUrl()+'/getAllLocalSettingWithDefaultGlobalSettingByUserCode/'+code);
  }

   /* Name:Shailesh
      use: for updating local setting by user code */
  updateSettingByUserCode(code,data,callback){
    return this.http.put(this.service.getHealthCareUrl()+'/updateSettingByUserCode/'+code,data).subscribe((data)=>{
      callback(data);
    })
  }

    /* Name:Vivek Chauhan
    use: for updating single local setting by user org code */
    updateSingleSettingByOrgCode(organizationCode,data,callback){
      return this.http.put(this.service.getHealthCareUrl()+'/updateLocalSetting/'+organizationCode,data).subscribe((data)=>{
        callback(data);
      })
    }

  /* Name:Shailesh
      use: for getting demo data and auto assing appointment */
  createDemoDataAndAutoAssignAppointments(code, sourceDate, startDate, endDate, callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/createDemoDataAndAutoAssignAppointments/'+code + '/'+sourceDate+'/'+startDate+'/'+endDate).subscribe((data)=>{
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for resetting demo data by user code */
  resetDemoData(code, callback){
    return this.http.get(this.service.getHealthCareUrl()+'/resetDemoData/'+code).subscribe((data)=>{
      callback(data);
    })
  }
}
