<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="col-lg-4 col-md-4 header-name ps-0">{{ 'common.labels.Location' | translate }}</div>
  <div class="col-lg-3 col-md-3 col-sm-12 float-start pe-3 mt-3">

    <!-- <mat-form-field appearance="outline" floatLabel="always"  appearance="fill">
      <mat-label>{{ 'dashboard.labels.Filter by Patient/Technician name' | translate }}</mat-label>
      <input matInput placeholder="" matInput (keyup)="applyFilter($event.target.value)" autocomplete="false" />
    </mat-form-field> -->
    
    <mat-form-field appearance="outline" floatLabel="always"  class="filter-tech">
      <mat-label>{{ 'dashboard.labels.Filter by Patient/Technician name' | translate }}</mat-label>
      <input matInput id="searchFilter" (keyup)="applyFilter($event.target.value)" placeholder="Search" autocomplete="false">
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 float-end"> 
    <div class="w-100 pe-2">
      <!-- <label class="mb-1 col-lg-2 col-md-2 justify-content-center
        align-self-center">{{ 'common.labels.Date' | translate }}</label> -->
      <div class="col-lg-10 p-0 col-md-10 col-sm-12 d-inline-block">
        <div class="mb-0 w-100 selected-date text-start">
          <input [value]="date.value" (dateChange)="choosedDate($event)"
            class="form-control ps-2" matInput
            #nonworkingday [matDatepicker]="picker1" (focus)="picker1.open()">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 (closed)="nonworkingday.blur()"></mat-datepicker>
          <div class="arrows">
            <mat-icon (click)="arrowDateChange('left')">chevron_left</mat-icon>
            <mat-icon (click)="arrowDateChange('right')">chevron_right</mat-icon>
          </div>          
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="hasPermission.addPermission === true">
  <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    <button *ngIf="userType !== 'Customer'" mat-raised-button routerLink="/dashboard/new-job-creation" [queryParams]="{pre_route: '/dashboard/location'}" title="Create Job" color="primary">Add</button>
  </div>
  </ng-template>
</div>
<div class="w-100 map-location">

  <div class="col-lg-12 col-md-12 col-sm-12">
    <label>Time</label>
    <ng5-slider [(value)]="value" [options]="options"
      (valueChange)="valueChange($event)"></ng5-slider>
  </div>

  <div class="clearfix"></div>

  <!-- <button *ngIf="userType !== 'Customer' && !disabled" class="autoAssign" color="primary" mat-raised-button (click)="autoAssign()" [disabled]="disabled">Auto Assign</button> -->

  <div id="dvMap" class="content-card location-map mt-4" fxLayout="column"
    #mapDis></div>

  <div id="mapLegend">
  </div>
  
    
</div>
 
<!-- <h5>Selector</h5>
 <lib-e-chat [orgCode]="orgCode" [adminCode]="adminCode" [userName]="userName"
[userCode]="userCode" [background_bg]="bg_path" [apiUri]="apiUri"></lib-e-chat> -->




<ng-template #schdeuledStaff_popUp>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Scheduled Staff</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row">

    <ng-template [ngIf]="staffData">
        
      <div class="border-bottom" [style.border-color]="staffData.jobColor">
        <app-cust-short-job-type-dts [shjobTypeDtsInput]="{
                        date         : staffData?.jobDateFixedFormat ,
                        customerName : staffData.customerName,
                        siteName     : staffData.siteName,  
                        siteAddress  : staffData.siteAddress, 
                        jobTypeName  : staffData.jobTypeName,
                        jobTypeIcon  : staffData.jobTypeIcon,
                        rateCard     : staffData.showRateCard,  
                        jobTypeColor : staffData.jobColor    
                      }">
        </app-cust-short-job-type-dts>

        <div class="div-border mt-1 mb-1"></div>

        <app-shift-with-job-type
        [shiftWithJobTypeInput]="{
             jobTypeWithShiftList : staffData,
             customerName         : staffData.customerName ,
             siteName             : staffData.siteName ,
             siteAddress          : staffData.siteAddress ,
             appointmentsCode     : staffData.appointmentsCode,
             customerCode         : staffData.customerCode,
             siteAddressCode      : staffData.siteAddressCode,
             jobDateFixedFormat   : staffData.jobDateFixedFormat,
             date                 : staffData.jobDateFixedFormat
        }"

       [allowScheduledStaff_Icon]="true"
        [allowActions]="allowActions" 
        [allowBreaks]="false"
        (updateParent)="handleRefreshEvent($event)">
    </app-shift-with-job-type>
    </div>
 
       </ng-template>

  </div>
</ng-template>
 
 