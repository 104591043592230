import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationService {
  customerData: {
    'customerCode': '',
    'customerName': '',
    'organizationCode': '',
    'homePhone': '',
    'mobilePhone': '',
    'workPhone': '',
    'mCountryCode': '',
    'customerEmail': '',
    'customerAddressResponseList': []
  }

  constructor(
    public service: ApiRootService,
    public common: CommonService,
    public http: HttpClient,
    public local: SessionStorageService
  ) { }

    /* Name:Vivek Chauhan
 use: for getting Customer details by Customer code*/
 getCustomerDetailByCustomerCode(customerCode, callback) {
  return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByCustomerCode/' + customerCode).subscribe((data) => {
    callback(data);
  })
}

  /* Name:Vivek Chauhan
  use: for getting All Role Name*/
  getAllRoleName() {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllRoleName')
  }

  /* Name:Vivek Chauhan
  use: for Add/Update admin */
  saveOrUpdateCustomerAdmin(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateCustomerAdmin', formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vivek Chauhan
  use: for getting all admin list using customer code */
  getCustomerAdminDetailsByCustomerCode(customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getCustomerAdminDetailsByCustomerCode/' + customerCode).subscribe((data) => {
      callback(data);
    })
  }

  getAllCustomerDetails(orgCode:string,) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCode/' + orgCode);
  }

/* Name:Vivek Chauhan
  use: for Delete/Remove user/admin data from list*/
  softDeleteCustomerAdminDetailsByCustomerAdminCode(customerAdminCode, callback) {
    return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteCustomerAdminDetailsByCustomerAdminCode/' + customerAdminCode).subscribe((data) => {
      callback(data);
    })
  }

  getCustomerPageAccessMster() {
    return this.http.get(this.service.getHealthCareUrl() + '/getCustomerPageAccessMster');
  }

  updateCustomerPageAccess(customerAdminCode:string, payload:any) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateCustomerPageAccess/'+customerAdminCode, payload);
  }

  /* Name:Vivek Chauhan
  use: for send welcome email to single contact using user code */
  sendWelcomeEmailToCustomer(userCode:string) {
    return this.http.get(this.service.getRegistrationUrl() + '/sendWelcomeEmailToCustomer/'+ userCode)
  }

  /* Name:Vivek Chauhan
  use: for send welcome email to all contacts */
  sendWelcomeEmailToAllCustomer(customerCode:string, orgCode:string) {
    return this.http.get(this.service.getRegistrationUrl() + '/sendWelcomeEmailToAllCustomer/'+ customerCode + '/' + orgCode)
  } 
  
}
