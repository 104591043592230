import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailTemplateService } from '../email-template-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DistributionService } from 'src/app/components/distribution-list/distribution-service';
import { ProcedureService } from 'src/app/dashboards/providers-dashboard/procedure/procedure.service';
import { RoleService } from 'src/app/components/role/role.service';
import { debounce } from 'lodash';
import Quill from "quill";
import {Mention, MentionBlot} from "quill-mention";
Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });
import "quill-mention/autoregister";

@Component({
  selector: 'add-email-template',
  templateUrl: './add-email-template.component.html',
  styleUrls: ['./add-email-template.component.scss']
})
export class AddEmailTemplateComponent implements OnInit {
  @Input() isOpenForBulkEmail: boolean = false
  modules = {};
  @ViewChild('quill') quill;
  country = new FormControl(null);
  templateForm!: FormGroup;
  options = [];
  popupTitle: string = 'Add Template';
  submitted = true;
  dropdownSettings = {};
  dropdownSkillSettings = {};
  dropdownDistributionSettings = {};
  allStaffMemberList: any[] = [];
  filtered_StaffMemberList: any[] = [];
  allSkillsList = [];
  allDistributionsList = [];
  isSaveLoading: boolean = false;
  isSendLoading: boolean = false;
  isSendTemplate: boolean = false;
  allRoleMasters = [];
  selectedSkills: any = [];
  selectedUsers: any = [];
  selectedDistributions: any = [];
  selectedCompany: any;
  orgCode: any;
  allTemplateList = [];
  templateSeachInput: FormControl = new FormControl();
  templateList_Filter:any =[];
  predefineTextValue: any = null;
  keysForMatchIsExist: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEmailTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public emailTemplateService: EmailTemplateService,
    private translate: TranslateService,
    public distributionService: DistributionService,
    public skillsService: ProcedureService,
    public roleServices: RoleService,
  ) {    

    const atValues = [{
        id: 1,
        name: "Company Name",
        value: 'Company Name'
      },
      {
        id: 2,
        name: "User Name",
        value: 'User Name'
      }
    ];
    const hashValues = [
      { id: 3, value: "Fredrik Sundqvist 2" },
      { id: 4, value: "Patrik Sjölin 2" }
    ];
      this.modules = {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["{"],
          source: function(searchTerm, renderList, mentionChar) {
            let values;
    
            if (mentionChar === "{") {
              values = atValues;
            } else {
              values = hashValues;
            }
    
            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++)
                if ( ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))                
                  matches.push(values[i]);              
              renderList(matches, searchTerm);
            }
          },
          onSelect: function(item, insertItem) {
            item.value = item.value+'}'
            // Remove the  '@' or '{' character from the selected mention
            const editor = this.quill;
            // console.log('item: ', item);
            // console.log('insertItem: ', insertItem);
            const range = editor.getSelection();
            // console.log('range: ', range, range.index, (range.index - 1));
            const text = editor.getText(range.index - 1, 1);
            // console.log('text: ', text);

    
            if (text === '{') {
              // console.log('editor: ', editor.container.innerText);
              editor.deleteText(range.index - 1, 1); // Remove the '@' or '{' character
            }
    
            insertItem(item);  // Insert the selected mention
          },
        }
      }

    this.dropdownSettings = {
      singleSelection: false,
      text: "Select User",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "select_user_section",
      disabled: false,
      // badgeShowLimit: 5,
      maxHeight:197,
      autoPosition: true       
    };
    this.dropdownSkillSettings = {
      singleSelection: false,
      text: "Select Skill",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "select_user_section",
      // badgeShowLimit: 5,
      maxHeight:197,
      autoPosition: true,
      disabled: false,   
    };
    this.dropdownDistributionSettings = {
      singleSelection: false,
      text: "Select Distribution",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "select_user_section",
      // badgeShowLimit: 5,
      maxHeight:197,
      autoPosition: true,
      disabled: false,       
    };
    this.getAllStaffMemberByOrganizationCode();
    this.getAllRoleByOrgCodeByPage();
    this.getAllDistributionByOrgCodeByPage();
    this.filterUser = debounce(this.filterUser, 600);
    this.matchKeysIsExistOrNot = debounce(this.matchKeysIsExistOrNot, 500);
    }

  ngOnInit() {
    console.log('isOpenForBulkEmail: ', this.isOpenForBulkEmail);
    if(this.isOpenForBulkEmail){
      this.getAllEmailTemplateListByOrgCode();
    }
    this.orgCode = this.distributionService.storage.get('orgCode');
    this.templateForm = new FormGroup({
      emailSubject: new FormControl('', Validators.required),
      emailTemplateCode: new FormControl(''),
      emailTemplate: new FormControl(''),
      emailTemplateName: new FormControl('', Validators.required),
      distributionsCodeList: new FormControl([]),
      userCodeList: new FormControl([]),
      skillCodeList: new FormControl([])
    });

    // console.log('Model_data: ', this.data?.templateData);
    if(this.data && this.data?.templateData){          
      // this.getDistributionUserListBytemplateCode(this.data.templateCode);
      this.popupTitle = this.data?.isSend ? 'Send Template' : 'Edit Template';
      // this.templateReqForm.controls['templateName'].setValue(this.data.templateName);
      // this.templateReqForm.controls['templateCode'].setValue(this.data.templateCode);
      this.emailTemplateService.common.progressLoader = false;
      this.templateForm.controls['emailSubject'].setValue(this.data?.templateData?.emailSubject);
      this.templateForm.controls['emailTemplateCode'].setValue(this.data?.templateData?.emailTemplateCode);
      this.templateForm.controls['emailTemplate'].setValue(this.data?.templateData?.emailTemplate ? this.data.templateData.emailTemplate : '<p>Hello {User Name},</p><p><br></p><p></p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>,');
      this.templateForm.controls['emailTemplateName'].setValue(this.data?.templateData?.emailTemplateName);
      this.selectedUsers = [];
      if(this.data?.isSend){
        // this.templateForm.controls.emailSubject.disable();
        this.templateForm.controls.emailTemplateName.disable();            
      }
    } else {
      this.popupTitle = 'Add Template';
      this.templateForm.reset();
      this.emailTemplateService.common.progressLoader = false;
      this.templateForm.controls['emailTemplate'].setValue('<p>Hello {User Name},</p><p><br></p><p></p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>,');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.data && this.data?.templateData){ 
        this.quill.quillEditor.selection.root.innerHTML = this.data?.templateData?.emailTemplate ? this.data.templateData.emailTemplate : '<p>Hello {User Name},</p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>';
      } else {
        this.quill.quillEditor.selection.root.innerHTML = '<p>Hello {User Name},</p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>';
      }
      if(!this.data?.isSend && this.data?.templateData && this.data?.templateData?.predefineText){
        this.predefineTextValue = this.data.templateData.predefineText;
        this.matchKeysIsExistOrNot();
      } 
    }, 500);
   }


  /* Name:Vivek Chauhan
      use: for searching distribution name */
  private _filter(value, field): string[] {
    //////console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        this.templateForm.controls['templateCode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        this.templateForm.controls['templateCode'].setValue(value.value);
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  displayFn(option): string | undefined {
    return option ? option.name : undefined;
  }

  onItemSelect(item: any, controlName:any) {
    // console.log('onItemSelect', item, controlName);
    if(controlName === 'user' && this.selectedUsers && this.selectedUsers.length > 0){
      this.selectedSkills = [];
      this.selectedDistributions = [];
    }
    if(controlName === 'skill' && this.selectedSkills && this.selectedSkills.length > 0){
      this.selectedUsers = [];
      this.selectedDistributions = [];
    }
    if(controlName === 'distribution' && this.selectedDistributions && this.selectedDistributions.length > 0){
      this.selectedUsers = [];
      this.selectedSkills = [];
    }
  }
  OnItemDeSelect(item: any, controlName:any) {
    // console.log('OnItemDeSelect', item, controlName);
    if(controlName === 'user' && this.selectedUsers && this.selectedUsers.length > 0){
      this.selectedSkills = [];
      this.selectedDistributions = [];
    }
    if(controlName === 'skill' && this.selectedSkills && this.selectedSkills.length > 0){
      this.selectedUsers = [];
      this.selectedDistributions = [];
    }
    if(controlName === 'distribution' && this.selectedDistributions && this.selectedDistributions.length > 0){
      this.selectedUsers = [];
      this.selectedSkills = [];
    }
  }
  onSelectAll(items: any, controlName:any) {
    // console.log('onSelectAll', items, controlName);
    if(controlName === 'user' && this.selectedUsers && this.selectedUsers.length > 0){
      this.selectedSkills = [];
      this.allDistributionsList = [];
    }
    if(controlName === 'skill' && this.selectedSkills && this.selectedSkills.length > 0){
      this.selectedUsers = [];
      this.allDistributionsList = [];
    }
    if(controlName === 'distribution' && this.selectedDistributions && this.selectedDistributions.length > 0){
      this.selectedUsers = [];
      this.selectedSkills = [];
    }
  }
  onDeSelectAll(items: any, controlName:any) {
    // console.log('onDeSelectAll', items, controlName);
    if(controlName === 'user' && this.selectedUsers && this.selectedUsers.length > 0){
      this.selectedSkills = [];
      this.allDistributionsList = [];
    }
    if(controlName === 'skill' && this.selectedSkills && this.selectedSkills.length > 0){
      this.selectedUsers = [];
      this.allDistributionsList = [];
    }
    if(controlName === 'distribution' && this.selectedDistributions && this.selectedDistributions.length > 0){
      this.selectedUsers = [];
      this.selectedSkills = [];
    }
  }

  changedEditor(event) {
    // tslint:disable-next-line:no-console
    // console.log('changedEditor', event.html);
  }

  focus($event: any) {
    // tslint:disable-next-line:no-console
    // console.log('focus', $event)
    // this.focused = true
    // this.blured = false
  }

  blur($event: any) {
    // tslint:disable-next-line:no-console
    // console.log('blur', $event)
    // this.focused = false
    // this.blured = true
  }

  /* Name:Vivek Chauhan
     use: for adding/creating/saving templateReqForm in organization */
     onSubmit() {    
      // console.log('Editor: ', this.quill.quillEditor.selection.root.innerHTML);
      // console.log("this.templateForm", this.templateForm);
      // console.log('keysForMatchIsExist: ', this.keysForMatchIsExist, this.predefineTextValue);
      this.submitted = false;
      if(!this.keysForMatchIsExist && this.predefineTextValue){
        this.emailTemplateService.common.message = "Predefine Text values " + this.predefineTextValue + " not found in editor please check and fixed before send template.";
        this.emailTemplateService.common.errorMessage = true;
        return true;
      }
      if (this.templateForm.valid) {
      //   this.isSaveLoading = true;
        // let staticVal = this.templateForm.controls['emailTemplate'].value;
        // let dynamicValue = staticVal ? staticVal?.replace(/[^{\}]+(?=})/g, event?.value?.name) : 'User';
        // this.templateForm.controls['emailTemplate'].setValue('dasda');
        let saveReq = {
          emailSubject: this.templateForm.controls.emailSubject.value,
          emailTemplate: this.quill.quillEditor.selection.root.innerHTML && this.emailTemplateService.parseString(this.quill.quillEditor.selection.root.innerHTML),
          emailTemplateCode: this.templateForm.controls.emailTemplateCode.value,
          emailTemplateName: this.templateForm.controls.emailTemplateName.value,
          organizationCode: this.orgCode
      }        
      // console.log('Form Values: ', saveReq);
      // return;
      this.isSaveLoading = true;
      this.emailTemplateService.common.progressLoader = true;
        this.emailTemplateService.saveOrUpdateEmailTemplate(saveReq).subscribe((response: any) => {
          this.emailTemplateService.common.progressLoader = false;
          this.isSaveLoading = false;
          if (response.responsecode === 200) {
            this.emailTemplateService.common.successMessage = true;
            this.emailTemplateService.common.message = response.message;
            this.dialogRef.close('success');
          } else {
            this.emailTemplateService.common.errorMessage = true;
            this.emailTemplateService.common.message = response.message;
          }
        });
      }
    }

    matchKeysIsExistOrNot(){
      if(this.predefineTextValue && this.quill.quillEditor.selection.root.innerHTML){
        // console.log('EditorVal', this.predefineTextValue);
        const dataString = this.quill.quillEditor.selection.root.innerHTML;
        // console.log('dataString', dataString);      
        const keys = this.predefineTextValue && this.predefineTextValue.split(",").map(item => item.trim());
        // console.log('keys', keys);        
        const keysExist = (dataString && keys && keys.length>0) && keys.every(key => dataString.includes(key));
        this.keysForMatchIsExist = keysExist;
        // console.log('keysExist', this.keysForMatchIsExist); 
      }
    }

      /* Name:Vivek Chauhan
     use: for Send templateReqForm in organization */
     onSend() {   
      // console.log('Editor: ', this.quill.quillEditor.selection.root.innerHTML); 
      // console.log("this.templateForm", this.templateForm);
      this.submitted = false;
      if (this.templateForm.valid && ((this.selectedUsers?.length > 0) || (this.selectedSkills?.length > 0) || (this.selectedDistributions?.length > 0))) {
        let cloneSelectedDistributions = this.selectedDistributions && this.selectedDistributions?.length > 0 ? JSON.parse(JSON.stringify(this.selectedDistributions)) : [];
        let cloneSelectedSkills =  this.selectedSkills && this.selectedSkills?.length > 0 ? JSON.parse(JSON.stringify(this.selectedSkills)) : [];
        let cloneSelectedUsers = this.selectedUsers && this.selectedUsers?.length > 0 ? JSON.parse(JSON.stringify(this.selectedUsers)) : [];
        const roleArray = cloneSelectedSkills && cloneSelectedSkills?.length > 0 && cloneSelectedSkills.map(item => item.skillCode);
        const userArray = cloneSelectedUsers && cloneSelectedUsers?.length > 0 && cloneSelectedUsers.map(item => item.staffCode);
        const distributionArray = cloneSelectedDistributions && cloneSelectedDistributions?.length > 0 && cloneSelectedDistributions.map(item => item.distributionCode);
      //   this.isSendLoading = true;
        // let staticVal = this.templateForm.controls['emailTemplate'].value;
        // let dynamicValue = staticVal ? staticVal?.replace(/[^{\}]+(?=})/g, event?.value?.name) : 'User';
        // this.templateForm.controls['emailTemplate'].setValue('dasda');
        let saveReq = {
          distributionList: distributionArray && distributionArray.length > 0 ? distributionArray : [],
          emailSubject: this.templateForm.controls.emailSubject.value,
          emailTemplate: this.quill.quillEditor.selection.root.innerHTML && this.emailTemplateService.parseString(this.quill.quillEditor.selection.root.innerHTML),
          emailTemplateCode: this.isOpenForBulkEmail ? this.templateForm.controls.emailTemplateName.value.emailTemplateCode : this.templateForm.controls.emailTemplateCode.value,
          emailTemplateName: this.isOpenForBulkEmail ? this.templateForm.controls.emailTemplateName.value.emailTemplateName : this.templateForm.controls.emailTemplateName.value,
          organizationCode: this.orgCode,
          skillList: roleArray && roleArray.length > 0 ? roleArray : [],
          userList: userArray && userArray.length > 0 ? userArray : []
        } 
        // console.log('Form Values: ', saveReq);
        // return;
        this.isSendLoading = true;
        this.emailTemplateService.common.progressLoader = true;        
        this.emailTemplateService.sendAndSaveEmailTemplate(saveReq).subscribe((response: any) => {
          this.emailTemplateService.common.progressLoader = false;
          this.isSendLoading = false;
          if (response.responsecode === 200) {
            this.emailTemplateService.common.successMessage = true;
            this.emailTemplateService.common.message = response.message;
            if(this.isOpenForBulkEmail){
              this.quill.quillEditor.selection.root.innerHTML = '<p>Hello {User Name},</p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>';
              this.templateForm.reset();
            } else {
              this.dialogRef.close('success');
            }
          } else {
            this.emailTemplateService.common.errorMessage = true;
            this.emailTemplateService.common.message = response.message;
          }
        });
      }
    }


/* Name:Vivek Chauhan
  use: for getting All Staff Member list page*/
  getAllStaffMemberByOrganizationCode() {
    try {
        this.emailTemplateService.common.progressLoader = true;    
        this.distributionService.getAllStaffMemberByOrgCode(
          this.distributionService.storage.get('orgCode'), (response) => {
            this.emailTemplateService.common.progressLoader = false;
            if (response.responsecode === 200) {                
              // console.log('ResData: ', response.data);
              // response.data = response.data.filter((item, idx) => idx < 20);
              response?.data && response.data.length > 0 && response.data.forEach((element, idx) => { element.itemName = element.name; element.id = element.staffCode; });
              this.allStaffMemberList = response?.data;
              this.filtered_StaffMemberList = this.allStaffMemberList;
            } else {
              this.allStaffMemberList = [];
              this.filtered_StaffMemberList = [];
            }                   

            /* Name:Vivek Chauhan
            use: for getting distribution by id */

              if (this.data && this.data?.templateData) {                      
                // this.templateReqForm.controls['templateName'].setValue(this.data.templateName);
                // this.templateReqForm.controls['templateCode'].setValue(this.data.templateCode);                                  
              } 
          });
      } catch (error) {
          //console.log(error)
      }
    }

      
  getAllRoleByOrgCodeByPage(){
    this.emailTemplateService.common.progressLoader = false;
    this.skillsService.getAllSkillsV2(this.distributionService.storage.get('orgCode'), (response) => {
        this.emailTemplateService.common.progressLoader = false;
        if (response.responsecode === 200) {   
          // console.log('ResDataRole: ', response?.data);
          response?.data && response.data.length > 0 && response.data.forEach((element, idx) => { element.itemName = element.skillName; element.id = element.skillCode });       
          this.allSkillsList = response.data;
          // console.log('allSkillsList: ', this.allSkillsList);
        } else {
          this.allSkillsList = [];
        }
      });
  }

  getAllEmailTemplateListByOrgCode(){
    this.emailTemplateService.common.progressLoader = false;
    this.emailTemplateService.getAllEmailTemplateListByOrgCode(this.distributionService.storage.get('orgCode'), false).subscribe((response: any) => {
        this.emailTemplateService.common.progressLoader = false;
        if (response.responsecode === 200) {      
          this.allTemplateList = response.data;
          this.templateList_Filter = this.allTemplateList;
          // console.log('allTemplateList: ', this.allTemplateList);
        } else {
          this.allTemplateList = [];
        }
      });
  }

  templateSearch(event){
    // console.log('Event', event.target.value);
    if(event.target.value && event.target.value.trim()){
      this.filterTemplate(event.target.value);
    } else {
      this.getAllEmailTemplateListByOrgCode();
    }
  }

  protected filterTemplate(search) {
    if (this.allTemplateList.length == 0) {
      return;
    }  
    this.templateList_Filter = this.allTemplateList.filter((ele) =>
      ele.emailTemplateName.toLowerCase().includes(search.toLowerCase())
    );
  }

  
  changeTemplate(event: any){
    // console.log('changeTemplate: ', event.value.emailTemplate);
    // console.log('selectedTemplate: ', this.templateForm.controls.selectedTemplate.value.emailTemplate);
      if(event && event?.value?.emailTemplate){ 
        this.quill.quillEditor.selection.root.innerHTML = event.value.emailTemplate;
        this.templateForm.controls['emailSubject'].setValue(event.value.emailSubject);
      } else {
        this.quill.quillEditor.selection.root.innerHTML = '<p>Hello {User Name},</p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>';
      }
   }


  getAllDistributionByOrgCodeByPage(){
    this.emailTemplateService.common.progressLoader = false;
    this.distributionService.getListDataByPage(this.distributionService.storage.get('orgCode'), 0, 1000, (response) => {
        this.emailTemplateService.common.progressLoader = false;
        if (response.responsecode === 200) {   
          // console.log('ResDataRole: ', response?.data);
          response?.data && response.data.length > 0 && response.data.forEach((element, idx) => { element.itemName = element.distributionName; element.id = element.distributionCode });       
          this.allDistributionsList = response.data;
          // console.log('allDistributionsList: ', this.allDistributionsList);
        } else {
          this.allDistributionsList = [];
        }
      });
  }

 filterUser(event) {
    // console.log('Search: ', event.target.value);
    if(event.target.value && event.target.value.trim() && this.allStaffMemberList.length > 0) {          
      let search = event.target.value.trim();
      this.filtered_StaffMemberList = this.allStaffMemberList.filter(ele => ele.name.toLowerCase().includes(search.toLowerCase()));
    } else {
      this.filtered_StaffMemberList = this.allStaffMemberList;
    }        
  }

   /* Name:Vivek Chauhan
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.emailTemplateService.common.noteRequest = null;
    this.dialogRef.close('true');
  }

}



