import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, takeUntil } from 'rxjs';
import { AddShiftComponent } from 'src/app/dashboards/providers-dashboard/shift-segment/add-shift/add-shift.component';
import { AddUserComponent } from 'src/app/dashboards/providers-dashboard/user-registration/add-user/add-user.component';
import { CommonService } from 'src/app/shared/common.service';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { CheckOutMissedService } from '../check-out-missed-list/check-out-missed-list.service';
import { RequestNewJobService } from 'src/app/dashboards/customer-dashboard/cust-request-new-job/request-new-job.service';
import { ChatComponent } from 'src/app/dashboards/providers-dashboard/chat/chat.component';
import { Router } from '@angular/router';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
 

@Component({
  selector: 'app-short-cuts',
  templateUrl: './short-cuts.component.html',
  styleUrls: ['./short-cuts.component.scss']
})
export class ShortCutsComponent implements OnInit, OnDestroy
{
 
  @Input() tilesGrid : boolean = false;
  @Input() iconsGrid : boolean = false ;

  permissionFor_NewJobShortCut:IBtnControlsPermission;
  permissionFor_NewShiftShortCut:IBtnControlsPermission;
  permissionFor_CustControlShortCut:IBtnControlsPermission;
  permissionFor_ApproveLeaveControlShortCut:IBtnControlsPermission;
  permissionFor_MissedClockControlShortCut:IBtnControlsPermission;
  permissionFor_ChatControlShortCut:IBtnControlsPermission;

  dialogRef: any = null;
  protected cancelJob_activeTab : string = "Pending";
  tabAtciveFor: string = 'Requested';

  newJobRequest_Count: number;
  missedClockOut_Count : number;
  requestCancel_Count:number | string ;

  notifyEmmitter = new EventEmitter();
  private dashboard_webSocket$ = new Subject<void>();
  private clearObs = new Subject<any>();

  constructor( 
    public common: CommonService,
    public dialog: MatDialog,
    private _sessionSCV: SessionStorageService,
    private _checkOutMissedSVC: CheckOutMissedService,
    private _newJobReqSVC : RequestNewJobService,
    public router: Router,
  )
  {}

  ngOnInit(): void 
  {
  
    this.hasPermissions();
    this.getMissedClockOutCount();
    this.getGlobalCancelJobRequestCount(this._sessionSCV.get('orgCode'));
    this.getRequestedNewJobsCount();

    this.webSocket_Shortcuts();
    this.subToPermissionChanged();
  }

  dialogRefForAll:any ={
    newJob:null,
    newShift:null,
    newLocation:null,
    newContact:null,
    approveLeave:null,
    missedClockOut:null,
    requestedNewJob:null,
    requestCancel:null,
    chat:null
  }

  openShortcutModal(isShortcutModalOpen: boolean, openModalFor: string, templateRef: any) 
  {
    if (openModalFor === 'New Job' && !this.dialogRefForAll.newJob) 
      {
      this.dialogRefForAll.newJob = this.dialog.open(templateRef,
         {
           width: '80%',
           position: { right: '0px', top: '0px' },
          disableClose: true,
          height: '100%'
           
        });
        this.dialogRefForAll.newJob.afterClosed().subscribe(result => { this.dialogRefForAll.newJob = null; });
    }

    if (openModalFor === 'New Shift' && !this.dialogRefForAll.newShift) 
      {
      this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true });
      this.dialogRefForAll.newShift = this.dialogRef;
      this.dialogRef.afterClosed().subscribe(result => { this.dialogRefForAll.newShift = null; });
     }

    if (openModalFor === 'New Location' && !this.dialogRefForAll.newLocation)
       {

      this.dialogRefForAll.newLocation = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRefForAll.newLocation.afterClosed().subscribe(result => { this.dialogRefForAll.newLocation = null; });
       }

    if (openModalFor === 'New Contact' && !this.dialogRefForAll.newContact) 
      {
      this.dialogRef = this.dialog.open(AddUserComponent, { width: '60%', disableClose: true, data: { isShortcutModalOpen: isShortcutModalOpen, dataForAdd: true } });
      this.dialogRefForAll.newContact = this.dialogRef;
      this.dialogRef.afterClosed().subscribe(result => { this.dialogRefForAll.newContact = null; });
     }

    if (openModalFor === 'Chat' && !this.dialogRefForAll.chat) 
      {
        this.dialogRef = this.dialog.open(ChatComponent, {
          width: '66%',
          panelClass: 'chat-popup', 
        });

        this.dialogRefForAll.chat = this.dialogRef;
        this.dialogRef.afterClosed().subscribe(result => { this.dialogRefForAll.chat = null; });
     
      }

 
    if (openModalFor === 'Approve Leave' && !this.dialogRefForAll.approveLeave) 
      {
        this.dialogRefForAll.approveLeave  = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
        this.dialogRefForAll.approveLeave.afterClosed().subscribe(result => { this.dialogRefForAll.approveLeave = null; });
      }

    if (openModalFor === 'Missed Clock-Out' && !this.dialogRefForAll.missedClockOut)
       {
        this.dialogRefForAll.missedClockOut = this.dialog.open(templateRef, { width: '80%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
        this.dialogRefForAll.missedClockOut.afterClosed().subscribe(result => { this.dialogRefForAll.missedClockOut = null; });
      }

      if (openModalFor === 'RequestedNewJobs' && !this.dialogRefForAll.requestedNewJob) 
      {
        this.tabAtciveFor = 'Requested';
        this.dialogRefForAll.requestedNewJob = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
        this.dialogRefForAll.requestedNewJob.afterClosed().subscribe(result => { this.dialogRefForAll.requestedNewJob = null; });
    }

    if (openModalFor === 'CancelJobApprovelRequest' && !this.dialogRefForAll.requestCancel)
       {
      this.dialogRefForAll.requestCancel = this.dialog.open(templateRef, { width: '75%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRefForAll.requestCancel.afterClosed().subscribe(result => { this.dialogRefForAll.requestCancel = null; });
    }

  //  console.log("dialogRefForAll open", this.dialogRefForAll);

  }

  closePopUp(flag:string) {
    this.common.resetNotesRequestForm();
    if(flag)
      {
        this.dialogRefForAll[flag].close();
        this.dialogRefForAll[flag] = null ;
      }
      else{
        this.dialogRef.close();
       }
    
     // console.log("dialogRefForAll close", this.dialogRefForAll);
  }

 

requestedJob_tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
   this.tabAtciveFor = tabChangeEvent?.tab?.textLabel; 
}

cancelJob_tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  this.cancelJob_activeTab = tabChangeEvent?.tab?.textLabel; 
}

checkNotified(status:string, displayedText:string)
{
  this.notifyEmmitter.emit(status);
}



webSocket_Shortcuts()
{
   this.subscribe_PendingLeaveCount();
   this.subscribe_MissedClockeOut();
   this.subscribe_RequestCancel();
   this.subscribe_newRequestJobs();
}

 
 
 

subscribe_PendingLeaveCount()
{
this.common.webSocketSubcribeTopic("OrgDashboardPendingLeaveCount_"+this._sessionSCV.get('orgCode'))
.pipe(takeUntil(this.dashboard_webSocket$))
.subscribe((res:any) =>
{

  if(res.body)
  {
    this.common.globalLeaveCount =  JSON.parse(res.body).count;
  }
});
}


subscribe_MissedClockeOut()
{
this.common.webSocketSubcribeTopic("OrgDashboardMissedClockOutCount_"+this._sessionSCV.get('orgCode'))
.pipe(takeUntil(this.dashboard_webSocket$))
.subscribe((res:any) =>
{

  if(res.body)
  {
    this.missedClockOut_Count =  JSON.parse(res.body).count;
  }
});
}

subscribe_RequestCancel()
{
this.common.webSocketSubcribeTopic("OrgDashboardRequestCancelCount_"+this._sessionSCV.get('orgCode'))
.pipe(takeUntil(this.dashboard_webSocket$))
.subscribe((res:any) =>
{

  if(res.body)
  {
    this.requestCancel_Count =  JSON.parse(res.body).count;
  }
});
}

subscribe_newRequestJobs()
{
this.common.webSocketSubcribeTopic("OrgDashboardRequestedJobsCount_"+this._sessionSCV.get('orgCode'))
.pipe(takeUntil(this.dashboard_webSocket$))
.subscribe((res:any) =>
{

  if(res.body)
  {
    this.newJobRequest_Count =  JSON.parse(res.body).count;
  }
});
}



ngOnDestroy() {
  
  this.dashboard_webSocket$.next();
  this.dashboard_webSocket$.unsubscribe();
//*************************************************************** */
  this.clearObs.next(''),
  this.clearObs.complete();
  
}


getMissedClockOutCount() {
  this._checkOutMissedSVC.getMissedClockOutListByPage(this._sessionSCV.get('orgCode'),'', 0,5).subscribe((res: any) => {
    if (res.responsecode == 200) {
      this.missedClockOut_Count = res.count;
    }
  });
}

  getRequestedNewJobsCount()
 {
  this._newJobReqSVC.getRequestedJobsCounts(this._sessionSCV.get('orgCode'), false).subscribe((res: any) => {
    if (res.responsecode == 200) {
      this.newJobRequest_Count = res.data[0].value;
    }
    else{
      this.newJobRequest_Count = 0;
    }
  });

}
 

getGlobalCancelJobRequestCount(orgCode: string) {
  this.common.getCancelJobRequestCount(orgCode, false , (data) => {
    if (data.responsecode == 200) 
      {
        this.requestCancel_Count = data.count;
      // this.common.globalCancelJobRequestCount = data.count;
      }  
  })
}

hasPermissions()
{
  this.permissionFor_NewJobShortCut =  this.common.getBtnEventPermissions('Job Scheduling');
  this.permissionFor_NewShiftShortCut =  this.common.getBtnEventPermissions('Shifts');
  this.permissionFor_CustControlShortCut =  this.common.getBtnEventPermissions('Customers');
  this.permissionFor_ApproveLeaveControlShortCut =  this.common.getBtnEventPermissions('Time Off');
  this.permissionFor_MissedClockControlShortCut =  this.common.getBtnEventPermissions('Time Card');
  this.permissionFor_ChatControlShortCut =  this.common.getBtnEventPermissions('Chat');
}

subToPermissionChanged()
{
 this.common.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermissions();
     }
 })
}

 


}
