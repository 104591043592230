<div class="col-md-12">
  <div class="row">
  <div class="col-md-5 col-sm-12 primary-color ">
    <div class="calendar-nav col-md-12 px-0 pb-4 mb-3 cal ">
      <div class="col-md-8 pe-2   p-0 fw-bold pointer header-name" [title]="navDate.format('MMMM YYYY')">
        <i class="fa fa-calendar ps-1 pe-3 cal-color" aria-hidden="true"></i>
        {{navDate.format('MMMM YYYY')}}
      </div>
      <div class="col-md-4  p-0 text-end">
        <div class="calendar-nav-previous-month  dt-yr ">
          <span class="resp-arrow">
            <span title="Previous Month"><i class="material-icons pe-2"
                (click)="changeNavMonth(-1)">keyboard_arrow_left</i></span>
            <span title="Next Month"><i class="material-icons pointer"
                (click)="changeNavMonth(1)">keyboard_arrow_right</i></span>
          </span>
        </div>
      </div>
    </div>
    <div class="calendar col-12 col-md-12 col-sm-12 w-100">
      <div class="calendar-container  w-100">
        <div class="calendar-header  w-100">
          <div class="calendar-date" *ngFor="let day of weekDaysHeaderArr">
            {{day}}
          </div>
        </div>
        <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
        <div class="calendar-body">
          <div *ngFor="let day of gridArrNew" [ngClass]="day.currentDate === selectedDate ? 'eventClass' : 'inactive'"
            class="calendar-date {{day.highlight}} pointer" [class.is-disabled]="!day.available"
            (click)="getAppointments(day?.date)">
            <button *ngIf="day.value !== 0" class="date-item">{{ day.value}} <span></span></button>
          </div>
        </div>
        <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
      </div>
    </div>
  </div>
  <div class="col-md-7 col-sm-12">
    <div class="">
      <div class="timelist">
        <h4 class="primary-color fw-bold mb-3 header-name">
          <!-- {{ 'dashboard.labels.New Appointment' | translate }} -->
          New Shifts
        </h4>
        <span class="app-date" *ngIf="selectedDate">{{selectedDate}} :</span>


        <div class="popup-viewport-body mt-3" *ngIf="appointmentData.length > 0">
          <!-- <staff-job-card [cardData]="appointmentData" [actionButtonUse]="true"></staff-job-card>  -->
          <!-- <app-new-job-card [dataSource]="appointmentData"></app-new-job-card> -->

          <div class="w-100 pt-3">
            <!-- <ng-container *ngFor="let parent_job of appointmentData; let pjIdx = index;"> -->
            <ng-container *ngIf="appointmentData && appointmentData.length > 0; else noJobs">
          
              <!-- <span title="P: {{parent_job | json}}">P : Data</span> -->
              <ng-container *ngFor="let element of appointmentData; let cjIdx = index; let last = last">
                <mat-card class="mx-auto card-appointment mt-3 mb-2">
                <div class="w-100">
                <div class="row job-content card-border" [style.border-color]="element.jobColor">
                  <!-- <span title="C: {{element | json}}">C : {{element?.customerAddress}}</span> -->
                  <div class="col-md-12 col-lg-12 p-0">
                    <div class="row div-border m-0 mb-3 pt-1">

                      <div class="col-4 p-0 pb-3">
                        <div class="text-nowrap">
                          <p>Customer Name</p>
                        <small class="text-nowrap w-100">{{element?.customerName}}</small>
                        </div>
                      </div>
          
                      <div class="col-4 ps-0 pb-3">
                        <p>{{ 'main.labels.Job Site' | translate }}</p>
                        <small>{{element?.customerAddress}}</small>
                    </div>
          
                      <div class="col-4 pb-3">
                        <div class="row" *ngFor="let job_element of element.organizationBasedProcedureDetailsList; let cjobIdx = index; let last = last">
                          <!-- <span title="CC: {{job_element | json}}">CC : {{job_element?.procedureName}}</span> -->
                          <div class="col p-0 m_width_50">
                            <div class="w-100">
                            <img class="img_jobtype"
                              [src]="job_element?.procedureIcon ? img_serverPath+job_element.procedureIcon : 'assets/common_images/profile.jpg'">
                              </div>
                          </div>
                          <div class="col pt-3">
                              <p>{{job_element?.procedureName}}</p>
                          </div>
                        </div>
                      </div>            
                     
                    </div>
          
                    <div class="row m-0 pb-3">
                      <div class="col-8 p-0">
                        <div class="text-nowrap">
                          <p>Shift</p>
                        <small class="text-nowrap w-100">{{element?.startTime}} to {{element?.endTime}}</small>
                        </div>
                      </div>
                      
                      <div class="col-4 p-0">
                        <div class="text-nowrap">
                          <p>Status</p>
                        <small class="text-nowrap w-100">{{element?.status | capitalize}}</small>
                        </div>
                      </div> 
          
                    </div>
          
                    <div class="row m-0" *ngIf="!element.isExpired">
                      <div class="col-12 p-0 text-end pb-1">
                        <ng-container *ngIf="loggedUserType !== 'Customer'">
                          <button class="accept-btn me-2" mat-raised-button (click)="appointmentStatus('accept', element)">{{ 'dashboard.labels.Accept' | translate }}</button>
                        <button class="reject-btn" mat-raised-button (click)="appointmentStatus('reject', element)">{{ 'dashboard.labels.Reject' | translate }}</button>
                        </ng-container>
                      </div>
                    </div>
          
                  </div>
          
                </div>
                </div>
                </mat-card>
              </ng-container>
            </ng-container>
            <ng-template #noJobs>
              <div class="w-100">
                <!-- <p>No jobs are present selected day</p> -->
                <p class="text-danger">No data are present for selected day</p>
              </div>
            </ng-template>
          <!-- </ng-container> -->
          </div>
          

          <!-- <div class="example-item" *ngFor="let data of appointmentData; let j=index;">

            <span class="app-date ms-2 " *ngIf="data.key">{{data.key}} :</span>
            <mat-card class=" mx-auto card-appointment mt-4 mb-4">
              <div class="row icon-round">
                <div class="col-md-2">

                  <small>{{ 'common.labels.Start Time' | translate }}</small>
                  <p>{{data.startTime}}</p>
                </div>
                <div class="col-md-2">

                  <small>{{ 'common.labels.End Time' | translate }}</small>
                  <p>{{data.endTime}}</p>
                </div>
                <div class="col-md-3">
                  <small>{{ 'dashboard.labels.Patient Name' | translate }}</small>
                  <p class="text-capitalize">{{data.customerName}}</p>
                </div>
                <div class="col-md-5 ps-0">
                  <small>{{ 'dashboard.labels.Patient Address' | translate }}</small>
                  <div *ngIf="data.customerAddress">
                    {{data.customerAddress}}
                  </div>
                  <div *ngIf="!data.customerAddress">
                    {{ 'dashboard.labels.Not Available' | translate }}
                  </div>
                </div>
              </div>
              <hr class="p-0">
              <div class="row">

                

                <div class="col-md-5 pe-0 text-start">
                  <ng-container *ngIf="loggedUserType !== 'Customer'">
                    <button class="accept-btn me-3" mat-raised-button (click)="appointmentStatus('accept', data.appointmentsDurationCode)">{{ 'dashboard.labels.Accept' | translate }}</button>
                  <button class="reject-btn" mat-raised-button (click)="appointmentStatus('reject', data.appointmentsDurationCode)">{{ 'dashboard.labels.Reject' | translate }}</button>
                  </ng-container>
                </div>

                <div class="col-md-2 text-start" >
                    
                    <span *ngIf="data.notes || data.patientRecurringNotes || data.comments">
                        <img class="cursor-pointer mt-2" src="/assets/notes_icon.png" title="Notes" (click)="getNotes(data)">
                    </span>
                    <span *ngIf="!data.notes && !data.patientRecurringNotes && !data.comments"></span>
                </div>

                <div class="col-md-5 dis-img text-end pe-0">
               
                  <div class=" me-0 pe-0 " >
                    <div class=" ms-1 mb-2 cursor-pointer"  *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                      <img class="img-icon" (click)="getProcedureDetails(procImg,data.notes)" alt="No Image" *ngIf="i <= 3 && procImg.procedureIcon" title="{{procImg.procedureName}}"
                        [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                      <img alt="No Image" *ngIf="!procImg.procedureIcon"
                        src="../../../../assets/common_images/profile.jpg" />
                    </div>
                  </div>
                  <div class="ps-0 img-data" *ngIf="data.organizationBasedProcedureDetailsList.length > 4" (click)="showImage(data)" >
                    <div class="img-count cursor-pointer">
                        {{data.organizationBasedProcedureDetailsList.length - 4}}
                    </div>
                            
                  </div>
                
      
                  <div class="  img-open " *ngIf="data.appointmentsDurationCode == showImg"> 
                          <div class="img-icon me-0 mt-1" >
                            <div *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                                <img (click)="getProcedureDetails(procImg,data.notes)" alt="No Image" class="me-2" *ngIf="i >= 4 && procImg.procedureIcon " title="{{procImg.procedureName}}"
                                [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                            </div>
                          </div>
                  </div>
              </div>



              </div>
            </mat-card>
          </div> -->
        </div>

        <div *ngIf="appointmentData.length == 0" class="mt-4" class="text-danger mt-4">
          <!-- {{ 'dashboard.labels.No appointments are present' | translate }}. -->
          No records found
        </div>
      </div>
    </div>
  </div>
  </div>
</div>