<div class="w-100">
<div class="row g-1">
 <div class="col-6">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Start Time</mat-label>
        <input matInput  [formControl]="startTime" [matAutocomplete]="startTime_autocomplete"
        (keyup)="startTimeKeyUp_ForFilter(startTime.value.trim())">
        <!-- <mat-icon matSuffix >access_time</mat-icon> -->
        </mat-form-field>

         <mat-autocomplete #startTime_autocomplete>
        <mat-option  *ngFor="let time of startTime_Filter " [value]="time">
          <span>{{ time }} </span>
        </mat-option>
      </mat-autocomplete>
 </div>

 <div class="col-6">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>End Time</mat-label>
      <input matInput   [formControl]="endTime" (keyup)="endTimeKeyUp_ForFilter(endTime.value.trim())"
      [matAutocomplete]="endTime_autocomplete">
      <!-- <mat-icon matSuffix >access_time</mat-icon> -->
     </mat-form-field>

    <mat-autocomplete #endTime_autocomplete>
      <mat-option  *ngFor="let time of endTime_Filter " [value]="time">
        <span>{{ time }} </span>
      </mat-option>
    </mat-autocomplete>

    <!-- <span class="help-block err"
      *ngIf="addShift_Form.controls.end_time.errors?.required && (is_formSubmitted || addShift_Form.controls.end_time.touched)">{{ 'Shift.Validators.Field can not be a blank' | translate }}
    </span>
    <span class="help-block err"
    *ngIf="addShift_Form.controls.end_time.errors?.pattern && (is_formSubmitted || addShift_Form.controls.end_time.touched)">Expected format: H:mm AM|PM
  </span> -->
  </div>

</div>
</div>
