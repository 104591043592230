import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';
import { ApiRootService } from '../shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from '../shared/common.service';

export  interface ISaveImage_Payload {
  file: FormData,
  from: string,
  fromName:string,
  groupCode: string,
  openViduSessionCode:any,
  isAdmin: boolean,
  isApp: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class EChatService  {

  // public service_baseUrl = new Subject<string>();
  // public service_baseUrlObs = this.service_baseUrl.asObservable();
  
  private _individualChatMarshalData: any;
  globalChatData: any | undefined;
  chatToken:any

  // TENNIS_URL = "https://wsmarshal-dev.gigflex.com:8762/tennistournamentservice"
  // CHAT_URL = "https://wsmarshal-dev.gigflex.com:8762/chatingservicev2";
  // SUPERADMIN_URL = "https://wsmarshal-dev.gigflex.com:8762/superadminservice";

  TENNIS_URL ! :string
  CHAT_URL! :string
  SUPERADMIN_URL! :string


  constructor(private http: HttpClient ,  public _apiService: ApiRootService,
   public _sessionService:SessionStorageService , public common :CommonService) { 
  //   this.service_baseUrlObs.subscribe(
  //     (Url: string) => {
         
  //       this.TENNIS_URL = `${Url}/tennistournamentservice`
  //       this.CHAT_URL = `${Url}/chatingservicev2`;
  //       this.SUPERADMIN_URL = `${Url}/superadminservice`;
  //  }
  //   )
  }

    
  get individualChatMarshalData() {
    return this._individualChatMarshalData;
  }

  set individualChatMarshalData(data) {
    this._individualChatMarshalData = data;
  }



  getFromStorage(key: string) {
    const keyJson = localStorage.getItem(key);
    try {
      return keyJson !== null ? JSON.parse(keyJson) : null;
    } catch (error) {
      return localStorage.getItem(key);
    }
  }
  
  getChatData(code: any, adminCode: any) {
    const url = `${this._apiService.getTennishUrl()}/getGroupDetailsOfSecurityAdmin/${code}/${adminCode}`;
    return this.http.get(url);
  }
  
  adminGetAllGroupByOrganizationCode(code: any, adminCode: any) {
    const url = `${this._apiService.getChatUrl()}/adminGetAllGroupByOrganizationCode/${code}/${adminCode}`;
    return this.http.get(url);
  }
  
  
  saveMessage(data:any) {
    const url = `${this._apiService.getChatUrl()}/saveMessage`;
    return this.http.post(url, data);
  }
 
  saveImage(request_body:ISaveImage_Payload) {
    const url = `${this._apiService.getChatUrl()}/v2/shareImageMessage?from=${request_body.from}&fromName=${request_body.fromName}&groupCode=${request_body.groupCode}&openViduSessionCode=${request_body.openViduSessionCode}&isAdmin=${request_body.isAdmin}&isApp=${request_body.isApp}`;
    return this.http.post(url, request_body.file);
  }
  
  twilioChatTokenGenerator(userCode:any) {
      const formData = {
      identity: userCode,
    };
    const url = `${this._apiService.getChatUrl()}/twilioChatTokenGenerator`;
    return this.http.post(url, formData);
  }
  
  getMessageListByGroupCodeAndUserCode(code:any, usercode:any, search:any, id:any, page:any, limit:any  ) {
      var url = '';
      //console.log("==search==",search);
      //console.log("==id==",id);
    if (search && id == null) {
        url = `${this._apiService.getChatUrl()}/getMessageListByGroupCodeAndUserCode/${code}/${usercode}/${search}?page=${page}&limit=${limit}`;
    } else if (search && id) {
        url = `${this._apiService.getChatUrl()}/getMessageListByGroupCodeAndUserCode/${code}/${usercode}/${search}/${id}?page=${page}&limit=${limit}`;     
    } else {
         url = `${this._apiService.getChatUrl()}/getMessageListByGroupCodeAndUserCode/${code}/${usercode}/?page=${page}&limit=${limit}`;
    }   //console.log("==url==",url);
    return this.http.get(url);
  }
  
  
  updateMessage(id:any, data:any) {      
    const url = `${this._apiService.getChatUrl()}/updateMessageReadStatus/${id}`;
    return this.http.put(url, data);
  }
  
  adminGetAllGroupByOrganizationCodeForSecurity(code:any, adminCode:any) {
    const url = `${this._apiService.getChatUrl()}/getGroupDetailsOfSecurityAdmin/${code}/${adminCode}`;
    return this.http.get(url);
  }
  updateisErasedMessageReadStatus(messageId:any,data:any) {
    const url = `${this._apiService.getChatUrl()}/updateisErasedMessageReadStatus/${messageId}`;
    return this.http.put(url,data);
  }
  
  getMessageLogByAdminCode(admincode: any, page: any, limit: any) {
    const MSG_LOG_DATA_URL = `${this._apiService.getChatUrl()}/getMessageLogByAdminCode/${admincode}?page=${page}&limit=${limit}`;
    return this.http.get(MSG_LOG_DATA_URL);
  }

}
