import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';

import * as moment from 'moment';
import { debounceTime, Subject, takeUntil } from 'rxjs';
 import { CommonService } from 'src/app/shared/common.service';
import { missed_clock_in_out_inputs } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
 

@Component({
  selector: 'app-update-clock-in-out-time',
  templateUrl: './update-clock-in-out-time.component.html',
  styleUrls: ['./update-clock-in-out-time.component.scss']
})

export class UpdateClockInOutTimeComponent implements OnInit, OnDestroy {

  @Input() missed_clock_in_out_inputs: missed_clock_in_out_inputs;
  @Output() update_parent = new EventEmitter();
  @Output() getFormData = new EventEmitter();
  @Input() eligibleStaff : any[] = [] ;

  form_update_clockInOut_time: FormGroup;
  add_break: boolean = false;
  private date_changes_observable = new Subject<any>();
  private clear_observable = new Subject();
  is_late_night: boolean = false;
  private is_approve_and_save: boolean = false;
  user_type: string = '';
  is_form_submitted: boolean = false;
  can_clock_in_before_time: number = 200 // minutes (defalut)

  selectedStaffCode : string = "" ;
  is_disabled : boolean = true ;
  disable_addBreak : boolean = false ;
  mark_no_show : boolean = false;
  mark_absent : boolean = false;

  @Output() absent_Or_NoShow = new EventEmitter();

  private absentOrNoShow: string = '' ;
   
  @Input() allow_to_mark_staff_absent_or_notshow : boolean = false;

  constructor(
    private _sessionStorageSVC: SessionStorageService,
     private _commonService: CommonService,
    public dialog: MatDialog 
 ) { }


  ngOnInit(): void {
    this.user_type = this._sessionStorageSVC.get('user_type');
    this.initialize_form();
    this.subscribe_date_change(); 
    this.get_clock_in_before_from_setting();

    if(this.missed_clock_in_out_inputs.workerCode)
    {
      this.selectedStaffCode = this.missed_clock_in_out_inputs.workerCode ;
      this.form_update_clockInOut_time.controls.selectedStaffCode.setValue(this.selectedStaffCode);

      if(this.missed_clock_in_out_inputs.staff_details_obj && Object.keys(this.missed_clock_in_out_inputs.staff_details_obj).length > 0)
        {
          // console.log("this.eligibleStaff", this.eligibleStaff);
          // console.log("this.missed_clock_in_out_inputs.staff_details_obj", this.missed_clock_in_out_inputs.staff_details_obj)
           this.eligibleStaff.push(this.missed_clock_in_out_inputs.staff_details_obj) // pushing selected staff 
        }

    }

    // allowing to change staff when, not have clock-in and clock-out data
   this.is_disabled =  this.missed_clock_in_out_inputs.prefield_clock_in || this.missed_clock_in_out_inputs.prefield_clock_out ? true : false ;

  }

  initialize_form() {
    this.form_update_clockInOut_time = new FormGroup({
      clock_in: new FormControl('', Validators.required),
      clock_out: new FormControl('', Validators.required),
      breakForm: new FormControl([]),
      comment: new FormControl(),
      selectedStaffCode: new FormControl('', Validators.required)
    })
  }

  onTimeChange(event: any) {
    this.date_changes_observable.next(event);
  }

  // onTimeChange(event: any , controls:string) {
  //   this.form_update_clockInOut_time.get(controls).setValue(moment(event.timeStamp).format('HH:mm:ss A')) ;
  //   this.date_changes_observable.next(event);
  // }


  captureFormData(event: any) {
    //.log("event",event);
    this.form_update_clockInOut_time.controls.breakForm.setValue(event);
    // this.toggleBreak(index);
  }

  handleNoBreak(event: any) {
    this.form_update_clockInOut_time.controls.breakForm.setValue([]);
    this.add_break = false;
  }

  addUpdateNotes() {
    this._commonService.addUpdateNotes();
  }

  resetNotes() {
    this._commonService.resetNotesRequestForm();
  }

 
  save_form() {
    // console.log(this.form_update_clockInOut_time.value);
    this.is_form_submitted = true;

    if (this.form_update_clockInOut_time.valid) {

      const data = 
      {
         "formData" : this.form_update_clockInOut_time.value,
         "missedClockInOutInputs" : this.missed_clock_in_out_inputs,
         "isApproved": this.is_approve_and_save,
         "staffCode" : this.selectedStaffCode,
         "noteRequest": this._commonService.noteRequest
      }

      this.getFormData.emit(data) ;

      // let req_body = {
      //   "appointmentsDurationCode": this.missed_clock_in_out_inputs.appointmentsDurationCode,
      //   "clinicianCheckinDetilsCode": this.missed_clock_in_out_inputs.clinicianCheckinDetilsCode ? this.missed_clock_in_out_inputs.clinicianCheckinDetilsCode : null,
      //   "date": this.missed_clock_in_out_inputs.jobFormatedDate ? this.missed_clock_in_out_inputs.jobFormatedDate : moment(this.missed_clock_in_out_inputs.jobDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      //   // "staffCode": this.missed_clock_in_out_inputs.workerCode,
      
      //   "staffCode": this.selectedStaffCode,

      //   "breakDetailsReqList": this.form_update_clockInOut_time.controls.breakForm.value || [],
      //   "clockinTime": this.form_update_clockInOut_time.controls.clock_in.value + ':00',
      //   "clockoutTime": this.form_update_clockInOut_time.controls.clock_out.value + ':00',

      //   "comment": this.form_update_clockInOut_time.controls.comment.value,
      //   "isAdmin": (this._sessionStorageSVC.get('user_type') !== "Staff" && this._sessionStorageSVC.get('user_type') !== "Customer") ? true : false,
      //   "organizationCode": this._sessionStorageSVC.get('orgCode'),

      //   "isApproved": this.is_approve_and_save,
      //   "noteRequest": this._commonService.noteRequest

      // }

      // this._commonService.progressLoader = true;

      // this._checkOutMissedSVC.saveOrUpdateAdminActionTimeSheet(req_body).subscribe((res: any) => {
      //  // console.log("res", res);
      //   this._commonService.progressLoader = false;

      //   if (res.responsecode === 200) {
      //     this._commonService.successMessage = true;
      //     this._commonService.message = res.message;
      //     this.update_parent.emit("200") // record saved successfully
      //   }
      //   else {
      //     this._commonService.errorMessage = true;
      //     this._commonService.message = res.message;
      //   }

      // })

    }

  }

  save_and_approve_form() 
  {
    this.is_form_submitted = true;
    if (this.form_update_clockInOut_time.valid) {

      this.is_approve_and_save = true; // setting true when only wants "save and approve"

      this.check_validation_and_save();

     // this.save_form();
    }

  }

  toggle_break() {
    this.add_break = true;
  }


  subscribe_date_change() {
    this.date_changes_observable.pipe(
      debounceTime(300),
      takeUntil(this.clear_observable))
      .subscribe((res: string) => {
        this.clock_out_is_before();
      })
  }


  clock_out_is_before() {

    if(this.form_update_clockInOut_time.controls.clock_in.value && this.form_update_clockInOut_time.controls.clock_out.value){
      // console.log('TimeDiff_1', this.form_update_clockInOut_time.controls.clock_in.value, this.form_update_clockInOut_time.controls.clock_out.value);

      const inTime = this.form_update_clockInOut_time.controls.clock_in.value;
      const outTime = this.form_update_clockInOut_time.controls.clock_out.value;

      if (inTime && outTime && this._commonService.isMoreThan8Hours(inTime, outTime)) {
        // console.log("TimeDiff_Time difference is greater than 8 hours");
        
        
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '600px',
          disableClose: true,
          data: { 
            msg: 'The Shift Start and End times will result in a shift greater than 8 hours and may required Overtime payments. Do you want to save these shift hours?',
            cancelBtnLabel: 'Cancel', 
            confirmBtnLabel: 'Save'
            // hideNoBtn: true
           }
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result && result.toLowerCase() === 'ok') {
            //  Do Nothing..
          }
          else
          {
            this.form_update_clockInOut_time.controls.clock_in.reset();
            this.form_update_clockInOut_time.controls.clock_out.reset();
            // this.is_approve_and_save = false;
          }
  
        });
      } else {
        // console.log("TimeDiff_Time difference is less than or equal to 8 hours");
      }
    }

    if (this.form_update_clockInOut_time.controls.clock_in.value && this.form_update_clockInOut_time.controls.clock_out.value) {
      let clock_in_time = moment(this.form_update_clockInOut_time.controls.clock_in.value, 'h:mm');
      let clock_out_time = moment(this.form_update_clockInOut_time.controls.clock_out.value, 'h:mm');
      this.is_late_night = clock_out_time.isBefore(clock_in_time);
    }
    else {
      this.is_late_night = false;
    }


    // setting validation error for "Clock-in time should not be before shift start time"


    // const control = this.form_update_clockInOut_time.get('clock_in') ;

    // if(this.form_update_clockInOut_time.controls.clock_in.value)
    //   {
    //     /* subtracting time added in organization setting  "User Can Only Clock-In To A Shift Up To xx minutes before"
    //     */

    //     let shift_start_time = moment(this.missed_clock_in_out_inputs.shift_start_time,'YYYY-MM-DD hh:mm:ss').subtract(this.can_clock_in_before_time, 'minutes');
    //     let clock_in_time = moment(`${this.missed_clock_in_out_inputs.jobFormatedDate}' '${this.form_update_clockInOut_time.controls.clock_in.value}' :00'`, 'YYYY-MM-DD hh:mm:ss');
    //     //  console.log(shift_start_time , clock_in_time);

    //     if(clock_in_time.isBefore(shift_start_time))
    //     {
    //       this.form_update_clockInOut_time.controls.clock_in.setErrors({before_shift_start_time:true});
    //     }
    //     else
    //     {
    //       if (control.errors?.["before_shift_start_time"]) 
    //         {
    //         //const errors = { ...control.errors };
    //        // if(control.errors[errorKey])
    //         delete control.errors?.["before_shift_start_time"];
    //        }

    //      // this.form_update_clockInOut_time.controls.clock_in.setErrors(null);
    //     }

    //   }
    //   else{
    //     if (control && control.errors) 
    //       {

    //       this.form_update_clockInOut_time.controls.clock_in.setErrors({ ...control.errors,
    //         before_shift_start_time:true
    //       });
    //      }
    //      else{
    //       this.form_update_clockInOut_time.controls.clock_in.setErrors({before_shift_start_time:true});
    //      }


    //     // this.form_update_clockInOut_time.controls.clock_in.setErrors(null);
    //   }


  }

  get_clock_in_before_from_setting() {
    this._commonService.getSettingBySettingName("clockin.start.min.before", this._sessionStorageSVC.get("orgCode"), (response: any) => {

      if (response.responsecode == 200) {
        //  console.log("clockin.start.min.before", response)
        this.can_clock_in_before_time = (response.settingValue && parseInt(response.settingValue) < 200) ? parseInt(response.settingValue) : 200;
      }
    })

  }

  ngOnDestroy(): void {
    this.clear_observable.next('');
    this.clear_observable.complete();
  }

  is_clock_in_time_before_shift_start_time(): boolean {
    let is_before_shift_start_time: boolean = false;

    if(!this.missed_clock_in_out_inputs.shift_start_time)
    {
      return false;
    }
    
    let shift_start_time = moment(this.missed_clock_in_out_inputs.shift_start_time, 'YYYY-MM-DD hh:mm:ss').subtract(this.can_clock_in_before_time, 'minutes');
    let clock_in_time = moment(`${this.missed_clock_in_out_inputs.jobFormatedDate}' '${this.form_update_clockInOut_time.controls.clock_in.value}' :00'`, 'YYYY-MM-DD hh:mm:ss');
    //  console.log(shift_start_time , clock_in_time);

    if (clock_in_time.isBefore(shift_start_time)) {
      is_before_shift_start_time = true;
    }
    else {
      is_before_shift_start_time = false;
    }

    return is_before_shift_start_time;
  }


  
  check_validation_and_save() 
  {
    this.is_form_submitted = true;

    if (this.form_update_clockInOut_time.valid) {

    if (this.is_clock_in_time_before_shift_start_time()) 
      {

      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'The clock-in time is earlier than the shift start time. Confirm if you want to proceed with this request?' }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result && result.toLowerCase() === 'ok') {
           this.save_form();
        }
        else
        {
          this.is_approve_and_save = false;
        }

      });

    }
    else {
      this.save_form();
    }
    
  }

  }


  getSelectedStaff(event:any)
  {
    //console.log("selection changed child", event.workerCode )
    this.form_update_clockInOut_time.controls.selectedStaffCode.setValue( event.staffCode);
   this.selectedStaffCode =  event.staffCode ;
  }

 
  onCheck_mark_absent(event:MatCheckboxChange, flag:string)
  {
    this.mark_no_show = false;
    this.disableForm(flag);
   }

  onCheck_mark_no_show(event:MatCheckboxChange, flag:string){
    this.mark_absent = false ;
    this.disableForm(flag);
  }


    /**
     * disable form control  
     */
   disableForm(flag:string)
   {
    if(this.mark_no_show || this.mark_absent)
      {
        this.absentOrNoShow = flag;
       // this.form_update_clockInOut_time.disable();

        Object.keys(this.form_update_clockInOut_time.controls).forEach(control => {
        //  console.log("control", control)
          if (control !== 'comment') {
            this.form_update_clockInOut_time.get(control)?.disable();
          }
        });

        this.is_disabled = true;
        this.disable_addBreak = true ;
        this.add_break = false;

        const breakForm:any[] = this.form_update_clockInOut_time.controls.breakForm.value || [];
        if(breakForm.length > 0)
        {
          this.form_update_clockInOut_time.controls.breakForm.setValue([]); // resetting breakform value
        }
        
    }
      else
      {
        this.absentOrNoShow = '';
        this.form_update_clockInOut_time.enable();
        this.is_disabled = false;
        this.disable_addBreak = false ;
      }
   }

   changeStaffStatus()
   {
    let data = {
      ...this.missed_clock_in_out_inputs,
       absent_or_norShow_flag: this.absentOrNoShow,
       comment: this.form_update_clockInOut_time.controls.comment.value
      }

    this.absent_Or_NoShow.emit(data);
   }

}
