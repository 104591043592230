
<div id="printData" class="mb-2">
    <div class="row">
        <div class="col-md-12">
            <label class="title mb-2"> {{'report.labels.Tracking Report' |
                translate }}</label>
        </div>
    </div>
</div>
   
   <div class="row">

            <div class="col-md-3">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>Choose Date</mat-label>
                    <!-- <input (focus)="picker.open()" matInput [matDatepicker]="picker" placeholder="Choose a date"
                        [value]="date" (dateChange)="onDateChange($event)"> -->
                        <input (focus)="picker.open()" matInput [matDatepicker]="picker" placeholder="Choose a date"
                         [formControl]="ctx_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> 
            </div>

            <div class="col-md-3">

                <staff-list-select
                (selectionChanged)="onStaffChanged($event)"
                 >
               </staff-list-select>
               <!-- <span class="help-block err" *ngIf="tracking_Form.get('tr_staffCode').errors?.required && (formSubmitted || tracking_Form.get('tr_staffCode').touched)">
                field is required
              </span> -->

                <!-- <mat-form-field appearance="outline" floatLabel="always" class="example-full-width custom-search">
                  <mat-label>Staff</mat-label>
                  <mat-select  placeholder="select staff" [formControl]="ctx_staff">
                    <mat-option>
                      <ngx-mat-select-search [placeholderLabel]="'Search Here...'" [noEntriesFoundLabel]="'Record not found'"
                        [formControl]="activeStaffSeachInput"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let option of activeStaffList_Filter" [value]="option">
                      {{option.name}}
                    </mat-option>
                     
                  </mat-select>
            
                </mat-form-field> -->
              </div>

              <div class="col-md-3 text-center">
                <button mat-raised-button color="primary" (click)="searchReport()">Search</button>
              </div>
             
            <!-- <div class="col-md-6 text-end" *ngIf="allReport.length != 0">
                <button type="button" class="ms-3 mt-2" mat-flat-button color="primary" (click)="export()">Export to
                    excel</button>
                <button type="button" class="ms-3 mt-2" mat-flat-button color="primary" (click)="printReport()">
                    <i class="material-icons pe-2"> print</i>{{'report.labels.Print
                    Report' | translate }} </button>
            </div> -->
        </div>

        <div class="table-scroll-height" id="checkinout" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
             <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="staffName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'report.labels.Technician Name' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.staffName">
                            {{element.staffName}}</span>
                        <span *ngIf="!element.staffName">NA</span>
                    </td>
                </ng-container> 
                <ng-container matColumnDef="trackingDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'report.labels.Date' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.trackingDate">
                            {{element.trackingDate}}</span>
                        <span *ngIf="!element.trackingDate">NA</span>
                    </td>
                </ng-container> 
                <ng-container matColumnDef="createdTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'report.labels.Time' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.createdTime">
                            {{element.createdTime}}</span>
                        <span *ngIf="!element.createdTime">NA</span>
                    </td>
                </ng-container> 
                <ng-container matColumnDef="latitude">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'report.labels.Latitude' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.latitude">
                            {{element.latitude}}</span>
                        <span *ngIf="!element.latitude">NA</span>
                    </td>
                </ng-container>  <ng-container matColumnDef="longitude">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'report.labels.Longitude' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.longitude">
                            {{element.longitude}}</span>
                        <span *ngIf="!element.longitude">NA</span>
                    </td>
                </ng-container> 

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
                    true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>  

            <div *ngIf="allReport.length === 0" class="no-data p-3 text-danger">
                <!-- {{'report.labels.No Report Generated' | translate }} -->
                No Records Found
            </div>

        </div>
        
        <div class="w-100" *ngIf="service.common.infiniteLoader">
            <div class="loadergif">
              <img src="assets/typint_dots_black.gif" alt="Loader">
              <p>Loading...</p>
            </div>
          </div>