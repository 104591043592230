<div id="printData">
    <div class="w-100">
        <div class="col-md-12">
            <label class="title mb-2 header-name"> {{'report.labels.Check In/Out Report' |
                translate }}</label>
        </div>
    </div>
</div>
<mat-tab-group dynamicHeight class="tab-button-auto-width">
    <mat-tab label="DAILY" class="daily">
        <div class=" row w-100 mt-3 ps-0 ms-0 me-0">

            <div class="col-3">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>From Date</mat-label>
                    <input (focus)="from_date_picker.open()" matInput [matDatepicker]="from_date_picker" placeholder=""
                        [(ngModel)]="searchForm.startDate" (dateChange)="onDateChange($event, 'fromDate')">
                    <mat-datepicker-toggle matSuffix [for]="from_date_picker"></mat-datepicker-toggle>
                    <mat-datepicker #from_date_picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-3">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>To Date</mat-label>
                    <input (focus)="to_date_picker.open()" matInput [matDatepicker]="to_date_picker" placeholder=""
                    [(ngModel)]="searchForm.endDate" (dateChange)="onDateChange($event,'toDate')">
                    <mat-datepicker-toggle matSuffix [for]="to_date_picker"></mat-datepicker-toggle>
                    <mat-datepicker #to_date_picker></mat-datepicker>
                </mat-form-field>
            </div>

            <!-- <div class="col-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-75">
                    <mat-label>{{'report.labels.Search Staff' | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="userName" placeholder="">
                </mat-form-field>
            </div> -->
            <div class="col-2">
                <!-- <button type="button" class="m-0 p-0" mat-raised-button color="primary" (click)="clearFilter(toggaleVariable)">Reset</button> -->
            </div>
            <div class="col-4 text-end" *ngIf="allReport.length != 0">
                <button type="button" class="me-3 mt-0" mat-raised-button color="primary" (click)="export()">Excel</button>
                <button type="button" class="mt-0" mat-raised-button color="accent" (click)="printReport()">
                    <!-- <i class="material-icons pe-2"> print</i> -->
                         Print </button>
            </div>
        </div>

        <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
        <div class=" row w-100 d-grid" *ngIf="allReport && allReport.length > 0">
        <div class="table-scroll-height" id="checkinout" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
            <table mat-table class="report-table position-relative" [dataSource]="dataSource" matSort style="width: 150%;">

                <ng-container matColumnDef="jobDate">
                    <th mat-header-cell *matHeaderCellDef style="width: 200px;"> 
                     
                         

                        <div class="d-flex align-items-center">
                            <span mat-sort-header> Date</span>
                            <i *ngIf="!toggleDateFilter" class="fa fa-filter" (click)="toggleFilter('toggleDateFilter')"></i>
                            <i *ngIf="toggleDateFilter" class="fa fa-times-circle text-danger"
                               matTooltip="clear filter"
                                 matTooltipPosition="above"
                                   matTooltipPosition="above"
                                     (click)="clearFilter('toggleDateFilter')">
                                    </i> 
                        </div>
                         
                        <ng-template [ngIf]="toggleDateFilter"> 
                            <mat-form-field appearance="outline" floatLabel="always" class="w-75">
                                <input matInput [(ngModel)]="DateInput" (keyup)="searchColumn(DateInput, 'Date')">
                                <mat-icon matSuffix (click)="searchColumn(DateInput, 'Date')">search</mat-icon>
                            </mat-form-field>     
                       </ng-template>
                           
                    
                       </th>
                    <td mat-cell *matCellDef="let element"> {{element.jobDate}}</td>
                </ng-container>

                <ng-container matColumnDef="staffName">
                    <th mat-header-cell *matHeaderCellDef  style="width: 200px;">
                    
                        <div class="d-flex align-items-center">
                            <span mat-sort-header> Staff</span>
                            <i *ngIf="!toggleStaffFilter" class="fa fa-filter" (click)="toggleFilter('toggleStaffFilter')"></i>
                            <i *ngIf="toggleStaffFilter"
                            class="fa fa-times-circle text-danger"
                               matTooltip="clear filter" 
                                matTooltipPosition="above" 
                                 matTooltipPosition="above" 
                                 (click)="clearFilter('toggleStaffFilter')">
                                 </i> 
                        </div>
                         
                        <ng-template [ngIf]="toggleStaffFilter"> 
                            <mat-form-field appearance="outline" floatLabel="always" class="w-75">
                                <input matInput [(ngModel)]="StaffInput" (keyup)="searchColumn(StaffInput, 'Staff Name')">
                                <mat-icon matSuffix (click)="searchColumn(StaffInput, 'Staff Name')">search</mat-icon>
                            </mat-form-field>     
                       </ng-template>    
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-nowrap">
                        <ng-container *ngIf="element?.staffName">
                            <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
                        </ng-container>                        
                    </td>
                </ng-container>

                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef  style="width: 200px;">
                        <div class="d-flex align-items-center">
                            <span mat-sort-header> Customer</span>
                            <i *ngIf="!toggleCustomerFilter" class="fa fa-filter" (click)="toggleFilter('toggleCustomerFilter')"></i>
                            <i *ngIf="toggleCustomerFilter" 
                            class="fa fa-times-circle text-danger"   
                            matTooltip="clear filter"  
                            matTooltipPosition="above"  
                            matTooltipPosition="above" 
                            (click)="clearFilter('toggleCustomerFilter')">
                        </i> 
                        </div>
                         
                        <ng-template [ngIf]="toggleCustomerFilter"> 
                            <mat-form-field appearance="outline" floatLabel="always" class="w-75">
                                <input matInput [(ngModel)]="CustomerInput" (keyup)="searchColumn(CustomerInput, 'Customer Name')">
                                <mat-icon matSuffix (click)="searchColumn(CustomerInput, 'Customer Name')">search</mat-icon>
                            </mat-form-field>     
                       </ng-template> 
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
                </ng-container>

                <ng-container matColumnDef="jobSite">
                    <th mat-header-cell *matHeaderCellDef  style="width: 200px;">
                        <div class="d-flex align-items-center">
                            <span mat-sort-header> {{ 'main.labels.Job Site' | translate }}</span>
                            <i *ngIf="!toggleLocationFilter" class="fa fa-filter" (click)="toggleFilter('toggleLocationFilter')"></i>
                            <i *ngIf="toggleLocationFilter" 
                            class="fa fa-times-circle text-danger"   
                            matTooltip="clear filter"  
                            matTooltipPosition="above"  
                            matTooltipPosition="above" 
                            (click)="clearFilter('toggleLocationFilter')">
                        </i> 
                        </div>
                         
                        <ng-template [ngIf]="toggleLocationFilter"> 
                            <mat-form-field appearance="outline" floatLabel="always" class="w-75">
                                <input matInput [(ngModel)]="LocationInput" (keyup)="searchColumn(LocationInput, 'Location')">
                                <mat-icon matSuffix (click)="searchColumn(LocationInput, 'Location')">search</mat-icon>
                            </mat-form-field>     
                       </ng-template> 
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.location}}</td>
                </ng-container>


                <ng-container matColumnDef="jobId">
                    <th mat-header-cell *matHeaderCellDef  style="width: 200px;">
                        <div class="d-flex align-items-center">
                            <span mat-sort-header> Job ID</span>
                            <i *ngIf="!togglejobIDFilter" class="fa fa-filter" (click)="toggleFilter('togglejobIDFilter')"></i>
                            <i *ngIf="togglejobIDFilter" 
                            class="fa fa-times-circle text-danger"   
                            matTooltip="clear filter"  
                            matTooltipPosition="above"  
                            matTooltipPosition="above" 
                            (click)="clearFilter('togglejobIDFilter')">
                        </i> 
                        </div>
                         
                        <ng-template [ngIf]="togglejobIDFilter"> 
                            <mat-form-field appearance="outline" floatLabel="always" class="w-75">
                                <input matInput [(ngModel)]="JobIDInput" (keyup)="searchColumn(JobIDInput, 'Job Id')">
                                <mat-icon matSuffix (click)="searchColumn(JobIDInput, 'Job Id')">search</mat-icon>
                            </mat-form-field>     
                       </ng-template>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.jobId}}</td>
                </ng-container>

                <ng-container matColumnDef="jobType" >
                    <th mat-header-cell *matHeaderCellDef  style="width: 200px;">
                        <div class="d-flex align-items-center">
                            <span mat-sort-header> Job Type</span>
                            <i *ngIf="!togglejobTypeFilter" class="fa fa-filter" (click)="toggleFilter('togglejobTypeFilter')"></i>
                            <i *ngIf="togglejobTypeFilter" 
                            class="fa fa-times-circle text-danger"   
                            matTooltip="clear filter"  
                            matTooltipPosition="above"  
                            matTooltipPosition="above" 
                            (click)="clearFilter('togglejobTypeFilter')">
                        </i> 
                        </div>
                         
                        <ng-template [ngIf]="togglejobTypeFilter"> 
                            <mat-form-field appearance="outline" floatLabel="always" class="w-75">
                                <input matInput [(ngModel)]="JobTypeInput" (keyup)="searchColumn(JobTypeInput, 'Job Type')">
                                <mat-icon matSuffix (click)="searchColumn(JobTypeInput, 'Job Type')">search</mat-icon>
                            </mat-form-field>     
                       </ng-template>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.jobType}}</td>
                </ng-container>

                <ng-container matColumnDef="scheduleStartTime">
                    <th mat-header-cell *matHeaderCellDef>Schedule Start Time</th>
                    <td mat-cell *matCellDef="let element">{{element.scheduleStartTime}}</td>
                </ng-container>

                <ng-container matColumnDef="scheduleEndTime">
                    <th mat-header-cell *matHeaderCellDef>Schedule End Time</th>
                    <td mat-cell *matCellDef="let element">{{element.scheduleEndTime}}</td>
                </ng-container>

                <ng-container matColumnDef="actualStartTime">
                    <th mat-header-cell *matHeaderCellDef>Actual Start Time</th>
                    <td mat-cell *matCellDef="let element">{{element.actualStartTime}}</td>
                </ng-container> 

                 <ng-container matColumnDef="actualEndTime">
                    <th mat-header-cell *matHeaderCellDef>Actual End Time</th>
                    <td mat-cell *matCellDef="let element">{{element.actualEndTime}}</td>
                </ng-container>

                <ng-container matColumnDef="estimatedMiles">
                    <th mat-header-cell *matHeaderCellDef>Estimate Miles</th>
                    <td mat-cell *matCellDef="let element">{{element.estimatedMiles}}</td>
                </ng-container> 

                 <ng-container matColumnDef="actualMiles">
                    <th mat-header-cell *matHeaderCellDef>Actual Miles</th>
                    <td mat-cell *matCellDef="let element">{{element.actualMiles}}</td>
                </ng-container>

                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef class="commentWidth">Notes</th>
                    <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">{{element.notes}}</td>
                </ng-container> 
 

                <tr mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky:
                    true"></tr>
                <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
            </table>

        </div>
        </div>

        <div *ngIf="allReport.length === 0" class="no-data p-3">
            {{'report.labels.No Report Generated' | translate }}
        </div>

        <div class="w-100" *ngIf="service.common.infiniteLoader">
            <div class="loadergif">
              <img src="assets/typint_dots_black.gif" alt="Loader">
              <p>Loading...</p>
            </div>
          </div>

    </mat-tab>
 
    <!-- <mat-tab label="WEEKLY" class="weekly">
        <div class="row w-100 mt-3 ps-3 ms-0 me-0">

            <div class="col-2">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>Start Date</mat-label>
                    <input (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder=""
                        [value]="formatedDateS" (dateChange)="onDateChangeS($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-2">
                <mat-form-field appearance="outline" floatLabel="always"  class="w-100">
                    <mat-label>End Date</mat-label>
                    <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder=""
                        [value]="formatedDateE" (dateChange)="onDateChangeE($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-4">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                    <mat-label>Select Staff</mat-label>
                    <input type="text" placeholder="" matInput [formControl]="worker"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="selectedAuto($event)">
                        <mat-option *ngFor="let option of filteredOptions |
                            async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
            <div class="col-md-4">
                <button *ngIf="workerexits && dataSourceW.data.length != 0" type="button" class="ms-3 mt-2 float-end" mat-flat-button color="primary"
                    (click)="printWeeklyReport()">
                    <i class="material-icons pe-2"> print</i>{{'report.labels.Print Report' | translate }} </button>
            </div>
        </div>


        <div class="table-scroll-height" *ngIf="dataSourceW && dataSourceW.data.length> 0">
            <table mat-table [dataSource]="dataSourceW" matSort>
                <ng-container matColumnDef="{{data}}" *ngFor="let data of
                    keys;let i= index">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                        <span *ngIf="i == 0">{{data}}</span>
                        <span *ngIf="i >= 1"><span class="daydt">{{data.split('
                                ')[0]}}</span><br>
                            <span>({{data.split(' ')[1]}})</span></span>
                        <table *ngIf="i> 0">
                            <tr class="border">
                                <td class="border">Scheduled StartTime</td>
                                <td class="border">Actual StartTime</td>
                                <td class="border">Actual EndTime</td>
                                <td class="border">Estimated  Miles</td>
                                <td class="border">Actual Miles</td>
                                <td class="border">Reason for delay</td>
                            </tr>
                        </table>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="i == 0"> {{element.CustomerName}} </div>
                        <table *ngIf="element[data] && i >= 1">
                            <tr class="border data" [ngStyle]="{'background-color':d.delayedInMin >=
                                20? '#ffe9ec' : '' }" *ngFor="let d of element[data];">
                                <td class="border wdt" *ngIf="d.scheduledStartTime"><span class="datatables">{{d.scheduledStartTime.split('
                                        ')[1]}}
                                        {{d.scheduledStartTime.split(' ')[2]}}</span></td>
                                <td class="border wdt" *ngIf="!d.scheduledStartTime"><span class="datatables">--</span>
                                </td>
                                <td class="border wdt" *ngIf="d.actualStartTime"><span class="datatables">{{d.actualStartTime.split('
                                        ')[1]}}
                                        {{d.actualStartTime.split(' ')[2]}}</span></td>
                                <td class="border wdt" *ngIf="!d.actualStartTime"><span class="datatables">--</span>
                                </td>
                                <td class="border wdt" *ngIf="d.actualEndTime"><span class="datatables">{{d.actualEndTime.split('
                                        ')[1]}}
                                        {{d.actualStartTime.split(' ')[2]}}</span></td>
                                <td class="border wdt" *ngIf="!d.actualEndTime"><span class="datatables">--</span>
                                </td>

                                <td class="border wdt" *ngIf="d.estimatedMiles"><span
                                    class="datatables">{{d.estimatedMiles}}</span></td>
                            <td class="border wdt" *ngIf="!d.estimatedMiles"><span class="datatables">--</span>
                            </td>
                            <td class="border wdt" *ngIf="d.actualMiles"><span
                                class="datatables">{{d.actualMiles}}</span></td>
                        <td class="border wdt" *ngIf="!d.actualMiles"><span class="datatables">--</span>
                        </td>
                                <td class="border wdt" *ngIf="d.delayedReason"><span
                                        class="datatables">{{d.delayedReason}}</span></td>
                                <td class="border wdt" *ngIf="!d.delayedReason"><span class="datatables">--</span>
                                </td>
                            </tr>
                        </table>
                        <table *ngIf="element[data].length == 0">
                            <tr class="border data">
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                                <td class="border wdt"><span class="datatables">--</span></td>
                            </tr>
                        </table>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="keys; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: keys;"></tr>
            </table>
        </div>
        <div class="p-4" *ngIf="workerexits && dataSourceW.data.length <= 0">
            Selected staff not worked in this week
        </div>
        <div class="p-4" *ngIf="dataSourceW && dataSourceW.data.length <= 0 &&
            workernotexit">
            Please select staff for weekly report
        </div>
    </mat-tab> -->
</mat-tab-group>



<div id="tbl_report" style="display: none;">
    <table style="font-size: 10px;" *ngIf="dataSource?.data.length > 0" class="position-relative">
        <thead>
            <tr>
                <td style="border-bottom: 1px solid;">Date </td>
                <td style="border-bottom: 1px solid;">Staff </td>
                <td style="border-bottom: 1px solid;">Customer </td>
                <td style="width: 20%;border-bottom: 1px solid;">{{ 'main.labels.Job Site' | translate }} </td>
                <td style="border-bottom: 1px solid;">Job ID </td>
                <td style="border-bottom: 1px solid;">Job Type</td>
                <td style="border-bottom: 1px solid;">Schedule Start Time </td>
                <td style="border-bottom: 1px solid;">Schedule End Time </td>
                <td style="border-bottom: 1px solid;">Actual Start Time </td>
                <td style="border-bottom: 1px solid;">Actual End Time </td>
                <td style="border-bottom: 1px solid;" class="commentWidth">Notes</td>
            
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let element of dataSource.data">
                <td style="border-bottom: 1px solid;">{{element.jobDate}} </td>
                <td style="border-bottom: 1px solid;" class="text-nowrap">
                    <ng-container *ngIf="element?.staffName">
                        <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
                    </ng-container>                    
                </td>
                <td style="border-bottom: 1px solid;">{{element.customerName}} </td>
                <td style="border-bottom: 1px solid;">{{element.location}} </td>
                <td style="border-bottom: 1px solid;">{{element.jobId}} </td>
                <td style="border-bottom: 1px solid;">{{element.jobType}}</td>
                <td style="border-bottom: 1px solid;">{{element.scheduleStartTime}} </td>
                <td style="border-bottom: 1px solid;">{{element.scheduleEndTime}} </td>
                <td style="border-bottom: 1px solid;">{{element.actualStartTime}} </td>
                <td style="border-bottom: 1px solid;">{{element.actualEndTime}} </td>
                <td style="border-bottom: 1px solid;" class="commentWidth text-truncate text-nowrap">{{element.notes}}</td>
                 
            </tr>
        </tbody>
    </table>
    </div>


 <iframe #iframe style="display:none;"></iframe>