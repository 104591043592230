
<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="col-lg-5 col-md-5 header-name ps-0">{{ 'common.labels.Location' | translate }}</div>
  <div class="col-lg-3 col-md-4 col-sm-12 float-end">
    <mat-form-field appearance="outline" floatLabel="always"  class="filter-tech">
      <mat-label>{{'dashboard.labels.Filter by Patient/Technician name' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder=""
        autocomplete="false">
    </mat-form-field>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 float-end">
    <div class="row">
      <label class="mb-1 col-lg-2 col-md-2 justify-content-center
        align-self-center">{{ 'common.labels.Date' | translate }}</label>
      <div class="col-lg-10 p-0 col-md-10 col-sm-12 d-inline-block">
        <div class="mb-0 form-group selected-date text-start">
          <input [value]="date.value" (dateChange)="choosedDate($event)"
            class="form-control ps-2" matInput
            #nonworkingday [matDatepicker]="picker1" (focus)="picker1.open()">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 (closed)="nonworkingday.blur()"></mat-datepicker>
          <div class="arrows">
            <mat-icon (click)="arrowDateChange('left')">chevron_left</mat-icon>
            <mat-icon (click)="arrowDateChange('right')">chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row map-location">

  <div class="col-lg-12 col-md-12 col-sm-12">
    <label>Time</label>
    <ng5-slider [(value)]="value" [options]="options"
      (valueChange)="valueChange($event)"></ng5-slider>
  </div>

  <div class="clearfix"></div>

  <!-- <button *ngIf="userType !== 'Customer' && !disabled" class="autoAssign" color="primary" mat-raised-button (click)="autoAssign()" [disabled]="disabled">Auto Assign</button> -->

  <div id="mainmap" class="mt-4">
  </div>

  <div id="mapLegend1">
  </div>

</div>