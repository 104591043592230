  <div class="row mb-3">
    <div class="col-md-6 align-self-center">
      <h6 class="mb-0 dialog-header">{{ popupTitle | capitalize }}</h6>
    </div>
    <div class="col-md-6 text-end">
      <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
    </div>
  </div>

  <div class="row">

    <div class="w-100 mb-3">

      <div class="popup-viewport-body" *ngIf="notifyUsersList?.length > 0" clickOutside (clickOutside)="showPopup = ''">
        <div *ngFor="let user of notifyUsersList | searchFilter : searchText:['name', 'email']; let idx = index"
          class="example-item">
          <mat-card class="card-appointment mt-2 mb-2" *ngIf="user?.name && user?.email">

            <div class="row icon-round">
              <div class="col w_68px" *ngIf="!user?.workerLogo">
                <img class="avatarImg" [src]="'assets/common_images/profile.jpg'">
              </div>
              <div class="col w_68px" *ngIf="user?.workerLogo">
                <div class="avatarImg" [style.background-image]="'url(' + dashboarService.service.getSuperAdmin() + '/downloadFile/' + user.workerLogo + ')'">
                </div>
              </div>
              <div class="col w-auto">
                <div><span class="cursor-pointer pointer"><span appPopover [appPopoverData]="user" [placement]="'top'">{{user?.name}}</span></span></div>
                <small>{{user?.email}}</small>
              </div>
            </div>

          </mat-card>
        </div>
      </div>

      <div class="row d-flex align-items-center " *ngIf="showSpinner && notifyUsersList?.length === 0">
        <div class="col-lg-2 text-end spinner">
          <mat-spinner></mat-spinner>
        </div>
        <div class="col-lg-8 text-start"><span>Please Wait, Getting notified staff ...</span></div>
      </div>

      <div *ngIf="notifyUsersList?.length === 0 && !showSpinner" class="mt-4 text-danger">
        No notified staff found
      </div>

    </div>
  </div>