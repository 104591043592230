<div class="worker">
  <mat-icon class="cursor-pointer icon-right" (click)="cancel()">close</mat-icon>
  <div class="assign-popup" >
        <div class="text-center"> 
            <div class="mb-3">
              <img width="65px" src="assets/common_images/technician_popup_1.png">
            </div>
            <div>
              <p>No Clinician are Assigned</p>
            </div>
            <div>
              <small>
                  Do you want to split this appointment?
              </small>
            </div>
        </div>
        <hr>
        <div class="text-center">
            <button (click)="cancel()" mat-button class="me-4 no-btn">No</button>
            <button (click)="submit()" mat-raised-button class="yes-btn">Yes</button>
        </div>
  </div>
</div>