import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateAppointmentService } from '../create-appointment.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assigned-worker',
  templateUrl: './assigned-worker.component.html',
  styleUrls: ['./assigned-worker.component.scss']
})
export class AssignedWorkerComponent implements OnInit {

  otherEligibleWorker = [];
  eligibleWorker = false;
  save = true;
  eligible: FormGroup;
  assignedWorker = [];
  imageUrl = this.service.service.getSuperAdmin() + '/downloadFile/';
  jobDurationCode;
  technicianData = [];
  msgKey;
  selectedAppointment;
  splitAppointment: any;
  constructor(
    public dialogRef: MatDialogRef<AssignedWorkerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: CreateAppointmentService, private router: Router, public dialog: MatDialog, public translate: TranslateService,
  ) {
    this.translate.setDefaultLang(this.service.common.local.get('language'));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.service.common.progressLoader = false;
    //console.log(this.data);
    this.technicianData = this.data['data'];
    this.eligible = new FormGroup({
      'worker': new FormControl(null, Validators.required)
    })
  }
/* Name:srinivas
       use:return patient details*/
  openPatientDetails(data) {
    if (data == this.selectedAppointment) {
      this.selectedAppointment = '';
    } else {
      this.selectedAppointment = data;
    }
  }
  /* Name:srinivas
       use: close popup. */
  cancel() {
    this.dialogRef.close();
  }
/* Name:srinivas
       use: cancel eligible */
  cancelEligible() {
    this.eligibleWorker = false;
  }
/* Name:srinivas
       use: assign job to worker */
  getJobsAssignToWorkerByJobsCode(code) {
    try {
   
    this.service.getJobsAssignToWorkerByJobsCode(code, (data) => {
      ////console.log(data);
      if (data.responsecode == 200) {
        ////console.log(this.data['data']);
        const index = this.data['data'].findIndex(element => element.appointmentsDurationCode == data.data.appointmentsDurationCode);
        ////console.log(index);
        if (index !== -1) {

          ////console.log(this.data['data'][index]);
          this.data['data'][index] = data.data;
        }
        // this.data.data = data.data;
      } else {
        this.assignedWorker = [];
      }
    })   
    } catch (error) {
      
    }
  }
/* Name:srinivas
       use: edit worker details and set to control.*/
  onEditWorker(jobDuration) {
    this.service.common.progressLoader = true;
    this.jobDurationCode = jobDuration;
    this.otherEligibleWorker = [];
    try {
    
    this.service.getEligibleWorkerForJobsByJobDurationCode(jobDuration, this.data['scheduledDate'], this.data['fromdate'], this.data['toDate'], (data) => {
      this.eligibleWorker = true;
      this.service.common.progressLoader = false;
      if (data.responsecode == 200) {
        if (data.data.workerWithDistanceList.length > 0) {
          data.data.workerWithDistanceList.forEach(elementData => {
            if (elementData.worker) {
              elementData.worker.isAssigned = elementData.isAssigned;
              this.otherEligibleWorker.push(elementData.worker);
            }
          });
          setTimeout(() => {
            this.otherEligibleWorker.forEach((element) => {
              if (element.isAssigned == true) {
                this.eligible.controls['worker'].setValue(element.workerCode);
              }
            })
          }, 500)
        } else {
          this.otherEligibleWorker = [];
        }
        setTimeout(() => {
          //////console.log(this.otherEligibleWorker);
        }, 200)
      } else {
        this.otherEligibleWorker = [];
      }
    })  
    } catch (error) {
      
    }
  }
/* Name:srinivas
       use: save assign details of worker. */
  onSubmit() {
    if (this.eligible.valid) {
      const worker = {
        "workerCode": this.eligible.value.worker
      }
      try {
      
      this.service.assignToWorkerByJobDurationCode(this.jobDurationCode, worker, (res) => {
        ////console.log(res);
        if (res.responsecode == 200) {
          this.eligibleWorker = false;
          this.service.common.message = res.message;
          this.service.common.successMessage = true;
          ////console.log(this.jobDurationCode);
          this.getJobsAssignToWorkerByJobsCode(this.jobDurationCode);
        } else {
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })  
      } catch (error) {
        
      }
    }
  }
/* Name:srinivas
       use: cancel confirm popup. */
  redirectAppointment() {
    
    this.translate.get(['common.labels.Are you sure you want to cancel all assigned technician'])
      .subscribe(translations => {
        this.msgKey = translations['common.labels.Are you sure you want to cancel all assigned technician'];
        // ////console.log(this.msgKey);
      });
    // ////console.log(title);
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: this.msgKey }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {

        ////console.log(this.technicianData);
        this.service.common.progressLoader = true;
        let data = [];
        this.technicianData.forEach((element) => {
          data.push(element.appointmentsDurationCode);
        })
        ////console.log(data.toString());
        try {
        
        this.service.adminUnAssignAppointmentsByAppointmentsDurationCodeList(data.toString(), (response) => {
          ////console.log(response);
          this.service.common.progressLoader = false;
          if (response.responsecode == 200) {
            // if (this.data && this.data['url']) {
            //   this.router.navigate(['/dashboard']);
            // } else {
            //   this.router.navigate(['/dashboard/appointment']);
            // }
            this.dialogRef.close();
          } else {
            this.service.common.message = response.message;
            this.service.common.errorMessage = true;
          }
        })  
        } catch (error) {
          
        }
      }
    });

  }
  /* Name:srinivas
       use: close confirm popup */
  confirmAppointment() {
    // if (this.data && this.data['url']) {
    //   this.router.navigate(['/dashboard']);
    // } 
    this.dialogRef.close();
  }
/* Name:srinivas
       use:  split appointment .*/
  submit() {

    this.service.common.progressLoader = true;
    //console.log(this.data);
    try {
   
    this.service.adminGetAppointmentDetailsToSplit(this.data.appointmentsDurationCodeList,this.service.session.get('orgCode'), (data) => {
      //console.log(data);
      if (data.responsecode == 200) {
        this.splitAppointment = data.data;
        this.service.splitForm.appointmentsCode = this.splitAppointment.appointmentsCode;
        this.service.splitForm.appointmentsDurationCode = this.splitAppointment.appointmentsDurationCode;
        this.splitAppointment.procedureDetailsesList.forEach(element => {
          let obj: any = {};
          obj.durationHours = element.duration.split(':')[0];
          obj.durationMinute = element.duration.split(':')[1];
          obj.notes = element.notes;
          obj.organizationBaseProcedureCode = element.organizationBaseProcedureCode;
          obj.startTime = element.startTime;

          this.service.splitForm.procedureDetailsesList.push(obj);
        });
        ////console.log(this.service.splitForm);
        this.service.common.progressLoader = false;
        this.service.adminSplitAppointment(this.service.splitForm, (res) => {
          ////console.log(res);
          if (res.responsecode == 200) {
            this.service.common.progressLoader = false;
            this.dialogRef.close(res.message);
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        })
      } else {
        this.service.common.progressLoader = false;
      }
    })   
    } catch (error) {
      
    }

  }

  
}
