import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';
import { ProfileService } from '../profile.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'app-add-organizaionnote',
  templateUrl: './add-organizaionnote.component.html',
  styleUrls: ['./add-organizaionnote.component.scss']
})
export class AddOrganizaionnoteComponent implements OnInit {

  orgNotesForm: FormGroup;
  sendButton = false;
  submitted = true;
  allOrgNotes = [];
  dataSource = new MatTableDataSource();
  noteCount = 0;
  filter = [];
  readStatus;
  reset: boolean=true;
  constructor(
    public dialogRef: MatDialogRef<AddOrganizaionnoteComponent>,
    public common: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public service: ProfileService, private localStorage: SessionStorageService) { }

  onNoClick(): void {
    this.service.orgNoteData.note = null;
    this.dialogRef.close(this.readStatus);
  }
  ngOnInit(): void {
    //console.log(this.data);
    if (this.data) {

      this.allOrgNotes = this.data.orgNotes;
      this.filter = this.allOrgNotes.filter(option => option.note != null);
      if (this.filter.length > 0) {

        this.filter[this.filter.length - 1].isdelete = true;
      }

    }
    //this.getOrgNotes();
   this.setForm();

    this.allOrgNotes.forEach((element) => {
      if (element.isCommentRead == false) {
        const data = {
          "isNote": false,
          "isRead": true
        }
        this.service.setIsReadOrganizationWiseDocumentCommentByCode(element.orgWiseDocCommentCode, data, (res) => {
          if (res.responsecode == 200) {
            this.readStatus = true;
          }
        });
      }
    });
  }

  getOrgNotes() {
    try {
      //console.log('1111', this.submitted);
      this.service.getOrganizationWiseDocumentNoteCommentByOrganizationCode(this.localStorage.get('orgCode'), (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.allOrgNotes = response.data.organizationWiseDocumentCommentResponseList;
          setTimeout(() => {

            this.filter = this.allOrgNotes.filter(option => option.note != null);
            if (this.filter.length > 0) {
              this.filter[this.filter.length - 1].isdelete = true;
            }
          }, 100);
          //console.log('2222', this.submitted);

        }
        else {
          this.allOrgNotes = [];

          this.service.common.progressLoader = false;
        }
      });
    } catch (error) {

    }
  }



  geteditNote(element) {
    //console.log(element);
    this.service.orgNoteData.note = element.note;
    this.service.orgNoteData.orgWiseDocCommentCode = element.orgWiseDocCommentCode;
  }


  onSubmit() {
    this.sendButton = true;
    //console.log(this.orgNotesForm);
    this.service.common.progressLoader = true;
    if (this.orgNotesForm.value.note) {
      this.service.orgNoteData.organizationCode = this.localStorage.get('orgCode');
      this.service.saveAndUpdateOrganizationWiseDocumentNoteComment(this.localStorage.get('userCode'), this.service.orgNoteData, (res) => {
        if (res.responsecode == 200) {
          this.sendButton = false;
          this.setForm();
          this.reset = false;
          setTimeout(() => {
            this.reset = true;
          });
          // this.common.successMessage = true;
          //this.common.message = res.message;
          setTimeout(() => {
            // this.dialogRef.close('true');
            this.getOrgNotes();
            this.submitted = true;
          }, 1000);
          this.service.common.progressLoader = false;
          this.service.orgNoteData.note = null;
          this.service.orgNoteData.orgWiseDocCommentCode = null;
        } else {
          this.sendButton = false;
          this.submitted = false;
          this.common.errorMessage = true;
          this.common.message = res.message;
          this.service.common.progressLoader = false;
        }
      });

    }
    else {
      this.sendButton = false;
      this.submitted = false;
      this.service.common.progressLoader = false;
    }
  }
  setForm() {
    this.orgNotesForm = new FormGroup({
      'note': new FormControl(null, Validators.required)

    });
  }



  deleteNote(documentCode) {
    //console.log(documentCode);
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete this organization document note?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.common.progressLoader = true;
          this.service.softDeleteOrganizationWiseDocumentCommentByCode(documentCode, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              setTimeout(() => {
                this.getOrgNotes();
              }, 1000);
              //this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              //this.service.common.successMessage = true;
            } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })

        } catch (error) {
          //console.log(error)
        }

      }
    });
  }

}
