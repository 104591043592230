<!-- <p>change-time-card works!</p> -->
<div class="row">
  <div class="col-md-12 col-sm-12">
    <div class="row mb-4">
      <div class="col-md-6 align-self-center">
        <h6 class="skill-test pt-2">Add Time</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" mat-dialog-close></button>
        </div>
      </div>
    </div>  
    <form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
      <div class="w-100 d-flex">
          <div class="form-group col-md-7 col-sm-7 p-0 pe-1">
              <mat-form-field appearance="outline" floatLabel="always" >
                  <mat-label>Clockin Date</mat-label>
                  <input (focus)="picker.open()" matInput [matDatepicker]="picker" placeholder="Choose a date" [value]="currentDate" (dateChange)="onDateChange($event, 'startTime')" formControlName="startTime">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <span class="help-block err"
                  *ngIf="inputForm.controls.startTime.errors?.required && (!submitted || inputForm.controls.startTime.touched)">Clockin Date is required</span>
          </div>
          <div class="form-group col-md-5 col-sm-5 p-0 ps-1">
            <label class="positionAbsLabel">Clockin Time*</label>
            <input type="time" class="form-control timeInput" [ngClass]="{'is-invalid': checkinTime.invalid && (!submitted || checkinTime.touched)}" value="10:05 AM" [formControl]="checkinTime" (change)="onTimeChange($event, 'checkinTime')" placeholder="Choose a Time" />
            <div class="help-block err mt-3" *ngIf="checkinTime.hasError('required') && (!submitted || checkinTime.touched)">Clockin Time is required</div>
        </div>
      </div>
      
      <div class="w-100 d-flex">
          <div class="form-group col-md-7 col-sm-7 p-0 pe-1">
              <mat-form-field appearance="outline" floatLabel="always" >
              <mat-label>Clockout Date</mat-label>
              <input  [min]="inputForm.controls.startTime.value" (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder="Choose a date" [value]="currentDate" (dateChange)="onDateChange($event, 'endTime')" formControlName="endTime">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <span class="help-block err"
              *ngIf="inputForm.controls.endTime.errors?.required && (!submitted || inputForm.controls.endTime.touched)">Clockout Date is required</span>
          </div>
          <div class="form-group col-md-5 col-sm-5 p-0 ps-1">
            <label class="positionAbsLabel">Clockout Time*</label>
            <input type="time" id="timePickerInput" class="form-control timeInput" [ngClass]="{'is-invalid': checkoutTime.invalid && (!submitted || checkoutTime.touched)}" [formControl]="checkoutTime" (change)="onTimeChange($event, 'checkoutTime')" placeholder="Choose a Time" />
            <div class="help-block err mt-3" *ngIf="checkoutTime.hasError('required') && (!submitted || checkoutTime.touched)">Clockout Time is required</div>
        </div>
      </div>

      <div class="form-group col-md-12 col-sm-12 p-0">
          <!-- <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Comment</mat-label> -->
          <span class="pe-5">{{userType === 'Customer' ? 'Notify' : 'Approved'}} </span>
            <mat-slide-toggle class="ms-4" color="primary" formControlName="sendNotification" (change)="setIsApproved($event)"></mat-slide-toggle>
          <!-- <textarea autoClose matInput formControlName="isSubmitted" type="text" placeholder=""></textarea>
          </mat-form-field>           -->
      </div>  
      
      <!-- <div class="form-group col-md-12 col-sm-12 p-0">
          <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Comment</mat-label>
          <textarea autoClose matInput formControlName="comment" type="text" placeholder=""></textarea>
          </mat-form-field>
          <span class="help-block err"
          *ngIf="inputForm.controls.comment.errors?.required && (!submitted || inputForm.controls.comment.touched)">Comment is required</span>
      </div>   -->
               
    </form>

    <div class="d-flex offset-md-8 justify-content-end mt-4">
      <button mat-button mat-dialog-close class="cancel-btn-primary me-2">Cancel</button>
      <button type="submit" mat-raised-button color="primary" (click)="onSubmit()">Save</button>
    </div>
  </div>
</div>

