import { Component, OnInit, ViewChild, ElementRef, OnDestroy, TemplateRef, Injector } from '@angular/core';
 import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
 import { TranslateService } from '@ngx-translate/core';
 import { FormControl } from '@angular/forms';
 import { SessionStorageService } from 'angular-web-storage';
import { AdminCustomerReportService } from './admin-customer-report.service'; 
import { ApiRootService } from 'src/app/shared/api-root.service';
import { ChangeTimeCardComponent } from '../admin-customer-reports/change-time-card/change-time-card.component';
import { AddTimeCardComponent } from '../admin-customer-reports/add-time-card/add-time-card.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { AddUpdateNoteComponent } from 'src/app/components/common-notes/add-update-note/add-update-note.component';
import {  Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { debounce } from 'lodash';
import { MatSort } from '@angular/material/sort';
 

@Component({
  selector: 'admin-customer-reports',
  templateUrl: './admin-customer-reports.component.html',
  styleUrls: ['./admin-customer-reports.component.scss']
})
export class AdminCustomerReportsComponent   implements OnInit , OnDestroy {

  displayedColumns = [
    'Staff_Name',
    'Custome_Name',
    'Job_Id',
    'Job_Site',
    'Clock_In_Time',
    'Clock_Out_Time',    
    'Total_Time',
    'Paid_Time',
    'comment',
    'status',
    'action'
    ];

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ReviewTimeCard_List : any[];
  //startDate = moment().subtract(1, 'months').toDate();
  startDate = moment().toDate();
  endDate= moment().toDate();
  searchText: any;
  customerAddressList : any[] ;
  FilteredCustomerAdd : any[] ;
  selectedOptions = new FormControl([]);
  searchControl = new FormControl();
  page: any = 0;
  limit = 30;
  baseURL: any;
  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();

  @ViewChild("view_details") view_details: TemplateRef<any>;

  view_details_obj : any | null;
  is_loading : boolean = false ;
  
  constructor(
    public dialog: MatDialog,
    private _translateSVC: TranslateService,
    private _storageSVC: SessionStorageService,
    private service : AdminCustomerReportService,
    private _apiService: ApiRootService,
    private _commonAPI_SVC : CommonApiService,
    private _commonSVC : CommonService,
    ) 
    {
     this.applyFilter = debounce(this.applyFilter, 600);
    this._translateSVC.setDefaultLang(this._storageSVC.get('language'));
  }

  onScroll() {    
    this.page = this.page + 1;
    this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate, 'scroll', this.searchText);
  }

  ngOnInit() 
  {
  this.baseURL = this._apiService.getSuperAdmin() + "/downloadFile/"
  // this.page = 0;
  // this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
  
  this._commonAPI_SVC.get_calendar_setting_by_setting_name(this._storageSVC.get('orgCode')).subscribe((res:any) => {
     this.startDate = res?.setting_start_of_week ?  moment().day(res?.setting_start_of_week).toDate() : moment().toDate();
    this.page = 0 ;
    this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
  });

  this.hasPermission =  this.service.common.getBtnEventPermissions("Time Card");
  this.subToPermissionChanged();
  }

  onStartDateChange(event:any) {
    this.searchText = '';
    this.page = 0;
    this.dataSource = new MatTableDataSource();
    // Apply the sorting accessor for case-insensitive sorting
    this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
    this.dataSource.sort = this.sort;

    if(event.value){
      this.startDate = event.value;
      this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
    }
  }

  onEndDateChange(event:any) {
    this.searchText = '';
    this.page = 0;
    this.dataSource = new MatTableDataSource();
    // Apply the sorting accessor for case-insensitive sorting
    this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
    this.dataSource.sort = this.sort;

    if(event.value){
      this.endDate = event.value;
      this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
    }
  }


  filterAddress(event:any){
    if(this.customerAddressList && this.customerAddressList.length > 0)
  this.FilteredCustomerAdd =  this.customerAddressList.filter(option => option.location.toLowerCase().includes(event));
  }

  applyFilter(event: string) {
    this.page = 0;
    this.searchText = event.trim().toLowerCase();
    this.dataSource = new MatTableDataSource();
    // Apply the sorting accessor for case-insensitive sorting
    this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
    this.dataSource.sort = this.sort;

      if(this.searchText){
        this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate, null, this.searchText);
      } else {
        this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
      }
  }
 

 getAdminTimeClockByPage(page, limit, fromDate:Date= null, toDate:Date = null, scroll = null, search = null) {
  // console.log('GetAdminTimeClockByPage: ', page, limit, fromDate, toDate, scroll, search);

  // if (!scroll && !search) {
  //   this.service.common.progressLoader = true;
  // }
  // else if (scroll) {
  //   this.service.common.infiniteLoader = true;
  // }

  try {
      let formatedFromDate = moment(fromDate).format('YYYY-MM-DD');
      let formatedToDate = moment(toDate).format('YYYY-MM-DD');
     // this.service.common.infiniteLoader = true;
      this.is_loading = true ;
      this.service.getAdminTimeClockByPage(this._storageSVC.get('orgCode'), formatedFromDate, formatedToDate, page, limit, search, (response) => {
      // this.service.common.progressLoader = false;
      // //console.log('getAllTeamsByPage: ', response)
      if (response.responsecode == 200) {
        if (scroll) {
          // let arrayData = this.dataSource.data;
          let arrayData = this.ReviewTimeCard_List;
          response.data.forEach(element => {
            arrayData.push(element)
          });
          
          // setTimeout(() => {
            this.dataSource = new MatTableDataSource(arrayData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.ReviewTimeCard_List = arrayData;
            // this.service.common.progressLoader = false;
             this.service.common.infiniteLoader = false;
            //  console.log('ReviewTimeCard_List_scroll: ', this.ReviewTimeCard_List);
          // }, 100);
          this.is_loading = false ;
        } 
        else
         {
          //////console.log(response.data);
          setTimeout(() => {
            this.service.common.progressLoader = false;
            this.ReviewTimeCard_List = response.data;
            this.dataSource = new MatTableDataSource(response.data);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.is_loading = false ;
            // console.log('ReviewTimeCard_List: ', this.ReviewTimeCard_List);
          }, 300)

        }

      } else {
        this.is_loading = false ;
        if (!scroll) {
          this.ReviewTimeCard_List = [];
          this.dataSource = new MatTableDataSource([]) ;
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sort;
        }
        //  else {
        //   this.service.common.infiniteLoader = false;
        // }
        // this.service.common.progressLoader = false;
       // this.service.common.infiniteLoader = false;
      }
    })
  } catch (error) {
    this.is_loading = false ;
    //console.log(error)
  }

}

     /* Name:Vivek Chauhan
    use: for Approve Customer*/
    approveCustomer(customer: any) {

      if(customer){
        let approveStatus = 'approved';
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '350px',
          data: { msg: 'Are you sure you want approve this request' }
        });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.toLowerCase() === 'ok'){
      //console.log('approveCustomer:', customer);
      let reqdata = {
        "checkinDateTime": null, 
        "checkoutDateTime": null,
        "clinicianCheckinDetilsCode": customer.clinicianCheckinDetilsCode,
        "comment": null,
        "organizationCode": this._storageSVC.get('orgCode'),
        "sendOnlyNotification": true
      }
      //console.log('reqdata', reqdata);
      this.service.common.progressLoader = true;
      this.service.sendAdminActionTimeSheet(reqdata, (response) => {
              
          if (response.responsecode == 200) {
            this.page = 0;
            this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
            this.service.common.message = response.message;
            this.service.common.progressLoader = false;
            this.service.common.successMessage = true;
            // this.getListDataByPage();
          } else {
            this.service.common.message = response.message;
            this.service.common.progressLoader = false;
            this.service.common.successMessage = true;
          }
    })
    }           
    });
      }
      
    }

    /* Name:Vivek Chauhan
    use: for open update/change time card popup*/
    changeTimeCard(data: any) {
      // //console.log('onEditData:', data);
      const dialogRef = this.dialog.open(ChangeTimeCardComponent, {
        width: '500px',
        disableClose: true,
        data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.page = 0;
        this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
      }
    });
    }

    /* Name:Vivek Chauhan
    use: for open Add time card popup*/
    addTimeCard(data: any) {
      // //console.log('onEditData:', data);
      const dialogRef = this.dialog.open(AddTimeCardComponent, {
        width: '500px',
        disableClose: true,
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.page = 0;
          this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
        }
      });
    } 


    private selectedData_forNotes : any ;

addNote(data:any){
  this.selectedData_forNotes = data;
  // console.log('Data: ', this.selectedData_forNotes);
    this.addUpdateNotes(data.noteList);
}


addUpdateNotes(noteArray:[])
{
  const dialogRef = this.dialog.open(AddUpdateNoteComponent, { 
    width: '50%',
    disableClose: true,
    panelClass: 'popup-pos',
    data: {
      noteArray: noteArray,
     }
  });
 

  dialogRef.afterClosed().subscribe((result) => {
     if(result)
    this.saveAndMapNotesEntity(result);
  });

}


saveAndMapNotesEntity(data)
{
  const payload ={
    "entityName": "Timecard",
    "entityUuid": this.selectedData_forNotes.clinicianCheckinDetilsCode ,
    "isPublic": data.isPublic,
    "note": data.note,
    "organizationCode": this._storageSVC.get('orgCode')
  }
  // console.log('Payload:', payload);
  // return;
  this.service.common.progressLoader = true;
  this._commonAPI_SVC.saveAndMapNotesEntity(payload).subscribe((res:any) =>{
    this.service.common.progressLoader = false;
   if(res.responsecode == 200)
    {
       this.service.common.message = res.message;
         this.service.common.successMessage = true;
        this.page = 0;
        this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
         this.service.common.resetNotesRequestForm();
    }
    else{
        this.service.common.message = res.message;
         this.service.common.errorMessage = true;
    }

  })
}

subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
      this.hasPermission =  this.service.common.getBtnEventPermissions("Time Card");
     }
 })
}

ngOnDestroy(): void {
  this.clearObs.next(''),
  this.clearObs.complete();
}


fn_view_details(data:any)
{
  this.view_details_obj = data ;
  
  const dialogRef = this.dialog.open(this.view_details, {
    width: '450px',
    disableClose: true,
    //panelClass : 'popup-pos'
   });

  dialogRef.afterClosed().subscribe(result => {
    this.view_details_obj = null ;
    
  });
}


// get_setting_value_by_setting_name()
// {
//   this.page = 0;

//   this._commonAPI_SVC.getSettingNameDetailsBySettingName(settings_keys.CALENDAR_VIEW, this._storageSVC.get('orgCode')).subscribe((res:any) =>{
//     if (res.responsecode == 200) {
//       if (res.settingValue === "Start of week") 
//       {
//         this._commonAPI_SVC.getSettingNameDetailsBySettingName(settings_keys.START_OF_WEEKS, this._storageSVC.get('orgCode')).subscribe((res:any) =>{
//           if (res.responsecode == 200) {
//             // this.Start_of_week = res.settingValue;
//             this.startDate =  moment().day(res.settingValue).toDate();
//             this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
//           }
//           else{
//             this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
//           }
//         })
//       }
//     }
//     else{
//       this.getAdminTimeClockByPage(this.page, this.limit, this.startDate, this.endDate);
//     }
//   })
// }




}
