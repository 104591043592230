import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AppointmentService } from '../providers-dashboard/appointment/appointment.service';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from '../providers-dashboard/patient/patient.service';
import { CreateAppointmentService } from '../providers-dashboard/appointment/create-appointment/create-appointment.service';
import { NewAppointmentService } from '../technician-dashboard/new-appointment/new-appointment.service';
import { TranslateService } from '@ngx-translate/core';
import { VersionCheckService } from 'src/app/version-check.service';
import { FormControl } from '@angular/forms';
import { Observable, Subject, interval, map, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { LicenceComponent } from '../providers-dashboard/licence/licence.component';
import { AllappointmentPopupComponent } from '../providers-dashboard/appointment/allappointment-popup/allappointment-popup.component';
import { SplitAppointmentComponent } from '../providers-dashboard/appointment/split-appointment/split-appointment.component';
import { ProcedureDetailComponent } from '../providers-dashboard/appointment/procedure-detail/procedure-detail.component';
import { AllAssignAppointmentComponent } from '../providers-dashboard/appointment/create-appointment/all-assign-appointment/all-assign-appointment.component';
import { MatDrawer } from '@angular/material/sidenav';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
import { AddShiftComponent } from '../providers-dashboard/shift-segment/add-shift/add-shift.component';
import { AddUserComponent } from '../providers-dashboard/user-registration/add-user/add-user.component';
import { AssignedWorkerComponent } from '../providers-dashboard/appointment/create-appointment/assigned-worker/assigned-worker.component';
import { DashboardPatientDataComponent } from '../providers-dashboard/patient/dashboard-patient-data/dashboard-patient-data.component';
import { UpdateAppointmentComponent } from '../providers-dashboard/appointment/update-appointment/update-appointment.component';
import { UpdateAppointmentSeriesComponent } from '../providers-dashboard/appointment/update-appointment-series/update-appointment-series.component';
import { AssignAppointmentComponent } from '../providers-dashboard/appointment/assign-appointment/assign-appointment.component';
import { ShowRoutesComponent } from '../providers-dashboard/appointment/show-routes/show-routes.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { CancelAppointmentComponent } from '../providers-dashboard/appointment/cancel-appointment/cancel-appointment.component';
import { NotesPopupComponent } from '../providers-dashboard/appointment/notes-popup/notes-popup.component';
import { ArrivalDepartureComponent } from '../providers-dashboard/arrival-departure/arrival-departure.component';
import { RejectAppointmentComponent } from '../technician-dashboard/new-appointment/reject-appointment/reject-appointment.component';
import { CommonService } from "src/app/shared/common.service";
import { CheckOutMissedService } from 'src/app/components/check-out-missed-list/check-out-missed-list.service';
import { RequestNewJobService } from '../customer-dashboard/cust-request-new-job/request-new-job.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IBtnControlsPermission, ICalendarDate } from 'src/app/shared/interface/core.interface';
 

@Component({
  selector: 'app-organization-dashboard',
  templateUrl: './organization-dashboard.component.html',
  styleUrls: ['./organization-dashboard.component.scss'],
  providers: [DatePipe, CheckOutMissedService, RequestNewJobService]
})

export class OrganizationDashboardComponent implements OnInit {
  openModalFor: string;
  isShortcutModalOpen: boolean = false;
  dialogRef: any;
  @ViewChild("appointmentDialogRef") appointmentDialogRef: TemplateRef<any>;
  @ViewChild("shiftDetailPopup") shiftDetailPopupRef: TemplateRef<any>;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild('notifypopup') notifypopup: TemplateRef<any>
  @ViewChild('assign_popup') assign_popup: TemplateRef<any>
  @ViewChild('statusDetailsPopUp') statusDetailsPopUp: TemplateRef<any>

  popup_reference: any;
  @Input() locale: string;
  @Input() isAvailableLogic: any = true;
  @Output() emitSelectedDate = new EventEmitter<any>();
  navDate: any;
  weekDaysHeaderArr: Array<string> = [];
  gridArr: Array<any> = [];
  // gridArrNew: Array<any> = [];
  gridArrNew: ICalendarDate[] = [];
  selectedDate: any;
  status = 'pending,assigned,accepted,inprogress,completed,expired,late or cancelled,cancelled';
  page = 0;
  limit = 10;
  code: any;
  currentDate = new Date();
  appointmentData = [];
  startDate: any;
  endDate: any;
  selectedMonth: any;
  // statusCount = [];
  statusCount: any = [];
  shiftStatusCount = [];
  shiftStatusList = [];
  clickedStatus: any;
  buttonStatus = '';
  selectDate;
  monthcolor = null;
  monthper = 0;
  buttonDisabled = false;
  currentSelectedDate;
  autoAssignStartDate;
  autoAssignEndDate;
  currentDateSelected = '';
  ShowSelectedStart;
  ShowSelectedEnd;
  startTime = '00:00:00';
  endTime = '23:59:59';
  yearMonthDateFormat = 'YYYY-MM-DD';
  monthDateYear = 'MM-DD-YYYY'
  dateMonthYear = 'DD-MM-YYYY'
  dateMonthNameFormat = 'DD MMMM';
  dateShotMonthName = 'DD MMM';
  assignmonthper = 0;
  acceptmonthper = 0;
  assignmonthcolor: any;
  acceptmonthcolor: any;
  finalmonthper = 0;
  showPopup;
  appointmentchange;
  welcome;
  user;
  showImg;
  imgLength;
  searchTerm;
  searchText;
  appointmentfilter = [];
  isShowLoader: boolean = true;
  isShiftActive = {};
  popupTitle: string = 'Title';
  CalendarCurrent_SlectedDate = new Date();
  Calendar_header;
  operationalStatusList: any = {};
  distributionList: any = [];
  distributionList_Filter: any = [];
  distributionSeachInput: FormControl = new FormControl();
  staffSeachInput: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  selectedDistribution: any = [];
  enableLoading: boolean = false;
  selected_appoinmentDurationCode: string;
  selectedAppointment: any;
  selectedSkills: any = []
  eligibleStaffsList: any = [];
  filtered_eligibleStaffsList: any = [];
  isLoading: boolean = false;
  is_backdropBackground: boolean = false;
  ObjStattusCount: any = {};
  selectedStaff: any;
  is_allSevenDaysData: boolean = false;
  missedClockOut_Count: number;
  requestCancel_Count:number | string ;

  newJobRequest_Count: number;
  tabAtciveFor: string = 'Requested';

  @ViewChild('viewDashboardStatus') viewDashboardStatus: TemplateRef<any>;
  @ViewChild('viewClockInClockOut') viewClockInClockOut: TemplateRef<any>;

  permissionFor_NewJobShortCut:IBtnControlsPermission;
  permissionFor_NewShiftShortCut:IBtnControlsPermission;
  permissionFor_CustControlShortCut:IBtnControlsPermission;
  permissionFor_ApproveLeaveControlShortCut:IBtnControlsPermission;
  permissionFor_MissedClockControlShortCut:IBtnControlsPermission;
  permissionFor_ChatControlShortCut:IBtnControlsPermission;

  constructor(
    public dashboarService: DashboardService,
    public datepipe: DatePipe,
    public router: Router,
    public appointmentService: AppointmentService,
    public dialog: MatDialog,
    public patientService: PatientService,
    public createPatientService: CreateAppointmentService,
    public newAppointmentService: NewAppointmentService,
    public translate: TranslateService,
    public renderer: Renderer2,
    public versionCheckService: VersionCheckService,
    public common: CommonService,
    private _checkOutMissedSVC: CheckOutMissedService,
    private _newJobReqSVC : RequestNewJobService
  ) {
    
    this.translate.setDefaultLang(this.dashboarService.session.get('language'));
    
    this.user = this.dashboarService.session.get('username');

    // this.common.startRefreshInterval();
    // this.subscribeDashboardRefresh();
  }


  ngOnInit() {

    this.getGlobalLeaveCount(this.dashboarService.session.get('orgCode'));

   // this.getGlobalCancelJobRequestCount(this.dashboarService.session.get('orgCode'));

    this.dashboarService.common.getpercentData();
    this.selectDate = moment(this.currentDate).format('D');
    this.selectedDate = moment(this.currentDate, this.yearMonthDateFormat).format(this.dateMonthNameFormat);
    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    this.currentDateSelected = this.selectedDate;
    let firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    this.startDate = moment(firstDay, this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    this.endDate = moment(lastDay, this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    ////console.log(this.startDate);
    this.ShowSelectedStart = this.currentSelectedDate;
    this.ShowSelectedEnd = this.currentSelectedDate;
    this.buttonStatus = 'today';
    moment.locale(this.locale);
    this.navDate = moment();
    this.makeHeader();
    //this.getLoadData();
    this.getSevenDays();
    //this.getSettingNameByName();
    // this.getSevenDaysFromCurrentDate();
    this.getDashboardSchedulingStatus(this.currentSelectedDate + ' ' + this.startTime, this.currentSelectedDate + ' ' + this.endTime)
    // this.createBlanlGrid();
    // this.getAppointmentData(this.dashboarService.session.get('orgCode'), this.status, this.startDate, this.endDate);
    //this.getAppointmentsofSelectedDate(this.currentSelectedDate + ' ' + this.startTime, this.currentSelectedDate + ' ' + this.endTime);
    this.getShiftStatusCount(this.currentSelectedDate);
    const month = new Date(moment(this.navDate, this.yearMonthDateFormat).format(this.yearMonthDateFormat));
    let firstautoAssignStartDate = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastautoAssignEndDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    this.autoAssignStartDate = moment(firstautoAssignStartDate, this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    this.autoAssignEndDate = moment(lastautoAssignEndDate, this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    this.getOperationalStatus();
    this.getDistributionList();

   // this.getMissedClockOutCount();
    this.webSocket_Dashboard();
   // this.getRequestedNewJobsCount();

    this.permissionFor_NewJobShortCut =  this.common.getBtnEventPermissions('Job Scheduling');
    this.permissionFor_NewShiftShortCut =  this.common.getBtnEventPermissions('Shifts');
    this.permissionFor_CustControlShortCut =  this.common.getBtnEventPermissions('Customers');
    this.permissionFor_ApproveLeaveControlShortCut =  this.common.getBtnEventPermissions('Time Off');
    this.permissionFor_MissedClockControlShortCut =  this.common.getBtnEventPermissions('Time Card');
    this.permissionFor_ChatControlShortCut =  this.common.getBtnEventPermissions('Chat');

    this.common.getnonWorkingDayslist();
  }
 
 

  getLoadData() {
    try {
      this.dashboarService.adminGetAllAppointmentsByOrganizationCodeWithFilterDateTimeForCurrentDate(this.dashboarService.session.get('orgCode'), (response) => {
        if (response.responsecode == 200) {
          this.getRightSideData(response);
        } else {
          this.dashboarService.common.progressLoader = false;
        }
      })
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }

  }

  openShortcutModal(isShortcutModalOpen: boolean, openModalFor: string, templateRef: any) {
    this.openModalFor = null;
    //console.log('openShortcutModal_1: ', isShortcutModalOpen, openModalFor);
    if (openModalFor === 'New Job') {
      this.dialogRef = this.dialog.open(templateRef, { width: '80%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRef.afterClosed().subscribe(result => {
        //console.log('AfterClosed: ', result);
      });
    }
    if (openModalFor === 'New Shift') {
      this.dialogRef = this.dialog.open(AddShiftComponent, { width: '50%', disableClose: true });
    }
    if (openModalFor === 'New Location') {
      this.dialogRef = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRef.afterClosed().subscribe(result => {
        //console.log('AfterClosed: ', result);
      });
    }
    if (openModalFor === 'New Contact') {
      this.dialogRef = this.dialog.open(AddUserComponent, { width: '60%', disableClose: true, data: { isShortcutModalOpen: isShortcutModalOpen, dataForAdd: true } });
    }
    if (openModalFor === 'Notify') {

    }
    if (openModalFor === 'Send Chat') {

    }
    if (openModalFor === 'Post Notice') {

    }
    if (openModalFor === 'Approve Leave') {
      this.dialogRef = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRef.afterClosed().subscribe(result => {
        //console.log('AfterClosed: ', result);
      });
    }

    if (openModalFor === 'Missed Clock-Out') {
      this.dialogRef = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRef.afterClosed().subscribe(result => {
        //console.log('Missed Clock-Out popup after closed: ', result);
      });
    }

      if (openModalFor === 'RequestedNewJobs') 
      {
        this.tabAtciveFor = 'Requested';
      this.dialogRef = this.dialog.open(templateRef, { width: '70%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      // this.dialogRef.afterClosed().subscribe(result => {
      //   //console.log('Missed Clock-Out popup after closed: ', result);
      // });
    }

    if (openModalFor === 'CancelJobApprovelRequest') {
      this.dialogRef = this.dialog.open(templateRef, { width: '75%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      // this.dialogRef.afterClosed().subscribe(result => {
      //   //console.log('Missed Clock-Out popup after closed: ', result);
      // });
    }

  }

  closePopUp() {
    this.dialogRef.close();
  }

  /* 
  
  use: passed response data in this function to convert date time to show on right side of dashboard page.
  */

  getRightSideData(responsedata) {
    let compareDate = '';
    responsedata.data.forEach(element => {

      const startdate = element.appointmentStartDate.split(' ')[0];
      let startDate;
      if (element.dateFormat == 'MM-dd-yyyy') {
        startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'MM-DD-YYYY').format(this.dateShotMonthName);
        //////console.log(startDate);
      } else if (element.dateFormat == 'dd-MM-yyyy') {

        startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format(this.dateShotMonthName);
        //////console.log(startDate);
      } else {
        startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'YYYY-MM-DD').format(this.dateShotMonthName);
        //////console.log(startDate);
      }

      let newDate = startDate
      element.appDate = startDate;

      if (newDate != compareDate) {
        element.date = newDate;
        compareDate = newDate;
      } else {
        element.date = '';
      }
    });
    // this.appointmentData = responsedata.data;
    this.appointmentData = responsedata.data;

    this.appointmentData.forEach((element) => {
      if (element.actualStartTime) {
        if (element.dateFormat == 'dd-MM-yyyy') {
          element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[0] + '-' + element.actualStartTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
          ////console.log(element.arrivalTimeDate);
        } else if (element.dateFormat == 'MM-dd-yyyy') {
          element.arrivalTimeDate = moment(element.actualStartTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
          ////console.log(element.arrivalTimeDate);
        } else {
          element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[2] + '-' + element.actualStartTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
          ////console.log(element.arrivalTimeDate);
        }
      }
      if (element.actualEndTime) {
        if (element.dateFormat == 'dd-MM-yyyy') {
          element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[0] + '-' + element.actualEndTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
          ////console.log(element.departureTimeDate);
        } else if (element.dateFormat == 'MM-dd-yyyy') {
          element.departureTimeDate = moment(element.actualEndTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
          ////console.log(element.departureTimeDate);
        } else {
          element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[2] + '-' + element.actualEndTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
          ////console.log(element.departureTimeDate);
        }
      }
    })

    this.appointmentfilter = this.appointmentData;
    //console.log('GetApponmentsD2: ', this.appointmentfilter)
  }

  /* 
  
  use: get data to fill blank calender grid by no. of percentage and color based on that percentage.  
  */


  getAppointmentData(code, status, startDate, endDate, selectedStatus = null, isrefresh = null) {
    // this.dashboarService.common.progressLoader = true;
    //////console.log(selectedStatus);
    if (selectedStatus === "month") {
      this.buttonStatus = '';
      this.gridArr = [];
      //this.appointmentData = [];
      this.selectDate = '';
    }
    // this.dashboarService.getJobForDashboardByOrganizationCode(code, startDate, endDate, (response) => {
    //   if (response.responsecode == 200) {
    // this.statusCount = response.data.filter(item => item.status !== 'cancelled' && item.status !== 'expired' && item.status !== 'rejected');
    try {
      this.dashboarService.getAllJobsByOrganizationCodeWithFilterByPage(code, status, startDate, endDate, (response) => {
        //////console.log(response);
        if (response.responsecode == 200) {
          // //console.log(isrefresh);
          // if (!isrefresh && selectedStatus !== "month") {
          // this.monthper = Math.round(response.data.fullfillmentAverage - (response.data.assignPercent + response.data.acceptPercent));
          //this.assignmonthper = Math.round(response.data.assignPercent);
          //this.acceptmonthper = Math.round(response.data.acceptPercent);
          ////console.log(!isrefresh);
          ////console.log(this.finalmonthper, 'jhk');
          // this.finalmonthper = Math.round(response.data.fullfillmentAverage);
          // //console.log(this.finalmonthper);
          //}

          //////console.log(this.monthper);
          const colodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(this.monthper));
          const assigncolodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(this.assignmonthper));
          const acceptcolodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(this.acceptmonthper));


          if (colodIndex !== -1) {
            this.monthcolor = this.dashboarService.common.dashboardColorData[colodIndex].class;
          }

          if (assigncolodIndex !== -1) {
            this.assignmonthcolor = this.dashboarService.common.dashboardColorData[assigncolodIndex].class;
          }

          if (acceptcolodIndex !== -1) {
            this.acceptmonthcolor = this.dashboarService.common.dashboardColorData[acceptcolodIndex].class;
          }
          this.getNewAppointments(response.data.percentageCalculationList, selectedStatus, startDate);
          this.dashboarService.common.progressLoader = false;
        } else {
          // this.monthper = 0;
          // this.monthcolor = null;
          // if (selectedStatus !== 'month') {
          //   if (this.appointmentData.length > 0 && this.gridArr.length > 0) {
          //     ////console.log(startDate);
          //     this.appointmentData.forEach((appoint) => {
          //       if (moment(startDate).format(this.dateShotMonthName) === appoint.key) {
          //         appoint.iscurrent = true;
          //         this.selectDate = moment(startDate).format('DD');
          //         ////console.log(this.selectDate);
          //       } else {
          //         appoint.iscurrent = false;
          //       }
          //     });
          //     ////console.log(this.appointmentData);
          //     this.getFilterDataByWeeks(startDate, endDate);
          //     this.dashboarService.common.progressLoader = false;
          //   } else {
          //     if (this.appointmentData.length === 0 && this.gridArr.length === 0) {
          this.gridArr = [];
          this.createBlanlGrid();
          //     } else {
          //       this.dashboarService.common.progressLoader = false;
          //     }
          //   }
          // } else {
          //   ////console.log(this.gridArr.length);
          //   if (this.appointmentData.length === 0 && this.gridArr.length === 0) {
          //     this.createBlanlGrid();
          //   } else {
          //     this.dashboarService.common.progressLoader = false;
          //   }
          // }
          this.dashboarService.common.progressLoader = false;
        }
      })
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }

    //   }
    // })

  }

  /*
      use: getting new appointments */
  getNewAppointments(allData, selectedStatus?, startDate = null) {


    if (allData.length > 0) {
      allData.forEach(element => {
        this.gridArrNew.forEach((inner_ele) => {

          if (inner_ele.date == element.date) {
            const colodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(element.fullfillmentRatio));
            // ////console.log(colodIndex, element.fullfillmentRatio);
            if (colodIndex !== -1) {
              inner_ele.dataPercent = this.dashboarService.common.dashboardColorData[colodIndex].class;
            } else {
              element.dataPercent = 0;
            }
          }
          element.dateFormat = element.dateFormat;
        })

      });
    }

    this.getHeader();
    this.dashboarService.common.progressLoader = false;

  }


  resetCalendarGridColor() {
    this.gridArrNew.filter(ele => ele.dataPercent = '')
  }

  /* this method is for getting next 7 days from current date*/

  // getSevenDaysFromCurrentDate(){
  //   const weekdays = [];

  //   let currentDate 

  //   if(this.Start_of_week)
  //   {
  //     let startOfWeek = moment().day(this.Start_of_week).format("MM-DD-YYYY");
  //    // currentDate =  moment(currentDate).format("MM-DD-YYYY")
  //    // //console.log("vivek_pr ", currentDate);
  //     currentDate = new Date(startOfWeek);
  //   }
  //   else
  //   {
  //     currentDate = new Date (this.CalendarCurrent_SlectedDate);
  //   }

  //      for (let i = 0; i < 7; i++) {
  //     weekdays.push({
  //       'dateObj' : new Date(currentDate),
  //       'date' : moment(new Date(currentDate)).format("MM-DD-YYYY"),
  //       "currentDate" : moment(new Date(currentDate)).format("MM-DD-YYYY"),
  //       'value' : moment(new Date(currentDate)).format("DD"),
  //       'dayName' : new Date(currentDate).toLocaleDateString("local", { weekday: 'short' }),
  //       "available" : true
  //       });

  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   this.gridArrNew =  weekdays;
  //   this.getHeader();

  //   }

  getSevenDays() {
      this.common.getSevenDaysByStartOfWeek((days:any) => {
        this.gridArrNew = days;
        this.getHeader();
      });
   
  }

  getHeader() {
    const firstDate = new Date();
    var first = this.gridArrNew[0].yearMonthDateFormat;
    var last = this.gridArrNew[6].yearMonthDateFormat;   // this array length will be seven

    var short_1 = new Date(first).toLocaleString('default', { month: 'short' });
    var short_2 = new Date(last).toLocaleString('default', { month: 'short' });

    if (short_1 != short_2) {
      this.Calendar_header = short_1 + '-' + short_2 + ' ' + firstDate.getFullYear()
    }
    else {
      this.Calendar_header = short_1 + ' ' + firstDate.getFullYear()
    }
  }

  resetFields() {
    this.CalendarCurrent_SlectedDate = new Date;
    this.ShowSelectedStart = moment().format(this.yearMonthDateFormat);
    this.ShowSelectedEnd = moment().format(this.yearMonthDateFormat);
    this.currentDate = new Date();
    this.appointmentData = [];
    this.appointmentfilter = [];
    this.searchTerm = '';
  }

  onClickAssign(data: any) {

    this.popup_reference = this.dialog.open(LicenceComponent, {
      position: { right: '0' },
      maxWidth: '70vw',
      maxHeight: '100vh',
      height: '100vh',
      width: '70%',
      disableClose: true,
      data: {}
      // panelClass: 'full-screen-modal'
    });

    this.popup_reference.afterClosed().subscribe(result => {

      if (result) {
        // //console.log('ClosePopupResult : ', result); 
      }
    });

  }


  getNextSevenDays() {
    // this.gridArrNew =  this.getSevenDaysFromCurrentDate()
    // let temap_arr = this.gridArrNew;
    // let firstInd = this.gridArrNew[0];
    // let lastInd = temap_arr.reverse()[0];   // getting last index 

    // const weekdays = [];
    // const currentDate = new Date(lastInd.date);
    // currentDate.setDate(currentDate.getDate() + 1);


    // for (let i = 0; i < 7; i++) {
    //   weekdays.push({
    //     'dateObj': new Date(currentDate),
    //     'date': moment(new Date(currentDate)).format("MM-DD-YYYY"),
    //     "currentDate": moment(new Date(currentDate)).format("MM-DD-YYYY"),
    //     'value': moment(new Date(currentDate)).format("DD"),
    //     'dayName': new Date(currentDate).toLocaleDateString("local", { weekday: 'short' }),
    //     "available": true
    //   });

    //   currentDate.setDate(currentDate.getDate() + 1);
    // }

    // this.gridArrNew = weekdays;

    this.gridArrNew = this.common.getNextSevenDays();
    this.getHeader();
    this.getStatus(this.status, this.clickedStatus)
  }


  getPreviousSevenDays() {

     // preventing togo back from current date
    // if (moment(new Date()).format(this.monthDateYear) == moment(new Date(this.gridArrNew[0].date)).format(this.monthDateYear)) {
    //   return false;
    // }

    // let firstInd = this.gridArrNew[0];
    // const weekdays = [];
    // const currentDate = new Date(firstInd.date);
    // currentDate.setDate(currentDate.getDate() - 1);

    // for (let i = 0; i < 7; i++) {
    //   weekdays.push({
    //     'dateObj': new Date(currentDate),
    //     'date': moment(new Date(currentDate)).format("MM-DD-YYYY"),
    //     "currentDate": moment(new Date(currentDate)).format("MM-DD-YYYY"),
    //     'value': moment(new Date(currentDate)).format("DD"),
    //     'dayName': new Date(currentDate).toLocaleDateString("local", { weekday: 'short' }),
    //     "available": true
    //   });

    //   currentDate.setDate(currentDate.getDate() - 1);
    // }

    // this.gridArrNew = weekdays.reverse();
    this.gridArrNew = this.common.getPreviousSevenDays();
    this.getHeader();
    this.getStatus(this.status, this.clickedStatus);
  }

  /* this method is for getting a week days */
  getCurrentWeek() {

    this.gridArrNew.forEach((date: any) => {
      const today = new Date(this.autoAssignStartDate);
      const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));

      date.isWeek = new Date(date.date) >= startOfWeek && new Date(date.date) <= endOfWeek;

      if (date.isWeek)   /// if only week days
      {
        date.value = moment(date.date).format("DD")
        date.available = true;
      }

    })

  }


  /* 
    
    use: use for creating blank calender grid.
    */

  createBlanlGrid() {
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MM-DD-YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {

        obj.value = i - initialEmptyCells + 1;
        obj.available = true;
        obj.highlight = '';
        obj.date = dateToCheck;
      }
      this.gridArr.push(obj);
    }

    this.gridArrNew = this.gridArr;

    this.dashboarService.common.progressLoader = false;

  }



  /*can check if anything not working in old getStatus() above (how previously works)*/
  /*below is same method with new code as per new dashboard design*/
  /* new code for getStatus()*/

  getStatus(status, displayText: string = 'all') {
    // //console.log('getStatus', status);
    let gridDays: any = this.gridArrNew;
    this.clickedStatus = displayText;  // using status for displaying the text on UI
    this.status = status;  // using status for fetching data

    // ensuring that the days is present
    if (this.gridArrNew.length == 0) {
      return ;
    }

    this.resetFields();

    let dateRange_Start;
    let dateRange_End;

    if (status === 'Scheduled' || status === 'Not Filled') 
    {

      this.is_allSevenDaysData = false;  // when fetching data for 7 Days

      // ensuring that the current date is exit in 'gridDays'
     const currentDateExit = gridDays.find(element =>element.yearMonthDateFormat === moment().format(this.yearMonthDateFormat))
    
     if(currentDateExit)
       {
        dateRange_Start = moment().format(this.yearMonthDateFormat);
        dateRange_End = moment().format(this.yearMonthDateFormat);
       }
       else
       {
         var start_week_date = gridDays[0].yearMonthDateFormat;
      dateRange_Start = moment(start_week_date).format(this.yearMonthDateFormat);
      dateRange_End = moment(start_week_date).format(this.yearMonthDateFormat);
       }
      
   
    // var start_week_date = gridDays[0].date;
      // dateRange_Start = moment(new Date(start_week_date)).format(this.yearMonthDateFormat);
      // dateRange_End = moment(new Date(start_week_date)).format(this.yearMonthDateFormat);
    }
    else 
    {
      this.is_allSevenDaysData = true;  // when fetching data for 7 Days
      var start_week = gridDays[0].yearMonthDateFormat;
      var end_week = gridDays[(this.gridArrNew.length - 1)].yearMonthDateFormat;

      dateRange_Start = moment(start_week).format(this.yearMonthDateFormat);
      dateRange_End = moment(end_week).format(this.yearMonthDateFormat);
    }


    this.ShowSelectedStart = dateRange_Start;
    this.ShowSelectedEnd = dateRange_End;

    this.getAppointmentsofSelectedDate(dateRange_Start + ' ' + this.startTime, dateRange_End + ' ' + this.endTime);
    // this.getFullFillmentRatio(dateRange_Start,dateRange_End);
    this.getFullFillmentRatio();
    this.resetCalendarGridColor(); // resetting calendar color
   // this.is_backdropBackground = true;
    // this.drawer.open();

    if(!this.isDialogOpen())
    {
       this.dialogRef = this.dialog.open(this.viewDashboardStatus, { width: '65%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: false });
      this.dialogRef.afterClosed().subscribe(result => { 
        this.closeDrawer();
      });
    }
     

  }

  isDialogOpen(): boolean {
    return this.dialogRef ? this.dialogRef.getState() === 0 /* MatDialogState.OPEN */ : false;
  }

  isDateBetween(checkDate: Date, startDate: Date, endDate: Date): boolean {
    return checkDate >= startDate && checkDate <= endDate;
  }

  isDateBetweenOrEqual(checkDate: Date, startDate: Date, endDate: Date): boolean {
    return checkDate >= startDate && checkDate <= endDate;
  }

  getDataForSingleDay(status: string) {
    // //console.log('getDataForSingleDay', status)
    this.clickedStatus = status;
    this.status = status;
    this.resetFields();
    this.is_allSevenDaysData = false;  // when fetching data for 7 Days

    this.ShowSelectedStart = moment().format(this.yearMonthDateFormat);
    this.ShowSelectedEnd = moment().format(this.yearMonthDateFormat);

    this.getAppointmentsofSelectedDate(this.ShowSelectedStart + ' ' + this.startTime, this.ShowSelectedEnd + ' ' + this.endTime);
    this.resetCalendarGridColor(); // resetting calendar color
    //this.is_backdropBackground = true;
    //this.drawer.open();

    if(!this.isDialogOpen())
    {
       this.dialogRef = this.dialog.open(this.viewDashboardStatus, { width: '65%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
      this.dialogRef.afterClosed().subscribe(result => { 
        this.closeDrawer();
      });
    }

  }

  /* 
  
  use: filter data by selected weeks
  */

  getFilterDataByWeeks(first, last, status = null) {

    this.gridArr.forEach(gridElem => {
      if (gridElem.value > first && gridElem.value < last) {
        gridElem.highlight = 'calhighlight';
      } else if (gridElem.value == first) {
        gridElem.highlight = 'startHighlight';
      } else if (gridElem.value == last) {
        gridElem.highlight = 'endHighlight';
      } else {
        gridElem.highlight = '';
      }
    });

    this.gridArrNew = this.gridArr;

  }


  
  /* 
  
  use: make calendar's week days header eg. sun,mon.. etc.
  */

  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
  }

  /* 
  
  use: this function will convert number to date.
  */

  dateFromNum(num: number, referenceDate: any): any {
    let returnDate = moment(referenceDate);
    return returnDate.date(num);
  }

  /* 
  
  use: this will call when single date is selected.
  */

  selectDay(day: any) {
    ////console.log(day);
    this.appointmentfilter = [];
    this.appointmentData = [];
    this.searchTerm = '';

    let momentObj;
    let momentString;
    if (day.dateFormat == 'dd-MM-yyyy') {
      momentObj = moment(day.date, this.dateMonthYear);
      momentString = momentObj.format(this.yearMonthDateFormat); // 2016-07-15

    } else if (day.dateFormat == 'MM-dd-yyyy') {
      momentObj = moment(day.date, this.monthDateYear);
      momentString = momentObj.format(this.yearMonthDateFormat); // 2016-07-15

    } else {
      momentObj = moment(day.yearMonthDateFormat, this.monthDateYear);
      // momentString = moment(day.yearMonthDateFormat, this.monthDateYear).format(this.yearMonthDateFormat);
      momentString = moment(day.yearMonthDateFormat, this.yearMonthDateFormat).format(this.yearMonthDateFormat);

    }
    // ////console.log(momentObj);
    ////console.log(momentString);
    this.is_allSevenDaysData = false;  // fetching data for single selected date
    this.currentSelectedDate = momentString;
    this.CalendarCurrent_SlectedDate = momentString
    this.selectDate = day.value;
    this.buttonStatus = 'newDate';
    this.currentDateSelected = day.currentDate;
    this.getAppointmentsofSelectedDate(momentString + ' ' + this.startTime, momentString + ' ' + this.endTime);
    this.autoAssignStartDate = momentString;
    this.ShowSelectedStart = momentString;
    this.ShowSelectedEnd = momentString
    this.autoAssignEndDate = momentString;

    // const dialogRef = this.dialog.open(this.appointmentDialogRef, {
    //   width: '50%',
    //   disableClose: false,

    // });
  }


  /* 
  
  use: get all status count and by defautl load data by all status and get right side data by selected date.
  */

  getFullFillmentRatio()
  {

    // if (this.status === 'Scheduled' || this.status === 'Not Filled') {  //  get only counts for 7 days
    //   var start_week = this.gridArrNew[0].date;
    //   var end_week = this.gridArrNew[(this.gridArrNew.length - 1)].date;

    //   startDate = moment(new Date(start_week)).format(this.yearMonthDateFormat);
    //   endDate = moment(new Date(end_week)).format(this.yearMonthDateFormat);
    // }
let startDate, endDate ;

    //if (this.status === 'Scheduled' || this.status === 'Not Filled') {  //  get only counts for 7 days
      var start_week = this.gridArrNew[0].yearMonthDateFormat;
      var end_week = this.gridArrNew[(this.gridArrNew.length - 1)].yearMonthDateFormat;

      startDate = moment(start_week).format(this.yearMonthDateFormat);
      endDate = moment(end_week).format(this.yearMonthDateFormat);
    //}

    this.dashboarService.getAllJobsByOrganizationCodeWithFilterByPage(this.dashboarService.session.get('orgCode'), this.status, startDate, endDate, (responseElement) => {
      //  this.dashboarService.common.progressLoader = false;
      this.enableLoading = false;
      if (responseElement.responsecode == 200) {
     
        this.setCounts(responseElement.data);

          // this.assignmonthper = Math.round(responseElement.data.assignPercent);
          // this.acceptmonthper = Math.round(responseElement.data.acceptPercent);
          // this.monthper = Math.round(responseElement.data.fullfillmentAverage - (responseElement.data.assignPercent + responseElement.data.acceptPercent));
          // this.finalmonthper = Math.round(responseElement.data.fullfillmentAverage);
        }

        // //////console.log(this.monthper);
        // const colodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(this.monthper));
        // const assigncolodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(this.assignmonthper));
        // const acceptcolodIndex = this.dashboarService.common.dashboardColorData.findIndex(data => data.percent == Math.round(this.acceptmonthper));

        // if (colodIndex !== -1) {
        //   this.monthcolor = this.dashboarService.common.dashboardColorData[colodIndex].class;
        // }

        // if (assigncolodIndex !== -1) {
        //   this.assignmonthcolor = this.dashboarService.common.dashboardColorData[assigncolodIndex].class;
        // }

        // if (acceptcolodIndex !== -1) {
        //   this.acceptmonthcolor = this.dashboarService.common.dashboardColorData[acceptcolodIndex].class;
        // }

       
 
    });
  }


  getAppointmentsofSelectedDate(startDate, endDate, isrefresh = null) {
    try {
      this.dashboarService.common.progressLoader = false;
      this.enableLoading = true;
      this.dashboarService.getAllJobsByOrganizationCodeWithFilterDateTime(this.dashboarService.session.get('orgCode'), this.status, startDate, endDate, (responsedata) => {
        this.enableLoading = false;

        if (responsedata.responsecode == 200) {
         
          if(responsedata.data.length > 0)
          {
          // responsedata.data.forEach(ele_first => {
              
          //   ele_first.jobParentResList.forEach(ele_second => {

          //     if(ele_second.jobTypeWithShiftList.length > 0)
          //     {
          //       ele_second.jobTypeWithShiftList.forEach(ele_third => {

          //         if (ele_third.jobDurationDetailsList.length > 0 )
          //         {
          //           ele_third.jobDurationDetailsList.forEach(ele_fourth => {
                      
          //             if(ele_fourth.inBreak)
          //             {
          //               ele_fourth.breakStart_timer$ = this.createTimerObservable(ele_fourth.breakStartTime , ele_fourth.timeZoneName);

          //               // //console.log("current time",ele_fourth.breakStartTime) 
          //               // //console.log("timezone", moment(new Date()).tz(ele_fourth.timeZoneName).format("MM-DD-YYYY HH:MM A") )
          //             }

          //           });
          //         }
                   
          //       });

          //     }
              
          //   });

          // });

          this.enableLoading = true;
         this.appointmentData = responsedata.data;
          this.appointmentfilter = this.appointmentData;
        
        }

          // if (this.status === 'Scheduled' || this.status === 'Not Filled') {  //  get only counts for 7 days
          //   var start_week = this.gridArrNew[0].date;
          //   var end_week = this.gridArrNew[(this.gridArrNew.length - 1)].date;

          //   startDate = moment(new Date(start_week)).format(this.yearMonthDateFormat);
          //   endDate = moment(new Date(end_week)).format(this.yearMonthDateFormat);
          // }

 
        } else {
          this.appointmentData = [];
          this.appointmentfilter = [];
          this.monthcolor = 'grey';
          this.acceptmonthcolor = 'grey';
          this.assignmonthcolor = 'grey';
          if (!isrefresh) {
            this.monthper = 0;
            this.acceptmonthper = 0;
            this.assignmonthper = 0;
            this.finalmonthper = 0;
          }
          this.dashboarService.common.progressLoader = false;
        }
      })

      //   } else {
      //     this.dashboarService.common.progressLoader = false;
      //   }
      // });
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }
  }



  setCounts(data: any) {
    let color = this.status === 'pending' ? 'reddark' : 'greendark';
    this.gridArrNew.forEach(parent_ele => {

      for (var i = 0; i < data.length; i++) 
      { // yearMonthDateFormat

        // if ((moment(parent_ele.date).format(this.yearMonthDateFormat) in data[i])) {
        //   let count = data[i][moment(parent_ele.date).format(this.yearMonthDateFormat)];

        if (parent_ele.yearMonthDateFormat in data[i]) 
        {
          let count = data[i][parent_ele.yearMonthDateFormat];

         // parent_ele.jobsCount = count;

         /* resetting keys before assigning new values */
         parent_ele.jobsCount = '';
         parent_ele.dataPercent ='';

          if (count > 0) {
            parent_ele.jobsCount = count;
            // parent_ele.dataPercent = color;

              if (this.status === 'Scheduled' || this.status === 'Not Filled') 
               {
                // setting color only for current date and greater in "this.gridArrNew"
                 //if(parent_ele.date === moment(new Date()).format("MM-DD-YYYY"))
                  
                // if(new Date(parent_ele.yearMonthDateFormat) > new Date() || parent_ele.yearMonthDateFormat === moment(new Date()).format("MM-DD-YYYY"))
                // {
                //   parent_ele.dataPercent = color;
                // }
                
                if(moment(parent_ele.yearMonthDateFormat).toDate() > moment().toDate() || 
                 parent_ele.yearMonthDateFormat === moment().format("YYYY-MM-DD"))
                {
                  parent_ele.dataPercent = color;
                }
                 
               } 
               else
               {
                parent_ele.dataPercent = color;
               }

          }

          break;

        }
      }

    });

    //console.log();
  }


  getDashboardSchedulingStatus(startDate, endDate) {
    // this.dashboarService.common.progressLoader = true;
    this.dashboarService.getJobForDashboardByOrganizationCode(this.dashboarService.session.get('orgCode'), startDate.split(' ')[0], endDate.split(' ')[0], (response) => {
      // this.dashboarService.common.progressLoader = false; 

      if (response.responsecode == 200) {

        // response.data.forEach(item => {
        //   const { status, count } = item;
        //   this.statusCount[status] = count;
        // });

        this.statusCount = response.data
      }
    })
  }


  /*
   name: Vivek Chauhan
   use: get shift count status.
   */
  getShiftStatusCount(currentDate: any) {
    this.dashboarService.getShiftStatusCount(this.dashboarService.session.get('orgCode'), currentDate, (responsedata) => {
      if (responsedata.responsecode == 200) {
        this.shiftStatusCount = responsedata?.data;
        // this.shiftStatusCount.splice(2, 0, {'name':'Late' , 'value' : 0});

      }
    })
  }

  getShiftList(status: any) {

    if (this.currentSelectedDate && status)
      this.dashboarService.common.progressLoader = true;
    this.dashboarService.getShiftListDetails(this.dashboarService.session.get('orgCode'), this.currentSelectedDate, status, (res) => {
      this.dashboarService.common.progressLoader = false;
      if (res.responsecode == 200) {
        this.shiftStatusList = res?.data;
      }
      else {
        this.shiftStatusList = [];
      }
      const dialogRef = this.dialog.open(this.shiftDetailPopupRef, {
        width: '50%',
        disableClose: false
      });
      this.popupTitle = status;

    })

  }

  showSelectedStatusDetails(status: any) { //statusDetailsPopUp
    // console.log('PopupStatus: ', status);
    if (status && (status === 'Scheduled' || status === 'Not Filled')) {
      // this.getDataForSingleDay(status);
      this.getStatus(status, status)
    }
    else if (status && status === 'Declined') {
      this.ShowStatusDetailsPopUp(status);
    }
    else if (status && status === 'Clocked-In' || status && status === 'Clocked-Out' || status && status === 'Late' || status && status === 'Not Clocked-In') {
      this.ShowClockedInDetailsPopUp(status);
    }
    else {
      this.getShiftList(status);
    }

  }

  DecliedAndLateList: any = [];

  ShowStatusDetailsPopUp(status: string) {
    this.popupTitle = status;

    this.common.progressLoader = true;
    this.dashboarService.getDecliedAndLateListByOrganizationCode(this.dashboarService.session.get('orgCode'), status).subscribe((res: any) => {

      this.common.progressLoader = false;

      this.dialog.open(this.statusDetailsPopUp, {
        width: '60%',
        disableClose: false
      });

      if (res.responsecode == 200) {

        this.DecliedAndLateList = res.data
      }
      else {
        this.DecliedAndLateList = [];
        // this.common.errorMessage = true;
        // this.common.message = res.message;
      }
    })

  }

  ShowClockedInDetailsPopUp(status: string) {
    this.clickedStatus = status;

    if(!this.isDialogOpen())
      {
         this.dialogRef = this.dialog.open(this.viewClockInClockOut, { width: '80%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: false });
        this.dialogRef.afterClosed().subscribe(result => { 
          this.closeDrawer();
        });
      }

  }

  /* use: this will redirect to appointment screen with selected date.
    */


  assignSchedule() {
    //////console.log(this.currentSelectedDate);
    this.router.navigate(['/dashboard/appointment'], { queryParams: { date: this.currentSelectedDate } });
  }
  /* 
    
    use: this will auto assign appointments of selected start and end date.
    */

  autoAssign() {
    const orgCode = this.dashboarService.session.get('orgCode');
    //////console.log(this.autoAssignStartDate);
    //////console.log(this.autoAssignEndDate);
    this.dashboarService.common.progressLoader = true;
    this.dashboarService.common.percentageLoader = true;
    try {
      this.appointmentService.adminAssignJobsByOptaplanner(orgCode, this.ShowSelectedStart, this.ShowSelectedEnd, (res) => {
        //////console.log(res);
        if (res.responsecode == 200) {

          this.appointmentService.totalRecords = res.data.totalRecord;
          this.appointmentService.processedRecords = res.data.processRecord;
          this.appointmentService.common.progressText = 'Processing ' + this.appointmentService.processedRecords + ' of  ' + this.appointmentService.totalRecords + ' appointments';

          try {
            this.appointmentService.getAppointmentThreadProcessingByCode(res.data.appointmentsThreadProcessingCode, (response) => {
              let arrayData = [];
              response.data.ewresLst.forEach(element => {
                if (element.workerWithDistanceList.length > 0) {
                  arrayData.push(element);
                }
              });
              setTimeout(() => {
                ////console.log(arrayData);
                this.dashboarService.common.progressLoader = false;
                this.appointmentService.common.progressText = '';
                this.dashboarService.common.percentageLoader = false;
                if (arrayData.length == 0) {
                  this.getSplitAppointment(response.data.appointmentsDurationCodeList);
                } else {

                  this.openDialog(arrayData);
                }
              }, 2000);
            })
          } catch (error) {
            this.dashboarService.common.progressLoader = false;
            //console.log(error)
          }


        } else {
          this.dashboarService.common.progressLoader = false;
          // this.dashboarService.common.message = res.message;
          // this.dashboarService.common.message = 'All appointments are assigned.';
          // this.dashboarService.common.errorMessage = true;
          this.allAsignedAppointment(res.message);
        }
      })
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }

  }

  /*
      use: to open popup for assigning clinician to appointment */
  openDialog(workers): void {
    ////console.log(this.autoAssignStartDate, this.autoAssignEndDate, this.currentDate);
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      disableClose: true,
      width: '500px',
      data: { data: workers, url: 'dashboard', fromdate: this.autoAssignStartDate, toDate: this.autoAssignEndDate, scheduledDate: moment(this.currentDate, 'YYYY-MM-DD').format('YYYY-MM-DD') }
    });
    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      this.isShowLoader = false;
      this.getStatus('all');
    });
  }

  /*
      use: to open popu to see all assign appointment */
  allAsignedAppointment(msg): void {
    const dialogRef = this.dialog.open(AllAssignAppointmentComponent, {
      width: '350px',
      disableClose: true,
      data: { msgShow: msg }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isShowLoader = false;
      this.getStatus('all');
      //////console.log('The dialog was closed');
    });
  }
  /* 
      
      use: this will show single appointment's detail.
      */
  showAppointment(startTime, endTime, newData) {
    ////console.log(newData);
    // ////console.log(startTime, endTime);
    const appointmentTime = startTime.split(' ');
    var startdt = moment(startTime.split(' ')[1] + ' ' + startTime.split(' ')[2], ["h:mm A"]).format("HH:mm");
    var enddt = moment(endTime.split(' ')[1] + ' ' + endTime.split(' ')[2], ["h:mm A"]).format("HH:mm");
    const data = {
      'startDate': appointmentTime[0] + ' ' + startdt,
      'endDate': appointmentTime[0] + ' ' + enddt,
    }
    ////console.log(appointmentTime[0]);
    let date = null;
    if (newData.dateFormat === 'MM-dd-yyyy') {
      date = moment(new Date(+appointmentTime[0].split('-')[2], +appointmentTime[0].split('-')[0] - 1, +appointmentTime[0].split('-')[1])).format(this.yearMonthDateFormat);

    } else if (newData.dateFormat === 'dd-MM-yyyy') {
      date = moment(new Date(+appointmentTime[0].split('-')[2], +appointmentTime[0].split('-')[1] - 1, +appointmentTime[0].split('-')[0])).format(this.yearMonthDateFormat);
    } else {
      date = moment(new Date(+appointmentTime[0].split('-')[0], +appointmentTime[0].split('-')[1] - 1, +appointmentTime[0].split('-')[2])).format(this.yearMonthDateFormat);
    }

    // dateData = moment(appointmentTime[0],'MM-DD-YYYY').format('YYYY-MM-DD')
    // ////console.log(dateData);
    //  ////console.log(moment(appointmentTime[0],'MM-DD-YYYY').format('YYYY-MM-DD'));
    // ////console.log(moment(new Date(+appointmentTime[0].split('-')[2], +appointmentTime[0].split('-')[0] - 1, +appointmentTime[0].split('-')[1])).format(this.yearMonthDateFormat));
    // const date = moment(new Date(+appointmentTime[0].split('-')[2], +appointmentTime[0].split('-')[1] - 1, +appointmentTime[0].split('-')[0])).format(this.yearMonthDateFormat);
    ////console.log(date);
    let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);

    this.dashboarService.common.progressLoader = true;
    const dialogRef = this.dialog.open(AllappointmentPopupComponent, {
      width: '722px',
      disableClose: true,
      data: { date, data, procedure: 'all', currentSelectedDate: this.currentSelectedDate, WeekstartDate: WeekstartDate, weekendDate: weekendDate }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      this.isShowLoader = false;
      this.getStatus('all');
    });
  }

  /* 
    
    use: on click on patient this popup will show all the appointments of this patient.
    */

  getPatientData(data) {
    ////console.log(data);
    const startdate = data.appointmentStartDate.split(' ')[0];
    const enddate = data.appointmentStartDate.split(' ')[0];
    let startDate;
    let endDate
    if (data.dateFormat == 'MM-dd-yyyy') {
      //////console.log(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1])
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1])).format(this.yearMonthDateFormat);
      endDate = moment(new Date(+enddate.split('-')[2], +enddate.split('-')[0] - 1, +enddate.split('-')[1]), this.monthDateYear).format(this.yearMonthDateFormat);
      //////console.log(startDate, endDate);
    } else if (data.dateFormat == 'dd-MM-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), this.dateMonthYear).format(this.yearMonthDateFormat);
      endDate = moment(new Date(+enddate.split('-')[2], +enddate.split('-')[1] - 1, +enddate.split('-')[0]), this.dateMonthYear).format(this.yearMonthDateFormat);
      //////console.log(startDate, endDate);
    } else {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
      endDate = moment(new Date(+enddate.split('-')[2], +enddate.split('-')[1] - 1, +enddate.split('-')[0]), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
      //////console.log(startDate, endDate);
    }

    this.dashboarService.common.progressLoader = true;
    try {
      this.patientService.getAllJobsByOrganizationCodeWithFilter(this.dashboarService.session.get('orgCode'), this.status, startDate, endDate, data.patientCode, (response) => {
        //////console.log(response);
        if (response.responsecode == 200) {
          this.openPatienDataDialog(response.data, 'patient', this.status, startDate, endDate, data.patientCode, data.appDate);
        } else {
          this.dashboarService.common.progressLoader = false;
        }
      });
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }

  }

  /*
      use: to open patient data popup */
  openPatienDataDialog(workers, direction, status = null, startDate = null, endDate = null, patientCode = null, date, WeekstartDate = null, weekendDate = null,): void {
    this.dashboarService.common.progressLoader = false;
    const dialogRef = this.dialog.open(DashboardPatientDataComponent, {
      width: '750px',
      disableClose: true,
      data: { data: workers, url: direction, status: status, startDate: startDate, endDate: endDate, patientCode: patientCode, date: date, WeekstartDate: WeekstartDate, weekendDate: weekendDate }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      this.isShowLoader = false;
      this.getStatus('all');
    });
  }
  /* 
    
    use: On click on clinician this popup will show all  the appointments of this clinician.
    */

  getTechnicianData(worker) {
    ////console.log(worker);

    if (worker.workerName) {
      const startdate = worker.appointmentStartDate.split(' ')[0];
      let startDate;
      if (worker.dateFormat == 'MM-dd-yyyy') {
        startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'DD-MM-YYYY').format(this.yearMonthDateFormat);
        ////console.log(startDate);
      } else if (worker.dateFormat == 'dd-MM-yyyy') {
        startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format(this.yearMonthDateFormat);
        ////console.log(startDate);
      } else {
        startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format(this.yearMonthDateFormat);
        ////console.log(startDate);
      }
      this.dashboarService.common.progressLoader = true;
      try {
        this.dashboarService.adminGetAllJobsByWorkerCodeAndDate(worker.workerCode, startDate, (response) => {
          ////console.log(response);
          if (response.responsecode == 200) {
            let WeekstartDate = moment(startDate).startOf('week').format(this.yearMonthDateFormat);
            let weekendDate = moment(startDate).endOf('week').format(this.yearMonthDateFormat);
            ////console.log(WeekstartDate, weekendDate);
            this.openPatienDataDialog(response.data, 'technician', null, startDate, null, worker.workerCode, worker.appDate, WeekstartDate, weekendDate);
          } else {
            this.dashboarService.common.progressLoader = false;
          }
        });
      } catch (error) {
        this.dashboarService.common.progressLoader = false;
        //console.log(error)
      }

    }
  }


  /* 
    
    use: this will open right side 3 dots menu
    */
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }


  route(routeTO, appointmentsDurationCode, appointmentsCode) {
    if (routeTO == 'update-appointment') {
      this.appointmentchange = true;
      // this.dialogRef.close(this.appointmentchange);
      // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: appointmentsDurationCode } });
      const dialogRef = this.dialog.open(UpdateAppointmentComponent, {
        width: '70%',
        disableClose: true,
        data: { jobDurationCode: appointmentsDurationCode }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        this.isShowLoader = false;
        this.getStatus('all');
      });
    } else if (routeTO == 'update-appointment-series') {
      this.appointmentchange = true;
      // this.dialogRef.close(this.appointmentchange);
      //this.router.navigate(['/dashboard/update-appointment-series'], { queryParams: { jobCode: appointmentsCode } });
      const dialogRef = this.dialog.open(UpdateAppointmentSeriesComponent, {
        width: '70%',
        disableClose: true,
        data: { jobCode: appointmentsCode }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        this.isShowLoader = false;
        this.getStatus('all');
      });
    }
  }
  /* 
      
      use: this will open popup to assign appointment to clinician.
      */
  assignAppointment(jobDurationCode, ProcedureDetailsList) {
    this.dashboarService.common.progressLoader = true;
    let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);

    try {
      this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, this.currentSelectedDate, WeekstartDate, weekendDate, (data) => {
        ////console.log(data);
        if (data.data.workerWithDistanceList.length > 0 || ProcedureDetailsList == 1) {
          const dialogRef = this.dialog.open(AssignAppointmentComponent, {
            width: '750px',
            disableClose: true,
            data: { data, jobDurationCode }
          });
          dialogRef.afterClosed().subscribe(result => {
            //////console.log(result);
            this.isShowLoader = false;
            if (result != undefined) {
              this.appointmentchange = true;
              // this.getAppointments();
              this.dashboarService.common.message = result;
              this.dashboarService.common.successMessage = true;
              this.getStatus('all');
            }
          });
        }
        else {

          const dialogRef = this.dialog.open(SplitAppointmentComponent, {
            width: '450px',
            disableClose: true,
            data: { data, jobDurationCode }
          });
          dialogRef.afterClosed().subscribe(result => {
            //////console.log(result);
            this.isShowLoader = false;
            if (result != undefined) {
              this.appointmentchange = true;
              // this.getAppointments();
              this.dashboarService.common.message = result;
              this.dashboarService.common.successMessage = true;
              this.getStatus('all');
            }
          });
        }
        this.dashboarService.common.progressLoader = false;

      });
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }



  }

  /*
      use: to open split appointment popup */
  getSplitAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(SplitAppointmentComponent, {
      width: '450px',
      disableClose: true,
      data: { jobDurationCode }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      this.isShowLoader = false;
      if (result != undefined) {
        this.appointmentchange = true;
        // this.getAppointments();
        this.dashboarService.common.message = result;
        this.dashboarService.common.successMessage = true;
        this.getStatus('all');
      }
    });
  }
  /* 
      
      use: this will accept the selected appointment.
      */
  acceptAppointment(jobDurationCode, workerCode) {
    this.dashboarService.common.progressLoader = true;
    const data = {
      'workerCode': workerCode
    }
    try {
      this.appointmentService.assignToWorkerAcceptedByJobDurationCode(jobDurationCode, data, (res) => {
        this.dashboarService.common.progressLoader = false;
        if (res.responsecode == 200) {
          this.dashboarService.common.message = res.message;
          this.dashboarService.common.successMessage = true;
          // this.getAppointments();
        } else {
          this.dashboarService.common.message = res.message;
          this.dashboarService.common.errorMessage = true;
          this.dashboarService.common.progressLoader = false;
        }
      })
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error)
    }

  }
  /* 
    
    use: this will reject the selected appointment.
    */

  rejectAppointment(jobcode, userCode) {
    const dialogRef = this.dialog.open(RejectAppointmentComponent, {
      width: '300px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      this.isShowLoader = false;
      if (result != undefined) {
        const data = {
          'comment': result,
          'workerCode': userCode
        }
        try {
          this.newAppointmentService.assignToWorkerrejectedByJobDurationCode(jobcode, data, (res) => {
            if (res.responsecode == 200) {
              this.getStatus('all');
              this.dashboarService.common.message = res.message;
              this.dashboarService.common.successMessage = true;
              // this.getAppointments(this.selectedAppointmentDate, this.dashboarService.common.pageOffset);
              // this.getNewAppointment(this.dashboarService.session.get('userCode'), this.startDate, this.endDate);
            } else {
              this.dashboarService.common.message = res.message;
              this.dashboarService.common.errorMessage = true;
              this.dashboarService.common.progressLoader = false;
            }
          })
        } catch (error) {
          this.dashboarService.common.progressLoader = false;
          //console.log(error)
        }

      }
    });
  }

  /*
      use: to show route of clinician by clinician code and job duration code */
  showRoute(data) {
    //console.log(data);
    let startdate;
    let startDate;

    // if (this.currentUrl == 'patient') {
    //   startdate = data.startTime.split(' ')[0];
    // } else {
    startdate = data.appointmentStartDate.split(' ')[0];
    // }

    if (data.dateFormat == 'MM-dd-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else if (data.dateFormat == 'dd-MM-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    }

    try {
      this.dashboarService.mobileGetRouteByTechnicianCodeDateAndJobdurationcode(data.workerCode, startDate, data.appointmentsDurationCode, (jobres) => {
        if (jobres.responsecode == 200) {

          const dialogRef = this.dialog.open(ShowRoutesComponent, {
            width: '800px',
            height: '575px',
            disableClose: true,
            data: { workerCode: data.workerCode, appointmentsDurationCode: data.appointmentsDurationCode, date: data.appointmentStartDate, data: jobres.data, driveTime: data.durationText, miles: data.distanceText }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.isShowLoader = false;
            if (result != undefined) {
              this.appointmentchange = true;
              // this.getAppointments();
              this.dashboarService.common.message = result;
              this.dashboarService.common.successMessage = true;
            }
          });
          //      //////console.log(jobres);
          // this.jobRouteData = jobres.data;
          // this.googleMap(this.jobRouteData);
        } else {
          this.dashboarService.common.progressLoader = false;
        }
        // this.dashboarService.common.loader = false;
      })
    } catch (error) {
      this.dashboarService.common.progressLoader = false;
      //console.log(error);
    }

  }
  /* 
      
      use: this will unassign the assigned appointment.
      */
  unAssignAppointment(jobDurationCode) {


    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '450px',
      data: { msg: 'Are you sure you want to unAssign Appointment?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isShowLoader = false;
      if (result != undefined) {
        this.dashboarService.common.progressLoader = true;
        const data = {
          'organizationCode': this.dashboarService.session.get('orgCode'),
        }
        try {
          this.dashboarService.adminUnAssignAppointment(jobDurationCode, data, (res) => {
            //console.log(res);
            if (res.responsecode == 200) {

              // this.dashboarService.common.message = res.message;
              this.dashboarService.common.successMessage = true;
              this.dashboarService.common.progressLoader = false;
              this.getStatus('all');
            }
            else {
              this.dashboarService.common.progressLoader = false;
              this.dashboarService.common.message = res.message;
              this.dashboarService.common.errorMessage = true;
              this.dashboarService.common.progressLoader = false;
            }
          })
        } catch (error) {
          this.dashboarService.common.progressLoader = false;
          //console.log(error)
        }

      }
    });

  }
  /* 
      
      use: this will cancel the appointment
      */
  cancelAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(CancelAppointmentComponent, {
      width: '450px',
      disableClose: true,
      data: { jobDurationCode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.appointmentchange = true;
        // this.getAppointments();
        this.dashboarService.common.message = result;
        this.dashboarService.common.successMessage = true;
        this.isShowLoader = false;
        this.getStatus('all');
      }
    });
  }
  /* 
      
      use: this will list all the procedures.
      */
  getProcedureDetails(procedure, notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: { procedure: procedure, notes: notes }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });

  }
  /* 
      
      use: this function will use for search patient and clinician on appointments.
      */
  searchFromJob() {

    this.appointmentfilter = this.appointmentData.filter((item) => {

      if (item.customerName && item.workerName) {
        return (item.customerName.toLowerCase().includes(this.searchTerm.trim().toLowerCase()) ||
          item.workerName.toLowerCase().includes(this.searchTerm.trim().toLowerCase()))
      }
      else {
        return item.customerName.toLowerCase().includes(this.searchTerm.trim().toLowerCase())
      }

      //console.log();
    })
  }
  /* 
      
      use: this will show the notes of the appointment.
      */
  getNotes(note) {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      width: '450px',
      data: note
    });
  }

  openArrivalDialog(arrival, departure): void {
    const dialogRef = this.dialog.open(ArrivalDepartureComponent, {
      width: '350px',
      data: { arrival: arrival, departure: departure }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }


  closeDrawer() {
    this.resetFields();
  //   // this.getSevenDaysFromCurrentDate();
  //   let date : any= this.Start_of_week ? this.Start_of_week : new Date();
    
  //   if(this.Start_of_week)
  //   {
  //     let startOfWeek = moment().day(this.Start_of_week).format("MM-DD-YYYY");
  //   //  this.getSevenDays(startOfWeek);
  //   }
  //   else{
  //  //   this.getSevenDays(new Date());
  //   }
  this.gridArrNew = this.common.getSevenDaysByDate();
   // this.is_backdropBackground = false;
    this.clickedStatus = '';
    // this.drawer.close();
    this.dialogRef.close();
  }

  getOperationalStatus() {
    let org_code = this.dashboarService.session.get('orgCode');
    this.dashboarService.getOperationalStatusData(org_code).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.operationalStatusList = res.data;
      }
    })
  }


  getDistributionList() {

    let org_code = this.dashboarService.session.get('orgCode');
    this.dashboarService.getAllDistributionList(org_code).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.distributionList = res.data;
        this.distributionList_Filter = this.distributionList;

        this.distributionSeachInput.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDistribution();
          });

      }

    });
  }


  protected filterDistribution() {
    if (this.distributionList.length == 0) {
      return;
    }
    let search = this.distributionSeachInput.value.trim();
    this.distributionList_Filter = this.distributionList.filter(ele => ele.distributionName.toLowerCase().includes(search.toLowerCase()));
  }


  protected filterStaff() {
    if (this.eligibleStaffsList.length == 0) {
      return;
    }
    let search = this.staffSeachInput.value.trim();
    this.filtered_eligibleStaffsList = this.eligibleStaffsList.filter(ele => ele.worker.name.toLowerCase().includes(search.toLowerCase()));
  }


  openNotifyPopUp(appointment: any) {
    //console.log("appoinmentDurationCode", appointment);
    this.selectedAppointment = appointment
    this.selected_appoinmentDurationCode = appointment.appointmentsDurationCode;
    this.getEligibleStaffs(appointment.appointmentsDurationCode);
    this.dialogRef = this.dialog.open(this.notifypopup, {
      disableClose: true,
      width: '500px'
    });
  }

  getEligibleStaffs(appoinmentDurationCode: string) {
    this.isLoading = true;
    this.dashboarService.getEligibleStaffs(appoinmentDurationCode).subscribe((res: any) => {
      this.isLoading = false;
      if (res.responsecode == 200) {
        this.eligibleStaffsList = res.data.workerWithDistanceList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;

        this.staffSeachInput.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterStaff();
          });
      }
    });
  }


  sendNotification() {

    //console.log()

    if (this.selectedDistribution.length == 0 && this.selectedSkills.length == 0) {
      return;
    }

    if (this.selectedDistribution.length > 0 && this.selectedSkills.length > 0) {
      this.dashboarService.common.message = 'Please select either distribution or staff';
      this.dashboarService.common.errorMessage = true;
      return;
    }


    let JSON: any = {
      "appointmentsCode": this.selectedAppointment.appointmentsCode,
      "appointmentsProceduresCode": this.selectedAppointment.appointmentsProceduresCode,
      "appointmentsDurationCode": this.selected_appoinmentDurationCode,
      "date": this.ShowSelectedStart,
      "organizationCode": this.dashboarService.session.get('orgCode'),
      "distributionCodeList": this.selectedDistribution,
      "userCodeList": this.selectedSkills
    }

    //console.log(JSON)

    this.dashboarService.common.progressLoader = true;
    this.dashboarService.PublishJob(JSON).subscribe((res: any) => {
      this.dashboarService.common.progressLoader = false;
      if (res.responsecode == 200) {
        this.dashboarService.common.successMessage = true;
        this.dashboarService.common.message = res.message;
        this.resetNotifyPopup();

        let v = moment(this.ShowSelectedStart).format('DD')
        let k = this.gridArrNew.findIndex(ele => ele.value === v)
        let obj = this.gridArrNew[k]
        this.selectDay(obj);  // refreshing list
      }
      else {
        this.dashboarService.common.message = res.message;
        this.dashboarService.common.errorMessage = true;

      }
    })


  }

  resetNotifyPopup() {
    this.dialogRef.close();
    this.selected_appoinmentDurationCode = '';
    this.selectedDistribution = [];
    this.selectedSkills = [];
    this.filtered_eligibleStaffsList = [];
    this.eligibleStaffsList = [];
    this.selected_appoinmentDurationCode = '';
    this.selectedStaff = null;
  }


  openAssignPopUp(appointment: any) {
    this.selected_appoinmentDurationCode = appointment.appointmentsDurationCode;
    this.getEligibleStaffs(appointment.appointmentsDurationCode);
    this.dialogRef = this.dialog.open(this.assign_popup, {
      disableClose: true,
      width: '700px'
    });
  }


  AssignStaff() {
    if (this.selectedStaff?.workerCode) {
      this.dashboarService.common.progressLoader = true;

      this.dashboarService.assignJob(this.selected_appoinmentDurationCode, this.selectedStaff?.workerCode).subscribe((res: any) => {
        this.dashboarService.common.progressLoader = false;
        if (res.responsecode == 200) {
          this.selected_appoinmentDurationCode = '';
          this.selectedStaff = null;
          this.dashboarService.common.successMessage = true;
          this.dashboarService.common.message = res.message;
          this.resetNotifyPopup();

          let v = moment(this.ShowSelectedStart).format('DD')
          let k = this.gridArrNew.findIndex(ele => ele.value === v)
          let obj = this.gridArrNew[k]
          this.selectDay(obj);  // refreshing list
        }
        else {
          this.dashboarService.common.message = res.message;
          this.dashboarService.common.errorMessage = true;

        }
      })
    }

  }

  getGlobalLeaveCount(orgCode: string) {
    this.common.getLeavePendingCount(orgCode, (data) => {
      if (data.responsecode == 200) {
        this.common.globalLeaveCount = data.count;
      } else {
        this.common.globalLeaveCount = 0;
      }
    })
  }

/* 
name: VivekChauhan
use: return Cancel Job Request Count by organization code .
*/
  getGlobalCancelJobRequestCount(orgCode: string) {
    this.common.getCancelJobRequestCount(orgCode, false , (data) => {
      if (data.responsecode == 200) 
        {
          this.requestCancel_Count = data.count;
        // this.common.globalCancelJobRequestCount = data.count;
        }  
    })
  }

  // subscribeDashboardRefresh() {
  //   // unsubscribing this subject from dashboard component destroy()
  //   this.common.refreshDashboardSubject.subscribe(res => {
  //     if (res) {
  //       this.refreshEvery30Second();
  //     }

  //   })
  // }

  // refreshEvery30Second() {
  //   let currentDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);

  //   this.getGlobalLeaveCount(this.dashboarService.session.get('orgCode'));
  //   this.getDashboardSchedulingStatus(currentDate + ' ' + this.startTime, currentDate + ' ' + this.endTime)
  //   this.getShiftStatusCount(currentDate);
  //   this.getOperationalStatus();
  //   this.getMissedClockOutCount();

  // }


  RefreshJobList(event: any) {
    // let v = moment(this.ShowSelectedStart).format('DD')
    // let k = this.gridArrNew.findIndex(ele => ele.value === v)
    // let obj = this.gridArrNew[k]
    // this.selectDay(obj);  // refreshing list
    this.getAppointmentsofSelectedDate(this.ShowSelectedStart + ' ' + this.startTime, this.ShowSelectedEnd + ' ' + this.endTime);
    this.getFullFillmentRatio();
  }

  // getMissedClockOutCount() {
  //   this._checkOutMissedSVC.getMissedClockOutListByPage(this.dashboarService.session.get('orgCode'), this.page, this.limit).subscribe((res: any) => {
  //     if (res.responsecode == 200) {
  //       this.missedClockOut_Count = res.count;
  //     }
  //   });

  // }

 
  private dashboard_webSocket$ = new Subject<void>();

  webSocket_Dashboard()
  {
     this.subscribeSchedulingStatus();
     this.subscribeTodaysStatus();
     this.subscribeOperationalStatus();
    //  this.subscribe_PendingLeaveCount();
    //  this.subscribe_MissedClockeOut();
    //  this.subscribe_RequestCancel();
    //  this.subscribe_newRequestJobs();
  }

subscribeSchedulingStatus()
{
   this.common.webSocketSubcribeTopic("OrgDashboardSchedulingStatus_"+this.dashboarService.session.get('orgCode'))
  .pipe(takeUntil(this.dashboard_webSocket$))
  .subscribe((res:any) =>
  {
     if(res.body)
     {
      this.statusCount =  JSON.parse(res.body);
     }
  });
}

subscribeTodaysStatus()
{
  this.common.webSocketSubcribeTopic("OrgDashboardTodaysStatus_"+this.dashboarService.session.get('orgCode'))
  .pipe(takeUntil(this.dashboard_webSocket$))
  .subscribe((res:any) =>
  {
    if(res.body)
    {
      this.shiftStatusCount =  JSON.parse(res.body);
    }
  });
}

subscribeOperationalStatus()
{
  this.common.webSocketSubcribeTopic("OrgDashboardOperationalStatus_"+this.dashboarService.session.get('orgCode'))
  .pipe(takeUntil(this.dashboard_webSocket$))
  .subscribe((res:any) =>
  {
    if(res.body)
    {
      this.operationalStatusList =  JSON.parse(res.body);
    }
  });
}

// subscribe_PendingLeaveCount()
// {
//   this.common.webSocketSubcribeTopic("OrgDashboardPendingLeaveCount_"+this.dashboarService.session.get('orgCode'))
//   .pipe(takeUntil(this.dashboard_webSocket$))
//   .subscribe((res:any) =>
//   {
 
//     if(res.body)
//     {
//       this.common.globalLeaveCount =  JSON.parse(res.body).count;
//     }
//   });
// }


// subscribe_MissedClockeOut()
// {
//   this.common.webSocketSubcribeTopic("OrgDashboardMissedClockOutCount_"+this.dashboarService.session.get('orgCode'))
//   .pipe(takeUntil(this.dashboard_webSocket$))
//   .subscribe((res:any) =>
//   {
 
//     if(res.body)
//     {
//       this.missedClockOut_Count =  JSON.parse(res.body).count;
//     }
//   });
// }

// subscribe_RequestCancel()
// {
//   this.common.webSocketSubcribeTopic("OrgDashboardRequestCancelCount_"+this.dashboarService.session.get('orgCode'))
//   .pipe(takeUntil(this.dashboard_webSocket$))
//   .subscribe((res:any) =>
//   {
 
//     if(res.body)
//     {
//       this.requestCancel_Count =  JSON.parse(res.body).count;
//     }
//   });
// }

// subscribe_newRequestJobs()
// {
//   this.common.webSocketSubcribeTopic("OrgDashboardRequestedJobsCount_"+this.dashboarService.session.get('orgCode'))
//   .pipe(takeUntil(this.dashboard_webSocket$))
//   .subscribe((res:any) =>
//   {
 
//     if(res.body)
//     {
//       this.newJobRequest_Count =  JSON.parse(res.body).count;
//     }
//   });
// }

// private createTimerObservable(startTime: string , timezone:string):Observable<any> {
//   return interval(1000).pipe(
//     map(() => {
//       let timezone_formate = moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss')
//       const currentTime = new Date(timezone_formate).getTime();
//       const elapsedTime = currentTime - new Date(startTime).getTime();
//       return this.formatElapsedTime(elapsedTime);
//     })
//   );
// }


// private formatElapsedTime(elapsedTime: number): string {
//  // const days = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));
//   const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//   const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
//   const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

//   // return `${days}d ${hours}h ${minutes}m ${seconds}s`;
//   return `${hours}h ${minutes}m ${seconds}s`;
// }


//new_req_appoinment_Status:string = "all";

// getRequestedNewJobsCount()
//  {
//   this._newJobReqSVC.getRequestedJobsCounts(this.dashboarService.session.get('orgCode'), false).subscribe((res: any) => {
//     if (res.responsecode == 200) {
//       this.newJobRequest_Count = res.data[0].value;
//     }
//     else{
//       this.newJobRequest_Count = 0;
//     }
//   });

// }
 
protected cancelJob_activeTab : string = "Pending";

requestedJob_tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
   this.tabAtciveFor = tabChangeEvent?.tab?.textLabel; 
}

cancelJob_tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  this.cancelJob_activeTab = tabChangeEvent?.tab?.textLabel; 
}

  ngOnDestroy() {
    this.resetFields();

  /*************************** unsubscribes observables ************************ */
    // this.common.stopRefreshInterval();
    this._onDestroy.next();
    this._onDestroy.complete();

    this.dashboard_webSocket$.next();
    this.dashboard_webSocket$.unsubscribe();
    
  }


}
