import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-all-assign-appointment',
  templateUrl: './all-assign-appointment.component.html',
  styleUrls: ['./all-assign-appointment.component.scss']
})
export class AllAssignAppointmentComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<AllAssignAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    //console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
