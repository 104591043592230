import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';
import { ApiRootService } from 'src/app/shared/api-root.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(
    private http: HttpClient,
    public common: CommonService,
    public storage: SessionStorageService,
    public apiRootService: ApiRootService,
  ) { }

  getAllEmailTemplateListByOrgCode(orgCode: any, isPredefined: any = null) {
    const api = isPredefined === null 
      ? `${this.apiRootService.getHealthCareUrl()}/getAllEmailTemplate/${orgCode}`
      : `${this.apiRootService.getHealthCareUrl()}/getAllEmailTemplate/${orgCode}/${isPredefined}`;    
    return this.http.get(api);
  }

  getAllEmailTemplateByPage(orgCode: any, searchText: any, page: any, limit: any) {
    const baseUrl = this.apiRootService.getHealthCareUrl();
    const searchParam = searchText ? `/${searchText}` : '';
    const api = `${baseUrl}/getAllEmailTemplateByPage/${orgCode}${searchParam}?page=${page}&limit=${limit}`;    
    return this.http.get(api);
  }

  getAllEmailTemplateBySearch(orgCode: any, searchValue: any, page: any, limit: any) {
    const api = `${this.apiRootService.getHealthCareUrl()}/getAllEmailTemplateByPage/${orgCode}/${searchValue}?page=${page}&limit=${limit}`;
    return this.http.get(api);
  }

  getAllRoleMasterByOrganizationCode(orgCode: any, isSystemRole: any) {
    const api = `${this.apiRootService.getHealthCareUrl()}/getAllRoleMasterByOrganizationCode/${orgCode}/${isSystemRole}`;
    return this.http.get(api);
  }

  saveOrUpdateEmailTemplate(formData: any) {
    const SAVE_OR_UPDATE_EMAIL_TEMPLATE = `${this.apiRootService.getHealthCareUrl()}/saveOrUpdateEmailTemplate`;
    return this.http.post(SAVE_OR_UPDATE_EMAIL_TEMPLATE, formData);
  }

  sendAndSaveEmailTemplate(formData: any) {
    const SEND_AND_SAVE_EMAIL_TEMPLATE = `${this.apiRootService.getHealthCareUrl()}/sendAndSaveEmailTemplate`;
    return this.http.post(SEND_AND_SAVE_EMAIL_TEMPLATE, formData);
  }

  sendEmailUsingTemplate(formData: any) {
    const SEND_EMAIL_USING_TEMPLATE = `${this.apiRootService.getHealthCareUrl()}/sendEmailUsingTemplate`;
    return this.http.post(SEND_EMAIL_USING_TEMPLATE, formData);
  }

  softDeleteByCode(emailTemplateCode) {
    const DELETE_EMAIL_TEMPLATE = `${this.apiRootService.getHealthCareUrl()}/softDeleteEmailTemplate/${emailTemplateCode}`;
    return this.http.delete(DELETE_EMAIL_TEMPLATE);
  }

  getEmailSettingByOrgCode(orgCode: any) {
    const api = `${this.apiRootService.getSuperAdmin()}/getEmailSettingByOrgCode/${orgCode}`;
    return this.http.get(api);
  }

  saveOrUpdateEmailSetting(formData: any) {
    const SAVE_OR_UPDATE_EMAIL_SETTING = `${this.apiRootService.getSuperAdmin()}/saveOrUpdateEmailSetting`;
    return this.http.post(SAVE_OR_UPDATE_EMAIL_SETTING, formData);
  }

  sendTestEmailByOrgCode(orgCode: any, toEmail: any) {
    const api = `${this.apiRootService.getSuperAdmin()}/sendTestEmailByOrgCode/${orgCode}/${toEmail}`;
    return this.http.get(api);
  }

  parseString(input) {
    // Handle the special mentions and replace them with proper text
    // let output = input.replace(/<span class="mention"[^>]*>(.*?)<\/span>/g, function(match, p1) {
    //     // Extract the text within the contenteditable span
    //     let innerText = p1.match(/<span[^>]*>(.*?)<\/span>/);
    //     return innerText ? innerText[1] : p1;
    // });

    // Remove any remaining HTML tags to get the plain text
    // output = 
    
    return input.replace(/\uFEFF/g, ' ');
}

// let string = `<p>Hello <span class="mention" data-index="1" data-denotation-char="{" data-id="2" data-value="User Name}">﻿<span contenteditable="false">{User Name}</span>﻿</span> </p><p><br></p><p>Text for send please type your text for {Company Name} here.</p><p><br></p><p>Regards,</p><p>{User Name}</p>`;
// let result = parseString(string);
// console.log(result);

}
