import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  viewDate;
  personalWorker = {
    "firstName": '',
    "lastName": '',
    "address": ' ',
    "location": '',
    "city": ' ',
    "apartmentNo": '',
    "state": ' ',
    "country": ' ',
    "cpassword": ' ',
    "dob": ' ',
    "email": ' ',
    "emergencyNo": ' ',
    "employmentTypeCode": ' ',
    "employmentTypeName": ' ',
    "homeCountryCode": ' ',
    "latitude": ' ',
    "longitude": ' ',
    "mobileNo": ' ',
    "name": ' ',
    'legalName':'',
    'parmanentAddress':'',
    "organizationWiseApprovalList": [
      {
        "dateFormat": ' ',
        "inactiveReason": ' ',
        "inactiveTime": ' ',
        "isActivated": true,
        "isApproved": true,
        "organizationCode": ' '
      }
    ],
    "password": ' ',
    "postCode": ' ',
    "workerCountryCode": ' ',
    "workerLogo": ' ',
    "departmentCode": '',
    'notes' : '',
    "noteRequest": null
  };

  professionalWorker = {
    "certificationmaster": [
      {
        "certificationCode": "",
        "certificationName": "",
        "certificationUrl": "",
        "isAssigned": true,
        "organizationCode": "",
        "organizationName": "",
        "skillCode": "",
        "skillName": "",
        "certificationExpirationDate": "",
        "isCertificateExpired": "",
        "viewDate": moment()
      }
    ],
    "employmentTypeCode": "",
    "organizationWiseApprovalList": [
      {
        "isApproved": true,
        "organizationCode": ""
      }
    ],
    "skilmaster": [
      {
        "skillCode": "",
        "skillName": ""
      }
    ],
    "workerLogo": ""
  };



  constructor(
    public service: ApiRootService,
    public common: CommonService,
    public http: HttpClient,
    public local: SessionStorageService) { }

  /* Name:Shailesh
        use: for getting worker and organization detail by org code , page and limit */
  getWorkerAndOrganizationFromWASByPage(code, page, limit, technicianName, emplyeeType, expFromYear, expFromMonth, expToYear, expToMonth, isActiveUserList, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getWorkerAndOrganizationFromWASByPage/' + code + '/' + technicianName + '/' + emplyeeType + '/' + expFromYear + '/' + expFromMonth + '/' + expToYear + '/' + expToMonth + '/' + isActiveUserList + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }

/* Name:Vivek Chauhna
  use: for getting all skill list */
  getAllSkillsOnlyByOrgCode(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getSkillsOnlyByOrganizationCode/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

/* Name:Vivek Chauhan
    use: for getting worker and organization detail by org code , page and limit */
    getStaffListByPage(reqBody, page, limit, callback) {
      return this.http.post(this.service.getHealthCareUrl() + '/getStaffListByPage' + '?page=' + page + '&limit=' + limit, reqBody).subscribe((data) => {
        callback(data);
      })
    }

    /* Name:Vivek Chauhan
    use: for getting worker and Staff count */
    getStaffCountByOrganizationCode(orgCode, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getStaffCountByOrganizationCode/'+orgCode).subscribe((data) => {
        callback(data);
      })
    }

/* Name:Vivek Chauhan
  use: for Change/Update Role for worker */
  changeWorkerRole(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/assignDailyUserRoleMapping', formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for getting worker/clinician personal detail by worker code and org code*/
  getWorkerProfilePersonalTabforMobileByworkerCode(workerCode, orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getWorkerProfilePersonalTabforMobileByworkerCode/' + workerCode + '/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
        use: for getting worker/clinician Professional detail by worker code*/
  getWorkerProfileProfessionalTabforMobileByworkerCode(workerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/getWorkerProfileProfessionalTabforMobileByworkerCode/' + workerCode).subscribe((data) => {
      callback(data);
    })
  }

/* Name:Vivek Chauahn
    use: for getting distribution list by worker code*/
    getDistributionUserListByWorkerCode(workerCode, orgCode, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getDistributionUserListByWorkerCode/' + workerCode + '/' + orgCode).subscribe((data) => {
        callback(data);
      })
    }

    /* Name:Vivek Chauahn
    use: for getting Assign And Unassign distribution list by staffCode code*/
    getAssignAndUnassignList(orgCode, staffCode, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getAssignAndUnassignList/' + orgCode + '/' + staffCode).subscribe((data) => {
        callback(data);
      })
    }

    /* Name:Vivek Chauhan
  use: for Assign/Unassign Distribution list for Staff by staff code*/
  assignOrUnassignDistribution(staffCode, reqBody, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/assignOrUnassignDistribution/' + staffCode, reqBody).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
        use: for getting worker/clinician all employment type*/
  getAllEmploymentType(callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllEmploymentType/').subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for saving/creating worker/clinician detail */
  saveWorker(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveWorker', formData).subscribe((data) => {
      callback(data);
    })
  }

/* Name:Vivek Chauhan
  use: for set Active / Inactive status */
  setActiveInactiveStaff(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/setTerminateStatus', formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for updating worker/clinician personal detail by worker code*/
  updateworkerProfilePersonelTabByworkerCode(workerCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/v2/updateworkerProfilePersonelTabByworkerCode/' + workerCode, formData).subscribe((data) => {
      callback(data);
    })
  }
  
/* Name:Shailesh
    use: for updating worker/clinician personal detail by worker code*/
    updateworkerProfilePersonelTabByworkerCodeNew(workerCode, formData, callback) {
      return this.http.put(this.service.getHealthCareUrl() + '/v3/updateworkerProfilePersonelTabByworkerCode/' + workerCode, formData).subscribe((data) => {
        callback(data);
      })
    }

/* Name:Vivek Chauhan
  use: for updating worker/clinician for Staff detail by staff code*/
  updateworkerProfilePersonelTabByworkerCodeForStaff(workerCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateworkerProfilePersonelTabByworkerCode/' + workerCode, formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for updating worker/clinician Professional detail by worker code*/
  updateWorkerProfileProfessionalTabforMobileByworkerCode(workerCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateWorkerProfileProfessionalTabforMobileByworkerCode/' + workerCode, formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for updating worker/clinician approval status by worker code*/
  adminSetOrgApprovalStatusByTechnicianCode(code, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminSetOrgApprovalStatusByTechnicianCode/' + code, formData).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
     use: for getting provider info by Org code*/
  checkAddProviderByClinicianCode(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/checkAddProviderByClinicianCode/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }


  uploadStaffMembers(formData : FormData)
  {
     return this.http.post(this.service.getRegistrationUrl() + '/uploadStaffMember/', formData)
  }

  addStaffMember(payload : any)
  {
     return this.http.post(this.service.getRegistrationUrl() + '/addStaffMember/', payload)
  }

  registerStaffWithoutMailSend(userCode:string, password:string)
  {
     return this.http.get(this.service.getRegistrationUrl() + '/registerStaffWithoutMailSend/'+userCode+'/'+password)
  }


  getAllStaffMembers(orgCode:string,page:number,limit:number,tbl_search_value:string = null) {

    let url ;

    if(!tbl_search_value){
      url = this.service.getHealthCareUrl() + '/getAllStaffMemberByPage/' + orgCode +  '?page=' + page + '&limit=' + limit ;
    }
    else{
      url = this.service.getHealthCareUrl() + '/getAllStaffMemberByPage/' + orgCode +'/'+ tbl_search_value +  '?page=' + page + '&limit=' + limit
    }

  return this.http.get(url);
 }


 Save_UpdateWorkerProfessionalDetails(request_body:any) {
  return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateWorkerProfessionalDetails', request_body)
}

sendWelcomeEmail(userCode:string) {
  return this.http.get(this.service.getRegistrationUrl() + '/sendWelcomeEmail/'+ userCode)
}

sendWelcomeEmailToAllStaff(orgCode:string) {
  return this.http.get(this.service.getRegistrationUrl() + '/sendWelcomeEmailToAllStaff/'+ orgCode)
} 

sendForgetPasswordLinkByCode(userCode:string)
{
   return this.http.get(this.service.getRegistrationUrl() + '/sendForgetPasswordLinkByCode/'+userCode)
}

getEmailAvailabilityForUpdate(userEmail, userCode, callback){
  return this.http.get(this.service.getRegistrationUrl()+'/getEmailAvailabilityForUpdate/'+userEmail+'/'+userCode).subscribe((data)=>{ 
    callback(data); 
  })
}


checkEmailAvialablity(email,callback) {
  return this.http.get(this.service.getRegistrationUrl()+'/getEmailAvailability/'+email).subscribe((data)=>{
    callback(data);
  })
}



saveStaffLogo(formData:any) {
  return this.http.post(this.service.getHealthCareUrl() + '/updateWorkerLogo/', formData)
}

saveNote(payload:any) {
  return this.http.post(this.service.getHealthCareUrl() + '/updateWorkerNotes/', payload)
}


get_appoinment_counts_for_staff(organizationCode:string, status:string, startDT:string, endDT:string, staffCode:string,)
{
 return this.http.get(`${this.service.getHealthCareUrl() }/adminGetAllAppointmentsByOrganizationCodeWithFilterForStaff/${organizationCode}/${status ? status : 'all'}/${startDT}/${endDT}/${staffCode}`)
}

get_appoinment_details_for_staff(organizationCode:string, status:string, startDT:string, endDT:string, orgBasedProcedureCodeList:any, staffCode:string)
{
 return this.http.get(`${this.service.getHealthCareUrl() }/adminGetAllAppointmentsByOrganizationCodeForStaffWithFilterDateTime/${organizationCode}/${status ? status : 'all'}/${startDT}/${endDT}/${orgBasedProcedureCodeList ? orgBasedProcedureCodeList : 'all'}/${staffCode}`)
}

 
}
 