import { SessionStorageService } from 'angular-web-storage';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './../../../shared/common.service';
import { ApiRootService } from './../../../shared/api-root.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  settings;
  skills = {
    "organizationCode": "",
    "skillList": [
      {
        "color": "",
        "skillCode": "",
        "skillImage": ""
      }
    ]
  };

  procedureForm = {
    "duration":"",
    "id": 0,
    "organizationBaseProcedureCode": "",
    "organizationCode": "",
    "procedureCode": "",
    "procedureDescription": "",
    "procedureIcon": "",
    "procedureName": "",
    "procedureId": "",
    "skillMasterList": [],
    "isDateFlexible" : false
  }

  constructor(
    public service: ApiRootService,
    public common: CommonService,
    public http: HttpClient,
    public storage: SessionStorageService,
  ) { }

  getAllSkills() {
    return this.http.get(this.service.HEALTHCARE_URL + '/getSkillsWithNamesByIndustryCode/b8fa3263-4a90-41e0-a5e8-896667268b62');
  }

  getAllSkillsProcedure(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getSkillMasterByOrganizationCode/'+code).subscribe((data) => {
      callback(data);
    })
  }

  getSkillsByOrganizationCodeByPage(organizationCode, page, limit, callback) {
    return this.http.get(this.service.HEALTHCARE_URL + '/getSkillsByOrganizationCodeByPage/' + organizationCode + '?page=' + page + '&limit = ' + limit).subscribe((data) => {
      callback(data);
    });
  }
  saveSkills(data, callback) {
    return this.http.post(this.service.HEALTHCARE_URL + '/saveOrganizationSkill', data).subscribe((data) => {
      callback(data);
    });
  }
  getSkillDataById(id, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getOrganizationSkill/' + id).subscribe((data) => {
      callback(data);
    })
  }

  getPatientDetailByOrganizationCodeByPage(code, page, limit, patientName, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByOrganizationCodeByPage/' + code + '/' + patientName + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
      //////console.log(data);
    })
  }

  uploadFile(data, callback) {
    return this.http.post(this.service.getHealthCareUrl()+'/uploadPatientFile', data).subscribe((data)=>{
        callback(data);
    });
  }


  getAllProcedureByOrganizationCodeByPage(code, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCodeByPage/' + code + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }

  updateProcedureByOrganizationBasedProcedureCode(orgBasedCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateProcedureByOrganizationBasedProcedureCode/' + orgBasedCode, formData).subscribe((data) => {
      callback(data);
    })
  }

  saveOrganizationBasedProcedure(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrganizationBasedProcedure', formData).subscribe((data) => {
      callback(data);
    })
  }
  getAllProcedureMaster(callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureMaster').subscribe((data) => {
      callback(data);
    })
  }
  getProcedureByOrganizationBasedProcedureCode(procedureCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getProcedureByOrganizationBasedProcedureCode/' + procedureCode).subscribe((data) => {
      callback(data);
    })
  }

  
  updateOrganizationOnboarding(code,form,callback){
    return this.http.put(this.service.getRegistrationUrl()+'/updateOrganizationOnboarding/'+code,form).subscribe((data)=>{
      callback(data);
    })
  }

  getUserPreferencesLocalSettingWithDefaultGlobalSettingByOrganizationCode(code, callback){
    return this.http.get(this.service.getHealthCareUrl()+'/getUserPreferencesLocalSettingWithDefaultGlobalSettingByOrganizationCode/'+code).subscribe((data)=>{
      callback(data)
    });
  }

     /* Name:Shailesh
      use: for updating local setting by user code */
      updateSettingByUserCode(code,data,callback){
        return this.http.put(this.service.getHealthCareUrl()+'/updateSettingByUserCode/'+code,data).subscribe((data)=>{
          callback(data);
        })
      }
}

