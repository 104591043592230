import { Component, OnDestroy, OnInit } from "@angular/core";
// import { RxStompService } from "@stomp/ng2-stompjs";
import { RxStompService } from 'src/app/rx-stomp.service';
//import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
//import { SessionStorageService } from "angular-web-storage";
import { NotificationMsgComponent } from "./notification-msg/notification-msg.component";
import { CommonService } from "../shared/common.service";
//import { Router } from "@angular/router";
import { PushNotificationService } from "../push.notification.service";
//import { MatTableDataSource } from "@angular/material/table";
//import { NotificationService } from "../dashboards/providers-dashboard/notification/notification.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"]
})

export class MessagesComponent implements OnInit, OnDestroy{
 // public receivedMessages: string[] = [];
  username: any;
  usertype;
  userdata;
  userID;
  limit = 10;
  message;
  snackBarref: any;
  notificationType;
  notificationCode;
  appointmentsDeatilsList = [];

  private topicChatSubscription: Subscription;
  //private healthCareNotification_Subscribe : Subscription;

  constructor(
    private rxStompService: RxStompService,
    public snackBar: MatSnackBar,
    //public session: SessionStorageService,
    public common: CommonService,
   // public router: Router,
    private _notificationService: PushNotificationService,
   // public notification: NotificationService
  ) {
    this._notificationService.requestPermission();
  }
  /* Name:srinivas
        use: page loads with socket message. */
  ngOnInit() {
    // this.openSnackBar('Hi this is test message');
    ////console.log(this.router.url);
    this.message = "hello how are you";
    let userCodeTopic = "/topic/" + this.common.local.get("super_admin_code");
    // console.log(userCodeTopic);
   this.topicChatSubscription = this.rxStompService.watch(userCodeTopic).subscribe(message => {
      //console.log(message);
    //  this.receivedMessages.push(message.body);
      let body = JSON.parse(message.body);
      //console.log(body);
      this.notificationType = body.notificationType;
      this.notificationCode = body.healthcareNotificationCode;
      this.username = this.common.local.get("username");
      let datamessage: Array<any> = [];
      datamessage.push({
        title: "StaffWorks",
        alertContent: body.shortMessage
      });
      //////console.log(datamessage);
      this._notificationService.generateNotification(datamessage);
      //  this.openSnackBar(body.shortMessage);
      this.openSnackBar("Message from " + body.fromName + ": " + body.message);

      if (body.groupCode !== null && body.isApp) {
        this.rxStompService.publish({
          destination: "/topic/chat",
          body: JSON.stringify({
            message: body.message,
            type: "Dashboard",
            groupCode: body.groupCode,
            isAdmin: false,
            from: body.from,
            messageTime: body.messageTime,
            isRead: false,
            fromName: body.fromName,
            id: body.id
          })
        });
      }
    });


  //  this.healthCareNotification_Subscribe = this.rxStompService
  //     .watch("/topic/healthcarenotification")
  //     .subscribe(message => {
  //       //console.log(message.body);
  //       this.receivedMessages.push(message.body);
  //       let body = JSON.parse(message.body);
  //       this.notificationType = body.notificationType;
  //       this.notificationCode = body.healthcareNotificationCode;
  //       this.username = this.session.get("username");
  //       // this.common.getUserDetails(this.username, (response) => {
  //       if (this.session.get("userCode") == body.userCode) {
  //         let datamessage: Array<any> = [];
  //         datamessage.push({
  //           title: "StaffWorks",
  //           alertContent: body.shortMessage
  //         });
  //         //////console.log(datamessage);
  //         this._notificationService.generateNotification(datamessage);
  //         this.openSnackBar(body.shortMessage);
  //         this.common.getAllHealthcareNotificationByUserCodeWithUnRead(
  //           this.session.get("userCode"),
  //           data => {

  //             if (data.responsecode == 200) {
  //               this.common.allNotificationsCount = data.data.length;
  //               //console.log(this.router.url);
  //               if (this.router.url == "/dashboard/notification") {
  //                 this.notification.getAllHealthcareNotificationByUserCodeWithReadByPage(
  //                   this.session.get("userCode"),
  //                   0,
  //                   15,
  //                   response => {
  //                     //console.log(response);
  //                     if (response.responsecode == 200) {
  //                       response.data.forEach(element => {
  //                         element.appointmentsDeatilsList = [];
  //                         if (element.appointmentDurationCode !== null) {
  //                           this.notification.getPatientDetailsByAppointmentDurationCodeList(
  //                             element.appointmentDurationCode,
  //                             responseNew => {
  //                               //console.log(responseNew);
  //                               if (responseNew.responsecode == 200) {
  //                                 element.appointmentsDeatilsList =
  //                                 responseNew.data;
  //                               }
  //                             }
  //                           );
  //                         }
  //                       });
  //                       this.common.allNotifications = [];
  //                       this.common.allNotifications = response.data;
  //                       this.common.dataSourceNotification = new MatTableDataSource(
  //                         this.common.allNotifications
  //                       );
  //                     }
  //                   }
  //                 );
  //                 // this.common.getAllHealthcareNotificationByUserCodeWithReadByPage(this.session.get('userCode'), 0, this.limit, (response) => {
  //                 //   //console.log(response);
  //                 //   if (response.responsecode == 200) {
  //                 //     this.common.allNotifications = data.data;
  //                 //   }
  //                 // })
  //               }
  //             } else {
  //               this.common.allNotificationsCount = "";
  //             }
  //           }
  //         );
  //         if (body.shortMessage == "Organization approval notification") {
  //           this.session.set("orgStatus", "Approved");
  //           this.common.organizationStatus = "Approved";

  //           //  this.router.navigate(['/dashboard/onboarding']);
  //         }
  //       }
  //       // });
  //     });


  }
  /* Name:srinivas
        use: return published message. */
  onSendMessage() {
    const message = `Message generated at ${new Date()}`;
    this.rxStompService.publish({
      destination: "/topic/notification",
      body: message
    });
  }
  /* Name:srinivas
        use: return notification deails. */
  openSnackBar(message) {
    try {
      if (this.notificationType == "Leave" || this.notificationType == "Late") {
        ////console.log(this.notificationCode);
        this.common.getHealthcareNotificationByHealthcareNotificationCode(
          this.notificationCode,
          this.common.local.get("userCode"),
          res => {
            ////console.log(res);
            if (res.responsecode == 200) {
              this.appointmentsDeatilsList = res.data;
            } else {
              this.appointmentsDeatilsList = [];
            }
          }
        );
        setTimeout(() => {
          this.snackBarref = this.snackBar.openFromComponent(
            NotificationMsgComponent,
            {
              verticalPosition: "top",
              horizontalPosition: "right",
              panelClass: "custom-snackbar",
              data: { message: message, data: this.appointmentsDeatilsList }
            }
          );
        }, 1000);
      } else {
        ////console.log('chat message');
        this.snackBarref = this.snackBar.openFromComponent(
          NotificationMsgComponent,
          {
            duration: 8000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: "custom-snackbar",
            data: { message: message, data: null }
          }
        );
      }
      this.snackBarref.instance.NotificationMsgComponent = this.snackBarref;
    } catch (error) {}
  }


  ngOnDestroy(): void
   {
    if (this.topicChatSubscription) {
      this.topicChatSubscription.unsubscribe();
    }

    // if (this.healthCareNotification_Subscribe) {
    //   this.healthCareNotification_Subscribe.unsubscribe();
    // }

  }

}
