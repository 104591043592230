import { OnboardingComponent } from './onboarding/onboarding.component';
import { RandomAutoAppointmentComponent } from './random-auto-appointment/random-auto-appointment.component';
import { ReportsComponent } from './reports/reports.component';
import { CertificationComponent } from './certification/certification.component';
import { LicenceComponent } from './licence/licence.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { ProvidersDashboardComponent } from './providers-dashboard.component';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { AppointmentComponent } from './appointment/appointment.component';
import { ProfileComponent } from './profile/profile.component';
// import { SettingsComponent } from './settings/settings.component';
import { ProcedureListComponent } from './procedure/procedure-list/procedure-list.component';
import { ProcedureEditComponent } from './procedure/procedure-edit/procedure-edit.component';
import { CreateAppointmentComponent } from './appointment/create-appointment/create-appointment.component';
import { PatientComponent } from './patient/patient.component';
import { SkillsComponent } from './skills/skills.component';
import { UpdateAppointmentComponent } from './appointment/update-appointment/update-appointment.component';
import { UpdateAppointmentSeriesComponent } from './appointment/update-appointment-series/update-appointment-series.component';
import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { PatientEditComponent } from './patient/patient-edit/patient-edit.component';
import { TechnicianListComponent } from './technician/technician-list/technician-list.component';
import { TechnicianEditComponent } from './technician/technician-edit/technician-edit.component';
import { NotificationComponent } from './notification/notification.component';
import { UnauthorizedComponent } from 'src/app/common/unauthorized/unauthorized.component';
import { SiteMaintenanceComponent } from 'src/app/common/unauthorized/site-maintenance/site-maintenance.component';
import { LocationComponent } from './location/location.component';
// import { ChatComponent } from './chat/chat.component';
import { ChatPageviewComponent} from './chat/chat-pageview/chat-pageview.component'
// import { ChatGroupComponent } from './chat/chat-group/chat-group.component';
import { DepartmentComponent } from './department/department.component';
import { AddDepartmentComponent } from './department/add-department/add-department.component';
import { SchedulingRulesComponent } from './scheduling-rules/scheduling-rules.component';
import { LocationLeafletComponent } from './location-leaflet/location-leaflet.component';
import { AppointmentListComponent } from './appointment/appointment-list/appointment-list.component';
import { MileageReportComponent } from './mileage-report/mileage-report.component';
 import { ShiftSegmentComponent } from './shift-segment/shift-segment.component';
import { NewJobComponent } from './appointment/new-job/new-job.component';
import { WeeklyCalendarComponent } from 'src/app/components/WeeklyCalendar-component/WeeklyCalendar-component';
import { LeaveComponent } from '../../components/leave/leave.component';
import { InvoiceComponent } from '../../components/generate-invoice/generate-invoice.component';
import { InvoiceDetailComponent } from '../../components/generate-invoice/invoice-detail/invoice-detail.component';
import { AdminCustomerReportsComponent } from "src/app/dashboards/providers-dashboard/admin-customer-reports/admin-customer-reports.component";
import { OrganizationDashboardComponent } from '../organization-dashboard/organization-dashboard.component';
import { SystemSettingsComponent } from './settings/system-settings/system-settings.component';
import { DistributionListComponent } from 'src/app/components/distribution-list/distribution-list.component';
import { NotesListComponent } from 'src/app/components/common-notes/notes-list/notes-list.component';
import { TrackingLogComponent } from '../technician-dashboard/tracking-log/tracking-log.component';
import { RouteTrackComponent } from './tracking/route-track/route-track.component';
import { TeamManagementComponent } from 'src/app/components/team-management/team-management.component';
import { InvoiceReportComponent } from 'src/app/components/generate-invoice/invoice-report/invoice-report.component';
import { TimeCardReportComponent } from './admin-customer-reports/time-card-report/time-card-report.component';
import { EmailTemplateComponent } from 'src/app/components/email-template/email-template.component';
import { BulkEmailComponent } from 'src/app/components/email-template/bulk-email/bulk-email.component';

const routes: Routes = [
  {
    // path: '', component: ProvidersDashboardComponent, canActivate: [AuthGuardService],
    path: '', component: OrganizationDashboardComponent, canActivate: [AuthGuardService],
    data: {
      title: "ProvidersDashboardComponent"
    }
  },
  // {
  //   path: 'OrganizationDashboard', component: OrganizationDashboardComponent ,  canActivate: [AuthGuardService]
  //   //, canActivate: [AuthGuardService]
  //   ,data: {
  //     title: "OrganizationDashboard"
  //   }
  // },
  {
    // path: 'appointment', 
    path: 'jobs', 
    component: AppointmentComponent, canActivate: [AuthGuardService],
    data: {
      title: "AppointmentComponent"
    }
  },
  {
    path: 'skills', component: SkillsComponent, canActivate: [AuthGuardService],
    data: {
      title: "SkillsComponent"
    }
  },
  {
    path: 'appointmentlist', component: AppointmentListComponent, 
    // canActivate: [AuthGuardService],
    // data: {
    //   title: "AppointmentListComponent"
    // }
  },

  {
    path: 'create-appointment', component: CreateAppointmentComponent, canActivate: [AuthGuardService],
    data: {
      title: "CreateAppointmentComponent"
    }
  },
  {
    path: 'profile', component: ProfileComponent, 
    // canActivate: [AuthGuardService],
    // data: {
    //   title: "ProfileComponent"
    // }
  },
  // {
  //   path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService],
  //   data: {
  //     title: "SettingsComponent"
  //   }
  // },

  {
    path: 'settings', component: SystemSettingsComponent, canActivate: [AuthGuardService],
    data: {
      title: "SettingsComponent"
    }
  },

  {
    // path: 'procedure/procedure-list',
    path: 'jobtype/jobtype-list',
     component: ProcedureListComponent, canActivate: [AuthGuardService],
    data: {
      title: "ProcedureListComponent"
    }
  },
  {
    path: 'jobtype/jobtype-edit', component: ProcedureEditComponent,
    //  canActivate: [AuthGuardService],
    // data: {
    //   title: "ProcedureEditComponent"
    // }
  },
  {
    // path: 'patient/patient-add',
    path: 'customer/customer-add',
     component: PatientComponent, 
    //  canActivate: [AuthGuardService],
    // data: {
    //   title: "PatientComponent"
    // }
  },
  {
    // path: 'patient/patient-list',
    path: 'customer/customer-list',
     component: PatientListComponent, canActivate: [AuthGuardService],
    data: {
      title: "PatientListComponent"
    }
  },
  {
    path: 'patient/patient-edit', component: PatientEditComponent, canActivate: [AuthGuardService],
    data: {
      title: "PatientEditComponent"
    }
  },
  {
    path: 'update-appointment', component: UpdateAppointmentComponent, canActivate: [AuthGuardService],
    data: {
      title: "UpdateAppointmentComponent"
    }
  },
  {
    path: 'update-appointment-series', component: UpdateAppointmentSeriesComponent, canActivate: [AuthGuardService],
    data: {
      title: "UpdateAppointmentSeriesComponent"
    }
  },
  {
    // path: 'technician/technician-list', 
    path: 'staffmember/member-list', 
    component: TechnicianListComponent, canActivate: [AuthGuardService],
    data: {
      title: "TechnicianListComponent"
    }
  },
  {
    // path: 'technician/technician-edit',
    path: 'staffmember/member-edit',
     component: TechnicianEditComponent,
    //   canActivate: [AuthGuardService],
    // data: {
    //   title: "TechnicianEditComponent"
    // }
  },

  {
    path: 'notification', component: NotificationComponent,
    //  canActivate: [AuthGuardService],
    // data: {
    //   title: "NotificationComponent"
    // }
  },
  {
    path: 'location', component: LocationComponent, canActivate: [AuthGuardService],
    data: {
      title: "LocationComponent"
    }
  },
  {
    path: 'department', component: DepartmentComponent, canActivate: [AuthGuardService],
    data: {
      title: "DepartmentComponent"
    }
  },
  {
    path: 'departmen/department-edit', component: AddDepartmentComponent, canActivate: [AuthGuardService],
    data: {
      title: "AddDepartmentComponent"
    }
  },
  {
    path: 'report', component: ReportsComponent, canActivate: [AuthGuardService],
    data: {
      title: "ReportsComponent"
    }
  },
  {
    path: 'certification', component: CertificationComponent
  },
  {
    path: 'licence', component: LicenceComponent
  },  
  // {
  //   path: 'chat', component: ChatComponent
  // },
  {
    path: 'scheduling-rules', component: SchedulingRulesComponent, canActivate: [AuthGuardService],
    data: {
      title: "SchedulingRulesComponent"
    }
  },
  {
    path: 'newlocation', component: LocationLeafletComponent, canActivate: [AuthGuardService],
    data: {
      title: "LocationLeafletComponent"
    }
  },
  {
    path: 'random-assign', component: RandomAutoAppointmentComponent, canActivate: [AuthGuardService],
    data: {
      title: "LocationLeafletComponent"
    }
  },
  {
    path: 'mileage-report', component: MileageReportComponent, canActivate: [AuthGuardService],
    data: {
      title: "MileageReportComponent"
    }
  }, 
  {
    path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuardService],
    data: {
      title: "OnboardingComponent"
    }
    
  },
  {
    path: 'weeklyview', component: WeeklyCalendarComponent,
    data: {
      title: "WeeklyCalendarComponentComponent"
    }
    
  },
  {
    path: 'communications/chat', component: ChatPageviewComponent,
  },
  {
    path: 'hr-skills/timeoff', component: LeaveComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'invoice', component: InvoiceComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'invoice-detail', component: InvoiceDetailComponent,
  },
  { path:'operations/timeclock', component: AdminCustomerReportsComponent ,canActivate: [AuthGuardService]},
  // {
  //   path: 'chatGroup', component: ChatGroupComponent, canActivate: [AuthGuardService],
  //   data: {
  //     title: "ChatGroupComponent"
  //   }
  // },
  // { path: '**', component: ProvidersDashboardComponent, canActivate: [AuthGuardService],
  //   data:{
  //     title: "ProvidersDashboardComponent"
  //   }
  // },
  // {path:'role',component:RoleComponent},
  // {path:'role-profile',component:RoleProfileComponent},
  { path: 'unauthorize', component: UnauthorizedComponent },
  {path:'shift-segment', component:ShiftSegmentComponent,canActivate: [AuthGuardService]},
  { path: 'site-maintenance', component: SiteMaintenanceComponent },
  {path:'shift-segment', component:ShiftSegmentComponent},
  {path:'new-job-creation' , component:NewJobComponent},
  {path: 'distribution', component : DistributionListComponent, canActivate: [AuthGuardService]},
  {path: 'notes', component : NotesListComponent, canActivate: [AuthGuardService]},
  {path:'tracking-log', component:TrackingLogComponent},
  {path:'track-route', component : RouteTrackComponent},
  {path: 'team-management', component : TeamManagementComponent, canActivate: [AuthGuardService]},
 
  {
    path:'invoice-report',
    component : InvoiceReportComponent , canActivate: [AuthGuardService]
  },
  {
    path:'time-card-report',
    component : TimeCardReportComponent , canActivate: [AuthGuardService]
  },
  {path: 'email-template', component : EmailTemplateComponent, canActivate: [AuthGuardService]},
  {path: 'bulk-email', component : BulkEmailComponent, canActivate: [AuthGuardService]},
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProvidersDashboardRoutingModule { }