<h4 class="mb-4" matDialogTitle>Repeat</h4>
 
<form [formGroup]="repeat" (ngSubmit)="onSubmit()">
  <div class="col-md-12 p-0">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
      <mat-label>Start Date</mat-label>
      <input [min]="today" matInput #nonworkingday (dateChange)="startDate($event.value)" formControlName="startDate" [matDatepicker]="picker2" (focus)="picker2.open()"
        placeholder="">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2 (closed)="nonworkingday.blur()"></mat-datepicker>
    </mat-form-field>
    <span class="help-block" *ngIf="!repeat.get('startDate').valid && (!submitted || repeat.get('startDate').touched)">Start date is required</span>
  </div>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field appearance="outline" floatLabel="always" >
        <mat-label>Repeat Every</mat-label>
          <mat-select formControlName="repeatnum">
            <mat-option *ngFor="let repeat of repeatEvery" [value]="repeat.value">
              {{repeat.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>  
        <span class="help-block" *ngIf="!repeat.get('repeatnum').valid && (!submitted || repeat.get('repeatnum').touched)">Please select any one of the options</span>        
    </div>
    <div class="col-md-6">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Repeat Every</mat-label>
            <mat-select formControlName="repeatevery" (selectionChange)="onChange($event)">
              <mat-option *ngFor="let evry of every" [value]="evry.value">
                {{evry.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block" *ngIf="!repeat.get('repeatevery').valid && (!submitted || repeat.get('repeatevery').touched)">Please select any one of the options</span>   
    </div>
  </div>
  <div class="col-md-12 p-0 weeks mb-3" *ngIf="week">
    <mat-checkbox [checked]="days.indexOf(2)!==-1" (change)="selectedDay($event,2)"></mat-checkbox>
    <mat-checkbox [checked]="days.indexOf(3)!==-1"  (change)="selectedDay($event,3)"></mat-checkbox>
    <mat-checkbox [checked]="days.indexOf(4)!==-1" (change)="selectedDay($event,4)"></mat-checkbox>
    <mat-checkbox [checked]="days.indexOf(5)!==-1"  (change)="selectedDay($event,5)"></mat-checkbox>
    <mat-checkbox [checked]="days.indexOf(6)!==-1"  (change)="selectedDay($event,6)"></mat-checkbox>
    <mat-checkbox [checked]="days.indexOf(7)!==-1"  (change)="selectedDay($event,7)"></mat-checkbox>
    <mat-checkbox [checked]="days.indexOf(1)!==-1"  (change)="selectedDay($event,1)"></mat-checkbox>
  </div>
  <div class="col-md-12 months p-0" *ngIf="month">
    <mat-radio-group aria-label="Select an option" formControlName="isDateOfMonth">
        <mat-radio-button value="true" class="text-capitalize">On Day {{day}}</mat-radio-button>
        <mat-radio-button value="false" class="text-capitalize">On The {{weekdaynumber}} {{weekday}}</mat-radio-button>
      </mat-radio-group>
  </div>
  <div class="col-md-12 p-0">
      <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
        <mat-label>End Date</mat-label>
        <input [min]="today" matInput #nonworkingday formControlName="endDate" [matDatepicker]="picker1" (focus)="picker1.open()"
          placeholder="">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 (closed)="nonworkingday.blur()"></mat-datepicker>
      </mat-form-field>
      <span class="help-block" *ngIf="!repeat.get('endDate').valid && (!submitted || repeat.get('endDate').touched)">End date is required</span>
    </div>
    <div class="text-center buttons-bottom">
      <a class="cursor-pointer" (click)="cancel()">Cancel</a>
      <button mat-raised-button color="primary">Save</button>
    </div>
</form>
