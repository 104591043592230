import { Injectable } from "@angular/core";
import { SessionStorageService } from "angular-web-storage";

interface validUrlTree {
  path: string,
  addPermission: boolean | null,
  editPermission: boolean | null,
  viewPermission: boolean | null,
  delPermission: boolean | null
}

@Injectable({
  providedIn: 'root'
})

export class ValidateRouteService {

  private validRoutes: validUrlTree[] = [];

  constructor(
    private _sessionSVC: SessionStorageService
  ) {

    if (this._sessionSVC.get('MenuListDataAndPagePermission') && this._sessionSVC.get('MenuListDataAndPagePermission').ppLst?.length > 0) {
      this.setValidRoutes();
    }

  }


  /*
  will set authorized routes in state "validRoutes" ;
  */
  setValidRoutes() {

    if(this._sessionSVC.get('user_type') === "Staff")
      {
        return
      }

    // default push as per logged in user
  if(this._sessionSVC.get('user_type') !== "Staff" && this._sessionSVC.get('user_type') !== "Customer")
      {
        this.validRoutes = [ 
          this.makeUrlObj('/dashboard/profile')  // allowing access to view "profile page" for admin users only...
         ]
      }
      else
      {
        this.validRoutes=[];
      }

    
 
    const ppList: any[] = this._sessionSVC.get('MenuListDataAndPagePermission').ppLst || [];

    ppList.forEach(outer_ele => {
      // this.validRoutes.push(outer_ele.pageUrl);
      const { pageUrl, addPermission, editPermission, viewPermission, delPermission } = outer_ele;
      this.validRoutes.push(
        {
          path: pageUrl,
          addPermission: addPermission,
          editPermission: editPermission,
          viewPermission: viewPermission,
          delPermission: delPermission
        }
      )

      if (outer_ele.childList.length > 0) {
        outer_ele.childList.forEach(inner_ele => {
          //this.validRoutes.push(inner_ele.pageUrl);
          const { pageUrl, addPermission, editPermission, viewPermission, delPermission } = inner_ele;

          this.validRoutes.push(
            {
              path: pageUrl,
              addPermission: addPermission,
              editPermission: editPermission,
              viewPermission: viewPermission,
              delPermission: delPermission
            }
          )

        });
      }

    });


// manually assigning routes after checking permissions.
if(this._sessionSVC.get('user_type') !== "Staff" && this._sessionSVC.get('user_type') !== "Customer")
  {

    if (this.has_only_add_permission("/dashboard/jobs")) {
      this.validRoutes.push(this.makeUrlObj('/dashboard/new-job-creation'));
    }
 
    if (this.has_add_or_Edit_permission("/dashboard/jobtype/jobtype-list")) {
      this.validRoutes.push(this.makeUrlObj('/dashboard/jobtype/jobtype-edit'));
    }
     
    if (this.has_add_or_Edit_permission("/dashboard/staffmember/member-list")) {
      this.validRoutes.push(this.makeUrlObj('/dashboard/staffmember/member-edit'));
    }

    if (this.has_add_or_Edit_permission("/dashboard/customer/customer-list")) {
      this.validRoutes.push( this.makeUrlObj('/dashboard/customer/customer-add'));
    }

    if (this.has_add_or_Edit_permission("/dashboard/invoice")) {
      this.validRoutes.push( this.makeUrlObj('/dashboard/invoice-detail'));
    }

  }
   
  if(this._sessionSVC.get('user_type') === "Customer")
    {
      if (this.has_add_or_Edit_permission("/dashboard-customer/invoice")) {
        this.validRoutes.push( this.makeUrlObj('/dashboard-customer/invoice-detail'));
      }

      if (this.has_add_or_Edit_permission("/dashboard-customer/requested-new-job-list")) {
        this.validRoutes.push( this.makeUrlObj('/dashboard-customer/request-new-job'));
      }

    }

 

 // console.log("validRoutes", this.validRoutes);

  }

  has_add_or_Edit_permission(path: string) {
    const { addPermission, editPermission } = this.validRoutes.find(ele => ele.path === path.toLocaleLowerCase() ) || {addPermission:false, editPermission:false };
    return addPermission || editPermission;
  }


  has_only_add_permission(path: string) {
    const { addPermission } = this.validRoutes.find(ele => ele.path === path.toLocaleLowerCase() ) || {addPermission:false};
    return addPermission ;
  }


  has_viewPermission(pageUrl:string)
  {
    console.log(pageUrl)
    console.log("has_viewPermission for" ,  this.validRoutes  );
    const { viewPermission }  = this.validRoutes.find(ele => ele.path === pageUrl.toLocaleLowerCase() ) || {viewPermission : false};
    console.log(viewPermission)
    return viewPermission;
  }


  makeUrlObj(path:string):validUrlTree {
    return {
      path: path,
      addPermission: null,
      editPermission: null,
      delPermission: null,
      viewPermission: null
    }
  }

  isValidRoute(route: string) {
    return this.validRoutes.some(path => path.path === route);
  }

  resetRouteCheck() {
    this.validRoutes = [];
  }



}

