// import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
// import { LOCAL_VARIABLES, TOAST_TYPE } from 'src/app/shared/constants/constants';
import { FormControl, FormGroup, Validators } from "@angular/forms";
// import { ConfirmationService } from "primeng/api";
// import { UsersService } from 'src/app/feature/users/users.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EmailTemplateService} from './email-template-service.service';
import { TranslateService } from '@ngx-translate/core';
 import { debounce } from 'lodash';
import { AddEmailTemplateComponent } from './add-email-template/add-email-template.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent implements OnInit {

  listData: any = [];
  displayedColumns: string[] = ['templateName', 'templateSubject', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  hasPermission:IBtnControlsPermission ;
  user_page:number = 0;
  user_limit:number = 20;
  is_Loading:boolean = false;
  img_serverPath: string = '';
  distributionUsers:any =[];
  tbl_selectedDistribution : any;
  staff_SearchText:string = '';

  modules = {}

  @ViewChild('editor') editor: any;

  orgCode: any;

  editorElem!: HTMLElement;

  title = 'angular-quill-example-app';

  blured = false
  focused = false

  templateForm!: FormGroup;
  editTemplateData: any;
  submitted = false;
  display: boolean = false;
  popupTitle: string = "Add Template";
  dataLoaded = false;
  isLoading: boolean = false;
  isSendTemplate: boolean = false;
  allTemplates = [];
  allRoleMasters = [];
  selectedRoles: any = [];
  selectedUsers: any = [];
  selectedCompany: any;
  columnHeader: any = [
    {
      label: "Template",
      value: "templateName",
      isSortable: true,
    },
    {
      label: "Subject",
      value: "Subject",
    },
    {
      label: "Action",
      value: "Action",
    }
  ];

  userListData: any;

  companyList: any = [
    {
      "name": "Company Name",
      "value": '{Company Name}'
    },
    {
      "name": "User Name",
      "value": '{User Name}'
    }
  ];
  searchData: any = "";
  searchControl = new FormControl;
  isQuillPreview:boolean = false;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    public emailTemplateService: EmailTemplateService,
  ) {
    this.orgCode = this.emailTemplateService.storage.get('orgCode');

    // this.modules = {
    //   'emoji-shortname': true,
    //   'emoji-textarea': false,
    //   'emoji-toolbar': true,
    //   'toolbar': {
    //     container: [
    //       [{ 'placeholder': ['[GuestName]', '[HotelName]'] }],
    //       [{ font: [] }],
    //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
    //       ["bold", "italic", "underline", "strike"],
    //       [{ color: [] }, { background: [] }],
    //       [{ script: "sub" }, { script: "super" }],
    //       ["blockquote", "code-block"],
    //       [{ list: "ordered" }, { list: "bullet" }],
    //       [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    //       ["link", "image", "video"],
    //       [{ 'header234': [1, 2, 3, 4, 5, 6, 7] }],
    //       ["clean"],
    //       // ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    //       // ['blockquote', 'code-block'],

    //       // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    //       // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //       // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    //       // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    //       // [{ 'direction': 'rtl' }],                         // text direction

    //       // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    //       // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    //       // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //       // [{ 'font': [] }],
    //       // [{ 'align': [] }],

    //       // ['clean'],                                         // remove formatting button

    //       // ['link', 'image', 'video'],                         // link and image, video
    //       // ['emoji'],
    //     ]
    //   }
    // }
  }

  ngOnInit(): void {
    this.hasPermission =  this.emailTemplateService.common.getBtnEventPermissions("Email Template");
    this.orgCode = this.emailTemplateService.storage.get('orgCode');
    this.page = 0;
    this.img_serverPath = this.emailTemplateService.apiRootService.getSuperAdmin() + '/downloadFile/';
    this.getAllEmailTemplateByPage(0, false);
    console.log('hasPermission: ', this.hasPermission);
  }


        /* Name:Vivek Chauhan
      use: for open add/create popup */
      addNew(dataForedit = null): void {
        const dialogRef = this.dialog.open(AddEmailTemplateComponent, {
          width: '850px',
          height: 'auto',
          maxHeight: '94vh',
          maxWidth:'850px',
          disableClose: true,
          data: { templateData : dataForedit, isSend: false}
        });
    
        dialogRef.updatePosition({
          top: '1%'    // Set top position
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined && result === 'success') {
            this.page = 0;
            this.getAllEmailTemplateByPage(0, false);
          }
        });
      }

              /* Name:Vivek Chauhan
      use: for open Sent template on emails popup */
      sendTemplate(dataForedit = null): void {
        const dialogRef = this.dialog.open(AddEmailTemplateComponent, {
          width: '850px',
          height: 'auto',
          maxHeight: '94vh',
          maxWidth:'850px',
          disableClose: true,
          data: { templateData : dataForedit, isSend: true}
        });
    
        dialogRef.updatePosition({
          top: '1%'    // Set top position
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined && result === 'success') {
            this.page = 0;
            this.getAllEmailTemplateByPage(0, false);
          }
        });
      }

  // lazyLoadData(event: any) {
    onScroll() {
    // console.log(event);
    // console.log("scrolled down!!");
    this.page = this.page + 1;
    this.getAllEmailTemplateByPage(this.page, true);
  }

  applyFilter(search) {
    this.page = 0;
    if(search && search.trim()){
      const searchText = search.trim().toLowerCase();    
      this.getAllEmailTemplateByPage(this.page, false, searchText);
    } else {
      this.getAllEmailTemplateByPage(this.page, false);
    }
  }

  getAllEmailTemplateByPage(page: number, isSecroll: boolean, searchText = null) {
    // console.log('getAllEmailTemplateByPage: ', page, isSecroll);
    this.emailTemplateService.common.progressLoader = true;
    if(isSecroll && !searchText){
      this.emailTemplateService.getAllEmailTemplateByPage(this.orgCode, searchText, page, this.limit).subscribe((res: any) => {
        // console.log(res);
        this.dataLoaded = true;
        if (res.responsecode === 200) {
          // console.log('getAllEmailTemplateByPage: ', res);
            this.allTemplates = res?.data && res?.data.length > 0 ? this.allTemplates.concat(res?.data) :  this.allTemplates;
            setTimeout(() => {
              this.dataSource = new MatTableDataSource(this.allTemplates);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.emailTemplateService.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.emailTemplateService.common.progressLoader = false;
            }, 1000); 
        }
      });
    } else {
      this.page = 0;
    this.emailTemplateService.getAllEmailTemplateByPage(this.orgCode, searchText, page, this.limit).subscribe((res: any) => {
      // console.log(res);      
      this.dataLoaded = true;
      if (res.responsecode === 200) {
        // console.log('getAllEmailTemplateByPage: ', res);
          setTimeout(() => {
            this.allTemplates = res.data;
            this.dataSource = new MatTableDataSource(this.allTemplates);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.emailTemplateService.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.emailTemplateService.common.progressLoader = false;
          }, 1000)
      } else {  
        this.dataLoaded = true;
        this.allTemplates = [];
        setTimeout(() => {
          this.dataSource = new MatTableDataSource();
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.emailTemplateService.common.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sort;
          this.emailTemplateService.common.progressLoader = false;
        }, 1000); 
      }
    });
  }
  }

      /* Name:Vivek Chauhan new API
  use: for delete/remove Distribution */
  deleteTemplate(data :any){
    // //console.log('deleteTemplate: ', data);    
    if(data?.emailTemplateCode){ 
      const dialog = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { 
          msg: 'Are you sure you want to delete this template?'
        }
      });      
  
      dialog.afterClosed().subscribe(result => {    
        if(result && result.toLowerCase() === 'ok'){ 
          this.emailTemplateService.common.progressLoader = true;
          this.emailTemplateService.softDeleteByCode(data.emailTemplateCode).subscribe((response: any) => {
            //console.log(response);
            this.emailTemplateService.common.progressLoader = false;
            if (response.responsecode == 200) {
              this.emailTemplateService.common.successMessage = true;
              this.emailTemplateService.common.message = response.message;
              this.page = 0;
              this.getAllEmailTemplateByPage(0, false);              
            } else {
              this.emailTemplateService.common.errorMessage = true;
              this.emailTemplateService.common.message = response.message;
            }
          });
        }
      });
    }
  }

  preview(){
    console.log('preview: ', true);
    this.isQuillPreview = true;
  }


}
