import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  procedureForm = {
    "id": 0,
    "organizationBaseProcedureCode": "",
    "organizationCode": "",
    "procedureCode": "",
    "procedureDescription": "",
    "procedureIcon": "",
    "procedureName": "",
    "orgSkillList": [],
    "isDateFlexible" : false,
    "regularRate" : null,
    "overTimeRate" : null,
    "jobColor":"",
    "noteRequest": {
      "isPublic": false,
      "note": ""
    }
  }

  

  constructor(
    public service: ApiRootService,
    public local: SessionStorageService,
    public http: HttpClient,
    public common: CommonService
  ) { }

  /* Name:Shailesh
      use: for getting all procedures by organization code with page*/
  getAllProcedureByOrganizationCodeByPage(code, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCodeByPage/' + code + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vivek Chauhan
    use: for procedures by search text*/
    getAllProcedureByOrganizationCodeByPageSearch(code, searchText, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCodeByPage/' + code + '/' + searchText).subscribe((data) => {
        callback(data);
      })
    }

  /* Name:Shailesh
      use: for getting all procedures by organization code */
  getAllProcedureByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: for adding organization based procedure */
  saveOrganizationBasedProcedure(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrganizationBasedProcedure', formData).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: for getting all procedures  */
  getAllProcedureMaster(callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureMaster').subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: for getting all organization based procedure by procedure code */
  getProcedureByOrganizationBasedProcedureCode(procedureCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getProcedureByOrganizationBasedProcedureCode/' + procedureCode).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: for update procedure by organization based procedure code */ 
  updateProcedureByOrganizationBasedProcedureCode(orgBasedCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateProcedureByOrganizationBasedProcedureCode/' + orgBasedCode, formData).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
      use: for getting all skills by organization code */
  getAllSkills(code, callback) { 
    return this.http.get(this.service.getHealthCareUrl() + '/getSkillMasterByOrganizationCode/'+code).subscribe((data) => {
      callback(data);
    })
  }

  getAllSkillsV2(code, callback) { 
    return this.http.get(this.service.getHealthCareUrl() + '/v2/getSkillsByOrganizationCode/'+code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Vivek Chauhan new API
    use: for delete/remove Job Type */
    softDeleteOrganizationBasedProcedureByOrganizationBasedProcedureCode(organizationBaseProcedureCode, callback) {
      return this.http.delete(this.service.getHealthCareUrl() + '/softDeleteOrganizationBasedProcedureByOrganizationBasedProcedureCode/' + organizationBaseProcedureCode).subscribe((data) => {
        callback(data);
      })
    }

    getAllJobTypeIcons()
    {
      return this.http.get(this.service.getHealthCareUrl() + '/getAllJobTypeIcon')
    }

}
