<div class="container">
<div class="row mb-3">
  <div class="col-md-8 text-start p-0">
    <h4 class="header-name">{{ 'common.labels.Notifications' | translate }}</h4>
  </div>

  <ng-template [ngIf]="activeTab === 'Unread' && !expiredNotifications">
  <div class="col-md-4 text-end p-0" *ngIf="dataSource.data.length > 0">
    <button mat-raised-button class="me-3" color="primary" (click)="!isMarkAsReadLoading && markAsReadeventSelected()"><span>Mark as Read </span><span *ngIf="isMarkAsReadLoading" class="spinner-border spinner-border-sm"></span></button>
    <button mat-raised-button color="primary"  (click)="!isMarkAllLoading && markAllAsRead()"><span>Mark All as Read </span><span *ngIf="isMarkAllLoading" class="spinner-border spinner-border-sm"></span></button>
  </div>
  </ng-template>

</div>


<mat-tab-group dynamicHeight class="tab-button-auto-width" #tabGroup (selectedTabChange)="onTabChanged($event)">      
  <mat-tab label="Unread">
    <!-- Put Empty Tab Content -> Data Content is showing based on tab selection below -->
  </mat-tab>
  <mat-tab label="Expired">
    <!-- Put Empty Tab Content -> Data Content is showing based on tab selection below -->
  </mat-tab>
</mat-tab-group>

<div *ngIf="service.common.infiniteLoader" class="mt-1">
<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="row mt-2 table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
  <table mat-table [dataSource]="dataSource" class="example-table w-100"
      matSort matSortActive="created" matSortDisableClear matSort
    matSortDirection="desc">

    <ng-container matColumnDef="read">
      <th class="read " mat-header-cell *matHeaderCellDef>{{ 'common.labels.Read' | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <span>
          <mat-checkbox (change)="markAsReadevent($event, element.staffNotificationCode)">
            {{ 'common.labels.Read' | translate }}</mat-checkbox>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="notificationDate">
      <th class="read" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common.labels.Date' | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <span>
          {{element.notificationDate}}
        </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="description">
      <th  mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'main.labels.Notification' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <span [innerHTML]="element.description"></span>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="call">
      <th class="ps-4 ms-4" mat-header-cell *matHeaderCellDef> </th>
      <td class="ps-4 ms-4 text-end" mat-cell *matCellDef="let element">
        <span *ngIf="element.appointmentsDeatilsList.length>0" (click)="openPatient(element)">
          <i class="material-icons menu-icon cursor-pointer"> list </i>
        </span>

      </td>
    </ng-container> -->


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <div *ngIf="showMessage">
    {{ 'common.labels.No notifications present' | translate }}
  </div> -->
  
  <div class="mt-4" *ngIf="dataSource.data.length === 0">
    <span class="text-danger">No notifications are present. </span>
   <!-- <span class="text-danger">{{ 'common.labels.No notifications present' | translate }}</span> -->
  </div>

</div>
<!-- <div class="w-100" *ngIf="service.common.infiniteLoader">
  <div class="loadergif">
    <img src="assets/typint_dots_black.gif" alt="Loader">
    <p>Loading...</p>
  </div>
</div> -->

</div>