import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';
import { TechnicianDashboardComponent } from './technician-dashboard.component';
import { TechProfileComponent } from './tech-profile/tech-profile.component';
import { TechSettingComponent } from './tech-setting/tech-setting.component';
import { TimeOfRequestComponent } from './time-of-request/time-of-request.component';
import { PreferenceSettingComponent } from './preference-setting/preference-setting.component';
import { NewAppointmentComponent } from './new-appointment/new-appointment.component';
import { ElegibleAppointmentComponent } from './elegible-appointment/elegible-appointment.component';
import { NotificationComponent } from '../providers-dashboard/notification/notification.component';
import { UnauthorizedComponent } from 'src/app/common/unauthorized/unauthorized.component';
import { TrackingLogComponent } from './tracking-log/tracking-log.component';
import { TechnicianEditComponent } from '../providers-dashboard/technician/technician-edit/technician-edit.component';
import { SiteMaintenanceComponent } from 'src/app/common/unauthorized/site-maintenance/site-maintenance.component';

const routes: Routes = [
  {path: '', component: TechnicianDashboardComponent, canActivate: [AuthGuardService],
    data:{
      title: "TechnicianDashboardComponent"
    }
  },
  {path: 'tech-profile', component: TechProfileComponent, canActivate: [AuthGuardService],
    data:{
      title: "TechProfileComponent"
    }
  },
  {path: 'tech-setting', component: TechSettingComponent, canActivate: [AuthGuardService],
    data:{
      title: "TechSettingComponent"
    }
  },
  {path: 'time-of-request', component: TimeOfRequestComponent, canActivate: [AuthGuardService],
    data:{
      title: "TimeOfRequestComponent"
    } 
  },
  {path: 'preference-setting', component: PreferenceSettingComponent, canActivate: [AuthGuardService],
    data:{
      title: "PreferenceSettingComponent"
    }
  },
  {path: 'new-appointment', component: NewAppointmentComponent, canActivate: [AuthGuardService],
    data:{
      title: "NewAppointmentComponent"
    }
  },
  {path: 'elegible-appointment', component: ElegibleAppointmentComponent, canActivate: [AuthGuardService],
    data:{
      title: "ElegibleAppointmentComponent"
    }
  },
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuardService],
    data:{
      title: "NotificationComponent"
    }
  },
  // { path: 'tracking-log', component: TrackingLogComponent, canActivate: [AuthGuardService],
  //   data:{
  //     title: "TrackingLogComponent"
  //   }
  // },
  { path: 'unauthorize', component: UnauthorizedComponent },
  { path: 'site-maintenance', component: SiteMaintenanceComponent },
  {
     path: 'self/update',  component: TechnicianEditComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TechnicianDashboardRoutingModule { }