import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProvidersDashboardService } from '../../providers-dashboard.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppointmentService } from '../appointment.service';

@Component({
  selector: 'app-notes-popup',
  templateUrl: './notes-popup.component.html',
  styleUrls: ['./notes-popup.component.scss']
})
export class NotesPopupComponent implements OnInit {
  submitted = true;
  editShow = false;
  recurringNoteForm : FormGroup;
  // hide = 'Show';

  constructor(public dialogRef: MatDialogRef<NotesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,  public translate: TranslateService,public service: ProvidersDashboardService,
    public editService: AppointmentService) { 
      this.translate.setDefaultLang(this.service.session.get('language'));
    }

  ngOnInit() { 
    ////console.log(this.data);
    this.recurringNoteForm = new FormGroup ({
      'editRecNote': new FormControl(null, Validators.required)
    }) 
  }
/* Name:srinivas
       use: save recurring Notes details. */
  onSubmit(){
    if (this.recurringNoteForm.valid){
      this.submitted = true;
      let formData = {
          "recurringNotes": this.data.patientRecurringNotes
      }
      try {
        
      
      this.editService.updatePatientRecurringNotesByPatientCode(this.data.patientCode, formData, (res) => {
        ////console.log(res);
      })
    } catch (error) {
        
      }
      this.showhide();
    } else {
      this.submitted = false;
    } 
  }
/* Name:srinivas
       use: show or hide */
  showhide(){
    // ////console.log(event);
    this.editShow = !this.editShow;
  }
/* Name:srinivas
       use: close popup */
  onNoClick(): void {
    this.dialogRef.close();
  }

    
    

}
