<div class="w-100 p-0 m-0">

   <div class="row mb-3">
      <div class="col-md-12">
          <label class="header-name">Tracking Data </label>
      </div>
  </div>

  <form [formGroup]="tracking_Form" class="trackingForm">
  <div class="row g-1">
    
    <div class="col-2">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Date</mat-label>
        <input matInput
         [matDatepicker]="tracking_datePicker"
          formControlName="tr_date" >
       <mat-datepicker-toggle matIconSuffix [for]="tracking_datePicker"></mat-datepicker-toggle>
        <mat-datepicker #tracking_datePicker></mat-datepicker>
      </mat-form-field>
      <span class="help-block err" *ngIf="tracking_Form.get('tr_date').errors?.required && (formSubmitted || tracking_Form.get('tr_date').touched)">
        field is required
      </span>
    </div>

    <div class="col-2 form-group outline-textbox position-relative" >
       <label class="positionAbsLabel">From Time</label>
        <input type="time" formControlName="tr_fromTime" id="fromTime_timePicker" class="form-control timeInput"  />
        <span class="help-block err" *ngIf="tracking_Form.get('tr_fromTime').errors?.required && (formSubmitted || tracking_Form.get('tr_fromTime').touched)">
          field is required
        </span>
     </div>

    <div class="col-2 form-group outline-textbox position-relative">
      <label class="positionAbsLabel">To Time</label>
      <input type="time" formControlName="tr_toTime" id="toTime_timePicker" class="form-control timeInput"  />
      <span class="help-block err" *ngIf="tracking_Form.get('tr_toTime').errors?.required && (formSubmitted || tracking_Form.get('tr_toTime').touched)">
        field is required
      </span>
    </div>

    <div class="col-2">
      <mat-form-field  appearance="outline" floatLabel="always">
        <mat-label>Interval(in minutes)</mat-label>
        <input matInput formControlName="tr_interval">
       </mat-form-field>
       <span class="help-block err" *ngIf="tracking_Form.get('tr_interval').errors?.required && (formSubmitted || tracking_Form.get('tr_interval').touched)">
        field is required
      </span>
    </div>

    <div class="col-3">
      <staff-list-select
      (selectionChanged)="onStaffChanged($event)"
       >
     </staff-list-select>
     <span class="help-block err" *ngIf="tracking_Form.get('tr_staffCode').errors?.required && (formSubmitted || tracking_Form.get('tr_staffCode').touched)">
      field is required
    </span>
    </div>

    <div class="col-1">
      <button type="button"mat-raised-button color="primary" (click)="getTrackingData()">Search</button>
    </div>
 
  </div>
</form>

  </div>

  <div class="row mt-3">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div
    id="tr_map"
    #mapDis
    style="overflow: visible; height: calc(100vh - 310px)"
  ></div>

    <!-- <google-map  #myGoogleMap [options]="mapOptions" style="width: 100%;">
     <map-marker *ngFor="let marker of markers"
        #markerElem="mapMarker"
         [position]="marker.position"
          [label]="marker.label"
          (mapClick)="openInfo(markerElem,marker.trackingDateTime)"
          (mapMouseover)="openInfo(markerElem,marker.trackingDateTime)"
          (mapMouseout)="closeInfo()"
        ></map-marker>
        <map-info-window>
           <div [innerHTML]="infoContent"></div>
        </map-info-window>
      </google-map> -->
</div>