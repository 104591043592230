import { Component, OnInit, Inject } from '@angular/core';
import { ElegibleAppointmentService } from '../elegible-appointment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-elegible-appointment-filter',
  templateUrl: './elegible-appointment-filter.component.html',
  styleUrls: ['./elegible-appointment-filter.component.scss']
})
export class ElegibleAppointmentFilterComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  procedureCode = 'all';
  constructor(
    public service: ElegibleAppointmentService,
    public dialogRef: MatDialogRef<ElegibleAppointmentFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

    ////console.log(this.data);
    try {


      this.service.mobileAdminGetEligibleProcedureListByTechnicianCode(this.service.session.get('userCode'), (response) => {
        ////console.log(response);
        if (response.responsecode) {
          response.data.forEach(element => {
            element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
            element.itemName = element.procedureName;
            this.dropdownList.push(element);
          });
          if (this.data.data == 'all') {
            response.data.forEach(element => {
              this.selectedItems.push(element);
            });
          } else {
            const selected = [];
            let prevSelected = [];
            prevSelected = this.data.data.split(',');
            // ////console.log(prevSelected);
            // ////console.log(this.dropdownList);
            prevSelected.forEach((element) => {
              let pro = this.dropdownList.filter((e) => {
                return e.organizationBaseProcedureCode == element;
              });
              // ////console.log(pro);
              if (pro) {
                selected.push(pro.pop());
              }
            });
            setTimeout(() => {
              // ////console.log(selected);
              this.selectedItems = selected;
            }, 200)
          }
        }
      })

      this.dropdownSettings = {
        singleSelection: false,
        text: "",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: "customClassAng2Multiselect",
        // badgeShowLimit: 5,
        maxHeight:200,
        autoPosition: true
      };
    } catch (error) {

    }
  }


  onItemSelect(item: any) {
    ////console.log(item);
  }
  OnItemDeSelect(item: any) {
    ////console.log(item);
    //////console.log(this.selectedItems);
    // this.getFilterdData();
  }

  onSelectAll(items: any) {
    ////console.log(items);
    //  this.getFilterdData();
  }
  onDeSelectAll(items: any) {
    ////console.log(items);
  }

  onNoClick(status): void {
    if (status != 'all') {
      let selectedData = []
      if (this.selectedItems.length > 0) {
        this.selectedItems.forEach(element => {
          selectedData.push(element.organizationBaseProcedureCode);
        });
        // ////console.log(this.selectedItems.length);
        // ////console.log(selectedData, selectedData.toString());
        if (this.dropdownList.length == selectedData.length) {
          this.procedureCode = 'all';
        } else {
          this.procedureCode = selectedData.toString();
        }
      }
    } else {
      this.procedureCode = 'all';
    }
    ////console.log(this.selectedItems,this.procedureCode)
    this.dialogRef.close(this.procedureCode);
  }

  onClose() {
    this.dialogRef.close();
  }

}
