import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TechnicianService } from '../technician.service';
import { RoleService } from 'src/app/components/role/role.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
 import { MatTabChangeEvent } from '@angular/material/tabs';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { AddMemberComponent } from '../add-member/add-member.component';
import * as XLSX from 'xlsx';
import { SkillsService } from '../../skills/skills.service';
import { MatTabGroup } from '@angular/material/tabs';
import { debounce } from 'lodash';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { AddUpdateNoteComponent } from 'src/app/components/common-notes/add-update-note/add-update-note.component';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { DirectEmailSendComponent } from 'src/app/components/email-template/direct-email-send/direct-email-send.component';
 
@Component({
  selector: 'app-technician-list',
  templateUrl: './technician-list.component.html',
  styleUrls: ['./technician-list.component.scss'],
})

export class TechnicianListComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;;
  displayedColumns: string[] = ['name', 'email', 'role', 'phone', 'employmentTypeName', 'skills', 'isAppUseFor', 'workerStatusCode'];
  dataSource = new MatTableDataSource();
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  public show = false;
  public searchClick: any = 'Show';
  technicianList = [];
  allRolesList = [];
  allSkillList = [];
  selectedSkillList = [];
  allSortNameList = [
    {'id': 1, 'name': 'First Name', 'key': 'firstName', 'isSort': true, 'isActive': false, 'order': 'A-Z'},
    {'id': 2, 'name': 'First Name', 'key': 'firstName', 'isSort': false, 'isActive': false, 'order': 'Z-A'},
    {'id': 3, 'name': 'Last Name', 'key': 'lastName', 'isSort': true, 'isActive': false, 'order': 'A-Z'},
    {'id': 4, 'name': 'Last Name', 'key': 'lastName', 'isSort': false, 'isActive': false, 'order': 'Z-A'}
  ]
  selectedSkillCodeListForSort = [];
  dataSourceForCardView = [];
  sortKeyName: any = null;
  isSortOrderAtoZ: boolean = true;
  searchTerm;
  technicianName = null;
  employmentType = 'all';
  expFromYear = 'all';
  expFromMonth = 'all';
  expToYear = 'all';
  expToMonth = 'all';
  isSkillLoading: boolean = false;
  isSkillSelected: boolean = false;
  searchText;
 // currentUpldStaff_File : any = [];
  tabChangeActiveInactive: string = 'Active';
  viewPoint:string = "cardview";
  hasPermission:IBtnControlsPermission ;
  staffCountData: any = null;
  currentUpldStaff_File : any = [];
  isSendMailLoading: boolean = false;
  dialogRef:any ;
   
  skillList:any = [];
  FileHeader:string[] = [
   "First Name*",
   "Last Name*",
   "Email*",
   "CA ID",
   "Phone*",
   "Work Phone",
   "Address",
   "City",
   "State",
   "Zip",
   "Country",
   "Notes"
  ]; 

  @ViewChild('addNote_popUp') addNote_popUp: TemplateRef<any>;
  @ViewChild('schedule_view_popup') schedule_view_popup: TemplateRef<any>;
   

  protected notes:string = '';
  public workerCode :string | null = null ;
  private clearObs = new Subject<any>();
  
  constructor(
    public technicianService: TechnicianService,
    public roleServices: RoleService,
    public common: CommonService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public _skillSVC: SkillsService,
    private _commonAPI_SVC : CommonApiService,
    )
    {
    this.translate.setDefaultLang(this.common.local.get('language'));
    this.search = debounce(this.search, 500);
  }

  ngOnInit() {
     this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
      this.getAllRoleByOrgCodeByPage();
      this.hasPermission =  this.common.getBtnEventPermissions("Staff Directory");
      this.getSkillList();
      this.subToPermissionChanged();
  } 

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  /* Name:Shailesh
      use: for getting worker and organization detail by org code , page and limit and used it to show the listing of 
      of technician/clinician in technician list page*/

  getData(page, limit, scroll, isActiveUserList:boolean, isSortOrderAtoZ: boolean, sortKeyName: any) {
    // console.log('GetData', page, limit, scroll, isActiveUserList, isSortOrderAtoZ, sortKeyName);    
    try {
      if (scroll) {
        this.technicianService.common.infiniteLoader = true;        
      } else {
        this.technicianService.common.progressLoader = true;
        this.getStaffCountByOrganizationCode();
      } 

      let reqBody = {
        isActive: isActiveUserList,
        isAscending: isSortOrderAtoZ,
        organizationCode: this.technicianService.local.get('orgCode'),
        searchName: this.technicianName ? this.technicianName : null,
        searchKey: (this.isSkillSelected && this.selectedSkillCodeListForSort && this.selectedSkillCodeListForSort.length > 0) ? 'skill' : null,
        searchValue: (this.isSkillSelected && this.selectedSkillCodeListForSort && this.selectedSkillCodeListForSort.length > 0) ? this.selectedSkillCodeListForSort : [],
        sortKey: sortKeyName   // firstName, lastName, email
      }
      // console.log('GetData_reqBody: ', reqBody);
      this.technicianService.getStaffListByPage(reqBody, page, limit, (response) => {
        this.technicianService.common.progressLoader = false;
        this.technicianService.common.infiniteLoader = false;
        if (response.responsecode === 200) {
          if (scroll) {              
              // const arrayData = this.dataSource.data;
              const arrayData = this.technicianList;
              response.data.forEach(element => {
                arrayData.push(element);
              });
              setTimeout(() => {
              this.technicianList = arrayData;
              this.dataSource = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.dataSourceForCardView = arrayData ;
              this.setSorting();
            }, 1000)

            } else {
              //this.dataSource = new MatTableDataSource();
              setTimeout(() => {
              this.technicianList = response.data;
              this.dataSource = new MatTableDataSource(response.data);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.dataSourceForCardView = response.data ;
               this.setSorting();
              }, 1000)
               setTimeout(()=>{
                this.scrollToTopStaffListScroller();
                },1000);
              //console.log('TechnicianList', this.technicianList);
            }
          } else {
            if (!scroll) {
              this.dataSourceForCardView = [];
              this.technicianList = [];
              this.dataSource = new MatTableDataSource();              
              setTimeout(()=>{
                this.scrollToTopStaffListScroller();
                },1000);
            } 
          }
        });
    } catch (error) {
        //console.log(error)
    }
  }

  getStaffCountByOrganizationCode(){
    this.technicianService.common.progressLoader = true; 
    this.technicianService.getStaffCountByOrganizationCode(this.technicianService.local.get('orgCode'), (response) => {
      this.technicianService.common.progressLoader = true; 
        if (response.responsecode === 200) {       
          this.staffCountData = response.data;
        } else {
          this.staffCountData = null;
        }
      });
  }

  getAllSkillsOnlyByOrgCode(){
    if(this.allSkillList && this.allSkillList.length > 0){
      return;
      // do nothing
    } else {
    this.isSkillLoading = true;
    this.technicianService.getAllSkillsOnlyByOrgCode(this.technicianService.local.get('orgCode'), (response) => {
      this.isSkillLoading = false;
        if (response.responsecode === 200) {          
          this.allSkillList = response.data;
        } else {
          this.allSkillList = [];
        }
      });
    }
  }

  selectSkillCodeList(skill: any, id: any){
    if(id){
      skill.isSkillChecked = !skill.isSkillChecked;
      this.selectedSkillCodeListForSort = this.allSkillList && this.allSkillList?.length > 0 && this.allSkillList.filter(skill => skill.id && skill.isSkillChecked && skill.skillCode).map(skill => skill.skillCode);
      this.selectedSkillList = this.allSkillList && this.allSkillList?.length > 0 && this.allSkillList.filter(skill => skill.id && skill.isSkillChecked && skill.skillCode);      
      return skill;
    }
  }

  filterBySkillCodeList(selectedSkillCodeListForSort: any){
    this.isSkillSelected = (this.selectedSkillCodeListForSort && this.selectedSkillCodeListForSort?.length > 0) ? true : false;
    if(selectedSkillCodeListForSort && selectedSkillCodeListForSort.length > 0){
      this.getData(0, this.limit = 30, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
    } else {
      this.getData(0, this.limit = 30, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
    }
  }

  clearSkillFilters(isClear: any){
    
    // if(isClear && this.isSkillSelected){
    //   // do nothing just close dropdown     
    // } else {      
      this.allSkillList = this.allSkillList && this.allSkillList?.length > 0 && this.allSkillList.map(skill => {
        if(skill.isSkillChecked){
          skill.isSkillChecked = false;
          return skill;
        }
        return skill;
      });
      this.isSkillSelected = false;
      this.selectedSkillCodeListForSort = [];
      this.selectedSkillList = [];
      this.getData(0, 30, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName)
  }

  getAllRoleByOrgCodeByPage(){

    this.roleServices.getAllRoleByOrgCodeByPage(
      this.technicianService.local.get('orgCode'), 0, 100, (response) => {
       
        this.technicianService.common.progressLoader = false;
        this.technicianService.common.infiniteLoader = false;

        if (response.responsecode === 200) {          
          this.allRolesList = response.data;
          // //console.log('allRolesList: ', this.allRolesList);
        } else {
          this.allRolesList = [];
        }
      })

  }

  ChangeRole(worker: any, role:any){
    // //console.log('ChangeRole Worker', worker);
    // //console.log('ChangeRole Role', role);
    if(role.roleCode && worker.workerCode){
      let roleChangeReq = {
        roleCode: role.roleCode,
        userCode: worker.workerCode,
      }
      // //console.log('roleChangeReq: ', roleChangeReq);
      // return;
      try {
        this.technicianService.changeWorkerRole(roleChangeReq, (res) => {
        if (res.responsecode == 200) {
          this.page = 0;
          this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
          this.technicianService.common.message = res.message;
          this.technicianService.common.successMessage = true;
          this.technicianService.common.progressLoader = false;
        } else {
          this.technicianService.common.message = res.message;
          this.technicianService.common.errorMessage = true;
          this.technicianService.common.progressLoader = false;
        }
      })
    } catch (error) {
      this.technicianService.common.progressLoader = false;
    }
  }
  }

  RoleChangeFromCardView(changeRoleObj: any){
    if(changeRoleObj && changeRoleObj?.worker && changeRoleObj?.role){
      this.ChangeRole(changeRoleObj.worker, changeRoleObj.role);
    }
  }


  setSorting(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      // ////console.log(item);
      switch (property) {
        case 'experience': return item['workerRes'].expYear + item['workerRes'].expMonth;
        case 'name': return item['workerRes'].name;
        default: return item[property];
      }
    };
    // setTimeout(() => {
    // Apply the sorting accessor for case-insensitive sorting
    this.dataSource.sortingDataAccessor = this.common.globalSortingDataAccessorForMatSort;
    this.dataSource.sort = this.sort;
  //   console.log('Sort_ss',  this.sort);
  // }, 1000)
  }

  /* Name:Shailesh
      use: for show and hide search click */
  filterToggle() {
    this.show = !this.show;

    if (this.show) {
      this.searchClick = 'Hide';
    } else {
      this.searchClick = 'Show';
    }
  }

  /* Name:Shailesh
      use: for infinite scroll */
  onScroll() {
    this.page = this.page + 1;
    // console.log('onScroll_: ', this.page);
    this.getData(this.page, this.limit, 'scroll', this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
  }

  /* Name:Vivek Chauhan
      use: for Staff searching */
  search() { 
    this.page = 0;   
    if (this.searchTerm !== null && this.searchTerm !== '' && this.searchTerm && (this.searchTerm.trim() || this.technicianName.trim())) {
      this.technicianName = this.searchTerm.toLowerCase().trim() ? this.searchTerm.toLowerCase().trim() : null;
      this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
    } 
    else {
      this.technicianName = null;
      this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
    }
  }

  sortBy(item: any){
    if(item && item?.order === 'A-Z'){
      this.isSortOrderAtoZ = true;
    } else {
      this.isSortOrderAtoZ = false;
    }
    this.sortKeyName = item?.key;
    item.isActive = true;
    if(item?.key){
      this.page = 0;
      this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
    }
    this.allSortNameList && this.allSortNameList?.length > 0 && this.allSortNameList.map(sortItem => {
      if(sortItem.id !== item.id && sortItem.isActive){
        sortItem.isActive = false
      }
      return sortItem;
    });
    return item;
  }

  resetAllSelectedFilters(){
    this.page = 0;
    this.searchTerm = null;
    this.technicianName = null;
    this.selectedSkillList = [];
    this.selectedSkillCodeListForSort = [];
    this.isSkillSelected = false;
    this.sortKeyName = null;
    this.isSortOrderAtoZ = null;
    this.allSortNameList = this.allSortNameList && this.allSortNameList?.length > 0 && this.allSortNameList.map(sortItem => {
      if(sortItem.isActive){
        sortItem.isActive = false;
        return sortItem;
      }
      return sortItem;
    });

    this.allSkillList = this.allSkillList && this.allSkillList?.length > 0 && this.allSkillList.map(skill => {
      if(skill.isSkillChecked){
        skill.isSkillChecked = false;
        return skill;
      }
      return skill;
    });
  }

  tabChangeEventActiveInactive = (tabChangeEvent: MatTabChangeEvent): void => {
    this.tabChangeActiveInactive = tabChangeEvent?.tab?.textLabel;
    this.page = 0 ;
    if(tabChangeEvent?.tab?.textLabel && this.tabChangeActiveInactive === 'Active'){
      this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, null);
    } else {
      this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, null);
    }
  }

  resetTabSelection() {
    this.tabGroup.selectedIndex = 0;
  }

  changeView(value:string){    
    this.isSortOrderAtoZ = true;
    this.sortKeyName = null;
    this.viewPoint = value ;
    this.searchTerm = null;
    this.technicianName = null;
    this.selectedSkillCodeListForSort = [];
    this.resetTabSelection();
    this.resetData();
    this.resetAllSelectedFilters();
    this.page = 0 ;
    this.getData(this.page, this.limit, null, true, true, null);
  }

  resetData(){
    this.searchTerm = null;
    this.technicianName = null;
    this.selectedSkillCodeListForSort = [];
    this.dataSourceForCardView = [];
    this.technicianList = [];
    this.dataSource = new MatTableDataSource();
    this.getAllSkillsOnlyByOrgCode();
  }

  fileUploadComplete(event)
  {
    this.resetData();
    this.page = 0 ;
    this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
  }

  isStaffListRefresh(event){
    this.page = 0;
    this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
  }

  setActiveInactiveStaff(workerRes: any, isActive:boolean){

    // console.log('setActiveInactiveStatus: ', workerRes, isActive);

    if(workerRes?.workerCode){

    let confirmMSG = "Are you sure want to "+ (isActive ? 'Active ' : 'Inactive ') +"staff "+ (workerRes?.name && this.technicianService.common.toTitleCase(workerRes.name)) +" ?" ;

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {     
      
         if(result && result.toLowerCase() === 'ok')
         {
              let data= {
                "isActiveStatus": isActive,
                "userCode": workerRes.workerCode
              }
              // console.log('setActiveInactiveStatus_1: ', data);              
              //  return;
              this.technicianService.common.progressLoader = true;
              this.technicianService.setActiveInactiveStaff(data, (response) => {
                if (response.responsecode == 200) {
                  this.page = 0;
                  this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
                  if(isActive){
                    workerRes.isActive = true;
                    } else {
                      workerRes.isActive = false;
                    } 
                    this.technicianService.common.message = response.message;
                    this.technicianService.common.progressLoader = false;
                    this.technicianService.common.successMessage = true;
                  } else {
                    this.technicianService.common.message = response.message;
                    this.technicianService.common.progressLoader = false;
                    this.technicianService.common.errorMessage = true;
                }
              })
         }
    });
    }
}


  downloadSample() {
    // const link = document.createElement("a");
    // link.setAttribute("target", "_blank");
    // link.setAttribute("href", "assets/user-sample-file/staff_samples.xls");
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
 
    const fileName = 'sample-file';
    const sheetName = 'Sheet 1';
 
    this.exportToExcel([], fileName, sheetName, this.FileHeader);

  }

 
  handleFileInputChange(file:any)
  {
   this.currentUpldStaff_File = file[0]
   }

   
   uploadStaff():void
   {
    if(this.currentUpldStaff_File && this.currentUpldStaff_File.name)
    {
      const formData: FormData = new FormData();
      formData.append("file", this.currentUpldStaff_File, this.currentUpldStaff_File.name);
      formData.append("organizationCode", this.technicianService.local.get('orgCode'));
      
      this.technicianService.common.progressLoader = true;
      this.technicianService.uploadStaffMembers(formData).subscribe((res:any) => {
        this.technicianService.common.progressLoader = false;
        if(res.responsecode == 200)
        {

           this.technicianService.common.message = res.message;
           this.technicianService.common.successMessage = true;
           this.currentUpldStaff_File = [];
           this.page = 0;
           this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);

        }
        else{
          this.technicianService.common.message = res.message;
          this.technicianService.common.errorMessage = true;
        }

      })

    }
   }

   PopUp_AddMember()
   {
    const dialogRef = this.dialog.open(AddMemberComponent, { width: '60%', disableClose: true });

      dialogRef.afterClosed().subscribe(result => {
       if (result) 
       {
          this.page = 0;
        // this.getAllStaffMembers();

        this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);

       }
      
    });
   }

   scrollToTopStaffListScroller(): void {
    var staffListScroller = document.getElementById('staffListScroll');
    if(staffListScroller)
    staffListScroller.scrollTop = 0;
  }
 
  changePassword(workerCode:string){ 
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '320px',
      data: { msg: 'Confirm you want to reset your password via email?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.technicianService.common.progressLoader = true;
    this.technicianService.sendForgetPasswordLinkByCode(workerCode)
    .subscribe((res:any) =>{
           
     this.technicianService.common.progressLoader = false;
      
 
      if(res.responsecode == 200)
      {
       // this.dialogRef.close('data saved');
        this.technicianService.common.message = res.message;
        this.technicianService.common.successMessage = true;
         
      }
      else{
        this.technicianService.common.message = res.message;
        this.technicianService.common.errorMessage = true;
      }
 
    });
      } 
    });

  }


   closePopUp()
    {
      this.dialogRef.close();
    }

 
sendWelcomeEmail(workerCode:string){

 const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '320px',
      data: { msg: 'Are you sure you want send welcome email?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        
  this.technicianService.common.progressLoader = true;
  this.technicianService.sendWelcomeEmail(workerCode).subscribe((res:any) =>{
    this.technicianService.common.progressLoader = false;

    if(res.responsecode == 200)
    {
       this.technicianService.common.message = res.message;
      this.technicianService.common.successMessage = true;
       
    }
    else{
      this.technicianService.common.message = res.message;
      this.technicianService.common.errorMessage = true;
    }
  })
      } 
    });

}

sendWelcomeEmailToAllStaff(){

  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
       width: '320px',
       data: { msg: 'Are you sure want to send the welcome email to all Staff?' }
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if (result != undefined) {
         this.isSendMailLoading = true;
          this.technicianService.common.progressLoader = true;
          this.technicianService.sendWelcomeEmailToAllStaff(this.technicianService.local.get('orgCode')).subscribe((res:any) =>{
            this.isSendMailLoading = false;
            this.technicianService.common.progressLoader = false;
        
            if(res.responsecode == 200)
            {
                this.technicianService.common.message = res.message;
              this.technicianService.common.successMessage = true;
                
            }
            else{
              this.technicianService.common.message = res.message;
              this.technicianService.common.errorMessage = true;
            }
          })
       } 
     });
 
 }


 
 exportToExcel(data: any[], fileName: string, sheetName: string, columnHeaders: string[]) {
  const worksheet: XLSX.WorkSheet = this.generateWorksheet(data, columnHeaders);
  const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

private generateWorksheet(data: any[], columnHeaders: string[]): XLSX.WorkSheet {
  const headerRow = this.createHeaderRow(columnHeaders);
  const dataRows = this.createDataRows(data, columnHeaders);

  return XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
}

private createHeaderRow(columnHeaders: string[]): any[] {
  return columnHeaders.map(header => ({ v: header }));
}

private createDataRows(data: any[], columnHeaders: string[]): any[][] {
  return data.map(item => columnHeaders.map(header => ({ v: item[header] })));
}

 
 getSkillList() {
  try {
   
    this._skillSVC.getSkillsByOrganizationCodeByPage(this._skillSVC.storage.get('orgCode'), 0, 1000, (data) => {
       if (data.responsecode == 200) {

          data.data.forEach(ele=>{
             this.FileHeader.push(ele.skillName);
          })
           
          this.FileHeader.push("","","For skill selection, please write 'yes' or '1' in the respective columns; otherwise, leave it blank")
       } 
    });
  } catch (error) {
    //console.log(error)
  }

}

 
// addNote(staffdata:any)
// {
//   this.notes = staffdata.notes;
//   this.workerCode = staffdata.workerCode;

//   this.dialogRef = this.dialog.open(this.addNote_popUp, { width: '50%' });

//   this.dialogRef.afterClosed().subscribe((result) => {
//     this.notes = '' ;
//     this.workerCode = '';
//     });

// }

saveNote()
{

  if(!this.notes?.trim())
    return false;


  let reqBody = {
    "notes": this.notes,
    "workerCode": this.workerCode
  }

  this.technicianService.common.progressLoader = true;
  this.technicianService.saveNote(reqBody).subscribe((res:any) =>{
    
    this.technicianService.common.progressLoader = false;
        
    if(res.responsecode == 200)
    {
      this.technicianService.common.message = res.message;
      this.technicianService.common.successMessage = true;
      this.dialogRef.close();
      this.refreshList();
    }
    else{
      this.technicianService.common.message = res.message;
      this.technicianService.common.errorMessage = true;
    }
    
  })
}


refreshList()
{
  this.page = 0;
  this.getData(this.page, this.limit, null, this.tabChangeActiveInactive === 'Active' ? true : false, this.isSortOrderAtoZ, this.sortKeyName);
}



private selectedData_forNotes : any ;

addNote(data:any){

  this.selectedData_forNotes = data.selectedCardData ;
  let workerCode = data?.selectedCardData?.workerCode || data?.workerCode;
  let selecteName = data?.selectedCardData?.name || data?.name;
  let isNoteAdded = data?.selectedCardData?.isNoteAdded || data?.isNoteAdded;

  if(isNoteAdded) {      
      this.common.progressLoader = true;
      this._commonAPI_SVC.getNotesByEntityCode(this.technicianService.local.get('orgCode'), 'Staff', workerCode).subscribe((res:any)=>{
        this.common.progressLoader = false;

        if(res.responsecode === 200)
          {
               this.addUpdateNotes(res.data, selecteName);
          }
     });      
    } else {
      this.addUpdateNotes([], selecteName);
    }   
}

addUpdateNotes(noteArray:[], noteTitleOpenFor = null){

  const dialogRef = this.dialog.open(AddUpdateNoteComponent, { 
    width: '50%',
    disableClose: true,
    panelClass: 'popup-pos',
    data: {
      noteArray: noteArray,
      noteTitleOpenFor: noteTitleOpenFor
     }
  });

  dialogRef.afterClosed().subscribe((result) => {
     if(result)
    this.saveAndMapNotesEntity(result);
  });

}


saveAndMapNotesEntity(data)
{
  const payload ={
    "entityName": "Staff",
    "entityUuid": this.selectedData_forNotes.workerCode ,
    "isPublic": data.isPublic,
    "note": data.note,
    "organizationCode": this.technicianService.local.get('orgCode')
  }
  // console.log('S_Data: ', this.selectedData_forNotes, payload);
  // return;
  this.common.progressLoader = true;
  this._commonAPI_SVC.saveAndMapNotesEntity(payload).subscribe((res:any) =>{
    this.common.progressLoader = false;
   if(res.responsecode == 200)
    {
       this.common.message = res.message;
         this.common.successMessage = true;
         this.refreshList();
         this.common.resetNotesRequestForm();
    }
    else{
        this.common.message = res.message;
         this.common.errorMessage = true;
    }

  })
}

subToPermissionChanged()
{
 this.common.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this.common.getBtnEventPermissions("Staff Directory");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }



  calendar_shcedule_view(element:any)
  {
    this.workerCode =  element.workerCode;

    const dialogRef = this.dialog.open( this.schedule_view_popup, {
      width: '90%', maxWidth:'90vw', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true ,
    });

   dialogRef.afterClosed().subscribe(result => {
    this.workerCode = null ;
   });
  
  }

  /* Name:Vivek Chauhan
  use: for open Sent template on emails popup */
  sendTemplate(staff = null): void {
    // console.log('Staff: ', staff);
    // return;
    if(staff?.email){
      const dialogRef = this.dialog.open(DirectEmailSendComponent, {
        width: '850px',
        height: 'auto',
        maxHeight: '94vh',
        maxWidth:'850px',
        disableClose: true,
        data: { selectedEmail : staff.email, isSend: true}
      });
  
      dialogRef.updatePosition({
        top: '1%'    // Set top position
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result === 'success') {
          // Do Nothing
        }
      });
    }
  }

}
