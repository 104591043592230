import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    public session: SessionStorageService,
    public http: HttpClient,
    public service: ApiRootService,
    public common: CommonService
  ) { }

  /*
      use: getting all appointments by organization code */
  getAllJobsByOrganizationCodeWithFilterByPage(organizationCode, status, startDt, endDt, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAllAppointmentsByOrganizationCodeWithFilter/' + organizationCode + '/' + status + '/' + startDt + '/' + endDt).subscribe((data) => {
      callback(data);
    })
  }

   /*
      use: getting all appointments by organization code and patient code */
  adminGetAllAppointmentsByOrganizationCodeAndPatientWithFilter(organizationCode, status, startDt, endDt, patientCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetAllAppointmentsByOrganizationCodeAndPatientWithFilter/' + organizationCode + '/' + status + '/' + startDt + '/' + endDt + '/' + patientCode).subscribe((data) => {
      callback(data);
    })
  }



  // getJobForDashboardByOrganizationCode(code, startDt, endDt, callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/getJobForDashboardByOrganizationCode/' + code + '/' + startDt + '/' + endDt).subscribe((data) => {
  //     callback(data);
  //   })
  // }

   /*
      use: getting all appointments for dashboard by organization code */
  getJobForDashboardByOrganizationCode(organizationCode, startDt, endDt, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetAppointmentForDashboardByOrganizationCode/' + organizationCode + '/' + startDt + '/' + endDt).subscribe((data) => {
      callback(data);
    })
  }

   /*
      use: getting all appointments by organization code with date time for current date */
  adminGetAllAppointmentsByOrganizationCodeWithFilterDateTimeForCurrentDate(organizationCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTimeForCurrentDate/' + organizationCode + '/' + 'all/all').subscribe((data) => {
      callback(data);
    })
  }
   /*
      use: getting all appointments by organization code with date time*/
  getAllJobsByOrganizationCodeWithFilterDateTime(organizationCode, status, startDate, endDate, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v3/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTime/' + organizationCode + '/' + status + '/' + startDate + '/' + endDate + '/all').subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }

   /*
  use: getting all appointments by organization code with date time*/
  getAllJobsByOrganizationCodeWithFilterDateTimeByCustomeCode(organizationCode, status, startDate, endDate, customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v3/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTime/' + organizationCode + '/' + status + '/' + startDate + '/' + endDate + '/all/' + customerCode).subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }  

  /* Name:Vivek Chauhan
 use: getting sift counts*/
  getShiftStatusCount(organizationCode, currentDate, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/getCheckInCheckOutDetailsCount/' + organizationCode + '/' + currentDate).subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }

  /* Name:Vivek Chauhan
  use: getting sift counts*/
  getShiftListDetails(organizationCode, currentDate, shiftName, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getCheckInCheckOutDetailsCountListByStatusType/' + organizationCode + '/' + currentDate + '/' + shiftName).subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }

      /* Name:Vivek Chauhan
  use: getting sift counts*/
  getClockInClockOutShiftDetailsForCustomer(organizationCode, currentDate, shiftName, customerCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v3/getCheckInCheckOutDetailsCountListByStatusType/' + organizationCode + '/' + currentDate + '/' + shiftName + '/' + customerCode).subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }

    /* Name:Vivek Chauhan
  use: getting sift counts*/
  getClockInClockOutShiftDetails(organizationCode, currentDate, shiftName, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v3/getCheckInCheckOutDetailsCountListByStatusType/' + organizationCode + '/' + currentDate + '/' + shiftName).subscribe((data) => {
      callback(data);
      // ////console.log(data);
    })
  }

  // getAllJobsByOrganizationCodeWithFilterDateTime(organizationCode, status, startDate, endDate, callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTime/' + organizationCode + '/' + status + '/' + startDate + '/' + endDate).subscribe((data) => {
  //     callback(data);
  //   })
  // }



  // adminGetAllJobsByWorkerCodeAndDate(organizationCode, date, callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/adminGetAllJobsByWorkerCodeAndDate/' + organizationCode + '/' + date).subscribe((data) => {
  //     callback(data);
  //   })
  // }

   /*
      use: getting all appointments by organization code */
  adminGetAllJobsByWorkerCodeAndDate(organizationCode, date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetAllAppointmentsByWorkerCodeAndDate/' + organizationCode + '/' + date).subscribe((data) => {
      callback(data);
      ////console.log(data);
    })
  }


  /*
      use: getting route by clinician code and job duration code  */
  mobileGetRouteByTechnicianCodeDateAndJobdurationcode(userCode, date, jobcode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileGetRouteByTechnicianCodeDateAndappointmentcode/' + userCode + '/' + date + '/' + jobcode).subscribe((data) => {
      callback(data);
    })
  }

  /*
      use: getting Schedule count detail by worker code schedule date date and date range */
  mobileAdminGetScheduleCountDetailsByWorkerCodeScheduleDateAndDateRange(workerCode, scheduledDT, fromDT, toDT, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileAdminGetScheduleCountDetailsByWorkerCodeScheduleDateAndDateRange/' + workerCode + '/' + scheduledDT + '/' + fromDT + '/' + toDT + '/' + this.session.get('orgCode')).subscribe((data) => {
      callback(data);
    })
  }

  /*
      use: for unasign appointments by org code */
  adminUnAssignAppointment(organizationCode, data, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminUnAssignAppointment/' + organizationCode, data).subscribe((data) => {
      callback(data);
    })
  }

  getOperationalStatusData(organizationCode:string) {
    return this.http.get(this.service.getHealthCareUrl() + '/getOperationalStatusMTD/' + organizationCode )
  }

  getAllDistributionList(organizationCode:string) {
    return this.http.get(this.service.getHealthCareUrl() + '/getDistributionListByOrganizationCodeByPage/' + organizationCode+ '?page=0&limit=1000')
  }

  PublishJob(request_body) {
    return this.http.post(this.service.getHealthCareUrl() + '/publishJobs' , request_body)
  }


  getEligibleStaffs(appoinmentDurationCode:string) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetEligibleTechnicianWithoutPreferredHoursForAppointmentByAppointmentsDurationCode/'+ appoinmentDurationCode)
  }

  assignJob(appoinmentDurationCode:string , workerCode : string) {
    return this.http.get(this.service.getHealthCareUrl() + '/jobAssignedByAdmin/'+ appoinmentDurationCode +'/' + workerCode)
  }

  getDecliedAndLateListByOrganizationCode(organizationCode:string, status:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + '/getDecliedAndLateListByOrganizationCode/'+ organizationCode +'/' + status)
  }

}
