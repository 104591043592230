<div class="row">
  <div class="col-md-7">
      <div><h4 class="primary-color">
          {{ 'Customers.labels.Customer' | translate }}
        </h4></div>
        <div class="float-start">
          <hr class="hr-line">
        </div>
  </div>
 <div class="col-md-5 text-end">
    <div class="ms-auto p-2 text-muted cursor-pointer" (click)="closeOnClick()">
        <i class="material-icons  clear-icon " > clear  </i>
      </div>
 </div>
  
</div>
<form [formGroup]="patientForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{ 'dashboard.labels.Patient Name' | translate }}</mat-label>
          <input matInput class="form-control" formControlName="patientName"
            [(ngModel)]="service.patientData.patientName">
        </mat-form-field>

        <span class="help-block err"
          *ngIf="!patientForm.get('patientName').valid && (!submitted || patientForm.get('patientName').touched)">
          {{ 'Customers.validators.Patient name is required' | translate }}</span>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="w-100">
         
        <app-country-code (setCountryCode)="getCountryCode($event,'phoneCountryCode')"
        [setCode]="countrycodep" [placeholder]="'Home No'"></app-country-code>

        <span class="help-block err"
          *ngIf="!patientForm.get('phoneCountryCode').valid && (!submitted || patientForm.get('phoneCountryCode').touched)">
          {{ 'Customers.validators.Country code is required' | translate }}</span>

      </div>
    </div>

    <div class="col-md-8">
      <div class="w-100">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width p-0">
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="" formControlName="phoneNumber"
            [(ngModel)]="service.patientData.phoneNumber">
        </mat-form-field>


        <span class="help-block err"
          *ngIf="patientForm.get('phoneNumber').errors?.required && (!submitted || patientForm.get('phoneNumber').touched)">
          {{ 'Customers.validators.Phone Number is required' | translate }}</span>
          <span class="help-block err"
          *ngIf="patientForm.get('phoneNumber').errors?.pattern && (!submitted || patientForm.get('phoneNumber').touched)">\
          {{ 'Customers.validators.Please enter valid phone number' | translate }}.</span>
      </div>
    </div>


  </div>
  <div class="row">


    <div class="col-md-4">
      <div class="w-100">

        <app-country-code (setCountryCode)="getCountryCode($event,'mobileCountryCode')"
                  [setCode]="countrycodem" [placeholder]="'Mobile No'"></app-country-code>

        <span class="help-block err"
          *ngIf="!patientForm.get('mobileCountryCode').valid && (!submitted || patientForm.get('mobileCountryCode').touched)">
          {{ 'Customers.validators.Country code is required' | translate }}</span>

      </div>
    </div>

    <div class="col-md-8">
      <div class="w-100">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width p-0">
          <mat-label>Mobile Number</mat-label>
          <input matInput placeholder="" formControlName="mobileNumber"
            [(ngModel)]="service.patientData.mobileNumber">
        </mat-form-field>

        <span class="help-block err"
          *ngIf="patientForm.get('mobileNumber').errors?.required && (!submitted || patientForm.get('mobileNumber').touched)">
          {{ 'Customers.validators.Mobile Number is required' | translate }}</span>

          <span class="help-block err"
          *ngIf="patientForm.get('mobileNumber').errors?.pattern && (!submitted || patientForm.get('mobileNumber').touched)">
          {{ 'Customers.validators.Please enter valid Mobile number' | translate }}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{ 'common.labels.Address' | translate }}</mat-label>
          <textarea class="form-control" matInput placeholder="" ngx-google-places-autocomplete [options]='options'
            #placesRef="ngx-places" formControlName="address"
            (onAddressChange)="handleAddressChange($event)"></textarea>
        </mat-form-field>
        <span class="help-block err"
          *ngIf="!patientForm.get('address').valid && (!submitted || patientForm.get('address').touched)">
          {{ 'registration.provider.validators.Address is required' | translate }}</span>


      </div>
    </div>
  </div>

</form>

<div class="ms-auto p-2">
  <div class="text-center">
    <button class="me-3" mat-button (click)="cancelPopup()">{{ 'common.labels.Cancel' | translate }}</button>
    <button mat-raised-button color="primary" (click)="onSubmit()">{{ 'common.labels.Create' | translate }}</button>
  </div>
</div>