import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notification = {
    appointmentCode: "",
    createdAt: '',
    healthcareNotificationCode: "",
    id: 876,
    ipAddress: "",
    isDeleted: false,
    isRead: false,
    message: "",
    shortMessage: "",
    updatedAt: '',
    userCode: "",
    userType: "",
  }

  constructor(public http: HttpClient,
    public service: ApiRootService,
    public session: SessionStorageService,
    public common: CommonService) { }

  allNotificationCode = {
    "notificationCodeList": [
      
    ]
  }

  notificationCodeList = {
    "notificationCodeList": [
      
    ]
  }

  /* Name:Shailesh
    use: for getting all healthcare notification by user code with read by page*/
  getAllHealthcareNotificationByUserCodeWithReadByPage(code, page, limit, callback) {

    // console.log(code,page,limit,'!!!!!');
    return this.http.get(this.service.getNotificationService() + '/getAllCareHuddleNotificationByUserCodeWithUnReadByPage/' + code + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
      //////console.log(data);
    })

    // return this.http.get(this.service.getHealthCareUrl() + '/getAllHealthcareNotificationByUserCodeWithUnReadByPage/' + code + '?page=' + page + '&limit=' + limit).subscribe((data) => {
    //   callback(data);
    //   //////console.log(data);
    // })

  }

  /* Name:Shailesh
    use: updating(read status) notification status with user code*/
  updateHealthcareNotification(code, isRead, callback) {
    return this.http.put(this.service.getNotificationService() + '/updateCareHuddleNotification/' + code, isRead).subscribe((data) => {
      callback(data);
    })

    // return this.http.put(this.service.getHealthCareUrl() + '/updateHealthcareNotification/' + code, isRead).subscribe((data) => {
    //   callback(data);
    // })
  }

  /* Name:Shailesh
     use: read (read status) all notification */
  updateMultipleHealthcareNotification(isRead, data, callback) {
    return this.http.put(this.service.getNotificationService() + '/updateMultipleCareHuddleNotification/' + isRead, data).subscribe((data) => {
      callback(data);
    })

    // return this.http.put(this.service.getHealthCareUrl() + '/updateMultipleHealthcareNotification/' + isRead, data).subscribe((data) => {
    //   callback(data);
    // })

  }
  
  getPatientDetailsByAppointmentDurationCodeList(appointmentCode, callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/getPatientDetailsByAppointmentDurationCodeList/'+appointmentCode).subscribe((data)=>{
      callback(data);
    })
  }


  getAllStaffNotification_ForOrganization(organizationCode:string, isExpired:boolean , page:number, limit:number)
  {
    return this.http.get(this.service.getNotificationService() + '/getAllStaffNotificationForOrganizationByUserCodeWithUnReadByPage/' + organizationCode +'/'+ isExpired +'?page=' + page + '&limit=' + limit);
     
  }

  getAllStaffNotification_ForStaff(userCode:string, organizationCode:string, isExpired:boolean,  page:number, limit:number)
  {
    return this.http.get(this.service.getNotificationService() + '/getAllStaffNotificationForUserByUserCodeWithUnReadByPage/'+userCode +'/'+ organizationCode +'/'+ isExpired + '?page=' + page + '&limit=' + limit);
     
  }

  getAllStaffNotification_ForCustomer(customerCode:string, organizationCode:string, isExpired:boolean , page:number, limit:number)
  {
    return this.http.get(this.service.getNotificationService() + '/getAllStaffNotificationForCustomerByUserCodeWithUnReadByPage/'+ customerCode +'/'+ organizationCode +'/'+ isExpired + '?page=' + page + '&limit=' + limit);
     
  }

  /* Name:Vivek Chauhan
  use: for Notification read multiselection in list */
  setIsReadStatusForStaffMultiNotification(isRead, notificationCodeList) {
      return this.http.put(this.service.getNotificationService()+ '/setIsReadStatusForStaffMultiNotification/' + isRead , notificationCodeList);
  }

  /* Name:Vivek Chauhan
  use: for Notification read all notification in list */
  setIsReadAllNotificationForOrganization(organizationCode, requestBody) {
    return this.http.put(this.service.getNotificationService()+ '/setIsReadStatusForAllStaffNotificationForOrganization/' + organizationCode , requestBody);
  }

    /* Name:Vivek Chauhan
  use: for Staff Notification read all notification in list */
  setIsReadStatusForAllStaffNotificationForUser(organizationCode, userCode, requestBody) {
    return this.http.put(this.service.getNotificationService()+ '/setIsReadStatusForAllStaffNotificationForUser/' + userCode + '/' +  organizationCode, requestBody);
  }

    /* Name:Vivek Chauhan
  use: for Customer Notification read all notification in list */
  setIsReadStatusForAllStaffNotificationForCustomer(organizationCode, customerCode, requestBody) {
    return this.http.put(this.service.getNotificationService()+ '/setIsReadStatusForAllStaffNotificationForCustomer/' + customerCode + '/' + organizationCode , requestBody);
  }

}
