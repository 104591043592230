import { ChatService } from './../chat/chat.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import { LocationService } from './location.service';
declare var google;
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import { Options } from 'ng5-slider';
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
// import { RxStompService } from "@stomp/ng2-stompjs";
import { RxStompService } from 'src/app/rx-stomp.service';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AssignAppointmentComponent } from '../appointment/assign-appointment/assign-appointment.component';
import { SplitAppointmentComponent } from '../appointment/split-appointment/split-appointment.component';
import { CreateAppointmentService } from '../appointment/create-appointment/create-appointment.service';
import { ChatComponent } from '../chat/chat.component';
import { Subject, iif, takeUntil } from 'rxjs';
import { ArrivalDepartureComponent } from '../arrival-departure/arrival-departure.component';
import { AssignedWorkerComponent } from '../appointment/create-appointment/assigned-worker/assigned-worker.component';
import { CreateAppointmentComponent } from '../appointment/create-appointment/create-appointment.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { SessionStorageService } from 'angular-web-storage';
import { IBtnControlsPermission, jobCardActions } from 'src/app/shared/interface/core.interface';
import { NotifyJobComponent } from 'src/app/components/new-job-card/notify-job/notify-job.component';
import { AssignJobComponent } from 'src/app/components/new-job-card/assign-job/assign-job.component';
import { CommonService } from 'src/app/shared/common.service';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd DD MMMM YYYY',
    monthYearLabel: 'ddd DD MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'ddd DD MMMM YYYY',
  },
};

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class LocationComponent implements OnInit , OnDestroy{

  date;
  selectedDate;
  value = 0;
  userType: any;
  dateRange = this.createDateRange("9:15", "10:00");
  options: Options = {
    stepsArray: this.dateRange.map((date) => {
      return { value: date, legend: date };
    }),
    showTicks: true,
    showTicksValues: false
  };
  isShowRefresh: boolean = true;
  map;
  bounds;
  gmarker = [];
  isZoom: boolean = false;
  markers = [];
  locationData = [];
  locationKeys = [];
  formatedDate;
  currentValue;
  nonWorkingTech = [];
  showImg;
  allLocationData = [];
  searchKey;
  legend;
  currentSelectedDate;
  yearMonthDateFormat = 'YYYY-MM-DD';
  lat;
  lang;
  disabled = true;
  pending = [];


  centerMarker = [];
  hasPermission: IBtnControlsPermission;
  protected dialogRef: any;

  @ViewChild("schdeuledStaff_popUp") schdeuledStaff_popUp: TemplateRef<any>;
  protected staffData: any = null;
  allowActions : jobCardActions;
  private clearObs = new Subject<any>();

  /* Name:Shailesh
      use: for calculating the time*/
  timeString(time) {

    ////console.log(time);

    let hours = Math.floor(time / 60).toString();
    let minutes = (time % 60).toString();

    let hours1 = Math.floor(time / 60);
    let minutes1 = (time % 60);

    if (hours1 < 10) hours = "0" + hours; //optional
    if (minutes1 < 10) minutes = "0" + minutes;

    return hours + ":" + minutes;
  }

  /* Name:Shailesh
      use: for creating date range*/
  createDateRange(start, end) {
    var start = start.split(":");
    var end = end.split(":");

    start = parseInt(start[0]) * 60 + parseInt(start[1]);
    end = parseInt(end[0]) * 60 + parseInt(end[1]);
    ////console.log(start);
    ////console.log(end);

    var result = [];

    for (let time = start; time <= end; time += 15) {
      result.push(this.timeString(time));
    }

    ////console.log(result);
    return result;
  }


  constructor(
    public service: LocationService,
    public datepipe: DatePipe,
    public rxStompService: RxStompService,
    public route: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    public createPatientService: CreateAppointmentService,
    public ChatService: ChatService,
    public session: SessionStorageService,
    private _commonSVC : CommonService
  ) {
    this.translate.setDefaultLang(this.service.common.local.get('language'));
    this.date = new FormControl(moment());
    this.selectedDate = this.date.value;
    this.formatedDate = this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
    this.service.common.progressLoader = true;
    this.getLatLangByOrgCode();
    this.adminGetAppointmentDetailsForMapView(this.formatedDate);
  }

  // orgCode
  // adminCode
  // userName
  // userCode
  // bg_path :string = 'assets/error_bg.png';
  // apiUri;


  ngOnInit() 
  {
    this.userType = this.session.get('user_type');
    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
 
    /* Name:Shailesh
      use: for showing Staff Member detail on map legend*/
    this.legend = document.getElementById('mapLegend');
    ////console.log(this.legend);
    if (this.legend) {
      ////console.log(this.legend);
      let div = document.createElement('div');
      div.innerHTML = `<div class="text-truncate" title="Customer"><span><img src="assets/common_images/pointer_blue.png"> Customer</span></div>
                      <div class="text-truncate" title="Staff Member current location"><span><img src="assets/common_images/tec_black_pointer.png"> Staff Member current location</span></div>
                      <div class="text-truncate" title="Staff Member available"><span><img src="assets/common_images/tec_gray_pointer.png"> Staff Member available</span> </div>
                      <div class="text-truncate" title="Jobs that need action"><span><img src="assets/common_images/location_red_pointer.png"> Jobs that need action</span> </div>
                      <div class="text-truncate" title="Staff Member late"><span><img src="assets/common_images/pointer_orange_active.png"> Staff Member late </span> </div>
                      <div class="text-truncate" title="Completed"><span><img src="assets/common_images/location_green_pointer.png"> Completed </span> </div>`;

      this.legend.appendChild(div);

    }

    this.hasPermission = this.service.common.getBtnEventPermissions("Job Scheduling");
    this.subToPermissionChanged();


    this.allowActions ={
      allowEdit: false,
      allowDelete: false,
      allowAssignStaff: false,
      allowNotifyStaff: this._commonSVC.isAllowAutoApproval ? true : false,
      allowToViewNotifiedStaff: this._commonSVC.isAllowAutoApproval ? true : false,
      allowToCopyJob: false,
      allowView: false,
      allowRosterPrint: false,
      allowViewInfo: true,
      allowToAddScheduledStaff : false
     }

  }

  /* Name:Shailesh
      use: for getting latlong by org code*/
  getLatLangByOrgCode() {
    try {
      this.service.getLatLangByOrgCode(this.service.common.local.get('orgCode'), (res) => {
        if (res.responsecode == 200) {
          ////console.log(res);
          this.lat = res.data.lat;
          this.lang = res.data.lang;
        } else {
          this.lat = 32.78306;
          this.lang = -96.80667;
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  choosedDate(event) {
    ////console.log(event);
    this.service.common.progressLoader = true;
    this.selectedDate = event.value;
    this.date.setValue(event.value);
    this.formatedDate = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.adminGetAppointmentDetailsForMapView(this.formatedDate);
  }

  /* Name:Shailesh
      use: for date change on click of arrow*/
  arrowDateChange(arrow) {
    this.service.common.progressLoader = true;
    if (arrow == 'left') {
      // this.date.value.add(-1, 'days');
      this.date = new FormControl(moment(this.selectedDate).add(-1, 'days'));
      this.choosedDate(this.date);
    } else if (arrow == 'right') {
      // this.date.value.add(+1, 'days');
      this.date = new FormControl(moment(this.selectedDate).add(+1, 'days'));
      this.choosedDate(this.date);
    }
  }

  /* Name:Shailesh
      use: for getting appointment detail for map view*/
  adminGetAppointmentDetailsForMapView(date) {
    let logedIn_CustomerCode = this.service.common.local.get('logedIn_CustomerCode');
    let orgCode = this.service.common.local.get('orgCode');
    try {
      if (orgCode && date) {
        if (logedIn_CustomerCode) {
          this.service.common.progressLoader = true;
          this.service.adminGetAppointmentDetailsForMapViewStartForCustomer(orgCode, date, logedIn_CustomerCode, (data) => {
            this.service.common.progressLoader = false;
            this.getMapViewValueData(data, false);
          });
          this.service.adminGetAppointmentDetailsForMapViewForCustomer(orgCode, date, logedIn_CustomerCode, (data) => {
            // this.service.common.progressLoader = false;
            this.getMapViewValueData(data, true);
          });
        } else {
          this.service.common.progressLoader = true;
          this.service.adminGetAppointmentDetailsForMapViewStart(orgCode, date, (data) => {
            this.service.common.progressLoader = false;
            this.getMapViewValueData(data, false);
          });
          this.service.adminGetAppointmentDetailsForMapView(orgCode, date, (data) => {
            // this.service.common.progressLoader = false;
            this.getMapViewValueData(data, true);
          });
        }
      }
    } catch (error) {
    }
  }

  /* Name:Shailesh
        use: for getting map view value data*/
  getMapViewValueData(data, flash = false) {
    if (data.responsecode == 200) {
      ////console.log(data.data);
      // if(flash == true){
      //   const newData = Object.assign({}, data.data);
      //   delete newData['10:30 AM'];
      //   this.locationData = newData;
      //   this.locationKeys = Object.keys(data.data);
      //   this.allLocationData = Object.assign({}, data.data);
      //   ////console.log(this.locationData);
      // } else {
      this.locationData = data.data;
      this.locationKeys = Object.keys(data.data);
      this.allLocationData = Object.assign({}, data.data);
      ////console.log(this.locationData); 
      // }

      // this.allLocationData = data.data;
      // this.allLocationData = this.locationData.slice();
      // ////console.log(this.locationKeys[0] + ' ---- ' +this.locationKeys[this.locationKeys.length-1])
      setTimeout(() => {
        // ////console.log(this.selectedDate);
        // ////console.log(moment());
        // this.dateRange = this.createDateRange(this.locationKeys[0].split(' ')[0], this.locationKeys[this.locationKeys.length-1].split(' ')[0]);
        const newOptions: Options = Object.assign({}, this.options);
        // ////console.log( moment(this.selectedDate).isSame(moment(), 'day'),this.selectedDate.isAfter());
        newOptions.stepsArray = this.locationKeys.map((date, index) => {
          if (this.locationData[date].isLatePending && moment(this.selectedDate).isSame(moment(), 'day') || this.locationData[date].isLatePending && this.selectedDate.isAfter()) {
            return { value: index, legend: '<p class="actionRequired">' + date + '</p>' };
          }
          else if (!this.locationData[date].isLatePending && this.locationData[date].jobParentCustomerResV1List.length > 0 && moment(this.selectedDate).isSame(moment(), 'day') || !this.locationData[date].isLatePending && this.locationData[date].jobParentCustomerResV1List.length > 0 && this.selectedDate.isAfter()) {
            ////console.log('actionRequiredNew');
            return { value: index, legend: '<p class="actionRequiredNew">' + date + '</p>' };
          }
          else {
            return { value: index, legend: date };
          }
        }),
          this.options = newOptions;
        setTimeout(() => {
          let parentClass = document.getElementsByClassName('actionRequired') as HTMLCollection;
          let parentClassNew = document.getElementsByClassName('actionRequiredNew') as HTMLCollection;

          if (moment(this.selectedDate).isSame(moment(), 'day') || this.selectedDate.isAfter()) {
            ////console.log(parentClass);
            ////console.log(parentClassNew);
            // ////console.log(parentClass.length);

            var elems = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredRed");
            [].forEach.call(elems, function (el) {
              el.classList.remove("actionRequiredRed");
            });
            var elems1 = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredGreen");
            [].forEach.call(elems1, function (el) {
              el.classList.remove("actionRequiredGreen");
            });

            for (var i = 0; i < parentClass.length; i++) {
              parentClass[i].parentElement.classList.add("actionRequiredRed");
            }
            for (var i = 0; i < parentClassNew.length; i++) {
              // ////console.log(parentClass[i]); //second console output
              parentClassNew[i].parentElement.classList.add("actionRequiredGreen");
            }
          } else {
            var elems = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredRed");
            [].forEach.call(elems, function (el) {
              el.classList.remove("actionRequiredRed");
            });
            var elems1 = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredGreen");
            [].forEach.call(elems1, function (el) {
              el.classList.remove("actionRequiredGreen");
            });
          }
        }, 1000)
        if (this.currentValue) {
          this.value = +this.currentValue;
          // ////console.log('current');
          // ////console.log(this.value);
        } else {
          this.value = 0;
          // ////console.log('notcurrent');
          // ////console.log(this.value);
        }
        // this.service.common.progressLoader = false;
        this.googleMap();
      }, 1000);
    } else {
      this.locationData = [];
      // this.service.common.progressLoader = false;
    }
  }

  valueChange(value) {
    // //console.log('Search_valueChange', value);
    if (value || value == 0) {
      this.value = +value;
      this.currentValue = +value;
      // ////console.log(this.currentValue);
      // this.googleMap();
      this.applyFilter(this.searchKey);
    }
  }
  zoomIndex = 10;

  /* Name:Shailesh
      use: for showing data on map*/
  googleMap() {
    // //console.log('Search_this.value', this.value);
    this.isZoom = false;

    this.map = new google.maps.Map(document.getElementById('dvMap'), {
      zoom: this.zoomIndex,
      center: new google.maps.LatLng(this.lat, this.lang),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });


    if (!this.value || this.value === 0) {
      if ((this.locationData[this.locationKeys[0]] && this.locationData[this.locationKeys[0]]?.jobParentCustomerResV1List.length > 0) || (this.locationData[this.locationKeys[0]] && this.locationData[this.locationKeys[0]]?.appointmentDetailsForMapViewOtherWorkerList.length > 0)) {
        this.getMarker();
      }
    } else {
      if ((this.value && this.locationData[this.locationKeys[this.value]] && this.locationData[this.locationKeys[this.value]]?.jobParentCustomerResV1List.length > 0) || (this.locationData[this.locationKeys[0]] && this.locationData[this.locationKeys[0]]?.appointmentDetailsForMapViewOtherWorkerList.length > 0)) {
        this.getMarker();
      }
    }

    // setTimeout(() => {
    //   /* Adding Map Legends */
    //   ////console.log('legend...');
    //   ////console.log(this.legend);

    setTimeout(() => {
      this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(this.legend);
    }, 200);
  }

  /* Name:Shailesh
      use: for getting marker on map view*/

  /* getMarker old method */
  // getMarker() {
  //   const bounds = new google.maps.LatLngBounds();
  //   const infowindow = new google.maps.InfoWindow();
  //   let marker, i, marker1, marker3, marker4;
  //   let lengthForOne, lengthForTwo;
  //   // setTimeout(()=>{
  //   ////console.log(this.value, this.locationKeys[this.value]);
  //   lengthForOne = this.locationData[this.locationKeys[this.value]].appointmentDetailsForMapViewOtherWorkerList.length;
  //   lengthForTwo = this.locationData[this.locationKeys[this.value]].jobParentCustomerResV1List.length;
  //   // })
  //   this.centerMarker = [];
  //   const options = { legWeight: 3 }; // Just an example of options - please set your own if necessary
  //   const oms = new OverlappingMarkerSpiderfier(this.map,
  //     { markersWontMove: true, markersWontHide: true });

  //   // //console.log(this.locationData,'Locatipn Data!!');

  //   Object.entries(this.locationData).forEach(([key, val]) => {
  //     // //console.log('key', key); // the name of the current key.
  //     // //console.log('val', val); // the value of the current key.
  //     let keyIndex = this.locationKeys?.findIndex((data) => data === key);
  //     // //console.log('keyIndex', keyIndex, this.value); // the name of the current key.
  //     if (keyIndex !== -1) {
  //       if ((val?.jobParentCustomerResV1List?.length > 0 || val?.appointmentDetailsForMapViewOtherWorkerList?.length > 0) && keyIndex === this.value) {
  //         // if ((val.jobParentCustomerResV1List.length > 0 || val.appointmentDetailsForMapViewOtherWorkerList.length > 0)) {

  //         this.pending = val?.jobParentCustomerResV1List?.filter(element => element?.status == 'pending').map(element => element?.appointmentsDurationCode);

  //         ////console.log(this.pending);

  //         if (this.pending?.length > 0) {
  //           this.disabled = false;
  //         } else {
  //           this.disabled = true;
  //         }

  //         if(val?.jobParentCustomerResV1List.length > 0)
  //         {
  //         val?.jobParentCustomerResV1List?.forEach((ele) => {

  //           // //console.log('Search_ele: ', ele);    

  //           if (ele?.workerName) {
  //             let loc = new google.maps.LatLng(ele?.customerLatitude, ele?.customerLongitude);

  //             let loc1 = new google.maps.LatLng(ele?.workerLatitude, ele?.workerLongitude);

  //             // let loc1 = { lat:element.pickLat, lng: element.pickLang, rgb: { r: 180, g: 180, b: 180 } };

  //             bounds.extend(loc);

  //             ////console.log(ele.status);
  //             let imageUrl, icon;
  //             if (ele?.status == 'inprogress') {
  //               ////console.log(ele.status);
  //               // if(keyIndex == this.value) {
  //               ////console.log(keyIndex, this.value + '....');
  //               imageUrl = './../../assets/common_images/pointer_blue_active.png';
  //               icon = {
  //                 url: imageUrl
  //               };

  //             } else if (ele.status == 'completed') {
  //               imageUrl = './../../assets/common_images/location_green_pointer.png';
  //               icon = {
  //                 url: imageUrl
  //               };
  //             } else if (ele.status == 'late or cancelled') {
  //               imageUrl = './../../assets/common_images/location_red_pointer.png';
  //               icon = {
  //                 url: imageUrl
  //               };
  //             } else {

  //               imageUrl = './../../assets/common_images/pointer_blue.png';
  //               icon = {
  //                 url: imageUrl
  //               };
  //             }

  //             bounds.extend(loc1);

  //             // ////console.log(element.bookingStatus);
  //             let imageUrl1, icon1;

  //             if (ele?.status == 'inprogress') {
  //               // ////console.log(element.bookingStatus);
  //               imageUrl1 = './../../assets/common_images/tec_black_pointer.png';
  //               icon1 = {
  //                 url: imageUrl1
  //               };
  //             } else if (ele.status == 'late or cancelled') {
  //               imageUrl1 = './../../assets/common_images/pointer_orange_active.png';
  //               icon1 = {
  //                 url: imageUrl1
  //               };
  //             } else {
  //               // ////console.log(element.bookingStatus);
  //               imageUrl1 = './../../assets/common_images/tec_black_pointer.png';
  //               icon1 = {
  //                 url: imageUrl1
  //               };
  //             }

  //             // const imageUrl1 = './../../assets/common_images/tec_black_pointer.png';
  //             // const icon1 = {
  //             //   url: imageUrl1
  //             // };

  //             marker = new google.maps.Marker({
  //               position: loc,
  //               map: this.map,
  //               icon: icon,
  //               type: 'patient'
  //             });

  //             marker1 = new google.maps.Marker({
  //               position: loc1,
  //               map: this.map,
  //               icon: icon1,
  //               type: 'technician'
  //             });              

  //             this.createMarker(ele, marker, infowindow, i, oms);
  //             this.createMarker(ele, marker1, infowindow, i, oms);

  //           } else {

  //             let loc4 = new google.maps.LatLng(ele?.customerLatitude, ele?.customerLongitude);

  //             bounds.extend(loc4);

  //             let imageUrl4, icon4;
  //             imageUrl4 = './../../assets/common_images/location_red_pointer.png';
  //             icon4 = {
  //               url: imageUrl4
  //             };

  //             marker4 = new google.maps.Marker({
  //               position: loc4,
  //               map: this.map,
  //               icon: icon4,
  //               type: 'patient'
  //             });

  //             this.createMarker(ele, marker4, infowindow, i, oms);
  //           }
  //         });
  //       }

  //         // //console.log(val.appointmentDetailsForMapViewOtherWorkerList.length,'val.appointmentDetailsForMapViewOtherWorkerList length !!!!')
  //         if(val?.appointmentDetailsForMapViewOtherWorkerList.length > 0)
  //         {
  //         val.appointmentDetailsForMapViewOtherWorkerList.forEach((ele) => {
  //           // //console.log(ele.workerName);
  //           if (ele.workerName) {
  //             // //console.log(ele,'ele!!!');

  //             let loc3 = new google.maps.LatLng(ele.workerLatitude, ele.workerLongitude);

  //             bounds.extend(loc3);

  //             // ////console.log(element.bookingStatus);
  //             let imageUrl3, icon3;

  //             // ////console.log(element.bookingStatus);
  //             imageUrl3 = './../../assets/common_images/tec_gray_pointer.png';
  //             icon3 = {
  //               url: imageUrl3
  //             }


  //             marker3 = new google.maps.Marker({
  //               position: loc3,
  //               map: this.map,
  //               icon: icon3,
  //               type: 'nontechnician'
  //             });

  //             this.createMarker(ele, marker3, infowindow, i, oms);
  //           }
  //         });
  //       }

  //         // lengthForOne = val.appointmentDetailsForMapViewOtherWorkerList.length;
  //         // lengthForTwo = val.jobParentCustomerResV1List.length;
  //       }
  //     }
  //   });
  //   ////console.log(lengthForOne);
  //   ////console.log(lengthForTwo);

  //   if (lengthForOne == 0 && lengthForTwo == 0) {
  //     bounds.extend(this.lat, this.lang);
  //     this.map.fitBounds(bounds);
  //   }
  //   // this.map.fitBounds(bounds);
  //   if (lengthForOne == 1 && lengthForTwo == 0 || lengthForTwo == 1 && lengthForOne == 0) {
  //     setTimeout(() => {
  //       this.map.setZoom(18);
  //     });
  //     this.map.panToBounds(bounds);
  //   }
  //   // this.map.panToBounds(bounds);
  //   setTimeout(() => {

  //     if(this.centerMarker.length == 1){
  //       google.maps.event.trigger(this.centerMarker[0].marker, 'click');
  //       this.map.setZoom(12); 
  //     this.map.setCenter(this.centerMarker[0].marker.getPosition() as google.maps.LatLng);
  //     }
  //   }, 1000);

  // }


  /* Name:Shailesh
      use: for create marker to show on map*/
  createMarker(element, marker, infowindow, i, oms) {
    // //console.log('createMarkerElemrnt: ', element);
    //console.log("marker click ele", element);
    // //console.log('Search_createMarker', element.workerName, marker.type);
    // //console.log('Search_createMarker2', marker.type);    

    google.maps.event.addListener(marker, 'click', () => {
      // ////console.log(marker.type);
      let name;
      let status;

      if (element.status == "pending") {
        status = '<i class="material-icons icon-appointment ' + element.status + '" >hourglass_empty</i>';
      } else if (element.status == "assigned") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">done</i>';
      } else if (element.status == "accepted") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">done</i>';
      } else if (element.status == "inprogress") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">more_horiz</i>';
      } else if (element.status == "completed") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">done</i>';
      } else if (element.status == "expired") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">history</i>';
      } else if (element.status == "cancelled") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">close</i>';
      } else if (element.status == "late or cancelled") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">assignment_late</i>';
      }
      else if (element.status == "notified") {
        status = '<i style="background: #e1d216;" class="material-icons icon-appointment ' + element.status + '">check_circle</i>';
      }
      else { }
      // if (element.workerName == null) {
      //   element.workerName = 'Not available';
      // }

      if (marker.type == 'patient') {
        let workerName;
        name = '<div class="map-markerdetails"><div class="title">Service Location ';
        if (element.delayedStartTime) {
          name += '<div>( ETA: ' + element.delayedStartTime + ' )</div>';
        }
        workerName = element.workerName ? element.workerName : 'Not Available';
        ////console.log(element.status);
        // if (element.status == "pending" || element.status == "late or cancelled") {
        //   name += '<i id="assignAppointment" class="material-icons icon-appointment float-end cursor-pointer ms-2" title="Assign Appointment">people</i>';
        // }

        if (element.status == "pending" || element.status == "notified") {
          name += `<div style="margin-left:2rem;">
            <button id="notifyStaff" class="btn btn-primary btn-sm">Notify</button>&nbsp;
            <button id="assignStaff" class="btn btn-danger btn-sm">Assign</button>
            </div>`;
        }


        if (element.status != "completed" && element.status != "pending") {
          name += '<i id="openPerticularChat" class="d-inline-block cursor-pointer material-icons float-end" title="Open chat">chat</i>';
        }

        if (element.status == 'completed' && element.actualStartTime) {
          name += '<i id="openArrrivalTime" class="clock-time d-inline-block cursor-pointer material-icons float-end" title="Actual Schedule">access_time</i>';
        }
        name += `<div class="clearfix"></div></div>
                 <div class="driver-details m-0 row border-bottom pb-2">
                 <span class="${element.status} booking-status map-status col-md-2 ps-0" title="${element.status}"> ${status}</span> 
                 <div class="driver-name col-md-5 ps-0">
                 <label class="d-block">Customer Name</label>
                  <span>${element.customerName}</span> 
                  </div> 
                  <div class="driver-name col-md-5 pe-0">
                  <label class="d-block">Staff Member Name</label> 
                  <span>${workerName}</span> 
                  </div></div>
                  <div class="clearfix"></div></div>
                  <div class="py-2 address-map border-bottom">
                  <label class="d-block">Customer Address</label> 
                  <span>${element.customerAddress}</span></div>
                  <div class="border-bottom py-2 driver-name row m-0">
                  <div class="driver-name col-md-6 ps-0 pe-1">
                  <label class="d-block">Time</label> 
                  <span>${element.startTime}-${element.endTime}</span>
                   </div>`;

        //  <div class="driver-name col-md-3 ps-0">
        //  <label class="d-block">Duration</label>
        //   <span>${element.durationHours }:${element.durationMinute }</span>
        //   </div>

      }
      else if (marker.type == 'nontechnician') {
        name = '<div class="map-markerdetails"><div class="title">Current Location ';
        // if(element.status != "completed") {
        name += '<i id="openPerticularChat" class="d-inline-block cursor-pointer material-icons float-end" title="Open chat">chat</i>';
        // }
        name += '<div class="clearfix"></div></div><div class="driver-details m-0 row border-bottom pb-2"><div class="driver-name col-md-10 ps-0"><label class="d-block">Staff Member Name</label> <span>' + element.workerName + '</span> </div> </div><div class="clearfix"></div></div><div class="py-2 address-map border-bottom"><label class="d-block">Staff Member Address</label> <span>' + element.workerAddress + '</span></div><div class="nontechnician border-bottom py-2 driver-name row m-0">';
      } else {
        name = '<div class="map-markerdetails"><div class="title">Current Location ';
        if (element.delayedStartTime) {
          name += '<div>( ETA: ' + element.delayedStartTime + ' )</div>';
        }
        if (element.status != "completed") {
          name += '<i id="openPerticularChat" class="d-inline-block cursor-pointer material-icons float-end" title="Open chat">chat</i>';
        }

        if (element.status == 'completed' && element.actualStartTime) {
          name += '<i id="openArrrivalTime" class="clock-time d-inline-block cursor-pointer material-icons float-end" title="Actual Schedule">access_time</i>';
        }
        name += '<div class="clearfix"></div></div><div class="driver-details m-0 row border-bottom pb-2"><span class="' + element.status + ' booking-status map-status col-md-2 ps-0">' + status + '</span> <div class="driver-name col-md-5 ps-0"><label class="d-block">Staff Member Name</label> <span>' + element.workerName + '</span> </div> <div class="driver-name col-md-5 pe-0"><label class="d-block">Customer Name</label> <span>' + element.customerName + '</span> </div></div><div class="clearfix"></div></div><div class="py-2 address-map border-bottom"><label class="d-block">Staff Member Address</label> <span>' + element.workerAddress + '</span></div><div class="border-bottom py-2 driver-name row m-0"><div class="driver-name col-md-6 ps-0 pe-1"><label class="d-block">Time</label> <span>' + element.startTime + '-' + element.endTime + '</span> </div>';
      }
      if (element.organizationBasedProcedureDetailsList.length > 0) {
        name += '<div id="allProcedures" class="col-md-6 d-flex flex-row-reverse locations-icons p-0">';
        if (element.organizationBasedProcedureDetailsList.length > 4) {
          const number = element.organizationBasedProcedureDetailsList.length - 3;
          name += '<div class="img-data" id="showIcons"><div class="img-count cursor-pointer">' + number + '</div></div>'
        }
        element.organizationBasedProcedureDetailsList.forEach((ele, index) => {
          ////console.log(index);
          if (index <= 2 && ele.procedureIcon) {
            name += '<img class="me-1" title="' + ele.procedureName + '" alt="' + ele.procedureName + '" src="' + this.service.common.superAdminUrl + ele.procedureIcon + '" />';
          }
          if (!ele.procedureIcon) {
            name += '<img alt="No Image" src="../../../../assets/common_images/profile.jpg" />'
          }
        });
        name += '<div class="clearfix"></div></div></div>'


        // document.getElementById("iconNumber").addEventListener("click", function(e) {
        //   ////console.log(element);
        // });
      }

      // if(element.appointmentsDurationCode == this.showImg) {
      //   element.organizationBasedProcedureDetailsList.forEach((ele, index)=>{
      //     ////console.log(index);
      //     if(index >=4  && ele.procedureIcon){
      //       name += '<img class="me-1" title="'+ele.procedureName+'" alt="'+ele.procedureName+'" src="'+ this.service.common.superAdminUrl+ele.procedureIcon +'" />';
      //     }
      //   });
      // }

      // //console.log('Search_infowindow', element);
      infowindow.setContent(name);
      infowindow.open(this.map, marker);
      google.maps.event.addListenerOnce(infowindow, 'domready', () => {
        // //console.log(element.status);
        // //console.log(document.getElementById('assignAppointment'));
        // if (element.status == "pending" || element.status == "late or cancelled") {
        //   if(document.getElementById('assignAppointment')) {
        //     document.getElementById('assignAppointment').addEventListener('click', () => {
        //       this.assignAppointment(element.appointmentsDurationCode);
        //     });
        //   }
        // }

        if (element.status == "pending" || element.status == "notified") {
          if (document.getElementById('notifyStaff')) {
            document.getElementById('notifyStaff').addEventListener('click', () => {
              this.notifyStaff(element);
            });
          }

          if (document.getElementById('assignStaff')) {
            document.getElementById('assignStaff').addEventListener('click', () => {
              this.assignStaff(element);
            });
          }

        }

        if (document.getElementById('openArrrivalTime')) {
          document.getElementById('openArrrivalTime').addEventListener('click', () => {
            this.openArrivalDialog(element);
          })
        }

        // ////console.log(document.getElementById('showIcons'));
        if (document.getElementById('showIcons')) {
          document.getElementById('showIcons').addEventListener('click', () => {
            this.showAllIcons(element);
          });
        }
        if (document.getElementById('openPerticularChat')) {
          document.getElementById('openPerticularChat').addEventListener('click', () => {
            this.showChat(element.workerCode);
          });
        }

      });



    });

    if (element?.isCenter) {
      // //console.log(element,'center!!!');

      this.centerMarker.push({ marker: marker, index: i });

      // this.map.setZoom(20); 
      // this.map.setCenter(marker.getPosition() as google.maps.LatLng);
      // google.maps.event.trigger(marker, 'click');
    }

    oms.addMarker(marker);

    // setTimeout(() => {
    //   oms.addMarker(marker);
    // }, 500);

    return marker;
  }

  /* Name:Shailesh
      use: for all icons on map*/
  showAllIcons(ele1) {
    // ////console.log(ele1);
    // document.getElementById('showIcons').remove();
    let showMoreIcons = document.getElementById('allProcedures');
    showMoreIcons.classList.remove("d-flex");
    let name = '';
    ele1.organizationBasedProcedureDetailsList.forEach((ele, index) => {
      ////console.log(index);
      if (ele.procedureIcon) {
        name += '<img class="me-1 mb-1" title="' + ele.procedureName + '" alt="' + ele.procedureName + '" src="' + this.service.common.superAdminUrl + ele.procedureIcon + '" />';
      }
      if (!ele.procedureIcon) {
        name += '<img alt="No Image" src="../../../../assets/common_images/profile.jpg" />'
      }
    });
    // ////console.log(name);
    showMoreIcons.innerHTML = name;
  }

  /* Name:Shailesh
      use: for assign appontment to the eligible Staff Member*/
  assignAppointment(jobDurationCode) {
    this.service.common.progressLoader = true;
    let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);
    try {
      this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, this.currentSelectedDate, WeekstartDate, weekendDate, (data) => {
        ////console.log(data);
        if (data.data.workerWithDistanceList.length > 0) {
          const dialogRef = this.dialog.open(AssignAppointmentComponent, {
            width: '750px',
            disableClose: true,
            data: { data, jobDurationCode }
          });
          dialogRef.afterClosed().subscribe(result => {
            //////console.log(result);
            if (result != undefined) {
              this.service.common.message = result;
              this.service.common.successMessage = true;
              this.adminGetAppointmentDetailsForMapView(this.formatedDate);
            }
          });
        }
        else {

          const dialogRef = this.dialog.open(SplitAppointmentComponent, {
            width: '450px',
            disableClose: true,
            data: { data, jobDurationCode }
          });
          dialogRef.afterClosed().subscribe(result => {
            //////console.log(result);
            if (result != undefined) {
              // this.getAppointments();
              this.service.common.message = result;
              this.service.common.successMessage = true;
              this.adminGetAppointmentDetailsForMapView(this.formatedDate);
            }
          });
        }
        this.service.common.progressLoader = false;

      });
    } catch (error) {
      //console.log(error)
    }

  }


  showImage(element) {
    ////console.log(element);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
  }

  /* Name:Shailesh
      use: for searching location */
  applyFilter(search) {
    this.searchKey = search;
    const locationValue = [];
    const workerValue = [];
    let currentSelectedValue;
    // ////console.log(this.locationKeys[this.currentValue]);
    if (this.value == 0) {
      currentSelectedValue = this.locationKeys[0];
    } else {
      currentSelectedValue = this.locationKeys[this.value];
    }
    // ////console.log(this.allLocationData);
    // //console.log('Search_applyFilter', search);
    if (search) {

      const allFilteredLocations = this.allLocationData[currentSelectedValue]['jobParentCustomerResV1List'].slice();
      const allFilteredWorkerLocations = this.allLocationData[currentSelectedValue]['appointmentDetailsForMapViewOtherWorkerList'].slice();

      const allFillterdArrayVal = [...allFilteredWorkerLocations, ...allFilteredLocations];

      // //console.log('Search_allFilteredLocations', allFilteredLocations);
      // //console.log('Search_allFilteredWorkerLocations', allFilteredWorkerLocations);
      // //console.log('Search_allFillterdArrayVal', allFillterdArrayVal);

      const searchLoc = allFillterdArrayVal && allFillterdArrayVal.length > 0 && allFillterdArrayVal.filter((item) => item?.customerName && item?.customerName?.toLowerCase().includes(search.toLowerCase()));

      // (item?.customerName && item?.customerName?.toLowerCase().includes(search.toLowerCase()))))

      const searchWorkerLoc = allFillterdArrayVal && allFillterdArrayVal.length > 0 && allFillterdArrayVal.filter((item) => item?.workerName && item?.workerName?.toLowerCase().includes(search.toLowerCase()));

      searchLoc && searchLoc.length > 0 && searchLoc.forEach((item, key) => {
        if (item) {
          item.isCenter = true;
        }
        locationValue.push(item);
      });

      searchWorkerLoc && searchWorkerLoc.length > 0 && searchWorkerLoc.forEach((item, key) => {
        if (item) {
          item.isCenter = true;
        }
        workerValue.push(item);
      })

      this.locationData[currentSelectedValue] = { 'jobParentCustomerResV1List': locationValue, 'appointmentDetailsForMapViewOtherWorkerList': workerValue };
      ////console.log(this.locationData[currentSelectedValue]);
      this.googleMap();

      setTimeout(() => {
        document.getElementById('searchFilter').focus();
      }, 2000);

    } else {

      if (currentSelectedValue && (!search || search === null)) {
        this.allLocationData[currentSelectedValue].jobParentCustomerResV1List.forEach((item, key) => {

          item.isCenter = false;
        })
        this.allLocationData[currentSelectedValue].appointmentDetailsForMapViewOtherWorkerList.forEach((item, key) => {
          item.isCenter = false;
        })

        this.locationData[currentSelectedValue] = { 'jobParentCustomerResV1List': this.allLocationData[currentSelectedValue].jobParentCustomerResV1List.slice(), 'appointmentDetailsForMapViewOtherWorkerList': this.allLocationData[currentSelectedValue].appointmentDetailsForMapViewOtherWorkerList.slice() };

        this.googleMap();

        setTimeout(() => {
          document.getElementById('searchFilter').focus();
        }, 2000);
      }
    }

  }

  /* Name:Shailesh
      use: for getting chat group detail by user code and admin code*/
  showChat(element) {
    // //console.log(element);
    this.service.common.progressLoader = true;
    try {
      this.ChatService.adminGetGroupDetailByUserCodeAndAdminCode(element, this.service.local.get('userCode'), (response) => {
        // //console.log(response);
        if (response.responsecode == 200) {
          ////console.log(response.data.groupDetailsCode);

          this.openPerticularChat(response.data.groupDetailsCode);
        } else {
          this.service.common.progressLoader = false;
        }
      })
    } catch (error) {
      //console.log(error)
    }
  }

  /* Name:Shailesh 
       use: for open perticual chat */
  openPerticularChat(groupDetailsCode): void {
    // ////console.log(groupDetailsCode);
    // ////console.log(document.getElementById('chat-application'));
    // document.getElementById('chat-application').setAttribute('groupdetailscode',groupDetailsCode)
    // document.getElementById('chat-application').click();
    let groupUrl = this.service.common.local.get('userCode') + '/' + groupDetailsCode;
    this.ChatService.adminGetAllGroupByOrganizationCode(this.service.common.local.get('orgCode'), groupUrl, (response) => {
      ////console.log(response);
      this.service.common.progressLoader = false;
      const dialogRef = this.dialog.open(ChatComponent, {
        width: '66%',
        panelClass: 'chat-popup',
        data: { arrayList: response, groupDetailsCode: groupDetailsCode }
      });
      // healthcare-notification-controller
      // const dialogRef = this.dialog.open(ChatComponent, {
      //   width: '66%',
      //   panelClass: 'chat-popup',
      //   data: { groupDetailsCode: groupDetailsCode }
      // });
      dialogRef.afterClosed().subscribe(result => {
        if (this.service.common.globalChatCount != 0) {
          this.service.common.getGlobalCountChat();
        }
      });
    });

  }

  /* Name:Shailesh
      use: for open arrival popup*/
  openArrivalDialog(element): void {
    ////console.log(element);


    if (element.actualStartTime) {
      if (element.dateFormat == 'dd-MM-yyyy') {
        element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[0] + '-' + element.actualStartTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      } else if (element.dateFormat == 'MM-dd-yyyy') {
        element.arrivalTimeDate = moment(element.actualStartTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      } else {
        element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[2] + '-' + element.actualStartTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      }
    }
    if (element.actualEndTime) {
      if (element.dateFormat == 'dd-MM-yyyy') {
        element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[0] + '-' + element.actualEndTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      } else if (element.dateFormat == 'MM-dd-yyyy') {
        element.departureTimeDate = moment(element.actualEndTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      } else {
        element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[2] + '-' + element.actualEndTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      }
    }

    // element.arrivalTimeDate = moment(new Date(element.actualStartTime), element.timeFormat).format(element.timeFormat);
    // element.departureTimeDate = moment(new Date(element.actualEndTime), element.timeFormat).format(element.timeFormat);

    const dialogRef = this.dialog.open(ArrivalDepartureComponent, {
      width: '350px',
      data: { arrival: element.arrivalTimeDate, departure: element.departureTimeDate }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }


  /* Name:Shailesh
      use: for assign appointment by optaplanner*/
  autoAssign() {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to auto staff assign?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.common.progressLoader = true;
          this.service.common.percentageLoader = true;
          try {
            this.service.adminAssignJobsByOptaplanner(this.service.common.local.get('orgCode'), this.formatedDate, this.formatedDate, this.pending, (res) => {
              //////console.log(res);
              if (res.responsecode == 200) {

                this.service.totalRecords = res.data.totalRecord;
                this.service.processedRecords = res.data.processRecord;
                this.service.common.progressText = 'Processing ' + this.service.processedRecords + ' of  ' + this.service.totalRecords + ' appointments';
                try {
                  this.service.getAppointmentThreadProcessingByCode(res.data.appointmentsThreadProcessingCode, (response) => {
                    let arrayData = [];
                    response.data.ewresLst.forEach(element => {
                      if (element.workerWithDistanceList.length > 0) {
                        arrayData.push(element);
                      }
                    });
                    setTimeout(() => {
                      // //console.log(arrayData);
                      this.service.common.progressLoader = false;
                      this.service.common.progressText = '';
                      this.service.common.percentageLoader = false;
                      if (arrayData.length == 0) {
                        this.getSplitAppointment(response.data.appointmentsDurationCodeList);
                      } else {

                        this.adminGetAppointmentDetailsForMapView(this.formatedDate);
                        this.openDialog(arrayData);
                      }
                    }, 2000);
                  })
                } catch (error) {
                  //console.log(error)
                }

              } else {
                this.service.common.progressLoader = false;
                this.service.common.message = res.message;
                this.service.common.errorMessage = true;
              }

            })
          } catch (error) {
            //console.log(error)
          }
        } catch (error) {
          //console.log(error)
        }

      }
    });

  }

  /* Name:Shailesh
   use: to open split appointment popup */
  getSplitAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(SplitAppointmentComponent, {
      width: '450px',
      disableClose: true,
      data: { jobDurationCode }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        this.isShowRefresh = false;
        // this.getAppointments();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }

  /* Name:Shailesh
      use: to open popup for assign Staff Member on appointment*/
  openDialog(workers): void {
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      width: '350px',
      disableClose: true,
      data: { data: workers, fromdate: this.formatedDate, toDate: this.formatedDate, scheduledDate: this.formatedDate }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.adminGetAppointmentDetailsForMapView(this.formatedDate);
      ////console.log('The dialog was closed');
    });
  }

  /* Name:Shailesh
      use: for open popup for creating appointment*/
  openAppointment() {
    const dialogRef = this.dialog.open(CreateAppointmentComponent, {
      width: '70%',
      height: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.saveAndassign == 'saveAndassign') {
          this.assignAppointment(result.appointmentsDurationCode);
        } else {
          this.adminGetAppointmentDetailsForMapView(this.formatedDate);

        }
      }
    });
  }


  notifyStaff(data: any) {
    let popupData = {
      appoinmentCode: data.appointmentsCode,
      appoinmentProcedureCode: data.appointmentsProceduresCode,
      date: data.formattedDate
    }

    //console.log("popupData",popupData)

    this.dialogRef = this.dialog.open(NotifyJobComponent, {
      disableClose: true,
      width: '500px',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('CloseNotifyPopupReq:', result);
      if (result != undefined && result === 'success') {

      }
    });
  }

  assignStaff(data: any) {
    let popupData = {
      appoinmentCode: data.appointmentsCode,
      appoinmentProcedureCode: data.appointmentsProceduresCode,
      date: data.formattedDate
    }
    // console.log("popupData",popupData)
    this.dialogRef = this.dialog.open(AssignJobComponent, {
      disableClose: true,
      width: '700px',
      data: popupData
    });

    this.dialogRef.afterClosed().subscribe(result => {
      //  console.log('CloseAssignPopupReq:', result);
      if (result != undefined && result === 'success') {

      }
    });
  }




  /* getMarker new method */

  getMarker() {
    const bounds = new google.maps.LatLngBounds();
    const infowindow = new google.maps.InfoWindow();
    let marker, i, marker1, marker3, marker4;
    let lengthForOne, lengthForTwo;
    // setTimeout(()=>{
    ////console.log(this.value, this.locationKeys[this.value]);
    lengthForOne = this.locationData[this.locationKeys[this.value]].appointmentDetailsForMapViewOtherWorkerList.length;
    lengthForTwo = this.locationData[this.locationKeys[this.value]].jobParentCustomerResV1List.length;
    // })
    this.centerMarker = [];
    const options = { legWeight: 3 }; // Just an example of options - please set your own if necessary
    const oms = new OverlappingMarkerSpiderfier(this.map,
      { markersWontMove: true, markersWontHide: true });

    // //console.log(this.locationData,'Locatipn Data!!');

    Object.entries(this.locationData).forEach(([key, val]) => {
      // //console.log('key', key); // the name of the current key.
      // //console.log('val', val); // the value of the current key.
      let keyIndex = this.locationKeys?.findIndex((data) => data === key);
      // //console.log('keyIndex', keyIndex, this.value); // the name of the current key.
      if (keyIndex !== -1) {
        if ((val?.jobParentCustomerResV1List?.length > 0 || val?.appointmentDetailsForMapViewOtherWorkerList?.length > 0) && keyIndex === this.value) {
          // if ((val.jobParentCustomerResV1List.length > 0 || val.appointmentDetailsForMapViewOtherWorkerList.length > 0)) {

          this.pending = val?.jobParentCustomerResV1List?.filter(element => element?.status == 'pending').map(element => element?.appointmentsDurationCode);

          ////console.log(this.pending);

          if (this.pending?.length > 0) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }

          if (val?.jobParentCustomerResV1List.length > 0) {
            val?.jobParentCustomerResV1List?.forEach((ele) => {

              if (ele.customerLatitude && ele.customerLongitude) {
                let g_mrker_icon: string = null;

                if (ele.required == ele.scheduled) {
                  g_mrker_icon = './../../assets/common_images/pointer_blue.png';
                }
                else {
                  g_mrker_icon = './../../assets/common_images/location_red_pointer.png';
                }

                let red_marker = new google.maps.Marker({
                  position: new google.maps.LatLng(ele?.customerLatitude, ele?.customerLongitude),
                  map: this.map,
                  icon: g_mrker_icon,
                  type: 'patient'
                });

                this.createMarker_for_job(ele, red_marker, i, oms);

              }

            });
          }

          // //console.log(val.appointmentDetailsForMapViewOtherWorkerList.length,'val.appointmentDetailsForMapViewOtherWorkerList length !!!!')
          if (val?.appointmentDetailsForMapViewOtherWorkerList.length > 0) {
            val.appointmentDetailsForMapViewOtherWorkerList.forEach((ele) => {
              // //console.log(ele.workerName);
              if (ele.workerName) {
                if (ele.workerLatitude && ele.workerLongitude) {

                  let loc3 = new google.maps.LatLng(ele.workerLatitude, ele.workerLongitude);

                  bounds.extend(loc3);

                  // ////console.log(element.bookingStatus);
                  let imageUrl3, icon3;

                  // ////console.log(element.bookingStatus);
                  imageUrl3 = './../../assets/common_images/tec_gray_pointer.png';
                  icon3 = {
                    url: imageUrl3
                  }


                  marker3 = new google.maps.Marker({
                    position: loc3,
                    map: this.map,
                    icon: icon3,
                    type: 'nontechnician'
                  });

                  this.createMarker(ele, marker3, infowindow, i, oms);
                }
              };
            });
          }

          // lengthForOne = val.appointmentDetailsForMapViewOtherWorkerList.length;
          // lengthForTwo = val.jobParentCustomerResV1List.length;
        }
      }
    });


    // if (lengthForOne == 0 && lengthForTwo == 0) {
    //   bounds.extend(this.lat, this.lang);
    //   this.map.fitBounds(bounds);
    // }
    // // this.map.fitBounds(bounds);
    // if (lengthForOne == 1 && lengthForTwo == 0 || lengthForTwo == 1 && lengthForOne == 0) {
    //   setTimeout(() => {
    //     this.map.setZoom(18);
    //   });
    //   this.map.panToBounds(bounds);
    // }
    // // this.map.panToBounds(bounds);
    // setTimeout(() => {

    //   if(this.centerMarker.length == 1){
    //     google.maps.event.trigger(this.centerMarker[0].marker, 'click');
    //     this.map.setZoom(12); 
    //   this.map.setCenter(this.centerMarker[0].marker.getPosition() as google.maps.LatLng);
    //   }
    // }, 1000);

  }


  createMarker_for_job(element, marker, i, oms) {

    google.maps.event.addListener(marker, 'click', () => {
      this.showScheduledStaff(element);
    });


    oms.addMarker(marker);

  }

   

  showScheduledStaff(data: any) {
  //  console.log("marker clicked showScheduledStaff", data);

    this.staffData = data;

    this.dialogRef = this.dialog.open(this.schdeuledStaff_popUp, { width: '85%' });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.staffData = null;
    });

  }


  handleRefreshEvent(event:any)
  {
    this.dialogRef.close();
    this.adminGetAppointmentDetailsForMapView(this.formatedDate);
  }


  subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
      this.hasPermission =  this._commonSVC.getBtnEventPermissions("Job Scheduling");
     }
 })
}


ngOnDestroy(): void {
  this.clearObs.next(''),
  this.clearObs.complete();
}


}
