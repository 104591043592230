<div class="w-100">
  <div class="row w-100 mx-auto">
    <div class="col-md-6 ps-0">
      <label class="title-header header-name">{{ 'chat.labels.Create Chat Group' | translate }}</label>
    </div>
    <div class="col-md-6 text-end p-0">
      <i class="cursor-pointer material-icons" (click)="cancel()">close</i>
    </div>
    <form class="w-100 row mx-auto" [formGroup]="chatFormGroup" (ngSubmit)="onSubmit()"
      *ngIf="!resetForm">
      <div class="col-md-12 p-0">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>Group Name</mat-label>
          <input matInput placeholder="" formControlName="groupname">
        </mat-form-field>
      </div>
      <div class="col-md-12 mt-3 p-0">
        <ng-template matStepLabel>{{ 'chat.labels.Marshals list' | translate }}</ng-template>
        <div class="w-100 providers-list">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width custom-search">
            <mat-label>{{ 'chat.labels.Find Technician' | translate }}</mat-label>
            <input type="text" matInput autoClose matInput
              [matAutocomplete]="auto" placeholder=""
              formControlName="selectmarshalname">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
              (optionSelected)="selectedOption($event)">
              <mat-option *ngFor="let option of filteredOptions | async"
                [value]="option">
                {{option.workerRes.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-icon class="spanarrow float-end searchIcon">search</mat-icon>
          </mat-form-field>
        </div>
        <div class="w-100">
          <mat-chip-list formControlName="marshalname">
            <mat-chip class="text-capitalize" *ngFor="let org of orgSelected;let i=
              index">{{ org.workerRes.name}} &nbsp;<a (click)="removeOrg(i)"><mat-icon>close</mat-icon></a></mat-chip>
          </mat-chip-list>
          <mat-error *ngIf="chatFormGroup.get('marshalname').errors?.required &&
            (!submitted || chatFormGroup.get('marshalname').touched)">{{ 'chat.labels.Please select atleast one marshal' | translate }}</mat-error>
        </div>
      </div>

    
        <div class="col-md-6 dateicon ps-0">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'chat.labels.Start Date' | translate }}</mat-label>
            <input matInput class="form-control" formControlName="startdate" [max]="breakYear" [matDatepicker]="picker"
              placeholder="">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-6 dateicon pe-0">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>{{ 'chat.labels.End Date' | translate }}</mat-label>
            <input matInput class="form-control" formControlName="enddate" [max]="breakYear" [matDatepicker]="picker1"
              placeholder="">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="w-100 text-center mt-3">
          <button class="me-3" color="secondary" mat-button (click)="cancel()"> {{ 'common.labels.Cancel' | translate }} </button>
          <button class="text-center" mat-raised-button color="primary">{{ 'chat.labels.Create' | translate }}</button>
        </div>
      
    </form>
  </div>
</div>