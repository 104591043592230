import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from './common.service';
import { LoginService } from '../authentication/login/login.service';
import { ValidateRouteService } from './services/validate-route.service';

@Injectable()
export class AuthService {
  private returnUrl: string | null = null;
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  username;
  userdata;
  userType;
  userCode;
  isActiveMenu: boolean = false;
  isWebAppPermission = false;


  constructor(
    private route: Router,
    public local: SessionStorageService,
    public common: CommonService,
    public loginService: LoginService,
    private _validateRouteSVC : ValidateRouteService
  ) {
    
  }

  isAuthenticated(path) {
    // console.log('isAuthenticatedPath: ', path);
    const promise = new Promise(
      (resolve, reject) => {
        if (this.local.get('token')) {
          this.username = this.local.get('username');
          this.userType = this.local.get('user_type');
          this.isWebAppPermission = this.local.get('MenuListDataAndPagePermission').isWebApp;
          this.common.organizationStatus = this.local.get('orgStatus');
          if (this.local.get('roleCode')) {

            if(path == 'dashboard-customer'){
              resolve(this.local.get('token'));
            }

            else{
            this.common.getAllActivitiesByRoleCodeByPage(this.local.get('roleCode'), (responseRoute) => {
              if (responseRoute.responsecode == 200) {
                //console.log(responseRoute);
                this.common.routesArray = responseRoute.data;                                               
                if ((path == 'dashboard') || (path == 'dashboard-technician') || (path == 'dashboard-customer')) {
                  resolve(this.local.get('token'));
                } else {
                  // if (this.common.organizationStatus == 'Pending') {
                  //   reject(this.route.navigate(['/dashboard/unauthorize']));
                  // } else {

                    const result = this.common.routesArray.find(findRoute => findRoute.urlRegex === path);
                    console.log(result);
                    if (!result && this.local.get('user_type') && !this.isWebAppPermission) {
                      if (this.userType === this.loginService.healthTechnician) {
                        reject(this.route.navigate(['/dashboard-technician/unauthorize']));
                      } else {
                        reject(this.route.navigate(['/dashboard/unauthorize']));
                      }

                    } else {
                      const index = this.common.validatePendingOrgList.find(findRoute => findRoute.name === path);
                      console.log(index);
                      if(!index && this.common.organizationStatus == 'Pending' && !this.isWebAppPermission) {
                        reject(this.route.navigate(['/dashboard/unauthorize']));
                      } else {
                        resolve(this.local.get('token'));
                      }
                    }
                  //}
                }
              }
            })
          }
          }
        } else {
          reject(this.route.navigate(['/login']));
        }
      }
    )
    return promise;
  }


    /********
     * authenticate with valid route rermission
     * code to check whether user have permission to view this page or not
     * ********/

  // isAuthenticated(stateUrl) 
  // {
  //    const promise = new Promise(
  //     (resolve, reject) => {
  //       if (this.local.get('token')) 
  //       {
  //         if(this.local.get('user_type') === "Staff")
  //         {
  //           resolve(this.local.get('token'));
  //         }
  //         else
  //         {
  //           //   if(stateUrl !== '/login' || stateUrl !== '/unauthorize')
  //           // {
               
  //              if(this._validateRouteSVC.isValidRoute(stateUrl))
  //              {
  //                 resolve(this.local.get('token'));
  //              }
  //              else
  //              {
  //                reject(this.route.navigate(['/unauthorize']));
  //              }
  //           // }
  //         }
       

  //       } 
  //       else 
  //       {
  //         reject(this.route.navigate(['/login']));
  //       }
  //     }
  //   )
  //   return promise;
  // }


  setReturnUrl(url: string): void {
    this.returnUrl = url;
  }

  getReturnUrl(): string | null {
    return this.returnUrl;
  }

  clearReturnUrl(): void {
    this.returnUrl = null;
  }

  login() {
    this.common.getTimeSlot();
    this.common.getColors();
    this.loggedIn.next(true);
  }

  logout() {

    this.clearSession();
    // this.local.set('token', null);
    // this.local.set('user_type', null);
    // this.local.set('username', null);
    // this.local.set('email', null);
    // this.local.set('orgCode', null);
    // this.local.set('orgName', null);
    // this.local.set('userCode', null);              // {4}
    // this.local.set('image', null);              // {4}
    // this.local.set('roleCode', null);              // {4}
    // this.local.set('timeZone', null);
    // this.local.set('log_type', null);
    // this.local.set('org_list', null);
    // this.local.set('orgStatus', null);
    // this.local.set('docExpiry', null);
    // this.local.set('isActiveOrg', null);
    // this.local.set('isOnboarding',null);
    // this.local.set('organizationOnboardingCode',null);
    // this.local.set('selected_OrgCode',null);
    // this.local.set('logedIn_CustomerCode',null);
    // {4}

    //  this.loggedIn.next(false);
    //  this.clearReturnUrl();
   //  this.unSubscribeObservablesWhenLogOut();
  //  this._validateRouteSVC.resetRouteCheck();
    this.route.navigate(['/login']);
  }

clearSession()
{
  this.local.set('token', null);
  this.local.set('user_type', null);
  this.local.set('username', null);
  this.local.set('email', null);
  this.local.set('orgCode', null);
  this.local.set('orgName', null);
  this.local.set('userCode', null);              // {4}
  this.local.set('image', null);              // {4}
  this.local.set('roleCode', null);              // {4}
  this.local.set('timeZone', null);
  this.local.set('log_type', null);
  this.local.set('org_list', null);
  this.local.set('orgStatus', null);
  this.local.set('docExpiry', null);
  this.local.set('isActiveOrg', null);
  this.local.set('isOnboarding',null);
  this.local.set('organizationOnboardingCode',null);
  this.local.set('selected_OrgCode',null);
  this.local.set('logedIn_CustomerCode',null);
  sessionStorage.clear();
  localStorage.clear();
  this.loggedIn.next(false);
   this.clearReturnUrl();
   this._validateRouteSVC.resetRouteCheck();
  // this.common.resetNotificationCount();
  // {4}

  // this.loggedIn.next(false);
  // this.route.navigate(['/login']);
  // this.clearReturnUrl();
}

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }


  // unSubscribeObservablesWhenLogOut()
  // {
  //   this.common.unSubscribeFromNotificationSocket();
  // }


}
