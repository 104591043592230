import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { SystemSettingsService } from '../system-settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

@Component({
  selector: 'app-notification-sys-setting',
  templateUrl: './notification-sys-setting.component.html',
  styleUrls: ['./notification-sys-setting.component.scss'],
  providers: [SystemSettingsService]
})
export class NotificationSysSettingComponent implements OnInit, OnDestroy {

  NotificationSetting_Form: FormGroup;
  is_FormSubmitted: boolean = false;
  notificationChipLocal: any[] = [];

  model_reminder: any;

  reminderOption: any = [
    { option: 'User Request' },
    { option: 'Schedule change' },
    { option: 'Alerts for next day schedule based on Past absenteeism' }
  ];

  boolean = [
    { text: 'True', value: 'true' },
    { text: 'False', value: 'false' }
  ]

  hasPermission:IBtnControlsPermission ;
  protected isLoading : boolean = false ;
  private clearObs = new Subject<any>();
  
  constructor(
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    private _commonSVC: CommonService,
    public _systemSettingsSVC: SystemSettingsService,

  ) {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
    this.initNotificationSetting_Form();
  }

  ngOnInit(): void {
    this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
    this.hasPermission =  this._commonSVC.getBtnEventPermissions("Notification Settings"); 
    this.subToPermissionChanged();
  }

  initNotificationSetting_Form() {
    this.NotificationSetting_Form = new FormGroup({

      'schedule_publish': new FormControl(null),
      'selectreminderOption': new FormControl(null),
      'notifiation_chip': new FormControl(null),
      'notifiation_chip2': new FormControl(null),
      'specific_starttime': new FormControl(null),
      'alert_distance': new FormControl(null),
      'push_notification': new FormControl(null),
      'configuremintues': new FormControl(null, Validators.required),
      'alertminutes': new FormControl(null, Validators.required),
      // 'expiredminutes': new FormControl(null, Validators.required),
      'isRecursive': new FormControl(null, Validators.required),
      'sms_notifications': new FormControl(null),
      'mail_notifications': new FormControl(null),

    });
  }


  getAllsettings(orgCode) {
    this._commonSVC.progressLoader = true;
    this.isLoading = true;
    try {
      this._systemSettingsSVC.getAllgetAllLocalSettingWithDefaultGlobalSettingByUserCode(orgCode).subscribe((data: any) => {
        this.isLoading = false;

        if (data.responsecode == 200) {
          this._systemSettingsSVC.settings = data.data;


          if (this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue == null) {
            this.NotificationSetting_Form.controls['notifiation_chip2'].setValue(this._systemSettingsSVC.settings[1].NotificationSetting[1].globalSettingValue);
            this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue = this._systemSettingsSVC.settings[1].NotificationSetting[1].globalSettingValue;
          } else {
            this.NotificationSetting_Form.controls['notifiation_chip2'].setValue(this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue);
          }


          this.NotificationSetting_Form.controls.selectreminderOption.setValue(
            this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue
              ? this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue
              : this._systemSettingsSVC.settings[1].NotificationSetting[1].globalSettingValue
          )


          /********************************************************************************************************************************* */


          this._systemSettingsSVC.settings.forEach(element => {
            // //////console.log(element);
            if (element.AdministratorSetting) {
              element.AdministratorSetting.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            if (element.NotificationSetting) {
              element.NotificationSetting.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            if (element.SchedulePreferences) {
              element.SchedulePreferences.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            if (element.UserPreferences) {
              element.UserPreferences.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
          });



          this._commonSVC.progressLoader = false;
        }

        else {
          this._commonSVC.progressLoader = false;
        }
      })
    } catch (error) {
      this._commonSVC.progressLoader = false;
      //console.log(error)
    }

  }



  selectReminder(event) {

    // if (this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue != null) {
    //   this.notificationChipLocal = this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue.toLowerCase().split(',');

    // }

    // if (this.notificationChipLocal.indexOf(event.value.toLowerCase()) == -1) {
    //   if (this._systemSettingsSVC.settings[1].NotificationSetting[1].globalSettingName == 'AddReminder.ReminderOption' && this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue != null) {
    //     this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue += ',' + event.value;
    //   }
    // }

    let value = event.value;
    let reminders: any[] = this.NotificationSetting_Form.controls.selectreminderOption.value ?
      this.NotificationSetting_Form.controls.selectreminderOption.value.split(',') : [];

    if (reminders.length > 0) {

      if (reminders.findIndex(ele => ele.toLowerCase() === event.value.toLowerCase()) == -1) {
        reminders.push(value);
      }
    }
    else {
      reminders.push(value);
    }

    this.NotificationSetting_Form.controls.selectreminderOption.setValue(reminders.toLocaleString());

    //console.log("reminders", this.NotificationSetting_Form.controls.selectreminderOption.value);

  }

  onRemoveReminder(index) {

    // if (this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue != null) {
    //   this.notificationChipLocal = this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue.split(',');
    //   this.notificationChipLocal.splice(index, 1);

    //    this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue = this.notificationChipLocal.toString();
    // }

    let reminders: any[] = this.NotificationSetting_Form.controls.selectreminderOption.value.split(',') || [];
    reminders.splice(index, 1);

    if (reminders.length === 0) {
      this.model_reminder = '';
      this.NotificationSetting_Form.controls.selectreminderOption.setValue('');
    }
    else {
      this.NotificationSetting_Form.controls.selectreminderOption.setValue(reminders.toLocaleString());
    }

  }



  updateNotificationSetting() {
    this.is_FormSubmitted = true;

    if (this.NotificationSetting_Form.valid)
       {
         this.isLoading = true;

      this._systemSettingsSVC.settings[1].NotificationSetting[0].localSettingValue = this.NotificationSetting_Form.value.schedule_publish
      this._systemSettingsSVC.settings[1].NotificationSetting[1].localSettingValue = this.NotificationSetting_Form.value.selectreminderOption;
      // this._systemSettingsSVC.settings[1].NotificationSetting[2].localSettingValue = this.NotificationSetting_Form.value.specific_starttime;
      this._systemSettingsSVC.settings[1].NotificationSetting[3].localSettingValue = this.NotificationSetting_Form.value.alert_distance;
      // this._systemSettingsSVC.settings[1].NotificationSetting[4].localSettingValue = this.NotificationSetting_Form.value.shedstaff;
      this._systemSettingsSVC.settings[1].NotificationSetting[10].localSettingValue = this.NotificationSetting_Form.value.configuremintues;
      this._systemSettingsSVC.settings[1].NotificationSetting[11].localSettingValue = this.NotificationSetting_Form.value.alertminutes;
      // this._systemSettingsSVC.settings[1].NotificationSetting[12].localSettingValue = this.NotificationSetting_Form.value.expiredminutes;
      this._systemSettingsSVC.settings[1].NotificationSetting[13].localSettingValue = this.NotificationSetting_Form.value.isRecursive;
      this._systemSettingsSVC.settings[1].NotificationSetting[14].localSettingValue = this.NotificationSetting_Form.value.sms_notifications;
      this._systemSettingsSVC.settings[1].NotificationSetting[15].localSettingValue = this.NotificationSetting_Form.value.mail_notifications;
      this._systemSettingsSVC.settings[1].NotificationSetting[16].localSettingValue = this.NotificationSetting_Form.value.push_notification;

      let updateSettings: any[] = [];

      this._systemSettingsSVC.settings.forEach(element => {
        // //////console.log(element);
        if (element.AdministratorSetting) {
          element.AdministratorSetting.forEach(newelement => {
            updateSettings.push(newelement);
          });
        }
        if (element.NotificationSetting) {
          element.NotificationSetting.forEach(newelement => {
            updateSettings.push(newelement);
          });
        }

        if (element.UserPreferences) {
          element.UserPreferences.forEach(newelement => {
            updateSettings.push(newelement);
          });
        }
        if (element.Design) {
          element.Design.forEach(newelement => {
            updateSettings.push(newelement);
          });
        }
      });
      try {
        this._commonSVC.progressLoader = true;
        this._systemSettingsSVC.updateSettingByUserCode(this._sessionStorageSVC.get('orgCode'), updateSettings, (res) => {
          this._commonSVC.progressLoader = false;
          this.isLoading = false;
          //////console.log(res);
          if (res.responsecode == 200) {
            this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
            this._commonSVC.successMessage = true;
            this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
            //  this._commonSVC.getColors();
          } else {
            this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
            this._commonSVC.errorMessage = true;
          }
        })
      } catch (error) {
        //console.log(error)
      }
    }

  }


  subToPermissionChanged()
  {
   this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
      
     if(res.responsecode === 200)
       {
         this.hasPermission =  this._commonSVC.getBtnEventPermissions("Notification Settings");
       }
   })
  }
  
   ngOnDestroy(): void {
      this.clearObs.next(''),
      this.clearObs.complete();
    }
  


}
