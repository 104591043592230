import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { SystemSettingsService } from '../system-settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';


@Component({
  selector: 'app-design-sys-setting',
  templateUrl: './design-sys-setting.component.html',
  styleUrls: ['./design-sys-setting.component.scss'],
  providers:[SystemSettingsService]
})
export class DesignSysSettingComponent implements OnInit, OnDestroy {

  DesignSetting_Form : FormGroup;
  is_FormSubmitted : boolean = false ;

  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();
 
  constructor(
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    public _commonSVC: CommonService,
    public _systemSettingsSVC : SystemSettingsService,
  
  )
  {
     this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
    this.initDesignSetting_Form();
  }

   ngOnInit(): void {
   this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
   this.hasPermission =  this._commonSVC.getBtnEventPermissions("Design Settings"); 
   this.subToPermissionChanged();
    }


    
    initDesignSetting_Form()
    {
      this.DesignSetting_Form =  new FormGroup({
   
        'org_logo': new FormControl(null),
        // 'org_logo_upl': new FormControl(null),
  
      });
    }
  
 
  
    getAllsettings(orgCode) {
      this._commonSVC.progressLoader = true;
      try {
        this._systemSettingsSVC.getAllgetAllLocalSettingWithDefaultGlobalSettingByUserCode(orgCode).subscribe((data:any) => {
        if (data.responsecode == 200) {
          this._systemSettingsSVC.settings = data.data;
          
          let logo = this._systemSettingsSVC.settings[6].Design[14].localSettingValue
                     ? this._systemSettingsSVC.settings[6].Design[14].localSettingValue
                     : this._systemSettingsSVC.settings[6].Design[14].globalSettingValue ;

    
        // this.DesignSetting_Form.controls['org_logo_upl'].setValue(logo);
        this.DesignSetting_Form.controls['org_logo'].setValue(logo);

    

    /********************************************************************************************************************************* */
    
       this._systemSettingsSVC.settings.forEach(element => {
            // //////console.log(element);
            if (element.AdministratorSetting) {
              element.AdministratorSetting.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            if (element.NotificationSetting) {
              element.NotificationSetting.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            if (element.SchedulePreferences) {
              element.SchedulePreferences.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            if (element.UserPreferences) {
              element.UserPreferences.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
          });
    
          
    
          this._commonSVC.progressLoader = false;
        }
        
        else {
          this._commonSVC.progressLoader = false;
        }
      })
      } catch (error) {
        this._commonSVC.progressLoader = false;
        //console.log(error) 
      }
      
    }
    

    imgUpload(event) {
      ////console.log(event);
      const target = event.target;
      if (event.target.files.length > 0) {
        let allowedExtension = ['jpeg', 'jpg', 'png'];
        let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
        let isValidFile = false;
        //////console.log(fileExtension);
        for (let index in allowedExtension) {
          if (fileExtension === allowedExtension[index] && event.target.files[0].size <= 2000000) {
            isValidFile = true;
            const formData = new FormData();
            formData.append("file", event.target.files[0]);

            this._commonSVC.uploadImage(formData, (res) => {

              if(res.filename)
              {
              if (target.id == 'orglogo') {
                 
                this.DesignSetting_Form.controls['org_logo'].setValue(res.filename);
                this.updateLogoSetting();
              }
            }
            else{
              this._commonSVC.message = 'File not able to upload';
              this._commonSVC.errorMessage = true;
            }
            })
            break;
          }
        }
  
        if (!isValidFile) {
          //////console.log(isValidFile);
          this._commonSVC.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 2MB';
          this._commonSVC.errorMessage = true;
          //event.srcElement.value = '';
          event.target.value = '';
        }
      }
    }


 
    updateLogoSetting() {
      this.is_FormSubmitted = true;
  
      if (this.DesignSetting_Form.valid) {
  
        this._systemSettingsSVC.settings[6].Design[14].localSettingValue = this.DesignSetting_Form.value.org_logo ;
      
       
       let updateSettings: any[] = [];

        this._systemSettingsSVC.settings.forEach(element => {
          // //////console.log(element);
          if (element.AdministratorSetting) {
            element.AdministratorSetting.forEach(newelement => {
              updateSettings.push(newelement);
            });
          }
          if (element.NotificationSetting) {
            element.NotificationSetting.forEach(newelement => {
              updateSettings.push(newelement);
            });
          }
  
          if (element.UserPreferences) {
            element.UserPreferences.forEach(newelement => {
              updateSettings.push(newelement);
            });
          }
          if (element.Design) {
            element.Design.forEach(newelement => {
              updateSettings.push(newelement);
            });
          }
        });
  
  
        try {
          this._systemSettingsSVC.updateSettingByUserCode(this._sessionStorageSVC.get('orgCode'), updateSettings, (res) => {
            //////console.log(res);
            if (res.responsecode == 200) {
              this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
              this._commonSVC.successMessage = true;
              this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
              //  this._commonSVC.getColors();
            } else {
              this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
              this._commonSVC.errorMessage = true;
            }
          })
        } catch (error) {
          //console.log(error)
        }
      }
  
    }

    subToPermissionChanged()
    {
     this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
        
       if(res.responsecode === 200)
         {
           this.hasPermission =  this._commonSVC.getBtnEventPermissions("Design Settings");
         }
     })
    }
    
     ngOnDestroy(): void {
        this.clearObs.next(''),
        this.clearObs.complete();
      }
    


}
