import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonNotesService } from '../common-notes.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/common.service';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { SessionStorageService } from 'angular-web-storage';
import { MatDialog } from "@angular/material/dialog";
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'grouped-note-list',
  templateUrl: './grouped-note-list.component.html',
  styleUrls: ['./grouped-note-list.component.scss'],
  providers: [CommonNotesService]
})
export class GroupedNoteListComponent implements OnInit {
  orgCode: any;
  groupNoteData: any;
  baseURL:any;
  isNoteEdit: boolean;
  seletedNoteForEdit: any;
  selectedStaffIndex: any;

  protected txt_notes : string ='';
  protected chx_isPublic : boolean = false;
  protected is_Loading : boolean = false ;
  protected is_not_PreviousDate : boolean = true;

  constructor(
    public dialog: MatDialog,
    public notesService: CommonNotesService,
    public dialogRef: MatDialogRef<GroupedNoteListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonSVC : CommonService,
   private _commonAPI_SVC : CommonApiService,
  private _sessionSVC : SessionStorageService) {}

    ngOnInit() {
      this.baseURL = this.notesService.service.getSuperAdmin() + "/downloadFile/";
      this.orgCode = this._sessionSVC.get('orgCode');      
      // console.log('ModelData: ', this.data);

      if(this.data?.isNotesAdded){
        if(this.data?.date) moment(this.data?.date).isSameOrAfter(moment() , 'day') ? this.is_not_PreviousDate = true : this.is_not_PreviousDate = false ;
      } else {        
        this.is_not_PreviousDate = true;
      }
      
      if(this.data?.appointmentsProcedureCode && this.data?.date && this.orgCode){
        this.getNotesByappointmentsProcedureCode(this.data.appointmentsProcedureCode, this.data.date);
      }
      setTimeout(() => { 
        document.getElementById('note_input_2').focus();
      }, 500);
     }
     
     noteChangeEvent(event: any, index = null) {
      // console.log('NoteChangeEvent_1: ', event.entityName, index, event);
      if(event?.isNoteEdit){
        this.selectedStaffIndex = index;
        this.isNoteEdit = true;
        this.seletedNoteForEdit = event;
        this.txt_notes = event.note;
        this.chx_isPublic = event?.isPublic;
        window.document.getElementById('note_input_2').scrollIntoView();
        setTimeout(() => { 
          document.getElementById('note_input_2').focus();
        }, 500);
      }
      if(event?.isNoteDelete && event.noteEntityMappingCode){
        this.selectedStaffIndex = index;
          const dialog = this.dialog.open(ConfirmLogoutComponent, {
            width: '350px',
            data: { 
              msg: 'Are you sure you want to delete this note?'
            }
          });      
      
          dialog.afterClosed().subscribe(result => {    
            if(result && result.toLowerCase() === 'ok'){
              this.notesService.common.progressLoader = true;
              this.notesService.softDeleteByNoteEntityMappingCode(event.noteEntityMappingCode, (response) => {
                //console.log(response);
                this.notesService.common.progressLoader = false;
                if (response.responsecode == 200) {

                  if(event?.entityName === 'Job' && this.groupNoteData?.job?.noteArray){
                    var foundIndex = this.groupNoteData?.job?.noteArray?.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                    if (foundIndex > -1) { // only splice array when item is found
                      this.groupNoteData?.job?.noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                    }
                  }
                  //Code for "entityName": "Job" content End
        
                  //Code for "entityName": "Customer" content Start
                  if(event?.entityName === 'Customer' && this.groupNoteData?.customer?.noteArray){
                    var foundIndex = this.groupNoteData?.customer?.noteArray?.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                    if (foundIndex > -1) { // only splice array when item is found
                      this.groupNoteData?.customer?.noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                    } 
                  }
                  //Code for "entityName": "Customer" content End
        
                  //Code for "entityName": "Job Site" content Start
                  if((event?.entityName === 'Job Site' || event?.entityName === 'Location') && this.groupNoteData?.location?.noteArray){
                    var foundIndex = this.groupNoteData?.location?.noteArray?.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                    if (foundIndex > -1) { // only splice array when item is found
                      this.groupNoteData?.location?.noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                    }
                  }
                  //Code for "entityName": "Job Site" content End
        
                  //Code for "entityName": "Job Type" content Start
                  if((event?.entityName === 'JobType' || event?.entityName === 'Job Type') && this.groupNoteData?.jobType?.noteArray){
                    var foundIndex = this.groupNoteData?.jobType?.noteArray?.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                    if (foundIndex > -1) { // only splice array when item is found
                      this.groupNoteData?.jobType?.noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                    }
                  }
                  //Code for "entityName": "Job Type" content End
        
                  //Code for "entityName": "Staff" content Start
                  if(event?.entityName === 'Staff' && this.groupNoteData?.staff){
                    // console.log('ArryNote: ', this.selectedStaffIndex, this.groupNoteData?.staff[this.selectedStaffIndex]?.
                    //   noteArray)
                    var foundIndex = this.groupNoteData?.staff[this.selectedStaffIndex].noteArray.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                    if (foundIndex > -1) { // only splice array when item is found
                      this.groupNoteData?.staff[this.selectedStaffIndex].noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                    }        
                  }
                  //Code for "entityName": "Staff" content End

                  this.notesService.common.successMessage = true;
                  this.notesService.common.message = response.message;
                }
                else 
                {
                  this.notesService.common.errorMessage = true;
                  this.notesService.common.message = response.message;
                }
              });
            }
          });       
        }
      }

     getNotesByappointmentsProcedureCode(appointmentsProcedureCode: any, date: any){
      this.is_Loading = true;
      this.notesService.getJobNoteEntityMappingByEntityNameAndKeyAndOrganizationCode(this.orgCode, appointmentsProcedureCode, date, (response) => {
        this.is_Loading = false;
        if(response.responsecode === 200){
          // console.log('ResData: ', response);
          this.groupNoteData = response.data;          
          if(this.groupNoteData && this.groupNoteData?.job && this.groupNoteData?.job?.dateFixedFormat){
            this.groupNoteData.job.week_day = moment(this.groupNoteData.job.dateFixedFormat).format('ddd');
            this.groupNoteData.job.month_day = moment(this.groupNoteData.job.dateFixedFormat).format('D');
            this.groupNoteData.job.month_name = moment(this.groupNoteData.job.ddateFixedFormatate).format('MMM');
          }
          // console.log('groupNoteList: ', this.groupNoteData);
        } else {
          this.groupNoteData = null;
        }
     })
    }

    updateNote(noteVal: any){
      delete noteVal.isNoteEdit;
      // console.log('UpdateNote: ', noteVal?.entityName, noteVal); 
      // if(this.notes && this.notes.trim()){
      //   return true;
      // }  
      const payload ={
        noteEntityMappingCode: noteVal?.noteEntityMappingCode,
        entityName: noteVal?.entityName,
        entityUuid: noteVal?.entityUuid,
        isPublic: this.chx_isPublic,
        note: this.txt_notes,
        organizationCode: noteVal?.organizationCode
      }
      // console.log('Payload:', payload);
      // return;

      this.notesService.common.progressLoader = true;
      this._commonAPI_SVC.saveAndMapNotesEntity(payload).subscribe((res:any) =>{
        this.notesService.common.progressLoader = false;
       if(res.responsecode == 200)
        {
              //Code for "entityName": "Job" content Start
          if(noteVal?.entityName === 'Job' && this.groupNoteData?.job?.noteArray){
            var foundIndex = this.groupNoteData?.job?.noteArray?.findIndex(note => note.noteEntityMappingCode === noteVal.noteEntityMappingCode && note.entityUuid === noteVal.entityUuid);
            this.groupNoteData.job.noteArray[foundIndex].note = this.txt_notes;
            this.groupNoteData.job.noteArray[foundIndex].isPublic = this.chx_isPublic;
          }
          //Code for "entityName": "Job" content End

          //Code for "entityName": "Customer" content Start
          if(noteVal?.entityName === 'Customer' && this.groupNoteData?.customer?.noteArray){
            var foundIndex = this.groupNoteData?.customer?.noteArray?.findIndex(note => note.noteEntityMappingCode === noteVal.noteEntityMappingCode && note.entityUuid === noteVal.entityUuid);
            this.groupNoteData.customer.noteArray[foundIndex].note = this.txt_notes;
            this.groupNoteData.customer.noteArray[foundIndex].isPublic = this.chx_isPublic; 
          }
          //Code for "entityName": "Customer" content End

          //Code for "entityName": "Job Site" content Start
          if((noteVal?.entityName === 'Job Site' || noteVal?.entityName === 'Location') && this.groupNoteData?.location?.noteArray){
            var foundIndex = this.groupNoteData?.location?.noteArray?.findIndex(note => note.noteEntityMappingCode === noteVal.noteEntityMappingCode && note.entityUuid === noteVal.entityUuid);
            this.groupNoteData.location.noteArray[foundIndex].note = this.txt_notes;
            this.groupNoteData.location.noteArray[foundIndex].isPublic = this.chx_isPublic;
          }
          //Code for "entityName": "Job Site" content End

          //Code for "entityName": "Job Type" content Start
          if((noteVal?.entityName === 'JobType' || noteVal?.entityName === 'Job Type') && this.groupNoteData?.jobType?.noteArray){
            var foundIndex = this.groupNoteData?.jobType?.noteArray?.findIndex(note => note.noteEntityMappingCode === noteVal.noteEntityMappingCode && note.entityUuid === noteVal.entityUuid);
            this.groupNoteData.jobType.noteArray[foundIndex].note = this.txt_notes;
            this.groupNoteData.jobType.noteArray[foundIndex].isPublic = this.chx_isPublic;
          }
          //Code for "entityName": "Job Type" content End

          //Code for "entityName": "Staff" content Start
          if(noteVal?.entityName === 'Staff' && this.groupNoteData?.staff){
            // console.log('ArryNote: ', this.selectedStaffIndex, this.groupNoteData?.staff[this.selectedStaffIndex]?.
            //   noteArray)
            var foundIndex = this.groupNoteData?.staff[this.selectedStaffIndex]?.
            noteArray.findIndex(note => note.noteEntityMappingCode === noteVal.noteEntityMappingCode && note.entityUuid === noteVal.entityUuid);
            this.groupNoteData.staff[this.selectedStaffIndex].noteArray[foundIndex].note = this.txt_notes;
            this.groupNoteData.staff[this.selectedStaffIndex].noteArray[foundIndex].isPublic = this.chx_isPublic;         
          }
          //Code for "entityName": "Staff" content End

          this.txt_notes = null;
          this.seletedNoteForEdit = null;
          this.chx_isPublic = false;
          this.isNoteEdit = false;
          this.selectedStaffIndex = null;
          this.notesService.common.message = res.message;
          this.notesService.common.successMessage = true;
        }
        else{
            this.notesService.common.message = res.message;
             this.notesService.common.errorMessage = true;
        }  
      });   
    }


  saveNote() {
    if (this.txt_notes && this.txt_notes.trim()) {
      const payload = {
        "entityName": "JobProcedures",
        "entityUuid": this.data?.appointmentsProcedureCode,
        "isPublic": this.chx_isPublic,
        "note": this.txt_notes,
        "organizationCode": this.orgCode
      }

      this._commonSVC.progressLoader = true;

      this._commonAPI_SVC.saveAndMapNotesEntity(payload).subscribe((res: any) => {
        this._commonSVC.progressLoader = false;
        if (res.responsecode == 200) {
          this._commonSVC.message = res.message;
          this._commonSVC.successMessage = true;
          this.resetNoteForm();
          this.getNotesByappointmentsProcedureCode(this.data.appointmentsProcedureCode, this.data.date);
        }
        else {
          this._commonSVC.message = res.message;
          this._commonSVC.errorMessage = true;
        }

      })
    }
  }


  resetNoteForm() {
    this.txt_notes = '';
    this.chx_isPublic = false;
  }

}
