

    <!-- <div class="container-fluid" [class.backdropBackground]="is_backdropBackground"> -->
      <div class="container-fluid">
        <div class="row justify-content-around">
            <div class="col-lg-4 col-md-12 col-sm-12 card card-status scheduling-card text-capitalize">
                <div class="float-start header-name mb-2">{{'dashboard.labels.Scheduling Status' | translate}}</div>
                  <!-- <app-dashboard-cards-component CardTitle="Pending" [CardValue]="ObjStattusCount['pending']" svgFilename="pending.svg" TextClass="status-pink"  ></app-dashboard-cards-component> -->
                <div class="d-flex justify-content-between mb-2">
                    <ng-container *ngFor="let status of statusCount"> 
                     <div class="col-lg-6 col-md-6 card card-status-inner status-pink" [class.border]="clickedStatus === 'To Schedule' ? true : false" *ngIf="status.status === 'pending'" (click)="getStatus(status.status, status.displayText)">
                            <div class="d-flex">
                                <div class="d-flex icon-round me-2">
                                    <img src="../../../assets/icons/svg/pending.svg" width="40" alt="">
                                </div>
                                <div>
                                    <h3 class="count">{{ status.count }}</h3>
                                    <h5 class="status">{{status.displayText}}</h5>
                                    
                                </div>
                            </div>
                    </div>
                    <div class="col-lg-6 col-md-6 card card-status-inner status-yellow" [class.border]="status.status === clickedStatus" *ngIf="status.status === 'notified'" (click)="getStatus(status.status, status.displayText)">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/assigned.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{ status.count }}</h3>
                                    <h5 class="status">{{status.displayText}}</h5>
                                
                            </div>
                        </div>
                    </div>
                    </ng-container>
                  </div>
                    <div class="d-flex justify-content-between">
                        <ng-container *ngFor="let status of statusCount">
                    <div class="col-lg-6 col-md-6 card card-status-inner status-green" [class.border]="status.status === clickedStatus" *ngIf="status.status === 'accepted'" (click)="getStatus(status.status, status.displayText)">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/accepted.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{ status.count }}</h3>
                                <h5 class="status">{{status.displayText}}</h5>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 card card-status-inner status-purple" [class.border]="status.status === clickedStatus" *ngIf="status.status === 'rejected'" (click)="showSelectedStatusDetails('Declined')">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/cancelled.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{ status.count }}</h3>
                                <h5 class="status">Declined</h5>
                                <!-- <h5 class="status">{{status.status}}</h5> -->
                                
                            </div>
                        </div>
                    </div>
                </ng-container>
                </div>
                 
                 
                 
                <!-- <div class="d-flex justify-content-between">
                    <div class="col-lg-4 col-md-6 card card-status-inner status-green">
                            <div class="d-flex">
                                <div class="d-flex icon-round me-2">
                                    <img src="../../../assets/icons/svg/completed.svg" width="40" alt="">
                                </div>
                                <div>
                                    <h3 class="count">01</h3>
                                    <h5 class="status">Completed</h5>
                                    
                                </div>
                            </div>
                    </div>
                    <div class="col-lg-4 col-md-6 card card-status-inner status-pink">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/cancelled.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">01</h3>
                                <h5 class="status">Cancelled</h5>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 card card-status-inner status-purple">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/scheduled.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">00</h3>
                                <h5 class="status">Scheduled</h5>
                                
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="col-lg-5 col-md-12 col-sm-12 card todays-status card-status">
                <div class="float-start header-name mb-2">{{'dashboard.labels.Todays Status' | translate}}</div>
                <!-- <div class="container"> -->
                  <div>
                <div class="row g-2">
                    <ng-container *ngFor="let shift of shiftStatusCount; let idx = index">
                      <!-- <div class="col-4"  (click)="getShiftList(shift, idx)"> -->
                        <!-- <div  class="card card-status-inner status-green"> -->
                          <div class="col-4"  (click)="showSelectedStatusDetails(shift?.name)">
                          <div  class="card card-status-inner {{shift.name.replace(' ', '')}}">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                              <ng-template [ngIf]="shift.name === 'Scheduled'">
                                <img src="../../../assets/icons/svg/scheduled.svg" alt="">
                              </ng-template>

                              <ng-template [ngIf]="shift.name === 'Clocked-In'">
                                <img src="../../../assets/icons/svg/checked-in.svg" width="40" alt="">
                              </ng-template>

                              <ng-template [ngIf]="shift.name === 'Not Clocked-In'">
                                <img src="../../../assets/icons/svg/checked-out.svg" alt="">
                              </ng-template>

                              <ng-template [ngIf]="shift.name === 'Not Filled'">
                                <img src="../../../assets/icons/svg/cancelled.svg" alt="">
                              </ng-template>

                              <ng-template [ngIf]="shift.name === 'Late'">
                                <img src="../../../assets/icons/svg/late.svg" alt="">
                              </ng-template>
                              
                              <ng-template [ngIf]="shift.name === 'Clocked-Out'">
                                <img src="../../../assets/icons/svg/checked-out.svg" alt="">
                              </ng-template>
                                
                            </div>
                            <div>
                                <h3 class="count">{{shift?.value}}</h3>
                                <h5 class="status">{{shift.name}}</h5>
                                
                            </div>
                        </div>
                        </div>
                </div>


                    <!-- <div *ngIf="shift?.name === 'Not Checked-In'" class="col-6" (click)="getShiftList(shift, idx)">
                        <div  class="card card-status-inner status-pink">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/not-checked-in.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{shift?.value}}</h3>
                                <h5 class="status">Not Checked-In</h5>
                                
                            </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-6" *ngIf="shift?.name === 'Checked-Out'" (click)="getShiftList(shift, idx)">
                        <div class="card card-status-inner status-blue">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/checked-out.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{shift?.value}}</h3>
                                <h5 class="status">Checked-Out</h5>
                                
                            </div>
                        </div>
                        </div>
                    </div>

                    <div *ngIf="shift?.name === 'Late or Cancelled'" class="col-6" (click)="getShiftList(shift, idx)">
                        <div class=" card card-status-inner status-grey">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/late.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{shift?.value}}</h3>
                                <h5 class="status">Late</h5>
                                
                            </div>
                        </div>
                        </div>
                    </div> -->

                    </ng-container>
                   
                    <!-- <div class="col-md-6 card card-status-inner status-pink">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/not-checked-in.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">01</h3>
                                <h5 class="status">Not Checked-In</h5>
                                
                            </div>
                        </div>
                    </div> -->
                </div>
                </div>

                <!-- <div class="d-flex justify-content-between">
                    <div class="col-md-6 card card-status-inner status-blue">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/checked-out.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">03</h3>
                                <h5 class="status">Checked-Out</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class=" card card-status-inner status-grey">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/late.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">00</h3>
                                <h5 class="status">Late</h5>
                                
                            </div>
                        </div>
                    </div>

                    

                    

                </div> -->
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 card card-status operational-status">
                <div class="float-start header-name mb-2">{{'dashboard.labels.Operational Status' | translate}}</div>
                <div class="d-flex justify-content-between mb-2">
                    <div class="col-md-6 card card-status-inner status-green">
                            <div class="d-flex">
                                <div class="d-flex icon-round me-2">
                                    <img src="../../../assets/icons/svg/job-booked.svg" width="40" alt="">
                                </div>
                                <div>
                                    <h3 class="count">{{operationalStatusList?.jobBooked}}</h3>
                                    <h5 class="status">{{'dashboard.labels.Jobs Booked' | translate}}</h5>
                                    
                                </div>
                            </div>
                    </div>
                    <div class="col-md-6 card card-status-inner status-blue">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/job-complete.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{operationalStatusList?.jobCompleted}}</h3>
                                <h5 class="status">{{'dashboard.labels.Jobs Completed' | translate}}</h5>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="col-md-6 card card-status-inner status-pink">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/hours-booked.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{operationalStatusList?.hoursBooked}}</h3>
                                <h5 class="status"> {{'dashboard.labels.Hours Booked' | translate}}</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class=" card card-status-inner status-grey">
                        <div class="d-flex">
                            <div class="d-flex icon-round me-2">
                                <img src="../../../assets/icons/svg/hours-complete.svg" alt="">
                            </div>
                            <div>
                                <h3 class="count">{{operationalStatusList?.hoursCompleted}}</h3>
                                <h5 class="status">{{'dashboard.labels.Hours Completed' | translate}}</h5>
                                
                            </div>
                        </div>
                    </div>

                    

                    

                </div>
            </div>
        </div>

        <!-- <div class="row col-md-12 col-sm-12 card shortcuts  card-status my-3 w-100"> -->

          <!-- <div class="row justify-content-around card shortcuts  card-status my-3">
           
            <div class="row card-justify pe-0 card-justify">

                <ng-template [ngIf]="permissionFor_NewJobShortCut.addPermission === true">
                <div class="col-md-2 col-sm-6 card-shortcut-inner status-grey mr-2" (click)="openShortcutModal(true, 'New Job', newJobTempRef)">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center pt-2 ">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/new-job.svg" alt="">
                            </div>
                            <div class="mt-2">
                                <h5 class="status">New Job</h5>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>

                <ng-template [ngIf]="permissionFor_NewShiftShortCut.addPermission === true">
                <div class="col-md-2 card-shortcut-inner  status-grey" (click)="openShortcutModal(true, 'New Shift', null)">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center  pt-2">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/new-shift.svg" alt="">
                            </div>
                            <div class="mt-2">
                                <h5 class="status">New Shift</h5>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>

                <ng-template [ngIf]="permissionFor_CustControlShortCut.addPermission === true">
                <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'New Location', newLocationTempRef)">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center  pt-2">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/new-location.svg" alt="">
                            </div>
                            <div class="mt-2">
                                <h5 class="status">New {{ 'main.labels.Job Site' | translate }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>

                <ng-template [ngIf]="permissionFor_CustControlShortCut.addPermission === true">
                <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'New Contact', null)">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center  pt-2">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/new-contact.svg" alt="">
                            </div>
                            <div class="mt-2">
                                <h5 class="status">New Contact</h5>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>

                <div class="col-2 card-shortcut-inner status-grey" (click)="getStatus('pending', 'pending')">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center  pt-2">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/post-notice.svg" alt="">
                            </div>
                            <div class="mt-2">
                                <h5 class="status">Notify</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-2 card-shortcut-inner status-grey" routerLink="/dashboard" [queryParams]="{chat: true}">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center  pt-2">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/send-chat.svg" alt="">
                                <span *ngIf="common?.globalChatCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                                  {{common?.globalChatCount}}
                                </span>
                            </div>
                            <div class="mt-2">
                                <h5 class="status">
                                  <span>Chat</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'Approve Leave', approveLeaveTempRef)">
                    <div class="text-center">
                        <div class="assigned const-height align-items-center  pt-2">
                            <div class="icon-round">
                                <img src="../../../assets/icons/svg/approve-leave.svg" alt="">
                                <span *ngIf="common?.globalLeaveCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                                  {{common?.globalLeaveCount}}
                                </span>
                            </div>
                            <div class="mt-2">
                                <h5 class="status">Approve Leave</h5>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-2 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'Missed Clock-Out', missedClockOut_TempRef)">
                  <div class="text-center">
                      <div class="assigned const-height align-items-center  pt-2">
                          <div class="icon-round">
                              <img src="../../../assets/icons/svg/checked-out.svg" alt="">
                              <span *ngIf="missedClockOut_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                                {{missedClockOut_Count}}
                              </span>
                          </div>
                          <div class="mt-2">
                              <h5 class="status">Missed Clock-Out</h5>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-2 card-shortcut-inner status-grey mr-2" (click)="openShortcutModal(true, 'RequestedNewJobs', newJobRequestList_TempRef)">
                <div class="text-center">
                    <div class="assigned const-height align-items-center  pt-2">
                        <div class="icon-round">
                          <img src="../../../assets/icons/svg/new-job.svg" alt="">
                            <span *ngIf="newJobRequest_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                              {{newJobRequest_Count}}
                            </span>
                        </div>
                        <div class="mt-2">
                            <h5 class="status">Requested Jobs</h5>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template [ngIf]="permissionFor_NewJobShortCut.addPermission === true">
              <div class="col-md-2 col-sm-6 card-shortcut-inner status-grey" (click)="openShortcutModal(true, 'CancelJobApprovelRequest', CancelJobApprovelRequest_TempRef)">
                  <div class="text-center">
                      <div class="assigned const-height align-items-center pt-2 ">
                          <div class="icon-round">
                              <img src="../../../assets/icons/svg/cancelled.svg" alt="">
                              <span *ngIf="requestCancel_Count > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                                {{requestCancel_Count}}
                              </span>
                          </div>
                          <div class="mt-2">
                              <h5 class="status">Request Cancel</h5>
                          </div>
                      </div>
                  </div>
              </div>
              </ng-template>

            </div>
        </div> -->
          
        <div class="row my-2">
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="card card-status mb-2">
                <chat-log></chat-log>
              </div>

              <div class="card card-status">
               <activity-log></activity-log>
               </div>

            </div>

            <div class="col-lg-7 col-md-7 col-sm-12 pe-0">
              <div class=" card card-status">
            <!-- <div class="col-lg-7 col-md-7 col-sm-12 card card-status"> -->
                     <app-dashboard-todays-job></app-dashboard-todays-job>
                     </div>
              </div>
        </div>

        


    </div>


    <!-- /*Mat-drawer  Calendar*/ -->

    <!-- <mat-drawer-container class="drawer-container">
        <mat-drawer #drawer class="full-width-drawer" position="end" mode="over"> -->
          <ng-template #viewDashboardStatus>
         <div class="col-md-6 text-end">
           <!-- <button class="btn-close cursor-pointer" (click)="closeDrawer()"></button> -->
           <button class="btn-close cursor-pointer" [mat-dialog-close]="true" cdkFocusInitial></button>
           
       </div>
      <div class=" row  mb-3 me-4">
       <div class="col-md-12 col-sm-12 primary-color">
        <div class="calendar-nav container cal">
      <div class="row">
           <div class="col-8 pe-2 dt-yr p-0 fw-bold pointer"
             [title]="Calendar_header">
             
           <i class="fa fa-calendar ps-0 pe-1 cal-color" aria-hidden="true"></i>
           <!-- {{navDate.format('MMMM YYYY')}} -->
           {{Calendar_header}}
         </div>
         
           <div class="col-3 p-0 text-end">
             <div class="calendar-nav-previous-month dt-yr">
           
    
                <!-- /* buttons for getting next or previous 7 days  */ -->
               <span class="resp-arrow">
                 <span class="pe-3" title="Previous Week"><i class="material-icons pointer"
                     (click)="getPreviousSevenDays()">keyboard_arrow_left</i></span>
                 <span  title="Next Week"><i class="material-icons pointer"
                     (click)="getNextSevenDays()">keyboard_arrow_right</i></span>
               </span>
    
             </div>
           </div> 
          </div>
         </div>
 
         
         <div class="calendar col-12 col-md-12 col-sm-12 w-100">
           <div class="calendar-container w-100">
             <div class="calendar-header w-100">
              <ng-container *ngFor="let day of gridArrNew">
               
                <div [ngClass]="(is_allSevenDaysData || ShowSelectedStart.split('-')[2]<=day.value
                 && ShowSelectedEnd.split('-')[2]>=day.value) 
                 ?'eventClass':'inactive'"
                 class="new-calendar-days {{day.highlight}} {{day.dataPercent}}
                 cursor-pointer" (click)="selectDay(day)">
                 <!-- class="calendar-date" -->
               <!-- <div class="calendar-date"> -->
 
                <div class="day-name">
                 {{day.dayName}}
               </div>
               <div class="day">
                {{day.value}}
              </div>
               </div>
 
               
                <!-- <div class="day-count {{day?.jobsCount >=0 ? day.dataPercent ? day.dataPercent : 'c7c7c7'  : ''}}"> -->
                  <div class="day-count {{day?.jobsCount > 0 ? day.dataPercent ? day.dataPercent : 'c7c7c7'  : ''}}">
                  {{day?.jobsCount}}
                </div>


               </ng-container>
    
             </div>
             <!-- <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
             <div class="calendar-body">
              <ng-container *ngFor="let day of gridArrNew">
                 <div [ngClass]="(is_allSevenDaysData || ShowSelectedStart.split('-')[2]<=day.value
                 && ShowSelectedEnd.split('-')[2]>=day.value) 
                 ?'eventClass':'inactive'"
                 class="calendar-date {{day.highlight}} {{day.dataPercent}}
                 cursor-pointer" (click)="selectDay(day)">
                  
                    <div class="row">
                    <div class="col-md-7">{{ day.value}}</div>
                    <div class="col-md-3 jobs-count">{{day?.jobsCount}}</div>
                   </div>
               </div>
            
    
             </ng-container>
             </div>
             <mat-divider class="mt-3 mb-3 ms-0"></mat-divider> -->
           </div>
    
         </div>
       </div>
     
       </div>
       <mat-divider class="mt-3 mb-3 ms-0"></mat-divider>
       <div class="row appointement-schedule">
         <div class="w-95 ps-3">
            <div class="row mb-3">
                   <div class="col-md-6 align-self-center">
                     <h4 class="mb-0 text-capitalize">{{clickedStatus}} {{ 'dashboard.labels.Appointments' | translate }}</h4>
                   </div>
                 
                 </div>
     
                 <div class="row">
                   
                   <div class="w-100 mb-3">
           
                   <!-- <div class="d-flex flex-row mb-3 border-bottom">
                     <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
                      <input autocomplete="off" type="text" placeholder="Search by customer name or staff name"
                       class="searchfilter form-control" name="search-name" [(ngModel)]="searchTerm" (keyup)="searchFromJob()">
                   </div> -->
           
                    <app-new-job-card 
                    [dataSource]="appointmentfilter"
                     [is_Loading]="enableLoading"
                     [status]="clickedStatus"
                     (updateParent)="RefreshJobList($event)"
                     (trigger_nextSevenDays)="getNextSevenDays()"
                     (trigger_previousSevenDays)="getPreviousSevenDays()"
                     ></app-new-job-card>
                   

                   
                   <!-- <div class="popup-viewport-body" *ngIf="appointmentfilter.length> 0" clickOutside (clickOutside)="showPopup = ''">
                     <div *ngFor="let newData of appointmentfilter; let i=index;" class="example-item">
                       <mat-card class="mx-auto card-appointment mt-4 mb-4">
                         <div class="row icon-round">
                           <div class="col-md-1">
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'pending'">
                               hourglass_empty
                             </i>
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'assigned'">
                               done
                             </i>
                             <i class="material-icons icon-appointment assigned" *ngIf="newData.status
                             == 'notified'">
                                done
                              </i>
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'accepted'">
                               done
                             </i>
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'inprogress'">
                               more_horiz
                             </i>
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'completed'">
                               done
                             </i>
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'expired'">
                               history
                             </i>
                             <i class="material-icons icon-appointment {{newData.status}}" *ngIf="newData.status
                                     == 'cancelled'">
                               close
                             </i>
                             <i class="material-icons icon-appointment {{newData.status}}"
                               *ngIf="newData.status == 'late or cancelled'">
                               assignment_late
                             </i>
                           </div>
     
                           <div class="col-md-3 default">
                             <small>{{ 'dashboard.labels.Patient Name' | translate }}</small>
                             <p>{{newData.customerName}}</p>
                           </div>
                           <div class="col-md-4">
                             <small>{{ 'dashboard.labels.Patient Address' | translate }}</small>
                             <p *ngIf="newData.customerAddress">{{newData.customerAddress}}</p>
                             <p *ngIf="!newData.customerAddress">{{ 'dashboard.labels.Not Available' | translate }}</p>
           
                           </div>
                          
                           <div class="col-md-4" *ngIf="newData.status === 'pending' || newData.status === 'notified'">
                            <button *ngIf="!newData.isPassedEndTime" class="me-3"  mat-raised-button color="primary" (click)="openAssignPopUp(newData)">Assign</button>
                            <button *ngIf="!newData.isPassedEndTime" mat-raised-button color="warn"   (click)="openNotifyPopUp(newData)">Notify</button>
                             </div>
     
                           <div class="edit-open col-md-10" *ngIf="newData.appointmentsDurationCode == showPopup">
                             <ul>
                               <li (click)="route('update-appointment',newData.appointmentsDurationCode,newData.appointmentsCode)">
                                 <mat-icon>edit</mat-icon>
                                 <p>{{ 'dashboard.labels.Edit this appointment' | translate }}</p>
                               </li>
                               <li
                                 (click)="route('update-appointment-series',newData.appointmentsDurationCode,newData.appointmentsCode)">
                                 <mat-icon>filter_none</mat-icon>
                                 <p>{{ 'dashboard.labels.Edit this appointment in series' |
                                   translate }}</p>
                               </li>
                               <li
                                 (click)="assignAppointment(newData.appointmentsDurationCode,newData.organizationBasedProcedureDetailsList.length)">
                                 <mat-icon>people</mat-icon>
                                 <p *ngIf="newData.workerName == null">{{
                                   'Jobs.messages.Assign Technician' | translate }}
                                 </p>
                                 <p *ngIf="newData.workerName != null">{{
                                   'dashboard.labels.Change Technician' | translate }}</p>
                               </li>
           
                               <li *ngIf="newData.workerName != null"
                                 (click)="unAssignAppointment(newData.appointmentsDurationCode)">
                                 <mat-icon>person</mat-icon>
                                 <p>{{'dashboard.labels.Unassign Technician' | translate }}</p>
                               </li>
           
                               <li (click)="cancelAppointment(newData.appointmentsDurationCode)">
                                 <mat-icon>close</mat-icon>
                                 <p>{{ 'dashboard.labels.Cancel Appointment' | translate }}</p>
                               </li>
                               <li *ngIf="newData.workerName != null" (click)="showRoute(newData)">
                                 <mat-icon>where_to_vote</mat-icon>
                                 <p>{{ 'dashboard.labels.Show Route' | translate }}</p>
                               </li>
                             </ul>
                           </div>
                         </div>
                         <hr class="p-0">
                         <div class="row d-flex justify-content-between">
     
                           <div class="col-md-3 default">
                             <small>{{ 'dashboard.labels.Technician Name' | translate }}</small>
                             <p *ngIf="newData.workerName">{{newData.workerName}}</p>
                             <p *ngIf="!newData.workerName">{{ 'dashboard.labels.Not Available'
                               | translate }}</p>
                           </div>
     
                           <div class="col-md-2 default">
                              <small>Start Time</small>
                             <p>{{newData.appointmentStartDate.split(' ')[1]}}
                               {{newData.appointmentStartDate.split(' ')[2]}}</p>
                           </div>
    
                           <div class="col-md-2 default">
                             <small>End Time</small>
                            <p>{{newData.appointmentEndDate.split(' ')[1]}}
                              {{newData.appointmentEndDate.split(' ')[2]}}</p>
                          </div>
      
                           <div class="col-md-1 cursor-default ps-0 d-flex align-self-center mb-2" *ngIf="newData.workerName
                                   && newData.status == 'completed' && newData.actualStartTime">
                             <mat-icon class="clock-time" title="Actual Schedule Time">access_time</mat-icon>
                           </div>
            
                           <div class="col-md-1 p-0">
                             <span *ngIf="newData.notes || newData.patientRecurringNotes ||
                                     newData.comments">
                               <img class="cursor-pointer mt-2 notes-tab-view" src="/assets/notes_icon.png" title="Notes"
                                 (click)="getNotes(newData)">
                             </span>
                             <span *ngIf="!newData.notes && !newData.patientRecurringNotes &&
                                     !newData.comments">
           
                             </span>
                           </div>
           
                           <div class="col-md-4 dis-img text-end pe-0">
           
                             <div class="me-0 pe-0">
                               <div [ngClass]="i <= 3 && procImg.procedureIcon?'ms-1 mb-2 me-2':''" *ngFor="let procImg of
                                       newData.organizationBasedProcedureDetailsList; index as i">
                                 <img (click)="getProcedureDetails(procImg,newData.notes)" class="img-icon cursor-pointer"
                                   alt="No Image" *ngIf="i <= 3 && procImg.procedureIcon" title="{{procImg.procedureName}}" [src]="dashboarService.service.getSuperAdmin() + '/downloadFile/' +
                                         procImg.procedureIcon" />
                                 <img alt="No Image" *ngIf="!procImg.procedureIcon"
                                   src="../../../../assets/common_images/profile.jpg" />
                               </div>
                             </div>
                             <div class="ps-1 img-data" *ngIf="newData.organizationBasedProcedureDetailsList.length> 4"
                               (click)="showImage(newData)">
                               <div class="img-count cursor-pointer">
                                 {{newData.organizationBasedProcedureDetailsList.length - 4}}
                               </div>
           
                             </div>
           
           
                             <div class="img-open" *ngIf="newData.appointmentsDurationCode ==
                                     showImg">
                               <div class="img-icon me-0 mt-1">
                                 <div *ngFor="let procImg of
                                         newData.organizationBasedProcedureDetailsList; index as i">
                                   <img (click)="getProcedureDetails(procImg,newData.notes)" alt="No Image"
                                     class="me-2 cursor-pointer" *ngIf="i >= 4 && procImg.procedureIcon "
                                     title="{{procImg.procedureName}}" [src]="dashboarService.service.getSuperAdmin() + '/downloadFile/' +
                                         procImg.procedureIcon" />
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
           
           
           
                       </mat-card>
                     </div>
                   </div> -->


                   <!-- <div *ngIf="appointmentfilter.length == 0 && !enableLoading" class="mt-4">
                     {{ 'dashboard.labels.No appointments are present' | translate }}.
                   </div> -->
           
                   <!-- <ng-template [ngIf]="enableLoading"> 
                   <div class="row" class="mt-5">
                    <div class="col-lg-4"></div>
                    <div class="col-lg-8">
                      <div class="mb-3">
                    <mat-spinner></mat-spinner>
                      </div>
                      <p>Please Wait ...</p>
                  </div>
                  </div>
              </ng-template> -->
    
                 </div>
               </div>
    
         </div>
       </div>
      </ng-template>
       <!-- </mat-drawer>
       </mat-drawer-container> -->

    <!-- /*mat-dreawer Ends*/ -->

    
   <ng-template #notifypopup>
    <!-- <mat-card>
      <mat-card-content> -->
        <div class="row  ">
          <div class="col-md-6 align-self-center">
            <h6 class="dialog-header pt-2">Send Notification</h6>
          </div>
          <div class="col-md-6">
            <div class="row justify-content-end">
              <button class="btn-close cursor-pointer" (click)="resetNotifyPopup()"></button>
            </div>
          </div>
        </div> 
  <div class="row">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width custom-search">
      <mat-label>Distribution</mat-label>
    <mat-select [(ngModel)]="selectedDistribution" multiple>
      <mat-option>
      <ngx-mat-select-search  [placeholderLabel]="'Search Here...'"  [noEntriesFoundLabel]="'Record not found'"
      [formControl]="distributionSeachInput"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of distributionList_Filter"  [value]="option.distributionCode">
        {{option.distributionName}}
      </mat-option>
      <!-- <mat-error>This field is required</mat-error> -->
    </mat-select>
     
    </mat-form-field>
  </div>

  <div class="row">
   <div class="col-lg-12 text-center">
    <p><strong>OR</strong></p>
   </div>
  </div>

  <div class="row mt-3 spinner">
    <ng-container *ngIf="eligibleStaffsList.length > 0; else noData">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width custom-search">
      <mat-label>Staff</mat-label>
    <mat-select [(ngModel)]="selectedSkills" multiple>
      <mat-option>
      <ngx-mat-select-search  [placeholderLabel]="'Search Here...'"  [noEntriesFoundLabel]="'Record not found'"
      [formControl]="staffSeachInput"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of filtered_eligibleStaffsList"  [value]="option.worker.workerCode">
        {{option.worker.name}}
      </mat-option>
      </mat-select>
     </mat-form-field>
  </ng-container>
  <ng-template #noData>
    <div class="row d-flex align-items-center" *ngIf="isLoading">
     <div class="col-lg-2 text-end">
      <mat-spinner></mat-spinner>
    </div>
    <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
    </div>
    <div *ngIf="!isLoading">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width custom-search">
      <mat-label>Staff</mat-label>
    <mat-select>
      <mat-option disabled>No Staff Available</mat-option>
    </mat-select>
    </mat-form-field>
  </div>
  </ng-template>
  </div>

  <div class="row mt-3">
    <div class="col-lg-12 text-end">
      <button mat-raised-button class="me-3" color="primary" (click)="sendNotification()">Send Notification</button>
      <button mat-button (click)="resetNotifyPopup()"   >Cancel</button>
      
    </div>
  </div>
 
   </ng-template>

<!-- /* Assign Popup start */ -->

<ng-template #assign_popup>
  <!-- <mat-card>
    <mat-card-content> -->
      <div class="row  ">
        <div class="col-md-6 align-self-center">
          <h6 class="dialog-header pt-2">Assign Job</h6>
        </div>
        <div class="col-md-6">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" (click)="resetNotifyPopup()"></button>
          </div>
        </div>
      </div> 
          
        <div class="row">
          <ul class="poupup-content" *ngIf="filtered_eligibleStaffsList.length > 0">
           <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedStaff">
              <li class="mt-2" *ngFor="let element of filtered_eligibleStaffsList">
                <div class="row">
                  <div class="col-md-1 text-center align-self-center p-0">
                    <mat-radio-button class="ms-3" [value]="element.worker"></mat-radio-button>
                  </div>
                  <div class="col-md-11 font-card">
                    <mat-card class="example-card">
                      <mat-card-content> 
                      <div class="row">
                        <div class="col-md-2">
                          <img class="staff-image me-2" *ngIf="element.worker.workerLogo" #imageUploaded [alt]="element.worker.name"
                            [src]="dashboarService.service.getSuperAdmin() + '/downloadFile/' + element.worker.workerLogo" />
                          <img *ngIf="!element.worker.workerLogo" [src]="'assets/common_images/profile.jpg'" class="staff-image  me-2">
                        </div>
                        <div class="col-md-5">
                          <p class="name mb-0 ms-2">{{element.worker.name}}</p>
                          <small class="email mb-0 ms-2">{{element.worker.email}}</small>
                        </div>
                        <!-- <div class="col-md-3">
                          <small class="name mb-0">{{ 'common.labels.Distance' | translate}}</small>
                          <p class="name mb-0">{{worker.distanceText}} </p>
                        </div>
                        <div class="col-md-3">
                          <small class="name mb-0">{{ 'common.labels.Drive Time' | translate}} </small>
                          <p class="name mb-0">{{worker.durationText}} </p>
                        </div> -->
                      </div>
                      <!-- <hr>
                      <div class="row">
                        <div class="col-md-6">
                            <small class="name mb-0">{{ 'common.labels.No of appointments (Daily-Weekly)' | translate}} </small>
                            <p class="name mb-0">{{ worker.scheduledAppointmentDaily }}/{{worker.targetAppointmentDaily}} - {{worker.scheduledAppointmentWeekly}}/{{worker.targetAppointmentWeekly}}</p>
                        </div>
                        <div class="col-md-6">
                            <small class="name mb-0">{{ 'common.labels.No of Schedules (Daily-Weekly)' | translate}} </small>  
                            <p class="name mb-0">{{worker.scheduledHoursDaily}}/{{worker.targetHoursDaily}} - {{worker.scheduledHoursWeekly}}/{{worker.targetHoursWeekly}}</p>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-md-12">
                          <small class="name mb-0">{{ 'common.labels.Skills' | translate}} </small> <br>
                          <span *ngFor="let skill of worker.skillMasterList;let i = index">
                            {{ skill.skillName}}
                            <span *ngIf="worker.skillMasterList.length-1!=i"> , </span> </span>
                        </div>
                      </div>   -->
                    </mat-card-content>
                    </mat-card>
                     
                  </div>
               
                </div>
              </li>
            </mat-radio-group>
      
          </ul>
          
          <div class="row d-flex align-items-center spinner" *ngIf="isLoading && filtered_eligibleStaffsList.length === 0">
            <div class="col-lg-1 text-end">
             <mat-spinner></mat-spinner>
           </div>
           <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
           </div>

          <div class="row" *ngIf="!isLoading && filtered_eligibleStaffsList.length === 0">
           <div>
            <span class="text-danger">No Staff Available</span>
           </div>
          </div>
        </div>

 
<div class="row mt-3">
  <div class="col-lg-12 text-end">
    <button class="me-3"  mat-button (click)="resetNotifyPopup()">Cancel</button>
    <button mat-raised-button color="primary" (click)="AssignStaff()">Assign</button>
   
  </div>
</div>

 </ng-template>

<!-- /* Assign popup end*/ -->

<!-- New Job Popup Start -->
  <ng-template #newJobTempRef>
    <div class="row">
       <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">New Job</h6>
       </div>
       <div class="col-md-6">
         <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
         </div>
       </div>
     </div> 
 
     <div class="row shortcutPopupScrollBody">
      <app-new-job [isShortcutModalOpen]="true"></app-new-job>
     </div>
   </ng-template>
   <!-- New Job Popup End -->

   <!-- New Location Popup Start -->
  <ng-template #newLocationTempRef>
    <div class="row  ">
       <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">New {{ 'main.labels.Job Site' | translate }}</h6>
       </div>
       <div class="col-md-6">
         <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
         </div>
       </div>
     </div> 
 
     <div class="row shortcutPopupScrollBody">
      <add-location [isShortcutModalOpen]="'true'"></add-location>
     </div>
   </ng-template>
   <!-- New Location Popup End -->

  <!-- Approve Leave Popup Start -->
  <ng-template #approveLeaveTempRef>
    <div class="row  ">
       <div class="col-md-6 align-self-center">
         <h6 class="dialog-header pt-2">Approve Leave</h6>
       </div>
       <div class="col-md-6">
         <div class="row justify-content-end">
           <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
         </div>
       </div>
     </div> 
 
     <div class="row shortcutPopupScrollBody">
      <app-leave [isShortcutModalOpen]="'true'"></app-leave>
     </div>
   </ng-template>
   <!-- Approve Leave Popup End -->


    <ng-template #shiftDetailPopup>
  
        <div class="row mb-4">
          <div class="col-md-6 align-self-center">
            <h4 class="mb-0">{{ popupTitle }}</h4>
          </div>
          <div class="col-md-6 text-end">
            <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
          </div>
        </div>
        
        <div class="row">
        
          <div class="w-100 mb-3">
        
            <!-- <div class="d-flex flex-row mb-3 border-bottom">
              <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
              <input autocomplete="off" type="text" placeholder="{{
                                'dashboard.labels.Search by Patient or Technician name' | translate }}"
                class="searchfilter form-control" name="search-name" [(ngModel)]="searchText">
            </div> -->
        
            <div class="popup-viewport-body" *ngIf="shiftStatusList?.length > 0" clickOutside (clickOutside)="showPopup = ''">
              <div *ngFor="let shift of shiftStatusList | searchFilter : searchText:['workerName', 'email']; let idx = index" class="example-item">
                <mat-card class="card-appointment mt-4 mb-4" *ngIf="shift?.workerName && shift?.email">

                  <div class="row icon-round">
                    <div class="col-md-1" *ngIf="!shift?.workerLogo">
                      <i class="material-icons icon-appointment assigned" *ngIf="popupTitle === 'Scheduled'">memory</i>
                      <i class="material-icons icon-appointment accepted" *ngIf="popupTitle === 'Checked-In'">memory</i>
                      <i class="material-icons icon-appointment expired" *ngIf="popupTitle === 'Not Checked-In' || popupTitle === 'Not Clocked-In'">memory</i>
                      <i class="material-icons icon-appointment late or cancelled" *ngIf="popupTitle === 'Checked-Out'">memory</i>
                    </div>
                    <div class="col-md-2" *ngIf="shift?.workerLogo">
                      <div class="avatarImg" [style.background-image]="'url(' + dashboarService.service.getSuperAdmin() + '/downloadFile/' + shift.workerLogo + ')'"></div>
                    </div>
                    <div class="col-md-9 default">
                      <div><span appPopover [appPopoverData]="shift" [placement]="'top'">{{shift?.workerName}}</span></div>
                      <small>{{shift?.email}}</small>
                    </div>
                    <div class="col-md-1 pt-3">
                      <p><span class="badge bg-secondary color-white">{{shift?.count}}</span></p>
                    </div>
                  </div>     

                </mat-card>
              </div>
            </div>
            <div  *ngIf="shiftStatusList?.length === 0" class="mt-4 text-danger">
              <!-- {{ 'dashboard.labels.No appointments are present' | translate }}. -->
              No Records Found
            </div>
        
          </div>
        </div>
      </ng-template>

      <ng-template #statusDetailsPopUp>
  
        <div class="row mb-4">
          <div class="col-md-6 align-self-center">
            <h4 class="mb-0">{{ popupTitle }}</h4>
          </div>
          <div class="col-md-6 text-end">
            <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
          </div>
        </div>
        
        <div class="row container overflow-auto">
          <table class="table table-nowrap mb-0 position-relative">
            <thead>
                <tr>
                    <th class="nowrapTxt">Staff Name</th>
                    <th class="nowrapTxt">Customer Name</th>
                    <th>{{ 'main.labels.Job Site' | translate }}</th>
                    <th class="nowrapTxt">Job Type</th>
                    <th class="nowrapTxt">Start Time</th>
                    <th class="nowrapTxt">End Time</th>
                    <th>Reason</th>
                </tr>
            </thead>
            <tbody>
              <ng-template [ngIf]="DecliedAndLateList.length > 0"  >
                <tr *ngFor="let element of DecliedAndLateList">
                    <td class="nowrapTxt">
                      <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
                    </td>
                    <td class="nowrapTxt">{{element.customerName}}</td>
                    <td [title]="element.customerAddress">{{element.siteName}}</td>
                    <td class="nowrapTxt">{{element.jobType}}</td>
                    <td class="nowrapTxt">{{element.startDate}}</td> 
                    <td class="nowrapTxt">{{element.endDate}}</td>
                    <td>{{element.reason}}</td>
                </tr>
              </ng-template>

              <ng-template [ngIf]="DecliedAndLateList.length == 0">
                <tr><td colspan="7" class="text-danger">
                  No Record Found
                </td></tr>
              </ng-template>
              
           </tbody>
        </table>
        </div>
      </ng-template>


      <!-- missed clock-out popup starts -->
          <ng-template #missedClockOut_TempRef>
        <div class="row">
           <!-- <div class="col-md-6 align-self-center">
             <h6 class="dialog-header pt-2">Approve Leave</h6>
           </div> -->
           <div class="col-md-6">
             <div class="row justify-content-end">
               <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
             </div>
           </div>
         </div> 
     
         <div class="row shortcutPopupScrollBody">
         <app-check-out-missed-list></app-check-out-missed-list>
         </div>
       </ng-template>
       <!-- missed clock-out popup ends -->


             <!-- new job request list popup starts -->
             <ng-template #newJobRequestList_TempRef>
              
              <div class="row  ">
                <div class="col-md-6 align-self-center">
                  <h6 class="dialog-header pt-2">Requested Job List</h6>
                </div>
                <div class="col-md-6">
                  <div class="row justify-content-end">
                    <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
                  </div>
                </div>
              </div> 
           
              <div class="row shortcutPopupScrollBody">

                <mat-tab-group
                dynamicHeight
                class="tab-button-auto-width"
                (selectedTabChange)="requestedJob_tabChanged($event)"
              >
              
                <mat-tab label="Requested">
                  <ng-template [ngIf]="tabAtciveFor === 'Requested'">    
                    <div class="w-100 mt-3">      
                      <app-admin-requested-new-job-list [componentFor]="'for requested job'" [isExpired]="false"></app-admin-requested-new-job-list>
                    </div>
                  </ng-template>
                </mat-tab>
                
                <mat-tab label="Processed">
                  <ng-template [ngIf]="tabAtciveFor === 'Processed'">
                    <div class="w-100 mt-3">
                      <app-admin-requested-new-job-list [componentFor]="'for processed job'" [appoinment_Status]="'Processed'"></app-admin-requested-new-job-list>
                    </div>        
                  </ng-template>
                </mat-tab>

                <mat-tab label="Expired">
                  <ng-template [ngIf]="tabAtciveFor === 'Expired'">
                    <div class="w-100 mt-3">
                      <app-admin-requested-new-job-list [componentFor]="'for expired job'" [isExpired]="true"></app-admin-requested-new-job-list>
                    </div>        
                  </ng-template>
                </mat-tab>

              </mat-tab-group>
             </div>
             </ng-template>
             <!-- new job request list popup ends -->
             
             <!-- Cancel Job Approvel Request list popup starts -->
             <ng-template #CancelJobApprovelRequest_TempRef>
              
              <div class="row  ">
                <div class="col-md-6 align-self-center">
                  <h6 class="dialog-header pt-2">Request Cancel</h6>
                </div>
                <div class="col-md-6">
                  <div class="row justify-content-end">
                    <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
                  </div>
                </div>
              </div> 
           
               <div class="row shortcutPopupScrollBody">
      
                <mat-tab-group
                dynamicHeight
                class="tab-button-auto-width"
                (selectedTabChange)="cancelJob_tabChanged($event)"
              >
              
                <mat-tab label="Pending">
                  <ng-template [ngIf]="cancelJob_activeTab === 'Pending'">    
                    <div class="w-100 mt-3">      
                       <cancel-jobs 
                      [isExpired]="false"
                      [rendered_componentFor]="'pending'" >
                  </cancel-jobs>
                    </div>
                  </ng-template>
                </mat-tab>
                
                <mat-tab label="Processed">
                  <ng-template [ngIf]="cancelJob_activeTab === 'Processed'">
                    <div class="w-100 mt-3">
                      <cancel-jobs 
                       [rendered_componentFor]="'processed'" >
                  </cancel-jobs>
                    </div>        
                  </ng-template>
                </mat-tab>

                <mat-tab label="Expired">
                  <ng-template [ngIf]="cancelJob_activeTab === 'Expired'">
                    <div class="w-100 mt-3">
                      <cancel-jobs 
                      [isExpired]="true"
                      [rendered_componentFor]="'expired'" >
                  </cancel-jobs>
                     </div>        
                  </ng-template>
                </mat-tab>

              </mat-tab-group>

             </div>
             </ng-template>
             <!-- Cancel Job Approvel Request list popup ends -->

             <!-- clock-in clockout drower Start-->
             <ng-template #viewClockInClockOut>
              <div class="col-md-6 text-end">
                <!-- <button class="btn-close cursor-pointer" (click)="closeDrawer()"></button> -->
                <button class="btn-close cursor-pointer" [mat-dialog-close]="true" cdkFocusInitial></button>
                
            </div>
            <div class="row appointement-schedule">
              <div class="w-95 ps-3">
                 <div class="row mb-3">
                        <div class="col-md-6 align-self-center">
                          <h4 class="mb-0 text-capitalize">{{clickedStatus}} {{ 'dashboard.labels.Appointments' | translate }}</h4>
                        </div>
                      
                      </div>
          
                      <div class="row">
                        
                        <div class="w-100 mb-3">
                         <clockin-clockout-job-card [status]="clickedStatus"></clockin-clockout-job-card>
                        </div>
                        
                    </div>
         
              </div>
            </div>
           </ng-template>
           <!-- clock-in clockout drower End-->