import { Component, OnInit, ViewChild } from '@angular/core';
import { DepartmentService } from './department.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  page = 0;
  limit = 10;
  allDeptData = [];
  displayedColumns: string[] = ['departmentLogo', 'departmentName','parentName', 'isActive', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public service: DepartmentService, public common: CommonService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.getAlldept(this.page, this.limit)
  }

   /* Name:Shailesh
  use: for getting all department*/
  getAlldept(page, limit, scroll = null) {
    if (!scroll) {
      this.service.common.progressLoader = true;
    }
    try {
       this.service.getDepartmentMasterByOrgCodeByPage(this.service.local.get('orgCode'), page, limit, (data) => {
      ////console.log(data);
      // this.allDeptData = data.data;
      if (data.responsecode == 200) {
        if (scroll) {
          let arrayData = this.dataSource.data;
          data.data.forEach(element => {
            if (element.departmentLogo != null) {
              element.imageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + element.departmentLogo;
            }
            arrayData.push(element);
          });

          setTimeout(() => {
            this.allDeptData = arrayData;
            this.dataSource = new MatTableDataSource(this.allDeptData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;

          }, 1000);

        } else {
          this.allDeptData = data.data;
          //////console.log(this.skillList);

          this.allDeptData.forEach(element => {
            if (element.departmentLogo != null) {
              element.imageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + element.departmentLogo;
            }
          });
          this.dataSource = new MatTableDataSource(this.allDeptData);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sort;
          this.service.common.progressLoader = false;
        }

      } else {
        this.service.common.progressLoader = false;
        if (!scroll) {
          this.dataSource = new MatTableDataSource();
          this.allDeptData = [];

        }
      }
    })
    } catch (error) {
      //console.log(error);
    }
   
  }  
  addDept(){
    ////console.log('department')
  }

  // deptAdd(): void {
  //   const dialogRef = this.dialog.open(AddDepartmentComponent, {
  //     width: '320px',
  //     disableClose: true,
  //     data: {}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       this.getAlldept(this.page, this.limit);
  //     }
  //   });
  // }

  // deptEdit(data) {
  //   const dialogRef = this.dialog.open(AddDepartmentComponent, {
  //     width: '320px',
  //     disableClose: true,
  //     data: data
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       this.getAlldept(this.page, this.limit);
  //     }
  //   });
  // }

  onScroll() {
    //////console.log('scroll');
    this.page = this.page + 1;
    this.getAlldept(this.page, this.limit, 'scroll');
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    //////console.log( this.dataSource);
  }
}
