
<div class="worker w-100">

  <div class="row">
  <div class="col-md-12">
  
    <div class="row m-0">
      <div class="col-md-6">
        <h4 class="mb-0">{{ 'common.labels.Select Staff' | translate}}</h4>
      </div>
      <div class="col-md-6 text-end">
        <div class="row justify-content-end">
          <button type="button" class="btn-close cursor-pointer" (click)="onNoClick()"></button>
        </div>
      </div>
    </div>
    </div>

  </div>

  <div class="w-100 d-flex flex-row mb-3 border-bottom">
    <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
    <input autocomplete="off" type="text" placeholder="{{ 'Jobs.messages.Search Staff' | translate}}"
      class="searchfilter form-control border-0" name="search-name" [(ngModel)]="searchTerm" (keyup)="search()">
  </div>

  <div class="w-100 popup-viewport-body">

  <form [formGroup]="eligible" (ngSubmit)="onSubmit()">
    
    <ul *ngIf="appointmentfilter.length > 0">
   

      <mat-radio-group aria-label="Select an option" formControlName="worker">
        <li class="mt-2" *ngFor="let worker of appointmentfilter">
          <div class="row">
            <div class="col-md-1 text-center align-self-center p-0">
              <mat-radio-button class="ms-3" [value]="worker.workerCode" [checked]="worker.isAssigned"></mat-radio-button>
            </div>
            <div class="col-md-11 font-card">
              <mat-card class="example-card">
                <mat-card-content> 
                <div class="row">
                  <div class="col-md-1">
                    <img class="product-image me-2" *ngIf="worker.workerLogo" #imageUploaded [alt]="worker.name"
                      [src]="service.service.getSuperAdmin() + '/downloadFile/' + worker.workerLogo" />
                    <img *ngIf="!worker.workerLogo" [src]="'assets/common_images/profile.jpg'" class="product-image  me-2">
                  </div>
                  <div class="col-md-5">
                    <p class="name mb-0 ms-2">{{worker.name}}</p>
                    <small class="email mb-0 ms-2">{{worker.email}}</small>
                  </div>
                  <div class="col-md-3">
                    <small class="name mb-0">{{ 'common.labels.Distance' | translate}}</small>
                    <p class="name mb-0">{{worker.distanceText}} </p>
                  </div>
                  <div class="col-md-3">
                    <small class="name mb-0">{{ 'common.labels.Drive Time' | translate}} </small>
                    <p class="name mb-0">{{worker.durationText}} </p>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                      <small class="name mb-0">{{ 'common.labels.No of appointments (Daily-Weekly)' | translate}} </small>
                      <p class="name mb-0">{{ worker.scheduledAppointmentDaily }}/{{worker.targetAppointmentDaily}} - {{worker.scheduledAppointmentWeekly}}/{{worker.targetAppointmentWeekly}}</p>
                  </div>
                  <div class="col-md-6">
                      <small class="name mb-0">{{ 'common.labels.No of Schedules (Daily-Weekly)' | translate}} </small>  
                      <p class="name mb-0">{{worker.scheduledHoursDaily}}/{{worker.targetHoursDaily}} - {{worker.scheduledHoursWeekly}}/{{worker.targetHoursWeekly}}</p>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <small class="name mb-0">{{ 'common.labels.Skills' | translate}} </small> <br>
                    <span *ngFor="let skill of worker.skillMasterList;let i = index">
                      {{ skill.skillName}}
                      <span *ngIf="worker.skillMasterList.length-1!=i"> , </span> </span>
                  </div>
                </div>  
              </mat-card-content>
              </mat-card>
               
            </div>
         
          </div>
        </li>
      </mat-radio-group>

    </ul>
    <div class="w-100" *ngIf="appointmentfilter.length == 0 && showMessage">
      <p>{{ 'common.labels.No other worker available for the job' | translate}}</p>
    </div>
  </form>

  </div>

  <div class="w-100">
    <div class="d-flex offset-md-8 justify-content-end pt-3">
      <button type="button" mat-button (click)="cancelEligible()" class="cancel-btn-primary">{{ 'common.labels.Cancel' | translate}}</button>
      <button class="ms-2 pe-4 ps-4" mat-raised-button color="primary" type="submit" (click)="onSubmit()" [disabled]="!eligible.valid">{{ 'common.labels.Submit' | translate}}</button>
    </div>
  </div>

</div>



