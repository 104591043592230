import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  totalRecords = 0;
  processedRecords = 0;

  constructor(public service: ApiRootService, public local: SessionStorageService, public common: CommonService, public http: HttpClient) { }

  /* Name:Shailesh
      use: for getting appointment detail for map view*/
  adminGetAppointmentDetailsForMapView(code, date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAppointmentDetailsForMapView/' + code + '/' + date).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for getting admin appointment detail for map view start*/
  adminGetAppointmentDetailsForMapViewStart(code, date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAppointmentDetailsForMapViewStart/' + code + '/' + date).subscribe((data) => {
      callback(data);
    })
  }

  // viv(code, date, callback)
  // {
  //   return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAppointmentDetailsForMapView/' + code + '/' + date).subscribe((data) => {
  //     callback(data);
  //   })
  // }

    /* Name:Vivek Chauhan
      use: for getting Job detail for map view*/
      adminGetAppointmentDetailsForMapViewForCustomer(code, date, customerCode, callback) {
        return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAppointmentDetailsForMapView/' + code + '/' + date + '/' + customerCode).subscribe((data) => {
          callback(data);
        })
      }
    
      /* Name:Shailesh
          use: for getting admin Job detail for map view start*/
      adminGetAppointmentDetailsForMapViewStartForCustomer(code, date, customerCode, callback) {
        return this.http.get(this.service.getHealthCareUrl() + '/v2/adminGetAppointmentDetailsForMapViewStart/' + code + '/' + date + '/' + customerCode).subscribe((data) => {
          callback(data);
        })
      }

  /* Name:Shailesh
      use: for getting latitude and longitude by order code*/
  getLatLangByOrgCode(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getLatLangByOrgCode/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for assigning appintment by optaplanner*/
      adminAssignJobsByOptaplanner(code, startDate, endDate, appDurationList, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminAssignAppointmentByOptaplanner/' + code + '/' + startDate + '/' + endDate + '/' + appDurationList).subscribe((data) => {
      callback(data);
    })
  }


    
       /* Name:Shailesh
          use: getting appointment tread processing by code */
      getAppointmentThreadProcessingByCode(code,callback) {
        return this.http.get(this.service.getHealthCareUrl()+'/getAppointmentThreadProcessingByCode/'+code).subscribe((data)=>{
          //callback(data);
          this.getresponseData(data,callback,code);
        })
      }
    
       /* Name:Shailesh
          use: getting appointment thread processing by code */
      getresponseData(response,callback,code){
        if(response.responsecode == 200){
           //console.log(response);
          this.totalRecords = response.data.totalRecord;
          this.processedRecords = response.data.processRecord;
    
          this.common.progressText ='Processing '+ this.processedRecords + ' of ' + this.totalRecords + ' appointments';
          //console.log(this.common.progressText);
          if(response.data.totalRecord == response.data.processRecord){
            callback(response);
          } else {
            this.getAppointmentThreadProcessingByCode(code,callback);
          }
        }
      }
}
