import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class TechnicianDashboardService {

  constructor(
    public http: HttpClient,
    public common: CommonService,
    public service: ApiRootService,
    public session: SessionStorageService,
  ) { }
/* Name:srinivas
      use: return dashboard details by dates. */
  mobileGetDashboardByTechnicianCodeAndBetweenDates(workerCode,startdt,enddt,orgCode,callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/mobileGetDashboardByTechnicianCodeAndBetweenDatesFilter/'+workerCode +'/' +startdt +'/' + enddt+'/'+ this.session.get('selected_OrgCode') ).subscribe((data)=>{
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    });
  }
/* Name:srinivas
      use: accept the worker. */
  assignToWorkerAcceptedByJobDurationCode(jobDurationcode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminMobileAssignAppointmentToWorkerAcceptedByAppointmentsDurationCode/' + jobDurationcode, formData).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    })
  }
/* Name:srinivas
      use: reject the worker. */
  
  assignToWorkerrejectedByJobDurationCode(jobDurationcode,formData,callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminMobileAssignAppointmentToWorkerRejectedByAppointmentsDurationCode/' + jobDurationcode, formData).subscribe((data) => {
      callback(data);
    }, (error) => {
      callback({ responsecode: 500, message: this.common.apiStopError });
    })
  }

   /* Name:Vivk Chauhan
      use: getting all jobs by date time filter*/

      // /v2/adminGetAllAppointmentsByOrganizationCodeForStaffWithFilterDateTime/{organizationCode}/{status}/{startDT}/{endDT}/{orgBasedProcedureCodeList}/{workerCode}

      // /staffingservice/mobileGetDashboardByTechnicianCodeAndBetweenDatesFilter/{workerCode}/{startDT}/{endDT}/{organizationCode}

      mobileGetDashboardByTechnicianCodeAndBetweenDatesFilter(orgCode, startDt, endDt, workerCode, callback) {
        return this.http.get(this.service.getHealthCareUrl() + '/mobileGetDashboardByTechnicianCodeAndBetweenDatesFilter/' + workerCode + '/' + startDt + '/' + endDt + '/' + orgCode).subscribe((data) => {
          callback(data);
        })
      }
}
