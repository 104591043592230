import { Component, Input, OnInit } from '@angular/core';
import { TechnicianDashboardService } from 'src/app/dashboards/technician-dashboard/technician-dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'staff-job-card',
  templateUrl: './staff-job-card.component.html',
  styleUrls: ['./staff-job-card.component.scss']
})
export class StaffJobCardComponent implements OnInit { 

  img_serverPath:string ='';
  @Input() cardData:any[] ;

  constructor(
    public service: TechnicianDashboardService, public translate: TranslateService, public common: CommonService) { 
    this.translate.setDefaultLang(this.common.local.get('language'));
  }

  ngOnInit() {
    this.img_serverPath =  this.service.service.getSuperAdmin() + '/downloadFile/';
    // //console.log('cardData: ', this.cardData)
  }
}
