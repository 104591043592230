import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from './role.service';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  providers:[RoleService]
})
export class RoleComponent implements OnInit, OnDestroy {

  roleform:FormGroup;

  displayedColumns: string[] = [
    'roleName', 
    'roleProfile', 
    'roleDescription',
    'action',
  ];

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  roleList:any = [];
  page:number = 0;
  limit:number = 10;

  profileListData:any = [];
  role_popup_reference:any;
  isForm_Submitted : boolean = false ;

  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();

  constructor(private _service :RoleService , 
    private translate: TranslateService,
    private fb : FormBuilder,
    private matDialog :   MatDialog,
    private _commonSVC : CommonService) {

    this.translate.setDefaultLang(this._service._session.get('language'));

   }

  ngOnInit(): void {
    this.InitializeForm();
    this.getAllRoleList();
    this.getProfileList();
    this.hasPermission =  this._service.common.getBtnEventPermissions("Role Settings"); 
    this.subToPermissionChanged();
  }

  InitializeForm(){
    this.roleform = this.fb.group({
      roleProfile : ['', Validators.required],
      roleName : ['', Validators.required],
      roleDesc : [''],
      roleCode : [''],
      isDefault:[false]
    })
  }

  getAllRoleList(scroll = null) {
    if (!scroll) {
      this._service.common.progressLoader = true;
    } else if(scroll) {
      this._service.common.infiniteLoader = true;
    } 
    try {
      this._service.getAllRoleByOrgCode(this._service._session.get('orgCode')).subscribe((response:any) => {
      //console.log("role List " , response);
      if (response.responsecode == 200) {

          this.roleList = response.data;
            this.dataSource = new MatTableDataSource(response.data);
            ////console.log(response.data)
          setTimeout(() => {  
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this._service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            // this.dataSource.paginator = this.paginator;
            this._service.common.progressLoader = false;
            this._service.common.infiniteLoader = false;
          });
         
        }
      })
  } catch (error) {
      //console.log(error)
    }
    
  }

  getProfileList() {
    
    try {
     this._service.common.progressLoader = true;
     this._service.getAllSystemRoleByOrgCode(this._service._session.get('orgCode')).subscribe((response:any) => {
      this._service.common.progressLoader = false;
      if (response.responsecode == 200) {
             this.profileListData = response.data;
         }
     })
 } catch (error) {
     //console.log(error)
   }
   
 }

  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    // this.page = this.page + 1;
    // this.getLicenceMasterByOrganizationCodeByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }
  
  openRoleFormPopUp(templateRef: TemplateRef<any>) : any 
  {
    
    this.role_popup_reference  =   this.matDialog.open(templateRef,{
      maxWidth: '40vw',
      width: '40%',
     // panelClass: 'full-screen-modal'
    });


     this.role_popup_reference.afterClosed().subscribe(result => {
     this.roleform.reset();
   });


  }


  
  addUpdateRole()
  {
    this.isForm_Submitted = true ;

    if(this.roleform.valid)
    {

    var request_body = {
      systemRoleCode:  this.roleform.controls.roleProfile.value  ,
      roleDescription: this.roleform.controls.roleDesc.value,
      roleName: this.roleform.controls.roleName.value,
      organizationCode: this._service._session.get('orgCode'),
      roleCode: this.roleform.controls.roleCode.value ? this.roleform.controls.roleCode.value : null,
      isDefault : this.roleform.controls.isDefault.value
    }

    try {
      this._service.common.progressLoader = true;
      this._service.saveSystemRole(request_body).subscribe((response:any) => {
       this._service.common.progressLoader = false;
       this.isForm_Submitted = false;
       if (response.responsecode == 200) { 
        this._service.common.message =  response.message;
        this._service.common.successMessage = true;
         this.role_popup_reference.close();
        this.getAllRoleList();
          }
          else{
            this._service.common.message =  response.message;
          this._service.common.errorMessage = true;
          }
      })
  } catch (error) {
      //console.log(error);;
      this._service.common.message =  error;
          this._service.common.errorMessage = true;
    }
  }

  }

  editRole(element:any , templateRef: TemplateRef<any>)
  {
 
this.roleform.patchValue({
  roleProfile : element.systemRoleCode ,
  roleName : element.roleName ,
  roleDesc : element.roleDescription ,
  roleCode :  element.roleCode,
  isDefault:  element.isDefault ? element.isDefault : false
})

this.role_popup_reference  =   this.matDialog.open(templateRef,{
  maxWidth: '40vw',
  width: '40%',
 // panelClass: 'full-screen-modal'
});


this.role_popup_reference.afterClosed().subscribe(result => {
  this.roleform.reset();
});


  }


  subToPermissionChanged()
  {
   this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
      
     if(res.responsecode === 200)
       {
         this.hasPermission =  this._commonSVC.getBtnEventPermissions("Role Settings");
       }
   })
  }
  
   ngOnDestroy(): void {
      this.clearObs.next(''),
      this.clearObs.complete();
    }
  


}
