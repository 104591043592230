import { CommonService } from './../../../shared/common.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl, FormBuilder, FormArray } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AddAdminComponent } from './add-admin/add-admin.component';

import * as moment from 'moment';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';
import { AdddocumentnoteComponent } from './adddocumentnote/adddocumentnote.component';
import { AddOrganizaionnoteComponent } from './add-organizaionnote/add-organizaionnote.component';
import { PageEvent } from '@angular/material/paginator';
import { TechnicianService } from '../technician/technician.service';
import { AddDocumentComponent } from './add-document/add-document.component';


declare var google;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isAdminPerson = null;
  isActiveIndexOwner = 0;
  isActiveIndexAdmin = 0;
  isDisable = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  skillCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Lemon'];
  allskills: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  @ViewChild('email', { static: false }) email;
  @ViewChild('skillInput', { static: true }) skillInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  profileForm: FormGroup;
  @ViewChild('password', { static: false }) password;
  @ViewChild('adminpassword', { static: false }) adminpassword;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options = { types: ['address'], componentRestrictions: { country: ["us", 'in'] } };
  lat: any;
  lng: any;
  uploadSubmitted = true;
  submitted = true;
  updatedAddress: any;
  imageupload: boolean;
  imageuploadAdmin: boolean;
  filetitle: any;
  fileUrl: any;
  fileUrlAdmin: any;
  approvalStatus: FormGroup;
  orgName;
  selectChecked = true;
  pass = false;
  toggelLogo = 0;
  uploadForm: FormGroup;
  submittedAdmin = true;
  allAdminData = [];
  displayedColumnsAdmin: string[] = ['adminImage', 'name', 'email', 'userName', 'isActive', 'actions'];
  dataSourceAdmin = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatSort, { static: true }) sortAdmin: MatSort;
  allDocuments = [];
  displayedColumns: string[] = ['documentName', 'expiryDate', 'note', 'actions'];
  dataSource = new MatTableDataSource();
  organizationDocumentCode;
  today = new Date();

  allService: FormGroup;
  filteredOptionservice: Observable<any>;
  selectedService = [];
  serviceAll = [];
  uploadButton: boolean = false;
  allOrgNotes = [];
  page = 0;
  limit = 5;
  count = 0;
  orgReadCount = 0;
  reset: boolean = true;
  showLoader: boolean = false;
  allNotes = [];
  productForm: FormGroup;
  items: FormArray;

  constructor(
    public service: ProfileService,
    private translate: TranslateService,
    private localStorage: SessionStorageService,
    private common: CommonService,
    public dialog: MatDialog,
    private fb: FormBuilder


  ) {
    this.translate.setDefaultLang(this.localStorage.get('language'));
    this.productForm = new FormGroup({});
  }


  /* Name:Shailesh
       use: for checking confirm password validation */
  confirmPassword(control: AbstractControl) {
    return new Promise<any>(
      (resolve, reject) => {
        //console.log(control.value, this.password)
        if (this.password && control.value !== this.password.nativeElement.value && this.password.nativeElement.value) {
          //console.log('in');
          resolve({
            invalid: true
          });
        } else {
          //console.log('out');
          resolve(null);
        }
      });
  }

  /* Name:Shailesh
      use: for checking admin confirm password */
  confirmAdminPassword(control: AbstractControl) {

    return new Promise<any>(
      (resolve, reject) => {
        //console.log(control.value, this.adminpassword);
        if (this.adminpassword && control.value !== this.adminpassword.nativeElement.value && this.adminpassword.nativeElement.value) {
          //console.log('in');
          resolve({
            invalid: true
          });
        } else {
          //console.log('out');
          resolve(null);
        }
      });

  }

  /* Name:Shailesh
        use: for getting organization detail by org code and admin code*/
  getDetail() {

    try {
      this.service.getOrganizationDetailByOrgCodeAndAdminCode(this.localStorage.get('orgCode'), this.localStorage.get('userCode'), (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.service.profileData = response.data;
          //console.log(this.service.profileData);
          if (this.service.profileData.ownerDetails) {
            this.codeLatLng(response, (addr) => {
              this.updatedAddress = addr[0].formatted_address;
              this.profileForm.controls['address'].setValue(this.updatedAddress);
            });
            this.orgName = this.service.profileData.ownerDetails.organizationName; setTimeout(() => {
              if (response.data.ownerDetails.ownerImage) {
                this.fileUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + response.data.ownerDetails.ownerImage;
                this.localStorage.set('image', response.data.ownerDetails.ownerImage);
                //this.service.profileData.ownerDetails.ownerImage = response.data.ownerDetails.organizationLogo;
                this.imageupload = true;
                if (this.localStorage.get('user_type') !== 'Staff' && this.localStorage.get('user_type') !== 'Customer') {
                  this.service.common.globalImage = response.data.ownerDetails.ownerImage;
                  this.service.common.globalImageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + response.data.ownerDetails.ownerImage;
                  //console.log(this.service.common.globalImageUrl);
                }
              }
            }, 100);
          }

          if (this.service.profileData.adminDetails) {
            setTimeout(() => {
              if (response.data.adminDetails.adminImage) {
                this.fileUrlAdmin = this.service.service.getSuperAdmin() + '/downloadFile/' + response.data.adminDetails.adminImage;
                this.service.profileData.adminDetails.adminImage = response.data.adminDetails.adminImage;
                this.imageuploadAdmin = true;
                if (this.localStorage.get('user_type') !== 'Staff' && this.localStorage.get('user_type') !== 'Customer') {
                  this.service.common.globalImage = response.data.adminDetails.adminImage;
                  this.service.common.globalImageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + response.data.adminDetails.adminImage;
                }
              }
            }, 100)
          }

          this.isAdminPerson = this.service.profileData.isAdmin;
          //console.log(this.localStorage.get('log_type'));
          if (this.service.profileData.isAdmin == true && this.service.profileData.role == 'admin') {
            this.isActiveIndexAdmin = 1;
            this.isDisable = true;

          }
          else if (this.service.profileData.isAdmin == true && this.service.profileData.role == 'owner') {
            this.isActiveIndexAdmin = 0;
            //this.isDisable = true;
            this.isDisable = false;

          }
          else {
            this.isActiveIndexOwner = 0;
            this.isActiveIndexAdmin = 0;
            this.isDisable = false;
          }

          //console.log(this.isActiveIndexAdmin, this.isDisable);

        }
      })
    } catch (error) {
      //console.log(error);
    }
  }

  getAllAdminData() {
    this.service.getAllOwnerAdminListByOrgCode(this.localStorage.get('orgCode'), this.localStorage.get('userCode'), (data) => {
      if (data.responsecode == 200) {
        this.allAdminData = data.data;
        this.allAdminData.forEach(element => {
          if (element.adminImage != null) {
            element.imageUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + element.adminImage;
          }
        });
        //console.log(this.allAdminData);
        this.dataSourceAdmin = new MatTableDataSource(this.allAdminData);
        setTimeout(() => {
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSourceAdmin.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
          this.dataSourceAdmin.sort = this.sortAdmin;
        }, 500);
      } else {
        this.allAdminData = [];
        this.dataSourceAdmin = new MatTableDataSource();
        this.dataSourceAdmin.sort = this.sortAdmin;
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSourceAdmin.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
      }
    });
  }

  ngOnInit() {

    /* Name:Shailesh
      use: for all form control */
    this.getAllMastrDocs();
    this.getDetail();
    this.getAllAdminData();
    // this.getService();
    // this.getServiceOrg();
    this.getOrgNotes();
    this.getallDocuments();
    //setTimeout(() => {
    this.profileForm = new FormGroup({
      'adminImage': new FormControl(null),
      'workerLogo': new FormControl(null),
      'organizationName': new FormControl(null, Validators.required),
      'ownerName': new FormControl(null, [Validators.required, Validators.pattern(this.service.common.name)]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$")]),
      'cpassword': new FormControl(null, [Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$")], this.confirmPassword.bind(this)),
      'primaryContact': new FormControl(null),
      'offHoursContact': new FormControl(null),
      'address': new FormControl(null, Validators.required),
      'ownerUserName': new FormControl(null, [Validators.required,
      Validators.pattern(this.common.username)], this.confirmOwnerNameAvailability.bind(this)),
    });

    this.allService = new FormGroup({
      'service': new FormControl(null)
    });

    this.filteredOptionservice = this.allService.controls['service'].valueChanges
      .pipe(
        startWith(''),
        map(name => this._filter(name))
      );
  }

  getAllMastrDocs() {
    this.service.getAllDocuments('all', 0, 20, (res) => {
      //console.log(res);
      if (res.responsecode == 200) {
        this.service.allMasterdocs = res.data;
        this.dynamiArray();
      } else {
        this.service.common.message = res.message;
        this.service.common.errorMessage = true;
      }
    });
  }

  dynamiArray() {
   // setTimeout(() => {

      //console.log('dynamiArray', this.service.allMasterdocs);

      for (var i = 0; i < this.service.allMasterdocs.length; i++) {

        this.service.allMasterdocs[i].note = null;
        this.service.allMasterdocs[i].expiryDate = null;
        this.service.allMasterdocs[i].uploadPath = null;
        const documentName = new FormControl(this.service.allMasterdocs[i].documentName);
        //this.service.allMasterdocs[i].controlName = this.service.allMasterdocs[i].documentName.replace(/^\s+|\s+$/g, "")
        this.productForm.addControl( 'documentName'+i,documentName);
        const expiryDate = new FormControl(this.service.allMasterdocs[i].expiryDate);
        this.productForm.addControl( 'expiryDate'+i,expiryDate);
        const note = new FormControl(this.service.allMasterdocs[i].note);
        this.productForm.addControl( 'note'+i,note);
        const upload = new FormControl(this.service.allMasterdocs[i].uploadPath);
        this.productForm.addControl( 'upload'+i,upload);
       // this.arrayDocs().push(this.newDocAdd(this.service.allMasterdocs[i]));
      }

  //  }, 1000);
  }
  // arrayDocs(): FormArray {
  //   return this.productForm.get("quantities") as FormArray
  // }

  // newDocAdd(element): FormGroup {
  //   //console.log();
  //   return this.fb.group({
  //     documentName: new FormControl(element.documentName),
  //     expiryDate: new FormControl(element.expiryDate),
  //     note: new FormControl(element.note)
  //   });
  // }


  displayFn(serviceAll): string | undefined {
    return serviceAll ? serviceAll.serviceName : undefined;
  }

  private _filter(name: string) {
    //console.log(name);
    if (name && name != '') {
      const filterValue = name;
      return this.serviceAll.filter(option => option.serviceName.toLowerCase().indexOf(filterValue) === 0);
    } else {
      //console.log('name');
      return this.serviceAll;
    }
  }

  selectedOption(event) {
    //console.log(event);
    const index = this.selectedService.findIndex(fruit => fruit.serviceName === event.option.value.serviceName);
    if (index == -1) {
      //this.selectedService.push(event.option.value);
      const data = {
        "organizationCode": this.service.common.local.get('orgCode'),
        "serviceTypeCode": event.option.value.serviceTypeCode
      }
      this.service.saveServiceTypeOrganization(data, (res) => {
        if (res.responsecode == 200) {
          // this.getServiceOrg();
        } else {
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      });
      this.allService.controls['service'].setValue(null);
    } else {
      this.service.common.message = event.option.value.serviceName + ' is already selected';
      this.service.common.errorMessage = true;
      this.allService.controls['service'].setValue(null);
    }
  }

  removeOrg(code) {
    //console.log(code);
    this.service.softDeleteServiceTypeOrganizationByServiceTypeOrganizationCode(code, (res) => {
      if (res.responsecode == 200) {
        // this.getServiceOrg();
      } else {
        this.service.common.message = res.message;
        this.service.common.errorMessage = true;
      }
    })
    if (this.selectedService.length == 0) {
      this.allService.controls['service'].setValue(null);
    }
  }

  // getServiceOrg() {
  //   this.service.getServiceTypeOrganizationByOrganizationCode(this.service.common.local.get('orgCode'), (data) => {
  //     //console.log(data);
  //     if (data.responsecode == 200) {
  //       this.selectedService = data.data;
  //       //console.log(this.selectedService);
  //     } else {
  //       this.selectedService = [];
  //     }
  //   })
  // }

  // getService() {
  //   this.service.getAllServiceTypeByIsActive((data) => {
  //     if (data.responsecode == 200) {
  //       this.serviceAll = data.data;
  //       //console.log(this.serviceAll);
  //       this.filteredOptionservice = this.allService.controls['service'].valueChanges
  //         .pipe(
  //           startWith(''),
  //           map(name => this._filter(name))
  //         );
  //     } else {
  //       this.serviceAll = [];
  //     }
  //   })
  // }
  /* Name:Shailesh
      use: for uploading profile picture */
  onFileChange(event, tab) {
    //console.log(event);
    if (event.target.files.length > 0) {

      let allowedExtension = ['jpeg', 'jpg', 'png'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      let isValidFile = false;
      //////console.log(fileExtension);
      for (let index in allowedExtension) {
        if (fileExtension === allowedExtension[index] && event.target.files[0].size <= 2000000) {
          isValidFile = true;
          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          try {
            this.service.common.uploadImage(formData, (res) => {
              //////console.log(res);
              if (tab == "owner") {
                this.fileUrl = res.fileurl;
                this.filetitle = res.filename;
                this.service.profileData.ownerDetails.ownerImage = res.filename;
                this.profileForm.controls['workerLogo'].setValue(res.filename);
                this.imageupload = true;
              } else {
                this.fileUrlAdmin = res.fileurl;
                this.filetitle = res.filename;
                this.service.profileData.adminDetails.adminImage = res.filename;
                this.profileForm.controls['adminImage'].setValue(res.filename);
                this.imageuploadAdmin = true;
              }
            })
          } catch (error) {
            //console.log(error)
          }

          break;
        }
      }

      if (!isValidFile) {
        //////console.log(isValidFile);
        this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 2MB';
        this.service.common.errorMessage = true;
        //event.srcElement.value = '';
        event.target.value = '';
      }
    }
  }

  codeLatLng(response, callback) {
    let geocoder = new google.maps.Geocoder();

    let newLatLang = new google.maps.LatLng(response.data.ownerDetails.lat, response.data.ownerDetails.lang)

    geocoder.geocode({ 'latLng': newLatLang }, function (results, status) {
      ////console.log(results, status);
      callback(results);
    });
  }

  /* Name:Shailesh
      use: for checking email id availability */
  confirmEmailAvailability(control: FormControl): Promise<any> | Observable<any> {
    const emailavaiable = new Promise<any>(
      (resolve, reject) => {

        try {
          this.service.checkEmailAvialablity(control.value, (response) => {
            //////console.log(response);
            if (response == false) {

              resolve({ invalid: true });
            } else {
              if (control.parent.get('adminemail')) {
                if (control.value !== this.email.nativeElement.value) {
                  resolve(null);
                } else {
                  resolve({ invalid: true });
                }
              } else {
                resolve(null);
              }
            }
          });
        } catch (error) {
          //console.log(error)
        }

      });
    return emailavaiable;
  }

  /* Name:Shailesh
      use: for updading organization detail */
  onSubmit() {
    // //console.log('Profile Form', this.profileForm);
    // //console.log(this.service.profileData);
    // return;

      if (this.profileForm.valid) {
        this.submitted = true;
        this.submitData();
      } else {
        this.submitted = false;
      }
  }

  submitData() {
    this.service.common.progressLoader = true;
    if (this.service.profileData.ownerDetails && this.service.profileData.ownerDetails.password === '') {
      this.service.profileData.ownerDetails.password == null
    } if (this.service.profileData.adminDetails && this.service.profileData.adminDetails.password === '') {
      this.service.profileData.adminDetails.password == null
    }
    // //console.log('SubmitData: ', this.service.profileData);
    // return;

    try {
      this.service.updateOrganisationDetail(this.service.profileData, this.localStorage.get('orgCode'), this.isAdminPerson, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          setTimeout(() => {
            this.getDetail();
          }, 1000);
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.successMessage = true;
        } else {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.errorMessage = true;
        }
      })
    } catch (error) {
      //console.log(error)
    }
  }

  handleAddressChange(address) {
    //console.log(address);
    if (address.geometry) {

      ////console.log(address.geometry.location.lat());
      ////console.log(address.geometry.location.lng());
      this.lat = address.geometry.location.lat();
      this.lng = address.geometry.location.lng();
      //console.log(this.lat, this.lng);
      //this.autocomplete=address;
      this.service.profileData.ownerDetails.lat = this.lat;
      this.service.profileData.ownerDetails.lang = this.lng;
      this.profileForm.controls['address'].setValue(address.formatted_address);
      // setTimeout(()=>{
      // this.addressValidation(this.employerform.controls['address']);
      // },2000);
    }



  }

  adminActivecheck($event) {
    //////console.log($event);
    if ($event.checked) {
      this.service.profileData.adminDetails.isActive = true;
    } else {
      this.service.profileData.adminDetails.isActive = false;
    }
  }

  ownerAcivecheck($event) {
    //////console.log($event);
    if ($event.checked) {
      this.service.profileData.ownerDetails.isActive = true;
    } else {
      this.service.profileData.ownerDetails.isActive = false;
    }
  }

  /* Name:Shailesh
      use: for checking username availability*/
  confirmUserNameAvailability(control: FormControl): Promise<any> | Observable<any> {
    if (this.service.profileData.isAdmin == true && this.service.profileData.role == 'owner') {
      const usernameavaiable = new Promise<any>(
        (resolve, reject) => {
          try {
            this.common.checkUserNameAvialablity(control.value, (response) => {
              //////console.log(response);
              if (response == false) {
                resolve({ invalid: true });
              } else {
                resolve(null);
              }
            });
          } catch (error) {
            //console.log(error)
          }

        });
      return usernameavaiable;
    } else {
      const usernameavaiable = new Promise<any>(
        (resolve, reject) => {
          this.service.getIsUniqueUserNameForUpdate(this.service.profileData.adminDetails.userCode, control.value, (response) => {
            //////console.log(response);
            if (response == false) {
              resolve({ invalid: true });
            } else {
              resolve(null);
            }
          });
        });
      return usernameavaiable;
    }

  }

  /* Name:Shailesh
      use: for checking owner name availbility */
  confirmOwnerNameAvailability(control: FormControl): Promise<any> | Observable<any> {
    const usernameavaiable = new Promise<any>(
      (resolve, reject) => {
        try {
          this.service.getIsUniqueUserNameForUpdate(this.service.profileData.ownerDetails.userCode, control.value, (response) => {
            // //console.log('confirmOwnerNameAvailability', response);
            if (response == false) {
              resolve({ invalid: true });
            } else {
              resolve(null);
            }
          });
        } catch (error) {
          //console.log(error)

        }

      });
    return usernameavaiable;
  }

  tabChange(event) {
    //console.log(event);
    this.toggelLogo = event.index;
    this.getDetail();
    this.getAllAdminData();
    this.getallDocuments();
    // this.getService();
    // this.getServiceOrg();
    this.getOrgNotes();
  }

  onEdit(element) {
    //console.log(element);
    const dialogRef = this.dialog.open(AddAdminComponent, {
      width: '800px',
      disableClose: true,
      data: { admin: element }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.getAllAdminData();
      }
    });
  }

  /* Name:Shailesh
    use: for seraching skill name */
  applyFilter(filterValue: string) {
    this.dataSourceAdmin.filter = filterValue.trim().toLowerCase();
    //////console.log( this.dataSource);
  }

  addAdmin() {
    const dialogRef = this.dialog.open(AddAdminComponent, {
      width: '800px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.getAllAdminData();
      }
    });
  }

  addDocument(element = null) {

    const dialogRef = this.dialog.open(AddDocumentComponent, {
      width: '800px',
      disableClose: true,
      panelClass:'popup-pos',
      data: { data: element }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.getallDocuments();
      }
    });
  }


  getallDocuments() {

    try {
      this.service.getOrganizationDocumentByOrganizationCode(this.localStorage.get('orgCode'), this.page, this.limit, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.orgReadCount = response.data.unreadCommentCount;
          this.count = response.count;
          this.allDocuments = response.data.organizationDocumentResponseList;
          this.dataSource = new MatTableDataSource(this.allDocuments);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sortAdmin;
          this.service.common.progressLoader = false;
        }
        else {
          this.allDocuments = [];
          this.dataSource = new MatTableDataSource();
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sortAdmin;
          this.service.common.progressLoader = false;
        }
      });
    } catch (error) {

    }
  }

  geteditData(element) {
    //console.log('element', element);

    // if (element) {
    //   this.service.uploadData.documentName = element.documentName;
    //   this.service.uploadData.expiryDate = element.expiryDate;
    //   this.service.uploadData.uploadPath = element.uploadPath;
    //   this.organizationDocumentCode = element.organizationDocumentCode;
    //   this.uploadButton = true;
    //   this.uploadSubmitted = true;
    //   this.service.uploadData.note = element.note;
    //   this.service.uploadData.orgDocWiseCommentCode = element.orgDocWiseCommentCode;

    // }
  }



  getNext(event: PageEvent) {
    //console.log(event);
    this.page = event.pageIndex;
    this.getallDocuments();

  }

  deleteUpload(documentCode) {
    //console.log(documentCode);
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete this document?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.softDeletePermissionsByPermissionsCode(documentCode, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              setTimeout(() => {
                this.getallDocuments();
              }, 1000);
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
            } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })

        } catch (error) {
          //console.log(error)
        }

      }
    });



  }



  fileDownload(fileUrl) {
    window.open(this.service.common.superAdminUrl + fileUrl);
  }

  getallNotes(organizationDocumentCode) {

    try {

      this.service.getOrganizationDocumentWiseNoteCommentByOrganizationDocumentCode(organizationDocumentCode, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.allNotes = response.data.orgDocCmtList;

        }
        else {
          this.allNotes = [];
        }
      });
    } catch (error) {

    }
  }

  viewDocNotes(organizationDocumentCode) 
  {
    this.service.common.progressLoader = true;
    this.getallNotes(organizationDocumentCode);

    setTimeout(() => {
      this.service.common.progressLoader = false;
      const dialogRef = this.dialog.open(AdddocumentnoteComponent, {
        width: '800px',
        disableClose: true,
        // data: { notes: this.allNotes, DocumentCode: organizationDocumentCode }
      });

      dialogRef.componentInstance.data = { notes: this.allNotes, DocumentCode: organizationDocumentCode };

        const sub = dialogRef.componentInstance.onAddUpdateRefreshData.subscribe((data: any) => {
            // debugger
            // console.log('viewDocNotes', data);
            // onAddUpdateRefreshData witout close popup MatDialog [Refresh Data without close popup]
            if(data?.isUpdate){
              this.getallDocuments();
            }
        });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.getallDocuments();
        }
      });

    }, 2000);

  }

  getOrgNotes() {
    this.service.getOrganizationWiseDocumentNoteCommentByOrganizationCode(this.localStorage.get('orgCode'), (response) => {
      //console.log(response);
      if (response.responsecode == 200) {
        this.allOrgNotes = response.data.organizationWiseDocumentCommentResponseList;

      }
      else {
        this.allOrgNotes = [];

      }
    });
  }

  vieworgNotes() {
    this.service.common.progressLoader = true;
    this.getOrgNotes();
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    });
    setTimeout(() => {
      this.service.common.progressLoader = false;
      const dialogRef = this.dialog.open(AddOrganizaionnoteComponent, {
        width: '800px',
        disableClose: true,

        data: { orgNotes: this.allOrgNotes }

      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.getallDocuments();
        }
      });
    }, 1000);
  }
  onCertificateUpload(event,indexData) {
    //console.log(event);
    this.showLoader = true;
    if (event.target.files.length > 0) {

      let allowedExtension = ['TXT', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'PDF', 'PAGES', 'AI', 'PSD', 'TIFF', 'DXF', 'SVG', 'EPS', 'PS', 'TTF', 'XPS', 'ZIP', 'RAR'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      let isValidFile = false;
      //////console.log(fileExtension);
      for (let index in allowedExtension) {
        if (fileExtension === allowedExtension[index].toLowerCase() && event.target.files[0].size <= 5000000) {
          isValidFile = true;
          //console.log('111111111');
          const formData = new FormData();
          formData.append("file", event.target.files[0]);
          ////console.log(formData,event.target.files[0]);
          this.service.common.uploadAllTypeFile(formData, (res) => {
            //console.log(res);
            //console.log(this.service.allMasterdocs);
            if (res.responsecode == 200) {
              this.service.allMasterdocs[indexData].uploadPath = res.filename;
              this.uploadButton = true;
              this.showLoader = false;
            }
            else {
              this.common.errorMessage = true;
              this.common.message = res.message;
              this.showLoader = false;
            }

          });
          break;
        }
      }

      if (!isValidFile) {
        this.showLoader = false;
        //////console.log(isValidFile);Allowed Extensions are : .' + allowedExtension.join(', .') + ' and
        this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 5MB';
        this.service.common.errorMessage = true;
        //event.srcElement.value = '';
        event.target.value = '';

      }
    }

  }

  uploadDoc(data) {
    //console.log(data);
    //console.log(this.productForm);
    //console.log(this.service.uploadData);
    //console.log(this.service.allMasterdocs[data]);
    // if (this.productForm.value) {

    //   try {
    //     this.service.uploadData.organizationCode = this.localStorage.get('orgCode');
    //     this.service.uploadData.userCode = this.localStorage.get('userCode');
    //     //console.log(this.service.uploadData);
    //     if (this.organizationDocumentCode) {
    //       this.service.updateOrganizationDocument(this.service.uploadData, this.organizationDocumentCode, (response) => {
    //         //console.log(response);
    //         if (response.responsecode == 200) {

    //           this.cancelUpload();
    //           this.service.common.message = response.message;
    //           this.service.common.progressLoader = false;
    //           this.service.common.successMessage = true;
    //         } else {

    //           this.service.common.message = response.message;
    //           this.service.common.progressLoader = false;
    //           this.service.common.errorMessage = true;
    //         }
    //       })
    //     } else {
    //       this.service.saveOrganizationDocument(this.service.uploadData, (response) => {
    //         //console.log(response);
    //         if (response.responsecode == 200) {

    //           this.cancelUpload();
    //           this.service.common.message = response.message;
    //           this.service.common.progressLoader = false;
    //           this.service.common.successMessage = true;
    //         } else {

    //           this.service.common.message = response.message;
    //           this.service.common.progressLoader = false;
    //           this.service.common.errorMessage = true;
    //         }
    //       })

    //     }

    //   } catch (error) {
    //     //console.log(error)
    //   }
    // }
    // else {
    //   this.uploadSubmitted = false;
    // }

  }

  cancelUpload() {
    this.uploadSubmitted = true;
    this.service.uploadData.documentName = null;
    this.service.uploadData.expiryDate = null;
    this.service.uploadData.uploadPath = null;
    this.organizationDocumentCode = null;
    this.service.uploadData.note = null;
    this.service.uploadData.orgDocWiseCommentCode = null;
    this.uploadButton = false;

  }


}
