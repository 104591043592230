import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFloatOnly]'
})
export class InputNumberFloatOnly {

  private regex: RegExp = new RegExp(/^-?\d+(\.\d*)?$/);
  private specialKeys: Array<string> = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'End', 'Home', '-'];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow special keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Allow decimal point
    if (event.key === '.' && !this.el.nativeElement.value.includes('.')) {
      return;
    }

    // Prevent invalid input
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    if (pastedText && !String(pastedText).match(this.regex)) {
      event.preventDefault();
    }
  }
}
