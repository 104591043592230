
  <div class="container">
  <div class="row">
      <div class="col-12">
        <h6 class="mini-header">Staff Exceptions</h6>
        </div>

        <form [formGroup]="exceptionForm">
        <div class="row g-1">

        <!-- <div class="col-4">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Staff</mat-label>
            <mat-select formControlName="excpStaff">
              <mat-option>
          <ngx-mat-select-search  [placeholderLabel]="'Search staff'"  [noEntriesFoundLabel]="'Staff not found'"
          [formControl]="staffSeachInput"></ngx-mat-select-search>
        </mat-option>
              <mat-option *ngFor="let staff of staffList_Filter"  [value]="staff">
                {{staff.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <div class="col-4">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Customer</mat-label>
                <mat-select formControlName="excpCustomer" (selectionChange)="onCustomerChange()">
                  <mat-option>
              <ngx-mat-select-search  [placeholderLabel]="'Search...'"  [noEntriesFoundLabel]="'Record not found'"
              [formControl]="customerSeachInput"></ngx-mat-select-search>
            </mat-option>
                  <mat-option *ngFor="let customer of customerList_Filter"  [value]="customer">
                    {{customer.customerName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>

        <div class="col-4">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
            <mat-select formControlName="excpJobSite" multiple>
              <app-select-check-all [model]="exceptionForm.get('excpJobSite')" [values]="customerSiteList">
              </app-select-check-all>
             <mat-option *ngFor="let site of customerSiteList" [value]="site">
                {{site.siteName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Notes</mat-label>
            <input matInput class="form-control" formControlName="excpNotes">
          </mat-form-field>
        </div>

        <div class="col-1 text-center">
          <button mat-raised-button color="primary" (click)="addStaffInBlockList()">Add</button>
        </div>
       </div>
       </form>

    </div>

    <div class="row">
   
     <div class="col-12">
      <div class="table-scroll-height">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-responsiv">

          <ng-container matColumnDef="staffIcon" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef class="avatarMaxWidth"></th>
          <td mat-cell *matCellDef="let element" class="common-icon-round text-nowrap avatarMaxWidth">
            <img alt="No Image" class="td_avatar_img"
              [src]="element?.customerLogo ? (image_path + element.customerLogo) : 'assets/common_images/profile.jpg'" />
          </td>
        </ng-container>

        <ng-container matColumnDef="name" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef class="text-nowrap">Customer</th>
          <td mat-cell *matCellDef="let element" class="text-nowrap"> {{element.customerName | capitalize}} </td>
        </ng-container>

        <ng-container matColumnDef="jobSite" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef class="text-nowrap">{{ 'main.labels.Job Site' | translate }}</th>
          <td mat-cell *matCellDef="let element; let idx = index"> {{element.siteName}} </td>
        </ng-container>

        <ng-container matColumnDef="notes" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef>Note</th>
          <td mat-cell *matCellDef="let element"> {{element.note}} </td>
        </ng-container>

        <ng-container matColumnDef="action" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <mat-icon class="text-danger" (click)="removeStaffFromBlockList(element)">delete</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div *ngIf="!blockedList || blockedList.length === 0" class="p-3 noList text-danger">No records found</div>
          </div>
     </div>
    </div>
</div>