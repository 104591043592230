<div class="job-card-parent-div">
  <ng-template [ngIf]="allJobList.length > 0" [ngIfElse]="_noDataFound">
  <div class="viewHeight">
     <ng-container *ngFor="let job of allJobList; let cjIdx = index; let last = last">
        <mat-card class="mx-auto card-appointment mt-3 mb-2">
           <div class="Card_JobDetails">
              <div class="row job-content container mb-0 card-border ms-0 pb-3 pt-2" [style.border-color]="job?.jobColor">

               
                  <app-cust-short-job-type-dts [shjobTypeDtsInput]="{
                                  date : job?.jobDateFixedFormat ,
                                  customerName : job.customerName,
                                  siteName : job.siteName,  
                                  siteAddress : job?.siteAddress, 
                                  jobTypeName : job.jobTypeName,
                                  jobTypeIcon : job.jobTypeIcon,
                                  rateCard : job?.showRateCard,  
                                  jobTypeColor : job?.jobColor
                                 }">
                                 <!-- shift : job?.startTime +'-'+ job?.endTime  -->
                  </app-cust-short-job-type-dts>
                   
                  <app-shift-with-job-type
                  [shiftWithJobTypeInput]="{
                       jobTypeWithShiftList : job,
                       customerName         : job.customerName ,
                       siteName             : job.siteName ,
                       siteAddress          : job.siteAddress ,
                       appointmentsCode     : job.appointmentsCode,
                       customerCode         : job.customerCode,
                       siteAddressCode      : job.siteAddressCode,
                       jobDateFixedFormat   : job.jobDateFixedFormat,
                       date                 : job.jobDateFixedFormat
                  }"
 
                  [allowScheduledStaff_Icon]="false"
                   [allowActions]="{
                        allowEdit: false,
                        allowDelete: false,
                        allowAssignStaff: false,
                        allowNotifyStaff: false,
                        allowToViewNotifiedStaff: false,
                        allowToCopyJob: false,
                        allowView: false
        }"
                  [allowBreaks]="false">
                   <!-- [status]="status" -->
              </app-shift-with-job-type>
                     
                    <ng-template [ngIf]="job?.jobDurationDetailsList?.length > 0 ">

                     <ng-container *ngFor="let jobDuration of job?.jobDurationDetailsList">
                         <admin-scheduled-staff-card [scheduleStaff_Input_Data]="jobDuration" (emitter_refreshList)="refreshData($event)">
                        </admin-scheduled-staff-card>
                     </ng-container>

                    </ng-template>

                      
            
                 <!-- <div class="col-md-12 col-lg-12">
                    <div class="row">
                       <div class="col-1">
                          <div class="row">
                             <div class="card-jobdate" [style.color]="job?.jobColor">
                                <span>{{ weekDay(job?.jobDate) }}</span>
                                <span class="month-day">{{ monthDay(job?.jobDate) }}</span>
                                 <span>{{ monthName(job?.jobDate) }}</span>
                                 
                             </div>
                          </div>
                       </div>
                       <div class="col-3">
                          <div class="row">
                             <div class="col">
                                <div class="row pt-2">
                                   <small>Customer Name </small><br>
                                   <p>{{job?.customerName}}</p>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div class="col-3">
                          <div class="row pt-2">
                             <small>{{ 'main.labels.Job Site' | translate }} </small><br>
                             <p [title]="job.siteName"> {{job?.siteName}}</p>
                          </div>
                       </div>
                       <div class="col-3">
                          <div class="row pt-2">
                             <div class="col p-0 m_width_50">
                                <div class="w-100">
                                   <img class="img_jobtype"
                                      [src]="job.jobTypeIcon ? img_serverPath+job.jobTypeIcon : 'assets/common_images/profile.jpg'"
                                      onerror="this.src='assets/common_images/profile.jpg';">
                                </div>
                             </div>
                             <div class="col ps-1">
                                <div class="row ps-2">
                                   <small>Job Type </small><br>
                                   <p>{{job?.jobTypeName}}</p>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                             <div class="row div-border mb-3 mt-3"></div>
                             <div class="col-lg-12">
                                <div class="row g-2">
                                   <div class="col-3">
                                      <div class="row">
                                         <div class="col-3 p-0 m_width_50 ps-2">
                                            <div class="w-100">
                                               <img class="img_jobtype"
                                                  [src]="job?.workerLogo ? img_serverPath+job.workerLogo : 'assets/common_images/profile.jpg'"
                                                  onerror="this.src='assets/common_images/profile.jpg';">
                                            </div>
                                         </div>
                                         <div class="col ps-2 highlight_staff">
                                            <div class="row ps-1 ms-1">
                                               <small>Staff Name </small><br>
                                               <p class="pointer" [class.cursor-pointer]="userType != 'Customer'">{{job.workerName}}</p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                   <div class="col-3 ps-2">
                                      <small>Mobile No </small><br>
                                      <p>{{job.mobileNo }}</p>
                                   </div>
                                   <div class="col-3 ps-2">
                                       <small>Shift</small><br>
                                       <p class="text-nowrap w-100">{{job?.startTime}}&nbsp;to &nbsp;{{job?.endTime}}</p>
                                   </div>
                                   <div class="col-3 ps-2 text-capitalize">
                                    <ng-template [ngIf]="job.isAllowClockin === true">
                                          <small>Status </small><br>
                                          <p>{{job.status }}</p>
                                       </ng-template>
                                </div>
                                      <ng-template [ngIf]="job.isAllowClockin === true">
                                         
                                         <ng-template [ngIf]="job.status !== 'accepted'">
                                            <ng-template [ngIf]="job.inBreak === true">
                                               <div class="col-2 mt-4">
                                                  <small>Break Time </small><br>
                                                  <div style="color: blue;">{{ job.breakStart_timer$ | async }}</div>
                                               </div>
                                            </ng-template>
                                         </ng-template>
                                         <ng-template [ngIf]="job.clockinoutDetailList.length > 0">
                                            <div class="col-lg-3 c_IO_tbl mt-4">
                                               <table>
                                                  <thead>
                                                     <tr>
                                                        <th>Clock In</th>
                                                        <th>Clock Out</th>
                                                     </tr>
                                                  </thead>
                                                  <tbody>
                                                     <tr *ngFor="let c_IO of job.clockinoutDetailList">
                                                        <td>{{c_IO.clockInTime ? c_IO.clockInTime : '--'}}</td>
                                                        <td [matTooltip]="c_IO.isNextDate ? c_IO.clockInDateTime +' - '+ c_IO.clockOutDateTime : ''">{{c_IO.clockOutTime ? c_IO.clockOutTime : '--'}}
                                                            <span class="half-moon" *ngIf="c_IO.isNextDate">
                                                            <img src="../../../assets/icons/svg/half-moon.svg" alt="">
                                                           </span>
                                                        </td>
                                                     </tr>
                                                  </tbody>
                                               </table>
                                            </div>
                                         </ng-template>
                                         <div class="col-2 mt-4">
                                            <ng-template [ngIf]="job.clockInOutButton && job.clockInOutButton !== null"> 
                                               <button class="btn-jobcard nowrapTxt" [style.color]="job?.jobColor"
                                               (click)="saveCheckInDetail(job)">
                                               {{job.clockInOutButton}} 
                                               </button>
                                            </ng-template>
                                         </div>
                                      </ng-template>
                                   <ng-template [ngIf]="!job.isPassedEndTime">
                                      <ng-template [ngIf]="job.status === 'accepted'">
                                         <div class="col-2 mt-4">
                                            <button class="btn-jobcard me-2 nowrapTxt" [style.color]="job?.jobColor"
                                            (click)="changeStaff(job.appointmentsDurationCode)">
                                            Change
                                            </button>
                                            <button class="btn-jobcard nowrapTxt"
                                            [style.color]="job?.jobColor"
                                            (click)="UnAssignStaff(job.workerName, job.appointmentsDurationCode)">
                                            UnAssign
                                            </button>
                                         </div>
                                      </ng-template>
                                   </ng-template>
                                </div>
                             </div>
                 </div> -->
            
               </div>
           </div>
        </mat-card>
     </ng-container>
  </div>
  </ng-template>
  <ng-template #_noDataFound>
     <div class="row no-job-found-div mt-4" *ngIf="!is_Loading">
        <span class="text-danger no-job-found-text">No record found</span>
     </div>
     <ng-template [ngIf]="is_Loading">
        <div class="div w-100 mt-5 text-center">
           <mat-spinner class="center_50_px_spinner"></mat-spinner>
           <p class="mt-3 mb-3 w-100 text-center">Please Wait ...</p>
        </div>
     </ng-template>
  </ng-template>
</div>

<ng-template #assign_popup>
   <!-- <mat-card>
       <mat-card-content> -->
   <div class="row  ">
     <div class="col-md-6 align-self-center">
       <h6 class="dialog-header pt-2">Assign Job</h6>
     </div>
     <div class="col-md-6">
       <div class="row justify-content-end">
         <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
       </div>
     </div>
   </div>
 
   <div class="row">
     <ul class="{{filtered_eligibleStaffsList.length > 4 ? 'poupup-content' : ''}}"
       *ngIf="filtered_eligibleStaffsList.length > 0">
       <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedStaff">
         <li class="mt-2" *ngFor="let element of filtered_eligibleStaffsList">
           <div class="row">
             <div class="col-md-1 text-center align-self-center ">
               <mat-radio-button class="ms-3" [checked]="element.isAssigned" [value]="element"></mat-radio-button>
             </div>
             <div class="col-md-11 font-card">
               <mat-card class="example-card">
                 <mat-card-content>
                   <div class="row">
                     <div class="col-md-2">
                       <img class="staff-image me-2"
                          [alt]="element.worker.name" [src]="img_serverPath + element.worker.workerLogo"
                         onerror="this.src='assets/common_images/profile.jpg';" />
                       <!-- <img *ngIf="!element.worker.workerLogo" [src]="'assets/common_images/profile.jpg'"
                         class="staff-image  me-2"> -->
                     </div>
                     <div class="col-md-5">
                       <p class="name mb-0 ms-2">{{element.worker.name}}</p>
                       <small class="email mb-0 ms-2">{{element.worker.email}}</small>
                     </div>
 
                   </div>
 
                 </mat-card-content>
               </mat-card>
 
             </div>
 
           </div>
         </li>
       </mat-radio-group>
 
     </ul>
 
     <div class="row d-flex align-items-center spinner" *ngIf="showSpinner && filtered_eligibleStaffsList.length === 0">
       <div class="col-lg-1 text-end">
         <mat-spinner></mat-spinner>
       </div>
       <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
     </div>
 
     <div class="row" *ngIf="!showSpinner && filtered_eligibleStaffsList.length === 0">
       <div>
         <span class="text-danger">No Staff Available</span>
       </div>
     </div>
   </div>
 
 
   <div class="row mt-3">
     <div class="col-lg-12 text-end">
       <button class="me-3" mat-button (click)="closePopup()">Cancel</button>
       <button *ngIf="filtered_eligibleStaffsList.length > 0" mat-raised-button color="primary"
         (click)="AssignStaff()">Assign</button>
 
     </div>
   </div>
 
 </ng-template>