import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../invoice-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';
import { UserRegistrationService } from '../../../dashboards/providers-dashboard/user-registration/user-registration.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ProfileService } from 'src/app/dashboards/providers-dashboard/profile/profile.service';
import { CommonService } from 'src/app/shared/common.service';
import { SaveAndPostInvoiceComponent } from 'src/app/components/generate-invoice/save-and-post-invoice/save-and-post-invoice.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {
  groupBy = 'jobType';
  myWindow:any;
  invoiceData: any = null;
  img_serverPath:string ='';
  tipEdit = {};
  exceedValue: boolean = false;
  overTimeRateEdit = {};
  regularRateEdit = {};
  date = new Date();
  formatedTodayDate = moment(this.date).format('YYYY-MM-DD');
  showInvoiceTodayDate = moment(this.date).format('MM-DD-YYYY');
  totalSumValue: number = 0;
  adjustmentReason: any;
  formatedDateBillDueDate = moment(this.date).add(15, 'days').format('YYYY-MM-DD');
  userType;
  organizationDetail: any;
  organizationLogo: any;
  isPreviewLoading:boolean = false;
  isDownloadPDFLoading: boolean = false;
  isSendPdfLoading: boolean = false;
  isInvoicePDF_view: boolean = false;
  protected noteList : any[] = [] ;
  hasPermission:IBtnControlsPermission ;

  constructor(
    public invoiceService: InvoiceService,
     public router: Router,
      public route: ActivatedRoute,
       public service: UserRegistrationService,
        public profileService: ProfileService,
        private _commonSVC : CommonService,
        public dialog: MatDialog, public dialogRef: MatDialogRef<SaveAndPostInvoiceComponent>,
      ) {
    // this.updateSomeOfTotalValue = debounce(this.updateSomeOfTotalValue, 700);
  }

  ngOnInit() {
    this.hasPermission =  this.service.common.getBtnEventPermissions("Invoice");
    this.getOrgDetail();
    this.getSettingBySettingName('Organization Logo');
    this.userType = this.service.local.get('user_type');
    this.img_serverPath =  this.invoiceService.service.getSuperAdmin() + '/downloadFile/';
      this.route.queryParams.subscribe((data) => {
        if (data && data?.billing) {
          // console.log('Params_Data', data);
          let elem = JSON.parse(data.billing);
          this.invoiceData = elem;
          // console.log('InvoiceRecieved_Detail: ', elem);
          if(this.invoiceData && this.invoiceData?.invoiceCode && (!this.invoiceData.isInvoiceCreate || this.invoiceData.isInvoiceCreate === false || this.invoiceData.isInvoiceCreate === 'false')){
          this.getInvoiceDetailByInvoiceId(this.invoiceData.invoiceCode, 'jobType');
          } else {
            this.isInvoicePDF_view = true;
          }
        }
      });      
     }

  /* Name: Vivek Chauhan
    use: Filter job type data by date and JobType filter */
  changeGroupBy(event) {
    //console.log('event', event);
    this.groupBy = event.value;
    if(event?.value){
      this.getInvoiceDetailByInvoiceId(this.invoiceData.invoiceCode, event.value);
    }
  }
  
  updatedTotalValue(editedItem: any){
    editedItem.totalAmount = ((editedItem?.workHours ? editedItem.workHours : 0) * ( editedItem?.ratePerHours ? editedItem.ratePerHours : 0)) + ((editedItem?.workHoursOverTime ? editedItem.workHoursOverTime : 0) * ( editedItem?.ratePerHoursOverTime ? editedItem.ratePerHoursOverTime : 0)) + (editedItem?.tip ? editedItem.tip : 0);
    this.updateSomeOfTotalValue();
    // console.log('updateTotalTaxValue_1: ', editedItem);    
    return editedItem;
  } 

  updateTotalTaxValue(isAutoUpdate = true) {
    const detail = this.invoiceData.generatedInvoiceDetail;
  
    // console.log('TaxAmount_0:', detail.totalAmount, detail.adjustmentAmount, detail.taxAmount, detail.taxPercent);
  
    const totalAmount = detail.totalAmount || 0;
    const adjustmentAmount = detail.adjustmentAmount || 0;
    const taxPercent = detail.taxPercent || 0;
  
    if (isAutoUpdate && taxPercent) {
      detail.taxAmount = ((totalAmount + adjustmentAmount) * taxPercent) / 100;
      // console.log('TaxAmount_1:', detail.taxAmount);
    }
  
    detail.totalFinalAmount = totalAmount + adjustmentAmount + (detail.taxAmount || 0);
    detail.finalTotalAmount = detail.totalFinalAmount;
  
    // console.log('TaxAmount_2:', detail.taxAmount);
  }

  adjustmentValueUpdate(event){
    let inputValue = (event.target.value.trim() && event.target.value) ? parseFloat(event.target.value) : 0;
    // console.log('AdjustmentValueUpdate: ', inputValue, event.target.value);
    if(inputValue && (inputValue < 0) && ((this.invoiceData?.generatedInvoiceDetail?.totalAmount?.toFixed(2) <= Math.abs(inputValue)))){
      this.invoiceService.common.errorMessage = true;
      this.invoiceService.common.message = 'The adjustment amount should not exceed the subtotal.';
      this.exceedValue = true;
    } else {
      if(inputValue){
        this.exceedValue = false;
        this.invoiceData.generatedInvoiceDetail.adjustmentAmount = inputValue;
        this.updateTotalTaxValue(true);
      } else {
        this.exceedValue = false;
        this.invoiceData.generatedInvoiceDetail.adjustmentAmount = 0;
        this.updateTotalTaxValue(true);
      }
    }
  }

  taxPercentValueUpdate(event){
    // console.log('taxPercentValueUpdate: ', event.target.value);
      if(event.target.value){ {
        this.invoiceData.generatedInvoiceDetail.taxAmount = event.target.value ? parseFloat(event.target.value) : 0;          
        // console.log('taxPercentValueUpdate: ', this.invoiceData.generatedInvoiceDetail.taxAmount);      
        this.updateTotalTaxValue(false);
      }
    }
  }


  onEditTip(jobIdx, jobIdxChild, editedItem: any){
    const oldTipValue = editedItem.tip;
    let tipValEle = <HTMLInputElement> document.getElementById('tip_'+jobIdx+'_'+jobIdxChild);
    let tipNumValue = tipValEle.value ? parseFloat(tipValEle.value) : 0;
    this.tipEdit[jobIdx+'_'+jobIdxChild] = false;
    if(oldTipValue === tipNumValue){
    } else {
      editedItem.tip = tipNumValue ? tipNumValue : 0;
      this.updatedTotalValue(editedItem);
      return editedItem;
    }
  }  

  onEditRegularRate(jobIdx, jobIdxChild, editedItem: any){
    const oldRatePerHoursValue = editedItem.ratePerHours;
    let rateValEle = <HTMLInputElement> document.getElementById('rate_'+jobIdx+'_'+jobIdxChild);
    let rateNumValue = rateValEle.value ? parseFloat(rateValEle.value) : 0;
    this.regularRateEdit[jobIdx+'_'+jobIdxChild] = false;
    if(oldRatePerHoursValue === rateNumValue){
    } else {
      editedItem.ratePerHours = rateNumValue ? rateNumValue : 0;
      this.updatedTotalValue(editedItem);
      return editedItem;
    }
  } 

  onEditOverTimeRate(jobIdx, jobIdxChild, editedItem: any){
    const oldRatePerHoursOverTimeValue = editedItem.ratePerHoursOverTime;
    let rateValEle = <HTMLInputElement> document.getElementById('rateOt_'+jobIdx+'_'+jobIdxChild);
    let rateNumValue = rateValEle.value ? parseFloat(rateValEle.value) : 0;
    this.overTimeRateEdit[jobIdx+'_'+jobIdxChild] = false;
    if(oldRatePerHoursOverTimeValue === rateNumValue){
    } else {
      editedItem.ratePerHoursOverTime = rateNumValue ? rateNumValue : 0;
      this.updatedTotalValue(editedItem);
      return editedItem;
    }
  } 

  editElement(elenentName, jobIdx, jobIdxChild){
    if(elenentName === 'overTimeRateEdit'){
      this.overTimeRateEdit[jobIdx+'_'+jobIdxChild] = true;
      setTimeout(() => {
        document.getElementById('rateOt_'+jobIdx+'_'+jobIdxChild).focus();
      }, 1000);
    }
    if(elenentName === 'regularRateEdit'){
      this.regularRateEdit[jobIdx+'_'+jobIdxChild] = true;
      setTimeout(() => {
        document.getElementById('rate_'+jobIdx+'_'+jobIdxChild).focus();
      }, 1000);
    }
  }

  updateSomeOfTotalValue(){
    if(this.invoiceData?.generatedInvoiceItems?.length > 0){
      let items = this.invoiceData?.generatedInvoiceItems.map((p_it) => {
        let newTotV = 0;
        p_it.invoiceDetailsResponseList.map((c_it) => {
          let newTotalAmount = ((c_it?.workHours ? c_it.workHours : 0) * ( c_it?.ratePerHours ? c_it.ratePerHours : 0)) + ((c_it?.workHoursOverTime ? c_it.workHoursOverTime : 0) * ( c_it?.ratePerHoursOverTime ? c_it.ratePerHoursOverTime : 0)) + (c_it?.tip ? c_it.tip : 0);
          newTotV = newTotV + c_it.totalAmount;
          return c_it;
        });
        return newTotV;
      });
      this.invoiceData.generatedInvoiceDetail.totalAmount = items && items?.length > 0 && items.reduce((sum, num) => {
        // console.log('SubNum_2: ', sum, num);
        return sum + num;
      });
      this.updateTotalTaxValue(true);
    }
  }

  onDateChangeE(event) {
    this.formatedDateBillDueDate = moment(event.value, 'MM-DD-YYYY').format('YYYY-MM-DD');
  }

  saveInvoice(){
    let cloneInvoiceData = this.invoiceData && JSON.parse(JSON.stringify(this.invoiceData));
    let newArr = cloneInvoiceData?.generatedInvoiceItems.map((item) => {
      if(item){
        let itemRe = item.invoiceDetailsResponseList;
        return itemRe;
      }      
      return item;
    });
    let saveInvoiceReq = {
      customerCode: cloneInvoiceData?.selectedCustomerFromDropdown?.customerCode,
      customerName: cloneInvoiceData?.selectedCustomerFromDropdown?.customerName,
      deadlinesPayment: this.formatedDateBillDueDate,
      invoiceDate: this.formatedTodayDate,
      invoiceDetailsReqList: newArr && newArr.length > 0 && this.flattenArray(newArr),
      invoiceCode: this.invoiceData?.generatedInvoiceDetail?.invoiceCode,
      invoiceStatus: 'Saved',
      organizationCode: this.organizationDetail?.organizationCode,
      taxAmount: this.invoiceData?.generatedInvoiceDetail?.taxAmount ? this.invoiceData.generatedInvoiceDetail.taxAmount : 0,
      totalAmount: cloneInvoiceData?.generatedInvoiceDetail?.totalAmount,
      finalTotalAmount: cloneInvoiceData?.generatedInvoiceDetail?.totalFinalAmount,
      workHours: cloneInvoiceData?.generatedInvoiceDetail?.totalHours,
      noteRequest : this._commonSVC.noteRequest ? this._commonSVC.noteRequest : { "isPublic": false, "note": ""},
      adjustmentAmount: this.invoiceData?.generatedInvoiceDetail?.adjustmentAmount ? this.invoiceData.generatedInvoiceDetail.adjustmentAmount : 0,
      adjustmentReason: this.adjustmentReason,
    }
    // console.log('saveInvoiceReq: ', saveInvoiceReq);
    // return;
    this.invoiceService.saveInvoice(saveInvoiceReq, (response) => {
      this.invoiceService.common.progressLoader = false;
      if (response.responsecode == 200) {
        this.invoiceData.invoiceCode = response?.data?.invoiceCode;
        this.invoiceData.invoiceId = response?.data?.invoiceId;
        this.invoiceData.selectedCustomerFromDropdown.invoiceStatus = response?.data?.invoiceStatus;
        this.getInvoiceDetailByInvoiceId(this.invoiceData.invoiceCode, 'jobType');
        this._commonSVC.resetNotesRequestForm();
        this.invoiceService.common.successMessage = true;
        this.invoiceService.common.message = response.message;
      } else {
        this.invoiceService.common.errorMessage = true;
        this.invoiceService.common.message = response.message;
      }
      })
  }

  flattenArray(arr) {
    let flattenedArray = [];    
    arr.forEach(item => {
      if (Array.isArray(item)) {
        flattenedArray = flattenedArray.concat(this.flattenArray(item));
      } else {
        flattenedArray.push(item);
      }
    });    
    return flattenedArray;
  }

  adjustmentReasonUpdate(event){
    this.adjustmentReason = event.target.innerText;
  }

  getInvoiceDetailByInvoiceId(invoiceCode: any, filterBy: any){
    this.service.common.progressLoader = true;
    this.invoiceService.getInvoiceDetail(invoiceCode, filterBy, (res) => { 
      this.service.common.progressLoader = false; 
        if (res.responsecode == 200) {
          // console.log('InvoiceResData: ', res.data);
          let cloneSelectedList = res?.data && JSON.parse(JSON.stringify(res.data));
          this.invoiceData.invoiceId = cloneSelectedList.invoiceId;
          this.invoiceData.generatedInvoiceItems = cloneSelectedList.invoiceResponseMidList;
          this.invoiceData.generatedInvoiceDetail = cloneSelectedList;
          this.invoiceData.selectedCustomerFromDropdown = cloneSelectedList.customer;
          this.isInvoicePDF_view = true;
          this.noteList = res.data.noteList ;
          this.adjustmentReason = res.data.adjustmentReason;
          this.updateTotalTaxValue(true);
        }
        else {
          this.isInvoicePDF_view = false;
          this.service.common.errorMessage = true;
          this.service.common.message = res.message;
        }
    })
   }

    preview_PDF(div_id){
      this.isPreviewLoading = true;
      let data = document.getElementById(div_id);  
      html2canvas(data, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
        const contentWidth = (data.offsetWidth > 0) ? data.offsetWidth : 1920;
        const contentHeight = (data.offsetHeight > 0) ? data.offsetHeight : 1024;
        var imgData = canvas.toDataURL('image/png');
        // Create an image element to display the PDF
        var img = new Image();
        img.src = imgData;
        // Set the width and height of the PDF image
        img.style.width = contentWidth + 'px';
        img.style.height = contentHeight + 'px';
        var doc = new jspdf({
          orientation: 'portrait', // Set landscape/portrait mode
          unit: 'px', // Set unit to pixels
          format: [contentHeight, contentWidth] // Set custom dimensions for high resolution (1920x1080 for example)
        });
        var imgData = imgData;
        var width = doc.internal.pageSize.getWidth();
        var height = contentHeight - 300;
        doc.addImage(imgData, 'JPEG', 0, 0, width, height, 'someAlias', 'FAST');
        this.isPreviewLoading = false;
        window.open(doc.output('bloburl'));
      }); 
    }

    save_PDF(div_id){
      this.isDownloadPDFLoading = true;
      let fileName = this.invoiceData?.invoiceId ? this.invoiceData.invoiceId : 'download';
      let data = document.getElementById(div_id);
      html2canvas(data, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
        const contentWidth = (data.offsetWidth > 0) ? data.offsetWidth : 1920;
        const contentHeight = (data.offsetHeight > 0) ? data.offsetHeight : 1024;
        var imgData = canvas.toDataURL('image/png');
        // Create an image element to display the PDF
        var img = new Image();
        img.src = imgData;
        // Set the width and height of the PDF image
        img.style.width = contentWidth + 'px';
        img.style.height = contentHeight + 'px';
        var doc = new jspdf({
          orientation: 'portrait', // Set landscape/portrait mode
          unit: 'px', // Set unit to pixels
          format: [contentHeight, contentWidth] // Set custom dimensions for high resolution (1920x1080 for example)
        });
        var imgData = imgData;
        var width = doc.internal.pageSize.getWidth();
        var height = contentHeight - 300;
        doc.addImage(imgData, 'JPEG', 0, 0, width, height, 'someAlias', 'FAST');
        this.isDownloadPDFLoading = false;
        // PDF.save('Invoice_#'+ fileName + '.pdf');  
        doc.save('Invoice_#'+ fileName + '.pdf');     
    });
    }

    save_PDF_textSelectable(div_id){
      this.isDownloadPDFLoading = true;
      let fileName = this.invoiceData?.invoiceId ? this.invoiceData.invoiceId : 'download';
      let htmlContent = document.getElementById(div_id);
      window["html2canvas"] = html2canvas;
      var doc = new jspdf('p', 'pt',  'a4');
      
      doc.html(htmlContent, {
        callback: function(doc) {
          doc.setTextColor(0,0,0);
          this.isDownloadPDFLoading = false;
            doc.save(fileName);
        },
        margin: [20, 20, 20, 20],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            letterRendering: true,
            logging: false,
            scale: .42
        }
    });
  }

    send_PDF_email(div_id){
      this.isSendPdfLoading = true;
      let fileName = this.invoiceData?.invoiceId ? this.invoiceData.invoiceId : 'download';
      let data = document.getElementById(div_id);
      html2canvas(data, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
        const contentWidth = (data.offsetWidth > 0) ? data.offsetWidth : 1920;
        const contentHeight = (data.offsetHeight > 0) ? data.offsetHeight : 1024;
        var imgData = canvas.toDataURL('image/png');
        // Create an image element to display the PDF
        var img = new Image();
        img.src = imgData;
        // Set the width and height of the PDF image
        img.style.width = contentWidth + 'px';
        img.style.height = contentHeight + 'px';
        var doc = new jspdf({
          orientation: 'portrait', // Set landscape/portrait mode
          unit: 'px', // Set unit to pixels
          format: [contentHeight, contentWidth] // Set custom dimensions for high resolution (1920x1080 for example)
        });
        var imgData = imgData;
        var width = doc.internal.pageSize.getWidth();
        var height = contentHeight - 300;
        doc.addImage(imgData, 'JPEG', 0, 0, width, height, 'someAlias', 'FAST');
      const attachmentName = 'Invoice_#'+ fileName + '.pdf';
      var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});      
      if(attachmentName && this.invoiceData?.invoiceCode){
        this.uploadFile(doc.output('blob'), attachmentName);
      }   
    });
    }

    uploadFile(pdfFile: Blob, attachmentName: any) {
      let newData = {
        invoiceCode: this.invoiceData?.generatedInvoiceDetail?.invoiceCode,
        customerCode: this.invoiceData?.generatedInvoiceDetail?.customerCode
      }
      const formData: FormData = new FormData();
      //   formData.append("file", pdfFile, attachmentName);
      //   formData.append("invoiceCode", invoiceCode);
      //   console.log('uploadFile: ', formData);
        const dialogRef = this.dialog.open(SaveAndPostInvoiceComponent, { 
          width: '50%',
          disableClose: true,
          panelClass: 'popup-pos',
          data: {
            pdfFile: pdfFile,
            attachmentName: attachmentName,
            invoiceDetail: newData,
            popupTitle: 'Send',
            sendForAttachment: true
           }
        });
      
        dialogRef.afterClosed().subscribe((result) => {
          this.isSendPdfLoading = false;
          console.log('PopupCloseResult: ', result);
           if(result != undefined && result){
              
           }
        });
    }

    getOrgDetail() {
      try {
        this.profileService.getOrganizationDetailByOrgCodeAndAdminCode(this.service.local.get('orgCode'), this.service.local.get('userCode'), (response) => {
          // //console.log(response);
          if (response.responsecode == 200) {
            this.profileService.profileData = response.data;
            if (this.profileService.profileData.ownerDetails) {
              this.organizationDetail = this.profileService.profileData.ownerDetails;
            }    
          }
        })
      } catch (error) {
        //console.log(error);
      }
    }

    getSettingBySettingName(settingName: any){
      try {
        this.service.common.getSettingBySettingName(settingName, this.service.local.get('orgCode'), (response)=>{
       if(response.responsecode == 200){
         this.organizationLogo = response.settingValue;
       } else {
         
       }
     })
     } catch (error) {
       //console.log(error)
     }
    }

    resetInvoiceDetailsAndBack(){
      if(this.userType === 'Customer'){
        this.router.navigate(['/dashboard-customer/invoice']);
      } else {
        this.router.navigate(['/dashboard/invoice']);
      }
      this.invoiceData = null;
      this.isInvoicePDF_view = false;      
    }


    openNotePopUp()
    {
      this._commonSVC.addUpdateNotes(this.noteList) ;
    }


}
