import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PatientService } from '../patient.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ProcedureDetailComponent } from '../../appointment/procedure-detail/procedure-detail.component';
import { CommonService } from 'src/app/shared/common.service';

export interface DialogData { }

@Component({
  selector: 'app-patient-calendar',
  templateUrl: './patient-calendar.component.html',
  styleUrls: ['./patient-calendar.component.scss'],
  providers: [DatePipe]
})
export class PatientCalendarComponent implements OnInit {
  @Input() locale: string;
  navDate: any;
  weekDaysHeaderArr: Array<string> = [];
  gridArr: Array<any> = [];
  // selectedDate: any;
  status = 'pending,assigned,accepted,inprogress,completed';
  page = 0;
  limit = 10;
  startDate: string;
  endDate: string;
  appointmentData: any = [];
  currentDate = new Date();
  selectDate: number;
  showImg;
  default_startTime: string = '00:00:00';
  default_endTime: string = '23:59:59';
  enableLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PatientCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_customer_data: DialogData,
    public service: PatientService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private _commonSVC : CommonService
  ) { }


  ngOnInit() {
    this._commonSVC.getpercentData();
    moment.locale(this.locale);
     this.navDate = moment();
    this.selectDate = +this.datepipe.transform(this.currentDate, 'dd');
    // this.selectedDate = this.datepipe.transform(this.currentDate, 'dd MMMM');
    let firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
    let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    //this.getDataByClick(this.service.local.get('orgCode'), this.status, this.startDate, this.endDate, this.dialog_customer_data['patientCode']);
   
    this.makeHeader();
    this.createCalendar();
    this.getAppoinments_Counts();
    this.getAllAppoinmentData_ForCustomer();

  }

  /* Name:Shailesh
     use: for creating week days*/
  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
  }

  /* Name:Shailesh
     use: for show and hide icon image*/
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }

  /* Name:Shailesh
     use: for getting all jobs by org code with filter*/
  // getDataByClick(orgCode, status, startDt, endDt, patientCode, monthChange = null) {
  //   this.service.common.progressLoader = true;
  //   try {
  //     this.service.getAllJobsByOrganizationCodeWithFilter(orgCode, status, startDt, endDt, patientCode, (response) => {
  //       ////console.log(response);
  //       if (response.responsecode == 200) {
  //         const date = moment(this.startDate).format('MM');
  //         const datemonth = moment().format('MM');
  //         //////console.log(date,datemonth);
  //         // this.getNewAppointments(response.data);
  //         this.createCalendar();

  //         setTimeout(() => {
  //           let index;
  //           if (monthChange && date !== datemonth) {
  //             //////console.log(this.startDate);
  //             index = this.gridArr.findIndex((data) => data.currentDate == moment(this.startDate).format('DD MMM'));
  //           } else {
  //             index = this.gridArr.findIndex((data) => data.currentDate == moment(this.currentDate).format('DD MMM'));
  //           }

  //           if (index !== -1) {
  //             this.selectDay(this.gridArr[index]);
  //           }
  //         }, 100);
  //       } else {
  //         // this.getNewAppointments([]);
  //         this.createCalendar();
  //       }
  //     })
  //   } catch (error) {
  //     //console.log(error)
  //   }

  // }

  /* Name:Shailesh
     use: for getting new appointments*/
  // getNewAppointments(allData) {

    createCalendar() {
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      //obj.eventData = [];
      let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      let date_formate_yyyy_mm_dd = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');
       obj.formatted_date = date_formate_yyyy_mm_dd;
       obj.date_obj = new Date(date_formate_yyyy_mm_dd);

      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
       
      } else {
        // if (allData.length > 0) {
        //   allData.forEach(element => {
        //     dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate), element.dateFormat);
        //     if (dateToCheck == element.startTime.split(' ')[0]) {
        //       obj.currentDate = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate), 'dd MMM');
        //       obj.available = true;
        //       obj.event = true;
        //       element.distanceData = (+element.distance).toFixed(2);
        //       obj.eventData.push(element);
        //       obj.value = i - initialEmptyCells + 1;
        //       obj.date = dateToCheck;

        //     } else {
        //       obj.currentDate = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate), 'dd MMM');
        //       obj.available = true;
        //       obj.value = i - initialEmptyCells + 1;
        //       obj.date = dateToCheck;
        //     }
        //   });
        //   // if (obj.eventData.length > 0) {
        //   //   this.appointmentData.push({ key: obj.currentDate, value: obj.eventData });
        //   // } else {
        //   //   this.appointmentData.push({ key: obj.currentDate, value: obj.eventData });
        //   // }
        // } else {
        //   obj.date = dateToCheck;
        //   obj.available = true;
        //   obj.value = i - initialEmptyCells + 1;
        // }

        obj.date = dateToCheck;
        obj.available = true;
        obj.value = i - initialEmptyCells + 1;

        obj.highlight = '';
      }
      this.gridArr.push(obj);
    }
    ////console.log("this.gridArr",this.gridArr)
    this.service.common.progressLoader = false;
  }

  dateFromNum(num: number, referenceDate: any): any {
    let returnDate = moment(referenceDate);
    return returnDate.date(num);
  }

  /* Name:Shailesh
     use: to change month on clickl*/
  changeNavMonth(num: number) {
    this.navDate.add(num, 'month');
    this.gridArr = [];
    this.appointmentData = [];
    const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    // this.selectedDate = this.datepipe.transform(this.dateFromNum(num, this.navDate), 'dd MMMM');
    this.selectDate = +this.datepipe.transform(this.currentDate, 'dd');
   // this.getDataByClick(this.service.local.get('orgCode'), this.status, this.startDate, this.endDate, this.dialog_customer_data['patientCode'], 'monthChange');
   this.createCalendar();
   this.getAllAppoinmentData_ForCustomer();
   this.getAppoinments_Counts();

  }

resetValues()
{
  this.gridArr = [];
  this.appointmentData = [];
  this.currentDate = new Date();
  this.startDate = '';
  this.endDate = '' ;
   this.selectDate = null;
}

  /* Name:Shailesh
     use: for selecting day*/
  // selectDay(day: any) {
  //   //////console.log(day);
  //   if (day.eventData.length > 0) {
  //     this.appointmentData = [{ key: day.currentDate, value: day.eventData }];
  //   } else {
  //     this.appointmentData = [];
  //   }
  //   this.selectDate = day.value;
  // }

  selectDay(day: any) {
   
    this.selectDate = day.value;
    this.startDate = day.formatted_date ;
    this.endDate = day.formatted_date ;

    this.getAllAppoinmentData_ForCustomer();
  }

  close() {
    this.dialogRef.close();
  }

  /* Name:Shailesh
     use: to open popup to get procedure detail*/
  getProcedureDetails(procedure, notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: { procedure: procedure, notes: notes }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });

  }


  RefreshAppoinmentsList(event:any)
  {
     this.appointmentData =[]
      this.getAllAppoinmentData_ForCustomer();
  }

  getAllAppoinmentData_ForCustomer() {

    this.appointmentData = [] ;
    this.enableLoading = true;
    this.service.getAllAppoinmentData_ByCustomerCode(
      this.service.local.get('orgCode'), this.startDate + ' ' + this.default_startTime, this.endDate + ' ' + this.default_endTime, this.dialog_customer_data['patientCode']
    ).subscribe((res: any) => {

      this.enableLoading = false;
      if (res.responsecode == 200) {
         this.appointmentData = res.data;
      }

     })

  }

 

  getAppoinments_Counts()
  {
    this.service.getAppoinments_Counts(
      this.service.local.get('orgCode'), this.startDate + ' ' + this.default_startTime, this.endDate + ' ' + this.default_endTime, this.dialog_customer_data['patientCode']
    ).subscribe((res: any) => {

       if (res.responsecode == 200) {

         this.setCounts(res.data);
        
      }

     })
  }


  setCounts(data: any) {
    // let color = this.status === 'pending' ? 'reddark' : 'greendark';
    this.gridArr.forEach(parent_ele => {

      for (var i = 0; i < data.length; i++) {
        if ((parent_ele.formatted_date in data[i])) {
           
          const obj = JSON.parse( data[i][parent_ele.formatted_date] ) ;
          
          parent_ele.jobsCount = obj.count;
 
          if(obj.fullfillmentRatio !== null && obj.fullfillmentRatio !== '')
          {
            const Index = this._commonSVC.dashboardColorData.findIndex(data => data.percent == Math.round(obj.fullfillmentRatio));
           
            if (Index !== -1) {
              parent_ele.fullfillmentRatio = this._commonSVC.dashboardColorData[Index].class;
            } 
  
          }
         
          
           break;

        }
      }

    });
 
  }




}
