import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
// import { SettingsService } from './settings.service';
import { SessionStorageService } from 'angular-web-storage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAccordion } from '@angular/material/expansion';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../../providers-dashboard/appointment/job-view/job-view.component';
import { TechSettingService } from './tech-setting.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-tech-setting',
  templateUrl: './tech-setting.component.html',
  styleUrls: ['./tech-setting.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class TechSettingComponent implements OnInit, OnDestroy {

  dateformat = [
    { format: 'MM-DD-YYYY (02-25-2019)', value: 'MM-dd-yyyy' },
    { format: 'DD-MM-YYYY (18-03-2019)', value: 'dd-MM-yyyy' },
    { format: 'YYYY-MM-DD (2019-12-27)', value: 'yyyy-MM-dd' },
  ]

  boolean = [
    { text: 'True', value: 'true' },
    { text: 'False', value: 'false' }
  ]

  todayDate = new Date();
  dateFormat;
  timezone = [];
  techSettingForm: FormGroup;
  submitted = true
  panelOpenState = true;
  isDeleteUser: boolean = false;
  @ViewChild('myaccordion', { static: true }) myPanels: MatAccordion;
  selectedStartTime = [];
  selectedEndTime = [];
  selectedTimezone = [];
  userCode;
  generalSetting = [];
  notificationSetting = [];
  settingData = [];
  updateSettings = [];
  step;
  userType;

  constructor(public common: CommonService,
    public datepipe: DatePipe,
    public service: TechSettingService,
    public local: SessionStorageService,
    public translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog, public auth: AuthService) {
    this.translate.setDefaultLang(this.common.local.get('language'));
    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
  }

  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
  }
  /* Name:srinivas
        use: loads data. */
  ngOnInit() {
    this.userType = this.local.get('user_type');
    this.step = 0;

    this.common.getAlltimeZone((data) => {
      //////console.log(data);
      if (data.responsecode == 200) {
        this.timezone = data.data;
        this.selectedTimezone = this.timezone;
      }
    });

    this.techSettingForm = new FormGroup({
      'timeZone': new FormControl(null),
      'dateFormat': new FormControl(null),
      'timeFormat': new FormControl(null),
      'alertMinutes': new FormControl(null),
      'sms_notifications': new FormControl(null),
      'mail_notifications': new FormControl(null),
    })

    this.common.getTimeSlotByOrganisationCode(this.local.get('orgCode'), (response) => {
      if (response.responsecode == 200) {
        this.common.timeIntervals = response.data;
        this.selectedStartTime = this.common.timeIntervals;
        this.selectedEndTime = this.common.timeIntervals;
        // this.selectedAlertTime = this.common.timeIntervals;
        // this.officeStartTime = this.common.timeIntervals;
        // this.officeEndTime = this.common.timeIntervals;
        //////console.log(this.common.timeIntervals);
      }
    })

    this.userCode = this.local.get('userCode');
    this.getTechnicianSetting();
    this.route.queryParams.subscribe((data) => {
      if (data?.isDeleteUser === 'true' && this.local.get('user_type') === 'Staff') {
        this.isDeleteUser = true;
      } else {
        this.isDeleteUser = false;
        if(this.local.get('user_type') !== 'Staff'){
          this.auth.logout();
        }        
      }
    })
  }

  // openAll() {
  //   this.myPanels.openAll();
  // }
  /* Name:srinivas
        use: return the setting details. */
  getTechnicianSetting() {
    try {
      
     this.service.common.progressLoader = true;
    this.service.getAllLocalSettingWithDefaultGlobalSettingByUserCode(this.userCode, (response) => {
      //////console.log(response);
      this.service.common.progressLoader = false;
      this.settingData = response.data;
      //////console.log(this.settingData);
    }) 
    } catch (error) {
      
    }
  }
  /* Name:srinivas
        use:save the data. */
  onSubmit() {
    //////console.log(this.techSettingForm);
    if (this.techSettingForm.valid) {
      this.submitted = true;

      this.settingData[0].GeneralSetting[0].localSettingValue = this.techSettingForm.value.timeZone;
      this.settingData[0].GeneralSetting[1].localSettingValue = this.techSettingForm.value.dateFormat;
      this.settingData[0].GeneralSetting[2].localSettingValue = this.techSettingForm.value.timeFormat;

      this.settingData[1].NotificationSetting[0].localSettingValue = this.techSettingForm.value.alertMinutes;
      this.settingData[1].NotificationSetting[1].localSettingValue = this.techSettingForm.value.sms_notifications;
      this.settingData[1].NotificationSetting[2].localSettingValue = this.techSettingForm.value.mail_notifications;

      this.settingData.forEach(element => {
        if (element.GeneralSetting) {
          element.GeneralSetting.forEach(newelement => {
            this.updateSettings.push(newelement);
          });
        }
        if (element.NotificationSetting) {
          element.NotificationSetting.forEach(newelement => {
            this.updateSettings.push(newelement);
          });
        }
      });
      //////console.log(this.updateSettings);
      try {
        this.service.updateSettingByUserCode(this.userCode, this.updateSettings, (res) => {
        //////console.log(res);
        if (res.responsecode == 200) {
          this.common.message = res.message;
          this.common.successMessage = true;
          this.getTechnicianSetting();
        } else {
          this.common.message = res.message;
          this.common.errorMessage = true;
        }
      })
    } catch (error) {
  
    }
    }
  }
  /* Name:srinivas
        use: returns serach details. */
  search(query) {
    // //////console.log('query', query);
    let result = this.select(query)
    this.selectedTimezone = result;
  }
  /* Name:srinivas
        use: search data return. */
  select(query) {
    let result = [];
    for (let a of this.timezone) {
      if (a.timeZoneName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result;
  }

/* Name:Vivek Chauhan new API
  use: for delete/remove User/Staff */
  softDeleteUserByUserCode(userCode :any){
    // //console.log('softDeleteUserByUserCode: ', userCode);
    if(userCode){
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete the account? Once deleted, it cannot be recovered.' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.common.progressLoader = true;
            this.service.softDeleteUserByUserCode(userCode, (response) => {
              this.common.progressLoader = false;
              //console.log(response);
              if (response.responsecode == 200) {
                this.common.successMessage = true;
                this.common.message = response.message;
                setTimeout(() => {
                  this.auth.logout();
                }, 1500);
              } else {
                this.common.errorMessage = true;
                this.common.message = response.message;
              }
            });
          } catch (error) {
            //console.log(error)
          }
      }
    });    
    }
  }

}
