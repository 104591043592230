<div class="w-100">
  <!--  
      <div class="d-flex col-lg-12 header-name mb-3">
        <span class="col-3"> 
        {{ 'Role.labels.Role Profile' | translate }} 
       </span>
     </div> -->
  
     <form [formGroup]="roleProfile_Form">
  
     <div class="mb-3 offset-md-2 d-flex">
  
      <div class="col-lg-4">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>Role Profile Name</mat-label>
          <input matInput placeholder="" autocomplete="off" formControlName="profileName">
        </mat-form-field>
      </div>
  
      <div class="col-lg-2">
        <section class="example-section">
          <mat-checkbox class="example-margin" formControlName="isDefault">Is Default</mat-checkbox>
         </section>
         </div>
  
       <div class="col-lg-6 text-center">
  
        <ng-template [ngIf]="hasPermission.editPermission === true">
       <button class="me-2"  mat-raised-button color="primary" (click)="add_Update_Permission()">
        {{!roleProfile_Form.controls.systemRoleCode.value ? 'Save' : 'Update '}} Profile
      </button>
      </ng-template>
   
    <ng-template [ngIf]="hasPermission.addPermission === true">

      <ng-template [ngIf]="!hasPermission.editPermission 
                           && hasPermission.addPermission === true
                           && !roleProfile_Form.controls.systemRoleCode.value">
        <button class="me-2"  mat-raised-button color="primary" (click)="add_Update_Permission()">
         Save Profile
       </button>
       </ng-template>

      <mat-icon *ngIf="roleProfile_Form.controls.systemRoleCode.value"
      (click)="AddNewProfile()" matTooltip="Create new role profile"
      class="btn-addProfile">add_circle_outline</mat-icon>

      <!-- <button *ngIf="roleProfile_Form.controls.systemRoleCode.value" class="ms-2" mat-raised-button 
      (click)="AddNewProfile()" matTooltip="Add new permission">
      <mat-icon class="btn-addProfile">add_circle_outline</mat-icon>
     </button> -->
    </ng-template>
  
      </div>
   
     </div>
    </form>
  <hr>
     <div class="row col-12">
  
      <div class="col-lg-4">
          <ul class="list-group">
            <ng-container *ngFor="let role of profileListData">
              <li class="list-group-item d-flex flex-column" [class.li-active]="clickedTab === role.roleName">  
                <span [title]="role.roleName" class="text-truncate">{{role.roleName}}</span>

                 <div class="actions text-end mt-2">

                  <ng-template [ngIf]="hasPermission.addPermission === true">
                    <i class="material-icons pointer pe-2"
                    [matTooltip]="'copy profile '+role.roleName"
                     (click)="copyProfile(role)">file_copy
                    </i>
                    </ng-template>

                     <ng-template [ngIf]="hasPermission.editPermission === true" [ngIfElse]="onlyPermissionView">
                      <i class="material-icons pointer pe-2" (click)="setSelectedProfile(role)">edit</i>
                      </ng-template>

                      <ng-template #onlyPermissionView>
                        <i class="material-icons pointer pe-2" [matTooltip]="'view profile'" (click)="setSelectedProfile(role)">visibility</i>
                        </ng-template>

                      <ng-template [ngIf]="hasPermission.delPermission === true">
                      <i class="material-icons pointer" (click)="deleteProfile(role)">delete</i>
                      </ng-template>
                      
                  </div>
  
              </li>
            </ng-container> 
            </ul>
      </div>
  
      <div class="col-lg-8 table-scroll-height">
     
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2 table-responsiv" multiTemplateDataRows>
      
                <ng-container matColumnDef="pagePermissionName" class="dis-icon">
                       <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Role.labels.Module' | translate }} </th>
                       <td mat-cell *matCellDef="let element"> {{element.pagePermissionName}}
                      
                        <ng-template [ngIf]="element.pagePermissionName === 'Mobile App'">
                        <mat-icon (click)="toggleRow(element)">
                          {{element?.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                        </ng-template>
                      </td>
                     </ng-container>
                   
                     <ng-container matColumnDef="add" class="dis-icon">
                        <th mat-header-cell *matHeaderCellDef> 
                          <mat-checkbox [(ngModel)]="checkbox.addPermission" (change)="onCheck($event,'addPermission')"></mat-checkbox>
                          {{ 'Role.labels.Add' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                          <div *ngIf="element.addPermission != null"> 
                            <!-- <button *ngIf="element.addPermission == true" mat-stroked-button color="primary" (click)="onClickUpdatePermission(element , false)">
                                <mat-icon>done_all</mat-icon>
                            </button>
                            <button *ngIf="element.addPermission == false" mat-stroked-button color="accent" (click)="onClickUpdatePermission(element , true)">
                                <mat-icon>clear</mat-icon>
                            </button> -->
        
                            <!-- <mat-slide-toggle (change)="onClickUpdatePermission($event)" [(ngModel)]="element.addPermission"></mat-slide-toggle> -->
                            <mat-slide-toggle  [(ngModel)]="element.addPermission" (change)="onClickToggle($event , element, 'addPermission')"></mat-slide-toggle>
                          </div>
                        </td>
                      </ng-container>
        
                     <ng-container matColumnDef="view" class="dis-icon">
                        <th mat-header-cell *matHeaderCellDef> 
                          <mat-checkbox [(ngModel)]="checkbox.viewPermission" (change)="onCheck($event,'viewPermission')"></mat-checkbox>
                          {{ 'Role.labels.View' | translate }} 
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div *ngIf="element.viewPermission != null"> 
                            <!-- <button *ngIf="element.viewPermission == true" mat-stroked-button color="primary" (click)="onClickUpdatePermission(element , false)">
                                <mat-icon>done_all</mat-icon>
                            </button>
                            <button *ngIf="element.viewPermission == false" mat-stroked-button color="accent" (click)="onClickUpdatePermission(element , true)">
                                <mat-icon>clear</mat-icon>
                            </button> -->
                            <!-- <mat-slide-toggle (change)="onClickUpdatePermission($event)" [(ngModel)]="element.viewPermission"></mat-slide-toggle> -->
                            <mat-slide-toggle [(ngModel)]="element.viewPermission" (change)="onClickToggle($event , element, 'viewPermission')"></mat-slide-toggle>
                          </div>
                        </td>
                      </ng-container>
        
                      <ng-container matColumnDef="edit" class="dis-icon">
                        <th mat-header-cell *matHeaderCellDef > 
                          <mat-checkbox [(ngModel)]="checkbox.editPermission" (change)="onCheck($event,'editPermission')"></mat-checkbox>
                          {{ 'Role.labels.Edit' | translate }} 
                        </th>
                        <td mat-cell *matCellDef="let element"> 
                          <div *ngIf="element.editPermission != null"> 
                            <!-- <button *ngIf="element.editPermission == true" mat-stroked-button color="primary" (click)="onClickUpdatePermission(element , false)">
                                <mat-icon>done_all</mat-icon>
                            </button>
                            <button *ngIf="element.editPermission == false" mat-stroked-button color="accent" (click)="onClickUpdatePermission(element , true)">
                                <mat-icon>clear</mat-icon>
                            </button> -->
        
                            <!-- <mat-slide-toggle (change)="onClickUpdatePermission($event)" [(ngModel)]="element.editPermission"></mat-slide-toggle> -->
                            <mat-slide-toggle  [(ngModel)]="element.editPermission" (change)="onClickToggle($event , element, 'editPermission')"></mat-slide-toggle>
                          </div>  
                        </td>
                      </ng-container>
        
                      <ng-container matColumnDef="delete" class="dis-icon">
                        <th mat-header-cell *matHeaderCellDef> 
                          <mat-checkbox [(ngModel)]="checkbox.delPermission" (change)="onCheck($event,'delPermission')"></mat-checkbox>
                          {{ 'Role.labels.Delete' | translate }}
                         </th>
                        <td mat-cell *matCellDef="let element"> 
                          <div *ngIf="element.delPermission != null"> 
                            <!-- <button *ngIf="element.delPermission == true" mat-stroked-button color="primary" (click)="onClickUpdatePermission(element , false)">
                                <mat-icon>done_all</mat-icon>
                            </button>
                            <button *ngIf="element.delPermission == false" mat-stroked-button color="accent" (click)="onClickUpdatePermission(element , true)">
                                <mat-icon>clear</mat-icon>
                            </button> -->
        
                            <!-- <mat-slide-toggle (change)="onClickUpdatePermission($event)" [(ngModel)]="element.delPermission"></mat-slide-toggle> -->
                            <mat-slide-toggle  [(ngModel)]="element.delPermission" (change)="onClickToggle($event , element, 'delPermission')"></mat-slide-toggle>
                          </div>  
                        </td>
                      </ng-container>
                 
                     <!-- <ng-container matColumnDef="action" class="dis-icon">
                       <th mat-header-cell *matHeaderCellDef> </th>
                       <td mat-cell *matCellDef="let element">
                         <i class="material-icons pointer" [routerLink]="['/dashboard','procedure','procedure-edit']"
                           [queryParams]="{ 'procedureCode': element.organizationBaseProcedureCode }">edit</i>
                       </td>
                     </ng-container> -->
  
                     
                
                 <!-- Expandable row content start -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element; let i = index" [attr.colspan]="displayedColumns.length">
                    <table>
                      <thead>
                        <tr>
                          <th>Module</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let inner_ele of element.dataPermissionRoleMappingList">
                          <td>{{inner_ele.dataPermissionName}}</td>
                          <td colspan="3">
                            <div *ngIf="inner_ele.isView != null"> 
                               <mat-slide-toggle  [(ngModel)]="inner_ele.isView"></mat-slide-toggle>
                            </div>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </ng-container>
                <!-- Expandable row content end -->
                
                 
                     <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                     <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [class.hide-row]="row.isExpanded"> </tr>
               </table>
        
       </div> 
     </div>
  
    
  
  </div>
  
    
   