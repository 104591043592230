<div class="col-md-12 mb-4" *ngIf="invoiceData && isInvoicePDF_view">
  <div class="d-flex align-items-center justify-content-between mb-4">

    <div>
      <div class="arrow_back" (click)="resetInvoiceDetailsAndBack()" title="Back">
        <mat-icon>arrow_back_ios</mat-icon>
      </div>
    </div>

    <div>
    <div class="row justify-content-start pe-3">
      <ng-container *ngIf="invoiceData?.invoiceCode">
        <mat-radio-group aria-label="Select an option" class="filterRadio" (change)="invoiceData?.invoiceCode && changeGroupBy($event)" [(ngModel)]="groupBy">
          <mat-radio-button value="jobType">Job Type</mat-radio-button>
          <mat-radio-button value="date">Date</mat-radio-button>
        </mat-radio-group>
      </ng-container>
    </div>
    </div>

    <div>
    <div class="d-block">
      <!-- <button type="button" mat-raised-button color="primary" class="col-auto me-2" (click)="resetInvoiceDetailsAndBack()">Back</button> -->

      <span title="Note" class="me-5 cursor-pointer" (click)="openNotePopUp()"
      *ngIf="userType !== 'Customer' && invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent'" >
        <img 
           [src]="noteList.length > 0 ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
           <span class="{{noteList.length > 0 ? 'primary-color' : ''}}">&nbsp;Note</span>
       </span>

      <button [disabled]="exceedValue" *ngIf="invoiceData?.invoiceId && invoiceData?.generatedInvoiceDetail?.invoiceStatus !== 'Paid' && userType !== 'Customer'" type="button" mat-raised-button color="primary" class="col-auto me-2" (click)="!isSendPdfLoading && send_PDF_email('printInvoiceId')">Send <span *ngIf="isSendPdfLoading" class="spinner-border spinner-border-sm"></span></button>

      <button [disabled]="exceedValue" *ngIf="invoiceData?.invoiceId" type="button" mat-raised-button color="primary" class="col-auto me-2" (click)="!isPreviewLoading && preview_PDF('printInvoiceId')">Print <span *ngIf="isPreviewLoading" class="spinner-border spinner-border-sm"></span>
      </button>
      
      <button [disabled]="exceedValue" *ngIf="invoiceData?.invoiceId" type="button" mat-raised-button color="primary" class="col-auto" [class.me-2]="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent'" (click)="!isDownloadPDFLoading && save_PDF('printInvoiceId')">Download <span *ngIf="isDownloadPDFLoading" class="spinner-border spinner-border-sm"></span></button>
     
      <button [disabled]="exceedValue" *ngIf="userType !== 'Customer' && hasPermission.editPermission === true && invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent' && invoiceData.generatedInvoiceDetail.invoiceStatus !== 'Paid'" type="button" mat-raised-button color="primary" class="col-auto" (click)="saveInvoice()"><span *ngIf="invoiceData?.invoiceId">Update</span><span *ngIf="!invoiceData?.invoiceId">Save</span></button>

    </div>
    </div>
  </div>
  <div class="row">
    <div class="cs-invoice cs-style1" id="printInvoiceId">
      <div class="cs-invoice_in" id="download_section">
        <div class="cs-invoice_head cs-type1 cs-mb25">
          <div class="cs-invoice_left">
            <p class="cs-invoice_number cs-primary_color cs-mb5 cs-f16"><b class="cs-primary_color">INVOICE </b>#{{invoiceData?.invoiceId}}</p>
            <p class="cs-invoice_number cs-primary_color cs-mb5 cs-f16"><b class="cs-primary_color">Date: </b>{{invoiceData?.generatedInvoiceDetail?.invoiceDate ? invoiceData.generatedInvoiceDetail.invoiceDate : showInvoiceTodayDate}}</p>
            <!-- <p class="cs-invoice_date cs-primary_color cs-mb0 flexWrap"><b class="cs-primary_color" [class.pt_7]="userType !== 'Customer'">Payment Due on or Before:&nbsp;&nbsp;</b>
              <ng-container *ngIf="userType !== 'Customer'; else showNonEditableDate">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                  <mat-label>Date</mat-label>
                  <input (focus)="picker2.open()" matInput [matDatepicker]="picker2" placeholder=""
                      [value]="formatedDateBillDueDate" (dateChange)="onDateChangeE($event)">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              </ng-container> 
              <ng-template #showNonEditableDate>
                <span>{{formatedTodayDate}}</span>
              </ng-template>               
            </p> -->
          </div>
          <div class="cs-invoice_left ps-" *ngIf="invoiceData.generatedInvoiceDetail.invoiceStatus === 'Paid'">
            <p class="cs-invoice_number cs-primary_color cs-mb5 cs-f16"><b class="cs-primary_color">Invoice Status: </b><span [class.statusPaid]="invoiceData?.generatedInvoiceDetail?.invoiceStatus === 'Paid'">{{invoiceData?.generatedInvoiceDetail?.invoiceStatus}}</span></p>
            <p class="cs-invoice_number cs-primary_color cs-mb5 cs-f16"><b class="cs-primary_color">Payment Date: </b>{{invoiceData?.generatedInvoiceDetail?.paymentDate}}</p>
          </div>
          <div class="cs-invoice_left ps-" *ngIf="invoiceData.generatedInvoiceDetail.invoiceStatus === 'Paid'">
            <p class="cs-invoice_number cs-primary_color cs-mb5 cs-f16"><b class="cs-primary_color">Payment Mode: </b>{{invoiceData?.generatedInvoiceDetail?.paymentMode}}</p>
            <p class="cs-invoice_number cs-primary_color cs-mb5 cs-f16"><b class="cs-primary_color">Payment Reference: </b>{{invoiceData?.generatedInvoiceDetail?.paymentReference}}</p>
          </div>
          <div class="cs-invoice_right cs-text_right">
            <div class="cs-logo cs-mb5"><img [src]="organizationLogo ? img_serverPath + organizationLogo : 'assets/common_images/profile.jpg'" alt="Logo"></div>
          </div>
        </div>
        <div class="cs-invoice_head cs-mb10">
          <div class="cs-invoice_left">
            <b class="cs-primary_color">Invoice:</b>
            <p *ngIf="organizationDetail">
              <span *ngIf="organizationDetail?.organizationName">{{organizationDetail.organizationName}} <br></span>
              <span *ngIf="organizationDetail?.email">Email: {{organizationDetail.email}} <br></span>
              <span *ngIf="organizationDetail?.primaryContact">Phone: {{organizationDetail.primaryContact}} <br></span>
              
            </p>
          </div>
          <div class="cs-invoice_right cs-text_right">
            <b class="cs-primary_color">Bill To:</b>
            <p *ngIf="invoiceData?.selectedCustomerFromDropdown">
              <span *ngIf="invoiceData.selectedCustomerFromDropdown?.customerName">{{invoiceData.selectedCustomerFromDropdown.customerName}} <br></span>
              <span *ngIf="invoiceData.selectedCustomerFromDropdown?.billingLocation || invoiceData.selectedCustomerFromDropdown?.customerLocation">{{invoiceData.selectedCustomerFromDropdown.billingLocation || invoiceData.selectedCustomerFromDropdown.customerLocation}}, <br></span>
              <span *ngIf="invoiceData.selectedCustomerFromDropdown?.billingPhone || invoiceData.selectedCustomerFromDropdown?.mainPhone">Phone: {{invoiceData.selectedCustomerFromDropdown.billingPhone || invoiceData.selectedCustomerFromDropdown.mainPhone}} <br></span>
              <span *ngIf="invoiceData.selectedCustomerFromDropdown?.billingEmail || invoiceData.selectedCustomerFromDropdown?.billingLocation">Email: {{invoiceData.selectedCustomerFromDropdown.billingEmail || invoiceData.selectedCustomerFromDropdown.customerEmail}}</span>
            </p>
          </div>
        </div>
        <div class="cs-table cs-style1">
          <div class="cs-round_border">
            <div class="cs-table_responsive position-relative">
              <table class="position-relative">
                <thead>
                  <tr>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">Staff Name</th>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">{{groupBy === 'jobType' ? 'Date' : 'Job Type'}}</th>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">Regular Hours</th>                    
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">Billing Rate/Hr</th>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">OT Hours</th>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">OT Rate/Hour</th>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg">Tip</th>
                    <th class="col-auto cs-semi_bold cs-primary_color cs-focus_bg cs-text_right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="invoiceData.generatedInvoiceItems && invoiceData.generatedInvoiceItems?.length > 0">
                    <ng-container *ngFor="let parentJob of invoiceData.generatedInvoiceItems; index as jobIdx">
                      <tr>
                        <td class="w-100 text-start text-underline bg_light_w" colspan="8">{{parentJob.jobTypeName}}</td>
                      </tr>
                    <ng-container *ngFor="let job of parentJob.invoiceDetailsResponseList; index as jobIdxChild">
                      <tr>
                        <td class="col-auto"><span>{{job.staffName}}</span></td>
                        <td class="col-auto">{{groupBy === 'jobType' ? job.jobDateView : job?.jobTypeName}}</td>
                        <td class="col-auto">{{(job?.workHours? job.workHours : 0) | number : '2.2-2' | noComma}}</td>
                        <td class="col-auto">
                          <!-- <span>${{job.ratePerHours | number : '2.2-2' | noComma}}</span> -->
                            <ng-container *ngIf="userType !== 'Customer'; else showNonEditableRate">                            
                            <span *ngIf="regularRateEdit[jobIdx+'_'+jobIdxChild]" class="dolSymbTip d_inline_block">$</span><span *ngIf="regularRateEdit[jobIdx+'_'+jobIdxChild]" class="d_inline_block"><input positiveNumberOnly class="cs-width_1 d_inline_block" type="number" [id]="'rate_'+jobIdx+'_'+jobIdxChild" [value]="(job?.ratePerHours ? job.ratePerHours : 0) | number : '2.2-2' | noComma" (blur)="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent' && onEditRegularRate(jobIdx, jobIdxChild, job)" [disabled]="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus === 'Sent'" class="form-control maxTipWid" placeholder="0" [min]="00.00" [step]="00.01" /></span>
                            <span *ngIf="!regularRateEdit[jobIdx+'_'+jobIdxChild]" class="d_inline_block">${{(job?.ratePerHours ? job.ratePerHours : 0) | number : '2.2-2' | noComma}} <mat-icon class="cursor-pointer editFieldIc" [class.disabled]="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus === 'Sent'" (click)="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent' && editElement('regularRateEdit', jobIdx,jobIdxChild)">edit</mat-icon></span>
                          </ng-container>
                          <ng-template #showNonEditableRate>
                            <span class="d_inline_block">${{(job?.ratePerHours ? job.ratePerHours : 0) | number : '2.2-2' | noComma}}</span>
                          </ng-template>
                        </td>
                        <td class="col-auto">{{(job?.workHoursOverTime ? job.workHoursOverTime : '0') | number : '2.2-2' | noComma}}</td>                         
                        <td class="col-auto">
                          <!-- <span>${{job.ratePerHours | number : '2.2-2' | noComma}}</span> -->
                            <ng-container *ngIf="userType !== 'Customer'; else showNonEditableOtRate">                            
                            <span *ngIf="overTimeRateEdit[jobIdx+'_'+jobIdxChild]" class="dolSymbTip d_inline_block">$</span><span *ngIf="overTimeRateEdit[jobIdx+'_'+jobIdxChild]" class="d_inline_block"><input positiveNumberOnly class="cs-width_1 d_inline_block" type="number" [id]="'rateOt_'+jobIdx+'_'+jobIdxChild" [value]="(job?.ratePerHoursOverTime ? job.ratePerHoursOverTime : 0) | number : '2.2-2' | noComma" (blur)="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent' && onEditOverTimeRate(jobIdx, jobIdxChild, job)" [disabled]="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus === 'Sent'"  class="form-control maxTipWid" placeholder="0" [min]="00.00" [step]="00.01" /></span>
                            <span *ngIf="!overTimeRateEdit[jobIdx+'_'+jobIdxChild]" class="d_inline_block">${{(job?.ratePerHoursOverTime ? job.ratePerHoursOverTime : 0) | number : '2.2-2' | noComma}} <mat-icon class="cursor-pointer editFieldIc" [class.disabled]="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus === 'Sent'" (click)="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent' && editElement('overTimeRateEdit', jobIdx, jobIdxChild)">edit</mat-icon></span>
                          </ng-container>
                          <ng-template #showNonEditableOtRate>
                            <span class="d_inline_block">${{(job?.ratePerHoursOverTime ? job.ratePerHoursOverTime : 0) | number : '2.2-2' | noComma}}</span>
                          </ng-template>
                        </td> 
                        <td class="col-auto d-flex">
                          <ng-container *ngIf="userType !== 'Customer'; else showNonEditable">                            
                            <span class="dolSymbTip d_inline_block">$ </span><span *ngIf="!tipEdit[jobIdx+'_'+jobIdxChild]" class="d_inline_block"><input positiveNumberOnly class="cs-width_1 d_inline_block" type="number" [id]="'tip_'+jobIdx+'_'+jobIdxChild" [value]="(job?.tip ? job.tip : 0) | number : '2.2-2' | noComma" (blur)="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus != 'Sent' && onEditTip(jobIdx, jobIdxChild, job)" [disabled]="invoiceData?.selectedCustomerFromDropdown?.invoiceStatus === 'Sent'"  class="form-control maxTipWid" placeholder="0" [min]="00.00" [step]="00.01" /></span>
                            <span *ngIf="tipEdit[jobIdx+'_'+jobIdxChild]" class="d_inline_block">{{(job?.tip ? job.tip : 0) | number : '2.2-2' | noComma}}</span>
                          </ng-container>
                          <!-- [(ngModel)]="tipValue[jobIdxChild]" -->
                          <!-- <span *ngIf="!tipEdit[jobIdxChild]">${{tipValue[jobIdxChild] ? tipValue[jobIdxChild] : 0}} <mat-icon class="cursor-pointer" (click)="tipEdit[jobIdxChild] = !tipEdit[jobIdxChild]">edit</mat-icon></span> -->
                          <ng-template #showNonEditable>
                            <span class="d_inline_block">${{(job?.tip ? job.tip : 0) | number : '2.2-2' | noComma}}</span>
                          </ng-template>                          
                        </td>
                        <td class="col-auto cs-text_right">${{((updatedTotalValue(job)).totalAmount) | number : '2.2-2' | noComma}}</td>
                      </tr>
                    </ng-container>
                </ng-container>
              </ng-container>
                </tbody>
              </table>
            </div>
            <div class="cs-invoice_footer cs-border_top">
              <div class="cs-left_footer cs-mobile_hide">
                <p class="cs-mb0"><b class="cs-primary_color">Additional Information:</b></p>
                <p class="cs-m0">Client must notify us of any invoice dispute <br>within the Payment Term.</p>
              </div>
              <div class="cs-right_footer">
                <table>
                  <tbody>
                    <tr class="cs-border_left">
                      <td class="cs-width_3 cs-semi_bold cs-primary_color lh_24">Adjustment Reason:</td>
                    </tr>
                    <tr class="cs-border_left">
                      <td class="cs-width_3 cs-primary_color d-flex w-100 pt_pb">
                        <div *ngIf="userType !== 'Customer' && hasPermission.editPermission === true" id="editable-div" contenteditable="true" (blur)="adjustmentReasonUpdate($event)">{{adjustmentReason}}</div>
                         <div *ngIf="userType === 'Customer'" id="editable-div" [class.forCustomer]="userType === 'Customer'" contenteditable="false">{{adjustmentReason}}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="cs-right_footer">
                <table>
                  <tbody>
                    <tr class="cs-border_left">
                      <td class="cs-width_3 cs-semi_bold cs-primary_color cs-focus_bg">Sub Total:</td>
                      <td class="cs-width_3 cs-semi_bold cs-focus_bg cs-primary_color cs-text_right"><span *ngIf="invoiceData.generatedInvoiceDetail?.totalAmount">${{invoiceData.generatedInvoiceDetail.totalAmount | number : '2.2-2' | noComma}}</span></td>
                    </tr>
                    <tr class="cs-border_left">
                      <td class="cs-width_3 cs-semi_bold cs-primary_color cs-focus_bg" title="Adjustment Amount">
                        <span>Adj. Amt.:</span>
                      </td>
                      <td *ngIf="userType !== 'Customer'" class="cs-width_3 cs-focus_bg cs-primary_color cs-text_right pr5" style="display: flex; width: 100%; justify-content: end;">
                        <span class="dolSymbTip d_inline_block">$</span>
                        <span>
                          <input appFloatOnly class="cs-width_1 d_inline_block text-end" type="text" class="form-control maxTipWid2 text-end" placeholder="0" [step]="00.01" [value]="(invoiceData?.generatedInvoiceDetail?.adjustmentAmount ? invoiceData.generatedInvoiceDetail.adjustmentAmount : 0) | number : '2.2-2' | noComma" (blur)="adjustmentValueUpdate($event)" [class.inputErrBorderAlert]="exceedValue" />
                        </span>
                      </td>
                      <td *ngIf="userType === 'Customer'" class="cs-width_3 cs-semi_bold cs-focus_bg cs-primary_color cs-text_right"><span *ngIf="invoiceData.generatedInvoiceDetail?.totalAmount">${{invoiceData.generatedInvoiceDetail.adjustmentAmount | number : '2.2-2' | noComma}}</span></td>
                    </tr>
                    <tr class="cs-border_left">
                      <td class="cs-width_3 cs-semi_bold cs-primary_color cs-focus_bg">
                        <span>Tax:</span>
                      </td>
                      <td class="cs-width_3 cs-focus_bg cs-primary_color cs-text_right" style="display: flex; width: 100%; justify-content: end; text-align: end;">
                        <span class="cs-semi_bold d_inline_block">$ {{(invoiceData?.generatedInvoiceDetail?.taxAmount ? invoiceData.generatedInvoiceDetail.taxAmount : 0) | number : '2.2-2' | noComma}}</span>
                        <!-- <span>
                          <input positiveNumberOnly class="cs-width_1 d_inline_block" type="number" class="form-control maxTipWid2" placeholder="0" [min]="00.00" [step]="00.01" [value]="(invoiceData?.generatedInvoiceDetail?.taxAmount ? invoiceData.generatedInvoiceDetail.taxAmount : 0) | number : '2.2-2' | noComma" (blur)="taxPercentValueUpdate($event)" />
                        </span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="cs-invoice_footer">
            <div class="cs-left_footer cs-mobile_hide w-100"></div>
            <div class="cs-right_footer">
              <table>
                <tbody>
                  <tr class="cs-border_none">
                    <td class="w-auto cs-border_top_0 cs-bold cs-f16 cs-primary_color text-nowrap d-flex" style="width: 150px !important;">Total Amount:</td>
                    <td class="cs-width_3 cs-border_top_0 cs-bold cs-f16 cs-primary_color cs-text_right">
                        <span>${{(invoiceData.generatedInvoiceDetail?.totalFinalAmount ? invoiceData.generatedInvoiceDetail.totalFinalAmount : invoiceData.generatedInvoiceDetail?.finalTotalAmount ? invoiceData.generatedInvoiceDetail.finalTotalAmount : 0) | number : '2.2-2' | noComma}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="cs-note">
          <div>
            <p><b class="cs-primary_color cs-bold">Note: </b>Client must notify us of any invoice dispute within the Payment Term. Revised invoices are payable upon receipt or within the Payment Term of the original invoice, whichever is later. Client must notify us if a Purchase Order ('PO') number or other information is required before invoices are processed. Unapproved, unfunded or missing POs do not excuse Client's payment obligation to our company. We may charge a late fee of 1.5% on any unpaid invoice or amount due after the Payment Term, and may withdraw any of our temporary employee(s) for failure to pay an invoice within the Payment Term.</p>
          </div>
        </div>
        <!-- .cs-note -->
      </div>
    </div>
  </div>
</div>
