import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { SchedulingRulesService } from './scheduling-rules.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'

@Component({
  selector: 'app-scheduling-rules',
  templateUrl: './scheduling-rules.component.html',
  styleUrls: ['./scheduling-rules.component.scss']
})
export class SchedulingRulesComponent implements OnInit {

  preferred = [
    {
      name: 'Required',
      val: 'required'
    },
    {
      name: 'Preferred',
      val: 'preferred'
    },
  ];

  match = [
    {
      name: 'Match All',
      val: 'matchall'
    },
    {
      name: 'Match Either',
      val: 'matcheither'
    },
  ];

  schedulingRules;
  schedulingForm: FormGroup;

  // @ViewChild('preferred1', {static: true}) preferred1;
  // @ViewChild('skills', {static: true}) skills;
  // @ViewChild('proficiency', {static: true}) proficiency;
  // @ViewChild('dist', {static: true}) dist;
  // @ViewChild('hours', {static: true}) hours;

  constructor(public service: SchedulingRulesService, private translate: TranslateService, public local: SessionStorageService, private fb: FormBuilder) {
    this.translate.setDefaultLang(this.local.get('language'));
    this.schedulingForm = this.fb.group({
      rules: this.fb.array([])
    });
    this.adminGetSchedulingRuleByOrganizationCode();
   }

  ngOnInit() {
    // ////console.log(this.preferred1);
  }

  setRules() {
    let control = this.schedulingForm.controls.rules as FormArray;
    this.schedulingRules.forEach(x => {
      control.push(this.fb.group({
        isChecked: x.isChecked, 
        selectionValue: x.selectionValue,
        sortingOrder: x.sortingOrder,
        appointmentsRuleMasterCode: x.appointmentsRuleMasterCode,
        appointmentsRuleOrganizationMappingCode: x.appointmentsRuleOrganizationMappingCode
      }));
    });
  }

  // onChange(event){
  //   ////console.log(event);
  //   ////console.log(event.source._elementRef.nativeElement.parentElement);
  //   ////console.log(event.source._elementRef.nativeElement.parentElement.parentElement);
  //   let select = event.source._elementRef.nativeElement.parentNode.parentNode;
  //   // ////console.log(select.getElementsByName("mat-select").length);
  //   // ////console.log(select.querySelectorAll('.mat-select'));
  //   let child = select.querySelectorAll('.mat-select');
  //   child[0].disabled = false;
  // }

   /* Name:Shailesh
      use: for getting scheduling rule by org code */
  adminGetSchedulingRuleByOrganizationCode(){
    try {
      this.service.adminGetSchedulingRuleByOrganizationCode(this.local.get('orgCode'), (data)=>{
      if(data.responsecode == 200){
        ////console.log(data.data);
        this.schedulingRules = data.data;
        this.setRules();
        // this.schedulingForm.controls.rules.disable();
      } else {
        this.schedulingRules = [];
      }
    });
    } catch (error) {
      //console.log(error)
    }
    
  }

   /* Name:Shailesh
      use: for assigning scheduling rule appointment rule by org code */
  onSubmit(){
    ////console.log(this.schedulingForm);

    try {
       this.service.adminAssignOrganizationToAppointmentsRule(this.local.get('orgCode'), this.schedulingForm.value.rules, (res)=>{
      if(res.responsecode == 200){
        this.service.common.message = res.message;
        this.service.common.progressLoader = false;
        this.service.common.successMessage = true;
        this.adminGetSchedulingRuleByOrganizationCode();
      } else {
        this.service.common.message = res.message;
        this.service.common.progressLoader = false;
        this.service.common.errorMessage = true;
      }
    })
    } catch (error) {
      //console.log(error)
    }
   
  }
}
