import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { startWith, map } from 'rxjs/operators';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MapsAPILoader } from '@agm/core';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';
import { AppointmentService } from '../appointment.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { TranslateService } from '@ngx-translate/core';

const moment = _moment;
declare var google;
declare var window;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};

@Component({
  selector: 'app-update-appointment',
  templateUrl: './update-appointment.component.html',
  styleUrls: ['./update-appointment.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class UpdateAppointmentComponent implements OnInit, OnDestroy {

  scheduleForm: FormGroup;
  options = { componentRestrictions: { country: "us" } };
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  countrycodep = this.service.common.CountryCode;
  countrycodem = this.service.common.CountryCode;
  selectedStartTime = [];
  selectedEndTime = [];
  submitted = true;
  assignList = [];
  patientData = [];
  optionsPatient = [];
  filteredOptions: Observable<any>;
  patientDataByCode = [];
  procedureData = [];
  today;
  itip;
  itim;
  instancep;
  jobDurationCode: any;
  addresses;
  durationSelectDropdown: any[];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  allDurations: any[];
  selectedDuration: string;
  preferredCountryCode = this.service.common.CountryCode;
  cancelUpdate = false;

  constructor(
    public service: AppointmentService,
    private dialog: MatDialog,
    private router: Router,
    private datepipe: DatePipe,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<UpdateAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.translate.setDefaultLang(this.service.session.get('language'));
    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Procedures",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      disabled: true,
      // badgeShowLimit: 5,
      maxHeight:200,
      autoPosition: true
    };
  }

  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
  }

  ngOnInit() {
    this.getForm();
    this.durationSelectDropdown = this.service.common.durationHours;

    /* Name:Shailesh
      use: for getting all procedures by organization code */
    try {
      this.service.getAllProcedureByOrganizationCode(this.service.session.get('orgCode'), (response) => {
      ////console.log(response);
      if (response.responsecode) {
        this.procedureData = response.data;
        // let procedureData  = [];
        response.data.forEach(element => {
          element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
          element.itemName = element.procedureName;
          this.dropdownList.push({ image: element.image, itemName: element.itemName, organizationBaseProcedureCode: element.organizationBaseProcedureCode })
        });
        // this.dropdownList = procedureData;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    



    //this.route.queryParams.subscribe(params => {
    /* Name:Shailesh
      use: for getting time slot by organization code */
    try {
      this.service.common.getTimeSlotByOrganisationCode(this.service.common.local.get('orgCode'), (response) => {
      this.selectedStartTime = response.data;
      this.selectedEndTime = response.data;
      //this.jobDurationCode = params.jobDurationCode;
      this.jobDurationCode = this.data.jobDurationCode;
      this.getLoadFormData();
    });
    } catch (error) {
      //console.log(error)
    }
    
    //})
    this.itip = document.querySelector('#phone');
    this.itim = document.querySelector('#mobile');
    this.getPatients();

    this.scheduleForm.valueChanges.subscribe((val) => {
      this.cancelUpdate = true;
    })
  }
  displayFn(optionsPatient): string | undefined {
    return optionsPatient ? optionsPatient.patientName : '';
  }

  /* Name:Shailesh
      use: to search patient names */
  private _filter(name): String[] {
    if (name && name.patientCode != undefined) {
      this.getPatientsDetail(name.patientCode);
    }
    if (name) {

      const filterValue = name.toLowerCase();
      return this.optionsPatient.filter(option => option.patientName.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.optionsPatient;
    }
  }

  getForm() {
    this.getServiceEmpty();
    this.scheduleForm = new FormGroup({
      'address': new FormControl({ value: null, disabled: true }),
      'phoneCountryCode': new FormControl({ value: this.countrycodep, disabled: true }, Validators.pattern(this.service.common.phonenumber)),
      'phoneNumber': new FormControl({ value: null, disabled: true }),
      'mobileCountryCode': new FormControl({ value: this.countrycodem, disabled: true }, Validators.pattern(this.service.common.phonenumber)),
      'mobileNumber': new FormControl({ value: null, disabled: true }),
      'startDate': new FormControl(null, Validators.required),
      'endDate': new FormControl(null, Validators.required),
      'startTime': new FormControl(null, Validators.required),
      'duration': new FormControl(null, Validators.required),
      'procedureCode': new FormControl({ value: null, disabled: true }),
      'patientName': new FormControl({ value: null, disabled: true }),
      // 'jobName': new FormControl(null),
      'notes': new FormControl(null),
      'preferredCountryCode': new FormControl({ value: this.preferredCountryCode, disabled: true }, Validators.required),
      'preferredNumber': new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(this.service.common.phonenumber)]),
      'isPeferredNumber': new FormControl(null),
      'isDateFlexible': new FormControl(null)
    })

  }


  /* Name:Shailesh
      use: for getting worker job duration by job duration code */
  getLoadFormData() {

    this.selectedItems = [];

    try {
      this.service.getWorkerJobDurationByJobDurationCode(this.jobDurationCode, (response) => {
      //console.log(response);
      if (response.responsecode == 200) {

        this.service.scheduleForm.jobName = response.data.jobName;
        this.service.scheduleForm.notes = response.data.notes;
        // this.service.scheduleForm.patientDetail = response.data.patientDetails;
        this.service.scheduleForm = response.data;
        // if (response.data.procedureMaster) {
        this.service.scheduleForm.organizationBaseProcedureCodeList = response.data.organizationBasedProcedureDetailsList;
        response.data.organizationBasedProcedureDetailsList.forEach(element => {
          // this.selectedItems.push(element);
          ////console.log(element);
          element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
          element.itemName = element.procedureName;
          this.selectedItems.push({ image: element.image, itemName: element.itemName, organizationBaseProcedureCode: element.organizationBaseProcedureCode })
        });
        // }
        ////console.log(this.dropdownList);
        ////console.log(this.selectedItems);
        const index = this.selectedStartTime.findIndex(data => {

          ////console.log(data.value, response.data.appointmentStartDate.split(' ')[1]);

          return data.value == response.data.appointmentStartDate.split(' ')[1];
        });
        this.service.scheduleForm.startTime = this.selectedStartTime[index].value;
        let startDate = response.data.appointmentStartDate.split(' ')[0];
        const time = this.service.scheduleForm.startTime.split(':');
        let hours = +time[0];
        const month = startDate.split('-');
        const newDate = new Date(+month[0], +month[1] - 1, +month[2], hours, +time[1]);
        this.scheduleForm.controls['startDate'].setValue(moment(newDate));
        // if (response.data.dateFormat == 'MM-dd-yyyy') {
        ////console.log(newDate);

        //this.scheduleForm.controls['startDate'].setValue(moment(newDate));
        this.service.scheduleForm.startDate = +startDate.split('-')[1] + '/' + +startDate.split('-')[0] + '/' + +startDate.split('-')[2];
        this.today = moment(newDate);
        const endData = response.data.appointmentEndDate.split(' ')[0];
        const endtime = response.data.appointmentEndDate.split(' ')[1].split(':');
        let endhours = +endtime[0];
        const endmonth = endData.split('-');

        const endnewDate = new Date(+endmonth[0], +endmonth[1] - 1, +endmonth[2], endhours, +endtime[1]);
        this.scheduleForm.controls['endDate'].setValue(moment(endnewDate));
        this.service.scheduleForm.endDate = +endData.split('-')[1] + '/' + +endData.split('-')[0] + '/' + +endData.split('-')[2];
        //this.service.scheduleForm.endDate =moment(this.scheduleForm.controls['endDate'].value(),'YYYY-MM-DD').format('YYYY-MM-DD');
        this.service.scheduleForm.durationHours = response.data.durationHours;
        this.service.scheduleForm.durationMinute = response.data.durationMinute;
        this.service.scheduleForm.patientCode = response.data.patientCode;
        this.service.scheduleForm.isDateFlexible = response.data.isDateFlexible;
        response.data.patientDetails = {
          "isActive": response.data.isActive,
          "mobileCountryCode": response.data.mobileCountryCode,
          "mobileNumber": response.data.mobileNumber,
          "organizationCode": response.data.organizationCode,
          "patientAddress": response.data.patientAddress,
          "patientCity": response.data.patientCity,
          "patientCode": response.data.patientCode,
          "patientCountry": response.data.patientCountry,
          "patientEmail": response.data.patientEmail,
          "patientLatitude": response.data.patientLatitude,
          "patientLongitude": response.data.patientLongitude,
          "patientName": response.data.patientName,
          "patientState": response.data.patientState,
          "phoneCountryCode": response.data.phoneCountryCode,
          "phoneNumber": response.data.phoneNumber,
          "zipCode": response.data.zipCode,
          "preferredNumber": response.data.preferredNumber,
          "preferredCountryCode": response.data.preferredCountryCode
        }
        this.service.scheduleForm.patientDetail = response.data.patientDetails;
        this.scheduleForm.controls['patientName'].setValue(response.data.patientDetails);
        this.scheduleForm.controls['duration'].setValue(response.data.durationHours + ':' + response.data.durationMinute + ':00');
        this.selectedDuration = response.data.durationHours + ':' + response.data.durationMinute + ':00';
        this.service.scheduleForm.address = response.data.patientDetails.patientAddress;
        setTimeout(() => {
          this.cancelUpdate = false;
        }, 1000);
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

  /* Name:Shailesh
    use: for getting patient detail by patient code */
  getPatientsDetail(code) {
    try {
       this.service.getPatientDetailByCode(code, (data) => {
      this.patientDataByCode = data.data;
      this.service.scheduleForm.patientCode = this.patientDataByCode['patientCode'];
      this.service.scheduleForm.homeCountryCode = this.patientDataByCode['phoneCountryCode'];
      this.countrycodep = this.patientDataByCode['phoneCountryCode'];
      this.countrycodem = this.patientDataByCode['mobileCountryCode'];
      this.service.scheduleForm.homeNumber = this.patientDataByCode['phoneNumber'];
      this.service.scheduleForm.mobileCountryCode = this.patientDataByCode['mobileCountryCode'];
      this.service.scheduleForm.mobileNumber = this.patientDataByCode['mobileNumber'];
      if (!this.patientDataByCode['patientAddress']) {
        this.getCurrentLocation(+this.patientDataByCode['patientLatitude'], +this.patientDataByCode['patientLongitude']);
      }
      if (this.patientDataByCode['preferredCountryCode']) {
        this.preferredCountryCode = this.patientDataByCode['preferredCountryCode'];
        this.service.scheduleForm.additionalCountryCode = this.preferredCountryCode;
        this.scheduleForm.controls['preferredCountryCode'].setValue(this.preferredCountryCode);
        this.service.scheduleForm.additionalNumber = this.patientDataByCode['preferredNumber'];
      }
      this.service.scheduleForm.preferredPhoneType = this.patientDataByCode['preferredPhoneType'];
    })
    } catch (error) {
      //console.log(error)
    }
   
  }

  getNumber(event, countryCode) {
    this.scheduleForm.controls[countryCode].setValue(event.dialCode);
    this.service.scheduleForm.patientDetail[countryCode] = event.dialCode;
  }

  searchStart(query) {
    let result = this.selectStart(query)
    this.selectedStartTime = result;
  }

  selectStart(query) {
    let result = [];
    for (let a of this.service.common.timeIntervals) {
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  searchEnd(query) {
    let result = this.selectStart(query)
    this.selectedEndTime = result;
  }

  handleAddressChange(address) {
    this.updateServiceAddress(address);
  }

  /* Name:Shailesh
      use: to show current location of patient */
  getCurrentLocation(lat, lng) {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = { lat: lat, lng: lng };
      let that = this;
      geocoder.geocode({ 'location': latlng }, function (results) {
        if (results[0]) {
          that.updateServiceAddress(results[0]);
        }
      });
    });
  }

  /* Name:Shailesh
      use: to update patient address */
  updateServiceAddress(address) {

    this.service.scheduleForm.patientDetail.patientCity = '';
    this.service.scheduleForm.patientDetail.patientCity = "",
      this.service.scheduleForm.patientDetail.patientCountry = "",
      this.service.scheduleForm.patientDetail.patientLatitude = "",
      this.service.scheduleForm.patientDetail.patientLongitude = "",
      this.service.scheduleForm.patientDetail.patientState = "",
      this.service.scheduleForm.patientDetail.zipCode = ""
    this.service.scheduleForm.patientDetail.patientApartmentNo = ""

    let places: any;
    const componentForm = {
      route: 'long_name',
      locality: 'long_name',
      political: 'long_name',
      administrative_area_level_1: 'long_name',
      postal_code: 'short_name',
      country: 'long_name'
    };

    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (componentForm[addressType]) {
        if (addressType === 'route') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientAddress = val;
        }
        if (addressType === 'street_number') {
          const val = address.address_components[i]['long_name'];
          this.service.scheduleForm.patientDetail.patientApartmentNo = val;
        }
        if (addressType === 'political') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientAddress = val;
        }
        if (addressType === 'postal_code') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.zipCode = val;
        }
        if (addressType === 'locality') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientCity = val;
        }
        if (addressType === 'administrative_area_level_1') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientState = val;
        }
        if (addressType === 'country') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientCountry = val;
        }
      }
    }

    this.service.scheduleForm.patientDetail.patientLatitude = address.geometry.location.lat();
    this.service.scheduleForm.patientDetail.patientLongitude = address.geometry.location.lng();
    this.scheduleForm.controls['address'].setValue(address.formatted_address);
  }

  /* Name:Shailesh
      use: for getting patient detail by organization code */
  getPatients() {
    this.service.common.progressLoader = true;
    try {
      this.service.getPaitents(this.service.common.local.get('orgCode'), (data) => {
      if (data.responsecode === 200) {
        this.patientData = data.data;
        this.patientData.forEach(element => {
          this.optionsPatient.push({ "patientName": element.patientName, "patientCode": element.patientCode });
        });
        this.service.common.progressLoader = false;
      } else {
        this.service.common.progressLoader = false;
      }
    });
    } catch (error) {
      //console.log(error);
    }
    
  }

  onItemSelect(item: any) {
    // ////console.log(item);
    // if (item.duration) {
    this.getDuration();
    // }

  }

  /* Name:Shailesh
      use: for getting appointment duration */
  getDuration() {
    this.allDurations = [];
    if (this.selectedItems.length > 0) {
      this.selectedItems.forEach(element => {
        this.allDurations.push(element.duration);
        const totalDurations = this.allDurations.slice(1)
          .reduce((prev, cur) => moment.duration(cur).add(prev),
            moment.duration(this.allDurations[0]));
        let duration = moment.utc(totalDurations.asMilliseconds()).format("HH:mm:ss");
        this.scheduleForm.controls['duration'].setValue(duration);
        this.scheduleForm.controls['notes'].setErrors(null);
        this.selectedDuration = duration;
      });
    } else {
      this.scheduleForm.controls['duration'].setValue(null);
      this.selectedDuration = '';
    }
  }

  getDurationData(event) {
    ////console.log(event.value, this.selectedDuration, this.scheduleForm.value.notes);
    if (event.value !== this.selectedDuration && !this.scheduleForm.value.notes) {
      this.scheduleForm.controls['notes'].setErrors({ 'incorrect': true });
      ////console.log(this.scheduleForm);
    } else {
      this.scheduleForm.controls['notes'].setErrors(null);
    }

  }


  getServiceEmpty() {
    this.service.scheduleForm = {
      "daysList": [
        0
      ],
      "durationHours": 0,
      "durationMinute": 0,
      "endDate": "",
      "isDateOfMonth": true,
      "jobName": "",
      "notes": "",
      "address": "",
      "organizationCode": "",
      "patientCode": "",
      "patientDetail": {
        "isActive": true,
        "mobileCountryCode": "",
        "mobileNumber": "",
        "organizationCode": "",
        "patientAddress": "",
        "patientCity": "",
        "patientCode": "",
        "patientCountry": "",
        "patientEmail": "",
        "patientLatitude": "",
        "patientLongitude": "",
        "patientName": "",
        "patientState": "",
        "phoneCountryCode": "",
        "phoneNumber": "",
        "zipCode": "",
        "patientApartmentNo": "",
        "preferredNumber": "",
        "preferredCountryCode": ""
      },
      "organizationBaseProcedureCodeList": [],
      "repeatType": "",
      "repeatValue": 0,
      "startDate": "",
      "startTime": "",
      "isActive": true,
      "mobileCountryCode": "",
      "mobileNumber": "",
      "patientAddress": "",
      "patientCity": "",
      "patientCountry": "",
      "patientEmail": "",
      "patientLatitude": "",
      "patientLongitude": "",
      "patientName": "",
      "patientState": "",
      "homeCountryCode": "",
      "homeNumber": "",
      "zipCode": "",
      'preferredPhoneType': '',
      "additionalCountryCode": "",
      "additionalNumber": "",
      "isDateFlexible": false
    }
  }

  /* Name:Shailesh
      use: for updating appointment detail */
  onSubmit() {
    ////console.log(this.service.scheduleForm);
    if (this.scheduleForm.valid) {
      this.service.common.progressLoader = true;
      this.submitted = true;
      this.scheduleForm.value.startDate = this.datepipe.transform(this.scheduleForm.value.startDate, 'yyyy-MM-dd');
      this.scheduleForm.value.endDate = this.datepipe.transform(this.scheduleForm.value.endDate, 'yyyy-MM-dd');
      if (this.service.scheduleForm.endDate == '') {
        this.service.scheduleForm.endDate = this.scheduleForm.value.startDate;
        this.service.scheduleForm.daysList = null;
        this.service.scheduleForm.repeatType = 'day';
        this.service.scheduleForm.repeatValue = 1;
        this.service.scheduleForm.isDateOfMonth = false;
      }
      this.service.scheduleForm.startDate = this.scheduleForm.value.startDate;
      this.service.scheduleForm.organizationCode = this.service.common.local.get('orgCode');
      if (this.patientDataByCode.length > 0 || this.patientDataByCode['patientName']) {
        this.service.scheduleForm.patientDetail.patientName = this.patientDataByCode['patientName'];
      } else {
        this.service.scheduleForm.patientDetail.patientName = this.scheduleForm.value.patientName;
      }
      this.service.scheduleForm.patientDetail.organizationCode = this.service.common.local.get('orgCode');
      // this.service.scheduleForm.patientDetail.patientLongitude = this.service.scheduleForm.patientDetail.patientLongitude.toString();
      // this.service.scheduleForm.patientDetail.patientLatitude = this.service.scheduleForm.patientDetail.patientLatitude.toString();
      this.service.scheduleForm.durationHours = this.scheduleForm.value.duration.split(':')[0];
      this.service.scheduleForm.durationMinute = this.scheduleForm.value.duration.split(':')[1];
      const formData = {
        "durationHours": this.service.scheduleForm.durationHours,
        "durationMinute": this.service.scheduleForm.durationMinute,
        "startTime": this.service.scheduleForm.startDate + ' ' + this.service.scheduleForm.startTime,
        "jobName": this.service.scheduleForm.jobName,
        "notes": this.service.scheduleForm.notes,
        "additionalCountryCode": this.service.scheduleForm.additionalCountryCode,
        "additionalNumber": this.service.scheduleForm.additionalNumber,
        "homeCountryCode": this.service.scheduleForm.homeCountryCode,
        "homeNumber": this.service.scheduleForm.homeNumber,
        "mobileCountryCode": this.service.scheduleForm.mobileCountryCode,
        "mobileNumber": this.service.scheduleForm.mobileNumber,
        "preferredPhoneType": this.service.scheduleForm.preferredPhoneType,
      };


      //console.log(formData);

      try {
        this.service.updateJobDurationByJobDurationCode(this.jobDurationCode, formData, (res) => {
        this.service.common.progressLoader = false;
        if (res.responsecode == 200) {
          this.service.common.message = res.message;
          this.service.common.successMessage = true;
          //this.router.navigate(['/dashboard/appointment']);
          this.dialogRef.close('true');
        } else {
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })
      } catch (error) {
        //console.log(error)
      }
      
    } else {
      this.submitted = false;
    }
  }
  addPatient() {
    this.router.navigate(['/dashboard/patient']);
  }
  getCountryCode(event, formControlName) {
    this.scheduleForm.controls[formControlName].setValue(event);
    this.service.scheduleForm.patientDetail[formControlName] = event;
  }

  /* Name:Shailesh
      use: to cancel the update appointment popup */
  canelPopup() {
    if (this.cancelUpdate) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          //this.getForm();
          //this.getLoadFormData();
          this.onNoClick();
        }
      });
    } else {
      this.onNoClick();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
