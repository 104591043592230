<div class="shift-scheduler-instance-header-main-section-start">
  <div class="cls-distrubution-list">

    <button class="btn me-2" type="button" title="Distribution List" id="dd_distributioList" data-bs-toggle="dropdown"
      data-bs-auto-close="outside" aria-expanded="false">
      <mat-icon class="user-grp">supervisor_account</mat-icon>
    </button>

    <ul class="dropdown-menu" aria-labelledby="dd_distributioList">
      <li class="">
        <h6 class="mini-header m-2">Distribution List</h6>
        <div class="schedule-roster-view-area__employees">
          <div class="schedule-roster-employees">
            <div class="schedule-roster-employees__list-wrap">
              <div class="schedule-roster-employees__list">
                <div class="schedule-roster-view-employee-card">
                  <div class="schedule-roster-area-employees__card-container"
                    *ngFor="let distribution of distributionList; let i = index">
                    <div class="schedule-roster-view-employee-card" draggable="true"
                      (dragstart)="drag($event,distribution)">
                      <div class="schedule-roster-view-employee-card__layout">
                        <div class="schedule-roster-view-employee-card__avatar"></div>
                        <div class="schedule-roster-view-employee-card__name">
                          {{distribution.distributionName}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="schedule-roster-view-area-employees__add-employee">
                    <div class="schedule-roster-view-area-employees__add-employee-card">
                      <mat-icon class="co-icon schedule-roster-view-area-employees__add-icon">add</mat-icon>
                      <ng-template [ngIf]="hasPermission.addPermission === true">
                      <div (click)="AddDistribution()" class="schedule-roster-view-area-employees__add-employee-text">
                        Add DistributionList
                      </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

    </ul>

  </div>
  <div>
    <div data-testid="automation-time-selector">
      <div class="connecteam-carousel-select">
        <div class="carousel-container">
          <div class="components-container">
            <div class="arrow-container" [class.readOnlyNotAllow]="addReadOnlyOnLoad" (click)="previousWeek()">
              <img src="../../../assets/common_images/arrow-small-left.svg" class="" />
            </div>
            <div class="connecteam-carousel-select-text-container cursor-pointer">
              <span *ngIf="!addReadOnlyOnLoad">{{ currentWeekStart }} - {{ currentWeekEnd }}</span><span  *ngIf="addReadOnlyOnLoad" class="spinner-border spinner-border-sm"></span>
            </div>
            <div class="arrow-container" [class.readOnlyNotAllow]="addReadOnlyOnLoad" (click)="nextWeek()">
              <img src="../../../assets/common_images/arrow-small-left.svg" class="flipped-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-container"></div>
  </div>

  <div style="width: 50%;">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-select [(ngModel)]="selectedSite" (selectionChange)="onJobSiteChange($event)">
        <!-- <mat-option
                *ngFor="let address of ddl_CustomerAddressList"
                [value]="address"
              >
                {{address.siteName }} -{{address.customerName}}
              </mat-option> -->
        <ng-container *ngFor="let customer of CustomerAddressListGroup | keyvalue">
          <mat-optgroup [label]="customer.key">
            <mat-option *ngFor="let item of customer.value" [value]="item">
              {{customer.key}}-{{ item.siteName }}
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <!--The content below is only a placeholder and can be replaced.-->

  <!--  <mat-button-toggle-group [(value)] = "selectedValue" #group="matButtonToggleGroup">
          <mat-button-toggle  *ngFor="let item of toggleOptions;" (change)="selectionChanged($event)" [value]="item">{{item}}</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="example-selected-value">Selected value: {{group.value}}</div>
 -->
</div>




<div class="shift-scheduler-instance-header-main-section-end">

</div>

<div class="schedule-days-header">
  <div class="schedule-days-panel schedule-days-header__datetime">
    <div *ngFor="let day of weekDays; let i = index" class="schedule-days-panel__table"
      [ngClass]="{'schedule-days-panel__table-active':day.datemoment.format('ddd D') == today.format('ddd D'),'day-header-nonworking-box':!day.isWorkingDay}">
      <div class="schedule-days-panel__inner-weekly schedule-days-panel__inner">
        <div
          [ngClass]="day.datemoment.format('ddd D') == today.format('ddd D') ? 'schedule-days-panel__inner-day-short-active ' : 'schedule-days-panel__inner-day-short'">
          {{ day.datemoment.format('ddd D') }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="rostering-internals overnight-changes__applied stats-table--closed">
  <div class="schedule-roster">
    <div class="schedule u-no-rhythm">
      <div class="schedule__body-container">
        <div class="schedule__body">
          <div class="schedule-roster-view-area-container">
            <div class="schedule-roster-view-area schedule-roster-view-area--AREA_WEEK">

              <div class="schedule-roster-view-area__roster" *ngIf="dataLoaded">
                <div class="schedule-roster-view-area__roster-inner">
                  <div id="roster-scroll-container"
                    class="schedule-roster-view-area__roster-scroll-container scrollbar-thin">
                    <div class="schedule-roster-view-area__roster-section">
                      <ng-container *ngIf="transformedData">
                        <div class="schedule-roster-view-area-area"
                          *ngFor="let jobType of transformedData | keyvalue; let i = index">
                          <ng-container *ngIf="jobType.key !== 'jobTypeIcon'">
                            <div class="schedule-roster-view-area-area__area-header">
                              <div class="schedule-roster-view-area-area__area-hide"></div>
                              <div class="schedule-roster-view-area-area__area-header-inner">
                                <div class="schedule-roster-view-area-area__area-name-area-badge common-icon-round">
                                  <img style="width: 20px; height: 20px" alt="No Image" class="td_avatar_img"
                                    *ngIf="jobType.value.jobTypeIcon"
                                    [src]="service.getSuperAdmin() + '/downloadFile/' + jobType.value.jobTypeIcon"
                                    [title]="jobType.key" />
                                  <img style="width: 20px; height: 20px" alt="No Image" class="td_avatar_img"
                                    *ngIf="!jobType.value.jobTypeIcon"
                                    src="https://api-dev.gigflex.com:8762/superadminservice/downloadFile/BarTender-1694152014861.png" />
                                </div>

                                <div class="schedule-roster-view-area-area__area-name">
                                  <div class="schedule-roster-view-area-area__area-name-area">
                                    {{jobType.key}}
                                  </div>
                                </div>
                                <div class="schedule-roster-view-area-area__area-icons"></div>
                              </div>
                            </div>
                            <div class="schedule-roster-view-area-area__area-roster">
                              <div class="schedule-roster-view-area-area__area-roster-days">
                                <div *ngFor="let date of jobType.value.datesData | keyvalue ;let j = index"
                                  class="schedule-roster-view-area-area-day--column"
                                  [ngClass]="{'day-header-nonworking-box':!date.value.isWorkingDay}">

                                  <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"
                                    class="schedule-roster-view-area-area-day__inner"
                                    [ngClass]="{'schedule-roster-view-area-area-day__inner--active':isPasteActive && date.value.isWorkingDay ,'day-header-nonworking-box':date.isWorkingDay}">
                                    <div
                                      class="schedule-roster-view-area-shift-list schedule-roster-view-area__roster-shifts-draggable color-header expandContent">
                                      <div class="eventdata-div"
                                        *ngFor="let eventdata of date.value.events | wvcTimeShorting ; let k = index">

                                        <div (drop)="drop($event,eventdata,date.key)"
                                          (dragover)="allowDrop($event,eventdata)"
                                          class="schedule-roster-view-area-shift schedule-roster-view-area-shift--hover schedule-roster-view-area-shift--AREA_WEEK"
                                          [ngClass]="isCopiied && eventdata.appointmentsProceduresCode==CopiedJob.appointmentsProceduresCode?'schedule-roster-view-area-shift--selected schedule-roster-view-area-shift--copy':''">
                                          <div (mouseenter)="isHidden = !isHidden" (mouseleave)="isHidden = !isHidden"
                                            class="schedule-roster-view-area-shift__placeholder" cdkDropList
                                            [cdkDropListEnterPredicate]="date.value.isWorkingDay">

                                            <div class="roster-view-employee-shift__area-colour"
                                              [ngClass]="eventdata.BG_Color"></div>
                                            <div>
                                            </div>
                                              <ng-template #Menucontent>

                                                <div class="d-flex flex-column">
                                                <button mat-raised-button color="primary" class="mb-2"
                                                (click)="openNotifyPopUp(eventdata.appointmentsCode, eventdata.appointmentsProceduresCode, date.key)">
                                                  Notify
                                                </button>

                                                <button mat-raised-button color="warn" 
                                                (click)="openAssignPopUp(eventdata.appointmentsCode, eventdata.appointmentsProceduresCode, date.key)">
                                                Assign
                                                </button>
                                                </div>
                                              </ng-template>

                                            <div class="schedule-roster-view-area-shift__info scrollbar-thin">
                                              <div class="schedule-roster-view-area-shift__info-time">
                                                <span [title]="'Customer : ' +eventdata.customerName" class="text-truncate w-100" style="height: 25px;">{{eventdata.customerName}} </span>
                                                <div class="w-auto">
                                                  <span class="notebtnContainer ps-1" matTooltip="Note" (click)="_commonSVC.showGroupNotes(eventdata.appointmentsProceduresCode, eventdata.jobDateFixedFormat, eventdata.isNotesAdded)">
                                                    <img *ngIf="eventdata?.isNotesAdded" class="p-0 notebtnIc curser-pointer" src="assets/images/note-icon-active.svg" />
                                                    <img *ngIf="!eventdata.isNotesAdded" class="p-0 notebtnIc curser-pointer" src="assets/images/note-icon.svg" />
                                                  </span>
                                                </div>
                                                <div *ngIf="eventdata?.isPassedEndTime !== true" class="three-dot-menu" [popover]="Menucontent" container="body"
                                              placement="auto" [adaptivePosition]="true" [outsideClick]="true"
                                              containerClass="popover-zIndex">
                                              <mat-icon style="font-size: 19px;">more_vert</mat-icon>
                                            </div>

                                              </div>
                                              <div class="schedule-roster-view-area-shift__info-time textWrap">
                                                <span [title]="'Site : '+eventdata.siteName">{{eventdata.siteName}}</span>
                                              </div>
                                              <div class="schedule-roster-view-area-shift__info-time d-flex textWrap justify-content-start">
                                                <div class="col w-auto nowrapTxt pe-2 pt-1">
                                                  {{formatTime(eventdata.startTime)}} -
                                                {{formatTime(eventdata.endTime)}}
                                                </div>
                                               
                                                <div *ngIf="eventdata.showRateCard" class="col w-auto nowrapTxt pe-2 pt-1">
                                                  <div class="rate-per-hr">

                                                    <div style="font-weight: bold">
                                                      $ {{eventdata.showRateCard}}
                                                    </div>

                                                  </div>
                                                </div>

                                                <div class="col w-auto nowrapTxt pt-1">

                                                  <img [class.mt_13]="eventdata?.scheduled && eventdata.scheduled > 0 && eventdata?.notified && eventdata.notified > 0"
                                                  matTooltip="Show Scheduled Staff"
                                                  *ngIf="eventdata.scheduled > 0" (click)="showScheduledUsers(eventdata,scheduledUsers_popup)" 
                                                  src="../../../assets/common_images/group-table-icon.png"
                                                 alt="shifts-group-icon" class="shifts-group-icon cursor-pointer me-1"
                                                 test-id="automation-shift-group-icon" />


                                                  <i *ngIf="eventdata?.notified && eventdata.notified > 0" matTooltip="Show Notified Staff" (click)="getNotifyStaffList('notified staff', eventdata.appointmentsCode, eventdata.appointmentsProceduresCode, date.key)" class="ms-1 material-icons cursor-pointer notify_icon">
                                                    notifications_none
                                                  </i>

                                                </div>
                                              </div>

                                              <div class="schedule-roster-view-employee-card__summary-text-container">
                                                <div *ngFor="let break of eventdata.breakList"
                                                  class="schedule-roster-view-area-shift__info-name">
                                                  <div>{{break.breakType | capitalize}}:</div>

                                                  <div style="font-weight: bold">
                                                    {{break.durationMin}} min
                                                  </div>
                                                  <div>
                                                    <span class="pe-1" *ngIf="break.paidStatus=='Paid'">$</span>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="schedule-roster-view-area-shift__info-name">

                                              </div>

                                              <div class="schedule-roster-view-area-shift__info-comments">

                                              </div>

                                            </div>

                                            <div class="flex-row-centered">
                                              <div class="tasks-progress-bar" style="
                                                background-color: rgba(
                                                  129,
                                                  168,
                                                  204,
                                                  0.2
                                                );
                                              ">
                                                <div class="tasks-progress-bar-inner"
                                                  [style.width.%]="eventdata.fullFilledRatio"
                                                  [ngClass]="eventdata.BG_Color "></div>
                                              </div>

                                              <div class="tasks-text-container">
                                                <span matTooltip="Scheduled" matTooltipPosition="above">{{eventdata?.scheduled ? eventdata.scheduled : 0}}</span>/<span matTooltip="Notified" matTooltipPosition="above">{{eventdata?.notified ? eventdata.notified : 0}}</span>/<span matTooltip="Required" matTooltipPosition="above">{{eventdata?.required ? eventdata.required : 0}}</span>
                                              </div>

                                            </div>


                                            <div class="bottom-buttons-parent">
                                              <div class="bottom-buttons">
 
                                                <ng-template [ngIf]="hasPermission.viewPermission === true">
                                                  <ng-template [ngIf]="eventdata.isPassedStartTime === true">
                                                  <div class="schedule-roster-view-area-shift__actions-edit"
                                                    (click)="ViewJob(eventdata.dynamicDate,eventdata)">
                                                    View
                                                  </div>
                                                  </ng-template>
                                                </ng-template>

                                                <ng-template [ngIf]="hasPermission.addPermission === true">
                                                  <div (click)="CopyJob(eventdata)"
                                                    class="schedule-roster-view-area-shift__actions-copy">
                                                    Copy
                                                  </div>
                                                </ng-template>

                                                <ng-template [ngIf]="hasPermission.editPermission === true">
                                                  <ng-template [ngIf]="!eventdata.isPassedStartTime === true">
                                                  <div class="schedule-roster-view-area-shift__actions-edit"
                                                    (click)="EditJob(date.key,eventdata)">
                                                    Edit
                                                  </div>
                                                  </ng-template>
                                                </ng-template>

                                                <!-- <ng-template [ngIf]="hasPermission.delPermission === true"> -->
                                                  <ng-template [ngIf]="!eventdata.isPassedStartTime === true">
                                                  <mat-icon class="text-danger"
                                                    (click)="deleteJob(date.key,eventdata)">
                                                    delete
                                                  </mat-icon>
                                                  </ng-template>
                                                <!-- </ng-template> -->

                                              </div>
                                            </div>


                                          </div>
                                        </div>
                                      </div>

                                      <ng-template [ngIf]="showActions(date.key)">

                                      <div class="col-lg-12 text-center mt-4 position-absolute bottom-0 ">
                                        <div class="d-flex justify-content-evenly">

                                          <!-- <ng-template [ngIf]="isCopiied && date.value.isWorkingDay">
                                           <div class="paste-icon">
                                        <mat-icon title="paste" class="me-2" (click)="PasteJob(date.key)">
                                            content_paste
                                        </mat-icon>
                                        <mat-icon class="text-danger" title="cancel" (click)="resetCopyPaste()">
                                          cancel
                                         </mat-icon>
                                        </div>
                                        </ng-template>  -->
                                        
                                      

                                      <ng-template [ngIf]="hasPermission.addPermission === true">
                                        <div *ngIf="date.value.isWorkingDay">
                                          <mat-icon (click)="AddJob(date.key, jobType.value.organizationBaseProcedureCode)" style="color: var(--main-color);">
                                            <!-- class="schedule-roster-view-area-area-day__shift-add js-walkme-add-shift" -->
                                            add_box
                                          </mat-icon>
                                        </div>
                                      </ng-template>
                                         </div>
                                    </div>

                                      </ng-template>


                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!transformedData">
                        <div class="no-data-message">
                          No data available. Please add data to display content.
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!dataLoaded">Loading data...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ShiftPopup_TempRef>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Shifts</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" mat-dialog-close></button>
      </div>
    </div>
  </div>

  <div class="row">
    <div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Shift</mat-label>
        <mat-select formControlName="Shift">
          <mat-option *ngFor="let shift of shiftList" [value]="shift">
            {{shift.shiftName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-12 text-end">
    <button class="me-4" mat-button mat-dialog-close color="primary">
      Close
    </button>

    <button mat-raised-button color="primary">Add Shift</button>
  </div>
</ng-template>

<ng-template #scheduledUsers_popup>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Scheduled Staff</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" (click)="dialogRef.close()"></button>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- <admin-scheduled-staff-card [scheduleStaff_CardData]="scheduledUsers" [showMoreAccess]="false"
      (emitter_refreshList)="refreshData()">
    </admin-scheduled-staff-card> -->
 
        <!-- <admin-scheduled-staff-card
        [showMoreControls]="true"
        [job_shortData_Modal]="scheduledUsers"
        (emitter_refreshList)="refreshData()">
      </admin-scheduled-staff-card> -->

      <app-common-card 
      [job_shortData_Modal]="scheduledUsers"
      (emitter_handleEvent)="refreshData($event)">
    </app-common-card>

  </div>

</ng-template>




<ng-template #jobPaste_calendar>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Paste Job</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" (click)="resetCopyPaste()"></button>
      </div>
    </div>
  </div>
   <div class="row">
    <span class="mb-2 pasteJobTxt">Choose date to paste the job</span>
    <mat-calendar  (selectedChange)="JobPasteDateChange($event)" [minDate]="pastDate" [dateFilter]="nonWorkingHourFilter"></mat-calendar>
     <!-- <mat-calendar [(selected)]="jobPaste_date" (selectedChange)="JobPasteDateChange($event)"></mat-calendar> -->
   </div>
</ng-template>