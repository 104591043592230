import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

import * as moment from 'moment';
import { DatePipe } from '@angular/common';
// import { SettingsService } from './settings.service';
import { SessionStorageService } from 'angular-web-storage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAccordion } from '@angular/material/expansion';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../../../dashboards/providers-dashboard/appointment/job-view/job-view.component';
import { PreferenceSettingService } from './preference-setting.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-preference-setting',
  templateUrl: './preference-setting.component.html',
  styleUrls: ['./preference-setting.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class PreferenceSettingComponent implements OnInit, OnDestroy {

  userCode: any;
  days: any;
  workForm: FormGroup;
  submitted: boolean = true;
  slideOpts = {
    effect: 'flip'
  };
  tabStatus = 'prefered'
  timeFormat= 'hh:mm a';
  operationData = [];
  daycode;
  formDay: FormGroup;
  readonly = false;
  hours;
  editShow = true;
  fromTime;
  toTime;
  PreferedHubmitted = true;
  selectedStartTime: any[];
  selectedEndTime: any[];
  selectedExtraDay: any;
  allDay = false;

  constructor(public common: CommonService,
    public datepipe: DatePipe,
    public service: PreferenceSettingService,
    public local: SessionStorageService,
    public translate: TranslateService) {
    this.translate.setDefaultLang(this.common.local.get('language'));
    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
  }

  ngOnDestroy() {
    this.common.sub.unsubscribe();
  }

  ngOnInit() {

    this.days = this.service.days;

    this.workForm = new FormGroup({
      'dayValue': new FormControl(false, Validators.required)
    })


    this.common.getTimeSlotByOrganisationCode(this.local.get('orgCode'), (response) => {
      if (response.responsecode == 200) {
        this.common.timeIntervals = response.data;
        this.selectedStartTime = this.common.timeIntervals;
        this.selectedEndTime = this.common.timeIntervals;
        //console.log(this.common.timeIntervals);
      }
    })

    this.userCode = this.local.get('userCode');
    this.getWorkerHours(this.userCode);
    this.getExtraHours();

    this.formDay = new FormGroup({
      'fromTime': new FormControl(null, Validators.required),
      'toTime': new FormControl(null, Validators.required),
      'id': new FormControl(null, Validators.required),
      'workerhoursOfOperationCode': new FormControl(null),
    })
  }
  /* Name:srinivas
    use: edit setting data. */

  onEdit(code) {
    //console.log(code);
    // //////console.log(hours);
    // this.formDay = new FormGroup({
    //   'fromTime': new FormControl(code.fromTime, Validators.required),
    //   'toTime': new FormControl(code.toTime, Validators.required),
    //   'id': new FormControl(code.id, Validators.required),
    //   'workerhoursOfOperationCode': new FormControl(code.workerhoursOfOperationCode),
    // })

    this.formDay.setValue({
      'fromTime': code.fromTime,
      'toTime': code.toTime,
      'id': code.id,
      'workerhoursOfOperationCode': code.workerhoursOfOperationCode
    })
    //console.log(this.formDay)

    this.daycode = code.days;
    this.editShow = false;
    this.hours = code.workerhoursOfOperationCode;
  }
/* Name:srinivas
    use: return worker availability working days. */
  getExtraHours() {
    try {
   
    this.service.getWorkerAvailabilityExtraWorkByWorkerCode(this.userCode, (response) => {
      //////console.log(response);
      if (response.responsecode == 200) {
        //////console.log(response);
        const dataDays = response.data.days.split(',');
        dataDays.forEach(element => {
          //////console.log(element);
          let checkedDays = this.service.preferedDays.find(x => x.dayCode == element);
          //////console.log(checkedDays);
          if (checkedDays) {
            //////console.log(checkedDays);
            checkedDays.checked = true;
          }
        });
        //  this.selectExtraDay(response.data.days);
      }
    }) 
  } catch (error) {
      
    }
  }
/* Name:srinivas
    use: return worker working hours. */
  getWorkerHours(code) {
try {
    this.service.common.progressLoader = true;
    this.service.getworkerHoursOfOperationByworkerCode(code, (data) => {
      //////console.log(data);
      if (data.responsecode == 200) {
        this.operationData = data.data;
        this.timeFormat = data.data[0].timeFormat;
        //////console.log(this.operationData);
        setTimeout(() => {
          this.service.days.forEach((element) => {
            element.fromTime = '';
            element.toTime = '';
            element.id = 0;
            element.checked = false;
            element.workerhoursOfOperationCode = '';
            this.operationData.forEach((data) => {
              if (element.days == data.dayCode) {
                element.fromTime = data.fromTime;
                element.toTime = data.toTime;
                element.id = data.id;
                element.checked = true;
                element.workerhoursOfOperationCode = data.workerhoursOfOperationCode;
                element.fromTimeView = data.fromTimeView;
                element.endTimeView = data.toTimeView; 
              }
            })
          })
          //console.log(this.service.days)
          this.service.common.progressLoader = false;
        }, 1000)
      } else {
        this.service.days.forEach((element) => {
          element.fromTime = '';
          element.toTime = '';
          element.id = 0;
          element.checked = false;
          element.workerhoursOfOperationCode = '';
        })
        this.operationData = [];
        this.service.common.progressLoader = false;
      }
    });
  } catch (error) {
  
  }
 }
/* Name:srinivas
    use: close popUp. */
  close() {
    this.daycode = ''
    this.editShow = true;
    this.readonly = false;
  }
  /* Name:srinivas
    use: check evnet for checkbox. */

  onChecked(check, boolean) {
    //////console.log(this.service.preferedDays[check]);
    //////console.log(boolean);
    this.service.preferedDays[check].checked = boolean.checked;
  }

  // selectExtraDay(day) {
  //   this.selectedExtraDay = day;
  //   this.service.preferedDays.forEach(x => {
  //     if (x.dayCode == day) {
  //       x.checked = true
  //     } else {
  //       x.checked = false;
  //     }
  //   });
  // }
/* Name:srinivas
    use: return all days checked list. */
  repeatAllDay(event) {
    //console.log(event);
    this.allDay = event.checked;
  }
/* Name:srinivas
    use: saves data after submit. */
  onSubmit() {
    //////console.log(this.formDay);
    //console.log(this.hours)
    //console.log(this.readonly);
    if (this.formDay.valid && this.readonly == false) {
      try {
        
      
      this.submitted = true;
      if (this.formDay.value.id == 0) {
        this.fromTime = moment(this.formDay.value.fromTime, 'HH:mm:ss').format('HH:mm:ss');
        this.toTime = moment(this.formDay.value.toTime, 'HH:mm:ss').format('HH:mm:ss');
      } else {
        this.fromTime = this.formDay.value.fromTime;
        this.toTime = this.formDay.value.toTime;
      }
      //////console.log(this.fromTime, this.toTime);
      if (this.fromTime && this.toTime) {
        let data;
        if (this.allDay == true) {
          data = {
            "dayCodeList": [2, 3, 4, 5, 6, 7, 1],
            "workerCode": this.userCode,
            "fromTime": this.fromTime,
            "workerhoursOfOperationCode": this.formDay.value.workerhoursOfOperationCode,
            "id": this.formDay.value.id,
            "toTime": this.toTime
          }
        } else {
          data = {
            "dayCode": this.daycode,
            "workerCode": this.userCode,
            "fromTime": this.fromTime,
            "workerhoursOfOperationCode": this.formDay.value.workerhoursOfOperationCode,
            "id": this.formDay.value.id,
            "toTime": this.toTime
          }
        }


        if (!this.allDay) {
          if (this.formDay.value.id == 0) {
            this.service.saveHoursOfOperation(data, (res) => {
              //////console.log(res);
              if (res.responsecode == 200) {
                this.service.common.message = res.message;
                this.service.common.successMessage = true;
                this.daycode = '';
                this.editShow = true;
                this.allDay = false;
                this.getWorkerHours(this.userCode);
              } else {
                this.service.common.message = res.message;
                this.service.common.errorMessage = true;
              }
            })
          } else {
            if (this.hours != undefined) {
              this.service.updateHoursOfOperation(this.hours, data, (res) => {
                if (res.responsecode == 200) {
                  this.service.common.message = res.message;
                  this.service.common.successMessage = true;
                  this.daycode = '';
                  this.editShow = true;
                  this.getWorkerHours(this.userCode);
                } else {
                  this.service.common.message = res.message;
                  this.service.common.errorMessage = true;
                }
              })
            }
          }
        } else {
          this.service.saveHoursOfOperationForWeek(data, (res) => {
            //////console.log(res);
            if (res.responsecode == 200) {
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              this.daycode = '';
              this.editShow = true;
              this.allDay = false;
              this.getWorkerHours(this.userCode);
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          })
        }
      } else {

      }
      } catch (error) {
              
        }
    }
    else if (this.readonly == true && this.hours) {
      this.submitted = true;
      try {
      this.service.cancelWorkerHoursOfOperationByHoursOfOperationCode(this.hours, (res) => {
        if (res.responsecode == 200) {
          this.service.common.message = res.message;
          this.service.common.successMessage = true;
          this.daycode = '';
          this.editShow = true;
          this.readonly = false;
          this.getWorkerHours(this.userCode);
        } else {
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })
     } catch (error) {
        
      }
    }
  }

/* Name:srinivas
    use: save extra days records. */
  saveExtraDay() {

    this.submitted = true;
    let dayNumnew = [];
    this.service.preferedDays.forEach((element) => {
      //////console.log(element);
      if (element.checked == true) {
        dayNumnew.push(element.dayCode);
      }
    })
    //////console.log(dayNumnew);
    const dayNum = dayNumnew.join(",");
    //////console.log(dayNum);
    const works = {
      "days": dayNum.toString(),
      "workerCode": this.userCode
    }
    try {
      this.service.saveWorkerAvailabilityExtraWork(works, (res) => {
      if (res.responsecode == 200) {
        this.service.common.message = res.message;
        this.service.common.successMessage = true;
        this.service.common.progressLoader = false;
      } else {
        this.service.common.message = res.message;
        this.service.common.errorMessage = true;
        this.service.common.progressLoader = false;
      }
    })
  } catch (error) {
  
  }
  }
/* Name:srinivas
    use: filter search. */
  searchStart(query) {
    // //////console.log('query', query);
    let result = this.selectStart(query)

    this.selectedStartTime = result;
  }
/* Name:srinivas
    use: return the search details. */
  selectStart(query) {
    let result = [];
    for (let a of this.common.timeIntervals) {
      // ////console.log(a);
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result;
  }
/* Name:srinivas
    use: filter  search time. */
  searchEndTime(query) {
    // //////console.log('query', query);
    let result = this.selectEndTime(query)

    this.selectedEndTime = result;

  }
/* Name:srinivas
    use: return search end time. */
  selectEndTime(query) {
    let result = [];
    for (let a of this.common.timeIntervals) {
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result;
  }
/* Name:srinivas
    use: show or hide day . */
  selectDay(event, hours) {
    ////console.log(event);
    ////console.log(hours);
    if (event.checked == true) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }
  }
}
