import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[positiveNumberOnly]'
})
export class PositiveNumberOnlyDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow control keys such as backspace, delete, arrow keys, etc.
    if (['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'].indexOf(event.key) !== -1) {
      return;
    }

    // Allow numbers and only one decimal point
    const input = this.el.nativeElement as HTMLInputElement;
    const currentValue = input.value;
    const decimalExists = currentValue.indexOf('.') !== -1;

    if (isNaN(Number(event.key)) && event.key !== '.' || (event.key === '.' && decimalExists)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;

    // Prevent input if the value is negative
    if (input.value.startsWith('-')) {
      input.value = input.value.replace('-', '');
    }

    // Ensure that the value is a valid number
    if (isNaN(Number(input.value))) {
      input.value = input.value.replace(/[^0-9.]/g, '');
    }
  }
}
