import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TechnicianDashboardComponent } from './technician-dashboard.component';
import { TechnicianDashboardRoutingModule } from './technician-dashboard-routing.module';
import { TechProfileComponent } from './tech-profile/tech-profile.component';
import { TechSettingComponent } from './tech-setting/tech-setting.component';
import { TimeOfRequestComponent } from './time-of-request/time-of-request.component';
import { PreferenceSettingComponent } from './preference-setting/preference-setting.component';
import { NewAppointmentComponent } from './new-appointment/new-appointment.component';
// import { RejectAppointmentComponent } from './new-appointment/reject-appointment/reject-appointment.component';
import { AddLeaveComponent } from './time-of-request/add-leave/add-leave.component';
import { ElegibleAppointmentComponent } from './elegible-appointment/elegible-appointment.component';
import { ElegibleAppointmentFilterComponent } from './elegible-appointment/elegible-appointment-filter/elegible-appointment-filter.component';
import { AddProviderComponent } from './tech-profile/add-provider/add-provider.component';
import { OrgPopupComponent } from './org-popup/org-popup.component';
//import { TrackingLogComponent } from './tracking-log/tracking-log.component';
import { DeleteReasonComponent } from './tech-profile/delete-reason/delete-reason.component';
// import { NotesPopupComponent } from '../providers-dashboard/appointment/notes-popup/notes-popup.component';

@NgModule({
  declarations: [
    TechnicianDashboardComponent,
    TechProfileComponent,
    TechSettingComponent,
    TimeOfRequestComponent,
    PreferenceSettingComponent,
    NewAppointmentComponent,
    // RejectAppointmentComponent,
    AddLeaveComponent,
    ElegibleAppointmentComponent,
    ElegibleAppointmentFilterComponent,
    AddProviderComponent,
    OrgPopupComponent,
    //TrackingLogComponent,
    DeleteReasonComponent,
    // NotesPopupComponent
  ],
  imports: [
    SharedModule,
    TechnicianDashboardRoutingModule
  ],
  exports: [
    TechnicianDashboardComponent,
    // RejectAppointmentComponent
  ],
  providers: [],
  entryComponents : [
    // RejectAppointmentComponent,
    AddLeaveComponent,
    ElegibleAppointmentFilterComponent,
    AddProviderComponent
    // NotesPopupComponent
  ],
})
export class TechnicianDashboardModule { }
