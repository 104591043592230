<div class="d-flex flex-row justify-content-between w-100 mb-4 logo
  align-items-center">

  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
    <div class="col float-start header-name lh_36">
        Email Configuration
    </div>
    <div class="col d-flex text-center" style="justify-content: end;"> 
        <button mat-raised-button color="primary" (click)="!isLoading && onSubmit()" [disabled]="isLoading"><span *ngIf="!isLoading">Save</span><span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>      
    </div>
  </div>

</div>
<div class="w-100">
  <div class="col-md-12">
    <div class="w-100 position-relative scrollContent">
      <form [formGroup]="emailConfigForm" autocomplete="off">
            <div class="col-md-12">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
                <mat-label>Email Address</mat-label>
                <input autoClose matInput formControlName="emailAddress" type="email" placeholder="" maxlength="70">
              </mat-form-field>
              <span class="help-block err"
                *ngIf="emailConfigForm.controls.emailAddress.errors?.required && (!submitted || emailConfigForm.controls.emailAddress.touched)">Email Address is Required</span>
                <span class="help-block err" *ngIf="emailConfigForm.controls.emailAddress.errors?.pattern && (!submitted || emailConfigForm.controls.emailAddress.touched) || emailConfigForm.controls.emailAddress.errors?.pattern">Please provide a valid email</span>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
                <mat-label>Email Display Name</mat-label>
                <input autoClose matInput formControlName="displayName" type="text" placeholder="" maxlength="70">
              </mat-form-field>
              <span class="help-block err"
                *ngIf="emailConfigForm.controls.displayName.errors?.required && (!submitted || emailConfigForm.controls.displayName.touched)">Email Display Name is required</span>
            </div>
            
            <div class="col-md-12">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
                <mat-label>Host</mat-label>
                <input autoClose matInput formControlName="emailHost" type="text" placeholder="" maxlength="70" (keyup)="checkLinkIsGmail($event.target.value)">
                <button *ngIf="enableHostInfo" mat-button matSuffix mat-stroked-button class="matGroupButton infoBtn" (click)="showHostInfo = !showHostInfo">
                  <mat-icon class="ipGroupIc">info</mat-icon>
                </button>
              </mat-form-field>
              <span class="help-block err"
                *ngIf="emailConfigForm.controls.emailHost.errors?.required && (!submitted || emailConfigForm.controls.emailHost.touched)">Host is required</span>
        </div>

        <div class="w-100" *ngIf="showHostInfo">
          <div class="card mb-3 w-100">
            <div class="card-header">Email Configuration Info</div>
            <div class="card-body">
              <!-- <h5 class="card-title"></h5> -->
              <p class="card-text">
                Gmail SMTP settings <br>
                Gmail SMTP server address: smtp.gmail.com<br>
                Gmail SMTP name: Your name<br>
                Gmail SMTP username: Your Gmail address<br>
                Gmail SMTP password: Your Gmail App password<br>
                Gmail SMTP port (TLS): 587<br>
                Gmail SMTP port (SSL): 465<br>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
            <mat-label>Port</mat-label>
            <input autoClose matInput formControlName="emailPort" numbersOnly type="text" placeholder="901" maxlength="20">
          </mat-form-field>
          <span class="help-block err"
            *ngIf="emailConfigForm.controls.emailPort.errors?.required && (!submitted || emailConfigForm.controls.emailPort.touched)">Port is required</span>
        </div>
  
        <div class="col-md-12 mb-3 mt_-10 d-flex">
            <div class="col">
              <mat-checkbox class="" formControlName="emailSsl" (change)="changeSSL($event, 'emailSsl')">SSL</mat-checkbox>
            </div>
            <div class="col">
              <mat-checkbox class="" formControlName="starttlsEnable" (change)="changeSSL($event, 'starttlsEnable')">STARTTLS</mat-checkbox>
            </div>
            <div class="col">
              &nbsp;
            </div>
            <div class="col">
              &nbsp;
            </div>            
        </div>
        <div class="col">
          <span class="help-block err"
            *ngIf="!submitted && !emailConfigForm.controls.emailSsl.value && !emailConfigForm.controls.starttlsEnable.value">Please check SSL or STARTTLS atleast one field check required</span>
        </div>

        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
            <mat-label>Username</mat-label>
            <input matInput type="text" formControlName="userName" placeholder="" maxlength="70" [attr.autocomplete]="'new-username'" autocomplete="chrome-off">
          </mat-form-field>
          <span class="help-block err"
            *ngIf="emailConfigForm.controls.userName.errors?.required && (!submitted || emailConfigForm.controls.userName.touched)">Username is required</span>
        </div>

        <div class="col-md-12">
          <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
            <mat-label>Password</mat-label>
            <input matInput [type]="close_pass_eye ? 'password' : 'text'" formControlName="password" placeholder="" maxlength="70" [attr.autocomplete]="'new-password'" autocomplete="chrome-off">
            <button mat-button matSuffix mat-stroked-button class="matGroupButton infoBtn" (click)="showLinkInfo = !showLinkInfo">
              <mat-icon class="ipGroupIc">info</mat-icon>
            </button>
            <button mat-button matSuffix mat-stroked-button class="matGroupButton visBtn" (click)="close_pass_eye = !close_pass_eye" title="{{close_pass_eye ? 'Show Password' : 'Hide Password'}}">
              <mat-icon class="ipGroupIc">{{close_pass_eye ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>            
          </mat-form-field>
          <div class="w-100" *ngIf="showLinkInfo">
            <div class="card mb-3 w-100">
              <div class="card-header">Password Description</div>
              <div class="card-body">
                <!-- <h5 class="card-title"></h5> -->
                <p class="card-text">How to create an app password for sending emails, please refer to this URL.</p>
                <a href="https://support.google.com/mail/answer/185833?hl=en" target="_blank" class="card-link">Click Here</a>
              </div>
            </div>
          </div>
          <span class="help-block err"
            *ngIf="emailConfigForm.controls.password.errors?.required && (!submitted || emailConfigForm.controls.password.touched)">Password is required</span>            
        </div>
              
      </form>
      </div>
  </div>
</div>

<div class="w-100 mt-3" *ngIf="emailConfigForm.controls.emailSettingCode?.value">
  <div class="col-md-12">
    <div class="row">
      <div class="col">
        Send Test Email (save settings first by clicking "Save" button)
        <hr class="mt-1">
      </div>      
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <form>
      <div class="input-group mb-3">
        <input type="email" class="form-control emilInput" [formControl]="toEmail" placeholder="email@mail.com">
        <span class="input-group-text cursor-pointer" type="button" [disabled]="isTestEmailLoading" (click)="!isTestEmailLoading && sendTestEmailByOrgCode()">
          <span *ngIf="!isTestEmailLoading">Send test email</span><span *ngIf="isTestEmailLoading" class="spinner-border spinner-border-sm"></span></span>
      </div>
        <!-- Display error messages -->
      <div class="w-100 mt-4" *ngIf="toEmail.invalid && (sendTestEmailSubmitted || toEmail.dirty || toEmail.touched)">
        <span class="help-block err" *ngIf="toEmail.errors?.required">Email is required</span>
        <span class="help-block err" *ngIf="toEmail.errors?.pattern">Please enter a valid email address</span>
      </div>
      </form>
    </div>
  </div>
</div>

<ng-template #errorLog_popup let>
  <div class="row  ">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Test Email Log</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
      </div>
    </div>
  </div>

  <div class="col w-100" style="word-wrap: break-word;">
    {{errorLog_popupMessage}}
  </div>
  <div class="row mt-3">
    <div class="col-lg-12 text-end">
      <button class="me-3" mat-button (click)="closePopup()">Cancel</button>
  </div>
  </div>

</ng-template>