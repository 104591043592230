import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class TechSettingService {

  settingData = [
    {
      "globalSettingCode": " ",
      "globalSettingName": " ",
      "globalSettingValue": " ",
      "id": 0,
      "localSettingCode": " ",
      "localSettingValue": " ",
      "settingType": " ",
      "userCode": " "
    }
  ]

  constructor(public service: ApiRootService, public http: HttpClient, public storage: SessionStorageService, public common: CommonService) { }
  /* Name:srinivas
        use: returns all local settings value. */
  getAllLocalSettingWithDefaultGlobalSettingByUserCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllLocalSettingWithDefaultGlobalSettingByUserCode/' + code).subscribe((data) => {
      callback(data)
    })
  }
/* Name:srinivas
      use: update etting by uercode. */
  updateSettingByUserCode(code, data, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateSettingByUserCode/' + code, data).subscribe((data) => {
      callback(data);
    })
  }

  
    /* Name:Vivek Chauhan new API
    use: for delete/remove [user/Staff] */
    softDeleteUserByUserCode(userCode, callback) {
      return this.http.delete(this.service.HEALTHCARE_URL + '/softDeleteUserByUserCode/' + userCode).subscribe((data) => {
        callback(data);  
      })
    }
}
