 <div class="row">  
   <div class="col-md-12 p-0">
        <i class="material-icons close-icon" (click)="onNoClick()">
            close
        </i>
    </div>
   
    <div class="col-md-12 p-0">
        <div id="map" #mapDis style="overflow: visible; height: 499px;"></div>
    </div>
    <div class="w-100 ">
        <div class="drivetime " id="mapLegend"></div>
    </div>
    
</div>   

