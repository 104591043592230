// import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';
import {RxStompConfig} from '@stomp/rx-stomp'
import * as SockJS from 'sockjs-client';
import { Versions } from '@stomp/stompjs';
import { environment } from 'src/environments/environment';

export const myRxStompConfig: RxStompConfig = {
  // Which server? 
  // brokerURL: 'wss://www.demo.gigflex.com:8088/socket',
  //  brokerURL: 'wss://healthcare.gigflex.com:8088/socket',
  // brokerURL: 'wss://healthcare-dev.gigflex.com:8088/socket',
 //https://www.demo.gigflex.com:8088/socket/info?t=1618923327580 
  // Headers https://healthcare-dev.gigflex.com:8088/socket/info?t=1618923389564
  // Typical keys: login, passcode, host
  //connectHeaders: {
  //   login: 'guest',
  //   passcode: 'guest'
  // },

  
  brokerURL: environment.brokerURL,

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
      ////console.log(new Date(), msg);
  }
};
// Try only versions 1.0 and 1.1 
myRxStompConfig.stompVersions = new Versions(['1.0', '1.1']);
myRxStompConfig.webSocketFactory = function () { 
  // return new SockJS("https://www.demo.gigflex.com:8088/socket");
  //  return new SockJS("https://healthcare.gigflex.com:8088/socket");
  // return new SockJS("https://healthcare-dev.gigflex.com:8088/socket");

  return new SockJS(environment.brokerURL);

};
