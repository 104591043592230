<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">{{'licence.labels.Licence' | translate }}
  </div>
  <div class="col-lg-5 col-md-5 col-sm-12 searchfilter">
    <input autocomplete="off" matInput
      (keyup)="applyFilter($event.target.value)" type="text"
      class="form-control"
      placeholder="{{'licence.labels.Search Licence' | translate }}">
    <mat-icon class="cursor-pointer">search</mat-icon>    
  </div>

  <ng-template [ngIf]="controlsPermissions.addPermission === true">
  <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    <button mat-raised-button color="primary" class="ms-2"  (click)="addLicence()" title="{{'licence.labels.Add Licence' | translate }}">Add</button>
  </div>
  </ng-template>

</div>
<div class="w-100">
  <div class="col-md-12">
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab">

      <ng-container matColumnDef="licenceName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'licence.labels.Licence' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.licenceName}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
        <td mat-cell *matCellDef="let element" class="action">

          <ng-template [ngIf]="controlsPermissions.editPermission === true">
          <mat-icon class="cursor-pointer" (click)="onEdit(element)">edit</mat-icon>
          </ng-template>

          <ng-template [ngIf]="controlsPermissions.delPermission === true">
            <mat-icon class="cursor-pointer ms-2" (click)="softDeleteLicenceMasterByLicenceCode(element)">delete</mat-icon>
            </ng-template>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!service.common.progressLoader && !service.common.infiniteLoader) && licenceData.length === 0" class="p-3 noList">{{'licence.messages.Licence are not available' | translate }}</div>
    </div>

    <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>
    
  </div>
</div>