import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { DataService } from './data.service';
@Component({
  selector: 'threaded-note-list',
  templateUrl: './threaded-note-list.component.html',
  styleUrls: ['./threaded-note-list.component.scss']
})
export class ThreadedNoteListComponent implements OnInit {
  postDataArr:any;
  @Input() noteArray: any;
  @Output() noteChangeEvent: EventEmitter<any> =new EventEmitter<any>();

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    // console.log('TreadedNoteList:', this.noteArray);
    this.dataService.data$.subscribe(data => {
      if(data && data !== null){
        // console.log('dataSubject', data, this.noteArray);
        var foundIndex = this.noteArray?.findIndex(note => note.noteEntityMappingCode === data.noteEntityMappingCode && note.entityUuid === data.entityUuid);
        // console.log('foundIndex: ', foundIndex)
        if (foundIndex > -1) {
          this.noteArray[foundIndex].note = data.updatedNote.note;
          this.noteArray[foundIndex].isPublic = data.updatedNote;
          this.updateVal(this.noteArray[foundIndex]);
        }
      } else {
        
      }
    });
  }

  updateVal(val){
    delete val.updatedNote;
    return val;
  }
  
  updateNote(note: any){
    // console.log('NoteChangeEvent_UpdateNote: ', note);
    note.isNoteEdit = true;
    delete note.isNoteDelete;
    this.noteChangeEvent.emit(note);
  }

  deleteNote(note: any){
    // console.log('NoteChangeEvent_DeleteNote: ', note);
    note.isNoteDelete = true;
    delete note.isNoteEdit;
    this.noteChangeEvent.emit(note);
  }

}
