import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core'
import { ApiRootService } from 'src/app/shared/api-root.service'; 
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
    providedIn : 'root'
})

export class AdminCustomerReportService {
    constructor(
    private httpClient: HttpClient,
    private _apiSVC: ApiRootService,
    public common: CommonService
    ){}

//     New Change
// GET API's with pagination
// /staffingservice/v2/getAdminTimeClock/{organizationCode}/{startDt}/{endDt}
// /staffingservice/v2/getAdminTimeClock/{organizationCode}/{startDt}/{endDt}/{searchVal}
//  /staffingservice/v2/getTimeCardReport/{organizationCode}/{startDt}/{endDt}/{searchVal

    getAdminTimeClock(organizationCode, checkinDate) {
     return this.httpClient.get(this._apiSVC.getHealthCareUrl()+`/getAdminTimeClock/${organizationCode}/${checkinDate}`);
    }

    sendAdminActionTimeSheet(payload, callback) {
        return this.httpClient.post(this._apiSVC.getHealthCareUrl() + '/sendAdminActionTimeSheet', payload).subscribe((data) => {
          callback(data);
        })
      }

    saveManualClockInAndOut(payload, callback) {
      return this.httpClient.post(this._apiSVC.getHealthCareUrl() + '/saveManualClockInAndOut', payload).subscribe((data) => {
        callback(data);
      })
    }

    getTimeCardReport(organizationCode:string, checkinDate:string, customerName:string, page:number, limit:number) {
      return this.httpClient.get(this._apiSVC.getHealthCareUrl()+`/getTimeCardReport/${organizationCode}/${checkinDate}/${customerName}?page=${page}&limit=${limit}`);
     }

     getAdminTimeClockByPageee(organizationCode:string, startDt:string, endDt:string, searchVal:string, page:number, limit:number) {
      return this.httpClient.get(this._apiSVC.getHealthCareUrl()+`/v2/getAdminTimeClock/${organizationCode}/${startDt}/${endDt}/${searchVal}?page=${page}&limit=${limit}`);
     }

         /* Name:Vivek Chauhan
      use: for getting Time Card List by OrganizationCode */
  //     getAdminTimeClockByPage(organizationCode, startDt, endDt, page, limit, search, callback) {
  //       let apiURL = null;
  //       if(startDt && endDt){
  //         apiURL = this._apiSVC.getHealthCareUrl() + '/v2/getAdminTimeClock/' + organizationCode + + '/' + startDt + '/' + endDt + '?page=' + page + '&limit=' + limit
  //       }
  //       if(startDt && endDt && search){
  //         apiURL = this._apiSVC.getHealthCareUrl() + '/v2/getAdminTimeClock/' + organizationCode + '/' + startDt + '/' + endDt + '/' + search +'?page=' + page + '&limit=' + limit
  //       }
  //       return this.httpClient.get(apiURL).subscribe((data) => {
  //         callback(data);
  //         //console.log(data);
  //       })
  // }

  getAdminTimeClockByPage(organizationCode, startDt, endDt, page, limit, search, callback) {
    if (!startDt || !endDt) {
        return; // or handle this case appropriately
    }

    let apiURL = this._apiSVC.getHealthCareUrl() + '/v2/getAdminTimeClock/' + organizationCode + '/' + startDt + '/' + endDt;
    
    if (search) {
        apiURL += '/' + search;
    }

    apiURL += '?page=' + page + '&limit=' + limit;

    return this.httpClient.get(apiURL).subscribe((data) => {
        callback(data);
    });
}

getTimeCardReportByPage(organizationCode, startDt, endDt, page, limit, search, callback) {
    if (!startDt || !endDt) {
        return; // or handle this case appropriately
    }

    let apiURL = this._apiSVC.getHealthCareUrl() + '/v2/getTimeCardReport/' + organizationCode + '/' + startDt + '/' + endDt;
    
    if (search) {
        apiURL += '/' + search;
    }

    apiURL += '?page=' + page + '&limit=' + limit;

    return this.httpClient.get(apiURL).subscribe((data) => {
        callback(data);
    });
  }

}