import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class CreateAppointmentService {

  scheduleForm = {
    "daysList": [
      0
    ],
    "customerAddressCode": "string",
  "customerCode": "string",
    "durationHours": 0,
    "durationMinute": 0,
    "endDate": "",
    "isDateOfMonth": false,
    "jobName": "",
    "notes": "",
    "organizationCode": "",
    "patientCode": "",
    "patientDetail": {
      "isActive": true,
      "mobileCountryCode": "",
      "mobileNumber": "",
      "organizationCode": "",
      "patientAddress": "",
      "patientCity": "",
      "patientCode": "",
      "patientCountry": "",
      "patientEmail": "",
      "patientLatitude": "",
      "patientLongitude": "",
      "patientName": "",
      "patientState": "",
      "phoneCountryCode": "",
      "phoneNumber": "",
      "zipCode": "",
      "patientApartmentNo": "",
      "preferredCountryCode": "",
      "preferredNumber": "",
      'preferredPhoneType': ''
    },
    "organizationBaseProcedureCodeList": [],
    "repeatType": "",
    "repeatValue": 0,
    "startDate": "",
    "startTime": "",
    "additionalCountryCode": "",
    "additionalNumber": "",
    'preferredPhoneType': '',
    "mobileCountryCode": "",
    "mobileNumber": "",
    "homeCountryCode": "",
    "homeNumber": "",
    "isDateFlexible": false
  };

  splitForm = {
    "appointmentsCode": "",
    "appointmentsDurationCode": "",
    "procedureDetailsesList": []
  };

  constructor(private http: HttpClient, public service: ApiRootService, public common: CommonService, public session: SessionStorageService) { }
/* Name:srinivas
       use: return all patien details.*/
  getPaitents(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByOrganizationCode/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }


  /* Name:Vivek Chauhan
       use: return customer address list */
  getCustomerAddressListByCustomerAdminCode(customerAdminCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getCustomerAddressListByCustomerAdminCode/' + customerAdminCode).subscribe((data) => {
      callback(data);
    })
  }

/* Name:Vivek Chauhan
    use: return customer address list by organization Code */
    getCustomerAddressListByOrganizationCode(organizationCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCode/' + organizationCode).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:srinivas
       use: return patient detail by atien code */
  getCustomerDetails(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCodeByPage/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

/* Name:srinivas
       use: return patient detail by atien code */
  getPatientDetailByCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPatientDetailByPatientCode/' + code).subscribe((data) => {
      callback(data);
    })
  }
  /* Name:srinivas
       use: return all procedures by org code */
  getAllProcedureByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllProcedureByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }
  /* Name:srinivas
       use: save appointment detail. */
  createWorkerJob(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/createWorkerAppointment', formData).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
       use: update worker details */
  updateWorkerJob(jobCode, formData, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateWorkerAppointmentSeriesByAppointmentCode/' + jobCode, formData).subscribe((data) => {
      callback(data);
    })
  }
  // getEligibleWorkerForJobsByJobDurationCode(code, callback) {
  //   return this.http.get(this.service.getHealthCareUrl() + '/getEligibleWorkerForJobsByJobDurationCode/' + code).subscribe((data) => {
  //     callback(data);
  //   })
  // }
/* Name:srinivas
       use: return eligble worke details */
  getEligibleWorkerForJobsByJobDurationCode(code, scheduledDT, fromDT, toDT, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetEligibleTechnicianForAppointmentByAppointmentsDurationCode/' + code + '/' + scheduledDT + '/' + fromDT + '/' + toDT).subscribe((data) => {
      callback(data);
    })
  }

/* Name:srinivas
       use:assign appointment to worker. */
  assignToWorkerByJobDurationCode(code, data, callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/adminAssignAppointmentToWorkerByAppointmentsDurationCode/' + code, data).subscribe((data) => {
      callback(data);
    })
  }
  /* Name:srinivas
       use:return all assigned jobs */
  getJobsAssignToWorkerByJobsCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetAssignToWorkerByAppointmentsCodeSpecialRes/' + code).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
       use: return job sries by job code. */
  getJobsSeriesByJobsCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetBasicWorkerAppointmentByAppointmentCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

/* Name:srinivas
       use: return unassigned appointment details */
  adminUnAssignAppointmentsByAppointmentsDurationCodeList(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminUnAssignAppointmentsByAppointmentsDurationCodeList/' + code).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
       use: return appointment details for split.*/
  adminGetAppointmentDetailsToSplit(list, code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminSplitAppointmentByAppointmentDurationList/' + list + '/' + code).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
       use: save split appointment */
  adminSplitAppointment(data, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/adminSplitAppointment/', data).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
       use: return schedule count and procedures details.*/
  mobileAdminGetScheduleCountAndOrganizationBasedProcedureDetailsByWorkerCodeScheduleDateAndDateRange(workerCode, scheduledDT, fromDT, toDT, orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileAdminGetScheduleCountAndOrganizationBasedProcedureDetailsByWorkerCodeScheduleDateAndDateRange/' + workerCode + '/' + scheduledDT + '/' + fromDT + '/' + toDT + '/' + orgCode).subscribe((data) => {
      callback(data);
    })
  }

}
