<div class="row">
  <div class="col-md-12 col-sm-12">
    <div class="row mb-2">
      <div class="col-md-6 align-self-center">
        <h6 class="skill-test pt-2">{{popupTitle}}</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
        </div>
      </div>
    </div>  
    <form [formGroup]="reqForm" (ngSubmit)="onSubmit()">
      <!-- <div class="form-group col-md-12 col-sm-12 p-0">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Distribution Name</mat-label>
          <input autoClose matInput formControlName="distributionName" type="text"
            placeholder="" maxlength="70">
        </mat-form-field>
        <span class="help-block err"
          *ngIf="reqForm.controls.distributionName.errors?.required && (!submitted || reqForm.controls.distributionName.touched)">{{ 'distributionList.validators.Distribution name is required' | translate }}</span>
      </div> -->

      <div class="form-group col-md-12 col-sm-12 p-0">
        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Team Name</mat-label>
          <input autoClose matInput formControlName="teamName" type="text"
            placeholder="" minlength="2" maxlength="100">            
        </mat-form-field>     
        <span class="help-block err"
          *ngIf="(submitted && reqForm.controls.teamName.errors?.required) || (reqForm.controls.teamName.touched && reqForm.controls.teamName.errors?.required)">Team name is required</span>
          <span class="help-block err"
          *ngIf="submitted && reqForm.controls.teamName.errors?.minlength || submitted && reqForm.controls.teamName.errors?.maxlength">Make sure the team name is between 2-100 characters long</span>   
      </div>

      <div class="w-100 col-md-12 col-sm-12 p-0">
        <angular2-multiselect [data]="allStaffMemberList" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedCodeList" [settings]="staffDropdownSettings" formControlName="userCodeList">
          <c-badge>
            <ng-template let-item="item">
              <div class="col-md-12">
                <div class="row">
                  <div class="col" style="max-width: calc(100% - 122px);">
                    <div class="row">
                      <label title="{{item.itemName}}" style="margin: 0px; padding: 3px 10px 0px 5px; flex-wrap: nowrap; white-space: nowrap;">{{item.itemName}}</label>
                    </div>
                  </div>
                  <div (click)="$event.stopPropagation();" class="col">
                    <div class="row justify-content-end">
                      <mat-checkbox class="requiredCheckbox" (click)="$event.stopPropagation();" (change)="isSupervisor($event, item)" [checked]='item.isSupervisor'>Supervisor</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </c-badge>
        </angular2-multiselect>  
        <span class="help-block err"
        *ngIf="(submitted && reqForm.controls.userCodeList.errors?.required) || (reqForm.controls.userCodeList.touched && reqForm.controls.userCodeList.errors?.required)">Please select a staff</span>      
    </div>

      <div class="w-100 col-md-12 col-sm-12 p-0">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-select [value]="selectedAddressCodeList" (selectionChange)="onJobSiteChange($event)" placeholder="Select Site" multiple>
              <ng-container *ngFor="let customer of customerSiteListWithDetail | keyvalue">
                <mat-optgroup [label]="customer.key">
                  <ng-container *ngFor="let item of customer.value">
                  <mat-option [value]="item.customerAddressCode">
                    {{item?.siteName ? item.siteName : 'Text Not Available'}}
                  </mat-option>
                </ng-container>
                </mat-optgroup>
              </ng-container>
            </mat-select>
          </mat-form-field>
      </div>
            
    </form>

    <div class="d-flex offset-md-8 justify-content-end">
      <button mat-button (click)="onNoClick()" class="cancel-btn-primary">Cancel</button>
      <button type="submit" class="ms-2 pe-4 ps-4 nowrapTxt" mat-raised-button color="primary" (click)="!isLoading && onSubmit()"> {{ popupTitle === 'Add Team' ? 'Save' : 'Update'}} <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>
    </div>
  </div>
</div>

