import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { CreateAppointmentService } from './create-appointment.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RepeatAppointmentComponent } from './repeat-appointment/repeat-appointment.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { startWith, map } from 'rxjs/operators';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MapsAPILoader } from '@agm/core';
import { AssignedWorkerComponent } from './assigned-worker/assigned-worker.component';
import { PatientDataComponent } from './patient-data/patient-data.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

const moment = _moment;
declare var google;
declare var window;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})

export class CreateAppointmentComponent implements OnInit, OnDestroy {

  scheduleForm: FormGroup;
  options = { types: ['address'], componentRestrictions: { country: ["in", "us"] } };
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  countrycodem = this.service.common.CountryCode;
  countrycodep = this.service.common.CountryCode;
  preferredCountryCode = this.service.common.CountryCode;
  selectedStartTime = [];
  selectedEndTime = [];
  submitted = true;
  assignList = [];
  patientData = [];
  optionsPatient = [];
  filteredOptions: Observable<any>;
  patientDataByCode = [];
  procedureData = [];
  today = new Date();
  itip;
  itim;
  instancep;
  durationSelectDropdown = [];
  updatedAddress: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  allDurations = [];
  selectedDuration;
  patientCode;
  isDateFlexible = false;
  labelNumbers : boolean = false;
  currentSelectedDate;
  yearMonthDateFormat = 'YYYY-MM-DD';
  saveAndassign :boolean =false;
  cancelSchedule = false;

  public address_list:any = [];
  address_popup_ref : any;
  @ViewChild('address_popup', { static: true }) address_popup: TemplateRef<any>;
  selected_customer :any = []

  constructor(public service: CreateAppointmentService, private dialog: MatDialog,
    private router: Router, private datepipe: DatePipe,
    private mapsAPILoader: MapsAPILoader, public translate: TranslateService, public route: ActivatedRoute, public dialogRef: MatDialogRef<CreateAppointmentComponent>,) {
    this.translate.setDefaultLang(this.service.session.get('language'));
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Job type",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "customClassAng2Multiselect",
      // badgeShowLimit: 5,
      maxHeight:200,
      autoPosition: true
    };
    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
  }

  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
  }

  ngOnInit() {
    this.durationSelectDropdown = this.service.common.durationHours;
    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    this.itip = document.querySelector('#phone');
    this.itim = document.querySelector('#mobile');
    this.getFormReset();
    try {
  

    this.service.getAllProcedureByOrganizationCode(this.service.session.get('orgCode'), (response) => {
      //////console.log(response);
      if (response.responsecode == 200) {
        this.procedureData = response.data;
        response.data.forEach(element => {
          element.image = this.service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon;
          element.itemName = element.procedureName;
        });
        this.dropdownList = response.data;
        // response.data.forEach(element => {
        //   this.selectedItems.push(element);
        // });
      }
    })
    this.service.common.getTimeSlotByOrganisationCode(this.service.common.local.get('orgCode'), (response) => {
      this.selectedStartTime = response.data;
      this.selectedEndTime = response.data;
      //////console.log(this.selectedEndTime);
    });
    } catch (error) {
  
}
    // this.getPatients();
    this.getCustomerDetails();
    this.getCustomerAddressListByCustomerAdminCode();
    this.route.queryParams.subscribe((data) => {
      //console.log(data);
      if (data.patientCode) {
        this.patientCode = data.patientCode;
      }
    })

    // this.filteredOptions = this.scheduleForm.controls['patientName'].valueChanges
    //   .pipe(
    //     startWith<string>(''),
    //     map(name => this._filter(name))
    //   );

      this.scheduleForm.valueChanges.subscribe((val)=>{
        this.cancelSchedule = true;
      });
      setTimeout(()=>{
        this.cancelSchedule = false;
      },500);
  }
/* Name:srinivas
       use: set all cntrol to empty. */
  getFormReset() {

    this.getServiceEmpty();
    this.scheduleForm = new FormGroup({
      'address': new FormControl(null, Validators.required),
      'phoneCountryCode': new FormControl(this.countrycodep, Validators.required),
      'phoneNumber': new FormControl(null),
      'mobileCountryCode': new FormControl(this.countrycodem),
      'mobileNumber': new FormControl(null),
      'startDate': new FormControl(null, Validators.required),
      'endDate': new FormControl(null),
      'startTime': new FormControl(null, Validators.required),
      'duration': new FormControl(null, Validators.required),
      'procedureCode': new FormControl(null, Validators.required),
      'patientName': new FormControl(null, Validators.required),
      // 'jobName': new FormControl(null, Validators.required),
      'notes': new FormControl(null),
      'preferredCountryCode': new FormControl(this.preferredCountryCode, Validators.required),
      // 'preferredNumber': new FormControl(null, [Validators.required, Validators.pattern(this.service.common.phonenumber)]),
      'preferredNumber': new FormControl(null),
      'isPeferredNumber': new FormControl(null, Validators.required),
      "skillcode": new FormControl(null, [Validators.required]),
      'isDateFlexible': new FormControl(null),
      'selected_address' : new FormControl()
    })


  }
/* Name:srinivas
       use: set patient name. */
  displayFn(optionsPatient): string | undefined {
    //////console.log(optionsPatient);
    // return optionsPatient ? optionsPatient.patientName : '';
    return optionsPatient ? optionsPatient.customerName : '';
  }
/* Name:srinivas
       use: return filter details */
  // private _filter(name): String[] { 
  //   this.scheduleForm.controls['skillcode'].setValue(null);

  //   if (name && name.customerCode != undefined) {
  //     //console.log('in')
  //     this.scheduleForm.controls['skillcode'].setValue(name.customerCode);
  //     //this.getPatientsDetail(name.customerCode);
  //   }
  //   if (name && name.customerName) {

  //     const filterValue = name.customerName.toLowerCase();

  //     //////console.log(filterValue);
  //     return this.optionsPatient.filter(option => option.customerName.toLowerCase().includes(filterValue));
  //   } else {

  //     //////console.log(name);
  //     return this.optionsPatient;
  //   }
  
  // }

  

  private _filter(value): any { 
     this.selected_customer = [];
     this.scheduleForm.controls['address'].setValue('');
    this.scheduleForm.controls['skillcode'].setValue(null);

    if (value && value.customerCode != undefined) {
      //console.log('in')
      this.scheduleForm.controls['skillcode'].setValue(value.customerCode);

      this.address_list = value.customerAddressResponseList ;
      this.selected_customer = value ;

      this.scheduleForm.controls['phoneNumber'].setValue(this.selected_customer?.mobilePhone);

      if (value?.customerAddressResponseList?.length > 1){
          this.address_popup_ref  = this.dialog.open(this.address_popup, {
            width: '500px',
            disableClose: true,
          });
        } else {
        if (value?.customerAddressResponseList[0])
          this.selectSingleAddress(value?.customerAddressResponseList[0]);
        }
    }  
}


    selectAddress(){       
    if( this.scheduleForm.controls.selected_address.value) {
      this.scheduleForm.controls['address'].setValue(this.scheduleForm.controls.selected_address.value.location);

      this.service.scheduleForm.customerCode  = this.selected_customer.customerCode;
      this.service.scheduleForm.customerAddressCode = this.scheduleForm.controls.selected_address.value.customerAddressCode;     
     
      this.service.scheduleForm.additionalCountryCode = this.selected_customer.mCountryCode;
      this.service.scheduleForm.additionalNumber = this.selected_customer.workPhone;

      this.service.scheduleForm.homeCountryCode = this.selected_customer.mCountryCode;
      this.service.scheduleForm.homeNumber = this.selected_customer.homePhone;

      this.service.scheduleForm.mobileCountryCode = this.selected_customer.mCountryCode;
      this.service.scheduleForm.mobileNumber = this.selected_customer.mobilePhone;

      this.address_popup_ref.close();
    }     
    }


  selectSingleAddress(selectedAddress: any) {
    if (selectedAddress) {
      this.scheduleForm.controls['address'].setValue(selectedAddress?.location);

      this.service.scheduleForm.customerCode = this.selected_customer.customerCode;
      this.service.scheduleForm.customerAddressCode = selectedAddress?.customerAddressCode;


      this.service.scheduleForm.additionalCountryCode = this.selected_customer.mCountryCode;
      this.service.scheduleForm.additionalNumber = this.selected_customer.workPhone;

      this.service.scheduleForm.homeCountryCode = this.selected_customer.mCountryCode;
      this.service.scheduleForm.homeNumber = this.selected_customer.homePhone;

      this.service.scheduleForm.mobileCountryCode = this.selected_customer.mCountryCode;
      this.service.scheduleForm.mobileNumber = this.selected_customer.mobilePhone;
    }
  }


/* Name:srinivas
       use: return filter details with patient name. */
  selectedPatient(event) {
    // this.filteredOptions = this.scheduleForm.controls['patientName'].valueChanges
    //   .pipe(
    //     startWith<string>(''),
    //     map(name => this._filter(name))
    //   );

    this._filter(event.option.value)

    //////console.log(event.option.value);
   // this.getPatientsDetail(event.option.value.patientCode);

  }

/* Name:srinivas
       use: return patient details. */
  getPatientsDetail(code) {
    //////console.log(code);
    this.labelNumbers = false;
    try {
      
   
    this.service.getPatientDetailByCode(code, (data) => {

      this.codeLatLng(data, (addr) => {
        // alert(addr);
        ////console.log(addr, addr[0].formatted_address);
        this.updatedAddress = addr[0].formatted_address;
        this.scheduleForm.controls['address'].setValue(this.updatedAddress);
      });
      this.scheduleForm.controls['skillcode'].setValue(data.data.patientCode);

      this.patientDataByCode = data.data;
      //console.log(data.data);
      // this.service.scheduleForm.patientDetail.patientName = this.patientDataByCode['patientName'];
      this.service.scheduleForm.patientCode = this.patientDataByCode['patientCode'];
      if (this.patientDataByCode['phoneCountryCode']) {
        this.service.scheduleForm.homeCountryCode = this.patientDataByCode['phoneCountryCode'];
        this.countrycodep = this.patientDataByCode['phoneCountryCode'];
        this.scheduleForm.controls['phoneCountryCode'].setValue(this.countrycodep);
      }
      if (this.patientDataByCode['mobileCountryCode']) {
        this.countrycodem = this.patientDataByCode['mobileCountryCode'];
        this.service.scheduleForm.mobileCountryCode = this.patientDataByCode['mobileCountryCode'];
        this.scheduleForm.controls['mobileCountryCode'].setValue(this.countrycodem);
      }
      if (this.patientDataByCode['preferredCountryCode']) {
        this.preferredCountryCode = this.patientDataByCode['preferredCountryCode'];
        this.service.scheduleForm.additionalCountryCode = this.preferredCountryCode;
        this.scheduleForm.controls['preferredCountryCode'].setValue(this.preferredCountryCode);
        this.service.scheduleForm.additionalNumber = this.patientDataByCode['preferredNumber'];
      }

      this.service.scheduleForm.homeNumber = this.patientDataByCode['phoneNumber'];
      this.service.scheduleForm.mobileNumber = this.patientDataByCode['mobileNumber'];

      this.scheduleForm.controls['phoneNumber'].setValue(this.service.scheduleForm.homeNumber);
      this.scheduleForm.controls['mobileNumber'].setValue(this.service.scheduleForm.mobileNumber);
      this.scheduleForm.controls['preferredNumber'].setValue(this.service.scheduleForm.additionalNumber);

      this.service.scheduleForm.patientDetail.patientCity = this.patientDataByCode['patientCity'],
        this.service.scheduleForm.patientDetail.patientCountry = this.patientDataByCode['patientCountry'],
        this.service.scheduleForm.patientDetail.patientLatitude = this.patientDataByCode['patientLatitude'],
        this.service.scheduleForm.patientDetail.patientLongitude = this.patientDataByCode['patientLongitude'],
        this.service.scheduleForm.patientDetail.patientState = this.patientDataByCode['patientState'],
        this.service.scheduleForm.patientDetail.zipCode = this.patientDataByCode['zipCode']
      this.service.scheduleForm.patientDetail.patientAddress = this.patientDataByCode['patientAddress'];
      this.service.scheduleForm.patientDetail.patientApartmentNo = this.patientDataByCode['patientApartmentNo'];
      this.service.scheduleForm.preferredPhoneType = this.patientDataByCode['preferredPhoneType'];

      // this.scheduleForm.controls['address'].setValue(this.patientDataByCode['patientAddress']);
    })
   } catch (error) {
      
    }
  }
/* Name:srinivas
       use: return lat long details */
  codeLatLng(response, callback) {
    let geocoder = new google.maps.Geocoder();

    geocoder.geocode({ 'address': response.data.patientApartmentNo + ',' + response.data.patientAddress + ',' + response.data.patientCity + ',' + response.data.patientState + ',' + response.data.zipCode + ',' + response.data.patientCountry }, function (results, status) {
      ////console.log(results, status);
      callback(results);
    });
  }

/* Name:srinivas
       use: set country code to control */

  getNumber(event, countryCode) {
    this.scheduleForm.controls[countryCode].setValue(event.dialCode);
    this.service.scheduleForm.patientDetail[countryCode] = event.dialCode;
  }
/* Name:srinivas
       use: return search details */
  searchStart(query) {
    // //////console.log('query', query);
    let result = this.selectStart(query)
    this.selectedStartTime = result;
  }
/* Name:srinivas
       use: filter search result */
  selectStart(query) {
    //////console.log(query);
    let result = [];
    for (let a of this.service.common.timeIntervals) {
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }

/* Name:srinivas
       use: finish search end */
  searchEnd(query) {
    // //////console.log('query', query);
    let result = this.selectStart(query)
    this.selectedEndTime = result;
  }

/* Name:srinivas
       use: set address details. */
  handleAddressChange(address) {
    //////console.log(address);
    //////console.log(address.address_components);
    this.updateServiceAddress(address);
  }
/* Name:srinivas
       use: get current lcation from google */
  getCurrentLocation(lat, lng) {
    this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = { lat: lat, lng: lng };
      let that = this;
      geocoder.geocode({ 'location': latlng }, function (results) {
        //////console.log(results);
        if (results[0]) {
          that.updateServiceAddress(results[0]);
          ////////console.log(that.currentLocation);
        } else {
          //////console.log('No results found');
        }
      });
    });
  }
/* Name:srinivas
       use: set address details to service fileds*/
  updateServiceAddress(address) {

    // this.service.scheduleForm.patientDetail.patientCity = '';
    this.service.scheduleForm.patientDetail.patientCity = "",
      this.service.scheduleForm.patientDetail.patientCountry = "",
      this.service.scheduleForm.patientDetail.patientLatitude = "",
      this.service.scheduleForm.patientDetail.patientLongitude = "",
      this.service.scheduleForm.patientDetail.patientState = "",
      this.service.scheduleForm.patientDetail.zipCode = ""
    // this.service.scheduleForm.patientDetail.patientApartmentNo = "";

    let places: any;
    const componentForm = {
      route: 'long_name',
      locality: 'long_name',
      political: 'long_name',
      administrative_area_level_1: 'long_name',
      postal_code: 'short_name',
      country: 'long_name'
    };

    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (componentForm[addressType]) {
        if (addressType === 'route') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientAddress = val;
        }
        if (addressType === 'street_number') {
          const val = address.address_components[i]['long_name'];
          this.service.scheduleForm.patientDetail.patientApartmentNo = val;
        }
        if (addressType === 'political') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientAddress = val;
        }
        if (addressType === 'postal_code') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.zipCode = val;
        }
        if (addressType === 'locality') {
          const val = address.address_components[i][componentForm[addressType]];
          //////console.log(val);
          this.service.scheduleForm.patientDetail.patientCity = val;
        }
        if (addressType === 'administrative_area_level_1') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientState = val;
        }
        if (addressType === 'country') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.scheduleForm.patientDetail.patientCountry = val;
        }
      }
    }

    this.service.scheduleForm.patientDetail.patientLatitude = address.geometry.location.lat();
    this.service.scheduleForm.patientDetail.patientLongitude = address.geometry.location.lng();
    //////console.log(this.service.scheduleForm);
    // this.showMapmarker(this.service.workerHome.latitude ,this.service.workerHome.longitude);
    this.scheduleForm.controls['address'].setValue(address.formatted_address);
    ////console.log(this.service.scheduleForm);
  }
/* Name:srinivas
       use: return patient details */
  getPatients() { 
    this.optionsPatient = [];
    this.service.common.progressLoader = true;
    this.service.getPaitents(this.service.common.local.get('orgCode'), (data) => {
      if (data.responsecode === 200) { 
        this.patientData = data.data;
        this.patientData.forEach(element => {
          this.optionsPatient.push({ "patientName": element.patientName, "patientCode": element.patientCode });
        });
        //////console.log(this.optionsPatient);
        if (this.patientCode) {
          setTimeout(() => {
            //console.log(this.optionsPatient);
            //console.log(this.patientCode);
            const selectedPatient = this.optionsPatient.filter(data => data.patientCode == this.patientCode);
            //console.log(selectedPatient[0]);
            this.scheduleForm.controls['patientName'].setValue({ "patientName": selectedPatient[0].patientName, "patientCode": selectedPatient[0].patientCode });
            this.filteredOptions = this.scheduleForm.controls['patientName'].valueChanges
              .pipe(
                startWith<string>(''),
                map(name => this._filter(name))
              );
            this.service.common.progressLoader = false;
          }, 1000);
        } else {
          this.service.common.progressLoader = false;
        }
      } else {
        this.service.common.progressLoader = false;
      }
    });
  }

  getCustomerDetails()
  {
    this.service.getCustomerDetails(this.service.common.local.get('orgCode'), (data) => {
      if (data.responsecode === 200) { 
        // this.patientData = data.data;
        // this.optionsPatient = this.patientData;
        
      } else {
        this.service.common.progressLoader = false;
      }
    })
  }

  getCustomerAddressListByCustomerAdminCode(){
    //console.log('UserType: ', this.service.session.get('user_type'));
    if(this.service.session.get('user_type') === 'Customer'){
      if(this.service.session.get('userCode')){
        this.service.getCustomerAddressListByCustomerAdminCode(this.service.session.get('userCode'), (data) => {
          // //console.log('data:22', data);
          if (data.responsecode === 200) { 
            let arrayDataList = [];
            if(data?.data){
            arrayDataList.push(data.data);
            }
            this.patientData = arrayDataList;
            this.optionsPatient = this.patientData;            
          } else {
            this.service.common.progressLoader = false;
            this.patientData = [];
            this.optionsPatient = [];
          }
        });
      }
    } else {
      if(this.service.session.get('orgCode')){
        this.service.getCustomerAddressListByOrganizationCode(this.service.session.get('orgCode'), (data) => {
          // //console.log('data:22', data);
          if (data.responsecode === 200) { 
            let arrayDataList = [];
            if(data?.data){
              arrayDataList = data?.data;
            // arrayDataList.push(data.data);
            }
            this.patientData = arrayDataList;
            this.optionsPatient = this.patientData;
          } else {
            this.service.common.progressLoader = false;
            this.patientData = [];
            this.optionsPatient = [];
          }
        });
      }    
  }
}

/* Name:srinivas
       use: set service empty */
  getServiceEmpty() {
    this.service.scheduleForm = {
      "daysList": [
        0
      ],
      "customerAddressCode": "",
  "customerCode": "",
      "durationHours": 0,
      "durationMinute": 0,
      "endDate": "",
      "isDateOfMonth": true,
      "jobName": "",
      "notes": "",
      "organizationCode": "",
      "patientCode": "",
      "patientDetail": {
        "isActive": true,
        "mobileCountryCode": "",
        "mobileNumber": "",
        "organizationCode": "",
        "patientAddress": "",
        "patientCity": "",
        "patientCode": "",
        "patientCountry": "",
        "patientEmail": "",
        "patientLatitude": "",
        "patientLongitude": "",
        "patientName": "",
        "patientState": "",
        "phoneCountryCode": "",
        "phoneNumber": "",
        "zipCode": "",
        "patientApartmentNo": "",
        "preferredCountryCode": "",
        "preferredNumber": "",
        'preferredPhoneType': ''
      },
      "organizationBaseProcedureCodeList": [],
      "repeatType": "",
      "repeatValue": 0,
      "startDate": "",
      "startTime": "",
      "additionalCountryCode": "",
      "additionalNumber": "",
      'preferredPhoneType': '',
      "mobileCountryCode": "",
      "mobileNumber": "",
      "homeCountryCode": "",
      "homeNumber": "",
      "isDateFlexible": false
    }
  }

 /* Name:srinivas
       use: call assign and save */

  onAssign(){
    this.saveAndassign=true;
    this.onSubmit();
  }

/* Name:srinivas
       use:save appointment details */
  onSubmit() {
    // //console.log('onSubmit', this.scheduleForm);
    // //////console.log(this.patientDataByCode);
    // //////console.log(this.service.scheduleForm);
    if (this.scheduleForm.valid) {
      this.service.common.progressLoader = true;
      this.submitted = true;
      this.scheduleForm.value.startDate = this.datepipe.transform(this.scheduleForm.value.startDate, 'yyyy-MM-dd');
      // this.scheduleForm.value.endDate = this.datepipe.transform(this.scheduleForm.value.endDate, 'yyyy-MM-dd');
      // this.service.scheduleForm.endDate = this.scheduleForm.value.endDate;
      // } else {
      if (this.service.scheduleForm.endDate == '') {
        this.service.scheduleForm.endDate = this.scheduleForm.value.startDate;
        this.service.scheduleForm.daysList = [];
        this.service.scheduleForm.repeatType = 'day';
        this.service.scheduleForm.repeatValue = 1;
        this.service.scheduleForm.isDateOfMonth = false;
      }
      this.service.scheduleForm.startDate = this.scheduleForm.value.startDate;

      this.service.scheduleForm.organizationCode = this.service.common.local.get('orgCode');

      if (this.patientDataByCode.length > 0 || this.patientDataByCode['patientName']) {
        //////console.log(this.patientDataByCode['patientName']);
        this.service.scheduleForm.patientDetail.patientName = this.patientDataByCode['patientName'];
      } else {
        //////console.log(this.scheduleForm.value.patientName);
        this.service.scheduleForm.patientDetail.patientName = this.scheduleForm.value.patientName;
      }
      this.service.scheduleForm.patientDetail.organizationCode = this.service.common.local.get('orgCode');
      this.service.scheduleForm.patientDetail.patientLongitude = this.service.scheduleForm.patientDetail.patientLongitude.toString();
      this.service.scheduleForm.patientDetail.patientLatitude = this.service.scheduleForm.patientDetail.patientLatitude.toString();
      this.service.scheduleForm.durationHours = +this.scheduleForm.value.duration.split(':')[0];
      this.service.scheduleForm.durationMinute = +this.scheduleForm.value.duration.split(':')[1];
      //console.log(this.selectedItems);

      this.selectedItems.forEach(element => {
        //console.log(this.service.scheduleForm.organizationBaseProcedureCodeList.length);
        if(this.service.scheduleForm.organizationBaseProcedureCodeList.length>0){

         for (let index in this.service.scheduleForm.organizationBaseProcedureCodeList) {
           //console.log(this.service.scheduleForm.organizationBaseProcedureCodeList[index]);
            if(this.service.scheduleForm.organizationBaseProcedureCodeList[index] != element.organizationBaseProcedureCode){
              this.service.scheduleForm.organizationBaseProcedureCodeList.push(element.organizationBaseProcedureCode);
            }
          }
        }else{
          this.service.scheduleForm.organizationBaseProcedureCodeList.push(element.organizationBaseProcedureCode);
        }
      });
      //console.log(this.service.scheduleForm);
      try {
            
      this.service.createWorkerJob(this.service.scheduleForm, (res) => {
        //console.log(res);
        if (res.responsecode == 200) {
          if (res.manualAssignment == false) {
            let workers = [];
            workers = res.data.eligibleWorkerResList;
            setTimeout(() => {
              //console.log(workers);
              this.service.common.progressLoader = false;
              if (workers.length > 0) {
                //////console.log(workers);
                this.openDialog(workers);
              } else {
                this.service.common.message = 'Job created successfully but not worker was associated with the job.';
                this.service.common.errorMessage = true;
                //this.router.navigate(['/dashboard/appointment']);
                this.dialogRef.close('ok');
              }
            }, 200);
          } else {
            this.service.common.progressLoader = false;
            if(this.saveAndassign===true){

              const data={
                "saveAndassign":"saveAndassign",
                "appointmentsDurationCode":res.data.appointmentsDurationResList[0].appointmentsDurationCode
              }
              this.dialogRef.close(data);
             // this.assignAppointment(res.data.appointmentsDurationResList[0].appointmentsDurationCode);
            }else{
              this.dialogRef.close('ok');
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
          }
            //this.router.navigate(['/dashboard/appointment']);
          }
        } else {
          this.service.common.progressLoader = false;
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })
       } catch (error) {
        
      }
    } else {
      this.submitted = false;
    }
  }
/* Name:srinivas
       use: open assign worker popup */
  openDialog(workers): void {
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      width: '500px',
      disableClose: true,
      data: { data: workers }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
    });
  }
/* Name:srinivas
       use:open repeat appointment popup */
  repeat() {
    const dialogRef = this.dialog.open(RepeatAppointmentComponent, {
      width: '500px',
      disableClose: true,
      data: { startDate: this.scheduleForm.controls['startDate'].value }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        if (result.days == null) {
          this.service.scheduleForm.daysList.push(0);
        } else {
          this.service.scheduleForm.daysList = result.days;
        }
        this.service.scheduleForm.repeatType = result.repeatevery;
        this.service.scheduleForm.repeatValue = result.repeatnum;
        this.scheduleForm.controls['startDate'].setValue(moment(result.startDate));
        this.service.scheduleForm.startDate = this.datepipe.transform(result.startDate, 'yyyy-MM-dd');
        this.service.scheduleForm.endDate = this.datepipe.transform(result.endDate, 'yyyy-MM-dd');
        this.service.scheduleForm.isDateOfMonth = result.isDateOfMonth;
      }
    });
  }
/* Name:srinivas
       use: open add patient popup */
  addPatient(): void {
    const dialogRef = this.dialog.open(PatientDataComponent, {
      disableClose: true,
      width: '550px'

    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      this.getPatients();
      //////console.log(result);
      if (result) {
        const data = {
          patientCode: result.data.patientCode,
          patientName: result.data.patientName
        };
        this.scheduleForm.controls['patientName'].setValue(data);
      }
    });
  }
/* Name:srinivas
       use: set country code to control. */
  getCountryCode(event, formControlName) {
    //////console.log(event, formControlName);
    //////console.log(event);
    this.scheduleForm.controls[formControlName].setValue(event);
    this.service.scheduleForm.patientDetail[formControlName] = event;
  }

  onItemSelect(item: any) {
    // ////console.log(item);
    // if (item.duration) {
    this.getDuration();
    // }

  }
/* Name:srinivas
       use: set duration time and notes to controls. */
  getDuration() {
    this.allDurations = [];
    //console.log(this.selectedItems);
    if (this.selectedItems.length > 0) {
      this.selectedItems.forEach(element => {
        this.allDurations.push(element.duration);
        const totalDurations = this.allDurations.slice(1)
          .reduce((prev, cur) => moment.duration(cur).add(prev),
            moment.duration(this.allDurations[0]));
        let duration = moment.utc(totalDurations.asMilliseconds()).format("HH:mm:ss");
        this.scheduleForm.controls['duration'].setValue(duration);
        this.scheduleForm.controls['notes'].setErrors(null);
        this.selectedDuration = duration;
        if(!this.service.scheduleForm.isDateFlexible) {
          this.service.scheduleForm.isDateFlexible = element.isDateFlexible;
        }
      });
    } else {
      this.scheduleForm.controls['duration'].setValue(null);
      this.selectedDuration = '';
      this.service.scheduleForm.isDateFlexible = false;
    }
  }
/* Name:srinivas
       use: set notes data */
  getDurationData(event) {
    //////console.log(event);
    if (event.value !== this.selectedDuration) {
      this.scheduleForm.controls['notes'].setErrors({ 'incorrect': true });
    } else {
      this.scheduleForm.controls['notes'].setErrors(null);
    }

  }
/* Name:srinivas
       use: reset page */
  canelPopup() {
    if(this.cancelSchedule){
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
      if (result != undefined) {
        //this.getFormReset();
        this.onNoClick();
      }
      // else{
      //   this.onNoClick();
      // }
    });
    } else {
      this.onNoClick();
    }
  }
/* Name:srinivas
       use: close popup */
  onNoClick(): void {
    this.dialogRef.close();
  }
}
