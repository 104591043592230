import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-reason',
  templateUrl: './delete-reason.component.html',
  styleUrls: ['./delete-reason.component.scss']
})
export class DeleteReasonComponent implements OnInit {

  reasonForm: FormGroup;
  submitted = false;
  constructor(public dialogRef: MatDialogRef<DeleteReasonComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.reasonForm = new FormGroup({
      reason: new FormControl(null, Validators.required)
    })
  }

  onSubmit(){
    if(this.reasonForm.valid) {
      this.submitted = false;
      this.dialogRef.close(this.reasonForm.value.reason);
    } else {
      this.submitted = true;
    }
  }

  onClose(){
    this.dialogRef.close();
  }
}
