<div class="">
  <div class="profileform-edit">
    <mat-tab-group dynamicHeight class="tab-button-auto-width" [selectedIndex]="isActiveIndexAdmin" (selectedTabChange)="tabChange($event)">
      <mat-tab id="tabone" [disabled]="isDisable">
        <ng-template mat-tab-label>
          <!-- <i class="material-icons pe-2"> supervisor_account </i> -->
          <img src="assets/owner-icon.png" alt="owner">&nbsp;&nbsp;
          Owner
        </ng-template>

        <div class="user-profile">


          <div class="row justify-content-between w-100 logo
            align-items-center m-0 mt-2">
            <div class="p-2 profile-box" *ngIf="toggelLogo == 0">
              <img alt="No Image" class="img-proc" *ngIf="imageupload"
                [src]="fileUrl" #imageUploaded
                class="profile-image avatar huge" />
              <img alt="No Image" class="img-proc" *ngIf="!imageupload"
                src="assets/common_images/profile.jpg"
                class="profile-image avatar huge" />
              <input class="profile-img" #file type="file"
                enctype="multipart/form-data"
                (change)="onFileChange($event, 'owner')" #fileInput>
              <i class="material-icons logo-icon pointer"
                (click)="file.click()">
                edit
              </i>
            </div>
            <!-- <div class="p-2 profile-box" *ngIf="toggelLogo == 1">
              <img alt="No Image" class="img-proc" *ngIf="imageuploadAdmin" [src]="fileUrlAdmin" #imageUploaded
                class="profile-image avatar huge" />
              <img alt="No Image" class="img-proc" *ngIf="!imageuploadAdmin" src="assets/common_images/profile.jpg"
                class="profile-image avatar huge" />
              <input class="profile-img" #fileAdmin type="file" enctype="multipart/form-data"
                (change)="onFileChange($event, 'admin')" #fileInput>
              <i class="material-icons logo-icon pointer" (click)="fileAdmin.click()">
                edit
              </i>
            </div> -->
            <div class="col-md-9 text-end p-2" *ngIf="toggelLogo == 0">
              <div class="text-end d-flex justify-content-end">
                <button class="mb-2" mat-raised-button color="primary" (click)="onSubmit()">Save Changes</button>
                <button mat-button class="ms-2 mb-2" routerLink="/dashboard">Cancel</button>
              </div>
              <!-- <div class="">
                <button mat-flat-button class="primary-btn"
                  (click)="onSubmit()">Save Changes</button>
                <div class="pe-4">
                  <button class="cancelbtn" mat-button routerLink="/dashboard">Cancel</button>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <form [formGroup]="profileForm" *ngIf="service.profileData.ownerDetails"
          (ngSubmit)="onSubmit()"
          autocomplete="off">
          <div class="row ln-bt pb-2 mt-4">
            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'registration.provider.labels.Organization' | translate }}</mat-label>
                  <input matInput class="form-control"
                    formControlName="organizationName" minlength="2"
                    maxlength="50"
                    [(ngModel)]="service.profileData.ownerDetails.organizationName">
                </mat-form-field>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.organizationName
                  .errors?.required && (!submitted ||
                  profileForm.controls.organizationName.touched)">{{
                  'registration.provider.validators.Organization Name is required' | translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.organizationName
                  .errors?.pattern && (!submitted ||
                  profileForm.controls.organizationName.touched)">{{
                  'registration.provider.validators.Organization Name can only have alphabets, numbers and special characters such as _ & -' | translate }}</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.organizationName.errors?.invalid
                  && (!submitted ||
                  profileForm.controls.organizationName.touched)">
                  {{ 'registration.provider.validators.Organization Name is already present' | translate }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'registration.provider.labels.Owner Name' | translate }}</mat-label>
                  <input matInput class="form-control"
                    formControlName="ownerName" minlength="2" maxlength="50"
                    [(ngModel)]="service.profileData.ownerDetails.name">
                </mat-form-field>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.ownerName
                  .errors?.required && (!submitted ||
                  profileForm.controls.ownerName.touched)">{{
                  'registration.provider.validators.Owner Name is required'
                  | translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.ownerName
                  .errors?.pattern && (!submitted ||
                  profileForm.controls.ownerName.touched)">{{
                  'registration.provider.validators.Owner Name should only have alphabets and special character such as' | translate
                  }} '</span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'registration.provider.labels.Email' | translate }}</mat-label>
                  <input matInput class="form-control" #email
                    formControlName="email" maxlength="50"
                    [(ngModel)]="service.profileData.ownerDetails.email">
                </mat-form-field>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.email
                  .errors?.required && (!submitted ||
                  profileForm.controls.email.touched)">{{
                  'registration.provider.validators.Email is required' | translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.email.errors?.email
                  && (!submitted || profileForm.controls.email.touched)">{{
                  'registration.provider.validators.Please enter valid email id' | translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.email.errors?.invalid
                  && (!submitted || profileForm.controls.email.touched)">
                  {{ 'registration.provider.validators.Email id is already present' | translate }}.</span>
              </div>
            </div>

          </div>

          <div class="row">            

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'registration.provider.labels.User Name' | translate }}</mat-label>
                  <input matInput class="form-control"
                    formControlName="ownerUserName" minlength="2" maxlength="50"
                    [(ngModel)]="service.profileData.ownerDetails.userName">
                </mat-form-field>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.ownerUserName
                  .errors?.required && (!submitted ||
                  profileForm.controls.ownerUserName.touched)">{{
                  'registration.provider.validators.User Name is required'
                  | translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.ownerUserName
                  .errors?.pattern && (!submitted ||
                  profileForm.controls.ownerUserName.touched)">{{
                  'registration.provider.validators.User Name should only have alphabets and special character such as' | translate
                  }} </span>
                <span class="help-block"
                  *ngIf="profileForm &&
                  profileForm.get('ownerUserName').errors?.invalid &&
                  (!submitted || profileForm.get('ownerUserName').touched)">
                  {{ 'registration.technician.validators.User Name is already present' | translate }}</span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'Technicians.labels.Password' | translate }}</mat-label>
                  <input matInput type="password" maxlength="50"
                    class="form-control" formControlName="password"
                    #password
                    [(ngModel)]="service.profileData.ownerDetails.password">
                </mat-form-field>
                <span class="help-block err" *ngIf="profileForm &&
                  profileForm.get('password').errors?.required &&
                  (!submitted || profileForm.get('password').touched)">
                  {{ 'registration.provider.validators.Password is required' | translate }}.</span>
                <span class="help-block err" *ngIf="profileForm &&
                  profileForm.get('password').errors?.pattern &&
                  (!submitted || profileForm.get('password').touched)">
                  {{ 'registration.provider.validators.Password must have Uppercase &lowercase letter, Symbol, one no. and must have more than 6 char' | translate }}.</span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'Technicians.labels.Confirm Password' | translate }}</mat-label>
                  <input matInput type="password" maxlength="50"
                    class="form-control" formControlName="cpassword"
                    #password
                    [(ngModel)]="service.profileData.ownerDetails.confirmedPassword">
                </mat-form-field>
                <span class="help-block err" *ngIf="profileForm &&
                  profileForm.controls.cpassword.errors?.invalid && (
                  !submitted || profileForm.get('cpassword').touched)">
                  {{ 'registration.provider.validators.Password does not match' | translate }}.</span>
                <span class="help-block err" *ngIf="profileForm &&
                  profileForm.get('cpassword').errors?.pattern &&
                  (!submitted || profileForm.get('cpassword').touched)">
                  {{ 'registration.provider.validators.Password does not match' | translate }}.</span>
                <span class="help-block err" *ngIf="profileForm &&
                  profileForm.controls.cpassword.errors?.maxlength &&
                  (!submitted || profileForm.controls.cpassword.touched)">
                  {{ 'registration.provider.validators.Password does not match' | translate }}.</span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'Technicians.labels.Primary Contact' | translate }}</mat-label>
                  <input matInput appPhoneMask   maxlength="12"
                    class="form-control" formControlName="primaryContact"
                    [(ngModel)]="service.profileData.ownerDetails.primaryContact">
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'Technicians.labels.Off Hours Contact' | translate }}</mat-label>
                  <input matInput appPhoneMask   maxlength="12"
                    class="form-control" formControlName="offHoursContact"
                     [(ngModel)]="service.profileData.ownerDetails.offHoursContact">
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-4">
              <div class="w-100">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{'registration.provider.labels.Organization Address' | translate }}</mat-label>
                  <textarea matInput ngx-google-places-autocomplete
                    [options]='options' #placesRef="ngx-places"
                    formControlName="address"
                    (keypress)="handleAddressChange($event)"
                    (onAddressChange)="handleAddressChange($event)"></textarea>
                </mat-form-field>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.address.errors?.required &&
                  (!submitted || profileForm.controls.address.touched)">{{
                  'registration.provider.validators.Address is required' |
                  translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.address.errors?.invalid
                  && (!submitted || profileForm.controls.address.touched)">{{
                  'registration.provider.validators.Please enter valid address' | translate }}.</span>
                <span class="help-block" *ngIf="profileForm &&
                  profileForm.controls.address.errors?.minlength &&
                  (!submitted || profileForm.controls.address.touched)">{{
                  'registration.provider.validators.Minimum length should be greater than 20' | translate }}.</span>
              </div>
            </div>

            <!-- <div class="col-md-4 d-flex align-items-center">
              <section class="example-section margin_adj">
                <mat-checkbox class="example-margin"
                  (change)="ownerAcivecheck($event)"
                  [checked]="service.profileData.ownerDetails.isActive">{{
                  'registration.provider.labels.Is Active' | translate }}</mat-checkbox>
              </section>
            </div> -->

          </div>
        </form>

        <!-- <hr /> -->
        <!-- <div class="w-100 p-0 col-md-122 col-sm-12">
          <form [formGroup]="allService">
            <h4 class="primary-color mb-3">{{
              'registration.provider.labels.Service Type' | translate }}</h4>
            <div class="w-100">
              <mat-chip-list>
                <mat-chip class="text-capitalize" *ngFor="let org of
                  selectedService;let i= index">{{org.serviceName}}
                  &nbsp;<a (click)="removeOrg(org.serviceTypeOrganizationCode)">
                    <mat-icon class="cursor-pointer">close</mat-icon>
                  </a></mat-chip>
              </mat-chip-list>
              <mat-form-field appearance="outline" floatLabel="always"  class="col-md-4 p-0 example-full-width
                custom-search mt-2">
                <mat-label>{{'registration.provider.labels.Service List' | translate }}</mat-label>
                <input type="text" autoClose matInput [matAutocomplete]="auto"
                  placeholder="" formControlName="service">
                <i class="material-icons float-end"> search </i>
                <mat-autocomplete #auto="matAutocomplete"
                  [displayWith]="displayFn"
                  (optionSelected)="selectedOption($event)">
                  <mat-option *ngFor="let option of filteredOptionservice |
                    async" [value]="option">
                    {{option.serviceName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </form>
        </div> -->

        <!-- <hr /> -->

      </mat-tab>
      <!-- <mat-tab [disabled]="isDisable">
        <ng-template mat-tab-label>
          <img src="assets/admin-icon.png" alt="admin">
          {{'registration.provider.labels.Admin' | translate }}
        </ng-template>
        <div class="mt-3">

          <div class="d-flex flex-row justify-content-end col-md-4 mb-2 logo
            align-items-center float-end p-0">
            <div class="col-md-9 searchfilter"
              [ngClass]="{'pe-0':service.profileData.role == 'admin'}">
              <input autocomplete="off" matInput
                (keyup)="applyFilter($event.target.value)" type="text"
                class="form-control ps-2"
                placeholder="{{'registration.provider.labels.Search Admin' |
                translate }}">
              <mat-icon class="cursor-pointer">search</mat-icon>
            </div>
            <button *ngIf="service.profileData.role == 'owner'" class="addAdminBtnSpace" mat-raised-button color="primary" (click)="addAdmin()">{{'registration.provider.labels.Add Admin' | translate }}</button>
          </div>
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-md-12 table-scroll-height">
              <table mat-table [dataSource]="dataSourceAdmin" matSort
                class="mat-elevation-z8">
                <ng-container matColumnDef="adminImage">
                  <th width="120" mat-header-cell *matHeaderCellDef>Image</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="skillImage" *ngIf="element.adminImage != ''">
                      <img [src]="element.imageUrl" alt="">
                    </div>
                    <div *ngIf="element.adminImage == ''">
                      <div class="skillImage default">
                        <img src="assets/common_images/profile.jpg" title="No
                          image" alt="">
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'registration.provider.labels.Name' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <div>{{element.name}} </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'registration.provider.labels.Email' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <div>{{element.email}} </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="userName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'registration.provider.labels.Username' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <div>{{element.userName}} </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="isActive">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'registration.provider.labels.Is Active' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <p *ngIf="element.isActive">Yes</p>
                      <p *ngIf="!element.isActive">No</p>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="action">{{'skills.labels.Actions'
                    | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="action">
                    <mat-icon *ngIf="element.isEdit" class="color-icon
                      cursor-pointer" (click)="onEdit(element)">edit
                    </mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsAdmin; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns:
                  displayedColumnsAdmin;"></tr>
              </table>
              <div *ngIf="allAdminData.length == 0 ||
                dataSourceAdmin.filteredData.length == 0" class="">
                {{'registration.provider.validators.No admins are available' |
                translate }} </div>
            </div>
          </div>
        </div>
      </mat-tab> -->
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/admin-icon.png" alt="admin">&nbsp;&nbsp;
          {{'registration.provider.labels.Business Document List' | translate }}
        </ng-template>
        <!-- <h5 class="title-head mt-4">Documents</h5> -->
        <!-- <form [formGroup]="productForm" >
                     
          <table class="table table-array" formArrayName="arrayDocs">
            <thead>
              <th width="15%"></th>
              <th width="15%"></th>
              <th width="35%"></th>
              <th width="25%"></th>
              <th width="10%"></th>
            </thead>
            <tr *ngFor="let quantity of arrayDocs().controls; let i=index" [formGroupName]="i">
              <td>
                <div class="w-100">                   
                    <label class="center-block primary-color">{{
                      'Technicians.labels.Document Name' | translate }} 
                     </label> 
                    <p> {{service.allMasterdocs[i].documentName}}</p>
                    <p> {{service.allMasterdocs[i].note}}</p>

                    <input type="hidden" formControlName="documentName" [value]="service.allMasterdocs[i].documentName" />
 
                </div>
                
              </td>
              <td>
                <div class="w-100 datepick">
                  <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
                    <mat-label>{{ 'Technicians.labels.Expiry Date' | translate }}</mat-label>
                    <input [min]="today" [(ngModel)]="service.allMasterdocs[i].expiryDate" class="form-control" matInput
                      formControlName="expiryDate" [matDatepicker]="picker2" (focus)="picker2.open()" placeholder="">
                    <mat-datepicker-toggle matSuffix [for]="picker2" class="date-icon"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="w-100">
                  <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                    <mat-label>{{'report.labels.Notes' | translate }}</mat-label>
                    <input matInput type="text" maxlength="500" class="form-control" formControlName="notes"
                      [(ngModel)]="service.allMasterdocs[i].note" >  
                      </mat-form-field>             
                  </div>
              </td>
              <td>
                <div class="w-100 " >
                  <div class="certificate-name">
                    {{service.uploadData.uploadPath}}
                  </div>
                  <a class="btn-cert" mat-raised-button color="gray">{{'Technicians.labels.Browse' | translate }} <input
                      formControlName="fileName" class="certificate-img" type="file" enctype="multipart/form-data"
                      (change)="onCertificateUpload($event,i)" #fileInput></a>
                      <div class="upload-loader pe-2" *ngIf="showLoader">
                        <mat-spinner diameter="40" class="mt-2"></mat-spinner>
                      </div>
                </div></td>
              <td>
              <div class="w-100 m-0">
                <button mat-flat-button class="primary-btn mb-1"  [disabled]="!uploadButton" (click)="uploadDoc(i)">{{'Technicians.labels.Upload' | translate }}
                </button>
                            
            </div></td>
            </tr>
          </table>
                   
        </form> -->

        <!-- <form [formGroup]="productForm">

          <div *ngFor="let element of service.allMasterdocs; let i=index;">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>Document Name</mat-label>
              <input matInput formControlName="{{'documentName'+i}}">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>Expiry Date</mat-label>
              <input matInput formControlName="{{'expiryDate'+i}}">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>Note</mat-label>
              <input matInput formControlName="{{'note'+i}}">
            </mat-form-field>

          </div>

        </form>
        <hr /> -->

        <div class="row">
          <div class="col-md-6 mt-4 mb-3">
            <h5 class="title-head">Documents</h5>
          </div>
          <div class="justify-content-end col-md-6 mt-3 mb-3 text-end">            
            <button class="ms-2" mat-raised-button color="primary" (click)="addDocument()">{{'registration.provider.labels.Add Document' | translate }} </button></div>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8
          patient-table document-table">
          <ng-container matColumnDef="documentName">
            <th width="400" mat-header-cell *matHeaderCellDef>{{'Technicians.labels.Document Name' |
              translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div *ngIf="!element.documentName">NA </div>
                <div *ngIf="element.documentName">{{element.documentName}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="expiryDate">
            <th mat-header-cell *matHeaderCellDef>{{'Technicians.labels.Expiry
              Date' |
              translate }} </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div *ngIf="!element.expiryDate">NA </div>
                <div>{{element.expiryDate}} </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>Note </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div class="text-truncate" style="max-width: 250px;">{{element.note}} </div>
              </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="actions">
            <th width="200" mat-header-cell *matHeaderCellDef>{{'skills.labels.Actions'
              |
              translate }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon title="Edit" class="color-icon me-2"
                (click)="addDocument(element)"
                class="cursor-pointer color-icon me-2">create</mat-icon>
              <mat-icon title="Delete"
                (click)="deleteUpload(element.organizationDocumentCode)"
                class="cursor-pointer color-icon me-2">delete</mat-icon>


              <a [href]="this.service.common.superAdminUrl + element.uploadPath"
                download>
                <mat-icon title="Download" class="cursor-pointer color-icon
                  me-2">
                  save_alt</mat-icon>
              </a>

              <mat-icon title="Notes {{element.unreadCommentCount}}"
                class="color-icon me-2"
                (click)="viewDocNotes(element.organizationDocumentCode)"
                class="cursor-pointer color-icon me-2">
                description <span class="note-dot"
                  *ngIf="element.unreadCommentCount> 0"></span></mat-icon>


            </td>

          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
            true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="count" #paginator *ngIf="allDocuments.length>
          0" [pageIndex]="0" [pageSize]="limit"
          [pageSizeOptions]="[10, 25, 100]" (page)="getNext($event)">
        </mat-paginator>
        <div *ngIf="allDocuments.length == 0"class="">{{'Technicians.messages.Documents
          are not available' | translate
          }}</div>

      </mat-tab>
    </mat-tab-group>
  </div>

</div>