import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomAutoAppointmentService {

  totalRecords = 0;
  processedRecords = 0;

  constructor(
    public http: HttpClient,
    public service: ApiRootService,
    public common: CommonService,
    public session: SessionStorageService,
  ) { }

  /* Name:Shailesh
      use: for getting demo by org code */
  getDemoByOrganizationCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getDemoByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for getting unassiged current date appointment by org code */
  unassignedCurrenetDateAppointmentsByOrgCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/unassignedCurrenetDateAppointmentsByOrgCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for getting random assigment of appointment for demo org code */
  adminRandomAssignForDemo(code,callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/adminRandomAssignForDemo/'+code).subscribe((data)=>{
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for auto assignment of appointment by org code */
  adminAutoAssignForDemo(code,callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/adminAutoAssignForDemo/'+code).subscribe((data)=>{
      callback(data);
    })
  }

  /* Name:Shailesh
      use: for getting appointment thread processing by org code */
  getAppointmentThreadProcessingByCode(code,callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/getAppointmentThreadProcessingByCodeForSimulation/'+code).subscribe((data)=>{
      //callback(data);
      this.getresponseData(data,callback,code);
    })
  }

  /* Name:Shailesh
      use: for getting appointment thread processing by org code */
  getresponseData(response,callback,code){
    if(response.responsecode == 200){
       //console.log(response);
      this.totalRecords = response.data.totalRecord;
      this.processedRecords = response.data.processRecord;
      this.common.progressText = this.processedRecords + ' out of ' + this.totalRecords + ' appointments';
      //console.log(this.common.progressText);
      if(response.data.totalRecord == response.data.processRecord){
        callback(response);
      } else {
        this.getAppointmentThreadProcessingByCode(code,callback);
      }
    }
  }
}
