import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssignAppointmentComponent } from '../assign-appointment/assign-appointment.component';
import { TradeShiftComponent } from '../trade-shift/trade-shift.component';
import { CommonService } from 'src/app/shared/common.service';
import { AppointmentService } from '../appointment.service';
import { CancelAppointmentComponent } from '../cancel-appointment/cancel-appointment.component';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';
import { ProvidersDashboardService } from '../../providers-dashboard.service';
import * as moment from 'moment';
import { ShowRoutesComponent } from '../show-routes/show-routes.component';
import { NewAppointmentService } from 'src/app/dashboards/technician-dashboard/new-appointment/new-appointment.service';
import { RejectAppointmentComponent } from 'src/app/dashboards/technician-dashboard/new-appointment/reject-appointment/reject-appointment.component';
import { TranslateService } from '@ngx-translate/core';
import { ProcedureDetailComponent } from '../procedure-detail/procedure-detail.component';
import { NotesPopupComponent } from '../notes-popup/notes-popup.component';
import { ArrivalDepartureComponent } from '../../arrival-departure/arrival-departure.component';
import { UpdateAppointmentSeriesComponent } from '../update-appointment-series/update-appointment-series.component';
import { UpdateAppointmentComponent } from '../update-appointment/update-appointment.component';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-appointment-popup',
  templateUrl: './appointment-popup.component.html',
  styleUrls: ['./appointment-popup.component.scss']
})
export class AppointmentPopupComponent implements OnInit {
  userType: any;
  showPopup = '';
  distance;
  appointmentchange;
  showImg: any;
  yearMonthDateFormat = 'YYYY-MM-DD';

  constructor(public dialogRef: MatDialogRef<AppointmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public dialog: MatDialog, public common: CommonService, public service: AppointmentService,
    public createPatientService: CreateAppointmentService,
    public dashboardService: ProvidersDashboardService,
    public newAppointmentService: NewAppointmentService,
    public translate: TranslateService,
    public session: SessionStorageService,
  ) { this.translate.setDefaultLang(this.service.session.get('language')); }

  ngOnInit() {
    this.userType = this.session.get('user_type');
    ////console.log(this.data);

    const newdate = this.data.appointment.appointmentStartDate.split(' ')[1] + ' ' + this.data.appointment.appointmentStartDate.split(' ')[2];
    const date = moment(newdate, 'hh:mm a').format(this.data.appointment.timeFormat);
    ////console.log(date);
    this.data.appointment.newdate = date;
    if (this.data.appointment.distance != null) {
      this.distance = (this.data.appointment.distance).toFixed(2);
    } else {
      this.distance = 'NA';
    }

    // if (this.data.appointment.actualStartTime) {
    //   this.data.appointment.arrivalTimeDate = moment(new Date(this.data.appointment.actualStartTime), this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
    //   ////console.log(this.data.appointment.arrivalTimeDate);
    // }
    // if (this.data.appointment.actualEndTime) {
    //   this.data.appointment.departureTimeDate = moment(new Date(this.data.appointment.actualEndTime), this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
    //   ////console.log(this.data.appointment.departureTimeDate);
    // }

    if (this.data.appointment.actualStartTime) {
      if(this.data.appointment.dateFormat == 'dd-MM-yyyy'){
        this.data.appointment.arrivalTimeDate = moment(this.data.appointment.actualStartTime.split('-')[1]+'-'+this.data.appointment.actualStartTime.split('-')[0]+'-'+this.data.appointment.actualStartTime.split('-')[2], 'MM-DD-YYYY '+this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
        ////console.log(this.data.appointment.arrivalTimeDate);
      } else if(this.data.appointment.dateFormat == 'MM-dd-yyyy'){
        this.data.appointment.arrivalTimeDate = moment(this.data.appointment.actualStartTime, 'MM-DD-YYYY '+this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
        ////console.log(this.data.appointment.arrivalTimeDate);
      } else {
        this.data.appointment.arrivalTimeDate = moment(this.data.appointment.actualStartTime.split('-')[1]+'-'+this.data.appointment.actualStartTime.split('-')[2]+'-'+this.data.appointment.actualStartTime.split('-')[0], 'MM-DD-YYYY '+this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
        ////console.log(this.data.appointment.arrivalTimeDate);
      }
    }
    if (this.data.appointment.actualEndTime) {
      if(this.data.appointment.dateFormat == 'dd-MM-yyyy'){
        this.data.appointment.departureTimeDate = moment(this.data.appointment.actualEndTime.split('-')[1]+'-'+this.data.appointment.actualEndTime.split('-')[0]+'-'+this.data.appointment.actualEndTime.split('-')[2], 'MM-DD-YYYY '+this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
        ////console.log(this.data.appointment.departureTimeDate);
      } else if(this.data.appointment.dateFormat == 'MM-dd-yyyy'){
        this.data.appointment.departureTimeDate = moment(this.data.appointment.actualEndTime, 'MM-DD-YYYY '+this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
        ////console.log(this.data.appointment.departureTimeDate);
      } else {
        this.data.appointment.departureTimeDate = moment(this.data.appointment.actualEndTime.split('-')[1]+'-'+this.data.appointment.actualEndTime.split('-')[2]+'-'+this.data.appointment.actualEndTime.split('-')[0], 'MM-DD-YYYY '+this.data.appointment.timeFormat).format(this.data.appointment.timeFormat);
        ////console.log(this.data.appointment.departureTimeDate);
      }
    }
  }
/* Name:srinivas
       use: on close popup */
  onNoClick(): void {
    this.dialogRef.close(this.appointmentchange);
  }
/* Name:srinivas
       use: show or hide popup */
  geteditData(element) {
    ////console.log(element);
    if (this.showPopup == element.appointment.appointmentsDurationCode) {
      this.showPopup = '';
    } else {
      this.showPopup = element.appointment.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }

  /* Name:srinivas
       use: open update appointment popup */
  route(routeTO, appointmentsDurationCode, appointmentsCode) {
    if (routeTO == 'update-appointment') {
      //this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: appointmentsDurationCode } });
      const dialogRef = this.dialog.open(UpdateAppointmentComponent, {
        width: '70%',
        disableClose: true,
        data: { jobDurationCode: appointmentsDurationCode}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(result != undefined){
          this.appointmentchange = true;
          this.dialogRef.close(this.appointmentchange);
        }
      });
    } else if (routeTO == 'update-appointment-series') {
      //this.router.navigate(['/dashboard/update-appointment-series'], { queryParams: { jobCode: appointmentsCode } });
      const dialogRef = this.dialog.open(UpdateAppointmentSeriesComponent, {
        width: '70%',
        disableClose: true,
        data: { jobCode: appointmentsCode}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(result != undefined){
          this.appointmentchange = true;
          this.dialogRef.close(this.appointmentchange);
        }
      });
    }
  }
/* Name:srinivas
       use: return job details */
  getFullJobDetail() {
    try {
        
    this.service.getFullJobDetailsByJobDurationCode(this.data.appointment.appointmentsDurationCode, (data) => {
      ////console.log(data);
      if (data.responsecode == 200) {
        this.showPopup = '';
        const newdate = data.data.appointmentStartDate.split(' ')[1] + ' ' + data.data.appointmentStartDate.split(' ')[2];
        const date = moment(newdate, 'hh:mm a').format(data.data.timeFormat);
        ////console.log(date);
        this.data.appointment = data.data;
        this.data.appointment.newdate = date;
      } else {
        this.showPopup = '';
        this.data.appointment = '';
      }
    }) 
  } catch (error) {
      
    }
  }
/* Name:srinivas
       use:return eligible woker details */
  assignAppointment(jobDurationCode) {

    this.service.common.progressLoader = true;
    let selecteddate;
    let startdate = this.data.appointment.appointmentStartDate.split(' ')[0];
    if (this.data.dateFormat == 'MM-dd-yyyy') {
      selecteddate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else if (this.data.dateFormat == 'dd-MM-yyyy') {
      selecteddate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else {
      selecteddate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    }
    let WeekstartDate = moment(selecteddate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(selecteddate).endOf('week').format(this.yearMonthDateFormat);
      try {
      this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, selecteddate, WeekstartDate, weekendDate, (data) => {
      const dialogRef = this.dialog.open(AssignAppointmentComponent, {
        width: '750px',
        data: { data, jobDurationCode }
      });

      dialogRef.afterClosed().subscribe(result => {
        //////console.log(result);
        if (result != undefined) {
          this.appointmentchange = true;
          this.getFullJobDetail();
          this.common.message = result;
          this.common.successMessage = true;
        }
      });
    });
  } catch (error) {
  
  }


  }

  // assignAppointment(jobDurationCode, jobsCode){
  //   const dialogRef = this.dialog.open(AssignAppointmentComponent, {
  //     width: '450px',
  //     data: { jobDurationCode, jobsCode }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     //////console.log(result);
  //     if(result != undefined){
  //       this.appointmentchange = true;
  //       this.getFullJobDetail();
  //       this.common.message = result;
  //       this.common.successMessage = true;
  //     }
  //   });
  // }

  /* Name:srinivas
       use: open popup for trade shift */
  tradeShift(data) {
    const dialogRef = this.dialog.open(TradeShiftComponent, {
      width: '450px',
      data: { data }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        this.appointmentchange = true;
        this.getFullJobDetail();
        this.common.message = result;
        this.common.successMessage = true;
      }
    });
  }
/* Name:srinivas
       use: open cancel appointment popup */
  cancelAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(CancelAppointmentComponent, {
      width: '450px',
      data: { jobDurationCode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.appointmentchange = true;
        this.getFullJobDetail();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }

/* Name:srinivas
       use: save accept appointment details */
  acceptAppointment(jobDurationCode, workerCode) {
    const data = {
      'workerCode': workerCode
    }
    try {
      
   
    this.service.assignToWorkerAcceptedByJobDurationCode(jobDurationCode, data, (res) => {
      if (res.responsecode == 200) {
        this.service.common.message = res.message;
        this.service.common.successMessage = true;
        this.getFullJobDetail();
      } else {
        this.service.common.message = res.message;
        this.service.common.errorMessage = true;
      }
    }) 
  } catch (error) {
      
    }
  }
/* Name:srinivas
       use:open popup for route details */
  showRoute(data) {
    //////console.log(data);
    let startdate;
    let startDate;

    // if (this.currentUrl == 'patient') {
    //   startdate = data.startTime.split(' ')[0];
    // } else {
    startdate = data.appointmentStartDate.split(' ')[0];
    // }

    if (data.dateFormat == 'MM-dd-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else if (data.dateFormat == 'dd-MM-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    }
    try {
   
    this.dashboardService.mobileGetRouteByTechnicianCodeDateAndJobdurationcode(data.workerCode, startDate, data.appointmentsDurationCode, (jobres) => {
      if (jobres.responsecode == 200) {

        const dialogRef = this.dialog.open(ShowRoutesComponent, {
          width: '550px',
          height: '575px',
          data: { workerCode: data.workerCode, appointmentsDurationCode: data.appointmentsDurationCode, date: data.appointmentStartDate, data: jobres.data }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.appointmentchange = true;
            this.getFullJobDetail();
            this.service.common.message = result;
            this.service.common.successMessage = true;
          }
        });
        //      //////console.log(jobres);
        // this.jobRouteData = jobres.data;
        // this.googleMap(this.jobRouteData);
      }
      // this.service.common.loader = false;
    })
   
    } catch (error) {
      
    }

  }
/* Name:srinivas
       use: shw or hide image */
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }
/* Name:srinivas
       use: open reject appointment popup */
  rejectAppointment(jobcode, userCode) {
    const dialogRef = this.dialog.open(RejectAppointmentComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      if (result != undefined) {
        const data = {
          'comment': result,
          'workerCode': userCode
        }
        try {
        
        this.newAppointmentService.assignToWorkerrejectedByJobDurationCode(jobcode, data, (res) => {
          if (res.responsecode == 200) {
            this.service.common.message = res.message;
            this.service.common.successMessage = true;
            this.getFullJobDetail();
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        })  
        } catch (error) {
          
        }
      }
    });
  }
/* Name:srinivas
       use: open procedure details popup */
  getProcedureDetails(procedure, notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: { procedure: procedure, notes: notes }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });

  }
/* Name:srinivas
       use: open notes popup */
  getNotes(note) {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      width: '450px',
      data: note
    });
  }
/* Name:srinivas
       use: open arrival dearture popup */
  openArrivalDialog(arrival,departure): void {
    const dialogRef = this.dialog.open(ArrivalDepartureComponent, {
      width: '350px',
      data: {arrival: arrival, departure: departure}
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}
