import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { Skills } from './skills.model';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
    providedIn: 'root'
})
export class SkillsService {

    organization;

    constructor(
        public service: ApiRootService,
        public http: HttpClient,
        public storage: SessionStorageService,
        public common: CommonService) {

        this.common.getOrgaizationCode();
    }

    /* Name:Shailesh
      use: for getting all skill list by industry code */
    getAllSkills() {
        return this.http.get(this.service.HEALTHCARE_URL + '/getSkillsWithNamesByIndustryCode/b8fa3263-4a90-41e0-a5e8-896667268b62');
    }

    /* Name:Shailesh
      use: for adding/creating new skill */
    saveSkills(data, callback) {
        return this.http.post(this.service.HEALTHCARE_URL + '/saveOrganizationSkill', data).subscribe((data) => {
            callback(data);
        });
    }

    /* Name:Vivek Chauhan new API
    use: for adding/creating new skill */
    saveAndUpdateAllOrganizationSkill(data, callback) {
    return this.http.post(this.service.HEALTHCARE_URL + '/saveAndUpdateAllOrganizationSkill', data).subscribe((data) => {
        callback(data);
    });
    }

    /* Name:Shailesh
      use: for getting skill list by org code */
    getSkillsByOrganizationCodeByPage(organizationCode, page, limit, callback) {
        return this.http.get(this.service.HEALTHCARE_URL + '/v2/getSkillsByOrganizationCodeByPage/' + organizationCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
            callback(data);
        });
    }

    /* Name: Vivek Chauhna
    use: for getting skill list by search text */
    getSkillsByOrganizationCodeByPageSearch(organizationCode, searchText, callback) {
    return this.http.get(this.service.HEALTHCARE_URL + '/v2/getSkillsByOrganizationCodeByPage/' + organizationCode + '/' + searchText).subscribe((data) => {
        callback(data);
    });
    }

      /* Name:Vivek Chauhan new API
    use: for delete/remove Skill */
    softDeleteOrganizationSkillBySkillCode(skillCode, callback) {
        return this.http.delete(this.service.HEALTHCARE_URL + '/softDeleteOrganizationSkillBySkillCode/' + skillCode).subscribe((data) => {
          callback(data);
    
        })
      }

    /* Name:Shailesh
      use: for getting organization skills by id */
    getSkillDataById(id, callback) {
        return this.http.get(this.service.getHealthCareUrl() + '/getOrganizationSkill/' + id).subscribe((data) => {
            callback(data);
        })
    }

}