import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  constructor(
    public service: ApiRootService,
    public http: HttpClient,
    public storage: SessionStorageService,
    public common: CommonService
  ) { }

    /* Name:Vivek Chauhan
     use: for getting all Processed List by page */
     getListDataByPageProcessed(orgCode, page, limit, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getLeaveDetailsByOrganizationCodeAndNotInPendingByPage/' + orgCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      });
    }  

        /* Name:Vivek Chauhan
     use: for getting all Search Processed List by page */
     getListDataBySearchProcessed(orgCode, page, limit, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getLeaveDetailsByOrganizationCodeAndNotInPendingByPageSearch/' + orgCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      });
    }  

    /* Name:Vivek Chauhan
     use: for Search Pending list by page */
     getListDataBySearchPending(orgCode, leaveStatus, searchText, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPendingLeaveDetailsByOrganizationCodeByPage/'+ orgCode + '/' + leaveStatus + '/' + searchText).subscribe((data) => {
      callback(data);
    });
  } 

  /* Name:Vivek Chauhan
     use: for getting all Pending List by page */
     getListDataByPagePending(orgCode, leaveStatus, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getPendingLeaveDetailsByOrganizationCodeByPage/' + orgCode + '/' + leaveStatus + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  }  

  /* Name:Vivek Chauhan
     use: for getting all Users details by user code */
  getAllUserTrackingByUserCodeAndDate(userCode,date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllUserTrackingByUserCodeAndDate/' + userCode + '/' + date).subscribe((data) => {
      callback(data);
    });
  }

/* Name:Vivek Chauhan
  use: for Leave Status [rejected/approved and pending] using leaveDetailsCode */
updateLeaveStatus(leaveDetailsCode, leaveStatus, dataval, callback) {
  return this.http.put(this.service.getHealthCareUrl() + '/updateLeaveStatus/' + leaveDetailsCode + '/' + leaveStatus, dataval).subscribe((data) => {
    callback(data);
  })
}

}
