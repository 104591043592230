import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/shared/common.service';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class TimeOfRequestService {

  constructor(public http: HttpClient, public service: ApiRootService, public common: CommonService, public session: SessionStorageService) { }
/* Name:srinivas
      use: returns time of worker details by dates. */
  getTimeOffByWorkerCodeAndBetweenDates(workercode, startDt, endDt, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getTimeOffByWorkerCodeAndBetweenDates/' + workercode + '/' + startDt + '/' + endDt).subscribe((data) => {
      callback(data);
    });
  }
  /* Name:Vivek Chauhan
      use: saves or update worker leave details. */
  saveOrUpdateLeaveDetails(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateLeaveDetails', formData).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
      use: delete time off worker detail. */
  cancelTimeOffBytimeOffCode(code, callback){
    return this.http.delete(this.service.getHealthCareUrl()+'/cancelTimeOffBytimeOffCode/'+code).subscribe((data)=>{
      callback(data);
    })
  }

  
    /* Name:Vivek Chauhan
     use: for getting all Processed List by page */
     getListDataByPageProcessed(orgCode, page, limit, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getLeaveDetailsByOrganizationCodeAndNotInPendingByPage/' + orgCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      });
    }  

        /* Name:Vivek Chauhan
     use: for getting all Search Processed List by page */
     getListDataBySearchProcessed(orgCode, page, limit, callback) {
      return this.http.get(this.service.getHealthCareUrl() + '/getLeaveDetailsByOrganizationCodeAndNotInPendingByPageSearch/' + orgCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
        callback(data);
      });
    }  

    /* Name:Vivek Chauhan
     use: for Search Pending list by page */
     getListDataBySearchPending(orgCode, leaveStatus, searchText, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getLeaveDetailsByOrganizationCodeByPage/'+ orgCode + '/' + leaveStatus + '/' + searchText).subscribe((data) => {
      callback(data);
    });
  } 

  /* Name:Vivek Chauhan
     use: for getting all Pending List by page */
     getListDataByPagePending(workerCode, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getLeaveDetailsByWorkerCodeByPage/' + workerCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  }  

  /* Name:Vivek Chauhan
     use: for getting all Users details by user code */
  getAllUserTrackingByUserCodeAndDate(userCode,date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllUserTrackingByUserCodeAndDate/' + userCode + '/' + date).subscribe((data) => {
      callback(data);
    });
  }

/* Name:Vivek Chauhan
  use: for Leave Status [rejected/approved and pending] using leaveDetailsCode */
updateLeaveStatus(leaveDetailsCode, leaveStatus, dataval, callback) {
  return this.http.put(this.service.getHealthCareUrl() + '/updateLeaveStatus/' + leaveDetailsCode + '/' + leaveStatus, dataval).subscribe((data) => {
    callback(data);
  })
}

}
