import { startWith, map } from 'rxjs/operators';
import { DepartmentService } from './../department.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit {

  deptForm: FormGroup;
  options = [];
  deptData;
  filteredOptions: Observable<string[]>;
  fileUrl;
  filetitle;
  imageupload;
  imageMsg;
  submitted = true;
  activeStatus = ['true', 'false']
  parentData = [];
  routeData;

  constructor(public service: DepartmentService, private translate: TranslateService,
    public route: ActivatedRoute, public router: Router, private dialog: MatDialog) {

     /* Name:Shailesh
  use: for getting all department for parent by org code*/

  try {
    this.service.getDepartmentForParentSelectByOrganizationCode(this.service.local.get('orgCode'), (data) => {
      ////console.log(data);
      this.parentData = data.data;
      if (this.parentData) {
        if (data.responsecode === 200) {
          this.parentData.forEach(parent => {
            if (parent.parentName) {
              this.options.push({ "name": parent.parentName, "value": parent.parentid });
            }
          });
          this.service.common.progressLoader = false;
        } else {
          this.service.common.progressLoader = false;
        }
      }
    });
  } catch (error) {
    //console.log(error);
  }
    
  }

  ngOnInit() {
    // ////console.log(this.data);
    this.resetFormData();
    
    
  }
  
  resetFormData() {
    this.resetParentData()
    this.deptForm = new FormGroup({
      "departmentName": new FormControl(null, [Validators.required]),
      "parentId": new FormControl(null),
      'departmentLogo': new FormControl(null),
      'isActive': new FormControl(null, [Validators.required]),
    });

    this.route.queryParams.subscribe((data) => {
      ////console.log(data);
      this.service.common.progressLoader = true;
      this.routeData = data;
      this.service.deptData.departmentName = this.routeData.departmentName
      this.deptForm.controls['departmentName'].setValue(this.routeData.departmentName)
      if (this.routeData.departmentLogo) {
        this.imageupload = true;
        this.fileUrl = this.service.service.getSuperAdmin() + '/downloadFile/' + this.routeData.departmentLogo;
        this.service.deptData.departmentLogo = this.routeData.departmentLogo
      }
      this.service.deptData.isActive = this.routeData.isActive
      this.deptForm.controls['isActive'].setValue(this.routeData.isActive)
      // this.service.deptData.parentid = this.data.parentid 
      this.deptForm.controls['parentId'].setValue({ "name": this.routeData.parentName, "value": this.routeData.parentid })
    })
    this.service.common.progressLoader = false;
    this.filteredOptions = this.deptForm.controls['parentId'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value): string[] {
    ////console.log(value);
    if (value) {
      let filterValue;
      // if (typeof value.name === undefined) {
      filterValue = value.name.toLowerCase();
      //   this.deptForm.controls['parentId'].setValue(null);
      // } else {
      //   filterValue = value.name.toLowerCase();
      //   this.deptForm.controls['parentId'].setValue(value.value);
      this.service.deptData.parentid = value.value;
      // }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }
  }

  displayFn(option): string | undefined {
    ////console.log(option);
    return option ? option.name : undefined;
  }

  selectedOption(event){
    ////console.log(event);
    this.deptForm.controls['parentId'].setValue(event.option.value);
  }

  onFileChange(event) {
    //////console.log(event);
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);


      let allowedExtension = ['jpeg', 'jpg', 'png', 'bmp'];
      let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
      let isValidFile = false;

      for (let index in allowedExtension) {

        if (fileExtension === allowedExtension[index] && event.target.files[0].size <= 2000000) {
          isValidFile = true;
          this.imageMsg = '';
          this.service.common.uploadImage(formData, (res) => {
            ////console.log(res);
            this.fileUrl = res.fileurl;
            this.filetitle = res.filename;
            // this.deptForm.controls['skilllabel'].setValue(res.filename);
            this.service.deptData.departmentLogo = res.filename;
            this.deptForm.controls['departmentLogo'].setValue(res.filename);
            //////console.log(this.skillService.skills.skillList[0].skillImage);
            this.imageupload = true;
          })
        }
        if (!isValidFile) {
          this.deptForm.controls['departmentLogo'].setValue(null);
          event.srcElement.value = '';
          event.target.files = null;
          this.imageMsg = 'Allowed Extensions are : *.' + allowedExtension.join(', *.') + ' and file size should be less then 2MB';
        }
      }
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.fileUrl = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /* Name:Shailesh
  use: for adding or updating department*/
  onSubmit() {
    ////console.log(this.deptForm);
    ////console.log(this.service.deptData);
    this.service.deptData.organizationCode = this.service.local.get('orgCode')
    if (this.deptForm.valid) {
      this.submitted = true;
      if (this.routeData.id) {
        try {
          this.service.updateDepartmentMaster(this.routeData.id, this.service.deptData, (response) => {
          if (response.responsecode == 200) {

            this.service.common.successMessage = true;
            this.service.common.message = response.message;
            this.router.navigate(['/dashboard/department']);
          } else {
            this.service.common.errorMessage = true;
            this.service.common.message = response.message;
          }
        })
        } catch (error) {
          //console.log(error);
        }
        
      } else {
        try { this.service.saveDepartmentMaster(this.service.deptData, (response) => {
          if (response.responsecode == 200) {

            this.service.common.successMessage = true;
            this.service.common.message = response.message;
            this.router.navigate(['/dashboard/department']);
          } else {
            this.service.common.errorMessage = true;
            this.service.common.message = response.message;
          }
        })
          
        } catch (error) {
          //console.log(error)
        }
       
      }
    } else {
      this.submitted = false;
    }
  }

  resetParentData() {
    this.service.deptData = {
      "departmentLogo": "",
      "departmentName": "",
      "isActive": true,
      "organizationCode": "",
      "parentid": 0
    }
  }

   /* Name:Shailesh
  use: for opening cancel popup*/
  cancelPopup() {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'This page is asking you to confirm that you want to reset – If any data you have entered may not be saved?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.resetFormData();
      }
    });
  }

}
