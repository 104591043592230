import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class JobRateCardService {  

  constructor(
    public service: ApiRootService,
    public local: SessionStorageService,
    public http: HttpClient,
    public common: CommonService
  ) { }

  /* Name:Shailesh
      use: for getting all procedures by organization code with page*/
  getAllJobTypeListByOrganizationCodeByPage(organizationCode, customerCode, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getListOfCustomerJobTypeRateByCustomerCodeAndOrganizationCodeByPage/' + organizationCode + '/' + customerCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }
  // /staffingservice/getListOfCustomerJobTypeRateByCustomerCodeAndOrganizationCodeByPageForSearch/{organizationCode}/{customerCode}/{searchName}
  /* Name:Vivek Chauhan
    use: for procedures by search text*/
    getAllJobTypeListByOrganizationCodeSearchText(organizationCode, customerCode, searchText, callback) { 
      return this.http.get(this.service.getHealthCareUrl() + '/getListOfCustomerJobTypeRateByCustomerCodeAndOrganizationCodeByPageForSearch/' + organizationCode + '/' + customerCode + '/' + searchText).subscribe((data) => {
        callback(data);
      })
    }

    /* Name:Vivek Chauhan new API
    use: for save Or Update Customer Job Type Rate */
    saveOrUpdateCustomerJobTypeRate(data, callback) {
      return this.http.post(this.service.HEALTHCARE_URL + '/saveOrUpdateCustomerJobTypeRate', data).subscribe((data) => {
          callback(data);
      });
      }

}
