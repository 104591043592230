import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NewJobCardService } from '../new-job-card.service';
import { DashboardService } from 'src/app/core/services/dashboard-service.service';
import { SessionStorageService } from 'angular-web-storage';
import { debounce } from 'lodash';
import { FailedStaffListPopup } from "src/app/components/failed-staff-list/failed-staff-list.component";

@Component({
  selector: 'assign-job',
  templateUrl: './assign-job.component.html',
  styleUrls: ['./assign-job.component.scss']
})
export class AssignJobComponent implements OnInit {
  searchText: any;
  img_serverPath: string = '';
  eligibleStaffsList: any = [];
  filtered_eligibleStaffsList: any[] = [];
  showSpinner: boolean = false;
  selected_appoinmentProcedureCode: string;
  selected_appoinmentDurationCode: string;
  selected_appoinmentCode: string
  selected_date: string;
  selectedStaff: any[] = [];
  failedWorkerResponseList: any[] = [];
  protected isDrivingTime : boolean = false;
  required_staffs : number | null = null ;
  
  constructor(
    public dialogRef: MatDialogRef<AssignJobComponent>,
    public dialog: MatDialog, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private jobCardService: NewJobCardService, 
    public dashboarService: DashboardService,
     ) 
     {
      this.applyFilter = debounce(this.applyFilter, 600);
     }

  ngOnInit(): void {
    // console.log('AdssignPopupData: ', this.data);  
    this.img_serverPath = this.jobCardService._apiSVC.getSuperAdmin() + '/downloadFile/';
    if(this.data && this.data?.appoinmentCode && this.data?.appoinmentProcedureCode && this.data?.date)
      {
      this.selected_appoinmentCode = this.data.appoinmentCode;
      this.selected_appoinmentProcedureCode = this.data.appoinmentProcedureCode;
      this.selected_date = this.data.date;
      // this.required_staffs = ( this.data.required - this.data.scheduled ) ;
      // this.required_staffs =  this.data.scheduled ;
      this.getEligibleStaffs(this.data.appoinmentCode, this.data.appoinmentProcedureCode, this.data.date);
    }
  }

  closePopup(isPopup = null): void {
    this.filtered_eligibleStaffsList = [];
    this.selected_appoinmentCode = null;
    this.selected_appoinmentProcedureCode = null;
    this.selected_date = null;
    this.dialogRef.close(isPopup);
  }

  AssignJobToStaff() {

   // console.log("selectedStaff", this.selectedStaff) ;

    let workerCodeList = [];

    // this.filtered_eligibleStaffsList.forEach(ele => {
    //   if (ele.isAssigned) {
    //     workerCodeList.push(ele.worker.workerCode);
    //   }
    // });

    workerCodeList = this.selectedStaff.map((ele:any) => ele.worker.workerCode);

 //console.log("workerCodeList", workerCodeList)

   if (workerCodeList.length > 0) {
      let payload = {
        "appointmentsCode": this.selected_appoinmentCode,
        "appointmentsProceduresCode": this.selected_appoinmentProcedureCode,
        "date": this.selected_date,
        "workerCodeList": workerCodeList
      }
      // console.log('AssignJobToStaffPayload: ', payload);
      // return;
      this.dashboarService.common.progressLoader = true;
      this.jobCardService.jobAssignedToManyStaffByAdmin(payload).subscribe((res: any) => {
        this.dashboarService.common.progressLoader = false;

        if (res.responsecode == 200) {
          this.dashboarService.common.successMessage = true;
          this.dashboarService.common.message = res.message;
          this.closePopup('success');
        }
        else {
          this.dashboarService.common.message = res.message;
          this.dashboarService.common.errorMessage = true;
        }
      })
    } else {
      this.dashboarService.common.message = 'Please select a Staff to assign.';
      this.dashboarService.common.errorMessage = true;
    }
  }

  getEligibleStaffs(appoinmentCode: string, appoinmentProcedureCode: string, date: string) {
    this.showSpinner = true;
    this.jobCardService.getEligibleStaffs(appoinmentCode, appoinmentProcedureCode, date).subscribe((res: any) => {
      this.showSpinner = false;
      if (res.responsecode == 200){
        this.failedWorkerResponseList =  res.data.failedWorkerResponseList;
        this.required_staffs = res.data.pendingCount ;
        this.isDrivingTime = (res?.IsDrivingTime && res?.IsDrivingTime === 'true' ) ? true : false ;
        this.eligibleStaffsList = res.data.workerWithDistanceList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;
       // this.set_staff_in_temp(this.filtered_eligibleStaffsList);
        // this.selectedStaff = this.eligibleStaffsList.find(ele => ele.isAssigned);
      }
    });
  }

  applyFilter(filterValue: string) {
    this.showSpinner = true;
    this.filtered_eligibleStaffsList = [];
    let searchVal = filterValue.trim().toLowerCase();
    if(searchVal){
      this.getEligibleStaffsSearch(searchVal);
    } else {
      this.getEligibleStaffs(this.data.appoinmentCode, this.data.appoinmentProcedureCode, this.data.date);
    }
  }

  getEligibleStaffsSearch(searchVal: string) {
    this.jobCardService.getEligibleStaffsSearch(this.data.appoinmentCode, this.data.appoinmentProcedureCode, this.data.date, searchVal).subscribe((res: any) => {
      this.showSpinner = false;
      if (res.responsecode == 200) {
        this.failedWorkerResponseList =  res.data.failedWorkerResponseList;
        this.eligibleStaffsList = res.data.workerWithDistanceList;
        this.filtered_eligibleStaffsList = this.eligibleStaffsList;        
       // this.set_staff_in_temp(this.filtered_eligibleStaffsList);
        // this.selectedStaff = this.eligibleStaffsList.find(ele => ele.isAssigned);
      }
    });
  }

 

 onChangeSetStaffs(event:any, selected_element:any , index:number)
 {

  if(event.target.checked && (this.selectedStaff.length == this.required_staffs))
  {
    this.dashboarService.common.message = 'You can not select more than required staff';
    this.dashboarService.common.errorMessage = true;
    event.target.checked = false ;
    event.preventDefault();
    event.stopPropagation();
    return ;
  }

  if(event.target.checked)
  {
    this.selectedStaff.push(selected_element);
  }
  else
  {
   const index = this.selectedStaff.findIndex((ele:any)=> ele.worker.workerCode === selected_element.worker.workerCode);

   if(index !== -1)
   {
    this.selectedStaff.splice(index,1) ;
   }
 
  }
 
 }

 set_staff_in_temp(eligible_Staff_list:any[])
 {
  if(eligible_Staff_list.length > 0)
  {
   let temp:any[] = eligible_Staff_list.filter((ele:any)=> ele.isAssigned) || [] ;

   if(temp.length > 0)
   this.selectedStaff = [... this.selectedStaff, ...temp];

  }
 }

 

 isChecked(element:any)
 {
   return this.selectedStaff.some(ele=>ele.worker.workerCode === element.worker.workerCode)
 }

 showFailedRes() {
  // console.log("FailedStaffListPopup_Open:", this.failedWorkerResponseList);
  if(this.failedWorkerResponseList){
  const dialogRef = this.dialog.open(FailedStaffListPopup, {
    disableClose: true,
    width: '700px',
    data: { failedStaffList: this.failedWorkerResponseList}
  });

  dialogRef.afterClosed().subscribe(result => {
    // console.log('FailedStaffListPopup_close:', result);
    if (result != undefined) {

      }
    });
  } else {
    this.dashboarService.common.message = 'Record not found';
    this.dashboarService.common.errorMessage = true;
  }
}


}
