import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { startWith, map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Observable } from 'rxjs';
import { PatientService } from '../../../patient/patient.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { TranslateService } from '@ngx-translate/core';
declare var google;
export interface DialogData { }

@Component({
  selector: 'app-patient-data',
  templateUrl: './patient-data.component.html',
  styleUrls: ['./patient-data.component.scss']
})
export class PatientDataComponent implements OnInit {

  patientForm: FormGroup;
  submitted = true;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options = { types: ['address'], componentRestrictions: { country: ["us", 'in'] } };
  lat: any;
  lng: any;
  countrycodem = this.service.common.CountryCode;
  countrycodep = this.service.common.CountryCode;
  patient: any;
  patientCode: any;
  filteredOptions: Observable<any>;
  updatedAddress: any;

  constructor(
    public service: PatientService,
    public router: Router,
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<PatientDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.translate.setDefaultLang(this.service.common.local.get('language'));
   }
/* Name:srinivas
       use: set country code to control. */
  getNumber(event, countryCode) {
    this.patientForm.controls[countryCode].setValue(event.dialCode);
    this.service.patientData[countryCode] = event.dialCode;
  }
/* Name:srinivas
       use: return lat long details */
  codeLatLng(response, callback) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': response.data.patientAddress + ',' + response.data.patientCity + ',' + response.data.patientState + ',' + response.data.zipCode + ',' + response.data.patientCountry }, function (results, status) {
      ////console.log(results, status);
      callback(results);
    });
  }
/* Name:srinivas
       use: set address to control. */
  handleAddressChange(address) {
    //////console.log(address);
    this.service.patientData.patientLatitude = address.geometry.location.lat();
    this.service.patientData.patientLongitude = address.geometry.location.lng();
    //////console.log(this.lat, this.lng);
    //this.autocomplete=address;
    this.patientForm.controls['address'].setValue(address.formatted_address);
    // setTimeout(()=>{
    this.updateServiceAddress(address);
    this.addressValidation(this.patientForm.controls['address']);
    // },2000);

  }
/* Name:srinivas
       use: check address validation. */
  addressValidation(control: AbstractControl): Promise<any> | Observable<any> {
    const addressValidation = new Promise<any>(
      (resolve, reject) => {
        let addr = control.value;
        // Get geocoder instance
        let geocoder = new google.maps.Geocoder();
        // Geocode the address
        //////console.log(control.value);
        geocoder.geocode({ 'address': addr }, function (results, status) {
          //////console.log(results);
          //////console.log(status);
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            // //////console.log('djksfsjkdfsd');
            ////////console.log(lat,lng);
            //control.setValue(results[0].formatted_address);
            //////console.log(addr);
            //////console.log(results[0].formatted_address);
            if (addr === results[0].formatted_address) {
              resolve(null);

            } else {
              resolve({
                invalid: true
              });
            }
            // show an error if it's not
          } else {
            //////console.log('invalid');
            resolve({
              invalid: true
            });
          }
        });
      });
    return addressValidation;
  }
/* Name:srinivas
       use: set address details to service file. */
  updateServiceAddress(address) {

    this.service.patientData.patientCity = '';
    this.service.patientData.patientCity = "",
      this.service.patientData.patientCountry = "",
      this.service.patientData.patientLatitude = "",
      this.service.patientData.patientLongitude = "",
      this.service.patientData.patientState = "",
      this.service.patientData.zipCode = ""

    let places: any;
    const componentForm = {
      route: 'long_name',
      locality: 'long_name',
      political: 'long_name',
      administrative_area_level_1: 'long_name',
      postal_code: 'short_name',
      country: 'long_name'
    };

    for (let i = 0; i < address.address_components.length; i++) {
      const addressType = address.address_components[i].types[0];
      if (componentForm[addressType]) {
        if (addressType === 'route') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.patientData.patientAddress = val;
        }
        if (addressType === 'political') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.patientData.patientAddress = val;
        }
        if (addressType === 'postal_code') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.patientData.zipCode = val;
        }
        if (addressType === 'locality' || addressType === 'postal_town') {
          const val = address.address_components[i][componentForm[addressType]];
          //////console.log(val);
          this.service.patientData.patientCity = val;
        }
        if (addressType === 'administrative_area_level_1') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.patientData.patientState = val;
        }
        if (addressType === 'country') {
          const val = address.address_components[i][componentForm[addressType]];
          this.service.patientData.patientCountry = val;
        }
      }
    }

    this.service.patientData.patientLatitude = address.geometry.location.lat();
    this.service.patientData.patientLongitude = address.geometry.location.lng();
    //////console.log(this.service.patientData);
    // this.showMapmarker(this.service.workerHome.latitude ,this.service.workerHome.longitude);
    this.patientForm.controls['address'].setValue(address.formatted_address);
  }

  ngOnInit() {

    this.service.patientData = {
      'patientIcon': '',
      'location':'',
      'patientName': '',
      'patientId': '',
      'phoneNumber': '',
      'mobileNumber': '',
      'patientAddress': '',
      'patientHistoryDescription': '',
      'recurringNotes' : '',
      'patientDocumentMappingReqList': [],
      'patientLatitude': '',
      'patientLongitude': '',
      'patientCountry': '',
      'patientCity': '',
      'zipCode': '',
      'patientState': '',
      "isActive": true,
      'mobileCountryCode': this.countrycodem,
      "organizationCode": "",
      "patientEmail": "",
      'phoneCountryCode': this.countrycodep,
      'patientCode': '',
      "address": '',
      'areaReferDoctor':'',
      'patientApartmentNo':'',
      'caretakerNumber': '',
      'caretakerCountryCode': '',
      'preferredNumber': '',
      'preferredCountryCode': '',
      'isPreferSameClinician': true,
      'excludeClinician': '',
      'experience': 0,
      'minimumProficiency': 0,
       'preferredPhoneType':''
    }
      try {
      this.service.getPatientByOrganizationCode(this.service.local.get('orgCode'), (response) => {
      if (response.responsecode == 200) {
        this.patient = response.data;
      }
    });
} catch (error) {
  
}
    this.getFormReset()
    
    this.service.patientData.organizationCode = this.service.local.get('orgCode');
  }
/* Name:srinivas
       use: set all control to blank.*/
  getFormReset() {
    this.getServiceDataReset();
    this.patientForm = new FormGroup({
      'patientIcon': new FormControl(null),
      'patientName': new FormControl(null, Validators.required),
      // 'patientId': new FormControl(null, Validators.required),
      'phoneNumber': new FormControl(null, [Validators.required, Validators.pattern(this.service.common.phonenumber)]),
      'mobileNumber': new FormControl(null, [ Validators.pattern(this.service.common.phonenumber)]),
      'address': new FormControl(null, Validators.required),
      'phoneCountryCode': new FormControl(this.countrycodep, Validators.required),
      'mobileCountryCode': new FormControl(this.countrycodem, Validators.required),
    })

    this.route.queryParams.subscribe((data) => {
      if (data.patientCode) {
        this.patientCode = data.patientCode;
        this.service.patientData.patientCode = this.patientCode;
        try {
          
      
        this.service.getPatientDetailByPatientCode(this.patientCode, (response) => {
          //////console.log(response);
          // this.setProcedureData(response.data);
          if (response.responsecode == 200) {

            this.codeLatLng(response, (addr) => {
              // alert(addr);
              ////console.log(addr, addr[0].formatted_address);
              this.updatedAddress = addr[0].formatted_address;
              this.patientForm.controls['address'].setValue(this.updatedAddress);
            });

            this.service.patientData.patientName = response.data.patientName;
            this.service.patientData.patientId = response.data.patientCode;
            this.service.patientData.patientAddress = response.data.patientAddress;
            this.service.patientData.mobileNumber = response.data.mobileNumber;
            this.service.patientData.phoneNumber = response.data.phoneNumber;
            this.service.patientData.organizationCode = response.data.organizationCode;
          }
        })  } catch (error) {
          
        }
      }

    });

  }

/* Name:srinivas
       use: se patient details to control. */

  setPatientData(element) {
    //////console.log(element);
    if (element) {
      this.service.patientData.patientName = element.patientName;
      this.service.patientData.mobileNumber = element.mobileNumber;
      this.service.patientData.phoneNumber = element.phoneNumber;
      this.service.patientData.patientId = element.patientId;
      if (element.patientId) {
        this.service.patientData.patientId = element.patientId;
      }
    }
  }
/* Name:srinivas
       use: save and update patient datails. */
  onSubmit() {
    //////console.log(this.patientForm);
    if (this.patientForm.valid) {
      this.service.common.progressLoader = true;
      this.submitted = true;
      //////console.log(this.patientForm);
      this.service.patientData.organizationCode = this.service.local.get('orgCode');
      //////console.log(this.service.patientData);
      try {
        
     
      if (this.patientCode) {
        this.service.updatePatientDetailByPatientCode(this.service.patientData.organizationCode, this.service.patientData, (response) => {
          //////console.log(response);
          if (response.responsecode == 200) {
            this.service.common.message = response.message;
            this.service.common.successMessage = true;
            this.dialogRef.close();
            // this.router.navigate(['/dashboard/patient/patient-list']);
          } else {
            this.service.common.message = response.message;
            this.service.common.errorMessage = true;
          }
        })
      } else {
        this.service.savePatientDetail(this.service.patientData, (response) => {
          //////console.log(response);
          if (response.responsecode == 200) {
            this.service.common.progressLoader = false;
            this.service.common.successMessage = true;
            this.service.common.message = response.message;
            this.dialogRef.close(response);
            // this.router.navigate(['/dashboard/patient/patient-list']);
          } else {
            this.service.common.progressLoader = false;
            this.service.common.errorMessage = true;
            this.service.common.message = response.message;
          }
        })
      } 
    } catch (error) {
        
      }
    } else {
      this.submitted = false;
    }
  }
  /* Name:srinivas
       use: set country code to control. */
  getCountryCode(event, formControlName) {
    //////console.log(event, formControlName);
    //////console.log(event);
    this.patientForm.controls[formControlName].setValue(event);
    this.service.patientData[formControlName] = event;
  }
/* Name:srinivas
       use: close popup */
  closeOnClick(): void {
    this.dialogRef.close();
  }
/* Name:srinivas
       use: reset page. */
  cancelPopup(){
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'This page is asking you to confirm that you want to reset – If any data you have entered may not be saved?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.getFormReset();
      }
    });
  }
/* Name:srinivas
       use: return all service keys reset. */
  getServiceDataReset() {
    this.service.patientData = {
      'patientIcon': '',
      'patientName': '',
      'location':'',
      'patientId': '',
      'phoneNumber': '',
      'mobileNumber': '',
      'patientAddress': '',
      'patientHistoryDescription': '',
      'recurringNotes' : '',
      'patientDocumentMappingReqList': [],
      'patientLatitude': '',
      'patientLongitude': '',
      'patientCountry': '',
      'patientCity': '',
      'zipCode': '',
      'patientState': '',
      "isActive": true,
      'mobileCountryCode': this.countrycodem,
      "organizationCode": "",
      "patientEmail": "",
      'phoneCountryCode': this.countrycodep,
      'patientCode': '',
      "address": '',
      'areaReferDoctor':'',
      'patientApartmentNo':'',
      'caretakerNumber': '',
      'caretakerCountryCode': '',
      'preferredNumber': '',
      'preferredCountryCode': '',
      'isPreferSameClinician': true,
      'excludeClinician': '',
      'experience': 0,
      'minimumProficiency': 0,
      'preferredPhoneType':''
    }
  }
}
