import { Component } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent {

  // selectedTab:string = 'general' ;
  selectedTab:string ;
  controlsPermissions:IBtnControlsPermission ;
  permissionList:any[] = [];

  isView_GeneralSetting:boolean = false ;
  isView_OrgWorkingHrSetting:boolean = false ;
  isView_NotificationSetting:boolean = false ;
  isView_RoleProfileSetting:boolean = false ;
  isView_RoleSetting:boolean = false ;
  isView_DesignSetting:boolean = false ;  
  isView_EmailTemplateSetting:boolean = false ;
  isView_EmailConfigSetting:boolean = false ;

  constructor(private _commonSVC:CommonService,
    private _sessionSVC : SessionStorageService){}

  ngOnInit(): void {
     this.controlsPermissions =  this._commonSVC.getBtnEventPermissions("Role Settings"); 
     this.simplifyArray();
     this.TabAccessAndVisibility();
  
  }


  changeTab(tabName:string)
  {
     this.selectedTab =  tabName ;
  }


  simplifyArray()
  {
    let ppList = this._sessionSVC.get('MenuListDataAndPagePermission').ppLst  || [];

    ppList.forEach(outer_ele => {
      this.permissionList.push(outer_ele);

      if(outer_ele.childList.length > 0)
      {
        outer_ele.childList.forEach(inner_ele => {
          this.permissionList.push(inner_ele);
         });
      }
    });
  }


   getTabAccess(tabName:string){
    if(tabName === 'Template Settings' || tabName === 'Email Settings'){
      return true;
    } else {

    }
    return this.permissionList.find(ele=> ele.pagePermissionName === tabName)?.viewPermission ;
  }


  TabAccessAndVisibility()
  {
    this.isView_GeneralSetting = this.getTabAccess("General Settings") ;
    this.isView_OrgWorkingHrSetting = this.getTabAccess("Organization Working hours Settings") ;
    this.isView_NotificationSetting = this.getTabAccess("Notification Settings") ;
    this.isView_RoleProfileSetting = this.getTabAccess("Role Profile Settings") ;
    this.isView_RoleSetting = this.getTabAccess("Role Settings") ;
    this.isView_DesignSetting = this.getTabAccess("Design Settings") ;
    this.isView_EmailTemplateSetting = this.getTabAccess("Template Settings") ;
    this.isView_EmailConfigSetting = this.getTabAccess("Email Settings") ;

    setTimeout(()=>{
   
    if(this.isView_GeneralSetting)
    {
       this.selectedTab = "general";
       return ;
    }
     if (this.isView_OrgWorkingHrSetting)
    {
      this.selectedTab = "userpreference";
      return ;
    }
    if (this.isView_NotificationSetting)
    {
      this.selectedTab = "notification";
      return ;
    }
    if (this.isView_RoleProfileSetting)
    {
      this.selectedTab = "role-profile";
      return ;
    }
    if (this.isView_RoleSetting)
    {
      this.selectedTab = "role";
      return ;
    }
    if (this.isView_DesignSetting)
    {
      this.selectedTab = "design";
      return ;
    }
  if (this.isView_EmailTemplateSetting)
    {
      this.selectedTab = "emailTemplate";
      return ;
    }
  if (this.isView_EmailConfigSetting)
    {
      this.selectedTab = "emailConfig";
      return ;
    }
      
},200)

  }



}
