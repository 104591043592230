import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ElegibleAppointmentService } from './elegible-appointment.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { RejectAppointmentComponent } from '../new-appointment/reject-appointment/reject-appointment.component';
import { NewAppointmentService } from '../new-appointment/new-appointment.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { TechSettingService } from '../tech-setting/tech-setting.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { ProcedureDetailComponent } from '../../providers-dashboard/appointment/procedure-detail/procedure-detail.component';
import { NotesPopupComponent } from '../../providers-dashboard/appointment/notes-popup/notes-popup.component';
import { ElegibleAppointmentFilterComponent } from './elegible-appointment-filter/elegible-appointment-filter.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-elegible-appointment',
  templateUrl: './elegible-appointment.component.html',
  styleUrls: ['./elegible-appointment.component.scss']
})
export class ElegibleAppointmentComponent implements OnInit {


  currentSelectedDate;
  eligibleData = [];
  weekDaysHeaderArr: Array<string> = [];
  navDate: any;
  currentDate = new Date();
  startDate: any;
  endDate: any;
  selectDate;
  selectedDate: any;

  gridArrNew: Array<any> = [];
  gridArr: Array<any> = [];
  @Input() locale: string;
  showPopup;
  showImg;
  procode = 'all';
  location = 'all';
  miles = 0;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  options = { types: ['geocode'], componentRestrictions: { country: ["in", "us"] } };
  serachFilter: FormGroup;
  submitted = true;
  resetForm = false;
  loggedUserType: any;

  constructor(public service: ElegibleAppointmentService,
    public dialog: MatDialog,
    public appoinmentService: NewAppointmentService,
    public translate: TranslateService, public common: CommonService) { 
      this.translate.setDefaultLang(this.common.local.get('language'));
    }


  ngOnInit() {

    this.loggedUserType = this.service.session.get('user_type');
    this.serachFilter = new FormGroup({
      'address': new FormControl(null),
      'miles': new FormControl(0, Validators.required)
    })


    this.selectDate = moment(this.currentDate).format('D');
    this.selectedDate = moment(this.currentDate).format('DD MMM');
    this.currentSelectedDate = moment(new Date()).format('YYYY-MM-DD');
    let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    // this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    this.startDate = moment().startOf('month').format('YYYY-MM-DD');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');

    this.navDate = moment();
    this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);
    this.makeHeader();
    this.createBlankGrid();
  }
   /* Name:srinivas
    use: shows the image. */
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }
   /* Name:srinivas
    use: returns elegiblae appointments list. */
  getElegibleAppointment(code, currentSelectedDate, endDate, procode, location,  miles) {
    // //////console.log('in');
    this.service.common.progressLoader = true;
    try {
      
    
    this.service.mobileGetEligibleAppointmentForTechnicianByDate(code, currentSelectedDate, endDate, procode, location,  miles, (data) => {
      ////console.log(data);
      if (data.responsecode == 200) {
        this.getNewAppointments(data.data);
        this.service.common.progressLoader = false;
      } else {
        this.createBlankGrid();
        this.eligibleData = [];
        this.service.common.progressLoader = false;
      }
    });
  } catch (error) {
      
    }
  }

 /* Name:srinivas
    use: gets new appointment. */
  getNewAppointments(allData, startDate = null) {
    //console.log(allData)
    this.gridArr = [];
    this.eligibleData = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      // //////console.log(moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY'));
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {
        if (allData.length > 0) {
          allData.forEach(element => {
            // //////console.log(element);
            if(element.dateFormat == 'dd-MM-yyyy'){
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');

            } else if(element.dateFormat == 'MM-dd-yyyy'){
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MM-DD-YYYY');

            } else {
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');
            }
            if (element.elegibleList.length > 0) {
              element.elegibleList.forEach(elementData => {
                // //////console.log(elementData);
                

                obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');

                if (element.appointmentDate == dateToCheck) {
                  obj.available = true;
                  obj.event = true;
                  obj.eventData.push(elementData);
                  obj.highlight = 'greendark';
                  obj.value = i - initialEmptyCells + 1;
                  obj.date = dateToCheck;
                  obj.key = obj.currentDate;
                  obj.dateFormat = element.dateFormat;
                }
              });

            } else {

              obj.available = true;
              obj.value = i - initialEmptyCells + 1;
              obj.date = dateToCheck;
              obj.key = obj.currentDate;
               obj.dateFormat = element.dateFormat;
               obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');

            }

          });

          // //////console.log(this.eligibleData);
        } else {
          obj.date = dateToCheck;
          obj.available = true;
          obj.value = i - initialEmptyCells + 1;
        }
        // //////console.log(obj.currentDate,this.selectedDate);
        if (obj.currentDate == this.selectedDate && obj.eventData.length > 0) {
          this.eligibleData.push({ key: obj.currentDate, color: obj.dataPercent, iscurrent: true, eventData: obj.eventData });
        }
      }
      this.gridArr.push(obj);
    }

    this.gridArrNew = this.gridArr;

    this.service.common.progressLoader = false;

  }
 /* Name:srinivas
    use: create header. */
  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
  }
 /* Name:srinivas
    use: change month. */
  changeNavMonth(num: number, monthData = null) {
    this.navDate.add(num, 'month');
    // //////console.log(this.datepipe.transform(this.navDate, 'dd/MM/yyyy'));
    // //////console.log(this.datepipe.transform(moment(), 'dd/MM/yyyy'));

    //////console.log(moment(this.navDate).format('DD/MM/YYYY'));
    //////console.log(moment().format('DD/MM/YYYY'));

    //////console.log(this.selectDate);
    // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    // if (this.datepipe.transform(this.currentDate, 'MMM') == this.datepipe.transform(firstDay, 'MMM')) {
    if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
      // this.startDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
      // this.selectDate = +this.datepipe.transform(this.currentDate, 'dd');
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
      this.selectDate = moment(this.currentDate).format('DD');
    } else {
      // this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
      this.startDate = moment(firstDay).format('YYYY-MM-DD');
    }
    // this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    // this.selectedDate = this.datepipe.transform(this.dateFromNum(num, this.navDate), 'dd MMMM');
    this.selectedDate = moment(this.dateFromNum(num, this.navDate)).format('dd MMM');
    if (!monthData) {
      this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);      //////console.log(monthData);
    }
  }
 /* Name:srinivas
    use: returns date from number. */
  dateFromNum(num: number, referenceDate: any): any {
    let returnDate = moment(referenceDate);
    return returnDate.date(num);
  }

   /* Name:srinivas
    use: returns selected day. */

  selectDay(day: any) {
    //console.log(day);
    this.currentSelectedDate = day.date;
    //////console.log(this.currentSelectedDate, this.selectedDate);
    let momentObj;
    let momentString;
    if (day.dateFormat == 'dd-MM-yyyy') {
      momentObj = moment(day.date, 'DD-MM-YYYY');
      momentString = momentObj.format('DD MMM'); // 2016-07-15

    } else if (day.dateFormat == 'MM-dd-yyyy') {
      momentObj = moment(day.date, 'MM-DD-YYYY');
      momentString = momentObj.format('DD MMM'); // 2016-07-15

    } else {
      momentObj = moment(day.date, 'YYYY-MM-DD');
      momentString = moment(day.date, 'YYYY-MM-DD').format('DD MMM');

    }

    this.selectedDate = momentString;
    // if (day.currentDate == this.selectedDate && obj.eventData.length > 0) {
      this.eligibleData = [];
      if(day.eventData.length > 0) {
        this.eligibleData.push({ key: day.currentDate, color: day.dataPercent, iscurrent: true, eventData: day.eventData });
      }
    // }
    //////console.log(this.eligibleData);
  }
 /* Name:srinivas
    use: create empty table. */
  createBlankGrid() {
    this.gridArrNew = [];
    this.gridArr = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {

        obj.value = i - initialEmptyCells + 1;
        obj.available = true;
        obj.highlight = '';
        obj.date = dateToCheck;
      }
      this.gridArr.push(obj);
    }
    this.gridArrNew = this.gridArr;
    //////console.log(this.gridArrNew);

    // this.service.common.progressLoader = false;

  }
   /* Name:srinivas
    use: .get the status. */

  getStatus(status) {
    this.gridArr = [];
    // this.selectDate = +this.datepipe.transform(new Date(), 'dd');
    this.selectDate = moment().format('DD');
    // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    // if (this.datepipe.transform(this.currentDate, 'MMM') == this.datepipe.transform(firstDay, 'MMM')) {
    if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
      // this.startDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    } else {
      // this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
      this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    }
    // this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    //this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
  }

   /* Name:srinivas
    use: assign worker by job code. */
  appointmentStatus(status, jobcode) {
    //////console.log(this.startDate, this.endDate);
    const data = {
      'workerCode': this.service.session.get('userCode')
    }
    if (status == 'accept') {

      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'Are you sure you want to accept the appointment?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          try {
            
         
          this.appoinmentService.assignToWorkerAcceptedByJobDurationCode(jobcode, data, (res) => {
            if (res.responsecode == 200) {
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          })
         } catch (error) {
            
          }
        }
      });
    } else {
      const dialogRef = this.dialog.open(RejectAppointmentComponent, {
        width: '300px',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        //////console.log('The dialog was closed');
        if (result != undefined) {
          const data = {
            'comment': result,
            'workerCode': this.service.session.get('userCode')
          }
          try {
            
         
          this.appoinmentService.assignToWorkerrejectedByJobDurationCode(jobcode, data, (res) => {
            if (res.responsecode == 200) {
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          })
         } catch (error) {
            
          }
        }
      });

    }
  }
 /* Name:srinivas
    use: show icon popup. */

  getIconPopup(appointmentsDurationCode){
    if (this.showPopup == appointmentsDurationCode) {
      this.showPopup = '';
    } else {
      this.showPopup = appointmentsDurationCode;
    }
  }

 /* Name:srinivas
    use: returns procedure details in popUp.. */
  getProcedureDetails(procedure,notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: {procedure: procedure, notes: notes}
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    })
  }
 /* Name:srinivas
    use: returns Note details in popUp. */
  getNotes(note) {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      width: '450px',
      data: note
    });
  }
   /* Name:srinivas
    use: filter technician by using popup. */

  filterTech() {

    const dialogRef = this.dialog.open(ElegibleAppointmentFilterComponent, {
      width: '550px',
      disableClose: true,
      data: { data: this.procode }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log(result);
      if(result != undefined){
        this.procode = result;
        this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);
      }
    })
  }

  handleAddressChange(address) {
    ////console.log(address);
    ////console.log(address.geometry.location.lat());
    ////console.log(address.geometry.location.lng());
    //////console.log(this.lat, this.lng);
    //this.autocomplete=address;
    this.serachFilter.controls['address'].setValue(address.formatted_address);
    // setTimeout(()=>{
    // this.updateServiceAddress(address);
    // this.addressValidation(this.serachFilter.controls['address']);
    // },2000);
  }

   /* Name:srinivas
    use: validation for google address. */
  
  addressValidation(control: AbstractControl): Promise<any> | Observable<any> {
    const addressValidation = new Promise<any>(
      (resolve, reject) => {
        let addr = control.value;
        // Get geocoder instance
        let geocoder = new google.maps.Geocoder();
        // Geocode the address
        // ////console.log(control.value);
        geocoder.geocode({ 'address': addr }, function (results, status) {
          ////console.log(results);
          ////console.log(status);
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            ////console.log(addr, results[0].formatted_address)
            if (addr === results[0].formatted_address) {
              resolve(null);

            } else {
              resolve({
                invalid: true
              });
            }
            // show an error if it's not
          } else {
            //////console.log('invalid');
            resolve({
              invalid: true
            });
          }
        });
      });
    return addressValidation;
  }
   /* Name:srinivas
    use: filter for appoinments. */
  onSearch(){
    if(this.serachFilter.valid){
      this.submitted = true;
      if(this.serachFilter.value.address){
        this.location =  this.serachFilter.value.address;
      }
      this.miles =  this.serachFilter.value.miles;
      this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);
    } else {
      this.submitted = false;
    }
  }
 /* Name:srinivas
    use: reset page. */
  resetFilter(){
    this.procode = 'all';
    this.location = 'all';
    this.miles = 0;
    this.serachFilter.reset();
    this.resetForm = true;
    setTimeout(()=>{
      this.resetForm = false;
    },100)
    this.getElegibleAppointment(this.service.session.get('userCode'), this.startDate, this.endDate, this.procode, this.location,  this.miles);
  }
}
