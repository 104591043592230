import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CheckOutMissedService } from './check-out-missed-list.service';
import { SessionStorageService } from 'angular-web-storage';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/common.service';
import { IBtnControlsPermission, missed_clock_in_out_inputs } from 'src/app/shared/interface/core.interface';
import { debounce, debounceTime, Subject, takeUntil } from 'rxjs';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { ClockedOutPopUp } from '../clocked-out-pop-up/clocked-out-pop-up.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
// import { H } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-check-out-missed-list',
  templateUrl: './check-out-missed-list.component.html',
  styleUrls: ['./check-out-missed-list.component.scss'],
  providers: [CheckOutMissedService]
})
export class CheckOutMissedListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, {
     static: true
  }) matSort: MatSort;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['workerName', 'jobType', 'siteName', 'shift', 'clockinTime', 'lastKnownTime', 'action'];
  page: number = 0;
  limit: number = 30;
  isLoadingData: boolean = false;
  private dialogref: any

  checkoutTime: FormControl = new FormControl(null, [Validators.required]);
  submitted: boolean = false;
  controlPermission: IBtnControlsPermission;

  input_search_value: string = "";
  private input_search_obs = new Subject();
  private clear_obs = new Subject();
  img_serverPath: string = '';

  clock_out_min_date = moment().toDate();

  @ViewChild('update_clock_in_out_time') update_clock_in_out_time: TemplateRef < any > ;

  missed_clock_in_out_inputs: missed_clock_in_out_inputs;

  constructor(
     private _checkOutMissedSVC: CheckOutMissedService,
     private _sessionStorageSVC: SessionStorageService,
     private dialog: MatDialog,
     private _commonSVC: CommonService,
     private _apiSVC: ApiRootService) {}


  ngOnInit(): void {
     this.img_serverPath = this._apiSVC.getSuperAdmin() + '/downloadFile/';
     this.getMissedClockOutList();
     this.controlPermission = this._commonSVC.getBtnEventPermissions('Time Card');
     this.subscribe_input_observable();
  }

  getMissedClockOutList(isTBL_Scroll ? : string) {

     this.isLoadingData = true;
     this._checkOutMissedSVC.getMissedClockOutListByPage(this._sessionStorageSVC.get('orgCode'), this.input_search_value, this.page, this.limit).subscribe((res: any) => {

        this.isLoadingData = false;

        if (res.responsecode == 200) {

           if (isTBL_Scroll) {
              let temp_array = this.dataSource.data;
              res.data.forEach(element => {
                 element.isExpanded = true;
                 temp_array.push(element);
              });
              this.dataSource = new MatTableDataSource(temp_array);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.matSort;
              console.log('List_Data_1: ', this.dataSource.data.length);
           } else {

              res.data.forEach(element => {
                 element.isExpanded = true;
              });
              this.dataSource = new MatTableDataSource(res.data);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.matSort;
           }

        }
     })
  }

  onScroll() {
     this.page = (this.page + 1);
     this.getMissedClockOutList('scroll');
  }

  closePopup() {
     this._commonSVC.resetNotesRequestForm();
     this.dialogref.close();
     this.checkoutTime.reset();
     this.submitted = false;
  }

  openClockOutPopUp(data: any) {
     const dialogRef = this.dialog.open(ClockedOutPopUp, {
        disableClose: true,
        width: '700px',
        panelClass : 'popup-pos',
        data: data
     });

     dialogRef.afterClosed().subscribe(result => {
        console.log('(ClockedOutPopUpSuccess:', result);
        if (result != undefined && result) {
           this.page = 0;
           this.dataSource = new MatTableDataSource();
           // Apply the sorting accessor for case-insensitive sorting
           this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
           this.dataSource.sort = this.matSort;
           this.getMissedClockOutList();
        }
     });
  }

  edit_time_card(element: any) {

   let staffObj = {
      name : element.workerName,
      workerLogo : element.workerLogo,
      staffCode : element.workerCode,
      email : '',
      mobileNo : ''
     }

     this.missed_clock_in_out_inputs = {
        appointmentsDurationCode: element.appointmentsDurationCode,
        clinicianCheckinDetilsCode: element.clinicianCheckinDetilsCode,
        jobDate: element.jobDate,
        jobFormatedDate: element.jobFormatedDate,
        workerCode: element.workerCode,
        shift_start_time: element.startDateTimeFixedFormat,
        // shift_end_time : element.endTimeOnly
        staff_details_obj : staffObj

     }

     // console.log("missed_time_card_inputs", this.missed_clock_in_out_inputs);

     this.dialogref = this.dialog.open(this.update_clock_in_out_time, {
        width: '700px',
        disableClose: true,
        panelClass: 'popup-pos'
     });

     this.dialogref.afterClosed().subscribe(result => {
        this.reset_clock_in_inputs();
     });

  }

  reset_clock_in_inputs() {
     this.missed_clock_in_out_inputs = {
        appointmentsDurationCode: null,
        clinicianCheckinDetilsCode: null,
        jobDate: '',
        jobFormatedDate: '',
        workerCode: '',
        shift_start_time: '',
        // shift_end_time : ''

     };
  }

//   refreshList(event: any) {
//      this.dialogref.close();
//      this.page = 0;
//      this.getMissedClockOutList();
//   }

 refreshList()
{
  this.dialogref.close();
  this.page = 0 ;
  this.getMissedClockOutList();
}
 

 update_missed_clock_in_out_data(data:any)
 {
 
   let req_body = {

        "appointmentsDurationCode": data.missedClockInOutInputs.appointmentsDurationCode,
        "clinicianCheckinDetilsCode": data.missedClockInOutInputs.clinicianCheckinDetilsCode ? data.missedClockInOutInputs.clinicianCheckinDetilsCode : null,
        "date": data.missedClockInOutInputs.jobFormatedDate ? data.missedClockInOutInputs.jobFormatedDate : moment(data.missedClockInOutInputs.jobDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        // "staffCode": data.missedClockInOutInputs.workerCode,
      
        "staffCode": data.staffCode,

        "breakDetailsReqList": data.formData.breakForm || [],
        "clockinTime": data.formData.clock_in + ':00',
        "clockoutTime": data.formData.clock_out + ':00',

        "comment": data.formData.comment,

        "isAdmin": (this._sessionStorageSVC.get('user_type') !== "Staff" && this._sessionStorageSVC.get('user_type') !== "Customer") ? true : false,
        "organizationCode": this._sessionStorageSVC.get('orgCode'),

        "isApproved": data.isApproved ? true : false,
        "noteRequest": data.noteRequest

      }
 

      this._commonSVC.progressLoader = true;

      this._checkOutMissedSVC.saveOrUpdateAdminActionTimeSheet(req_body).subscribe((res: any) => {
       // console.log("res", res);
        this._commonSVC.progressLoader = false;

        if (res.responsecode === 200) {
          this._commonSVC.successMessage = true;
          this._commonSVC.message = res.message;
         this.refreshList();
        }
        else {
          this._commonSVC.errorMessage = true;
          this._commonSVC.message = res.message;
        }

      })

 }




  applyFilter(search_text: string) {
     if (search_text.trim() || this.input_search_value.trim()) {
        this.input_search_value = search_text.trim();
        this.input_search_obs.next(this.input_search_value.trim());
     }

  }

  subscribe_input_observable() {
     this.input_search_obs.pipe(debounceTime(600), takeUntil(this.clear_obs)).subscribe((res: any) => {
        this.page = 0;
        this.dataSource = new MatTableDataSource();
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.matSort;
        this.getMissedClockOutList();

     })
  }

  ngOnDestroy(): void {
     this.clear_obs.next('');
     this.clear_obs.complete();
  }



  
 staff_absent_Or_NoShow(event:any)
 {
 let confirm_msg:string = '';

 if(event?.staff_details_obj?.name)
 {
  confirm_msg = `Are you sure want to mark staff  "${event?.staff_details_obj?.name}" as ${event.absent_or_norShow_flag}?`;
 }
 else
 {
   confirm_msg = `Are you sure want to mark as ${event.absent_or_norShow_flag}?`;
 }

  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
    width: '400px',
    data: { msg: confirm_msg }
  });

  dialogRef.afterClosed().subscribe(result => {

    if (result && result.toLowerCase() === 'ok') 
      {
       this.changeStaffStatus(event);
    }
  
  });
 }

 changeStaffStatus(data:any)
 {
  const req_body = {
  "absentOrNoShow": data.absent_or_norShow_flag ? data.absent_or_norShow_flag : '',
  "appointmentsDurationCode": data.appointmentsDurationCode,
  "comment" : data?.comment ? data?.comment : ''
  }

 // console.log("changeStaffStatus" , req_body)

  this._commonSVC.progressLoader = true;
  this._checkOutMissedSVC.changeStaffStatus(req_body).subscribe((res:any)=>{
    this._commonSVC.progressLoader = false;

    if (res.responsecode === 200) {
      this._commonSVC.successMessage = true;
      this._commonSVC.message = res.message;
     this.refreshList();
    }
    else {
      this._commonSVC.errorMessage = true;
      this._commonSVC.message = res.message;
    }
  })
 }



}