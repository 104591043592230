<h2 class="d-inline-block mb-0">{{'Technicians.labels.Add provider' | translate}}</h2>
<mat-icon (click)="onClose()" class="float-end cursor-pointer close">close</mat-icon>
<div class="clearfix"></div>
<hr class="mt-0">
<form [formGroup]="providerForm" (ngSubmit)="onSubmit()" class="px-3" *ngIf="providersList.length > 0">
<div class="">
    <div class="">
      <mat-form-field appearance="outline" floatLabel="always"  class="w-100 custom-search">
        <mat-label>{{'Technicians.labels.Provider' | translate}}</mat-label>
          <input type="text" matInput autoClose matInput placeholder="" [matAutocomplete]="auto1" formControlName="providers">
          <mat-icon class="input-icons">search</mat-icon>

        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="providersDisplayFn"
          (optionSelected)="selectedOption($event)">
          <mat-option *ngFor="let option of filteredProviders | async" [value]="option">
            {{option.organizationName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="form-group mb-1 scrollProvider">
      <mat-chip-list>
          <div *ngFor="let org of selectedProviders;let i = index"
            class="w-100 mb-3">
            <mat-chip class="text-capitalize">
              {{org.organizationName}} &nbsp;<a (click)="removeOrg(i)">
                <mat-icon>close</mat-icon>
              </a>
            </mat-chip>
          </div>
        </mat-chip-list>
    </div>
  </div>
  <div class="col p-0">
     <div class="text-center">
      <button mat-raised-button color="primary" [disabled]="selectedProviders.length == 0?'true':'false'" type="submit" (click)="onSubmit()">{{'common.labels.Submit' | translate}}</button>
    </div>
  </div>
</form>
<p *ngIf="providersList.length == 0">{{message}}</p>