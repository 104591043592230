import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { SystemSettingsService } from '../system-settings.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
 
@Component({
  selector: 'app-general-sys-setting',
  templateUrl: './general-sys-setting.component.html',
  styleUrls: ['./general-sys-setting.component.scss'],
  providers: [SystemSettingsService]
})
export class GeneralSysSettingComponent implements OnInit, OnDestroy {

  GeneralSetting_Form: FormGroup;
  isForm_Submitted: boolean = false;

  dateformat = [
    { format: 'MM-DD-YYYY (02-25-2019)', value: 'MM-dd-yyyy' },
    { format: 'DD-MM-YYYY (18-03-2019)', value: 'dd-MM-yyyy' },
    { format: 'YYYY-MM-DD (2019-12-27)', value: 'yyyy-MM-dd' },
  ]

  selectedTimezone: any[] = [];
  timezone: any[] = [];
  driveTimeShow: boolean = true;
  allActivityList: any = [];
  hasPermission:IBtnControlsPermission ;
  isLoading : boolean = false;
  private previousSavedTimeZone:string = null;
  private clearObs = new Subject<any>();

  radius_unit_label : string = "Meters" ;

  constructor(
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    private _commonSVC: CommonService,
    public _systemSettingsSVC: SystemSettingsService,
    private dialog: MatDialog ,
  ) {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
    this.initNotificationSetting_Form();
  }

  ngOnInit(): void {
    this.getTimeZone();
    this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
    this.hasPermission =  this._commonSVC.getBtnEventPermissions("General Settings"); 
    this.subToPermissionChanged();
  }


  initNotificationSetting_Form() {
    this.GeneralSetting_Form = new FormGroup({

      'scheduling_mode_auto': new FormControl(null),
      'scheduling_mode_distribution_list': new FormControl(null),
      'scheduling_mode_self_signup': new FormControl(null),
      'distribution_list_auto': new FormControl(null),
      'scheduling_mode_manual': new FormControl(null),
      'distribution_list_userdefine': new FormControl(null),
      'distribution_list_skill_based': new FormControl(null),
      'distribution_list_location_based': new FormControl(null),
      'driveTime': new FormControl(null),
      'dateformat': new FormControl(null, Validators.required),
      'timeformat': new FormControl(null, Validators.required),
      'timezone': new FormControl(null, Validators.required),
      'distance_tolerance': new FormControl(null, [Validators.required, Validators.pattern(this._commonSVC.numberDecimal)]),
      'geoFenceClockIn': new FormControl(false),
      'button_show_to_schedule_CheckIn': new FormControl(false),
      'allow_multiple_clock_ins': new FormControl(false),
      'ignore_user_work_preference': new FormControl(false),
      'enable_notifications_for_shift_swapping_based_on_skill': new FormControl(false),
      'enable_notifications_for_shift_swapping_based_on_distribution_list': new FormControl(false),
      'notifyStaffRadius': new FormControl('', [Validators.pattern(this._commonSVC.numberDecimal)]),
      'clockInbefore': new FormControl('', [Validators.pattern(this._commonSVC.numberDecimal)]),
      'ClockOutAlertTime': new FormControl('', [Validators.pattern(this._commonSVC.numberDecimal)]),
      'ClockOutGeofenceRadius': new FormControl('', [Validators.required,Validators.pattern(this._commonSVC.numberDecimal)]),
      'allActivity': new FormControl(''),
      'calendarView': new FormControl(''),
      'startOfWeek': new FormControl(''),
      'UserCanNotRejectShift': new FormControl(''),
      // 'UserCanNotUnClaimShift': new FormControl(''),
      'MaxWorkHoursPerUserPerWeek': new FormControl(''),
      'MaxNumberOfShiftsPerUserPerWeek': new FormControl(''),
      'MaxWorkHoursPerUserPerDay': new FormControl(''),
      'MaxNumberOfShiftsPerUserPerDay': new FormControl(''),
      'RatePerHour': new FormControl(''),
      'Employees_Site_Supervisor': new FormControl(),
      'autoJobApproval': new FormControl(false),
      'note_enable_for_supervisor' : new FormControl(false),
      'radius_unit' : new FormControl(),
      'clockin_mode' : new FormControl(''),

    },this.validateGeoFence.bind(this));

   

  }


  validateGeoFence(control: FormControl): Promise<any> | Observable<any> {


    if (!control.get("distance_tolerance").value && !control.get("ClockOutGeofenceRadius").value) {
      return
   }

   if (control.get("distance_tolerance").value && control.get("ClockOutGeofenceRadius").value) 
     {
     
      // if (isNaN(parseFloat(control.get("ClockOutGeofenceRadius").value))) {
      //   control.get("ClockOutGeofenceRadius").setErrors({ notANumber: true });
      //   return ;
      // }
      // else {
      //   control.get("ClockOutGeofenceRadius").setErrors(null);
      // }

      if (!/^[1-9][0-9]*$/.test(control.get("ClockOutGeofenceRadius").value)) {
        control.get("ClockOutGeofenceRadius").setErrors({ notANumber: true });
        return ;
      }
      else {
        control.get("ClockOutGeofenceRadius").setErrors(null);
      }
  
      
     if (parseFloat(control.get("distance_tolerance").value) >= parseFloat(control.get("ClockOutGeofenceRadius").value)) {
       control.get("ClockOutGeofenceRadius").setErrors({ radius: true });
       return;
     }
     else {
       control.get("ClockOutGeofenceRadius").setErrors(null);
     }

   }

    // if (!control.get("distance_tolerance").value?.trim() && !control.get("ClockOutGeofenceRadius").value?.trim()) {
    //   control.get("distance_tolerance").setErrors(null);
    //   control.get("ClockOutGeofenceRadius").setErrors(null);
    //   return
    // }

    // if (control.get("distance_tolerance").value || control.get("ClockOutGeofenceRadius").value) {
    //   control.get("distance_tolerance").setErrors(Validators.required);
    //   control.get("ClockOutGeofenceRadius").setErrors(Validators.required);
    // }

    // if (control.get("distance_tolerance").value?.trim() && control.get("ClockOutGeofenceRadius").value?.trim()) 
    //   {
    //     control.get("distance_tolerance").setErrors(null);
    //     control.get("ClockOutGeofenceRadius").setErrors(null);

    //   if (parseFloat(control.get("distance_tolerance").value) >= parseFloat(control.get("ClockOutGeofenceRadius").value)) {
    //     control.get("ClockOutGeofenceRadius").setErrors({ radius: true })
    //   }
    //   else {
    //     control.get("ClockOutGeofenceRadius").setErrors(null);
    //   }

    // }

  }

  // validateGeoFence(control: FormGroup): { [key: string]: any } | null {
  //   const distanceTolerance = control.get('distance_tolerance');
  //   const clockOutGeofenceRadius = control.get('ClockOutGeofenceRadius');
  
  //   if (!distanceTolerance.value && !clockOutGeofenceRadius.value) {
  //     return null;
  //   }
  
  //   if (distanceTolerance.value && clockOutGeofenceRadius.value) {
  //     const distanceToleranceNum = parseFloat(distanceTolerance.value);
  //     const clockOutGeofenceRadiusNum = parseFloat(clockOutGeofenceRadius.value);
  
  //     if (isNaN(clockOutGeofenceRadiusNum)) {
  //       clockOutGeofenceRadius.setErrors({ notANumber: true });
  //       return { notANumber: true };
  //     }
  
  //     if (distanceToleranceNum >= clockOutGeofenceRadiusNum) {
  //       clockOutGeofenceRadius.setErrors({ radius: true });
  //       return { radius: true };
  //     }
  //   }
  
  //   // Clear any existing errors if validation passes
  //   clockOutGeofenceRadius.setErrors(null);
  //   return null; // No errors
  // }

  getTimeZone() {
    this._commonSVC.getAlltimeZone((data) => {
      //////console.log(data);
      if (data.responsecode == 200) {
        this.timezone = data.data;
        this.selectedTimezone = this.timezone;
      }
    });
  }

  getAllsettings(orgCode) {
    this._commonSVC.progressLoader = true;
    this.isLoading = true ;
    try {
      this._systemSettingsSVC.getAllgetAllLocalSettingWithDefaultGlobalSettingByUserCode(orgCode).subscribe((data: any) => {
        if (data.responsecode == 200) {
          this._systemSettingsSVC.settings = data.data;

          let timeZone = this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === "TimeZone");
          if(timeZone)
            {
              let val = timeZone.localSettingValue ? timeZone.localSettingValue : timeZone.globalSettingValue;
              this.previousSavedTimeZone = val;
              this.GeneralSetting_Form.controls.timezone.setValue(val);
            }

          if (this._systemSettingsSVC.settings[0].AdministratorSetting[28].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[28].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.geoFenceClockIn.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.geoFenceClockIn.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[28].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.geoFenceClockIn.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.geoFenceClockIn.setValue(false);
            }
          }

          if (this._systemSettingsSVC.settings[0].AdministratorSetting[44].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[44].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[44].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(false);
            }
          }

          if (this._systemSettingsSVC.settings[0].AdministratorSetting[49].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[49].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.allow_multiple_clock_ins.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.allow_multiple_clock_ins.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[49].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.allow_multiple_clock_ins.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.allow_multiple_clock_ins.setValue(false);
            }
          }

          if (this._systemSettingsSVC.settings[0].AdministratorSetting[47].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[47].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.ignore_user_work_preference.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.ignore_user_work_preference.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[47].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.ignore_user_work_preference.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.ignore_user_work_preference.setValue(false);
            }
          }

          if (this._systemSettingsSVC.settings[0].AdministratorSetting[45].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[45].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_skill.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_skill.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[45].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_skill.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_skill.setValue(false);
            }
          }

          if (this._systemSettingsSVC.settings[0].AdministratorSetting[46].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[46].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_distribution_list.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_distribution_list.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[46].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_distribution_list.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.enable_notifications_for_shift_swapping_based_on_distribution_list.setValue(false);
            }
          }


          // scheduling mode  scheduling_mode_auto
          let schedulingMode = this._systemSettingsSVC.settings[0].AdministratorSetting[24].localSettingValue != null
            ? this._systemSettingsSVC.settings[0].AdministratorSetting[24].localSettingValue
            : this._systemSettingsSVC.settings[0].AdministratorSetting[24].globalSettingValue;

          this.GeneralSetting_Form.controls.scheduling_mode_auto.setValue(schedulingMode === 'true' ? true : false);


          let scheduling_mode_distribution_list = this._systemSettingsSVC.settings[0].AdministratorSetting[25].localSettingValue != null
            ? this._systemSettingsSVC.settings[0].AdministratorSetting[25].localSettingValue
            : this._systemSettingsSVC.settings[0].AdministratorSetting[25].globalSettingValue;

          this.GeneralSetting_Form.controls.scheduling_mode_distribution_list.setValue(scheduling_mode_distribution_list === 'true' ? true : false);

          /*rate per hour*/
          let ratePerHour = this._systemSettingsSVC.settings[0].AdministratorSetting[43].localSettingValue != null
            ? this._systemSettingsSVC.settings[0].AdministratorSetting[43].localSettingValue
            : this._systemSettingsSVC.settings[0].AdministratorSetting[43].globalSettingValue;

          this.GeneralSetting_Form.controls.RatePerHour.setValue(ratePerHour === 'true' ? true : false);

          /********************************************************************************************************************************************************* */

          // distribution user define
          let distribution_list_userdefine = this._systemSettingsSVC.settings[0].AdministratorSetting[26].localSettingValue != null
            ? this._systemSettingsSVC.settings[0].AdministratorSetting[26].localSettingValue
            : this._systemSettingsSVC.settings[0].AdministratorSetting[26].globalSettingValue;

          this.GeneralSetting_Form.controls.distribution_list_userdefine.setValue(distribution_list_userdefine === 'true' ? true : false);

          /********************************************************************************************************************************************************* */
          this.GeneralSetting_Form.controls['distribution_list_userdefine']?.value === true ? false : this.GeneralSetting_Form.controls['distribution_list_auto']?.value;

          this.GeneralSetting_Form.controls.ClockOutAlertTime.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[33].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[33].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[33].globalSettingValue
          )

          this.GeneralSetting_Form.controls.ClockOutGeofenceRadius.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[34].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[34].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[34].globalSettingValue
          )


          this.setActivityChecked()

          this.GeneralSetting_Form.controls.calendarView.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[31].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[31].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[31].globalSettingValue
          )

          this.GeneralSetting_Form.controls.startOfWeek.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[32].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[32].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[32].globalSettingValue
          )

          /*********************************  Binding user limitation ************************************************************/

          this.GeneralSetting_Form.controls.UserCanNotRejectShift.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[37].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[37].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[37].globalSettingValue
          )

          // this.GeneralSetting_Form.controls.UserCanNotUnClaimShift.setValue(
          //   this._systemSettingsSVC.settings[0].AdministratorSetting[38].localSettingValue
          //     ? this._systemSettingsSVC.settings[0].AdministratorSetting[38].localSettingValue
          //     : this._systemSettingsSVC.settings[0].AdministratorSetting[38].globalSettingValue
          // )

          this.GeneralSetting_Form.controls.clockInbefore.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[30].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[30].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[30].globalSettingValue
          )


          /*********************************  Binding Scheduler Limitations ************************************************************/


          this.GeneralSetting_Form.controls.MaxWorkHoursPerUserPerWeek.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[39].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[39].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[39].globalSettingValue
          )

          this.GeneralSetting_Form.controls.MaxNumberOfShiftsPerUserPerWeek.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[40].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[40].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[40].globalSettingValue
          )

          this.GeneralSetting_Form.controls.MaxWorkHoursPerUserPerDay.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[41].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[41].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[41].globalSettingValue
          )

          this.GeneralSetting_Form.controls.MaxNumberOfShiftsPerUserPerDay.setValue(
            this._systemSettingsSVC.settings[0].AdministratorSetting[42].localSettingValue
              ? this._systemSettingsSVC.settings[0].AdministratorSetting[42].localSettingValue
              : this._systemSettingsSVC.settings[0].AdministratorSetting[42].globalSettingValue
          )


          /********************************************************************************************************************************* */


          // Setting Set for Employees_Site_Supervisor Start 

          
          if (this._systemSettingsSVC.settings[0].AdministratorSetting[48].localSettingValue != null) {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[48].localSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.Employees_Site_Supervisor.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.Employees_Site_Supervisor.setValue(false);
            }

          }
          else {
            let val = this._systemSettingsSVC.settings[0].AdministratorSetting[48].globalSettingValue;
            if (val === 'true') {
              this.GeneralSetting_Form.controls.Employees_Site_Supervisor.setValue(true);
            }
            else {
              this.GeneralSetting_Form.controls.Employees_Site_Supervisor.setValue(false);
            }
          }

          // Setting Set for Employees_Site_Supervisor Endl

          // Auto Job Approval For Customers
  
           let approvalVal = this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === "Customer.Auto.Job.Approval");
              if(approvalVal)
                {
                  let val = approvalVal.localSettingValue ? approvalVal.localSettingValue : approvalVal.globalSettingValue;
                  
                  this.GeneralSetting_Form.controls.autoJobApproval.setValue(
                    (val && val === 'true') ? true : false
                  );
                }

         // Note enable for supervisor
           const noteEnable = this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === "Note.On.Mobile");
              if(noteEnable)
                {
                  let val = noteEnable.localSettingValue ? noteEnable.localSettingValue : noteEnable.globalSettingValue;
                  
                  this.GeneralSetting_Form.controls.note_enable_for_supervisor.setValue(
                    (val && val === 'true') ? true : false
                  );
                }
          
              // Radius Unit
         const radiusUnit = this.getValueByKeyName("Radius.Unit") ; 
            if(radiusUnit)
            {
              let val = radiusUnit.localSettingValue ? radiusUnit.localSettingValue : radiusUnit.globalSettingValue;
                this.GeneralSetting_Form.controls.radius_unit.setValue(val);
                this.radius_unit_label = val ;
            }

            // clockin mode
         const clockin_mode = this.getValueByKeyName("Clockin.Mode") ; 
         if(clockin_mode)
         {
           let val = clockin_mode.localSettingValue ? clockin_mode.localSettingValue : clockin_mode.globalSettingValue;
             this.GeneralSetting_Form.controls.clockin_mode.setValue(val);
          }

          this._systemSettingsSVC.settings.forEach(element => {
            // //////console.log(element);
            if (element.AdministratorSetting) {
              element.AdministratorSetting.forEach(newelement => {
                newelement.userCode = orgCode;
              });
            }
            // if (element.NotificationSetting) {
            //   element.NotificationSetting.forEach(newelement => {
            //     newelement.userCode = orgCode;
            //   });
            // }
            // if (element.SchedulePreferences) {
            //   element.SchedulePreferences.forEach(newelement => {
            //     newelement.userCode = orgCode;
            //   });
            // }
            // if (element.UserPreferences) {
            //   element.UserPreferences.forEach(newelement => {
            //     newelement.userCode = orgCode;
            //   });
            // }
          });



          this._commonSVC.progressLoader = false;
          this.isLoading = false ;
        }

        else {
          this._commonSVC.progressLoader = false;
          this.isLoading = false ;
        }
      })
    } catch (error) {
      this._commonSVC.progressLoader = false;
      this.isLoading = false ;
      //console.log(error)
    }

  }



  changeFull(name, event) {
    // if (name == 'full_manualassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['full_autoassign'].setValue(false)
    // } else if (name == 'full_autoassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['full_manualassign'].setValue(false)
    // }

    // if (name == 'full_manualassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['full_autoassign'].setValue(true)
    // } else if (name == 'full_autoassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['full_manualassign'].setValue(true)
    // }

    // if (name == 'full_manualassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['full_autoassign'].setValue(false)
    // } else if (name == 'full_autoassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['full_manualassign'].setValue(false)
    // }

    // if (name == 'full_manualassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['full_autoassign'].setValue(true)
    // } else if (name == 'full_autoassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['full_manualassign'].setValue(true)
    // }

    // if (name == 'per_manualassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['per_autoassign'].setValue(false)
    // } else if (name == 'per_autoassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['per_manualassign'].setValue(false)
    // }

    // if (name == 'per_manualassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['per_autoassign'].setValue(true)
    // } else if (name == 'per_autoassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['per_manualassign'].setValue(true)
    // }

    // if (name == 'contract_manualassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['contract_autoassign'].setValue(false)
    // } else if (name == 'contract_autoassign' && event.checked == true) {
    //   this.GeneralSetting_Form.controls['contract_manualassign'].setValue(false)
    // }

    // if (name == 'contract_manualassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['contract_autoassign'].setValue(true)
    // } else if (name == 'contract_autoassign' && event.checked == false) {
    //   this.GeneralSetting_Form.controls['contract_manualassign'].setValue(true)
    // }
  }


  search(query) {
    // //////console.log('query', query);
    let result = this.select(query)
    this.selectedTimezone = result;
  }

  select(query) {
    let result = [];
    for (let a of this.timezone) {
      if (a.timeZoneName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result;
  }




  setActivityChecked() {
    this.allActivityList = [];

    let allact: any = this._systemSettingsSVC.settings[0].AdministratorSetting[35].globalSettingValue.split(",") || [];

    let selectedActivity = this._systemSettingsSVC.settings[0].AdministratorSetting[36].localSettingValue
      ? this._systemSettingsSVC.settings[0].AdministratorSetting[36].localSettingValue.split(",")
      : this._systemSettingsSVC.settings[0].AdministratorSetting[36].globalSettingValue.split(",");

    if (allact.length > 0) {
      allact.forEach(ele => {
        let a = {
          activityName: ele,
          isChecked: selectedActivity.indexOf(ele) > -1
        };

        this.allActivityList.push(a);
      })
    }

    this.GeneralSetting_Form.controls.allActivity.setValue(selectedActivity);

  }


  onChangeDemo(event, i) {
    const item = this.allActivityList[i];
    let selectedActivity = this.GeneralSetting_Form.controls.allActivity.value || [];

    if (event.checked) {
      selectedActivity.push(item?.activityName)
    }
    else {

      const idx = selectedActivity.findIndex(element => element === item?.activityName)

      if (idx != -1) {
        selectedActivity.splice(idx, 1);
      }
    }

    this.GeneralSetting_Form.controls.allActivity.setValue(selectedActivity);

  }

  gerFormatedActivity() {
    return this.GeneralSetting_Form.controls.allActivity.value.toString();
  }


  updateGeneralSetting() {
    this.isForm_Submitted = true;

    if (this.GeneralSetting_Form.valid) 
      {
        this.isLoading = true ;

      // this._systemSettingsSVC.settings[0].AdministratorSetting[0].localSettingValue = this.GeneralSetting_Form.value.full_manualassign;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[1].localSettingValue = this.GeneralSetting_Form.value.full_autoassign;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[2].localSettingValue = this.GeneralSetting_Form.value.per_autoassign;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[3].localSettingValue = this.GeneralSetting_Form.value.per_manualassign;

      // this._systemSettingsSVC.settings[0].AdministratorSetting[4].localSettingValue = this.GeneralSetting_Form.value.contract_manualassign;
      //  this._systemSettingsSVC.settings[0].AdministratorSetting[5].localSettingValue = this.GeneralSetting_Form.value.contract_autoassign;

      // this._systemSettingsSVC.settings[0].AdministratorSetting[9].localSettingValue = this.GeneralSetting_Form.value.fullTimeDailyHours;
      //  this._systemSettingsSVC.settings[0].AdministratorSetting[10].localSettingValue = this.GeneralSetting_Form.value.fullTimeWeeklyHours;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[11].localSettingValue = this.GeneralSetting_Form.value.perTimeDailyHours;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[12].localSettingValue = this.GeneralSetting_Form.value.perTimeWeeklyHours;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[13].localSettingValue = this.GeneralSetting_Form.value.contractTimeDailyHours;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[14].localSettingValue = this.GeneralSetting_Form.value.contractWeeklyHours;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[15].localSettingValue = this.GeneralSetting_Form.value.fullTimeDailyAppointment;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[16].localSettingValue = this.GeneralSetting_Form.value.fullTimeWeeklyAppointment;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[17].localSettingValue = this.GeneralSetting_Form.value.perTimeDailyAppointment;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[18].localSettingValue = this.GeneralSetting_Form.value.perTimeWeeklyAppointment;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[19].localSettingValue = this.GeneralSetting_Form.value.contractTimeDailyAppointment;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[20].localSettingValue = this.GeneralSetting_Form.value.contractTimeWeeklyAppointment;

      this._systemSettingsSVC.settings[0].AdministratorSetting[6].localSettingValue = this.GeneralSetting_Form.value.dateformat;
      this._systemSettingsSVC.settings[0].AdministratorSetting[7].localSettingValue = this.GeneralSetting_Form.value.timeformat;
      this._systemSettingsSVC.settings[0].AdministratorSetting[8].localSettingValue = this.GeneralSetting_Form.value.timezone;


      this._systemSettingsSVC.settings[0].AdministratorSetting[21].localSettingValue = this.GeneralSetting_Form.value.driveTime;
      this._systemSettingsSVC.settings[0].AdministratorSetting[22].localSettingValue = this.GeneralSetting_Form.value.mileage;
      this._systemSettingsSVC.settings[0].AdministratorSetting[23].localSettingValue = this.GeneralSetting_Form.value.distance_tolerance;

      this._systemSettingsSVC.settings[0].AdministratorSetting[28].localSettingValue = this.GeneralSetting_Form.value.geoFenceClockIn;
      this._systemSettingsSVC.settings[0].AdministratorSetting[29].localSettingValue = this.GeneralSetting_Form.value.notifyStaffRadius;

      this._systemSettingsSVC.settings[0].AdministratorSetting[48].localSettingValue = this.GeneralSetting_Form.value.Employees_Site_Supervisor;
     
      this._systemSettingsSVC.settings[0].AdministratorSetting[53].localSettingValue = this.GeneralSetting_Form.value.autoJobApproval;
       
      this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === "Note.On.Mobile").localSettingValue = this.GeneralSetting_Form.value.note_enable_for_supervisor;
      
      /************************************************************************************************************************************************* */
      this._systemSettingsSVC.settings[0].AdministratorSetting[24].localSettingValue = this.GeneralSetting_Form.value.scheduling_mode_auto;
      this._systemSettingsSVC.settings[0].AdministratorSetting[25].localSettingValue = this.GeneralSetting_Form.value.scheduling_mode_distribution_list;
      this._systemSettingsSVC.settings[0].AdministratorSetting[26].localSettingValue = this.GeneralSetting_Form.value.distribution_list_userdefine;

      this._systemSettingsSVC.settings[0].AdministratorSetting[33].localSettingValue = this.GeneralSetting_Form.value.ClockOutAlertTime;
      this._systemSettingsSVC.settings[0].AdministratorSetting[34].localSettingValue = this.GeneralSetting_Form.value.ClockOutGeofenceRadius;
      this._systemSettingsSVC.settings[0].AdministratorSetting[36].localSettingValue = this.gerFormatedActivity();

      this._systemSettingsSVC.settings[0].AdministratorSetting[31].localSettingValue = this.GeneralSetting_Form.controls.calendarView.value;
      this._systemSettingsSVC.settings[0].AdministratorSetting[32].localSettingValue = this.GeneralSetting_Form.controls.startOfWeek.value;

      /**************************************************User Limitation*************************************************************** */

      this._systemSettingsSVC.settings[0].AdministratorSetting[30].localSettingValue = this.GeneralSetting_Form.value.clockInbefore;
      this._systemSettingsSVC.settings[0].AdministratorSetting[37].localSettingValue = this.GeneralSetting_Form.value.UserCanNotRejectShift;
      // this._systemSettingsSVC.settings[0].AdministratorSetting[38].localSettingValue = this.GeneralSetting_Form.value.UserCanNotUnClaimShift;

      /***************************************************Scheduler Limitations*********************************************************** */
      this._systemSettingsSVC.settings[0].AdministratorSetting[39].localSettingValue = this.GeneralSetting_Form.value.MaxWorkHoursPerUserPerWeek;
      this._systemSettingsSVC.settings[0].AdministratorSetting[40].localSettingValue = this.GeneralSetting_Form.value.MaxNumberOfShiftsPerUserPerWeek;
      this._systemSettingsSVC.settings[0].AdministratorSetting[41].localSettingValue = this.GeneralSetting_Form.value.MaxWorkHoursPerUserPerDay;
      this._systemSettingsSVC.settings[0].AdministratorSetting[42].localSettingValue = this.GeneralSetting_Form.value.MaxNumberOfShiftsPerUserPerDay;

      /***************************************************Rate Per Hour************************************************************************************* */
      this._systemSettingsSVC.settings[0].AdministratorSetting[43].localSettingValue = this.GeneralSetting_Form.value.RatePerHour;

      /*************************************************************************************************************************************8 */
      this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === "Radius.Unit").localSettingValue = this.GeneralSetting_Form.value.radius_unit;
      this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === "Clockin.Mode").localSettingValue = this.GeneralSetting_Form.value.clockin_mode;
      
      let updateSettings: any[] = [];
      this._systemSettingsSVC.settings.forEach(element => {
        // //////console.log(element);
        if (element.AdministratorSetting) {
          element.AdministratorSetting.forEach(newelement => {
            updateSettings.push(newelement);
          });
        }
      
      });
 

      try {
        this._systemSettingsSVC.updateSettingByUserCode(this._sessionStorageSVC.get('orgCode'), updateSettings, (res) => {
          //////console.log(res);
          this.isLoading = false ;
          
          if (res.responsecode == 200) {
            this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
            this._commonSVC.successMessage = true;
            this.getAllsettings(this._sessionStorageSVC.get('orgCode'));

            if(this.previousSavedTimeZone != this.GeneralSetting_Form.controls.timezone.value)
              {
                 this._commonSVC.timeZone_Sub.next('')
              }

            //  this._commonSVC.getColors();
          } else {
            this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
            this._commonSVC.errorMessage = true;
          }
        })
      } catch (error) {
        //console.log(error)
      }
    }

  }

  addCheckInButtonForSchedule(setting: any, event){    
    // console.log('addCheckInButtonForSchedule: ', setting, event.checked);
    let confirmMSG = "Are you sure want to add a Clock-In button to the user's schedule?" ;

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {
      
         if(setting && result && result.toLowerCase() === 'ok'){
              let data= {
                id: setting.id,
                localSettingCode: setting.localSettingCode,
                localSettingValue: event.checked ? 'true' : 'false',
                globalSettingCode: setting.globalSettingCode,
                userCode: setting.userCode,
                globalSettingName: setting.globalSettingName,
                globalSettingValue: setting.globalSettingValue,
                settingType: setting.settingType
            }
              // console.log('addCheckInButtonForScheduleReq: ', data);
              // return;
              try {
                this._commonSVC.progressLoader = true;
                this._systemSettingsSVC.updateSingleSettingByOrgCode(this._sessionStorageSVC.get('orgCode'), data, (res) => {
                  this._commonSVC.progressLoader = false;
                  //console.log(res);
                  if (res.responsecode == 200) {
                    this._commonSVC.message = res.message;
                    this._commonSVC.successMessage = true;
                    this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
                    //  this._commonSVC.getColors();
                  } else {
                    this._commonSVC.message = res.message;
                    this._commonSVC.errorMessage = true;
                    this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(event.checked ? false : true);
                  }
                })
              } catch (error) {
                this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(event.checked ? false : true);
                //console.log(error)
              }
         } else {
          this.GeneralSetting_Form.controls.button_show_to_schedule_CheckIn.setValue(event.checked ? false : true);
         }

    });
  }

  swapAllowBasedOnSkillChange(setting: any, event){ 
    setting.localSettingValue = event.checked ? 'true' : 'false'
    return setting;
  }

  swappingBasedOn(selectedSetting: any, value: any){
    if(selectedSetting && value){
      selectedSetting.localSettingValue = value;
      return selectedSetting;
    }
  }

  // Vivek Chauhan - common radio toggle input change value update in setting
  switchRadioToggle(selectedSetting: any, event){
    // console.log('switchRadioToggle: ', selectedSetting, event.checked);
    if(selectedSetting && event){
      selectedSetting.localSettingValue = event.checked ? 'true' : 'false';
      return selectedSetting;
    }
  }


  subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this._commonSVC.getBtnEventPermissions("General Settings");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }


  getValueByKeyName(keyName:string)
  {
    return  this._systemSettingsSVC.settings[0].AdministratorSetting.find( ele => ele.globalSettingName === keyName);
   }

  

}
