import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(public service: ApiRootService,
    public common: CommonService,
    public http: HttpClient,
    public local: SessionStorageService) { }


    deptData = {
      "departmentLogo": "",
      "departmentName": "",
      "isActive": true,
      "organizationCode": "",
      "parentid": 0
    }

   /* Name:Shailesh
  use: for getting all department*/
  getDepartmentMasterByOrgCodeByPage(code, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getDepartmentMasterByOrgCodeByPage/' + code + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
  use: for getting department list by org code*/
  getDepartmentMasterByOrganizationCode(code, callback){
    return this.http.get(this.service.getHealthCareUrl() + '/getDepartmentMasterByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
  use: for getting department for parent by org code*/
  getDepartmentForParentSelectByOrganizationCode(code, callback){
    return this.http.get(this.service.getHealthCareUrl() + '/getDepartmentForParentSelectByOrganizationCode/' + code).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
  use: for adding department */
  saveDepartmentMaster(formData, callback) {
    return this.http.post(this.service.getHealthCareUrl() + '/saveDepartmentMaster', formData).subscribe((data) => {
        callback(data);
    });
  }

   /* Name:Shailesh
  use: for updating department*/
  updateDepartmentMaster(id,formData,callback) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateDepartmentMaster/' + id, formData).subscribe((data) => {
        callback(data);
    })
  }

}
