<div class="row">
  <div class="col-md-12">
    <h6 class="mb-0 title-pd">{{'dashboard.labels.Actual Schedule Time' | translate }}</h6>
    <hr class="title">
    <i class="clear-icon cursor-pointer material-icons" (click)="onNoClick()"> clear </i>
  </div>
  <div class="col-md-6">
    <small>{{'dashboard.labels.Clinician Arrival' | translate }}:</small>
    <p>{{data['arrival']}}</p>
  </div>
  <div class="col-md-6">
    <small>{{'dashboard.labels.Clinician Departure' | translate }}:</small>
    <p>{{data['departure']}}</p>
  </div>
</div>
