// error-logging.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ApiRootService } from '../api-root.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  private apiUrl = this.service.APIROOT+'/superadminservice/saveProjectException'; // Replace with your API endpoint

  constructor(private http: HttpClient,    private service: ApiRootService,
    ) {}

  logError(error: any): void {
    const errorInfo = this.getEnhancedErrorInfo(error);
   var payloadbody= {
        "componentName": "string",
        "exceptionDetail":JSON.stringify(errorInfo),
        "execeptionStatusCode": 0,
        "lineNumber": 0,
        "serviceUri": "string"
      }
    // Log the error to the server
    this.http.post(this.apiUrl,payloadbody).pipe(
      catchError((err) => {
        console.error('Error logging failed:', err);
        return throwError(err);
      })
    ).subscribe(() => {
      //console.log('Error logged successfully');
    });
  }

  private getEnhancedErrorInfo(error: any): any {
    const enhancedErrorInfo: any = {
      message: error.message,
      stack: error.stack,
      timestamp: new Date(),
      browserInfo: this.getBrowserInfo(),
      osInfo: this.getOSInfo(),
      // You can include additional information based on your needs
    };

    return enhancedErrorInfo;
  }

  private getBrowserInfo(): string {
    const userAgent = navigator.userAgent;
    return `User Agent: ${userAgent}`;
  }

  private getOSInfo(): string {
    const platform = navigator.platform;
    return `Platform: ${platform}`;
  }
}
