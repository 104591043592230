import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/common.service';
import { AppointmentService } from '../appointment.service';
import { CreateAppointmentService } from '../create-appointment/create-appointment.service';
import { ProvidersDashboardService } from '../../providers-dashboard.service';
import * as moment from 'moment';
import { NewAppointmentService } from 'src/app/dashboards/technician-dashboard/new-appointment/new-appointment.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-allappointment-popup',
  templateUrl: './allappointment-popup.component.html',
  styleUrls: ['./allappointment-popup.component.scss']
})
export class AllappointmentPopupComponent implements OnInit {
  userType: any;
  appointmentStatus = [];
  showPopup;
  searchTerm;
  allFilteredData = [];
  // noPatient= "No Patient Data Found";
  appointmentchange: boolean = false;  // flag when popup closed list should be refreshed
  showImg: any;
  date = new Date();
  disabled = true;
  pending = [];
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<AllappointmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public router: Router, public common: CommonService, public dialog: MatDialog, public service: AppointmentService, public createPatientService: CreateAppointmentService, public dashboardService: ProvidersDashboardService,
    public newAppointmentService: NewAppointmentService, public translate: TranslateService,
    public session: SessionStorageService,
  ) { this.translate.setDefaultLang(this.service.session.get('language')); }

  /* Name:srinivas
      use: return seraching details */
  // search() {
  //   this.service.common.progressLoader = true;
  //   if (this.searchTerm) {
  //     this.appointmentStatus = this.allFilteredData.filter((item) => {
  //       return item.patientName.toLowerCase().includes(this.searchTerm.toLowerCase());
  //     });
  //     this.service.common.progressLoader = false;
  //   } else {
  //     this.service.common.progressLoader = false;
  //     this.appointmentStatus = this.allFilteredData;
  //     // noPatient = this.allFilteredData;
  //     // return noPatient;
  //   }
  // }


  ngOnInit() {
    this.userType = this.session.get('user_type');
    if (this.data && this.data?.isSingleOpen) {
      //console.log('SingleData: ', this.data)
      this.getSingleAppointmentWithCode(this.data);
    } else {
      this.getAppointments();
    }
    ////console.log(this.data);
    ////console.log(this.data['procedure']);

  }
  /* Name:srinivas
        use: return appointments details */
  getAppointments() {
    //this.service.common.progressLoader = true;
    // v3/adminGetAllAppointmentsByOrganizationCodeWithFilterDateTime/2c015c77-073c-403e-95d5-445dfc2b4017/all/2023-10-13%2000:00:00/2023-10-13%2023:59:59/all


    // this.service.getAllJobsByOrganizationCodeWithFilterDateTime(this.service.session.get('orgCode'), 
    // 'all',
    //  this.data['procedure'],
    //   this.data.date + ' ' + this.data.data.startDate.split(' ')[1] + ':00',
    //    this.data.date + ' ' + this.data.data.endDate.split(' ')[1] + ':00',
    //     (data) => {


    this.isLoading = true;
    this.service.getAppoinmentWithDateTime(this.service.session.get('orgCode'),
      'all',
      this.data['procedure'],
      this.data.date + ' ' + this.data.data.startDate.split(' ')[1] + ':00',
      this.data.date + ' ' + this.data.data.endDate.split(' ')[1] + ':00',
      (data) => {

        this.isLoading = false;
        this.showPopup = '';
        if (data.responsecode == 200) {
          //this.common.progressLoader = false;

          this.appointmentStatus = data.data;
          //console.log('GetApponments: ', this.appointmentStatus)
          this.allFilteredData = data.data;

          this.pending = data.data.filter(element => element.status == 'pending').map(element => element.appointmentsDurationCode);

          ////console.log(this.pending);

          if (this.pending.length > 0) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }

          this.allFilteredData.forEach((element) => {
            if (element.actualStartTime) {
              if (element.dateFormat == 'dd-MM-yyyy') {
                element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[0] + '-' + element.actualStartTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
                ////console.log(element.arrivalTimeDate);
              } else if (element.dateFormat == 'MM-dd-yyyy') {
                element.arrivalTimeDate = moment(element.actualStartTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
                ////console.log(element.arrivalTimeDate);
              } else {
                element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[2] + '-' + element.actualStartTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
                ////console.log(element.arrivalTimeDate);
              }
            }
            if (element.actualEndTime) {
              if (element.dateFormat == 'dd-MM-yyyy') {
                element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[0] + '-' + element.actualEndTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
                ////console.log(element.departureTimeDate);
              } else if (element.dateFormat == 'MM-dd-yyyy') {
                element.departureTimeDate = moment(element.actualEndTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
                ////console.log(element.departureTimeDate);
              } else {
                element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[2] + '-' + element.actualEndTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
                ////console.log(element.departureTimeDate);
              }
            }
          })

        } else {
          // this.common.progressLoader = false;
          this.isLoading = false;
          this.appointmentStatus = [];
        }
      });
  }



  convertDateFormat(inputDate) {
    if (inputDate) {
      // Split the input string into date and time parts
      const [datePart, timePart] = inputDate.split(' ');

      // Split the date part into month, day, and year
      const [month, day, year] = datePart.split('-');

      // Split the time part into hours and minutes
      const [time, ampm] = timePart.split(' ');

      // Convert to 24-hour format
      const [hours, minutes] = time.split(':');
      const isAM = ampm === 'AM';
      let hourValue = parseInt(hours, 10);
      if (hourValue === 12) {
        hourValue = isAM ? 0 : 12;
      } else {
        hourValue = isAM ? hourValue : hourValue + 12;
      }

      // Create a Date object with the parsed values
      const convertedDate = new Date(year, month - 1, day, hourValue, parseInt(minutes, 10));

      // Format the date as yyyy-mm-dd
      const yyyy = convertedDate.getFullYear();
      const mm = String(convertedDate.getMonth() + 1).padStart(2, '0');
      const dd = String(convertedDate.getDate()).padStart(2, '0');

      return `${yyyy}-${mm}-${dd}`;
    }
  }




  // /* Name:srinivas
  //        use: show or hide poup up */

  // geteditData(element) {
  //   //////console.log(element);
  //   //////console.log(this.showPopup);
  //   if (this.showPopup == element.appointmentsDurationCode) {
  //     this.showPopup = '';
  //   } else {
  //     this.showPopup = element.appointmentsDurationCode;
  //   }
  //   // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  // }
  // /* Name:srinivas
  //        use: show or hide image */
  // showImage(element) {
  //   // ////console.log(element);
  //   // ////console.log(this.showPopup);
  //   if (this.showImg == element.appointmentsDurationCode) {
  //     this.showImg = '';
  //   } else {
  //     this.showImg = element.appointmentsDurationCode;
  //   }
  //   // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  // }
  // /* Name:srinivas
  //        use: open update appointment popup */

  // route(routeTO, appointmentsDurationCode, jobsCode) {
  //   if (routeTO == 'update-appointment') {
  //     //this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: appointmentsDurationCode } });
  //     const dialogRef = this.dialog.open(UpdateAppointmentComponent, {
  //       width: '70%',
  //       disableClose: true,
  //       data: { jobDurationCode: appointmentsDurationCode }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       //console.log('The dialog was closed');
  //       if (result != undefined) {
  //         this.appointmentchange = true;
  //         this.dialogRef.close(this.appointmentchange);
  //       }
  //     });
  //   } else if (routeTO == 'update-appointment-series') {
  //     //this.router.navigate(['/dashboard/update-appointment-series'], { queryParams: { jobCode: jobsCode } });
  //     const dialogRef = this.dialog.open(UpdateAppointmentSeriesComponent, {
  //       width: '70%',
  //       disableClose: true,
  //       data: { jobCode: jobsCode }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       //console.log('The dialog was closed');
  //       if (result != undefined) {
  //         this.appointmentchange = true;
  //         this.dialogRef.close(this.appointmentchange);
  //       }
  //     });
  //   }
  // }
  // /* Name:srinivas
  //        use: close popup */

  // /* Name:srinivas
  //        use: open assign appointment popup */
  // assignAppointment(jobDurationCode) {
  //   // ////console.log(jobDurationCode)
  //   this.service.common.progressLoader = true;
  //   this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, this.data['currentSelectedDate'], this.data['WeekstartDate'], this.data['weekendDate'], (data) => {
  //     const dialogRef = this.dialog.open(AssignAppointmentComponent, {
  //       width: '750px',
  //       data: { data, jobDurationCode }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       //////console.log(result);
  //       if (result != undefined) {
  //         this.appointmentchange = true;
  //         this.getAppointments();
  //         this.common.message = result;
  //         this.common.successMessage = true;
  //       }
  //     });
  //   });


  // }
  // /* Name:srinivas
  //        use: open trade shift popup */
  // tradeShift(data) {
  //   const dialogRef = this.dialog.open(TradeShiftComponent, {
  //     width: '450px',
  //     data: { data }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       this.appointmentchange = true;
  //       this.getAppointments();
  //       this.service.common.message = result;
  //       this.service.common.successMessage = true;
  //     }
  //   });
  // }
  // /* Name:srinivas
  //        use: open cancel popup */
  // cancelAppointment(jobDurationCode) {
  //   const dialogRef = this.dialog.open(CancelAppointmentComponent, {
  //     width: '450px',
  //     data: { jobDurationCode }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       this.appointmentchange = true;
  //       this.getAppointments();
  //       this.service.common.message = result;
  //       this.service.common.successMessage = true;
  //     }
  //   });
  // }
  // /* Name:srinivas
  //        use: save accept appointment details */
  // acceptAppointment(jobDurationCode, workerCode) {
  //   const data = {
  //     'workerCode': workerCode
  //   }
  //   try {


  //     this.service.assignToWorkerAcceptedByJobDurationCode(jobDurationCode, data, (res) => {
  //       if (res.responsecode == 200) {
  //         this.service.common.message = res.message;
  //         this.service.common.successMessage = true;
  //         this.getAppointments();
  //       } else {
  //         this.service.common.message = res.message;
  //         this.service.common.errorMessage = true;
  //       }
  //     })
  //   } catch (error) {

  //   }
  // }
  // /* Name:srinivas
  //        use: open show root popup */

  // showRoute(data) {
  //   //////console.log(data);
  //   let startdate;
  //   let startDate;

  //   // if (this.currentUrl == 'patient') {
  //   //   startdate = data.startTime.split(' ')[0];
  //   // } else {
  //   startdate = data.appointmentStartDate.split(' ')[0];
  //   // }

  //   if (data.dateFormat == 'MM-dd-yyyy') {
  //     startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'DD-MM-YYYY').format('YYYY-MM-DD');
  //     //////console.log(startDate);
  //   } else if (data.dateFormat == 'dd-MM-yyyy') {
  //     startDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2]), 'DD-MM-YYYY').format('YYYY-MM-DD');
  //     //////console.log(startDate);
  //   } else {
  //     startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format('YYYY-MM-DD');
  //     //////console.log(startDate);
  //   }
  //   try {

  //     this.dashboardService.mobileGetRouteByTechnicianCodeDateAndJobdurationcode(data.workerCode, startDate, data.appointmentsDurationCode, (jobres) => {
  //       if (jobres.responsecode == 200) {

  //         const dialogRef = this.dialog.open(ShowRoutesComponent, {
  //           width: '550px',
  //           height: '575px',
  //           data: { workerCode: data.workerCode, appointmentsDurationCode: data.appointmentsDurationCode, date: data.appointmentStartDate, data: jobres.data }
  //         });

  //         dialogRef.afterClosed().subscribe(result => {
  //           if (result != undefined) {
  //             this.appointmentchange = true;
  //             this.getAppointments();
  //             this.service.common.message = result;
  //             this.service.common.successMessage = true;
  //           }
  //         });
  //         //      //////console.log(jobres);
  //         // this.jobRouteData = jobres.data;
  //         // this.googleMap(this.jobRouteData);
  //       }
  //       // this.service.common.loader = false;
  //     })
  //   } catch (error) {

  //   }

  // }

  // /* Name:srinivas
  //        use: save reject appointmnet detils. */
  // rejectAppointment(jobcode, userCode) {
  //   const dialogRef = this.dialog.open(RejectAppointmentComponent, {
  //     width: '300px',
  //     data: {}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     //////console.log('The dialog was closed');
  //     if (result != undefined) {
  //       const data = {
  //         'comment': result,
  //         'workerCode': userCode
  //       }
  //       try {

  //         this.newAppointmentService.assignToWorkerrejectedByJobDurationCode(jobcode, data, (res) => {
  //           if (res.responsecode == 200) {
  //             this.service.common.message = res.message;
  //             this.service.common.successMessage = true;
  //             this.getAppointments();
  //           } else {
  //             this.service.common.message = res.message;
  //             this.service.common.errorMessage = true;
  //           }
  //         })
  //       } catch (error) {

  //       }
  //     }
  //   });
  // }
  // /* Name:srinivas
  //        use: open orocedure details popup */
  // getProcedureDetails(procedure, notes) {
  //   ////console.log(procedure, notes);

  //   const dialogRef = this.dialog.open(ProcedureDetailComponent, {
  //     width: '650px',
  //     disableClose: true,
  //     data: { procedure: procedure, notes: notes }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     ////console.log('The dialog was closed');
  //     // this.animal = result;
  //   });

  // }
  // /* Name:srinivas
  //        use: open notes popup */
  // getNotes(note) {
  //   const dialogRef = this.dialog.open(NotesPopupComponent, {
  //     width: '450px',
  //     data: note
  //   });
  // }
  // /* Name:srinivas
  //        use: open arrival departure popup */
  // openArrivalDialog(arrival, departure): void {
  //   const dialogRef = this.dialog.open(ArrivalDepartureComponent, {
  //     width: '350px',
  //     data: { arrival: arrival, departure: departure }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     ////console.log('The dialog was closed');
  //     // this.animal = result;
  //   });
  // }
  // /* Name:srinivas
  //        use: return auto assign appintment details*/


  // autoAssign() {

  //   const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
  //     width: '350px',
  //     data: { msg: 'Are you sure you want to auto staff assign?' }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result != undefined) {
  //       try {
  //         const orgCode = this.service.session.get('orgCode');
  //         this.service.common.progressLoader = true;
  //         this.service.common.percentageLoader = true;
  //         try {
  //           this.service.adminAssignJobsByOptaplanner(this.service.session.get('orgCode'), this.data.date, this.data.date, (newData) => {
  //             ////console.log(newData);
  //             if (newData.responsecode == 200) {

  //               this.service.totalRecords = newData.data.totalRecord;
  //               this.service.processedRecords = newData.data.processRecord;
  //               this.service.common.progressText = 'Processing ' + this.service.processedRecords + ' of  ' + this.service.totalRecords + ' appointments';
  //               try {
  //                 this.service.getAppointmentThreadProcessingByCode(newData.data.appointmentsThreadProcessingCode, (response) => {
  //                   let arrayData = [];
  //                   response.data.ewresLst.forEach(element => {
  //                     if (element.workerWithDistanceList.length > 0) {
  //                       arrayData.push(element);
  //                     }
  //                   });
  //                   setTimeout(() => {
  //                     //console.log(arrayData);
  //                     this.service.common.progressLoader = false;
  //                     this.service.common.progressText = '';
  //                     this.service.common.percentageLoader = false;
  //                     this.getAppointments();
  //                     if (arrayData.length == 0) {
  //                       this.getSplitAppointment(response.data.appointmentsDurationCodeList);
  //                     } else {

  //                       this.appointmentchange = true;
  //                       this.openDialog(arrayData);
  //                     }
  //                   }, 2000);
  //                 })
  //               } catch (error) {
  //                 //console.log(error)
  //               }

  //             } else {
  //               this.service.common.progressLoader = false;
  //               this.service.common.message = newData.message;
  //               this.service.common.errorMessage = true;
  //             }


  //           })
  //         } catch (error) {

  //         }
  //       } catch (error) {
  //         //console.log(error)
  //       }

  //     }
  //   });

  // }


  //    /* Name:Shailesh
  //     use: to open split appointment popup */
  //     getSplitAppointment(jobDurationCode){
  //       const dialogRef = this.dialog.open(SplitAppointmentComponent, {
  //         width: '450px',
  //         disableClose: true,
  //         data: { jobDurationCode }
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         //////console.log(result);
  //         if (result != undefined) {
  //           this.appointmentchange = false;
  //           // this.getAppointments();
  //           this.service.common.message = result;
  //           this.service.common.successMessage = true;
  //         }
  //       });
  //     }

  // /* Name:srinivas
  //      use: open assign worker popup */

  // openDialog(workers): void {
  //   const dialogRef = this.dialog.open(AssignedWorkerComponent, {
  //     width: '350px',
  //     disableClose: true,
  //     data: { data: workers, fromdate: this.data.date, toDate: this.data.date, scheduledDate: this.data.date }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     this.getAppointments();
  //     ////console.log('The dialog was closed');
  //   });
  // }



  getSingleAppointmentWithCode(data: any) {
    // this.service.common.progressLoader = true;
    this.isLoading = true;
    let newDate = data?.data?.dateString && this.convertDateFormat(data.data.dateString);
    // //console.log('getSingleAppointmentWithCode: ', data);
    // //console.log('Orgcode: ', this.service.session.get('orgCode'));
    // //console.log('appointmentsCode: ', this.data?.data?.appointmentsCode);
    // //console.log('appointmentsProceduresCode: ', this.data?.data?.jobTypeWithShiftList?.[data.workIdx].appointmentsProceduresCode);

    this.service.getJobsFullDetailsByAppointmentsProceduresCode(this.service.session.get('orgCode'), this.data?.data?.appointmentsCode, this.data?.data?.jobTypeWithShiftList?.[data.workIdx].appointmentsProceduresCode, newDate, (data) => {
      ////console.log(data);
      this.isLoading = false;
      this.showPopup = '';
      if (data.responsecode == 200) {
        // this.common.progressLoader = false;
        // //console.log('GetApponments: ', this.appointmentStatus)
        this.appointmentStatus = data.data;
      } else {
        // this.common.progressLoader = false;
        this.appointmentStatus = [];
      }
    }
    )
  }

  onNoClick(): void {
    this.dialogRef.close(this.appointmentchange);
  }


  RefreshJobList(event: any) {
    this.appointmentchange = true // when popup closed list should be refreshed
    if (this.data && this.data?.isSingleOpen) { this.getSingleAppointmentWithCode(this.data); }
    else { this.getAppointments(); }
  }


}
