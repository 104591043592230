<div
  *ngIf="!chatLogDataLoaded"
  class="mt-4 empty-cards-marshaldeskstaffing"
></div>
<div class="row" *ngIf="chatLogDataLoaded">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="chatLogData">
    <div class="gi-card mb-3 p-3 bg-light-warning font-14 pCardChatLog">
      <div
        class="d-flex align-items-center justify-content-between mb-3"
        
      >
      <div class="float-start header-name">Chats</div>

        <div
          class="d-flex align-items-center justify-content-center"
          style="width: 2.5rem; height: 2.5rem"
        >
          <span class="svg-icon svg-icon-warning svg-icon-2x cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g
                _ngcontent-jbp-c155=""
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <rect
                  _ngcontent-jbp-c155=""
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                ></rect>
                <path
                  _ngcontent-jbp-c155=""
                  d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                  fill="#000000"
                ></path>
                <path
                  _ngcontent-jbp-c155=""
                  d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
              </g>
            </svg>
          </span>
        </div>
      </div>
      <div class="gi-content-scroll">
        <div
          class="card-title-style card-title-chatLog border-top-style"
          *ngIf="chatLogData.length == 0"
        >
          No Records Found.
        </div>
        <div *ngIf="chatLogData.length != 0">
          <div (click)="StopAnimation(chatdata)"
          [@flash]="chatdata.isNew && animState"
          (@flash.done)="animDone($event)"
            *ngFor="let chatdata of chatLogData; let i = index"
            class="p-2 px-3 bg-warning rounded mb-2"
          >
            <div class=""   >
              <div class="d-flex align-items-center">
                <div class="block font-14 font-medium">
                 {{ chatdata.fromName }}
                </div>
                <span class="ms-auto small text-dark"
                  >{{ chatdata.messageTimeStamp | lib_pipe_niceDateFormat }}
                </span>
              </div>
            </div>
            
            <div class="d-flex align-items-center">
              <div class="">
                <ng-template [ngIf]="!chatdata.isImage"> 
                <span class="text-dark">{{ chatdata.message }}</span>
              </ng-template>
              <ng-template [ngIf]="chatdata.isImage"> 
                <img [src]="ImagePath + chatdata.message" alt="chatdata.message">
              </ng-template>
              </div>
              <div
                class="d-flex align-items-center ms-auto"
               >  
             
                <!-- <div  class="cursor-pointer" (click)="showChat()"  > -->
                 
                 <div class="cursor-pointer"  (click)="openIndividualChat(chatdata.from)" > 

                  <span class="svg-icon svg-icon-secondary svg-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        _ngcontent-jbp-c155=""
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect
                          _ngcontent-jbp-c155=""
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        ></rect>
                        <path
                          _ngcontent-jbp-c155=""
                          d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z"
                          fill="#000000"
                        ></path>
                        <path
                          _ngcontent-jbp-c155=""
                          d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                      </g></svg></span>
                </div>

                <div
                  class="text-danger ms-3 cursor-pointer"
                  (click)="onClickDeleteChatLog(chatdata)"
                >
                 <mat-icon>delete</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
 <!-- <ng-container *ngIf="OpenIndividualChat">
  <div  #myModal  id="myModal" class="v_modal">

     
    <div class="v_modal-content">
      <span class="close" (click)="close()">&times;</span>
 
<lib-e-chat [orgCode]="orgCode" [adminCode]="adminCode" [userName]="userName"
[userCode]="userCode" [apiUri]="apiUri"></lib-e-chat>
 
</div>
</div>
</ng-container> -->