<div class="design-setting">
  <div class="row">
    <form [formGroup]="DesignSetting_Form">
      <div class="row">
        <div class="accordian-description">

          <div class="row">
            <div class="col-6 text-start">
              <h4 class="header-name">
                Logo Settings
              </h4>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="">
              <div class="orange-title">
                {{ 'setting.labels.Organization Logo' | translate}}
              </div>

              <ng-template
                [ngIf]="hasPermission.addPermission === true || hasPermission.editPermission === true"
                [ngIfElse]="onlyViewImg">
                <div class="col-md-12 mt-2">
                  <!-- <input type="file" id="orglogo" placeholder="" formControlName="org_logo" (change)="imgUpload($event)"> -->
                  <input type="file" id="orglogo" placeholder="" style="display: none;" (change)="imgUpload($event)">
                  <div class="upload-inner-border d-flex mb-3" onclick="document.getElementById('orglogo').click()">
                    <div class="d-flex align-items-center">
                      <div class="upload-inner-icon me-3">
                        <img
                          [src]="DesignSetting_Form.controls.org_logo.value ?
                                                     _commonSVC.superAdminUrl + DesignSetting_Form.controls.org_logo.value : ''"
                          alt="">
                      </div>
                      <p>{{DesignSetting_Form.controls.org_logo.value ? DesignSetting_Form.controls.org_logo.value :
                        'Choose File'}}</p>
                    </div>
                    <div>
                      <img src="../../../assets/images/plus-icon.svg" alt="">
                    </div>

                  </div>

                  <span class="help-block err mt-3"
                    *ngIf="!DesignSetting_Form.get('org_logo').valid && (is_FormSubmitted || DesignSetting_Form.get('org_logo').touched)">
                    {{ 'setting.validators.Organization Logo is required' | translate}}</span>

                </div>
              </ng-template>

              <ng-template #onlyViewImg>
                <div class="col-md-12 mt-2">
                  <div class="upload-inner-border d-flex mb-3">
                    <div class="d-flex align-items-center">
                      <div class="upload-inner-icon me-3">
                        <img
                          [src]="DesignSetting_Form.controls.org_logo.value ?
                                                     _commonSVC.superAdminUrl + DesignSetting_Form.controls.org_logo.value : ''"
                          alt="">
                      </div>
                      <p>{{DesignSetting_Form.controls.org_logo.value}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>


            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</div>