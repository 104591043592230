import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiRootService {

  APIROOT;
  BASE_URL;
  REGISTRATION;
  REGISTRATION_URL;
  HEALTHCARE_URL;
  SUPERADMIN_URL;
  LOCAL;
  downloadUrl;
  CHATURL: string;
  CAREHUDDLESERVICE;
  NOTIFICATION;
  WSMARSHAL_URL;
  TENNISH_URL:string;
  IS_PRODUCTION: boolean;

  constructor() {
    this.APIROOT = environment.apiUrl;
    this.IS_PRODUCTION = environment.production;
    this.LOCAL = "http://172.20.2.87:8085";
    this.initializeURL();
   }

  initializeURL() {
    this.IS_PRODUCTION = this.IS_PRODUCTION;
    this.BASE_URL = this.APIROOT;
    this.REGISTRATION_URL = this.APIROOT+'/userservice';
    this.HEALTHCARE_URL = this.APIROOT + '/staffingservice';
    // this.HEALTHCARE_URL = this.APIROOT+'/healthcareservice';
    this.SUPERADMIN_URL = this.APIROOT+'/superadminservice';
    // this.CHATURL = this.APIROOT + '/chatingservice';
    this.CHATURL = this.APIROOT + '/chatingservicev2';

    this.downloadUrl = this.SUPERADMIN_URL;
    this.CAREHUDDLESERVICE =  '/staffingservice';
    this.NOTIFICATION = this.APIROOT + '/notificationservice';
    this.WSMARSHAL_URL = this.APIROOT +'/userservice';
    this.TENNISH_URL = this.APIROOT + '/tennistournamentservice';
  }

  getIsProduction() {
    return this.IS_PRODUCTION;
  }
 
  getBaseUrl() {
    return this.BASE_URL;
  }

  getTennishUrl() {
    return this.TENNISH_URL;
  }


  getRegistrationUrl(){
    return this.REGISTRATION_URL;
  }

  getHealthCareUrl(){
    return this.HEALTHCARE_URL;
  }

  getSuperAdmin(){
    return this.SUPERADMIN_URL;
  }
  getChatUrl(){
    return this.CHATURL;
  }
  getCarehudlUrl(){
    return this.CAREHUDDLESERVICE;
  }
  getNotificationService(){
    return this.NOTIFICATION;
  }

  getWsmarshalUrl(){
    return this.WSMARSHAL_URL;
  }
}
