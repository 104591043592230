import { Component, Inject, OnInit, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TechnicianService } from 'src/app/dashboards/providers-dashboard/technician/technician.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AddDistributionInStaff } from 'src/app/components/add-distribution-in-staff/add-distribution-in-staff.component';

@Component({
  selector: 'assigned-distribution-list-for-staff',
  templateUrl: './assigned-distribution-list-for-staff.component.html',
  styleUrls: ['./assigned-distribution-list-for-staff.component.scss']
})
export class AssignedDistributionListForStaff implements OnInit {
  @Input() workerCode: any;
  @Input() listOpenFrom: any;
  listData: any = [];
  displayedColumns: string[] = ['distributionName'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;

  constructor(
    private dialog: MatDialog,
    public technicianService: TechnicianService,
    public router: Router, public route: ActivatedRoute) {}

  ngOnInit(){
    // console.log('AssignedDistributionListForStaff: ', this.workerCode, this.listOpenFrom);
    if(this.workerCode){
      this.getDistributionUserListByWorkerCode(this.workerCode);
    }
  }

  /* Name:Vivek Chauhan
    use: for getting distribution list by worker code */
    getDistributionUserListByWorkerCode(workerCode: any) {
      try {
        this.technicianService.common.progressLoader = true;
        this.technicianService.getDistributionUserListByWorkerCode(workerCode, this.technicianService.common.local.get('orgCode'), (response) => {
          this.technicianService.common.progressLoader = false;
        if (response.responsecode == 200) {          
          // console.log('getDistributionUserListByWorkerCode', response);
          this.listData = response.data;
          this.dataSource = new MatTableDataSource(this.listData);
          setTimeout(() => {           
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 1000)
        } else {
          this.listData = [];
            this.dataSource = new MatTableDataSource(this.listData);
            setTimeout(() => {           
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 1000)
        }
      })
      } catch (error) {
        //console.log(error)
      }
      
  }    

  /* Name:Vivek Chauhan
    use: for Open and add distribution list by Staff code */

openAddDistribution(){
  const dialogRef = this.dialog.open(AddDistributionInStaff, { 
    width: '60%', 
    disableClose: true,
    data: {staffCode: this.workerCode}
   });

  dialogRef.afterClosed().subscribe(result => {
    if (result != undefined && result){
      // console.log('openAddDistributionSuccess:', result);
      this.getDistributionUserListByWorkerCode(this.workerCode);
    }
  });
}

}
