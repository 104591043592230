<div>

    <div class="row">
        <div class="col-md-12 text-end">
            <mat-icon class="close cursor-pointer" (click)="onClose()">close</mat-icon>
        </div>
    </div>
    <div class="row mt-3 mb-4">
        <div class="col-md-4">
            <strong class="filter-title primary-color">{{ 'Jobs.labels.Apply Filters' | translate }}</strong>
            <hr class="hr-popup">
        </div>
        <div class="col-md-8 text-end">
            <span class="clear-all-c cursor-pointer" (click)="onNoClick('all')"> <span>Clear All</span>
                <i class="material-icons cursor-pointer delete"> delete_outline </i>
            </span>
        </div>
    </div>
    <div class="row ">
        <div class=" col-md-12 col-sm-12 ps-0">
            <h5 class="w-75 procedure-text">{{ 'JobType.labels.Job' | translate }}</h5>
            <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings">
                <c-badge>
                    <ng-template let-item="item">
                        <img [src]="item.image" class="img-filter" />
                        <label style="margin: 0px;">{{item.itemName}}</label>
                    </ng-template>
                </c-badge>
            </angular2-multiselect>
        </div>

    </div>
    <hr>
    <div class="col-md-12 text-center mt-3">
        <button color="primary" (click)="onNoClick('procode')" mat-button
            mat-raised-button>{{ 'Jobs.labels.Apply Filters' | translate }} </button>
    </div>

</div>