import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../../common.service';

@Directive({
  selector: '[autoClose]'
})
export class AutoCloseDirective implements OnDestroy {

  delay:number = 4000;
  timerId:any

  constructor(private el: ElementRef , private _commonSVC : CommonService) 
  {
    this.startTimer();
  }

  ngOnDestroy(): void {
     clearTimeout(this.timerId);
  }

  @HostListener('mouseover', ['$event']) onMouseUp(event: MouseEvent): void
   {
     this.pauseTimer();
  }

  @HostListener('mouseout', ['$event']) onMouseDown(event: MouseEvent): void 
  {
      
    this.delay = 1000; // reduce delay
     this.startTimer();
   }

startTimer()
{
  this.timerId = setTimeout(() => {   
        
    if(this._commonSVC.successMessage) {  this._commonSVC.successMessage = false;  }
    if(this._commonSVC.errorMessage)  { this._commonSVC.errorMessage = false;  }

       }, this.delay)
}

   pauseTimer() {
    clearTimeout(this.timerId);
  }

}