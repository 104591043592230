import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    public http: HttpClient,
    public service: ApiRootService,
    public session: SessionStorageService,
    public common: CommonService,
  ) { }

   /* Name:Shailesh
  use: for getting all chat group by org code*/
  adminGetAllGroupByOrganizationCode(code,adminCode, callback) {
    return this.http.get(this.service.getChatUrl() + '/adminGetAllGroupByOrganizationCode/' + code +'/' + adminCode ).subscribe((data) => {
      callback(data);
    })
  }

 /* Name:Shailesh
  use: for getting meassage list by group code and user code*/
  getMessageListByGroupCodeAndUserCode(code,usercode, search, id, page, limit, callback) {
    if(search && id == null){
      return this.http.get(this.service.getChatUrl() + '/getMessageListByGroupCodeAndUserCode/' + code + '/' + usercode+'/'+search+'?page='+page+'&limit='+limit).subscribe((data) => {
        callback(data);
      })
    } else if(search && id) {
      return this.http.get(this.service.getChatUrl() + '/getMessageListByGroupCodeAndUserCode/' + code + '/' + usercode+'/'+search+'/'+id+'?page='+page+'&limit='+limit).subscribe((data) => {
        callback(data);
      });
    } else {
      return this.http.get(this.service.getChatUrl() + '/getMessageListByGroupCodeAndUserCode/' + code + '/' + usercode+'?page='+page+'&limit='+limit).subscribe((data) => {
        callback(data);
      })
    }
  }

   /* Name:Shailesh
  use: to save sent message*/
  saveMessage(data,callback){
    return this.http.post(this.service.getChatUrl() + '/saveMessage', data).subscribe((data) => {
      callback(data);
    })
  }

  /* Name: Vivek Chauhan
  use:to update messages Status Read/Unread */
  updateMessage(id, data, callback){
    return this.http.put(this.service.getChatUrl()+'/updateMessageReadStatus/'+id, data).subscribe((data)=>{
      callback(data);
    })
  }

   /* Name:Shailesh
  use: for getting all clinician and organization by org code*/
  getWorkerAndOrganizationFromWAS(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getWorkerAndOrganizationFromWAS/' + code).subscribe((data) => {
      callback(data);
    })
  }

   /* Name:Shailesh
  use: for creating group with user*/
  createGroupAndMapWithUser(data,callback){
    return this.http.post(this.service.getChatUrl() + '/createGroupAndMapWithUser', data).subscribe((data) => {
      callback(data);
    })
  }
  
   /* Name:Shailesh
  use: for getting group detail by user code and admin code*/
  adminGetGroupDetailByUserCodeAndAdminCode(userCode,adminCode,callback) {
    return this.http.get(this.service.getChatUrl()+'/adminGetGroupDetailByUserCodeAndAdminCode/'+userCode+'/'+adminCode).subscribe((data)=>{
      callback(data);
    })
  }

   /* Name:Shailesh
  use: for deleting message by id*/
  deleteMessageById(msgId,callback) {
    return this.http.delete(this.service.getChatUrl()+'/softDeleteMessageById/'+msgId).subscribe((data)=>{
      callback(data);
    })
  }

  adminGetAllGroupByUserCode(adminCode, callback) {
    return this.http.get(this.service.getChatUrl() + '/adminGetAllGroupByUserCode/'+adminCode ).subscribe((data) => {
      callback(data);
    })
  }

  

}
