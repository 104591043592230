import { OrgapprovalComponent } from './onboarding/orgapproval/orgapproval.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ProvidersDashboardComponent } from './providers-dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProvidersDashboardRoutingModule } from './providers-dashboard-routing.module';
import { AppointmentComponent } from './appointment/appointment.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { ProcedureListComponent } from './procedure/procedure-list/procedure-list.component';
import { ProcedureEditComponent } from './procedure/procedure-edit/procedure-edit.component';
import { CreateAppointmentComponent } from './appointment/create-appointment/create-appointment.component';
import { RepeatAppointmentComponent } from './appointment/create-appointment/repeat-appointment/repeat-appointment.component';
import { PatientComponent } from './patient/patient.component';
import { SkillsComponent } from './skills/skills.component';
import { AddskillsComponent } from './skills/addskills/addskills.component';
import { AssignedWorkerComponent } from './appointment/create-appointment/assigned-worker/assigned-worker.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UpdateAppointmentComponent } from './appointment/update-appointment/update-appointment.component';
import { UpdateAppointmentSeriesComponent } from './appointment/update-appointment-series/update-appointment-series.component';
import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { PatientEditComponent } from './patient/patient-edit/patient-edit.component';
import { PatientDataComponent } from './appointment/create-appointment/patient-data/patient-data.component';
import { TechnicianListComponent } from './technician/technician-list/technician-list.component';
import { TechnicianEditComponent } from './technician/technician-edit/technician-edit.component';
import { PatientCalendarComponent } from './patient/patient-calendar/patient-calendar.component';
import { AppointmentPopupComponent } from './appointment/appointment-popup/appointment-popup.component';
// import { NotificationComponent } from './notification/notification.component';
import { AllappointmentPopupComponent } from './appointment/allappointment-popup/allappointment-popup.component';
import { AssignAppointmentComponent } from './appointment/assign-appointment/assign-appointment.component';

import { TradeShiftComponent } from './appointment/trade-shift/trade-shift.component';
import { CancelAppointmentComponent } from './appointment/cancel-appointment/cancel-appointment.component';
import { DashboardPatientDataComponent } from './patient/dashboard-patient-data/dashboard-patient-data.component';
import { ShowRoutesComponent } from './appointment/show-routes/show-routes.component';
import { AllAssignAppointmentComponent } from './appointment/create-appointment/all-assign-appointment/all-assign-appointment.component';
import { AppointmentFilterComponent } from './appointment/appointment-filter/appointment-filter.component';
import { SiteMapComponent } from './appointment/site-map/site-map.component';
import { TechnicianFilterComponent } from './technician/technician-list/technician-filter/technician-filter.component';
import { SplitAppointmentComponent } from './appointment/split-appointment/split-appointment.component';
import { ChatComponent } from './chat/chat.component';
import { ChatPageviewComponent } from './chat/chat-pageview/chat-pageview.component'
import { LocationComponent } from './location/location.component';
import { ChatGroupComponent } from './chat/chat-group/chat-group.component';
import { ResetSettingPopupComponent } from './settings/reset-setting-popup/reset-setting-popup.component';
import { BroadcastMessageComponent } from './broadcast-message/broadcast-message.component';
import { CallPatientComponent } from './call-patient/call-patient.component';
import { DepartmentComponent } from './department/department.component';
import { AddDepartmentComponent } from './department/add-department/add-department.component';
import { ReportsComponent } from './reports/reports.component';
import { ArrivalDepartureComponent } from './arrival-departure/arrival-departure.component';
import { SchedulingRulesComponent } from './scheduling-rules/scheduling-rules.component';
import { LocationLeafletComponent } from './location-leaflet/location-leaflet.component';
import { RandomAutoAppointmentComponent } from './random-auto-appointment/random-auto-appointment.component';
import { AppointmentListComponent } from './appointment/appointment-list/appointment-list.component';
import { MileageReportComponent } from './mileage-report/mileage-report.component';
import { AddAdminComponent } from './profile/add-admin/add-admin.component';
import { AdddocumentnoteComponent } from './profile/adddocumentnote/adddocumentnote.component';
import { AddOrganizaionnoteComponent } from './profile/add-organizaionnote/add-organizaionnote.component';
import { AddDocumentComponent } from './profile/add-document/add-document.component';
import { RoleComponent } from 'src/app/components/role/role.component';
//import { ChatLogComponent, lib_pipe_niceDateFormat } from 'src/app/chat/e-chat-log/e-chat-log.component';
import { CriticalPositionComponent } from './critical-position/critical-position.component';
import { RoleProfileComponent } from 'src/app/components/role-profile/role-profile.component';
import { AssignStaffMemberComponent } from 'src/app/components/assign-staff-member/assign-staff-member.component';
// import { JobViewComponent } from './appointment/job-view/job-view.component';
// import { StaffViewComponent } from './appointment/staff-view/staff-view.component';
import { MemberUploadComponent } from './technician/member-upload/member-upload.component';
import { CertificationComponent } from './certification/certification.component';
import { AddCertificationComponent } from './certification/add-certification/add-certification.component';
import { ShiftService } from './shift-segment/shift.service';
import { ShiftSegmentComponent } from './shift-segment/shift-segment.component';
import { LicenceComponent } from './licence/licence.component';
import { AddLicenceComponent } from './licence/add-licence/add-licence.component';
 import { NewJobService } from './appointment/new-job/new-job.service';
 import { ActivityLogService } from 'src/app/components/activity-log/activity-log.service';
import { TodaysJobService } from './dashboard-todays-job/dashboard-todays-job.service';
import { JobsCustomeCardComponent } from './jobs-custome-card/jobs-custome-card.component';
import { AddShiftComponent } from './shift-segment/add-shift/add-shift.component';
import { AddUserComponent } from './user-registration/add-user/add-user.component';
 import { SkillListComponent } from './skills/skill-list/skill-list.component';
import { AdminCustomerReportsComponent } from './admin-customer-reports/admin-customer-reports.component';
import { ChangeTimeCardComponent } from './admin-customer-reports/change-time-card/change-time-card.component';
import { AddTimeCardComponent } from './admin-customer-reports/add-time-card/add-time-card.component';
import { AddMemberComponent } from './technician/add-member/add-member.component';
import { SystemSettingsComponent } from './settings/system-settings/system-settings.component';
import { UserPreferencesComponent } from './settings/system-settings/user-preferences/user-preferences.component';
import { NotificationSysSettingComponent } from './settings/system-settings/notification-sys-setting/notification-sys-setting.component';
import { GeneralSysSettingComponent } from './settings/system-settings/general-sys-setting/general-sys-setting.component';
import { DesignSysSettingComponent } from './settings/system-settings/design-sys-setting/design-sys-setting.component';
import { JobRateCardComponent } from './job-rate-card/job-rate-card.component';
import { SelectCheckAllComponent } from 'src/app/common/select-all-check/select-check-all.component';
import { StaffExceptionComponent } from 'src/app/components/staff-exception/staff-exception.component';
import { ActivityTrackingLogComponent } from './tracking/activity-tracking-log/activity-tracking-log.component';
import { RouteTrackComponent } from './tracking/route-track/route-track.component';
import { InvoiceReportComponent } from 'src/app/components/generate-invoice/invoice-report/invoice-report.component';
import { TimeCardReportComponent } from './admin-customer-reports/time-card-report/time-card-report.component';
import { StaffCalendarViewComponent } from './technician/staff-calendar-view/staff-calendar-view.component';
 
// import { NotesPopupComponent } from './appointment/notes-popup/notes-popup.component';

@NgModule({
  declarations: [
    ProvidersDashboardComponent,
    AppointmentComponent,
    ProfileComponent,
    SettingsComponent,
    ProcedureComponent,
    ProcedureListComponent,
    ProcedureEditComponent,
    CreateAppointmentComponent,
    RepeatAppointmentComponent,
    PatientComponent,
    SkillsComponent,
    AddskillsComponent,
    SkillListComponent,
    AssignedWorkerComponent,
    UpdateAppointmentComponent,
    UpdateAppointmentSeriesComponent,
    PatientListComponent,
    PatientEditComponent,
    PatientDataComponent,
    TechnicianListComponent,
    TechnicianEditComponent,
    PatientCalendarComponent,
    AppointmentPopupComponent,
    // NotificationComponent,
    AllappointmentPopupComponent,
    AssignAppointmentComponent,
    TradeShiftComponent,
    CancelAppointmentComponent,
    DashboardPatientDataComponent,
    ShowRoutesComponent,
    AllAssignAppointmentComponent,
    AppointmentFilterComponent,
    SiteMapComponent,
    TechnicianFilterComponent,
    SplitAppointmentComponent,
    ChatComponent,
    ChatPageviewComponent,
    LocationComponent,
    ChatGroupComponent,
    ResetSettingPopupComponent,
    BroadcastMessageComponent,
    CallPatientComponent,
    DepartmentComponent,
    AddDepartmentComponent,
    ReportsComponent,
    ArrivalDepartureComponent,
    SchedulingRulesComponent,
    LocationLeafletComponent,
    RandomAutoAppointmentComponent,
    AppointmentListComponent,
    MileageReportComponent,
    AddAdminComponent,
    AdddocumentnoteComponent,
    AddOrganizaionnoteComponent,
    OnboardingComponent,
    AddDocumentComponent,
    OrgapprovalComponent,
    RoleComponent,
    RoleProfileComponent,
   // ChatLogComponent,
    CriticalPositionComponent,
   // lib_pipe_niceDateFormat,
    AssignStaffMemberComponent,
    // JobViewComponent,
    // StaffViewComponent,
    MemberUploadComponent,
    CertificationComponent,
    AddCertificationComponent,
    ShiftSegmentComponent,
    LicenceComponent,
    AddLicenceComponent,
     JobsCustomeCardComponent,
    AddShiftComponent,
    AddUserComponent,
     ChatPageviewComponent,
    AdminCustomerReportsComponent,
    ChangeTimeCardComponent,
    AddTimeCardComponent,
    AddMemberComponent,
    SystemSettingsComponent,
    UserPreferencesComponent,
    NotificationSysSettingComponent,
    GeneralSysSettingComponent,
    DesignSysSettingComponent,
    JobRateCardComponent,
    SelectCheckAllComponent,
    StaffExceptionComponent,
    ActivityTrackingLogComponent,
    RouteTrackComponent,
    // NotesPopupComponent
    InvoiceReportComponent,
    TimeCardReportComponent,
    StaffCalendarViewComponent
  ],
  imports: [
    SharedModule,
    ProvidersDashboardRoutingModule, 
    MatButtonToggleModule,
    ScrollingModule,
    ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],  

  exports: [
    AppointmentComponent,
    ProfileComponent,
    SettingsComponent,
    ProcedureComponent,
    ProcedureListComponent,
    ProcedureEditComponent,
    JobRateCardComponent,
    SelectCheckAllComponent,
    StaffExceptionComponent
    // JobViewComponent,
    // StaffViewComponent
  ],
  providers: [
    ShiftService ,
    NewJobService,
    ActivityLogService,
    TodaysJobService
  ],
  entryComponents : [
    RepeatAppointmentComponent,
    AddskillsComponent,
    SkillListComponent,
    AssignedWorkerComponent,
    PatientDataComponent,
    PatientCalendarComponent,
    AppointmentPopupComponent,
    AllappointmentPopupComponent,
    AssignAppointmentComponent,
    TradeShiftComponent,
    CancelAppointmentComponent,
    DashboardPatientDataComponent,
    ShowRoutesComponent,
    AllAssignAppointmentComponent,
    AppointmentFilterComponent,
    SiteMapComponent,
    TechnicianFilterComponent,
    SplitAppointmentComponent,
    ResetSettingPopupComponent,
    BroadcastMessageComponent,
    ChatGroupComponent,
    ChatComponent,
    ChatPageviewComponent,
    CallPatientComponent,
    ArrivalDepartureComponent,
    CreateAppointmentComponent,
    UpdateAppointmentComponent,
    UpdateAppointmentSeriesComponent,
    AddAdminComponent,
    AdddocumentnoteComponent,
    AddDocumentComponent,
    OrgapprovalComponent,
    AddCertificationComponent,
    AddLicenceComponent,
    // JobViewComponent,
    // StaffViewComponent
    // NotesPopupComponent
  ],
})
export class ProvidersDashboardModule { }
