<div class="logout">
  <div class="btn text-center w-100">
    <form [formGroup]="reasonForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>Reason</mat-label>
          <input
            matInput
            class="form-control"
            formControlName="reason"
            minlength="2"
            maxlength="50"
          />
        </mat-form-field>
        <span
          class="help-block err"
          *ngIf="
            reasonForm.get('reason').errors?.required &&
            (!submitted || reasonForm.get('reason').touched)
          "
        >
          Reason is required
        </span>
      </div>
      <button mat-raised-button color="primary">
        Submit
      </button>
    </form>
  </div>
</div>
