import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportsService } from '../../reports/reports.service';
import * as moment from 'moment';

declare var google;

@Component({
  selector: 'app-route-track',
  templateUrl: './route-track.component.html',
  styleUrls: ['./route-track.component.scss']
})
export class RouteTrackComponent implements OnInit, AfterViewInit {

  mapOptions: google.maps.MapOptions = {
    mapTypeId:'satellite'
  };

  private polyLine : google.maps.Polyline;
   GMap:any;
  @ViewChild('mapDis', { static: true }) mapDis;
  private markers : google.maps.Marker[] = [];
  tracking_Form:FormGroup;
  formSubmitted : boolean = false ;
  isLoading : boolean = false ;
   
  trackingDataList : any[] = [] ;
  private positionMarkerList : google.maps.Marker[] = [];

  constructor(
    private _reportsService : ReportsService
  ){}


  ngOnInit(): void {
    this.initializeForm();
  }


  ngAfterViewInit(): void
   {
    this.initializeMap();
  }


  initializeForm(){
    this.tracking_Form = new FormGroup({
      tr_date : new FormControl(null , Validators.required),
      tr_fromTime : new FormControl(null , Validators.required),
      tr_toTime : new FormControl(null , Validators.required),
      tr_interval : new FormControl(null , Validators.required),
      tr_staffCode : new FormControl(null , Validators.required)

    })
  }


 initializeMap()
 {

let currentLatLng:any = { lat: -34.397, lng: 150.644 };

  // this.getCurrentLatLng((response) => {
  //   currentLatLng = new google.maps.LatLng(response.coords.latitude, response.coords.longitude);
  // })


  setTimeout(()=>{
  if(!this.GMap)
  {
    this.GMap = new google.maps.Map(document.getElementById('tr_map'), {
      zoom: 10,
      center: currentLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: false
    } );
  }

  // this.marker = new google.maps.Marker({
  //   map: this.GMap,
  //   position: currentLatLng,
  //   draggable: true,
  //   icon: { url: './../../assets/location_1.png' },
  // });

  // this.drawMarker();
  // this.drawPolyLine();

},500)

}


// drawPolyLine()
// {
//   let position  = this.markers.map(ele=> ele.getPosition());
//   console.log("position", position);

//   this.polyLine = new google.maps.Polyline({
//     path: position,
//     strokeColor: '#FF0000',
//     strokeWeight: 3
//   });
 
//   // Add the polyline to the map
//     this.polyLine.setMap(this.GMap);

// }

// drawMarker()
// {
//   // Draw markers
//   this.markers = [
//     new google.maps.Marker({
//       position: { lat: -34.397, lng: 150.644 },
//       map: this.GMap,
//       title: 'Marker 1'
//     }),
//     new google.maps.Marker({
//       position: { lat: -34.3, lng: 150.7 },
//       map: this.GMap,
//       title: 'Marker 2'
//     }),
//     new google.maps.Marker({
//       position: { lat: -34.5, lng: 150.5 },
//       map: this.GMap,
//       title: 'Marker 3'
//     })
//   ];

//   // Add click event listener to markers
//   this.markers.forEach((marker, index) => {
//     marker.addListener('click', () => {
//       this.onMarkerClick(marker, index);
//     });
//   });
// }

onMarkerClick(marker: google.maps.Marker, index: number): void {
  console.log('Marker clicked:', marker, 'Index:', index);
}

  getCurrentLatLng(callback) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      ////console.log(position);
      callback(position);

    }, function () {
      // handleLocationError(true, infoWindow, map.getCenter());
    });
  }


  getTrackingData()
  {
    this.formSubmitted = true ;
     if(this.tracking_Form.valid)
     {
      this.isLoading = true ;
      this._reportsService.getTrackingDataByStaffCode(
        moment(this.tracking_Form.value.tr_date).format("YYYY-MM-DD"),
        moment(this.tracking_Form.value.tr_fromTime, "hh:mm").format("hh:mm A"),
        moment(this.tracking_Form.value.tr_toTime, "hh:mm").format("hh:mm A"),
        this.tracking_Form.value.tr_interval,
        this.tracking_Form.value.tr_staffCode,
      ).subscribe((res:any)=>{
        this.isLoading = false ;
          console.log("res", res) ;
          
          if(res.responsecode == 200)
          {
             this.trackingDataList = res.data ;
             this.showMarkerPositionOnMap();
          }

      })
     }
   }

  onStaffChanged(event:any)
  {
    this.tracking_Form.controls.tr_staffCode.setValue(event.staffCode);
   }


  showMarkerPositionOnMap()
  {
    if(this.trackingDataList.length === 0)
    {
      return;
    }
  
    this.isLoading = true ;
    this.trackingDataList.forEach(ele=>{

      let title = `Name: ${ele.staffName}  Date: ${ele.trackingDate} ${ele.createdTime}`;

      let marker =  new google.maps.Marker({
        position: { lat: Number(ele.latitude), lng: Number(ele.longitude) },
        map: this.GMap,
        title: title
      });

      this.positionMarkerList.push(marker);

    });

    // Add click event listener to markers
    this.positionMarkerList.forEach((marker, index) => {
      marker.addListener('click', () => {
        this.onMarkerClick(marker, index);
      });
    });
    this.isLoading = false ;

    this.drawPolyLineOnMap();

  }


  drawPolyLineOnMap()
{
  this.isLoading = true ;
  let position  = this.positionMarkerList.map(ele=> ele.getPosition());
  console.log("position", position);

  this.polyLine = new google.maps.Polyline({
    path: position,
    strokeColor: '#FF0000',
    strokeWeight: 3
  });
 
  // Add the polyline to the map
    this.polyLine.setMap(this.GMap);
    this.isLoading = false ;

    this.GMap.setCenter(this.positionMarkerList[0].getPosition());

}


}
