
<div class="appointment-popup">
  <div class="row m-0">
    <div class="col-md-6">
      <h6 class="mb-0">{{ 'Jobs.labels.Jobs' | translate }} </h6>
    </div>
    <div class="col-md-6 text-end">
      <div class="row justify-content-end">
        <!-- <button *ngIf="userType !== 'Customer' && !disabled" type="button" (click)="autoAssign()" class="w-auto me-2" mat-raised-button color="primary" [disabled]="disabled">Auto Assign</button> -->
        <button class="btn-close cursor-pointer" (click)="onNoClick()"></button>
      </div>          
    </div>
    <!-- <div class="col-md-1 pe-0">
      <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
    </div>
    <div class="col-md-11 search ps-0">
      <input autocomplete="off" type="text" class="form-control search ps-0" id="search-name"
        placeholder="{{ 'Jobs.messages.Search Job Location' | translate }}" name="search-name"
        [(ngModel)]="searchTerm" (keyup)="search()">
    </div> -->
  </div>
    <hr class="hr-div m-0">
    <div>
      <app-new-job-card
       [dataSource]="appointmentStatus"
        [is_Loading]="isLoading"
        [allowActions]="{
          allowEdit: false,
          allowDelete: false,
          allowAssignStaff: false,
          allowNotifyStaff: false,
          allowToViewNotifiedStaff: false,
          allowToCopyJob: false,
          allowView: false
    }"
        (updateParent)="RefreshJobList($event)"></app-new-job-card>
    </div>

  </div>
    
  <!-- <div class="appointment-details" clickOutside (clickOutside)="showPopup = ''">
    <mat-card class=" mx-auto card-appointment-popup mt-3 mb-3" *ngFor="let data of appointmentStatus;">
      <div class="row icon-round pb-0">
        <div class="col-md-2 text-center">
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'pending'">
            hourglass_empty
          </i>
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'assigned'">
            done
          </i>
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'accepted'">
            done
          </i>
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'inprogress'">
            more_horiz
          </i>
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'completed'">
            done
          </i>
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'expired'">
            history
          </i>
          <i class="material-icons icon-appointment {{data.status}} " *ngIf="data.status == 'cancelled'">
            close
          </i>
          <i class="material-icons icon-appointment {{data.status}}" *ngIf="data.status == 'late or cancelled'">
            assignment_late
          </i>
        </div>
        <div class="col-md-3 text-start">
          <small>{{ 'Jobs.messages.Job Location' | translate }}</small>
          <p>{{data.customerName}}</p>
          <p *ngIf="!data.customerName">Not Available</p>
        </div>
        <div class="col-md-6">
          <small>{{ 'Jobs.messages.Address' | translate }}</small>
          <p *ngIf="data.customerAddress">{{data.customerAddress}}</p>
          <p *ngIf="!data.customerAddress">{{ 'Jobs.messages.Not Available' | translate }}</p>
        </div>
        <div class="col-md-1 text-end">
          <i class="material-icons menu-icon " (click)="geteditData(data)"
            *ngIf="data.status != 'inprogress' && data.status != 'completed' && data.status != 'cancelled' && data.status != 'expired'">
            more_vert
          </i>
        </div>
        <div class="edit-open col-md-9" *ngIf="data.appointmentsDurationCode == showPopup">
          <ul>
            <li *ngIf="userType !== 'Customer'" (click)="assignAppointment(data.appointmentsDurationCode)">
              <mat-icon>people</mat-icon>
              <p *ngIf="data.workerName == null">{{ 'Jobs.messages.Assign Staff' | translate }}</p>
              <p *ngIf="data.workerName != null">{{ 'Jobs.messages.Change Staff' | translate }}</p>
            </li>

            <li *ngIf="data.status == 'assigned'"
              (click)="acceptAppointment(data.appointmentsDurationCode, data.workerCode)">
              <mat-icon>check</mat-icon>
              <p>{{ 'Jobs.messages.Accept Job' | translate }}</p>
            </li>
            <li *ngIf="data.status !=='rejected' && data.status == 'assigned'"
              (click)="rejectAppointment(data.appointmentsDurationCode, data.workerCode)">
              <mat-icon>close</mat-icon>
              <p>{{ 'Jobs.messages.Reject Job' | translate }}</p>
            </li>
            <li (click)="cancelAppointment(data.appointmentsDurationCode)">
              <mat-icon>close</mat-icon>
              <p>{{ 'Jobs.messages.Cancel Job' | translate }}</p>
            </li>
            <li *ngIf="data.workerName != null"
              (click)="showRoute(data)">
              <mat-icon>where_to_vote</mat-icon>
              <p>{{ 'Jobs.messages.Show Route' | translate }}</p>
            </li>

          </ul>
        </div>

      </div>
      <hr class="p-0">
      <div class="row  d-flex justify-content-between">
        <div class="col-md-3">
          <small>{{ 'Jobs.messages.Staff' | translate }}</small>
          <p *ngIf="data.workerName">{{data.workerName}}</p>
          <p *ngIf="!data.workerName">Not Available</p>
        </div>
        <div class="col-md-2">
          <small>{{ 'Jobs.messages.Time' | translate }}</small>
          <p>{{data.appointmentStartDate.split(' ')[1] }} {{data.appointmentStartDate.split(' ')[2]}}</p>
        </div>


        <div class="col-md-2 cursor-pointer ps-0" *ngIf="data.workerName && data.status !== 'completed'"
          (click)="service.common.toggleDistance(data.appointmentsDurationCode)">
          <div class="distanceImg" *ngIf="service.common.showDistance !== data.appointmentsDurationCode">
            <small>{{ 'dashboard.labels.Distance' | translate }}</small>
            <p *ngIf="data.distanceText">{{data.distanceText}}</p>
            <p *ngIf="!data.distanceText">0.00 Miles</p>
          </div>
          <div class="distanceImg" *ngIf="service.common.showDuration == data.appointmentsDurationCode">
            <small>{{ 'Jobs.Create Appointment.labels.Duration' | translate }}</small>
            <p *ngIf="data.durationText">{{data.durationText}}</p>
            <p *ngIf="!data.durationText">0 Minute</p>
          </div>
        </div>

        <div class="col-md-1 cursor-pointer ps-0 d-flex align-self-center" *ngIf="data.workerName
          && data.status === 'completed' && data.actualStartTime"
          (click)="openArrivalDialog(data.arrivalTimeDate,data.departureTimeDate)">
          <mat-icon class="clock-time" title="Actual Schedule Time">access_time</mat-icon>
        </div>


        <div class="col-md-2" *ngIf="!data.workerName && data.status !== 'completed'">
          <small>{{ 'dashboard.labels.Distance' | translate }}</small>
          <p *ngIf="data.distanceText">{{data.distanceText}}</p>
          <p *ngIf="!data.distanceText">0.00 Miles</p>
        </div>
        <div class="col-md-1">
            
              <span *ngIf="data.notes || data.patientRecurringNotes || data.comments">
                  <img class="cursor-pointer mt-2" src="/assets/notes_icon.png" title="Notes" (click)="getNotes(data)">
              </span>
              <span *ngIf="!data.notes && !data.patientRecurringNotes && !data.comments"></span>
        </div>
        <div class="col-md-4 dis-img text-end pe-0 ps-0">

          <div class=" me-0 pe-0 ">
            <div [ngClass]="i <= 3  && procImg.procedureIcon?'':'hideIcon'" class=" me-1 mb-2"
              *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
              <img (click)="getProcedureDetails(procImg,data.notes)" class="img-icon cursor-pointer" alt="No Image" *ngIf="i <= 3 && procImg.procedureIcon"
                title="{{procImg.procedureName}}"
                [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
              <img alt="No Image" *ngIf="!procImg.procedureIcon"
                src="../../../../assets/common_images/profile.jpg" />
            </div>
          </div>
          <div class="ps-0 me-1 img-data" *ngIf="data.organizationBasedProcedureDetailsList.length > 4"
            (click)="showImage(data)">
            <div class="img-count cursor-pointer">
              {{data.organizationBasedProcedureDetailsList.length - 4}}
            </div>
          </div>

          <div class="img-open " *ngIf="data.appointmentsDurationCode == showImg">
            <div class="img-icon me-0 mt-1">
              <div [ngClass]="i >= 4 && procImg.procedureIcon?'':'hideIcon'"
                *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                <img (click)="getProcedureDetails(procImg,data.notes)" alt="No Image" class="me-2 cursor-pointer" *ngIf="i >= 4 && procImg.procedureIcon "
                  title="{{procImg.procedureName}}"
                  [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
              </div>
            </div>
          </div>
      
        </div>
      </div>
    </mat-card>
  </div> -->
 
