import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TeamManagementService } from './team-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { debounce } from 'lodash';
import { AddTeamSiteComponent } from "src/app/components/add-team-site/add-team-site.component";
import { CommonDataListViewComponent } from "src/app/components/common-data-list-view/common-data-list-view.component";
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

@Component({
  selector: 'team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss']
})
export class TeamManagementComponent implements OnInit, OnDestroy {
  displayedColumns = ['teamName', 'associateStaffs', 'supervisors' , 'jobSite', 'edit'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  public show: boolean = false;
  public searchClick: any = 'Show';
  page = 0;
  limit = 30;
  searchTerm;
  patientName = 'all';
  teamList:any =[];
  teamList_Filter:any =[];
  img_serverPath:string ='';
  viewPoint:string = "cardview";
  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();

  constructor(
    public service: TeamManagementService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private _commonSVC : CommonService 
  ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }

  ngOnInit() {
    this.img_serverPath = this.service._apiSVC.getSuperAdmin() + '/downloadFile/';
    this.getAllTeamList(this.page, this.limit);
    this.hasPermission =  this.service.common.getBtnEventPermissions("Team Management");
    this.subToPermissionChanged();
  }

  applyFilter(filterValue: string) {
    this.page = 0;
    let searchVal = filterValue.trim().toLowerCase();
    this.getAllTeamList(this.page, this.limit, null, searchVal);
  }

  

  /* Name:Shailesh
     use: for patient detail by org code*/  
    //  getData(
  getAllTeamList(page, limit, scroll = null, search = null) {
    if (!scroll && !search) {
      this.service.common.progressLoader = true;
    }
    else if (scroll) {
      this.service.common.infiniteLoader = true;
    }
    try {
      // this.service.getPatientDetailByOrganizationCodeByPage(this.service.storage.get('orgCode'), page, limit, this.patientName, (response) => {
        this.service.common.infiniteLoader = true;
      this.service.getAllTeamsByPage(this.service.storage.get('orgCode'), page, limit, search, (response) => {
        // this.service.common.progressLoader = false;
        // //console.log('getAllTeamsByPage: ', response)
        if (response.responsecode == 200) {
          response.data.map((team) => {
            if(team.teamStaffMappingResponseList && team.teamStaffMappingResponseList.length>0){
              team.supervisorList = team.teamStaffMappingResponseList.filter((team2) => team2.isSupervisor);
              team.associatedStaffList = team.teamStaffMappingResponseList.filter((team2) => !team2.isSupervisor);
              return team;
            }
            return team;
          })
          if (scroll) {
            response.data.map((team) => {
              if(team.teamStaffMappingResponseList && team.teamStaffMappingResponseList.length>0){
                team.supervisorList = team.teamStaffMappingResponseList.filter((team2) => team2.isSupervisor);
                team.associatedStaffList = team.teamStaffMappingResponseList.filter((team2) => !team2.isSupervisor);
                return team;
              }
              return team;
            })
            // let arrayData = this.dataSource.data;
            let arrayData = this.teamList;
            response.data.forEach(element => {
              arrayData.push(element)
            });
            
            // setTimeout(() => {
              this.dataSource = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.teamList = arrayData;
              this.teamList_Filter = this.teamList;
              // this.service.common.progressLoader = false;
               this.service.common.infiniteLoader = false;
            // }, 100);

          } else {
            //////console.log(response.data);
            setTimeout(() => {
              this.teamList = response.data;
              this.dataSource = new MatTableDataSource(this.teamList);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.teamList_Filter = this.teamList;
              // this.service.common.progressLoader = false;
               this.service.common.infiniteLoader = false;
            }, 1000)

          }

        } else {
          if (!scroll) {
            this.teamList = [];
            this.teamList_Filter = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 200)

          }
          //  else {
          //   this.service.common.infiniteLoader = false;
          // }
          // this.service.common.progressLoader = false;
          this.service.common.infiniteLoader = false;
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Shailesh
     use: for open calender popup*/
  openDialog(patientCode): void {
    // const dialogRef = this.dialog.open(PatientCalendarComponent, {
    //    width: '80%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true ,
    //   data: { patientCode: patientCode }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   //////console.log('The dialog was closed');
    // });
  }

  /* Name:Shailesh
     use: to show and hide serach click*/
  filterToggle() {
    this.show = !this.show;

    if (this.show) {
      this.searchClick = "Hide";
    }
    else {
      this.searchClick = "Show";
    }
  }

  /* Name:Shailesh
     use: for infinite scroll*/
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.getAllTeamList(this.page, this.limit, 'scroll');
  }

  // search() {

  //   if (this.searchTerm) {
  //     this.patientName = this.searchTerm.toLowerCase();
  //     this.getAllTeamList(this.page = 0, this.limit , null, 'search');
  //   } else {
  //     this.patientName = 'all';
  //     this.getAllTeamList(this.page = 0, this.limit, null, 'search');

  //   }
  // }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLocaleLowerCase();
      this.applyFilter(filterValue);
     
  }

  Active_InActiveCustomer(element:any , index:number){

    let confirmMSG = "Are you sure want to "+ (element.isActive ? 'Inactive ' : 'Active ') +"customer "+ (element?.teamName && this.service.common.toTitleCase(element.teamName)) +" ?" ; 

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {
      
         if(result && result.toLowerCase() === 'ok')
         {
              let data= {
              "customerCode": element.customerCode,
              "isActiveStatus": !element.isActive
              }
              this.service.common.progressLoader = true;
            //   this.service.Active_OR_InActiveCustomer(data).subscribe((response:any) =>{

            //   if (response.responsecode == 200) {
            //   this.service.common.message = response.message;
            //   this.service.common.progressLoader = false;
            //   this.service.common.successMessage = true;

            //   // /*Instead if calling refresh api , setting isActive status locally by index*/
            //   // this.teamList[index].isActive = !element.isActive;
            //   // this.teamList_Filter =  this.teamList;
            //  this.resertData();
            //   this.page = 0;
            //   this.getAllTeamList(this.page, this.limit);
            //   // this.teamList_Filter[index].isActive = !element.isActive;
            //   } else {
            //   this.service.common.message = response.message;
            //   this.service.common.progressLoader = false;
            //   this.service.common.errorMessage = true;
            //   }

            //   })
         }

    });

 }

 resertData(){
   this.searchTerm = '';
  this.teamList = [];
  this.teamList_Filter = [];
  this.dataSource = new MatTableDataSource();
 }

 onFileChange(event , selectedEle) {
  const target = event.target;
  if (event.target.files.length > 0) {
    let allowedExtension = ['jpeg', 'jpg', 'png'];
    let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
     let index =  allowedExtension.findIndex((ele:any) => ele === fileExtension);

     if(index == -1){
      this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') ;
      this.service.common.errorMessage = true;
      event.target.value = '';
      return;
     }

     if(event.target.files[0].size >= 2000000)
     {
      this.service.common.message = 'file size should be less then 2MB';
      this.service.common.errorMessage = true;
      event.target.value = '';
      return;
     }

       const formData = new FormData();
        formData.append("file", event.target.files[0]);
        this.service.common.progressLoader = true;
        this.service.common.uploadImage(formData, (res) => {
          this.service.common.progressLoader = false;
           if(res.responsecode == 200){
            // res.filename
            this.saveCustomerLogo(res.filename , selectedEle.customerCode)
           }
           else{
            this.service.common.message = "Failed to upload file";
            this.service.common.errorMessage = true;
           }
          });

  }
}


saveCustomerLogo(filename , customerCode){
  
  if(filename && customerCode)
  {
    let JSON = {
      "customerCode":  customerCode,
      "customerLogo": filename
    }
    this.service.common.progressLoader = true;
    // this.service.saveCustomerLogo(JSON).subscribe((response:any) =>{
    //   this.service.common.progressLoader = false;
    //   if (response.responsecode == 200) {
    //     this.service.common.message = response.message;
    //      this.service.common.successMessage = true;
    //      this.resertData();
    //      this.page = 0;
    //     this.getAllTeamList(this.page, this.limit);
    //     // this.teamList_Filter[index].isActive = !element.isActive;
    //     } else {
    //     this.service.common.message = response.message;
    //      this.service.common.errorMessage = true;
    //     }
    // })

  }

}

changeView(value:string){
  this.viewPoint = value ;
   this.resertData();
  this.page = 0 ;
  this.getAllTeamList(this.page, this.limit);
}

openPopupAddUpdateTeam(data = null){
  const dialogRef = this.dialog.open(AddTeamSiteComponent, {
    width: '50%',
    disableClose: false,   
    data: data   
  });
  dialogRef.updatePosition({
    top: '1%'    // Set top position
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != undefined && result === 'success') {
      this.page = 0;
      this.getAllTeamList(this.page, this.limit);
    }
  });
}

openPopupViewList(data = null, popupType = null){
  let cloneList = data && JSON.parse(JSON.stringify(data));
  cloneList.popupTitle = popupType;
  cloneList.userList = popupType === 'Teams' ? cloneList.associatedStaffList : popupType === 'Supervisors' ? cloneList.supervisorList : popupType === 'Sites' ? cloneList.customerAddressResponseList : [];
  delete cloneList.teamStaffMappingResponseList;
  delete cloneList.customerAddressResponseList;
  delete cloneList.supervisorList;
  const dialogRef = this.dialog.open(CommonDataListViewComponent, {
    width: '80%',
    disableClose: false,   
    data: cloneList   
  });
  dialogRef.updatePosition({
    top: '1%'    // Set top position
  });
  // dialogRef.afterClosed().subscribe(result => {
  //   if (result != undefined && result === 'success') {
  //     this.page = 0;
  //     this.getAllTeamList(this.page, this.limit);
  //   }
  // });
}


subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this._commonSVC.getBtnEventPermissions("Team Management");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }


}

