import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CertificationService } from './certification.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportAsService } from 'ngx-export-as';
import { debounce } from 'lodash';
import { AddCertificationComponent } from './add-certification/add-certification.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit {

  certificationData: any = [];
  displayedColumns: string[] = ['certificationName', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  controlsPermissions:IBtnControlsPermission ;
  
  constructor(
    public dialog: MatDialog,
    public service: CertificationService,
    private translate: TranslateService,
     ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }

  ngOnInit() {
    this.page = 0;
    this.getOrgCertificationMasterByOrganizationCodeByPage();
    this.controlsPermissions =  this.service.common.getBtnEventPermissions(); 
  }
  
   /* Name:Vivek Chauhan
  use: for getting all Org Certification Master data by org code */
  getOrgCertificationMasterByOrganizationCodeByPage(scroll = null) {
      //console.log('getOrgCertificationMasterByOrganizationCodeByPage: ', this.page, scroll);
      if (!scroll) {
        this.service.common.progressLoader = true;
      }
      else if (scroll) {
        this.service.common.infiniteLoader = true;
      }
      try {
        this.service.getOrgCertificationMasterByOrganizationCodeByPage(this.service.storage.get('orgCode'), this.page, this.limit, (response) => {
          if(response.responsecode === 200){
            if (scroll) {
              let arrayData = this.dataSource.data;
              response.data.forEach(element => {
                arrayData.push(element)
              });
              setTimeout(() => {
                this.certificationData = arrayData;
                this.dataSource = new MatTableDataSource(arrayData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.infiniteLoader = false;
              }, 1000);

            } else {
              ////console.log(response.data);
              setTimeout(() => {
                this.certificationData = response.data;
                this.dataSource = new MatTableDataSource(this.certificationData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.progressLoader = false;
              }, 1000)
            }
          } else {
            if (!scroll) {
              this.certificationData = [];
              setTimeout(() => {
                this.dataSource = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
              }, 1000);      
            } else {
              this.service.common.infiniteLoader = false;
            }
            this.service.common.progressLoader = false;
          }
      });    
      } catch (error) {
        //console.log(error);
      }
  }

    /* Name:Vivek Chauhan
      use: for open edit Certification popup*/
    onEdit(data) {
      // //console.log('onEditData:', data);
      const dialogRef = this.dialog.open(AddCertificationComponent, {
        width: '500px',
        disableClose: true,
        data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result === 'success') {
        this.page = 0;
        this.getOrgCertificationMasterByOrganizationCodeByPage();
      }
    });
    }

      /* Name:Vivek Chauhan
      use: for open certification add popup */
    addCertification(): void {
    const dialogRef = this.dialog.open(AddCertificationComponent, {
      width: '500px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result === 'success') {
        this.page = 0;
        this.getOrgCertificationMasterByOrganizationCodeByPage();
      }
    });
  }

    /* Name:Vivek Chauhan new API
  use: for delete/remove Certification */
  softDeleteOrgCertificationMasterByCertificationCode(data :any){
    // //console.log('softDeleteOrgCertificationMasterByCertificationCode: ', data);
    if(data.certificationCode){ 
      const dialog = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { 
          msg: 'Are you sure want to delete this certification?'
        }
      });      
  
      dialog.afterClosed().subscribe(result => {    
        if(result && result.toLowerCase() === 'ok'){ 
          this.service.common.progressLoader = true;
          this.service.softDeleteOrgCertificationMasterByCertificationCode(data.certificationCode, (response) => {
            // //console.log(response);
            this.service.common.progressLoader = false;
            if (response.responsecode == 200) {
              this.service.common.successMessage = true;
              this.service.common.message = response.message;
              this.page = 0;
              this.getOrgCertificationMasterByOrganizationCodeByPage();
            }
            else{
              this.service.common.errorMessage = true;
              this.service.common.message = response.message;
            }
          })
        }
      });
    }
  }

    /* Name:Vivek Chauhan
    use: for infinite scroll */
    onScroll() {
        ////console.log('scroll');
        this.page = this.page + 1;
        this.getOrgCertificationMasterByOrganizationCodeByPage('scroll');
        // //console.log('OnsScroll', this.page+1, this.limit);
      }

    /* Name:Vivek Chauhan
  use: for seraching Certification Data name */
  applyFilter(searchText: string) {
    // //console.log('applyFilter', filterValue);
  // this.dataSource.filter = filterValue.trim().toLowerCase();
  
  this.page = 0;
    this.service.common.progressLoader = true;
  try {
    if(searchText && searchText.trim()){
    this.service.getOrgCertificationMasterByOrganizationCodeByPageSearch(this.service.storage.get('orgCode'), searchText, (response) => {
      if(response.responsecode === 200){
          setTimeout(() => {
            this.certificationData = response.data;
            this.dataSource = new MatTableDataSource(this.certificationData);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }, 1000);
      } else {
        this.service.common.progressLoader = false;
          this.certificationData = [];
          setTimeout(() => {
            this.dataSource = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }, 1000);
      }
  });
  } else {
    this.getOrgCertificationMasterByOrganizationCodeByPage();
  } 
  } catch (error) {
    //console.log(error);
  }
}

}
