import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class MileageReportService {

  constructor( public service: ApiRootService,
    public http: HttpClient,
    public storage: SessionStorageService,
    public common: CommonService) { }
  

    /* Name:Shailesh
      use: for getting daily mileage report by worker code from date and to date and org code*/
  getAdminAppointmentMileageReport(wcode, fromdt, todt, orgCode, callback) {
    return this.http.get(
      this.service.getHealthCareUrl() + '/getAdminAppointmentMileageReport/' + wcode + '/' + fromdt + '/' + todt + '/' + orgCode).
      subscribe((data) => {
        callback(data);
      });
  }

  /* Name:Shailesh
      use: for getting weekly mileage report by worker code from date and to date and org code */
  getAdminAppointmentMileageReportWeekly(wcode, fromdt, todt, orgCode, callback) {
    return this.http.get(
      this.service.getHealthCareUrl() + '/getAdminAppointmentMileageReportWeekly/' + wcode + '/' + fromdt + '/' + todt + '/' + orgCode).
      subscribe((data) => {
        callback(data);
      });
  }
}
