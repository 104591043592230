import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PatientService } from '../patient.service';
import { PatientCalendarComponent } from '../patient-calendar/patient-calendar.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { debounce } from 'lodash';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { CommonService } from 'src/app/shared/common.service';
import { AddUpdateNoteComponent } from 'src/app/components/common-notes/add-update-note/add-update-note.component';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { UserRegistrationComponent } from 'src/app/dashboards/providers-dashboard/user-registration/user-registration.component';
import { DirectEmailSendComponent } from 'src/app/components/email-template/direct-email-send/direct-email-send.component';
// import { element } from 'protractor';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit , OnDestroy {
  displayedColumns = ['customerName', 'customerEmail' , 'mainPhone', 'patientAddress', 'edit'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  public show: boolean = false;
  public searchClick: any = 'Show';

  patient = [];
  page = 0;
  limit = 30;
  searchTerm;
  patientName = 'all';
  customerList:any =[];
  customerList_Filter:any =[];
  img_serverPath:string ='';
  viewPoint:string = "cardview";
  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();

  sortKeyName: any = null;
  isSortOrderAtoZ: boolean = null;

  allSortNameList = [
    {'id': 1, 'name': 'Name', 'key': 'name', 'isSort': true, 'isActive': false, 'order': 'A-Z'},
    {'id': 2, 'name': 'Name', 'key': 'name', 'isSort': false, 'isActive': false, 'order': 'Z-A'}
  ]

  constructor(
    public service: PatientService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private _commonAPI_SVC : CommonApiService,
    private commonSVC : CommonService
  ) {
    this.translate.setDefaultLang(this.service.local.get('language'));
    this.search = debounce(this.search, 500);
  }


  ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }

  ngOnInit() {
    this.img_serverPath = this.service.service.getSuperAdmin() + '/downloadFile/';
    this.getData(this.page, this.limit);
    this.hasPermission =  this.service.common.getBtnEventPermissions("Customers");
    this.subToPermissionChanged();
  }

  refreshRestAllFilters(){
    this.page = 0;
    this.searchTerm = null;
    this.isSortOrderAtoZ = null;
    this.customerList = [];
    this.customerList_Filter = [];
    this.dataSource = new MatTableDataSource();
    this.allSortNameList && this.allSortNameList?.length > 0 && this.allSortNameList.map(sortItem => {
      if(sortItem.id && sortItem.isActive){
        sortItem.isActive = false
      }
      return sortItem;
    });
    this.getData(this.page, this.limit);
  }

  search(event: Event) {
    this.searchTerm = (event.target as HTMLInputElement).value.trim().toLocaleLowerCase();
    this.page = 0;   
    if (this.searchTerm !== null && this.searchTerm !== '' && this.searchTerm) {
      this.searchTerm.toLowerCase().trim() ? this.searchTerm.toLowerCase().trim() : null;
      this.getData(this.page, this.limit, null, this.searchTerm);
    } 
    else {
      this.getData(this.page, this.limit, null, this.searchTerm);
    }
     
  }

  sortBy(item: any){
    if(item && item?.order === 'A-Z'){
      this.isSortOrderAtoZ = true;
    } else {
      this.isSortOrderAtoZ = false;
    }
    this.sortKeyName = item?.key;
    item.isActive = true;
    if(item?.key){
      this.page = 0;
      this.getData(this.page, this.limit, null, this.searchTerm);
    }
    this.allSortNameList && this.allSortNameList?.length > 0 && this.allSortNameList.map(sortItem => {
      if(sortItem.id !== item.id && sortItem.isActive){
        sortItem.isActive = false
      }
      return sortItem;
    });
    return item;
  }

  /* Name:Vivek Chauhan
     use: for patient detail by org code*/
  getData(page, limit, scroll = null, search = null) {
    let reqBody = {
      isAscending: this.isSortOrderAtoZ,
      organizationCode: this.service.local.get('orgCode'),
      searchKey: null,
      searchName: this.searchTerm ? this.searchTerm : null,
      searchValue: [],
      sortKey: null
    }
    try {
        this.service.common.infiniteLoader = true;
      this.service.getAllCustomerDetailsByOrganizationCode(reqBody, page, limit, (response) => {
        if (response.responsecode == 200) {
          if (scroll) {
            let arrayData = this.customerList;
            response.data.forEach(element => {
              arrayData.push(element)
            });
              this.patient = arrayData;
              this.dataSource = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.customerList = arrayData;
              this.customerList_Filter = this.customerList;
               this.service.common.infiniteLoader = false;

          } else {
            setTimeout(() => {
              this.patient = response.data;
              this.dataSource = new MatTableDataSource(this.patient);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;            
              this.customerList = this.patient
              this.customerList_Filter = this.customerList;
               this.service.common.infiniteLoader = false;
            }, 1000)

          }

        } else {
          if (!scroll) {
            this.patient = [];
            this.customerList = [];
            this.customerList_Filter = [];
            setTimeout(() => {
              this.dataSource = new MatTableDataSource();
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
            }, 200);
          }
          this.service.common.infiniteLoader = false;
        }
      })
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Shailesh
     use: for open calender popup*/
  openDialog(patientCode): void {
    const dialogRef = this.dialog.open(PatientCalendarComponent, {
       width: '80%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true ,
      data: { patientCode: patientCode }
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
    });
  }

  /* Name:Vivek Chauhan
    use: for open Contact list popup*/
    openContactDialog(patientCode): void {
    const dialogRef = this.dialog.open(UserRegistrationComponent, {
        width: '80%', height: 'calc(100% - 48px)', position: { right: '0px', top: '0px' }, disableClose: true,
        data: { customerCode: patientCode, isCustomerLoggedIn: false, isOpenFromCustomerList: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

      /* Name:Vivek Chauhan
      use: for open Sent template on emails popup */
      sendTemplate(customer = null): void {
        if(customer?.customerEmail){
          const dialogRef = this.dialog.open(DirectEmailSendComponent, {
            width: '850px',
            height: 'auto',
            maxHeight: '94vh',
            maxWidth:'850px',
            disableClose: true,
            data: { selectedEmail : customer?.customerEmail, isSend: true}
          });
      
          dialogRef.updatePosition({
            top: '1%'    // Set top position
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result === 'success') {
              // Do Nothing
            }
          });
        }
      }

  /* Name:Shailesh
     use: to show and hide serach click*/
  filterToggle() {
    this.show = !this.show;

    if (this.show) {
      this.searchClick = "Hide";
    }
    else {
      this.searchClick = "Show";
    }
  }

  /* Name:Shailesh
     use: for infinite scroll*/
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.getData(this.page, this.limit, 'scroll');
  }

  Active_InActiveCustomer(element:any , index:number){

    let confirmMSG = "Are you sure want to "+ (element.isActive ? 'Inactive ' : 'Active ') +"customer "+ (element?.customerName && this.service.common.toTitleCase(element.customerName)) +" ?" ; 

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: confirmMSG }
    });

    dialogRef.afterClosed().subscribe(result => {
      
         if(result && result.toLowerCase() === 'ok')
         {
              let data= {
              "customerCode": element.customerCode,
              "isActiveStatus": !element.isActive
              }
              this.service.common.progressLoader = true;
              this.service.Active_OR_InActiveCustomer(data).subscribe((response:any) =>{

              if (response.responsecode == 200) {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.successMessage = true;
              this.refreshRestAllFilters();
              } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
              }

              })
         }

    });

 }

 onFileChange(event , selectedEle) {
  const target = event.target;
  if (event.target.files.length > 0) {
    let allowedExtension = ['jpeg', 'jpg', 'png'];
    let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
     let index =  allowedExtension.findIndex((ele:any) => ele === fileExtension);

     if(index == -1){
      this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') ;
      this.service.common.errorMessage = true;
      event.target.value = '';
      return;
     }

     if(event.target.files[0].size >= 2000000)
     {
      this.service.common.message = 'file size should be less then 2MB';
      this.service.common.errorMessage = true;
      event.target.value = '';
      return;
     }

       const formData = new FormData();
        formData.append("file", event.target.files[0]);
        this.service.common.progressLoader = true;
        this.service.common.uploadImage(formData, (res) => {
          this.service.common.progressLoader = false;
           if(res.responsecode == 200){
            // res.filename
            this.saveCustomerLogo(res.filename , selectedEle.customerCode)
           }
           else{
            this.service.common.message = "Failed to upload file";
            this.service.common.errorMessage = true;
           }
          });

  }
}


saveCustomerLogo(filename , customerCode){
  
  if(filename && customerCode)
  {
    let JSON = {
      "customerCode":  customerCode,
      "customerLogo": filename
    }
    this.service.common.progressLoader = true;
    this.service.saveCustomerLogo(JSON).subscribe((response:any) =>{
      this.service.common.progressLoader = false;
      if (response.responsecode == 200) {
        this.service.common.message = response.message;
         this.service.common.successMessage = true;
         this.refreshRestAllFilters();
        } else {
        this.service.common.message = response.message;
         this.service.common.errorMessage = true;
        }
    })

  }

}

changeView(value:string){
  this.viewPoint = value ;
  this.refreshRestAllFilters();
}

private selectedData_forNotes : any ;

openNotePopUp(data:any, name = null)
{

  this.selectedData_forNotes = data ;

  if(!data.isNoteAdded)
    {
      this.addUpdateNotes([], name);
    }
   else
    {
      this.service.common.progressLoader = true;
      this._commonAPI_SVC.getNotesByEntityCode(this.service.local.get('orgCode'), 'Customer', data.customerCode).subscribe((res:any)=>{
        this.service.common.progressLoader = false;

        if(res.responsecode === 200)
          {
            console.log('Res: ', res);
               this.addUpdateNotes(res.data, name);
          }
     });
    }
   
 
}


addUpdateNotes(noteArray:[], noteTitleOpenFor = null)
{
  const dialogRef = this.dialog.open(AddUpdateNoteComponent, { 
    width: '50%',
    disableClose: true,
    panelClass : 'popup-pos',
    data: {
      noteArray: noteArray,
      noteTitleOpenFor: noteTitleOpenFor
     }
  }); 

  dialogRef.afterClosed().subscribe((result) => {
     if(result)
    this.saveAndMapNotesEntity(result);
  });

}


saveAndMapNotesEntity(data)
{
  const payload ={
    "entityName": "Customer",
    "entityUuid": this.selectedData_forNotes.customerCode ,
    "isPublic": data.isPublic,
    "note": data.note,
    "organizationCode": this.service.local.get('orgCode')
  }
  // console.log('S_Data: ', this.selectedData_forNotes, payload);
  
  // return;
  this.service.common.progressLoader = true;
  this._commonAPI_SVC.saveAndMapNotesEntity(payload).subscribe((res:any) =>{
    this.service.common.progressLoader = false;
   if(res.responsecode == 200)
    {
       this.service.common.message = res.message;
         this.service.common.successMessage = true;
         this.refreshRestAllFilters();
         this.commonSVC.resetNotesRequestForm();
    }
    else{
      this.service.common.message = res.message;
         this.service.common.errorMessage = true;
    }

  })
}


subToPermissionChanged()
{
 this.commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this.commonSVC.getBtnEventPermissions("Customers");
     }
 })
}


}
