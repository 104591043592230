import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProvidersDashboardService } from '../../providers-dashboard.service';
import { CancelAppointmentComponent } from '../../appointment/cancel-appointment/cancel-appointment.component';
import { TradeShiftComponent } from '../../appointment/trade-shift/trade-shift.component';
import { AssignAppointmentComponent } from '../../appointment/assign-appointment/assign-appointment.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/common.service';
import { CreateAppointmentService } from '../../appointment/create-appointment/create-appointment.service';
import { AppointmentService } from '../../appointment/appointment.service';
import { PatientService } from '../patient.service';
import { ShowRoutesComponent } from '../../appointment/show-routes/show-routes.component';
import * as moment from 'moment';
import { NewAppointmentService } from 'src/app/dashboards/technician-dashboard/new-appointment/new-appointment.service';
import { RejectAppointmentComponent } from 'src/app/dashboards/technician-dashboard/new-appointment/reject-appointment/reject-appointment.component';
import { TranslateService } from '@ngx-translate/core';
import { ProcedureDetailComponent } from '../../appointment/procedure-detail/procedure-detail.component';
import { NotesPopupComponent } from '../../appointment/notes-popup/notes-popup.component';
import { ArrivalDepartureComponent } from '../../arrival-departure/arrival-departure.component';
import { UpdateAppointmentSeriesComponent } from '../../appointment/update-appointment-series/update-appointment-series.component';
import { UpdateAppointmentComponent } from '../../appointment/update-appointment/update-appointment.component';
export interface DialogData {

}

@Component({
  selector: 'app-dashboard-patient-data',
  templateUrl: './dashboard-patient-data.component.html',
  styleUrls: ['./dashboard-patient-data.component.scss']
})
export class DashboardPatientDataComponent implements OnInit {

  appointmentStatus = [];
  showPopup;
  searchTerm;
  allFilteredData = [];
  // noPatient= "No Patient Data Found";
  appointmentchange;
  currentUrl;
  showImg: any;
  ScheduleCountData;




  constructor(
    public dialogRef: MatDialogRef<DashboardPatientDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public service: ProvidersDashboardService,
    public router: Router, public common: CommonService,
    public dialog: MatDialog,
    public createPatientService: CreateAppointmentService,
    public serviceAppointment: AppointmentService,
    public patientService: PatientService,
    public newAppointmentService: NewAppointmentService,
    public translate: TranslateService
  ) { this.translate.setDefaultLang(this.service.session.get('language')); }

  ngOnInit() {
    //////console.log(this.data['url']);
    this.currentUrl = this.data['url'];
    this.appointmentStatus = this.data['data'];
    this.allFilteredData = this.data['data'];

    this.allFilteredData.forEach((element) => {
      if (element.actualStartTime) {
        element.arrivalTimeDate = moment(new Date(element.actualStartTime), element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      }
      if (element.actualEndTime) {
        element.departureTimeDate = moment(new Date(element.actualEndTime), element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      }
    })


    ////console.log(this.appointmentStatus);
    ////console.log(this.data['startDate'])
    ////console.log(this.data['WeekstartDate'])
    ////console.log(this.data['weekendDate'])
    ////console.log(this.data['patientCode'])
    this.getAllhours(this.data['patientCode'],this.data['startDate'],this.data['WeekstartDate'],this.data['weekendDate'])


  }

 /* Name:Shailesh
      use: to show and hide icon images*/
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }

   /* Name:Shailesh
      use: for getting all job by org code with filter*/
  getAppointments() {
    if (this.currentUrl == 'patient') {
      this.showPopup = '';

      try {
        this.patientService.getAllJobsByOrganizationCodeWithFilter(this.service.session.get('orgCode'), this.data['status'], this.data['startDate'], this.data['endDate'], this.data['patientCode'], (response) => {
        //////console.log(response);
        if (response.responsecode == 200) {
          this.appointmentStatus = response.data;
          this.allFilteredData = response.data;

          this.allFilteredData.forEach((element) => {
            if (element.actualStartTime) {
              element.arrivalTimeDate = moment(new Date(element.actualStartTime), element.timeFormat).format(element.timeFormat);
              ////console.log(element.arrivalTimeDate);
            }
            if (element.actualEndTime) {
              element.departureTimeDate = moment(new Date(element.actualEndTime), element.timeFormat).format(element.timeFormat);
              ////console.log(element.departureTimeDate);
            }
          })
          ////console.log(this.allFilteredData);
        }
      });
      } catch (error) {
        //console.log(error)
      }
      
    } else {
      this.showPopup = '';
      try {
        this.service.adminGetAllJobsByWorkerCodeAndDate(this.data['patientCode'], this.data['startDate'], (response) => {
        //////console.log(response);
        if (response.responsecode == 200) {
          this.appointmentStatus = response.data;
          this.allFilteredData = response.data;

          this.allFilteredData.forEach((element) => {
            if (element.actualStartTime) {
              element.arrivalTimeDate = moment(new Date(element.actualStartTime), element.timeFormat).format(element.timeFormat);
              ////console.log(element.arrivalTimeDate);
            }
            if (element.actualEndTime) {
              element.departureTimeDate = moment(new Date(element.actualEndTime), element.timeFormat).format(element.timeFormat);
              ////console.log(element.departureTimeDate);
            }
          })
          ////console.log(this.allFilteredData);
        }
      });
      } catch (error) {
        //console.log(error)
      }
      
    }
  }

   /* Name:Shailesh
      use: for searching appointments , clinician and patient*/
  search() {
    // this.service.common.progressLoader = true;
    if (this.searchTerm) {
      //////console.log(this.search);
      this.appointmentStatus = this.allFilteredData.filter((item) => {
        if (this.currentUrl == 'patient') {
          return item.workerName.toLowerCase().includes(this.searchTerm.toLowerCase());
        } else {
          return item.patientName.toLowerCase().includes(this.searchTerm.toLowerCase());
        }

      });
      // this.service.common.progressLoader = false;
    } else {
      // this.service.common.progressLoader = false;
      this.appointmentStatus = this.allFilteredData;
      // noPatient = this.allFilteredData;
      // return noPatient;
    }
  }

   /* Name:Shailesh
      use: to show and hide edit popver*/
  geteditData(element) {
    //////console.log(element);
    //////console.log(this.showPopup);
    if (this.showPopup == element.appointmentsDurationCode) {
      this.showPopup = '';
    } else {
      this.showPopup = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }

 /* Name:Shailesh
      use: to open pop up for update and update series appointment*/
  route(routeTO, appointmentsDurationCode, jobsCode) {
    if (routeTO == 'update-appointment') {
      //this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: appointmentsDurationCode } });
      const dialogRef = this.dialog.open(UpdateAppointmentComponent, {
        width: '70%',
        disableClose: true,
        data: { jobDurationCode: appointmentsDurationCode}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(result != undefined){
          this.appointmentchange = true;
          this.dialogRef.close(this.appointmentchange);
        }
      });
    } else if (routeTO == 'update-appointment-series') {
      //this.router.navigate(['/dashboard/update-appointment-series'], { queryParams: { jobCode: jobsCode } });
      const dialogRef = this.dialog.open(UpdateAppointmentSeriesComponent, {
        width: '70%',
        disableClose: true,
        data: { jobCode: jobsCode}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
        if(result != undefined){
          this.appointmentchange = true;
          this.dialogRef.close(this.appointmentchange);
        }
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close(this.appointmentchange);
  }

   /* Name:Shailesh
      use: for assigning appointment to clinician*/
  assignAppointment(jobDurationCode) {
    this.service.common.progressLoader = true;
    try {
      this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode,this.data['startDate'],this.data['WeekstartDate'],this.data['weekendDate'], (data) => {
      const dialogRef = this.dialog.open(AssignAppointmentComponent, {
        width: '750px',
        data: { data, jobDurationCode }
      });

      dialogRef.afterClosed().subscribe(result => {
        //////console.log(result);
        if (result != undefined) {
          this.appointmentchange = true;
          this.common.message = result;
          this.common.successMessage = true;
        }
      });
    });
    } catch (error) {
      //console.log(error)
    }
    

  }

   /* Name:Shailesh
      use: to open tradeshift popup*/
  tradeShift(data) {
    const dialogRef = this.dialog.open(TradeShiftComponent, {
      width: '450px',
      data: { data }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.appointmentchange = true;
        this.getAppointments();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }

   /* Name:Shailesh
      use: to open cancel appointment popup*/
  cancelAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(CancelAppointmentComponent, {
      width: '450px',
      data: { jobDurationCode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.appointmentchange = true;
        this.getAppointments();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }

   /* Name:Shailesh
      use: for assigned appointment accept by clinician by worker code*/
  acceptAppointment(jobDurationCode, workerCode) {
    const data = {
      'workerCode': workerCode
    }
    try {
      this.serviceAppointment.assignToWorkerAcceptedByJobDurationCode(jobDurationCode, data, (res) => {
      if (res.responsecode == 200) {
        this.getAppointments();
        this.service.common.message = res.message;
        this.service.common.successMessage = true;
      } else {
        this.service.common.message = res.message;
        this.service.common.errorMessage = true;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }


   /* Name:Shailesh
      use: for route by technician by jobduration code*/
  showRoute(data) {
    //////console.log(data);
    let startdate;
    let startDate;

    if (this.currentUrl == 'patient') {
      startdate = data.startTime.split(' ')[0];
    } else {
      startdate = data.appointmentStartDate.split(' ')[0];
    }

    if (data.dateFormat == 'MM-dd-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[0] - 1, +startdate.split('-')[1]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else if (data.dateFormat == 'dd-MM-yyyy') {
      startDate = moment(new Date(+startdate.split('-')[0], +startdate.split('-')[1] - 1, +startdate.split('-')[2]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    } else {
      startDate = moment(new Date(+startdate.split('-')[2], +startdate.split('-')[1] - 1, +startdate.split('-')[0]), 'DD-MM-YYYY').format('YYYY-MM-DD');
      //////console.log(startDate);
    }

    try {
      this.service.mobileGetRouteByTechnicianCodeDateAndJobdurationcode(data.workerCode, startDate, data.appointmentsDurationCode, (jobres) => {
      if (jobres.responsecode == 200) {

        const dialogRef = this.dialog.open(ShowRoutesComponent, {
          width: '550px',
          height: '575px',
          data: { workerCode: data.workerCode, appointmentsDurationCode: data.appointmentsDurationCode, date: data.appointmentStartDate, data: jobres.data }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.appointmentchange = true;
            this.getAppointments();
            this.service.common.message = result;
            this.service.common.successMessage = true;
          }
        });
        //      //////console.log(jobres);
        // this.jobRouteData = jobres.data;
        // this.googleMap(this.jobRouteData);
      }
      // this.service.common.loader = false;
    })
    } catch (error) {
      //console.log(error)
    }
    


  }

 /* Name:Shailesh
      use: for rejecting the appointment*/
  rejectAppointment(jobcode, userCode) {
    const dialogRef = this.dialog.open(RejectAppointmentComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //////console.log('The dialog was closed');
      if (result != undefined) {
        const data = {
          'comment': result,
          'workerCode': userCode
        }
        try {
          this.newAppointmentService.assignToWorkerrejectedByJobDurationCode(jobcode, data, (res) => {
          if (res.responsecode == 200) {
            this.service.common.message = res.message;
            this.service.common.successMessage = true;
            this.getAppointments();
          } else {
            this.service.common.message = res.message;
            this.service.common.errorMessage = true;
          }
        })
        } catch (error) {
          //console.log(error);
        }
        
      }
    });
  }

   /* Name:Shailesh
      use: for getting procedure detail on popup*/
  getProcedureDetails(procedure, notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: { procedure: procedure, notes: notes }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });

  }

  getNotes(note) {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      width: '450px',
      data: note
    });
  }

 /* Name:Shailesh
      use: for getting schedule count detail*/
  getAllhours(workerCode, selectedDate, startdate, enddate) {
    try {
       this.service.mobileAdminGetScheduleCountDetailsByWorkerCodeScheduleDateAndDateRange(workerCode, selectedDate, startdate, enddate, (response) => {
      ////console.log(response);
      if (response.responsecode == 200) {
        this.ScheduleCountData = response.data;
      }
    })
    } catch (error) {
      //console.log(error)
    }
   
  }

   /* Name:Shailesh
      use: for arrival departure popup*/
  openArrivalDialog(arrival, departure): void {
    const dialogRef = this.dialog.open(ArrivalDepartureComponent, {
      width: '350px',
      data: { arrival: arrival, departure: departure }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}
