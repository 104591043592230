import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class TechProfileService {
  
  constructor(public service: ApiRootService, public http: HttpClient, public storage: SessionStorageService, public common: CommonService) { }
/* Name:srinivas
      use: removes the organization. */
  mobileAdminGetEligibleOrganisationListByTechnicianCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/mobileAdminGetEligibleOrganisationListByTechnicianCode/' + code ).subscribe((data) => {
      callback(data);
    })
  }
/* Name:srinivas
      use: removes the organization. */
  mobileAdminAssignUnassignWorkerToOrganization(workerCode, data, callback){
    return this.http.put(this.service.getHealthCareUrl()+'/mobileAdminAssignUnassignWorkerToOrganization/' + workerCode, data).subscribe((data)=>{
      callback(data);
    })
  }
/* Name:srinivas
      use: removes the organization. */
  mobileAdminSetOrgActivateStatusByTechnicianCode(workerCode, data, callback){
    return this.http.put(this.service.getHealthCareUrl()+'/mobileAdminSetOrgActivateStatusByTechnicianCode/' + workerCode, data).subscribe((data)=>{
      callback(data);
    })
  }
/* Name:srinivas
      use: removes the organization. */
  getWorkerApprovalStatusByWorkerCode(code, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getWorkerApprovalStatusByWorkerCodeV2/' + code ).subscribe((data) => {
      callback(data);
    })
  }
  /* Name:srinivas
      use: removes the organization. */
  getAllSkillMaster(callback) {
    return this.http.get(this.service.getHealthCareUrl()+'/getAllSkillMaster').subscribe((data)=>{
      callback(data);
    }) 
  }
  /* Name:srinivas
      use: removes the organization. */
  getAllCertificationsMaster(callback){
    return this.http.get(this.service.getHealthCareUrl()+'/getAllCertificationsMaster').subscribe((data)=>{
      callback(data);
    })
  }
}
