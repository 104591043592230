import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { CommonService } from 'src/app/shared/common.service';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root'
})
export class TeamManagementService {

  constructor(
    public http: HttpClient,
    public _apiSVC: ApiRootService,
    public common: CommonService,
    public storage: SessionStorageService,
  ) { }


  getAllDistributionList(org_code:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getDistributionListByOrganizationCodeByPage/' + org_code+ '?page=0&limit=1000')
  }

  PublishJob(request_body) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/publishJobs' , request_body)
  }


  getEligibleStaffsByAppointmentsDurationCode(appoinmentDurationCode:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleTechnicianWithoutPreferredHoursForAppointmentByAppointmentsDurationCode/'+ appoinmentDurationCode)
  }

  assignJob(appoinmentDurationCode:string , workerCode : string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobAssignedByAdmin/'+ appoinmentDurationCode +'/' + workerCode)
  }

  // Vivek - Get
  getAllCustomerSiteDetailsWithCustomerByOrganizationCode(organizationCode:string){
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllCustomerSiteDetailsWithCustomerByOrganizationCode/'+ organizationCode)
  }

  getDecliedAndLateListByOrganizationCode(organizationCode:string, status:string)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getDecliedAndLateListByOrganizationCode/'+ organizationCode +'/' + status)
  }

  getEligibleStaffs(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaff/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date )
  }

  jobAssignedByAdminJobCode(appointmentsCode:string, appointmentsProceduresCode:string, date:string, workerCode:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobAssignedByAdminJobCode/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date +'/' +workerCode )
  }

  getNotifiedUserList(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getNotifiedUserList/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date)
  }
 

  jobAssignedToManyStaffByAdmin(payload:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/jobAssignedToManyStaffByAdmin', payload)
  }

  saveClinicianCheckinDetail(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveClinicianCheckinDetailsAdminDashboard' , request_body)
  }

  saveAndUpdateTeamMaster(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveAndUpdateTeamMaster' , request_body)
  }
 
  adminGetEligibleStaffForNotify(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaffForNotify/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date )
  }

  createNewJob(request_body:any){
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/createJob' ,request_body);
 
  }

  UnAssignStaff(appointmentsDurationCode:any)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobUnAssignedByAdmin/'+appointmentsDurationCode);
 
  }
 
  deleteJob(appointmentsCode:string, date:string, appointmentsProcedureCode:string,  organizationCode:string)
  {
    return this.http.delete(this._apiSVC.getHealthCareUrl() + `/deleteJob/${appointmentsCode}/${date}/${appointmentsProcedureCode}/${organizationCode}`);
 
  }

//   New Changes
// GET  API
// /staffingservice/getAllTeamsByPage/{organizationCode}
// /staffingservice/getAllTeamsByPage/{organizationCode}/{search}

    /* Name:Vivek Chauhan
  use: for getting All Team List by OrganizationCode */
  getAllTeamsByPage(organizationCode, page, limit, search, callback) {
    let apiURL = search ? this._apiSVC.getHealthCareUrl() + '/getAllTeamsByPage/' + organizationCode + '/' + search +'?page=' + page + '&limit=' + limit : this._apiSVC.getHealthCareUrl() + '/getAllTeamsByPage/' + organizationCode + '?page=' + page + '&limit=' + limit;
    return this.http.get(apiURL).subscribe((data) => {
      callback(data);
      //console.log(data);
    })
  }

}
