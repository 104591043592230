<!-- <ng-template [ngIf]="is_JobCopied">
  <div class="card-calendarStrip">

    <div class="row">
      <div class="col-4">
      Create (or paste) job for the date :
      </div>

      <div class="col-8">
       <div class="d-flex">
          <ng-container *ngFor="let c_days of _commonSVC.CardCalendarDays_Strip">
        <div class="cal-day-box" [class.disabled]="c_days.isWorkingDay == false || showActionsButtons(c_days.date)"
         (click)="c_days.isWorkingDay && !showActionsButtons(c_days.date) && pasteJobOnSelectedDay(c_days)">
          <span>{{c_days.dayName}}</span>
          <span>{{c_days.value}}</span>
        </div>
        </ng-container>

        <div class="ms-4">
          <i class="material-icons icon-left" title="previous seven days" *ngIf="!showPreviousBtn(_commonSVC.CardCalendarDays_Strip[0].date)" (click)="PreSevenDays()">keyboard_arrow_left</i>
          <i class="material-icons icon-right ms-2" title="next seven days" (click)="nextSevenDays()">keyboard_arrow_right</i>
        </div>
  
        <div class="ms-4" (click)="cancelCopyPaste()">
          <mat-icon class="icon-cancel" title="cancel copy/paste"  style="background: rgb(241 18 18 / 60%)">close</mat-icon>
        </div>

        </div>
      
      </div>
   </div>

  </div>
</ng-template> -->

<div class="job-card-parent-div">
  <ng-template [ngIf]="dataSource.length > 0" [ngIfElse]="_noDataFound">
    <div class="viewHeight">
      <ng-container *ngFor="let parent_element of dataSource">
         <mat-card class="mx-auto card-appointment mt-3 mb-2">

          <ng-container *ngFor="let parent_job of parent_element.jobParentResList; let pjIdx = index;">
            <ng-container *ngFor="let element of parent_job.jobTypeWithShiftList; let cjIdx = index; let last = last">
          
              <div class="Card_JobDetails">
                <div 
                class="row job-content container mb-2 card-border"
                 [style.border-color]="element.jobColor"
                 [class.childCardBorder]="pjIdx > 0 || cjIdx > 0"
                   >
                   <!-- [class.job-card-active]="copiedJobData?.jobData?.appointmentsProceduresCode === element.appointmentsProceduresCode" -->
                  <div class="col-md-12 col-lg-12">
                    <!-- <div class="row div-border mb-2 mt-2" *ngIf="pjIdx > 0 || cjIdx > 0"></div> -->
                    <!-- <div class="row div-border">
                      <div class="col-1">
                        <div class="row">
                          <div class="card-jobdate" [style.color]="element.jobColor">
                            <span>{{parent_element?.week_day}}</span>
                            <span class="month-day">{{parent_element?.month_day}}</span>
                            <span>{{parent_element?.month_name}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="row">
                           <div class="col">
                            <div class="row">
                              <small>Customer Name </small><br>
                              <p>{{parent_element?.customerName}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="row">
                          <small>{{ 'main.labels.Job Site' | translate }} </small><br>
                          <p [title]="parent_element.siteAddress"> {{parent_element?.siteName}}</p>
                        </div>
                      </div>
 
                      <div class="col-3">
                        <div class="row">
                          <div class="col p-0 m_width_50">
                            <div class="w-100">
                              <img class="img_jobtype"
                                [src]="element.jobTypeIcon ? img_serverPath+element.jobTypeIcon : 'assets/common_images/profile.jpg'"
                                onerror="this.src='assets/common_images/profile.jpg';">
                            </div>
                          </div>
                          <div class="col  ps-2">
                            <div class="row">
                              <small>Job Type </small><br>
                              <p>{{element?.jobTypeName}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template [ngIf]="element?.showRateCard">
                        <div class="col-lg-2">
                          <div class="row">
                            <small>Billing Rate/Hr </small><br>
                            <p> $&nbsp;{{element?.showRateCard}}</p>
                          </div>
                        </div>
                      </ng-template>

                    </div> -->

                    <app-cust-short-job-type-dts
                     [shjobTypeDtsInput]="{
                      date         : parent_element.jobDateFixedFormat ,
                      customerName : parent_element?.customerName,
                      siteName     : parent_element?.siteName,  
                      siteAddress  : parent_element.siteAddress, 
                      jobTypeName  : element?.jobTypeName,
                      jobTypeIcon  : element.jobTypeIcon,
                      rateCard     : element?.showRateCard,  
                      jobTypeColor : element.jobColor    
                    }">
                   </app-cust-short-job-type-dts>

                   <app-shift-with-job-type
                   [shiftWithJobTypeInput]="{
                        jobTypeWithShiftList : element,
                       customerName : parent_element.customerName ,
                        siteName : parent_element.siteName ,
                       siteAddress : parent_element.siteAddress ,
                       appointmentsCode :  parent_job.appointmentsCode,
                       customerCode  : parent_element.customerCode,
                      siteAddressCode  : parent_element.siteAddressCode,
                      jobDateFixedFormat : parent_element.jobDateFixedFormat,
                      date : parent_element.jobDateFixedFormat
                   }"
                    [allowActions]="allowActions"
                   [status]="status"
                   (updateParent)="handleEvent($event)">
               </app-shift-with-job-type>
 
                    <!-- <div class="row div-border">
                      <div class="col-4">
                        <div class="row text-nowrap">
                          <small>Shift</small><br>
                          <p class="text-nowrap w-100">{{element?.startTime}}&nbsp;to &nbsp;{{element?.endTime}}</p>
                        </div>
                        <ng-template [ngIf]="element.breakList.length > 0">
                          <div class="row" style="font-size: 12px;" *ngFor="let break of element.breakList">
                            <div class="col-3 text-capitalize"> {{break.breakType}} </div>
                            <div class="col-6"> {{break.durationMin}}&nbsp;min &nbsp; {{break.paidStatus =='Paid' ? '$'
                              : ''}}</div>
                          </div>
                        </ng-template>
                      </div>

                      <div class="col-3">
                        <div class="row">
                          <small>Required </small><br>
                          <p>{{element.required}}</p>
                        </div>
                      </div>

                      <div class="col-3 text-capitalize">
                        <div class="row">
                          <small>{{status ? status : 'Scheduled'}} </small><br>
                          <p>{{element.scheduled}}</p>
                        </div>
                      </div>

                      <div class="col-2">

                        <ng-template [ngIf]="element.jobDurationDetailsList.length > 0">
                        <mat-icon class="user-grp me-3"
                          (click)="showScheduledStaff(
                            parent_element.customerName,
                            parent_element.jobDateFixedFormat,
                            parent_element.siteName,
                            parent_element.siteAddress,
                            parent_job.appointmentsCode,
                            element,
                            schdeuledStaff_popUp
                              )"
                              >supervisor_account
                        </mat-icon>
                        </ng-template>

                        <mat-icon
                        (click)="CardViewInfo(element)">info
                        </mat-icon>
                      </div>
 
                     </div>

                    <div class="row">

                      <div class="col-6 text-start">
                         

                        <ng-template [ngIf]="element.isPassedStartTime === true">
                          <button title="Date: {{parent_element.jobDate}}" [style.color]="element.jobColor" class=" btn-jobcard me-2"
                            (click)="CardViewJob(parent_job.appointmentsCode, element.appointmentsProceduresCode, parent_element.jobDate)">
                            View
                          </button>
                          </ng-template>
 
                          <ng-template [ngIf]="allowToCopyJob">
                          <button [style.color]="element.jobColor" class=" btn-jobcard me-2"
                            (click)="CopyJob(parent_element.customerCode, parent_element.siteAddressCode, element)">
                            Copy
                          </button>
                        </ng-template>

                          <ng-template [ngIf]="!element.isPassedStartTime">
                            <button [style.color]="element.jobColor" class=" btn-jobcard me-2"
                              (click)="CardEditJob(parent_job.appointmentsCode, element.appointmentsProceduresCode, parent_element.jobDateFixedFormat)">
                              Edit
                            </button>
                            </ng-template>

                            <ng-template [ngIf]="!element.isPassedStartTime">
                            <button class="btn-jobcard" [style.color]="element.jobColor"
                            (click)="deleteJob(parent_job.appointmentsCode, element.appointmentsProceduresCode, parent_element.jobDateFixedFormat)">
                            Delete
                          </button>
                          </ng-template>

                          <span class="job-copied"
                            *ngIf="is_JobCopied && copiedJobData?.jobData?.appointmentsProceduresCode === element.appointmentsProceduresCode">
                            this job has been copied*</span>
                        
                      </div>

                      <div class="col-6 text-end p-0">

                        <ng-template [ngIf]="!element.isPassedEndTime">
                          <ng-template
                            [ngIf]="!(element?.required == element?.scheduled && (status?.toLowerCase() === 'accepted' || status?.toLowerCase() === 'scheduled' ))">

                            <button class="btn-jobcard me-1" [style.color]="element.jobColor"
                              (click)="openAssignPopUp(parent_job.appointmentsCode, element.appointmentsProceduresCode, element.date)">
                              Assign
                            </button>

                            <button class="btn-jobcard me-1" [style.color]="element.jobColor"
                              (click)="openNotifyPopUp(parent_job.appointmentsCode, element.appointmentsProceduresCode, element.date)">
                              Notify
                            </button>

                            <button class="btn-jobcard" [style.color]="element.jobColor"
                              *ngIf="userType !== 'Staff' && userType !== 'Customer'"
                              (click)="parent_job?.appointmentsCode && element?.appointmentsProceduresCode && element?.date && getNotifyList('notified staff', parent_job.appointmentsCode, element.appointmentsProceduresCode, element.date)">
                              Notified Staff List
                            </button>

                          </ng-template>
                        </ng-template>
                      </div>
                    </div> -->
 
                  </div>

                </div>

              </div>
            </ng-container>
          </ng-container>



        </mat-card>
        <!-- </div> -->
      </ng-container>

    </div>


  </ng-template>

  <ng-template #_noDataFound>
    <div class="row no-job-found-div" *ngIf="!is_Loading">
      <span class="text-danger no-job-found-text">No jobs found</span>
    </div>

    <ng-template [ngIf]="is_Loading">
      <div class="div w-100 mt-5 text-center">
        <mat-spinner class="center_50_px_spinner"></mat-spinner>
        <p class="mt-3 mb-3 w-100 text-center">Please Wait ...</p>
      </div>
    </ng-template>

  </ng-template>

</div>

<!-- 
<ng-template #EditJob_PopUp>
  <app-new-job [EditJobmodalData]="EditJobmodalData" (event)="editJob_RefreshList($event)"></app-new-job>
</ng-template>

<ng-template #ViewInfo_PopUp>

  <div class="row mb-3">
    <div class="col-md-6 align-self-center">
      <h6 class="mb-0 dialog-header">Job Details</h6>
    </div>
    <div class="col-md-6 text-end">
      <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-3">
     Uniform
    </div>
    <div class="col-9">
     :&nbsp; {{jobInfo?.uniform ? jobInfo?.uniform : '--'}}
    </div>
  </div>
 
  <div class="row mb-2">
    <div class="col-3">
     Equipment
    </div>
    <div class="col-9">
      :&nbsp; {{jobInfo?.equipments ? jobInfo?.equipments : '--'}}
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-3">
     Supervisor
    </div>
    <div class="col-9">
      :&nbsp; {{jobInfo?.supervisorName ? jobInfo?.supervisorName : '--'}}
    </div>
  </div>

  
  <div class="row mb-2">
    <div class="col-3">
      Special Instructions
    </div>
    <div class="col-9">
      :&nbsp; {{jobInfo?.specification ? jobInfo?.specification : '--'}}
    </div>
  </div>

</ng-template>

<ng-template #ViewJobDetails_PopUp>
  <app-view-job-details [Job_ModalData]="View_JobmodalData" (event)="dialogRef.close();"></app-view-job-details>
</ng-template>




<ng-template #schdeuledStaff_popUp>
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Scheduled Staff</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row">
   
  <app-common-card 
  [job_shortData_Modal]="job_shortData_Modal"
  (emitter_handleEvent)="refreshData($event)">
</app-common-card>

  </div>
</ng-template> -->
