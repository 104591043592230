
<div class="view-job-details-parent-div" [class.CalViewPop]="Job_ModalData?.isOpenFrom === 'WeeklyCalendar_View'">

<div class="row w-100  mb-3 logo ">
    <div class="col-6 text-start">
      <div class="float-start header-name">Job Details</div>
   </div>
 

  <div class="col-6 text-end">
    <button  class="btn-close cursor-pointer" (click)="closeBsPopUp()"></button>
  </div>
  </div>

<ng-template [ngIf]="jobDetails">
    <div class="job-details">
   <div class="row mb-3">
        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">Customer</label>
            <label class="lbl_value">{{jobDetails.customerName ? jobDetails.customerName : '--'}}</label>
        </div>
        </div>

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">Job Date</label>
            <label class="lbl_value">{{jobDetails.jobDate ? jobDetails.jobDate : '--'}}</label>
            </div>
        </div>

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">{{ 'main.labels.Job Site' | translate }}</label>
            <label class="lbl_value">{{jobDetails.siteName ? jobDetails.siteName : '--'}}</label>
            </div>
        </div>

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">Job ID</label>
            <label class="lbl_value">{{jobDetails.jobId ? jobDetails.jobId : '--'}}</label>
            </div>
        </div>
    </div>


    <div class="row mb-3">
        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">Site Address</label>
            <label class="lbl_value">{{jobDetails.siteAddress ? jobDetails.siteAddress : '--'}}</label>
            </div>
        </div>

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">{{ 'main.labels.Job Site' | translate }} Contact</label>
            <label class="lbl_value">{{jobDetails.siteContact ? jobDetails.siteContact : '--'}}</label>
            </div>
        </div>

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">{{ 'main.labels.Job Site' | translate }} Contact Ph</label>
            <label class="lbl_value">{{jobDetails.siteContactPh ? jobDetails.siteContactPh : '--'}}</label>
            </div>
        </div>

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">Order Date</label>
            <label class="lbl_value">{{jobDetails.orderDT ? jobDetails.orderDT : '--'}}</label>
            </div>
        </div>
    </div>

    <hr>

    <div class="row g-1 mb-3">

        <div class="col-3">
            <div class="d-flex flex-column">
            <label class="lbl_title">Shift</label>
            <label class="lbl_value">{{jobDetails.startTime}} - {{jobDetails.endTime}}</label>
            </div>

            <ng-template [ngIf]="jobDetails.breakList && jobDetails.breakList.length > 0">
                <div class="row" style="font-size: 12px;" *ngFor="let break of jobDetails.breakList ">
                  <div class="col text-capitalize"> {{break.breakType}} </div>
                  <div class="col"> {{break.duration}}&nbsp;min &nbsp; {{break.paidStatus ? '$'
                    : ''}}</div>
                </div>
              </ng-template>

        </div>

        <div class="col-2">
            <div class="d-flex flex-column">
            <label class="lbl_title">Job Request</label>
            <label class="lbl_value">{{jobDetails.jobTypeName ? jobDetails.jobTypeName : '--'}}</label>
            </div>
        </div>

        <div class="col-1">
            <div class="d-flex flex-column">
            <label class="lbl_title">Job Qty</label>
            <label class="lbl_value">{{jobDetails.numberOfWorker ? jobDetails.numberOfWorker :'--'}}</label>
            </div>
        </div>

        <div class="col-2">
            <div class="d-flex flex-column">
            <label class="lbl_title">Billing Rate/Hr</label>
            <label class="lbl_value">{{jobDetails.ratePerHours ? '$'+jobDetails.ratePerHours : '--'}}</label>
            </div>
        </div>

        <div class="col-2">
            <div class="d-flex flex-column">
            <label class="lbl_title">Uniform</label>
            <label class="lbl_value">{{jobDetails.uniform ? jobDetails.uniform : '--'}}</label>
            </div>
        </div>

        <div class="col-2">
            <div class="d-flex flex-column">
            <label class="lbl_title">Equipment</label>
            <label class="lbl_value">{{jobDetails.equipments ? jobDetails.equipments : '--'}}</label>
            </div>
        </div>

    </div>

    <hr>

    <div class="row mb-3">

        <div class="col-6">
            <div class="d-flex flex-column">
            <label class="lbl_title">Site Supervisor</label>
            <label class="lbl_value">{{jobDetails.supervisorName ? jobDetails.supervisorName : '--'}}</label>
            </div>
        </div>

        <div class="col-6">
            <div class="d-flex flex-column">
            <label class="lbl_title"> Special Instructions </label>
            <label class="lbl_value">{{jobDetails.specification ? jobDetails.specification : ' -- '}}</label>
            </div>
        </div>

    </div>
 </div>
</ng-template>

</div>