<div class="row appoint-header mb-1 pt-4">
    <div class="col-lg-3 col-md-3 col-sm-12 search-tabview search">
        <input autocomplete="off" type="text" class="form-control" id="search-appointment"
            placeholder="{{ 'Jobs.labels.Search Staff' | translate }}" name="search-appointment"
            [(ngModel)]="searchTerm" (keyup)="search()">
        <mat-icon class="search_ic">search</mat-icon>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-1 align-items-center">
                <label for="pwd">{{ 'Jobs.labels.Date' | translate }}</label>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-12 date-tabview">
                <div class="form-group selected-date text-start">
                    <input [value]="date.value" (dateChange)="choosedDate($event)" class="form-control ps-2" matInput
                        #nonworkingday [matDatepicker]="picker1" (focus)="picker1.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 (closed)="nonworkingday.blur()"></mat-datepicker>
                    <div class="arrows">
                        <mat-icon (click)="arrowDateChange('left')">chevron_left</mat-icon>
                        <mat-icon (click)="arrowDateChange('right')">chevron_right</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5  col-sm-12 float-end">
        <ul class="swatches p-0">

            <li>
                <div>
                    <span class="appointment-swatch"></span> {{ 'Jobs.labels.Job' | translate }}
                </div>
            </li>
            <li *ngIf="userType !== 'Customer'">
                <div *ngIf="driveTime_settingValue === 'true'">
                    <span class="driving-swatch"></span> {{ 'Jobs.labels.Drive Time' | translate }}
                </div>
            </li>
            <li>
                <div class="assign-tabview mt-3">
                    <!-- <button *ngIf="userType !== 'Customer'" color="primary" mat-button mat-raised-button class="me-2" (click)="autoAssign()">{{
                        'Jobs.labels.Auto Assign' | translate }}
                    </button> -->
                     
                        <button color="primary" class="me-2" mat-button mat-raised-button
                            routerLink="/dashboard/appointmentlist">List
                            View</button>

                   <ng-template [ngIf]="controlsPermissions.addPermission === true">
                        <button mat-raised-button
                            *ngIf="userType === 'Customer' || actionPermissions?.addPermission == true"
                            title="Create Job" routerLink="/dashboard/new-job-creation" color="primary"
                            (click)="addDistribution()">
                            Add
                        </button>
                    </ng-template>
                </div>
            </li>
        </ul>
    </div>
</div>


<mat-progress-bar mode="indeterminate" *ngIf="service.common.infiniteLoader"></mat-progress-bar>
<div class="color-grid" *ngIf="statusCountList.length > 0">
    <div class="color-header">
        <div class="row">
            <div class="col-md-3 col-sm-12 align-self-center">
                <h4># {{ 'Jobs.labels.of Jobs' | translate }} <span
                        class="statusall">({{assignedStatusCount}}/{{allStatusCount}})</span> </h4>
            </div>
            <div class="col-md-9 col-sm-12 mb-3">
                <div class="row m-0 mb-3">
                    <div class="hours col p-0 text-center" *ngFor="let hr of statusCountList; let i = index">
                        {{hr.viewStartTime}}</div>
                </div>
                <div class="row m-0">
                    <div class="status-count col p-0 text-center cursor-pointer" [ngClass]="status.dataPercent"
                        *ngFor="let status of statusCountList; let i = index" (click)="openAppointmentCounts(status)">
                        <div class="statusCountData">
                            {{status.assignedCount}}/{{status.allCount}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="color-grid1 position-relative" id="color-grid1" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
        <div class="worker-detail">
            <div class="row" *ngFor="let worker of workerAppointmentList; let i = index">
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="d-flex">
                        <div class="img">
                            <img *ngIf="worker.workerLogo"
                                [src]="service.service.getSuperAdmin() + '/downloadFile/' + worker.workerLogo" alt="">
                            <img *ngIf="!worker.workerLogo" src="assets/common_images/profile.jpg" alt="">
                        </div>
                        <div class="detail">
                            <p class="name text-truncate cursor-pointer pointer" (click)="service.common.openStaffDetailPopup(worker.workerCode)"><span appPopover [appPopoverData]="worker" [placement]="'top'">{{worker.name}}</span></p>
                            <p class="email text-truncate">{{worker.email}}</p>
                        </div>
                        <div class="icons">
                            <mat-icon class="d-inline-block cursor-pointer" (click)="showRoute(worker)"
                                title="Show Route">where_to_vote</mat-icon>

                                <mat-icon class="d-inline-block cursor-pointer" title="Open chat"
                                (click)="openPerticularChat(worker.workerCode)">chat</mat-icon>

                            <!-- <mat-icon class="d-inline-block cursor-pointer" title="Open chat"
                                (click)="showChat(worker)">chat</mat-icon> -->
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div>
                        <hr class="hr-line">
                    </div>
                </div>

                <div class="col-lg-9 col-md-9 col-sm-12 m-0 mb-3">
                    <div class="row m-0">
                        <div class="hours booked-hours col p-0" *ngFor="let hrs of statusCountList; let m = index">
                            <div class="row m-0">
                                <span *ngFor="let i of hrs.interval" class="col p-0">
                                    <div *ngFor="let appointment of worker.appointmentDetailList; let n = index"
                                        [ngClass]="userType !== 'Customer' && driveTime_settingValue === 'true' && (i >= appointment.driveTime && i < appointment.startTime)?'bg-orange':''"
                                        [title]="(i >= appointment.driveTime && i < appointment.startTime)?'Distance: '+appointment.distanceText+' - Duration: '+appointment.durationText+'':(i >= appointment.startTime && i < appointment.endTime)?''+appointment.viewStartTime +' - '+ appointment.viewEndTime+'':''">
                                        <div [ngClass]="(i >= appointment.startTime && i < appointment.endTime)?'bg-blue':''"
                                            [title]="(i >= appointment.startTime && i < appointment.endTime)?''+appointment.viewStartTime +' - '+ appointment.viewEndTime+'':(i >= appointment.driveTime && i < appointment.startTime)?'Distance: '+appointment.distanceText+' - Drive Time: '+appointment.durationText+'':''">
                                            <small *ngIf="appointment"
                                                (click)="showAppointment(appointment)">&nbsp;</small>
                                            <small *ngIf="!appointment">&nbsp;</small>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="appointment-message" *ngIf="showmsg">
            <h5 class="tab-view">{{message}}</h5>
        </div>
    </div>

    <!-- <div class="w-100" *ngIf="service.common.infiniteLoader">
        <div class="loadergif">
          <img src="assets/typint_dots_black.gif" alt="Loader">
          <p>Loading...</p>
        </div>
      </div> -->
</div>

<i class="material-icons filter-icon forStaffView" (click)="filterTech()" title="Filter">
    filter_list </i>