import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProcedureService } from '../procedure.service';
import { Router, ActivatedRoute } from '@angular/router';
 import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-procedure-edit',
  templateUrl: './procedure-edit.component.html',
  styleUrls: ['./procedure-edit.component.scss']
})
export class ProcedureEditComponent implements OnInit, OnDestroy {
  submitted: boolean = true;
  fileUrl: any;
  filetitle: any;
  imageupload: boolean;
  procedures: any = [];
  filteredOptions: Observable<any>;
  procedureCode: any;
  procedureData: [];
  procDataByCode: [];
  //selectedEndTime = [];
  durationSelectDropdown: any[];
  selectedProcedure: any[];
  dropdownSettings = {};
  skills;
  options = [];
  selectedSkillItems = [];

  colorList:string[] = [
    "#34b6b9",
    "#2ecc71",
    "#3498db",
    "#f1c40f",
    "#e74c3c",
    "#f44336",
    "#ff4081",
    "#198754",
    "#018786",
    "#212529",
    "#6c757d"
  ];

  //toggleColorContainer:boolean = false;
  defaultColor:string = '#34b6b9';
  //toggleInput:boolean = false;

  img_serverPath:string;
  getAllJobTypeIcon:any[] = [];
  isLoading: boolean = false;
  protected noteList : any[] = [] ;
  

  constructor(
    public service: ProcedureService,
    public router: Router,
    public route: ActivatedRoute, 
    public translate: TranslateService,
    private dialog: MatDialog,
    private _commonSVC : CommonService
    ) {
      this.translate.setDefaultLang(this.service.local.get('language'));
      this.service.common.sub = this.service.common.confirmEmit.subscribe(
        (data: any) => {
          // //////console.log(data);
          if (data == 'showpopup') {
            this.service.common.showConfirmPopup();
          }
        });
        this.dropdownSettings = {
          singleSelection: false,
          text: "Select Skills",
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableSearchFilter: true,
          classes: "customClassAng2Multiselect",
          // badgeShowLimit: 5,
          maxHeight:200,
          autoPosition: true
        };

        this.img_serverPath = this.service.service.getSuperAdmin() + '/downloadFile/';
        this.getJobTypeIcons();
        this.getAllSkils();

  }

  ngOnDestroy() {
    this.service.common.sub.unsubscribe();
  }

  procedureForm: FormGroup;
  cancelProcedure = false;

  ngOnInit() {

    this.resetFormData();
    this.durationSelectDropdown = this.service.common.durationHours;
    this.imageupload = false;
    this.procedureForm.valueChanges.subscribe((val)=>{
      this.cancelProcedure = true;
    });

    this.route.queryParams.subscribe((data) => {
      //console.log('getProcedureByOrganizationBasedProcedureCode_1', data);
      if (data.procedureCode) {
        this.procedureCode = data.procedureCode;        
      }
    })
    
  }
  onItemSelect(item: any) {
    //console.log('onItemSelect', item);
    //console.log('selectedSkillItems', this.selectedSkillItems);
  }
  OnItemDeSelect(item: any) {
    //console.log('OnItemDeSelect', item);
    //console.log('selectedSkillItems', this.selectedSkillItems);
  }
  onSelectAll(items: any) {
    //console.log('onSelectAll', items);
    //console.log('selectedSkillItems', this.selectedSkillItems);
  }
  onDeSelectAll(items: any) {
    //console.log('onDeSelectAll', items);
    //console.log('selectedSkillItems', this.selectedSkillItems);
  }
  
  resetFormData() {

    this.procedureDataReset();
    this.procedureForm = new FormGroup({
      'procedureDescription': new FormControl(null, Validators.required),
      'procedureIcon': new FormControl(null, Validators.required),
      'procedureName': new FormControl(null, Validators.required),
      'selctedskills': new FormControl(null, Validators.required),
      "isDateFlexible": new FormControl(false) ,
      'regularRate': new FormControl(null,[Validators.pattern(/^[0-9]*.?[0-9]+$/) ]),
      'overTimeRate': new FormControl(null,[Validators.pattern(/^[0-9]*.?[0-9]+$/)]),
      'jobColor' : new FormControl(this.defaultColor)
    });

  }

  getSelectedData(){
    this.service.common.progressLoader = true;
        this.service.procedureForm.procedureCode = this.procedureCode;
        try {
          this.service.getProcedureByOrganizationBasedProcedureCode(this.procedureCode, (response) => { 

          if (response.responsecode == 200) {
            this.skills = response?.data?.orgSkillList;
            this.noteList =  response?.data?.noteList ;
            response?.data?.orgSkillList && response.data.orgSkillList.length > 0 && response.data.orgSkillList.forEach((element) => { element.itemName = element.skillName; element.id = element.skillCode });
            this.service.procedureForm.procedureName = response.data.procedureName;
            this.service.procedureForm.procedureDescription = response.data.procedureDescription;
            this.service.procedureForm.procedureCode = response.data.procedureCode ? response.data.procedureCode : this.procedureCode;
            this.service.procedureForm.organizationBaseProcedureCode = response.data.organizationBaseProcedureCode;
            this.service.procedureForm.organizationCode = response.data.organizationCode;
            this.service.procedureForm.id = response.data.id;
            this.procedureForm.controls.regularRate.setValue(response.data.regularRate);
            this.procedureForm.controls.overTimeRate.setValue(response.data.overTimeRate);
            this.procedureForm.controls.jobColor.setValue(response.data.jobColor ? response.data.jobColor : this.defaultColor);
           
            this.service.procedureForm.procedureIcon = response.data.procedureIcon;
            this.procedureForm.controls['procedureIcon'].setValue(response.data.procedureIcon);
            // this.service.procedureForm.orgSkillList = response.data.orgSkillList;

            if(response?.data?.orgSkillList){
              const selectedList = response?.data?.orgSkillList && response.data.orgSkillList.length > 0 && this.service.procedureForm.orgSkillList && this.service.procedureForm.orgSkillList.length > 0 && this.service.procedureForm.orgSkillList.filter((el: any) => {
                return response.data.orgSkillList.some((f:any) => {
                  return f?.skillCode === el?.skillCode;
                });
              });

              if(selectedList && selectedList.length > 0) {
                this.selectedSkillItems = selectedList;
                this.procedureForm.controls['selctedskills'].setValue(selectedList);
              } else {
                this.selectedSkillItems = [];
                this.procedureForm.controls['selctedskills'].setValue([]);
              } 
            }

            this.service.procedureForm.isDateFlexible = response.data.isDateFlexible;

          }
          setTimeout(()=>{
            this.cancelProcedure = false;
          }, 500);
        })
        } catch (error) {
          //console.log(error)
        }
  }

  /* Name:Shailesh
      use: for getting all skills by org code*/
  getAllSkils(){
    try {
      this.service.getAllSkillsV2(this.service.local.get('orgCode'),(skills) => {
      if(skills.responsecode == 200){
        this.options = skills.data;
        skills.data.forEach(element => {
          element.itemName = element.skillName;
        });
        //console.log('getAllSkillsV2:', skills);
        this.service.procedureForm.orgSkillList = skills.data;
        this.getSelectedData();
      } else {
        this.options = [];
      }
    });
    } catch (error) {
      //console.log(error)
    }
    
  }

  private _filter(value) {
    //console.log(value);
    if(value && value != ''){
      // //console.log('IN');
      //const filterValue = ('' + value).toLowerCase();

      const filterValue = value;
      //return this.options.filter((option) => option.skillName.toLowerCase().includes(filterValue.toLowerCase()));
      return this.options.filter((option) => {
        if(option && option?.skillName && filterValue){
          return option.skillName.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
        }
      });
    } else {
      // //console.log('OUT');
      return this.options;
    }
  }

  displayFn(option): string | undefined {
    return option ? option.skillName : undefined;
  }

  /* Name:Shailesh
      use: for adding and updating procedure*/
  onSubmit() {
     
    if (this.procedureForm.valid) {
      this.submitted = true;            
      this.service.procedureForm.organizationCode = this.service.local.get('orgCode');
      this.service.procedureForm.orgSkillList = this.procedureForm.controls.selctedskills.value;
      
      this.service.procedureForm.regularRate = this.procedureForm.controls.regularRate.value ? parseFloat(this.procedureForm.controls.regularRate.value) : this.procedureForm.controls.regularRate.value;
      this.service.procedureForm.overTimeRate = this.procedureForm.controls.overTimeRate.value ? parseFloat(this.procedureForm.controls.overTimeRate.value) : this.procedureForm.controls.overTimeRate.value;
      this.service.procedureForm.jobColor = this.procedureForm.controls.jobColor.value;
      this.service.procedureForm.noteRequest =  this._commonSVC.noteRequest ? this._commonSVC.noteRequest : { "isPublic": false, "note": ""}
      

      if (this.procedureCode) {
        this.isLoading = true;
        try {

          this.service.updateProcedureByOrganizationBasedProcedureCode(this.service.procedureForm.organizationBaseProcedureCode, this.service.procedureForm, (response) => {
            this.isLoading = false;
          //////console.log(response);
          if (response.responsecode == 200) {
            this.service.common.message = response.message;
            this.service.common.successMessage = true;
            this._commonSVC.resetNotesRequestForm();
            // this.router.navigate(['/dashboard/procedure/procedure-list']);
             this.router.navigate(['/dashboard/jobtype/jobtype-list']);
           // this.getSelectedData();
          } else {
            this.service.common.message = response.message;
            this.service.common.errorMessage = true;
          }
        })
        } catch (error) {
          //console.log(error)
        }
        
      } else {
        try {
          this.isLoading = true;
          this.service.saveOrganizationBasedProcedure(this.service.procedureForm, (response) => {
          //////console.log(response);
          this.isLoading = false;
          if (response.responsecode == 200) {
            this.service.common.message = response.message;
            this.service.common.successMessage = true;
                 // this.router.navigate(['/dashboard/procedure/procedure-list']);
                 this.router.navigate(['/dashboard/jobtype/jobtype-list']);
          } else {
            this.service.common.message = response.message;
            this.service.common.errorMessage = true;
          }
        })
        } catch (error) {
          //console.log(error)
        }
        
      }
    } else {
      this.submitted = false;
    }
  }


  /* Name:Shailesh
      use: for select time interval*/
  selectStart(query) {
    //////console.log(query);
    let result = [];
    for (let a of this.service.common.timeIntervals) {
      if (a.text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  searchprocedure(query) {
    let result = this.selectprocedure(query);
    this.selectedProcedure = result;
  }

  /* Name:Shailesh
      use: for set procedure data for form*/
  selectprocedure(query) {
    // //////console.log(query);
    let result = [];    
    return result;
  }

  /* Name:Shailesh
      use: for cancel pop up*/
  cancelPopup(){
    if(this.cancelProcedure){
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        //this.resetFormData();
             // this.router.navigate(['/dashboard/procedure/procedure-list']);
             this.router.navigate(['/dashboard/jobtype/jobtype-list']);
      }
    });
    } else {
           // this.router.navigate(['/dashboard/procedure/procedure-list']);
           this.router.navigate(['/dashboard/jobtype/jobtype-list']);
    }
  }

  procedureDataReset() {
    this.service.procedureForm = {
      "id": 0,
      "organizationBaseProcedureCode": "",
      "organizationCode": "",
      "procedureCode": "",
      "procedureDescription": "",
      "procedureIcon": "",
      "procedureName": "",
      "orgSkillList": [],
      "isDateFlexible" : false,
      "regularRate": null,
      "overTimeRate": null,
      "jobColor":"",
      "noteRequest": {
      "isPublic": false,
      "note": ""
    }
    }
  }

  /* Name:Shailesh
      use: for select and set skill to form control*/
  selectedSkill(skill){
    // //console.log('selectedSkill: ', skill, this.service.procedureForm.orgSkillList);
    const index = this.service.procedureForm.orgSkillList.findIndex((element)=> element.skillName === skill.option.value.skillName);
    ////console.log(index);
    if(index == -1){
      this.service.procedureForm.orgSkillList.push(skill.option.value);
      ////console.log(this.service.procedureForm.orgSkillList);
      this.procedureForm.controls['selctedskills'].setValue(skill.option.value);
      this.procedureForm.controls['skills'].setValue(null);
      ////console.log(this.procedureForm.controls['skills'].value);
    } else {
      this.procedureForm.controls['skills'].setValue(null);
    }
  }

  
 /* Name:Shailesh
      use: for removing selected skill*/
  removeOrg(i){
    ////console.log(i);
    this.service.procedureForm.orgSkillList.splice(i, 1);
    if (this.service.procedureForm.orgSkillList.length == 0) {
      this.procedureForm.controls['selctedskills'].setValue(null);
    }
  }



  colorChange(colorCode:string)
  {
      this.procedureForm.controls.jobColor.setValue(colorCode);
  }

getJobTypeIcons()
{
  try {
    this.service.getAllJobTypeIcons().subscribe((res:any) =>{
      if(res.responsecode == 200)
      {
        this.getAllJobTypeIcon = res.data ;
       //console.log("getAllJobTypeIcon", res);
      }
    });
  } catch (error) {
    //console.log(error)
  }
}


selectIcon(ele:any)
{
  this.procedureForm.controls.procedureIcon.setValue(ele.iconName);
  this.service.procedureForm.procedureIcon = ele.iconName;
}

openNotePopUp()
{
  this._commonSVC.addUpdateNotes(this.noteList) ;
}

}
