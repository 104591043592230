import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {

  //tabAtciveFor: string = 'Job View';
  tabAtciveFor: string = 'Weekly View';

  constructor() {}

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    // let job_v_t_active = document.getElementById('mat-tab-label-0-1');
    // job_v_t_active.click();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // //console.log('tabChangeEvent => ', tabChangeEvent?.tab?.textLabel);
    this.tabAtciveFor = tabChangeEvent?.tab?.textLabel;
  }

}
