import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
 import * as moment from 'moment';
import { InvoiceService } from '../invoice-service';
import { SessionStorageService } from 'angular-web-storage';
import { Subject,  debounceTime, pipe, takeUntil } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { CommonApiService } from 'src/app/shared/services/common-apiService';

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.scss']
})

export class InvoiceReportComponent implements OnInit, OnDestroy {

  invoiceReportInputForm: FormGroup;

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    "customerName",
    "invoiceId",
    "invoiceDate",
    "deadlinesPayment",
    "taxAmount",
    "adjustmentAmount",
    "totalAmount",
    "invoiceStatus",
    "paymentDate",
    "paymentMode",
    "paymentReference",
    "adjustmentReason"
  ]

  isLoading: boolean = false;
  private page: number = 0;
  private limit: number = 30;

  private observableDestroyer = new Subject();
  private isValuePersist : string | null = null ;

  exportAsConfig: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'invoive-report-dataTable', // the id of html/table element
    // options: { // html-docx-js document options
    //   orientation: 'landscape',
    //   margins: {
    //     top: '2000',
    //     left: '2000',
    //     right: '2000',
    //     bottom: '2000',
    //     header: '2000',
    //     gutter: '20'
    //   },
    // }
  };
  @ViewChild('iframe') iframe: ElementRef;
  all_customer_list:any[] = [];
  all_customer_list_filtered:any[] = [];
  
  constructor(
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private sessionService: SessionStorageService,
    private exportAsService: ExportAsService,
    private commonAPIService :CommonApiService
  ) { }


  ngOnInit(): void {
    this.initalizeInvoiceReportForm();
   // this.getInvoiceReports();
   this.getTableData();
    this.inputSearch();
    this.get_all_customer_list();
  }

  // moment().subtract(1, 'months').toDate()
  initalizeInvoiceReportForm() {
    this.invoiceReportInputForm = this.fb.group({
      startDate: new FormControl(moment().toDate()),
      endDate: new FormControl(moment().toDate()),
      customerName: new FormControl(null),
      invoiceStatus: new FormControl("All"),
    });

  }

  onScroll() {
    this.page += 1;
    this.getInvoiceReports('scroll');

  }

  getInvoiceReports(isScroll?) {
    this.isLoading = true;
    this.invoiceService.getInvoiceReports(
      this.sessionService.get("orgCode"),
      moment(this.invoiceReportInputForm.get("startDate").value).format('YYYY-MM-DD'),
      moment(this.invoiceReportInputForm.get("endDate").value).format('YYYY-MM-DD'),
      this.invoiceReportInputForm.get("customerName").value ? this.invoiceReportInputForm.get("customerName").value : 'All',
      this.invoiceReportInputForm.get("invoiceStatus").value,
      this.page,
      this.limit
    ).subscribe((res: any) => {
      this.isLoading = false;
      if (res.responsecode === 200) {
       // console.log("invoice report", res);

        if(isScroll)
          {
            this.dataSource = new MatTableDataSource([...this.dataSource.data, ...res.data]);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.invoiceService.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }
          else{
            this.dataSource = new MatTableDataSource(res.data);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.invoiceService.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }
      }
      else{
        if(!isScroll)
          this.dataSource = new MatTableDataSource();
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this.invoiceService.common.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.sort;
        }
    });


  }

  onChangeDate(event: any) {
    this.getInvoiceReportDataList();
  }


  inputSearch() {
    this.invoiceReportInputForm.controls.customerName.valueChanges.pipe(debounceTime(700), takeUntil(this.observableDestroyer)).subscribe((value: string) => {
      if (value || this.isValuePersist) {
        this.isValuePersist = value ;
        // console.log("on customer name serach");
        this.dataSource = new MatTableDataSource();
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this.invoiceService.common.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.sort;
        this.getInvoiceReportDataList();

      }
    })
  }


  onChangeInvoiceStatus(event: any) {
    this.getInvoiceReportDataList();
  }

  getInvoiceReportDataList() {
    this.page = 0;
    this.dataSource = new MatTableDataSource();
    // Apply the sorting accessor for case-insensitive sorting
    this.dataSource.sortingDataAccessor = this.invoiceService.common.globalSortingDataAccessorForMatSort;
    this.dataSource.sort = this.sort;
    this.getInvoiceReports();
  }

  ngOnDestroy(): void {
    this.observableDestroyer.next('');
    this.observableDestroyer.complete();
  }


  export() {
    this.exportAsService.save(this.exportAsConfig, 'InvoiceReport').pipe(takeUntil(this.observableDestroyer)).subscribe(() => {
     // console.log("export data" , res)
    });
  }


  printInvoiceReport(): void {
    const printContents = document.getElementById('invoice_report_tableData_forPrint').innerHTML;

    this.iframe.nativeElement.name = "frame1";
    this.iframe.nativeElement.style.position = "absolute";
    this.iframe.nativeElement.style.top = "-1000000px";
    document.body.appendChild(this.iframe.nativeElement);
    let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head><title>Invoice Report</title>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(printContents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(this.iframe.nativeElement);
    }, 1000);

  }


  get_all_customer_list()
  {
   this.commonAPIService.getAllCustomerList(this.sessionService.get("orgCode")).subscribe((res:any)=>{
   // console.log("getAllCustomerList", res);
    if(res.responsecode === 200)
    {
      this.all_customer_list = res.data ;
      this.all_customer_list_filtered = res.data;
    }
   })
  }

  searchCustomer(value: any) {
    if(value.trim())
    {
      this.all_customer_list_filtered = this.all_customer_list.filter(ele => ele.customerName.toLowerCase().includes(value.trim().toLowerCase()));
    }
    else{
      this.all_customer_list_filtered = [...this.all_customer_list];
    }
   
  // console.log("this.all_customer_list_filtered ", this.all_customer_list_filtered )
  }

  on_customer_name_inputs(custName:string)
  {
    this.invoiceReportInputForm.controls.customerName.setValue(custName) ;
    // console.log( this.invoiceReportInputForm.controls.customerName.setValue(custName) )
  }



  getTableData()
  {
    this.commonAPIService.get_calendar_setting_by_setting_name(this.sessionService.get('orgCode')).subscribe((res:any) => {

      this.invoiceReportInputForm.controls.startDate.setValue(
        res?.setting_start_of_week ?  moment().day(res?.setting_start_of_week).toDate() : moment().toDate()
      );
      
      this.page = 0 ;
     this.getInvoiceReports();
   });
  }


}
