import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    public service: ApiRootService,
    public http: HttpClient,
    public storage: SessionStorageService,
    public common: CommonService
  ) { }

  /* Name:Shailesh
      use: for getting admin appointment report by org code */
  getAdminAppointmentReport(date, orgCode, page, limit, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAdminAppointmentReport/' + date + '/' + orgCode + '?page=' + page + '&limit=' + limit).subscribe((data) => {
      callback(data);
    });
  }

  /* Name:Shailesh
     use: for getting weekly report of client by from date, to date and org code */
  getWeeklyReportOfClinician(wcode, fromdt, todt, orgCode, callback) {
    return this.http.get(
      this.service.getHealthCareUrl() + '/getWeeklyReportOfClinician/' + wcode + '/' + fromdt + '/' + todt + '/' + orgCode).
      subscribe((data) => {
        callback(data);
      });
  }

  /* Name:Shailesh
     use: for getting all local setting with global setting by user code */
  adminGetWorkerAndOrganizationFromWASActive(orgCode, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/adminGetWorkerAndOrganizationFromWASActive/' + orgCode).subscribe((data) => {
      callback(data);
    });
  }

  /* Name:Shailesh
     use: for getting all tracking details by user code */
  getAllUserTrackingByUserCodeAndDate(userCode,date, callback) {
    return this.http.get(this.service.getHealthCareUrl() + '/getAllUserTrackingByUserCodeAndDate/' + userCode + '/' + date).subscribe((data) => {
      callback(data);
    });
  }


  getDailyReports(request_body:any, page:number, limit:number) {
    return this.http.post(this.service.getHealthCareUrl() + `/getReportWithSearchFilterByPage?page=${page}&limit=${limit}`, request_body);
  }

  getAllStaffMemberByOrgCode(organizationCode:string)
  {
    return this.http.get(this.service.getHealthCareUrl() + `/getAllStaffMember/${organizationCode}`);
  }

  getTrackingDataByStaffCode(trackingDate:string, fromTime:string, toTime:string, timeIntervalInMin:number | string, staffCode:string)
  {
    return this.http.get(`${this.service.getHealthCareUrl()}/getAllUserTrackingByStaffCodeAndDate/${trackingDate}/${staffCode}/${fromTime}/${toTime}/${timeIntervalInMin}`)
  }

  
}
