import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class NewNotificationService {
  constructor(
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public common: CommonService
  ) { }

  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    // this.zone.run(() => {
    //   this.snackBar.open(message, 'X');
    // });
  }

  showError(message: string): void {
    // this.common.progressLoader = false;

    // this.zone.run(() => {
    //   //////console.log(this.snackBar);
    //   //////console.log(message);
    //   this.snackBar.open(message, 'X', { panelClass: ['error'] });

    // });
    this.common.message = message;
            this.common.errorMessage = true;
  }
}
