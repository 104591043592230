import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError, EMPTY } from 'rxjs';

//import { AuthService } from "../services/auth.service";


import { prefixReq } from './http-config';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { logMessage } from './log';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly AUTH_HEADER = "Authorization";

  constructor(private router: Router, 
    //private sessionService: SessionService
    //private readonly authService: AuthService,
    ) {}

  /* intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.currentUserValue?.Authorization;
    console.info("Auth token", token);
    let newHeaders = request.headers;
    // newHeaders = new HttpHeaders({
    //   "Access-Token": ["e4fgf32r-fgt6-f677-yyui-5659057458by"],
    // });
    //if (!this.utilityService.isEmpty(token)) {
    newHeaders = newHeaders.append(this.AUTH_HEADER, `Bearer ${token}`);
    // }
    const authReq = request.clone({ headers: newHeaders });
    //console.info("Auth Request", authReq);

    return next.handle(authReq);
  } */

  /* intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if account is logged in and request is to the api url
    const account = this.authService.currentUserValue;
    const isLoggedIn = account?.Authorization;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${account.Authorization}` }
      });
    }

    return next.handle(request);
  } */

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const Authorization = "";//this.authService.currentUserValue?.Authorization;
    let authReq;

    if (Authorization) {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${Authorization}`,
          //'Content-Type': 'application/json', 
        },
        withCredentials: true,
      });
      //logMessage(`${prefixReq} 🔑 Auth`, [`Adding Auth header`]);
    } else {
      //logMessage(`${prefixReq} 🔑 Auth`, [`No Auth Token to add`]);
      authReq = req.clone();
    }

    // Pass on the cloned request instead of the original request.
    return next.handle(authReq).pipe((source) => this.handleAuthErrors(source));
  }

  handleAuthErrors(
    source: Observable<HttpEvent<any>>,
  ): Observable<HttpEvent<any>> {
    return source.pipe(
      catchError((error: HttpErrorResponse) => {
        const authResHeader = error.headers.get('WWW-Authenticate') ;
        if (error.status === 401) {
          //if (/is expired/.test(authResHeader)) {
            // TODO: Another option is to refresh token
            // this.sessionService.refreshToken();
           // this.router.navigate(['signin']);
          //} else {
            this.router.navigate(['authfailed']);
          //}
          return EMPTY;
        } else {
          return throwError(error);
        }
      }),
    );
  }
}
