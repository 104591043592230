
<div class="worker">
  <h4>Cancel Job</h4>
  <form [formGroup]="cancelAppointment" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
      <mat-label>Leave a comment</mat-label>
      <textarea matInput placeholder="" formControlName="comment"></textarea>
    </mat-form-field>
    <span class="help-block" *ngIf="!cancelAppointment.get('comment').valid && cancelAppointment.get('comment').touched">Comment is required</span>
    <div class="text-end buttons-bottom">
      <a class="cursor-pointer" (click)="cancel()">Cancel</a>
      <button mat-raised-button color="primary" [disabled]="!cancelAppointment.valid">Submit</button>
    </div>
  </form>
</div>
