import * as L from 'leaflet';
import 'leaflet.markercluster';
import { ChatService } from './../chat/chat.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
declare var google;
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import { Options } from 'ng5-slider';
// import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
// import { RxStompService } from '@stomp/ng2-stompjs';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AssignAppointmentComponent } from '../appointment/assign-appointment/assign-appointment.component';
import { SplitAppointmentComponent } from '../appointment/split-appointment/split-appointment.component';
import { CreateAppointmentService } from '../appointment/create-appointment/create-appointment.service';
import { ChatComponent } from '../chat/chat.component';
import { iif } from 'rxjs';
import { ArrivalDepartureComponent } from '../arrival-departure/arrival-departure.component';
import { AssignedWorkerComponent } from '../appointment/create-appointment/assigned-worker/assigned-worker.component';
import { LocationService } from '../location/location.service';
import { RxStompService } from 'src/app/rx-stomp.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { SessionStorageService } from 'angular-web-storage';

declare var OverlappingMarkerSpiderfier;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd DD MMMM YYYY',
    monthYearLabel: 'ddd DD MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'ddd DD MMMM YYYY',
  },
};


@Component({
  selector: 'app-location-leaflet',
  templateUrl: './location-leaflet.component.html',
  styleUrls: ['./location-leaflet.component.scss'],
  providers: [DatePipe, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class LocationLeafletComponent implements OnInit {

  date;
  selectedDate;
  value = 0;

  dateRange = this.createDateRange("9:15", "10:00");
  options: Options = {
    stepsArray: this.dateRange.map((date) => {
      return { value: date, legend: date };
    }),
    showTicks: true,
    showTicksValues: false
  };


  userType: any;

  map;
  bounds;
  gmarker = [];
  isZoom: boolean = false;
  markers = [];
  locationData = [];
  locationKeys = [];
  formatedDate;
  currentValue;
  nonWorkingTech = [];
  showImg;
  allLocationData = [];
  searchKey;
  legend;
  currentSelectedDate;
  yearMonthDateFormat = 'YYYY-MM-DD';
  lat;
  lang;
  disabled = true;
  pending = [];
  removeAllMarkers = [];
  isShowRefresh: boolean = true;
  /* Name:Shailesh
      use: for calulating time */
  timeString(time) {

    ////console.log(time);

    let hours = Math.floor(time / 60).toString();
    let minutes = (time % 60).toString();

    let hours1 = Math.floor(time / 60);
    let minutes1 = (time % 60);

    if (hours1 < 10) hours = "0" + hours; //optional
    if (minutes1 < 10) minutes = "0" + minutes;

    return hours + ":" + minutes;
  }

  /* Name:Shailesh
      use: for creating date range*/
  createDateRange(start, end) {
    var start = start.split(":");
    var end = end.split(":");

    start = parseInt(start[0]) * 60 + parseInt(start[1]);
    end = parseInt(end[0]) * 60 + parseInt(end[1]);
    ////console.log(start);
    ////console.log(end);

    var result = [];

    for (let time = start; time <= end; time += 15) {
      result.push(this.timeString(time));
    }

    ////console.log(result);
    return result;
  }


  constructor(
    public service: LocationService,
    public datepipe: DatePipe,
    public rxStompService: RxStompService,
    public route: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    public createPatientService: CreateAppointmentService,
    public ChatService: ChatService,
    public session: SessionStorageService,
  ) {
    this.translate.setDefaultLang(this.service.common.local.get('language'));
    this.date = new FormControl(moment());
    this.selectedDate = this.date.value;
    this.formatedDate = this.datepipe.transform(this.date.value, 'yyyy-MM-dd');
    this.service.common.progressLoader = true;
    this.getLatLangByOrgCode();
    this.adminGetAppointmentDetailsForMapView(this.formatedDate);
  }

  ngOnInit() {
    ////console.log(
      this.userType = this.session.get('user_type');;
    ////console.log(this.route.url);

    // var mymap = L.map('mapid').setView([51.505, -0.09], 13);

    // const tiles = L.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
    //   maxZoom: 19,
    //   // attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // });

    // tiles.addTo(mymap);

    // var circle = L.circle([51.508, -0.11], {
    //   color: 'red',
    //   fillColor: '#f03',
    //   fillOpacity: 0.5,
    //   radius: 500
    // }).addTo(mymap);

    // var polygon = L.polygon([
    //   [51.509, -0.08],
    //   [51.503, -0.06],
    //   [51.51, -0.047]
    // ]).addTo(mymap);

    // var marker = L.marker([51.5, -0.09]).addTo(this.map);

    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);

    /* Name:Shailesh
      use: for getting notification message */
      try {
        this.rxStompService.watch('/topic/healthcarenotification').subscribe((message) => {
      ////console.log(message);
      if (message) {
        let body = JSON.parse(message.body);
        if (this.route.url == '/dashboard/location' && this.service.common.local.get('userCode') == body.userCode) {
          this.service.common.progressLoader = true;
          this.adminGetAppointmentDetailsForMapView(this.formatedDate);
        }
      }
    });
      } catch (error) {
        //console.log(error);
      }
    



    this.legend = document.getElementById('mapLegend1');
    ////console.log(this.legend);
    // if (this.legend) {
    //   ////console.log(this.legend);
    //   let div = document.createElement('div');
    //   div.innerHTML = `<div class="text-truncate" title="Patient"><span><img src="assets/common_images/pointer_blue.png"> Patient</span></div>
    //                   <div class="text-truncate" title="Clinician current location"><span><img src="assets/common_images/tec_black_pointer.png"> Clinician current location</span></div>
    //                   <div class="text-truncate" title="Clinician available"><span><img src="assets/common_images/tec_gray_pointer.png"> Clinician available</span> </div>
    //                   <div class="text-truncate" title="Appointments that need action"><span><img src="assets/common_images/location_red_pointer.png"> Appointments that need action</span> </div>
    //                   <div class="text-truncate" title="Clinician late"><span><img src="assets/common_images/pointer_orange_active.png"> Clinician late </span> </div>
    //                   <div class="text-truncate" title="Completed"><span><img src="assets/common_images/location_green_pointer.png"> Completed </span> </div>`;

    //   this.legend.appendChild(div);

    // }


  }

  /* Name:Shailesh
      use: for getting longitute and latitude by org code*/
  getLatLangByOrgCode() {
    try {
      this.service.getLatLangByOrgCode(this.service.common.local.get('orgCode'), (res) => {
      if (res.responsecode == 200) {
        ////console.log(res);
        this.lat = res.data.lat;
        this.lang = res.data.lang;
      } else {
        this.lat = 32.78306;
        this.lang = -96.80667;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

  choosedDate(event) {
    ////console.log(event);
    this.service.common.progressLoader = true;
    this.selectedDate = event.value;
    this.date.setValue(event.value);
    this.formatedDate = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.adminGetAppointmentDetailsForMapView(this.formatedDate);
  }

  /* Name:Shailesh
      use: for changing date on click of arrow*/
  arrowDateChange(arrow) {
    this.service.common.progressLoader = true;
    if (arrow == 'left') {
      // this.date.value.add(-1, 'days');
      this.date = new FormControl(moment(this.selectedDate).add(-1, 'days'));
      this.choosedDate(this.date);
    } else if (arrow == 'right') {
      // this.date.value.add(+1, 'days');
      this.date = new FormControl(moment(this.selectedDate).add(+1, 'days'));
      this.choosedDate(this.date);
    }
  }

  /* Name:Shailesh
      use: for getting appointment details for map view s*/
  adminGetAppointmentDetailsForMapView(date) {

    this.service.common.progressLoader = true;
    try {
      this.service.adminGetAppointmentDetailsForMapViewStart(this.service.common.local.get('orgCode'), date, (data) => {
      this.service.common.progressLoader = false;
      this.getMapViewValueData(data, false);
    })
    } catch (error) {
      //console.log(error)
    }
    
    try {
       this.service.adminGetAppointmentDetailsForMapView(this.service.common.local.get('orgCode'), date, (data) => {
      // this.service.common.progressLoader = false;
      this.getMapViewValueData(data, true);
    })
    } catch (error) {
      //console.log(error)
    }
   
  }

/* Name:Shailesh
      use: for getting data on map view*/
  getMapViewValueData(data, flash = false) {
    if (data.responsecode == 200) {
      ////console.log(data.data);
      // if(flash == true){
      //   const newData = Object.assign({}, data.data);
      //   delete newData['10:30 AM'];
      //   this.locationData = newData;
      //   this.locationKeys = Object.keys(data.data);
      //   this.allLocationData = Object.assign({}, data.data);
      //   ////console.log(this.locationData);
      // } else {
      this.locationData = data.data;
      this.locationKeys = Object.keys(data.data);
      this.allLocationData = Object.assign({}, data.data);
      ////console.log(this.locationData);
      // }

      // this.allLocationData = data.data;
      // this.allLocationData = this.locationData.slice();
      // ////console.log(this.locationKeys[0] + ' ---- ' +this.locationKeys[this.locationKeys.length-1])
      setTimeout(() => {
        // ////console.log(this.selectedDate);
        // ////console.log(moment());
        // this.dateRange = this.createDateRange(this.locationKeys[0].split(' ')[0], this.locationKeys[this.locationKeys.length-1].split(' ')[0]);
        const newOptions: Options = Object.assign({}, this.options);
        // ////console.log( moment(this.selectedDate).isSame(moment(), 'day'),this.selectedDate.isAfter());
        newOptions.stepsArray = this.locationKeys.map((date, index) => {
          if (this.locationData[date].isLatePending && moment(this.selectedDate).isSame(moment(), 'day') || this.locationData[date].isLatePending && this.selectedDate.isAfter()) {
            return { value: index, legend: '<p class="actionRequired">' + date + '</p>' };
          }
          else if (!this.locationData[date].isLatePending && this.locationData[date].appointmentDetailsForMapViewList.length > 0 && moment(this.selectedDate).isSame(moment(), 'day') || !this.locationData[date].isLatePending && this.locationData[date].appointmentDetailsForMapViewList.length > 0 && this.selectedDate.isAfter()) {
            ////console.log('actionRequiredNew');
            return { value: index, legend: '<p class="actionRequiredNew">' + date + '</p>' };
          }
          else {
            return { value: index, legend: date };
          }
        }),
          this.options = newOptions;
        setTimeout(() => {
          let parentClass = document.getElementsByClassName('actionRequired') as HTMLCollection;
          let parentClassNew = document.getElementsByClassName('actionRequiredNew') as HTMLCollection;

          if (moment(this.selectedDate).isSame(moment(), 'day') || this.selectedDate.isAfter()) {
            ////console.log(parentClass);
            ////console.log(parentClassNew);
            // ////console.log(parentClass.length);

            var elems = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredRed");
            [].forEach.call(elems, function (el) {
              el.classList.remove("actionRequiredRed");
            });
            var elems1 = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredGreen");
            [].forEach.call(elems1, function (el) {
              el.classList.remove("actionRequiredGreen");
            });

            for (var i = 0; i < parentClass.length; i++) {
              parentClass[i].parentElement.classList.add("actionRequiredRed");
            }
            for (var i = 0; i < parentClassNew.length; i++) {
              // ////console.log(parentClass[i]); //second console output
              parentClassNew[i].parentElement.classList.add("actionRequiredGreen");
            }
          } else {
            var elems = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredRed");
            [].forEach.call(elems, function (el) {
              el.classList.remove("actionRequiredRed");
            });
            var elems1 = document.querySelectorAll(".ng5-slider-tick-legend.actionRequiredGreen");
            [].forEach.call(elems1, function (el) {
              el.classList.remove("actionRequiredGreen");
            });
          }
        }, 1000)
        if (this.currentValue) {
          this.value = +this.currentValue;
          // ////console.log('current');
          // ////console.log(this.value);
        } else {
          this.value = 0;
          // ////console.log('notcurrent');
          // ////console.log(this.value);
        }
        // this.service.common.progressLoader = false;
        this.googleMap();
      }, 1000);
    } else {
      this.locationData = [];
      // this.service.common.progressLoader = false;
    }
  }

  valueChange(value) {
    ////console.log(value);
    if (value || value == 0) {
      this.value = +value;
      this.currentValue = +value;
      // ////console.log(this.currentValue);
      // this.googleMap();
      this.applyFilter(this.searchKey);
    }
  }
  zoomIndex = 10;

  /* Name:Shailesh
      use: for showing google map*/ 
  googleMap() {
    this.isZoom = false;

    // this.map = new google.maps.Map(document.getElementById('dvMap'), {
    //   zoom: this.zoomIndex,
    //   center: new google.maps.LatLng(this.lat, this.lang),
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // });
    document.getElementById('mainmap').innerHTML = '';
    document.getElementById('mainmap').innerHTML = '<div id="dvMap" class="content-card location-map" #mapDis style="width:100%; height: 70vh;"></div>';

    this.map = L.map('dvMap').setView([this.lat, this.lang], this.zoomIndex);

    const tiles = L.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      // maxZoom: 19,
    });

    tiles.addTo(this.map);

    const mapDiv = document.getElementById('mapLegend1');

    ////console.log(mapDiv);

    // if(mapDiv){
      document.getElementById('mapLegend1').innerHTML = '';
      if (this.legend) {
        ////console.log(this.legend);
        let div = document.createElement('div');
        div.innerHTML = `<div class="text-truncate" title="Patient"><span><img src="assets/common_images/pointer_blue.png"> Patient</span></div>
                        <div class="text-truncate" title="Clinician current location"><span><img src="assets/common_images/tec_black_pointer.png"> Clinician current location</span></div>
                        <div class="text-truncate" title="Clinician available"><span><img src="assets/common_images/tec_gray_pointer.png"> Clinician available</span> </div>
                        <div class="text-truncate" title="Appointments that need action"><span><img src="assets/common_images/location_red_pointer.png"> Appointments that need action</span> </div>
                        <div class="text-truncate" title="Clinician late"><span><img src="assets/common_images/pointer_orange_active.png"> Clinician late </span> </div>
                        <div class="text-truncate" title="Completed"><span><img src="assets/common_images/location_green_pointer.png"> Completed </span> </div>`;
  
        this.legend.appendChild(div);
  
      // }


      if (this.value == 0) {
        if (this.locationData[this.locationKeys[0]].appointmentDetailsForMapViewList.length > 0 || this.locationData[this.locationKeys[0]].appointmentDetailsForMapViewOtherWorkerList.length > 0) {
          this.getMarker();
        }
      } else {
        if (this.locationData[this.locationKeys[this.value]].appointmentDetailsForMapViewList.length > 0 || this.locationData[this.locationKeys[0]].appointmentDetailsForMapViewOtherWorkerList.length > 0) {
          this.getMarker();
        }
      }
    }

    // setTimeout(() => {
    //   /* Adding Map Legends */
    //   ////console.log('legend...');
    //   ////console.log(this.legend);

    // this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(this.legend);
    // }, 2000);
  }

  /* Name:Shailesh
      use: for getting all markers on map*/
  getMarker() {
    // const bounds = new google.maps.LatLngBounds();
    // const infowindow = new google.maps.InfoWindow();
    const infowindow = null;

    const bounds = L.latLngBounds();
    // const infowindow = L.InfoWindow();

    let marker, i, marker1, marker3, marker4;
    let lengthForOne, lengthForTwo;
    // setTimeout(()=>{
    ////console.log(this.value, this.locationKeys[this.value]);
    lengthForOne = this.locationData[this.locationKeys[this.value]].appointmentDetailsForMapViewOtherWorkerList.length;
    lengthForTwo = this.locationData[this.locationKeys[this.value]].appointmentDetailsForMapViewList.length;
    // })

    const options = { legWeight: 3 }; // Just an example of options - please set your own if necessary
    // const oms = new OverlappingMarkerSpiderfier(this.map,
    //   { markersWontMove: true, markersWontHide: true });

    // const oms = new OverlappingMarkerSpiderfier(this.map,
    //   { markersWontMove: true, markersWontHide: true });

    var markerGroup = L.markerClusterGroup();

    ////console.log(this.locationData); 

    Object.entries(this.locationData).forEach(([key, val]) => {
      // ////console.log(key); // the name of the current key.
      // ////console.log(val); // the value of the current key.
      let keyIndex = this.locationKeys.findIndex((data) => data == key);
      // ////console.log(keyIndex, this.value); // the name of the current key.
      if (keyIndex !== -1) {
        if ((val.appointmentDetailsForMapViewList.length > 0 || val.appointmentDetailsForMapViewOtherWorkerList.length > 0) && keyIndex == this.value) {
          // if ((val.appointmentDetailsForMapViewList.length > 0 || val.appointmentDetailsForMapViewOtherWorkerList.length > 0)) {

          this.pending = val.appointmentDetailsForMapViewList.filter(element => element.status == 'pending').map(element => element.appointmentsDurationCode);

          ////console.log(this.pending);

          if (this.pending.length > 0) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }

          val.appointmentDetailsForMapViewList.forEach((ele) => {

            if (ele.workerName) {
              // let loc = new google.maps.LatLng(ele.patientLatitude, ele.patientLongitude);
              let loc = L.latLng(ele.patientLatitude, ele.patientLongitude);

              // let loc1 = new google.maps.LatLng(ele.workerLatitude, ele.workerLongitude);
              let loc1 = L.latLng(ele.workerLatitude, ele.workerLongitude);

              // let loc1 = { lat:element.pickLat, lng: element.pickLang, rgb: { r: 180, g: 180, b: 180 } };





              bounds.extend(loc);
              ////console.log(ele.status);
              let imageUrl, icon0;
              if (ele.status == 'inprogress') {
                ////console.log(ele.status);
                // if(keyIndex == this.value) {
                ////console.log(keyIndex, this.value + '....');
                // imageUrl = './../../assets/common_images/pointer_blue_active.png';
                // icon = {
                //   url: imageUrl
                // };

                icon0 = L.icon({
                  iconUrl: './../../assets/common_images/pointer_blue_active.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });

              } else if (ele.status == 'completed') {

                // imageUrl = './../../assets/common_images/location_green_pointer.png';
                // icon = {
                //   url: imageUrl
                // };

                icon0 = L.icon({
                  iconUrl: './../../assets/common_images/location_green_pointer.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });

              } else if (ele.status == 'late or cancelled') {

                // imageUrl = './../../assets/common_images/location_red_pointer.png';
                // icon = {
                //   url: imageUrl
                // };

                icon0 = L.icon({
                  iconUrl: './../../assets/common_images/location_red_pointer.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });

              } else {
                // ////console.log(element.bookingStatus);

                // if(keyIndex == this.value) {
                //   ////console.log(keyIndex, this.value+'....');
                //   imageUrl = './../../assets/common_images/pointer_blue.png';
                //   icon = {
                //     url: imageUrl
                //   };
                // } else {
                //   imageUrl = './../../assets/common_images/move_icon.png';
                //   icon = {
                //     url: imageUrl
                //   };
                // }

                // imageUrl = './../../assets/common_images/pointer_blue.png';
                // icon = {
                //   url: imageUrl
                // };

                icon0 = L.icon({
                  iconUrl: './../../assets/common_images/pointer_blue.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });
              }


              bounds.extend(loc1);
              // ////console.log(element.bookingStatus);
              let imageUrl1, icon1;

              if (ele.status == 'inprogress') {
                // ////console.log(element.bookingStatus);
                // imageUrl1 = './../../assets/common_images/tec_black_pointer.png';
                // icon1 = {
                //   url: imageUrl1
                // };

                icon1 = L.icon({
                  iconUrl: './../../assets/common_images/tec_black_pointer.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });

              } else if (ele.status == 'late or cancelled') {

                // imageUrl1 = './../../assets/common_images/pointer_orange_active.png';
                // icon1 = {
                //   url: imageUrl1
                // };

                icon1 = L.icon({
                  iconUrl: './../../assets/common_images/pointer_orange_active.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });

              } else {
                // ////console.log(element.bookingStatus);
                // imageUrl1 = './../../assets/common_images/tec_black_pointer.png';
                // icon1 = {
                //   url: imageUrl1
                // };

                icon1 = L.icon({
                  iconUrl: './../../assets/common_images/tec_black_pointer.png',
                  // iconSize: [38, 95], // size of the icon
                  // popupAnchor: [0,-15]
                });

              }

              // const imageUrl1 = './../../assets/common_images/tec_black_pointer.png';
              // const icon1 = {
              //   url: imageUrl1
              // };


              // marker = new google.maps.Marker({
              //   position: loc,
              //   map: this.map,
              //   icon: icon,
              //   type: 'patient'
              // });

              // marker = L.marker(
              //   loc,
              //   {
              //     icon: icon,
              //     type: 'patient'
              //   }
              // );



              var marker = L.marker([ele.patientLatitude, ele.patientLongitude], {
                icon: icon0,
                type: 'patient',
              });



              // marker1 = new google.maps.Marker({
              //   position: loc1,
              //   map: this.map,
              //   icon: icon1,
              //   type: 'technician'
              // });

              // marker1 = L.marker(
              //   loc1,
              //   {
              //     icon: icon1,
              //     type: 'technician'
              //   }
              // );

              var marker1 = L.marker([ele.patientLatitude, ele.patientLongitude], {
                icon: icon1,
                type: 'technician'
              });

              this.removeAllMarkers.push(marker);
              this.removeAllMarkers.push(marker1);

              marker.bindTooltip("<b>Worker Name</b><br>" + ele.workerName, {
                permanent: false,
                direction: 'top',
              }).openTooltip();

              marker1.bindTooltip("<b>Worker Name</b><br>" + ele.workerName, {
                permanent: false,
                direction: 'top',
              }).openTooltip();

              markerGroup.addLayer(marker);
              markerGroup.addLayer(marker1);

              // this.map.addLayer(marker);
              // this.map.addLayer(marker1);

              this.map.addLayer(markerGroup);

              // oms.addMarker(marker);
              // oms.addMarker(marker1);

              // this.createMarker(ele, marker, infowindow, i, oms);
              // this.createMarker(ele, marker1, infowindow, i, oms);
            } else {
              // let loc4 = new google.maps.LatLng(ele.patientLatitude, ele.patientLongitude);

              // var marker = L.marker([ele.patientLatitude, ele.patientLongitude]).addTo(this.map);

              let loc4 = L.LatLng(ele.patientLatitude, ele.patientLongitude);

              bounds.extend(loc4);

              let imageUrl4, icon4;
              // imageUrl4 = './../../assets/common_images/location_red_pointer.png';
              // icon4 = {
              //   url: imageUrl4
              // };

              icon4 = L.icon({
                iconUrl: './../../assets/common_images/location_red_pointer.png',
                // iconSize: [38, 95], // size of the icon
                // popupAnchor: [0,-15]
              });

              // marker4 = new google.maps.Marker({
              //   position: loc4,
              //   map: this.map,
              //   icon: icon4,
              //   type: 'patient'
              // });

              // marker4 = L.marker(
              //   loc4,
              //   {
              //     icon: icon4,
              //     type: 'patient'
              //   }
              // );

              marker4 = L.marker([ele.patientLatitude, ele.patientLongitude], {
                icon: icon4,
                type: 'patient'
              });

              this.removeAllMarkers.push(marker4);

              marker4.bindTooltip("<b>Worker Name</b><br>" + ele.workerName, {
                permanent: false,
                direction: 'top',
              }).openTooltip();

              markerGroup.addLayer(marker4);

              // this.map.addLayer(marker4);

              this.map.addLayer(markerGroup);

              // oms.addMarker(marker4);

              // this.createMarker(ele, marker4, infowindow, i, oms);
            }
          });


          val.appointmentDetailsForMapViewOtherWorkerList.forEach((ele) => {

            if (ele.workerName) {

              // let loc3 = new google.maps.LatLng(ele.workerLatitude, ele.workerLongitude);

              let loc3 = L.LatLng(ele.workerLatitude, ele.workerLongitude);


              bounds.extend(loc3);
              // ////console.log(element.bookingStatus);
              let imageUrl3, icon3;

              // ////console.log(element.bookingStatus);
              // imageUrl3 = './../../assets/common_images/tec_gray_pointer.png';
              // icon3 = {
              //   url: imageUrl3
              // }

              icon3 = L.icon({
                iconUrl: './../../assets/common_images/tec_gray_pointer.png',
                // iconSize: [38, 95], // size of the icon
                // popupAnchor: [0,-15]
              });

              // marker3 = new google.maps.Marker({
              //   position: loc3,
              //   map: this.map,
              //   icon: icon3,
              //   type: 'nontechnician'
              // });

              // marker3 = L.marker(
              //   loc3,
              //   {
              //     icon: icon3,
              //     type: 'nontechnician'
              //   }
              // );

              marker3 = L.marker([ele.workerLatitude, ele.workerLongitude], {
                icon: icon3,
                type: 'nontechnician'
              });

              this.removeAllMarkers.push(marker3);

              marker3.bindTooltip("<b>Worker Name</b><br>" + ele.workerName, {
                permanent: false,
                direction: 'top',
              }).openTooltip();

              markerGroup.addLayer(marker3);

              // this.map.addLayer(marker3);
              this.map.addLayer(markerGroup);

              // oms.addMarker(marker3);

              // this.createMarker(ele, marker3, infowindow, i, oms);
            }
          });

          lengthForOne = val.appointmentDetailsForMapViewOtherWorkerList.length;
          lengthForTwo = val.appointmentDetailsForMapViewList.length;
        }
      }
    });

    // oms.addListener('spiderfy', function() {
    //   this.map.closePopup();
    // });


    ////console.log(lengthForOne);
    ////console.log(lengthForTwo);
    if (lengthForOne == 0 && lengthForTwo == 0) {
      bounds.extend(this.lat, this.lang);
    }
    setTimeout(()=>{
      this.map.fitBounds(bounds);
    },500);
    if (lengthForOne == 1 && lengthForTwo == 0 || lengthForTwo == 1 && lengthForOne == 0) {
      setTimeout(() => {
        this.map.setZoom(18);
      });
    }
    // this.map.flyToBounds(bounds);
  }

/* Name:Shailesh
      use: for creating markers on map*/

  createMarker(element, marker, infowindow, i, oms) {
    ////console.log(element);
    // ////console.log(marker);

    google.maps.event.addListener(marker, 'click', () => {
      // ////console.log(marker.type);
      let name;
      let status;

      if (element.status == "pending") {
        status = '<i class="material-icons icon-appointment ' + element.status + '" >hourglass_empty</i>';
      } else if (element.status == "assigned") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">done</i>';
      } else if (element.status == "accepted") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">done</i>';
      } else if (element.status == "inprogress") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">more_horiz</i>';
      } else if (element.status == "completed") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">done</i>';
      } else if (element.status == "expired") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">history</i>';
      } else if (element.status == "cancelled") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">close</i>';
      } else if (element.status == "late or cancelled") {
        status = '<i class="material-icons icon-appointment ' + element.status + '">assignment_late</i>';
      }

      // if (element.workerName == null) {
      //   element.workerName = 'Not available';
      // }

      if (marker.type == 'patient') {
        let workerName;
        name = '<div class="map-markerdetails"><div class="title">Service Location ';
        if (element.delayedStartTime) {
          name += '<div>( ETA: ' + element.delayedStartTime + ' )</div>';
        }
        workerName = element.workerName ? element.workerName : 'Not Available';
        ////console.log(element.status);
        if (element.status == "pending" || element.status == "late or cancelled") {
          name += '<i id="assignAppointment" class="material-icons icon-appointment float-end cursor-pointer ms-2" title="Assign Appointment">people</i>';
        }
        if (element.status != "completed" && element.status != "pending" && element.status != "late or cancelled") {
          name += '<i id="openPerticularChat" class="d-inline-block cursor-pointer material-icons float-end" title="Open chat">chat</i>';
        }

        if (element.status == 'completed' && element.actualStartTime) {
          name += '<i id="openArrrivalTime" class="clock-time d-inline-block cursor-pointer material-icons float-end" title="Actual Schedule">access_time</i>';
        }
        name += '<div class="clearfix"></div></div><div class="driver-details m-0 row border-bottom pb-2"><span class="' + element.status + ' booking-status map-status col-md-2 ps-0" title="' + element.status + '">' + status + '</span> <div class="driver-name col-md-5 ps-0"><label class="d-block">Patient Name</label> <span>' + element.patientName + '</span> </div> <div class="driver-name col-md-5 pe-0"><label class="d-block">Clinician Name</label> <span>' + workerName + '</span> </div></div><div class="clearfix"></div></div><div class="py-2 address-map border-bottom"><label class="d-block">Patient Address</label> <span>' + element.patientAddress + '</span></div><div class="border-bottom py-2 driver-name row m-0"><div class="driver-name col-md-3 ps-0 pe-1"><label class="d-block">Time</label> <span>' + element.startTime + '</span> </div><div class="driver-name col-md-3 ps-0"><label class="d-block">Duration</label> <span>' + element.durationHours + ':' + element.durationMinute + '</span> </div>';
      } else if (marker.type == 'nontechnician') {
        name = '<div class="map-markerdetails"><div class="title">Current Location ';
        // if(element.status != "completed") {
        name += '<i id="openPerticularChat" class="d-inline-block cursor-pointer material-icons float-end" title="Open chat">chat</i>';
        // }
        name += '<div class="clearfix"></div></div><div class="driver-details m-0 row border-bottom pb-2"><div class="driver-name col-md-10 ps-0"><label class="d-block">Clinician Name</label> <span>' + element.workerName + '</span> </div> </div><div class="clearfix"></div></div><div class="py-2 address-map border-bottom"><label class="d-block">Clinician Address</label> <span>' + element.workerAddress + '</span></div><div class="nontechnician border-bottom py-2 driver-name row m-0">';
      } else {
        name = '<div class="map-markerdetails"><div class="title">Current Location ';
        if (element.delayedStartTime) {
          name += '<div>( ETA: ' + element.delayedStartTime + ' )</div>';
        }
        if (element.status != "completed") {
          name += '<i id="openPerticularChat" class="d-inline-block cursor-pointer material-icons float-end" title="Open chat">chat</i>';
        }

        if (element.status == 'completed' && element.actualStartTime) {
          name += '<i id="openArrrivalTime" class="clock-time d-inline-block cursor-pointer material-icons float-end" title="Actual Schedule">access_time</i>';
        }
        name += '<div class="clearfix"></div></div><div class="driver-details m-0 row border-bottom pb-2"><span class="' + element.status + ' booking-status map-status col-md-2 ps-0">' + status + '</span> <div class="driver-name col-md-5 ps-0"><label class="d-block">Clinician Name</label> <span>' + element.workerName + '</span> </div> <div class="driver-name col-md-5 pe-0"><label class="d-block">Patient Name</label> <span>' + element.patientName + '</span> </div></div><div class="clearfix"></div></div><div class="py-2 address-map border-bottom"><label class="d-block">Clinician Address</label> <span>' + element.workerAddress + '</span></div><div class="border-bottom py-2 driver-name row m-0"><div class="driver-name col-md-3 ps-0 pe-1"><label class="d-block">Time</label> <span>' + element.startTime + '</span> </div><div class="driver-name col-md-3 ps-0"><label class="d-block">Duration</label> <span>' + element.durationHours + ':' + element.durationMinute + '</span> </div>';
      }
      if (element.organizationBasedProcedureDetailsList.length > 0) {
        name += '<div id="allProcedures" class="col-md-6 d-flex flex-row-reverse locations-icons p-0">';
        if (element.organizationBasedProcedureDetailsList.length > 4) {
          const number = element.organizationBasedProcedureDetailsList.length - 3;
          name += '<div class="img-data" id="showIcons"><div class="img-count cursor-pointer">' + number + '</div></div>'
        }
        element.organizationBasedProcedureDetailsList.forEach((ele, index) => {
          ////console.log(index);
          if (index <= 2 && ele.procedureIcon) {
            name += '<img class="me-1" title="' + ele.procedureName + '" alt="' + ele.procedureName + '" src="' + this.service.common.superAdminUrl + ele.procedureIcon + '" />';
          }
          if (!ele.procedureIcon) {
            name += '<img alt="No Image" src="../../../../assets/common_images/profile.jpg" />'
          }
        });
        name += '<div class="clearfix"></div></div></div>'


        // document.getElementById("iconNumber").addEventListener("click", function(e) {
        //   ////console.log(element);
        // });
      }

      // if(element.appointmentsDurationCode == this.showImg) {
      //   element.organizationBasedProcedureDetailsList.forEach((ele, index)=>{
      //     ////console.log(index);
      //     if(index >=4  && ele.procedureIcon){
      //       name += '<img class="me-1" title="'+ele.procedureName+'" alt="'+ele.procedureName+'" src="'+ this.service.common.superAdminUrl+ele.procedureIcon +'" />';
      //     }
      //   });
      // }

      // infowindow.setContent(name);
      // infowindow.open(this.map, marker);
      // google.maps.event.addListenerOnce(infowindow, 'domready', () => {
      //   if (element.status == "pending" || element.status == "late or cancelled") {
      //     document.getElementById('assignAppointment').addEventListener('click', () => {
      //       this.assignAppointment(element.appointmentsDurationCode);
      //     });
      //   }

      //   if (document.getElementById('openArrrivalTime')) {
      //     document.getElementById('openArrrivalTime').addEventListener('click',()=>{
      //       this.openArrivalDialog(element);
      //     })
      //   }

      //   // ////console.log(document.getElementById('showIcons'));
      //   if (document.getElementById('showIcons')) {
      //     document.getElementById('showIcons').addEventListener('click', () => {
      //       this.showAllIcons(element);
      //     });
      //   }
      //   if (document.getElementById('openPerticularChat')) {
      //     document.getElementById('openPerticularChat').addEventListener('click', () => {
      //       this.showChat(element.workerCode);
      //     });
      //   }





      // });
    });

    oms.addMarker(marker);
    return marker;
  }

  /* Name:Shailesh
      use: for showing procedure icons*/
  showAllIcons(ele1) {
    // ////console.log(ele1);
    // document.getElementById('showIcons').remove();
    let showMoreIcons = document.getElementById('allProcedures');
    showMoreIcons.classList.remove("d-flex");
    let name = '';
    ele1.organizationBasedProcedureDetailsList.forEach((ele, index) => {
      ////console.log(index);
      if (ele.procedureIcon) {
        name += '<img class="me-1 mb-1" title="' + ele.procedureName + '" alt="' + ele.procedureName + '" src="' + this.service.common.superAdminUrl + ele.procedureIcon + '" />';
      }
      if (!ele.procedureIcon) {
        name += '<img alt="No Image" src="../../../../assets/common_images/profile.jpg" />'
      }
    });
    // ////console.log(name);
    showMoreIcons.innerHTML = name;
  }

  /* Name:Shailesh
      use: for assign appointment to elegible clinician*/

  assignAppointment(jobDurationCode) {
    this.service.common.progressLoader = true;
    let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);
    try {
      this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode, this.currentSelectedDate, WeekstartDate, weekendDate, (data) => {
      ////console.log(data);
      if (data.data.workerWithDistanceList.length > 0) {
        const dialogRef = this.dialog.open(AssignAppointmentComponent, {
          width: '750px',
          disableClose: true,
          data: { data, jobDurationCode }
        });
        dialogRef.afterClosed().subscribe(result => {
          //////console.log(result);
          if (result != undefined) {
            this.service.common.message = result;
            this.service.common.successMessage = true;
            this.adminGetAppointmentDetailsForMapView(this.formatedDate);
          }
        });
      }
      else {

        const dialogRef = this.dialog.open(SplitAppointmentComponent, {
          width: '450px',
          disableClose: true,
          data: { data, jobDurationCode }
        });
        dialogRef.afterClosed().subscribe(result => {
          //////console.log(result);
          if (result != undefined) {
            // this.getAppointments();
            this.service.common.message = result;
            this.service.common.successMessage = true;
            this.adminGetAppointmentDetailsForMapView(this.formatedDate);
          }
        });
      }
      this.service.common.progressLoader = false;

    });
    } catch (error) {
      //console.log(error)
    }
    
  }

/* Name:Shailesh
      use: for ahowing procedure image*/
  showImage(element) {
    ////console.log(element);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
  }

  /* Name:Shailesh
      use: for seraching location*/
  applyFilter(search) {
    ////console.log(search);
    this.searchKey = search;
    const locationValue = [];
    const workerValue = [];
    let currentSelectedValue;
    // ////console.log(this.locationKeys[this.currentValue]);
    if (this.value == 0) {
      currentSelectedValue = this.locationKeys[0];
    } else {
      currentSelectedValue = this.locationKeys[this.currentValue];
    }
    // ////console.log(this.allLocationData);
    ////console.log(this.removeAllMarkers);

    this.removeAllMarkers.forEach((element) => {
      this.map.removeLayer(element);
    });

    // this.map.clearMarkers();

    if (search) {

      const allFilteredLocations = this.allLocationData[currentSelectedValue]['appointmentDetailsForMapViewList'].slice();
      const allFilteredWorkerLocations = this.allLocationData[currentSelectedValue]['appointmentDetailsForMapViewOtherWorkerList'].slice();
      allFilteredLocations.forEach((item, key) => {
        let data = ((item.patientName.toLowerCase().includes(search.toLowerCase()))
          || (item.workerName && item.workerName.toLowerCase().includes(search.toLowerCase())));
        if (data) {
          locationValue.push(item);
        }
      })
      allFilteredWorkerLocations.forEach((item, key) => {
        let data = ((item.workerName.toLowerCase().includes(search.toLowerCase())));
        if (data) {
          workerValue.push(item);
        }
      })
      this.locationData[currentSelectedValue] = { 'appointmentDetailsForMapViewList': locationValue, 'appointmentDetailsForMapViewOtherWorkerList': workerValue };
      ////console.log(this.locationData[currentSelectedValue]);
      this.googleMap();
    } else {
      this.locationData[currentSelectedValue] = { 'appointmentDetailsForMapViewList': this.allLocationData[currentSelectedValue].appointmentDetailsForMapViewList.slice(), 'appointmentDetailsForMapViewOtherWorkerList': this.allLocationData[currentSelectedValue].appointmentDetailsForMapViewOtherWorkerList.slice() };
      this.googleMap();
    }

  }

  /* Name:Shailesh
      use: for opening chat with perticular technician*/
  showChat(element) {
    ////console.log(element);
    this.service.common.progressLoader = true;
    try {
      this.ChatService.adminGetGroupDetailByUserCodeAndAdminCode(element, this.service.local.get('userCode'), (response) => {
      ////console.log(response);
      if (response.responsecode == 200) {
        ////console.log(response.data.groupDetailsCode);
        this.service.common.progressLoader = false;
        this.openPerticularChat(response.data.groupDetailsCode);
      }
    })
    } catch (error) {
      //console.log(error)
    }
    

  }


  /* Name:Shailesh
      use: for open chat screen*/
  openPerticularChat(groupDetailsCode): void {
    // ////console.log(groupDetailsCode);
    // ////console.log(document.getElementById('chat-application'));
    // document.getElementById('chat-application').setAttribute('groupdetailscode',groupDetailsCode)
    // document.getElementById('chat-application').click();

    const dialogRef = this.dialog.open(ChatComponent, {
      width: '66%',
      panelClass: 'chat-popup',
      data: { groupDetailsCode: groupDetailsCode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.service.common.globalChatCount != 0) {
        this.service.common.getGlobalCountChat();       
      }
    });
  }

  /* Name:Shailesh
      use: for open popup for showing arrival and departure timing*/
  openArrivalDialog(element): void {
    ////console.log(element);


    if (element.actualStartTime) {
      if (element.dateFormat == 'dd-MM-yyyy') {
        element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[0] + '-' + element.actualStartTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      } else if (element.dateFormat == 'MM-dd-yyyy') {
        element.arrivalTimeDate = moment(element.actualStartTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      } else {
        element.arrivalTimeDate = moment(element.actualStartTime.split('-')[1] + '-' + element.actualStartTime.split('-')[2] + '-' + element.actualStartTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.arrivalTimeDate);
      }
    }
    if (element.actualEndTime) {
      if (element.dateFormat == 'dd-MM-yyyy') {
        element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[0] + '-' + element.actualEndTime.split('-')[2], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      } else if (element.dateFormat == 'MM-dd-yyyy') {
        element.departureTimeDate = moment(element.actualEndTime, 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      } else {
        element.departureTimeDate = moment(element.actualEndTime.split('-')[1] + '-' + element.actualEndTime.split('-')[2] + '-' + element.actualEndTime.split('-')[0], 'MM-DD-YYYY ' + element.timeFormat).format(element.timeFormat);
        ////console.log(element.departureTimeDate);
      }
    }

    // element.arrivalTimeDate = moment(new Date(element.actualStartTime), element.timeFormat).format(element.timeFormat);
    // element.departureTimeDate = moment(new Date(element.actualEndTime), element.timeFormat).format(element.timeFormat);

    const dialogRef = this.dialog.open(ArrivalDepartureComponent, {
      width: '500px',
      data: { arrival: element.arrivalTimeDate, departure: element.departureTimeDate }
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }


  /* Name:Shailesh
      use: for assign appointment by opta planner*/
  autoAssign() {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to auto staff assign?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.common.progressLoader = true;
          this.service.common.percentageLoader = true;
          try {
            this.service.adminAssignJobsByOptaplanner(this.service.common.local.get('orgCode'), this.formatedDate, this.formatedDate, this.pending, (res) => {
              //////console.log(res);
              if (res.responsecode == 200) {

                this.service.totalRecords = res.data.totalRecord;
                this.service.processedRecords = res.data.processRecord;
                this.service.common.progressText = 'Processing ' + this.service.processedRecords + ' of  ' + this.service.totalRecords + ' appointments';
                try {
                  this.service.getAppointmentThreadProcessingByCode(res.data.appointmentsThreadProcessingCode, (response) => {
                    let arrayData = [];
                    response.data.ewresLst.forEach(element => {
                      if (element.workerWithDistanceList.length > 0) {
                        arrayData.push(element);
                      }
                    });
                    setTimeout(() => {
                      //console.log(arrayData);
                      this.service.common.progressLoader = false;
                      this.service.common.progressText = '';
                      this.service.common.percentageLoader = false;
                      if (arrayData.length == 0) {
                        this.getSplitAppointment(response.data.appointmentsDurationCodeList);
                      } else {

                        this.adminGetAppointmentDetailsForMapView(this.formatedDate);
                        this.openDialog(arrayData);
                      }
                    }, 2000);
                  })
                } catch (error) {
                  //console.log(error)
                }

              } else {
                this.service.common.progressLoader = false;
                this.service.common.message = res.message;
                this.service.common.errorMessage = true;
              }

            })
          } catch (error) {
            //console.log(error)
          }
        } catch (error) {
          //console.log(error)
        }

      }
    });
   
  }

  
  /* Name:Shailesh
   use: to open split appointment popup */
   getSplitAppointment(jobDurationCode) {
    const dialogRef = this.dialog.open(SplitAppointmentComponent, {
      width: '450px',
      disableClose: true,
      data: { jobDurationCode }
    });
    dialogRef.afterClosed().subscribe(result => {
      //////console.log(result);
      if (result != undefined) {
        this.isShowRefresh = false;
        // this.getAppointments();
        this.service.common.message = result;
        this.service.common.successMessage = true;
      }
    });
  }

  /* Name:Shailesh
      use: for open popup for assigning clinician */
  openDialog(workers): void {
    const dialogRef = this.dialog.open(AssignedWorkerComponent, {
      width: '500px',
      disableClose: true,
      data: { data: workers, fromdate: this.formatedDate, toDate: this.formatedDate, scheduledDate: this.formatedDate }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.adminGetAppointmentDetailsForMapView(this.formatedDate);
      ////console.log('The dialog was closed');
    });
  }

}
