import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
 import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { RoleService } from '../role/role.service';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';
import { CommonService } from 'src/app/shared/common.service';
 

@Component({
  selector: 'app-role-profile',
  templateUrl: './role-profile.component.html',
  styleUrls: ['./role-profile.component.scss'],
  providers:[RoleService]
})
export class RoleProfileComponent implements OnInit , OnDestroy{
  profileListData:any =[];
  permissionListData:any =[];
  roleProfile_Form : FormGroup ;

  displayedColumns: string[] = [
    'pagePermissionName', 
    'add',
    'view',
    'edit',
    'delete'
   ];

   displayedColumnsForMobile: string[] = [
    'dataPermissionName', 
      'view' 
   ];

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  checkbox:any ={
    addPermission:false,
    editPermission:false,
    viewPermission:false,
    delPermission:false
   }
  
   hasPermission:IBtnControlsPermission ;
   clickedTab:string;

   private clearObs = new Subject<any>();

  constructor(private _roleService :RoleService ,
     private translate: TranslateService,
     private dialog: MatDialog,
    private _commonSVC : CommonService) {

    this.translate.setDefaultLang(this._roleService._session.get('language'));

   }

  ngOnInit(): void {
    this.initializeForm();
    this.getProfileList('');
    this.hasPermission =  this._roleService.common.getBtnEventPermissions("Role Profile Settings"); 
     this.subToPermissionChanged();

  }

  initializeForm()
  {
    this.roleProfile_Form = new FormGroup({
      profileName : new FormControl('', Validators.required),
      isDefault: new FormControl(false),
      systemRoleCode : new FormControl('')
    })
  }

  getProfileList(systemRoleCode:string) {

     try {
      this._roleService.common.progressLoader = true;
      this._roleService.getAllSystemRoleByOrgCode(this._roleService._session.get('orgCode')).subscribe((response:any) => {
      //console.log("role profile List " , response);
      if (response.responsecode == 200) {

          this._roleService.common.progressLoader = false;
          this.profileListData = response.data;

          const index = response.data.findIndex(
            (element: any) => element.systemRoleCode === systemRoleCode
          );
          if (index !== -1) {
            
            // fetching permission list data as per recent save or update
            this.getPermissionList(response.data[index]);
          }
          else{
            // fetching permission list  data first time on page load
          this.getPermissionList(response.data[0]);
          }
 

         }
      })
  } catch (error) {
      //console.log(error)
    }
    
  }

 
  getPermissionList(data:any) { 
    
    this.clickedTab = data.roleName;
    this.resetCheckboxes();
    // Binding Form 
   this.roleProfile_Form.patchValue({
    profileName :  data.roleName,
    isDefault:  data.isDefault,
    systemRoleCode : data.systemRoleCode
   })

    try {
     this._roleService.common.progressLoader = true;
     this._roleService.getAllPermissionList(data.systemRoleCode).subscribe((response:any) => {
      //console.log("roronoa zoro" , response);
     if (response.responsecode == 200) {

        this._roleService.common.progressLoader = false;
         this.permissionListData = response.data;

         this.permissionListData.forEach(ele=>{
          if(ele.pagePermissionName === "Mobile App" )
          {
            ele.isExpanded  = false;
          }
          else{
            ele.isExpanded  = true;
          }
         })

         this.dataSource = new MatTableDataSource(this.permissionListData);
         // Apply the sorting accessor for case-insensitive sorting
         this.dataSource.sortingDataAccessor = this._roleService.common.globalSortingDataAccessorForMatSort;
         this.dataSource.sort = this.matSort;

      }
     })
 } catch (error) {
     //console.log(error)
   }
   
 }
 

 
 resetCheckboxes()
 {
  this.checkbox ={
    addPermission:false,
    editPermission:false,
    viewPermission:false,
    delPermission:false
   }
 }

AddNewProfile()
{ 

 this.clickedTab = '';  
 this.resetCheckboxes();

this.permissionListData.forEach((element: any) => {
  element.addPermission = (element.addPermission != null)? false : element.addPermission;
  element.editPermission = (element.editPermission != null)? false : element.editPermission;
  element.viewPermission = (element.viewPermission != null)? false : element.viewPermission;
  element.delPermission = (element.delPermission != null)? false : element.delPermission;
  
  if (element?.dataPermissionRoleMappingList?.length > 0) {
    element.dataPermissionRoleMappingList.forEach(
      (elementChild: any) => {
        elementChild.isView = (elementChild.isView != null)? false : elementChild.isView;
      }
    );
  }
});


this.roleProfile_Form.reset()

}


setSelectedProfile(item:any)
{
this.getPermissionList(item);
}


add_Update_Permission()
{
  if(this.roleProfile_Form.valid)
  {
    var request_body = {

              isDefault :   this.roleProfile_Form.controls.isDefault.value,
              organizationCode :   this._roleService._session.get('orgCode'),
              roleDescription :  'Staffing Role description Test',
              roleName :    this.roleProfile_Form.controls.profileName.value,
              systemRoleCode :    this.roleProfile_Form.controls.systemRoleCode.value,  // based on systemRole add/update
    }
    this._roleService.common.progressLoader = true;
  this._roleService.saveSystemRoleProfile(request_body).subscribe((res:any) =>{

    this._roleService.common.progressLoader = false;

    if(res.responsecode == 200)
    { 
      this.permissionListData.forEach((element: any) => { // updating rolecode
        element.roleCode = res.data.systemRoleCode;
        if (element?.dataPermissionRoleMappingList?.length > 0) {
          element.dataPermissionRoleMappingList.forEach(
            (elementNew: any) => {
              elementNew.roleCode = res.data.systemRoleCode;
            }
          );
        }
      });

      this.savePagePermissionDetailsByRole(this.permissionListData , res.data.systemRoleCode);

    }
  })


  }
}


savePagePermissionDetailsByRole(Updated_permissionListData:any , systemRoleCode:any)
{
  this._roleService.savePagePermissionDetailsByRole(Updated_permissionListData).subscribe((res:any) => {
    if (res.responsecode == 200) {
      this._roleService.common.successMessage = true;
      this._roleService.common.message = res.message;
      this.getProfileList(systemRoleCode);  
    }
    else{
      this._roleService.common.errorMessage = true;
      this._roleService.common.message = res.message;
    }
  })
}
 
// onClickUpdatePermission(event)
// { 
//   this.add_Update_Permission();
// }

deleteProfile(item:any)
{
  const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
    width: '350px',
    data: { msg: 'Are you sure want to delete role profile "'+item.roleName+'"?' }
  });

  dialogRef.afterClosed().subscribe(result => {

    if(result && result.toLowerCase() === 'ok')
    {
      this._roleService.common.progressLoader = true;
   this._roleService.softDeleteBySystemRoleCode(item.systemRoleCode).subscribe((res:any) => {
    this._roleService.common.progressLoader = false;
    if (res.responsecode == 200) {
      this._roleService.common.successMessage = true;
      this._roleService.common.message = res.message;
      this.getProfileList('');  
    }
    else{
      this._roleService.common.errorMessage = true;
      this._roleService.common.message = res.message;
    }

      })
    }
   })
 }


 toggleRow(element: any): void {
  element.isExpanded = !element.isExpanded;
 //this.dataSource.data = [...this.dataSource.data];
}


// onCheck(event:any, permission:string)
// {

//   this.permissionListData.forEach((element: any) => {
//     if(element.pagePermissionName !== 'Mobile App')
//     {
//       element[permission] = event.checked;
//     }
     
//    });

  
   

// }



onCheck(event:any, permission:string)
{

  if(permission === 'viewPermission'){
    if(!event.checked)
      {
        this.permissionListData.forEach((element: any) => {
          if(element.pagePermissionName !== 'Mobile App')
          {
            // element[permission] = false;
            element.delPermission = false;
            element.editPermission = false;
            element.addPermission = false;
            element.viewPermission = false;
          }
           
         });

         setTimeout(()=>{
          this.resetCheckboxes();   // setting value as false ;
         },300)
         
      }
      else
      {
        this.permissionListData.forEach((element: any) => {
          if(element.pagePermissionName !== 'Mobile App')
          {
             element[permission] = event.checked;
           }
           
         });
      }

  }
  else{

    this.permissionListData.forEach((element: any) => {
      if(element.pagePermissionName !== 'Mobile App')
      {
        if(event.checked)
        {
          element.viewPermission = event.checked;
        }
         
         element[permission] = event.checked;
       }
       
     });

    setTimeout(()=>{
        this.checkbox.viewPermission = true ;
      },300)

  }

  
}




copyProfile(data:any) { 
  this.resetCheckboxes();
   
 this.roleProfile_Form.patchValue({
  profileName :  data.roleName+'_copy',
  isDefault:  false,
  systemRoleCode : ''
 });

  try {
   this._roleService.common.progressLoader = true;
   this._roleService.getAllPermissionList(data.systemRoleCode).subscribe((response:any) => {
    this._roleService.common.progressLoader = false;
     if (response.responsecode == 200) {

      this.permissionListData = response.data;
       this.add_Update_Permission();
    }
   })
} catch (error) {
   //console.log(error)
 }
 
}


// onClickToggle(event:MatSlideToggleChange, selected_tblData:any, permission:string)

onClickToggle(event:any, selected_tblData:any, permission:string)
{
 
  if(permission === 'viewPermission')
  {
      if(!event.checked)
      {
        selected_tblData.delPermission = false;
        selected_tblData.editPermission = false;
        selected_tblData.addPermission = false;
      }
  }
  else{

    selected_tblData[permission] = event.checked;

    if(event.checked)
    {
      selected_tblData.viewPermission = event.checked;
    }

    // if(!selected_tblData.viewPermission)
    // {
    //   this._roleService.common.errorMessage = true;
    //   this._roleService.common.message = selected_tblData.pagePermissionName +" has no view permission";
      
    //    selected_tblData.delPermission = false;
    //     selected_tblData.editPermission = false;
    //     selected_tblData.addPermission = false;

    //     // uncheck toggle
    //     event.source.checked = false ;
    // }

  }

}


subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this._commonSVC.getBtnEventPermissions("Role Profile Settings");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }



}
