<div class="w-100">

  <div class="col-md-12">

    <div class="row mb-4">
      <div class="col-md-6 align-self-center">
        <h4 class="mb-0">{{ popupTitle }}</h4>
      </div>
      <div class="col-md-6 text-end">
        <button class="btn-close cursor-pointer" [mat-dialog-close]="true"></button>
      </div>
    </div>

    <div class="row overflow_popup_body">

      <div class="w-100 mb-3 customPopUpScrollBody">

        <form class="admin-form" [formGroup]="addAdminForm" autocomplete="off" (ngSubmit)="onSubmitUser(false)">

          <div class="w-100 p-0 m-0 mt-2" *ngIf="isShortcutModalOpen">
            <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
              <mat-label>Select Customer</mat-label>
              <mat-select formControlName="customer" placeholder="Select" (selectionChange)="changeCustomer($event)">
                <mat-option *ngFor="let customer of customerList" [value]="customer?.customerCode">
                  {{customer?.customerName}}
                </mat-option>
              </mat-select>
              <mat-error class="help-block err"
                *ngIf="addAdminForm.get('customer').errors?.required && (!submitted || addAdminForm.get('customer').touched)">
                Please select a role.
              </mat-error>
            </mat-form-field>

          </div>

          <div class="w-100 p-0 m-0 mt-2">
            <mat-form-field appearance="outline" floatLabel="always" class="example-full-width p-0 m-0">
              <mat-label>Name</mat-label>
              <input matInput class="form-control" formControlName="adminName">

              <mat-error class="help-block err" *ngIf="addAdminForm.get('adminName').errors?.required && (!submitted
              || addAdminForm.get('adminName').touched)">Name Required*</mat-error>
            </mat-form-field>
          </div>

          <div class="w-100 p-0 m-0">
            <mat-form-field appearance="outline" floatLabel="always" class="example-full-width p-0 m-0">
              <mat-label>Email</mat-label>
              <input matInput class="form-control" formControlName="adminEmail">

              <mat-error class="help-block err" *ngIf="addAdminForm.get('adminEmail').errors?.required && (!submitted
              || addAdminForm.get('adminEmail').touched)">Email is Required*</mat-error>
              <mat-error class="help-block err" *ngIf="addAdminForm.get('adminEmail').errors?.pattern && (!submitted
              || addAdminForm.get('adminEmail').touched) || addAdminForm.get('adminEmail').errors?.pattern">Please
                provide a valid email address.</mat-error>

              <mat-error class="help-block err"
                *ngIf="addAdminForm.get('adminEmail').errors?.invalid  && (!submitted || addAdminForm.get('adminEmail').touched)">
                Email ID already taken
              </mat-error>

            </mat-form-field>

          </div>

          <div class="w-100 p-0 m-0">
            <mat-form-field appearance="outline" floatLabel="always" class="example-full-width p-0 m-0">
              <mat-label>Phone Number</mat-label>
              <input matInput class="form-control" formControlName="phoneNumber" appPhoneMask maxlength="12">

              <mat-error class="help-block err" *ngIf="addAdminForm.get('phoneNumber').errors?.pattern && (!submitted
              || addAdminForm.get('phoneNumber').touched) || addAdminForm.get('phoneNumber').errors?.pattern">
                Please, Enter 10 digit Mobile Number.</mat-error>
            </mat-form-field>

          </div>

          <!-- <div class="w-100 p-0 m-0">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
              <mat-label>Select Role</mat-label>
              <mat-select formControlName="roleName" placeholder="Select">
                <mat-option *ngFor="let role of allRoleList" [value]="role.roleName">
                  {{role.roleName}}
                </mat-option>
              </mat-select>
              <mat-error class="help-block err"
              *ngIf="addAdminForm.get('roleName').errors?.required && (!submitted || addAdminForm.get('roleName').touched)">
              Please select a role.
            </mat-error>
            </mat-form-field>
           
          </div> -->

          <div class="w-100 mt-4" *ngIf="isDropdownLoaded">
            <angular2-multiselect [data]="customerAddressList"
              [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
              (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
              (onDeSelectAll)="onDeSelectAll($event)" formControlName="customerAdminAddressMappingRequestList">
            </angular2-multiselect>
          </div>

          <ng-template [ngIf]="customerPermission.length > 0">
            <hr>
            <div class="row">
              <span class="mini-header">Permissions</span>
            </div>
            <div class="row  checkbox-lbl">
              <ng-container *ngFor="let permission of customerPermission">
                <div class="col-md-4">
                  <mat-checkbox [(ngModel)]="permission.isView" [ngModelOptions]="{standalone: true}"
                    (change)="chx_permissionChange($event,permission)">
                    {{permission.dataPermissionName}}</mat-checkbox>
                </div>
              </ng-container>
              <!-- <div class="col-md-3">
            <mat-checkbox >Job Status</mat-checkbox>
            <mat-checkbox >Job Requests</mat-checkbox>
            </div>
            <div class="col-md-4">
            <mat-checkbox >Check-in/Check-out Workers</mat-checkbox>
            <mat-checkbox >Supervise Break</mat-checkbox>
            </div>
            <div class="col-md-4">
            <mat-checkbox >Approve Timecards</mat-checkbox>
            <mat-checkbox >Approve Invoice</mat-checkbox>
           </div> -->
            </div>
          </ng-template>

          <div class="ms-auto p-2 mt-3">
            <div class="text-end d-flex float-end">
              <button mat-button type="button" class="me-3" [mat-dialog-close]="true">{{ 'Technicians.labels.Cancel' |
                translate }}</button>
                <button *ngIf="!customerAdminCode" class="me-3" type="button" mat-raised-button color="primary" matTooltip="Add and send welcome email" (click)="onSubmitUser(true)">Add & Send</button>
              <button type="submit" mat-raised-button color="primary">{{ !customerAdminCode ? 'Add' : 'Update'
                }}</button>
            </div>
          </div>

        </form>

      </div>
    </div>

  </div>

</div>