<div class="appointment-popup">
  
    <div class="row m-0 mb-3">
      <div class="col-md-10">
        <div class="row justify-content-start">
        <h6 class="mb-0 title-pd ps-0">{{ 'JobType.labels.Job Detail' | translate }} </h6>
        <hr class="title">
        </div>
      </div>
      <div class="col-md-2">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" type="button" (click)="onNoClick()"></button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 text-center pe-0">
        <img class="img-procedure" alt="No Image" title="{{data['procedure'].procedureName}}"
          [src]="service.service.getSuperAdmin() + '/downloadFile/' + data['procedure'].procedureIcon" />
      </div>
      <div class="col-md-10 ps-0">
        <small>{{ 'JobType.labels.Job Name' | translate }}</small>
        <p *ngIf="data['procedure'].procedureName">{{data['procedure'].procedureName}}</p>
      </div>


    </div>

  
</div>