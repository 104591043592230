import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { Observable } from 'rxjs';
 

@Injectable({
  providedIn: 'root'
})
export class NewJobService {

   constructor(
     private http: HttpClient,
     private  _apiSVC: ApiRootService,
   ) { }
  
  getAllCustomerDetails(orgCode:string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllCustomerDetailsByOrganizationCode/' + orgCode);
  }

  getAllShiftMaster(orgCode:string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getShiftMasterByOrganizationCode/' + orgCode);
  }

  getAllEquipmentMaster(orgCode:string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllEquipmentMasterDetailsByOrganizationCode/' + orgCode);
  }

  getAllUniformMaster(orgCode:string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllUniformMasterDetailsByOrganizationCode/' + orgCode);
  }

  getAllProcedure(orgCode:string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllProcedureByOrganizationCode/' + orgCode);
  }

  createNewJob(request_body:any){
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/v2/createJob' ,request_body); 
  }

  EditJob(appointmentsCode:string, appointmentsProcedureCode:string, request_body:any){
    return this.http.put(this._apiSVC.getHealthCareUrl() + '/editJob'+'/'+ appointmentsCode +'/'+ appointmentsProcedureCode ,request_body);
  }

  saveUniform(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateUniformMaster' ,request_body);
  }

  saveEquipment(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateEquipmentMaster' ,request_body);
  }

  // saveJobSite(request_body:any) {
  //   return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateCustomerAddressOnlyForCustomerAddress' ,request_body);
  // }

  saveJobSite(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveCustomerAddressOnlyForCustomerAddressFormJob' ,request_body);
  }

  saveNewCustomer(request_body:any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateCustomerOnlyForCustomer' ,request_body);
  }

  getAllAdminByCustomerCodeAndCustomerAddressCode(customerCode:string , customerAddressCode : string){
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllAdminByCustomerCodeAndCustomerAddressCode/' + customerCode + '/'+customerAddressCode);
  }

  getSiteSupervisorList_forUpdate(organizationCode:string, customerCode:string, appointmentsCode:string, appointmentsProceduresCode: string ,date: string)
  {
    return this.http.get(`${this._apiSVC.getHealthCareUrl()}/getSiteSupervisorListForUpdate/${organizationCode}/${customerCode}/${appointmentsCode}/${appointmentsProceduresCode}/${date}`);
  }
 
  getJobsByAppointmentsProceduresCode(organizationCode:string,appointmentsCode:string,appointmentsProceduresCode:string,jobDate){
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getJobsByAppointmentsProceduresCode/' + organizationCode+'/' + appointmentsCode+'/' + appointmentsProceduresCode+'/' + jobDate);
  }
  editJob(appointmentsCode:string,appointmentsProceduresCode:string,request_body:any){
    return this.http.put(this._apiSVC.getHealthCareUrl() + '/editJob/'  + appointmentsCode+'/' + appointmentsProceduresCode,request_body);
  }
  getAllDistributionList(org_code:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getDistributionListByOrganizationCodeByPage/' + org_code+ '?page=0&limit=1000')
  }

  PublishJob(request_body) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/publishJobs' , request_body)
  }


  getEligibleStaffsByAppointmentsDurationCode(appoinmentDurationCode:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleTechnicianWithoutPreferredHoursForAppointmentByAppointmentsDurationCode/'+ appoinmentDurationCode)
  }

  assignJob(appoinmentDurationCode:string , workerCode : string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobAssignedByAdmin/'+ appoinmentDurationCode +'/' + workerCode)
  }

  getDecliedAndLateListByOrganizationCode(organizationCode:string, status:string)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getDecliedAndLateListByOrganizationCode/'+ organizationCode +'/' + status)
  }

  getEligibleStaffs(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaff/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date )
  }

  jobAssignedByAdminJobCode(appointmentsCode:string, appointmentsProceduresCode:string, date:string, workerCode:string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/jobAssignedByAdminJobCode/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date +'/' +workerCode )
  }

  createJobByCustomerRequest(appointmentsRequestCode:string, request_body:any)
  {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/v2/createJobFromCustomerRequest/'+appointmentsRequestCode ,request_body);
    // return this.http.post(this._apiSVC.getHealthCareUrl() + '/createJobFromCustomerRequest/'+appointmentsRequestCode ,request_body);
   }
 
getRatePerHour(organizationBaseProcedureCode:string, customerCode:string)
{
  return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllCustomerJobTypeRateByCustomerCodeAndOrganizationBaseProcedureCode/'+ organizationBaseProcedureCode +'/' + customerCode ) 
}
 

jobAssignedToManyStaffByAdmin(payload:any) {
  return this.http.post(this._apiSVC.getHealthCareUrl() + '/jobAssignedToManyStaffByAdmin', payload)
}


adminGetEligibleStaffForNotify(appointmentsCode:string, appointmentsProceduresCode:string, date:string) {
  return this.http.get(this._apiSVC.getHealthCareUrl() + '/adminGetEligibleStaffForNotify/'+ appointmentsCode +'/' + appointmentsProceduresCode +'/' +date )
}

saveOrUpdateCustomerSpecialInstruction(payload:any):Observable<any>
{
  return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateCustomerInstructions', payload)
}

v3_EditJob(appointmentsCode:string, date:string, request_body:any){
  return this.http.put(this._apiSVC.getHealthCareUrl() + '/v3/editJob'+'/'+ appointmentsCode +'/'+ date , request_body);

}


multipleJobUnAssignedByAdmin(organizationCode:string, payload:any)
{
  return this.http.put(this._apiSVC.getHealthCareUrl() + '/multipleJobUnAssignedByAdmin'+'/'+ organizationCode, payload );
 }

 getSiteSupervisorList_ForSave(organizationCode:string, customerCode:string, ){
  return this.http.get(this._apiSVC.getHealthCareUrl() + '/getSiteSupervisorListForSave/'+organizationCode+'/'+customerCode);
}


}
