import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AdminCustomerReportService } from '../admin-customer-report.service';
import { SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { CommonApiService } from 'src/app/shared/services/common-apiService';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-time-card-report',
  templateUrl: './time-card-report.component.html',
  styleUrls: ['./time-card-report.component.scss']
})
export class TimeCardReportComponent implements OnInit, OnDestroy {

  displayedColumns = [
    'Staff_Name',
    'Custome_Name',
    'Job_Id',
    'Job_Site',
    'Clock_In_Time',
    'Clock_Out_Time',    
    'Total_Time',
    'Paid_Time',
    'comment',
    'status'
    ];

  select = new FormControl();

  img_serverPath = this.commonAPIService.service.getSuperAdmin() + '/downloadFile/';

  timeCardReport_List : any[];
  dataSource = new MatTableDataSource();
  checkInReportForm : FormGroup ;
  private page: number = 0;
  private limit : number = 30 ;
  isLoading:boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private observableDestroyer = new Subject();
  private isValuePersist : string | null = null ;
  // startDate = moment().subtract(1, 'months').toDate();
  startDate = moment().toDate();
  endDate= moment().toDate();
  searchText: any;

  exportAsConfig: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'time-card-report-dataTable', // the id of html/table element
    // options: { // html-docx-js document options
    //   orientation: 'landscape',
    //   margins: {
    //     top: '2000',
    //     left: '2000',
    //     right: '2000',
    //     bottom: '2000',
    //     header: '2000',
    //     gutter: '20'
    //   },
    // }
  };
  @ViewChild('iframe') iframe: ElementRef;
  all_customer_list:any[] = [];
  all_customer_list_filtered:any[] = [];

  constructor(
    private timeCardService : AdminCustomerReportService,
    private sessionService: SessionStorageService,
    private _translateSVC: TranslateService,
    private exportAsService: ExportAsService,
    private commonAPIService :CommonApiService
  ){
    this._translateSVC.setDefaultLang(this.sessionService.get('language'));
  }

  
  ngOnInit(): void {
    this.initializeForm();
    // this.page = 0;
    // this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate);
    // this.getTimeCardReports();
    this.getTableData();
    this.inputSearch();
    this.get_all_customer_list();
  }

  initializeForm()
  {
    this.checkInReportForm = new FormGroup({
      checkinDate  : new FormControl(moment().toDate()),
      customerName : new FormControl()
    })
  }

  
  applyFilter(event: string) {
    this.page = 0;
    this.searchText = event.trim().toLowerCase();
      if(this.searchText){
        this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate, null, this.searchText);
      } else {
        this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate);
      }
  }

  onStartDateChange(event:any) {
    this.searchText = '';
    this.page = 0;
    if(event.value){
      this.startDate = event.value;
      this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate);
    }
  }

  onEndDateChange(event:any) {
    this.searchText = '';
    this.page = 0;
    if(event.value){
      this.endDate = event.value;
      this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate);
    }
  }

  getTimeCardReportByPage(page, limit, fromDate = null, toDate = null, scroll = null, search = null) {
    // console.log('GetAdminTimeClockByPage: ', page, limit, fromDate, toDate, scroll, search);
    if (!scroll && !search) {
      this.timeCardService.common.progressLoader = true;
    }
    else if (scroll) {
      this.timeCardService.common.infiniteLoader = true;
    }
    try {
      const searchVal = search ? search : 'all';
        let formatedFromDate = moment(fromDate).format('YYYY-MM-DD');
        let formatedToDate = moment(toDate).format('YYYY-MM-DD');
        this.timeCardService.getTimeCardReportByPage(this.sessionService.get("orgCode"), formatedFromDate, formatedToDate, page, limit, searchVal, (response) => {
        this.timeCardService.common.infiniteLoader = false;
        this.timeCardService.common.progressLoader = false;
        // //console.log('getAllTeamsByPage: ', response)
        if (response.responsecode == 200) {
          if (scroll) {
            // let arrayData = this.dataSource.data;
            let arrayData = this.timeCardReport_List;
            response.data.forEach(element => {
              arrayData.push(element)
            });
            
            // setTimeout(() => {
              this.dataSource = new MatTableDataSource(arrayData);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.timeCardService.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.timeCardReport_List = arrayData;
              //  console.log('timeCardReport_List: ', this.timeCardReport_List.length);
            // }, 100);
  
          } else {
            //////console.log(response.data);
            setTimeout(() => {
              this.timeCardReport_List = response.data;
              this.dataSource = new MatTableDataSource(response.data);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.timeCardService.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              // console.log('timeCardReport_List: ', this.timeCardReport_List.length);
            }, 1000)
  
          }
  
        } else {
          if (!scroll) {
            this.timeCardReport_List = [];
            this.dataSource = new MatTableDataSource([]) ;
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.timeCardService.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
          }
        }
      })
    } catch (error) {
      this.timeCardService.common.infiniteLoader = false;
      this.timeCardService.common.progressLoader = false;
      //console.log(error)
    }
  
  }


  // getTimeCardReports(isScroll?)
  // {
  //   this.isLoading = true;
  //   this.timeCardService.getTimeCardReport(
  //     this.sessionService.get("orgCode"),
  //     moment(this.checkInReportForm.get("checkinDate").value).format('YYYY-MM-DD'),
  //     this.checkInReportForm.get("customerName").value ? this.checkInReportForm.get("customerName").value : 'All',
  //     this.page,
  //     this.limit
  //   ).subscribe((res:any)=>{
  //     this.isLoading = false;
  //     if (res.responsecode === 200) {
  //       // console.log("invoice report", res);
 
  //        if(isScroll)
  //          {
  //            this.dataSource = new MatTableDataSource([...this.dataSource.data, ...res.data]);
  //          }
  //          else{
  //            this.dataSource = new MatTableDataSource(res.data);
  //          }
  //        Apply the sorting accessor for case-insensitive sorting
  //        this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
  //        this.dataSource.sort = this.sort;
  //      }
  //      else{
  //        if(!isScroll)
  //          this.dataSource = new MatTableDataSource();
  //      }
  //   })
  // }


  inputSearch() {
    this.checkInReportForm.controls.customerName.valueChanges.pipe(debounceTime(700), takeUntil(this.observableDestroyer)).subscribe((value: string) => {
      if (value || this.isValuePersist) {
        this.isValuePersist = value ;
         this.getTimeCardReportDataList();

      }
    })
  }


  getTimeCardReportDataList(){
    this.page = 0 ;
    this.dataSource = new MatTableDataSource();
    // this.getTimeCardReports();
  }

  onChangeDate(event: any) {
    this.getTimeCardReportDataList();
  }

  export() {
    this.exportAsService.save(this.exportAsConfig, 'TimeCardReport').pipe(takeUntil(this.observableDestroyer)).subscribe(() => {
     // console.log("export data" , res)
    });
  }

  onScroll(){
   this.page = this.page + 1;
   this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate, 'scroll', this.searchText);
  }

  printInvoiceReport(): void {
    const printContents = document.getElementById('time_card_report_tableData_forPrint').innerHTML;

    this.iframe.nativeElement.name = "frame1";
    this.iframe.nativeElement.style.position = "absolute";
    this.iframe.nativeElement.style.top = "-1000000px";
    document.body.appendChild(this.iframe.nativeElement);
    let frameDoc = this.iframe.nativeElement.contentWindow ? this.iframe.nativeElement.contentWindow : this.iframe.nativeElement.contentDocument.document ? this.iframe.nativeElement.contentDocument.document : this.iframe.nativeElement.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head><title>Time Card Report</title>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(printContents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(this.iframe.nativeElement);
    }, 1000);

  }

  ngOnDestroy(): void {
    this.observableDestroyer.next('');
    this.observableDestroyer.complete();
  }

  
  get_all_customer_list()
  {
   this.commonAPIService.getAllCustomerList(this.sessionService.get("orgCode")).subscribe((res:any)=>{
   // console.log("getAllCustomerList", res);
    if(res.responsecode === 200)
    {
      this.all_customer_list = res.data ;
      this.all_customer_list_filtered = res.data;
    }
   })
  }

  searchCustomer(value: any) {
    if(value.trim())
    {
      this.all_customer_list_filtered = this.all_customer_list.filter(ele => ele.customerName.toLowerCase().includes(value.trim().toLowerCase()));
    }
    else{
      this.all_customer_list_filtered = [...this.all_customer_list];
    }
   
  // console.log("this.all_customer_list_filtered ", this.all_customer_list_filtered )
  }

  on_customer_name_inputs(custName:string)
  {
    this.checkInReportForm.controls.customerName.setValue(custName) ;
    // console.log( this.invoiceReportInputForm.controls.customerName.setValue(custName) )
  }


  getTableData()
  {
    this.commonAPIService.get_calendar_setting_by_setting_name(this.sessionService.get('orgCode')).subscribe((res:any) => {
      this.startDate = res?.setting_start_of_week ?  moment().day(res?.setting_start_of_week).toDate() : moment().toDate();
      this.page = 0;
    this.getTimeCardReportByPage(this.page, this.limit, this.startDate, this.endDate);
   });
  }

}
