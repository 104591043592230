 
  <div [ngClass]="{'p-4': (EditJobmodalData?.isOpenFrom === 'WeeklyCalendar_View' || AddJobmodalData?.isOpenFrom === 'WeeklyCalendar_View'), 'w-100': isShortcutModalOpen}">
    <div class="col-md-12 mb-3 logo">
      <div class="row">
        <div class="col-6 text-start">
          <div class="row justify-content-start">
            <div *ngIf="!is_RequestedJob" class="float-start header-name" style="display: inline-block;">
              <span>{{'Jobs.labels.Jobs' | translate }}</span>
              <span class="headerNoteTitleBtn">
                <span class="ps-4 cursor-pointer" (click)="jobEditData_fromAPI?.noteList ? _commonSVC.addUpdateNotes(jobEditData_fromAPI.noteList) : _commonSVC.addUpdateNotes()">
                  <span class="notebtnContainer" *ngIf="newJobCreation_Form.controls.appointmentsCode.value && jobEditData_fromAPI?.noteList?.length > 0"><img class="p-1 notebtnIc" src="assets/images/note-icon-active.svg" /><span class="ps-1 noteBtnTxtActive">Note</span></span>
                  <span class="notebtnContainer" *ngIf="!newJobCreation_Form.controls.appointmentsCode.value || jobEditData_fromAPI?.noteList?.length === 0"><img class="p-1 notebtnIc" src="assets/images/note-icon.svg" /><span class="ps-1 noteBtnTxtInactive">Note</span></span>
                </span>
              </span>
            </div>
            
          </div>
        </div> 
  
        <div class="col-6 text-end">
            <!-- cancel button -->
          <ng-template [ngIf]="!isShortcutModalOpen">
            <ng-template [ngIf]="!isShortcutModalOpen && !AddJobmodalData && !EditJobmodalData" [ngIfElse]="clickCancel">
              <button class="me-2 w-auto" type="button" mat-button [routerLink]="routeLink">Cancel</button>
            </ng-template>
            <ng-template #clickCancel>
              <button class="me-2 w-auto" type="button" mat-button (click)="closeBsPopUp()">Cancel</button>
            </ng-template>
          </ng-template>
            
            <!-- save and update button -->
            <ng-template [ngIf]="is_RequestedJob" [ngIfElse]="regularButtons">
              <button class="w-auto" type="button" mat-raised-button color="primary" (click)="acceptRequestedJob()">Accept Job</button>
            </ng-template>
        
            <ng-template #regularButtons>
              <!-- <button class="w-auto me-2" type="button" mat-raised-button color="primary" (click)="_commonSVC.addUpdateNotes()">Add Note</button> -->
            <!-- <ng-template [ngIf]="newJobCreation_Form.controls.appointmentsCode.value && newJobCreation_Form.controls.appointmentsProceduresCode.value" [ngIfElse]="saveJob"> -->
              <ng-template [ngIf]="newJobCreation_Form.controls.appointmentsCode.value" [ngIfElse]="saveJob">
              <button type="button" class="w-auto me-2" mat-raised-button color="primary" [disabled]="allow_BtnDisable" (click)="saveEditedJob()">Update Job</button>
              <button *ngIf="isDetectedFormChanges" class="w-auto" type="button" mat-raised-button color="primary" [disabled]="allow_BtnDisable" (click)="updateAndNotifyJob()">Update & Notify</button>
            </ng-template>
  
            <ng-template #saveJob>
              <button class="w-auto" type="button" mat-raised-button color="primary" [disabled]="allow_BtnDisable" (click)="AddUpdateJob()">Save Job</button>
              <button *ngIf="!isJobDateLessThenJobminDate" class="w-auto ms-2" type="button" mat-raised-button color="primary" [disabled]="allow_BtnDisable" (click)="openDistributionPopUp()">Save & Notify</button>
            </ng-template>
  
          </ng-template>
        </div>
      </div>
    </div>
   
  <div class="main-div">
    <form [formGroup]="newJobCreation_Form">
    <div class="card">
        <div class="card-body">
         <div class="row {{disabledFields ? 'mb-4' : 'mb-2'}}">
         <div class="col-3">
       <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Select Customer</mat-label>
            <mat-select formControlName="Customer" (selectionChange)="onCustomerChange()" [disabled]="(disabledFields || disable_whenJobRequested)">
              <mat-option>
          <ngx-mat-select-search  [placeholderLabel]="'Search here'"  [noEntriesFoundLabel]="'Record not found'"
          [formControl]="customerSeachInput"></ngx-mat-select-search>
        </mat-option>
              <mat-option *ngFor="let customer of customerList_Filter"  [value]="customer">
                {{customer.customerName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
           <div class="row">
            <div class=" col-12">
           <span class=" help-block err"
              *ngIf="newJobCreation_Form.controls.Customer.errors?.required && 
              (isForm_Submitted || newJobCreation_Form.controls.Customer.touched)">
             Please select customer
            </span>
          </div>
            <div class="col-12 text-end" *ngIf="!(disabledFields || disable_whenJobRequested)"> <a (click)="openCustomerPopup(CustomerPopUp_TempRef)">Add New</a></div>
            </div> 
        </div>
    
        <div class="col-3">
          <!-- <label class="form-label">
            <span >Job Date</span>
              </label>
              <input type="date" class="form-control" formControlName="Job_Date"> -->
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Job Date</mat-label>
                <input matInput
                 [matDatepicker]="Job_Date"
                  formControlName="Job_Date"                 
                 [matDatepickerFilter]="nonWorkingHourFilter"
                  [disabled]="disabledFields" (dateChange)="dateValueChange($event)">

                  <!-- [min]="JobminDate" min date range commneted for create feture jobs-->
                  <!-- (dateChange)="dateValueChange($event)" -->
                
                 <mat-datepicker-toggle matIconSuffix [for]="Job_Date"></mat-datepicker-toggle>
                <mat-datepicker #Job_Date></mat-datepicker>
              </mat-form-field>
              <span class="help-block err"
              *ngIf="newJobCreation_Form.controls.Job_Date.errors?.required && 
              (isForm_Submitted || newJobCreation_Form.controls.Job_Date.touched)">
             Please select job date
            </span>
  
            <span class="help-block err" 
            *ngIf="newJobCreation_Form.controls.Job_Date.errors?.matDatepickerMin && 
            (isForm_Submitted || newJobCreation_Form.controls.Job_Date.touched)">
              Job date should not be past date
            </span>
  
        </div>
    
        <div class="col-3">
          <!-- <label class="form-label">
            <span>{{ 'main.labels.Job Site' | translate }}</span>
        </label>
          <select class="form-select" formControlName="Job_Site" (change)="onJobSiteChange()">
            <option selected value="">selcet {{ 'main.labels.Job Site' | translate }}</option>
             <option *ngFor="let address of ddl_CustomerAddressList"  [ngValue]="address">
              {{address.location}}</option>
           </select> -->
           <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
            <mat-select formControlName="Job_Site" (selectionChange)="onJobSiteChange()" [disabled]="disabledFields">
              <mat-option *ngFor="let address of ddl_CustomerAddressList"  [value]="address">
                {{address.siteName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
           <div class="row">
            <div class=" col-12">
           <span class="help-block err"
           *ngIf="newJobCreation_Form.controls.Job_Site.errors?.required && 
           (isForm_Submitted || newJobCreation_Form.controls.Job_Site.touched)">
          Please select {{ 'main.labels.Job Site' | translate }}
         </span>
         </div>
         <div class="col-12 text-end" *ngIf="!disabledFields"> <a (click)="openJobSitePopup(JobSitePopUp_TempRef)">Add New</a></div>
         </div>
        </div>
    
        <div class="col-3">
          <!-- <label class="form-label">
            <span>Job Id</span>
              </label>
              <input type="text" class="form-control" formControlName="Job_ID"> -->
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Job Id</mat-label>
                <input matInput formControlName="Job_ID"  [readOnly]="disabledFields">
              </mat-form-field>
        </div>
  
      </div>
  
  
    <div class="row">
  
       <div class="col-3">
          <!-- <label class="form-label">
            <span>Site Address</span>
        </label>
              <textarea  class="form-control" formControlName="Site_Address" readonly></textarea> -->
              <mat-form-field  appearance="outline" floatLabel="always">
                <mat-label>Site Address</mat-label>
                <input matInput formControlName="Site_Address" readonly>
              </mat-form-field>
        </div>
   
        <div class="col-3">
          <!-- <label class="form-label">
            <span>Site Contact</span>
              </label>
              <input type="text" class="form-control" formControlName="Site_Contact"> -->
                <mat-form-field   appearance="outline" floatLabel="always">
                  <mat-label>Job Contact</mat-label>
                  <input matInput formControlName="Site_Contact" >
                </mat-form-field>
        </div>
    
       <div class="col-3">
          <!-- <label class="form-label">
            <span >Site Contact Ph</span>
              </label>
              <input type="text" class="form-control" formControlName="Site_Contact_Ph" maxlength="10"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Job Contact Ph</mat-label>
                <input matInput formControlName="Site_Contact_Ph" appPhoneMask maxlength="12" [readOnly]="disabledFields">
              </mat-form-field>
              <!-- <span class="help-block err"
              *ngIf="newJobCreation_Form.controls.Site_Contact_Ph.errors?.required && 
              (isForm_Submitted || newJobCreation_Form.controls.Site_Contact_Ph.touched)">
             Field is required
            </span> -->
            <span class="help-block err"
              *ngIf="newJobCreation_Form.controls.Site_Contact_Ph.errors?.pattern && 
              (isForm_Submitted || newJobCreation_Form.controls.Site_Contact_Ph.touched)">
              Please, Enter 10 digit Mobile Number.
            </span>
        </div>
  
        <div class="col-3">
          <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>Order Date</mat-label>
               <input matInput [max]="ordermaxDate" [matDatepicker]="Order_Date" formControlName="Order_Date" [disabled]="disabledFields">
                <mat-datepicker-toggle matIconSuffix [for]="Order_Date"></mat-datepicker-toggle>
               <mat-datepicker #Order_Date></mat-datepicker>
             </mat-form-field>
  
             <span class="help-block err" 
             *ngIf="newJobCreation_Form.controls.Order_Date.errors?.matDatepickerMax && 
             (isForm_Submitted || newJobCreation_Form.controls.Order_Date.touched)">
               Order date should not be future date
             </span>
            
       </div>
  
    </div>
   
   </div>
    </div>
     
    <div class="card">
      <div class="card-body">
        <ng-container formArrayName="JobData_Array">
    <div class="row  " *ngFor="let phone of getJobData_Array.controls; let i=index ; let last = last;" [formGroupName] = "i">
      
      <!-- <div class="col-md-10"> -->
        <div class="row g-1">
      <div class="col-3 ps-3">
      <!-- <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Shift</mat-label>
          <input matTooltip="{{getJobData_Array.controls[i].get('Shift_ViewOnly').value}}" matInput placeholder="select shift" formControlName="Shift_ViewOnly" (click)="openShiftPopUp(i) " readonly>
       </mat-form-field> -->

       <app-time-input-select
       [startTime]="getJobData_Array.controls[i].get('startTime')"
       [endTime]="getJobData_Array.controls[i].get('endTime')"
       ></app-time-input-select>

        <span class="help-block err"
      *ngIf="(getJobData_Array.controls[i].get('startTime').errors?.required || getJobData_Array.controls[i].get('endTime').errors?.required  )  && 
      (isForm_Submitted || ( getJobData_Array.controls[i].get('startTime').touched || getJobData_Array.controls[i].get('startTime').touched ))">
      Shift Start time and End time is required
    </span>

    <span class="help-block err"
    *ngIf="(getJobData_Array.controls[i].get('startTime').errors?.pattern || getJobData_Array.controls[i].get('endTime').errors?.pattern  ) 
     && (is_formSubmitted || ( getJobData_Array.controls[i].get('startTime').touched || getJobData_Array.controls[i].get('startTime').touched ))">Expected format: H:mm AM|PM
  </span>

    <!-- <div class="col-12 text-end"> <a (click)="openAddShiftPopup(i)">Add New</a></div> -->

    <!-- <div class="col-12 text-end"> <a (click)="openBreakPopUp(i , breakPopUp)">Add Break</a></div> -->

    <div class="col-12 text-end"> 
      <a class="d-flex justify-content-end" style="text-decoration: none;" (click)="toggleBreak(i)">
        {{ getJobData_Array.controls[i].get('breakForm').value?.length > 0 ? 'Add/Update Break' : 'Add Break'}}
      <mat-icon class="mat-arrow-right-down">{{break_arr_toggle[i] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
    </a>
    </div>

    <!-- <div *ngIf="getJobData_Array.controls[i].get('breakForm').value && getJobData_Array.controls[i].get('breakForm').value?.length > 0 && !break_arr_toggle[i]">
      <div class="row" style="font-size: 12px;" *ngFor="let break of getJobData_Array.controls[i].get('breakForm').value">
        <div class="col text-capitalize"> {{break.breakType}} </div>
        <div class="col"> {{break.duration}}&nbsp;min &nbsp; {{break.paidStatus ? '$' : ''}}</div>
      </div>
    </div> -->

    <!-- <ng-template #breakPopUp>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <h6 class="dialog-header pt-2">Add Break</h6>
        </div>
        <div class="col-md-6">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" mat-dialog-close></button>
          </div>
        </div>
      </div>

      <div class="card-body-content">
        <app-break-form 
        [formData]="getJobData_Array.controls[i].get('breakForm').value"
        (getFormData)="captureFormData($event,i)"
        ></app-break-form>
      </div>
      
    </ng-template> -->

       </div>
    
        <div class="col-2">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Job Req</mat-label>
            <!-- [disabled]="disabledFields" -->
            <mat-select formControlName="Job_Req" (selectionChange)="onChangeJobReq($event,i)">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search'"  [noEntriesFoundLabel]="'Record not found'"
                [formControl]="Job_ReqSeachInput"></ngx-mat-select-search>
              </mat-option>
              <mat-option class="mt-op" *ngFor="let procedure of procedureList_filter" [value]="procedure">
                {{procedure.procedureName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="help-block err"
          *ngIf="getJobData_Array.controls[i].get('Job_Req').errors?.required && 
          (isForm_Submitted || getJobData_Array.controls[i].get('Job_Req').touched)">
         Please select Job req
        </span>
        </div>
   
        <div class="col-1">
           <mat-form-field   appearance="outline" floatLabel="always">
              <mat-label>Job Qty</mat-label>
              <input matInput formControlName="Job_Qty">
            </mat-form-field>
  
            <span class="help-block err"
            *ngIf="getJobData_Array.controls[i].get('Job_Qty').errors?.required && 
            (isForm_Submitted || getJobData_Array.controls[i].get('Job_Qty').touched)">
           Please enter Qty
          </span>
          <span class="help-block err"
          *ngIf="getJobData_Array.controls[i].get('Job_Qty').errors?.pattern && 
          (isForm_Submitted || getJobData_Array.controls[i].get('Job_Qty').touched)">
         Please enter valid number
        </span>
        </div>
    
        <div class="col-1 icon">
          <mat-form-field   appearance="outline" floatLabel="always">
             <!-- <mat-label>Billing Rate/Hr</mat-label> -->
             <mat-label>Rate/Hr</mat-label>
             <mat-icon matPrefix> attach_money</mat-icon>
             <input matInput formControlName="ratePerHours" maxlength="10" (blur)="rateHrOnBlur(i)">
           </mat-form-field>
  
           <span class="help-block err"
           *ngIf="getJobData_Array.controls[i].get('ratePerHours').errors?.required && 
           (isForm_Submitted || getJobData_Array.controls[i].get('ratePerHours').touched)">
          Required field
         </span>
         <span class="help-block err"
         *ngIf="getJobData_Array.controls[i].get('ratePerHours').errors?.pattern && 
         (isForm_Submitted || getJobData_Array.controls[i].get('ratePerHours').touched)">
         Should be valid number
       </span>
       </div>
  
        <div class="col-2">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Uniform</mat-label>
            <mat-select formControlName="Uniform">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search here'"  [noEntriesFoundLabel]="'Record not found'"
                [formControl]="uniformSeachInput"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="getJobData_Array.controls[i].get('Uniform').value" class="resetSelectedOption" value="">
                Reset
              </mat-option>
              <mat-option class="mt-op" *ngFor="let uniform of uniformList_Filter" [value]="uniform">
                {{uniform.uniformName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
       <div class="col-12 text-end"> <a (click)="openUniformPopup(addUniform_TempRef , i)">Add New</a></div>
        </div>
    
        <div class="col-2">
         <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Equipment</mat-label>
            <mat-select formControlName="Equipment">
              <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search here'"  [noEntriesFoundLabel]="'Record not found'"
                [formControl]="equipmentSeachInput"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="getJobData_Array.controls[i].get('Equipment').value" class="resetSelectedOption" value="">
                Reset
              </mat-option>
              <mat-option class="mt-op" *ngFor="let equipment of equipmentList_Filter" [value]="equipment">
                {{equipment.equipmentName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        
          <div class="col-12 text-end"> <a (click)="openEquipmentPopup(addEquipment_TempRef , i)">Add New</a></div>
        </div>
  
       
  
       <!-- </div> -->
       
       <!-- <div class="col-md-2 text-center"> -->
        <div class="col-1 add-more">
          <div class="w-50">
            <!-- [disabled]="disabledFields" -->
          <button mat-icon-button color="warn" *ngIf="getJobData_Array.controls.length > 1" (click)="deleteFromJobData_Array(i)">
            <mat-icon>delete</mat-icon>
          </button>
          </div>
  
          <div class="w-50">
            <!-- [disabled]="disabledFields" -->
           <button mat-icon-button color="primary" *ngIf="last" (click)="addInJobData_Array()" >
            <mat-icon>add_circle</mat-icon>
          </button>
          </div>
     
        </div>
        
      </div>

      <ng-template [ngIf]="break_arr_toggle[i]">
      <div class="row mt-1">
        <div class="col-5">
          <div class="card-body-content">
            <app-break-form 
            [formData]="getJobData_Array.controls[i].get('breakForm').value"
            (getFormData)="captureFormData($event,i)"
            (noBreaks)="handleNoBreak($event,i)"
            ></app-break-form>
          </div>
        </div>
      </div>
      </ng-template>

      <!-- </div> -->
  
      <hr *ngIf="!last">
  
    </div>
   </ng-container>
  </div>
  </div>
    
  <div class="card">
        <div class="card-body">
       <div class="row">
         
            <div class="col">
    
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Site Supervisor</mat-label>
                <mat-select formControlName="Site_Supervisor">
                  <!-- <ng-container *ngFor="let admin of allAdminList_Filter"> -->
                    <mat-option>
                      <ngx-mat-select-search [placeholderLabel]="'Search'"  [noEntriesFoundLabel]="'Record not found'"
                      [formControl]="siteSupervisorSeachInput"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="newJobCreation_Form.controls.Site_Supervisor.value" class="resetSelectedOption" value="">
                      Reset
                    </mat-option>
                  <mat-option *ngFor="let admin of allAdminList_Filter"
                   [class.isCustomer]="admin.isCustomer" [value]="admin">
                    {{admin.workerName}}
                  </mat-option>
                <!-- </ng-container> -->
                </mat-select>
              </mat-form-field>
   
            </div>
    
            <div class="col">
          <mat-form-field  appearance="outline" floatLabel="always">
              <mat-label>Special Instructions</mat-label>
              <textarea matInput formControlName="Special_Instruction" maxlength="1000" class="textareaInput"></textarea>
            </mat-form-field>
    
            </div>
     
       </div>
      </div>
    </div>
  </form>
    </div>
    </div>
  
     <!-- Unifrom popup  starts -->
    <ng-template #addUniform_TempRef>
     <div class="row  ">
        <div class="col-md-6 align-self-center">
          <h6 class="dialog-header pt-2">Add new uniform requirement
          </h6>
        </div>
        <div class="col-md-6">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" mat-dialog-close></button>
          </div>
        </div>
      </div> 
  
      <div class="row">
        <form>
         <!-- <div class="col-12">
          <label class="form-label"><span>Uniform Name</span></label>
            <input type="text" class="form-control" [(ngModel)]="Uniform_Name" [ngModelOptions]="{standalone: true}">
         </div> -->
         <mat-form-field   appearance="outline" floatLabel="always">
          <mat-label>Uniform Description</mat-label>
          <input matInput [(ngModel)]="Uniform_Name" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
       </form>
      </div>
  
     <div class=" footer mt-4 d-flex offset-md-8 justify-content-end">
        <button class="me-4" mat-button mat-dialog-close color="primary">
          Close
        </button>
  
        <button class="me-5"  mat-raised-button color="primary" (click)="saveUniform()">
          Add 
         </button>
  
     </div>
    </ng-template>
    <!-- // Unifrom popup  ends -->
  
  
      <!-- Equipment popup  starts -->
      <ng-template #addEquipment_TempRef>
  
        <div class="row  ">
          <div class="col-md-6 align-self-center">
            <h6 class="dialog-header pt-2">Add new Equipment</h6>
          </div>
          <div class="col-md-6">
            <div class="row justify-content-end">
              <button class="btn-close cursor-pointer" mat-dialog-close></button>
            </div>
          </div>
        </div> 
  
       <div class="row">
          <form>
           <div class="col-12">
            <!-- <label class="form-label"><span>Equipment Name</span></label>
              <input type="text" class="form-control" [(ngModel)]="Equipment_Name" [ngModelOptions]="{standalone: true}"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Equipment Name</mat-label>
                <input matInput [(ngModel)]="Equipment_Name" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
           </div>
         </form>
        </div>
        <div class=" footer mt-4 d-flex offset-md-8 justify-content-end">
          <button class="me-4" mat-button mat-dialog-close color="primary">
            Close
          </button>
    
          <button class="me-5"  mat-raised-button color="primary" (click)="saveEquipment()">
            Add 
           </button>
      </div>
  
      </ng-template>
      <!-- // Equipment popup  ends -->
  
  
    <!-- JobSite popup  starts -->
    <ng-template #JobSitePopUp_TempRef>
      <div class="row  ">
        <div class="col-md-6 align-self-center">
          <h6 class="dialog-header pt-2">Add New {{ 'main.labels.Job Site' | translate }}</h6>
        </div>
        <div class="col-md-6">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" mat-dialog-close></button>
          </div>
        </div>
      </div>  
      <div class="row">
        <form [formGroup]="addNewJobSite_Form" class="main-div">
  
          <div class="col-12 mb-3">
             <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Site Name</mat-label>
                <input matInput formControlName="JobSite_Name" >
              </mat-form-field>
              <span class="help-block err"
              *ngIf="addNewJobSite_Form.controls.JobSite_Name.errors?.required && 
              (isJobSiteForm_Submitted || addNewJobSite_Form.controls.JobSite_Name.touched)">
             Please enter site name
            </span>
            </div>
  
         <div class="col-12 mb-3">
            <mat-form-field   appearance="outline" floatLabel="always">
              <mat-label>{{ 'main.labels.Job Site' | translate }} Address</mat-label>
              <input matInput formControlName="JobSite_Address"
              ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
             (onAddressChange)="handleAddressChange($event)">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addNewJobSite_Form.controls.JobSite_Address.errors?.required && 
            (isJobSiteForm_Submitted || addNewJobSite_Form.controls.JobSite_Address.touched)">
           Please enter a address
          </span>
          </div>
  
          <div class="row">
         <div class="col-6">
          <!-- <label class="form-label"><span> Site Contact</span></label>
            <input type="text" class="form-control" formControlName="SiteContact" > -->
            <mat-form-field   appearance="outline" floatLabel="always">
              <mat-label> Site Contact</mat-label>
              <input matInput formControlName="SiteContact">
            </mat-form-field>
         </div>
  
         <div class="col-6">
          <!-- <label class="form-label"><span> Site Contact Phone</span></label>
            <input type="text" class="form-control" formControlName="SiteContactPhone" maxlength="10"> -->
            <mat-form-field   appearance="outline" floatLabel="always">
              <mat-label> Site Contact Phone</mat-label>
              <input matInput formControlName="SiteContactPhone" appPhoneMask   maxlength="12">
            </mat-form-field>
            <span class="help-block err"
            *ngIf="addNewJobSite_Form.controls.SiteContactPhone.errors?.pattern && 
            (isJobSiteForm_Submitted || addNewJobSite_Form.controls.SiteContactPhone.touched)">
           Please enter a valid number
          </span>
         </div>
        </div>
       </form>
      </div>
      
      <div class=" footer mt-4 d-flex offset-md-8 justify-content-end">
        <button class="me-4" mat-button mat-dialog-close color="primary">
          Close
        </button>
  
        <button class="me-5"  mat-raised-button color="primary" (click)="saveJobSite()">
          Add 
         </button>
         </div>
       
    </ng-template>
    <!-- // JobSite popup  ends -->
  
     
    <!-- Customer popup  starts -->
    <ng-template #CustomerPopUp_TempRef>
      <div class="row  ">
        <div class="col-md-6 align-self-center">
          <h6 class="dialog-header pt-2">Add New Customer</h6>
        </div>
        <div class="col-md-6">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" mat-dialog-close></button>
          </div>
        </div>
      </div>  
    
         <form [formGroup]="newCustomer_Form" class="main-div">
             <div class="row  mb-3">
          <div class="col-6">
            <!-- <label class="form-label"><span>Customer Name</span></label>
              <input type="text" class="form-control" formControlName="CustomerName"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Customer Name</mat-label>
                <input matInput formControlName="CustomerName" >
              </mat-form-field>
              <span class="help-block err"
              *ngIf="newCustomer_Form.controls.CustomerName.errors?.required && 
              (isCustomerForm_Submitted || newCustomer_Form.controls.CustomerName.touched)">
              Customer name is required
            </span>
           </div>
  
           <div class="col-6">
            <!-- <label class="form-label"><span>Email</span></label>
              <input type="text" class="form-control" formControlName="Email"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Email</mat-label>
                <input matInput formControlName="Email" >
              </mat-form-field>
              <span class="help-block err"
              *ngIf="newCustomer_Form.controls.Email.errors?.required && 
              (isCustomerForm_Submitted || newCustomer_Form.controls.Email.touched)">
              Email ID is required
            </span>
            <span class="help-block err"
            *ngIf="newCustomer_Form.controls.Email.errors?.email && 
            (isCustomerForm_Submitted || newCustomer_Form.controls.Email.touched)">
            Please enter a valid email
          </span>
           </div>
          
            </div>
  
         <div class="row  mb-3">
           <div class="col-6">
            <!-- <label class="form-label"><span>Mobile No.</span></label>
              <input type="text" class="form-control" formControlName="Mobile" maxlength="10"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Mobile No.</mat-label>
                <input matInput formControlName="Mobile" appPhoneMask   maxlength="12">
              </mat-form-field>
              <!-- <span class="help-block err"
              *ngIf="newCustomer_Form.controls.Mobile.errors?.minlength && 
              (isCustomerForm_Submitted || newCustomer_Form.controls.Mobile.touched)">
              Must contain 10 digits
            </span> -->
            <span class="help-block err"
            *ngIf="newCustomer_Form.controls.Mobile.errors?.pattern && 
            (isCustomerForm_Submitted || newCustomer_Form.controls.Mobile.touched)">
            Please enter valid number
          </span>
           </div>
   
           <div class="col-6">
            <!-- <label class="form-label"><span>Fax</span></label>
              <input type="text" class="form-control" formControlName="Fax"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Fax</mat-label>
                <input matInput formControlName="Fax">
              </mat-form-field>
           </div>
            </div>
  
            <div class="row  mb-3">
           <div class="col-6">
            <!-- <label class="form-label"><span>Primary Contact</span></label>
              <input type="text" class="form-control" formControlName="PrimaryContact"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Primary Contact</mat-label>
                <input matInput formControlName="PrimaryContact">
              </mat-form-field>
           </div>
  
           <div class="col-6">
            <!-- <label class="form-label"><span>Primary Contact Mobile No.</span></label>
              <input type="text" class="form-control" formControlName="PrimaryContact_Mobile" maxlength="10"> -->
              <mat-form-field   appearance="outline" floatLabel="always">
                <mat-label>Primary Contact Mobile No.</mat-label>
                <input matInput formControlName="PrimaryContact_Mobile" appPhoneMask   maxlength="12">
              </mat-form-field>
              <!-- <span class="help-block err"
              *ngIf="newCustomer_Form.controls.PrimaryContact_Mobile.errors?.minlength && 
              (isCustomerForm_Submitted || newCustomer_Form.controls.PrimaryContact_Mobile.touched)">
              Must contain 10 digits
            </span> -->
            <span class="help-block err"
            *ngIf="newCustomer_Form.controls.PrimaryContact_Mobile.errors?.pattern && 
            (isCustomerForm_Submitted || newCustomer_Form.controls.PrimaryContact_Mobile.touched)">
            Please enter valid number
          </span>
           </div>
          </div>
       </form>
      
      <div class=" footer mt-4 d-flex offset-md-8 justify-content-end">
        <button class="me-4" mat-button mat-dialog-close color="primary">
          Close
        </button>
  
        <button class="me-5"  mat-raised-button color="primary" (click)="saveCustomer()">
          Add 
         </button>
         </div>
       
      
    </ng-template>
    <!-- // customer popup  ends -->
  
  
  
    <ng-template #shiftSelect_TemplateRef>
    
      <div class="row">
        <div class="col-3 align-self-center">
          <h6 class="dialog-header pt-2">Select Shift</h6>
        </div>
  
        <div class="col-6">
          <div class="d-flex flex-row mb-3 border-bottom" *ngIf="shiftList.length > 0">
            <mat-icon class="cursor-pointer search-icon mt-2">search</mat-icon>
             <input autocomplete="off" type="text" placeholder="Search by shift"
              class="searchfilter form-control" name="search-name" [formControl]="shiftSeachInput">            
          </div> 
        </div>
  
        <div class="col-2">
          <div class="row justify-content-end">
            <button type="button" mat-raised-button color="primary" class="w-auto" (click)="openAddShiftPopUp()">Add</button>
          </div>
        </div>
  
        <div class="col-1">
          <div class="row justify-content-end">
            <button class="btn-close cursor-pointer" mat-dialog-close></button>
          </div>
        </div>
      </div> 
  
     <div class="row select-shift-div">
      <div class="select-shift-card-height">
      <ng-template [ngIf]="shiftList.length > 0" [ngIfElse]="noShiftsFound">
        <ng-container *ngFor="let shift of shiftList_Filter">
      <mat-card class="shift-select-card mt-3 mb-3" [class.selectedShift]="shift?.shiftMasterCode === selectedShift?.shiftMasterCode">
        <div class="row">
          <div class="col-3">
            <small>Start Time </small><br>
            <p> {{shift.startTime}}</p>
          </div>
          <div class="col-3">
            <small>End Time </small><br>
            <p> {{shift.endTime}}</p>
          </div>
          <div class="col-6 text-end">
            <button type="button" mat-raised-button color="primary" (click)="updateShift(shift)" class="w-auto me-2">Edit Shift</button>
            <button type="button" mat-raised-button color="primary" (click)="selectShift_btn(shift)">Select</button>
            <!-- <button type="button" [disabled]="shift?.shiftMasterCode === selectedShift?.shiftMasterCode" mat-raised-button color="primary" (click)="selectShift_btn(shift)">Select</button> -->
          </div>
          <hr>
  
          <div class="col-12">
  
            <ng-template [ngIf]="shift.shiftBreakResponseList.length > 0" [ngIfElse]="noBreakFound">
              <table>
                <thead>
                  <tr>
                    <th><small>Break Type</small></th>
                    <th><small>Paid Status</small></th>
                    <th><small>Duration</small></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let break of shift.shiftBreakResponseList">
                    <th class="text-capitalize"><span>{{break.breakType}}</span></th>
                    <th class="text-capitalize"><span>{{break.paidStatus}}</span></th>
                    <th><span>{{break.durationMin}}&nbsp;min</span></th>
                  </tr>
                  </tbody>
              </table>
            </ng-template>
  
            <ng-template #noBreakFound>
                <span class="text-danger">No break added</span>
              </ng-template>
            </div>
  
        </div>
          </mat-card>
        </ng-container>
        </ng-template>
  
        <ng-template #noShiftsFound>
         <div class="col-12 mt-5 text-center">
         <span class="text-danger">No Shifts Found</span>
         </div>
        </ng-template>
     </div>
      </div>
    </ng-template>
  
  
    <ng-template #UnAssignStaffPopUp>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <h6 class="dialog-header pt-2">UnAssign Job</h6>
          </div>
          <div class="col-md-6">
            <div class="row justify-content-end">
              <button class="btn-close cursor-pointer" #btn_closePopUp mat-dialog-close></button>
            </div>
          </div>
        </div>
      
        <div class="row staff-list">
          <ul class="{{filtered_StaffList.length > 4 ? 'poupup-content' : ''}}"
            *ngIf="filtered_StaffList.length > 0">
            <li class="mt-2" *ngFor="let element of filtered_StaffList">
              <div class="row">
                <div class="col-md-1 text-center align-self-center ">
                  <mat-checkbox [(ngModel)]="element.isChecked"> </mat-checkbox>
                </div>
                <div class="col-md-11 font-card">
                  <mat-card class="example-card">
                    <mat-card-content>
                      <div class="row">
                        <div class="col avatarBox">
                          <img class="staff-image me-2" [alt]="element.name"
                            [src]="img_serverPath + element.workerLogo"
                            onerror="this.src='assets/common_images/profile.jpg';" />
                        </div>
                        <div class="col">
                          <p class="name mb-0 ms-2">{{element.name | capitalize}}</p>
                          <small class="email mb-0 ms-2">{{element.email}}</small>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
      
                </div>
      
              </div>
            </li>
          </ul>
   
        </div>
      
        <div class="row mt-3">
          <div class="col-lg-12 text-end">
            <button class="me-3" mat-button mat-dialog-close>Cancel</button>
            <button *ngIf="filtered_StaffList.length > 0" mat-raised-button color="primary"
              (click)="UnAssignJobs(btn_closePopUp)">UnAssign</button>
          </div>
        </div>
  
      </div>
    </ng-template>
  
  
    <ng-template #distribution_sendNotificationPopUp>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <h6 class="dialog-header pt-2">Send Notification</h6>
          </div>
          <div class="col-md-6">
            <div class="row justify-content-end">
              <button class="btn-close cursor-pointer" #btnDistribution_closePopUp mat-dialog-close></button>
            </div>
          </div>
        </div>
      
        <div class="row">
          <mat-form-field appearance="outline" floatLabel="always" class="example-full-width custom-search">
            <mat-label>Distribution</mat-label>
            <mat-select [(ngModel)]="selectedDistribution" multiple>
              <!-- <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'Search Here...'" [noEntriesFoundLabel]="'Record not found'"
                  [formControl]="distributionSeachInput"></ngx-mat-select-search>
              </mat-option> -->
              <mat-option *ngFor="let option of distributionList" [value]="option.distributionCode">
                {{option.distributionName | capitalize}}
              </mat-option>
            </mat-select>
        
          </mat-form-field>
        </div>
      
        <div class="row mt-3">
          <div class="col-lg-12 text-end">
            <button class="me-3" mat-button mat-dialog-close>Cancel</button>
            <button   mat-raised-button color="primary"
              (click)="saveAndNotifyJob()" >
              {{newJobCreation_Form.controls.appointmentsCode.value ? 'Update ' : 'Save '}}& Notify</button>
          </div>
        </div>
  
      </div>
    </ng-template>