import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-reject-appointment',
  templateUrl: './reject-appointment.component.html',
  styleUrls: ['./reject-appointment.component.scss']
})
export class RejectAppointmentComponent implements OnInit {

  rejectForm: FormGroup;
  submitted = true;

  constructor(public dialogRef: MatDialogRef<RejectAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public translate: TranslateService, public common: CommonService) { 
      this.translate.setDefaultLang(this.common.local.get('language'));
    }

  ngOnInit() {
    // console.log('PopupData: ', this.data);
    this.rejectForm = new FormGroup({
      'comments': new FormControl(null, Validators.required)
    })
  }
/* Name:srinivas
    use: close popup. */
  closePopup(){
    this.dialogRef.close();
  }
/* Name:srinivas
    use: submit reject. */
  onSubmit(){
    ////console.log(this.rejectForm);
    if(this.rejectForm.valid){
      this.submitted = true;
      this.dialogRef.close(this.rejectForm.value.comments);
    } else {
      this.submitted = false;
    }
  }
}
