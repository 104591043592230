import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { Subject, interval, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/dashboards/providers-dashboard/notification/notification.service';
import { CommonService } from 'src/app/shared/common.service';

type notification = {
  count: number | string | null,
  data: any[]
}

@Component({
  selector: 'app-short-notification',
  templateUrl: './short-notification.component.html',
  styleUrls: ['./short-notification.component.scss']
})
export class ShortNotificationComponent implements OnInit, OnDestroy {

  private notification_count_sub$ = new Subject<void>();
  private notification_socket_subs$ = new Subject<void>();
  //protected notificationCounts : number | null =  null;

  public notificationsList: notification = {
    count: null,
    data: []
  };

  constructor(protected _commonSVC: CommonService,
    private _sessionSVC: SessionStorageService,
    private _notificationSVC: NotificationService,
    private _router: Router
  ) {


  }
  ngOnDestroy(): void {
    this.notification_socket_subs$.next();
    this.notification_socket_subs$.complete();
  }

  ngOnInit(): void {
    this.getNotificationCounts_and_subscribeTopic();

  }




  getNotificationCounts_and_subscribeTopic() {

    interval(2000).pipe(takeUntil(this.notification_count_sub$)).subscribe((res => {
      console.log("notification count sub start , ", res)
      if (res) {
        if (this._sessionSVC.get('user_type')) {
          if (this._sessionSVC.get('user_type') === 'Customer') {
            if (this._sessionSVC.get('orgCode') && this._sessionSVC.get('logedIn_CustomerCode')) {
              this.unsub_notification_count();
              this.subSocket_ForCustomerNotification();
              this.getAllStaffNotification_ForCustomer();
            }
          }
          else if (this._sessionSVC.get('user_type') === 'Staff') {

            if (this._sessionSVC.get('orgCode') && this._sessionSVC.get('userCode')) {
              this.unsub_notification_count();
              this.subSocket_ForStaffNotification();
              this.getAllStaffNotification_ForStaff();

            }
          }
          else {
            if (this._sessionSVC.get('orgCode')) {
              this.unsub_notification_count();
              this.subSocket_ForOrgNotification(); // subscribibg to notification socket for the organization
              this.getAllStaffNotification_ForOrganization();

            }
          }

        }
      }



    }))


  }





  getAllStaffNotification_ForOrganization() {

    this._notificationSVC.getAllStaffNotification_ForOrganization(this._sessionSVC.get('orgCode'), false, 0, 5).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.notificationsList = res;
      }
      else {
        this.notificationsList = {
          count: null,
          data: []
        };
      }
    })
  }


  getAllStaffNotification_ForStaff() {
    this._notificationSVC.getAllStaffNotification_ForStaff(this._sessionSVC.get('userCode'), this._sessionSVC.get('orgCode'), false, 0, 5).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.notificationsList = res;
      }
      else {
        this.notificationsList = {
          count: null,
          data: []
        };
      }
    })

  }

  getAllStaffNotification_ForCustomer() {

    this._notificationSVC.getAllStaffNotification_ForCustomer(this._sessionSVC.get('logedIn_CustomerCode'), this._sessionSVC.get('orgCode'), false, 0, 5).subscribe((res: any) => {
      if (res.responsecode == 200) {
        this.notificationsList = res;
      }
      else {
        this.notificationsList = {
          count: null,
          data: []
        };
      }
    })

  }

  resetNotificationCount() {
    //this.notificationCounts = null;
  }


  subSocket_ForOrgNotification() {

    console.log("notificationOrganization_" + this._sessionSVC.get('orgCode'))
    this._commonSVC.webSocketSubcribeTopic("notificationOrganization_" + this._sessionSVC.get('orgCode'))
      .pipe(takeUntil(this.notification_socket_subs$))
      .subscribe((res: any) => {
        console.log("socket notification for org", res);
        if (res.body) {
          this.getAllStaffNotification_ForOrganization();
        }
      });
  }


  subSocket_ForStaffNotification() {
    this._commonSVC.webSocketSubcribeTopic("notificationStaff_" + this._sessionSVC.get('userCode'))
      .pipe(takeUntil(this.notification_socket_subs$))
      .subscribe((res: any) => {
        console.log("socket notification for staff", res);
        if (res.body) {
          this.getAllStaffNotification_ForStaff();
        }
      });
  }

  subSocket_ForCustomerNotification() {
    this._commonSVC.webSocketSubcribeTopic("notificationCustomer_" + this._sessionSVC.get('logedIn_CustomerCode'))
      .pipe(takeUntil(this.notification_socket_subs$))
      .subscribe((res: any) => {
        console.log("socket notification for customer", res);
        if (res.body) {
          this.getAllStaffNotification_ForCustomer();
        }
      });
  }



  unsub_notification_count() {
    console.log("unsub to notification countsssss.........")
    this.notification_count_sub$.next();
    this.notification_count_sub$.unsubscribe();

  }


  navigate() {

    if (this._sessionSVC.get('user_type') === 'Customer') {
      this._router.navigate(['/dashboard-customer/notification'])
    }
    else if (this._sessionSVC.get('user_type') === 'Staff') {
      this._router.navigate(['/dashboard-technician/notification'])
    }

    else {
      this._router.navigate(['/dashboard/notification'])
    }

  }

}
