import { Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import * as moment_ from "moment";
import { AnimationEvent } from "@angular/animations";
import {
  bounceAnimation,
  flashAnimation,
  pulseAnimation,
  rubberBandAnimation,
  shakeAnimation,
  swingAnimation,
  tadaAnimation,
  wobbleAnimation,
  jelloAnimation,
  heartBeatAnimation,
  headShakeAnimation,
  bounceInAnimation,
  bounceInDownAnimation,
  bounceInLeftAnimation,
  bounceInRightAnimation,
  bounceInUpAnimation,
  bounceOutAnimation,
  bounceOutDownAnimation,
  bounceOutLeftAnimation,
  bounceOutRightAnimation,
  bounceOutUpAnimation,
  fadeInAnimation,
  fadeInDownAnimation,
  fadeInDownBigAnimation,
  fadeInLeftAnimation,
  fadeInLeftBigAnimation,
  fadeInRightAnimation,
  fadeInRightBigAnimation,
  fadeInUpAnimation,
  fadeInUpBigAnimation,
  fadeOutAnimation,
  fadeOutDownAnimation,
  fadeOutDownBigAnimation,
  fadeOutLeftAnimation,
  fadeOutLeftBigAnimation,
  fadeOutRightAnimation,
  fadeOutRightBigAnimation,
  fadeOutUpAnimation,
  fadeOutUpBigAnimation,
  flipAnimation,
  flipInXAnimation,
  flipInYAnimation,
  flipOutXAnimation,
  flipOutYAnimation,
  lightSpeedInAnimation,
  lightSpeedOutAnimation,
  rotateInAnimation,
  rotateInDownLeftAnimation,
  rotateInDownRightAnimation,
  rotateInUpLeftAnimation,
  rotateInUpRightAnimation,
  rotateOutAnimation,
  rotateOutDownLeftAnimation,
  rotateOutDownRightAnimation,
  rotateOutUpLeftAnimation,
  rotateOutUpRightAnimation,
  slideInDownAnimation,
  slideInLeftAnimation,
  slideInRightAnimation,
  slideInUpAnimation,
  slideOutDownAnimation,
  slideOutLeftAnimation,
  slideOutRightAnimation,
  slideOutUpAnimation,
  zoomInAnimation,
  zoomInDownAnimation,
  zoomInLeftAnimation,
  zoomInRightAnimation,
  zoomInUpAnimation,
  zoomOutAnimation,
  zoomOutDownAnimation,
  zoomOutLeftAnimation,
  zoomOutRightAnimation,
  zoomOutUpAnimation,
  hingeAnimation,
  jackInTheBoxAnimation,
  rollInAnimation,
  rollOutAnimation,
  // other
  collapseAnimation,
  collapseHorizontallyAnimation,
  rotateAnimation,
  bounceInUpOnEnterAnimation,
  hueRotateAnimation,
} from "angular-animations";

import { Subject, Subscription, takeUntil } from "rxjs";
import { DatePipe } from '@angular/common';
import { EChatService } from '../e-chat.service';
//import { LibCommonService } from '../lib.common.service';
import { ChatService } from 'src/app/dashboards/providers-dashboard/chat/chat.service';
import { ChatComponent } from 'src/app/dashboards/providers-dashboard/chat/chat.component';
import { MatDialog } from '@angular/material/dialog';
import { RxStompService } from 'src/app/rx-stomp.service';


const moment = moment_;

@Component({
  selector: 'chat-log',
  templateUrl: './e-chat-log.component.html',
  styleUrls: ['./e-chat-log.component.scss'],
  animations: [
    bounceInUpOnEnterAnimation({ anchor: "enter1" }),
    bounceInUpOnEnterAnimation({ anchor: "enter2", delay: 100 }),
    bounceInUpOnEnterAnimation({ anchor: "enter3", delay: 200 }),
    bounceAnimation(),
    flashAnimation(),
    pulseAnimation({ anchor: "pulse" }),
    rubberBandAnimation(),
    shakeAnimation(),
    swingAnimation(),
    tadaAnimation(),
    wobbleAnimation(),
    jelloAnimation(),
    heartBeatAnimation(),
    headShakeAnimation(),
    bounceInAnimation(),
    bounceInDownAnimation(),
    bounceInLeftAnimation(),
    bounceInRightAnimation(),
    bounceInUpAnimation(),
    bounceOutAnimation(),
    bounceOutDownAnimation(),
    bounceOutLeftAnimation(),
    bounceOutRightAnimation(),
    bounceOutUpAnimation(),
    fadeInAnimation(),
    fadeInDownAnimation(),
    fadeInDownBigAnimation(),
    fadeInLeftAnimation(),
    fadeInLeftBigAnimation(),
    fadeInRightAnimation(),
    fadeInRightBigAnimation(),
    fadeInUpAnimation(),
    fadeInUpBigAnimation(),
    fadeOutAnimation(),
    fadeOutDownAnimation(),
    fadeOutDownBigAnimation(),
    fadeOutLeftAnimation(),
    fadeOutLeftBigAnimation(),
    fadeOutRightAnimation(),
    fadeOutRightBigAnimation(),
    fadeOutUpAnimation(),
    fadeOutUpBigAnimation(),
    flipAnimation(),
    flipInXAnimation(),
    flipInYAnimation(),
    flipOutXAnimation(),
    flipOutYAnimation(),
    lightSpeedInAnimation(),
    lightSpeedOutAnimation(),
    rotateInAnimation(),
    rotateInDownLeftAnimation(),
    rotateInDownRightAnimation(),
    rotateInUpLeftAnimation(),
    rotateInUpRightAnimation(),
    rotateOutAnimation(),
    rotateOutDownLeftAnimation(),
    rotateOutDownRightAnimation(),
    rotateOutUpLeftAnimation(),
    rotateOutUpRightAnimation(),
    slideInDownAnimation(),
    slideInLeftAnimation(),
    slideInRightAnimation(),
    slideInUpAnimation(),
    slideOutDownAnimation(),
    slideOutLeftAnimation(),
    slideOutRightAnimation(),
    slideOutUpAnimation(),
    zoomInAnimation(),
    zoomInDownAnimation(),
    zoomInLeftAnimation(),
    zoomInRightAnimation(),
    zoomInUpAnimation(),
    zoomOutAnimation(),
    zoomOutDownAnimation(),
    zoomOutLeftAnimation(),
    zoomOutRightAnimation(),
    zoomOutUpAnimation(),
    hingeAnimation(),
    jackInTheBoxAnimation(),
    rollInAnimation(),
    rollOutAnimation(),
    // other
    collapseAnimation(),
    collapseHorizontallyAnimation(),
    rotateAnimation(),
    rotateAnimation({ anchor: "rotate90", degrees: 90 }),
    hueRotateAnimation(),
    hueRotateAnimation({ anchor: "hueButton", duration: 20000 }),
  ]
})

export class ChatLogComponent implements OnInit, OnDestroy {

  chatLogData: any[]=[];
  chatLogDataLoaded: boolean = false;
  chatLogDataCode = "";
  page: number = 0;
  limit: number = 10;
  //adminCode = "";
  showChatFeature: boolean = true;
  animation = "rubberBand";
  animating = false;
  animState = false;
  hueBtnState = false;
  //subscription!: Subscription;
  @Input() globalChatData: any;
  ImagePath: string = '';
  popupType: string = '';
  openChat: boolean = false;

  @Input() orgCode: any;
  @Input() adminCode: any;
  @Input() userName: any;
  @Input() userCode: any;
  @Input() apiUri!: string;

  OpenIndividualChat: boolean = false;

  private destroyObs = new Subject<string>();

  constructor(
    // private Dashboardservice: DashboardService,
    private chatService: EChatService,
    // private dialogService: DialogService,
    // public commonService: LibCommonService,
    private service: ChatService,
    public dialog: MatDialog,
    private rxStompService: RxStompService,
  ) {

    this.orgCode = this.chatService._sessionService.get('orgCode');
    this.adminCode = this.chatService._sessionService.get('super_admin_code');
    this.userName = this.chatService._sessionService.get('username');
    this.userCode = this.chatService._sessionService.get('userCode');

    //     this.subscription = this.commonService.onMessage().subscribe(
    //       (message: any) => {
    //         // this.chatLogData = res;
    //         if (message) {
    //           message.isNew=true
    //           var someDate = new Date(message.messageTime);
    //           //console.log(someDate.getTime()) //1587143959831
    //           var utxtimes = moment(someDate.getTime()).tz("America/New_York").format('YYYY-MM-DD HH:mm:ss'); 
    //           var tmsx = moment(utxtimes).utc(true).format("X");  
    //           //console.log(utxtimes) //1587143959831
    //           //console.log(tmsx  ) //1587143959831
    //          /*  const [dateComponents, timeComponents] = message.messageTime.split(' ');
    // //console.log(dateComponents); // 👉️ "09/24/2022"
    // //console.log(timeComponents); // 👉️ "09:25:32"

    // const [year,month, day] = dateComponents.split('-');
    // const [hours, minutes, seconds] = timeComponents.split(':');

    // const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
    // //console.log(date); // 👉️ Sat Sep 24 2022 09:25:32

    // // ✅ Get Unix timestamp
    // const unixTimestamp =Math.floor(date.getTime() / 1000);
    // //console.log(unixTimestamp); // 👉️ 1664000732 */

    //          // message.messageTimeStamp=tmsx
    //           if(this.chatLogData==undefined)
    //           {
    //             this.chatLogData=[];
    //           }
    //           this.chatLogData = [message, ...this.chatLogData]; // RESULT : [34,23, 45, 12, 67]
    //           //this.chatLogData.unshift(message);
    //           this.playSound();
    //           this.StartAnimation();
    //         } else {
    //         }
    //         console.info(this.chatLogData);
    //       }
    //     );


    //   this.getMessageLogByAdminCode(this.adminCode, this.page, this.limit);


  }

  ngOnInit(): void {
    // this.chatService.service_baseUrl.next(this.apiUri);
    this.ImagePath = this.chatService.CHAT_URL + '/viewSharedImage/';
    this.getMessageLogByAdminCode(this.adminCode, this.page, this.limit);
    //this.chatLogData= this.common.getChatLog()
    this.socketListner();
  }

  getMessageLogByAdminCode(
    admincode: any,
    page: any,
    limit: any,
    scroll = null
  ) {
    if (scroll) {
      //this.common.infiniteLoader = true;
    }
    this.chatService.getMessageLogByAdminCode(
      admincode,
      page,
      limit
    ).subscribe((response: any) => {
      //console.log("RESPONSE CHAT", response);
      if (response.responsecode == 200) {
        if (scroll) {
          let arrayData = this.chatLogData;
          response.data.msgLogResList.forEach((element: any) => {
            if (element.messageTimeStamp) {
              element.formatedTIme = moment(element.messageTimeStamp).tz(this.chatService._sessionService.get('timeZone') ? this.chatService._sessionService.get('timeZone') :"America/New_York").format("hh:mm A");
            } else {
              element.formatedTIme = element.messageTime;
            }
            element.isNew = false;
            arrayData.push(element);
          });

          setTimeout(() => {
            this.chatLogData = arrayData.reverse();
            //this.common.SetChatlogtDataSource(arrayData.reverse());

            //this.common.infiniteLoader = false;
            this.chatLogDataLoaded = true;
          }, 1000);
        } else {
          response.data.msgLogResList.forEach((element: any) => {
            //console.log(element);
            if (element.messageTimeStamp) {
              element.formatedTIme = moment(element.messageTimeStamp).tz(this.chatService._sessionService.get('timeZone') ? this.chatService._sessionService.get('timeZone') :"America/New_York").format("hh:mm A");
            } else {
              element.formatedTIme = element.messageTime;
            }
            element.isNew = false;
          });
          this.chatLogData = response.data.msgLogResList.reverse();
          //this.common.SetChatlogtDataSource(
          //  response.data.msgLogResList.reverse()
          //);

      console.log(" this.chatLogData ",  this.chatLogData );

          this.chatLogDataLoaded = true;
        }
      } else {
        //this.common.SetChatlogtDataSource([]);
        this.chatLogData = []
        this.chatLogDataLoaded = true;
      }
    });
  }

  onClickDeleteChatLog(chatdata: any) {
    //console.log("DELETE CHAT LOG", chatdata);
    const readData = {
      isRead: true,
      userCode: this.adminCode,
    };
    this.chatService
      .updateisErasedMessageReadStatus(chatdata.id, readData)
      .subscribe((res: any) => {
        if (res.responsecode == 200) {
          this.getMessageLogByAdminCode(this.adminCode, this.page, this.limit);
        } else {
          // this.chatService.common.message = res.message;
          //this.chatService.common.errorMessage = true;
        }
      });
  }

 

  StartAnimation() {
    /*   this.animating = !this.animating;
      this.animState = !this.animState; */
    this.animating = true;
    this.animState = true;

  }
  StopAnimation(chatdata: any) {
    chatdata.isNew = false;
    this.animating = false;
    this.animState = false;

  }

  animDone(event: AnimationEvent) {
    if (this.animating) {
      this.animState = !this.animState;
    }
  }
  playSound() {
    let soundSource = "./../../../../assets/Notification.mp3";
    const audio = new Audio(soundSource);
    audio.play();
  }


  close() {
    this.OpenIndividualChat = false;
  }


  showChat(): void {
    this.service.common.progressLoader = true;

    if (this.chatService._sessionService.get("user_type") == "Health Technician") {
      this.service.adminGetAllGroupByUserCode(
        this.service.common.local.get("userCode"),
        response => {
          //console.log(response, "chat!!!");
          this.getChatData(response);
          this.service.common.progressLoader = false;
        }
      );
    } else {
      try {
        this.service.adminGetAllGroupByOrganizationCode(
          this.service.common.local.get("orgCode"),
          this.service.common.local.get("userCode"),
          response => {
            this.getChatData(response);
          }
        );
      } catch (error) {
        this.service.common.progressLoader = false;
        //console.log(error);
      }
    }
  }

  getChatData(response) {
    ////console.log(response);
    this.service.common.progressLoader = false;
    const dialogRef = this.dialog.open(ChatComponent, {
      width: "66%",
      panelClass: 'chat-popup',
      data: { arrayList: response }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.service.common.globalChatCount != 0) {
        try {
          this.service.common.adminGetAllUnReadMessageCountByOrganizationCode(
            this.service.common.local.get("super_admin_code"),
            data => {
              if (data.responsecode == 200) {
                this.service.common.globalChatCount = data.count;
              } else {
                this.service.common.globalChatCount = 0;
              }
              this.service.common.progressLoader = false;
            }
          );
        } catch (error) {
          this.service.common.progressLoader = false;
          //console.log(error);
        }
      }
    });
  }



  socketListner(): void {

   // console.log("chat log socker listining start....", '/topic/' + this.chatService._sessionService.get('super_admin_code'));
    this.rxStompService.watch('/topic/' + this.chatService._sessionService.get('super_admin_code'))
      .pipe(takeUntil(this.destroyObs)).subscribe((message) => {
       // console.log("chat log socker data", JSON.parse(message.body));
        let data = JSON.parse(message.body);

        if(data){
          if (data.messageTimeStamp)
             {
            data.formatedTIme =  moment(data.messageTimeStamp).tz(this.chatService._sessionService.get('timeZone') ? this.chatService._sessionService.get('timeZone') :"America/New_York").format("hh:mm A");
            } 
          else
           {
            data.formatedTIme = data.messageTime;
          }
          data.isNew = true; // new message
           this.chatLogData.unshift(data);
        }
        
      })
    //console.log(message);



  }


  ngOnDestroy() {
     this.destroyObs.next('');
    this.destroyObs.complete();
  }



  openIndividualChat(workerCode:string): void 
  {
     const dialogRef = this.dialog.open(ChatComponent, {
      width: '66%',
      panelClass: 'chat-popup',
      data: { user_or_worker_code : workerCode },
    });

    dialogRef.afterClosed().subscribe(result => {
     
      // if (this.service.common.globalChatCount != 0) {
      //   try {
      //     this.service.common.adminGetAllUnReadMessageCountByOrganizationCode(this.service.common.local.get('super_admin_code'), (data) => {
      //       if (data.responsecode == 200) {
      //         this.service.common.globalChatCount = data.count;
      //       } else {
      //         this.service.common.globalChatCount = 0;
      //       }
      //     })
      //   } catch (error) {
      //     //console.log(error)
      //   }

      // }
    });
  }


}





@Pipe({
  name: "lib_pipe_niceDateFormat",
})
export class lib_pipe_niceDateFormat implements PipeTransform {
  transform(value: any) {
    var _value = Number(value);
    /*   var _value = moment(value);
  var currentime =moment(Date.now()).tz("America/New_York")
  var dif = Math.floor(_value.diff(currentime) / 1000 / 86400);; */
    var dif = Math.floor((Date.now() - _value) / 1000 / 86400);
    //console.log("niceDateFormatPipeNew diff",dif)
    if (dif == -1) {
      _value += 5
      setTimeout(() => {
        //console.log("wait")
      }, 500);
    }
    if (dif < 30) {
      return convertToNiceDate(value);
    } else {
      var datePipe = new DatePipe("en-US");
      value = datePipe.transform(value, "MMM-dd-yyyy");

      return value;
    }
  }
}


function convertToNiceDate(time: string) {
  const timeZone = JSON.parse(sessionStorage.getItem('timeZone'))._value || '' ;
  const date = moment(time).tz( timeZone ? timeZone : "America/New_York");
  const now = moment().tz(timeZone ? timeZone : "America/New_York");
  const diffSeconds = now.diff(date, 'seconds');
  const diffDays = now.diff(date, 'days');

  if (diffSeconds < 60) {
    return "Just now";
  } else if (diffSeconds < 120) {
    return "1 minute ago";
  } else if (diffSeconds < 3600) {
    return Math.floor(diffSeconds / 60) + " minutes ago";
  } else if (diffSeconds < 7200) {
    return "1 hour ago";
  } else if (diffSeconds < 86400) {
    return Math.floor(diffSeconds / 3600) + " hours ago";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else if (diffDays < 7) {
    return diffDays + " days ago";
  } else if (diffDays < 31) {
    return Math.ceil(diffDays / 7) + " week(s) ago";
  }

  return date.format('YYYY-MM-DD'); // If older than 31 days, return formatted date
}

// export function convertToNiceDate(time: string) {
//   var date = new Date(time),
//     diff = (new Date().getTime() - date.getTime()) / 1000,
//     daydiff = Math.floor(diff / 86400);

//   //if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return "";

//   return (
//     (daydiff == 0 &&
//       ((diff < 60 && "Just now") ||
//         (diff < 120 && "1 minute ago") ||
//         (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
//         (diff < 7200 && "1 hour ago") ||
//         (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
//     (daydiff == 1 && "Yesterday") ||
//     (daydiff < 7 && daydiff + " days ago") ||
//     (daydiff < 31 && Math.ceil(daydiff / 7) + " week(s) ago")
//   );
// }