import { SessionStorageService } from 'angular-web-storage';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../skills/skill-list/skill-list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-arrival-departure',
  templateUrl: './arrival-departure.component.html',
  styleUrls: ['./arrival-departure.component.scss']
})
export class ArrivalDepartureComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ArrivalDepartureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, public storage: SessionStorageService
  ) { 
    this.translate.setDefaultLang(this.storage.get('language'));
  }

  ngOnInit() {
    ////console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
