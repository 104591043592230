<div class="row">
  <div class="col-md-11">
    <span *ngIf="data.notes">
      <h6 class="title-pd mb-0">{{ 'common.labels.Notes' | translate }}</h6>
      <hr class="title">
    </span>

  </div>
  <div class="col-md-1 pe-0 ">
    <i class="material-icons cursor-pointer clear-icon" (click)=" onNoClick()"> clear </i>
  </div>
  <div class="col-md-12 mt-2" *ngIf="data.notes">
    <p>
      {{data.notes}}
    </p>

  </div>
  <div class="col-md-10" *ngIf="data.patientRecurringNotes">
    <h6 class="title-pd mb-0">{{ 'common.labels.Patient Recurring Notes' | translate }}</h6>
    <hr class="title">
  </div>
  <div class="col-md-10" *ngIf="data.patientRecurringNotes">
    <p *ngIf="!editShow">
      {{data.patientRecurringNotes}}
    </p>
    <form [formGroup]="recurringNoteForm" (ngSubmit)="onSubmit()" class="example-form" *ngIf="editShow">
      <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
        <mat-label>Edit Recurring Note</mat-label>
        <input type="textarea" formControlName="editRecNote"  matInput placeholder="" [(ngModel)]="data.patientRecurringNotes">
      </mat-form-field>
      <span class="help-block err"
      *ngIf="!recurringNoteForm.get('editRecNote').valid && (!submitted || recurringNoteForm.get('editRecNote').touched)">
          Please enter text
      </span>
    </form>
  </div>
  <div class="col-md-2 text-center cursor-pointer"  *ngIf="data.patientRecurringNotes">
    <p>
      <i class="material-icons" title="Edit Recurring Note" (click)="showhide()"> edit </i>
    </p>
  </div>
  <div class="col-md-12" *ngIf="data.comments">
    <h6 class="title-pd mb-0">{{ 'common.labels.Notes to Administrator' | translate }}</h6>
    <hr class="title">
  </div>
  <div class="col-md-12" *ngIf="data.comments">
    <p>
      {{data.comments}}
    </p>
  </div>
</div>
