import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { AssignAppointmentComponent } from '../appointment/assign-appointment/assign-appointment.component';
import * as moment from 'moment';
import { CreateAppointmentService } from '../appointment/create-appointment/create-appointment.service';

@Component({
  selector: 'app-call-patient',
  templateUrl: './call-patient.component.html',
  styleUrls: ['./call-patient.component.scss']
})
export class CallPatientComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CallPatientComponent>,
    public common: CommonService, 
    public dialog: MatDialog,
    public createPatientService: CreateAppointmentService,
    @Inject(MAT_DIALOG_DATA) public data) { }


    patientList=[];
    currentSelectedDate;
    yearMonthDateFormat = 'YYYY-MM-DD';

  ngOnInit() {
    ////console.log(this.data)
    this.currentSelectedDate = moment(new Date(), this.yearMonthDateFormat).format(this.yearMonthDateFormat);
    this.patientList=this.data;
    

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

   /* Name:Shailesh
      use: assign appointment to elegible clinician */
  assignAppointment(jobDurationCode) {
    ////console.log(jobDurationCode)
    ////console.log(this.currentSelectedDate)
    this.common.progressLoader = true;
    let WeekstartDate = moment(this.currentSelectedDate).startOf('week').format(this.yearMonthDateFormat);
    let weekendDate = moment(this.currentSelectedDate).endOf('week').format(this.yearMonthDateFormat);
    try {
      this.createPatientService.getEligibleWorkerForJobsByJobDurationCode(jobDurationCode,this.currentSelectedDate,WeekstartDate,weekendDate, (data) => {
      ////console.log(data);
      if(data.data.workerWithDistanceList.length>0 )
      {
        const dialogRef = this.dialog.open(AssignAppointmentComponent, {
          width: '750px',
          disableClose: true,
          data: { data, jobDurationCode}  
        });
        dialogRef.afterClosed().subscribe(result => {
          //////console.log(result);
          if (result != undefined) {
            ////console.log("close dialog")
          }
        });
      }
      this.common.progressLoader = false;
      
    });
    } catch (error) {
      //console.log(error)
    }
    


  }
}
