import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.scss']
})
export class LeaveComponent implements OnInit {
  @Input() isShortcutModalOpen: any;
  tabAtciveFor: string = 'Pending';
  constructor() {}

  ngOnInit() {
    //console.log(this.isShortcutModalOpen)
  }
  
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // //console.log('tabChangeEvent => ', tabChangeEvent?.tab?.textLabel);
    this.tabAtciveFor = tabChangeEvent?.tab?.textLabel;
  }

}



