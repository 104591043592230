import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppointmentService } from '../appointment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss']
})
export class CancelAppointmentComponent implements OnInit {

  cancelAppointment: FormGroup;

  constructor(public service: AppointmentService, public dialogRef: MatDialogRef<CancelAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {
    this.cancelAppointment = new FormGroup({
      'comment': new FormControl(null, Validators.required)
    })
  }
/* Name:srinivas
       use: close cancel popup */
  cancel(){
    this.dialogRef.close();
  }
/* Name:srinivas
       use: save details of cancel appointment. */
  onSubmit(){
    //////console.log(this.cancelAppointment);
    if(this.cancelAppointment.valid){
      const data = {
        "comment": this.cancelAppointment.value.comment,
      }
      try {
        
      
      this.service.assignToWorkercancelByJobDurationCode(this.data.jobDurationCode, data, (res)=>{
        if(res.responsecode == 200){
          this.dialogRef.close(res.message);
        } else {
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
      })
    } catch (error) {
        
      }
    }
  }
}
