import { Directive, HostListener, Renderer2, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appPhoneMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneMaskDirective),
      multi: true
    }
  ]
})
export class PhoneMaskDirective implements ControlValueAccessor {
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  // @HostListener('input', ['$event.target.value'])
  @HostListener('input', ['$event'])
  // onInput(value: string) {
    onInput(event: any) {
    const pattern = /^[0-9-]+$/;
    let value = event.target.value ;
    if(pattern.test(value))
    {
      const formattedValue = this.formatPhoneNumber(value);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(formattedValue.replace(/\D/g, ''));
    }
    else{
      event.target.value = '';
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      this.onChange('');
      event.preventDefault();
      event.stopPropagation();
    }

  
  }

  writeValue(value: any): void {
    if (value) {
      const formattedValue = this.formatPhoneNumber(value);
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private formatPhoneNumber(value: string): string {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
}
 

