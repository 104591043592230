<div class="d-flex flex-row justify-content-between w-100 mb-3 mt-3 logo
  align-items-center">
  <div class="col-lg-6 col-md-6 col-sm-12 float-start header-name">{{ 'main.labels.Rate Card' | translate }}
  </div>
  <div class="col-lg-6 col-md-5 col-sm-12 searchfilter d-flex">
    <input matInput (keyup)="applyFilter($event.target.value)" type="text" autocomplete="false" class="form-control ps-2" autocomplete="off" placeholder="{{ 'main.labels.Search' | translate }}">
    <mat-icon class="cursor-pointer">search</mat-icon> 
    <button class="ms-2" mat-raised-button color="primary" (click)="saveRowData(dataSource)" >Save</button>
    <!-- <button mat-button (click)="cancelPopup()">Cancel</button> -->
  </div>
  <!-- <div class="col-lg-1 col-md-1 col-sm-12 float-end">
    &nbsp;
  </div> -->
</div>
<div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-responsiv">

    <ng-container matColumnDef="procedureIcon" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="avatarMaxWidth"> {{ 'main.labels.Job' | translate }} </th>
      <td mat-cell *matCellDef="let element" class="common-icon-round text-nowrap avatarMaxWidth">
        <img alt="No Image" class="td_avatar_img" *ngIf="element.procedureIcon"
          [src]="service.service.getSuperAdmin() + '/downloadFile/' + element.procedureIcon"
          [title]="element.procedureIcon" />
        <img alt="No Image" class="td_avatar_img" *ngIf="!element.procedureIcon"
          src="../../../../../assets/common_images/profile.jpg" />
      </td>
    </ng-container>

    <ng-container matColumnDef="procedureName" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'JobType.labels.Job Name' | translate }} </th>
      <td mat-cell *matCellDef="let element"> {{element.procedureName}} </td>
    </ng-container>

    <ng-container matColumnDef="regularRate" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Billing Rate/Hr</th>
      <td mat-cell *matCellDef="let element; let idx = index">
        <div class="d-flex text-nowrap">
          <span class="d_il_flex">$&nbsp;<input type="number" [value]="element?.regularRate ? element.regularRate : 0" class="form-control maxTipWid" [min]="00.00" [max]="100.00" [step]="00.01" (change)="editRowData($event, element, idx, 'regularRate')"></span>
        </div>        
      </td>
    </ng-container>

    <ng-container matColumnDef="overTimeRate" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Over Time Rate</th>
      <td mat-cell *matCellDef="let element; let idx = index">
        <div class="d-flex text-nowrap">
          <span class="d_il_flex">
            <input type="number" [value]="element?.overTimeRate ? element.overTimeRate : 0" 
            class="form-control maxTipWid" [min]="00.00" [max]="100.00" [step]="00.01"
             (change)="editRowData($event, element, idx, 'overTimeRate')" >
             &nbsp;% </span>
        </div>        
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="edit" class="dis-icon">
      <th mat-header-cell *matHeaderCellDef class="action"> </th>
      <td mat-cell *matCellDef="let element; let idx = index" class="action">
        <button *ngIf="showSaveBtn[idx]" type="button" class="btn btn-info btn-sm minWid w-100" (click)="saveRowData(dataSource, idx)">Save</button>
      </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div *ngIf="procedureList.length == 0 || dataSource.filteredData.length == 0" class="p-3 noList">{{ 'JobType.messages.Procedure data not available' | translate }}</div>
</div>

<div class="w-100" *ngIf="service.common.infiniteLoader">
  <div class="loadergif">
    <img src="assets/typint_dots_black.gif" alt="Loader">
    <p>Loading...</p>
  </div>
</div>