import { Component, OnInit, ViewChild, OnDestroy, TemplateRef, Input, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder, FormArray, FormControlName } from '@angular/forms';
import { UserRegistrationService } from './user-registration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AddUserComponent } from './add-user/add-user.component';
import { TechnicianService } from 'src/app/dashboards/providers-dashboard/technician/technician.service';

interface IcustomerDataPermissionRequestList
{
  dataPermissionCode: string,
  dataPermissionName: string,
  customerAdminCode: string,
  isView: boolean
} 
export interface DialogData { }


@Component({
  selector: 'user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {
  @Input() customerCode: any;
  @Input() isCustomerLoggedIn: boolean = false;
  addAdminForm: FormGroup;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  customerAdminCode: any;
  customerData: any;

  displayedColumns = ['adminName','phoneNumber', 'adminEmail', 'status', 'edit'];
  dataSourceUser = new MatTableDataSource();
  tabAtciveFor: string = 'Customer Detail';

  popupTitle: string = 'Add Contact';

  userRoleList = [];
  allRoleList = [];
  customerAddressList: any;
  dropdownSettings = {};
  dropdownDataList = [];
  selectedDropdownItems = [];
  dialogRef: any;
  cancelPatient = false;
  selectedCustomer: any;
  isSendMailLoading: boolean = false;
  customerPermission:IcustomerDataPermissionRequestList[] =[];

  constructor(
    public dialogRefTemp: MatDialogRef<UserRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public service: UserRegistrationService, public router: Router, public route: ActivatedRoute, public translate: TranslateService, public technicianService: TechnicianService, 
    private dialog: MatDialog, private fb: FormBuilder
  ) {
    this.translate.setDefaultLang(this.service.local.get('language'));


    this.service.common.sub = this.service.common.confirmEmit.subscribe(
      (data: any) => {
        // //////console.log(data);
        if (data == 'showpopup') {
          this.service.common.showConfirmPopup();
        }
      });
  }

  ngOnInit() {
    //console.log('CustomerData: ', this.customerCode);
    // console.log('dialog_data: ', this.dialog_data);
    
    if(this.customerCode || this.dialog_data?.customerCode) {
      this.isCustomerLoggedIn = this.isCustomerLoggedIn || this.dialog_data?.isCustomerLoggedIn;
      let customerCode = this.customerCode || this.dialog_data?.customerCode;
      this.customerCode = customerCode;
      this.service.getCustomerDetailByCustomerCode(customerCode, (response) => {
        // console.log('Res: ', response);
        if (response.responsecode == 200) {          
          this.service.customerData = response.data;
          this.customerData = response;

          let customerAddressList = this.customerData?.data && JSON.parse(JSON.stringify(this.customerData.data?.customerAddressResponseList)).map((item, i: { customerAddressCode: any, location: any, id: any }) => {
            return {id: i,  itemName: item.location, customerAddressCode: item.customerAddressCode, customerAdminCode: item.customerAddressCode}
          });
          this.customerAddressList = customerAddressList;
          this.getAdminListByCustomerCode(customerCode);
          this.resetUserFormData();
          this.getAllRoleName();
        }
      })
    }

    this.selectedDropdownItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Address',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'customClassAng2Multiselect',
      // badgeShowLimit: 5,
      maxHeight:200,
      autoPosition: true
    };
  }

  getAllRoleName(){
    this.service.getAllRoleName().subscribe((response: any) => {
      if (response.responsecode == 200) {
        this.allRoleList = response.data;
      } else {
        this.allRoleList = [];
      }
    })    
  }

  getAdminListByCustomerCode(customerCode: any){
    //console.log('customerCode: ', customerCode);
    if(customerCode){
    this.service.common.progressLoader = true;
    this.service.getCustomerAdminDetailsByCustomerCode(customerCode, (response) => {
      if (response.responsecode == 200) {
        this.service.common.progressLoader = false;
        this.userRoleList = response.data;
        this.dataSourceUser = new MatTableDataSource(this.userRoleList);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSourceUser.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;
       this.dataSourceUser.sort = this.sort;
      //console.log('DataSourceUser_1: ', this.userRoleList);
          } else {
            this.service.common.progressLoader = false;
            let dataUserStatic = [];
            this.userRoleList = [];
            this.dataSourceUser = new MatTableDataSource(dataUserStatic);
            // Apply the sorting accessor for case-insensitive sorting
        this.dataSourceUser.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;
      this.dataSourceUser.sort = this.sort;
      this.userRoleList = [];
      //console.log('DataSourceUser_2: ', this.userRoleList);
          }
      });
    }
  }  

  applyFilter(filterValue: string) {
    this.dataSourceUser.filter = filterValue.trim().toLowerCase();
    // //console.log(this.dataSourceUser.filter);
    if (this.dataSourceUser.paginator) {
      this.dataSourceUser.paginator.firstPage();
    }
  }

  addEditContact(data: any){
    // //console.log('Edit Data: ', data);    
    let dataVal = {};
    if(data){
      dataVal = data;
    } else {
      dataVal = {
        customerCode : this.customerCode,
        isShortcutModalOpen: false, 
        dataForAdd: true
      }
    }
    this.service.customerData = null;
    if(data){
      this.popupTitle = 'Update Contact';
      this.service.customerData = data;
      this.customerAdminCode = data.customerAdminCode;
      this.addAdminForm.controls['customerAdminCode'].setValue(data.customerAdminCode);
      this.addAdminForm.controls['adminName'].setValue(data.adminName);
      this.addAdminForm.controls['adminEmail'].setValue(data.adminEmail);
      this.addAdminForm.controls['phoneNumber'].setValue(data.phoneNumber);
      this.addAdminForm.controls['roleName'].setValue(data.roleName);
      let selectedList = this.customerAddressList.filter(o1 => data.customerAdminAddressMappingResponseList.some(o2 => o1.customerAddressCode === o2.customerAddressCode));
      this.selectedDropdownItems = selectedList;
    } else {    
      this.resetUserFormData();  
      this.addAdminForm.controls['customerAdminCode'].setValue(null);
      this.popupTitle = 'Add Contact';
    }
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '50%',
      disableClose: false,   
      data: dataVal  
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.getAdminListByCustomerCode(this.customerCode);
      }
    })
    // //console.log('CustomerAddressList Data: ', this.customerAddressList);
  }

  onItemSelect(item: any) {
    // //console.log('onItemSelect', item);
  }
  OnItemDeSelect(item: any) {
    // //console.log('OnItemDeSelect', item);
  }

  onSelectAll(items: any) {
    // //console.log('onSelectAll', items);
  }
  onDeSelectAll(items: any) {
    // //console.log('onDeSelectAll', items);
  }

  resetUserFormData() {
    this.selectedDropdownItems = [];
    this.addAdminForm = this.fb.group({
      'adminEmail': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
      'adminName': new FormControl('', Validators.required),
      'customerAdminCode': new FormControl(null),
      'customerCode': new FormControl(this.customerCode),
      'organizationCode': new FormControl(this.service.local.get('orgCode')),
      'phoneNumber': new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
      'roleName': new FormControl('', Validators.required),
      'customerAdminAddressMappingRequestList': new FormControl(''),
    });
  }

  onSubmitUser(){

    // //console.log('DataService: ', this.service.customerData);
    // //console.log('FormValues: ', this.addAdminForm.value)
    if (this.addAdminForm.valid) {

      let customerAdminAddressMappingRequestListVal = this.addAdminForm.value?.customerAdminAddressMappingRequestList && JSON.parse(JSON.stringify(this.addAdminForm.value?.customerAdminAddressMappingRequestList)).map((item: { customerAddressCode: any}) => {
        return {customerAddressCode: item.customerAddressCode}
      });
      // //console.log('CustomerAdminAddressMappingRequestListVal: ', customerAdminAddressMappingRequestListVal)

      // this.service.common.progressLoader = true;
      // this.submitted = true;

      // //console.log('FormValues: ', this.addAdminForm.value);

      let reqFormData = {
        customerAdminAddressMappingRequestList: customerAdminAddressMappingRequestListVal ? customerAdminAddressMappingRequestListVal : [],
        customerCode: this.customerCode,
        adminEmail: this.addAdminForm.value?.adminEmail,
        adminName: this.addAdminForm.value?.adminName,
        phoneNumber: this.addAdminForm.value?.phoneNumber,
        // customerAdminCode: this.popupTitle === 'Edit Operational User' ? this.customerAdminCode : null,
        customerAdminCode:  this.customerAdminCode ?  this.customerAdminCode : null,
        roleName: this.addAdminForm.value?.roleName,
        organizationCode: this.service.local.get('orgCode')
      }
      // //console.log('SubmitUserForm: ', reqFormData);
      // return;
      this.service.common.progressLoader = true;
      this.service.saveOrUpdateCustomerAdmin(reqFormData, (response) => {

        //////console.log(response);
        if (response.responsecode == 200) {
          this.resetUserFormData();
          this.customerAdminCode = '' ;
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.successMessage = true;
          // //console.log('saveOrUpdateCustomerAdmin:', response);
          this.getAdminListByCustomerCode(this.customerCode);
          this.dialogRef.close();
        } else {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.errorMessage = true;
        }
      })
    }
  }

  deleteContact(data: any){
    // //console.log('Delete Data: ', data); 
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete this contact?' }
    });
  
    dialogRef.afterClosed().subscribe(result => {
  
      if(result && result.toLowerCase() === 'ok')
      {
    if(data?.customerAdminCode){
      this.service.common.progressLoader = true;
      this.service.softDeleteCustomerAdminDetailsByCustomerAdminCode(data?.customerAdminCode, (response) => {
              
              if (response.responsecode == 200) {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.successMessage = true;
                this.getAdminListByCustomerCode(this.customerCode);
              } else {
                this.service.common.message = response.message;
                this.service.common.progressLoader = false;
                this.service.common.successMessage = true;
              }
     })
    }
  }
  })
  }

  cancelPopup() {
    if (this.cancelPatient) {
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'You have made some changes on the screen! Do you want to cancel the changes?' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          // this.resetFormData();
          // this.router.navigate(['/dashboard/patient/patient-list']);
          this.router.navigate(['/dashboard/customer/customer-list']);
        }
      });
    } else {
      // this.router.navigate(['/dashboard/patient/patient-list']);
      this.router.navigate(['/dashboard/customer/customer-list']);
    }
  }

  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    // this.page = this.page + 1;
    // this.getLicenceMasterByOrganizationCodeByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }


  openPermissionPopUp(element:any, templateRef:TemplateRef<any>){ 
    this.selectedCustomer = element?.adminName;
     this.customerPermission = JSON.parse(JSON.stringify(element.customerDataPermissionRequestList));
     this.dialogRef= this.dialog.open(templateRef, {  width: '600px'});
     this.dialogRef.afterClosed().subscribe((result) => { 
      this.customerPermission = [];
      this.selectedCustomer = '';
    });
  }

  closePopUp(){
    this.customerPermission = [] ;
    this.dialogRef.close();
    this.selectedCustomer = '';
    }

  updatePermissions()
  {
 
    if(this.customerPermission.length > 0)
    {
      this.service.updateCustomerPageAccess(this.customerPermission[0].customerAdminCode, this.customerPermission).subscribe((response:any) => {
              
        if (response.responsecode == 200)
         {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.successMessage = true;
          this.dialogRef.close();
          this.getAdminListByCustomerCode(this.customerCode);
        }
         else 
         {
          this.service.common.message = response.message;
          this.service.common.progressLoader = false;
          this.service.common.successMessage = true;
        }
   });
    }
  }


  chx_permissionChange(event,element)
  {
    if(element.dataPermissionName === "View Jobs & Status"){ 
      if(!element.isView){
        const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '500px',
          data: { msg: "If you uncheck this, then that contact won't be able to login. Are you sure you want to change this permission?" }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.customerPermission.map(ele => ele.isView = false);
          } else {
            this.customerPermission.find(ele => ele.dataPermissionName === 'View Jobs & Status').isView = true;
          }
        });
      }
    }
    else
    {
      if(element.isView){
        this.customerPermission.find(ele => ele.dataPermissionName === 'View Jobs & Status').isView = true;
      }
    }
    
  }

  sendWelcomeEmail(userCode:string){

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
         width: '320px',
         data: { msg: 'Are you sure you want send welcome email?' }
       });
   
       dialogRef.afterClosed().subscribe(result => {
         if (result != undefined) {
           
     this.service.common.progressLoader = true;
     this.service.sendWelcomeEmailToCustomer(userCode).subscribe((res:any) =>{
       this.service.common.progressLoader = false;
   
       if(res.responsecode == 200)
       {
          this.service.common.message = res.message;
         this.service.common.successMessage = true;
          
       }
       else{
         this.service.common.message = res.message;
         this.service.common.errorMessage = true;
       }
     })
         } 
       });
   
   }
   
   sendWelcomeEmailToAllStaff(customerCode: string){
   
     const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
          width: '320px',
          data: { msg: 'Are you sure want to send the welcome email to all customers?' }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            this.isSendMailLoading = true;
             this.service.common.progressLoader = true;
             this.service.sendWelcomeEmailToAllCustomer(customerCode, this.service.local.get('orgCode')).subscribe((res:any) =>{
               this.isSendMailLoading = false;
               this.service.common.progressLoader = false;
           
               if(res.responsecode == 200)
               {
                   this.service.common.message = res.message;
                 this.service.common.successMessage = true;
                   
               }
               else{
                 this.service.common.message = res.message;
                 this.service.common.errorMessage = true;
               }
             })
          } 
        });
    }

    changePassword(workerCode:string){ 
      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '320px',
        data: { msg: 'Confirm you want to reset your password via email?' }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          this.service.common.progressLoader = true;
      this.technicianService.sendForgetPasswordLinkByCode(workerCode)
      .subscribe((res:any) =>{
             
       this.service.common.progressLoader = false;
        
   
        if(res.responsecode == 200)
        {
         // this.dialogRef.close('data saved');
          this.service.common.message = res.message;
          this.service.common.successMessage = true;
           
        }
        else{
          this.service.common.message = res.message;
          this.service.common.errorMessage = true;
        }
   
      });
        } 
      });
  
    }

    close() {
      this.dialogRefTemp.close();
    }

}
