import { Injectable } from '@angular/core';
import { ApiRootService } from 'src/app/shared/api-root.service';
import { HttpClient } from '@angular/common/http';
 
@Injectable()
export class CheckOutMissedService {

constructor(
public service: ApiRootService,
public http: HttpClient,
) {   }

getMissedClockOutListByPage(org_code:string , search_text:string, page:number, limit:number){
  return this.http.get(this.service.getHealthCareUrl() + `/getClockOutMissListByOrganizationCodeByPage/${org_code}/${search_text}?page=${page}&limit=${limit}`)
}

saveMissedClockOutByAdmin(request_body:any) {
return this.http.post(this.service.getHealthCareUrl() + '/saveMissedClockOutByAdmin', request_body);
}

saveOrUpdateAdminActionTimeSheet(request_body:any) {
  return this.http.post(this.service.getHealthCareUrl() + '/saveOrUpdateAdminActionTimeSheet', request_body);
  }

 
  addAdminActionTimeSheet(request_body:any) {
    return this.http.post(this.service.getHealthCareUrl() + '/addAdminActionTimeSheet', request_body);
  }
 
  changeStaffStatus(request_body:any) {
    return this.http.put(this.service.getHealthCareUrl() + '/updateAdminAction', request_body);
  }
}