<div class="notification-setting StickyPosition position-relative">
  <div class="row">
    <form [formGroup]="NotificationSetting_Form">
      <div class="row">
        <div class="accordian-description">
          <div class="row position-sticky">
            <div class="col-6 text-start">
              <h4 class="header-name">
                Notification Settings
              </h4>
            </div>

            <ng-template [ngIf]="hasPermission.editPermission === true">
            <div class="col-6 text-end">
              <button mat-raised-button color="primary"
               (click)="updateNotificationSetting()"
               [disabled]="isLoading">
               <span  *ngIf="isLoading" class="spinner-border spinner-border-sm"></span>&nbsp;
               Update Setting
              </button>
            </div>
          </ng-template>

            <div>
              <hr>
            </div>
          </div>

          <div class="form-group accordian-description notifications">

            <div class="row">

              <!-- <div class="col-lg-6">
                {{ 'setting.labels.Notify On Schedule Publish' | translate}}
                <mat-slide-toggle class="ms-4" color="primary"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[0].globalSettingName == 'Shedule.Publish.Notification'"
                  [ngModel]="(_systemSettingsSVC.settings[1].NotificationSetting[0].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[0].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[0].globalSettingValue) === 'true'"
                  formControlName="schedule_publish"></mat-slide-toggle>

              </div> -->

              <div class="col-lg-4">
                <mat-checkbox color="primary" formControlName="push_notification"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[16].globalSettingName == 'push_notifications'"
                  class="day-name"
                  [ngModel]="(_systemSettingsSVC.settings[1].NotificationSetting[16].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[16].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[16].localSettingValue)==='true'">
                  Push Notification</mat-checkbox>

              </div>
              
              <div class="col-lg-4">
                <mat-checkbox *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[14].globalSettingName == 'sms_notifications'" color="primary" formControlName="sms_notifications"
                  class="day-name" [ngModel]="(_systemSettingsSVC.settings[1].NotificationSetting[14].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[14].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[14].localSettingValue)==='true'">
                  SMS Notification</mat-checkbox>

              </div>

              <div class="col-lg-4">
                <mat-checkbox *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[15].globalSettingName == 'mail_notifications'" color="primary" formControlName="mail_notifications"
                  class="day-name" [ngModel]="(_systemSettingsSVC.settings[1].NotificationSetting[15].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[15].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[15].localSettingValue)==='true'">
                  Mail Notification</mat-checkbox>

              </div>
              
            </div>

            <div class="mt-3">
              <!-- <div class="orange-title mt-3">
                {{ 'setting.labels.Add Reminder On' | translate}}
              </div> -->

              <!-- <div class="col-md-4 ">
                <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
                  <mat-label>{{ 'setting.labels.Select Reminder Option' | translate}}</mat-label>
                  <mat-select placeholder=" {{ 'setting.labels.Please Select' | translate}}"
                    [(ngModel)]="model_reminder" [ngModelOptions]="  {standalone: true}"
                    (selectionChange)="selectReminder($event)">
                    <mat-option *ngFor="let options of reminderOption" [value]="options.option">
                      {{options.option}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

              <!-- <div class="form-group">
                <mat-chip-list *ngIf="NotificationSetting_Form.controls.selectreminderOption.value">
                  <mat-chip
                    *ngFor="let reminder of NotificationSetting_Form.controls.selectreminderOption.value.split(','); let i=index">
                    <span>{{reminder}}</span> <a (click)="onRemoveReminder(i)">
                      <mat-icon>close</mat-icon>
                    </a></mat-chip>
                </mat-chip-list>
              </div> -->

              <!-- <div class="col-md-12 col-sm-12 float-start  mt-2">

                <div class="form-group col-md-9  ps-0 float-start">
                  <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                    *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[3].globalSettingName == 'AlertOn.SpecificTime.DistanceAppoinmentLocation'">
                    <mat-label>{{ 'setting.labels.Set Alert On Distance From Appoinment Location' |
                      translate}}</mat-label>
                    <input matInput placeholder="" formControlName="alert_distance"
                      [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[3].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[3].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[3].globalSettingValue">
                  </mat-form-field>
                  <span class="help-block err"
                    *ngIf="!NotificationSetting_Form.get('alert_distance').valid && (is_FormSubmitted || NotificationSetting_Form.get('alert_distance').touched)">
                    {{ 'setting.validators.Set alert on distance from appoinment location is required' | translate}}
                  </span>
                </div>

              </div> -->
            </div>
            <!-- <div class="col-md-4 col-sm-4 float-start">
                    <div class="orange-title mt-3">
                      {{ 'setting.labels.Get Notifications' | translate}}
                    </div>
                    <mat-checkbox color="primary" formControlName="push_notification" 
                    *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[16].globalSettingName == 'push_notifications'" class="day-name"
                    [ngModel]="(_systemSettingsSVC.settings[1].NotificationSetting[16].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[16].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[16].globalSettingValue)==='true'">
                    Notification</mat-checkbox>
                  </div> -->
            <!-- <div class="clearfix"></div> -->
            <!-- <hr>
            <div class="orange-title">
              {{ 'setting.labels.Assign Assignments' | translate}}
            </div> -->

            <div class="row g-2">
              <div class="form-group col-md-6  ">
                <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[10].globalSettingName == 'ConfigureMinutes'">
                  <mat-label>{{ 'setting.labels.Show Upcoming Job With' | translate}}</mat-label>
                  <input matInput placeholder="" formControlName="configuremintues"
                    [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[10].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[10].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[10].globalSettingValue">
                </mat-form-field>
                <!-- <div class="inline ">
                        {{ 'setting.labels.Minutes' | translate}} 
                      </div> -->
                <span class="help-block err"
                  *ngIf="!NotificationSetting_Form.get('configuremintues').valid && (is_FormSubmitted || NotificationSetting_Form.get('configuremintues').touched)">
                  {{ 'setting.validators.Configure mintues for job is required' | translate}}</span>
              </div>
              <div class="form-group col-md-6  ">
                <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[11].globalSettingName == 'AlertMinutes'">
                  <mat-label>{{ 'setting.labels.Alert Job' | translate}}</mat-label>
                  <input matInput placeholder="" formControlName="alertminutes"
                    [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[11].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[11].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[11].globalSettingValue">
                </mat-form-field>
                <!-- <div class="inline">
                        {{ 'setting.labels.Minutes' | translate}} 
                      </div> -->
                <span class="help-block err"
                  *ngIf="!NotificationSetting_Form.get('alertminutes').valid && (is_FormSubmitted || NotificationSetting_Form.get('alertminutes').touched)">
                  {{ 'setting.validators.Alert mintues for job is required' | translate}}</span>
              </div>
            </div>

            <div class="row g-2">
              <!-- <div class="form-group col-md-6 ">
                <mat-form-field appearance="outline" floatLabel="always" class="example-full-width"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[12].globalSettingName == 'ExpiredMinutes'">
                  <mat-label>{{ 'setting.labels.Expired Job' | translate}}</mat-label>
                  <input matInput placeholder="" formControlName="expiredminutes"
                    [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[12].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[12].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[12].globalSettingValue">
                </mat-form-field>
               <span class="help-block err"
                  *ngIf="!NotificationSetting_Form.get('expiredminutes').valid && (is_FormSubmitted || NotificationSetting_Form.get('expiredminutes').touched)">
                  {{ 'setting.validators.Expired mintues for job is required' | translate}}</span>
              </div> -->
              <div class="form-group col-md-6">
                <mat-form-field appearance="outline" floatLabel="always" clas="example-full-width"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[13].globalSettingName == 'isRecursive'">
                  <mat-label>{{ 'setting.labels.Is Recursive' | translate}}</mat-label>
                  <mat-select formControlName="isRecursive"
                    [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[13].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[13].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[13].globalSettingValue">
                    <mat-option *ngFor="let bool of boolean" [value]="bool.value">
                      {{bool.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="help-block err"
                  *ngIf="!NotificationSetting_Form.get('isRecursive').valid && (is_FormSubmitted || NotificationSetting_Form.get('isRecursive').touched)">
                  {{ 'setting.validators.Is required is required' | translate}}</span>
              </div>

            </div>

            <!-- <div class="row g-2">
              <div class="form-group col-md-6 ">
                <mat-form-field appearance="outline" floatLabel="always" clas="example-full-width"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[14].globalSettingName == 'sms_notifications'">
                  <mat-label>{{ 'setting.labels.SMS Notifications' | translate}}</mat-label>
                  <mat-select formControlName="sms_notifications"
                    [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[14].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[14].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[14].globalSettingValue">
                    <mat-option *ngFor="let bool of boolean" [value]="bool.value">
                      {{bool.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="help-block"
                  *ngIf="!NotificationSetting_Form.get('sms_notifications').valid && (is_FormSubmitted || NotificationSetting_Form.get('sms_notifications').touched)">
                  {{ 'setting.validators.SMS notifications is required' | translate}}</span>
              </div>
              <div class="form-group col-md-6 ">
                <mat-form-field appearance="outline" floatLabel="always" clas="example-full-width"
                  *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[1].NotificationSetting[15].globalSettingName == 'mail_notifications'">
                  <mat-label>{{ 'setting.labels.Mail Notifications' | translate}}</mat-label>
                  <mat-select formControlName="mail_notifications"
                    [(ngModel)]="_systemSettingsSVC.settings[1].NotificationSetting[15].localSettingValue != null?_systemSettingsSVC.settings[1].NotificationSetting[15].localSettingValue:_systemSettingsSVC.settings[1].NotificationSetting[15].globalSettingValue">
                    <mat-option *ngFor="let bool of boolean" [value]="bool.value">
                      {{bool.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span class="help-block"
                  *ngIf="!NotificationSetting_Form.get('mail_notifications').valid && (is_FormSubmitted || NotificationSetting_Form.get('mail_notifications').touched)">
                  {{ 'setting.validators.Mail notifications is required' | translate}}</span>
              </div>
            </div> -->

          </div>


        </div>
      </div>

    </form>

  </div>
</div>