
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CertificationService } from '../certification.service';
 import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-certification',
  templateUrl: './add-certification.component.html',
  styleUrls: ['./add-certification.component.scss']
})
export class AddCertificationComponent implements OnInit {

  certificationForm: FormGroup;
  options = [];
  certificationdata;
  fileUrl;
  filetitle;
  imageupload;
  imageMsg;
  submitted = true;
  popupTitle: string = 'Add Certification';
  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddCertificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public certificationService: CertificationService,
    private translate: TranslateService

  ) {}

  ngOnInit() {
    this.certificationForm = new FormGroup({
      "organizationCode":new FormControl(null),
      "certificationName": new FormControl(null, [Validators.required]),
      "certificationCode":new FormControl(null),
      'issuedBy' :new FormControl(false),
      'numberValue' :new FormControl(false),
      'expiration' :new FormControl(false)
    });    

    /* Name:Shailesh
     use: for getting Certification by id */

    try {
      // //console.log('ModData_:', this.data);
      if (this.data && this.data.certificationCode) {
            this.popupTitle = 'Edit Certification';
            this.certificationForm.controls['certificationName'].setValue(this.data.certificationName);
            this.certificationForm.controls['certificationCode'].setValue(this.data.certificationCode);
            this.certificationForm.controls['issuedBy'].setValue(this.data.issuedBy);
            this.certificationForm.controls['numberValue'].setValue(this.data.numberValue);
            this.certificationForm.controls['expiration'].setValue(this.data.expiration);
        
            this.certificationService.common.progressLoader = false;
          } else {
            this.popupTitle = 'Add Certification';
            this.certificationForm.reset();
            this.certificationService.common.progressLoader = false;
          }
      
    } catch (error) {
      //console.log(error)
    }  }


  /* Name:Shailesh
      use: for searching Certification name */
  private _filter(value, field): string[] {
    //////console.log(value);
    if (value) {
      let filterValue;
      if (typeof value.name === 'undefined') {
        filterValue = value.toLowerCase();
        this.certificationForm.controls['certificationCode'].setValue(null);
      } else {
        filterValue = value.name.toLowerCase();
        this.certificationForm.controls['certificationCode'].setValue(value.value);
      }
      return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.options;
    }

  }

  displayFn(option): string | undefined {
    return option ? option.name : undefined;
  }

  /* Name:Vivek Chauhan
     use: for adding/creating/saving certificationForm in organization */
  onSubmit() {

    // //console.log('Form: ', this.certificationForm);
    try {
      if (this.certificationForm.valid) {
        this.certificationForm.controls['organizationCode'].setValue(this.certificationService.storage.get('orgCode'));
        // //console.log('AddCertificationReq: ', this.certificationForm.value);
        // return;
        this.isLoading = true;
        this.submitted = true;
        this.certificationService.saveAndUpdateOrgCertificationMaster(this.certificationForm.value, (response) => {
          this.isLoading = false;
          if (response.responsecode == 200) {
            this.dialogRef.close('success');
            this.certificationForm.reset();
            this.certificationService.common.successMessage = true;
            this.certificationService.common.message = response.message;
          } else {
            this.submitted = false;
            this.certificationService.common.errorMessage = true;
            this.certificationService.common.message = response.message;
          }
        })
      } 
    } catch (error) {
      //console.log(error)
    }
    ////console.log(this.certificationForm);

  }

   /* Name:Shailesh
      use: for closing the dialogue/popup */
  onNoClick(): void {
    this.dialogRef.close('true');
  }
}
