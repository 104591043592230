import { Component, OnInit, ViewChild } from '@angular/core';
import { RandomAutoAppointmentService } from './random-auto-appointment.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-random-auto-appointment',
  templateUrl: './random-auto-appointment.component.html',
  styleUrls: ['./random-auto-appointment.component.scss']
})
export class RandomAutoAppointmentComponent implements OnInit {
  isRandom = false;
  dataSource: any;
  displayedColumns = ['clinicianName', 'noOfAppointment', 'drivingTime'];
  @ViewChild(MatSort) sort: MatSort;
  allReport = [];
  autoAssign = [];
  dataSource1: MatTableDataSource<unknown>;
  randomDriveTIme;
  autoDriveTime;
  constructor(
    public service: RandomAutoAppointmentService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getTabActive();
  }

  /* Name:Shailesh
      use: for current date appointment by org code */
  unassignAppointment() {
    this.service.common.progressLoader = true;

    try {
      this.service.unassignedCurrenetDateAppointmentsByOrgCode(this.service.session.get('orgCode'), (response) => {
      //console.log(response);
      this.service.common.progressLoader = false;
      if (response.responsecode == 200) {
        
        this.service.common.message = 'All appointmets are unassigned';
        this.service.common.successMessage = true;
      } else {

        this.service.common.message = response.message;
        this.service.common.errorMessage = true;
      }
    })
    } catch (error) {
      //console.log(error);
    }
    
  }

  /* Name:Shailesh
      use: for auto assignment of appointment by org code */
  adminAutoAssignForDemo() {

    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to auto staff assign?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.common.progressLoader = true;
          this.service.common.percentageLoader = true;
          try {
            this.service.adminAutoAssignForDemo(this.service.session.get('orgCode'), (res) => {
              //console.log(res);
              if (res.responsecode == 200) {

                this.service.totalRecords = res.data.totalRecord;
                this.service.processedRecords = res.data.processRecord;
                this.service.common.progressText = 'Processing ' + this.service.processedRecords + ' of ' + this.service.totalRecords + ' appointments';

                this.service.getAppointmentThreadProcessingByCode(res.data.appointmentsThreadProcessingCode, (response) => {
                  //console.log(response);
                  this.dataSource1 = new MatTableDataSource(response.tableData);
                  // Apply the sorting accessor for case-insensitive sorting
                  this.dataSource1.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                  this.dataSource1.sort = this.sort;
                  this.autoAssign = response.tableData;
                  this.autoDriveTime = response.totalDrivingTime;
                  this.getTabActive();
                  this.service.common.progressLoader = false;
                  this.service.common.progressText = '';
                  this.service.common.percentageLoader = false;
                })

              } else {
                this.autoAssign = [];
                this.dataSource1 = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource1.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource1.sort = this.sort;
                this.service.common.message = res.message;
                this.service.common.errorMessage = true;
                this.service.common.progressLoader = false;
              }
            })
          } catch (error) {
            //console.log(error)
          }
        } catch (error) {
          //console.log(error)
        }

      }
    });
    
  }

  /* Name:Shailesh
      use: for random assignment of appointment by org code */
  adminRandomAssignForDemo() {
    this.service.common.progressLoader = true;
    try {
      this.service.adminRandomAssignForDemo(this.service.session.get('orgCode'), (response) => {
      //console.log(response);
      this.service.common.progressLoader = false;
      if (response.responsecode == 200) {
        this.service.common.message = response.message;
        this.service.common.successMessage = true;
        this.dataSource = new MatTableDataSource(response.data);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.sort;
        this.randomDriveTIme = response.totalDrivingTime;
        this.allReport = response.data;
        this.getTabActive();
      } else {
        this.allReport = [];
        this.dataSource = new MatTableDataSource();
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
        this.service.common.message = response.message;
        this.service.common.errorMessage = true;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

  /* Name:Shailesh
      use: for getting demo by org code */
  getTabActive() {
    this.service.common.progressLoader = true;
    try {
      this.service.getDemoByOrganizationCode(this.service.session.get('orgCode'), (response) => {
      //console.log(response);
      this.service.common.progressLoader = false;
      if (response.responsecode == 200) {
        this.isRandom = response.data.isStarted;
      }
    })
    } catch (error) {
      //console.log(error)
    }
    
  }

}
