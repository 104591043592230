import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveService } from '../leave-service';
import { TranslateService } from '@ngx-translate/core';
 
@Component({
  selector: 'processed-leaves',
  templateUrl: './processed-leaves.component.html',
  styleUrls: ['./processed-leaves.component.scss']
})
export class ProcessedLeavesComponent implements OnInit {
  @Input() isShortcutModalOpen: any;
  leaveStatus: string = 'processed';
  distributionData: any = [];
  displayedColumns: string[] = ['name', 'startDate', 'endDate', 'comment', 'leaveStatus'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  page = 0;
  limit = 30;
  searchInput:string ;

  constructor(
    public dialog: MatDialog,
    public service: LeaveService,
    private translate: TranslateService,
     
    ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
   // this.applyFilter = debounce(this.applyFilter, 500);
  }

  ngOnInit() {
    this.page = 0;
    this.getListDataByPage();
  }

  
   /* Name:Vivek Chauhan
  use: for getting all Org Distribution Master data by org code */
  getListDataByPage(scroll = null) {
      // //console.log('getListDataByPage: ', this.page, scroll);
      if (!scroll) {
        this.service.common.progressLoader = true;
      }
      else if (scroll) {
        this.service.common.infiniteLoader = true;
      }
      try {
        this.service.getListDataByPageProcessed(this.service.storage.get('orgCode'), this.page, this.limit, (response) => {
          if(response.responsecode === 200){
            if (scroll) {
              let arrayData = this.dataSource.data;
              response.data.forEach(element => {
                arrayData.push(element)
              });
              setTimeout(() => {
                this.distributionData = arrayData;
                this.dataSource = new MatTableDataSource(arrayData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.infiniteLoader = false;
              }, 1000);

            } else {
              ////console.log(response.data);
              setTimeout(() => {
                this.distributionData = response.data;
                this.dataSource = new MatTableDataSource(this.distributionData);
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
                this.service.common.progressLoader = false;
              }, 1000)
            }
          } else {
            if (!scroll) {
              this.distributionData = [];
              setTimeout(() => {
                this.dataSource = new MatTableDataSource();
                // Apply the sorting accessor for case-insensitive sorting
                this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
                this.dataSource.sort = this.sort;
              }, 1000);      
            } else {
              this.service.common.infiniteLoader = false;
            }
            this.service.common.progressLoader = false;
          }
      });    
      } catch (error) {
        //console.log(error);
      }
  }


  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    ////console.log('scroll');
    this.page = this.page + 1;
    this.getListDataByPage('scroll');
    // //console.log('OnsScroll', this.page+1, this.limit);
  }

  /* Name:Vivek Chauhan
  use: for seraching Distribution Data name */
  //   applyFilter(searchText: string) {
  //     // //console.log('applyFilter', searchText);
  //   // this.dataSource.filter = filterValue.trim().toLowerCase();
  //   return;
  //   this.page = 0;
  //     this.service.common.progressLoader = true;
  //   try {
  //     if(searchText && searchText.trim()){
  //     this.service.getListDataBySearchProcessed(this.service.storage.get('orgCode'), this.leaveStatus, searchText, (response) => {
  //       if(response.responsecode === 200){
  //           setTimeout(() => {
  //             this.distributionData = response.data;
  //             this.dataSource = new MatTableDataSource(this.distributionData);
  //             this.dataSource.sort = this.sort;
  //             this.service.common.progressLoader = false;
  //           }, 1000);
  //       } else {
  //         this.service.common.progressLoader = false;
  //           this.distributionData = [];
  //           setTimeout(() => {
  //             this.dataSource = new MatTableDataSource();
  //             this.dataSource.sort = this.sort;
  //           }, 1000);          
  //       }
  //   });
  //   } else {
  //     this.getListDataByPage();
  //   } 
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // }


  applyFilter() {
    let filterValue = this.searchInput.trim().toLowerCase();
     
      this.dataSource.filter = filterValue.trim().toLowerCase();
     
     
  }

}





