<div class="row pb-4 mb-3">
  <div class="col-md-9 col-sm-12 item-inline">
    <div class="">
      <img alt="No Image" class="img-proc" *ngIf="imageupload" [src]="fileUrl" #imageUploaded
        class="profile-image avatar huge" />
      <img alt="No Image" class="img-proc" *ngIf="!imageupload" src="assets/common_images/profile.jpg"
        class="profile-image avatar huge" />
      <input class="profile-img" type="file" #file enctype="multipart/form-data" (change)="onFileChange($event)"
        #fileInput>
      <i class="material-icons logo-icon pointer" (click)="file.click()">
        edit
      </i>
    </div>

    <div>
      <div class="mt-2">
        <h4 class="primary-color worker-name" *ngIf="userName">
          {{userName}}
        </h4>
      </div>
      <!-- <div class="worker-desig">
        <p *ngIf="userDesignation">{{userDesignation}}</p>
        <p *ngIf="!userDesignation">{{ 'Technicians.labels.No Designation is present' | translate }}</p>
      </div> -->
    </div>
  </div>

  <div class="col-md-3 col-sm-12 mt-3">
    <div class="text-center">
      <div>
        <button *ngIf="personal" mat-raised-button color="primary"
          (click)="onSubmit()">{{ 'Technicians.labels.Save Changes' | translate }}</button>
        <button *ngIf="professional" mat-raised-button color="primary"
          (click)="onSubmitPro()">{{ 'Technicians.labels.Save Changes' | translate }}
        </button>
      </div>
      <button mat-button [routerLink]="['/dashboard-technician']"
        *ngIf="cancel">{{ 'Technicians.labels.Cancel' | translate }}</button>
    </div>
  </div>

</div>

<mat-tab-group dynamicHeight class="tab-button-auto-width" (selectedTabChange)="tabChange($event)">
  <mat-tab id="tabone">
    <ng-template mat-tab-label>
      <i class="material-icons pe-1"> perm_identity </i>
      {{ 'Technicians.labels.Personal' | translate }}
    </ng-template>
    <form [formGroup]="personalform" id="tabone" (ngSubmit)="onSubmit()">
      <div class="row ln-bt">
        <div class="col-md-4">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>{{ 'Technicians.labels.Name' | translate }}</mat-label>
              <input matInput class="form-control" formControlName="name" minlength="2" maxlength="50"
                [(ngModel)]="technicianService.personalWorker.name">
            </mat-form-field>
            <span class="help-block err"
              *ngIf="personalform.get('name').errors?.required && (!submitted || personalform.get('name').touched)">
              {{ 'Technicians.Validators.Name is required' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('name').errors?.pattern && (!submitted || personalform.get('name').touched)">
              {{ 'Technicians.Validators.Please enter valid name' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('name').errors?.minlength && (!submitted || personalform.get('name').touched)">
              {{ 'Technicians.Validators.Minlength is 2' | translate }}.
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('name').errors?.maxlength && (!submitted || personalform.get('name').touched)">
              {{ 'Technicians.Validators.Maxlength is 50' | translate }}.
            </span>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>{{ 'Technicians.labels.Email' | translate }}</mat-label>
              <input matInput class="form-control" formControlName="email"
                [(ngModel)]="technicianService.personalWorker.email">
            </mat-form-field>
            <span class="help-block err"
              *ngIf="personalform.get('email').errors?.required && (!submitted || personalform.get('email').touched)">
              {{ 'Technicians.Validators.Email Address is required' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('email').errors?.email && (!submitted || personalform.get('email').touched)">
              {{ 'Technicians.Validators.Please enter valid email address' | translate }}
            </span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>{{ 'Technicians.labels.Password' | translate }}</mat-label>
              <input matInput type="password" maxlength="50" tabindex="6" class="form-control"
                formControlName="password" #password [(ngModel)]="technicianService.personalWorker.password">
            </mat-form-field>
            <span class="help-block err"
              *ngIf="personalform.get('password').errors?.required && (!submitted || personalform.get('password').touched)">{{ 'Technicians.Validators.Password is required' | translate }}</span>
            <span class="help-block err"
              *ngIf="personalform.get('password').errors?.pattern && (!submitted || personalform.get('password').touched)">{{ 'Technicians.Validators.Password must have Uppercase & lowercase letter, Symbol, one no. and must have more than 6 char' | translate }}.</span>
            <span class="help-block err"
              *ngIf="personalform.get('password').errors?.maxlength && (!submitted || personalform.get('password').touched)">{{ 'Technicians.Validators.Password must be more less than 50 characters' | translate }}.</span>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>{{ 'Technicians.labels.Confirm Password' | translate }}</mat-label>
              <input type="password" matInput maxlength="50" class="form-control" formControlName="cpassword"
                [(ngModel)]="technicianService.personalWorker.cpassword">
            </mat-form-field>
            <span class="help-block err"
              *ngIf="personalform.get('cpassword').errors?.required && (!submitted || personalform.get('cpassword').touched)">{{ 'Technicians.Validators.Confirm Password is required' | translate }}</span>
            <span class="help-block err"
              *ngIf="personalform.controls.cpassword.errors?.invalid && ( !submitted || personalform.get('cpassword').touched)">{{ 'Technicians.Validators.Password doesnt match' | translate }}</span>
            <span class="help-block err"
              *ngIf="personalform.get('cpassword').errors?.pattern && (!submitted || personalform.get('cpassword').touched) ">{{ 'Technicians.Validators.Password must have Uppercase & lowercase letter, Symbol, one no. and must have more than 6 char' | translate }}.</span>
            <span class="help-block err"
              *ngIf="personalform.controls.cpassword.errors?.maxlength && (!submitted || personalform.controls.cpassword.touched)">{{ 'Technicians.Validators.Confirm Password must be more less than 50 characters' | translate }}.</span>
          </div>
        </div>
      </div>

      <div class="row" style="display: none;">
        <div class="col-md-4 col-sm-8">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
              <mat-label>{{ 'Technicians.labels.Date of Birth' | translate }}</mat-label>
              <input matInput class="form-control" formControlName="dob" [max]="breakYear" [matDatepicker]="picker"
                placeholder="">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <span class="help-block err"
              *ngIf="personalform.get('dob').errors?.required && (!submitted || personalform.get('dob').touched)">{{ 'Technicians.Validators.Date of Birth is required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-4 col-sm-12 col-xm-12">
          <div class="form-group row">
            <app-country-code class="col-md-4" (setCountryCode)="getCountryCode($event,'workerCountryCode')"
              [setCode]="workerCountryCode" [placeholder]="'Country Code'"></app-country-code>
            <!-- <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-8 p-0 no-form"> -->
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-8 p-0">
                <mat-label>Emergency No</mat-label>
              <input class="form-control" minlength="10" maxlength="10" matInput placeholder=""
                formControlName="mobileNo" [(ngModel)]="technicianService.personalWorker.mobileNo">
            </mat-form-field>
            <span class="help-block err"
              *ngIf="!personalform.get('homeCountryCode').valid && (!submitted || personalform.get('homeCountryCode').touched)">{{ 'Technicians.Validators.Mobile country code is required' | translate }}</span>
            <span class="help-block err"
              *ngIf="personalform.get('mobileNo').errors?.required && (!submitted || personalform.get('mobileNo').touched)">
              {{ 'Technicians.Validators.Mobile number is required' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('mobileNo').errors?.minlength && (!submitted || personalform.get('mobileNo').touched)">
              {{ 'Technicians.Validators.Mobile number length should be 10' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('mobileNo').errors?.maxlength && (!submitted || personalform.get('mobileNo').touched)">
              {{ 'Technicians.Validators.Mobile number length should be 10' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('mobileNo').errors?.pattern && (!submitted || personalform.get('mobileNo').touched)">
              {{ 'Technicians.Validators.Mobile number must be in number' | translate }}
            </span>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group row">
            <app-country-code class="col-md-4" (setCountryCode)="getCountryCode($event,'homeCountryCode')"
              [setCode]="homeCountryCode" [placeholder]="'Country Code'"></app-country-code>
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width col-md-8 p-0">
                <mat-label>Emergency No</mat-label>
              <input matInput class="form-control" maxlength="10" placeholder="" formControlName="emergencyNo"
                [(ngModel)]="technicianService.personalWorker.emergencyNo">
            </mat-form-field>
            <span class="help-block err"
              *ngIf="!personalform.get('workerCountryCode').valid && (!submitted || personalform.get('workerCountryCode').touched)">{{ 'Technicians.Validators.Country code is required' | translate }}</span>
            <span class="help-block err"
              *ngIf="personalform.get('emergencyNo').errors?.required && (!submitted || personalform.get('emergencyNo').touched)">{{ 'Technicians.Validators.Emergency number is required' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('emergencyNo').errors?.pattern && (!submitted || personalform.get('emergencyNo').touched)">{{ 'Technicians.Validators.Emergency number must be in number' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('emergencyNo').errors?.minlength && (!submitted || personalform.get('emergencyNo').touched)">{{ 'Technicians.Validators.Emergency number length should be 10' | translate }}
            </span>
            <span class="help-block err"
              *ngIf="personalform.get('emergencyNo').errors?.maxlength && (!submitted || personalform.get('emergencyNo').touched)">{{ 'Technicians.Validators.Emergency number length should be 10' | translate }}
            </span>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <div class="form-group">

          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
            <mat-label>Permanent Address</mat-label>
            <textarea class="form-control" matInput placeholder="" formControlName="parmanentAddress"
              ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
              (keypress)="handlePermanentAddressChange($event)" (onAddressChange)="handlePermanentAddressChange($event)"
              [(ngModel)]="technicianService.personalWorker.parmanentAddress"></textarea>
            <!-- <input class="form-control" matInput placeholder="" [(ngModel)]="technicianService.personalWorker.parmanentAddress"  formControlName="parmanentAddress"> -->
          </mat-form-field>

        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-7 col-sm-12 mt-4">
          <div class="border">
            <div id="map" #mapDis style="overflow: visible; height: 495px;"></div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12">
          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width  p-0 ">
              <mat-label>{{ 'main.labels.Job Site' | translate }}</mat-label>
              <textarea class="form-control" matInput placeholder="" formControlName="address"
                ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                (keypress)="handleAddressChange($event)" (onAddressChange)="handleAddressChange($event)"></textarea>

            </mat-form-field>
            <span class="help-block err"
              *ngIf="personalform.get('address').errors?.required && (!submitted || personalform.get('address').touched)">
              {{ 'main.labels.Job Site' | translate }} is required</span>
            <span class="help-block err"
              *ngIf="personalform.get('address').errors?.invalid && (!submitted || personalform.get('address').touched)">
              {{ 'Technicians.Validators.Enter valid Address' | translate }} </span>
          </div>
          <div class="row">

            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{ 'Technicians.labels.Apt/Unit no' | translate }}</mat-label>
                  <input [(ngModel)]="technicianService.personalWorker.apartmentNo" formControlName="apartmentNo"
                    (keyup)="getAppartmentNumber($event)" matInput class="form-control" placeholder="">
                </mat-form-field>
                <span class="help-block err"
                  *ngIf="!personalform.get('apartmentNo').valid && (!submitted || personalform.get('apartmentNo').touched)">{{ 'Technicians.Validators.Apartment/Unit no is required' | translate }}</span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{ 'Technicians.labels.Street Address' | translate }}</mat-label>
                  <input [(ngModel)]="technicianService.personalWorker.address" formControlName="fullAddress" matInput
                    (keyup)="getAppartmentNumber($event)" class="form-control" placeholder="">
                </mat-form-field>
                <span class="help-block err"
                  *ngIf="!personalform.get('fullAddress').valid && (!submitted || personalform.get('fullAddress').touched)">{{ 'Customers.validators.Street address is required' | translate }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{ 'Technicians.labels.City' | translate }}</mat-label>
                  <input matInput class="form-control" formControlName="city"
                    [(ngModel)]="technicianService.personalWorker.city" (keyup)="getAppartmentNumber($event)">
                </mat-form-field>
                <span class="help-block err"
                  *ngIf="personalform.get('city').errors?.required && (!submitted || personalform.get('city').touched)">
                  {{ 'Technicians.Validators.City is required' | translate }}</span>
                <span class="help-block err"
                  *ngIf="personalform.get('city').errors?.invalid && (!submitted || personalform.get('city').touched)">
                  {{ 'Technicians.Validators.City is required' | translate }}</span>
                <span class="help-block"
                  *ngIf="personalform.get('city').errors?.pattern && (!submitted || personalform.get('city').touched)">
                  {{ 'Technicians.Validators.City must be letter or space' | translate }}.
                </span>
              </div>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                <mat-label>{{ 'Technicians.labels.State' | translate }}</mat-label>
                <input matInput class="form-control" formControlName="state"
                  [(ngModel)]="technicianService.personalWorker.state" (keyup)="getAppartmentNumber($event)">
              </mat-form-field>
              <span class="help-block err"
                *ngIf="personalform.get('state').errors?.required && (!submitted || personalform.get('state').touched)">
                {{ 'Technicians.Validators.State is required' | translate }}
              </span>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{ 'Technicians.labels.Country' | translate }}</mat-label>
                  <input matInput class="form-control" minlength="2" maxlength="50" formControlName="country"
                    (keyup)="getAppartmentNumber($event)" [(ngModel)]="technicianService.personalWorker.country">
                </mat-form-field>
                <span class="help-block err"
                  *ngIf="personalform.get('country').errors?.required && (!submitted || personalform.get('country').touched)">
                  {{ 'Technicians.Validators.Country is required' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('country').errors?.minlength && (!submitted || personalform.get('country').touched)">
                  {{ 'Technicians.Validators.Country minlength is 2' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('country').errors?.maxlength && (!submitted || personalform.get('country').touched)">
                  {{ 'Technicians.Validators.Country minlength is 50' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('country').errors?.pattern && (!submitted || personalform.get('country').touched)">
                  {{ 'Technicians.Validators.Postcode pattern must be letter or number or space and must have more than 4 characters' | translate }}.
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
                  <mat-label>{{ 'Technicians.labels.Postcode' | translate }}</mat-label>
                  <input matInput class="form-control" minlength="5" maxlength="10" formControlName="postCode"
                    (keyup)="getAppartmentNumber($event)" [(ngModel)]="technicianService.personalWorker.postCode">
                </mat-form-field>
                <span class="help-block err"
                  *ngIf="personalform.get('postCode').errors?.required && (!submitted || personalform.get('postCode').touched)">
                  {{ 'Technicians.Validators.Postcode is required' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('postCode').errors?.invalid && (!submitted || personalform.get('postCode').touched)">
                  {{ 'Technicians.Validators.Postcode is required' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('postCode').errors?.minlength && (!submitted || personalform.get('postCode').touched)">
                  {{ 'Technicians.Validators.Postcode minlength is 5' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('postCode').errors?.maxlength && (!submitted || personalform.get('postCode').touched)">
                  {{ 'Technicians.Validators.Postcode maxlength is 10' | translate }}
                </span>
                <span class="help-block err"
                  *ngIf="personalform.get('postCode').errors?.pattern && (!submitted || personalform.get('postCode').touched)">
                  {{ 'Technicians.Validators.Post code pattern must be number and hyphen (-) and must have more than 5 characters' | translate }}.
                </span>
              </div>
            </div>
          </div>


        </div>
      </div>

    </form>
  </mat-tab>
  <mat-tab class="protab" name="tabs" name="tabs" id="tabone">
    <ng-template mat-tab-label>
        <i class="material-icons pe-1"> business_center</i>
        {{ 'Technicians.labels.Professional' | translate }}
    </ng-template>
    <form [formGroup]="professionalForm" id="tabone" (ngSubmit)="onSubmitPro()">
      <div class="row pt-4 mt-4">

        <div class="form-group  col-md-12 col-sm-12">


          <div class="form-group">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width custom-search">
              <mat-label>{{ 'Technicians.labels.Skills' | translate }}</mat-label>
              <input type="text" autoClose matInput [matAutocomplete]="auto"
                placeholder="{{ 'Technicians.labels.Skills List' | translate }}" formControlName="skills">
              <i class="material-icons float-end"> keyboard_arrow_down </i>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                (optionSelected)="selectedOption($event,'skill')">
                <mat-option *ngFor="let option of filteredOptionsSkill | async" [value]="option">
                  {{option.skillName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-chip-list formControlName="skillsSearch">
              <mat-chip class="text-capitalize"
                *ngFor="let org of technicianService.professionalWorker.skilmaster;let i = index">{{org.skillName}}
                &nbsp;<a (click)="removeOrg(i,'skill')">
                  <mat-icon>close</mat-icon>
                </a></mat-chip>
            </mat-chip-list>

            <mat-error
              *ngIf="professionalForm.get('skillsSearch').errors?.required && (!submitted || professionalForm.get('skillsSearch').touched)">
              {{ 'Technicians.Validators.Please select atleast one Skill' | translate }}</mat-error>
          </div>



        </div>
        <div class="form-group  col-md-12 col-sm-12">
          <div class="form-group w-100">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width" >
              <mat-label>{{ 'Technicians.labels.Employee Type' | translate }}</mat-label>
              <mat-select formControlName="employmentType" placeholder=""
                [(ngModel)]="technicianService.personalWorker.employmentTypeCode">
                <mat-option *ngFor="let lang of employeeType" [value]="lang.employmentTypeCode">
                  {{lang.employmentTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="help-block"
              *ngIf="professionalForm.get('employmentType').errors?.required && (!submitted || professionalForm.get('employmentType').touched)">{{ 'Technicians.Validators.Employee Type is required' | translate }}
            </span>
          </div>
        </div>
        <div class="form-group  example-full-width col-md-12">



          <div class="form-group ">
            <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width custom-search">
              <mat-label>{{ 'Technicians.labels.Certification' | translate }}</mat-label>
              <input type="text" matInput autoClose matInput [matAutocomplete]="auto1"
                placeholder="{{ 'Technicians.labels.Certification List' | translate }}"
                formControlName="certifications">
              <i class="material-icons float-end"> keyboard_arrow_down </i>
              <mat-autocomplete #auto1="matAutocomplete" [displayWith]="certificatedisplayFn"
                (optionSelected)="selectedOption($event,'certifications')">
                <mat-option *ngFor="let option of filteredCertificateOptions | async" [value]="option">
                  {{option.certificationName}}
                </mat-option>
              </mat-autocomplete>

            </mat-form-field>
          </div>

          <div class="form-group ">
            <mat-chip-list formControlName="certificationsSearch">
              <div *ngFor="let org of technicianService.professionalWorker.certificationmaster;let i = index"
                class="w-100 mb-3">
                <mat-chip class="text-capitalize">
                  {{org.certificationName}} &nbsp;<a (click)="removeOrg(i,'certifications')">
                    <mat-icon>close</mat-icon>
                  </a>
                </mat-chip>
                <div class="w-100 d-flex">
                  <div class="upload-certificate-img w-50" *ngIf="org.certificationUrl">
                    <div class="certificate-name w-75">
                      {{org.certificationUrl}}
                    </div>
                    <a class="btn-cert" target="_blank"
                      href="{{technicianService.service.getSuperAdmin()}}{{'/downloadFile/'+org.certificationUrl}}"
                      mat-raised-button color="gray">{{ 'Technicians.labels.Download' | translate }}</a>
                  </div>
                  <div class="upload-certificate-img w-50" *ngIf="!org.certificationUrl">
                    <div class="certificate-name">
                      {{org.certificationUrl}}
                    </div>
                    <a class="btn-cert" mat-raised-button color="gray">{{'Technicians.labels.Upload' | translate }}
                      <input class="certificate-img" type="file" enctype="multipart/form-data"
                        (change)="onCertificateUpload(org,$event)" #fileInput></a>
                  </div>
                  <div class="  certi-date w-50" *ngIf="org.certificationUrl">
                    <mat-form-field appearance="outline" floatLabel="always"  class="date-field w-100">
                      <mat-label>Certificate Expiration Date</mat-label>
                      <input matInput class="m-0" [matDatepicker]="picker2" (dateChange)="expirCertDate('org', $event)"
                        [value]="certificatedate[i]">
                      <mat-datepicker-toggle matSuffix [for]="picker2" class="datepick"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <!-- [(ngModel)]="technicianService.professionalWorker.certificationmaster.viewDate" -->
                  </div>
                </div>

              </div>
            </mat-chip-list>

            <mat-error
              *ngIf="professionalForm.get('certificationsSearch').errors?.required && (!submitted || professionalForm.get('certificationsSearch').touched)">
              {{'Technicians.Validators.Please select atleast one Certification' | translate }}</mat-error>
          </div>

        </div>
      </div>

    </form>

  </mat-tab>

  <mat-tab class="protab" name="tabs" name="tabs" id="tabthree">
    <ng-template mat-tab-label>
        <i class="material-icons pe-1"> business</i>
        {{'Technicians.labels.Provider' | translate}}
    </ng-template>
    <div class="row  pt-4">
      <div class="d-flex align-items-center d-flex justify-content-between">
        <span class="text-start"  *ngIf="!isAddProvider">
          Please complete the personal, professional and preferred workings hours before selecting a provider.
        </span>

        <div class="text-end">
          <button [disabled]="isAddProvider == false?true:false" mat-raised-button color="primary" class="my-3"
            (click)=addProvider()> {{'Technicians.labels.Add provider' | translate}}</button>
        </div>

      </div>
      <!--   <div class="col-md-2">
        <div class="row justify-content-end">
          <button [disabled]="isAddProvider == false?true:false" mat-raised-button color="primary" class="my-3"
            (click)=addProvider()> {{'Technicians.labels.Add provider' | translate}}</button>
        </div> 
      </div> -->
      <div class="clearfix"></div>
    </div>
    <div class="row" *ngIf="isAddProvider">
      <div class="col-md-12 table-scroll-height">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">

          <ng-container matColumnDef="providername">
            <th mat-header-cell *matHeaderCellDef>{{'Technicians.labels.Providers Name' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <div>
                <div>{{element.organizationName}} </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{'skills.labels.Actions' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <!-- <mat-icon *ngIf="element.isActivated" class="color-icon" title="{{'Technicians.labels.Deactivate' | translate}}"
                class="cursor-pointer me-2" (click)="onDeactivate(false,element.organization_Code)">not_interested</mat-icon>  -->

                <img alt="No Image" class="color-icon cursor-pointer me-2 statusClass" title="{{'Technicians.labels.Deactivate' | translate}}" *ngIf="element.isActivated" src="assets/common_images/block_icon@.png" (click)="onDeactivate(false,element.organization_Code)" />

                <img alt="No Image" class="color-icon cursor-pointer me-2 statusClass" title="Activate" *ngIf="!element.isActivated" src="assets/common_images/unblock_icon@.png" (click)="onDeactivate(true,element.organization_Code)" />
              <mat-icon *ngIf="providersList.length > 1" class="color-icon" title="{{'common.labels.Delete' | translate}}" class="cursor-pointer"
                (click)="onDelete(element.organization_Code)">delete</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="providersList.length == 0 || dataSource.filteredData.length == 0" class="">
          {{'Technicians.labels.No provider present' | translate}}</div>
      </div>
    </div>
  </mat-tab>

</mat-tab-group>