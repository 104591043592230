import { Component, OnInit } from '@angular/core';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-chat-pageview',
  templateUrl: './chat-pageview.component.html',
  styleUrls: ['./chat-pageview.component.scss']
})
export class ChatPageviewComponent implements OnInit {
  data: any;
  userType: any;

  constructor(
    public service: ChatService,
  ) {  }

  ngOnInit() {
    this.showChat();
  }

  showChat(): void {
    this.service.common.progressLoader = true;

    if (this.userType == "Health Technician") {
      this.service.adminGetAllGroupByUserCode(
        this.service.common.local.get("userCode"),
        response => {
          //console.log(response, "chat!!!");
          // this.getChatData(response);
          this.data = { arrayList: response };
          //console.log('RecivedDatafromPage_3: ', this.data)
          this.service.common.progressLoader = false;
        }
      );
    } else {
      try {
        this.service.adminGetAllGroupByOrganizationCode(
          this.service.common.local.get("orgCode"),
          this.service.common.local.get("userCode"),
          response => {
            this.service.common.progressLoader = false;
            // this.getChatData(response);
            this.data = { arrayList: response };
            //console.log('RecivedDatafromPage_4: ', this.data)
          }
        );
      } catch (error) {
        this.service.common.progressLoader = false;
        //console.log(error);
      }
    }
  }

}
