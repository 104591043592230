<!-- <p>user-registration works!</p> -->

<div class="w-100" [class.pt-4]="!dialog_data?.isOpenFromCustomerList">
    <div class="d-flex flex-row justify-content-between w-100 pb-3 logo align-items-center">
      <div class="float-start header-name"> Contacts </div>
    
      <div class="col-lg-4 col-md-4 col-sm-12 searchpat">
        <input type="text" class="form-control" id="search-user" autocomplete="off"
          placeholder="Search User" name="search-user" matInput (keyup)="applyFilter($event.target.value)">
        <mat-icon class="cursor-pointer">search</mat-icon>
      </div>

      <div class="text-center d-flex pt-1">
          <button
            class="mb-2"
            mat-raised-button
            color="primary"
            (click)="addEditContact(null)" >
            Add Contact
          </button>
          <button mat-raised-button class="ms-2" color="primary" matTooltip="Send welcome email to all contacts" (click)="sendWelcomeEmailToAllStaff(customerCode)">
            <mat-icon>mail</mat-icon>
            Send to all
          </button>
        <!-- <button mat-raised-button color="primary" class="mb-2" (click)="addEditContact(null)">Add Contact</button> -->
        <button *ngIf="!isCustomerLoggedIn" class="ms-2" mat-button (click)="dialog_data?.isOpenFromCustomerList ? close() : cancelPopup()">{{ 'Technicians.labels.Cancel'
          | translate }}</button>          
      </div>
    
    
    </div>
    <div class="table-scroll-height" [class.popupView]="dialog_data?.isOpenFromCustomerList" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
      <table mat-table [dataSource]="dataSourceUser" matSort class="mat-elevation-z8 patient-table">
    
        <ng-container matColumnDef="adminName" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name </th>
          <td mat-cell *matCellDef="let element" class="common-icon-round">
            {{element.adminName | capitalize}}
          </td>
        </ng-container>
    
        <!-- <ng-container matColumnDef="roleName" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role </th>
          <td mat-cell *matCellDef="let element" class="common-icon-round">
            {{element.roleName}}
          </td>
        </ng-container> -->
    
        <ng-container matColumnDef="phoneNumber" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.phoneNumber">{{element.phoneNumber}} </span>
            <span *ngIf="!element.phoneNumber">--</span>
          </td>
        </ng-container>
    
    
        <ng-container matColumnDef="adminEmail" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.adminEmail">{{element.adminEmail}}</span>
            <span *ngIf="!element.adminEmail">--</span>
          </td>
        </ng-container>       
        
        <ng-container matColumnDef="status" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
             <span>{{element.status}}</span>
          </td>
        </ng-container> 
    
        <ng-container matColumnDef="edit" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef class="action" style="width: 200px !important;">Action</th>
          <td mat-cell *matCellDef="let element" class="action" style="width: 200px !important;"> 
            <i class="material-icons curser-pointer me-2" matTooltipPosition="left" matTooltip="Send reset password link" (click)="changePassword(element.customerAdminCode)">lock</i>
            <i class="material-icons curser-pointer me-2" matTooltipPosition="left" matTooltip="Send welcome email" (click)="sendWelcomeEmail(element.customerAdminCode)">mail</i>
            <i class="material-icons curser-pointer me-2"  matTooltipPosition="left" matTooltip="Permission Access" (click)="openPermissionPopUp(element, permissionAccess_popUp)">settings</i>
            <i class="material-icons curser-pointer me-2" (click)="addEditContact(element)">edit</i>
            <i class="material-icons curser-pointer" (click)="deleteContact(element)">delete</i>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <div *ngIf="userRoleList.length == 0" class="p-3 noList">{{ 'Customers.messages.Not Available' | translate}}</div>
    </div>

    <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div>
 
  </div>


  
  <ng-template #permissionAccess_popUp>
    <div class="row mb-3">
      <div class="col-md-10 align-self-center">
        <h6 class="mb-0 dialog-header">Permission Access <span *ngIf="selectedCustomer">({{selectedCustomer | capitalize}})</span></h6>
      </div>
      <div class="col-md-2 text-end">
        <button class="btn-close cursor-pointer" (click)="closePopUp()"></button>
      </div>
    </div>
  
    <div class="row checkbox-lbl">
      <ng-template [ngIf]="customerPermission.length > 0">
      <ng-container *ngFor="let permission of customerPermission">
        <div class="col-md-4 mb-3">
          <mat-checkbox [(ngModel)]="permission.isView" [ngModelOptions]="{standalone: true}" (change)="chx_permissionChange($event,permission)">
            {{permission.dataPermissionName}}</mat-checkbox>
        </div>
      </ng-container>
      </ng-template>
    </div>

    <div class="row mt-5">
      <div class="col-12 text-end">
        <button class="me-3" mat-button (click)="closePopUp()">Cancel</button>
        <button mat-raised-button color="primary" (click)="updatePermissions()">Update Permission</button>
      </div>
    </div>

  </ng-template>