
<div class="row">
  <div class="mt-5 mb-5">
    <!-- <mat-icon (click)="close()" class="close cursor-pointer posAbsClose">close</mat-icon> -->
    <button class="btn-close cursor-pointer posAbsClose" (click)="close()"></button>
  </div>
<div class="row">
  
<div class="row patient-calendar m-0">
  
  <div class="col-md-4">
    <div class="calendar-whole">
    <div class="row calendar-nav cal mb-2">
      <div class="dt-yr col-lg-6 p-0 fw-bold float-start">
        {{navDate.format('MMMM YYYY')}}
      </div>
      <div class="col-lg-6 p-0 text-end float-end">
        <div class="calendar-nav-previous-month dt-yr">
          <i class="material-icons pe-2 pointer" (click)="changeNavMonth(-1)">keyboard_arrow_left</i>
          <i class="material-icons pointer" (click)="changeNavMonth(1)">keyboard_arrow_right</i>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="calendar">
      <div class="calendar-container">
        <!-- <div class="calendar-header">
          <div class="calendar-date" *ngFor="let day of weekDaysHeaderArr">
            {{day}}
          </div>
        </div> -->
        <div class="calendar-body">
          
          <div class="calendar-header calendar-date" *ngFor="let day of weekDaysHeaderArr">
            {{day}}
          </div>

          <div *ngFor="let day of gridArr" [ngClass]="day.available && selectDate===day.value ? 'selected-date' : '' "
            class="calendar-date" [class.is-disabled]="!day.available">
            <button *ngIf="day.value !== 0" class="date-item {{day?.fullfillmentRatio}} {{day?.fullfillmentRatio ? 'text-white' : ''}}" 
            (click)="selectDay(day)">{{day.value}} 
              <!-- <span [ngClass]="day?.jobsCount > 0 ? 'dot' : ''"></span> -->
              <!-- <span [ngClass]="day.eventData.length >0?'dot':''"></span> -->
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <!-- <div class="col-md-8 appointement-schedule"> -->
    <!-- <ng-template *ngIf="appointmentData.length > 0"> -->
      <div class="col-md-8">
    <!-- <div class="popup-viewport-body"> -->
      <div>
      <app-new-job-card 
      [dataSource]="appointmentData"
       [is_Loading]="enableLoading"
       [allowActions]="{
        allowEdit: true,
        allowDelete: true,
        allowAssignStaff: true,
        allowNotifyStaff: true,
        allowToViewNotifiedStaff: true,
        allowToCopyJob: true,
        allowView: true
        }"
       (updateParent)="RefreshAppoinmentsList($event)"
       ></app-new-job-card>
      <!-- <div *ngFor="let newData of appointmentData; let i=index;" class="example-item">
        <span>{{newData.key}} :</span>
        <div *ngIf="newData.value.length == 0" class="mt-2 mb-3">
            {{ 'common.labels.No appointments are present' | translate }}.
        </div>
        <div class="d-flex flex-row justify-content-between mt-2"
          *ngFor="let data of newData.value; let j=index;">
          <div class=" col-md-7 pat-tech pb-2">
            <p class="fw-bold text-capitalize">{{data.patientName}}</p>
            <p class="distance">{{ 'common.labels.Distance' | translate }}: {{data.distanceData}} {{ 'common.labels.Miles' | translate }}</p>
            <div *ngIf="data.workerName">
                {{ 'common.labels.Technician Name' | translate }} : {{data.workerName}}
            </div>
            <div *ngIf="!data.workerName">
                {{ 'common.labels.Technician Name' | translate }} : {{ 'common.labels.Not Available' | translate }}
            </div>
           
          </div>

          <div class="col-md-5 dis-img text-end pe-0 ps-0">
            <div class=" ms-1 mb-2" *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                <span>
                    <img class="img-icon cursor-pointer" alt="No Image" (click)="getProcedureDetails(procImg,data.notes)" *ngIf="i <= 3 && procImg.procedureIcon"
                    title="{{procImg.procedureName}}"
                    [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                </span>
                <img alt="No Image" *ngIf="!procImg.procedureIcon"
                  src="../../../../assets/common_images/profile.jpg" />
              </div>
              <div class="ps-0 img-data" *ngIf="data.organizationBasedProcedureDetailsList.length > 4"
                (click)="showImage(data)">
                <div class="img-count cursor-pointer">
                  {{data.organizationBasedProcedureDetailsList.length - 4}}
                </div>
    
              </div>
            </div>
  
            <div class="img-open " *ngIf="data.appointmentsDurationCode == showImg"> 
              <div class="img-icon me-0 mt-1" >
                <div *ngFor="let procImg of data.organizationBasedProcedureDetailsList; index as i">
                    <img (click)="getProcedureDetails(procImg,data.notes)" alt="No Image" class="me-2 cursor-pointer" *ngIf="i >= 2 && procImg.procedureIcon " title="{{procImg.procedureName}}"
                    [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
                </div>
              </div>
      </div>
        </div>
      </div> -->
    </div>
    <!-- </ng-template> -->
    <!-- <div *ngIf="appointmentData.length == 0" class="mt-4">
        {{ 'common.labels.No appointments are present' | translate }}.
    </div> -->
  </div>
</div>
</div>
</div>