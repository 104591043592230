<div class="row">
    <div class="mt-5 mb-5">
        <!-- <mat-icon (click)="close()" class="close cursor-pointer posAbsClose">close</mat-icon> -->
        <!-- <button class="btn-close cursor-pointer posAbsClose" (click)="close()"></button> -->
    </div>
    <div class="row">

        <div class="row patient-calendar m-0">

            <div class="col-md-3">
                <div class="calendar-whole">
                    <div class="row calendar-nav cal mb-2">
                        <div class="dt-yr col-lg-6 p-0 fw-bold float-start">
                            {{navDate.format('MMMM YYYY')}}
                        </div>
                        <div class="col-lg-6 p-0 text-end float-end">
                            <div class="calendar-nav-previous-month dt-yr">
                                <i class="material-icons pe-2 pointer"
                                    (click)="changeNavMonth(-1)">keyboard_arrow_left</i>
                                <i class="material-icons pointer" (click)="changeNavMonth(1)">keyboard_arrow_right</i>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="calendar">
                        <div class="calendar-container">
                            <!-- <div class="calendar-header">
            <div class="calendar-date" *ngFor="let day of weekDaysHeaderArr">
              {{day}}
            </div>
          </div> -->
                            <div class="calendar-body">

                                <div class="calendar-header calendar-date" *ngFor="let day of weekDaysHeaderArr">
                                    {{day}}
                                </div>

                                <div *ngFor="let day of gridArr"
                                    [ngClass]="day.available && selectDate == day.value ? 'selected-date' : '' "
                                    class="calendar-date" [class.is-disabled]="!day.available">
                                    <button *ngIf="day.value !== 0"
                                        class="date-item {{day?.fullfillmentRatio}} {{day?.fullfillmentRatio ? 'text-white' : ''}}"
                                        (click)="selectDay(day)">{{day.value}}
                                        <!-- <span [ngClass]="day?.jobsCount > 0 ? 'dot' : ''"></span> -->
                                        <!-- <span [ngClass]="day.eventData.length >0?'dot':''"></span> -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <div class="staff-appoinment-card-parent-div" style="width: 105%;">
                    <ng-template [ngIf]="appointmentData.length > 0" [ngIfElse]="_noDataFound">
                        <div class="viewHeight">
                            <ng-container *ngFor="let job of appointmentData; let cjIdx = index; let last = last">
                                <mat-card class="mx-auto card-appointment mt-3 mb-2">
                                    <div class="Card_JobDetails">
                                        <div class="row job-content container mb-0 card-border ms-0 pb-3 pt-2"
                                            [style.border-color]="job?.jobColor">


                                            <app-cust-short-job-type-dts [shjobTypeDtsInput]="{
                                                    date : job?.jobDateFixedFormat ,
                                                    customerName : job.customerName,
                                                    siteName : job.siteName,  
                                                    siteAddress : job?.siteAddress, 
                                                    jobTypeName : job.jobTypeName,
                                                    jobTypeIcon : job.jobTypeIcon,
                                                    rateCard : job?.showRateCard,  
                                                    jobTypeColor : job?.jobColor
                                                   }">
                                                <!-- shift : job?.startTime +'-'+ job?.endTime  -->
                                            </app-cust-short-job-type-dts>

                                            <app-shift-with-job-type [shiftWithJobTypeInput]="{
                                         jobTypeWithShiftList : job,
                                         customerName         : job.customerName ,
                                         siteName             : job.siteName ,
                                         siteAddress          : job.siteAddress ,
                                         appointmentsCode     : job.appointmentsCode,
                                         customerCode         : job.customerCode,
                                         siteAddressCode      : job.siteAddressCode,
                                         jobDateFixedFormat   : job.jobDateFixedFormat,
                                         date                 : job.jobDateFixedFormat
                                                 }" [allowScheduledStaff_Icon]="false" [allowActions]="{
                                          allowEdit: false,
                                          allowDelete: false,
                                          allowAssignStaff: false,
                                          allowNotifyStaff: false,
                                          allowToViewNotifiedStaff: false,
                                          allowToCopyJob: false,
                                          allowView: false
                                           }" [allowBreaks]="false">
                                                <!-- [status]="status" -->
                                            </app-shift-with-job-type>

                                            <ng-template [ngIf]="job?.jobDurationDetailsList?.length > 0 ">

                                                <ng-container *ngFor="let jobDuration of job?.jobDurationDetailsList">
                                                    <admin-scheduled-staff-card [scheduleStaff_Input_Data]="jobDuration"
                                                        (emitter_refreshList)="refreshData($event)">
                                                    </admin-scheduled-staff-card>
                                                </ng-container>

                                            </ng-template>

                                        </div>
                                    </div>
                                </mat-card>
                            </ng-container>
                        </div>
                    </ng-template>
                    <ng-template #_noDataFound>
                        <div class="row no-job-found-div mt-4" *ngIf="!is_Loading">
                            <span class="text-danger no-job-found-text">No record found</span>
                        </div>
                        <ng-template [ngIf]="is_Loading">
                            <div class="div w-100 mt-5 text-center snipper-position">
                                <mat-spinner class="center_50_px_spinner"></mat-spinner>
                                <p class="mt-3 mb-3 w-100 text-center">Please Wait ...</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>