<div class="title">
  <h6 *ngIf="!data">Add Admin</h6>
  <h6 *ngIf="data">Update Admin</h6>
  <mat-icon (click)="onNoClick()" class="cursor-pointer close-icon">close</mat-icon>
</div>
<form [formGroup]="adminForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="row">
    <div class="m-auto">
      <div class="p-2 profile-box">
        <img alt="No Image" class="img-proc" *ngIf="imageuploadAdmin" [src]="fileUrlAdmin" #imageUploaded
          class="profile-image avatar huge" />
        <img alt="No Image" class="img-proc" *ngIf="!imageuploadAdmin" src="assets/common_images/profile.jpg"
          class="profile-image avatar huge" />
        <input class="profile-img" #fileAdmin type="file" enctype="multipart/form-data"
          (change)="onFileChange($event, 'admin')" #fileInput>
        <i class="material-icons logo-icon pointer" (click)="fileAdmin.click()">
          edit
        </i>
      </div>
  
    </div>
  </div>
  <div class="row">
    <div class="clearfix"></div>
        <div class="col-md-6">
      <div class="form-group">

        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{'registration.provider.labels.Name' | translate }}</mat-label>
          <input matInput class="form-control" formControlName="adminName" minlength="2" maxlength="50"
            [(ngModel)]="service.adminData.adminDetails.name">
        </mat-form-field>
        <span class="help-block" *ngIf="adminForm.controls.adminName
                          .errors?.required && (!submittedAdmin ||
                          adminForm.controls.adminName.touched)">{{
                          'registration.provider.validators.Admin Username is required'
                          | translate }}.</span>
        <span class="help-block" *ngIf="adminForm.controls.adminName
                          .errors?.pattern && (!submittedAdmin ||
                          adminForm.controls.adminName.touched)">{{
                          'registration.provider.validators.Admin Username should only
                          have alphabets and special character such as' | translate
                          }} '</span>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{'registration.provider.labels.Email' | translate }}</mat-label>
          <input matInput class="form-control" #email formControlName="adminEmail" maxlength="50"
            [(ngModel)]="service.adminData.adminDetails.email">
        </mat-form-field>
        <span class="help-block" *ngIf="adminForm.controls.adminEmail
                          .errors?.required && (!submittedAdmin ||
                          adminForm.controls.adminEmail.touched)">{{
                          'registration.provider.validators.Email is required' |
                          translate }}.</span>
        <span class="help-block" *ngIf="adminForm.controls.adminEmail.errors?.email
                          && (!submittedAdmin || adminForm.controls.adminEmail.touched)">{{
                          'registration.provider.validators.Please enter valid email
                          id' | translate }}.</span>
        <span class="help-block" *ngIf="adminForm.controls.adminEmail.errors?.invalid
                          && (!submittedAdmin || adminForm.controls.adminEmail.touched)">
          {{ 'registration.provider.validators.Email id is already
                          present' | translate }}.</span>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">

        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{'registration.provider.labels.User Name' | translate }}</mat-label>
          <input matInput class="form-control" formControlName="adminUserName" minlength="2" maxlength="50"
            [(ngModel)]="service.adminData.adminDetails.userName">
        </mat-form-field>
        <span class="help-block" *ngIf="adminForm.controls.adminUserName
                          .errors?.required && (!submittedAdmin ||
                          adminForm.controls.adminUserName.touched)">{{
                          'registration.provider.validators.User Name is required'
                          | translate }}.</span>
        <span class="help-block" *ngIf="adminForm.controls.adminUserName
                          .errors?.pattern && (!submittedAdmin ||
                          adminForm.controls.adminUserName.touched)">{{
                          'registration.provider.validators.User Name should only
                          have alphabets and special character such as' | translate
                          }} '</span>
        <span class="help-block"
          *ngIf="adminForm.get('adminUserName').errors?.invalid && (!submittedAdmin || adminForm.get('adminUserName').touched)">
          {{ 'registration.technician.validators.User Name is already present' | translate }}</span>
      </div>
    </div>


    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{'Technicians.labels.Password' | translate }}</mat-label>
          <input matInput type="password" maxlength="50" class="form-control" formControlName="adminpassword"
            #adminpassword [(ngModel)]="service.adminData.adminDetails.password">
        </mat-form-field>
        <span class="help-block err" *ngIf="adminForm.get('adminpassword').errors?.required &&
                              (!submittedAdmin || adminForm.get('adminpassword').touched)">
          {{ 'registration.provider.validators.Password is required' |
                              translate }}.</span>
        <span class="help-block err" *ngIf="adminForm.get('adminpassword').errors?.pattern &&
                              (!submittedAdmin || adminForm.get('adminpassword').touched)">
          {{ 'registration.provider.validators.Password must have
                              Uppercase &lowercase letter, Symbol, one no. and must have
                              more than 6 char' | translate }}.</span>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{'Technicians.labels.Confirm Password' | translate }}</mat-label>
          <input type="password" matInput maxlength="50" class="form-control" formControlName="admincpassword"
            [(ngModel)]="service.adminData.adminDetails.confirmedPassword">
        </mat-form-field>
        <span class="help-block err" *ngIf="adminForm.get('admincpassword').errors?.required &&
                              (!submittedAdmin || adminForm.get('admincpassword').touched)">
          {{ 'registration.provider.validators.Password is required' |
                              translate }}.</span>
        <span class="help-block err" *ngIf="adminForm.controls.admincpassword.errors?.invalid && (
                              !submittedAdmin || adminForm.get('admincpassword').touched)">
          {{ 'registration.provider.validators.Password
                              does not match' | translate }}.</span>
        <span class="help-block err" *ngIf="adminForm.get('admincpassword').errors?.pattern &&
                              (!submittedAdmin || adminForm.get('admincpassword').touched)">
          {{ 'registration.provider.validators.Password
                              does not match' | translate }}.</span>
        <span class="help-block err" *ngIf="adminForm.controls.admincpassword.errors?.maxlength &&
                              (!submittedAdmin || adminForm.controls.admincpasswords.touched)">
          {{ 'registration.provider.validators.Password
                              does not match' | translate }}.</span>
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center" *ngIf="service.profileData.role == 'owner'">
      <section class="example-section mt-4">
        <mat-checkbox class="example-margin" (change)="adminActivecheck($event)"
          [checked]="service.adminData.adminDetails.isActive">Is Active</mat-checkbox>
      </section>
    </div>
  </div>

  <div class="text-center">
    <button mat-flat-button class="primary-btn">Submit</button>
  </div>
</form>