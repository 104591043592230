
  <div class="appointment-popup">
        <div class="row m-0">
          <div class="col-md-10">
            <h6 class="mb-0 title-pd">{{ 'Jobs.labels.Jobs' | translate }}</h6>            
          </div>
          <div class="col-md-2">
            <div class="row justify-content-end">
              <mat-icon class="btn-close -pd cursor-pointer" (click)="onNoClick()">close</mat-icon>
            </div>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-md-12">
            <hr class="title">
          </div>
        </div>
        <div class="row m-0">
          <div class="col-md-12">
  <mat-card class=" mx-auto card-appointment-popup mt-2 mb-2" clickOutside (clickOutside)="showPopup = ''">
    <div class="row icon-round pb-0">
      <div class="col-md-2 text-center">
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'pending'">
          hourglass_empty
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'assigned'">
          done
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'accepted'">
          done
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'inprogress'">
          more_horiz
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'completed'">
          done
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'expired'">
          history
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}} "
          *ngIf="data.appointment.status == 'cancelled'">
          close
        </i>
        <i class="material-icons icon-appointment {{data.appointment.status}}"
          *ngIf="data.appointment.status == 'late or cancelled'">
          assignment_late
        </i>
      </div>
      <div class="col-md-3 text-start">
        <small>{{ 'dashboard.labels.Patient Name' | translate }} </small>
        <p>{{data.appointment.customerName}}</p>
      </div>
      <div class="col-md-6">
        <small>{{ 'dashboard.labels.Patient Address' | translate }}</small>
        <p *ngIf="data.appointment.customerAddress">{{data.appointment.customerAddress}}</p>
        <p *ngIf="!data.appointment.customerAddress">Not Available</p>
      </div>
      <div class="col-md-1 text-end">
        <i class="material-icons menu-icon " (click)="geteditData(data)"
          *ngIf="data.appointment.status != 'inprogress' && data.appointment.status != 'completed' && data.appointment.status != 'cancelled' && data.appointment.status != 'expired'">
          more_vert
        </i>
      </div>
      <div class="edit-open col-md-9" *ngIf="data.appointment.appointmentsDurationCode == showPopup">
        <ul>
          <!-- <li (click)="route('update-appointment',data.appointment.appointmentsDurationCode,data.appointment.appointmentsCode)">
            <mat-icon>edit</mat-icon>
            <p>{{ 'Jobs.messages.Edit this job' | translate }}</p>
          </li>
          <li
            (click)="route('update-appointment-series',data.appointment.appointmentsDurationCode,data.appointment.appointmentsCode)">
            <mat-icon>filter_none</mat-icon>
            <p>{{ 'Jobs.messages.Edit this job in series' | translate }}</p>
          </li> -->
          <li *ngIf="userType !== 'Customer'" (click)="assignAppointment(data.appointment.appointmentsDurationCode)">
            <mat-icon>people</mat-icon>
            <p *ngIf="data.appointment.workerName == null">{{ 'Jobs.messages.Assign Staff' | translate }}
            </p>
            <p *ngIf="data.appointment.workerName !== null">{{ 'Jobs.messages.Change Staff' | translate }}
            </p>
          </li>

          <li *ngIf="data.appointment.status == 'assigned'"
            (click)="acceptAppointment(data.appointment.appointmentsDurationCode, data.appointment.workerCode)">
            <mat-icon>check</mat-icon>
            <p>{{ 'Jobs.messages.Accept Job' | translate }}</p>
          </li>
          <li *ngIf="data.appointment.status !=='rejected' && data.appointment.status == 'assigned'"
            (click)="rejectAppointment(data.appointment.appointmentsDurationCode, data.appointment.workerCode)">
            <mat-icon>close</mat-icon>
            <p>{{ 'Jobs.messages.Reject Job' | translate }}</p>
          </li>
          <li (click)="cancelAppointment(data.appointment.appointmentsDurationCode)">
            <mat-icon>close</mat-icon>
            <p>{{ 'Jobs.messages.Cancel Job' | translate }}</p>
          </li>
          <li
            *ngIf="data.appointment.status !=='rejected' && data.appointment.status !=='pending' && data.status !=='late or cancelled'"
            (click)="showRoute(data.appointment)">
            <mat-icon>where_to_vote</mat-icon>
            <p>{{ 'Jobs.messages.Show Route' | translate }}</p>
          </li>

        </ul>
      </div>

    </div>
    <hr class="p-0">
    <div class="row d-flex justify-content-between">
      <div class="col-md-3">
        <small>{{ 'Jobs.messages.Staff' | translate }}</small>
        <p *ngIf="data.appointment.workerName"><span appPopover [appPopoverData]="data.appointment" [placement]="'top'">{{data.appointment.workerName}}</span></p>
        <p *ngIf="!data.appointment.workerName">{{ 'Jobs.messages.Not Available' | translate }}</p>
      </div>
      <div class="col-md-3">
        <small>{{ 'Jobs.messages.Time' | translate }}</small>
        <!-- <p>{{data.appointment.newdate}}</p> -->
        <p class="text-uppercase">{{data.appointment.viewStartTime}}-{{data.appointment.viewEndTime}}</p>
      </div>

      <!-- <div class="col-md-2 cursor-pointer ps-0" *ngIf="data.appointment.workerName && data.appointment.status !== 'completed'"
        (click)="service.common.toggleDistance(data.appointment.appointmentsDurationCode)">
        <div class="distanceImg" *ngIf="service.common.showDistance !== data.appointment.appointmentsDurationCode">
          <small>{{ 'dashboard.labels.Distance' | translate }}</small>
          <p *ngIf="data.appointment.distanceText">{{data.appointment.distanceText}}</p>
          <p *ngIf="!data.appointment.distanceText">0.00 Miles</p>
        </div>
        <div class="distanceImg" *ngIf="service.common.showDuration == data.appointment.appointmentsDurationCode">
          <small>{{ 'Jobs.Create Appointment.labels.Duration' | translate }}</small>
          <p *ngIf="data.appointment.durationText">{{data.appointment.durationText}}</p>
          <p *ngIf="!data.appointment.durationText">0 Minute</p>
        </div>
      </div> -->


      <div class="col-md-1 cursor-pointer ps-0 d-flex align-self-center" *ngIf="data.appointment.workerName && data.appointment.status === 'completed' && data.appointment.actualStartTime"
      (click)="openArrivalDialog(data.appointment.arrivalTimeDate,data.appointment.departureTimeDate)">
        <mat-icon class="clock-time" title="Actual Schedule Time">access_time</mat-icon>
      </div>


      <!-- <div class="col-md-2" *ngIf="!data.appointment.workerName && data.appointment.status !== 'completed'">
        <small>{{ 'dashboard.labels.Distance' | translate }}</small>
        <p *ngIf="data.appointment.distanceText">{{data.appointment.distanceText}}</p>
        <p *ngIf="!data.appointment.distanceText">0.00 Miles</p>
      </div> -->

      <div class="col-md-1">
          <span *ngIf="data.appointment.notes || data.appointment.patientRecurringNotes || data.appointment.comments">
            <img class="cursor-pointer mt-2" src="/assets/notes_icon.png" title="Notes" (click)="getNotes(data.appointment)">
          </span>
          <span *ngIf="!data.appointment.notes && !data.appointment.patientRecurringNotes && !data.appointment.comments"></span>
      </div>
      <div class="col-md-4 dis-img text-end pe-0 ps-0">

        <div class=" me-0 pe-0 ">
          <div class=" me-1 mb-2" [ngClass]="i <= 3  && procImg.procedureIcon?'':'hideIcon'"
            *ngFor="let procImg of data.appointment.organizationBasedProcedureDetailsList; index as i">
            <img (click)="getProcedureDetails(procImg,data.appointment.notes)" class="img-icon" alt="No Image" *ngIf="i <= 3 && procImg.procedureIcon"
              title="{{procImg.procedureName}}"
              [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
            <img alt="No Image" *ngIf="!procImg.procedureIcon"
              src="../../../../assets/common_images/profile.jpg" />
          </div>
        </div>
        <div class="ps-0 me-1 img-data" *ngIf="data.appointment.organizationBasedProcedureDetailsList.length > 4"
          (click)="showImage(data.appointment)">
          <div class="img-count cursor-pointer">
            {{data.appointment.organizationBasedProcedureDetailsList.length - 4}}
          </div>

        </div>
        <div class="img-open " *ngIf="data.appointment.appointmentsDurationCode == showImg">
          <div class="img-icon me-0 mt-1">
            <div [ngClass]="i >= 4 && procImg.procedureIcon?'':'hideIcon'"
              *ngFor="let procImg of data.appointment.organizationBasedProcedureDetailsList; index as i">
              <img (click)="getProcedureDetails(procImg,data.appointment.notes)" alt="No Image" class="me-2" *ngIf="i >= 4 && procImg.procedureIcon "
                title="{{procImg.procedureName}}"
                [src]="service.service.getSuperAdmin() + '/downloadFile/' + procImg.procedureIcon" />
            </div>
          </div>
        </div>

      
      </div>
    </div>
  </mat-card>
  </div>
  </div>
</div>
