<div class="d-flex flex-row justify-content-between w-100  items-center">
  <div>

    <span class="float-start header-name"> {{ 'main.labels.Job Type' | translate }} </span>

     <span title="Note" class="ms-5 cursor-pointer" (click)="openNotePopUp()">
       <img 
          [src]="noteList.length > 0 ? 'assets/images/note-icon-active.svg' :'assets/images/note-icon.svg'" />
          <span class="{{noteList.length > 0 ? 'primary-color' : ''}}">&nbsp;Note</span>
      </span>

    </div>
  <div class="ms-auto p-2">
    <div class="text-center d-flex">
      <button class="mb-2 me-2" mat-raised-button color="primary" (click)="!isLoading && onSubmit()"> {{ 'JobType.labels.Save Changes'
        | translate }} <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span></button>
      <br />
      <button mat-button (click)="cancelPopup()">{{ 'JobType.labels.Cancel' | translate }}</button>
    </div>
  </div>
</div>

<div class="form-card procedureForm-div">
<form [formGroup]="procedureForm"  class="procedure-form custom-form">

  <div class="row">
<div class="col-md-2">

 <div class="button-container" [style.border-color]="procedureForm.controls.jobColor.value  + ' !important'">
    <div class="jobType-avatar" type="button" >
      <img class="img"  [src]="img_serverPath+procedureForm.controls.procedureIcon.value"
      onerror="this.src='assets/common_images/profile.jpg';">
    </div>
    <button class="btn  dropdown-toggle dropdown-toggle-split me-2" type="button" 
    id="dd_colorPicker"
    style="font-size: 30px;"
     data-bs-toggle="dropdown"
     data-bs-auto-close="outside" aria-expanded="false">
     <span class="visually-hidden">Toggle Dropdown</span>
    </button>

    <ul class="dropdown-menu" aria-labelledby="dd_colorPicker">
      <li class="_jobTypeAvatarContainer">
    <div class="icons-containers mb-3">
          <div class="row g-2">
            <h6>Choose Job Type Icon</h6>
            <ng-container *ngFor="let icon of getAllJobTypeIcon">
                <div class="col-2">
                 <a class="pointer" (click)="selectIcon(icon)">
                <div>
                 <img class="img"  style="width: 40px !important;" [src]="img_serverPath+icon.iconName">
                </div>
                </a>
              </div>
              </ng-container>
              
          </div>
         </div>

        <div class="row">
          <div class="col-md-12 mb-2">
            <h6>Choose Job Type Color</h6>
            </div>

        <div class="custom-radios">
          <ng-container *ngFor="let color of colorList">
          <div>
            <input type="radio" [id]="'cc_'+color" name="color" [value]="color" 
            [checked]="procedureForm.controls.jobColor.value === color"
             formControlName="jobColor"
               (change)="colorChange(color)">
            <label [for]="'cc_'+color">
              <span [style.background-color]="color">
                <mat-icon>done</mat-icon>
              </span>
            </label>
          </div>
          </ng-container>
         </div>
         </div>
      </li>
      
    </ul>
  </div>

  <span class="help-block"
  *ngIf="!procedureForm.get('procedureIcon').valid && (!submitted || procedureForm.get('procedureIcon').touched)">
  {{ 'JobType.validators.Job icon is required' | translate }}
</span>

</div>

<div class="col-md-10">
  <div class="row g-4">

    <div class="col-md-6 col-sm-12 d-flex">
      <div class="w-100 pb-0 mb-0">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
          <mat-label>{{ 'JobType.labels.Job Name' | translate }}</mat-label>
          <input autoClose matInput  placeholder="" type="text" class="form-control" formControlName="procedureName" [(ngModel)]="service.procedureForm.procedureName">       
        </mat-form-field>
        <span class="help-block err"
          *ngIf="!procedureForm.get('procedureName').valid && (!submitted || procedureForm.get('procedureName').touched)">{{ 'JobType.validators.Job name is required' | translate }}</span>
      </div>
 

    </div>

    <div class="col-md-6 col-sm-12">
      <div class="w-100 pb-0 mb-0">
        <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
          <mat-label>{{ 'JobType.labels.Job Description' | translate }}</mat-label>
          <input matInput placeholder="" class="form-control" formControlName="procedureDescription"
            [(ngModel)]="service.procedureForm.procedureDescription">
        </mat-form-field>
        <span class="help-block err"
          *ngIf="!procedureForm.get('procedureDescription').valid && (!submitted || procedureForm.get('procedureDescription').touched)">{{ 'JobType.validators.Job description is required' | translate }}</span>
      </div>
    </div>
 
 
      <div class="col-6">
        <div class="form-label">
          <span>Skills</span>
        </div>
        <angular2-multiselect appScrollAdjustment [data]="service.procedureForm.orgSkillList" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)" (onSelectAll)="onItemSelect($event)" (onDeSelectAll)="onItemSelect($event)" [(ngModel)]="selectedSkillItems" [settings]="dropdownSettings" formControlName="selctedskills"></angular2-multiselect>
        <span class="help-block err"
        *ngIf="procedureForm.controls.selctedskills.errors?.required && (!submitted || procedureForm.controls.selctedskills.touched)">{{ 'skills.validators.Skill name is required' | translate }}</span>
      </div>

        <div class="col-3 mt-0">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
            <mat-label> Billing Rate/Hr </mat-label>
             
            <mat-icon [inline]="true" matPrefix>$</mat-icon> 
            <input matInput type="text" class="form-control" formControlName="regularRate" maxlength="5">       
          </mat-form-field>
            <span class="help-block err"
            *ngIf="procedureForm.get('regularRate').errors?.pattern && (!submitted || procedureForm.get('regularRate').touched)">
            {{ 'JobType.validators.Should be number' | translate }}
          </span>
 
          </div>

        <div class="col-3 mt-0">
          <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width ">
            <mat-label>{{ 'JobType.labels.Over Time Rate' | translate }}</mat-label>
            <input  matInput  type="text" class="form-control" formControlName="overTimeRate" maxlength="5"> 
            <mat-icon [inline]="true" matSuffix>%</mat-icon>       
          </mat-form-field>
            <span class="help-block err"
            *ngIf="procedureForm.get('overTimeRate').errors?.pattern && (!submitted || procedureForm.get('overTimeRate').touched)">
            {{ 'JobType.validators.Should be number' | translate }}
          </span>
        </div>    
  
      <div class="col-md-6 col-sm-12 m-0">
        <mat-checkbox class="date-label" formControlName="isDateFlexible" [(ngModel)]="service.procedureForm.isDateFlexible">
          Skill Flexible</mat-checkbox>
      </div>
    
  </div>
  </div>
  
</div>
</form>
</div>