import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SessionStorageService } from 'angular-web-storage';
import { RequestNewJobService } from '../../customer-dashboard/cust-request-new-job/request-new-job.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ApiRootService } from 'src/app/shared/api-root.service';

@Component({
  selector: 'app-admin-requested-new-job-list',
  templateUrl: './admin-requested-new-job-list.component.html',
  styleUrls: ['./admin-requested-new-job-list.component.scss'],
  providers:[RequestNewJobService]
})

export class AdminRequestedNewJobListComponent {

  @Input() componentFor:string = "for requested job";
  @Input() isExpired:boolean = false;

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  displayedColumns: string[] = ['startDT', 'orderDT', 'requestStatus'];
  @Input() appoinment_Status:string = "all";
  private page:number = 0;
  private limit : number = 10 ;
  show_tbl_data_progressbar:boolean = false;
   
  controlsPermissions:IBtnControlsPermission ;
  protected img_serverPath :string = undefined;
  private isTBL_Scroll:boolean = false;
  
selected_job:any;
dialogRef:any;

constructor( 
  private _newJobSVC: RequestNewJobService,
  private _sessionSVC: SessionStorageService,
  private dialog: MatDialog,
  private _commonSVC: CommonService,
  private _apiSVC: ApiRootService,
  )
{

}

  ngOnInit(): void 
  {
    this.img_serverPath = this._apiSVC.getSuperAdmin() + '/downloadFile/';
     this.controlsPermissions =  this._commonSVC.getBtnEventPermissions("Job Scheduling"); 
     this.getDataAsPerRenderedComponent();
 }


   getDataAsPerRenderedComponent()
   {
    if(this.componentFor === 'for requested job')
      {
        this.getRequestedJobs();
      }
      else if (this.componentFor === 'for processed job')
      {
        this.getAllAppointmentsRequestByAdminCode();
      }
      else if (this.componentFor === 'for expired job')
      {
        this.getRequestedJobs();
      }
      else
      {
        throw new Error('no flag found for rendering component');
      }
   }

  /* get all new requested job by orgCode (Admin View)*/
  getAllAppointmentsRequestByAdminCode()
  {

    let orgCode = this._sessionSVC.get("orgCode");

     this.show_tbl_data_progressbar = true;
    this._newJobSVC.getProcessedJobs(orgCode, this.appoinment_Status, this.page, this.limit ).subscribe((res:any) =>{
      this.show_tbl_data_progressbar = false;
      if(res.responsecode === 200)
      {
        if (this.isTBL_Scroll) {
          let temp_array = this.dataSource.data;
          res.data.forEach(element => {
            element.isExpanded = true;
            temp_array.push(element);
          });
          this.dataSource = new MatTableDataSource(temp_array);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.matSort;
        }
        else {

          res.data.forEach(element => {
            element.isExpanded = true;
          });
          this.dataSource = new MatTableDataSource(res.data);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.matSort;
        }

      }
      else
      {
        if(!this.isTBL_Scroll)
          {
            this.dataSource = new MatTableDataSource();
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.matSort;
          }
      }
      
    })
  }

  
  toggleRow(element: any): void {
    element.isExpanded = !element.isExpanded;
    }



onScroll()
{
  this.isTBL_Scroll = true ;
 this.page = (this.page + 1);
 this.getDataAsPerRenderedComponent();
}
 

viewJob(selected_ele:any,templateRef:any)
{
  this.selected_job = selected_ele;
//console.log("this.selected_job", this.selected_job);

  this._commonSVC.dialogRef = this.dialog.open(templateRef, { width: '80%', height: '100%', position: { right: '0px', top: '0px' }, disableClose: true });
 
   this._commonSVC.dialogRef.afterClosed().subscribe(result => {
    if(result)
    {
      this.isTBL_Scroll = false;
      this.page = 0;
       this.getDataAsPerRenderedComponent();
    }
  });
   
  }


  closePopUp() {
    this._commonSVC.dialogRef.close();
  }



  /* get all new requested job by orgCode (Admin View)*/
  getRequestedJobs()
  {

    let orgCode = this._sessionSVC.get("orgCode");

     this.show_tbl_data_progressbar = true;
    this._newJobSVC.getRequestedJobs(orgCode, this.isExpired, this.page, this.limit ).subscribe((res:any) =>{
      this.show_tbl_data_progressbar = false;
      if(res.responsecode === 200)
      {
        if (this.isTBL_Scroll) {
          let temp_array = this.dataSource.data;
          res.data.forEach(element => {
            element.isExpanded = true;
            temp_array.push(element);
          });
          this.dataSource = new MatTableDataSource(temp_array);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.matSort;
        }
        else {

          res.data.forEach(element => {
            element.isExpanded = true;
          });
          this.dataSource = new MatTableDataSource(res.data);
          // Apply the sorting accessor for case-insensitive sorting
          this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
          this.dataSource.sort = this.matSort;
        }

      }
      else
      {
        if(!this.isTBL_Scroll)
          {
            this.dataSource = new MatTableDataSource();
          }
      }
      
    })
  }



}
