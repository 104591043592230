<div class="d-flex flex-row justify-content-between w-100 mb-2 logo
  align-items-center">
  <div class="float-start header-name">{{'JobType.labels.Job List' |
    translate }}
  </div>
</div>

<div class="d-flex justify-content-start mt-2">
  <div class=" searchfilter search">
    <input autocomplete="off" (keyup)="applyFilter($event.target.value)" type="text" class="form-control"
      placeholder="Search">
    <mat-icon class="cursor-pointer search-icon">search</mat-icon>
  </div>
  <div class="dateMaxWidth ps-2">
    <mat-form-field appearance="outline" floatLabel="always" class="w-100">
      <mat-label>Start Date</mat-label>
      <input (focus)="picker.open()" matInput [matDatepicker]="picker" placeholder="" [value]="startDate"
        (dateChange)="onDateChangeS($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
    <div class="dateMaxWidth ps-2">
    <mat-form-field appearance="outline" floatLabel="always" class="w-100">
      <mat-label>End Date</mat-label>
      <input (focus)="picker1.open()" matInput [matDatepicker]="picker1" placeholder="" [value]="enddDate"
        (dateChange)="onDateChangeE($event)">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="ps-2 pe-2">
    <button type="button" mat-raised-button color="primary" (click)="getData()">Filter</button>
  </div>
  <div class="vertical-line"> </div>

  <div class="ps-2">
    <mat-radio-group aria-label="Select an option" class="appointment-radio" (change)="changeStatus($event)"
      [(ngModel)]="status">
      <mat-radio-button value="all">All <span *ngIf="status == 'all'">({{count}})</span></mat-radio-button>
      <mat-radio-button value="accepted">Accepted <span
          *ngIf="status == 'accepted'">({{count}})</span></mat-radio-button>
      <mat-radio-button value="pending">Pending <span *ngIf="status == 'pending'">({{count}})</span></mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="row mb-2">
  <div class="col-lg-6">
    <button type="button" class="ms-2 mt-2" mat-raised-button color="primary" routerLink="/dashboard/jobs">Timeline
      View</button>
  </div>
  <div class="col-lg-6 text-end ">

    <button type="button" class="ms-2 mt-2" mat-raised-button color="primary" title="Export to Excel"
      (click)="export()">Excel</button>
    <button type="button" class="ms-2 mt-2" mat-raised-button color="primary" title="Export to PDF"
      (click)="!isPreviewPDFLoading && print_PDF('printJobList')">
      <span>PDF </span><span *ngIf="isPreviewPDFLoading" class="spinner-border spinner-border-sm"></span></button>

  </div>
</div>

<div class="row" id="printJobList">
  <div class="table-scroll-height col-md-12 scroll">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 appointmentList position-relative">
      <ng-container matColumnDef="patientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'JobType.labels.Customer Name' |
          translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div *ngIf="!element.customerName">NA </div>
            <div *ngIf="element.customerName">{{element.customerName | capitalize}} </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="procedureName">
        <th mat-header-cell *matHeaderCellDef>{{'JobType.labels.Job Type' |
          translate }} </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.organizationBasedProcedureDetailsList[0].procedureName}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="shift">
        <th mat-header-cell *matHeaderCellDef>Shift</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div>{{element.date}} {{element.startTime}}-{{element.endTime}}</div>
          </div>
        </td>

      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <div *ngIf="!element.siteName">NA </div>
            <div *ngIf="element.siteName">{{element.siteName}} </div>
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'report.labels.Status' |
          translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div>

            <div *ngIf="element.status">{{element.status | capitalize}} </div>
            <div *ngIf="!element.status">NA </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="workerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'JobType.labels.Staff Name' |
          translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-nowrap">
          <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="action"></th>
                  <td mat-cell *matCellDef="let element" class="popup action">
                    <mat-icon class="color-icon" (click)="geteditData(element)" class="cursor-pointer"
                      >more_vert</mat-icon>
                      <div class="edit-open"
                      *ngIf="element.appointmentsDurationCode == showPopup">
                      <ul>                       
                         <li>  <span>Patient Address :</span>   
                          <p>{{element.patientAddress}}</p>
                        </li>
                        <li><span>Distance :</span> 
                          <p *ngIf="!element.distanceText">NA</p> 
                          <p *ngIf="element.distanceText">{{element.distanceText}}</p>
                        </li>
                        <li><span>Preferred No :</span> 
                          <p *ngIf="!element.preferredPhoneType">NA</p> 
                          <p  *ngIf="element.preferredNumber == 'homeNumber'">{{element.homeNumber}}</p>
                            <p  *ngIf="element.preferredNumber == 'mobileNumber'">{{element.mobileNumber}}</p>
                          <p  *ngIf="element.preferredNumber == 'additionalNumber'">{{element.additionalNumber}}</p>  
                        </li>
                         <li> <span>Recurring Notes :</span>  
                          <p *ngIf="!element.patientRecurringNotes">NA</p> 
                          <p *ngIf="element.patientRecurringNotes">{{element.patientRecurringNotes}}</p>
                        </li>
                         <li><span>Notes :</span> 
                          <p *ngIf="!element.notes">NA</p>                            
                        <p  *ngIf="element.notes">{{element.notes}}</p>
                        </li>
                      </ul></div>
                  </td>

                </ng-container>
                     -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
      true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="norecords" class="no-data p-3">{{'report.labels.No data found' | translate }}</div>
  </div>
</div>

<div class="mt-5 table-scroll-height" id="printTable" style="display: none;">

  <table id="print" border="1" width="100%" class="position-relative" style="border-style: solid; border-collapse: collapse; border: 1px solid
        #cecfd5; margin-bottom: 40px;">
    <tr style="text-align: left">
      <th style="padding: 10px 15px;">{{'report.labels.Sr No' | translate
        }}.</th>

      <th style="padding: 10px 15px;">{{'report.labels.Patient Name' |
        translate }}</th>
      <th style="padding: 10px 15px;">{{'JobType.labels.Job Name' | translate }}</th>
      <th style="padding: 10px 15px;">Shift</th>

      <th style="padding: 10px 15px;">Location</th>

      <th style="padding: 10px 15px;">{{'report.labels.Status' |
        translate }}</th>
      <th style="padding: 10px 15px;">{{'report.labels.Technician Name' |
        translate }}</th>

    </tr>
    <tr *ngFor="let element of allAppontments; let i=index;">

      <td style="padding: 10px 15px;">
        <span>{{i+1}}</span>
      </td>

      <td style="padding: 10px 15px;">
        <span *ngIf="element.customerName">{{element.customerName | capitalize}}</span>
        <span *ngIf="!element.customerName">--</span>
      </td>
      <td style="padding: 10px 15px;">
        <span
          *ngIf="element.organizationBasedProcedureDetailsList[0].procedureName">{{element.organizationBasedProcedureDetailsList[0].procedureName}}</span>
        <span *ngIf="!element.organizationBasedProcedureDetailsList[0].procedureName">--</span>
      </td>
      <td style="padding: 10px 15px;">
        <span *ngIf="element.date">{{element.date}} {{element.startTime}}-{{element.endTime}}</span>
        <span *ngIf="!element.date">--</span>
      </td>

      <td style="padding: 10px 15px;">
        <span *ngIf="element.siteName">{{element.siteName}}</span>
        <span *ngIf="!element.siteName">--</span>
      </td>

      <td style="padding: 10px 15px;">
        <span *ngIf="element.status">{{element.status | capitalize}}</span>
        <span *ngIf="!element.status">--</span>
      </td>
      <td style="padding: 10px 15px;" class="text-nowrap">
        <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
      </td>
      
    </tr>
  </table>
</div>
<iframe #iframe style="display:none;"></iframe>