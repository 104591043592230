<div class="col-md-12">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Select Email</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <button class="btn-close cursor-pointer" matDialogClose></button>
      </div>
    </div>
  </div>

  <div class="row scrollableContent mb-3">
    <ul *ngIf="customerContactList.length > 0">
      <li class="mt-2" *ngFor="let element of customerContactList; index as idx">
        <div class="row">
          <div class="col-md-1 text-center align-self-center ">
            <mat-checkbox [(ngModel)]="element.isAssigned"> </mat-checkbox>
          </div>
          <div class="col-md-11 font-card">
            <mat-card class="example-card">
              <mat-card-content>
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0 cursor-pointer pointer nowrapTxt text-truncate">{{element.adminName | capitalize}}</p>
                  </div>
                  <div class="col-6">
                    <p class="mb-0 cursor-pointer pointer nowrapTxt text-truncate">{{element.adminEmail}}</p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

          </div>

        </div>
      </li>
    </ul>

    <div class="row d-flex align-items-center text-center spinner spinner-sm justify-content-center mb-3" *ngIf="showSpinner && customerContactList.length === 0">
      <div class="col text-center" style="display: contents;">
        <span class="me-3"><mat-spinner></mat-spinner></span><span>Please Wait, Getting Customer Contacts ...</span>
      </div>
    </div>

    <div class="row text-center justify-content-center mb-3" *ngIf="!showSpinner && customerContactList.length === 0">
      <div>
        <span class="text-danger">Customer Contact List Not Available</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">      
        <mat-form-field appearance="outline" floatLabel="none"  class="example-full-width">
         <textarea class="noteInput py-2" placeholder="Type emails comma separated like -> test@abc.com, email.123@mail.in" id="note_input_1" matInput rows="2" [(ngModel)]="otherEmails" (keyup)="validateEmailOrNot($event)" style="line-height: 24px;"></textarea>
        </mat-form-field>
        <span class="help-block err mt_16" *ngIf="invalidEmails?.length > 0">Please enter a valid email</span>
    </div>
  </div>

  <div class="row mt-1">    
    <div class="col-lg-12 text-end">
      <button class="me-3" mat-button mat-dialog-close>Cancel</button>
      <button [disabled]="isSendPdfLoading || invalidEmails?.length > 0" *ngIf="data?.popupTitle === 'Send' && !data?.sendForAttachment" mat-raised-button color="primary" (click)="postOrSaveAndPost(data.invoiceDetail, 'Send')"><span *ngIf="!isSendPdfLoading">Send </span><span *ngIf="isSendPdfLoading" class="spinner-border spinner-border-sm colorWhite"></span></button>
      <button [disabled]="isSendPdfLoading || invalidEmails?.length > 0" *ngIf="data?.popupTitle !== 'Send' && !data?.sendForAttachment" mat-raised-button color="primary" (click)="postOrSaveAndPost(data.invoiceDetail, 'Post and Send')"><span *ngIf="!isSendPdfLoading">Post and Send</span><span *ngIf="isSendPdfLoading" class="spinner-border spinner-border-sm colorWhite"></span></button>
      <button [disabled]="isSendPdfLoading || invalidEmails?.length > 0" *ngIf="data?.sendForAttachment" mat-raised-button color="primary" (click)="sendWithAttachment(data)"><span *ngIf="!isSendPdfLoading">Send </span><span *ngIf="isSendPdfLoading" class="spinner-border spinner-border-sm colorWhite"></span></button>
    </div>
  </div>
</div>
