import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TechnicianService } from 'src/app/dashboards/providers-dashboard/technician/technician.service';
import { MatCheckboxChange } from '@angular/material/checkbox'; 

@Component({
  selector: 'add-distribution-in-staff',
  templateUrl: './add-distribution-in-staff.component.html',
  styleUrls: ['./add-distribution-in-staff.component.scss']
})
export class AddDistributionInStaff implements OnInit {

  listData: any = [];
  displayedColumns: string[] = ['distributionName'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AddDistributionInStaff>,
    @Inject(MAT_DIALOG_DATA) public data: any, public technicianService: TechnicianService,) {}

  ngOnInit(){
    // console.log('Model_Data_AddDistributionInStaff: ', this.data);
    if(this.data?.staffCode){
      this.getAssignAndUnassignList(this.data.staffCode);
    }
  }

    /* Name:Vivek Chauhan
    use: for getting Assign And Unassign list by staffCode code */
    getAssignAndUnassignList(staffCode: any) {
      try {
        this.technicianService.common.progressLoader = true;
        this.technicianService.getAssignAndUnassignList(this.technicianService.common.local.get('orgCode'), staffCode, (response) => {
          this.technicianService.common.progressLoader = false;
        if (response.responsecode == 200) {          
          // console.log('getAssignAndUnassignList', response);
          this.listData = response.data;
          this.dataSource = new MatTableDataSource(this.listData);
          setTimeout(() => {       
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;    
            this.dataSource.sort = this.sort;
          }, 1000)
        } else {
          this.listData = [];
            this.dataSource = new MatTableDataSource(this.listData);
            setTimeout(() => {      
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.technicianService.common.globalSortingDataAccessorForMatSort;     
              this.dataSource.sort = this.sort;
            }, 1000)
        }
      })
      } catch (error) {
        //console.log(error)
      }
      
    }

    toggleSelection(element, change: MatCheckboxChange): void {
      if (change.checked) {
        element.isAssigned = true;
      } else {
        element.isAssigned = false;
      }
    }

    assignDistribution(){
      // console.log('AssignDistribution', this.listData);
      if(this.data?.staffCode){

        const filteredResult = this.listData && this.listData?.length > 0 && this.listData.filter(item => item).map(item => ({ distributionCode: item.distributionCode, isAssigned: item.isAssigned }));

        const reqBody = filteredResult ? filteredResult : [];

        // console.log('ReqBody: ', reqBody);
        // return;

        this.technicianService.assignOrUnassignDistribution(this.data.staffCode, reqBody, (response) => {
          if (response.responsecode == 200) {
            this.dialogRef.close('Success');
            this.technicianService.common.message = response.message;
            this.technicianService.common.successMessage = true;
          } else {
            this.technicianService.common.message = response.message;
            this.technicianService.common.progressLoader = false;
            this.technicianService.common.errorMessage = true;
          }
        });

      }
    }

}
