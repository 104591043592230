
<form [formGroup]="rejectForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
    <mat-label>{{data?.labelText ? data.labelText : 'Comments'}}</mat-label>
    <textarea matInput placeholder="" formControlName="comments" class="textareaInput"></textarea>
  </mat-form-field>
  <span class="help-block" *ngIf="!rejectForm.get('comments').valid && (!submitted || rejectForm.get('comments').touched)">
    {{data?.labelText ? data.labelText : 'Comments'}} is required</span>
  <div class="btns text-end mt-2">
    <a class="cursor-pointer d-inline-block me-3" (click)="closePopup()">{{ 'common.labels.Cancel' | translate }}</a>
    <button mat-raised-button color="primary" (click)="onSubmit()">{{ 'common.labels.Submit' | translate }}</button>
  </div>
</form>
