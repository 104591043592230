import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  tabAtciveFor: string = 'Skills';

  constructor() {}

  ngOnInit() {}

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // //console.log('tabChangeEvent => ', tabChangeEvent?.tab?.textLabel);
    this.tabAtciveFor = tabChangeEvent?.tab?.textLabel; 
  }

}