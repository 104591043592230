<div id="container">

  <div class="w-100">
    <mat-tab-group dynamicHeight class="tab-button-auto-width" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Weekly View">
        <div class="w-100 mt-4">
          <ng-container *ngIf="tabAtciveFor === 'Weekly View'">
            <app-WeeklyCalendar-component></app-WeeklyCalendar-component>
          </ng-container>  
        </div>              
      </mat-tab>
      <mat-tab label="Job View" id="Job_View_tab">
        <ng-container *ngIf="tabAtciveFor === 'Job View'">
          <app-job-view></app-job-view>
        </ng-container>        
      </mat-tab>

      <mat-tab label="Staff View">
        <ng-container *ngIf="tabAtciveFor === 'Staff View'">
          <app-staff-view></app-staff-view>
        </ng-container>        
      </mat-tab>

    </mat-tab-group>
  </div> 
  
</div>