<div class="custom-card">
    <div class="row">
        <ul class="settings-nav nav nav-tabs  col-md-3" id="myTab" role="tablist">

            <li class="nav-item" role="presentation" *ngIf="isView_GeneralSetting">
                <button class="nav-link" [class.active]="selectedTab === 'general'" type="button" role="tab" (click)="changeTab('general')">
                    <span class="me-2">
                        <img src="../../../assets/images/general-icon.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/general-green-icon.svg" class="active-icon" alt="">
                    </span>General</button>
            </li>

            <li class="nav-item" role="presentation" *ngIf="isView_OrgWorkingHrSetting">
                <button class="nav-link" [class.active]="selectedTab === 'userpreference'" type="button" role="tab" (click)="changeTab('userpreference')">
                    <span class="me-2">
                        <img src="../../../assets/images/user-preference-icon.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/user-preference-green-icon.svg" class="active-icon" alt="">
                    </span>Organization Working Hours</button>
            </li>

            <li class="nav-item" role="presentation" *ngIf="isView_NotificationSetting">
                <button class="nav-link" [class.active]="selectedTab === 'notification'"  type="button" role="tab" (click)="changeTab('notification')">
                    <span class="me-2">
                        <img src="../../../assets/images/notification-icon.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/notification-green-icon.svg" class="active-icon" alt="">
                    </span>Notification</button>
            </li>

            <li class="nav-item" role="presentation" *ngIf="isView_RoleProfileSetting">
                <button class="nav-link" [class.active]="selectedTab === 'role-profile'" type="button" role="tab" (click)="changeTab('role-profile')">
                    <span class="me-2">
                        <img src="../../../assets/images/role-profile-icon.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/role-profile-green-icon.svg" class="active-icon" alt="">
                    </span>Role Profile</button>
            </li>

            <li class="nav-item" role="presentation" *ngIf="isView_RoleSetting">
                <button class="nav-link" [class.active]="selectedTab === 'role'" type="button" role="tab" (click)="changeTab('role')">
                    <span class="me-2">
                        <img src="../../../assets/images/role-icon.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/role-green-icon.svg" class="active-icon" alt="">
                    </span>Role</button>
            </li>

            <li class="nav-item" role="presentation" *ngIf="isView_DesignSetting">
                <button class="nav-link" [class.active]="selectedTab === 'design'" type="button" role="tab" (click)="changeTab('design')">
                    <span class="me-2" (click)="changeTab('userpreference')">
                        <img src="../../../assets/images/design-icon.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/design-green-icon.svg" class="active-icon" alt="">
                    </span>Design</button>
            </li>

            <!-- <li class="nav-item" role="presentation" *ngIf="isView_EmailTemplateSetting">
                <button class="nav-link" [class.active]="selectedTab === 'emailTemplate'" type="button" role="tab" (click)="changeTab('emailTemplate')">
                    <span class="me-2">
                        <img src="../../../assets/images/Email-temp.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/Email-temp-active.svg" class="active-icon" alt="">
                    </span>Email Template</button>
            </li> -->

            <li class="nav-item" role="presentation" *ngIf="isView_EmailConfigSetting">
                <button class="nav-link" [class.active]="selectedTab === 'emailConfig'" type="button" role="tab" (click)="changeTab('emailConfig')">
                    <span class="me-2">
                        <img src="../../../assets/images/Emai-config.svg" class="inactive-icon" alt="">
                        <img src="../../../assets/images/Emai-config-active.svg" class="active-icon" alt="">
                    </span>Email Config</button>
            </li>

        </ul>

        <div class="tab-content settings-tab col-md-9 " id="myTabContent">

            <ng-template [ngIf]="selectedTab === 'general'">
                <div>
                    <app-general-sys-setting></app-general-sys-setting>
                </div>
            </ng-template>

            <ng-template [ngIf]="selectedTab === 'userpreference'">
                <div>
                    <app-user-preferences></app-user-preferences>
                </div>
            </ng-template>

            <ng-template [ngIf]="selectedTab === 'notification'">
                <div>
                    <app-notification-sys-setting></app-notification-sys-setting>
                </div>
            </ng-template>

            <ng-template [ngIf]="selectedTab === 'design'">
                <div>
                    <app-design-sys-setting></app-design-sys-setting>
                </div>
            </ng-template>

            <ng-template [ngIf]="selectedTab === 'role-profile'">
                <div>
                    <app-role-profile></app-role-profile>
                </div>
            </ng-template>


            <ng-template [ngIf]="selectedTab === 'role'">
                <div>
                    <app-role></app-role>
                </div>
            </ng-template>

            <ng-template [ngIf]="selectedTab === 'emailTemplate'">
                <div>
                    <app-email-template></app-email-template>
                </div>
            </ng-template>

            <ng-template [ngIf]="selectedTab === 'emailConfig'">
                <div>
                    <email-configuration-setting></email-configuration-setting>
                </div>
            </ng-template>
        </div>
    </div>




</div>