import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { StaffExceptionService } from './staff-exception.service';
import { CommonService } from 'src/app/shared/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { NewJobService } from 'src/app/dashboards/providers-dashboard/appointment/new-job/new-job.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-staff-exception',
  templateUrl: './staff-exception.component.html',
  styleUrls: ['./staff-exception.component.scss'],
  providers:[StaffExceptionService]
 })

export class StaffExceptionComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @Input() customerCode : string;
  @Input() staffCode : string ;

    exceptionForm: FormGroup;
  private _onDestroy = new Subject<void>();
  protected staffList:any[] =[];
  protected staffList_Filter:any[] =[];
  protected customerSiteList:any[] =[];
  protected blockedList:any[] =[];
  protected is_Loading:boolean = false;
  protected staffSeachInput: FormControl = new FormControl();

  customerList:any[] = [];
  customerList_Filter:any[] = [];
  customerSeachInput: FormControl = new FormControl();
  image_path : string;

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'staffIcon',
    'name', 
    'jobSite',
    'notes', 
    'action'
  ];

  constructor(
    private _StaffExceptionSVC: StaffExceptionService,
    private _commonSVC : CommonService,
    private dialog: MatDialog,
    private _newJobSVC: NewJobService,
    ){}


  ngAfterViewInit(): void {
   this.getAllBlockedStaffByStaffCode();
  }

ngOnInit(): void {
  this.image_path = this._StaffExceptionSVC.service.getSuperAdmin() + '/downloadFile/';
  this.initializeForm();
  this.getAllCustomerList();
  //this.getAllStaffMemberByOrgCode();
}

initializeForm()
{
  this.exceptionForm = new FormGroup({
    "excpStaff" : new FormControl(),
    "excpJobSite" : new FormControl(),
    "excpCustomer" : new FormControl(),
    "excpNotes"  : new FormControl()
  })
}

 

  // private filterStaff() {
  //   if (this.staffList.length == 0) {
  //     return;
  //   }
  //   let search = this.staffSeachInput.value.trim();

  //   this.staffList_Filter = this.staffList.filter((ele) =>
  //     ele.name.toLowerCase().includes(search.toLowerCase())
  //   );
  // }

  protected filterCustomer() {
    if (this.customerList.length == 0) {
      return;
    }
    let search = this.customerSeachInput.value.trim();

    this.customerList_Filter = this.customerList.filter((ele) =>
      ele.customerName.toLowerCase().includes(search.toLowerCase())
    );
  }


  // getAllStaffMemberByOrgCode()
  // {
  //   this._StaffExceptionSVC.getAllStaffMemberByOrgCode(this._StaffExceptionSVC._sessionSVC.get('orgCode')).subscribe((response:any) =>{
        
  //         if (response.responsecode == 200) 
  //           {
  //            this.staffList = response.data;
  //            this.staffList_Filter = response.data;
             
  //            this.staffSeachInput.valueChanges
  //            .pipe(takeUntil(this._onDestroy))
  //            .subscribe(() => {
  //              this.filterStaff();
  //            });
             
  //           // console.log(" this.staffList",  this.staffList)
  //           } 
          
                   
  //   })
  // }

  addStaffInBlockList()
  {
    if(this.exceptionForm.controls.excpCustomer.value && this.exceptionForm.controls.excpJobSite.value && this.exceptionForm.controls.excpJobSite.value?.length > 0) {
      // Do nothing
    } else {
      this._commonSVC.message = 'Please select customer and job site for block';
      this._commonSVC.errorMessage = true;
    }
    if(this.exceptionForm.controls.excpCustomer.value && this.exceptionForm.controls.excpJobSite.value && this.exceptionForm.controls.excpJobSite.value?.length > 0)
    {
      let customerAddressCodeList :any[] =[];
 
      if(this.exceptionForm.controls.excpJobSite.value?.length === this.customerSiteList.length)
      {
       customerAddressCodeList.push('All');
      }
      else{
       customerAddressCodeList = this.exceptionForm.controls.excpJobSite.value.map(ele=>{return ele.customerAddressCode});
      }

      
       let requestBody = {
        "customerAddressCodeList":customerAddressCodeList ,
        "customerCode": this.customerCode,
        "staffCode": this.staffCode,
        "note" : this.exceptionForm.controls.excpNotes.value
      
      }

      this._commonSVC.progressLoader = true;
      this._StaffExceptionSVC.saveBlockedStaffs(requestBody).subscribe((response:any) =>{
        this._commonSVC.progressLoader = false;

          this._commonSVC.message = response.message;
          if (response.responsecode == 200) 
            {
              this._commonSVC.successMessage = true;
              // this.getAllBlockedStaff();
              this.getAllBlockedStaffByStaffCode();
              this.exceptionForm.controls.excpCustomer.setValue("");
              this.exceptionForm.controls.excpJobSite.setValue([]);
              this.exceptionForm.controls.excpNotes.setValue("");
            } 
            else {
                 this._commonSVC.errorMessage = true;
              }

     })

    }
    

  }

  // getAllBlockedStaff()
  // {
  //   this._StaffExceptionSVC.getAllBlockedStaffByCustomerCode(this.customerCode).subscribe((response:any) =>{
        
  //     if (response.responsecode == 200) 
  //       {
  //        this.blockedList = response.data;
  //       } 
  //      })
  // }

  getAllBlockedStaffByStaffCode()
  {
    this._StaffExceptionSVC.getAllBlockedStaffByStaffCode(this.staffCode).subscribe((response:any) =>{
        
      if (response.responsecode == 200) 
        {
         this.blockedList = response.data;
       
        } 
        else{
          this.blockedList = [];
        }

        this.dataSource = new MatTableDataSource(this.blockedList);
        // Apply the sorting accessor for case-insensitive sorting
        this.dataSource.sortingDataAccessor = this._commonSVC.globalSortingDataAccessorForMatSort;
        this.dataSource.sort = this.matSort;
       })
  }


  removeStaffFromBlockList(element)
  {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '400px',
      data: { msg: 'Are you sure want to remove this staff from list?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) 
      {
        this._commonSVC.progressLoader = true;
        this._StaffExceptionSVC.removeStaffFromBlockList(element.blockedCustomerStaffCode).subscribe((response:any) =>{
          this._commonSVC.progressLoader = false;

          this._commonSVC.message = response.message;
          if (response.responsecode == 200) 
            {
              this._commonSVC.successMessage = true;
              this.getAllBlockedStaffByStaffCode();
            } 
            else {
                 this._commonSVC.errorMessage = true;
              }
                
           })
      }
    });
  }

  onJobSiteChange()
  {
    if(this.exceptionForm.controls.excpJobSite.value.find(ele=>ele === 'All'))
       {
        this.customerSiteList.map(ele => ele.isDisable = true);
       }
       else{
        this.customerSiteList.map(ele => ele.isDisable = false)
       }
  }


  ngOnDestroy() {
     this._onDestroy.next();
    this._onDestroy.complete();
  }


  onCustomerChange()
  {
    this.customerSiteList = this.exceptionForm.controls.excpCustomer.value.customerAddressResponseList || [];
    this.customerCode = this.exceptionForm.controls.excpCustomer.value.customerCode;
  
  }


  getAllCustomerList() {
    this._commonSVC.progressLoader = true ;
    this._newJobSVC
      .getAllCustomerDetails(this._StaffExceptionSVC._sessionSVC.get("orgCode"))
      .subscribe((res: any) => {
        this._commonSVC.progressLoader = false ;
        if (res.responsecode == 200) 
        {
          this.customerList = res.data;
          this.customerList_Filter = this.customerList;

          this.customerSeachInput.valueChanges
             .pipe(takeUntil(this._onDestroy))
             .subscribe(() => {
               this.filterCustomer();
             });
          
         }  
      });
  }

}
