import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
 import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { TechnicianService } from '../technician.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  addMember_Form:FormGroup;
  options = { types: [], componentRestrictions: { country: ["in", "us"] } };
  is_formSubmitted:boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddMemberComponent>,
    private _translateSVC: TranslateService,
    private _sessionStorageSVC: SessionStorageService,
    public technicianService: TechnicianService,
    private fb: FormBuilder
     ) {
    this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
  }


  ngOnInit(): void {
      this.initializeAddMemberForm();
  }

  initializeAddMemberForm()
  {
    this.addMember_Form = this.fb.group({
      // firstName : ['' , [Validators.required,Validators.minLength(3), Validators.pattern(this.technicianService.common.onlyNameWithSpace)]],
      firstName : ['' , [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      lastName : ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      email : ['', [Validators.required,Validators.email],this.confirmEmailAvailability.bind(this)],
      mobilePhone : ['', [Validators.required, Validators.minLength(10)]],
      workPhone : ['', [Validators.minLength(10)]],
      address : [''],
      city : [''],
      stateName : [''],
      postalCode : ['', [Validators.pattern(this.technicianService.common.postCode)]],
      country : [''],
      latitude : [''],
      longitude : [''],
      location : [''],
      notes : [''],
    })
  }

  closePopUp()
  {
    this.technicianService.common.resetNotesRequestForm();
      this.dialogRef.close('');
  }

  
  handleAddressChange(address: Address){
  
    let route = this.extractAddressComponent(address, 'route') ;

    let sublocality_level_2 = this.extractAddressComponent(address, 'sublocality_level_2');
    let sublocality_level_1 = this.extractAddressComponent(address, 'sublocality_level_1')
    this.addMember_Form.controls.address.setValue(route+' '+sublocality_level_2+' '+sublocality_level_1);
   
     this.addMember_Form.controls.location.setValue( address.formatted_address );

    this.addMember_Form.controls.city.setValue(this.extractAddressComponent(address, 'locality'));
    this.addMember_Form.controls.country.setValue(this.extractAddressComponent(address, 'country'));
    this.addMember_Form.controls.stateName.setValue(this.extractAddressComponent(address, 'administrative_area_level_1'));
    this.addMember_Form.controls.postalCode.setValue(this.extractAddressComponent(address, 'postal_code'));
    this.addMember_Form.controls.latitude.setValue(address.geometry.location.lat());
    this.addMember_Form.controls.longitude.setValue(address.geometry.location.lng());
    
  }

 
  extractAddressComponent(place: Address, componentType: string) {
    const component = place.address_components.find(comp =>
      comp.types.some(type => type === componentType)
    );
    return component ? component.long_name : '';
  }


addStaffMember()
{
  this.is_formSubmitted = true ;

  if(this.addMember_Form.valid)
  {
    let JSON = {
      "organizationCode":  this._sessionStorageSVC.get('orgCode'),
      
      "address": this.addMember_Form.controls.address.value,
       "city": this.addMember_Form.controls.city.value,
      "country": this.addMember_Form.controls.country.value,
      "email": this.addMember_Form.controls.email.value,
      "firstName":this.addMember_Form.controls.firstName.value,
      "lastName": this.addMember_Form.controls.lastName.value,
      "latitude": this.addMember_Form.controls.latitude.value,
      "longitude": this.addMember_Form.controls.longitude.value,
      "mobilePhone": this.addMember_Form.controls.mobilePhone.value,
       "postalCode": this.addMember_Form.controls.postalCode.value,
      "stateName": this.addMember_Form.controls.stateName.value,
      "workPhone": this.addMember_Form.controls.workPhone.value,
      "location": this.addMember_Form.controls.location.value,
      "caId": "",
      "homePhone": "",
      "notes": this.addMember_Form.controls.mobilePhone.value,
      "noteRequest": this.technicianService?.common?.noteRequest ? this.technicianService.common.noteRequest : null
    }
 
     this.technicianService.common.progressLoader = true;
     this.technicianService.addStaffMember(JSON).subscribe((res:any)=>{
      this.technicianService.common.progressLoader = false;
     this.is_formSubmitted = false;

      if(res.responsecode == 200)
      {
        this.technicianService.common.resetNotesRequestForm();
        this.dialogRef.close('data saved');
        this.technicianService.common.message = res.message;
        this.technicianService.common.successMessage = true;
         
      }
      else{
        this.technicianService.common.message = res.message;
        this.technicianService.common.errorMessage = true;
      }

     });

  }
 }

 confirmEmailAvailability( control: FormControl ): Promise<any> | Observable<any> {
  const emailavaiable = new Promise<any>((resolve, reject) => {
    this.technicianService.checkEmailAvialablity(control.value, response => {
      ////console.log(response);
      if (response == false) {
        resolve({ invalid: true });
      } else {
        resolve(null);
      }
    });
  });
  return emailavaiable;
}


}
