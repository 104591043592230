<div class="d-flex flex-row justify-content-between w-100 mb-2 mt-3 logo
  align-items-center">
  <div class="float-start header-name">Processed Leaves
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 searchfilter text-end">
    <input autocomplete="off" matInput
      (keyup)="applyFilter()" type="text"
      class="form-control"
      placeholder="Search"
      [(ngModel)]="searchInput">
    <mat-icon class="cursor-pointer">search</mat-icon>
    <!-- <button mat-raised-button color="primary">Add</button> -->
  </div>
</div>
<div class="w-100">
  <div class="col-md-12">
    <mat-progress-bar mode="indeterminate" *ngIf="service.common.infiniteLoader"></mat-progress-bar>
<div class="table-scroll-height mt-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 lic_tab position-relative">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Name</th>
        <td mat-cell *matCellDef="let element" class="text-nowrap">
          <profile-avatar-with-name [userDetail]="element" [isRowNameWithLogo]="true"></profile-avatar-with-name>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">Start</th>
        <td mat-cell *matCellDef="let element" class="text-nowrap">
          <div>
            <div>{{element.startDate}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap">End</th>
        <td mat-cell *matCellDef="let element" class="text-nowrap">
          <div>
            <div>{{element.endDate}} </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="commentWidth">Comments</th>
        <td mat-cell *matCellDef="let element" class="commentWidth text-truncate text-nowrap">
          <span title="{{element.comment}}">{{element.comment}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="leaveStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">

          <span class="badge bg-success" *ngIf="element.leaveStatus.toLowerCase() === 'approved'">{{element.leaveStatus | capitalize}}</span>
          <span class="badge bg-danger" *ngIf="element.leaveStatus.toLowerCase() === 'rejected'">{{element.leaveStatus | capitalize}}</span>
          
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="(!service.common.progressLoader && !service.common.infiniteLoader) && distributionData.length === 0" class="p-3 noList">Processed leaves are not available</div>
    </div>

    <!-- <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div> -->
    
  </div>
</div>