import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SkillsService } from '../skills.service';
import { CommonService } from 'src/app/shared/common.service';
//import { AddskillsComponent } from '../addskills/addskills.component';
import { TranslateService } from '@ngx-translate/core';
import { debounce } from 'lodash';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss']
})
export class SkillListComponent implements OnInit, OnDestroy {

  isSkillEdit: boolean = false;
  skillData: any;
  skillList = [];
  workerList;
  displayedColumns: string[] = ['skillName', 'actions'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  page = 0;
  limit = 30;
  isShowRefresh: boolean = true;
  hasPermission:IBtnControlsPermission ;
  private clearObs = new Subject<any>();
  
  constructor(public dialog: MatDialog, public service: SkillsService, public common: CommonService,
    private translate: TranslateService

  ) {
    this.translate.setDefaultLang(this.service.storage.get('language'));
    this.applyFilter = debounce(this.applyFilter, 500);
  }
  ngOnInit() {
    this.getSkillList(this.page, this.limit);  
    this.hasPermission =  this.service.common.getBtnEventPermissions("Skills");  
    this.subToPermissionChanged();
  }

  skillChangeEvent(event: any) {
    // //console.log('skillChangeEvent: ', event);
    if(event){
    if(event === 'success') {
      this.isSkillEdit = false;
      this.skillData = null;
        this.page = 0;
        this.getSkillList(this.page, this.limit);
    } else {
      this.isSkillEdit = false;
      this.skillData = null;
    }
  }
  }

  /* Name:Shailesh
      use: for getting skill list by org code */
  getSkillList(page, limit, scroll = null) {
    try {
      if (!scroll && this.isShowRefresh) {
        this.service.common.progressLoader = true;
      } else if (scroll) {
        this.service.common.infiniteLoader = true;
      }
      this.service.getSkillsByOrganizationCodeByPage(this.service.storage.get('orgCode'), page, limit, (data) => {
        //////console.log(data);
        if (data.responsecode == 200) {

          if (scroll) {
            let arrayData = this.dataSource.data;
            data.data.forEach(element => {
              arrayData.push(element);
            });

            setTimeout(() => {
              this.skillList = arrayData;
              this.dataSource = new MatTableDataSource(this.skillList);
              // Apply the sorting accessor for case-insensitive sorting
              this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
              this.dataSource.sort = this.sort;
              this.service.common.infiniteLoader = false;

            }, 1000);

          } else {
            this.skillList = data.data;
            //////console.log(this.skillList);
            this.dataSource = new MatTableDataSource(this.skillList);
            // Apply the sorting accessor for case-insensitive sorting
            this.dataSource.sortingDataAccessor = this.service.common.globalSortingDataAccessorForMatSort;
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }

        } else {          
          if (!scroll) {
            this.dataSource = new MatTableDataSource();
            this.skillList = [];
            this.service.common.progressLoader = false;
          } else {
            this.service.common.infiniteLoader = false;
          }
        }
      });
    } catch (error) {
      //console.log(error)
    }

  }

  /* Name:Shailesh
      use: for open skill add popup */
  addSkills(): void {
    this.isSkillEdit = true;
    this.skillData = null;
    // const dialogRef = this.dialog.open(AddskillsComponent, {
    //   width: '500px',
    //   disableClose: true,
    //   data: {}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result != undefined && result ==='success') {
    //     this.isShowRefresh = false;
    //     this.page = 0;
    //     this.getSkillList(this.page, this.limit);
    //   }
    // });
  }

      /* Name:Vivek Chauhan new API
  use: for delete/remove Skill */
  softDeleteOrganizationSkillBySkillCode(skill :any){
    // //console.log('softDeleteOrganizationSkillBySkillCode: ', skill);
    // return;
    if(skill.skillCode){ 

    const dialog = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { 
        msg: 'Are you sure want to delete this skill?'
      }
    });      

    dialog.afterClosed().subscribe(result => {    
      if(result && result.toLowerCase() === 'ok'){ 
        this.service.common.progressLoader = true;
        this.service.softDeleteOrganizationSkillBySkillCode(skill.skillCode, (response) => {
          this.service.common.progressLoader = false;
           
          this.service.common.message = response.message;
           
          if (response.responsecode == 200) 
          {
            this.service.common.successMessage = true;
            this.page = 0;
            this.getSkillList(this.page, this.limit);
          } 
          else{
            this.service.common.errorMessage = true;
          }
        });
      }
    });
  }
  }

  /* Name:Shailesh
      use: for open edit skill popup*/
  onEdit(data) {
    this.isSkillEdit = true;
    this.skillData = data;
    // //console.log('onEditData:', data);
    // const dialogRef = this.dialog.open(AddskillsComponent, {
    //   width: '500px',
    //   disableClose: true,
    //   data: data
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result != undefined && result ==='success') {
    //     this.isShowRefresh = false;
    //     this.page = 0;
    //     this.getSkillList(this.page, this.limit);
    //   }
    // });
  }

  /* Name:Shailesh
      use: for infinite scroll */
  onScroll() {
    //////console.log('scroll');
    this.page = this.page + 1;
    this.getSkillList(this.page, this.limit, 'scroll');
  }

  /* Name:Shailesh
      use: for seraching skill name */
  applyFilter(searchText: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    ////console.log( this.dataSource);
    this.page = 0;
    this.service.common.progressLoader = true;
  try {
    if(searchText && searchText.trim()){
    this.service.getSkillsByOrganizationCodeByPageSearch(this.service.storage.get('orgCode'), searchText, (response) => {
      if(response.responsecode === 200){
          setTimeout(() => {
            this.skillList = response.data;
            this.dataSource = new MatTableDataSource(this.skillList);
            this.dataSource.sort = this.sort;
            this.service.common.progressLoader = false;
          }, 1000);
      } else {
          this.skillList = [];
          setTimeout(() => {
            this.dataSource = new MatTableDataSource();
            this.dataSource.sort = this.sort;
          }, 1000);
        this.service.common.progressLoader = false;
      }
  });
  } else {
    this.getSkillList(this.page, this.limit);
  } 
  } catch (error) {
    //console.log(error);
  }
  }


  subToPermissionChanged()
{
 this.common.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this.common.getBtnEventPermissions("Skills");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }



}
