<div class="snackNotification" fxLayout="row wrap">
  
  <mat-icon (click)="onDismiss()" class="cursor-pointer">close</mat-icon>
  <!-- <div class="row" (click)="redirectUser()" > -->
    <div class="row" >
    <div fxFlex="20" class="carimage col-md-2">
      <div class="carstyle">
        <img src="assets/gig_loader.png">
      </div>
    </div>
     
    <div class="col-md-8" fxFlex="60">
      <h5>New Message</h5>
      <p>{{message}}</p> 
    </div>
    
    <div *ngIf="appointmentsDeatilsList.length>0" class="col-md-2"  fxFlex="20"> 
      <i class="material-icons menu-icon cursor-pointer" (click)="openPatient()"> list </i>
    </div>
    </div>
 