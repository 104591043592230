<div class="user-preferences StickyPosition position-relative">
  <div class="row">
    <form [formGroup]="UserPreferences_Form">
      <div class="row">
        <div class="accordian-description">

          <div class="row position-sticky">
            <div class="col-6 text-start">
              <h4 class="header-name">
                Organization Working hours
              </h4>
            </div>

            <ng-template [ngIf]="hasPermission.editPermission === true">
            <div class="col-6 text-end">
              <button mat-raised-button color="primary"
               (click)="UpdateUserPreferences()"
               [disabled]="isLoading">
               <span  *ngIf="isLoading" class="spinner-border spinner-border-sm"></span>&nbsp;
               Update Setting</button>
            </div>
            </ng-template>

            <div>
              <hr>
            </div>
          </div>

          <div class="form-group">
            <div class="orange-title">
              {{ 'setting.labels.Office Days' | translate}}
            </div>
            <div class="days">
              <mat-checkbox color="primary" formControlName="offmon" (change)="OfficeDaysValid($event, 'mon')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[11].globalSettingName == 'office.days.mon'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[11].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[11].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[11].globalSettingValue)==='true'">
                Mon</mat-checkbox>
              <mat-checkbox color="primary" formControlName="offtue" (change)="OfficeDaysValid($event, 'tue')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[12].globalSettingName == 'office.days.tue'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[12].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[12].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[12].globalSettingValue)==='true'">
                Tue</mat-checkbox>
              <mat-checkbox color="primary" formControlName="offwed" (change)="OfficeDaysValid($event, 'wed')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[13].globalSettingName == 'office.days.wed'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[13].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[13].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[13].globalSettingValue)==='true'">
                Wed</mat-checkbox>
              <mat-checkbox color="primary" formControlName="offthu" (change)="OfficeDaysValid($event, 'thu')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[14].globalSettingName == 'office.days.thu'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[14].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[14].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[14].globalSettingValue)==='true'">
                Thu</mat-checkbox>
              <mat-checkbox color="primary" formControlName="offfri" (change)="OfficeDaysValid($event, 'fri')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[15].globalSettingName == 'office.days.fri'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[15].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[15].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[15].globalSettingValue)==='true'">
                Fri</mat-checkbox>
              <mat-checkbox color="primary" formControlName="offsat" (change)="OfficeDaysValid($event, 'sat')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[16].globalSettingName == 'office.days.sat'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[16].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[16].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[16].globalSettingValue)==='true'">
                Sat</mat-checkbox>
              <mat-checkbox color="primary" formControlName="offsun" (change)="OfficeDaysValid($event, 'sun')"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[17].globalSettingName == 'office.days.sun'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[17].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[17].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[17].globalSettingValue)==='true'">
                Sun</mat-checkbox>
            </div>

            <span class="help-block"
              *ngIf="UserPreferences_Form.get('officeDays').errors?.required && (isForm_submitted || UserPreferences_Form.get('officeDays').touched)">
              {{ 'setting.validators.Please select atleast one Office day' | translate}}.
            </span>

          </div>

          <hr>

          <div class="form-group">
            <div class="orange-title">
              {{ 'setting.labels.Office Time' | translate}}
            </div>
            <div class="time-fields clearfix">


              <div class="form-group col-md-4 ps-0 float-start">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{ 'setting.labels.Start Time' | translate}}</mat-label>
                  <input matInput formControlName="office_starttime"
                    (keyup)="daysOfOperation_startTimeKeyUp(UserPreferences_Form.controls.office_starttime.value.trim())"
                    [matAutocomplete]="office_starttime_autocomplete"
                    (blur)="blur_daysOfOperation(UserPreferences_Form.controls.office_starttime.value.trim())"
                    style="text-transform: uppercase">
                  <mat-icon matSuffix>access_time</mat-icon>
                </mat-form-field>
                <mat-autocomplete #office_starttime_autocomplete>
                  <mat-option *ngFor="let time of daysOfOperation_startTime_Filter " [value]="time">
                    <span>{{ time }} </span>
                  </mat-option>
                </mat-autocomplete>
                <span class="help-block err" *ngIf="UserPreferences_Form.controls.office_starttime.errors?.required && 
                     (isForm_submitted || UserPreferences_Form.controls.office_starttime.touched)"> this filed is
                  required
                </span>
                <span class="help-block err" *ngIf="UserPreferences_Form.controls.office_starttime.errors?.pattern && 
                     (isForm_submitted || UserPreferences_Form.controls.office_starttime.touched)">Expected format:
                  H:mm AM|PM
                </span>
              </div>


              <div class="form-group col-md-4 ps-0 float-start">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{ 'setting.labels.End Time' | translate}}</mat-label>
                  <input matInput formControlName="office_endtime"
                    (keyup)="daysOfOperation_endTimeKeyUp(UserPreferences_Form.controls.office_endtime.value.trim())"
                    [matAutocomplete]="office_endtime_autocomplete" style="text-transform: uppercase">
                  <mat-icon matSuffix>access_time</mat-icon>
                </mat-form-field>
                <mat-autocomplete #office_endtime_autocomplete>
                  <mat-option *ngFor="let time of daysOfOperation_endTime_Filter" [value]="time">
                    <span>{{ time }} </span>
                  </mat-option>
                </mat-autocomplete>
                <span class="help-block err" *ngIf="UserPreferences_Form.controls.office_endtime.errors?.required && 
                     (isForm_submitted || UserPreferences_Form.controls.office_endtime.touched)"> this filed is
                  required
                </span>
                <span class="help-block err" *ngIf="UserPreferences_Form.controls.office_endtime.errors?.pattern && 
                     (isForm_submitted || UserPreferences_Form.controls.office_endtime.touched)">Expected format: H:mm
                  AM|PM
                </span>
              </div>

            </div>
          </div>

          <hr>

          <div class="form-group">
            <div class="orange-title">
              {{ 'setting.labels.Operational Days' | translate}}
            </div>
            <div class="days">

              <mat-checkbox color="primary" formControlName="mon" (change)="OperationalDaysValid($event)"
                [disabled]="mon"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[0].globalSettingName == 'Working.Days.Mon'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[0].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[0].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[0].globalSettingValue)==='true'">
                Mon</mat-checkbox>
              <mat-checkbox color="primary" formControlName="tue" (change)="OperationalDaysValid($event)"
                [disabled]="tue"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[1].globalSettingName == 'Working.Days.Tue'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[1].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[1].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[1].globalSettingValue)==='true'">
                Tue</mat-checkbox>
              <mat-checkbox color="primary" formControlName="wed" (change)="OperationalDaysValid($event)"
                [disabled]="wed"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[2].globalSettingName == 'Working.Days.Wed'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[2].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[2].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[2].globalSettingValue)==='true'">
                Wed</mat-checkbox>
              <mat-checkbox color="primary" formControlName="thu" (change)="OperationalDaysValid($event)"
                [disabled]="thu"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[3].globalSettingName == 'Working.Days.thu'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[3].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[3].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[3].globalSettingValue)==='true'">
                Thu</mat-checkbox>
              <mat-checkbox color="primary" formControlName="fri" (change)="OperationalDaysValid($event)"
                [disabled]="fri"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[4].globalSettingName == 'Working.Days.Fri'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[4].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[4].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[4].globalSettingValue)==='true'">
                Fri</mat-checkbox>
              <mat-checkbox color="primary" formControlName="sat" (change)="OperationalDaysValid($event)"
                [disabled]="sat"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[5].globalSettingName == 'Working.Days.Sat'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[5].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[5].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[5].globalSettingValue)==='true'">
                Sat</mat-checkbox>
              <mat-checkbox color="primary" formControlName="sun" (change)="OperationalDaysValid($event)"
                [disabled]="sun"
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[6].globalSettingName == 'Working.Days.Sun'"
                class="day-name"
                [ngModel]="(_systemSettingsSVC.settings[3].UserPreferences[6].localSettingValue != null?_systemSettingsSVC.settings[3].UserPreferences[6].localSettingValue:_systemSettingsSVC.settings[3].UserPreferences[6].globalSettingValue)==='true'">
                Sun</mat-checkbox>
            </div>
            <span class="help-block"
              *ngIf="UserPreferences_Form.get('operationalDays').errors?.required && (isForm_submitted || UserPreferences_Form.get('operationalDays').touched)">{{
              'setting.validators.Please select atleast one Operational day' | translate}} .
            </span>
          </div>

          <hr>

          <div class="form-group">
            <div class="orange-title">
              {{ 'setting.labels.Operational Time' | translate}}
            </div>
            <div class="time-fields clearfix">

              <div class="form-group col-md-4 ps-0 float-start">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{ 'setting.labels.Start Time' | translate}}</mat-label>
                  <input matInput formControlName="user_starttime"
                    (keyup)="hoursOfOperation_startTimeKeyUp(UserPreferences_Form.controls.user_starttime.value.trim())"
                    [matAutocomplete]="user_starttime_autocomplete"
                    (blur)="blur_hoursOfOperation(UserPreferences_Form.controls.user_starttime.value.trim())"
                    style="text-transform: uppercase">
                  <mat-icon matSuffix>access_time</mat-icon>
                </mat-form-field>
                <mat-autocomplete #user_starttime_autocomplete>
                  <mat-option *ngFor="let time of hoursOfOperation_startTime_Filter " [value]="time">
                    <span>{{ time }} </span>
                  </mat-option>
                </mat-autocomplete>
                <span class="help-block err" *ngIf="UserPreferences_Form.controls.user_starttime.errors?.pattern && 
                     (isForm_submitted || UserPreferences_Form.controls.user_starttime.touched)">Expected format: H:mm
                  AM|PM
                </span>
              </div>


              <div class="form-group col-md-4 ps-0 float-start">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{ 'setting.labels.End Time' | translate}}</mat-label>
                  <input matInput formControlName="user_endtime"
                    (keyup)="hoursOfOperation_endTimeKeyUp(UserPreferences_Form.controls.user_endtime.value.trim())"
                    [matAutocomplete]="user_endtime_autocomplete" style="text-transform: uppercase">
                  <mat-icon matSuffix>access_time</mat-icon>
                </mat-form-field>
                <mat-autocomplete #user_endtime_autocomplete>
                  <mat-option *ngFor="let time of hoursOfOperation_endTime_Filter " [value]="time">
                    <span>{{ time }} </span>
                  </mat-option>
                </mat-autocomplete>
                <span class="help-block err" *ngIf="UserPreferences_Form.controls.user_endtime.errors?.pattern && 
                     (isForm_submitted || UserPreferences_Form.controls.user_endtime.touched)">Expected format: H:mm
                  AM|PM
                </span>
              </div>

            </div>
          </div>

          <hr>

          <div class="form-group">
            <div class="orange-title">
              {{ 'setting.labels.Specific Non Working Days' | translate}}
            </div>
            <div class="form-group col-md-4 p-0">
              <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
                <mat-label>{{ 'setting.labels.Select Date' | translate}}</mat-label>
                <input matInput [min]="todayDate" #nonworkingday (dateChange)="nonWorkingDays($event)"
                  formControlName="workingDays" [matDatepicker]="picker" (focus)="picker.open()"
                  placeholder="{{ 'setting.labels.Choose a date' | translate}}">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker (closed)="nonworkingday.blur()"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-chip-list
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[10].globalSettingName == 'Specific.Non.Working.Days' && _systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue == null"
                formControlName="nonworking_days"
                [(ngModel)]="_systemSettingsSVC.settings[3].UserPreferences[10].globalSettingValue">
                <ng-container
                  *ngFor="let date of _systemSettingsSVC.settings[3].UserPreferences[10].globalSettingValue.split(','); let i=index">
                  <mat-chip *ngIf="date">
                    <span>{{date}}</span> <a (click)="onRemoveChip(i)">
                      <mat-icon>close</mat-icon>
                    </a></mat-chip>
                </ng-container>
              </mat-chip-list>
              <mat-chip-list
                *ngIf="_systemSettingsSVC.settings && _systemSettingsSVC.settings[3].UserPreferences[10].globalSettingName == 'Specific.Non.Working.Days' && _systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue != null">
                <ng-container
                  *ngFor="let date of _systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue.split(','); let i=index">
                  <mat-chip *ngIf="date">
                    <span>{{date}}</span> <a (click)="onRemoveChip(i)">
                      <mat-icon>close</mat-icon>
                    </a></mat-chip>
                </ng-container>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>



    </form>

  </div>
</div>