<div class="w-100">
  <div class="btn_generate_pdf" *ngIf="dataToPrint.length > 0">
    <button *ngIf="!isGeneratingPDF" type="button" mat-raised-button color="accent" class="col-auto"
      (click)="generatePDF()">Roster Print</button>
    <ng-template [ngIf]="isGeneratingPDF">
      <span class="spinner-border spinner-border-sm"></span> &nbsp;
      <span>Generating PDF...</span>
    </ng-template>
  </div>

  <!-- <ng-template [ngIf]="s_staffList?.jobDurationDetailsList?.length > 0" [ngIfElse]="else_temp">
     
      <div id="tbl_report">
      
      <div class="border-bottom" [style.border-color]="s_staffList.jobColor" id="shift-details">
        <div class="row">
        <div class="col-md-9">
      <app-cust-short-job-type-dts [shjobTypeDtsInput]="{
                        date         : s_staffList?.jobDateFixedFormat ,
                        customerName : s_staffList.customerName,
                        siteName     : s_staffList.siteName,  
                        siteAddress  : s_staffList.siteAddress, 
                        jobTypeName  : s_staffList.jobTypeName,
                        jobTypeIcon  : s_staffList.jobTypeIcon,
                        rateCard     : s_staffList.showRateCard,  
                        jobTypeColor : s_staffList.jobColor    
                      }">
        </app-cust-short-job-type-dts>

        <div class="div-border mt-1 mb-1"></div>

        <app-shift-with-job-type
        [shiftWithJobTypeInput]="{
             jobTypeWithShiftList : s_staffList,
             customerName         : s_staffList.customerName ,
             siteName             : s_staffList.siteName ,
             siteAddress          : s_staffList.siteAddress ,
             appointmentsCode     : s_staffList.appointmentsCode,
             customerCode         : s_staffList.customerCode,
             siteAddressCode      : s_staffList.siteAddressCode,
             jobDateFixedFormat   : s_staffList.jobDateFixedFormat,
             date                 : s_staffList.jobDateFixedFormat
        }"

        [status]="status"
        [allowScheduledStaff_Icon]="false"
        [allowActions]="{
              allowEdit: false,
              allowDelete: false,
              allowAssignStaff: false,
              allowNotifyStaff: false,
              allowToViewNotifiedStaff: false,
              allowToCopyJob: false,
              allowView: false,
              allowRosterPrint : false,
              allowViewInfo : false,
              allowToAddScheduledStaff : false
        }"
        [allowBreaks]="false"
        [showClockInPIN]="false">
       </app-shift-with-job-type>
       </div>
       <div class="col-md-3">
        <div class="d-flex"> 
          <small>Clock-In PIN&nbsp;:&nbsp;</small>  <span><b>{{s_staffList.clockinPin ? s_staffList.clockinPin : 'NA'}}</b></span>
        </div>

          <ng-template [ngIf]="s_staffList.clockinPin">
            <qrcode 
            #qrCodeElement
            [qrdata]="s_staffList.clockinPin.toString()" 
            [width]="140"
             [errorCorrectionLevel]="'M'"
             [elementType]="'canvas'"></qrcode>
            </ng-template>

       </div>
      </div>
     </div>
  
        <div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50" (scrolled)="onScrollStaff()" [scrollWindow]="false" [fromRoot]="true">
          <div class="table-scroll-height">
          <ng-container *ngFor="let staff of s_staffList.jobDurationDetailsList">
            <div class="row w-100 m-0">
            <admin-scheduled-staff-card [showActionButtons]="false" [allowToUpdateTime]="false" [scheduleStaff_Input_Data]="staff" (emitter_refreshList)="refreshData($event)">
            </admin-scheduled-staff-card>
          </div>
          </ng-container>
        </div>
        </div>

      </div>
      </ng-template> -->


  <ng-template [ngIf]="rosterPrintData" [ngIfElse]="else_temp">

    <div class="row">


      <div class="col-1">
        <div class="row">
          <div class="card-jobdate" [style.color]="rosterPrintData?.jobTypeColor">
            <span>{{rosterPrintData?.week_day}}</span>
            <span class="month-day">{{rosterPrintData?.month_day}}</span>
            <span>{{rosterPrintData?.month_name}}</span>
          </div>
        </div>
      </div>


      <div class="col-3">
        <small>Customer Name </small><br>
        <p>{{rosterPrintData.customerName}}</p>
      </div>


      <div class="col-2">
        <small>{{ 'main.labels.Job Site' | translate }} </small><br>
        <p> {{rosterPrintData.siteName}}</p>
      </div>

      <div class="col-2">
        <small> Shift</small><br>
        <p> {{rosterPrintData.startTime}}</p>
      </div>

      <div *ngIf="rosterPrintData?.clockinPin" class="col-4 d-flex">
        <div>
          <small> Clock-In PIN</small><br>
          <p class="clock_in_pin"> {{rosterPrintData.clockinPin ? rosterPrintData.clockinPin : 'NA'}}</p>
        </div>

        <ng-template [ngIf]="rosterPrintData.clockinPin">
          <div class="qr-pos">
            <qrcode #qrCodeElement [qrdata]="rosterPrintData.clockinPin.toString()" [width]="100"
              [errorCorrectionLevel]="'M'" [elementType]="'canvas'">
            </qrcode>
          </div>
        </ng-template>

      </div>

    </div>

    <div class="col-md-12 shift-roster-table">
     <table class="table table-bordered">
        <thead>
          <tr>
             <th scope="col">Name</th>
            <th scope="col">Role</th>
            <!-- <th scope="col">Clock-in Time</th>
            <th scope="col">Clock-out Time</th>
            <th scope="col">Comments</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let parent_element of rosterPrintData.jobTypeShiftDetailsList">
            <ng-container *ngFor="let child_element of parent_element.staffList">
              <tr>
                <td>{{child_element.workerName}} </td>
                <td>{{parent_element.jobTypeName}} </td>
                <!-- <td> </td>
                <td> </td>
                <td> </td> -->
              </tr>
            </ng-container>
          </ng-container>

        </tbody>
      </table>

    </div>

  </ng-template>


  <ng-template #else_temp>
    <div class="row d-flex align-items-center spinner" *ngIf="isLoading">
      <div class="col-lg-1 text-end">
        <mat-spinner></mat-spinner>
      </div>
      <div class="col-lg-8 text-start"><span>Please Wait ...</span></div>
    </div>

    <div class="row" *ngIf="!isLoading && !rosterPrintData">
      <div>
        <span class="text-danger">No Record Available</span>
      </div>
    </div>
  </ng-template>


</div>