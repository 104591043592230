import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { CommonService } from 'src/app/shared/common.service';
import { SystemSettingsService } from '../system-settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { Subject, takeUntil } from 'rxjs';
import { IPagePermissionResponse } from 'src/app/shared/interface/login.interface';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  providers:[SystemSettingsService,DatePipe]
  
})
export class UserPreferencesComponent implements OnInit , OnDestroy {

  UserPreferences_Form : FormGroup ;
  todayDate = new Date();
  dateFormat;
  nonWorkingDaysSplitLocal:any[] = [];
  isForm_submitted : boolean = false;

  daysOfOperation_startTime_Filter:any[] = this._commonSVC.time12Hrs;
  daysOfOperation_endTime:any[] = this._commonSVC.time12Hrs; 
  daysOfOperation_endTime_Filter:any[] = this._commonSVC.time12Hrs;

  
  hoursOfOperation_startTime_Filter:any[] = this._commonSVC.time12Hrs;
  hoursOfOperation_endTime:any[] = this._commonSVC.time12Hrs; 
  hoursOfOperation_endTime_Filter:any[] = this._commonSVC.time12Hrs;

  sun = true;
  mon = true;
  tue = true;
  wed = true;
  thu = true;
  fri = true;
  sat = true;

  Officechecked;
  Operationalchecked;

hasPermission:IBtnControlsPermission ;

 isLoading:boolean = false;
 private clearObs = new Subject<any>();
 
constructor(
  private _translateSVC: TranslateService,
  private _sessionStorageSVC: SessionStorageService,
  private _commonSVC: CommonService,
  public _systemSettingsSVC : SystemSettingsService,
  private datepipe: DatePipe,
)
{
  this._translateSVC.setDefaultLang(this._sessionStorageSVC.get('language'));
  this.initUserPreference_Form();
}

  ngOnInit(): void {
    this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
    this.hasPermission =  this._commonSVC.getBtnEventPermissions("Organization Working hours Settings"); 
    this.subToPermissionChanged();
    }

 initUserPreference_Form()
{
 
  this.UserPreferences_Form = new FormGroup({

    'offmon': new FormControl(null),
    'offtue': new FormControl(null),
    'offwed': new FormControl(null),
    'offthu': new FormControl(null),
    'offfri': new FormControl(null),
    'offsat': new FormControl(null),
    'offsun': new FormControl(null),
    'office_starttime': new FormControl(null, [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)]),
    'office_endtime': new FormControl(null, [Validators.required, Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)]),
    'operationalDays': new FormControl(null, Validators.required),
    'officeDays': new FormControl(null, Validators.required),
    'mon': new FormControl(null),
    'tue': new FormControl(null),
    'wed': new FormControl(null),
    'thu': new FormControl(null),
    'fri': new FormControl(null),
    'sat': new FormControl(null),
    'sun': new FormControl(null),
    'user_starttime': new FormControl(null , Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)),
    'user_endtime': new FormControl(null ,Validators.pattern(/^(0?[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/i)),
    'workingDays': new FormControl(null),
    'nonworking_days2': new FormControl(null),
    'nonworking_days': new FormControl(null),

  })
 }


 getAllsettings(orgCode) {
  this._commonSVC.progressLoader = true;
  try {
    this.isLoading = true ;
    this._systemSettingsSVC.getAllgetAllLocalSettingWithDefaultGlobalSettingByUserCode(orgCode).subscribe((data:any) => {
      this.isLoading = false ;
      if (data.responsecode == 200) 
      {
      this._systemSettingsSVC.settings = data.data;
      // //console.log('UpdateSettings_4: ', this._systemSettingsSVC.settings);

      if (!this._systemSettingsSVC.settings[3].UserPreferences[0].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[1].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[2].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[3].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[4].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[5].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[6].globalSettingValue) {
        ////////console.log(this.Operationalchecked + '.....if');
        this.Operationalchecked = false;
      } else {
        this.Operationalchecked = true;
        ////////console.log(this.Operationalchecked + '.....else');
        this.UserPreferences_Form.controls['operationalDays'].setValue(this.Operationalchecked);
      }


      if (!this._systemSettingsSVC.settings[3].UserPreferences[11].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[12].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[13].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[14].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[15].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[16].globalSettingValue && !this._systemSettingsSVC.settings[3].UserPreferences[17].globalSettingValue) {
        ////////console.log(this.Officechecked + '.....if');
        this.Officechecked = false;
      } else {
        this.Officechecked = true;
        ////////console.log(this.Officechecked + '.....else');
        this.UserPreferences_Form.controls['officeDays'].setValue(this.Officechecked);
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue == null) {
        this.UserPreferences_Form.controls['nonworking_days2'].setValue(this._systemSettingsSVC.settings[3].UserPreferences[10].globalSettingValue);
        this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue = this._systemSettingsSVC.settings[3].UserPreferences[10].globalSettingValue;
      } else {
        this.UserPreferences_Form.controls['nonworking_days2'].setValue(this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue);
      }
 

      if (this._systemSettingsSVC.settings[3].UserPreferences[11].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[11].localSettingValue == 'true') {
        this.mon = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[11].globalSettingValue == 'true') {
        this.mon = false;
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[12].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[12].localSettingValue == 'true') {
        this.tue = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[12].globalSettingValue == 'true') {
        this.tue = false;
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[13].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[13].localSettingValue == 'true') {
        this.wed = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[13].globalSettingValue == 'true') {
        this.wed = false;
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[14].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[14].localSettingValue == 'true') {
        this.thu = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[14].globalSettingValue == 'true') {
        this.thu = false;
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[15].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[15].localSettingValue == 'true') {
        this.fri = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[15].globalSettingValue == 'true') {
        this.fri = false;
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[16].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[16].localSettingValue == 'true') {
        this.sat = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[16].globalSettingValue == 'true') {
        this.sat = false;
      }

      if (this._systemSettingsSVC.settings[3].UserPreferences[17].localSettingValue != null && this._systemSettingsSVC.settings[3].UserPreferences[17].localSettingValue == 'true') {
        this.sun = false;
      } else if (this._systemSettingsSVC.settings[3].UserPreferences[17].globalSettingValue == 'true') {
        this.sun = false;
      }


      
        // office time
       this.UserPreferences_Form.controls.office_starttime.setValue(
         this._systemSettingsSVC.settings[3].UserPreferences[18].localSettingValue != null
         ? this._systemSettingsSVC.settings[3].UserPreferences[18].localSettingValue
         : this._systemSettingsSVC.settings[3].UserPreferences[18].globalSettingValue
       )
     
       this.UserPreferences_Form.controls.office_endtime.setValue(
         this._systemSettingsSVC.settings[3].UserPreferences[19].localSettingValue != null
         ? this._systemSettingsSVC.settings[3].UserPreferences[19].localSettingValue
         : this._systemSettingsSVC.settings[3].UserPreferences[19].globalSettingValue
       )
     
       this.blur_daysOfOperation(this.UserPreferences_Form.controls.office_starttime.value.toUpperCase());
     
     
         // hours of operation
        this.UserPreferences_Form.controls.user_starttime.setValue(
         this._systemSettingsSVC.settings[3].UserPreferences[7].localSettingValue != null
         ? this._systemSettingsSVC.settings[3].UserPreferences[7].localSettingValue
         : this._systemSettingsSVC.settings[3].UserPreferences[7].globalSettingValue
        )
     
        this.UserPreferences_Form.controls.user_endtime.setValue(
         this._systemSettingsSVC.settings[3].UserPreferences[8].localSettingValue != null
         ? this._systemSettingsSVC.settings[3].UserPreferences[8].localSettingValue
         : this._systemSettingsSVC.settings[3].UserPreferences[8].globalSettingValue
        )
     
        this.blur_hoursOfOperation(this.UserPreferences_Form.controls.user_starttime.value.toUpperCase());
     
       

/********************************************************************************************************************************* */


      this._systemSettingsSVC.settings.forEach(element => {
        // //////console.log(element);
        if (element.AdministratorSetting) {
          element.AdministratorSetting.forEach(newelement => {
            newelement.userCode = orgCode;
          });
        }
        if (element.NotificationSetting) {
          element.NotificationSetting.forEach(newelement => {
            newelement.userCode = orgCode;
          });
        }
        if (element.SchedulePreferences) {
          element.SchedulePreferences.forEach(newelement => {
            newelement.userCode = orgCode;
          });
        }
        if (element.UserPreferences) {
          element.UserPreferences.forEach(newelement => {
            newelement.userCode = orgCode;
          });
        }
      });

      

      this._commonSVC.progressLoader = false;
    }
    
    else {
      this._commonSVC.progressLoader = false;
    }
  })
  } catch (error) {
    this._commonSVC.progressLoader = false;
    //console.log(error) 
  }
  
}


daysOfOperation_startTimeKeyUp(value:any) { 
  this.daysOfOperation_startTime_Filter = this._commonSVC.time12Hrs.filter(ele =>  ele.toLowerCase().includes(value.toLowerCase()));
}

daysOfOperation_endTimeKeyUp(value:any) { 
this.daysOfOperation_endTime_Filter = this.daysOfOperation_endTime.filter(ele =>  ele.toLowerCase().includes(value.toLowerCase()));
}

blur_daysOfOperation(startTime:string)
{
   let list = this._commonSVC.setEndTimeArray(startTime);
  this.daysOfOperation_endTime = list.length > 0 ? list : this.daysOfOperation_endTime ;
  this.daysOfOperation_endTime_Filter = Array.from(this.daysOfOperation_endTime);

}


hoursOfOperation_startTimeKeyUp(value:any) { 
  this.hoursOfOperation_startTime_Filter = this._commonSVC.time12Hrs.filter(ele =>  ele.toLowerCase().includes(value.toLowerCase()));
}

hoursOfOperation_endTimeKeyUp(value:any) { 
this.hoursOfOperation_endTime_Filter = this.hoursOfOperation_endTime.filter(ele =>  ele.toLowerCase().includes(value.toLowerCase()));
}


blur_hoursOfOperation(startTime:string)
{
   let list = this._commonSVC.setEndTimeArray(startTime);
  this.hoursOfOperation_endTime = list.length > 0 ? list : this.hoursOfOperation_endTime ;
  this.hoursOfOperation_endTime_Filter = Array.from(this.hoursOfOperation_endTime);

}


nonWorkingDays(event) {
  //////console.log(event.value);
  this.dateFormat = this.datepipe.transform(event.value, 'dd MMMM yyyy');
  // this.chipsDate.push(this.dateFormat);

  if (this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue != null) {
    this.nonWorkingDaysSplitLocal = this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue.split(',');
  }

  if (this.nonWorkingDaysSplitLocal.indexOf(this.dateFormat) == -1) {
    if (this._systemSettingsSVC.settings[3].UserPreferences[10].globalSettingName == 'Specific.Non.Working.Days' && this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue != null) {
      this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue += ',' + this.dateFormat;
    }
  }
  this.UserPreferences_Form.controls['nonworking_days2'].setValue(this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue);
 
}

onRemoveChip(index) {
   if (this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue != null) 
   {
    this.nonWorkingDaysSplitLocal = this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue.split(',');
    this.nonWorkingDaysSplitLocal.splice(index, 1);
    this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue = this.nonWorkingDaysSplitLocal.toString();
    this.UserPreferences_Form.controls['nonworking_days2'].setValue(this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue);
     
}

}


OfficeDaysValid(event, day) {
  ////////console.log(event.checked);
  if (event.checked) {
    //console.log(day);
    // if (day == 'sun') { this.sun = false; }
    // if (day == 'mon') { this.mon = false; }
    // if (day == 'tue') { this.tue = false; }
    // if (day == 'wed') { this.wed = false; }
    // if (day == 'thu') { this.thu = false; }
    // if (day == 'fri') { this.fri = false; }
    // if (day == 'sat') { this.sat = false; }
    this.Officechecked = true;
    this.UserPreferences_Form.controls['officeDays'].setValue(this.Officechecked);
  } 
  else
   {
    //this.UserPreferences_Form.controls[day].setValue(false);
    // if (day == 'sun') { this.sun = true; }
    // if (day == 'mon') { this.mon = true; }
    // if (day == 'tue') { this.tue = true; }
    // if (day == 'wed') { this.wed = true; }
    // if (day == 'thu') { this.thu = true; }
    // if (day == 'fri') { this.fri = true; }
    // if (day == 'sat') { this.sat = true; }

    if (!this.UserPreferences_Form.value.offmon && !this.UserPreferences_Form.value.offtue && !this.UserPreferences_Form.value.offwed && !this.UserPreferences_Form.value.offthu && !this.UserPreferences_Form.value.offfri && !this.UserPreferences_Form.value.offsat && !this.UserPreferences_Form.value.offsun) {
      ////////console.log(this.Officechecked + '.....if');
      this.Officechecked = false;
      this.UserPreferences_Form.controls['officeDays'].setValue(null);
    } else {
      this.Officechecked = true;
      ////////console.log(this.Officechecked + '.....else');
      this.UserPreferences_Form.controls['officeDays'].setValue(this.Officechecked);
    }
  }
 }


 OperationalDaysValid(event) {
  ////console.log(event);
  if (event.checked) {
    this.Operationalchecked = true;
    this.UserPreferences_Form.controls['operationalDays'].setValue(this.Operationalchecked);
  }
  else {
    if (!this.UserPreferences_Form.value.mon && !this.UserPreferences_Form.value.tue && !this.UserPreferences_Form.value.wed && !this.UserPreferences_Form.value.thu && !this.UserPreferences_Form.value.fri && !this.UserPreferences_Form.value.sat && !this.UserPreferences_Form.value.sun) {
      ////////console.log(this.Operationalchecked + '.....if');
      this.Operationalchecked = false;
      this.UserPreferences_Form.controls['operationalDays'].setValue(null);
    } else {
      this.Operationalchecked = true;
      ////////console.log(this.Operationalchecked + '.....else');
      this.UserPreferences_Form.controls['operationalDays'].setValue(this.Operationalchecked);
    }
  }
}


UpdateUserPreferences()
{
  this.isForm_submitted = true ;

  if(this.UserPreferences_Form.valid)
  {
    this.isLoading = true ;

    this._systemSettingsSVC.settings[3].UserPreferences[0].localSettingValue = this.UserPreferences_Form.value.mon;
    this._systemSettingsSVC.settings[3].UserPreferences[1].localSettingValue = this.UserPreferences_Form.value.tue;
    this._systemSettingsSVC.settings[3].UserPreferences[2].localSettingValue = this.UserPreferences_Form.value.wed;
    this._systemSettingsSVC.settings[3].UserPreferences[3].localSettingValue = this.UserPreferences_Form.value.thu;
    this._systemSettingsSVC.settings[3].UserPreferences[4].localSettingValue = this.UserPreferences_Form.value.fri;
    this._systemSettingsSVC.settings[3].UserPreferences[5].localSettingValue = this.UserPreferences_Form.value.sat;
    this._systemSettingsSVC.settings[3].UserPreferences[6].localSettingValue = this.UserPreferences_Form.value.sun;
    this._systemSettingsSVC.settings[3].UserPreferences[7].localSettingValue = this.UserPreferences_Form.value.user_starttime ? this._commonSVC.formateTime(this.UserPreferences_Form.value.user_starttime) : this.UserPreferences_Form.value.user_starttime;
    this._systemSettingsSVC.settings[3].UserPreferences[8].localSettingValue = this.UserPreferences_Form.value.user_endtime ? this._commonSVC.formateTime(this.UserPreferences_Form.value.user_endtime) : this.UserPreferences_Form.value.user_endtime;
    //this._systemSettingsSVC.settings[3].UserPreferences[9].localSettingValue = this.UserPreferences_Form.value.user_offhours;
    this._systemSettingsSVC.settings[3].UserPreferences[10].localSettingValue = this.UserPreferences_Form.value.nonworking_days2;

    this._systemSettingsSVC.settings[3].UserPreferences[11].localSettingValue = this.UserPreferences_Form.value.offmon;
    this._systemSettingsSVC.settings[3].UserPreferences[12].localSettingValue = this.UserPreferences_Form.value.offtue;
    this._systemSettingsSVC.settings[3].UserPreferences[13].localSettingValue = this.UserPreferences_Form.value.offwed;
    this._systemSettingsSVC.settings[3].UserPreferences[14].localSettingValue = this.UserPreferences_Form.value.offthu;
    this._systemSettingsSVC.settings[3].UserPreferences[15].localSettingValue = this.UserPreferences_Form.value.offfri;
    this._systemSettingsSVC.settings[3].UserPreferences[16].localSettingValue = this.UserPreferences_Form.value.offsat;
    this._systemSettingsSVC.settings[3].UserPreferences[17].localSettingValue = this.UserPreferences_Form.value.offsun;

    this._systemSettingsSVC.settings[3].UserPreferences[18].localSettingValue = this.UserPreferences_Form.value.office_starttime ? this._commonSVC.formateTime(this.UserPreferences_Form.value.office_starttime) : this.UserPreferences_Form.value.office_starttime;
    this._systemSettingsSVC.settings[3].UserPreferences[19].localSettingValue = this.UserPreferences_Form.value.office_endtime  ? this._commonSVC.formateTime(this.UserPreferences_Form.value.office_endtime) : this.UserPreferences_Form.value.office_endtime;
  

   let updateSettings:any[] =[];

    this._systemSettingsSVC.settings.forEach(element => {
      // //////console.log(element);
      if (element.AdministratorSetting) {
        element.AdministratorSetting.forEach(newelement => {
          updateSettings.push(newelement);
        });
      }
      if (element.NotificationSetting) {
        element.NotificationSetting.forEach(newelement => {
          updateSettings.push(newelement);
        });
      }
    
      if (element.UserPreferences) {
        element.UserPreferences.forEach(newelement => {
          updateSettings.push(newelement);
        });
      }
      if (element.Design) {
        element.Design.forEach(newelement => {
          updateSettings.push(newelement);
        });
      }
    });


    try {
      this._systemSettingsSVC.updateSettingByUserCode(this._sessionStorageSVC.get('orgCode'), updateSettings, (res) => {
       this.isLoading = false ;
      if (res.responsecode == 200) {
        this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
        this._commonSVC.successMessage = true;
        this.getAllsettings(this._sessionStorageSVC.get('orgCode'));
      //  this._commonSVC.getColors();
      } else {
        this._commonSVC.message = this._translateSVC.instant('setting.messages.' + res.message);
        this._commonSVC.errorMessage = true;
      }
    })
    } catch (error) {
      //console.log(error)
    }



  }

}



subToPermissionChanged()
{
 this._commonSVC.newPermissions.pipe(takeUntil(this.clearObs)).subscribe((res:IPagePermissionResponse)=>{
    
   if(res.responsecode === 200)
     {
       this.hasPermission =  this._commonSVC.getBtnEventPermissions("Organization Working hours Settings");
     }
 })
}

 ngOnDestroy(): void {
    this.clearObs.next(''),
    this.clearObs.complete();
  }



}
