 
<div class="d-flex flex-row justify-content-between w-100 mb-2 logo align-items-center">
    <div></div>
  
    <!-- <div class="col-lg-4 col-md-4 col-sm-12 searchfilter">
      <input matInput (keyup)="applyFilter($event.target.value)" type="text" autocomplete="false" class="form-control ps-2" autocomplete="off" placeholder="{{ 'JobType.labels.Search JobType' | translate }}">
      <mat-icon class="cursor-pointer">search</mat-icon>
    </div>  -->
  
    <ng-template [ngIf]="hasPermission.addPermission === true">
    <button class="me-5" mat-raised-button color="primary" (click)="openRoleFormPopUp(role_popup)">Add Role</button>
     </ng-template>

  </div>
  <div class="table-scroll-height" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false" [fromRoot]="true">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2 table-responsiv">
   
     <ng-container matColumnDef="roleName" class="dis-icon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Role.labels.Role Name' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
      </ng-container>
   
      <ng-container matColumnDef="roleProfile" class="dis-icon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Role.labels.Role Profile' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <span>{{element.systemRoleName}} </span></td>
      </ng-container>
  
      <ng-container matColumnDef="roleDescription" class="dis-icon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Role.labels.Role Description' | translate }} </th>
          <td mat-cell *matCellDef="let element"> <span>{{element.roleDescription}} </span> </td>
        </ng-container>
  
      <ng-container matColumnDef="action" class="dis-icon">
        <th mat-header-cell *matHeaderCellDef class="action"> </th>
        <td mat-cell *matCellDef="let element" class="action">
          <ng-template [ngIf]="hasPermission.editPermission === true">
          <i class="material-icons pointer" (click)="editRole(element , role_popup)">edit</i>
          </ng-template>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="roleList.length == 0" class="ms-3">{{ 'JobType.messages.Procedure data not available' | translate }}</div>
    </div>
    <!-- <div class="w-100" *ngIf="service.common.infiniteLoader">
      <div class="loadergif">
        <img src="assets/typint_dots_black.gif" alt="Loader">
        <p>Loading...</p>
      </div>
    </div> -->

  <ng-template #role_popup>
    <h2 matDialogTitle>{{roleform.controls.roleCode.value ? 'Update' : 'Add'}} Role</h2>
  <form [formGroup]="roleform">
   
  <div class="col-12 mt-2">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Select Role Profile</mat-label>
      <mat-select formControlName="roleProfile">
        <mat-option *ngFor="let element of profileListData" value="{{element.systemRoleCode}}">{{element.roleName}}
        </mat-option>
      </mat-select>
      <mat-error class="help-block err"
        *ngIf="roleform.get('roleProfile').errors?.required && (isForm_Submitted || roleform.get('roleProfile').touched)">
        Please select role profile.
      </mat-error>
    </mat-form-field>
   </div>

   <div class="col-12 mt-2">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
      <mat-label>Role Name</mat-label>
      <input matInput autocomplete="off" formControlName="roleName" >
      <mat-error class="help-block err"
      *ngIf="roleform.get('roleName').errors?.required && (isForm_Submitted || roleform.get('roleName').touched)">
      Role Name is required.
    </mat-error>
    </mat-form-field>
   
  </div>

  <div class="col-12 mt-2">
    <mat-form-field appearance="outline" floatLabel="always"  class="example-full-width">
      <mat-label>Role Description</mat-label>
      <textarea matInput formControlName="roleDesc"></textarea>
    </mat-form-field>
  </div>

  <div class="col-12">
    <mat-checkbox class="example-margin" formControlName="isDefault">Is Default</mat-checkbox>
  </div>
 
  </form>
   
     
    <mat-dialog-actions align="end">
      <div class="col-lg-12 align-self-end"> 
      <button mat-button matDialogClose class="me-5"  color="primary">Dismiss</button>
      <button class="me-5" mat-raised-button color="primary" (click)="addUpdateRole()">Save</button>
    </div>
    </mat-dialog-actions>
  </ng-template>