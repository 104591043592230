import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';
import { ProfileService } from '../profile.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {

  adminForm: FormGroup;
  submittedAdmin = true;
  @ViewChild('adminpassword', { static: false }) adminpassword;
  fileUrl: any;
  fileUrlAdmin: any;
  imageuploadAdmin: boolean;
  filetitle: any;

  constructor(
    public dialogRef: MatDialogRef<AddAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public common: CommonService, public service: ProfileService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    //console.log(this.data);
    this.adminForm = new FormGroup({
      'adminImage': new FormControl(null),
      'adminName': new FormControl(null, [Validators.required, Validators.pattern(this.service.common.name)]),
      'adminEmail': new FormControl(null, [Validators.required, Validators.email]),
      'adminpassword': new FormControl(null, [Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$")]),
      'admincpassword': new FormControl(null, [Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$")], this.confirmAdminPassword.bind(this)),
      'adminUserName': new FormControl(null, [Validators.required,
      Validators.pattern(this.common.username)], this.confirmUserNameAvailability.bind(this))
    });

    if(this.data){
      this.service.adminData.adminDetails = Object.assign({}, this.data.admin);
      if(this.data.admin.adminImage != ''){
        this.fileUrlAdmin = this.service.service.getSuperAdmin() + '/downloadFile/' + this.data.admin.adminImage;
        this.imageuploadAdmin = true;
      }
    } else {
      this.service.adminData = {
        adminDetails : {
          confirmedPassword: null,
          email: null,
          isActive: false,
          name: null,
          password: null,
          userCode: null,
          userName: null,
          adminImage: null,
          isAdmin: true,
          isOwner: false,
          organizationCode: null
        }
      }
    }
    this.service.adminData.adminDetails.organizationCode = this.common.local.get('orgCode');

  }


  /* Name:Shailesh
      use: for uploading profile picture */
      onFileChange(event, tab) {
        //console.log(event);
        if (event.target.files.length > 0) {
    
          let allowedExtension = ['jpeg', 'jpg', 'png'];
          let fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
          let isValidFile = false;
          //////console.log(fileExtension);
          for (let index in allowedExtension) {
            if (fileExtension === allowedExtension[index] && event.target.files[0].size <= 2000000) {
              isValidFile = true;
              const formData = new FormData();
              formData.append("file", event.target.files[0]);
              try {
                this.service.common.uploadImage(formData, (res) => {
                  //////console.log(res);
                    this.fileUrlAdmin = res.fileurl;
                    this.filetitle = res.filename;
                    this.service.adminData.adminDetails.adminImage = res.filename;
                    this.adminForm.controls['adminImage'].setValue(res.filename);
                    this.imageuploadAdmin = true;
                })
              } catch (error) {
                //console.log(error)
              }
    
              break;
            }
          }
    
          if (!isValidFile) {
            //////console.log(isValidFile);
            this.service.common.message = 'Allowed Extensions are : .' + allowedExtension.join(', .') + ' and file size should be less then 2MB';
            this.service.common.errorMessage = true;
            //event.srcElement.value = '';
            event.target.value = '';
          }
        }
      }
    

  /* Name:Shailesh
       use: for checking admin confirm password */
  confirmAdminPassword(control: AbstractControl) {

    return new Promise<any>(
      (resolve, reject) => {
        //console.log(control.value, this.adminpassword);
        if (this.adminpassword && control.value !== this.adminpassword.nativeElement.value && this.adminpassword.nativeElement.value) {
          //console.log('in');
          resolve({
            invalid: true
          });
        } else {
          //console.log('out');
          resolve(null);
        }
      });

  }


  /* Name:Shailesh
    use: for checking username availability*/
  confirmUserNameAvailability(control: FormControl): Promise<any> | Observable<any> {
    if (!this.data) {
      const usernameavaiable = new Promise<any>(
        (resolve, reject) => {
          try {
            this.common.checkUserNameAvialablity(control.value, (response) => {
              //////console.log(response);
              if (response == false) {
                resolve({ invalid: true });
              } else {
                resolve(null);
              }
            });
          } catch (error) {
            //console.log(error)
          }

        });
      return usernameavaiable;
    } else {
      const usernameavaiable = new Promise<any>(
        (resolve, reject) => {
          this.service.getIsUniqueUserNameForUpdate(this.service.adminData.adminDetails.userCode, control.value, (response) => {
            //console.log(response);
            if (response == false) {
              resolve({ invalid: true });
            } else {
              resolve(null);
            }
          });
        });
      return usernameavaiable;
    }

  }

  adminActivecheck($event) {
    //////console.log($event);
    if ($event.checked) {
      this.service.adminData.adminDetails.isActive = true;
    } else {
      this.service.adminData.adminDetails.isActive = false;
    }
  }

  onSubmit(){
    if(this.adminForm.valid){
      this.submittedAdmin = true;
      if(this.data){
        this.service.updateOwnerAdminDetails(this.service.adminData.adminDetails.userCode, this.service.adminData.adminDetails, (res)=>{
          if(res.responsecode == 200){
            this.dialogRef.close('true');
            this.common.successMessage = true;
            this.common.message = res.message;
          } else {
            this.common.errorMessage = true;
            this.common.message = res.message;
          }
        });
      } else {
        this.service.addNewAdmin(this.service.adminData.adminDetails,(res)=>{
          if(res.responsecode == 200){
            this.dialogRef.close('true');
            this.common.successMessage = true;
            this.common.message = res.message;
          } else {
            this.common.errorMessage = true;
            this.common.message = res.message;
          }
        });
      }
    } else {
      this.submittedAdmin = false;
    }
  }
}
