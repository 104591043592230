import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from 'src/app/shared/api-root.service';


@Injectable()
export class RequestNewJobService {

  constructor(
    private http: HttpClient,
    private _apiSVC: ApiRootService,
  ) { }



  getAllShiftMaster(orgCode: string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getShiftMasterByOrganizationCode/' + orgCode);
  }

  getAllEquipmentMaster(orgCode: string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllEquipmentMasterDetailsByOrganizationCode/' + orgCode);
  }

  getAllUniformMaster(orgCode: string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllUniformMasterDetailsByOrganizationCode/' + orgCode);
  }

  getAllProcedure(orgCode: string,) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllProcedureByOrganizationCode/' + orgCode);
  }


  EditJob(appointmentsCode: string, appointmentsProcedureCode: string, request_body: any) {
    return this.http.put(this._apiSVC.getHealthCareUrl() + '/editJob' + '/' + appointmentsCode + '/' + appointmentsProcedureCode, request_body);

  }

  saveUniform(request_body: any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateUniformMaster', request_body);
  }

  saveEquipment(request_body: any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateEquipmentMaster', request_body);
  }


  editJob(appointmentsCode: string, appointmentsProceduresCode: string, request_body: any) {
    return this.http.put(this._apiSVC.getHealthCareUrl() + '/editJob/' + appointmentsCode + '/' + appointmentsProceduresCode, request_body);
  }


  getCustomerAddressListByCustomerCode(customerCode: string) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + '/getCustomerAddressListByCustomerCode/' + customerCode)
  }

  requestNewJob(request_body: any) {
    return this.http.post(this._apiSVC.getHealthCareUrl() + '/v2/saveOrUpdateAppointmentsRequest', request_body);
    // return this.http.post(this._apiSVC.getHealthCareUrl() + '/saveOrUpdateAppointmentsRequest', request_body);
  }

  getAllAppointmentsRequestByOrganizationCodeAndCustomerCode(organizationCode: string, status: string, customerCode: string, page:number, limit:number) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + `/getAllAppointmentsRequestByOrganizationCodeAndCustomerCodeByPage/${organizationCode}/${status}/${customerCode}?page=${page}&limit=${limit}`)
  }

 
  getProcessedJobs(organizationCode: string, status: string,page:number, limit:number)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + `/getAllAppointmentsRequestByOrganizationCodeAndCustomerCodeByPage/${organizationCode}/${status}?page=${page}&limit=${limit}`)
  }

/* Name:Vivek Chauhan
  use: for getting all Pending Job List by page */
  getAllCancelJobAssignmentByOrganizationCodePage(orgCode, jobStatus, page, limit, callback) {
  return this.http.get(this._apiSVC.getHealthCareUrl() + '/getAllCancelJobAssignmentByOrganizationCodePage/' + orgCode + '/' + jobStatus + '?page=' + page + '&limit=' + limit).subscribe((data) => {
    callback(data);
  });
}  

/* Name:Vivek Chauhan
  use: for getting all Pending Job List by page */ 
  cancellationJobRequestApproveDecline(request_body: any) {
    return this.http.put(this._apiSVC.getHealthCareUrl() + '/updateCancelJobAssignment/', request_body);
  }


  getRequestedJobs(organizationCode: string, isExpired: boolean, page:number, limit:number)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + `/v2/getAllAppointmentsRequestByOrganizationCodeByPage/${organizationCode}/${isExpired}?page=${page}&limit=${limit}`)
  }

  getRequestedJobsCounts(organizationCode: string, isExpired: boolean = false)
  {
    return this.http.get(this._apiSVC.getHealthCareUrl() + `/getAllAppointmentsRequestByOrganizationCodeCount/${organizationCode}/${isExpired}`)
    // return this.http.get(this._apiSVC.getHealthCareUrl() + `/getAllAppointmentsRequestByOrganizationCodeAndCustomerCodeByPage/${organizationCode}/${status}?page=${page}&limit=${limit}`)
  }
 
  getAllCancelJobs(orgCode: string, isExpired:boolean , page:number , limit:number) {
    return this.http.get(this._apiSVC.getHealthCareUrl() + `/getAllCancelJobAssignmentByOrganizationCodeForExpiredPage/${orgCode}/${isExpired}?page=${page}&limit=${limit}`);
  }

  autoStaffAssign(date:string, appointmentsRequestCode:string) {
    // return this.http.get(`${this._apiSVC.getHealthCareUrl()}/adminGetEligibleStaffForNotifyForCustomer/${appointmentsRequestCode}/${date}/${noOfPublish}`);
    return this.http.get(`${this._apiSVC.getHealthCareUrl()}/adminGetEligibleStaffForNotifyForCustomer/${appointmentsRequestCode}/${date}`);
  }


}
