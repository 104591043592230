import { Component, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { CommonNotesService } from '../common-notes.service';
import { AddUpdateNoteComponent } from "src/app/components/common-notes/add-update-note/add-update-note.component";
import { IBtnControlsPermission } from 'src/app/shared/interface/core.interface';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';
import * as moment from 'moment';
import { debounce } from 'lodash';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
  providers: [CommonNotesService]
})
export class NotesListComponent implements OnInit {

  protected notes:string = '';
  baseURL: any;
  listData: any = [];
  page = 0;
  limit = 30;
  noteDetail: any;
  searchTerm;
  isNoteDetailLoading: boolean = false;
  controlsPermissions:IBtnControlsPermission ;

  constructor(public dialog: MatDialog, public notesService: CommonNotesService) {
    this.applyFilter = debounce(this.applyFilter, 500);
    
  }

  ngOnInit() {
    this.baseURL = this.notesService.service.getSuperAdmin() + "/downloadFile/";
    // this.controlsPermissions =  this.notesService.common.getBtnEventPermissions();
    // console.log('Perm: ', this.controlsPermissions);
    this.getNoteListDataByPageByOrgCode();
    // console.log('ModelData: ', true);
    this.notesService.common.getCommonRefreshEvent().subscribe((refreshData)=>{
      // console.log('ResultRefresh_M', refreshData);
      // if(refreshData?.isRefresh && refreshData?.refreshListFor === 'noteListData'){
      //   this.getNoteListDataByPageByOrgCode();
      // }
    });
  }

  noteChangeEvent(event: any) {
    // console.log('NoteChangeEvent_3: ', event);
    if(event?.isNoteEdit){
      this.addUpdateNotes(event);
    }
    if(event?.isNoteDelete && event.noteEntityMappingCode){
        const dialog = this.dialog.open(ConfirmLogoutComponent, {
          width: '350px',
          data: { 
            msg: 'Are you sure you want to delete this note?'
          }
        });      
    
        dialog.afterClosed().subscribe(result => {    
          if(result && result.toLowerCase() === 'ok'){ 
            this.notesService.common.progressLoader = true;
            this.notesService.softDeleteByNoteEntityMappingCode(event.noteEntityMappingCode, (response) => {
              //console.log(response);
              this.notesService.common.progressLoader = false;
              if (response.responsecode == 200) {
                if(this.noteDetail?.noteArray?.length > 1){
                  var foundIndex = this.noteDetail?.noteArray?.findIndex(note => note.noteEntityMappingCode === event.noteEntityMappingCode && note.entityUuid === event.entityUuid);
                  // console.log('FonndIdx: ', foundIndex);
                  if (foundIndex > -1) { // only splice array when item is found
                    this.noteDetail?.noteArray?.splice(foundIndex, 1); // 2nd parameter means remove one item only
                  }
                } else {
                  this.page = 0;
                  this.getNoteListDataByPageByOrgCode();
                }  
                this.notesService.common.successMessage = true;
                this.notesService.common.message = response.message;
              }
              else 
              {
                this.notesService.common.errorMessage = true;
                this.notesService.common.message = response.message;
              }
            });
          }
        });       
      }
    }

  /* Name:Vivek Chauhan
  use: for getting all Note List Master data by org code */
  getNoteListDataByPageByOrgCode(scroll = null, search = null) {
    // console.log('getNoteListDataByPageByOrgCode: ', this.page, true);
    let orgCode = this.notesService.common.local.get('orgCode');    
    if (!scroll) {
      this.notesService.common.progressLoader = true;
    }
    else if (scroll) {
      this.notesService.common.infiniteLoader = true;
    }
    try {
      this.notesService.getNoteEntityMappingByOrganizationCodeByPage(orgCode, this.page, this.limit, search, (response) => {
        this.notesService.common.progressLoader = false;
        this.notesService.common.infiniteLoader = false;
        if(response.responsecode === 200){
          if (scroll) {
            // console.log('scroll Array_12', response.data);
            let cloneList = this.listData && JSON.parse(JSON.stringify(this.listData));
            const cloneListFlat = cloneList && cloneList.length > 0 && cloneList.reduce((acc, group) => {
              group.groupList.forEach(item => {
                  acc.push(item);
              });
              return acc;
              }, []);

              if(cloneListFlat){
                response.data.forEach(element => {
                  cloneListFlat.push(element)
                });
              }  

            setTimeout(() => {
              // console.log('Array_1', cloneListFlat);
              this.listData = this.groupedArrayForNoteList(cloneListFlat);
            }, 1000);

          } else {
            ////console.log(response.data);
            setTimeout(() => {              
              this.listData = this.groupedArrayForNoteList(response.data);
              // console.log("this.listData",this.listData);
              this.getNoteDetail(this.listData[0]?.groupList[0], 0, 0);
            }, 1000)
          }
        } else {          
          if (!scroll) {
            this.noteDetail = null;
          } else {
            this.notesService.common.infiniteLoader = false;
          }
          this.notesService.common.progressLoader = false;
        }
    });    
    } catch (error) {
      //console.log(error);
    }
}

  /* Name:Vivek Chauhan
  use: for make group by date in note list */
groupedArrayForNoteList(noteList: any){
    // Step 1: Group by 'groupedBy'
    if(noteList && noteList?.length > 0){
      const grouped = noteList.reduce((acc, current) => {
        if (!acc[current.groupedBy]) {
            acc[current.groupedBy] = [];
        }
        acc[current.groupedBy].push(current);
        return acc;
      }, {});

      // Step 2: Convert the grouped object to the desired format
        const groupedArray = Object.keys(grouped).map(key => {
          return {
              groupBy: key,
              groupList: grouped[key]
          };
        });
        return groupedArray;
      } else {
        return [];
    }
  }

  /* Name:Vivek Chauhan
  use: for getting note details data by noteEntityMappingCode */

getNoteDetail(note: any, gIdx:any, idx: any){
  note.isSelected = true;
  this.listData = this.listData.map((group, gIndex) => {
    if(group && gIndex === gIdx){
      group.groupList = group.groupList.filter((note, i) => {
        if(i === idx && note){ note.isSelected = true} 
        else { note.isSelected = false } 
        return note;
      })
    } else {
      group.groupList = group.groupList.filter((note) => {
        if(note){ note.isSelected = false} 
        return note;
      })
    }
    return group;
  });
  this.isNoteDetailLoading = true;
  this.noteDetail = null;
  this.notesService.getNoteEntityDetailMappingByNoteEntityMappingCode(note.noteEntityMappingCode, (response) => {
    this.isNoteDetailLoading = false;
    if(response.responsecode === 200){      
      this.noteDetail = response.data;
      if(this.noteDetail && this.noteDetail.dateFixedFormat){
        this.noteDetail.week_day = moment(this.noteDetail.dateFixedFormat).format('ddd');
        this.noteDetail.month_day = moment(this.noteDetail.dateFixedFormat).format('D');
        this.noteDetail.month_name = moment(this.noteDetail.ddateFixedFormatate).format('MMM');
      }
      // console.log('NoteDetail: ', this.noteDetail);
    } else {
      this.noteDetail = null;
      if(response.responsecode === 400 || response.responsecode === 404){
        // not show alert
      } else {
      this.notesService.common.message = response.message;
      this.notesService.common.errorMessage = true;
      }
    }
})
}

applyFilter(filterValue: string) {
  this.page = 0;
  let searchVal = filterValue.trim().toLowerCase();
  this.getNoteListDataByPageByOrgCode(null, searchVal);
}

  /* Name:Vivek Chauhan
  use: for getting all Note List Master data by org code */
  getNoteListDataByPageByUserCode() {
    this.notesService.common.progressLoader = true;
    let userCode = this.notesService.common.local.get('userCode');
    try{
      this.notesService.getNoteListDataByUserCode(userCode, (response) => {
        this.notesService.common.progressLoader = false;
        if(response.responsecode === 200){
          // console.log('ResData; ', response.data);
          this.addUpdateNotes(response.data);
        } else {
          this.addUpdateNotes();
          if(response.responsecode === 400 || response.responsecode === 404){
            // not show alert
          } else {
          this.notesService.common.message = response.message;
          this.notesService.common.errorMessage = true;
          }
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  /* Name:Vivek Chauhan
  use: for infinite scroll */
  onScroll() {
    //console.log('scroll');
    // this.page = this.page + 1;
    this.page = this.page + 1;
    this.getNoteListDataByPageByOrgCode('scroll');
    //console.log('OnsScroll', this.page+1, this.limit);
  }

  addUpdateNotes(noteData: any = null){
    // console.log('addUpdateNotes: ', true, noteData);
    const dialogRef = this.dialog.open(AddUpdateNoteComponent, { 
      width: '50%',
      data: { note: noteData }
    });

    dialogRef.afterClosed().subscribe((result) => {
        // console.log('ModelClose: ', result);
        if (result != undefined && result === 'success') {
          this.getNoteListDataByPageByOrgCode();
        }
      });
  }

  saveNote(noteMasterCode:any = null){

    let userCode = this.notesService.common.local.get('userCode');
    let orgCode = this.notesService.common.local.get('orgCode');

  if(!this.notes?.trim())
    return false;

  let reqBody = {
    note: this.notes,
    noteMasterCode: noteMasterCode ? noteMasterCode : null,
    organizationCode: orgCode,
    userCode: userCode
  }

  // console.log('NotesText: ', reqBody);
  // return;

  this.notesService.common.progressLoader = true;
  this.notesService.saveAndUpdateNoteMaster(reqBody).subscribe((res:any) =>{
    
    this.notesService.common.progressLoader = false;
        
    if(res.responsecode == 200)
    {
      this.notesService.common.message = res.message;
      this.notesService.common.successMessage = true;      
    }
    else{
      this.notesService.common.message = res.message;
      this.notesService.common.errorMessage = true;
    }
    
  })
}

    /* Name:Vivek Chauhan new API
  use: for delete/remove Distribution */
  softDeleteNoteMasterByNoteMasterCode(noteMasterCode :any){
    // //console.log('softDeleteByCode: ', data);    
    if(noteMasterCode){ 
      const dialog = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { 
          msg: 'Are you sure you want to delete this note?'
        }
      });      
  
      dialog.afterClosed().subscribe(result => {    
        if(result && result.toLowerCase() === 'ok'){ 
          // console.log('Codda: ', noteMasterCode);
          // return;
          this.notesService.common.progressLoader = true;
          this.notesService.softDeleteNoteMasterByNoteMasterCode(noteMasterCode, (response) => {
            //console.log(response);
            this.notesService.common.progressLoader = false;
            if (response.responsecode == 200) {
              this.notesService.common.successMessage = true;
              this.notesService.common.message = response.message;
              this.page = 0;
              this.getNoteListDataByPageByOrgCode();
            }
            else 
            {
              this.notesService.common.errorMessage = true;
              this.notesService.common.message = response.message;
            }
          });
        }
      });
    }
  }

}

