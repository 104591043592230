<!-- For  Admin/Owner/Othersr Side Menus Start --> 
<!-- <ng-container *ngIf="userType !== 'Staff' && userType !== 'Customer'"> -->

<ng-container *ngIf="userType !== 'Staff'">
  <mat-sidenav-container class="sidenav-container" [class.isMobileMenuOpen]="isMobileMenuOpen">
      <mat-sidenav
          #sidenav
          class="sidenav"
          [class.toggleMenuVal]="toggleMenuVal"
          [class.isMobileMenuOpen]="isMobileMenuOpen"
          fixedInViewport="true"
          [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
          [mode]="(isHandset$ | async) ? 'over' : 'side'"
          [opened]="!(isHandset$ | async)"
          style="overflow: hidden !important;"
          hasBackdrop="true"
      >
          <mat-nav-list >

            <div class="img-fluid p-2 me-auto">
                <img class="header-app-logo cursor-pointer ms-2" [src]="common.webLogo" (click)="logoClicked()">
             </div>
         

             <div class="text-center nav-list-scroll" [class.toggleMenuVal]="!toggleMenuVal">
              <div class="mat-nav-list-scroll" [class.toggleMenuVal]="!toggleMenuVal">
                  <ng-container *ngFor="let menu of MenuListDataAndPagePermission.ppLst; index as idx">
                    <div class="menu-li" *ngIf="menu.pagePermissionName != 'Settings'" [class.toggleMenuVal]="!toggleMenuVal">
                        <a *ngIf="menu?.childList && menu?.childList?.length > 0; else linkedMenu" mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="accordionExpand[idx] = !accordionExpand[idx]">
                            <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : menu.pagePermissionName}}">
                                <img class="svgIcon" [src]="menu?.icon ? (baseURL + menu.icon + '.svg') : ''" alt="{{menu.icon}}">
                                <img class="svgActiveIcon" [src]="menu?.activeIcon ? (baseURL + menu.activeIcon + '.svg') : baseURL + menu.icon + '.svg'" alt="{{menu.icon}}">
                                <span class="p-0 navTitle"><small>{{menu.pagePermissionName}}</small></span>
                                <i *ngIf="accordionExpand[idx]" class="material-icons small-icon accordianIc">expand_more</i>
                                <i *ngIf="!accordionExpand[idx]" class="material-icons small-icon accordianIc">expand_less</i>
                            </div>
                        </a>
                        <ng-template #linkedMenu>
                            <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="{{menu.pageUrl}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : menu.pagePermissionName}}">
                                    <img class="svgIcon" [src]="menu?.icon ? (baseURL + menu.icon + '.svg') : ''" alt="{{menu.icon}}">
                                    <img class="svgActiveIcon" [src]="menu?.activeIcon ? (baseURL + menu.activeIcon + '.svg') : baseURL + menu.icon + '.svg'" alt="{{menu.icon}}">
                                    <span class="p-0 navTitle"><small>{{menu.pagePermissionName}}</small></span>
                                </div>
                            </a>
                        </ng-template>
                    </div>
                  <ng-container *ngIf="!accordionExpand[idx]">
                        <ng-container *ngFor="let childMenu of menu.childList">
                      <div class="menu-li" *ngIf="menu.pagePermissionName != 'Settings'" [class.toggleMenuVal]="!toggleMenuVal">
                          <a *ngIf="childMenu?.pagePermissionName === 'Chat'; else childMenuItem" mat-list-item class="left-menu-padding first childNav" [class.toggleMenuVal]="!toggleMenuVal" (click)="chatOpen()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              <div class="left-icon d-flex childLeftPadd" title="{{toggleMenuVal ? '' : childMenu.pagePermissionName}}">
                                <img class="svgIcon" [src]="childMenu?.icon ? (baseURL + childMenu.icon + '.svg') : ''" alt="{{childMenu.icon}}">
                                  <img class="svgActiveIcon" [src]="childMenu?.activeIcon ? (baseURL + childMenu.activeIcon + '.svg') : baseURL + childMenu.icon + '.svg'" alt="{{childMenu.icon}}">
                                 
                                  <ng-template [ngIf]="!toggleMenuVal">
                                  <small *ngIf="common?.globalChatCount > 0" class="badge rounded-pill bg-danger badge-short-nav">
                                    {{common?.globalChatCount}}
                                  </small>
                                </ng-template>

                                  <span class="p-0 navTitle">
                                    <small>{{childMenu.pagePermissionName}} </small>
                                  </span>&nbsp;&nbsp;

                                  <ng-template [ngIf]="toggleMenuVal">
                                  <small *ngIf="common?.globalChatCount > 0" class="badge rounded-pill bg-danger badge-full-nav">
                                    {{common?.globalChatCount}}
                                  </small>
                                </ng-template>

                                  <!-- <span *ngIf="common?.globalChatCount > 0" class="position-absolute translate-middle badge rounded-pill bg-danger">
                                    {{common?.globalChatCount}}
                                  </span> -->
                              </div>
                          </a>
                          <ng-template #childMenuItem>
                            <a mat-list-item class="left-menu-padding first childNav" [class.toggleMenuVal]="!toggleMenuVal" routerLink="{{childMenu.pageUrl}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <div class="left-icon d-flex childLeftPadd" title="{{toggleMenuVal ? '' : childMenu.pagePermissionName}}">
                                    <img class="svgIcon" [src]="childMenu?.icon ? (baseURL + childMenu.icon + '.svg') : ''" alt="{{childMenu.icon}}">
                                    <img class="svgActiveIcon" [src]="childMenu?.activeIcon ? (baseURL + childMenu.activeIcon + '.svg') : baseURL + childMenu.icon + '.svg'" alt="{{childMenu.icon}}">
                                    <span class="p-0 navTitle"><small>{{childMenu.pagePermissionName}}</small></span>
                                </div>
                            </a>
                          </ng-template>
                      </div>
                    </ng-container>
                    </ng-container>
                  </ng-container>
              </div>

              </div>
          </mat-nav-list>
      </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
<!-- For Admin/Owner/Others Side Menus End -->

<!-- For Staff User Side Menus Start -->

<ng-container *ngIf="userType === 'Staff'">
  <mat-sidenav-container class="sidenav-container" *ngIf="userType === 'Staff'">
      <mat-sidenav
          #drawer
          class="sidenav"
          [class.toggleMenuVal]="toggleMenuVal"
          fixedInViewport="true"
          [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
          [mode]="(isHandset$ | async) ? 'over' : 'side'"
          [opened]="!(isHandset$ | async)"
          style="overflow: hidden !important;"
          hasBackdrop="true"
      >
          <mat-nav-list>
            <div class="img-fluid p-2 me-auto">
                <img class="header-app-logo ms-2 cursor-pointer" [src]="common.webLogo" (click)="logoClicked()">
             </div>

             <div class="text-center nav-list-scroll" [class.toggleMenuVal]="!toggleMenuVal">
           
                <div class="mat-nav-list-scroll" [class.toggleMenuVal]="!toggleMenuVal">
                  <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-technician" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Dashboard'}}">
                              <i class="material-icons small-icon pb-1">
                                  home
                              </i>
                              <span class="p-0 navTitle"><small>{{ 'main.labels.Dashboard' | translate }}</small></span>
                          </div>
                      </a>
                  </div>

                  <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-technician/new-appointment" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : ' New Shifts'}}">
                              <i class="material-icons small-icon pb-1">
                                  calendar_today
                              </i>
                              <span class="p-0 navTitle"><small>New Shifts</small></span>
                              <!-- <span class="p-0 navTitle"><small>{{ 'main.labels.New Jobs' | translate }}</small></span> -->
                          </div>
                      </a>
                  </div>

                  <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-technician/time-of-request" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Leave Requests'}}">
                              <i class="material-icons small-icon pb-1">
                                  schedule
                              </i>
                              <span class="p-0 navTitle"><small>{{ 'main.labels.Leave Requests' | translate }}</small></span>
                          </div>
                      </a>
                  </div>

                  <!-- <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-technician/tracking-log" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Tracking Report'}}">
                              <i class="material-icons small-icon pb-1">
                                  assignment
                              </i>
                              <span class="p-0 navTitle"><small>{{ 'report.labels.Tracking Report' | translate }}</small></span>
                          </div>
                      </a>
                  </div> -->

                  <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-technician/preference-setting" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'User Preference Settings'}}">
                              <i class="material-icons small-icon pb-1">
                                  settings
                              </i>
                              <span class="p-0 navTitle"><small>{{ 'main.labels.User Preference Settings' | translate }}</small></span>
                          </div>
                      </a>
                  </div>
              </div>

              </div>

          </mat-nav-list>
      </mat-sidenav>
  </mat-sidenav-container>
</ng-container>

<!-- For Staff User Side Menus End -->

<!-- For Customer User Side Menus Start -->

<!-- <ng-container *ngIf="userType === 'Customer'">
  <mat-sidenav-container class="sidenav-container" *ngIf="userType === 'Customer'">
      <mat-sidenav
          #drawer
          class="sidenav"
          [class.toggleMenuVal]="toggleMenuVal"
          fixedInViewport="true"
          [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
          [mode]="(isHandset$ | async) ? 'over' : 'side'"
          [opened]="!(isHandset$ | async)"
          style="overflow: hidden !important;"
          hasBackdrop="true"
      >
          <mat-nav-list class="text-center nav-list-scroll" [class.toggleMenuVal]="!toggleMenuVal">
              <div class="mat-nav-list-scroll" [class.toggleMenuVal]="!toggleMenuVal">
                  <div class="menu-li">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-customer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Dashboard'}}">
                              <i class="material-icons small-icon pb-1">
                                  home
                              </i>
                              <span class="p-0 navTitle"><small>{{ 'main.labels.Dashboard' | translate }}</small></span>
                          </div>
                      </a>
                  </div>

                  <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                      <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-customer/customer-reports" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Review Time Card'}}">
                              <i class="material-icons small-icon pb-1">
                                  assignment
                              </i>
                              <span class="p-0 navTitle"><small>{{ 'main.labels.ReviewTimeCard' | translate }}</small></span>
                          </div>
                      </a>
                  </div>

                  <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                    <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-customer/requested-new-job-list" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Request New Job'}}">
                            <i class="material-icons small-icon pb-1">
                                assignment
                            </i>
                            <span class="p-0 navTitle"><small>Request New Job</small></span>
                        </div>
                    </a>
                </div>

                <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                    <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-customer/approve-invoice" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Approve Invoice'}}">
                            <i class="material-icons small-icon pb-1">
                                assignment
                            </i>
                            <span class="p-0 navTitle"><small>Approve Invoice</small></span>
                        </div>
                    </a>
                </div>

                <div class="menu-li" [class.toggleMenuVal]="!toggleMenuVal">
                    <a mat-list-item class="left-menu-padding first" [class.toggleMenuVal]="!toggleMenuVal" routerLink="/dashboard-customer/invoice" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <div class="left-icon d-flex" title="{{toggleMenuVal ? '' : 'Invoice'}}">
                            <i class="material-icons small-icon pb-1">
                                assignment
                            </i>
                             <span class="p-0 navTitle"><small>Invoice</small></span>
                        </div>
                    </a>
                </div>


              </div>
          </mat-nav-list>
      </mat-sidenav>
  </mat-sidenav-container>
</ng-container> -->

<!-- For Customer User Side Menus End -->
