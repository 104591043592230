import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { CountryCodeModel } from './country-code.model';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-country-code',
  templateUrl: './country-code.component.html',
  styleUrls: ['./country-code.component.scss']
})
export class CountryCodeComponent implements OnInit {

  allCountry;
  filteredStates: Observable<any[]>;
  country = new FormControl(null, Validators.required);
  filterCountryCode = new FormControl(null);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @Output() setCountryCode: EventEmitter<number> = new EventEmitter<number>();
  @Input('setCode') setCode;
  @Input('disable') disable = false;
  @Input() withoutFloatLabel: boolean = false;
  @Input('placeholder') placeholder: any;

  constructor(
    public countryCode: CountryCodeModel,
  ) {
    //////console.log(this.disable);
  }

  ngOnInit() {
    this.allCountry = this.countryCode.AllNewCountries;

    this.filteredStates = this.filterCountryCode.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    // this.country.setValue(this.setCode);
    // //////console.log(this.allCountry);
    //////console.log(this.setCode);
  }

  getCountry(event) {
    // //////console.log(event);
    this.setCountryCode.emit(event.value);
  }

  public _filter(value: string) {
    // //////console.log(value);
    if (value) {
      const filterValue = value.toLowerCase();
      return this.allCountry.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return this.allCountry;
    }
  }




}
