import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CallPatientComponent } from 'src/app/dashboards/providers-dashboard/call-patient/call-patient.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-msg',
  templateUrl: './notification-msg.component.html',
  styleUrls: ['./notification-msg.component.scss']
})
export class NotificationMsgComponent implements OnInit {

  message = '';
  appointmentsList = [];
  appointmentsDeatilsList = [];


  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public dialog: MatDialog,
    public snackBarRef: MatSnackBarRef<NotificationMsgComponent>, public router: Router) { }

/* Name:srinivas
      use:on page load. */
  ngOnInit() {
    ////console.log(this.data);
    this.message = this.data['message'];
    if (this.data['data']) {
      this.appointmentsList = this.data['data'];
      this.appointmentsDeatilsList = this.data['data'].appointmentsDeatilsList;
    }
  }
/* Name:srinivas
      use: return snack bar. */
  onDismiss() {
    this.snackBarRef.dismiss();
  }
/* Name:srinivas
      use: return user to notification page. */

  redirectUser() {
    this.snackBarRef.dismiss();
    this.router.navigate(["/dashboard/notification"])

  }
/* Name:srinivas
      use: open patient popUp. */
  openPatient(): void {
    const dialogRef = this.dialog.open(CallPatientComponent, {
      width: '600px',
      data: this.appointmentsList
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');

    });
  }
}
