import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { TechnicianDashboardService } from './technician-dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common.service';
import { ProcedureDetailComponent } from '../providers-dashboard/appointment/procedure-detail/procedure-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { NotesPopupComponent } from '../providers-dashboard/appointment/notes-popup/notes-popup.component';
import { RejectAppointmentComponent } from './new-appointment/reject-appointment/reject-appointment.component';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'app-technician-dashboard',
  templateUrl: './technician-dashboard.component.html',
  styleUrls: ['./technician-dashboard.component.scss']
})
export class TechnicianDashboardComponent implements OnInit { 

  img_serverPath:string ='';
  navDate: any;
  currentDate = new Date();
  startDate: any;
  endDate: any;
  selectDate;
  selectedDate: any;
  weekDaysHeaderArr: Array<string> = [];
  //currentSelectedDate;
  @Input() locale: string;
  gridArr: Array<any> = [];
  gridArrNew: Array<any> = [];
  timeofreq = [];
  status = 'pending,assigned,accepted,inprogress,completed,expired';
  userName;
  showPopup;
  showImg;
  loggedUserType: any;
  cardData: any;
  startTime = '00:00:00';
  endTime = '23:59:59';
  yearMonthDateFormat = 'YYYY-MM-DD';
  monthDateYear = 'MM-DD-YYYY'
  dateMonthYear = 'DD-MM-YYYY'
  dateMonthNameFormat = 'DD MMMM';
  dateShotMonthName = 'DD MMM';
  assignmonthper = 0;
  acceptmonthper = 0;
  assignmonthcolor: any;
  acceptmonthcolor: any;
  finalmonthper = 0;
  loggedUserCode: any;

  constructor(
    public service: TechnicianDashboardService, public translate: TranslateService, public common: CommonService,
    public dialog: MatDialog,

  ) { 
    this.translate.setDefaultLang(this.common.local.get('language'));
  }
/* Name:srinivas
      use: close popUp. */
  ngOnInit() {

    this.img_serverPath =  this.service.service.getSuperAdmin() + '/downloadFile/';
    this.loggedUserCode = this.service.common.local.get("userCode");
    this.getAllOrgDetails();

    this.loggedUserType = this.service.session.get('user_type');

    this.common.orgCodeEmit.subscribe(
      (data: any) => {
        if (data){
       // this.common.globalOrgcode = data;
        this.common.local.set('selected_OrgCode', data);
      this.getAllOrgDetails();
      }
     });
  }
/* Name:srinivas
      use: set all dash board date and return time request details . */
  getAllOrgDetails(){
    this.selectDate = moment(this.currentDate).format('D');
    this.selectedDate = moment(this.currentDate).format('DD MMM');
    //this.currentSelectedDate = moment(new Date()).format('YYYY-MM-DD');
    let firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    let lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
    this.startDate = moment(firstDay).format('YYYY-MM-DD');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    moment.locale(this.locale);
    this.navDate = moment();
    if (this.weekDaysHeaderArr.length==0){
        this.makeHeader();
    } 
    this.createBlanlGrid();
    this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
    ////console.log(this.selectedDate,'.....');
    this.userName = this.service.session.get('username');

  }
  /* Name:srinivas
      use: return image detail. */
  showImage(element) {
    // ////console.log(element);
    // ////console.log(this.showPopup);
    if (this.showImg == element.appointmentsDurationCode) {
      this.showImg = '';
    } else {
      this.showImg = element.appointmentsDurationCode;
    }
    // this.router.navigate(['/dashboard/update-appointment'], { queryParams: { jobDurationCode: '7da6999e-f404-4ba4-9f54-e4b927e7985b' } });
  }
/* Name:srinivas
      use: change month details. */
  changeNavMonth(num: number, monthData = null) {
    this.navDate.add(num, 'month');
    // //////console.log(this.datepipe.transform(this.navDate, 'dd/MM/yyyy'));
    // //////console.log(this.datepipe.transform(moment(), 'dd/MM/yyyy'));

    //////console.log(moment(this.navDate).format('DD/MM/YYYY'));
    //////console.log(moment().format('DD/MM/YYYY'));

    //////console.log(this.selectDate);
    // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    // if (this.datepipe.transform(this.currentDate, 'MMM') == this.datepipe.transform(firstDay, 'MMM')) {
    if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
      // this.startDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
      // this.selectDate = +this.datepipe.transform(this.currentDate, 'dd');
      this.startDate = moment(firstDay).format('YYYY-MM-DD');
      this.selectDate = moment(this.currentDate).format('DD');
      this.selectedDate = moment(this.currentDate).format('DD MMM');

    } else {
      // this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
      this.startDate = moment(firstDay).format('YYYY-MM-DD');
      this.selectedDate = moment(this.dateFromNum(num, this.navDate)).format('DD MMM');
    }
    // this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    // this.selectedDate = this.datepipe.transform(this.dateFromNum(num, this.navDate), 'dd MMMM');
    if (!monthData) {
      this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
      //////console.log(monthData);
    }
  }
/* Name:srinivas
      use: return date from number. */
  dateFromNum(num: number, referenceDate: any): any {
    let returnDate = moment(referenceDate);
    return returnDate.date(num);
  }
/* Name:srinivas
      use:create empty table. */
  makeHeader() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format('ddd')));
  }
/* Name:srinivas
      use: create empty table. */
  createBlanlGrid() {
    this.gridArrNew = [];
    this.gridArr = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
        obj.yearMonthDateFormat = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format(this.yearMonthDateFormat);
      } else {

        obj.value = i - initialEmptyCells + 1;
        obj.available = true;
        obj.highlight = '';
        obj.date = dateToCheck;
        obj.yearMonthDateFormat = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format(this.yearMonthDateFormat);
      }
      this.gridArr.push(obj);
    }
    this.gridArrNew = this.gridArr;

    // this.service.common.progressLoader = false;

  }
/* Name:srinivas
      use: return time request details. */
  getTimeOfReq(code, startDate, endDate) {
   
   try {
   
    // //console.log('in',startDate,endDate);
      this.service.common.progressLoader = true;
      this.service.mobileGetDashboardByTechnicianCodeAndBetweenDates(code, startDate, endDate,this.common.local.get('selected_OrgCode'), (data) => { 
        // //console.log(data);
        this.gridArr = [];
       // ('1.2').toPrecision(1);
        //decodeURI('%');
        this.timeofreq = [];
        if (data.responsecode == 200) {
         //  this.timeofreq = data.data;
          this.getNewAppointments(data.data);
          this.service.common.progressLoader = false;
         
        } else {
          this.createBlanlGrid(); 
          this.service.common.progressLoader = false;
        }
      });
    } catch (error) {
      // //console.log('catcherror',error);
      this.common.saveException('TechnicianDashboardComponent',error,0,0,'');

    }
  }
/* Name:srinivas
      use: return new appointments. */
  getNewAppointments(allData, startDate = null) {
    this.gridArr = [];
    const firstDayDate = moment(this.navDate).startOf('month');
    const initialEmptyCells = firstDayDate.weekday();
    const lastDayDate = moment(this.navDate).endOf('month');
    const lastEmptyCells = 6 - lastDayDate.weekday();
    const daysInMonth = this.navDate.daysInMonth();
    const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
    for (let i = 0; i < arrayLength; i++) {
      let obj: any = {};
      obj.eventData = [];
      // let dateToCheck = this.datepipe.transform(this.dateFromNum(i - initialEmptyCells + 1, this.navDate));
      // //////console.log(moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MMM D, YYYY'));
      // //console.log(allData.length);
      let dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');
      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.value = 0;
        obj.available = false;
        obj.highlight = '';
        obj.date = dateToCheck;
      } else {
        if (allData.length > 0) {
          allData.forEach(element => {
            // ////console.log(element);
            if(element.dateFormat == 'dd-MM-yyyy'){
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD-MM-YYYY');

            } else if(element.dateFormat == 'MM-dd-yyyy'){
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('MM-DD-YYYY');

            } else {
              dateToCheck = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('YYYY-MM-DD');

            }
            if (element.appointmentDetailList.length > 0) {
              element.appointmentDetailList.forEach(elementData => {
              //  //console.log(elementData);
              
                obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');

                if (element.appointmentDate == dateToCheck) {
                 // //console.log(dateToCheck,'if');
                  obj.available = true;
                  obj.event = true;
                  obj.eventData.push(elementData);
                  // obj.highlight = 'greendark';
                  obj.highlight = '';
                  obj.value = i - initialEmptyCells + 1;
                  obj.date = dateToCheck;
                  obj.key = obj.currentDate;
                  obj.finalStatus = element.finalStatus;
                  obj.dateFormat = element.dateFormat;
                  obj.yearMonthDateFormat = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format(this.yearMonthDateFormat);
                }
              });
           
            } else {
             // //console.log(dateToCheck,'else');
             obj.currentDate = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format('DD MMM');
              obj.available = true;
              obj.value = i - initialEmptyCells + 1;
              obj.date = dateToCheck;
              obj.key = obj.currentDate;
              obj.dateFormat = element.dateFormat;
              obj.yearMonthDateFormat = moment(this.dateFromNum(i - initialEmptyCells + 1, this.navDate)).format(this.yearMonthDateFormat);

            }

          });
          
          //////console.log(this.timeofreq);
        } else {
          obj.date = dateToCheck;
          obj.available = true;
          obj.value = i - initialEmptyCells + 1;
        }

        //////console.log(obj.currentDate,this.selectedDate,obj.eventData.length);
        if (obj.currentDate == this.selectedDate && obj.eventData.length > 0) {
          this.timeofreq.push({ key: obj.currentDate, color: obj.dataPercent, iscurrent: true, eventData: obj.eventData });
        }

        // if (obj.eventData.length > 0) {
        //   this.timeofreq.push({ key: obj.currentDate, color: obj.dataPercent, iscurrent: true, eventData: obj.eventData });
        // } else {
        //   this.timeofreq.push({ key: obj.currentDate, color: '', iscurrent: false, eventData: obj.eventData });
        // }

        // ////console.log(this.timeofreq);
        
      }
      if(obj?.currentDate === this.selectedDate){
        this.selectDay(obj);
      }
      this.gridArr.push(obj);
    }

    this.gridArrNew = this.gridArr;
   // //console.log(this.gridArrNew);
     this.service.common.progressLoader = false;
    // ////console.log(this.timeofreq[0].eventData[0].organizationBasedProcedureDetailsList);
    // ////console.log(this.timeofreq[0].eventData[0].organizationBasedProcedureDetailsList.length);

  }
/* Name:srinivas
      use: get popup show and hide. */
getIconPopup(appointmentsDurationCode){
  if (this.showPopup == appointmentsDurationCode) {
    this.showPopup = '';
  } else {
    this.showPopup = appointmentsDurationCode;
  }
}
/* Name:srinivas
      use: get selected day. */
  selectDay(day: any) {
    // //console.log('selectDay: ', day, this.currentSelectedDate, this.selectDate, this.selectedDate);
    // let formatedDate = '';
    // if(day && day?.date){
    //   formatedDate = day?.date && moment(day.date, 'MM-DD-YYYY').format('YYYY-MM-DD');
    // } else {
    //   formatedDate = this.currentSelectedDate;
    // }
    // //////console.log(this.timeofreq);

    let formatedDate = day.yearMonthDateFormat;

    this.cardData = [];

    this.service.common.progressLoader = true;

    if(day?.available && day?.currentDate){
      this.service.mobileGetDashboardByTechnicianCodeAndBetweenDatesFilter(this.service.session.get('orgCode'), formatedDate, formatedDate, this.loggedUserCode,  (data) => {
        this.showPopup = '';
        if (data.responsecode == 200) { 
          this.common.progressLoader = false;
          this.cardData = data.data;
          // //console.log('GetApponments: ', this.cardData);
        }
      });
      
    this.timeofreq = [];

    let momentObj;
    if (day.dateFormat == 'dd-MM-yyyy') {
      momentObj = moment(day.date, 'DD-MM-YYYY');

    } else if (day.dateFormat == 'MM-dd-yyyy') {
      momentObj = moment(day.date, 'MM-DD-YYYY');

    } else {
      momentObj = moment(day.date, 'YYYY-MM-DD');

    }
    let momentString = momentObj.format('DD MMM');

    //momentString = momentObj.format('DD MMM'); // 2016-07-15  

    //const momentObj = moment(day.date, 'MM-DD-YYYY');
    
    // //console.log(momentObj,momentString);
   // this.currentSelectedDate = momentString;

    this.selectDate = day.value;
    this.selectedDate = momentString;
    // //console.log(momentString,  this.currentSelectedDate, this.selectDate, this.selectedDate);
    // //console.log(day.currentDate, this.selectedDate)
   // //console.log(this.selectedDate);
    day.iscurrent = true;
    this.timeofreq.push(day);
    // //console.log(this.timeofreq);
    // this.getTimeOfReq(this.service.session.get('userCode'), moment(momentObj).format('YYYY-MM-DD'), moment(momentObj).format('YYYY-MM-DD'));

    } else {
      this.common.progressLoader = false;
          this.cardData = [];
          return;
    }  
  }
/* Name:srinivas
      use: get status detail. */
  getStatus(status) {

    // this.selectDate = +this.datepipe.transform(new Date(), 'dd');
    this.selectDate = moment().format('DD');
    // const month = new Date(this.datepipe.transform(this.navDate, 'yyyy-MM-dd'));
    const month = new Date(moment(this.navDate).format('YYYY-MM-DD'));
    let firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    let lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    // if (this.datepipe.transform(this.currentDate, 'MMM') == this.datepipe.transform(firstDay, 'MMM')) {
    // if (moment(this.currentDate).format('MMM') == moment(firstDay).format('MMM')) {
    //   // this.startDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    //   this.startDate = moment(this.currentDate).format('YYYY-MM-DD');
    // } else {
    //   // this.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
    // }
    this.startDate = moment(firstDay).format('YYYY-MM-DD');

    // this.endDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd');
    this.endDate = moment(lastDay).format('YYYY-MM-DD');
    this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
  }
/* Name:srinivas
      use: return procedure details. */
  getProcedureDetails(procedure,notes) {
    ////console.log(procedure, notes);

    const dialogRef = this.dialog.open(ProcedureDetailComponent, {
      width: '650px',
      disableClose: true,
      data: {procedure: procedure, notes: notes}
    });

    dialogRef.afterClosed().subscribe(result => {
      ////console.log('The dialog was closed');
      // this.animal = result;
    });
  }
/* Name:srinivas
      use: return notes detail. */
  getNotes(note) {
    const dialogRef = this.dialog.open(NotesPopupComponent, {
      width: '450px',
      data: note
    });
  }
/* Name:srinivas
      use: return status detail. */
  appointmentStatus(status, jobcode){
    //////console.log(this.startDate,this.endDate);
    const data = {
      'workerCode': this.service.session.get('userCode')
    }
    if(status == 'accept'){

      const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
        width: '350px',
        data: { msg: 'Are you sure you want to accept the appointment?' }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          try {
         
          this.service.assignToWorkerAcceptedByJobDurationCode(jobcode, data, (res)=>{
            if(res.responsecode == 200){
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
              // this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          })   
          } catch (error) {
            
          }
        }
      });

    } else {
      const dialogRef = this.dialog.open(RejectAppointmentComponent, {
        width: '300px',
        data: {}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        //////console.log('The dialog was closed');
        if(result != undefined){
          const data = {
            'comment': result,
            'workerCode': this.service.session.get('userCode')
          }
          try {
            
          
          this.service.assignToWorkerrejectedByJobDurationCode(jobcode, data, (res)=>{
            if(res.responsecode == 200){
              this.service.common.message = res.message;
              this.service.common.successMessage = true;
              this.getTimeOfReq(this.service.session.get('userCode'), this.startDate, this.endDate);
              // this.getNewAppointment(this.service.session.get('userCode'), this.startDate, this.endDate);
            } else {
              this.service.common.message = res.message;
              this.service.common.errorMessage = true;
            }
          })
        } catch (error) {
            
          }
        }
      });

    }
  }
}
