import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { ProfileService } from '../profile.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { SettingsService } from '../../settings/settings.service';
import { ConfirmLogoutComponent } from 'src/app/common/confirm-logout/confirm-logout.component';

@Component({
  selector: 'app-adddocumentnote',
  templateUrl: './adddocumentnote.component.html',
  styleUrls: ['./adddocumentnote.component.scss']
})
export class AdddocumentnoteComponent implements OnInit {

  @Output() onAddUpdateRefreshData = new EventEmitter<any>(true);

  notesForm: FormGroup;
  allNotes = [];
  submitted = true;
  sendButton: boolean = false;
  noteCount = 0;
  filter = [];
  readStatus;
  reset: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AdddocumentnoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public common: CommonService,
    public dialog: MatDialog,
    public service: ProfileService, private localStorage: SessionStorageService) { }

  onNoClick(): void {
    this.service.noteData.note = null;
    this.dialogRef.close(this.readStatus);
  }
  ngOnInit(): void {
    //console.log(this.data);
    if (this.data) {
      this.allNotes = this.data.notes;
      this.service.noteData.organizationDocumentCode = this.data.DocumentCode;
      this.filter = this.allNotes.filter(option => option.note != null);
      if (this.filter.length > 0) {

        this.filter[this.filter.length - 1].isdelete = true;
      }
    }
    this.setForm();

    this.allNotes.forEach((element) => {
      if (element.isCommentRead == false) {
        const data = {
          "isNote": false,
          "isRead": true
        }
        this.service.setIsReadOrganizationDocumentWiseCommentByCode(element.orgDocWiseCommentCode, data, (res) => {
          if (res.responsecode == 200) {
            this.readStatus = true;
          }
        });
      }
    });
  }

  geteditNote(element) {
    //console.log(element);
    this.service.noteData.note = element.note;
    this.service.noteData.orgDocWiseCommentCode = element.orgDocWiseCommentCode;
    this.service.noteData.organizationDocumentCode = element.organizationDocumentCode;
  }

  getallNotes() {

    try {

      this.service.getOrganizationDocumentWiseNoteCommentByOrganizationDocumentCode(this.service.noteData.organizationDocumentCode, (response) => {
        //console.log(response);
        if (response.responsecode == 200) {
          this.allNotes = response.data.orgDocCmtList;
          setTimeout(() => {

            this.filter = this.allNotes.filter(option => option.note != null);
            if (this.filter.length > 0) {
              this.filter[this.filter.length - 1].isdelete = true;
            }
          }, 1000);
        }
        else {
          this.allNotes = [];
        }
      });
    } catch (error) {

    }
  }

  deleteNote(documentCode) {
    //console.log(documentCode);
    const dialogRef = this.dialog.open(ConfirmLogoutComponent, {
      width: '350px',
      data: { msg: 'Are you sure you want to delete this document note?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        try {
          this.service.common.progressLoader = true;
          this.service.softDeleteOrganizationDocumentWiseCommentByCode(documentCode, (response) => {
            //console.log(response);
            if (response.responsecode == 200) {
              setTimeout(() => {
                // onAddUpdateRefreshData witout close popup MatDialog [Refresh Data without close popup]
                this.onAddUpdateRefreshData.emit({isUpdate: true});
                // this.dialogRef.close('true');
                this.getallNotes();
              }, 1000);
              // this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              // this.service.common.successMessage = true;
            } else {
              this.service.common.message = response.message;
              this.service.common.progressLoader = false;
              this.service.common.errorMessage = true;
            }
          })

        } catch (error) {
          //console.log(error)
        }

      }
    });
  }


  onSubmit() {
    this.sendButton = true;
    //console.log(this.notesForm);
    //console.log(this.service.noteData)
    //console.log(this.data.notes)
    this.service.common.progressLoader = true;
    if (this.notesForm.value.note) {
      // this.service.noteData.organizationDocumentCode = this.data.notes.organizationDocumentCode;
      this.service.saveAndUpdateOrganizationDocumentWiseNoteComment(this.localStorage.get('userCode'), this.service.noteData, (res) => {
        if (res.responsecode == 200) {
          this.setForm();
          this.reset = false;
          setTimeout(() => {
            this.reset = true;
          });
          this.sendButton = false;
          this.submitted = true;
          this.service.noteData.note = null;
          this.service.noteData.orgDocWiseCommentCode = null;
          //  this.common.successMessage = true;
          // this.common.message = res.message;
          setTimeout(() => {
            // this.dialogRef.close('true');
            // onAddUpdateRefreshData witout close popup MatDialog [Refresh Data without close popup]
            this.onAddUpdateRefreshData.emit({isUpdate: true});
            this.getallNotes();
          }, 1000);
          this.service.common.progressLoader = false;
        } else {
          this.sendButton = false;
          this.common.errorMessage = true;
          this.common.message = res.message;
          this.service.common.progressLoader = false;
        }
      });

    }
    else {
      this.sendButton = false;
      this.submitted = false;
      this.service.common.progressLoader = false;
    }
  }
  setForm() {
    this.notesForm = new FormGroup({
      'note': new FormControl(null, Validators.required)
    });
  }

}
